import React from "react";
import PropTypes from "prop-types";
import Clipboard from "react-clipboard.js";

class CopyToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  render() {
    return (
      <Clipboard
        className={this.props.className}
        style={{
          ...this.props.style,
          color: this.state.copied ? "#00af76" : "#00518C"
        }}
        option-text={() => this.props.text}
        onSuccess={() => {
          this.setState({ copied: true });
          setTimeout(() => this.setState({ copied: false }), 1000);
          this.props.onSuccess();
        }}
      >
        <span className="text-actions"> {this.props.label} </span>
      </Clipboard>
    );
  }
}

CopyToClipboard.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onSuccess: PropTypes.func
};

CopyToClipboard.defaultProps = {
  label: "Copy",
  text: "",
  className: "butn",
  onSuccess: () => {}
};

export default CopyToClipboard;
