/* @flow */

import React from "react";
import withErrorBoundary from "../../../errorBoundaryHOC";

import "./totalSearchResults.less";

type propTypes = {
  total: number
};

const TotalSearchResults = ({ total }: propTypes) => {
  let text;
  if (!total) {
    text = "No dataset found";
  } else {
    text = `${total} dataset${total !== 1 ? "s" : ""} found`;
  }

  return (
    <div className="total-search-results">
      <span>{text}</span>
    </div>
  );
};

export default withErrorBoundary(TotalSearchResults);
