export const editDatasourceProvider = {
  query: `mutation Q ($datasourceUri: String!, $input: UpdateDatasourceProviderInput!) {
        updateDatasourceProvider(datasourceUri:$datasourceUri, input:$input) {
          arn
          overwrite
          userid
          description
          status
        }
      }
    `,
  keyOutput: "updateDatasourceProvider"
};
