/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Routes from "../routes";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";
import "./create.less";
import GraphQl from "../../graphQL";
import Breadcrumb from "../../components/breadcrumb";
import { haveSpecialCharactersCDH } from "../../utils/string";
import SelectClassic from "../../components/SelectClassic";
import { REGIONS } from "../../utils/constants";
import ToggleButton from "../../components/toggleButton";
import HelpOn from "../../components/helpOn";

const Log = Logger("PlaygroundCreate");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  errorSubmit: boolean,
  playground: Object,
  isUpdating: boolean,
  ready: boolean,
  error: Object
};

class PlaygroundEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isUpdating: false,
      ready: false,
      playground: {},
      error: undefined
    };
  }

  componentDidMount() {
    this.getPlayground();
  }

  getPlayground() {
    return this.props.api.playground
      .get(
        this.props.match.params.uriAccount,
        this.props.match.params.uriPlayground
      )
      .then((response) => {
        this.setState({
          ready: true,
          playground: response.playground
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  save = (values, setSubmitting) => {
    this.setState({ isUpdating: true });
    const platformUri = this.props.match.params.uriPlayground;
    const input = {
      name: values.name,
      description: values.description,
      environment: values.environment,
      default_region: values.default_region,
      auto_refresh_stages: this.state.playground.auto_refresh_stages
    };
    return this.props.api.playground
      .update(platformUri, input)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Environment updated",
          type: "success"
        });
        setSubmitting(false);
        this.setState({ isUpdating: false });
        return this.props.goTo({
          route: Routes.Playground.View,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriPlayground: this.props.match.params.uriPlayground
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isUpdating: false });
        this.props.showGlobalNotification({
          message: "Environment update failed",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({
          errorSubmit
        });
      });
  };

  handleChange = () => {
    this.setState((prevState) => {
      const playground = Object.assign({}, prevState.playground);
      playground.auto_refresh_stages =
        !prevState.playground.auto_refresh_stages;
      return { playground };
    });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"PlaygroundEdit"} />;
    if (!this.state.ready) return <Loading message={"Environment Form"} />;
    return (
      <div className="playgrounds-create">
        <div className="title">
          <Breadcrumb view={"Edit environment"} />
        </div>
        <div className="form-container">
          <Formik
            initialValues={{
              name: this.state.playground.name,
              description: this.state.playground.description,
              environment:
                this.state.playground.environment === "Prod"
                  ? "Prod"
                  : "NoProd",
              aws: this.state.playground.aws,
              default_region: this.state.playground.default_region
            }}
            validate={(values) => {
              Log.info("validate", values);
              const errors = {};
              if (!values.name) {
                errors.name = "This field is mandatory";
              }
              if (haveSpecialCharactersCDH(values.name))
                errors.name =
                  "Name must not contain these special characters ( & / ' ; )";
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              Log.info("submit !", values);
              this.save(values, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {this.state.errorSubmit && (
                  <Error
                    error={this.state.errorSubmit}
                    path={"PlaygroundCreate"}
                    rawErrorMessage
                    stringOnly
                  />
                )}
                <fieldset className="form-group">
                  <legend className="label-form">name</legend>
                  <Field
                    type="name"
                    name="name"
                    className="form-control bg-white"
                    placeholder="Environment name..."
                    required
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">description</legend>
                  <Field
                    component="textarea"
                    name="description"
                    className="form-control bg-white"
                    placeholder="Description..."
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Environment</legend>
                  <Field
                    component={({ form, field }) => (
                      <SelectClassic
                        options={[
                          { value: "Prod", label: "Prod" },
                          { value: "NoProd", label: "NoProd" }
                        ]}
                        selectedOption={
                          field.value
                            ? {
                                value: field.value,
                                label: field.value
                              }
                            : null
                        }
                        onSelectOption={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                      />
                    )}
                    name="environment"
                    className="form-control bg-white"
                    required
                  />
                  <ErrorMessage
                    name="environment"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">AWS Account Id</legend>
                  <div className={"alert alert-light"}>
                    <i className="fas fa-exclamation-circle" />
                    &nbsp;Requirements:
                    <div>
                      You need to add AWS IAM Role on your AWS account
                      called&nbsp;
                      <span className={"font-weight-bold"}>
                        powerupmanagement
                      </span>
                      .
                      <div>
                        <a
                          href="https://confluence.tools.digital.engie.com/pages/viewpage.action?pageId=103191880"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info on Helpcenter
                        </a>
                      </div>
                    </div>
                  </div>
                  <Field
                    type="text"
                    name="aws"
                    className="form-control bg-white"
                    placeholder="ex: 123456789101"
                    required
                    disabled
                  />
                  <ErrorMessage
                    name="aws"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Default Region</legend>
                  <Field
                    component={({ form, field }) => (
                      <SelectClassic
                        placeholder="Select a region"
                        options={REGIONS.map((region) => ({
                          value: region.key,
                          label: `(${region.key})  ${region.label}`
                        }))}
                        selectedOption={{
                          value: field.value,
                          label: field.value
                        }}
                        onSelectOption={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                      />
                    )}
                    name="default_region"
                  />
                  <ErrorMessage
                    name="default_region"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label className="label-form">
                    Enable Auto Refresh Stages
                  </label>
                  <div className="auto-refresh-stages">
                    <div className="d-flex">
                      <ToggleButton
                        id="auto-refresh-stages-id"
                        checkedValue={
                          !!this.state.playground.auto_refresh_stages
                        }
                        onChange={() => this.handleChange()}
                      />
                      <div>
                        <HelpOn
                          content={
                            <div>
                              <h3>{"Auto Refresh Stages"}</h3>
                              <div>
                                <b>{"Activated:"}</b>
                              </div>
                              <div>
                                {
                                  "Will allow CDH to discover new stages added to datasets in this "
                                }
                              </div>
                              <div>
                                {"environment and display them in the catalog."}
                              </div>
                              <div>{""}</div>
                              <div>
                                <b>{"Deactivated:"}</b>
                              </div>
                              <div>
                                {
                                  "CHD will only display stages created through the platform."
                                }
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn"
                  >
                    {this.state.isUpdating && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Edit Environment
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(PlaygroundEdit)));
