/* @flow */
import React from "react";
import "./style.less";

type propTypes = {
  items: Array<$Shape<{ value: string, nb: number, label?: string }>>,
  onSelectItem: Function,
  placeholder: string
};
type stateTypes = {
  suggestions: Array<$Shape<{ value: string, nb: number, label?: string }>>,
  isSuggestionsOpen: boolean,
  value: string
};

class SearchByFacet extends React.Component<propTypes, stateTypes> {
  wrapperRef: Object;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      isSuggestionsOpen: false,
      suggestions: props.items,
      value: ""
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node: Object) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event: Object) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideSuggestions();
    } else {
      this.showSuggestions();
    }
  };

  static getDerivedStateFromProps(props: propTypes, state: stateTypes) {
    const filterBy = (): Array<
      $Shape<{ value: string, nb: number, label?: string }>
    > =>
      state.value
        ? props.items.filter((item) =>
            (item.label ? item.label : item.value)
              .toLowerCase()
              .includes(state.value.toLowerCase())
          )
        : props.items;
    return { suggestions: filterBy() };
  }

  filterBy = (
    items: Array<$Shape<{ value: string, nb: number, label?: string }>>,
    value: string
  ): Array<$Shape<{ value: string, nb: number, label?: string }>> =>
    value
      ? items.filter((item) =>
          (item.label ? item.label : item.value)
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      : items;

  onSearch = (event: Object) => {
    const { value } = event.target;
    this.setState({
      value,
      suggestions: this.filterBy(this.props.items, value)
    });
  };

  showSuggestions = () => {
    this.setState({ isSuggestionsOpen: true });
  };

  hideSuggestions = () => {
    this.setState({ isSuggestionsOpen: false });
  };

  onClear = () => {
    this.setState({ value: "", suggestions: this.props.items });
    this.hideSuggestions();
    this.props.onSelectItem("");
  };

  render() {
    return (
      <div className="search-by-facet" ref={this.setWrapperRef}>
        <div className="search-by-facet-input">
          <input
            className="form-control"
            placeholder={this.props.placeholder}
            onChange={this.onSearch}
            value={this.state.value}
          />
          {this.state.value ? (
            <i className="fa fa-times-circle" onClick={this.onClear} />
          ) : (
            <i className="fa fa-caret-down" />
          )}

          {this.state.isSuggestionsOpen && (
            <ul className="search-by-facet-suggestions">
              {this.state.suggestions.map((suggestion) => (
                <li key={suggestion.value}>
                  <div
                    onClick={() => {
                      this.props.onSelectItem(suggestion.value);
                      this.setState({
                        value: suggestion.label
                          ? suggestion.label
                          : suggestion.value
                      });
                      this.hideSuggestions();
                    }}
                  >
                    {suggestion.label ? suggestion.label : suggestion.value}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default SearchByFacet;
