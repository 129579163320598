export const listDatabricksAccountGroups = {
  query: `query Q ($uuid:String!) {
        listDatabricksAccountGroups(uuid: $uuid) {
            limit
            offset
            total
            results {
                dbx_group_name
                dbx_group_id
                dbx_account_uuid
                is_account_admin
                cdh_group_uri
            }
        }
      }
    `,
  keyOutput: "listDatabricksAccountGroups"
};
