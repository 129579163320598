export const queryGetStageProfilingExecution = {
  query: `query Q ($stageId: String!, $datasetUri: String!) {
        getStage(stageId: $stageId, datasetUri: $datasetUri) {
            profiling_history {
                run_id
                status
                platform_uri
                createdat
                aws
                job_name
                glue_job_run_id
            }
        }
    }`,
  keyOutput: "getStage.profiling_history"
};
