/* @flow */

import { queryListMyNotifications } from "./queryListMyNotifications";
import {
  mutationReadNotification,
  type ReadNotificationPropsTypes
} from "./mutationReadNotification";
import {
  mutationDeleteNotification,
  type DeleteNotificationPropsTypes
} from "./mutationDeleteNotification";
import {
  countUnreadNotifications,
  type CountUnreadNotificationsPropsTypes
} from "./queryCountUnreadNotifications";
import {
  queryNotificationTypesList,
  type NotificationTypePropsTypes,
  mutationNotificationTypesCreate,
  type NotificationTypeInputPropsTypes
} from "./notificationTypes";
import type { QueryOptions } from "../propstypes";
import { mutationNotificationCreate } from "./mutationCreateNotification";
import { queryListMyNotificationsTypes } from "./queryListMyNotificationsTypes";
import { mutationBatchActionNotifications } from "./mutationBatchActionNotifications";

class NewtificationApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  create(message: string, notificationTypeId: string): Promise<boolean> {
    return this.fetchAppSync(mutationNotificationCreate, {
      message,
      notificationTypeId
    });
  }

  listMyNotifications(options: QueryOptions) {
    return this.fetchAppSync(queryListMyNotifications, { options }, true);
  }

  listMyNotificationsTypes() {
    return this.fetchAppSync(queryListMyNotificationsTypes);
  }

  readNotification(
    notificationId: string
  ): Promise<ReadNotificationPropsTypes> {
    return this.fetchAppSync(mutationReadNotification, { notificationId });
  }

  deleteNotification(
    notificationId: string
  ): Promise<DeleteNotificationPropsTypes> {
    return this.fetchAppSync(mutationDeleteNotification, { notificationId });
  }

  countUnreadNotifications(): Promise<CountUnreadNotificationsPropsTypes> {
    return this.fetchAppSync(countUnreadNotifications);
  }

  listNotificationTypes(): Promise<Array<NotificationTypePropsTypes>> {
    return this.fetchAppSync(queryNotificationTypesList);
  }

  createNotificationTypes(
    input: NotificationTypeInputPropsTypes
  ): Promise<NotificationTypePropsTypes> {
    return this.fetchAppSync(mutationNotificationTypesCreate, { input });
  }

  batchActionNotifications(
    newtificationIds: Array<string>,
    action: string
  ): Promise<Boolean> {
    return this.fetchAppSync(mutationBatchActionNotifications, {
      newtificationIds,
      action
    });
  }
}

export default NewtificationApi;
