export const createFeatureToggle = {
  query: `
  mutation Q ($input: NewFeatureToggleInput!) {
    createFeatureToggle(input: $input) {
        code
        description
        environment
        version
        status
        groupuri
        userid
    }
  }
`,
  keyOutput: "createFeatureToggle"
};
