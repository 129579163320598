/* @flow */
import React from "react";
import DatasetCard from "../card";
import TotalSearchResults from "../components/totalSearchResults";
import Paginator from "../../../components/paginator";
import withAppSync from "../../AppsyncHOC";
import ExportCSV from "../../../components/exportCsv";
import GraphQl from "../../../graphQL";

type propstypes = {
  api: GraphQl,
  datasets: Array<Object>,
  total: number,
  offset: number,
  onChangeOffset: Function,
  searchOptions: Object
};

class ListDatasets extends React.PureComponent<propstypes> {
  render() {
    const { datasets = [], total, offset, onChangeOffset } = this.props;

    if (datasets.length <= 0) {
      return <TotalSearchResults total={0} />;
    }

    return (
      <div className={"without-carousel"}>
        <div className="catalog-results-header">
          <TotalSearchResults total={total} />
          <ExportCSV
            fetchUrlMethod={() => {
              const searchOptions = Object.assign({}, this.props.searchOptions);
              delete searchOptions.limit;
              delete searchOptions.offset;
              return this.props.api.exportCSV.getCSVExportForCatalog(
                searchOptions
              );
            }}
          />
        </div>
        <div className="list-scroll-datasets">
          <Paginator
            limit={10}
            initialOffset={offset}
            list={datasets}
            componentRender={(dataset) => (
              <DatasetCard key={dataset.uri} {...dataset} />
            )}
            loadPage={onChangeOffset}
            totalCount={total}
          />
        </div>
      </div>
    );
  }
}

export default withAppSync(ListDatasets);
