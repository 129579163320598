/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import withAppSync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import NewStageCardDetails from "./newStageCardDetails";
import { getAccountUriFromUri } from "../../../../utils/toolsForUri";
import { updateMessage } from "../../../globalNotifications/actions";
import Paginator from "../../../../components/paginator";
import "./stageDetails.less";
import PublishRSStage from "../RSStage/publishRSStage";
import { isRSSourceType, isS3SourceType } from "../../../../utils/constants";

const Log = Logger("StagesList");

type propTypes = {
  dataset: Object,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  stages: Object,
  ready: boolean,
  error: ?Object,
  isFetching: boolean,
  isDetectingSchema: boolean,
  isChangingDefaultStage: boolean,
  stagesSyncError?: Object,
  detectSchemaError?: Object,
  changeDefaultStageError?: Object,
  offset: number,
  keywords: string,
  total: number,
  showDataFilters: boolean,
  openPublishRSStageDialog: boolean
};

class NewStageCardList extends Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      stages: {},
      ready: false,
      error: null,
      offset: 0,
      total: 0,
      isFetching: false,
      keywords: "",
      isChangingDefaultStage: false,
      isDetectingSchema: false,
      stagesSyncError: undefined,
      detectSchemaError: undefined,
      changeDefaultStageError: undefined,
      showDataFilters: false,
      openPublishRSStageDialog: false
    };
  }

  componentDidMount() {
    this.isDataFiltersFeatureEnabled();
    this.listStages();
  }

  isDataFiltersFeatureEnabled() {
    const accountUri = `uri:account:${this.props.dataset.uri.split(":")[2]}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATA_FILTERS", accountUri)
      .then((showDataFilters) => {
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  listStages = async () => {
    let dataFiltersFeatureEnabled = false;
    await this.props.api.featureToggle
      .isFeatureToggleEnabled(
        "DATA_FILTERS",
        `uri:account:${this.props.dataset.uri.split(":")[2]}`
      )
      .then((showDataFilters) => {
        dataFiltersFeatureEnabled = showDataFilters;
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
    const options = {
      limit: 10,
      offset: this.state.offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords
      };
    }
    let allDatasetDataFilters = [];
    if (dataFiltersFeatureEnabled && this.props.dataset.access === "owner") {
      // fetch all datafilters for the dataset
      allDatasetDataFilters = await this.props.api.dataset.listDataFilters(
        this.props.dataset.uri,
        null,
        {
          limit: 999,
          offset: 0
        }
      );
      allDatasetDataFilters = allDatasetDataFilters.results;
    }
    return this.props.api.dataset
      .listStages(this.props.dataset.uri, options)
      .then((stages) => {
        stages.results = stages.results.map((stage) => {
          let dataFiltersCount = 0;
          if (dataFiltersFeatureEnabled) {
            dataFiltersCount = allDatasetDataFilters.filter(
              (i) => i.stage.id === stage.id
            ).length;
          }
          return {
            ...stage,
            dataFiltersCount
          };
        });
        this.setState({ stages, total: stages.total, ready: true });
      })
      .catch((stageError) => {
        this.setState({ ready: true, error: stageError });
      });
  };

  detectSchema = () => {
    this.setState({
      isDetectingSchema: true,
      detectSchemaError: undefined
    });
    const accountUri = getAccountUriFromUri(this.props.dataset.uri);
    this.props.api.dataset
      .detectSchema(accountUri, this.props.dataset.uri, "")
      .then((detectSchemaResponse) => {
        Log.info(
          "Automatic schema detection succeeded with response ... ",
          detectSchemaResponse
        );
        this.setState({
          isDetectingSchema: false
        });
        this.props.showGlobalNotification({
          message: "Schema detection started",
          type: "success"
        });
      })
      .catch((detectSchemaError) => {
        Log.info(
          "Automatic schema detection failed with error ...",
          detectSchemaError
        );
        this.setState({
          isDetectingSchema: false,
          detectSchemaError
        });
        this.props.showGlobalNotification({
          message: "Schema detection failed",
          type: "alert"
        });
      });
  };

  setDefaultStage = (stage: Object) => {
    this.setState({
      isChangingDefaultStage: true,
      changeDefaultStageError: undefined
    });
    return this.props.api.dataset
      .setDefaultStage(stage.id, this.props.dataset.uri)
      .then(() => {
        this.setState({ isChangingDefaultStage: false });
        return this.listStages();
      })
      .catch((changeDefaultStageError) => {
        this.setState({
          isChangingDefaultStage: false,
          changeDefaultStageError
        });
      });
  };

  syncStagesWithGlue = () => {
    this.setState({ isFetching: true, stagesSyncError: undefined });
    return this.props.api.dataset
      .syncStagesWithGlue(this.props.dataset.uri)
      .then(() => {
        this.listStages();
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Stages refreshed",
          type: "success"
        });
      })
      .catch((stagesSyncError) => {
        this.setState({ isFetching: false, stagesSyncError });
        this.props.showGlobalNotification({
          message: "Stages refresh failed",
          type: "alert"
        });
      });
  };

  publishRSStages = () => {
    this.setState({ isFetching: true, stagesSyncError: undefined });
    this.props.api.dataset
      .publishRedshiftServerlessStages(this.props.dataset.uri)
      .then(() => {
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Stages refreshed",
          type: "success"
        });
        this.listStages();
      })
      .catch((stagesSyncError) => {
        this.setState({ isFetching: false, stagesSyncError });
      });
  };

  renderCard(stage: Object) {
    return (
      <NewStageCardDetails
        key={stage.id}
        dataset_uri={this.props.dataset.uri}
        stage={stage}
        dataset={this.props.dataset}
        reloadStages={() => this.setDefaultStage(stage)}
        listStages={() => this.listStages()}
        access={this.props.dataset.access}
        dataFiltersCount={stage.dataFiltersCount}
        showDataFilters={
          this.state.showDataFilters && this.props.dataset.access === "owner"
        }
      />
    );
  }

  getOutputsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listStages().then(r))
    );

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords,
          offset: 0
        },
        this.listStages
      );
    }, 300);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    const { stages, ready, error, isFetching, isChangingDefaultStage } =
      this.state;
    return (
      <div>
        {this.props.dataset.access === "owner" &&
          isS3SourceType(this.props.dataset.source_type) && (
            <div>
              <div className="actions-outputs">
                <div>
                  <div
                    className="butn butn-flat"
                    onClick={() =>
                      !this.state.isFetching && this.syncStagesWithGlue()
                    }
                    style={{ margin: "5px", width: "150" }}
                  >
                    {this.state.isFetching && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-sync-alt" />
                    <span className="butn-text">Refresh Stages</span>
                  </div>
                  <div
                    className="butn butn-flat"
                    onClick={() =>
                      !this.state.isDetectingSchema && this.detectSchema()
                    }
                    style={{ margin: "5px", width: "150" }}
                  >
                    {this.state.isDetectingSchema && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-search" />
                    <span className="butn-text">Detect Schema</span>
                  </div>
                  <div
                    className="butn butn-flat"
                    onClick={() =>
                      this.setState({ openPublishRSStageDialog: true })
                    }
                    style={{ margin: "5px", width: "150" }}
                  >
                    <i className="fa fa-paper-plane" />
                    <span className="butn-text">Publish Stages</span>
                  </div>
                </div>
                <div className="search-input">
                  <input
                    className="form-control"
                    placeholder="Search"
                    onChange={this.onChangeInput}
                  />
                  <i className="fas fa-search" />
                </div>
              </div>
            </div>
          )}

        {this.props.dataset.access === "owner" &&
          isRSSourceType(this.props.dataset.source_type) && (
            <div>
              <div className="actions-outputs">
                <div>
                  <div
                    className="butn butn-flat"
                    onClick={this.publishRSStages}
                    style={{ margin: "5px", width: "150" }}
                  >
                    {this.state.isFetching && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-sync-alt" />
                    <span className="butn-text">Refresh Stages</span>
                  </div>
                  <div
                    className="butn butn-flat"
                    onClick={() =>
                      this.setState({ openPublishRSStageDialog: true })
                    }
                    style={{ margin: "5px", width: "150" }}
                  >
                    <i className="fa fa-paper-plane" />
                    <span className="butn-text">Publish Stages</span>
                  </div>
                </div>
                <div className="search-input">
                  <input
                    className="form-control"
                    placeholder="Search"
                    onChange={this.onChangeInput}
                  />
                  <i className="fas fa-search" />
                </div>
              </div>
            </div>
          )}

        {this.state.stagesSyncError && (
          <Error
            error={this.state.stagesSyncError}
            path={"outputsRefresh"}
            stringOnly
          />
        )}
        {this.state.detectSchemaError && (
          <Error
            error={this.state.detectSchemaError}
            path={"detectSchema"}
            stringOnly
          />
        )}
        {this.state.changeDefaultStageError && (
          <Error
            error={this.state.changeDefaultStageError}
            path={"changeDefaultStageError"}
            stringOnly
          />
        )}

        {error && <Error error={error} path={"StagesList"} />}

        {(!ready || isFetching || isChangingDefaultStage) && (
          <Loading message={"Stages"} />
        )}

        {ready && (
          <React.Fragment>
            <div className="card-container">
              <Paginator
                limit={10}
                initialOffset={0}
                totalCount={this.state.total}
                list={stages.results}
                componentRender={(stage) => this.renderCard(stage)}
                loadPage={this.getOutputsFromPagination}
                onNoResult={() => (
                  <p className="total-search-results">
                    No stages found for this dataset.
                  </p>
                )}
              />
            </div>
          </React.Fragment>
        )}
        {this.state.openPublishRSStageDialog && (
          <PublishRSStage
            api={this.props.api}
            dataset={this.props.dataset}
            open={this.state.openPublishRSStageDialog}
            onClose={() => this.setState({ openPublishRSStageDialog: false })}
            reloadStages={this.listStages}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(NewStageCardList));
