/* @flow */
import React from "react";
import route from "../../routes";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import DatasourceCard from "../../users/components/DatasourceCard";
import withGoTo from "../../goToHOC";
import Paginator from "../../../components/paginator";
import "./list.less";

type PropTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  goTo: Function,
  api: GraphQl,
  location: {
    state: {
      account: {
        name: string
      }
    }
  },
  uriAccount: string
};

type StateTypes = {
  ready: boolean,
  datasources: Array<Object>,
  totalDatasources: number,
  error: Object,
  offset: number,
  keywords: string
};

class AccountDatasources extends React.Component<PropTypes, StateTypes> {
  timeout: TimeoutID;

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      ready: false,
      datasources: [],
      totalDatasources: 0,
      error: undefined,
      offset: 0,
      keywords: ""
    };
  }

  onClickNewDatasource = () =>
    this.props.goTo({
      route: route.Datasource.Create,
      state: {
        account: {
          uri: this.props.match.params.uriAccount || this.props.uriAccount
        }
      }
    });

  componentDidMount() {
    this.getAccountDatasources();
  }

  getAccountDatasources = () => {
    const options = {
      limit: 10,
      offset: this.state.offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.account
      .getAccountDatasources(
        this.props.match.params.uriAccount || this.props.uriAccount,
        options
      )
      .then((datasources) => {
        if (!datasources) return false;
        this.setState({
          datasources: datasources.results,
          ready: true,
          totalDatasources: datasources.total
        });
        return datasources;
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          offset: 0,
          keywords
        },
        () => {
          this.getAccountDatasources();
        }
      );
    }, 300);
  };

  getDatasourcesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getAccountDatasources().then(r))
    );

  renderContent() {
    if (this.state.error)
      return <Error error={this.state.error} path="DatasourcesList" />;
    if (!this.state.ready) return <Loading message="Datasources List" />;

    return (
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={0}
          list={this.state.datasources}
          componentRender={(datasource) => (
            <DatasourceCard key={datasource.uri} datasource={datasource} />
          )}
          loadPage={this.getDatasourcesFromPagination}
          onNoResult={() => (
            <p className="total-search-results">No datasources found</p>
          )}
          totalCount={this.state.totalDatasources}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="organizations-list">
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search datasource !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={this.onClickNewDatasource}
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New datasource</span>
              </div>
            </div>
          </div>
        </div>

        {this.renderContent()}
      </div>
    );
  }
}

export default withAppSync(withGoTo(AccountDatasources));
