export const queryList = {
  query: `query Q ($cartUri: String!, $options: ListOptions) {
        listSagemakerNotebookInstances(cartUri:$cartUri, options:$options) {
            offset
            limit
            total
            results {
                id
                arn
                name
                instance_type
                status
                createdat
                updatedat
                error
                region
                enable_shutdown_idle_lifecycle
                network {
                    id
                    name
                    subnet_ids
                    security_group_ids
                }
                environment {
                    uri
                    environment
                    name
                    aws
                }
            }
        }
      }
    `,
  keyOutput: "listSagemakerNotebookInstances"
};
