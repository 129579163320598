/* @flow */

import React from "react";
import routes from "../../../../routes";
import DatasetShareByOwner from "../../../../share/ShareDatasetByOwner";

type propTypes = {
  dataset: Object,
  goTo: Function,
  reload: Function
};

type stateTypes = {
  openShareDatasetForm: boolean
};

class ShareAction extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      openShareDatasetForm: false
    };
  }

  render() {
    if (!this.state.openShareDatasetForm) {
      if (this.props.dataset.can_share && this.props.dataset.shareable) {
        return (
          <button
            type={"button"}
            className="butn butn-flat"
            onClick={() => this.setState({ openShareDatasetForm: true })}
            style={{ margin: "5px" }}
          >
            <i className={"fas fa-lock-open"} />
            <span>{" Share with a group"}</span>
          </button>
        );
      }

      if (
        this.props.dataset.access !== "owner" &&
        this.props.dataset.shareable
      ) {
        return (
          <button
            type={"button"}
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Share.Request,
                params: { uriDataset: this.props.dataset.uri },
                state: { dataset: this.props.dataset }
              })
            }
            style={{ margin: "5px" }}
          >
            <i className={"fas fa-lock-open"} />
            <span>{" Request Access"}</span>
          </button>
        );
      }

      return false;
    }

    return (
      <DatasetShareByOwner
        dataset={this.props.dataset}
        reload={() => {
          this.props.reload();
          this.setState({ openShareDatasetForm: false });
        }}
      />
    );
  }
}

export default ShareAction;
