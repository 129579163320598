/* @flow */
import {
  listGroupIdentities,
  type GroupIdentitiesPropsTypes
} from "./querylistGroupIdentities";
import {
  listGroupNotIdentities,
  type GroupNotIdentitiesPropsTypes
} from "./querylistGroupNotIdentities";
import { getGroup, type GroupPropstypes } from "./queryGetGroup";
import { removeUser } from "./mutationRemoveUserFromGroup";
import { addUser } from "./mutationAddUserToGroup";
import { createGroup } from "./mutationCreateGroup";
import { deleteGroup } from "./mutationDeleteGroup";
import {
  listGroupPolicies,
  type GroupPoliciesPropsTypes
} from "./queryListGroupPolicies";
import { addIamPolicyToGroup } from "./mutationAddPolicyToGroup";
import { removeIamPolicyFromGroup } from "./mutationRemovePolicyFromGroup";
import {
  mutationUpdateGroup,
  type UpdateGroupInputPropsTypes
} from "./mutationUpdateGroup";
import { getGroupMembers } from "./queryGetGroupMembers";
import { getGroupProjects } from "./queryGetGroupProjectsAttached";
import { getGroupEnvironemnts } from "./queryGetGroupEnvironmentsAttached";
import { attachTrustToGroup } from "./mutationAttachTrustToGroup";
import { detachTrustFromGroup } from "./mutationDetachTrustFromGroup";
import { acceptTrustAttachToGroup } from "./mutationAcceptTrustAttachToGroup";
import { rejectTrustAttachToGroup } from "./mutationRejectTrustAttachToGroup";
import { attachTrustToAllGroups } from "./mutationAttachTrustToAllGroups";
import { addIamPolicyToMultipleGroups } from "./mutationAddIamPolicyToMultipleGroups";

class GroupApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  get(groupUri: string): Promise<GroupPropstypes> {
    return this.fetchAppSync(getGroup, { $accountUri: "", groupUri });
  }

  getGroupMembers(groupUri: string, options: Object = {}) {
    return this.fetchAppSync(getGroupMembers, { groupUri, options });
  }

  getGroupProjects(groupUri: string, options: Object = {}) {
    return this.fetchAppSync(getGroupProjects, { groupUri, options });
  }

  getGroupEnvironments(groupUri: string, options: Object = {}) {
    return this.fetchAppSync(getGroupEnvironemnts, { groupUri, options });
  }

  listUsers(
    accountUri: string,
    groupUri: string,
    options: Object = {}
  ): Promise<GroupIdentitiesPropsTypes> {
    return this.fetchAppSync(
      listGroupIdentities,
      { accountUri, groupUri, options },
      true
    );
  }

  listNotUsers(
    accountUri: string,
    groupUri: string,
    options: Object = {}
  ): Promise<GroupNotIdentitiesPropsTypes> {
    return this.fetchAppSync(
      listGroupNotIdentities,
      { accountUri, groupUri, options },
      true
    );
  }

  addUser(
    accountUri: string,
    groupUri: string,
    identityUri: string
  ): Promise<{ uri: string }> {
    return this.fetchAppSync(addUser, { accountUri, groupUri, identityUri });
  }

  removeUser(
    accountUri: string,
    groupUri: string,
    identityUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(removeUser, { accountUri, groupUri, identityUri });
  }

  createGroup(
    accountUri: string,
    newGroupInput: Object
  ): Promise<{ uri: string }> {
    return this.fetchAppSync(createGroup, { accountUri, newGroupInput });
  }

  update(
    groupUri: string,
    input: UpdateGroupInputPropsTypes
  ): Promise<{ uri: string }> {
    return this.fetchAppSync(mutationUpdateGroup, {
      accountUri: "",
      groupUri,
      input
    });
  }

  deleteGroup(groupUri: string): Promise<boolean> {
    return this.fetchAppSync(deleteGroup, { groupUri });
  }

  listPolicies(
    accountUri: string,
    groupUri: string,
    platformUri: string
  ): Promise<GroupPoliciesPropsTypes> {
    return this.fetchAppSync(listGroupPolicies, {
      accountUri,
      groupUri,
      platformUri
    });
  }

  attachPolicy(
    platformUri: string,
    groupUri: string,
    policyArn: string,
    description: string,
    addToExistingProjects: boolean
  ): Promise<boolean> {
    return this.fetchAppSync(addIamPolicyToGroup, {
      platformUri,
      groupUri,
      policyArn,
      description,
      addToExistingProjects
    });
  }

  addIamPolicyToMultipleGroups(
    platformUri: String,
    groupUriList: Array<String>,
    policyArn: string,
    addToExistingProjects: boolean,
    description: string
  ) {
    return this.fetchAppSync(addIamPolicyToMultipleGroups, {
      platformUri,
      groupUriList,
      policyArn,
      addToExistingProjects,
      description
    });
  }

  detachPolicy(
    platformUri: string,
    groupUri: string,
    policyArn: string
  ): Promise<boolean> {
    return this.fetchAppSync(removeIamPolicyFromGroup, {
      platformUri,
      groupUri,
      policyArn
    });
  }

  attachTrustToGroup(trustId: string, groupUri: string) {
    return this.fetchAppSync(attachTrustToGroup, { trustId, groupUri });
  }

  attachTrustToAllGroups(trustId: string) {
    return this.fetchAppSync(attachTrustToAllGroups, { trustId });
  }

  detachTrustFromGroup(trustId: string, groupUri: string) {
    return this.fetchAppSync(detachTrustFromGroup, { trustId, groupUri });
  }

  acceptTrustAttachToGroup(trustId: string, groupUri: string) {
    return this.fetchAppSync(acceptTrustAttachToGroup, { trustId, groupUri });
  }

  rejectTrustAttachToGroup(trustId: string, groupUri: string) {
    return this.fetchAppSync(rejectTrustAttachToGroup, { trustId, groupUri });
  }
}

export default GroupApi;
