export const getOBDCConnection = {
  query: `query Q ($clusterUri:String!,$cartUri:String!) {
        getOBDCConnection (clusterUri:$clusterUri,cartUri:$cartUri) {
            redshift_dsn
            server
            port
            database_name
            database_user
        }
    }
    `,
  keyOutput: "getOBDCConnection"
};
