/* @flow */

import { getDataset, type GetDatasetPropTypes } from "./queryGetDataset";
import { getDatasetPublic } from "./queryGetDatasetPublic";
import { listDatasets, type ListDatasetsProptypes } from "./queryListDatasets";
import {
  createDataset,
  type CreateDatasetPropsTypes
} from "./mutationCreateDataset";
import {
  updateDatasetDetails,
  type UpdateDatasetDetailsPropsTypes,
  type UpdateDatasetInputPropsTypes
} from "./mutationUpdateDataset";
import { presignedUrl, type PresignedUrlPropsTypes } from "./queryPresignedUrl";
import { detectDatasetSchema } from "./mutationDetectSchema";
import { deleteDataset } from "./mutationDeleteDataset";
import { cancelDeleteDataset } from "./mutationCancelDeleteDataset";
import { setDataQualityRule } from "./mutationSetDataQualityRule";
import {
  queryGetDatasetPreview,
  queryGetDatasetPreviewResult,
  queryGetDatasetPreviewStatus
} from "./queryGetPreview";
import { getDatasetShares } from "./queryGetDatasetShare";
import {
  getDatasetOwner,
  type GetDatasetSharingManagementGroupPropTypes
} from "./queryGetDatasetOwner";
import { search, type SearchPropsTypes } from "./querySearch";
import { mutationRunDataProfilingJob } from "./mutationRunDataProfilingJob";
import { queryGetDatasetCrawlers } from "./queryGetDatasetCrawlers";
import { queryGetDatasetCrawlerStatus } from "./queryGetDatasetCrawlerStatus";
import { queryGetDatasetCustomCrawlerStatus } from "./queryGetDatasetCustomCrawlerStatus";
import { updateStageColumnDescription } from "./mutationUpdateStageColumnDescription";
import { addToBookmark, removeFromBookmark } from "./mutationsBookmark";
import type {
  QueryOptions,
  SearchInput,
  StageUpdateInput
} from "../propstypes";
import { searchTags } from "./querySearchTags";
import { queryGetDatasetProjects } from "./queryGetDatasetProjects";
import type { SearchFacetsPropsTypes } from "./querySearchFacets";
import { searchFacets } from "./querySearchFacets";
import { queryListStages } from "./queryListStages";
import { queryGetStage } from "./queryGetStage";
import { queryGetStageProfilingExecution } from "./queryGetStageProfilingExecution";
import { mutationEditStage } from "./mutationEditStage";
import { mutationSetDefaultStage } from "./mutationSetDefaultStage";
import { mutationSyncStagesWithGlue } from "./mutationSyncStagesWithGlue";
import { queryGetRecentStageProfilingExecution } from "./queryGetRecentStageProfilingExecution";
import { listDataFilters } from "./queryListDataFilters";
import { createDataFilter } from "./mutationCreateDataFilter";
import { updateDataFilter } from "./mutationUpdateDataFilter";
import { deleteDataFilter } from "./mutationDeleteDataFilter";
import { getDatasetOutputs } from "./queryGetDatasetOutputs";
import { mutationDeleteStage } from "./mutationDeleteStage";
import { mutationSyncDataFilters } from "./mutationSyncDataFilters";
import { listAllMyDatasets } from "./queryListAllMyDatasets";
import { listMyOwnedDatasets } from "./queryListOwnedDatasets";
import { listSharedWithMeDatasets } from "./queryListSharedWithMeDatasets";
import { listAllMyDatasetsUris } from "./queryListAllMyDatasetsUri";
import { listMyOwnedDatasetsUris } from "./queryListMyOwnedDatasetsUri";
import { mutationStageNormalizing } from "./mutationStageNormalizing";
import { listMyBookmarkedDatasets } from "./queryListMyBookmarkedDatasets";
import { getDatasetBookmark } from "./queryGetDatasetBookmark";
import { getLastDataUpdate } from "./queryGetLastDataUpdate";
import { publishRedshiftServerlessStages } from "./mutationPublishRedshiftServerlessStage";
import { listUnpublishedDatasetStages } from "./queryListUnpublishedDatasetStages";
import { publishDatasetStages } from "./mutationPublishDatasetStages";

class DatasetApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  get(datasetUri: string): Promise<GetDatasetPropTypes> {
    return this.fetchAppSync(getDataset, { datasetUri });
  }

  getDatasetPublic(datasetUri: string): Promise<any> {
    return this.fetchAppSync(getDatasetPublic, { datasetUri });
  }

  getSharingManagementGroup(
    datasetUri: string
  ): Promise<GetDatasetSharingManagementGroupPropTypes> {
    return this.fetchAppSync(getDatasetOwner, { datasetUri });
  }

  getDatasets(option: SearchInput): Promise<ListDatasetsProptypes> {
    return this.fetchAppSync(listDatasets, { search: option }, true);
  }

  search(option: SearchInput): Promise<SearchPropsTypes> {
    return this.fetchAppSync(search, { search: option }, true);
  }

  searchFacets(option: SearchInput): Promise<SearchFacetsPropsTypes> {
    return this.fetchAppSync(searchFacets, { search: option }, true);
  }

  create(
    accountUri: string,
    datasourceUri: string,
    input: Object
  ): Promise<CreateDatasetPropsTypes> {
    return this.fetchAppSync(createDataset, {
      accountUri,
      datasourceUri,
      input
    });
  }

  update(
    datasetUri: string,
    input: UpdateDatasetInputPropsTypes
  ): Promise<UpdateDatasetDetailsPropsTypes> {
    return this.fetchAppSync(updateDatasetDetails, {
      accountUri: "",
      datasetUri,
      input
    });
  }

  presignedUrl(
    datasetUri: string,
    fileName: string,
    prefix: string
  ): Promise<PresignedUrlPropsTypes> {
    return this.fetchAppSync(presignedUrl, { datasetUri, fileName, prefix });
  }

  detectSchema(
    accountUri: string,
    datasetUri: string,
    prefix: string
  ): Promise<string> {
    return this.fetchAppSync(detectDatasetSchema, {
      accountUri,
      datasetUri,
      prefix
    });
  }

  deleteDataset(datasetUri: string): Promise<boolean> {
    return this.fetchAppSync(deleteDataset, { datasetUri });
  }

  cancelDeleteDataset(datasetUri: string): Promise<boolean> {
    return this.fetchAppSync(cancelDeleteDataset, { datasetUri });
  }

  setDataQualityRule(
    datasetUri: string,
    name: string,
    dataQualityRule: string
  ) {
    return this.fetchAppSync(setDataQualityRule, {
      datasetUri,
      name,
      dataQualityRule
    });
  }

  getDatasetPreview(
    datasetUri: string,
    defaultTable?: Object,
    stageId?: string
  ) {
    return this.fetchAppSync(queryGetDatasetPreview, {
      datasetUri,
      defaultTable,
      stageId
    });
  }

  getDatasetPreviewStatus(datasetUri: string, queryId?: string) {
    return this.fetchAppSync(queryGetDatasetPreviewStatus, {
      datasetUri,
      queryId
    });
  }

  getDatasetPreviewResult(datasetUri: string, queryId?: string) {
    return this.fetchAppSync(queryGetDatasetPreviewResult, {
      datasetUri,
      queryId
    });
  }

  getShares(datasetUri: string, options?: QueryOptions) {
    return this.fetchAppSync(getDatasetShares, { datasetUri, options });
  }

  listStages(datasetUri: string, options?: QueryOptions) {
    return this.fetchAppSync(queryListStages, { datasetUri, options });
  }

  listUnpublishedDatasetStages(datasetUri: string) {
    return this.fetchAppSync(listUnpublishedDatasetStages, { datasetUri });
  }

  getStage(stageId: string, datasetUri: string) {
    return this.fetchAppSync(queryGetStage, { stageId, datasetUri });
  }

  updateStage(
    stageId: string,
    stageInput: StageUpdateInput,
    datasetUri: string
  ) {
    return this.fetchAppSync(mutationEditStage, {
      stageId,
      stageInput,
      datasetUri
    });
  }

  setDefaultStage(stageId: string, datasetUri: string) {
    return this.fetchAppSync(mutationSetDefaultStage, { stageId, datasetUri });
  }

  syncStagesWithGlue(datasetUri: string) {
    return this.fetchAppSync(mutationSyncStagesWithGlue, { datasetUri });
  }

  mutationRunDataProfilingJob(outputId: string) {
    return this.fetchAppSync(mutationRunDataProfilingJob, { outputId });
  }

  updateStageColumnDescription(
    datasetUri: string,
    stageId: string,
    name: string,
    description: string
  ) {
    return this.fetchAppSync(updateStageColumnDescription, {
      datasetUri,
      stageId,
      name,
      description
    });
  }

  getDatasetCrawlers(datasetUri: string) {
    return this.fetchAppSync(queryGetDatasetCrawlers, { datasetUri });
  }

  getDatasetCrawlerStatus(datasetUri: string, prefix: string) {
    return this.fetchAppSync(queryGetDatasetCrawlerStatus, {
      datasetUri,
      prefix
    });
  }

  getDatasetCustomCrawlerStatus(datasetUri: string) {
    return this.fetchAppSync(queryGetDatasetCustomCrawlerStatus, {
      datasetUri
    });
  }

  getDatasetProjects(datasetUri: string) {
    return this.fetchAppSync(queryGetDatasetProjects, { datasetUri });
  }

  getStageProfilingExecution(stageId: string, datasetUri: string) {
    return this.fetchAppSync(queryGetStageProfilingExecution, {
      stageId,
      datasetUri
    });
  }

  getRecentStageProfilingExecution(
    stageId: string,
    datasetUri: string
  ): Promise<string> {
    return this.fetchAppSync(queryGetRecentStageProfilingExecution, {
      stageId,
      datasetUri
    });
  }

  addToBookmark(datasetUri: string): Promise<boolean> {
    return this.fetchAppSync(addToBookmark, { datasetUri });
  }

  removeFromBookmark(datasetUri: string): Promise<boolean> {
    return this.fetchAppSync(removeFromBookmark, { datasetUri });
  }

  searchTags(options: SearchInput): Promise<Array<string>> {
    return this.fetchAppSync(searchTags, { options }, true);
  }

  listDataFilters(
    datasetUri: string,
    stageId: ?string = null,
    options?: Object
  ) {
    return this.fetchAppSync(listDataFilters, { datasetUri, stageId, options });
  }

  createDataFilter(datasetUri: string, input: Object) {
    return this.fetchAppSync(createDataFilter, { datasetUri, input });
  }

  updateDataFilter(datasetUri: string, dataFilterId: string, input: Object) {
    return this.fetchAppSync(updateDataFilter, {
      datasetUri,
      dataFilterId,
      input
    });
  }

  deleteDataFilter(datasetUri: string, dataFilterId: string) {
    return this.fetchAppSync(deleteDataFilter, { datasetUri, dataFilterId });
  }

  getDatasetOutputs(datasetUri: string) {
    return this.fetchAppSync(getDatasetOutputs, { datasetUri });
  }

  deleteStage(stageId: string, datasetUri: string, deleteAwsResource: boolean) {
    return this.fetchAppSync(mutationDeleteStage, {
      stageId,
      datasetUri,
      deleteAwsResource
    });
  }

  syncDataFiltersFromLakeFormation(datasetUri: string) {
    return this.fetchAppSync(mutationSyncDataFilters, { datasetUri });
  }

  runStageNormalizing(
    datasetUri: string,
    s3Location: string,
    delay: number,
    maxAttempts: number
  ) {
    return this.fetchAppSync(mutationStageNormalizing, {
      datasetUri,
      s3Location,
      delay,
      maxAttempts
    });
  }

  listAllMyDatasets(options: ?QueryOptions) {
    return this.fetchAppSync(listAllMyDatasets, { options }, true);
  }

  listMyOwnedDatasets(options: ?QueryOptions) {
    return this.fetchAppSync(listMyOwnedDatasets, { options }, true);
  }

  listSharedWithMeDatasets(options: ?QueryOptions) {
    return this.fetchAppSync(listSharedWithMeDatasets, { options }, true);
  }

  listMyBookmarkedDatasets(options: ?QueryOptions) {
    return this.fetchAppSync(listMyBookmarkedDatasets, { options }, true);
  }

  listAllMyDatasetsUris(options: ?QueryOptions) {
    return this.fetchAppSync(listAllMyDatasetsUris, { options }, true);
  }

  listMyOwnedDatasetUris(options: ?QueryOptions) {
    return this.fetchAppSync(listMyOwnedDatasetsUris, { options }, true);
  }

  getDatasetBookmark(datasetUri: string) {
    return this.fetchAppSync(getDatasetBookmark, { datasetUri });
  }

  getLastDataUpdate(uris: Array<string>) {
    return this.fetchAppSync(getLastDataUpdate, { uris });
  }

  publishRedshiftServerlessStages(datasetUri: string) {
    return this.fetchAppSync(publishRedshiftServerlessStages, { datasetUri });
  }

  publishDatasetStages(datasetUri: string, stageInputs: Object) {
    return this.fetchAppSync(publishDatasetStages, { datasetUri, stageInputs });
  }
}

export default DatasetApi;
