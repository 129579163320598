export const addIamPolicyToGroup = {
  query: `mutation Q (
        $platformUri:String!,
        $groupUri:String!,
        $policyArn:String!,
        $description:String,
        $addToExistingProjects:Boolean!
    ) {
        addIamPolicyToGroup(
            platformUri:$platformUri,
            groupUri:$groupUri,
            policyArn:$policyArn,
            description:$description,
            addToExistingProjects:$addToExistingProjects
        ) 
    }
    `,
  keyOutput: "addIamPolicyToGroup"
};
