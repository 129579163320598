export const getMyAccessibleDatasources = {
  query: `
  query Q ($ownership: String!, $cartUri: String!, $options: QueryOptions ) {
    getMyAccessibleDatasources(ownership: $ownership, cartUri: $cartUri, options: $options) {
      uri
      resource_name
      name
      bucket
      description
      tags
      region
      accountid
      parent
      createdat
      aws
      user_roles
      kmsalias
      kmsid
      datasetsList {
        total
      }
      platform {
        uri
        name
        aws
        environment
      }
      s3_custom_tags {
        Key
        Value
      }
      owner {
          uri
          name
      }
    }
  }
`,
  keyOutput: "getMyAccessibleDatasources"
};
