/* @flow */
export const importRedshiftClusters = {
  query: `mutation Q ($platformUri: String!, $importRedshiftClustersInput: ImportRedshiftClustersInput!) {
        importRedshiftClusters(platformUri:$platformUri, importRedshiftClustersInput:$importRedshiftClustersInput) {
          uri
          cluster_name
        }
      }
    `,
  keyOutput: "importRedshiftClusters"
};
export type ImportRedshiftClustersInput = {
  owneruri: string,
  region: string,
  clustersIdentifiers: Array<Object>
};
