/* @flow */
import React from "react";
import "./style.less";
import CopyToClipboard from "../copyToClipboard";

type propTypes = {
  text: string
};

const TextField = ({ text }: propTypes) => (
  <div className="detail-item">
    <span className="detail-item-value">{text}</span>
    <div className="detail-item-value-copy">
      <CopyToClipboard
        text={text}
        className={"fas fa-copy btn-carre"}
        label=""
      />
    </div>
  </div>
);

export default TextField;
