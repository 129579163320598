/* eslint-disable quote-props */
/* @flow */

import React from "react";
import "./style.less";
import { findDomainName } from "../constants";
import Stringslider from "../../../../components/stringslider";

type propTypes = {
  category: string,
  facet: {
    value: string,
    nb: number
  },
  isActive: boolean,
  onClickFacet: Function,
  style?: Object
};

const Facet = ({
  category,
  facet,
  isActive,
  onClickFacet,
  style = {}
}: propTypes) => (
  <li className="facet-criteria" key={facet.value} style={style}>
    <div className="facet-criteria-name">
      <input
        id={`${category}-${facet.value}`}
        type="checkbox"
        className={"checkbox-facet"}
        checked={isActive}
        onClick={(event) =>
          onClickFacet(category, facet.value, event.target.checked)
        }
      />
      <label htmlFor={`${category}-${facet.value}`} className="fas">
        <span className="labelCustom text-capitalize">
          {category === "domain" ? (
            findDomainName(facet.value)
          ) : (
            <Stringslider s={facet.value} height={12} width={200} />
          )}
        </span>
      </label>
    </div>
    <div className={"badge-criteria badge badge-pill badge-primary"}>
      {facet.nb}
    </div>
  </li>
);

export default Facet;
