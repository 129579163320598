/* @flow */
import React from "react";
import withAppSync from "../AppsyncHOC";
import Loading from "../../components/loading";
import Error from "../../components/error";
import Row from "../../components/row";
import GraphQl from "../../graphQL";
import Breadcrumb from "../../components/breadcrumb";
import { fromHex } from "../../utils/encodeHex";
import Paginator from "../../components/paginator";
import "./user.less";
import route from "../routes";
import GroupName from "./components/groupName";
import withGoTo from "../goToHOC";

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      uri: string
    }
  },
  goTo: Function
};

type stateTypes = {
  user: Object,
  identities: Array<Object>,
  error: boolean,
  isFetching: boolean,
  groupsByOrga: Object,
  isLoadingGroups: string
};

class User extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      error: false,
      isFetching: false,
      identities: [],
      groupsByOrga: {},
      isLoadingGroups: ""
    };
  }

  componentDidMount() {
    this.getFindByGid();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.uri !== prevProps.match.params.uri) {
      this.getFindByGid();
    }
  }

  getFindByGid = () => {
    this.setState({ isFetching: true, user: false, error: false });
    this.props.api.identity
      .findByGid(fromHex(this.props.match.params.uri).split(":")[3])
      .then((user) => {
        const { identities } = user;
        const identity =
          identities && identities.length > 0 ? identities[0] : {};
        const groupsByOrga = {};
        identities.forEach((id) => {
          groupsByOrga[id.account.uri] =
            id.groupsList && id.groupsList.results ? id.groupsList.results : [];
        });

        this.setState({
          user: {
            name: identity.name
          },
          isFetching: false,
          identities,
          groupsByOrga
        });
      })
      .catch((error) => {
        this.setState({ error, isFetching: false });
      });
  };

  getGroupsIdentity =
    (identity) =>
    (offset = 0) => {
      this.setState({ isLoadingGroups: identity.uri });
      return this.props.api.identity
        .listGroupsIdentity(identity.uri, { offset })
        .then((groupsResponse) => {
          this.setState((prevState) => {
            const { groupsByOrga } = prevState;
            groupsByOrga[identity.account.uri] = groupsResponse.results;
            return {
              groupsByOrga,
              isLoadingGroups: ""
            };
          });
          return groupsResponse.results;
        })
        .catch((error) => {
          this.setState({ error });
          return [];
        });
    };

  render() {
    const { error, isFetching, user } = this.state;

    return (
      <div className={"container user-view"}>
        <Breadcrumb view="User" />
        {error && <Error error={error} path={"User"} />}
        {isFetching && <Loading message={"User"} />}
        {user && (
          <React.Fragment>
            <Row className={"mt-4 ml-2"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Name:
              </div>
              <div>{user.name}</div>
            </Row>
          </React.Fragment>
        )}
        {this.state.identities.map((identity) => (
          <div className={"mt-4 orga-info"}>
            <Row className="orga-element">
              <div className="orga-title">{identity.account.name}</div>
            </Row>
            <Row className="orga-element">
              <span className={"label-form"} style={{ marginRight: "5px" }}>
                User URI:
              </span>
              <span>{identity.uri}</span>
            </Row>
            <Row className="orga-element">
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Groups:
              </div>
              <div className="identity-groups">
                <Paginator
                  isLoadingPage={this.state.isLoadingGroups === identity.uri}
                  limit={10}
                  initialOffset={0}
                  totalCount={identity.groupsList.total || 0}
                  list={this.state.groupsByOrga[identity.account.uri] || []}
                  componentRender={(group) => (
                    <div
                      key={group.uri}
                      className="card group-card card-shadow noPointer"
                    >
                      <div
                        className="card-group-left"
                        onClick={() =>
                          this.props.goTo({
                            route: route.Group.View,
                            params: {
                              uriGroup: group.uri
                            }
                          })
                        }
                      >
                        <GroupName group={group} />
                        <div>{group.description}</div>
                      </div>
                      <div className="card-group-right"></div>
                    </div>
                  )}
                  loadPage={this.getGroupsIdentity(identity)}
                  onNoResult={() => (
                    <p className="total-search-results">
                      No group or No permissions to list them.
                    </p>
                  )}
                />
              </div>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

export default withGoTo(withAppSync(User));
