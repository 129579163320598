/* @flow */
import React from "react";

type propTypesDatasetCardShared = {
  access: "owner" | "shared" | "other"
};
const DatasetCardShared = ({
  access
}: // eslint-disable-next-line consistent-return
propTypesDatasetCardShared) => {
  if (access === "shared") {
    return <span className="card-dataset-access shared">SHARED</span>;
  }

  if (access === "owner") {
    return <span className="card-dataset-access owner">OWNED</span>;
  }

  return "";
};

export default DatasetCardShared;
