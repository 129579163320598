/* @flow */

export const deleteDataFilter = {
  query: `mutation Q ($datasetUri: String!, $dataFilterId: String!) {
        deleteDataFilter(datasetUri: $datasetUri, dataFilterId: $dataFilterId) {
            id
            name
            resource_name
            creator
            status
            dataset {
                uri
                name
            }
            stage {
                id
                name
            }
            createdat
            updatedat
            description
            database_name
            table_name
            columns
            column_level_access
            row_filter_expression
            region
            aws
        }
      }
    `,
  keyOutput: "deleteDataFilter"
};
