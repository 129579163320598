/* @flow */

export const listDatasets = {
  query: `query Q ($search: SearchInput) {
        searchDatasetResults(search: $search) {
            results {
                uri
                name
                access
            }
        }
      }
    `,
  keyOutput: "searchDatasetResults.results"
};

export type ListDatasetsProptypes = Array<{
  uri: string,
  name: string,
  access: string
}>;
