/* @flow */
import React from "react";
import SelectClassic from "../../../components/SelectClassic";

type Props = {
  datasetName: string,
  datasetUri: string
};

function FormFieldDataset({ datasetName, datasetUri }: Props) {
  return (
    <fieldset className="form-group">
      <legend className="label-form">Dataset:</legend>
      <SelectClassic
        placeholder={datasetName}
        isDisabled
        selectedOption={{
          value: datasetUri,
          label: datasetName
        }}
      />
    </fieldset>
  );
}

export default FormFieldDataset;
