/* @flow */
import React from "react";
import { Field, Form, Formik } from "formik";
import "./view.less";
import Error from "../../../components/error";
import GraphQl from "../../../graphQL";
import SelectClassic from "../../../components/SelectClassic";
import HelpOn from "../../../components/helpOn";

type propTypes = {
  provider: {
    id: string,
    arn: string,
    description: string,
    policy_condition: string,
    type: string
  },
  api: GraphQl,
  onEdit: Function,
  onClose: Function
};
type stateTypes = {
  errorEdit: Object,
  selectedType: Object
};

class CartProviderEditItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      errorEdit: undefined,
      selectedType: {
        label: this.props.provider.type,
        value: this.props.provider.type
      }
    };
  }

  updateTrust = (values: Object = {}) => {
    this.setState({
      errorEdit: undefined
    });

    const input = {
      description: values.description,
      type: this.state.selectedType.value,
      policy_condition: values.policy_condition
    };

    return this.props.api.cart
      .updateTrustFromCart(this.props.provider.id, input)
      .then(() => {
        this.props.onEdit();
      })
      .catch((errorEdit) => {
        this.setState({
          errorEdit
        });
      });
  };

  render() {
    const { provider, onClose } = this.props;
    return (
      <div className={"cart-providers-form"}>
        {this.state.errorEdit && (
          <Error
            error={this.state.errorEdit}
            path={"CartProviderEditItem"}
            stringOnly
          />
        )}
        <Formik
          initialValues={{
            description: provider.description,
            arn: provider.arn,
            policy_condition: provider.policy_condition
          }}
          validate={() => {
            const errors = {};

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) =>
            this.updateTrust(values).then(() => setSubmitting(false))
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="from-group">
                <label className="label-form">Type</label>
                <SelectClassic
                  selectedOption={this.state.selectedType}
                  placeholder={"Select a type"}
                  options={["AssumeRole", "AssumeRoleWithWebIdentity"].map(
                    (elt) => ({
                      label: elt,
                      value: elt
                    })
                  )}
                  onSelectOption={(option) =>
                    this.setState({ selectedType: option })
                  }
                />
              </div>

              <div className="form-group">
                <div style={{ height: "16px" }}></div>
                <label className="label-form">Service or ARN</label>
                <Field
                  disabled
                  name="arn"
                  className="form-control form-control-sm"
                  placeholder=" Arn"
                />
              </div>

              <div className="from-group mt-3">
                <div style={{ display: "flex", gap: "10px" }}>
                  <label className="label-form">Condition</label>
                  <div style={{ position: "relative", top: "-1px" }}>
                    <HelpOn
                      content={
                        <div>
                          <h3>Policy condition</h3>
                          <div>
                            For more information about conditions in trust
                            policy, please
                            <a href="https://aws.amazon.com/blogs/security/how-to-use-trust-policies-with-iam-roles/">
                              read the documentation here
                            </a>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <Field
                  component="textarea"
                  name="policy_condition"
                  className="form-control bg-white mt-1"
                  placeholder="Policy condition..."
                />
              </div>

              <div className="form-group">
                <label className="label-form">Description</label>
                <Field
                  name="description"
                  component="textarea"
                  className="form-control form-control-sm"
                  placeholder=" Write a description..."
                />
              </div>

              <div className="actions">
                <button
                  type="button"
                  onClick={onClose}
                  className="butn butn-delete"
                >
                  Close
                </button>
                <button type="submit" disabled={isSubmitting} className="butn">
                  Edit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CartProviderEditItem;
