/* @flow */

import React from "react";
import DatasetShareRequestPending from "./datasetShareRequestPending";
import DatasetShareRequestAccepted from "./datasetShareRequestAccepted";
import DatasetShareRequestRejected from "./datasetShareRequestRejected";
import DatasetShareRequestRevoked from "./datasetShareRequestRevoked";
import withErrorBoundary from "../../../errorBoundaryHOC";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";

type propTypes = {
  share: {
    id?: string,
    dataset: {
      access: string,
      uri: string,
      name: string,
      expiration_date?: string,
      datasource: {
        uri: string,
        name: string
      }
    },
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    request_status: string,
    createdat: string,
    updatedat: ?string,
    message: string
  },
  api: GraphQl,
  onAction: Function
};

const DatasetShare = ({ share, api, onAction }: propTypes) => {
  if (share.request_status === "accepted") {
    return (
      <DatasetShareRequestAccepted
        key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
        revokeShare={(shareUri, groupUri, rejectMessage) =>
          api.share.revokeShare(shareUri, groupUri, rejectMessage)
        }
        onAction={onAction}
      />
    );
  }

  if (share.request_status === "pending") {
    return (
      <DatasetShareRequestPending
        key={`${share.dataset.uri}.${share.group.uri}`}
        pendingShareRequest={share}
        api={api}
        onAction={onAction}
      />
    );
  }

  if (share.request_status === "rejected") {
    return (
      <DatasetShareRequestRejected
        key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
        acceptRequest={(shareUri, groupUri) =>
          api.share.acceptRequest(shareUri, groupUri)
        }
        onAction={onAction}
      />
    );
  }

  if (share.request_status === "revoked") {
    return (
      <DatasetShareRequestRevoked
        key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
        acceptRequest={(shareUri, groupUri) =>
          api.share.acceptRequest(shareUri, groupUri)
        }
        onAction={onAction}
      />
    );
  }

  return <div>{"{{unknown share}}"}</div>;
};

DatasetShare.defaultProps = {
  share: {
    request_status: "",
    createdat: "00-00-00 00:00:00",
    updatedat: undefined,
    dataset: {
      access: "read",
      uri: ":::::::",
      name: "-",
      datasource: {
        uri: ":::::::",
        name: "-"
      }
    },
    group: {
      uri: ":::::::",
      name: "-",
      account: {
        name: "-"
      }
    }
  },
  onAction: Function
};

export default withAppSync(withErrorBoundary(DatasetShare));
