export const updateMyEmailNotificationSubscriptionConfig = {
  query: `mutation Q ($config: String!) {
        updateMyEmailNotificationSubscriptionConfig(config: $config) {
              dataset {
                id
                name
                description
                enable
              }
              datasource {
                  id
                  name
                  description
                  enable
              }
              group {
                  id
                  name
                  description
                  enable
              }
              customCDH {
                  id
                  name
                  description
                  enable
              }
        }
      }
    `,
  keyOutput: "updateMyEmailNotificationSubscriptionConfig"
};
