/* @flow */

export const listUnpublishedDatasetStages = {
  query: `query Q ($datasetUri: String!) {
        listUnpublishedDatasetStages(datasetUri: $datasetUri){
            name
            location
        }
    }
    `,
  keyOutput: "listUnpublishedDatasetStages"
};
