/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable camelcase */
import React from "react";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Logger from "../../../../utils/logger";
import ShareDatasetRequestManaged from "../managedShare/datasetShare";
import GraphQl from "../../../../graphQL";
import Paginator from "../../../../components/paginator";
import "../style.less";
import FilterPolicy from "../../../groups/policyManagement/filtersPolicies/filterPolicy";

const Log = Logger("ManagedShareList");

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  sharesManaged: Array<Object>,
  sharesManagedReady: boolean,
  sharesManagedError: Object,
  sharesManagedTotal: number,
  sharesManagedLimit: number,
  searchGroupSharingManagement: Object,
  searchGroupRequester: Object,
  searchStatus: Object,
  groupsSharingManagement: Array<Object>,
  groupsRequester: Array<Object>,
  statusSuggestions: Array<string>
};

class ManagedShareList extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      sharesManagedReady: false,
      sharesManagedError: undefined,
      searchGroupSharingManagement: { key: "", value: "" },
      searchGroupRequester: { key: "", value: "" },
      searchStatus: { key: "", value: "" },
      sharesManaged: [],
      sharesManagedTotal: 0,
      sharesManagedLimit: 10,
      groupsSharingManagement: [],
      groupsRequester: [],
      statusSuggestions: ["ACCEPTED", "REVOKED", "PENDING"]
    };
  }

  componentDidMount() {
    this.getGroupsSharingManagement();
    this.getGroupsRequester();
    this.listMyManagedShares();
  }

  getGroupsSharingManagement() {
    this.props.api.share
      .getGroupsSharingManagementSent()
      .then((groups) => {
        this.setState({
          groupsSharingManagement: groups
        });
      })
      .catch((sharesManagedError) => {
        Log.error(sharesManagedError);
        this.setState({ sharesManagedError });
        return [];
      });
  }

  getGroupsRequester() {
    this.props.api.share
      .getGroupsRequesterSent()
      .then((groups) => {
        this.setState({
          groupsRequester: groups
        });
      })
      .catch((sharesManagedError) => {
        Log.error(sharesManagedError);
        this.setState({ sharesManagedError });
        return [];
      });
  }

  listMyManagedShares = (offset = 0) => {
    this.setState({ sharesManagedReady: false });
    Log.verbose("search dataset start...");
    const options: {
      limit?: number,
      term?: string,
      filters?: Array<any>,
      order_by?: ?Object,
      group_by?: ?Object,
      offset?: number,
      search?: ?Object
    } = {
      offset,
      filters: []
    };

    if (this.state.searchGroupSharingManagement.value) {
      options.filters.push(this.state.searchGroupSharingManagement);
    }

    if (this.state.searchGroupRequester.value) {
      options.filters.push(this.state.searchGroupRequester);
    }

    if (this.state.searchStatus.value) {
      options.filters.push(this.state.searchStatus);
    }

    return this.props.api.share
      .listMyManagedShares(options)
      .then((MyManagedSharesResponse) => {
        this.setState({
          sharesManagedReady: true,
          sharesManaged: MyManagedSharesResponse.results,
          sharesManagedTotal: MyManagedSharesResponse.total,
          sharesManagedLimit: MyManagedSharesResponse.limit
        });
        return MyManagedSharesResponse.results;
      })
      .catch((sharesManagedError) => {
        Log.error(sharesManagedError);
        this.setState({
          sharesManagedReady: true,
          sharesManagedError
        });
        return [];
      });
  };

  render() {
    if (this.state.sharesManagedError)
      return (
        <Error
          error={this.state.sharesManagedError}
          path={"ManagedShareList"}
        />
      );

    return (
      <React.Fragment>
        <div className="filters">
          <FilterPolicy
            onChangeTerm={(group_uri) =>
              this.setState((prevState) => {
                let { searchGroupSharingManagement } = prevState;
                searchGroupSharingManagement = {
                  key: "share_manager",
                  value: group_uri
                };
                return { searchGroupSharingManagement };
              })
            }
            runSearch={() => this.listMyManagedShares()}
            onClear={() => {
              this.setState((prevState) => {
                let { searchGroupSharingManagement } = prevState;
                searchGroupSharingManagement = { key: "", value: "" };
                return { searchGroupSharingManagement };
              });
              this.listMyManagedShares();
            }}
            suggestions={this.state.groupsSharingManagement}
            placeholder="Select Group Sharing Management"
          />
          <FilterPolicy
            onChangeTerm={(group_uri) =>
              this.setState((prevState) => {
                let { searchGroupRequester } = prevState;
                searchGroupRequester = {
                  key: "share_requester",
                  value: group_uri
                };
                return { searchGroupRequester };
              })
            }
            runSearch={() => this.listMyManagedShares()}
            onClear={() => {
              this.setState((prevState) => {
                let { searchGroupRequester } = prevState;
                searchGroupRequester = { key: "", value: "" };
                return { searchGroupRequester };
              });
              this.listMyManagedShares();
            }}
            suggestions={this.state.groupsRequester}
            placeholder="Select Group Requester"
          />
          <FilterPolicy
            onChangeTerm={(status: string) =>
              this.setState((prevState) => {
                let { searchStatus } = prevState;
                if (status) {
                  searchStatus = { key: "status", value: status.toLowerCase() };
                } else {
                  searchStatus = { key: "", value: "" };
                }
                return { searchStatus };
              })
            }
            runSearch={() => this.listMyManagedShares()}
            onClear={() => {
              this.setState((prevState) => {
                let { searchStatus } = prevState;
                searchStatus = { key: "", value: "" };
                return { searchStatus };
              });
              this.listMyManagedShares();
            }}
            suggestions={this.state.statusSuggestions}
            placeholder="Select Status"
          />
        </div>
        <Paginator
          isLoadingPage={!this.state.sharesManagedReady}
          container={(content) => <ul className="list-group">{content}</ul>}
          limit={this.state.sharesManagedLimit}
          initialOffset={0}
          totalCount={this.state.sharesManagedTotal}
          list={this.state.sharesManaged}
          componentRender={(share) => (
            <ShareDatasetRequestManaged
              key={`share.${share.dataset.uri}.${share.group.uri}`}
              share={share}
              dataset={share.dataset}
              onAction={this.listMyManagedShares}
            />
          )}
          loadPage={this.listMyManagedShares}
          onNoResult={() => "You dont have any share."}
        />
      </React.Fragment>
    );
  }
}

export default withAppSync(ManagedShareList);
