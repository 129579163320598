/* @flow */
import React from "react";

const HelpLevelOfMaturity = () => (
  <div>
    <h3>{"Level of maturity"}</h3>
    <div>
      <ul className="help-level-maturity">
        <li>
          <u>Initial</u> : in construction/ draft. Can contain inconsistent
          data. NO COMMITMENT of the owner to keep dataset structure and
          expected type of data flied.
        </li>
        <li>
          <u>Managed</u> : dataset in published version with RISK of
          inconsistent data. NO COMMITMENT of the owner to keep dataset
          structure and expected type of data flied.
        </li>
        <li>
          <u>Standardized</u> : dataset in published version with RISK of
          inconsistent data. COMMITMENT of the owner to keep dataset structure
          and expected type of data flied.
        </li>
        <li>
          <u>Optimized</u> : dataset in published version with NO RISK of
          inconsistent data. COMMITMENT of the owner to keep dataset structure
          and expected type of data flied.
        </li>
      </ul>
    </div>
    <div>
      <table className="tg">
        <thead>
          <tr>
            <th className="tg-0lax"></th>
            <th className="tg-0lax">Initial</th>
            <th className="tg-0lax">Managed</th>
            <th className="tg-0lax">Standardized</th>
            <th className="tg-0lax">Optimized</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0lax">
              Dataset Version<br></br>
            </td>
            <td className="tg-0lax">Draft</td>
            <td className="tg-0lax">Published</td>
            <td className="tg-0lax">Published</td>
            <td className="tg-0lax">Published</td>
          </tr>
          <tr>
            <td className="tg-0lax">Risk of inconsistent data</td>
            <td className="tg-0lax">Yes</td>
            <td className="tg-0lax">Yes</td>
            <td className="tg-0lax">
              Yes<br></br>
            </td>
            <td className="tg-0lax">No</td>
          </tr>
          <tr>
            <td className="tg-0lax">
              Data owner commitment on keeping<br></br>dataset structure and
              data flied expected type<br></br>
            </td>
            <td className="tg-0lax">No</td>
            <td className="tg-0lax">No</td>
            <td className="tg-0lax">Yes </td>
            <td className="tg-0lax">Yes</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default HelpLevelOfMaturity;
