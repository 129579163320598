const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getErrorMessage = (error) => {
  try {
    if (typeof error === "string") return error;
    if (error && error.message && isJson(error.message)) {
      const err = JSON.parse(error.message);
      if (err[0]) return err.map((e) => e.message).join(", ");
      return err.message;
    }
    if (
      typeof error === "object" &&
      error.message &&
      typeof error.message === "string"
    )
      return error.message;
    if (Array.isArray(error))
      return error.map((e) => e.message).join(" ******* ");
  } catch (e) {
    return "";
  }
  return "";
};

const getErrorMessageWithoutFunctionalCode = (errorMessage = "") =>
  errorMessage
    .split("(CODE/F1000):")
    .slice(-1)
    .pop() // eslint-disable-line newline-per-chained-call
    .split("(RULES/R1000):")
    .slice(-1)
    .pop(); // eslint-disable-line newline-per-chained-call

export const getErrorMessageWithoutCode = (error) =>
  getErrorMessageWithoutFunctionalCode(getErrorMessage(error));

export const isPermissionError = (error) => {
  const message = getErrorMessage(error);
  return (
    (typeof error === "object" && error.errorType === "ForbiddenOperation") ||
    message.indexOf("Forbidden Operation Error (SEC/S1000)") !== -1
  );
};

export const understandableErrorMessage = (errorMessage) => {
  // specific messages
  if (
    errorMessage.indexOf(
      "You need to be part of a group before a request access..."
    ) !== -1
  ) {
    return "You need to be part of a group before a request access...";
  }
  if (errorMessage.indexOf("Execution timed out") !== -1) {
    return "A communication error with server has occurred, try again";
  }
  const errorD100 = "Error (DATAERROR/D1000):";
  if (errorMessage.indexOf(errorD100) !== -1) {
    return errorMessage.substr(
      errorMessage.indexOf(errorD100) + errorD100.length
    );
  }
  if (
    errorMessage.indexOf(
      '(psycopg2.IntegrityError) duplicate key value violates unique constraint "tplatform_pkey"'
    ) !== -1
  ) {
    return "This playground name already exists, choose an other";
  }
  if (
    errorMessage ===
    "Variable 'newPlatformInput' has coerced Null value for NonNull type 'String!'"
  ) {
    return "The environment field can't be empty";
  }

  return getErrorMessageWithoutFunctionalCode(errorMessage);
};
