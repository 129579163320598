export const queryListMyNotificationsTypes = {
  query: `query Q {
    listMyNotificationsTypes {
        id
        domain
        code
        name
    }
}
`,
  keyOutput: "listMyNotificationsTypes"
};
