/* @flow */

export const presignedUrl = {
  query: `query Q ($datasetUri:String!, $fileName: String!, $prefix: String!){
        getPresignedUrlForDataset(datasetUri:$datasetUri, fileName : $fileName, prefix:$prefix){
            url
            headers
        }
      }
    `,
  keyOutput: "getPresignedUrlForDataset"
};

export type PresignedUrlPropsTypes = {
  url: string,
  headers: string
};
