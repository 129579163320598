export const listCartServices = {
  query: `query Q ($cartUri: String!) {
        listCartServices(cartUri:$cartUri) {
            id
            name
            description
            mandatory
            active
        }
    }
    `,
  keyOutput: "listCartServices"
};
