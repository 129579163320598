import React from "react";
import PropTypes from "prop-types";
import Uploadform from "../components/uploadform";
import withAppSync from "../../AppsyncHOC";
import Routes from "../../routes";
import withGoTo from "../../goToHOC";
import "./uploadData.less";
import config from "../../../config";
import HelpOn from "../../../components/helpOn";

class UploadDataDataset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choose: "now" //eslint-disable-line
    };
  }

  redirectTabPreview() {
    this.props.goTo({
      route: Routes.Dataset.Preview,
      params: {
        uriDataset: this.props.match.params.uriDataset
      }
    });
    window.location.reload();
  }

  render() {
    const { match, api } = this.props;

    return (
      <React.Fragment>
        <div className={"upload-data"}>
          <HelpOn
            content={
              <div>
                <div>{" - Dataset Upload. "}</div>
                <div>
                  {"You can upload your data manually via the portal (<5Gb)"}
                </div>
                <div>
                  {
                    "Please note that the upload can take several minutes depending of the file size."
                  }
                </div>
                <div style={{ marginTop: "15px" }} />
                <div>
                  {
                    "|!| After upload you need to wait some time to see the preview. "
                  }
                </div>
                {/* add a link to help center */}
                <hr />
                <div>{"Requirement:"}</div>
                <div>{"- To be owner of the dataset."}</div>
              </div>
            }
            helpCenterLink={`${config.HELP_CENTER}`}
          />
        </div>
        <div className="form-container">
          <div className="form-group">
            <Uploadform
              dataset={{
                uri: match.params.uriDataset,
                type: this.props.dataset.type,
                name: this.props.dataset.name,
                arn: this.props.dataset.arn
              }}
              api={api}
              onNext={() => this.redirectTabPreview()}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UploadDataDataset.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uriDataset: PropTypes.string.isRequired
    })
  }),
  api: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      dataset: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  goTo: PropTypes.func,
  dataset: Object
};

export default withAppSync(withGoTo(UploadDataDataset));
