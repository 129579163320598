export const listDssInstanceGroups = {
  query: `query Q ($dssInstanceUri:String!, $options: QueryOptions) {
        listDssInstanceGroups(dssInstanceUri: $dssInstanceUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                description
            }
        }
      }
    `,
  keyOutput: "listDssInstanceGroups"
};
