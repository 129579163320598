/* @flow */

export const updateShareDatasource = {
  query: `mutation Q ($datasourceUri: String!, $groupUri: String!, $areNewDatasetsShared: Boolean) {
        updateShareDatasource(datasourceUri:$datasourceUri, groupUri:$groupUri, areNewDatasetsShared:$areNewDatasetsShared) {
          datasource {
              uri
          }
          group {
              uri
              name
          }
          createdat
          updatedat
          are_new_datasets_shared 
        }
      }
    `,
  keyOutput: "updateShareDatasource"
};
