/* @flow */

import React from "react";
import { connect } from "react-redux";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import ButtonAction from "../../../components/buttonAction";
import "./view.less";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  onSuccess: Function,
  trust: {
    arn: string,
    description: string,
    is_admin: boolean,
    status: string,
    id: string,
    type: string,
    platform: {
      name: string,
      aws: string,
      uri: string
    }
  },
  cart: Object,
  onEdit: Function,
  onDelete: Function
};

type stateTypes = {
  error?: Object
};

class CartProviderItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  getClassStatus = () => {
    switch (this.props.trust.status) {
      case "accepted":
        return "trust-status-text-accepted";
      case "pending":
        return "trust-status-text-pending";
      default:
        return "trust-status-text-rejected";
    }
  };

  acceptTrustAttachToCart = (trust) =>
    this.props.api.cart
      .acceptTrustAttachToCart(trust.id, this.props.cart.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust attached to project",
          type: "success"
        });
        if (this.props.cart.is_refresh_auto_enabled) {
          this.props.showGlobalNotification({
            message:
              "Trust attached to project, your role project will be automatically refreshed",
            type: "success"
          });
        }
        this.props.onSuccess();
      })
      .catch((error) => {
        this.setState({
          error
        });
        this.props.showGlobalNotification({
          message: "Failed to attached trust",
          type: "alert"
        });
      });

  rejectTrustAttachToCart = (trust) =>
    this.props.api.cart
      .rejectTrustAttachToCart(trust.id, this.props.cart.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust detached from project",
          type: "success"
        });
        if (this.props.cart.is_refresh_auto_enabled) {
          this.props.showGlobalNotification({
            message:
              "Trust detached from project, your role project will be automatically refreshed",
            type: "success"
          });
        }
        this.props.onSuccess();
      })
      .catch((error) => {
        this.setState({
          error
        });
        this.props.showGlobalNotification({
          message: "Failed to detached trust",
          type: "alert"
        });
      });

  render() {
    return (
      <React.Fragment>
        <div className={"cart-provider-item"}>
          <div className={"cart-provider-item-content"}>
            <div className={"wrap-word"}>
              <span className="bold">Service or ARN:</span>
              <span className="trust-arn">{this.props.trust.arn}</span>
            </div>
            <div className={"wrap-word"}>
              <span className="bold">Environment:</span>
              <span className="platform-name-aws">
                {`${this.props.trust.platform.name} [${this.props.trust.platform.aws}]`}
              </span>
            </div>
            <div className={"wrap-word"}>
              <span className="bold">Description:</span>
              <span className="description-type">
                {this.props.trust.description}
              </span>
            </div>
            <div className={"wrap-word"}>
              <span className="bold">Type:</span>
              <span className="description-type">{this.props.trust.type}</span>
            </div>
            {this.props.trust.is_admin && (
              <div className={"wrap-word"}>
                <span className="bold">Status:</span>
                <span className="trust-status">
                  <span className={this.getClassStatus()}>
                    <span className="status-label">
                      {this.props.trust.status}
                    </span>
                  </span>
                </span>
              </div>
            )}
          </div>
          {this.props.trust.is_admin && (
            <React.Fragment>
              <div className={"cart-provider-admin-text"}>Admin Trust</div>
              <div className="cart-provider-admin">
                <div className="admin-buttons-trusts">
                  {this.props.trust.status === "rejected" && (
                    <ButtonAction
                      label="Accept"
                      type="button"
                      className="butn butn-check"
                      onClick={() =>
                        this.acceptTrustAttachToCart(this.props.trust)
                      }
                    />
                  )}
                  {this.props.trust.status === "accepted" && (
                    <ButtonAction
                      label="Reject"
                      type="button"
                      className="butn"
                      onClick={() =>
                        this.rejectTrustAttachToCart(this.props.trust)
                      }
                    />
                  )}
                  {this.props.trust.status === "pending" && (
                    <div>
                      <ButtonAction
                        label="Accept"
                        type="button"
                        className="butn butn-check"
                        onClick={() =>
                          this.acceptTrustAttachToCart(this.props.trust)
                        }
                      />
                      <ButtonAction
                        label="Reject"
                        type="button"
                        className="butn"
                        onClick={() =>
                          this.rejectTrustAttachToCart(this.props.trust)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
          {!this.props.trust.is_admin && (
            <div className="cart-provider-no-admin">
              <ButtonAction
                label="Edit"
                type="button"
                className="butn"
                onClick={this.props.onEdit}
              />
              <ButtonAction
                label="Delete"
                type="button"
                className="butn butn-delete"
                onClick={this.props.onDelete}
              />
            </div>
          )}
        </div>
        {this.state.error && (
          <Error error={this.state.error} path="CartProviderItem" stringOnly />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(CartProviderItem));
