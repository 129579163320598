/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../../links";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import Stringslider from "../../../components/stringslider";
import { formatDate } from "../../../utils/date";
import "../style.less";

type propTypes = {
  project: Object
};

class ProjectArchiveCard extends React.PureComponent<propTypes> {
  render() {
    return (
      <div className={"archive-card card-shadow card bg-white"}>
        <div className="card-archive-header">
          <div className="card-archive-begin">
            <div className="card-archive-begin-content">
              <i className="fas fa-user-friends" />
              <Links.Group.View
                uriGroup={this.props.project.groupuri}
                state={{ group: this.props.project.groupuri }}
              >
                <span className="text-header-archive">
                  {this.props.project.group_name.substring(0, 50)}
                </span>
              </Links.Group.View>
            </div>
          </div>
          <div className="card-archive-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-archive">
              {formatDate(this.props.project.cart_archivedat, false)}
            </span>
          </div>
        </div>
        <div className="card-archive-body">
          <div className="card-archive-body-left">
            <div className={"text-capitalize archive-card-name"}>
              <Stringslider
                s={this.props.project.name}
                height={30}
                width={275}
              />
            </div>
            <div className="card-archive-details">
              <div className="cdh-card-detail">
                <i className="fas fa-user-shield" />
                <span className="content-card-bucket">
                  <b>Archived by:</b>{" "}
                  {this.props.project.creatoruri.split(":").pop()}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-key" />
                <span className="content-card-bucket">
                  <b>Role name:</b> {this.props.project.role_name}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-database" />
                <span className="content-card-bucket">
                  <b>Glue database:</b> {this.props.project.database}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-globe-europe" />
                <span className="content-card-bucket">
                  <b>Region:</b> {this.props.project.region}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-folder-open" />
                <span className="content-card-bucket">
                  <b>Datasets:</b> {this.props.project.datasets.length}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-chart-line" />
                <span className="content-card-bucket">
                  <b>PowerBI:</b>{" "}
                  {this.props.project.is_attached_to_powerbi ? "Yes" : "No"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-archive-footer">
          <div className="card-archive-begin">
            <div className="card-archive-begin-content">
              <div className="flag-user-roles">
                <span className="tag-metrics user-role">
                  {this.props.project.action_type || "DETACH_FOR_EQUANS"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(ProjectArchiveCard));
