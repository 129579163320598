/* @flow */
import React from "react";
import { SecureRoute, withAuth } from "@okta/okta-react";
import ReactGA from "react-ga";
import Navbar from "./navbar";
import withErrorBoundary from "./errorBoundaryHOC";
import withGoTo from "./goToHOC";
import ReportProblemButton from "./reportProblem/reportProblemButton";
import GlobalNotifications from "./globalNotifications";
import isSupported from "../utils/supportedBrowser";
import "./layout.less";
import Footer from "./footer";
import { isUserFromCDHTeam } from "../utils/cdhTeam";

type propTypes = {
  classname: string,
  component: Object | Function,
  auth: {
    getUser: Function
  },
  location: {
    pathname: string
  },
  path: string
};

type stateTypes = {
  component: Object | Function,
  componentWithHoc: Object,
  isUserFromCDHTeam: boolean
};

class LayoutRoute extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      componentWithHoc: withGoTo(withErrorBoundary(props.component)),
      isUserFromCDHTeam: false
    };
  }

  componentDidMount() {
    this.props.auth.getUser().then((user = {}) => {
      this.setState({
        isUserFromCDHTeam: isUserFromCDHTeam(
          (user.preferred_username || "").split("@")[0]
        )
      });
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.component !== state.component) {
      return {
        component: props.component,
        componentWithHoc: withGoTo(withErrorBoundary(props.component))
      };
    }
    return null;
  }

  render() {
    ReactGA.set({
      page:
        window.location.pathname + window.location.hash + window.location.search
    });
    ReactGA.pageview(
      window.location.pathname + window.location.hash + window.location.search
    );

    const { component, ...rest } = this.props;
    const Component = this.state.componentWithHoc; // HOC, all containers receive props 'location' and 'auth'

    return (
      <SecureRoute
        {...rest}
        // eslint-disable-next-line no-confusing-arrow
        render={(matchProps) =>
          isSupported ? (
            <div id="layout">
              <Navbar isUserFromCDHTeam={this.state.isUserFromCDHTeam} />
              <GlobalNotifications />
              <div
                className={`${this.props.classname} page-container container`}
              >
                <div className={"full-size-container"}>
                  <Component {...matchProps} />
                </div>
              </div>
              <ReportProblemButton />
              <Footer />
            </div>
          ) : (
            <div></div>
          )
        }
      />
    );
  }
}

export default withAuth(LayoutRoute);
