/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import Links from "../../links";
import { formatDate } from "../../../utils/date";
import Breadcrumb from "../../../components/breadcrumb";
import routes from "../../routes";
import Paginator from "../../../components/paginator";
import RemoveShareItemModal from "./RemoveShareItemModal";
import RemoveAllShareItemsModal from "./RemoveAllShareItemsModal";

type propTypes = {
  match: {
    params: {
      shareId: string
    },
    url: string
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  isAddingAll: boolean,
  isAccepting: boolean,
  isRevoking: boolean,
  isRejecting: boolean,
  error: boolean,
  share: Object,
  removeItemModal: boolean | Object,
  removeAllShareItemsModal: boolean | Object,
  showDataFilters: boolean
};

function ShareItemRow(props: {
  share: any,
  item: any,
  openRemoveItemModal: any,
  showDataFilters: boolean
}) {
  return (
    <tr className="table-row">
      <td className="table-link">
        {!props.share.all_tables && props.item.table_name !== "ALL_TABLES" ? (
          <Links.Stage.View
            datasetUri={props.share.dataset.uri}
            outputId={(props.item || {}).stage_id || "null"}
          >
            <span>{props.item.table_name}</span>
          </Links.Stage.View>
        ) : (
          <span>{props.item.table_name}</span>
        )}
      </td>
      {props.showDataFilters && (
        <td>
          {props.item.data_filter_id && (
            <Links.Dataset.View uriDataset={props.share.dataset.uri}>
              {props.item.data_filter.description ? (
                <Tooltip title={props.item.data_filter.description}>
                  <span>{props.item.data_filter.name}</span>
                </Tooltip>
              ) : (
                <span>{props.item.data_filter.name}</span>
              )}
            </Links.Dataset.View>
          )}
          {!props.item.data_filter_id && <span>{"N/A"}</span>}
        </td>
      )}
      {props.item.table_name !== "ALL_TABLES" && props.showDataFilters && (
        <td className="table-link">
          <div
            className="btn butn-remove-row"
            onClick={() => props.openRemoveItemModal(props.item)}
          >
            <i className="far fa-trash-alt fa-spacing" />
          </div>
        </td>
      )}
    </tr>
  );
}

class ShareDetail extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      ready: false,
      isAddingAll: false,
      isAccepting: false,
      isRevoking: false,
      isRejecting: false,
      share: {},
      error: false,
      removeItemModal: false,
      removeAllShareItemsModal: false,
      showDataFilters: false
    };
  }

  componentDidMount() {
    this.getShare();
  }

  getShare = () => {
    this.props.api.share
      .getShare(this.props.match.params.shareId)
      .then((share) => {
        this.isDataFiltersFeatureEnabled(share.parent);
        this.setState({
          ready: true,
          share
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  addAllItems = () => {
    this.setState({ isAddingAll: true });

    return this.props.api.share
      .addAllDatasetShareItems(
        this.state.share.dataset.uri,
        this.state.share.group.uri
      )
      .then(() => {
        this.setState({ isAddingAll: false });
        this.props.showGlobalNotification({
          message: "All dataset items were added to the share request",
          type: "success"
        });
        this.getShare();
      })
      .catch((error) => {
        this.setState({ isAddingAll: false });
        this.setState({ error });
        this.props.showGlobalNotification({
          message: "Failed to add all dataset items to the share request",
          type: "alert"
        });
      });
  };

  openRemoveItemModal = (item) => this.setState({ removeItemModal: item });

  closeRemoveItemModal = () => this.setState({ removeItemModal: undefined });

  openRemoveAllShareItemsModal = () =>
    this.setState({ removeAllShareItemsModal: true });

  closeRemoveAllShareItemsModal = () =>
    this.setState({ removeAllShareItemsModal: undefined });

  static getStatusClassName(status: string) {
    let statusClassName = "";

    switch (status) {
      case "approved":
      case "accepted":
        statusClassName = "text-success";
        break;
      case "rejected":
      case "revoked":
        statusClassName = "text-danger";
        break;
      case "pending":
        statusClassName = "text-warning";
        break;
      default:
        statusClassName = "text-primary";
    }
    return statusClassName;
  }

  acceptRequest = () => {
    this.setState({ isAccepting: true });
    return this.props.api.share
      .acceptRequest(this.state.share.dataset.uri, this.state.share.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "The access request has been successfully accepted",
          type: "success"
        });
        this.setState({ isAccepting: false });
        this.getShare();
      })
      .catch((error) => {
        this.setState({
          error,
          isAccepting: false
        });
        this.props.showGlobalNotification({
          message:
            "An error occurred during the acceptance of the access request",
          type: "alert"
        });
      });
  };

  revokeRequest = () => {
    this.setState({ isRevoking: true });
    return this.props.api.share
      .revokeShare(
        this.state.share.dataset.uri,
        this.state.share.group.uri,
        "Revoked by data stewards"
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "The access request has been successfully revoked",
          type: "success"
        });
        this.setState({ isRevoking: false });
        this.getShare();
      })
      .catch((error) => {
        this.setState({
          error,
          isRevoking: false
        });
        this.props.showGlobalNotification({
          message: "An error occurred during the revoke of the access request",
          type: "alert"
        });
      });
  };

  rejectRequest = () => {
    this.setState({ isRejecting: true });
    return this.props.api.share
      .rejectRequest(
        this.state.share.dataset.uri,
        this.state.share.group.uri,
        "Rejected by data stewards"
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "The access request has been successfully rejected",
          type: "success"
        });
        this.setState({ isRejecting: false });
        this.getShare();
      })
      .catch((error) => {
        this.setState({
          error,
          isRejecting: false
        });
        this.props.showGlobalNotification({
          message: "An error occurred during the reject of the access request",
          type: "alert"
        });
      });
  };

  isDataFiltersFeatureEnabled(datasetUri) {
    const accountUri = `uri:account:${datasetUri.split(":")[2]}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATA_FILTERS", accountUri)
      .then((showDataFilters) => {
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  render() {
    const { error, ready, share } = this.state;
    if (error) return <Error error={this.state.error} path={"Share View"} />;
    if (!ready) return <Loading message={"Share View"} />;
    return (
      <div>
        <div className="dataset-top">
          <div className="bread-line">
            <Breadcrumb view={`Dataset Share: ${share.dataset.name}`} />
          </div>
          <div className="container-actions mt-2 mb-2">
            <div className="actions-left">
              <div style={{ marginLeft: "30px" }}>
                {share.user_role === "DataSteward" &&
                  share.request_status === "pending" && (
                    <div>
                      <div
                        className="butn butn-create"
                        style={{ marginLeft: "5px" }}
                        onClick={() => this.acceptRequest()}
                      >
                        {this.state.isAccepting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        <i className="fas fa-check-circle" />
                        <span className="butn-text">Accept</span>
                      </div>
                      <div
                        className="butn butn-delete"
                        style={{ marginLeft: "5px" }}
                        onClick={() => this.rejectRequest()}
                      >
                        {this.state.isRejecting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        <i className="fa fa-ban" />
                        <span className="butn-text">Reject</span>
                      </div>
                    </div>
                  )}
                {share.user_role === "DataSteward" &&
                  share.request_status === "accepted" && (
                    <div
                      className="butn butn-delete"
                      style={{ marginLeft: "5px" }}
                      onClick={() => this.revokeRequest()}
                    >
                      {this.state.isRevoking && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      <i className="fa fa-ban" />
                      <span className="butn-text">Revoke</span>
                    </div>
                  )}
              </div>
            </div>
            {this.state.showDataFilters && (
              <div className="actions-right">
                <div
                  className="butn butn-flat"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    this.props.goTo({
                      route: routes.Share.AddItem,
                      params: {
                        shareId: this.props.match.params.shareId
                      },
                      state: { share: this.state.share }
                    })
                  }
                >
                  <i className="fas fa-plus butn-flat" />
                  <span className="butn-text">Add Item</span>
                </div>

                <div
                  className="butn butn-full"
                  style={{ margin: "5px" }}
                  onClick={() => this.addAllItems()}
                >
                  {this.state.isAddingAll && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-bolt" />
                  <span className="butn-text">Add All</span>
                </div>
                <div
                  className="butn butn-delete"
                  style={{ margin: "5px" }}
                  onClick={() => this.openRemoveAllShareItemsModal()}
                >
                  <i className="fas fa-trash-alt" />
                  <span className="butn-text">Remove All</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="dataset-body">
          <div className="dataset-body-left">
            <div className={"product"}>
              <div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">
                    <i className="fa fa-share-alt dataset-detail-icon" />
                    Status
                  </div>
                  <span
                    className={`${ShareDetail.getStatusClassName(
                      share.request_status
                    )} text-uppercase`}
                  >
                    <b>{share.request_status}</b>
                  </span>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">
                    <i className="far fa-folder-open dataset-detail-icon" />
                    Dataset
                  </div>
                  <span className="datasource-info-detail">
                    <Links.Dataset.View
                      uriDataset={(share.dataset || {}).uri || "null"}
                    >
                      {(share.dataset || {}).name || "-"}
                    </Links.Dataset.View>
                  </span>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">
                    <i className="fas fa-user-friends dataset-detail-icon" />
                    Data Requesters
                  </div>
                  <span className="datasource-info-detail">
                    <Links.Group.View
                      uriGroup={(share.group || {}).uri || "null"}
                    >
                      {(share.group || {}).name || "-"}
                    </Links.Group.View>
                  </span>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">
                    <i className="fas fa-user-friends dataset-detail-icon" />
                    Data Stewards
                  </div>
                  <span className="datasource-info-detail">
                    <Links.Group.View
                      uriGroup={(share.dataset || {}).owner.uri || "-"}
                    >
                      {(share.dataset || {}).owner.name || "-"}
                    </Links.Group.View>
                  </span>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">All Stages</div>
                  <p className="wrap-word">{share.all_tables ? "Yes" : "No"}</p>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">Requested on</div>
                  <span>{formatDate(share.createdat)}</span>
                </div>
                <div className="dataset-detail-element">
                  <div className="dataset-detail-title">Reason</div>
                  <p className="wrap-word">
                    {share.message || "No description"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="dataset-body-right">
            <div className="list-policies">
              <div className="mt-2">
                <Paginator
                  container={(content) => (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-policy" scope="col">
                            STAGE NAME
                          </th>
                          {this.state.showDataFilters && (
                            <th className="col-policy" scope="col">
                              DATA FILTER
                            </th>
                          )}
                          {this.state.showDataFilters && (
                            <th className="col-env" scope="col">
                              ACTION
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>{content}</tbody>
                    </table>
                  )}
                  limit={10}
                  initialOffset={0}
                  totalCount={share.all_tables ? 1 : share.items.total}
                  list={
                    share.items.results.length === 0 && share.all_tables
                      ? [
                          {
                            table_name: "ALL_STAGES",
                            data_filter_id: null,
                            data_filter: {
                              name: "-",
                              columns: ["ALL_COLUMNS"]
                            }
                          }
                        ]
                      : share.items.results
                  }
                  componentRender={(item) => (
                    <ShareItemRow
                      item={item}
                      share={this.state.share}
                      openRemoveItemModal={this.openRemoveItemModal}
                      showDataFilters={this.state.showDataFilters}
                    />
                  )}
                  loadPage={"this.getPoliciesFromPagination"}
                />
              </div>
            </div>
            {this.state.removeItemModal && (
              <RemoveShareItemModal
                shareItem={this.state.removeItemModal}
                share={this.state.share}
                onRemove={() => {
                  this.getShare();
                  this.closeRemoveItemModal();
                }}
                onClose={this.closeRemoveItemModal}
              />
            )}
            {this.state.removeAllShareItemsModal && (
              <RemoveAllShareItemsModal
                share={this.state.share}
                onRemove={() => {
                  this.getShare();
                  this.closeRemoveAllShareItemsModal();
                }}
                onClose={this.closeRemoveAllShareItemsModal}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(ShareDetail));
