/* @flow */
import React from "react";
import { Form, Formik } from "formik";
import "./view.less";

import Logger from "../../../utils/logger";
import Error from "../../../components/error";
import SelectAutocomplete from "../../../components/SelectAutocomplete";
import ToggleButton from "../../../components/toggleButton";
import HelpOn from "../../../components/helpOn";
import ButtonAction from "../../../components/buttonAction";
import SelectClassic from "../../../components/SelectClassic";

type propTypes = {
  datasource: Object,
  replication: Object,
  putDatasourceReplication: Function,
  onClose: Function
};

type stateTypes = {
  selectAll: boolean,
  selectedDatasets: Array<Object>,
  errorAdd: Object,
  datasourceDestination: string,
  isStorageClassSelected: boolean,
  selectedStorageClass: Object
};

const availableStorageClasses = [
  { label: "STANDARD", value: "STANDARD" },
  { label: "REDUCED_REDUNDANCY", value: "REDUCED_REDUNDANCY" },
  { label: "STANDARD_IA", value: "STANDARD_IA" },
  { label: "ONEZONE_IA", value: "ONEZONE_IA" },
  { label: "INTELLIGENT_TIERING", value: "INTELLIGENT_TIERING" },
  { label: "GLACIER", value: "GLACIER" },
  { label: "DEEP_ARCHIVE", value: "DEEP_ARCHIVE" },
  { label: "OUTPOSTS", value: "OUTPOSTS" },
  { label: "GLACIER_IR", value: "GLACIER_IR" }
];

const Log = Logger("DatasetReplicationRuleAdd");

class DatasetReplicationRuleAdd extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      datasourceDestination: "",
      errorAdd: undefined,
      selectAll: false,
      selectedDatasets: [],
      selectedStorageClass: availableStorageClasses.find(
        (i) => i.value === props.replication.storage_class
      ),
      isStorageClassSelected: false
    };
    this.onChangeToggle = this.onChangeToggle.bind(this);
  }

  componentDidMount() {
    if (this.props.replication) {
      this.setState({
        selectAll: Boolean(this.props.replication.is_entire_bucket)
      });

      if (this.props.replication.replication_datasource_uri.length > 0) {
        this.setState({
          datasourceDestination:
            this.props.replication.replication_datasource_uri
        });
      }

      if (this.props.replication.replication_rules.length > 0) {
        const selectedDatasets = [];
        this.props.datasource.datasets.map((dataset) =>
          this.props.replication.replication_rules.map((rules) => {
            if (rules.replicated_data === dataset.uri) {
              selectedDatasets.push({
                label: dataset.name,
                value: dataset.uri
              });
            }
            return selectedDatasets;
          })
        );
        this.setState({ selectedDatasets });
      }
    }
  }

  onChangeToggle = () =>
    this.setState((prevState) => {
      const selectAll = !prevState.selectAll;
      return { selectAll };
    });

  onChangeIsStorageClassSelectedToggle = () =>
    this.setState((prevState) => {
      const isStorageClassSelected = !prevState.isStorageClassSelected;
      return { isStorageClassSelected };
    });

  onSelectOption = (options: Array<Object>) => {
    this.setState({ selectedDatasets: options || [] });
  };

  getSelectedOptions = () => {
    const list = [];
    this.state.selectedDatasets.map((o) => list.push(o.value));
    return this.state.selectAll ? [] : list;
  };

  onSubmit = () => {
    const { datasource, replication } = this.props;
    const {
      datasourceDestination: destinationUri,
      selectedStorageClass,
      isStorageClassSelected
    } = this.state;
    const sourceUri = datasource.uri;
    const datasetsUriList = this.getSelectedOptions();
    const selectedStorageClassValue =
      selectedStorageClass && selectedStorageClass.value;
    const currentStorageClass = replication && replication.storage_class;

    const payload = {
      sourceUri,
      destinationUri,
      datasetsUriList,
      selectedStorageClass: selectedStorageClassValue,
      isStorageClassSelected,
      currentStorageClass
    };
    Log.info("putDatasourceReplication payload", payload);
    this.props.putDatasourceReplication(payload);
  };

  render() {
    const datasets = [];
    this.props.datasource.datasets.map((dataset) =>
      datasets.push({
        label: dataset.name,
        value: dataset.uri
      })
    );

    return (
      <div className={"datasource-providers-add datasource-providers-form"}>
        {this.state.errorAdd && (
          <Error
            error={this.state.errorAdd}
            path={"DatasourceProvidersAdd"}
            stringOnly
          />
        )}
        <Formik>
          {() => (
            <Form>
              <fieldset className="form-group">
                <legend className="label-form">Scope rule</legend>
                <div className="d-flex">
                  <ToggleButton
                    id="select-all-datasets-id"
                    checkedValue={this.state.selectAll}
                    onChange={() => this.onChangeToggle()}
                  />
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Select all Datasets "}</h3>
                        <div>
                          <b>{""}</b>
                        </div>
                        <div>
                          {"This option is used to select the entire bucket."}
                        </div>
                      </div>
                    }
                  />
                  Select all datasets
                </div>
                {!this.state.selectAll && (
                  <SelectAutocomplete
                    placeholder={"Select Datasets"}
                    options={datasets}
                    selectedOption={this.state.selectedDatasets}
                    isMulti
                    onSelectOption={(options) => this.onSelectOption(options)}
                  />
                )}
              </fieldset>

              <div className="mb-3">
                <legend className="label-form">Storage class</legend>
                <div className="d-flex">
                  <ToggleButton
                    id="is-storage-class-selected-toggle"
                    checkedValue={this.state.isStorageClassSelected}
                    onChange={() => this.onChangeIsStorageClassSelectedToggle()}
                  />
                  <HelpOn
                    content={
                      <div>
                        <a
                          href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/storage-class-intro.html"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {"See Storage Class documentation"}
                        </a>
                      </div>
                    }
                  />
                  Change the replication storage class
                </div>
                {this.state.isStorageClassSelected && (
                  <SelectClassic
                    className={""}
                    options={availableStorageClasses}
                    selectedOption={this.state.selectedStorageClass}
                    onSelectOption={(option) => {
                      this.setState({ selectedStorageClass: option });
                    }}
                  />
                )}
              </div>

              <div className="form-group">
                <ButtonAction
                  type={"submit"}
                  label={
                    this.state.datasourceDestination.length
                      ? "Update"
                      : "Create"
                  }
                  className="butn butn-create"
                  onClick={this.onSubmit}
                />
                <ButtonAction label="Close" onClick={this.props.onClose} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DatasetReplicationRuleAdd;
