/* @flow */
import React from "react";
import { Field } from "formik";

type Props = {
  errors: Object,
  setFieldValue: Function,
  name: string
};

function FormFieldName({ errors, setFieldValue, name }: Props) {
  return (
    <fieldset className="form-group">
      <legend className="label-form">Name:</legend>
      <div className="label-form-name">
        <input
          disabled
          className="form-control bg-white label-form-name-cdh"
          value="cdh-"
        />
        <Field
          type="name"
          name="name"
          className="form-control bg-white"
          placeholder="Name"
          onChange={(event) => setFieldValue("name", event.target.value)}
          value={name}
        />
      </div>
      {errors.name && <div className="error-msg">{errors.name}</div>}
    </fieldset>
  );
}

export default FormFieldName;
