/* @flow */

import React from "react";
import ItemFromList from "../../../../components/itemFromList";
import { formatDate } from "../../../../utils/date";
import Links from "../../../links";
import Error from "../../../../components/error";
import { getAccountUriFromUri } from "../../../../utils/toolsForUri";
import ButtonAction from "../../../../components/buttonAction";
import MoreDetailsNotification from "../MoreDetailsNotification";

type propTypes = {
  share: {
    id?: string,
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    dataset: {
      access: string,
      uri: string,
      name: string,
      expiration_date?: string,
      datasource: {
        uri: string,
        name: string
      }
    },
    updatedat: ?string,
    message: string
  },
  acceptRequest: Function,
  onAction: Function
};
type stateTypes = {
  error: Object
};

class DatasetShareRequestRevoked extends React.Component<
  propTypes,
  stateTypes
> {
  static defaultProps = {
    share: {
      group: {
        uri: ":::::::",
        name: "-",
        account: {
          name: "-"
        }
      },
      dataset: {
        access: "read",
        uri: ":::::::",
        name: "-",
        datasource: {
          uri: ":::::::",
          name: "-"
        }
      },
      updatedat: undefined,
      message: ""
    }
  };

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  getTitle = () => {
    const { share } = this.props;
    const { dataset } = share;
    const accountUri = getAccountUriFromUri(dataset.uri);

    return (
      <div className="share-title">
        {share && share.id && (
          <div className="share-title mb-2">
            <Links.Share.View
              shareId={(share || {}).id || "null"}
              state={{ share }}
            >
              {`${share.group.name} share request for dataset ${share.dataset.name}`}
            </Links.Share.View>
          </div>
        )}
        {"The share requested from the group "}
        <Links.Group.View
          uriGroup={share.group.uri}
          state={{ group: share.group }}
        >
          {share.group.name}
        </Links.Group.View>
        {`, from ${share.group.account.name} , has been revoked for the dataset `}
        <Links.Dataset.View uriDataset={dataset.uri}>
          {dataset.name}
        </Links.Dataset.View>
        {" ("}
        <Links.Datasource.View
          uriAccount={accountUri}
          uriDatasource={(dataset.datasource || {}).uri}
        >
          {(dataset.datasource || {}).name}
        </Links.Datasource.View>
        {")"}
      </div>
    );
  };

  getDescription = () => {
    const { share } = this.props;

    return (
      <React.Fragment>
        <div className="share-description">
          <span style={{ color: "red", fontWeight: "600" }}>{"Revoked "}</span>
          {`the ${formatDate(share.updatedat, false)}`}
          {this.state.error && (
            <Error
              error={this.state.error}
              path={"DatasetShareRequestRevoked"}
              stringOnly
            />
          )}
        </div>
        <MoreDetailsNotification share={share} />
      </React.Fragment>
    );
  };

  acceptRequest = () => {
    const { share, acceptRequest, onAction } = this.props;

    return acceptRequest(share.dataset.uri, share.group.uri)
      .then(() => onAction())
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getActions = () => {
    const { share } = this.props;
    const { dataset = {} } = share;

    if (dataset.can_share && !dataset.expiration_date) {
      return [
        <ButtonAction label="Share" onClick={() => this.acceptRequest()} />
      ];
    }
    return [];
  };

  render() {
    return (
      <ItemFromList
        className={"a-share"}
        name={this.getTitle()}
        actions={this.getActions()}
        description={this.getDescription()}
        hideLabelName
        hideLabelDescription
      />
    );
  }
}

export default DatasetShareRequestRevoked;
