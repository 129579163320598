/* @flow */

import React from "react";
import "./style.less";
import DisplayCompleteness from "./displayCompleteness";
import DisplayMetadataString from "./displayMetadataString";
import DisplayMetadataDate from "./displayMetadataDate";
import DisplayMetadataNumber from "./displayMetadataNumber";
import StageMetadataUpdate from "./stageMetadataUpdate";

type propTypes = {
  stage: {
    id: string,
    columns: Array<{
      Type: string,
      Name: string,
      Metadata: Object,
      Description: string,
      Comment: string
    }>,
    profiling_execution: {
      status: string
    }
  },
  column: {
    Type: string,
    Name: string,
    Metadata: Object,
    Description: string,
    Comment: string
  },
  reloadData: Function
};

type stateTypes = {
  editionMode: boolean
};

class StageMetadataFullOverviewContent extends React.PureComponent<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      editionMode: false
    };
  }

  // eslint-disable-next-line class-methods-use-this
  renderGraph(column: Object) {
    if (column.Type === "string" || column.Type === "varchar") {
      return <DisplayMetadataString metadata={column.Metadata} />;
    }

    if (column.Type === "date") {
      return <DisplayMetadataDate metadata={column.Metadata} />;
    }

    if (
      column.Type === "bigint" ||
      column.Type === "double" ||
      column.Type === "float" ||
      column.Type === "Numeric" ||
      column.Type.includes("int")
    ) {
      return <DisplayMetadataNumber metadata={column.Metadata} />;
    }

    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  renderCompleteness(column: Object) {
    if (column.Metadata) {
      return (
        <DisplayCompleteness completeness={column.Metadata.Completeness} />
      );
    }

    return false;
  }

  closeEditionMode = () => {
    this.setState({ editionMode: false });
  };

  render() {
    const { stage, column } = this.props;

    const { columns = [] } = stage;

    if (!columns || columns.length <= 0) {
      return <div>No Column to display</div>;
    }

    if (!this.state.editionMode) {
      return (
        <React.Fragment>
          <div className="update-button">
            <i
              onClick={() =>
                this.setState((prevState) => ({
                  editionMode: !prevState.editionMode
                }))
              }
              className="fas fa-edit"
            ></i>
          </div>
          <div className="info mb-2">
            <h2>{column.Name}</h2>
            <code>{column.Type}</code>
          </div>
          <div className="info-description">
            {column.Description === "" && !column.Comment
              ? "No description available"
              : column.Comment || column.Description}
          </div>
          {["NOT LAUNCHED", "RUNNING", "FAILED"].includes(
            stage.profiling_execution.status
          ) && <div className="values">Profiling not available.</div>}
          {!["NOT LAUNCHED", "RUNNING", "FAILED"].includes(
            stage.profiling_execution.status
          ) && (
            <div className="values">
              {this.renderCompleteness(column)}
              {this.renderGraph(column)}
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <StageMetadataUpdate
        stage={this.props.stage}
        column={this.props.column}
        reloadData={this.props.reloadData}
        closeEditionMode={this.closeEditionMode}
      />
    );
  }
}

export default StageMetadataFullOverviewContent;
