/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { updateMessage } from "../../globalNotifications/actions";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Routes from "../../routes";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import "./style.less";
import SelectInfinite from "../../../components/SelectInfinite";

const Log = Logger("AddStageDataFilter");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      datasetUri: string,
      id: string
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  error: Object,
  errorSubmit: Object,
  stage: Object,
  initialDataFilters: Object[],
  totalDataFilters: number,
  selectedDataFilterId: any
};

class AddStageDataFilter extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      errorSubmit: undefined,
      stage: {
        id: "",
        dataset: {
          uri: ""
        },
        name: "",
        description: "",
        type: ""
      },
      initialDataFilters: [],
      totalDataFilters: 0,
      selectedDataFilterId: null
    };
  }

  componentDidMount() {
    this.getStage();
    this.loadDataFilters();
  }

  getStage = () =>
    this.props.api.dataset
      .getStage(this.props.match.params.id, this.props.match.params.datasetUri)
      .then((stage) => {
        this.setState({
          stage,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const stage = Object.assign({}, prevState.stage);
      stage[field] = value;
      return { stage };
    });
  };

  save = (setSubmitting) => {
    const input = {
      preview_data_filter_id: this.state.selectedDataFilterId
    };
    return this.props.api.dataset
      .updateStage(
        this.state.stage.id,
        input,
        this.props.match.params.datasetUri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Stage data filter applied",
          type: "success"
        });
        setSubmitting(false);
        return this.props.goTo(
          {
            route: `${Routes.Stage.View}#preview`,
            params: {
              outputId: this.state.stage.id,
              datasetUri: this.state.stage.dataset.uri
            }
          },
          "replace"
        );
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.props.showGlobalNotification({
          message: "Failed to apply data filter",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({ errorSubmit });
      });
  };

  loadDataFilters = (offset = 0) =>
    this.props.api.dataset
      .listDataFilters(
        this.props.match.params.datasetUri,
        this.props.match.params.id,
        {
          offset,
          limit: 10000
        }
      )
      .then((response) => {
        this.setState({ totalDataFilters: response.total });
        return response.results.map((g) => ({
          label: g.name,
          value: g.id
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"stage-edit"} />;
    if (!this.state.ready)
      return <Loading className={"my-3"} message={"Data Filters"} />;
    return (
      <div className="stage-edit">
        <Breadcrumb view={"Add Stage Data Filter"} />

        <Formik
          initialValues={{}}
          validate={() => {
            Log.info("validate", this.state.stage);
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.save(setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="stage-edit-form">
              {this.state.errorSubmit && (
                <Error
                  error={this.state.errorSubmit}
                  displayError
                  path={"StageEdit"}
                  stringOnly
                />
              )}

              <div className="form-group">
                <legend className="label-form">Data Filter</legend>
                <SelectInfinite
                  placeholder={"Select Data Filter"}
                  initialOptions={this.state.initialDataFilters}
                  totalOptions={this.state.totalDataFilters}
                  loadMoreOptions={this.loadDataFilters}
                  onSelectOption={(value) =>
                    this.setState({
                      selectedDataFilterId: value
                    })
                  }
                />
              </div>

              <div className={"ml-8"}>
                <button type="submit" disabled={isSubmitting} className="butn">
                  {isSubmitting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  {"Apply"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(AddStageDataFilter));
