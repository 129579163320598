import Logger from "./logger";

const Log = Logger("SupportedBrowser");

const getBrowser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }

  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: +M[1]
  };
};

const isSupported = (browser) => {
  let supported = false;
  if (browser.name === "Chrome" && browser.version >= 60) {
    // Juillet 2017
    supported = true;
  } else if (browser.name === "Edge" && browser.version >= 14) {
    // Fevrier 2016
    supported = true;
  } else if (browser.name === "Firefox" && browser.version >= 50) {
    // Septembre 2016
    supported = true;
  } else if (browser.name === "Safari" && browser.version >= 6.1) {
    // Juin 2013
    supported = true;
  } else if (browser.name === "Opera" && browser.version >= 53) {
    // Mai 2018
    supported = true;
  }
  Log.verbose(
    "browser:",
    browser,
    supported ? "browser supported" : "browser not supported"
  );
  return supported;
};

export default isSupported(getBrowser());
