/* @flow */

import React from "react";
import { connect } from "react-redux";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import AttachTrust from "./attachTrust";
import GroupTrustList from "./listGroupTrusts";
import "./style.less";

type propTypes = {
  accountUri: string,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  newGroupTrust: boolean,
  error?: Object
};

class TrustTabAttach extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      newGroupTrust: false
    };
  }

  renderNewGroupTrust() {
    return (
      <div className="create-container">
        <button
          type="button"
          className={!this.state.newGroupTrust ? "butn butn-check" : "butn"}
          onClick={() =>
            this.setState((prevState) => ({
              newGroupTrust: !prevState.newGroupTrust
            }))
          }
        >
          {!this.state.newGroupTrust ? "Attach Trust" : "Return to the list"}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="attach-trust">
        {this.state.error && (
          <Error error={this.state.error} path="TrustManagement.list" />
        )}
        {this.renderNewGroupTrust()}
        <div className="">
          {this.state.newGroupTrust && (
            <AttachTrust
              api={this.props.api}
              accountUri={this.props.accountUri}
              showGlobalNotification={this.props.showGlobalNotification}
              callbackSuccess={() => {
                this.setState({ newGroupTrust: false });
              }}
            />
          )}
          {!this.state.newGroupTrust && (
            <GroupTrustList
              api={this.props.api}
              accountUri={this.props.accountUri}
              showGlobalNotification={this.props.showGlobalNotification}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(TrustTabAttach)));
