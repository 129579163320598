export const queryListMyNotificationsTypes = {
  query: `query Q {
    listMyNewtificationsSubtypes {
        id
        code
        name
    }
}
`,
  keyOutput: "listMyNewtificationsSubtypes"
};
