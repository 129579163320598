/* @flow */
import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import Breadcrumb from "../../../components/breadcrumb";
import ToggleButton from "../../../components/toggleButton";
import SelectClassic from "../../../components/SelectClassic";
import HelpOn from "../../../components/helpOn";
import Error from "../../../components/error";
import routes from "../../routes";
import "./view.less";

type propTypes = {
  location: {
    state: {
      cart: Object
    }
  },
  match: {
    params: {
      cartUri: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  cartUri: string,
  cart: Object,
  isFetchingGroups: boolean,
  groups: Array<Object>,
  newGroupSelected: Object,
  error?: Object,
  errorSubmit?: Object,
  isSubmitting: boolean,
  updateOptions: Object
};

class CartTransfertOwnership extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      cartUri: this.props.match.params.cartUri,
      cart: this.props.location.state.cart,
      isFetchingGroups: true,
      groups: [],
      newGroupSelected: undefined,
      error: undefined,
      errorSubmit: undefined,
      isSubmitting: false,
      updateOptions: {
        removeData: false,
        removeSageMaker: false,
        removePowerBI: false,
        removeRedshift: false,
        removeTrust: false,
        removeLogsGroup: false,
        removeDatabrew: false,
        removeTemplate: false
      }
    };
  }

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () =>
    this.props.api.cart
      .listAvailableGroupsForChangeOwnership(this.state.cartUri)
      .then((response) => {
        if (!response) return [];
        this.setState({
          groups: response,
          isFetchingGroups: false
        });
        return response;
      })
      .catch((error) => {
        this.setState({
          error,
          groups: [],
          isFetchingGroups: false
        });
      });

  submit = () => {
    this.setState({ isSubmitting: true });
    this.props.api.cart
      .updateCartOwnership(
        this.state.cartUri,
        this.state.newGroupSelected.value,
        this.state.updateOptions
      )
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Transfer Done !",
          type: "success"
        });
        this.props.goTo({ route: routes.Cart.List });
      })
      .catch((errorSubmit) => {
        this.setState({
          errorSubmit,
          isSubmitting: false
        });
      });
  };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const { updateOptions } = prevState;
      updateOptions[field] = value;
      return { updateOptions };
    });
  };

  render() {
    return (
      <div className="cart-transfert-ownership">
        <Breadcrumb view="Project, transfer ownership" />

        <div className="cart-transfer">
          <div className="cart-transfer-info">
            <div className={"alert alert-light"}>
              <i className="fas fa-exclamation-circle" />
              &nbsp;Warning:&nbsp;
              <div>
                Once the transfer is done, you lose ownership on the
                project.&nbsp; Please note that the configuration and resources
                of your project might change.&nbsp; Please see the information
                about the following options for more details.
              </div>
              <div>
                The project ownership can only be transferred to groups attached
                to the same environment(s).&nbsp;
              </div>
            </div>
          </div>

          <hr />

          <div className="cart-info">
            <div>
              <label className={"label-form"}>Name:</label>
              <SelectClassic isDisabled placeholder={this.state.cart.name} />
            </div>
            <div>
              <label className={"label-form"}>Current Owner group:</label>
              <SelectClassic
                isDisabled
                placeholder={this.state.cart.group.name}
              />
            </div>
          </div>

          <hr />

          {this.state.error && (
            <Error
              error={this.state.error}
              path={"CartTransferOwnership"}
              stringOnly
            />
          )}

          <div className="cart-transfer-form">
            <div>
              <label className="label-form">Choose the new owner group</label>
              <SelectClassic
                isLoadingOptions={this.state.isFetchingGroups}
                placeholder="Select a group"
                options={this.state.groups.map((group) => ({
                  label: group.name,
                  value: group.uri
                }))}
                onSelectOption={(option) => {
                  this.setState({ newGroupSelected: option });
                }}
              />
            </div>

            <div className="cart-transfer-form-options-title">
              Transfer options:
            </div>

            <div className="cart-transfer-form-options">
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">Remove All Data ?</label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Remove All Data"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will remove all the data added to the project'
                          }
                        </div>
                        <div>
                          {
                            'If set to "No", will only remain the data to which the new group has access to (the data it owns & the data shared with it)'
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeData"
                    checkedValue={this.state.updateOptions.removeData}
                    onChange={() =>
                      this.handleChange(
                        "removeData",
                        !this.state.updateOptions.removeData
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">
                    Remove SageMaker Notebooks ?
                  </label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Remove SageMaker Notebooks"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will delete the SageMaker notebooks attached to the project'
                          }
                        </div>
                        <div>
                          {
                            "If so, please make sure that the notebook instances are stopped."
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeSageMaker"
                    checkedValue={this.state.updateOptions.removeSageMaker}
                    onChange={() =>
                      this.handleChange(
                        "removeSageMaker",
                        !this.state.updateOptions.removeSageMaker
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <label className="label-form">Detach PowerBI ?</label>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removePowerBI"
                    checkedValue={this.state.updateOptions.removePowerBI}
                    onChange={() =>
                      this.handleChange(
                        "removePowerBI",
                        !this.state.updateOptions.removePowerBI
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">
                    Detach Redshift Clusters ?
                  </label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Remove Redshift Clusters"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will detach from PowerBI and remove the Redshift Clusters associated to the project'
                          }
                        </div>
                        <div>
                          {
                            'If set to "No", will only be tranferred the clusters to which the new group has been granted access to'
                          }
                        </div>
                        <div>
                          {
                            "If the cluster is attached to PowerBI, you will have to manually re-attached it"
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeRedshift"
                    checkedValue={this.state.updateOptions.removeRedshift}
                    onChange={() =>
                      this.handleChange(
                        "removeRedshift",
                        !this.state.updateOptions.removeRedshift
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">Delete / Reject Trusts ?</label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Remove Trusts"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will reject Admin Trusts and delete trusts created from this project'
                          }
                        </div>
                        <div>
                          {
                            'If set to "No", will only be tranferred the Admin Trusts to which the new group has been granted access to'
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeTrust"
                    checkedValue={this.state.updateOptions.removeTrust}
                    onChange={() =>
                      this.handleChange(
                        "removeTrust",
                        !this.state.updateOptions.removeTrust
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <label className="label-form">Delete Logs Groups ?</label>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeLogsGroup"
                    checkedValue={this.state.updateOptions.removeLogsGroup}
                    onChange={() =>
                      this.handleChange(
                        "removeLogsGroup",
                        !this.state.updateOptions.removeLogsGroup
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">
                    Delete Databrew Projects ?
                  </label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Delete Databrew Projects"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will delete the Databrew projects associated to the project'
                          }
                        </div>
                        <div>
                          {
                            'If set to "No", will only be tranferred the Databrew projects linked to datasets remaining in the project'
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeDatabrew"
                    checkedValue={this.state.updateOptions.removeDatabrew}
                    onChange={() =>
                      this.handleChange(
                        "removeDatabrew",
                        !this.state.updateOptions.removeDatabrew
                      )
                    }
                  />
                </div>
              </div>
              <div className="cart-transfer-form-field">
                <div className="cart-transfer-form-field-text">
                  <label className="label-form">
                    Delete Acceleration Templates ?
                  </label>
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Delete Acceleration Templates"}</h3>
                        <div>
                          {
                            'If set to "Yes", this option will delete the Acceleration Templates associated to the project'
                          }
                        </div>
                        <div>
                          {
                            'If set to "No", will only be tranferred the Acceleration Templates to which the new group has been granted access to'
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="cart-transfer-form-field-value">
                  <ToggleButton
                    id="id-removeTemplate"
                    checkedValue={this.state.updateOptions.removeTemplate}
                    onChange={() =>
                      this.handleChange(
                        "removeTemplate",
                        !this.state.updateOptions.removeTemplate
                      )
                    }
                  />
                </div>
              </div>
              {/* <div className="cart-transfer-form-field">
                                <label className="label-form">Remove SageMaker Notebooks ?</label>
                                <div className="cart-transfer-form-field-value">
                                    <ToggleButton
                                        id="id-removeSageMaker"
                                        checkedValue={this.state.updateOptions.removeSageMaker}
                                        onChange={() => this.handleChange('removeSageMaker', !this.state.updateOptions.removeSageMaker)}
                                    />
                                </div>
                            </div> */}
            </div>

            {this.state.errorSubmit && (
              <Error
                error={this.state.errorSubmit}
                path={"CartransferOwnership"}
                stringOnly
              />
            )}

            <div className="cart-transfer-form-submit">
              <button
                type="submit"
                disabled={
                  !this.state.newGroupSelected || this.state.isSubmitting
                }
                className="butn"
                onClick={this.submit}
              >
                {this.state.isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Transfer ownership
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartTransfertOwnership)));
