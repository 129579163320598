/* @flow */

import React, { Component } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Row from "../../../../components/row";
import Col from "../../../../components/col";
import Routes from "../../../routes";
import Error from "../../../../components/error";
import { updateMessage } from "../../../globalNotifications/actions";
import Breadcrumb from "../../../../components/breadcrumb";
import config from "../../../../config";
import Loading from "../../../../components/loading";
import { REGIONS, RS_BASE_CAPACITIES } from "../../../../utils/constants";
import "./redshiftClusterForm.less";
import SelectInfinite from "../../../../components/SelectInfinite";
import { Alert } from "@material-ui/lab";
import SelectClassic from "../../../../components/SelectClassic";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  }
};

type stateTypes = {
  ready: boolean,
  error: Object,
  groupError: boolean,
  errorSubmit: Object,
  isSubmitting: boolean,
  isFetchingNetworks: boolean,
  selectedValue: {
    name: string,
    platform_uri: string,
    description: string,
    network: Object,
    region: string,
    workgroup_base_capacity: Object,
    owneruri: string
  },
  networks: Array<Object>,
  selectedNetwork: Object,
  totalNetworks: number,
  totalGroups: number,
  groupsOffset: number
};

class CreateRSNamespaceForm extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: true,
      error: null,
      groupError: false,
      errorSubmit: false,
      isSubmitting: false,
      isFetchingNetworks: false,
      selectedValue: {
        name: "",
        description: "",
        platform_uri: this.props.match.params.uriPlayground,
        owneruri: "",
        network: null,
        region: REGIONS[0].key,
        workgroup_base_capacity: 8
      },
      networks: [],
      selectedNetwork: null,
      totalNetworks: 0,
      totalGroups: 0,
      groupsOffset: 0,
      networksOffset: 0
    };
  }

  componentDidMount() {
    this.getNetworkList();
  }

  getGroups = (offset = 0) =>
    this.props.api.identity
      .listMyGroups({
        offset,
        filters: [
          { key: "account_uri", value: this.props.match.params.uriAccount },
          { key: "platform_uri", value: this.props.match.params.uriPlayground },
          { key: "exclude_service_groups", value: true }
        ]
      })
      .then((allMyGroups) => {
        const groups = allMyGroups.results;
        const newTotal = allMyGroups.total;

        if (this.state.groupsOffset === 0 && groups.length <= 0) {
          this.setState({ groupError: true });
          return [];
        }

        this.setState({
          totalGroups: newTotal,
          groupsOffset: allMyGroups.offset + allMyGroups.limit
        });

        return groups.map((g) => ({ value: g.uri, label: g.name }));
      })
      .catch((error) => {
        this.setState({ error });
      });

  getNetworkList = () => {
    this.props.api.playground
      .listNetworks(this.props.match.params.uriPlayground)
      .then((response) => {
        if (response) {
          let rsNetworks = response.results.filter(
            (d) => d.network_type === "REDSHIFT_SERVERLESS"
          );
          if (this.state.selectedValue.region) {
            rsNetworks = rsNetworks.filter(
              (d) => d.region === this.state.selectedValue.region
            );
          }
          rsNetworks = rsNetworks.map((g) => ({
            value: g.id,
            label: `${g.name} | ${g.vpc_id} | (${g.subnet_ids.join(", ")})`
          }));
          this.setState({
            networks: rsNetworks,
            totalNetworks: response.total,
            isFetchingNetworks: false
          });
        }
        return [];
      })
      .catch(() => {
        this.setState({
          isFetchingNetworks: false
        });
      });
  };

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  save() {
    const { selectedValue } = this.state;
    this.setState({ errorSubmit: null, isSubmitting: true });
    const inputNetwork = {
      name: selectedValue.name,
      description: selectedValue.description,
      network_id: selectedValue.network.value,
      region: selectedValue.region,
      owneruri: selectedValue.owneruri
    };
    return this.props.api.analytics
      .createRSNamespace(this.props.match.params.uriPlayground, inputNetwork)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Redshift Serverless namespace creation started...",
          type: "success"
        });

        return this.props.goTo({
          route: `${Routes.Playground.Analytics}#analytics`,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriPlayground: this.props.match.params.uriPlayground
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ errorSubmit, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Redshift Serverless namespace creation failed",
          type: "alert"
        });
      });
  }

  render() {
    const { selectedValue, error, ready, isSubmitting } = this.state;
    if (error) return <Error error={error} path={"RedshiftServerlessForm"} />;
    if (!ready)
      return <Loading message={"Redshift Serverless Creation Form"} />;
    return (
      <div className="RedshiftClusterForm">
        <Breadcrumb view={"New Redshift Serverless Namespace"} />
        {this.state.ready && this.state.groupError ? (
          <div style={{ marginLeft: "30px" }}>
            {
              "You need to be part of a group before creating an analytics tool..."
            }
          </div>
        ) : (
          <Row>
            <Col size={1} />
            <Col size={9}>
              <Formik
                initialValues={{
                  name: selectedValue.name,
                  description: selectedValue.description,
                  network: selectedValue.network,
                  region: selectedValue.region,
                  owneruri: selectedValue.owneruri
                }}
                validate={() => {
                  const errors = {};
                  if (
                    !selectedValue.owneruri ||
                    selectedValue.owneruri.length <= 0
                  ) {
                    errors.owneruri = "Owner is mandatory";
                  }
                  if (!selectedValue.name || selectedValue.name.length <= 0) {
                    errors.name = "Namespace name is mandatory";
                  }
                  if (
                    !selectedValue.region ||
                    selectedValue.region.length <= 0
                  ) {
                    errors.region = "Namespace region type is mandatory";
                  }
                  if (!selectedValue.network) {
                    errors.network = "Network is mandatory";
                  }
                  return errors;
                }}
                onSubmit={() => {
                  this.save();
                }}
              >
                {
                  <Form>
                    {this.state.errorSubmit && (
                      <Error
                        stringOnly
                        error={this.state.errorSubmit}
                        path={"create"}
                      />
                    )}
                    <div className={"alert alert-light"}>
                      <i className="fas fa-exclamation-circle" />
                      &nbsp;Requirements:&nbsp;
                      <div>
                        Prerequisites for Amazon Redshift Serverless Namespace
                        creation,&nbsp;
                        <a
                          href={`${config.HELP_CENTER}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          see on Helpcenter{" "}
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="mb-3">
                        <div>
                          <label className="label-form">Owner Group</label>
                          <SelectInfinite
                            key={"select-infinite-redshift-group"}
                            placeholder={"Select a group"}
                            totalOptions={this.state.totalGroups}
                            loadMoreOptions={this.getGroups}
                            onSelectOption={(groupUri) =>
                              this.handleChange("owneruri", groupUri)
                            }
                          />
                        </div>
                        <ErrorMessage
                          name="owneruri"
                          component="div"
                          className="error-msg"
                        />
                      </div>
                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Namespace Name
                        </legend>
                        <Field
                          type="name"
                          name="name"
                          className="form-control bg-white"
                          placeholder="Namespace Name"
                          onChange={(event) =>
                            this.handleChange("name", event.target.value)
                          }
                          value={selectedValue.name}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Namespace Description
                        </legend>
                        <Field
                          component="textarea"
                          name="description"
                          className="form-control bg-white"
                          placeholder="Description..."
                          onChange={(event) =>
                            this.handleChange("description", event.target.value)
                          }
                          value={selectedValue.description}
                          rows={"3"}
                        />
                      </fieldset>
                      <div className="mb-3">
                        <label className="label-form cluster-label-form">
                          Namespace Region
                        </label>
                        <SelectClassic
                          placeholder="select a region"
                          options={REGIONS.map((region) => ({
                            value: region.key,
                            label: `(${region.key})  ${region.label}`
                          }))}
                          selectedOption={{
                            value: this.state.selectedValue.region,
                            label: this.state.selectedValue.region
                          }}
                          onSelectOption={(option) => {
                            this.handleChange("region", option.value);
                            this.getNetworkList();
                            this.handleChange("network", "");
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div className="mb-3">
                          <label className="label-form">Network</label>
                          <div>
                            <SelectClassic
                              placeholder="Select a Network"
                              options={
                                this.state.networks
                                  ? this.state.networks.map((n) => ({
                                      value: n.value,
                                      label: n.label
                                    }))
                                  : []
                              }
                              selectedOption={
                                selectedValue.network
                                  ? {
                                      value: selectedValue.network.value,
                                      label: selectedValue.network.label
                                    }
                                  : { label: "Select a Network", value: "" }
                              }
                              onSelectOption={(value) => {
                                this.handleChange("network", value);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="network"
                            component="div"
                            className="error-msg"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="label-form">RPU Base Capacity</label>
                        <div>
                          <SelectClassic
                            placeholder="Select a base capacity"
                            options={RS_BASE_CAPACITIES.map((src) => ({
                              value: src.key,
                              label: src.label
                            }))}
                            selectedOption={{
                              value:
                                this.state.selectedValue
                                  .workgroup_base_capacity,
                              label: RS_BASE_CAPACITIES.find(
                                (x) =>
                                  x.key ===
                                  this.state.selectedValue
                                    .workgroup_base_capacity
                              ).label
                            }}
                            onSelectOption={(option) => {
                              this.handleChange(
                                "workgroup_base_capacity",
                                option.value
                              );
                            }}
                          />
                        </div>
                        <div className={"mt-2"}>
                          <Alert severity={"info"}>
                            The minimum number of required IP addresses scales
                            higher as the number of Base Redshift Processing
                            Units (RPUs) for your workgroup increases. You must
                            have the minimum number (9 per 8 RPUs) of IP
                            addresses available for each subnet in each
                            workgroup that you want to create
                          </Alert>
                        </div>
                      </div>
                      <div className={"ml-1 row justify-content-center"}>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="butn"
                        >
                          {isSubmitting && (
                            <i className="fas fa-circle-notch fa-spin fa-spacing" />
                          )}
                          Create
                        </button>
                      </div>
                    </div>
                  </Form>
                }
              </Formik>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(CreateRSNamespaceForm));
