/* @flow */

import React from "react";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import Paginator from "../../../../components/paginator";
import withGoTo from "../../../goToHOC";
import GroupCard from "../../../../components/groups/group-card";
import { CDHButton } from "../../../../components/button/cdh-button";

const DatabricksWorkspaceDetailsGroupsTab = ({
  dbx_groups,
  error,
  groupOffset,
  isLoading,
  updateGroups,
  unregisterWorkspace
}) => {
  return error ? (
    <Error error={error} path={"GroupsList"} />
  ) : isLoading ? (
    <Loading message={"Groups List"} />
  ) : (
    <div className="organizations-list">
      <div className="header"></div>
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={groupOffset}
          totalCount={dbx_groups.total}
          list={dbx_groups.groups}
          componentRender={(group) => (
            <GroupCard
              {...{
                group,
                key: group.name,
                rightAction: (
                  <CDHButton
                    {...{
                      btnTypeClass: "butn-delete",
                      faClass: "fa-minus",
                      onClick: () => unregisterWorkspace(group.uri),
                      title: "Revoke group"
                    }}
                  />
                )
              }}
            />
          )}
          loadPage={updateGroups}
          onNoResult={() => (
            <p className="total-search-results">No groups found</p>
          )}
        />
      </div>
    </div>
  );
};

export default withGoTo(DatabricksWorkspaceDetailsGroupsTab);
