/* @flow */
import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import "./style.less";
import classnames from "classnames";
import Error from "../../../../components/error";
import ToggleButton from "../../../../components/toggleButton";
import HelpOn from "../../../../components/helpOn";
import LevelOfMaturity from "../../../datasets/components/LevelOfMaturity";
import TagsField from "../../../datasets/components/TagsField";
import { DOMAINS } from "../../../datasets/components/constants";
import Row from "../../../../components/row";
import Col from "../../../../components/col";
import AutoCompleteLanguages from "../../../datasets/components/form/components/autoCompleteLanguages";
import HelpLevelOfMaturity from "../../../datasets/components/form/components/helpLevelOfMaturity";
import AutoCompleteDataTypes from "../../../datasets/components/form/components/autoCompleteDataTypes";

type propTypes = {
  dataset: Object,
  submit: Function,
  errorSubmit: Object,
  handleChange: Function,
  displayName: boolean
};

const MIN = 30;

class DatasetsForm extends React.PureComponent<propTypes> {
  render() {
    const { dataset, submit, errorSubmit, handleChange, displayName } =
      this.props;
    return (
      <Formik
        initialValues={{}}
        validate={() => {
          const errors = {};
          if (displayName && (!dataset.name || dataset.name.length <= 0))
            errors.name = "Name can not be empty";
          if (!dataset.tags || dataset.tags.length <= 0)
            errors.tags = "List of Tags can not be empty";
          if (!dataset.languages) errors.language = "Language is mandatory";
          if (!dataset.domain || dataset.domain === "default")
            errors.domain = "Domain is mandatory";
          if (!dataset.update_frequency)
            errors.update_frequency = "Update frequency is mandatory";
          if (!dataset.description || dataset.description.length < MIN)
            errors.description = `Description is too short (${MIN} characters min)`;
          if (dataset.description.trim().length <= 0)
            errors.description = "Description must contain text";
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) =>
          submit().then(() => setSubmitting(false))
        }
      >
        {({ isSubmitting, handleSubmit, errors, touched }) => (
          <form className="datasets-form" onSubmit={handleSubmit}>
            <label className="label-form title-s">Datasets Feeder</label>
            {errorSubmit && (
              <div className="dataset-form-main-part">
                <Error
                  title={"Create datasets, error..."}
                  error={errorSubmit}
                  path={"DatasetsForm"}
                  stringOnly
                />
              </div>
            )}
            <div className="dataset-form-main-part">
              <div>
                <fieldset className="form-group">
                  <legend className="label-form">name *</legend>
                  {displayName ? (
                    <Field
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={dataset.name}
                      type="name"
                      name="name"
                      className="form-control bg-white"
                      placeholder="Dataset name..."
                    />
                  ) : (
                    <div>
                      The names are the prefixes (You can edit the name later)
                    </div>
                  )}
                  {errors.name && (
                    <div className="error-msg">{errors.name}</div>
                  )}
                </fieldset>

                <fieldset className="form-group">
                  <legend className="label-form">
                    description *
                    <div
                      className={classnames("dataset-description", {
                        "description-is-too-short":
                          (dataset.description || "").length < MIN,
                        "description-is-good":
                          (dataset.description || "").length >= MIN
                      })}
                    >
                      ({(dataset.description || "").length}/{MIN} characters
                      min)
                    </div>
                  </legend>
                  <Field
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    value={dataset.description}
                    component="textarea"
                    name="description"
                    className="form-control bg-white"
                    placeholder="Description..."
                    rows={4}
                  />
                  {errors.description && (
                    <div className="error-msg">{errors.description}</div>
                  )}
                </fieldset>

                <Row>
                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">Type of dataset *</legend>
                      <Field
                        onChange={(e) => handleChange("type", e.target.value)}
                        value={dataset.type}
                        name="type"
                        component="select"
                        className="form-control bg-white"
                      >
                        <option value="structured">structured</option>
                        <option value="unstructured">unstructured</option>
                      </Field>
                      <ErrorMessage name="type" component="div" />
                    </fieldset>
                  </Col>

                  <Col size={6}>
                    <fieldset className="form-group">
                      <label className="label-form">
                        Basic metadata display *
                      </label>
                      <div className="basic-metadata-display">
                        <ToggleButton
                          id="basic-metadata-display-id"
                          checkedValue={!!dataset.basic_metadata}
                          onChange={() =>
                            handleChange(
                              "basic_metadata",
                              !dataset.basic_metadata
                            )
                          }
                        />

                        <div>
                          <HelpOn
                            content={
                              <div>
                                <h3>{"Basic metadata display"}</h3>
                                <div>
                                  <b>{"Activated:"}</b>
                                </div>
                                <div>
                                  {
                                    "Displays an overview of the dataset structure and its quality (metadata visibility)"
                                  }
                                </div>
                                <div>{""}</div>
                                <div>
                                  <b>{"Deactivated:"}</b>
                                </div>
                                <div>
                                  {
                                    "Displays a limited access to the metadata for a CDH user with no access to the dataset."
                                  }
                                </div>
                                <div>
                                  {
                                    "(visibility limited to Field name, Field Type and Field Completeness analysis)"
                                  }
                                </div>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Row>
                  <Col size={6}>
                    <fieldset className="form-group">
                      <label className="label-form">
                        Level of maturity *
                        <HelpOn content={<HelpLevelOfMaturity />} />
                      </label>
                      <LevelOfMaturity
                        step={dataset.maturity_level || 0}
                        onStepChange={(step) =>
                          handleChange("maturity_level", step)
                        }
                      />
                    </fieldset>
                  </Col>
                </Row>

                <fieldset className="form-group">
                  <div className="tags d-flex">
                    <div className="multiple-elements">
                      <TagsField
                        tags={dataset.tags}
                        onChangeTags={(e) =>
                          handleChange("tags", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.tags && (
                    <div className="error-msg"> {errors.tags}</div>
                  )}
                </fieldset>
                <fieldset className="form-group">
                  <div className="d-flex">
                    <div className="multiple-elements">
                      <legend className="label-form">Usage</legend>
                      <Field
                        onChange={(e) =>
                          handleChange("visibility", e.target.value)
                        }
                        value={dataset.visibility}
                        component="select"
                        name="visibility"
                        className="form-control bg-white"
                      >
                        <option key="1" value="Public">
                          Public
                        </option>
                        <option key="2" value="Internal Use">
                          Internal Use
                        </option>
                      </Field>
                    </div>
                    <div className="ml-3">
                      <label className="label-form">Sharing Feature</label>
                      <div className="d-flex">
                        <ToggleButton
                          id="shareable-id"
                          checkedValue={!!dataset.shareable}
                          onChange={() =>
                            handleChange("shareable", !dataset.shareable)
                          }
                        />
                        <HelpOn
                          content={
                            <div>
                              <h3>{"Shareable"}</h3>
                              <div>
                                <b>{""}</b>
                              </div>
                              <div>
                                {
                                  "This option is used to define whether the dataset can be shared or not."
                                }
                              </div>
                              <div>
                                {
                                  "If this option is set as unshareable, the dataset will continue to appear in the catalog but will no longer have a request access or share option"
                                }
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <Row>
                  <Col size={12}>
                    <fieldset className="form-group">
                      <label className="label-form">
                        Register With AWS Lake Formation
                      </label>
                      <div className="d-flex">
                        <ToggleButton
                          id="isregisteredwithlakeformationid"
                          checkedValue={
                            !!dataset.is_registered_with_lakeformation
                          }
                          onChange={() =>
                            handleChange(
                              "is_registered_with_lakeformation",
                              !dataset.is_registered_with_lakeformation
                            )
                          }
                          disabled={
                            dataset.datasourceRegisteredWithLakeFormation
                          }
                        />
                        <HelpOn
                          content={
                            <div>
                              <h3>{"Register With Lake Formation"}</h3>
                              <div>
                                <b>{""}</b>
                              </div>
                              <div>
                                {
                                  "When enabled CDH relies on AWS Lake Formation and IAM to share and control access to the dataset stages."
                                }
                              </div>
                              <div>
                                {
                                  "This feature is disabled by default, which means CDH relies only on AWS IAM to manage access and sharing of the dataset stages."
                                }
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>
                <fieldset className="form-group">
                  <legend className="label-form">Data Types</legend>
                  <AutoCompleteDataTypes
                    defaultValue={dataset.data_types}
                    handleChange={(e) =>
                      handleChange("data_types", e.target.value)
                    }
                  />
                  {errors.data_types && touched.data_types && (
                    <div className="error-msg"> {errors.data_types}</div>
                  )}
                </fieldset>
                <Row>
                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">Domain *</legend>
                      <Field
                        onChange={(e) => handleChange("domain", e.target.value)}
                        value={dataset.domain || "default"}
                        component="select"
                        name="domain"
                        className="form-control bg-white"
                      >
                        {[
                          <option key={"default"} value={"default"} disabled>
                            {"Domain"}
                          </option>
                        ].concat(
                          DOMAINS.map((domain) => (
                            <option key={domain.label} value={domain.key}>
                              {domain.label}
                            </option>
                          ))
                        )}
                      </Field>
                      {errors.domain && (
                        <div className="error-msg"> {errors.domain}</div>
                      )}
                    </fieldset>
                  </Col>

                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">Languages *</legend>
                      <AutoCompleteLanguages
                        defaultValue={dataset.languages}
                        handleChange={(e) =>
                          handleChange("languages", e.target.value)
                        }
                      />
                      {errors.language && (
                        <div className="error-msg"> {errors.language}</div>
                      )}
                    </fieldset>
                  </Col>
                </Row>

                <Row>
                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">Update frequency *</legend>
                      <Field
                        onChange={(e) =>
                          handleChange("update_frequency", e.target.value)
                        }
                        value={dataset.update_frequency || "default"}
                        component="select"
                        name="update_frequency"
                        className="form-control bg-white"
                      >
                        <option key="1" value="default" disabled>
                          Select Frequency
                        </option>
                        <option key="2" value="Daily">
                          Daily
                        </option>
                        <option key="3" value="Weekly">
                          Weekly
                        </option>
                        <option key="4" value="Monthly">
                          Monthly
                        </option>
                        <option key="5" value="Unfrequent">
                          Unfrequent
                        </option>
                      </Field>
                      {errors.update_frequency && (
                        <div className="error-msg">
                          {" "}
                          {errors.update_frequency}
                        </div>
                      )}
                    </fieldset>
                  </Col>

                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">
                        Link to description
                      </legend>
                      <div className={"input-group"}>
                        <div className={"input-group-prepend"}>
                          <span
                            className="input-group-text"
                            style={{ padding: "5px" }}
                          >
                            URL
                          </span>
                        </div>
                        <Field
                          onChange={(e) =>
                            handleChange(
                              "external_description_url",
                              e.target.value
                            )
                          }
                          value={dataset.external_description_url || ""}
                          type="text"
                          name="external_description_url"
                          className="form-control bg-white"
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Row>
                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">License</legend>
                      <Field
                        onChange={(e) =>
                          handleChange("license", e.target.value)
                        }
                        value={dataset.license || ""}
                        type="text"
                        name="license"
                        className="form-control bg-white"
                      />
                    </fieldset>
                  </Col>

                  <Col size={6}>
                    <fieldset className="form-group">
                      <legend className="label-form">Contact</legend>
                      <Field
                        onChange={(e) =>
                          handleChange("contact", e.target.value)
                        }
                        value={dataset.contact || ""}
                        type="text"
                        name="contact"
                        className="form-control bg-white"
                      />
                    </fieldset>
                  </Col>
                </Row>
              </div>
            </div>
            <div className={"row justify-content-center"}>
              <button type="submit" disabled={isSubmitting} className="butn">
                {isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                {"Populate selected dataset(s)"}
                <i className="fas fa-spacing fa-arrow-down" />
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default DatasetsForm;
