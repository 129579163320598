/* @flow */

export const getDatasetPublic = {
  query: `
        query Q ($datasetUri: String!) {
            getDatasetPublic(datasetUri: $datasetUri) {
                access
                accountid
                arn
                aws
                createdat
                creatoruri
                description
                domain
                languages
                name
                owneruri
                parent
                tags
                type
                updatedat
                uri
                basic_metadata
                maturity_level
                metadata_frequency
                update_frequency
                size
                s3_inventory_last_update
                last_metadata_update
                visibility
                shareable
                is_partitioned
                data_types
                is_registered_with_lakeformation
                source_type
                database
                datasource {
                    uri
                    name
                    aws
                    region
                    sharingManagementGroup {
                        uri
                        name
                    }
                }
                owner {
                    name
                    uri
                }
                creator {
                    name
                    uri
                }
            }
        }
    `,
  keyOutput: "getDatasetPublic"
};
