export const mutationIndexDatasetOnCollibra = {
  query: `mutation Q ($datasetUri: String!, $id: ID!) {
        publishDatasetToCollibra(datasetUri: $datasetUri, id: $id){
            asset_id
            asset_url
            status
        }
      }
    `,
  keyOutput: "publishDatasetToCollibra"
};
