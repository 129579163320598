export const listDssProjectConnections = {
  query: `query Q ($cartUri:String!) {
        listDssProjectConnections(cartUri:$cartUri) {
            id
            name
            platform_uri
            platform{
                aws
                name
                environment
            }
            datasource_uri
            dataset_uri
            instance_uri
            connection_type
            custom_class
            output_bucket
            output_encryption_key
            output_prefix
            createdat
            status
            error
            updatedat
            dss_instance{
             uri
             name
             url
             accountid
            }
        }
      }
    `,
  keyOutput: "listDssProjectConnections"
};
