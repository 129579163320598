/* @flow */

import React from "react";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import routes from "../../routes";
import Modal from "../../../components/modal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import Logger from "../../../utils/logger";
import { updateMessage } from "../../globalNotifications/actions";
import "./view.less";
import Tabs from "../../../components/tabs";
import GraphQl from "../../../graphQL";
import Breadcrumb from "../../../components/breadcrumb";
import CartEnvironments from "./CartEnvironments";
import ButtonAction from "../../../components/buttonAction";
import CartDetails from "./CartDetails";
import CartTools from "./CartTools";
import CartLogs from "./CartLogs";
import DetachProjectModal from "../components/detachProjectModal";
import CartData from "./CartData";
import CartDssProjectConnections from "./DssConnection/CartDssProjectConnections";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import ProjectSqlLab from "../../sqlLab/ProjectSqlLab";
import smstudiologo from "../../smstudio/studio-logo.png";
import CartRSDatasources from "./RSDatasource/CartRSDatasources";
import { Alert } from "@material-ui/lab";
import { isRSSourceType } from "../../../utils/constants";
import CartDatabricksWorkspacesConnection from "./Databricks/CartDatabricksWorkspacesConnection";

const Log = Logger("CartView");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      cartUri: string
    },
    url: string
  },
  showGlobalNotification: Function,
  auth: {
    getUser: Function
  }
};

type stateTypes = {
  ready: boolean,
  cart: Object,
  error: boolean,
  cartModal: boolean,
  errorDatasourceModal: string,
  detachCartModal: boolean,
  logsGroups: [],
  deleteLogsGroupsToo: boolean,
  userCanDeleteInSandbox: boolean,
  showDataikuDSS: boolean,
  hideInfoPanel: boolean,
  showSagemakerStudioLogo: boolean,
  isRSDatasourceLinked: boolean,
  showRS: boolean,
  showDatabricks: boolean,
  hasRSDatasets: boolean,
  showDatabricks: boolean
};

class CartView extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      cart: {},
      error: false,
      cartModal: false,
      errorDatasourceModal: "",
      detachCartModal: false,
      logsGroups: [],
      deleteLogsGroupsToo: false,
      userCanDeleteInSandbox: true,
      showDataikuDSS: false,
      showDatabricks: false,
      hideInfoPanel: false,
      showSagemakerStudioLogo: false,
      isRSDatasourceLinked: false,
      showRS: false,
      hasRSDatasets: false
    };
    this.isDisabled = this.isDisabled.bind(this);
  }

  componentDidMount() {
    this.isDataikuFeatureEnabled();
    this.isRSFeatureEnabled();
    this.isDatabricksFeatureEnabled();
    this.getCart();
    this.fetchLogsGroupList();
    this.getUserCanDeleteInSandbox();
    this.isSagemakerUserProfilUsed();
    this.listProjectRSDatasources();
  }

  isSagemakerUserProfilUsed = () => {
    this.props.api.smstudio
      .listUserProfiles(this.props.match.params.cartUri)
      .then((SagemakerStudioUserProfiles) => {
        this.setState({
          showSagemakerStudioLogo: !!(
            SagemakerStudioUserProfiles && SagemakerStudioUserProfiles.total > 0
          )
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  isDataikuFeatureEnabled() {
    const { cartUri } = this.props.match.params;
    const accountUri = getAccountUriFromUri(cartUri);
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATAIKU", accountUri)
      .then((showDataikuDSS) => {
        this.setState({ showDataikuDSS });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  isDatabricksFeatureEnabled() {
    const { cartUri } = this.props.match.params;
    const accountUri = getAccountUriFromUri(cartUri);
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATABRICKS", accountUri)
      .then((showDatabricks) => {
        this.setState({ showDatabricks });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  isRSFeatureEnabled() {
    const { cartUri } = this.props.match.params;
    const accountUri = getAccountUriFromUri(cartUri);
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("REDSHIFT_SERVERLESS", accountUri)
      .then((showRS) => {
        this.setState({ showRS });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getUserCanDeleteInSandbox = async () => {
    let canDelete = true;
    const sandboxProjectId =
      "uri:cart:445391:Online Training:dataikutraininglabproje_45631";
    if (this.props.match.params.cartUri === sandboxProjectId) {
      canDelete = false;
      const groups = await this.props.api.identity.listMyGroups({
        offset: 0,
        limit: 99,
        filters: []
      });
      const hasTrainingGroup = !!groups.results.find(
        (i) => i.uri === "uri:group:445391:Online Training"
      );
      const hasTrainersGroup = !!groups.results.find(
        (i) => i.uri === "uri:group:445391:CDH Lab Trainers"
      );
      if (hasTrainingGroup && hasTrainersGroup) {
        canDelete = true;
      }
    }
    this.setState(() => ({
      userCanDeleteInSandbox: canDelete
    }));
  };

  getCart = () => {
    this.setState({ ready: false }, () => {
      this.props.api.cart
        .getCart(this.props.match.params.cartUri, { limit: 10 })
        .then((cart) => {
          Log.info("cart:", cart);
          const rsDatasets = cart.datasets.filter((d) =>
            isRSSourceType(d.source_type)
          );
          this.setState({
            cart,
            ready: true,
            hasRSDatasets: rsDatasets && rsDatasets.length > 0
          });
        })
        .catch((error) => {
          Log.error(error);
          this.setState({ error });
        });
    });
  };

  openCartModal() {
    return this.setState({
      cartModal: true,
      errorDatasourceModal: undefined
    });
  }

  closeCartModal() {
    return this.setState({ cartModal: false });
  }

  fetchLogsGroupList = () =>
    this.props.api.logGroups
      .listLogsGroup(this.props.match.params.cartUri)
      .then((cartLogsGroups) => {
        this.setState({ logsGroups: cartLogsGroups });
      });

  deleteCart = () => {
    this.props.api.cart
      .deleteCart(this.state.cart.uri, this.state.deleteLogsGroupsToo)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Project deleted",
          type: "success"
        });
        this.closeCartModal();
        return this.props.goTo({
          route: routes.Cart.List
        });
      })
      .catch((errorDatasourceModal) => {
        Log.error(errorDatasourceModal);
        this.setState({
          errorDatasourceModal: getErrorMessageWithoutCode(errorDatasourceModal)
        });
        this.props.showGlobalNotification({
          message: "Project deletion failed.",
          type: "alert"
        });
      });
  };

  listProjectRSDatasources = (offset: number = 0) => {
    const options = {
      limit: 1000,
      offset
    };
    return this.props.api.cart
      .listProjectRSDatasources(this.props.match.params.cartUri, options)
      .then((datasources) => {
        this.setState({
          isRSDatasourceLinked: datasources.total > 0
        });
      })
      .catch(() => {
        this.setState({
          isRSDatasourceLinked: false
        });
      });
  };

  cartModal() {
    const { cart, errorDatasourceModal } = this.state;

    return (
      <Modal
        title={`Delete project ${cart.name}`}
        body={
          <React.Fragment>
            <div className="first-part-modal">Are you sure ? </div>
            {this.state.logsGroups.length > 0 ? (
              <div className="delete-kms-container">
                <input
                  id="deleteKMSKey"
                  type="checkbox"
                  onClick={(event) =>
                    this.setState({ deleteLogsGroupsToo: event.target.checked })
                  }
                />
                <label htmlFor="deleteKMSKey" className="fas">
                  <small className="attribute-label">
                    Check if you want to delete associed logs groups
                  </small>
                </label>
              </div>
            ) : null}
          </React.Fragment>
        }
        errorMessage={errorDatasourceModal}
        actions={[
          <ButtonAction label="Close" onClick={() => this.closeCartModal()} />,
          <ButtonAction
            label="Delete"
            className="butn butn-delete"
            onClick={this.deleteCart}
          />
        ]}
      />
    );
  }

  closeDetachCartModal = () => this.setState({ detachCartModal: false });

  isDisabled = (value) => value;

  getTabs = () => {
    const { cart } = this.state;

    const tabs = [
      {
        name: "Data",
        index: "data",
        route: `${this.props.match.url}#data`,
        content: (
          <CartData
            isDisabled={this.isDisabled}
            cart={cart}
            showGlobalNotification={this.props.showGlobalNotification}
            api={this.props.api}
            reloadCart={this.getCart}
          />
        )
      },
      {
        name: "Environments",
        index: "environments",
        route: `${this.props.match.url}#environments`,
        content: (
          <CartEnvironments
            cart={cart}
            api={this.props.api}
            goTo={this.props.goTo}
          />
        )
      },
      {
        name: <span>Tools</span>,
        index: "tools",
        route: `${this.props.match.url}#tools`,
        content: (
          <CartTools
            isDisabled={this.isDisabled}
            cart={cart}
            api={this.props.api}
            reloadCart={this.getCart}
          />
        )
      },
      {
        name: "Logs",
        index: "logs",
        route: `${this.props.match.url}#logs`,
        content: <CartLogs api={this.props.api} cart={cart} />
      }
    ];
    if (this.state.showRS) {
      tabs.push({
        name: (
          <span>
            Redshift Serverless<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "rsd",
        route: `${this.props.match.url}#rsd`,
        content: <CartRSDatasources api={this.props.api} cart={cart} />
      });
    }
    if (this.state.showDataikuDSS) {
      tabs.push({
        name: (
          <span>
            Dataiku DSS<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "dss",
        route: `${this.props.match.url}#dss`,
        content: (
          <CartDssProjectConnections
            cart={cart}
            api={this.props.api}
            goTo={this.props.goTo}
            dssInstances={cart.dssInstances}
            platforms={cart.platforms}
          />
        )
      });
    }
    if (this.state.showDatabricks) {
      tabs.push({
        name: (
          <span>
            Databricks<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "dbks",
        route: `${this.props.match.url}#dbks`,
        content: (
          <CartDatabricksWorkspacesConnection
            cart={cart}
            api={this.props.api}
            goTo={this.props.goTo}
            match={this.props.match}
          />
        )
      });
    }
    tabs.push({
      name: (
        <span>
          SQL Lab<span className="beta-tab-flag">BETA</span>
        </span>
      ),
      index: "sqllab",
      route: `${this.props.match.url}#sqllab`,
      content: (
        <ProjectSqlLab
          api={this.props.api}
          cart={cart}
          showInfo={this.showInfo}
          hideInfo={this.hideInfo}
        />
      )
    });

    return tabs;
  };

  hideInfo = () => {
    this.setState((prevState) => ({ hideInfoPanel: !prevState.hideInfoPanel }));
  };

  showInfo = () => {
    if (this.state.hideInfoPanel) {
      this.setState({ hideInfoPanel: false });
    }
  };

  render() {
    const { error, ready, cart, cartModal, detachCartModal } = this.state;

    if (error) return <Error error={error} path={"CartView"} />;
    if (!ready) return <Loading message={"project"} />;

    return (
      <div>
        <div className="cartView">
          <Breadcrumb
            view={
              <span>
                {cart.name}{" "}
                {this.state.showSagemakerStudioLogo && (
                  <img
                    className="connector-logo-img mr-1"
                    src={smstudiologo}
                    alt="SagemakerStudio"
                    width={45}
                    height={40}
                    style={{ marginBottom: "15px" }}
                  />
                )}
              </span>
            }
          />
        </div>
        <div className="container-actions">
          <div className="actions-project-left">
            <div
              className="butn butn-flat"
              onClick={() =>
                this.props.goTo({
                  route: routes.Cart.AddDataset,
                  params: {
                    cartUri: cart.uri,
                    groupUri: cart.groupuri
                  }
                })
              }
            >
              <i className="fas fa-plus butn-flat" />
              <span className="butn-text">Add data</span>
            </div>
          </div>
          <div className="actions-right">
            <button
              className="butn"
              onClick={() =>
                this.props.goTo({
                  route: routes.Cart.Edit,
                  params: { cartUri: cart.uri },
                  state: { cart }
                })
              }
              type={"button"}
              style={{ margin: "5px" }}
            >
              Edit
            </button>
            {this.state.userCanDeleteInSandbox && (
              <button
                className="butn butn-delete"
                onClick={() => this.openCartModal()}
                type={"button"}
                style={{ margin: "5px" }}
              >
                Delete Project
              </button>
            )}
          </div>
        </div>
        {this.state.cart &&
          this.state.cart.datasets &&
          this.state.cart.datasets.length > 0 &&
          this.state.hasRSDatasets &&
          !this.state.isRSDatasourceLinked && (
            <div className={"mb-3"}>
              <Alert severity="info">
                Your project now includes Redshift Serverless datasets. To
                utilize them, simply add a Redshift Serverless datasource via
                the dedicated tab.
              </Alert>
            </div>
          )}
        <div className={this.state.hideInfoPanel ? "" : "project-body"}>
          {!this.state.hideInfoPanel && (
            <div className="project-body-left">
              <CartDetails cart={cart} />
            </div>
          )}
          <div className={this.state.hideInfoPanel ? "" : "project-body-right"}>
            <Tabs
              isPreload={false}
              defaultActiveIndex={"datasets"}
              tabs={this.getTabs()}
            />
            {cartModal && this.cartModal()}
            {detachCartModal && (
              <DetachProjectModal
                cart={this.state.cart}
                hide={() => this.closeDetachCartModal()}
                showGlobalNotification={this.props.showGlobalNotification}
                goBack={() => this.props.goTo({ route: routes.Cart.List })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(CartView));
