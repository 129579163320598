import React, { useState, useEffect } from "react";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import { connect } from "react-redux";
import { useDatabricks } from "./hook/useDatabricks";
import AttachDBKSWorpsaceModal from "./modal/AttachDBKSWorpsaceModal";
import RefreshTablesModal from "./modal/RefreshTablesModal";
import Links from "../../../links";

const CartDatabricksWorkspacesConnection = ({ api, cart, match }) => {
  const { error, isLoading, workspaces } = useDatabricks(api, cart, match);
  const [showModal, setShowModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const openRefreshModal = () => {
    setShowRefreshModal(true);
  };

  const unregisterDbx = () => {
    api.databricks
      .unregisterDatabricksData(cart.uri)
      .then(() => location.reload());
  };

  const CartRendering = ({ workspace }) => {
    return (
      <div
        className={"pipeline-card card-shadow card bg-white"}
        onClick={() => {}}
      >
        <div className="card-datasource-body">
          <div className="card-cart-body-left">
            <div className="text-capitalize datasource-card-name">
              {workspace.dbx_workspace_name}
            </div>
            <div className="card-datasource-details">
              <p className="card-cart-description">
                Account Name : {workspace.dbxAccount.dbx_account_name}
              </p>
            </div>
          </div>
          <div className="card-cart-body-right">
            <div className="card-datasource-body-actions">
              <a
                className="btn-cart"
                target="_blank"
                href={workspace.dbx_workspace_url}
              >
                <i className="fas fa-play fa-spacing" />
                <span className="text-actions">Databricks Access</span>
              </a>
              <div className="btn-cart" onClick={() => openRefreshModal()}>
                <i className="fas fa-sync-alt fa-spacing" />
                <span className="text-actions">Refresh tables</span>
              </div>
              <div className="btn-cart" onClick={() => unregisterDbx()}>
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Detach from workspace</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {error !== null ? (
        <Error error={error} path="Databricks workspaces" />
      ) : isLoading ? (
        <Loading message="Databricks instances" />
      ) : cart.dbx_workspace.dbx_workspace_name !== null &&
        cart.dbx_catalog.dbx_catalog_name !== null &&
        workspaces !== null &&
        workspaces !== undefined &&
        workspaces.length > 0 &&
        workspaces[0].dbxWorkspaces !== null &&
        workspaces[0].dbxWorkspaces !== undefined &&
        workspaces[0].dbxWorkspaces.length > 0 ? (
        <div>
          <CartRendering workspace={workspaces[0].dbxWorkspaces[0]} />
          {showRefreshModal && (
            <RefreshTablesModal
              api={api}
              cart={cart}
              match={match}
              open={showRefreshModal}
              onClose={() => setShowRefreshModal(false)}
            />
          )}
        </div>
      ) : (
        <div>
          <button
            className="butn butn-primary"
            onClick={() => {
              openModal();
            }}
          >
            Attach to a Databricks Workspace
          </button>
          {showModal && (
            <AttachDBKSWorpsaceModal
              api={api}
              cart={cart}
              match={match}
              open={showModal}
              onClose={() => setShowModal(false)}
              workspaces={workspaces}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartDatabricksWorkspacesConnection)));
