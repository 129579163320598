/* @flow */

import React from "react";
import "./style.less";
import Facet from "./Facet";
import FacetsFromActiveFilters from "./FacetsFromActiveFilters";
import type { FacetFilter } from "../../../../graphQL/propstypes";
import Scroll from "../../../../components/List/Scroll";

type propTypes = {
  categoryFacets: {
    category: string,
    name: string,
    facets: Array<Object>
  },
  activeFilters: Array<FacetFilter>,
  onClickFacet: Function
};

type stateTypes = {
  open: boolean
};

class CategoryFacets extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      open: this.props.categoryFacets.facets.length < 5
    };
  }

  onClickCollapseToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    return (
      <li className="facet-category" key={this.props.categoryFacets.category}>
        <div className="facet-title" onClick={this.onClickCollapseToggle}>
          <h3>{this.props.categoryFacets.name}</h3>
          <i
            className={`facet-chevron fas ${
              this.state.open ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </div>
        <ul
          className={`facet-list-criteria collapse ${
            this.state.open ? "show" : " in"
          }`}
        >
          <FacetsFromActiveFilters
            categoryFacets={this.props.categoryFacets}
            activeFilters={this.props.activeFilters}
            onClickFacet={this.props.onClickFacet}
          />
          {this.props.categoryFacets.facets.length < 15 ? (
            this.props.categoryFacets.facets.map((facet) => (
              <Facet
                key={facet.value}
                category={this.props.categoryFacets.category}
                facet={facet}
                isActive={
                  this.props.activeFilters.filter(
                    (activeFilter) =>
                      activeFilter.field ===
                        this.props.categoryFacets.category &&
                      activeFilter.values.find((value) => value === facet.value)
                  ).length > 0
                }
                onClickFacet={this.props.onClickFacet}
              />
            ))
          ) : (
            <div style={{ maxHeight: "400px" }}>
              <Scroll
                itemComponent={(facet, key, style) => (
                  <Facet
                    key={key}
                    style={style}
                    category={this.props.categoryFacets.category}
                    facet={facet}
                    isActive={
                      this.props.activeFilters.filter(
                        (activeFilter) =>
                          activeFilter.field ===
                            this.props.categoryFacets.category &&
                          activeFilter.values.find(
                            (value) => value === facet.value
                          )
                      ).length > 0
                    }
                    onClickFacet={this.props.onClickFacet}
                  />
                )}
                list={this.props.categoryFacets.facets}
                itemComponentHeight={32}
              />
            </div>
          )}
        </ul>
      </li>
    );
  }
}

export default CategoryFacets;
