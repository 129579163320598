/* @flow */

import React from "react";
import DatasetShareRequestPending from "./datasetShareRequestPending";
import DatasetShareRequestAccepted from "./datasetShareRequestAccepted";
import DatasetShareRequestRejected from "./datasetShareRequestRejected";
import DatasetShareRequestRevoked from "./datasetShareRequestRevoked";
import withErrorBoundary from "../../../errorBoundaryHOC";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";

type propTypes = {
  share: {
    id?: string,
    dataset: {
      access: string,
      uri: string,
      name: string
    },
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    request_status: string,
    createdat: string,
    updatedat: ?string,
    last_request_date: string,
    message: string
  },
  api: GraphQl,
  onAction: Function
};

const DatasetShare = ({ share, api, onAction }: propTypes) => {
  if (share.request_status === "accepted") {
    return (
      <DatasetShareRequestAccepted
        key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
      />
    );
  }

  if (share.request_status === "pending") {
    return (
      <DatasetShareRequestPending
        key={`${share.dataset.uri}.${share.group.uri}`}
        api={api}
        pendingShareRequest={share}
        onAction={onAction}
      />
    );
  }

  if (share.request_status === "rejected") {
    return (
      <DatasetShareRequestRejected
        key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
      />
    );
  }

  if (share.request_status === "revoked") {
    return (
      <DatasetShareRequestRevoked
        // key={`${share.dataset.uri}.${share.group.uri}`}
        share={share}
      />
    );
  }

  return false;
};

DatasetShare.defaultProps = {
  share: {
    group: {
      uri: ":::::::",
      name: "-",
      account: {
        name: "-"
      }
    },
    dataset: {
      access: "read",
      uri: ":::::::",
      name: "-"
    },
    updatedat: undefined,
    request_status: ""
  },
  onAction: Function
};

export default withAppSync(withErrorBoundary(DatasetShare));
