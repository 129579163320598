/* @flow */
import React from "react";
import classnames from "classnames";
import Step from "./step";
import "./style.less";

type propTypes = {
  steps: Array<{
    label: string,
    tooltip: string
  }>,
  isLock: boolean,
  step: number,
  onStepChange: Function
};

type stateTypes = {
  step: number,
  stepHovered?: number
};

class Stepper extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    steps: [],
    isLock: false,
    step: 0,
    onStepChange: () => {}
  };

  constructor(props: propTypes) {
    super(props);
    this.state = {
      step: props.step || 0,
      stepHovered: undefined
    };
  }

  onStepChange = (step: number) => {
    if (!this.props.isLock) {
      this.setState({ step });
      this.props.onStepChange(step);
    }
  };

  onStepHoverChange = (stepHovered: number) => {
    if (!this.props.isLock) this.setState({ stepHovered });
  };

  onStepHoverClear = () => {
    this.setState({ stepHovered: undefined });
  };

  getClassname = (index: number) => {
    const isHovered = this.state.stepHovered && index <= this.state.stepHovered;
    const isSelected = index <= this.state.step;
    const isLastStep = this.props.steps.length === index + 1;

    if (index === 0) return "stepper-step-first is-selected";
    if (isLastStep && isSelected) return "stepper-step-last is-selected";
    if (isLastStep && isHovered) return "stepper-step-last is-hovered";
    if (isLastStep) return "stepper-step-last";
    if (isSelected) return "stepper-step-middle is-selected";
    if (isHovered) return "stepper-step-middle is-hovered";
    return "stepper-step-middle";
  };

  getDisplayStep = () =>
    this.props.steps[
      typeof this.state.stepHovered === "number"
        ? this.state.stepHovered
        : this.state.step
    ];

  render() {
    return (
      <div className="stepper">
        <ul>
          {this.props.steps.map((step, index) => (
            <Step
              key={step.label}
              classname={classnames(this.getClassname(index), {
                "is-lock": this.props.isLock
              })}
              onClick={() => this.onStepChange(index)}
              handleIsHovered={() => this.onStepHoverChange(index)}
              handleIsNotHovered={this.onStepHoverClear}
            />
          ))}
        </ul>
        &nbsp;
        <div className="tooltiped">
          {this.getDisplayStep().label}
          &nbsp;
          <i className="far fa-question-circle" />
          <div className="tooltiptext">{this.getDisplayStep().tooltip}</div>
        </div>
      </div>
    );
  }
}

export default Stepper;
