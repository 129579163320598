/* @flow */
import React from "react";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import CartUserProfile from "./CartUserProfile";
import CartUserProfileCreate from "./CartUserProfileCreate";

type propTypes = {
  cart: Object,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  error: Object,
  openCreateDialog: boolean,
  userProfiles: Array<Object>,
  domains: Array<Object>,
  loadingDomains: boolean
};
class CartUserProfiles extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      openCreateDialog: false,
      userProfiles: [],
      domains: [],
      ready: false,
      loadingDomains: false
    };
  }

  componentDidMount() {
    this.getDomains();
    this.getUserProfiles();
  }

  getDomains = () => {
    const domains = [];
    this.setState({
      loadingDomains: true
    });
    this.props.cart.platforms.forEach((platform) => {
      this.props.api.smstudio
        .listDomains(platform.uri)
        .then((ds) => {
          if (ds && ds.results.length > 0) {
            ds.results.forEach((d) => {
              if (d.region === this.props.cart.region) domains.push(d);
            });
          }
        })
        .catch((error) => {
          this.setState({
            error
          });
        });
    });
    this.setState({
      loadingDomains: false,
      domains
    });
  };

  getUserProfiles = () => {
    this.setState({
      ready: false
    });
    this.props.api.smstudio
      .listUserProfiles(this.props.cart.uri)
      .then((userProfiles) => {
        this.setState({
          ready: true,
          userProfiles: userProfiles.results
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"UserProfiles"} />;
    if (this.state.loadingDomains || !this.state.ready)
      return <Loading message="Sagemaker Studio User Profiles" />;
    return (
      <div className={"environments-container"}>
        <div>
          <div
            className="butn butn-flat"
            onClick={() => this.setState({ openCreateDialog: true })}
          >
            <i className="fas fa-plus butn-icon" />
            <span className="butn-text">New User Profile</span>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error error={this.state.error} path={"UserProfiles"} stringOnly />
          </div>
        )}
        <div className={"card-container"}>
          {this.state.userProfiles.length <= 0 && (
            <div style={{ marginLeft: "15px" }}>
              No user profiles to display.
            </div>
          )}
          {this.state.userProfiles.length > 0 &&
            this.state.userProfiles.map((up) => (
              <CartUserProfile
                key={`${this.props.cart.uri}.${up.id}`}
                api={this.props.api}
                cart={this.props.cart}
                userProfile={up}
                reload={this.getUserProfiles}
              />
            ))}
        </div>
        {this.state.openCreateDialog && (
          <CartUserProfileCreate
            api={this.props.api}
            cart={this.props.cart}
            userProfiles={this.state.userProfiles}
            domains={this.state.domains}
            open={this.state.openCreateDialog}
            onClose={() => this.setState({ openCreateDialog: false })}
            reloadUserProfiles={this.getUserProfiles}
          />
        )}
      </div>
    );
  }
}

export default CartUserProfiles;
