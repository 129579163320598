export const addCartProvider = {
  query: `mutation Q ($cartUri: String!, $input: CartProviderInput!) {
        addCartTrustRelationship(cartUri:$cartUri,input:$input) {
          arn
          description
        }
      }
    `,
  keyOutput: "addCartTrustRelationship"
};
