import React from "react";
import Logger from "../../../../utils/logger";
import InfoList from "../../../../components/info/info-list";
import Paginator from "../../../../components/paginator";
import ProjectCard from "../../../projects/project-card";
const Log = Logger("DatabricksWorkspaceDetailsTab");

const DatabricksWorkspaceDetails = ({
  projectOffset,
  updateProjects,
  workspace
}) => (
  <InfoList
    {...{
      informationList: [
        {
          label: "Name :",
          content: workspace.dbx_workspace_name
        },
        {
          label: "Workspace URL :",
          content: (
            <a
              href={workspace.dbx_workspace_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {workspace.dbx_workspace_url}
            </a>
          )
        },
        {
          label: "Databricks account :",
          content: workspace.dbx_account_uuid
        },
        {
          label: "Databricks Workspace ID",
          content: workspace.dbx_workspace_id
        },
        {
          label: "CDH URI or Unique identifier :",
          content: workspace.uuid
        },
        {
          label: "SQL Warehouse ID :",
          content: workspace.dbx_warehouse_id
        },
        {
          label: "Projects :",
          content: (
            <div style={{ marginTop: 16 }}>
              <Paginator
                limit={10}
                initialOffset={projectOffset}
                totalCount={workspace.projects.total + 1}
                list={workspace.projects.projects}
                componentRender={(project) => (
                  <ProjectCard {...{ project, key: project.name }} />
                )}
                loadPage={updateProjects}
                onNoResult={() => (
                  <p className="total-search-results">No project found</p>
                )}
              />
            </div>
          )
        }
      ]
    }}
  />
);

export default DatabricksWorkspaceDetails;
