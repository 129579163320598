export const LOGGER_LEVEL = {
  EXCEPTION: "EXCEPTION",
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  DEBUG: "DEBUG",
  VERBOSE: "VERBOSE"
};

export const COLOURS = {
  VERBOSE: "lightblue",
  DEBUG: "blue",
  INFO: "darkblue",
  WARNING: "orange",
  ERROR: "red",
  EXCEPTION: "DarkRed"
};

export const REGIONS = [
  {
    label: "Ireland",
    key: "eu-west-1"
  },
  {
    label: "Frankfurt",
    key: "eu-central-1"
  },
  {
    label: "London",
    key: "eu-west-2"
  },
  {
    label: "Paris",
    key: "eu-west-3"
  },
  {
    label: "Northern Virginia",
    key: "us-east-1"
  },
  {
    label: "Ohio",
    key: "us-east-2"
  },
  {
    label: "Oregon",
    key: "us-west-2"
  },
  {
    label: "Canada",
    key: "ca-central-1"
  },
  {
    label: "São Paulo",
    key: "sa-east-1"
  },
  {
    label: "Singapore",
    key: "ap-southeast-1"
  },
  {
    label: "Tokyo",
    key: "ap-northeast-1"
  },
  {
    label: "Sydney",
    key: "ap-southeast-2"
  },
  {
    label: "Seoul",
    key: "ap-northeast-2"
  },
  {
    label: "Mumbai",
    key: "ap-south-1"
  },
  {
    label: "Hong Kong",
    key: "ap-east-1"
  },
  {
    label: "Bahrain",
    key: "me-south-1"
  }
];

export const getRegionLabel = (regionKey) => {
  const regionsFound = REGIONS.filter((region) => region.key === regionKey);
  if (regionsFound.length !== 1) return null;
  return regionsFound[0].label;
};

export const BASE = `DATAFLIX_${process.env.NODE_ENV}`;

export const SOURCE_TYPES = [
  {
    label: "Simple Storage Service (S3)",
    key: "S3"
  },
  {
    label: "Redshift Serverless",
    key: "REDSHIFT_SERVERLESS"
  }
];

export const isS3SourceType = (sourceType) => {
  return !sourceType || sourceType === SOURCE_TYPES[0].key;
};

export const isRSSourceType = (sourceType) => {
  return sourceType && sourceType === SOURCE_TYPES[1].key;
};

export const RS_BASE_CAPACITIES = [
  {
    label: "8",
    key: 8
  },
  {
    label: "16",
    key: 16
  },
  {
    label: "32",
    key: 32
  },
  {
    label: "64",
    key: 64
  },
  {
    label: "128",
    key: 128
  },
  {
    label: "256",
    key: 256
  },
  {
    label: "512",
    key: 512
  }
];
