/* @flow */
import React from "react";
import "./view.less";
import Modal from "../../../components/modal";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";

type propTypes = {
  cart: Object,
  cartProvider: Object,
  showGlobalNotification: Function,
  onAction: Function,
  hide: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string
};

class CartProviderModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  render() {
    const { cart, cartProvider, hide, showGlobalNotification, onAction } =
      this.props;
    return (
      <Modal
        title={`Delete project provider "${cartProvider.arn}"`}
        body={"Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-close-${cart.uri}`}
            type="button"
            className="butn"
            onClick={hide}
          >
            Close
          </button>,
          <button
            key={`provider-delete-${cartProvider.arn}`}
            type="button"
            className="butn butn-delete"
            onClick={() =>
              this.props.api.cart
                .removeTrustFromCart(cartProvider.id, cart.uri)
                .then(onAction)
                .then(
                  showGlobalNotification({
                    message: "Project trustee deleted",
                    type: "success"
                  })
                )
                .catch((error) => {
                  showGlobalNotification({
                    message: "Project trustee deletion failed",
                    type: "alert"
                  });
                  this.setState({ error });
                })
            }
          >
            Delete
          </button>
        ]}
      />
    );
  }
}

export default withAppSync(CartProviderModal);
