/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import "./style.less";

type propTypes = {
  tags: Array<Object>,
  onChangeTags: Function,
  customField: string,
  showGlobalNotification: Function,
  isDisabled?: boolean
};

type stateTypes = {
  rows: Array<Object>
};

class TagsDatasourceFields extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.tags || [{ Key: "", Value: "" }]
    };
  }

  handleAddRow = () => {
    if (this.state.rows.length <= 46) {
      if (this.state.rows.every((row) => row.Key !== "" && row.Value !== "")) {
        const item = {
          Key: "",
          Value: ""
        };
        this.setState((prevState) => ({
          rows: [...prevState.rows, item]
        }));
      } else {
        this.props.showGlobalNotification({
          message: "You cannot add a row if the previous row is empty",
          type: "alert"
        });
      }
    } else {
      this.props.showGlobalNotification({
        message: "You cannot add more than 50 Tags (4 Tags reserved for CDH)",
        type: "alert"
      });
    }
  };

  handleChange = (idx, field) => (e) => {
    const { value } = e.target;
    this.setState((prevstate) => {
      const rows = [...prevstate.rows];
      if (field === "key") {
        rows[idx].Key = value;
      } else {
        rows[idx].Value = value;
      }

      if (this.props.customField) {
        this.props.onChangeTags(this.props.customField, rows);
      } else {
        this.props.onChangeTags("s3_custom_tags", rows);
      }

      return { rows };
    });
  };

  handleRemoveRow = (idx) => {
    this.setState((prevstate) => {
      const rows = [...prevstate.rows];
      rows.splice(idx, 1);
      if (this.props.customField) {
        this.props.onChangeTags(this.props.customField, rows);
      } else {
        this.props.onChangeTags("s3_custom_tags", rows);
      }
      return { rows };
    });
  };

  render() {
    return (
      <div>
        <table className="table-tags-datasource">
          <thead>
            <tr>
              <th className="text-center">Key</th>
              <th className="text-center">Value</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.rows.map((item, idx) => (
              <React.Fragment key={idx}>
                <tr id="addr0" key={idx}>
                  <td>
                    <input
                      type="text"
                      name="key"
                      disabled={this.props.isDisabled}
                      value={this.state.rows[idx].Key}
                      onChange={this.handleChange(idx, "key")}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="value"
                      disabled={this.props.isDisabled}
                      value={this.state.rows[idx].Value}
                      onChange={this.handleChange(idx, "value")}
                      className="form-control"
                    />
                  </td>
                  {!this.props.isDisabled && (
                    <td>
                      <div
                        className="butn-remove-row"
                        onClick={() => {
                          this.handleRemoveRow(idx);
                        }}
                      >
                        <i className="fas fa-times-circle"></i>
                      </div>
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {!this.props.isDisabled && (
          <button
            type="button"
            onClick={this.handleAddRow}
            className="butn butn-small-add"
          >
            <i className="fas fa-plus fa-spacing"></i>
            Add Row
          </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(TagsDatasourceFields);
