export const listNetworkSagemakerNotebookInstances = {
  query: `query Q ($id:String!, $options: ListOptions) {
        getNetwork(id:$id) {
            sagemakerNotebookInstances(options:$options) {
                total
                offset
                limit
                results {
                    id
                    name
                    instance_type
                    arn
                    status
                    createdat
                    updatedat
                    error
                    region
                    project {
                        name
                        uri
                    }
                    environment {
                        uri
                        name
                        aws
                    }
                }
            }
        }
      }
    `,
  keyOutput: "getNetwork.sagemakerNotebookInstances"
};
