export const listCartLogs = {
  query: `query Q ($cartUri: String!, $options: QueryOptions) {
        getCartLogs(cartUri:$cartUri, options: $options) {
            total
            results {
                id
                createdat
                updatedat
                status
                output
                action
                operation
                triggered_by
                endedat
                creator {
                    name
                    gid
                }
            }
        }
      }
    `,
  keyOutput: "getCartLogs"
};
