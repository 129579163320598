/* @flow */

import React from "react";
import withGoTo from "../../goToHOC";

type propTypes = {
  account: Object
};

type stateTypes = {
  gbus: Array<Object>,
  entities: Array<Object>,
  bus: Array<Object>,
  scopes: Array<Object>,
  subScopes: Array<Object>
};

class AccountInfo extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    if (props.account.engieEntities) {
      const gbus = [];
      const entities = [];
      const bus = [];
      const scopes = [];
      const subScopes = [];
      props.account.engieEntities.map((entity) => {
        gbus.push(entity.gbus);
        entities.push(entity.name);
        bus.push(entity.scope);
        scopes.push(entity.scope_id);
        subScopes.push(entity.sub_scope);
        return gbus;
      });
      this.state = {
        gbus: Array.from(new Set(gbus)),
        entities: Array.from(new Set(entities)),
        bus: Array.from(new Set(bus)),
        scopes: Array.from(new Set(scopes)),
        subScopes: Array.from(new Set(subScopes))
      };
    } else {
      this.state = {
        gbus: [],
        entities: [],
        bus: [],
        scopes: [],
        subScopes: []
      };
    }
  }

  standarDisplay(field, value, link) {
    // eslint-disable-line class-methods-use-this
    const v = (value && value.split("\n")) || [];
    let p = v.length > 1 ? v.map((i, key) => <p key={key}>{i}</p>) : value;
    if (typeof p !== "object") {
      if (link && p) {
        if (!p.startsWith("http")) {
          p = `https://${p}`;
        }
      }
    }

    return (
      <div className="display" style={{ width: "100%" }}>
        <div
          className="field-name"
          style={{
            display: "inline-table",
            width: "200px",
            textAlign: "left"
          }}
        >
          {field}
        </div>
        <div
          className="content"
          style={{
            marginLeft: "15px",
            display: "inline-table"
          }}
        >
          {link && p != null ? (
            <a href={p} target="_blank" rel="noopener noreferrer">
              {p}
            </a>
          ) : (
            p || "-"
          )}
        </div>
      </div>
    );
  }

  render() {
    const { account } = this.props;
    return (
      <div className={"dataset-info"}>
        {this.standarDisplay("Organization URI", account.uri)}
        {this.standarDisplay("Name", account.name)}
        {this.standarDisplay("Created By", account.creator.name)}
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Global Business Units
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <table>
              <tbody>
                {this.state.gbus.map((e) => (
                  <tr>
                    <td className="TagValue">{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Business Units
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <table>
              <tbody>
                {this.state.bus.map((e) => (
                  <tr>
                    <td className="TagValue">{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Business Entities
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <table>
              <tbody>
                {this.state.entities.map((e) => (
                  <tr>
                    <td className="TagValue">{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Business Scopes
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <table>
              <tbody>
                {this.state.scopes.map((e) => (
                  <tr>
                    <td className="TagValue">{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Business Sub Scopes
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <table>
              <tbody>
                {this.state.subScopes.map((e) => (
                  <tr>
                    <td className="TagValue">{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withGoTo(AccountInfo);
