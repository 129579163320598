import React from "react";
import PropTypes from "prop-types";
import { withAuth } from "@okta/okta-react";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    return this.checkAuthentication();
  }

  componentDidUpdate() {
    return this.checkAuthentication();
  }

  checkAuthentication() {
    return this.props.auth.isAuthenticated().then((authenticated) => {
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    });
  }

  logout() {
    return this.props.auth.logout();
  }

  render() {
    if (this.state.authenticated === null || !this.state.authenticated)
      return false;
    return (
      <button className={"butn"} type={"button"} onClick={this.logout}>
        Logout
      </button>
    );
  }
}

Logout.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  })
};

export default withAuth(Logout);
