/* eslint-disable quote-props */
/* @flow */
import React from "react";
import "../card/card.less";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import DatasetCardTitle from "../card/DatasetCardTitle";
import DatasetCardPublisher from "../card/DatasetCardPublisher";
import DatasetCardActions from "../card/DatasetCardActions";
import DatasetCardContainer from "../card/DatasetCardContainer";
import DatasetCardShared from "../card/DatasetCardShared";
import { findDomainName } from "../components/constants";
import BookmarkStar from "../components/bookmarkStar";
import { formatDate } from "../../../utils/date";
import { convertSize } from "../../../utils/size";
import NbBookmarksStar from "../components/nbBookmarksStar";
import withErrorBoundary from "../../errorBoundaryHOC";
import Links from "../../links";

type propTypes = {
  goTo: Function,
  dataset: Object,
  actions: Object,
  style: Object,
  reloadBookmarks: Function
};

type stateTypes = {
  isSelected: boolean,
  nb_bookmarks: number
};

const classEnvironment = {
  NoProd: "NoProd",
  Prod: "Prod"
};

class MyDataDatasetCard extends React.Component<propTypes, stateTypes> {
  DOMRef: Object;

  constructor(props) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {
      isSelected: false,
      nb_bookmarks: props.dataset.metric.statistic.nb_bookmarks || 0
    };
  }

  shouldComponentUpdate(nextProps): boolean {
    return nextProps.dataset.uri !== this.props.dataset.uri;
  }

  componentWillUnmount() {}

  renderActions() {
    if (this.props.actions) {
      return this.props.actions;
    }

    return (
      <DatasetCardActions
        access={this.props.dataset.access}
        name={this.props.dataset.name}
        type={this.props.dataset.type}
        uri={this.props.dataset.uri}
        canShare={this.props.dataset.can_share}
        shareable={this.props.dataset.shareable}
        defaultTable={this.props.dataset.default_table}
        goTo={this.props.goTo}
      />
    );
  }

  render() {
    return (
      <div className={"my-data-v2-datasets-card mt-3 mb-3"}>
        <DatasetCardContainer
          style={this.props.style}
          ref={this.DOMRef}
          isSelected={this.state.isSelected}
          access={this.props.dataset.access}
          goToDataset={() =>
            this.props.goTo({
              route: routes.Dataset.View,
              params: {
                uriDataset: this.props.dataset.uri
              },
              state: {
                dataset: {
                  uri: this.props.dataset.uri,
                  name: this.props.dataset.name
                }
              }
            })
          }
          dataset={{
            key: Math.random(),
            uri: this.props.dataset.uri,
            name: this.props.dataset.name
          }}
        >
          <div className="card-dataset-header">
            <div className="card-dataset-begin">
              <div className="card-dataset-begin-content">
                <BookmarkStar
                  datasetUri={this.props.dataset.uri}
                  isBookmarked={this.props.dataset.is_bookmarked}
                  onChange={(value) => {
                    this.setState((prevState) => ({
                      nb_bookmarks: prevState.nb_bookmarks + value
                    }));
                    this.props.reloadBookmarks();
                  }}
                />
              </div>
              <div className="card-dataset-begin-content">
                <i className="far fa-suitcase" />
                <span className="text-header-dataset">
                  {findDomainName(this.props.dataset.domain)}
                </span>
              </div>
            </div>
            <div className="card-dataset-end">
              <span className="text-type-dataset">
                {this.props.dataset.type} Data
              </span>
              <div
                className={`dataset-card-environment ${
                  classEnvironment[this.props.dataset.environment]
                }`}
              >
                <span>{this.props.dataset.environment}</span>
              </div>
              <DatasetCardShared access={this.props.dataset.access} />
            </div>
          </div>
          <div className="card-dataset-body">
            <div className="card-dataset-body-left">
              <div>
                <DatasetCardTitle
                  name={this.props.dataset.name}
                  uri={this.props.dataset.uri}
                  access={this.props.dataset.access}
                  defaultTable={this.props.dataset.default_table}
                  type={this.props.dataset.type}
                  size={25}
                />
                <DatasetCardPublisher
                  publisher={this.props.dataset.owner.account.name}
                  ownergroupname={this.props.dataset.owner.name}
                  size={200}
                />
                <div className="card-size-details">
                  <p className="card-size-updateDate">
                    <span className="font-weight-bold">
                      Last Data Update :{" "}
                    </span>
                    {formatDate(this.props.dataset.s3_inventory_last_update)} |{" "}
                    <span className="font-weight-bold">Size : </span>
                    {convertSize(this.props.dataset.size)}
                  </p>
                </div>
              </div>
              <div className="card-dataset-details">
                <p className="card-dataset-description">
                  {this.props.dataset.description}
                </p>
              </div>
              <div>
                <div className="card-dataset-tags">
                  <div className="">
                    {this.props.dataset.tags
                      .split(",")
                      .filter((tag) => tag && tag.trim())
                      .map((tag) => (
                        <div className="dataset-badge" key={tag}>
                          <div className="circle-tag" />
                          {tag}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {this.props.dataset.access !== "other" && (
              <div className="card-dataset-body-right">
                <div className="actionIcon tooltiped">
                  <Links.Dataset.AddToCart
                    uriDataset={this.props.dataset.uri}
                    state={{
                      dataset: {
                        uri: this.props.dataset.uri,
                        name: this.props.dataset.name
                      }
                    }}
                  >
                    <div className="butn butn-flat min-width-130">
                      <i className="fas fa-plus butn-icon"></i>
                      <span className="butn-text">Add to project</span>
                    </div>
                  </Links.Dataset.AddToCart>
                </div>
              </div>
            )}
            {this.props.dataset.access === "other" && (
              <div className="card-dataset-body-right">
                <div className="actionIcon tooltiped">
                  <Links.Share.Request
                    uriDataset={this.props.dataset.uri}
                    state={{
                      dataset: {
                        uri: this.props.dataset.uri,
                        name: this.props.dataset.name
                      }
                    }}
                  >
                    <div className="butn butn-flat min-width-130">
                      <i className="fas fa-lock-open butn-icon"></i>
                      <span className="butn-text">Request Access</span>
                    </div>
                  </Links.Share.Request>
                </div>
              </div>
            )}
          </div>
          <div className="card-dataset-footer">
            <div className="card-dataset-begin">
              <div className="card-dataset-begin-content d-flex">
                <i className="fas fa-database"></i>
                <span className="text-header-dataset">
                  {this.props.dataset.datasource.name < 20
                    ? this.props.dataset.datasource.name
                    : `${this.props.dataset.datasource.name.substring(
                        0,
                        20
                      )}...`}
                </span>
              </div>
            </div>
            <div className="card-dataset-end">
              <div className="text-footer-metrics">
                Seen&nbsp;
                <span className="tag-metrics">
                  {this.props.dataset.nb_views === null
                    ? "0"
                    : this.props.dataset.nb_views}
                </span>
                , Shared with&nbsp;
                <span className="tag-metrics">
                  {this.props.dataset.metric.statistic.nb_shares === null
                    ? "0"
                    : this.props.dataset.metric.statistic.nb_shares}
                </span>
                &nbsp;groups, in&nbsp;
                <span className="tag-metrics">
                  {this.props.dataset.metric.statistic.nb_carts === null
                    ? "0"
                    : this.props.dataset.metric.statistic.nb_carts}
                </span>
                &nbsp;projects&nbsp;
                <NbBookmarksStar nb={this.state.nb_bookmarks} />
              </div>
            </div>
          </div>
        </DatasetCardContainer>
      </div>
    );
  }
}

export default withErrorBoundary(withGoTo(MyDataDatasetCard), false);
