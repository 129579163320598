/* @flow */

export const listGroupTrusts = {
  query: `query Q ($accountUri: String!, $options: GroupTrustSearch) {
        listAccountGroupTrusts(accountUri: $accountUri, options: $options) {
            total
            results {
                is_admin
                trusts {
                    arn
                    type
                    id
                    description
                    group {
                        name
                        uri
                    }
                    platform {
                        name
                        uri
                        aws
                    }
                    type
                    is_admin
                    nb_carts
                    status
                }
            }
        }
      }
    `,
  keyOutput: "listAccountGroupTrusts"
};

export type GroupTrustsSearchOptionsPropsTypes = {
  group_uri?: string,
  platform_uri?: string,
  trust_arn?: string,
  limit?: number,
  offset: number
};
