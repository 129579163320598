/* @flow */
import React from "react";
import Tabs from "../../../components/tabs";
import LakeFormationMigrations from "./components/lakeformationMigrations";
import LakeFormationRegistrations from "./components/lakeformationRegistrations";

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  goTo: Function
};

class PlaygroundLakeFormation extends React.PureComponent<propTypes> {
  render() {
    return (
      <div>
        <Tabs
          tabs={[
            {
              name: <div>Migrations</div>,
              index: "migrations",
              content: (
                <LakeFormationMigrations
                  uriAccount={this.props.uriAccount}
                  uriPlayground={this.props.uriPlayground}
                  goTo={this.props.goTo}
                />
              )
            },
            {
              name: <div>Registrations</div>,
              index: "registrations",
              content: (
                <LakeFormationRegistrations
                  uriAccount={this.props.uriAccount}
                  uriPlayground={this.props.uriPlayground}
                  goTo={this.props.goTo}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}

export default PlaygroundLakeFormation;
