/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import withAppSync from "../../../../AppsyncHOC";
import routes from "../../../../routes";
import GraphQl from "../../../../../graphQL";
import Logger from "../../../../../utils/logger";
import { updateMessage } from "../../../../globalNotifications/actions";
import Modal from "../../../../../components/modal";
import { formatDate } from "../../../../../utils/date";
import withGoTo from "../../../../goToHOC";
import "./style.less";

const Log = Logger("SagemakerNotebookDetails");

type propTypes = {
  cart: Object,
  databrew: Object,
  api: GraphQl,
  showGlobalNotification: Function,
  reload: Function,
  goTo: Function
};

type stateTypes = {
  autorefreshing: boolean,
  error: Object,
  errorDelete: Object,
  loadingDeleteDatabrew: boolean,
  deleteDatabrewModal: boolean,
  databrewAccessPending: boolean,
  status: string,
  refreshing: boolean
};

class DatabrewDetails extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      autorefreshing: false,
      loadingDeleteDatabrew: false,
      deleteDatabrewModal: false,
      databrewAccessPending: false,
      refreshing: false,
      errorDelete: undefined,
      error: false,
      status: props.databrew.status
    };
  }

  componentDidMount() {
    if (
      ["Pending", "Stopping", "Updating"].includes(this.state.status) ||
      this.instanceIsNotUpToDate()
    ) {
      this.autoRefresh();
    }
  }

  instanceIsNotUpToDate = () => {
    const ONE_HOUR = 60 * 60 * 1000;
    return (
      new Date().getTime() - new Date(this.props.databrew.updatedat).getTime() <
      ONE_HOUR
    );
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true, error: false });

      this.props.api.cart
        .refreshDatabrewProjectsStatus(
          this.props.cart.uri,
          this.props.databrew.id
        )
        .then((response) => {
          this.setState({
            status: response,
            refreshing: false
          });
        })
        .catch((error) => {
          this.setState({
            refreshing: false,
            autorefreshing: false,
            error
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        });
    }
  };

  opendeleteDatabrewModal = () => this.setState({ deleteDatabrewModal: true });

  closedeleteDatabrewModal = () =>
    this.setState({ deleteDatabrewModal: false, errorDelete: undefined });

  deleteDatabrewModal = () => (
    <Modal
      title={`Delete the databrew project: ${this.props.databrew.name}`}
      body={"Are you sure ?"}
      errorMessage={this.state.errorDelete}
      actions={
        this.state.errorDelete
          ? [
              <button
                type="button"
                className="butn"
                onClick={this.closedeleteDatabrewModal}
              >
                Close
              </button>
            ]
          : [
              <button
                type="button"
                className="butn"
                onClick={this.closedeleteDatabrewModal}
              >
                Close
              </button>,
              <button
                type="button"
                className="butn butn-delete"
                onClick={this.deleteDatabrew}
              >
                Delete
              </button>
            ]
      }
    />
  );

  deleteDatabrew = () => {
    this.setState({ loadingDeleteDatabrew: true });

    return this.props.api.cart
      .deleteDatabrew(this.props.cart.uri, this.props.databrew.id)
      .then((response) => {
        Log.info("Databrew Project deleted:", response);
        this.props.showGlobalNotification({
          message: "Databrew Project deleted",
          type: "success"
        });
        this.setState({ loadingDeleteDatabrew: false });
        this.closedeleteDatabrewModal();
        this.props.reload();
      })
      .catch((errorDelete) => {
        this.props.showGlobalNotification({
          message: "Databrew Project deleting failed",
          type: "alert"
        });
        this.setState({
          loadingDeleteDatabrew: false,
          errorDelete
        });
      });
  };

  getDatabrewConsoleAccess = () => {
    this.setState({ databrewAccessPending: true, error: false });
    return this.props.api.cart
      .getDatabrewConsoleAccess(this.props.cart.uri, this.props.databrew.id)
      .then((credentials) => {
        if (credentials && credentials.ConsoleUrl)
          window.open(credentials.ConsoleUrl, "_blank");
        this.setState({
          databrewAccessPending: false
        });
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Databrew Project Console Access failed",
          type: "alert"
        });
        this.setState({
          databrewAccessPending: false,
          error
        });
      });
  };

  render() {
    const { databrew } = this.props;
    return (
      <li className="cdh-card card-shadow card bg-white card-databrew-details">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <i className="fas fa-book fa-spacing" />
              Databrew Project
            </div>
          </div>
          <div className="cdh-cart-end">
            <div
              className={`cart-environment-tag ${databrew.platform.environment}`}
            >
              <span>{databrew.platform.environment}</span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <span className="text-capitalize cdh-card-name">
              {this.props.databrew.name}
            </span>

            <div className="cdh-card-details">
              <p>
                <b>Environment:</b> {databrew.platform.name} (
                {databrew.platform.aws})
              </p>

              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>arn:</b> {databrew.arn}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>sampling type:</b> {databrew.sample_type}
                </span>
              </div>

              {databrew.sample_size && (
                <div className="cdh-card-detail">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    <b>sampling size:</b> {databrew.sample_size}
                  </span>
                </div>
              )}

              <div className="cdh-card-detail">
                <i className="fas fa-database" />
                <span className="content-card-bucket">
                  <b>dataset name:</b> {databrew.dataset.name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-layer-group" />
                <span className="content-card-bucket">
                  <b>stage name:</b> {databrew.dataset_output.name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>&nbsp;created:</b> {formatDate(databrew.createdat)}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div className="btn-cart" onClick={this.getDatabrewConsoleAccess}>
                {this.state.databrewAccessPending && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fab fa-aws" />
                <span className="text-actions"> Databrew Project Access</span>
              </div>

              <div
                className={"btn-cart"}
                onClick={() =>
                  this.props.goTo({
                    route: routes.Cart.EditDatabrewProject,
                    params: {
                      cartUri: this.props.cart.uri,
                      databrewId: this.props.databrew.id
                    },
                    state: {
                      databrew: this.props.databrew
                    }
                  })
                }
              >
                <i className="fas fa-pencil-alt fa-spacing" />
                <span className="text-actions">Edit</span>
              </div>

              <div
                className="btn-cart"
                onClick={() => this.opendeleteDatabrewModal()}
              >
                {this.state.loadingDeleteDatabrew && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <i className="far fa-calendar-alt fa-spacing" />
            <span className="text-footer-left">
              last update: {formatDate(databrew.updatedat)}
            </span>
          </div>

          <div className="cdh-card-end">
            <button
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.refreshStatus}
            >
              {this.state.refreshing || this.state.autorefreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
            <div className="text-footer-metrics">
              Status
              <span
                className={classnames("tag-metrics", {
                  "green-status":
                    this.state.status === "READY" ||
                    this.state.status === "ASSIGNED",
                  "warning-status": this.state.status === "FAILED"
                })}
              >
                {this.state.status}
              </span>
            </div>
          </div>
        </div>
        {this.state.error && (
          <Alert severity="error">{this.state.error.message}</Alert>
        )}
        <div>
          {this.state.deleteDatabrewModal && this.deleteDatabrewModal()}
        </div>
      </li>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatabrewDetails)));
