/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import "./style.less";
import { updateMessage } from "../../../globalNotifications/actions";
import Logger from "../../../../utils/logger";

const Log = Logger("BookmarkStar");

type propTypes = {
  api: GraphQl,
  datasetUri: string,
  isBookmarked: boolean,
  showGlobalNotification: Function,
  onChange: Function
};

type stateTypes = {
  isFetching: boolean,
  isBookmarked: boolean
};

class BookmarkStar extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isBookmarked: props.isBookmarked
    };
  }

  handleBookmarkStarClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { isBookmarked } = this.state;
    this.setState((prevState) => ({
      isFetching: true,
      isBookmarked: !prevState.isBookmarked
    }));
    if (!isBookmarked) {
      return this.props.api.dataset
        .addToBookmark(this.props.datasetUri)
        .then(() => {
          this.setState({ isFetching: false });
          this.props.onChange(1);
          this.props.showGlobalNotification({
            message: "Dataset added to bookmarks.",
            type: "success"
          });
        })
        .catch((error) => {
          this.setState({ isFetching: false, isBookmarked: false });
          Log.error(error);
          this.props.showGlobalNotification({
            message: "Bookmark creation failed",
            type: "alert"
          });
        });
    }
    return this.props.api.dataset
      .removeFromBookmark(this.props.datasetUri)
      .then(() => {
        this.setState({ isFetching: false });
        this.props.onChange(-1);
        this.props.showGlobalNotification({
          message: "Dataset removed from bookmarks.",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({ isFetching: false, isBookmarked: true });
        Log.error(error);
        this.props.showGlobalNotification({
          message: "bookmark deletion failed.",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div
        className="dataset-bookmark-star"
        onClick={(e) =>
          !this.state.isFetching && this.handleBookmarkStarClick(e)
        }
      >
        <i
          className={classnames("bookmark-star far fa-star", {
            "is-bookmarked": this.state.isBookmarked
          })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(BookmarkStar));
