/* @flow */

import React from "react";
import ErrorContent from "./ErrorContent";
import ErrorTitle from "./ErrorTitle";
import ErrorComment from "./ErrorComment";
import { getErrorMessage } from "../../utils/error";
import ErrorFeedback from "./ErrorFeedback";

type propTypesStandardError = {
  title?: string,
  error: string | Object,
  comment?: string,
  hideComment?: boolean,
  sendFeedBack: Function,
  handleToggle: Function,
  isDetailsOpen?: boolean,
  rawErrorMessage?: boolean
};
const StandardError = ({
  title,
  error,
  comment,
  hideComment,
  sendFeedBack,
  isDetailsOpen,
  handleToggle,
  rawErrorMessage
}: propTypesStandardError) => (
  <div className={"ooops-error-standard bg-white border border-info"}>
    <div className={"alert"}>
      <ErrorTitle title={title} />
      <hr />
      {!hideComment && (
        <ErrorComment
          comment={comment || getErrorMessage(error)}
          rawErrorMessage={rawErrorMessage}
        />
      )}
      {process.env.NODE_ENV !== "production" && (
        <ErrorContent
          error={error}
          isDetailsOpen={isDetailsOpen}
          handleToggle={handleToggle}
        />
      )}
      <ErrorFeedback sendFeedBack={sendFeedBack} />
    </div>
  </div>
);

export default StandardError;
