export const listDssInstanceNotGroups = {
  query: `query Q ($dssInstanceUri:String!, $options: QueryOptions) {
        listDssInstanceNotGroups(dssInstanceUri:$dssInstanceUri, options: $options) {
            total
            offset
            limit
            results {
                name
                uri
                account {
                    uri
                    name
                }
            }
        }
      }
    `,
  keyOutput: "listDssInstanceNotGroups"
};
