import React from "react";
const CDHButton = ({
  disabled = false,
  btnTypeClass = "butn-create",
  faClass,
  onClick,
  title,
  type = "submit"
}) => (
  <button
    {...{
      className: `butn ${btnTypeClass}`,
      disabled,
      onClick,
      type
    }}
  >
    <i className={`fas ${faClass}`}></i>
    <span className="butn-text">{title}</span>
  </button>
);

export { CDHButton };
