export const listNetworkEnvironment = {
  query: `query Q ($platformUri:String!, $options:ListOptions) {
        listNetworkEnvironment(platformUri:$platformUri, options:$options) {
            offset
            limit
            total
            results {
                id
                name
                vpc_id
                security_group_ids
                subnet_ids
                region
                description
                createdat
                updatedat
                platform
                proxy
                noproxy
                vpc_id
                network_type
                sagemakerNotebookInstances {
                    total
                    results {
                        id
                        name
                    }
                }
            }
        }
      }
    `,
  keyOutput: "listNetworkEnvironment"
};
