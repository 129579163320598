import React from "react";
import PropTypes from "prop-types";
import Row from "../../../components/row";
import Col from "../../../components/col";
import withAppSync from "../../AppsyncHOC/index";
import DatasetAnalyse from "./datasetAnalyze";
import DatasourceAnalyse from "./datasourceAnalyze";
import GroupAnalyse from "./groupAnalyze";

const GlobalAnalyse = ({ api, match }) => (
  <React.Fragment>
    <Row>
      <Col className={"mt-3"} size={7}>
        <h4 className={"text-primary"}>Account Statistics</h4>
      </Col>
    </Row>

    <DatasetAnalyse api={api} uriAccount={match.params.uriAccount} />
    <DatasourceAnalyse api={api} uriAccount={match.params.uriAccount} />
    <GroupAnalyse api={api} uriAccount={match.params.uriAccount} />
  </React.Fragment>
);

GlobalAnalyse.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uriAccount: PropTypes.string
    })
  }),
  api: PropTypes.object
};

export default withAppSync(GlobalAnalyse);
