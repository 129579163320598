/* @flow */

import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";
import Row from "../../../components/row";
import Col from "../../../components/col";
import Routes from "../../routes";
import { updateMessage } from "../../globalNotifications/actions";
import Breadcrumb from "../../../components/breadcrumb";
import { haveSpecialCharacters } from "../../../utils/string";
import TagsDatasourceFields from "../../datasources/components/form/TagsDatasourceFields";
import GraphQl from "../../../graphQL";

export const RETENTION_POLICIES = [
  1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 365, 400, 545, 731, 1827, 2192,
  2557, 2922, 3288, 3653
];

const Log = Logger("AddLogGroup");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      cart: Object
    }
  }
};

type stateTypes = {
  cart: Object,
  editionDisabled: boolean,
  availablePlatforms: [Object],
  availablePlatforms: [Object],
  formValues: Object
};

class AddLogGroup extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    const { location } = props;
    const cart = (location.state && location.state.cart) || {};
    const platforms = cart.platforms || [];
    this.state = {
      cart,
      editionDisabled: false,
      availablePlatforms: platforms,
      formValues: {
        name: "",
        platformUri: platforms[0] ? platforms[0].uri : "",
        description: "",
        tags: [], // [{ Key, Value }]
        retentionPolicy: 3
      }
    };
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const formValues = { ...prevState.formValues };
      formValues[field] = value;
      return { formValues };
    });
  }

  save(setSubmitting) {
    setSubmitting(true);
    const { formValues } = this.state;
    const { name, platformUri, description, tags, retentionPolicy } =
      formValues;

    return this.props.api.logGroups
      .createLogsGroup(this.state.cart.uri, platformUri, {
        name,
        description,
        tags,
        retention_policy: retentionPolicy
      })
      .then(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Log group created",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-logs-groups`,
          params: { cartUri: this.state.cart.uri }
        });
      })
      .catch(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Log group creation failed",
          type: "alert"
        });
      });
  }

  componentDidMount = () => {
    // this.setState({ editionDisabled: !!this.props.location.state.endpoint });
  };

  render() {
    const { formValues } = this.state;
    return (
      <div className="add-logs-group">
        <Breadcrumb view={"Create a new Logs Group"} />
        <Row>
          <Col size={1} />
          <Col className={"my-5"} size={9}>
            <Formik
              initialValues={{
                name: formValues.name,
                platformUri: formValues.platformUri,
                description: formValues.description
              }}
              validate={() => {
                const errors = {};
                if (!formValues.name) {
                  errors.name = "A name is mandatory";
                }
                if (haveSpecialCharacters(formValues.name)) {
                  errors.name =
                    "Name must not contain these special characters ( & / ' ; )";
                }
                if (!formValues.platformUri) {
                  errors.platformUri = "A platform uri is mandatory";
                }
                Log.info("validate", formValues, errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <Field
                        type="text"
                        name="name"
                        className={
                          !this.state.editionDisabled
                            ? "form-control  bg-white"
                            : "form-control  bg-grey-smooth"
                        }
                        placeholder="Name..."
                        onChange={(event) =>
                          this.handleChange("name", event.target.value)
                        }
                        value={formValues.name}
                        disabled={this.state.editionDisabled}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <div className="row">
                      <fieldset className="col form-group">
                        <legend className="label-form">
                          Environement URI:
                        </legend>
                        <select
                          className="form-control form-control-sm"
                          onChange={(event) =>
                            this.handleChange("platformUri", event.target.value)
                          }
                          disabled={this.state.editionDisabled}
                          defaultValue={this.state.formValues.platformUri}
                        >
                          {this.state.availablePlatforms.map(
                            ({ name, uri, environment }) => (
                              <option key={uri} value={uri}>
                                {name} ({environment})
                              </option>
                            )
                          )}
                        </select>
                      </fieldset>

                      <fieldset className="col form-group">
                        <legend className="label-form">
                          Retention policy (days):
                        </legend>
                        <select
                          className="form-control form-control-sm"
                          disabled={this.state.editionDisabled}
                          defaultValue={this.state.formValues.retentionPolicy}
                          onChange={(event) =>
                            this.handleChange(
                              "retentionPolicy",
                              event.target.value
                            )
                          }
                        >
                          {RETENTION_POLICIES.map((i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="retentionPolicy"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>
                    </div>

                    <fieldset className="form-group">
                      <legend className="label-form">Description:</legend>
                      <Field
                        component="textarea"
                        name="description"
                        className="form-control bg-white"
                        placeholder="Description (optional) ..."
                        onChange={(event) =>
                          this.handleChange("description", event.target.value)
                        }
                        value={formValues.description}
                        rows={"5"}
                        disabled={this.state.editionDisabled}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group mb-3">
                      <legend className="label-form">Tags</legend>
                      <div
                        style={{
                          border: "1px solid #e5ecf7",
                          borderRadius: "4px",
                          padding: "20px"
                        }}
                      >
                        <TagsDatasourceFields
                          tags={[{ Key: "", Value: "" }]}
                          customField="tags"
                          // onChangeTags={(field, value) => handleChange(field, value)}
                          onChangeTags={(field, tags) =>
                            this.handleChange("tags", tags)
                          }
                          isDisabled={this.state.editionDisabled}
                        />
                      </div>
                    </fieldset>

                    <div className={"ml-1 row justify-content-end pt-4 mr-1"}>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="butn"
                      >
                        {isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Create log group
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppsync(AddLogGroup));
