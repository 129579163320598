/* @flow */

import { listAccounts } from "./queryListAccounts";
import { listAllAccounts } from "./queryListAllAccounts";
import { listAllGbus } from "./queryListAllGbus";
import { listIdentities } from "./queryListAccountIdentities";
import { listGroups } from "./queryListAccountGroups";
import { listPlatforms } from "./queryListAccountPlatforms";
import { listPolicies } from "./queryListPolicies";
import { countDatasetCreatedPerDay } from "./queryCountDatasetCreatedPerDay";
import { countDatasourceCreatedPerDay } from "./queryCountDatasourceCreatedPerDay";
import { countGroupCreatedPerDay } from "./querycountGroupCreatedPerDay";
import { createAccount } from "./mutationCreateAccount";
import { updateAccount } from "./mutationUpdateAccount";
import { getAccount, type GetAccountPropsTypes } from "./queryGetAccount";
import { deleteAccount } from "./mutationDeleteAccount";
import type { QueryOptions } from "../propstypes";
import { getAccountDatasources } from "./queryGetAccountDatasources";
import { listAccountsAndPlatforms } from "./queryListAccountAndPlatforms";
import { listGroupsForSharingGroup } from "./queryListAccountGroupsForSharingGroup";
import type { listAccountsAndPlatformsPropsTypes } from "./queryListAccountAndPlatforms";
import { createTrust } from "./mutationCreateTrust";
import { listTrusts } from "./queryListTrusts";
import { deleteTrust } from "./mutationDeleteTrust";
import { listGroupTrusts } from "./queryListGroupTrusts";
import type { listAccountsPropsTypes } from "./queryListAccounts";
import { listPlatformGroups } from "./queryListPlatformGroups";
import { listAccountDssInstances } from "./queryListAccountDssInstances";

type NewAccountInputPropsTypes = {
  name: string,
  resource_name: string,
  user_name: string,
  user_email: string,
  user_gid: string
};

type UpdateAccountInputPropsTypes = {
  name: string
};

type PoliciesSearchOptionsPropsTypes = {
  group_name?: string,
  platform_uri?: string,
  policyarn?: string,
  description?: string,
  nb_projects?: number,
  invert_group_relation: boolean
};

type GroupTrustsSearchOptionsPropsTypes = {
  group_uri?: string,
  platform_uri?: string,
  trust_arn?: string
};

type NewTrustInputPropsTypes = {
  arn: string,
  description?: string,
  platform_uri: string,
  type: string,
  is_admin: string
};

class AccountApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  get(accountUri: string): Promise<GetAccountPropsTypes> {
    return this.fetchAppSync(getAccount, { accountUri });
  }

  getAccounts(): Promise<Array<listAccountsPropsTypes>> {
    return this.fetchAppSync(listAccounts);
  }

  listAccountsAndPlatforms(): Promise<listAccountsAndPlatformsPropsTypes> {
    return this.fetchAppSync(listAccountsAndPlatforms);
  }

  getAllAccounts() {
    return this.fetchAppSync(listAllAccounts);
  }

  getAccountDatasources(accountUri: string, options?: QueryOptions) {
    return this.fetchAppSync(
      getAccountDatasources,
      { accountUri, options },
      true
    );
  }

  listAllGbus() {
    return this.fetchAppSync(listAllGbus);
  }

  listIdentities(accountUri: string, options: QueryOptions) {
    return this.fetchAppSync(listIdentities, { accountUri, options }, true);
  }

  listGroups(accountUri: string, options?: QueryOptions) {
    return this.fetchAppSync(listGroups, { accountUri, options }, true);
  }

  listPlatformGroups(platformUri: string, options?: QueryOptions) {
    return this.fetchAppSync(
      listPlatformGroups,
      { platformUri, options },
      true
    );
  }

  listGroupsForSharingGroup(accountUri: string, options?: QueryOptions) {
    return this.fetchAppSync(
      listGroupsForSharingGroup,
      { accountUri, options },
      true
    );
  }

  listPlatforms(accountUri: string, options: QueryOptions) {
    return this.fetchAppSync(listPlatforms, { accountUri, options });
  }

  listPolicies(accountUri: string, options?: PoliciesSearchOptionsPropsTypes) {
    return this.fetchAppSync(listPolicies, { accountUri, options }, true);
  }

  createTrust(accountUri: string, input: NewTrustInputPropsTypes) {
    return this.fetchAppSync(createTrust, { accountUri, input });
  }

  deleteTrust(trustId: string) {
    return this.fetchAppSync(deleteTrust, { trustId });
  }

  listTrusts(accountUri: string, options?: QueryOptions) {
    return this.fetchAppSync(listTrusts, { accountUri, options });
  }

  listGroupTrusts(
    accountUri: string,
    options?: GroupTrustsSearchOptionsPropsTypes
  ) {
    return this.fetchAppSync(listGroupTrusts, { accountUri, options });
  }

  countDatasetCreatedPerDay(accountUri: string) {
    return this.fetchAppSync(countDatasetCreatedPerDay, { accountUri });
  }

  countDatasourceCreatedPerDay(accountUri: string) {
    return this.fetchAppSync(countDatasourceCreatedPerDay, { accountUri });
  }

  countGroupCreatedPerDay(accountUri: string) {
    return this.fetchAppSync(countGroupCreatedPerDay, { accountUri });
  }

  create(newAccountInput: NewAccountInputPropsTypes) {
    return this.fetchAppSync(createAccount, { newAccountInput });
  }

  update(accountUri: string, updateAccountInput: UpdateAccountInputPropsTypes) {
    return this.fetchAppSync(updateAccount, { accountUri, updateAccountInput });
  }

  delete(accountUri: string) {
    return this.fetchAppSync(deleteAccount, { accountUri });
  }

  listDssInstances(accountUri: string, options: QueryOptions) {
    return this.fetchAppSync(listAccountDssInstances, { accountUri, options });
  }
}

export default AccountApi;
