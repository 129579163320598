/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./view.less";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import Modal from "../../../components/modal";
import withAppSync from "../../AppsyncHOC";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  share: {
    datasource: {
      uri: string
    },
    group: {
      uri: string
    },
    are_new_datasets_shared: boolean
  },
  api: GraphQl,
  onDelete: Function,
  hideModal: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: ?string,
  isFetching: boolean
};

class DatasourceShareModelDelete extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: undefined,
      isFetching: false
    };
  }

  deleteDatasourceShare = () => {
    this.setState({ error: undefined, isFetching: true });
    this.props.api.datasource
      .deleteShareDatasource(
        this.props.share.datasource.uri,
        this.props.share.group.uri
      )
      .then(() => {
        this.setState({ isFetching: true });
        this.props.showGlobalNotification({
          message: "Revoke succeeded",
          type: "success"
        });
      })
      .then(this.props.onDelete)
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Revoke failed",
          type: "alert"
        });
        this.setState({ error, isFetching: false });
      });
  };

  render() {
    return (
      <Modal
        title={"Revoke Datasource Share"}
        body={"Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key={`delete-datasource-share-close-${this.props.share.datasource.uri}`}
            label="Close"
            className="butn butn-flat"
            onClick={this.props.hideModal}
          />,
          <ButtonAction
            key={`delete-datasource-share-delete-${this.props.share.datasource.uri}`}
            label="Revoke"
            className="butn butn-delete"
            onClick={this.deleteDatasourceShare}
            icon={
              this.state.isFetching ? "fas fa-circle-notch fa-spin" : undefined
            }
          />
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasourceShareModelDelete)
);
