/* @flow */
export const createRSNamespace = {
  query: `mutation Q ($platformUri: String!, $input: NewRSNamespaceInput!) {
        createRSNamespace(platformUri:$platformUri, input:$input) {
          uri
        }
      }
    `,
  keyOutput: "createRSNamespace"
};
