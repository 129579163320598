/* @flow */

export const search = {
  query: `query Q ($search: SearchInput) {
        searchDatasetResults(search: $search) {
            results {
                uri
                name
                createdat
                environment
                description
                domain
                tags
                type
                owner_group_name
                default_table
                access
                is_bookmarked
                default_table
                shareable
                account_name
                account_uri
                datasource_name
                datasource_uri
                nb_carts
                nb_shares
                nb_views
                nb_bookmarks
                updatedat
                size
                can_share
            }
            total
        }
      }
    `,
  keyOutput: "searchDatasetResults"
};

export type SearchPropsTypes = {
  results: Array<{
    uri: string,
    name: string,
    createdat: string,
    environment: string,
    description: string,
    domain: string,
    tags: string,
    type: string,
    owner_group_name: string,
    default_table: string,
    access: string,
    is_bookmarked: boolean,
    default_table: string,
    tags: string,
    account_name: string,
    account_uri: string,
    datasource_name: string,
    datasource_uri: string,
    nb_carts: number,
    nb_shares: number,
    nb_views: number,
    nb_bookmarks: number,
    updatedat: string,
    size: number
  }>,
  total: number
};
