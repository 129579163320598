export const getWorkspaceFromCart = {
  query: `query Q ($cartUri: String!) {
                getCart(cartUri: $cartUri) {
                dbx_workspace {
                dbx_workspace_name
                dbx_workspace_id
                uuid
                dbxGroups {
                    results {
                    dbxWorkspaces {
                        dbx_workspace_name
                        dbx_workspace_id
                    }
                    }
                }
                }
            }
            }
          `,
  keyOutput: "getWorkspaceFromCart"
};
