/* @flow */

import React from "react";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import Links from "../links";
import Loading from "../../components/loading";
import route from "../routes";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import GraphQl from "../../graphQL";
import withGoTo from "../goToHOC";
import Paginator from "../../components/paginator";
import "./list.less";
import SmStudioLogo from "../smstudio/smstudio-logo.png";

type propTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  showGlobalNotification: Function,
  goTo: Function,
  api: GraphQl,
  location: {
    state: {
      account: {
        name: string
      }
    }
  },
  auth: {
    getUser: Function
  }
};

type stateTypes = {
  ready: boolean,
  playgrounds: Array<Object>,
  error: boolean,
  keywords: string,
  total: number,
  showArchives: boolean,
  isEnablingAutoRefreshStages: boolean,
  isCurrentUserAdmin: boolean
};

class PlaygroundList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      playgrounds: [],
      error: false,
      total: 0,
      keywords: "",
      showArchives: false,
      isEnablingAutoRefreshStages: false,
      isCurrentUserAdmin: false
    };
  }

  currentUserIsAdmin = async () => {
    const me = await this.props.auth.getUser();
    const groups = await this.props.api.account.listGroups(
      this.props.match.params.uriAccount,
      { offset: 0, limit: 999 }
    );
    for (const group of groups.results.filter(
      (g) =>
        g.uri.includes("administratorsgroup") || g.uri.includes("_admin_group")
    )) {
      if (group.responsible && group.responsible.name === me.name) {
        return true;
      }
    }
    return false;
  };

  async componentDidMount() {
    this.isDetachForEquansFeatureEnabled();
    this.searchPlaygrounds();
    this.setState({
      isCurrentUserAdmin: await this.currentUserIsAdmin()
    });
  }

  isDetachForEquansFeatureEnabled() {
    return this.props.api.featureToggle
      .isFeatureToggleEnabled(
        "DETACH_FOR_EQUANS",
        this.props.match.params.uriAccount
      )
      .then((showArchives) => {
        this.setState({ showArchives });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords
        },
        this.searchPlaygrounds
      );
    }, 500);
  };

  searchPlaygrounds = (offset = 0) => {
    const options = {
      limit: 10,
      offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.account
      .listPlatforms(this.props.match.params.uriAccount, options)
      .then((items) => {
        if (!items) return false;
        this.setState({
          ready: true,
          playgrounds: items.results,
          total: items.total
        });
        return items;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getEnvironmentsFromPagination = (offset) => this.searchPlaygrounds(offset);

  enableAutoRefreshStages = async (playground) => {
    if (this.state.isEnablingAutoRefreshStages === true) {
      return;
    }
    this.setState({ isEnablingAutoRefreshStages: true });
    try {
      await this.props.api.playground.update(playground.uri, {
        name: playground.name,
        auto_refresh_stages: true
      });
      this.props.showGlobalNotification({
        message: "Auto refresh stages enabled",
        type: "success"
      });
    } catch (error) {
      this.setState({ error });
    }
    this.searchPlaygrounds();
    this.setState({ isEnablingAutoRefreshStages: false });
  };

  renderContent() {
    if (this.state.error)
      return <Error error={this.state.error} path="PlaygroundList" />;
    if (!this.state.ready) return <Loading message="Environment List" />;

    return (
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={0}
          totalCount={this.state.total}
          list={this.state.playgrounds}
          componentRender={(playground) => (
            <div
              key={playground.uri}
              className={"cart-card card-shadow card bg-white"}
            >
              <div className="card-datasource-header">
                <div className="card-datasource-begin">
                  <div className="card-datasource-begin-content">
                    <i className="fab fa-aws" />
                    <span className="text-header-datasource">
                      {playground.aws}
                    </span>
                  </div>
                </div>
                <div className="card-datasource-end">
                  <div
                    className={`cart-environment-tag ${playground.environment}`}
                  >
                    <span>{playground.environment}</span>
                  </div>
                </div>
              </div>
              <div className="card-datasource-body">
                <div className="card-environment-body-left">
                  <div className={"text-capitalize datasource-card-name"}>
                    <Links.Playground.View
                      uriAccount={this.props.match.params.uriAccount}
                      uriPlayground={playground.uri}
                    >
                      {playground.name}
                    </Links.Playground.View>
                  </div>
                  <div className="card-datasource-details">
                    {/* { JSON.stringify(playground) } */}
                    <p className="card-cart-description">
                      {playground.description &&
                      playground.description.length > 250
                        ? `${playground.description.substring(0, 250)}...`
                        : playground.description}
                    </p>
                    {playground.auto_refresh_stages === false &&
                    this.state.isCurrentUserAdmin ? (
                      <div>
                        <Alert
                          severity="info"
                          action={
                            <button
                              type={"button"}
                              className="butn butn-flat"
                              style={{ margin: "5px" }}
                              onClick={() =>
                                this.enableAutoRefreshStages(playground)
                              }
                            >
                              {this.state.isEnablingAutoRefreshStages && (
                                <i className="fas fa-circle-notch fa-spin fa-spacing" />
                              )}
                              Enable
                            </button>
                          }
                        >
                          Automated discovery of your dataset's Glue tables is
                          available. Enable it now !
                        </Alert>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="card-environment-body-right">
                  <div className="card-datasource-body-actions">
                    <div className="btn-cart">
                      <Links.Playground.View
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                      >
                        <i className="fas fa-play fa-spacing" />
                        <span className="text-actions">View Details</span>
                      </Links.Playground.View>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.Groups
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-users-cog fa-spacing" />
                        <span className="text-actions">Manage Groups</span>
                      </Links.Playground.Groups>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.Network
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-network-wired fa-spacing" />
                        <span className="text-actions">Network</span>
                      </Links.Playground.Network>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.NotebooksAdmin
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-book fa-spacing" />
                        <span className="text-actions">Notebooks</span>
                      </Links.Playground.NotebooksAdmin>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.Analytics
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-chart-bar fa-spacing" />
                        <span className="text-actions">Analytics</span>
                      </Links.Playground.Analytics>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.LakeFormation
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-water fa-spacing" />
                        <span className="text-actions">Lake Formation</span>
                      </Links.Playground.LakeFormation>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.AccelerationTemplate
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-rocket fa-spacing" />
                        <span className="text-actions">
                          Acceleration Template
                        </span>
                      </Links.Playground.AccelerationTemplate>
                    </div>
                    {this.state.showArchives && (
                      <div className="btn-cart">
                        <Links.Playground.Archives
                          uriAccount={this.props.match.params.uriAccount}
                          uriPlayground={playground.uri}
                          state={{ playground }}
                        >
                          <i className="fas fa-archive fa-spacing" />
                          <span className="text-actions">
                            Archives<span className="equans-card">EQUANS</span>
                          </span>
                        </Links.Playground.Archives>
                      </div>
                    )}
                    <div className="btn-cart">
                      <Links.Playground.SmStudio
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <img
                          className="connector-logo-img ml-1 mr-1"
                          src={SmStudioLogo}
                          alt="Sagemaker Studio"
                          width={14}
                          height={14}
                        />
                        <span className="text-actions">
                          Sagemaker Studio
                          <span className="smstudio-beta-flag">BETA</span>
                        </span>
                      </Links.Playground.SmStudio>
                    </div>
                    <div className="btn-cart">
                      <Links.Playground.DbxWorkspace
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={playground.uri}
                        state={{ playground }}
                      >
                        <i className="fas fa-briefcase fa-spacing" />
                        <span className="text-actions">
                          Databricks Workspace
                        </span>
                      </Links.Playground.DbxWorkspace>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          loadPage={this.getEnvironmentsFromPagination}
          onNoResult={() => (
            <p className="total-search-results">No environments found</p>
          )}
        />
      </div>
    );
  }

  render = () => (
    <div className="organizations-list">
      <div className="header">
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search Environment !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={() =>
                  this.props.goTo({
                    route: route.Playground.Create,
                    params: {
                      uriAccount: this.props.match.params.uriAccount
                    }
                  })
                }
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New environment</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.renderContent()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(PlaygroundList)));
