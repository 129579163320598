/* @flow */
import React from "react";
import GraphQl from "../../../graphQL";
import SelectClassic from "../../../components/SelectClassic";
import withAppSync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";

const Log = Logger("FilterByNotificationTypes");

type propTypes = {
  api: GraphQl,
  onFilter: Function
};

type stateTypes = {
  ready: boolean,
  notificationsTypes: Array<Object>
};

class FilterByNewtificationTypes extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      notificationsTypes: []
    };
  }

  componentDidMount(): void {
    this.props.api.newtification
      .listMyNotificationsTypes()
      .then((notificationsTypes) => {
        this.setState({
          ready: true,
          notificationsTypes: notificationsTypes.map((n) => ({
            label: n.name,
            value: n.id
          }))
        });
      })
      .catch((error) => {
        Log.error(error);
      });
  }

  render() {
    return (
      <SelectClassic
        isClearable
        isLoadingOptions={!this.state.ready}
        placeholder="Filter by type"
        options={this.state.notificationsTypes}
        onSelectOption={(options) =>
          this.props.onFilter(options ? options.value : undefined)
        }
      />
    );
  }
}

export default withAppSync(FilterByNewtificationTypes);
