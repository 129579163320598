export const listAccelerationTemplates = {
  query: `query Q {
        listAccelerationTemplates {
            id
            name
            description
            version
            link_to_doc
        }
      }
    `,
  keyOutput: "listAccelerationTemplates"
};
