/* @flow */

import { queryListMySharesRequests } from "./queryListMySharesRequests";
import { queryListMyManagedShares } from "./queryListMyManagedShares";
import { createRequest } from "./mutationRequest";
import { acceptRequest } from "./mutationAcceptRequest";
import { rejectRequest } from "./mutationRejectRequest";
import { revokeShare } from "./mutationRevokeShare";
import { shareDataset } from "./mutationOwnersShare";
import { resendShareRequest } from "./mutationResendRequest";
import { queryListGroupReceivedSharingManagement } from "./queryListGroupReceivedSharingManagement";
import { queryListGroupReceivedRequester } from "./queryListGroupReceivedRequester";
import { queryListGroupSentSharingManagement } from "./queryListGroupSentSharingManagement";
import { queryListGroupSentRequester } from "./queryListGroupSentRequester";
import { queryGetShare } from "./queryGetShare";
import { mutationAddShareItem } from "./mutationAddShareItem";
import { mutationAddAllDatasetShareItems } from "./mutationAddAllDatasetShareItems";
import { mutationRemoveAllDatasetShareItems } from "./mutationRemoveAllDatasetShareItems";
import { mutationRemoveShareItem } from "./mutationRemoveShareItem";
import { queryListShareItems } from "./queryListShareItems";

class ShareApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  listMySharesRequests(options: Object) {
    return this.fetchAppSync(queryListMySharesRequests, { options });
  }

  getGroupsSharingManagementReceived() {
    return this.fetchAppSync(queryListGroupReceivedSharingManagement, {});
  }

  getGroupsRequesterReceived() {
    return this.fetchAppSync(queryListGroupReceivedRequester, {});
  }

  getGroupsSharingManagementSent() {
    return this.fetchAppSync(queryListGroupSentSharingManagement, {});
  }

  getGroupsRequesterSent() {
    return this.fetchAppSync(queryListGroupSentRequester, {});
  }

  listMyManagedShares(options: Object) {
    return this.fetchAppSync(queryListMyManagedShares, { options });
  }

  createRequest(datasetUri: string, groupUri: string, message: string) {
    return this.fetchAppSync(createRequest, {
      datasetUri,
      groupUri,
      message
    });
  }

  resendShareRequest(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(resendShareRequest, {
      datasetUri,
      groupUri
    });
  }

  acceptRequest(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(acceptRequest, { datasetUri, groupUri });
  }

  rejectRequest(datasetUri: string, groupUri: string, rejectMessage: string) {
    return this.fetchAppSync(rejectRequest, {
      datasetUri,
      groupUri,
      rejectMessage
    });
  }

  revokeShare(datasetUri: string, groupUri: string, rejectMessage: string) {
    return this.fetchAppSync(revokeShare, {
      datasetUri,
      groupUri,
      rejectMessage
    });
  }

  shareDataset(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(shareDataset, {
      datasetUri,
      groupUri
    });
  }

  getShare(shareId: string) {
    return this.fetchAppSync(queryGetShare, { shareId });
  }

  addShareItem(datasetUri: string, groupUri: string, input: Object) {
    return this.fetchAppSync(mutationAddShareItem, {
      datasetUri,
      groupUri,
      input
    });
  }

  addAllDatasetShareItems(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(mutationAddAllDatasetShareItems, {
      datasetUri,
      groupUri
    });
  }

  removeAllDatasetShareItems(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(mutationRemoveAllDatasetShareItems, {
      datasetUri,
      groupUri
    });
  }

  removeShareItem(datasetUri: string, groupUri: string, shareItemId: string) {
    return this.fetchAppSync(mutationRemoveShareItem, {
      datasetUri,
      groupUri,
      shareItemId
    });
  }

  listShareItems(datasetUri: string, groupUri: string) {
    return this.fetchAppSync(queryListShareItems, {
      datasetUri,
      groupUri
    });
  }
}

export default ShareApi;
