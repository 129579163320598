/* @flow */

import React from "react";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import withGoTo from "../../goToHOC";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import "./style.less";
import Logger from "../../../utils/logger";
import { formatDate } from "../../../utils/date";
import { getErrorMessage } from "../../../utils/error";
import DeleteObjectWithFrictionModal from "../../../components/DeleteObjectWithFrictionModal";
import SmStudioLogo from "../smstudio-logo.png";
import GraphQl from "../../../graphQL";

const Log = Logger("CartUserProfile");

type propTypes = {
  cart: Object,
  userProfile: Object,
  api: GraphQl,
  showGlobalNotification: Function,
  reload: Function
};

type stateTypes = {
  autorefreshing: boolean,
  error: Object,
  loadingGetUrlInstance: boolean,
  loadingDeleteInstance: boolean,
  deleteInstanceModal: boolean,
  refreshing: boolean,
  userProfile: Object
};

class CartUserProfile extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      userProfile: props.userProfile,
      autorefreshing: false,
      error: null,
      loadingGetUrlInstance: false,
      loadingDeleteInstance: false,
      deleteInstanceModal: false,
      refreshing: false
    };
  }

  componentDidMount() {
    if (
      ["Pending", "Stopping", "Updating"].includes(
        this.state.userProfile.status
      ) ||
      this.instanceIsNotUpToDate()
    ) {
      this.autoRefresh();
    }
  }

  instanceIsNotUpToDate = () => {
    const ONE_HOUR = 60 * 60 * 1000;
    return (
      new Date().getTime() -
        new Date(this.props.userProfile.updatedat).getTime() <
      ONE_HOUR
    );
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true });

      this.props.api.smstudio
        .getUserProfile(this.props.cart.uri, this.props.userProfile.id)
        .then((userProfile) => {
          this.setState({
            userProfile,
            refreshing: false
          });
          if (
            ["Pending", "Stopping", "Updating"].includes(userProfile.status)
          ) {
            this.autoRefresh();
          } else {
            if (this.interval) {
              clearInterval(this.interval);
              this.interval = undefined;
            }
            this.setState({ autorefreshing: false });
          }
        })
        .catch((error) => {
          this.setState({
            refreshing: false,
            autorefreshing: false,
            error
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        });
    }
  };

  deleteUserProfile = (deleteFromAws) => {
    this.setState({ loadingDeleteInstance: true });

    return this.props.api.smstudio
      .deleteUserProfile(
        this.props.cart.uri,
        this.props.userProfile.id,
        deleteFromAws
      )
      .then((response) => {
        Log.info(
          "Sagemaker Studio user profile scheduled for deletion",
          response
        );
        this.props.showGlobalNotification({
          message: "Sagemaker Studio user profile scheduled for deletion",
          type: "success"
        });
        this.setState({
          loadingDeleteInstance: false,
          deleteInstanceModal: false
        });
        this.props.reload();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Studio user profile deletion failed",
          type: "alert"
        });
        this.setState({
          loadingDeleteInstance: false,
          error
        });
      });
  };

  getUrl = () => {
    this.setState({ loadingGetUrlInstance: true });
    return this.props.api.smstudio
      .getPresignedUrl(this.props.cart.uri, this.props.userProfile.id)
      .then((url) => {
        Log.info("Sagemaker Notebook getUrl:", url);
        this.props.showGlobalNotification({
          message:
            "Sagemaker Studio Jupyter app is being created. It may take a few minutes...",
          type: "success"
        });
        this.setState({ loadingGetUrlInstance: false });
        window.open(url, "_blank");
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message:
            "Failed to create Jupyter app on Sagemaker Studio Jupyter app.",
          type: "alert"
        });
        this.setState({
          loadingGetUrlInstance: false,
          error
        });
      });
  };

  render() {
    const { userProfile } = this.state;
    return (
      <li className="cdh-card card-shadow card bg-white card-sagemaker-details">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <img
                className="connector-logo-img mr-1"
                src={SmStudioLogo}
                alt="Sagemaker Studio"
                width={18}
                height={18}
              />
              Sagemaker Studio User Profile
            </div>
          </div>
          <div className="card-cart-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-datasource">
              {formatDate(userProfile.createdat, false)}
            </span>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <span className="text-capitalize cdh-card-name">
              {userProfile.name}
            </span>

            <div className="cdh-card-details">
              <p>
                <b>Environment:</b> {userProfile.aws}
              </p>

              <div className="cdh-card-detail">
                <i className="fas fa-box" />
                <span className="content-card-bucket">
                  <b>Domain ID:</b> {userProfile.sm_domain_id}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-table" />
                <span className="content-card-bucket">
                  <b>CloudFormation Stack:</b> {userProfile.stack_name}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div
                className={classnames("btn-cart", {
                  disabled: userProfile.status !== "InService"
                })}
                onClick={() =>
                  userProfile.status === "InService" && this.getUrl()
                }
              >
                {this.state.loadingGetUrlInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-play fa-spacing" />
                <span className="text-actions">Open Jupyter</span>
              </div>
              <div
                className={"btn-cart"}
                onClick={() => this.setState({ deleteInstanceModal: true })}
              >
                {this.state.loadingDeleteInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>

        <div className="cdh-card-footer">
          <div className="cdh-card-begin" style={{ display: "flex" }}>
            <div className="text-footer-metrics ml-2">
              <i className="fas fa-user fa-spacing" />
              UserProfile Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": ["InService"].includes(userProfile.status),
                  "warning-status": userProfile.status === "pending",
                  "error-status": ["Deleted", "Failed", "NOT_FOUND"].includes(
                    userProfile.status
                  )
                })}
              >
                {userProfile.status}
              </span>
            </div>
            <div className="text-footer-metrics ml-2">
              <i className="fas fa-table fa-spacing" />
              CloudFormation Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": [
                    "CREATE_COMPLETE",
                    "UPDATE_COMPLETE"
                  ].includes(userProfile.stack_status),
                  "warning-status":
                    userProfile.stack_status === "CREATE_IN_PROGRESS",
                  "error-status": [
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETE",
                    "NOT_FOUND"
                  ].includes(userProfile.stack_status)
                })}
              >
                {userProfile.stack_status}
              </span>
            </div>
            <div className="text-footer-metrics ml-2">
              <i className="fas fa-book fa-spacing" />
              Jupyter Status
              <span
                className={classnames("tag-metrics", {
                  "green-status":
                    userProfile.jupyter_app_status === "InService",
                  "warning-status":
                    userProfile.jupyter_app_status === "Stopped",
                  "error-status": ["Failed", "NOT_FOUND", "Error"].includes(
                    userProfile.jupyter_app_status
                  )
                })}
              >
                {userProfile.jupyter_app_status || "NOT_FOUND"}
              </span>
            </div>
          </div>
          <div className="cdh-card-end">
            <button
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.refreshStatus}
            >
              {this.state.refreshing || this.state.autorefreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
          </div>
        </div>
        {this.state.error && (
          <Alert severity="error">{getErrorMessage(this.state.error)}</Alert>
        )}
        {userProfile.jupyter_app_error && (
          <Alert severity="error">
            {getErrorMessage(userProfile.jupyter_app_error)}
          </Alert>
        )}
        <div>
          {this.state.deleteInstanceModal && (
            <DeleteObjectWithFrictionModal
              objectName={"User profile"}
              deleteMessage={""}
              onApply={() => this.setState({ deleteInstanceModal: false })}
              onClose={() => this.setState({ deleteInstanceModal: false })}
              open={() => this.setState({ deleteInstanceModal: true })}
              deleteFunction={this.deleteUserProfile}
              isAWSResource
            />
          )}
        </div>
      </li>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartUserProfile)));
