/* @flow */

import React from "react";
import withErrorBoundary from "../../errorBoundaryHOC";
import withGoTo from "../../goToHOC";
import Logger from "../../../utils/logger";

const Log = Logger("SearchSortDataset");

const sortOrder = [
  { value: "createdat", key: "Creation date" },
  { value: "name", key: "Alphabetical" },
  { value: "nb_shares", key: "Number of shares" },
  { value: "nb_carts", key: "Number of projects" },
  { value: "nb_views", key: "Number of views" }
];

type propTypes = {
  onChangeSort: Function,
  field: string,
  direction: string
};

class SearchSortDataset extends React.PureComponent<propTypes> {
  static defaultProps = {
    onChangeSort: () => {
      Log.warning("onChangeSort not defined !");
    },
    field: "nb_shares",
    direction: "desc"
  };

  onChangeSortField = (e) => {
    const field = e.target.value;
    this.props.onChangeSort(field, this.props.direction);
  };

  onChangeSortDirection = (direction) => {
    this.props.onChangeSort(this.props.field, direction);
  };

  render() {
    return (
      <div className="sort-datasets">
        <select
          className="form-control form-control-sm sort-fields"
          onChange={this.onChangeSortField}
          value={this.props.field}
        >
          {sortOrder.map((option) => (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          ))}
        </select>
        {this.props.direction === "asc" ? (
          <button
            type="button"
            className="sort-direction btn"
            onClick={() => this.onChangeSortDirection("desc")}
            disabled={!this.props.field}
          >
            <i className=" fas fa-long-arrow-alt-down" />
          </button>
        ) : (
          <button
            type="button"
            className="sort-direction btn"
            onClick={() => this.onChangeSortDirection("asc")}
            disabled={!this.props.field}
          >
            <i className=" fas fa-long-arrow-alt-up" />
          </button>
        )}
      </div>
    );
  }
}

export default withErrorBoundary(withGoTo(SearchSortDataset));
