/* @flow */
import React from "react";
import classnames from "classnames";

type propTypes = {
  access: "owner" | "shared" | "other",
  isSelected: boolean,
  children: any,
  style: Object
};

class DatasourceCardContainer extends React.Component<propTypes> {
  static defaultProps = {
    access: "other",
    isSelected: false
  };

  x: number;

  render() {
    const style = this.props.style
      ? { top: this.props.style.top, position: "absolute" }
      : { marginBottom: "10px" };
    return (
      <div
        className={classnames(
          "datasets-card card-shadow card bg-white card-Dataset card-rounded",
          {
            isSelected: this.props.isSelected,
            "dataset-owner": this.props.access === "owner",
            "dataset-shared": this.props.access === "shared",
            "dataset-other": this.props.access === "other"
          }
        )}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default DatasourceCardContainer;
