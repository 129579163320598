/* @flow */
import { listProjectsAttachedToGateway } from "./queryGatewayProjectsList";
import { getInfoFromGid } from "./querGetInfoFromGid";

class AdminApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  gatewayProjectsList() {
    return this.fetchAppSync(listProjectsAttachedToGateway);
  }

  getInfoFromGid(gid: String) {
    return this.fetchAppSync(getInfoFromGid, { gid });
  }
}

export default AdminApi;
