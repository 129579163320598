/* @flow */
import React from "react";
import GraphQl from "../../../graphQL";
import Tabs from "../../../components/tabs";
import CartProviders from "./CartProviders";
import CartAnalytics from "../components/analytics/CartAnalytics";
import CartVisualization from "./CartVisualization";
import CartDatascience from "./Datascience";
import withGoTo from "../../goToHOC";
import CartAccelerationTemplates from "./CartAccelerationTemplates";
import HelpOn from "../../../components/helpOn";
import ListDatabrews from "../components/databrew/listDatabrews";
import Logger from "../../../utils/logger";
import LogGroups from "../components/log-groups/logGroups";
import CartUserProfiles from "../../smstudio/userprofile/CartUserProfiles";

const Log = Logger("CartTools");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      cartUri: string
    },
    url: string
  },
  cart: Object,
  reloadCart: Function
};

type stateTypes = {
  cart: Object,
  totalDatabrew: number,
  totalRedshift: number
};

class CartTools extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      cart: {},
      totalDatabrew: 0,
      totalRedshift: 0
    };
  }

  getDatabrewProjects = () =>
    this.props.api.cart
      .listDatabrewProjects(this.props.cart.uri)
      .then((response) => {
        if (!response) {
          return false;
        }
        this.setState({
          totalDatabrew: response.total
        });
        return response.total;
      })
      .catch((error) => {
        Log.error(error);
      });

  getProjectRedshiftClusters = () =>
    this.props.api.analytics
      .getProjectRedshiftClusters(this.props.cart.uri)
      .then((clusters) => {
        this.setState({
          totalRedshift: clusters.length
        });
        return clusters.length;
      })
      .catch((error) => {
        Log.error(error);
      });

  getTabs = () => {
    const { api, goTo, cart, match, reloadCart } = this.props;

    const warningIcon = (
      <i className="fa fa-exclamation-triangle orange-icon"></i>
    );
    const warningMessage = (
      <p className="btn btn-outline-danger btn-block" role="alert">
        {warningIcon} This tool is disabled, if you want to use it please make
        sure to activate the "database project use" option by clicking on "edit"
      </p>
    );

    return [
      {
        name: "Trust",
        index: "tools-trusts",
        route: `${match.url}#tools-trusts`,
        content: <CartProviders cart={cart} api={api} />
      },
      {
        name: "Log groups",
        index: "tools-logs-groups",
        route: `${match.url}#tools-logs-groups`,
        content: <LogGroups cart={cart} api={api} />
      },
      {
        name: (
          <span>
            Data Preparation{" "}
            {this.state.totalDatabrew === 0 &&
            !this.props.cart.is_database_enabled
              ? warningIcon
              : ""}
          </span>
        ),
        index: "tools-preparation",
        route: `${match.url}#tools-preparation`,
        content: (
          <ListDatabrews
            isDisabled={
              this.state.totalDatabrew === 0 &&
              !this.props.cart.is_database_enabled
            }
            warningMessage={warningMessage}
            cart={cart}
            goTo={goTo}
          />
        )
      },
      {
        name: (
          <div>
            Analytics{" "}
            {this.state.totalRedshift === 0 &&
            !this.props.cart.is_database_enabled
              ? warningIcon
              : ""}
          </div>
        ),
        index: "tools-analytics",
        route: `${match.url}#tools-analytics`,
        content: (
          <CartAnalytics
            isDisabled={
              this.state.totalRedshift === 0 &&
              !this.props.cart.is_database_enabled
            }
            warningMessage={warningMessage}
            cart={cart}
            goTo={goTo}
          />
        )
      },
      {
        name: (
          <div>
            Visualization{" "}
            {!this.state.cart.is_attached_to_powerbi &&
            !this.props.cart.is_database_enabled
              ? warningIcon
              : ""}
          </div>
        ),
        index: "tools-visualization",
        route: `${match.url}#tools-visualization`,
        content: (
          <CartVisualization
            isDisabled={
              !this.state.cart.is_attached_to_powerbi &&
              !this.props.cart.is_database_enabled
            }
            warningMessage={warningMessage}
            reloadCart={reloadCart}
            cart={cart}
          />
        )
      },
      {
        name: <div>Datascience</div>,
        index: "tools-science",
        route: `${match.url}#tools-science`,
        content: <CartDatascience cart={cart} />
      },
      {
        name: (
          <div>
            SM Studio<span className="beta-sm-studio">BETA</span>
          </div>
        ),
        index: "tools-smstudio",
        route: `${match.url}#tools-smstudio`,
        content: <CartUserProfiles cart={cart} api={this.props.api} />
      },
      {
        name: "Acceleration Templates",
        activeName: (
          <div style={{ display: "flex" }}>
            Acceleration Templates
            <div
              style={{
                position: "absolute",
                top: "18px",
                left: "184px",
                textTransform: "initial",
                color: "initial",
                fontWeight: "400",
                backgroundColor: "#fff",
                borderRadius: "3px"
              }}
            >
              <HelpOn
                content={
                  <div>
                    <div>
                      Acceleration Templates are AWS Cloudformation templates to
                      accelerate implementation of data use cases.
                    </div>
                    <div>
                      These solutions are proposed by cdh and configured/enabled
                      by your environment admin
                    </div>
                    <div>_</div>
                    <div>Benefits:</div>
                    <div>- Its provide common technical solutions</div>
                    <div>- Accelerate project development</div>
                    <div>- Aligns with Engie Group security practices</div>
                  </div>
                }
              />
            </div>
          </div>
        ),
        index: "tools-acceleration-templates",
        route: `${match.url}#tools-acceleration-templates`,
        content: <CartAccelerationTemplates cart={cart} api={api} />
      }
    ];
  };

  componentDidMount() {
    this.getDatabrewProjects();
    this.getProjectRedshiftClusters();
  }

  render() {
    return (
      <Tabs
        isPreload={false}
        defaultActiveIndex={"tools-trusts"}
        tabs={this.getTabs()}
      />
    );
  }
}

export default withGoTo(CartTools);
