export const listNetworkAccelerationTemplateAccess = {
  query: `query Q ($id:String!, $options: ListOptions) {
        getNetwork(id:$id) {
            accessToTemplates(options: $options) {
                offset
                limit
                total
                results {
                    id
                    acceleration_template {
                        id
                        name
                        description
                        version
                        link_to_doc
                    }
                    group {
                        uri
                        name
                    }
                    template_projects {
                        cart { uri name }
                    }
                }
            }
        }
    }
    `,
  keyOutput: "getNetwork.accessToTemplates"
};
