/* @flow */
import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import Links from "../../links";
import "./AddAnalytics.less";

const Log = Logger("AddRedshiftCluster");

type propTypes = {
  match: {
    params: {
      cartUri: string,
      groupUri: string
    }
  },
  cluster: Object,
  handleAddCluster: Function,
  api: GraphQl,
  showGlobalNotification: Function
};
type stateTypes = {
  iamRole: string,
  error: Object,
  addingClusterToProject: boolean
};
class AddRedshiftCluster extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      addingClusterToProject: false,
      iamRole: "",
      error: null
    };
  }

  addRedshiftClusterToProject = (cluster) => {
    this.setState({
      addingClusterToProject: true
    });
    const { cartUri } = this.props.match.params;
    return this.props.api.analytics
      .addRedshiftClusterToProject(cartUri, cluster.uri, this.state.iamRole)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Redshift cluster added to project",
          type: "success"
        });
        this.setState({
          addingClusterToProject: false
        });
        this.props.handleAddCluster();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to add Redshift cluster to project",
          type: "alert"
        });
        Log.error(error);
        this.setState({
          error,
          addingClusterToProject: false
        });
      });
  };

  handleChange(value) {
    return this.setState({
      iamRole: value
    });
  }

  render() {
    const { cluster } = this.props;
    return (
      <div className={"cart-cluster-card card-shadow card bg-white"}>
        {this.state.error && (
          <Error error={this.state.error} path="AddRedshiftCluster" />
        )}
        <div className="card-cluster-header">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fab fa-aws"></i>
              <span className="text-header-cluster">
                {cluster.platform.aws}
              </span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div
              className={`cluster-environment-tag ${cluster.platform.environment}`}
            >
              <span>{cluster.platform.environment}</span>
            </div>
          </div>
        </div>
        <div className="card-cluster-body">
          <div className="card-cluster-body-left">
            <Links.Analytics.View clusterUri={cluster.uri}>
              <span className={"cluster-name"}>{cluster.cluster_name}</span>
            </Links.Analytics.View>

            <div className="card-cluster-details">
              <p className="card-cluster-description">
                {cluster.description && cluster.description.length > 100
                  ? `${cluster.description.substring(0, 50)}...`
                  : cluster.description}
              </p>
              <div className="card-cluster-role">
                <i className="fab fa-aws"></i>
                <span className="content-card-bucket">
                  {cluster.resource_name}
                </span>
              </div>
            </div>
          </div>
          <div className="card-cart-body-right">
            <div className="card-datasource-body-actions">
              <div>
                <select
                  onChange={(event) => this.handleChange(event.target.value)}
                  value={this.state.iamRole}
                  className="form-control form-control-sm"
                >
                  <option value={""} disabled>
                    {"Choose a Redshift cluster IAM role"}
                  </option>
                  {cluster.iam_roles.map((role) => (
                    <option key={role} value={role}>
                      {`${role}`}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {this.state.iamRole &&
                  cluster.status !== "NotFound" &&
                  cluster.status !== "deleting" &&
                  cluster.status !== "creating" && (
                    <button
                      type={"button"}
                      className="butn butn-flat"
                      onClick={() => this.addRedshiftClusterToProject(cluster)}
                    >
                      {this.state.addingClusterToProject && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      <i className="fas fa-plus"></i>
                      <span className="text-actions">Add to project</span>
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-cluster-footer">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fas fa-globe-europe"></i>
              <span className="text-header-cluster">{cluster.region}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className="text-footer-metrics">
              Status <span className="tag-metrics">{cluster.status}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(AddRedshiftCluster)));
