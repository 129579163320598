/* @flow */
import React from "react";
import Tabs from "../../../components/tabs";
import RedshiftClustersList from "../../analytics/redshift/list/redshiftClustersList";
import RSNamespaces from "../../analytics/redshiftserverless/list/RSNamespaces";

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  goTo: Function
};

class PlaygroundAnalytics extends React.PureComponent<propTypes> {
  render() {
    return (
      <div>
        <Tabs
          tabs={[
            {
              name: (
                <div>
                  Redshift Serverless<span className="beta-rss">BETA</span>
                </div>
              ),
              index: "rss",
              content: (
                <RSNamespaces
                  uriAccount={this.props.uriAccount}
                  environmentUri={this.props.uriPlayground}
                  goTo={this.props.goTo}
                />
              )
            },
            {
              name: <div>Redshift Clusters</div>,
              index: "redshift",
              content: (
                <RedshiftClustersList
                  uriAccount={this.props.uriAccount}
                  environmentUri={this.props.uriPlayground}
                  goTo={this.props.goTo}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}

export default PlaygroundAnalytics;
