/* @flow */
import React from "react";
import { connect } from "react-redux";
import ButtonAction from "../../../components/buttonAction";
import Modal from "../../../components/modal";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";

const Log = Logger("AccelerationTemplateEditGroupModal");

type propTypes = {
  api: GraphQl,
  templateEnvironementGroup: Object,
  onEdit: Function,
  onClose: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  monitoringEmail: string,
  error?: Object
};

class EditGroupModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      monitoringEmail: props.templateEnvironementGroup.monitoring_email
    };
  }

  submitEdit = () => {
    this.setState({ error: undefined });
    this.props.api.accelerationTemplates
      .updateAccessToAccelerationTemplate(
        this.props.templateEnvironementGroup.id,
        {
          monitoring_email: this.state.monitoringEmail
        }
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Edited !",
          type: "success"
        });
        this.props.onEdit();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error });
      });
  };

  render() {
    return (
      <Modal
        title="Edit"
        body={
          <div>
            <div className="form-group">
              <legend className="label-form">Group</legend>
              <input
                disabled
                className="form-control"
                placeholder="Group"
                value={this.props.templateEnvironementGroup.group.name}
              />
            </div>

            <div className="form-group">
              <legend className="label-form">Network</legend>
              <input
                disabled
                className="form-control"
                placeholder="Network"
                value={this.props.templateEnvironementGroup.network.name}
              />
            </div>

            <div className="form-group">
              <legend className="label-form">Monitoring Email</legend>
              <input
                className="form-control"
                placeholder="Monitoring email"
                onChange={(event) => {
                  const monitoringEmail = event.target.value;
                  this.setState({ error: undefined, monitoringEmail });
                }}
                value={this.state.monitoringEmail}
              />
            </div>
          </div>
        }
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key={`group-edit-${this.props.templateEnvironementGroup.group.uri}`}
            label="Edit"
            className="butn butn-flat"
            onClick={this.submitEdit}
          />,
          <button
            key={`group-close-${this.props.templateEnvironementGroup.group.uri}`}
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(EditGroupModal));
