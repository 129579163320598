/* @flow */
import React from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from "@material-ui/core";
import Links from "../../links";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import Paginator from "../../../components/paginator";
import Error from "../../../components/error";
import Loading from "../../../components/loading";

type propTypes = {
  dataset: Object,
  project: Object,
  api: GraphQl,
  onClose: Function,
  open: boolean
};

type stateTypes = {
  datasetItems: Object,
  error?: Object,
  loading?: boolean
};

class DatasetProjectModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      loading: true,
      datasetItems: null
    };
  }

  componentDidMount() {
    this.listDatasetItems();
  }

  listDatasetItems = () => {
    this.setState({
      loading: true
    });
    if (
      this.props.dataset.datasourceOwner.uri !== this.props.project.groupuri
    ) {
      this.props.api.share
        .listShareItems(this.props.dataset.uri, this.props.project.groupuri)
        .then((datasetItems) => {
          this.setState({
            datasetItems
          });
        });
    } else {
      this.props.api.dataset
        .listStages(this.props.dataset.uri, { offset: 0, limit: 10000 })
        .then((datasetItems) => {
          this.setState({
            datasetItems
          });
        });
    }
    this.setState({
      loading: false
    });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasetProjectData"} />;
    if (this.state.loading) return <Loading message={"Loading"} />;
    return (
      <div>
        {!this.state.loading && (
          <Dialog
            open={this.props.open}
            onClose={this.props.onClose}
            maxWidth={"md"}
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              <div>
                <Typography color="textSecondary" variant="h6">
                  Available data for project <b>{this.props.project.name}</b>{" "}
                  from dataset <b>{this.props.dataset.name}</b>
                </Typography>
              </div>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div className="list-policies">
                <div className="mt-2">
                  {this.state.datasetItems &&
                    this.state.datasetItems.results && (
                      <Paginator
                        container={(content) => (
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="col-policy" scope="col">
                                  NAME
                                </th>
                                <th className="col-policy" scope="col">
                                  DATA FILTER
                                </th>
                              </tr>
                            </thead>
                            <tbody>{content}</tbody>
                          </table>
                        )}
                        limit={10}
                        initialOffset={0}
                        totalCount={this.state.datasetItems.total}
                        list={
                          this.state.datasetItems.results.length === 0
                            ? [
                                {
                                  table_name: "ALL_TABLES",
                                  data_filter_id: null,
                                  data_filter: {
                                    name: "-",
                                    columns: ["ALL_COLUMNS"]
                                  }
                                }
                              ]
                            : this.state.datasetItems.results
                        }
                        componentRender={(item) => (
                          <tr className="table-row">
                            <td className="table-group-column">
                              {item.id ? (
                                <Links.Stage.View
                                  datasetUri={this.props.dataset.uri}
                                  outputId={(item || {}).id || "null"}
                                >
                                  {item.table ? item.table : item.table_name}
                                </Links.Stage.View>
                              ) : (
                                <Links.Stage.View
                                  datasetUri={this.props.dataset.uri}
                                  outputId={(item || {}).stage_id || "null"}
                                >
                                  {item.table ? item.table : item.table_name}
                                </Links.Stage.View>
                              )}
                            </td>
                            <td>
                              <b>
                                {item.data_filter_id
                                  ? item.data_filter.name
                                  : "N/A"}
                              </b>
                            </td>
                          </tr>
                        )}
                        loadPage={() => {}}
                      />
                    )}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DatasetProjectModal));
