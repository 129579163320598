/* @flow */

import React from "react";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import ProjectUsage from "../../../carts/components/ProjectUsage";

type propTypes = {
  redshiftCluster: Object,
  api: GraphQl
};

type stateTypes = {
  projects: Array<Object>,
  ready: boolean,
  error: Object
};

class RedshiftClusterUsage extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      projects: [],
      error: undefined
    };
  }

  componentDidMount() {
    this.getRedshiftClusterUsage();
  }

  getRedshiftClusterUsage = () => {
    this.props.api.analytics
      .getRedshiftClusterUsage(this.props.redshiftCluster.uri)
      .then((projects) => {
        this.setState({
          projects,
          ready: true
        });
      })
      .catch((clusterProjectsError) => {
        this.setState({
          ready: true,
          error: clusterProjectsError
        });
      });
  };

  render() {
    const { ready, error, projects } = this.state;
    if (error) return <Error error={error} path={"RedshiftClusterUsage"} />;
    if (!ready) return <Loading message={"Redshift Cluster Usage"} />;
    if (ready && projects.length <= 0) {
      return (
        <div>This Redshift cluster has not been added to any project yet.</div>
      );
    }
    return (
      <div>
        {projects.map((project) => (
          <ProjectUsage project={project} />
        ))}
      </div>
    );
  }
}

export default withAppSync(RedshiftClusterUsage);
