import { React, useState, useEffect } from "react";

const useDatabricks = (api, cart, match) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workspaces, setWorkspaces] = useState(null);
  const [workspacesAttachedOffset, setWorkspacesAttachedOffset] = useState(0);

  const fetchWorkspacesAssociatedToGroups = async () => {
    setIsLoading(true);
    try {
      const workspaces = await api.databricks.getWorkspacesAssociatedToGroups(
        cart.groupuri,
        {
          projects_offset: workspacesAttachedOffset
        }
      );

      setWorkspaces(workspaces.dbx_groups.results);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading && !error && workspaces === null) {
      fetchWorkspacesAssociatedToGroups();
    }
  }, [workspaces, workspacesAttachedOffset]);

  const fetchAccountName = async () => {
    setIsLoading(true);
    try {
      const account = await api.databricks.getCartForAccount(cart.uri);
      setWorkspaces(account);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      workspaces !== null &&
      workspaces !== undefined &&
      workspaces.length > 0 &&
      workspaces[0].dbxWorkspaces.length > 0 &&
      workspaces[0].dbxWorkspaces[0].dbxAccount === undefined
    ) {
      fetchAccountName();
    }
  }, [workspaces]);

  return {
    error,
    isLoading,
    workspaces
  };
};

export { useDatabricks };
