/* @flow */
import React from "react";
import "./listPendingShares.less";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import Logger from "../../../../utils/logger";
import Error from "../../../../components/error";
import PendingShareElement from "./pendingShareElement";

const Log = Logger("ListPendingShares");

type propTypes = {
  api: GraphQl,
  uriDataset: string
};

type stateTypes = {
  error: Object,
  pendingShares: Array<Object>,
  ready: boolean
};

class ListPendingShares extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      pendingShares: []
    };
  }

  componentDidMount() {
    this.getShares();
  }

  getShares = () => {
    this.setState({ ready: false });
    this.props.api.dataset
      .getShares(this.props.uriDataset)
      .then((shares) => {
        this.filterPendingShares(shares);
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  };

  filterPendingShares = (shares) => {
    if (shares) {
      const pendingShares = [];
      shares.map((share) => {
        if (share.request_status === "pending") {
          pendingShares.push(share);
        }
        return false;
      });
      this.setState({
        pendingShares,
        ready: true
      });
    }
  };

  onResendReminderError = (error) => {
    this.setState({ error });
  };

  render() {
    if (!this.state.pendingShares || this.state.pendingShares.length <= 0) {
      return false;
    }

    if (!this.state.ready) {
      return <Loading message={"List Pending Shares"} />;
    }

    return (
      <React.Fragment>
        <div className="title">
          <h4 className="title text-primary">My pending requests</h4>
        </div>
        {this.state.error && (
          <Error error={this.state.error} path={"RequestForm"} stringOnly />
        )}
        <div className="mt-3 list-shares-pending">
          <table className="table">
            <thead>
              <tr>
                <th className="col-organization" scope="col">
                  ORGANIZATION
                </th>
                <th className="col-group" scope="col">
                  GROUP
                </th>
                <th className="col-date" scope="col">
                  LAST REQUEST DATE
                </th>
                <th
                  className="col-action text-center"
                  scope="col center-column"
                ></th>
              </tr>
            </thead>
            <tbody>
              {this.state.pendingShares.map((share) => (
                <PendingShareElement
                  share={share}
                  onError={(error) => this.onResendReminderError(error)}
                  onSuccess={() => this.getShares()}
                />
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(ListPendingShares);
