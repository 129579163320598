/* @flow */

import React from "react";
import { connect } from "react-redux";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import CopyToClipboard from "../../../../components/copyToClipboard";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import { formatDate, diffDate } from "../../../../utils/date";
import "./redshiftClusterCredentials.less";
import config from "../../../../config";

const Log = Logger("RedshiftClusterCredentials");

type propTypes = {
  redshiftCluster: Object,
  cart: Object,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  generatingCredentials: boolean,
  generatingEphemeralCredentials: boolean,
  redshiftClusterCredentials: Object,
  ready: boolean,
  database: string,
  isValidCredentials: boolean
};

class RedshiftClusterCredentials extends React.Component<
  propTypes,
  stateTypes
> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      generatingCredentials: false,
      generatingEphemeralCredentials: false,
      database: this.props.cart
        ? this.props.cart.database
        : this.props.redshiftCluster.database_name,
      redshiftClusterCredentials: {
        database_name: "",
        database_user: "",
        database_password: "",
        expiration: this.props.redshiftCluster.credentials_expiration_date
      },
      ready: true,
      isValidCredentials: false
    };
  }

  getRedshiftClusterCredentials = () => {
    this.setState({
      generatingEphemeralCredentials: true
    });
    const cartUri = this.props.cart ? this.props.cart.uri : "";
    this.props.api.analytics
      .getRedshiftClusterCredentials(this.props.redshiftCluster.uri, cartUri)
      .then((redshiftClusterCredentials) => {
        Log.info("Credentials generated:", redshiftClusterCredentials);
        this.props.showGlobalNotification({
          message: "Credentials generated",
          type: "success"
        });
        this.setState({
          redshiftClusterCredentials,
          isValidCredentials: true,
          generatingEphemeralCredentials: false
        });
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to generate credentials",
          type: "alert"
        });
        this.setState({
          error,
          isValidCredentials: false,
          generatingEphemeralCredentials: false
        });
      });
  };

  getRedshiftClusterPermanentCredentials = () => {
    this.setState({
      generatingCredentials: true
    });
    const cartUri = this.props.cart ? this.props.cart.uri : "";
    this.props.api.analytics
      .getRedshiftClusterPermanentCredentials(
        this.props.redshiftCluster.uri,
        cartUri
      )
      .then((redshiftClusterCredentials) => {
        Log.info("Credentials generated:", redshiftClusterCredentials);
        this.props.showGlobalNotification({
          message: "Credentials generated",
          type: "success"
        });
        this.setState({
          redshiftClusterCredentials,
          isValidCredentials: true,
          generatingCredentials: false
        });
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to generate credentials",
          type: "alert"
        });
        this.setState({
          error,
          isValidCredentials: false,
          generatingCredentials: false
        });
      });
  };

  displayCredentialsExpiration = () => {
    const dateNow = new Date();
    if (
      diffDate(
        dateNow.toString(),
        this.state.redshiftClusterCredentials.expiration
      ) < 0
    ) {
      return (
        <div className="cluster-credentials-date red-warning">
          <i className="fas fa-exclamation-circle fa-spacing"></i>
          Warning : Cluster credentials expired on{" "}
          {formatDate(this.state.redshiftClusterCredentials.expiration, false)}
        </div>
      );
    }
    if (
      diffDate(
        dateNow.toString(),
        this.state.redshiftClusterCredentials.expiration
      ) < 240
    ) {
      return (
        <div className="cluster-credentials-date red-warning">
          <i className="fas fa-exclamation-circle fa-spacing"></i>
          Warning : Cluster credentials will expire on{" "}
          {formatDate(this.state.redshiftClusterCredentials.expiration, false)}
        </div>
      );
    }
    if (
      diffDate(
        dateNow.toString(),
        this.state.redshiftClusterCredentials.expiration
      ) < 1080
    ) {
      return (
        <div className="cluster-credentials-date yellow-warning">
          <i className="fas fa-exclamation-circle fa-spacing"></i>
          Cluster credentials will expire on{" "}
          {formatDate(this.state.redshiftClusterCredentials.expiration, false)}
        </div>
      );
    }
    if (
      diffDate(
        dateNow.toString(),
        this.state.redshiftClusterCredentials.expiration
      ) > 1080
    ) {
      return (
        <div className="cluster-credentials-date green-warning">
          <i className="fas fa-check-circle fa-spacing"></i>
          Cluster credentials will expire on{" "}
          {formatDate(this.state.redshiftClusterCredentials.expiration, false)}
        </div>
      );
    }

    return false;
  };

  render() {
    const { redshiftCluster, cart } = this.props;
    const {
      error,
      ready,
      generatingCredentials,
      isValidCredentials,
      redshiftClusterCredentials,
      generatingEphemeralCredentials
    } = this.state;
    return (
      <div className="cluster-create">
        {error && <Error error={error} path="RedshiftClusterCredentials" />}
        {!error && !ready && <Loading message="Redshift Cluster Credentials" />}
        {!error && ready && (
          <div>
            <div className="form-container">
              {this.state.redshiftClusterCredentials.expiration &&
                this.displayCredentialsExpiration()}
              <div className="form-group">
                <div className={"alert alert-light"}>
                  <i className="fas fa-exclamation-circle" />
                  &nbsp;<span>Warning</span>
                  <div>
                    <div>
                      <span>
                        At each credentials generation the previous credentials
                        are{" "}
                      </span>
                      <span className={"font-weight-bold"}>invalidated</span>.
                      &nbsp;
                      <span>
                        Please make sure to store the generated credentials
                        somewhere secure.
                      </span>
                    </div>
                    <div>
                      <a
                        href={`${config.HELP_CENTER}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More info on Helpcenter
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                {!cart && (
                  <button
                    type="button"
                    className="butn butn-flat"
                    onClick={this.getRedshiftClusterCredentials}
                  >
                    {generatingEphemeralCredentials && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-stopwatch fa-spacing"></i>
                    Generate ephemeral cluster credentials (1h)
                  </button>
                )}
                {cart && (
                  <button
                    type="button"
                    className="butn butn-flat"
                    onClick={this.getRedshiftClusterPermanentCredentials}
                  >
                    {generatingCredentials && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-key fa-spacing"></i>
                    Generate cluster credentials (90 days)
                  </button>
                )}
              </div>
              <div className="form-group">
                <label className="label-form">Endpoint Address</label>
                <div className="input-group">
                  <input
                    disabled
                    name="name"
                    className="form-control form-control-sm"
                    placeholder="Endpoint Address"
                    value={redshiftCluster.endpoint_address}
                  />
                  <CopyToClipboard
                    text={redshiftCluster.endpoint_address}
                    className={"fas fa-copy btn-carre"}
                    label={""}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="label-form">Endpoint Port</label>
                <div className="input-group">
                  <input
                    disabled
                    name="name"
                    className="form-control form-control-sm"
                    placeholder="Endpoint Port"
                    value={redshiftCluster.endpoint_port}
                  />
                  <CopyToClipboard
                    text={redshiftCluster.endpoint_port}
                    className={"fas fa-copy btn-carre"}
                    label={""}
                  />
                </div>
              </div>
              {isValidCredentials && (
                <div>
                  <div className="form-group">
                    <label className="label-form">Database Name</label>
                    <div className="input-group">
                      <input
                        disabled
                        name="name"
                        className="form-control form-control-sm"
                        placeholder="Database Name"
                        value={this.state.database}
                      />
                      <CopyToClipboard
                        text={this.state.database}
                        className={"fas fa-copy btn-carre"}
                        label={""}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-form">Database User</label>
                    <div className="input-group">
                      <input
                        disabled
                        name="name"
                        className="form-control form-control-sm"
                        placeholder="Datasource Name"
                        value={redshiftClusterCredentials.database_user}
                      />
                      <CopyToClipboard
                        text={redshiftClusterCredentials.database_user}
                        className={"fas fa-copy btn-carre"}
                        label={""}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-form">Database Password</label>
                    <div className="input-group">
                      <input
                        disabled
                        name="name"
                        className="form-control form-control-sm"
                        placeholder="Datasource Name"
                        value={redshiftClusterCredentials.database_password}
                      />
                      <CopyToClipboard
                        text={redshiftClusterCredentials.database_password}
                        className={"fas fa-copy btn-carre"}
                        label={""}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(RedshiftClusterCredentials));
