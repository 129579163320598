/* @flow */

export const getAccount = {
  query: `query Q ($accountUri: String!){
        getAccount(accountUri:$accountUri){
            uri
            name
            creator{
              name
            }
            is_group_responsible_managed
            gbu {
              id
              code
              name
            }
            engieEntities {
                aws_account
                org_id
                scope_id
                scope
                sub_scope
                gbus
                name
            }
        }
    }`,
  keyOutput: "getAccount"
};

export type GetAccountPropsTypes = {
  uri: string,
  name: string,
  is_group_responsible_managed: boolean,
  gbu: Object
};
