/* @flow */

export const queryNotificationTypesList = {
  query: `query Q { listNewtificationPublicSubtypes {
        id
        name
        code
        description
     } 
    }
`,
  keyOutput: "listNewtificationPublicSubtypes"
};

export const mutationNotificationTypesCreate = {
  query: `mutation Q ($input: NotificationTypeInput!) {
    createNotificationType(notificationTypeInput:$input) {
        id
        name
        code
        description
    }
  }
`,
  keyOutput: "createNotificationType"
};

type NotificationTypeInput = {
  code: string,
  name: string,
  description: string
};

type NotificationType = {
  id: string,
  code: string,
  name: string,
  description: string
};
export type NotificationTypePropsTypes = NotificationType;
export type NotificationTypeInputPropsTypes = NotificationTypeInput;
