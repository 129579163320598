export const queryListStages = {
  query: `query Q ($datasetUri: String!, $options: QueryOptions) {
        listStages(datasetUri: $datasetUri, options: $options) {
            results {
                id
                uri
                name
                createdat
                updatedat
                table
                location
                is_default
                type
                description
                dataset_uri
                dataset {
                    access
                }
                im_size
                im_last_update
                profiling_nb_rows
                profiling_last_update
                profiling_execution {
                    status
                }
                is_table_existing_in_glue_catalog
                schema
                source_type
                columns {
                    Name
                    Type
                    Comment
                    Description
                }
                data_format_type
            }
            total
            limit
            offset
        }
    }`,
  keyOutput: "listStages"
}
