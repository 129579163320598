/* @flow */
import React from "react";
import Row from "../../../../components/row";
import GroupTrust from "./groupTrust";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import Paginator from "../../../../components/paginator";

const Log = Logger("Your Group Trusts");

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  offset: number,
  isFetchingGroupTrust: boolean,
  myGroupTrusts: Array<Object>,
  error?: Object,
  groupTrustLimit: number,
  groupTrustTotal: number
};

class YourGroupTrusts extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      isFetchingGroupTrust: true,
      myGroupTrusts: [],
      groupTrustLimit: 10,
      groupTrustTotal: 0,
      error: undefined
    };
  }

  componentDidMount() {
    this.getMyGroupsTrusts();
  }

  getMyGroupsTrusts = () => {
    const options = {
      limit: 10,
      offset: this.state.offset
    };
    this.setState({ isFetchingGroupTrust: true });
    return this.props.api.identity
      .listMyGroupTrusts(options)
      .then((groupTrusts) => {
        this.setState({
          isFetchingGroupTrust: false,
          myGroupTrusts: groupTrusts.results,
          groupTrustLimit: groupTrusts.limit,
          groupTrustTotal: groupTrusts.total
        });
      })
      .catch((error) => {
        Log.warning(error);
        this.setState({
          isFetchingGroupTrust: false,
          error
        });
      });
  };

  searchGroupTrustsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getMyGroupsTrusts().then(r))
    );

  render() {
    return (
      <Row className="mx-2 mt-4">
        {this.state.error && (
          <Error error={this.state.error} path={"yourgrouptrusts"} />
        )}
        <Paginator
          limit={this.state.groupTrustLimit}
          totalCount={this.state.groupTrustTotal}
          initialOffset={0}
          list={this.state.myGroupTrusts}
          componentRender={(groupTrust) => (
            <GroupTrust
              key={groupTrust.arn + groupTrust.type + groupTrust.group.name}
              groupTrust={groupTrust}
              api={this.props.api}
              onSuccess={() => this.getMyGroupsTrusts()}
            />
          )}
          loadPage={this.searchGroupTrustsFromPagination}
          isLoadingPage={this.state.isFetchingGroupTrust}
          onNoResult={() => (
            <div className="total-search-results">No trust found</div>
          )}
        />
      </Row>
    );
  }
}

export default withAppSync(YourGroupTrusts);
