/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import ItemFromList from "../../../../components/itemFromList";
import Links from "../../../links";
import { getAccountUriFromUri } from "../../../../utils/toolsForUri";
import { updateMessage } from "../../../globalNotifications/actions";
import Error from "../../../../components/error";
import { formatDate } from "../../../../utils/date";
import ButtonAction from "../../../../components/buttonAction";
import Modal from "../../../../components/modal";
import withAppSync from "../../../AppsyncHOC";
import TermsConditionsModal from "../../../../components/termsConditionsModal";
import MoreDetailsNotification from "../MoreDetailsNotification";

type propTypes = {
  pendingShareRequest: {
    id: string,
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    dataset: {
      access: string,
      uri: string,
      name: string,
      expiration_date?: string,
      datasource: {
        uri: string,
        name: string
      }
    },
    createdat: string,
    updatedat: ?string,
    message: string
  },
  api: Object,
  onAction: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  acceptTCModal: boolean,
  complianceModal: boolean,
  rejectModal: boolean,
  rejectMessage: string,
  isSubmitting: boolean
};

class DatasetShareRequestPending extends React.Component<
  propTypes,
  stateTypes
> {
  static defaultProps = {
    pendingShareRequest: {
      id: "-",
      group: {
        uri: ":::::::",
        name: "-",
        account: {
          name: "-"
        }
      },
      dataset: {
        access: "read",
        uri: ":::::::",
        name: "-",
        datasource: {
          uri: ":::::::",
          name: "-"
        }
      },
      updatedat: undefined,
      createdat: "00-00-00 00:00:00",
      message: ""
    }
  };

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      acceptTCModal: false,
      rejectModal: false,
      complianceModal: false,
      rejectMessage: "",
      isSubmitting: false
    };
  }

  openRejectModal = () => this.setState({ rejectModal: true });

  closeRejectModal = () => this.setState({ rejectModal: false });

  onChange(event) {
    const { value } = event.target;
    this.setState({
      rejectMessage: value
    });
  }

  contentRejectModal() {
    return (
      <div>
        <fieldset className="form-group">
          <legend className="label-form">
            Reject Message
            <div
              className={classnames("reject-message", {
                "message-is-too-long":
                  (this.state.rejectMessage || "").length > 300,
                "message-is-good":
                  (this.state.rejectMessage || "").length <= 300
              })}
            >
              ({(this.state.rejectMessage || "").length}/300 characters max)
            </div>
          </legend>
          <textarea
            onChange={(event) => this.onChange(event)}
            rows="4"
            className="form-control bg-white"
            placeholder="Add a reject message..."
          />
          {this.state.rejectMessage.length > 300 && (
            <div className="error-msg">
              Reject message is too long (300 characters max)
            </div>
          )}
        </fieldset>
      </div>
    );
  }

  rejectModal() {
    return (
      <Modal
        title={"Reject Share"}
        body={this.contentRejectModal()}
        actions={[
          <button
            type="button"
            className="butn butn-delete"
            disabled={
              this.state.rejectMessage.length > 300 || this.state.isSubmitting
            }
            onClick={() => this.rejectRequest()}
          >
            Reject
          </button>,
          <button
            type="button"
            className="butn"
            onClick={() => this.closeRejectModal()}
          >
            Close
          </button>
        ]}
      />
    );
  }

  openAcceptTCModal = () => this.setState({ acceptTCModal: true });

  closeAcceptTCModal = () => this.setState({ acceptTCModal: false });

  acceptTCModal() {
    return (
      <Modal
        title={"Share"}
        body={this.contentAcceptTCModal()}
        actions={[
          <button
            type="button"
            className="butn butn-create"
            onClick={() => this.acceptRequest()}
          >
            Share
          </button>,
          <button
            type="button"
            className="butn"
            onClick={() => this.closeAcceptTCModal()}
          >
            Close
          </button>
        ]}
      />
    );
  }

  openComplianceModal = () => this.setState({ complianceModal: true });

  closeComplianceModal = () => this.setState({ complianceModal: false });

  contentAcceptTCModal() {
    return (
      <div>
        I acknowledge having read and accept the general{" "}
        <span className="terms-highlight" onClick={this.openComplianceModal}>
          terms & conditions
        </span>{" "}
        of access and use of the service
      </div>
    );
  }

  acceptRequest = () => {
    const { pendingShareRequest, api, onAction } = this.props;
    const { dataset } = pendingShareRequest;

    return api.share
      .acceptRequest(dataset.uri, pendingShareRequest.group.uri)
      .then(() => {
        onAction();
        this.props.showGlobalNotification({
          message: "The access request has been successfully accepted",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
        this.props.showGlobalNotification({
          message:
            "An error occurred during the acceptance of the access request",
          type: "alert"
        });
      });
  };

  rejectRequest = () => {
    if (this.state.rejectMessage.length <= 300) {
      this.setState({ isSubmitting: true });
      const { pendingShareRequest, api, onAction } = this.props;
      const { dataset } = pendingShareRequest;

      return api.share
        .rejectRequest(
          dataset.uri,
          pendingShareRequest.group.uri,
          this.state.rejectMessage
        )
        .then(() => {
          this.setState({ isSubmitting: false });
          this.closeRejectModal();
          this.props.showGlobalNotification({
            message: "The access request has been successfully rejected",
            type: "success"
          });
          onAction();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          this.props.showGlobalNotification({
            message:
              "An error occurred during the reject of the access request",
            type: "alert"
          });
          this.setState({
            error
          });
        });
    }
    return false;
  };

  getActions = () => {
    const { pendingShareRequest } = this.props;
    const { dataset = {} } = pendingShareRequest;

    if (dataset.can_share && !dataset.expiration_date) {
      return [
        <ButtonAction label="Share" onClick={this.openAcceptTCModal} />,
        <ButtonAction
          label="Reject"
          className="butn butn-delete"
          onClick={this.openRejectModal}
        />
      ];
    }

    return [];
  };

  getTitle = () => {
    const { pendingShareRequest } = this.props;
    const { dataset } = pendingShareRequest;
    const datasetName = dataset.name;
    const datasourcetName = (dataset.datasource || {}).name;
    const datasourceUri = (dataset.datasource || {}).uri;
    const accountUri = getAccountUriFromUri(dataset.uri);

    return (
      <div className="share-title">
        {pendingShareRequest && pendingShareRequest.id && (
          <div className="share-title mb-1">
            <Links.Share.View
              shareId={(pendingShareRequest || {}).id || "null"}
              state={{ pendingShareRequest }}
            >
              {`${pendingShareRequest.group.name} share request for dataset ${dataset.name}`}
            </Links.Share.View>
          </div>
        )}
        {"You have a pending request on the dataset "}
        <Links.Dataset.View uriDataset={dataset.uri}>
          {datasetName}
        </Links.Dataset.View>
        {" ("}
        <Links.Datasource.View
          uriAccount={accountUri}
          uriDatasource={datasourceUri}
        >
          {datasourcetName}
        </Links.Datasource.View>
        {")"}
      </div>
    );
  };

  getDescription = () => {
    const { pendingShareRequest } = this.props;

    return (
      <React.Fragment>
        <div className="share-description">
          {"Group "}
          <Links.Group.View
            uriGroup={pendingShareRequest.group.uri}
            state={{ group: pendingShareRequest.group }}
          >
            {pendingShareRequest.group.name}
          </Links.Group.View>
          {`, from ${pendingShareRequest.group.account.name}, requested access to your dataset`}
          {`, the  ${formatDate(pendingShareRequest.createdat, false)}`}
          {this.state.error && (
            <Error
              error={this.state.error}
              path={"DatasetShareRequestPending"}
              stringOnly
            />
          )}
        </div>
        <MoreDetailsNotification share={pendingShareRequest} />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div>
        <ItemFromList
          className={"a-share"}
          name={this.getTitle()}
          description={this.getDescription()}
          actions={this.getActions()}
          hideLabelName
          hideLabelDescription
        />
        <div>{this.state.acceptTCModal && this.acceptTCModal()}</div>
        <div>{this.state.rejectModal && this.rejectModal()}</div>
        {this.state.complianceModal && (
          <TermsConditionsModal onClose={this.closeComplianceModal} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasetShareRequestPending)
);
