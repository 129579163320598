/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./view.less";
import Error from "../../../components/error";
import CartProviderModal from "./CartProviderModal";
import { updateMessage } from "../../globalNotifications/actions";
import CartProviderAdd from "./CartProviderAdd";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import CartProviderEditItem from "./CartProviderEditItem";
import CartProviderItem from "./CartProviderItem";
import GraphQl from "../../../graphQL";
import Col from "../../../components/col";
import Row from "../../../components/row";
import Loading from "../../../components/loading";

type propTypes = {
  cart: Object,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  cartProviderModal: Object,
  providers: Array<Object>,
  openForm: boolean,
  isFetching: boolean
};

class CartProviders extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      providers: [],
      cartProviderModal: undefined,
      openForm: false,
      isFetching: false
    };
  }

  componentDidMount() {
    this.listCartTrusts();
  }

  listCartTrusts = () => {
    this.setState({ isFetching: true }, () => {
      this.props.api.cart
        .listCartTrusts(this.props.cart.uri)
        .then((providers) => {
          this.setState({
            providers,
            isFetching: false,
            openForm: false
          });
        })
        .catch((error) => {
          this.setState({
            error,
            isFetching: false
          });
        });
    });
  };

  openCartProviderModal = (cartProvider) =>
    this.setState({ cartProviderModal: { cartProvider } });

  closeCartProviderModal = () =>
    this.setState({ cartProviderModal: undefined });

  render() {
    return (
      <div className={"cart-providers"}>
        {this.state.error && (
          <Error error={this.state.error} path={"CartProviders"} />
        )}
        {this.state.isFetching && <Loading message={"trustees"} />}
        {this.state.providers &&
          this.state.providers.length > 0 && [
            this.state.providers && this.state.providers.length > 0 && (
              <div className={"alert alert-light"}>
                <i className="fas fa-exclamation-circle fa-spacing" />
                The refresh role is mandatory to apply a new trustee
              </div>
            ),
            <hr />,
            <div className={"cart-providers-list"}>
              <ul className="list-group">
                {this.state.providers.map((provider) =>
                  provider.isEditMode ? (
                    <CartProviderEditItem
                      provider={provider}
                      cart={this.props.cart}
                      onEdit={this.listCartTrusts}
                      onClose={() =>
                        this.setState((previousState) => {
                          const providers = previousState.providers.map((p) => {
                            if (
                              p.arn === provider.arn &&
                              p.type === provider.type &&
                              p.platform.uri === provider.platform.uri
                            ) {
                              return Object.assign({}, p, {
                                isEditMode: false
                              });
                            }
                            return Object.assign({}, p);
                          });
                          return { providers };
                        })
                      }
                      api={this.props.api}
                    />
                  ) : (
                    <CartProviderItem
                      cart={this.props.cart}
                      trust={provider}
                      onEdit={() =>
                        this.setState((previousState) => {
                          const providers = previousState.providers.map((p) => {
                            if (
                              p.arn === provider.arn &&
                              p.type === provider.type &&
                              p.platform.uri === provider.platform.uri
                            ) {
                              return Object.assign({}, p, { isEditMode: true });
                            }
                            return Object.assign({}, p);
                          });
                          return { providers };
                        })
                      }
                      onDelete={() => this.openCartProviderModal(provider)}
                      onSuccess={this.listCartTrusts}
                    />
                  )
                )}
              </ul>
            </div>
          ]}

        {this.state.openForm ? (
          <CartProviderAdd
            cart={this.props.cart}
            api={this.props.api}
            onAdd={() => {
              this.props.showGlobalNotification({
                message: "Trustee created",
                type: "success"
              });
              if (this.props.cart.is_refresh_auto_enabled) {
                this.props.showGlobalNotification({
                  message:
                    "Trustee created, your role project will be automatically refreshed",
                  type: "success"
                });
              }
              this.setState({ openForm: false });
              this.listCartTrusts();
            }}
          />
        ) : (
          <Row className={"mb-3 mt-3"}>
            <Col size={12} className={"text-primary"}>
              <div
                className="butn butn-flat"
                onClick={() => this.setState({ openForm: true })}
              >
                <i className={"fas fa-lg fa-plus pr-2"} />
                New trustee
              </div>
            </Col>
          </Row>
        )}

        {this.state.cartProviderModal && (
          <CartProviderModal
            cart={this.props.cart}
            cartProvider={this.state.cartProviderModal.cartProvider}
            hide={this.closeCartProviderModal}
            showGlobalNotification={this.props.showGlobalNotification}
            onAction={() => {
              this.closeCartProviderModal();
              this.listCartTrusts();
            }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

const connector = connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartProviders)));
export default connector;
