export const getAccountDatasources = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        getAccount (accountUri: $accountUri) {
            uri
            myDatasourcesList {
                total (options: $options)
                results (options: $options) {
                    parent
                    uri 
                    name
                    bucket
                    region
                    description
                    accountid
                    createdat
                    user_roles
                    owner {
                        name
                        resource_name
                        uri
                    }
                    datasetsList {
                        total
                    }
                }
            }
        }
      }
    `,
  keyOutput: "getAccount.myDatasourcesList"
};
