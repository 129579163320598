export const listMyGroupsAndUsers = {
  query: `query Q ($options: QueryOptions) {
        getMyGroupsList(options: $options) {
            total
            limit
            offset
            results {
                uri 
                name
                parent
                account {
                    uri
                    name
                }
                identitiesList {
                    total
                }
                platformsList {
                    total
                }
                cartsList {
                    total
                }
            }
        }
    }`,
  keyOutput: "getMyGroupsList"
};
