/* @flow */
import React from "react";
import withAppSync from "../../../AppsyncHOC";
import "./style.less";
import GraphQl from "../../../../graphQL";
import Loading from "../../../../components/loading";
import Activity from "./activity";

type propTypes = {
  api: GraphQl
};
type stateTypes = {
  history: Array<Object>,
  ready: boolean,
  error: Object
};

class UserRecentHistory extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      ready: false,
      error: undefined
    };
  }

  componentDidMount() {
    this.props.api.user
      .getRecentUserHistory({ limit: 50 })
      .then((history) => {
        this.setState({
          history,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  }

  render() {
    return (
      <div className={"UserRecentHistory"}>
        <h5 className="border-title">My recent activities</h5>
        {this.state.error && (
          <ul className={"list-group recent-activities-list"}>
            <li className="text-center">Not available</li>
          </ul>
        )}
        <div className="">
          {!this.state.ready && <Loading message={"activities"} />}
        </div>
        {!this.state.error && this.state.ready && this.state.history && (
          <ul className={"list-group recent-activities-list"}>
            {this.state.history.map((activity) => (
              <Activity key={activity.activityid} activity={activity} />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withAppSync(UserRecentHistory);
