export const listAccelerationTemplatesProject = {
  query: `query Q ($projectUri: String!, $options: QueryOptions) {
        listAccessesToTemplatesFromProject (cartUri: $projectUri, options: $options) {
            limit
            offset
            total
            results {
                id
                monitoring_email
                acceleration_template { id name description link_to_doc }
                network { id name }
                platform { uri name aws }
                template_projects(cartUri: $projectUri) {
                    id
                    package_name
                    github_url
                    version_deployed
                    updatedat
                    last_status
                    stack_id
                    custom_tags {
                        Key
                        Value
                    }
                }
            }
        }
    }
    `,
  keyOutput: "listAccessesToTemplatesFromProject"
};

/*
version_deployed
last_status
updatedat
 */
