/* @flow */

import React from "react";
import ItemFromList from "../../../../components/itemFromList";
import { formatDate } from "../../../../utils/date";
import Links from "../../../links";
import MoreDetailsNotification from "../MoreDetailsNotification";

type propTypes = {
  share: {
    id?: string,
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    dataset: {
      access: string,
      name: string,
      uri: string
    },
    updatedat: ?string,
    message: string
  }
};

const DatasetShareRequestRejected = ({ share }: propTypes) => {
  const { dataset } = share;
  const title = (
    <div className="share-title">
      {share && share.id && (
        <div className="share-title mb-2">
          <Links.Share.View
            shareId={(share || {}).id || "null"}
            state={{ share }}
          >
            {`${share.group.name} share request for dataset ${share.dataset.name}`}
          </Links.Share.View>
        </div>
      )}
      {"Your share requested on "}
      <Links.Dataset.View uriDataset={dataset.uri}>
        {dataset.name}
      </Links.Dataset.View>
      {" for your group "}
      <Links.Group.View
        uriGroup={share.group.uri}
        state={{ group: share.group }}
      >
        {share.group.name}
      </Links.Group.View>
      {`, from ${share.group.account.name}, has been rejected.`}
    </div>
  );

  const description = (
    <React.Fragment>
      <div className="share-description">
        <span style={{ color: "red", fontWeight: "600" }}>{"Rejected "}</span>
        {`the ${formatDate(share.updatedat, false)}`}
      </div>
      <MoreDetailsNotification share={share} />
    </React.Fragment>
  );

  return (
    <ItemFromList
      className={"a-share"}
      name={title}
      description={description}
      hideLabelName
      hideLabelDescription
    />
  );
};

DatasetShareRequestRejected.defaultProps = {
  share: {
    group: {
      uri: ":::::::",
      name: "-",
      account: {
        name: "-"
      }
    },
    dataset: {
      access: "read",
      uri: ":::::::",
      name: "-"
    },
    updatedat: undefined,
    message: ""
  }
};

export default DatasetShareRequestRejected;
