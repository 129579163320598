export const queryGetDatasetProjects = {
  query: `
  query Q ($datasetUri: String!) {
    getDatasetUsage(datasetUri: $datasetUri) {
      uri
      name
      group{
        uri
        name
      }
    }
  }
`,
  keyOutput: "getDatasetUsage"
};
