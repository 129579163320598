/* @flow */

import React from "react";
import { connect } from "react-redux";
import Error from "../../../components/error";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import "./style.less";
import PoliciesList from "./list";
import AddPolicy from "./addPolicy";
import withAppSync from "../../AppsyncHOC";

type propTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  isAdmin: boolean,
  isFetchingPolicies: boolean,
  selectedGroup?: Object,
  selectedEnvironment?: Object,
  newPolicy: boolean,
  error?: Object
};

class PolicyManagement extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingPolicies: false,
      newPolicy: false,
      isAdmin: false
    };
  }

  componentDidMount() {
    this.getPolicies();
  }

  callbackAddPolicy = () => {
    this.setState({ newPolicy: false });
  };

  getPolicies = () => {
    this.setState({ isFetchingPolicies: true }, () => {
      this.props.api.account
        .listPolicies(this.props.match.params.uriAccount)
        .then((policies) => {
          this.setState({
            isFetchingPolicies: false,
            isAdmin: policies.results.is_admin
          });
        })
        .catch((error) => {
          this.setState({
            isFetchingPolicies: false,
            error
          });
        });
    });
  };

  renderNewPolicy() {
    if (!this.state.isFetchingPolicies) {
      if (this.state.isAdmin) {
        return (
          <div className="create-container">
            <button
              type="button"
              className={!this.state.newPolicy ? "butn butn-check" : "butn"}
              onClick={() =>
                this.setState((prevState) => ({
                  newPolicy: !prevState.newPolicy
                }))
              }
            >
              {!this.state.newPolicy
                ? "Attach new policy"
                : "Return to the list"}
            </button>
          </div>
        );
      }

      return (
        <div className="">
          You need to be an administrator of this organization to manage
          policies
        </div>
      );
    }
    return false;
  }

  render() {
    return (
      <div className="policies-management">
        {this.state.error && (
          <Error error={this.state.error} path="PolicyManagement.list" />
        )}
        {this.renderNewPolicy()}
        <div className="form-container">
          {this.state.newPolicy && (
            <AddPolicy
              api={this.props.api}
              accountUri={this.props.match.params.uriAccount}
              callbackSuccess={this.callbackAddPolicy}
            />
          )}
          {!this.state.newPolicy && this.state.isAdmin && (
            <PoliciesList
              api={this.props.api}
              accountUri={this.props.match.params.uriAccount}
              showGlobalNotification={this.props.showGlobalNotification}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(PolicyManagement)));
