import React from "react";
import PropTypes from "prop-types";
import Logger from "../../../utils/logger";
import Error from "../../../components/error";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";

const Log = Logger("Uploadform");

class Uploadform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefix: "raw",
      startCrawler: true,
      file: null,
      succeeded: false,
      error: false,
      isFetching: false
    };
  }

  onStartCrawlerChange = (event) => {
    this.setState({
      startCrawler: event.target.checked
    });
  };

  onPrefixChange = (event) => {
    this.setState({
      prefix: event.target.value
    });
  };

  onFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 0) {
      if (file.size > 5000000000) {
        this.setState({
          file: null,
          error: "Please upload a file that does not exceed 5GB",
          succeeded: false
        });
      } else {
        this.setState({
          file,
          error: false,
          succeeded: false
        });
      }
    } else {
      this.setState({
        file: null,
        error: "You cannot upload an empty file.",
        succeeded: false
      });
    }
  };

  onClickUpload = () => {
    this.setState({
      isFetching: true,
      succeeded: false,
      error: false
    });
    return this.getPresignedUrl()
      .then((getPresignedUrlResponse) => {
        if (
          getPresignedUrlResponse.errors &&
          getPresignedUrlResponse.errors.length
        ) {
          Log.error("getPresignedUrl", getPresignedUrlResponse);
          return this.setState({
            isFetching: false,
            succeeded: false,
            error: getPresignedUrlResponse.errors
          });
        }

        Log.info(
          "Presigned URL generation succeeded with response ... ",
          getPresignedUrlResponse
        );
        return this.uploadFile(getPresignedUrlResponse);
      })
      .catch((getPresignedUrlError) => {
        this.setState({
          isFetching: false,
          succeeded: false,
          error: getPresignedUrlError
        });
      });
  };

  getPresignedUrl = () =>
    this.props.api.dataset.presignedUrl(
      this.props.dataset.uri,
      this.state.file.name,
      this.state.prefix
    );

  uploadFile = (getPresignedUrlResponse) => {
    const body = new FormData();
    body.append("file", this.state.file);
    const request = new Request(getPresignedUrlResponse.url, {
      method: "PUT",
      body: this.state.file,
      headers: new Headers(JSON.parse(getPresignedUrlResponse.headers))
    });
    return fetch(request)
      .then((uploadResponse) => {
        if (
          this.props.dataset.type === "structured" &&
          this.state.startCrawler
        ) {
          Log.info("Upload succeeded with response ... ", uploadResponse);
          return this.startDetectSchema();
        }
        Log.info("Upload succeeded with response ... ", uploadResponse);
        return this.setState({
          isFetching: false,
          succeeded: true,
          error: false
        });
      })
      .catch((uploadError) => {
        Log.info("Upload failed with error ...", uploadError);
        this.setState({
          isFetching: false,
          succeeded: false,
          error: uploadError
        });
      });
  };

  detectSchema = () => {
    const accountUri = getAccountUriFromUri(this.props.dataset.uri);
    return this.props.api.dataset.detectSchema(
      accountUri,
      this.props.dataset.uri,
      this.state.prefix
    );
  };

  startDetectSchema = () =>
    this.detectSchema(this.props.dataset.uri)
      .then((detectSchemaResponse) => {
        Log.info(
          "Automatic schema detection succeeded with response ... ",
          detectSchemaResponse
        );
        this.setState({
          isFetching: false,
          succeeded: true,
          error: false
        });
      })
      .catch((detectSchemaError) => {
        this.setState({
          isFetching: false,
          succeeded: false,
          error: detectSchemaError
        });
      });

  render() {
    if (this.state.succeeded) {
      return (
        <div>
          <div>Upload Success !</div>
        </div>
      );
    }

    if (this.state.isFetching) {
      return (
        <div>
          Wait during upload...
          <i className="fas fa-circle-notch fa-spin" />
        </div>
      );
    }
    return (
      <div>
        {this.state.error && (
          <Error
            title={"Issue with upload... :("}
            error={
              this.state.error && this.state.error.message === "Failed to fetch"
                ? "It seems you have an issue with S3..."
                : this.state.error
            }
            path={"Uploadform"}
            displayError
            hideComment
            stringOnly
          />
        )}
        <div>
          <div className="input-group-prepend">
            <label className="input-group-text">
              {this.props.dataset.arn.replace("arn:aws:s3:::", "s3://")}/
            </label>
            <input
              name="prefix"
              className="form-control bg-white"
              placeholder="prefix"
              required
              onChange={this.onPrefixChange}
              value={this.state.prefix}
            />
          </div>
          <small className="attribute-label">
            {`Upload data from file ${
              this.props.dataset.type === "structured"
                ? " (supported extensions : `csv`,`gz`,`json`,`tsv`,`xlsx`,`xls`,`xlsm`):"
                : ""
            }`}
          </small>
          <div className="input-group">
            <div className="custom-file">
              {this.props.dataset.type === "structured" ? (
                <input
                  id={"inputGroupFile"}
                  type="file"
                  className="bg-white custom-file-input"
                  aria-describedby="inputGroupFileAddon04"
                  onChange={this.onFileChange}
                  accept={"*"}
                />
              ) : (
                <input
                  id={"inputGroupFile"}
                  type="file"
                  className="bg-white custom-file-input"
                  aria-describedby="inputGroupFileAddon04"
                  onChange={this.onFileChange}
                />
              )}
              <label className="custom-file-label" htmlFor="inputGroupFile">
                {!this.state.file ? "Select a file" : this.state.file.name}
              </label>
            </div>
          </div>
          {this.props.dataset.type === "structured" ? (
            <div className="form-group">
              <input
                id="startCrawler"
                className={"checkbox-facet"}
                type="checkbox"
                checked={this.state.startCrawler}
                onClick={this.onStartCrawlerChange}
              />
              <label className="fas" htmlFor="startCrawler">
                <span className="labelCustom">
                  Run a crawler to infer schema
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          <div className="mt-3 ml-8 input-group-append">
            <button
              className="file butn"
              type="button"
              onClick={this.onClickUpload}
              disabled={
                !this.state.prefix || !this.state.file || this.state.isFetching
              }
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Uploadform.propTypes = {
  dataset: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string,
    bucket: PropTypes.string,
    arn: PropTypes.string
  }),
  api: PropTypes.shape({
    dataset: PropTypes.shape({
      presignedUrl: PropTypes.func,
      detectSchema: PropTypes.func
    })
  })
};

export default Uploadform;
