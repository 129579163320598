export const getRedshiftClusterGroups = {
  query: `query Q ($clusterUri:String!) {
        getRedshiftCluster (clusterUri:$clusterUri) {
          groups{
            uri 
            name
            description
            tags
            resource_name
            account {
                    uri
                    name
                }
          }
        }
    }
    `,
  keyOutput: "getRedshiftCluster.groups"
};
