export const createRequest = {
  query: `mutation Q ($datasetUri: String!, $groupUri: String!, $message: String) {
        requestAccess(datasetUri: $datasetUri, groupUri: $groupUri, message: $message) {
          group {
                uri
                name
                account {
                    uri
                    name
                }
            }
            dataset {
                uri
                name
                access
                can_share
                datasource {
                    uri
                    name
                }
            }
            createdat
            updatedat
            request_status
        }
      }
    `,
  keyOutput: "requestAccess"
};
