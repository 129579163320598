export const editAccelerationTemplateFromProject = {
  query: `mutation Q ($templateProjectId: String!, $input: UpdateTemplateProjectInput) {
        updateTemplateProject(templateProjectId: $templateProjectId, input: $input) {
            id
            package_name
            stack_name
            github_url
        }
    }
    `,
  keyOutput: "updateTemplateProject"
};
