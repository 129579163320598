/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Breadcrumb from "../../../components/breadcrumb";
import { updateMessage } from "../../globalNotifications/actions";
import "./view.less";
import DatasetsList from "./DatasetsList";
import DatasetModal from "./DatasetModal";
import DatasourceActions from "./DatasourceActions";
import DatasourceModal from "./DatasourceModal";
import Tabs from "../../../components/tabs";
import DatasourceProviders from "./DatasourceProviders";
import { getAccountUriFromAccountId } from "../../../utils/toolsForUri";
import GraphQl from "../../../graphQL";
import config from "../../../config";
import DatasourceAccess from "./DatasourceAccess";
import DatasourceLogs from "./DatasourceLogs";
import routes from "../../routes";
import DatasourceDetails from "./DatasourceDetails";
import DetachDatasourceModal from "./DetachDatasourceModal";
import DataReplication from "./DataReplication";
import DatasourceShares from "./DatasourceShares";
import DatasourceSqlLab from "../../sqlLab/DatasourceSqlLab";
import logoRedshift from "../../logos/redshift-logo.png";
import logoS3 from "../../logos/s3-logo.png";
import RSDatasourceActions from "../RSDatasource/view/RSDatasourceActions";
import RSDatasourceDetails from "../RSDatasource/view/RSDatasourceDetails";
import RSDatasourceSqlLab from "../../sqlLab/RSDatasourceSqlLab";
import RSDatasourceAccess from "../RSDatasource/view/RSDatasourceAccess";
import { isRSSourceType, isS3SourceType } from "../../../utils/constants";
import Links from "../../links";

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriDatasource: string
    },
    url: string
  },
  goTo: Function,
  api: GraphQl,
  location: {
    hash: string,
    pathname: string,
    state: {
      account: {
        name: string,
        uri: string
      },
      playground: {
        name: string
      },
      datasource: {
        name: string
      }
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  error: boolean,
  datasource: Object,
  datasetModal: boolean | Object,
  datasourceModal: boolean,
  detachModal: boolean,
  detachForEquansFeatureEnabled: boolean,
  userCanDeleteInSandbox: boolean,
  hideInfoPanel: boolean,
  showCollibra: boolean,
  isRefreshing: boolean,
  rsDatasource: Object,
  readyRsDatasource: boolean,
  isSynchronizing: boolean,
  hideProjectLink: boolean
};

class DatasourceView extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  constructor(props: Object) {
    super(props);
    this.state = {
      ready: false,
      datasource: {},
      error: false,
      datasetModal: false,
      datasourceModal: false,
      detachModal: false,
      detachForEquansFeatureEnabled: false,
      userCanDeleteInSandbox: false,
      hideInfoPanel: false,
      showCollibra: false,
      isRefreshing: false,
      rsDatasource: {},
      readyRsDatasource: false,
      isSynchronizing: false,
      hideProjectLink: false
    };
  }

  async componentDidMount() {
    this.getRSDatasource();
    this.isDetachForEquansFeatureEnabled();
    this.getDatasource();
    this.getUserCanDeleteInSandbox();
    const isCollibraFeatureEnabled = this.isCollibraFeatureEnabled();
    const isCollibraLinkAvailable = this.isCollibraLinkAvailable();
    Promise.all([isCollibraLinkAvailable, isCollibraFeatureEnabled]).then(
      (items) => {
        this.setState({
          showCollibra: items[0] && items[1]
        });
      }
    );
    this.checkRSDatasourceStackStatus();
  }

  checkRSDatasourceStackStatus = () => {
    this.interval = setInterval(this.loadRsDatasourceStack, 20000);
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  isCollibraFeatureEnabled() {
    const uriAccount = this.props.match.params.uriAccount.includes(
      "uri:account:"
    )
      ? this.props.match.params.uriAccount
      : `uri:account:${this.props.match.params.uriAccount}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("COLLIBRA", uriAccount)
      .then((showCollibra) => {
        return showCollibra;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  isCollibraLinkAvailable() {
    const uriAccount = this.props.match.params.uriAccount.includes(
      "uri:account:"
    )
      ? this.props.match.params.uriAccount
      : `uri:account:${this.props.match.params.uriAccount}`;
    return this.props.api.collibra
      .list(uriAccount)
      .then((links) => {
        return links && links.length > 0;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getUserCanDeleteInSandbox = async () => {
    let canDelete = true;
    const sandboxDatasourceId =
      "uri:datasource:445391:Training sessions environment:dataikutraininglabdatasource";
    if (this.props.match.params.uriDatasource === sandboxDatasourceId) {
      canDelete = false;
      const groups = await this.props.api.identity.listMyGroups({
        offset: 0,
        limit: 99,
        filters: []
      });
      const hasTrainingGroup = !!groups.results.find(
        (i) => i.uri === "uri:group:445391:Online Training"
      );
      const hasTrainersGroup = !!groups.results.find(
        (i) => i.uri === "uri:group:445391:CDH Lab Trainers"
      );
      if (hasTrainingGroup && hasTrainersGroup) {
        canDelete = true;
      }
    }
    this.setState(() => ({
      userCanDeleteInSandbox: canDelete
    }));
  };

  isDetachForEquansFeatureEnabled = () => {
    const accountUri = `uri:account:${
      this.props.match.params.uriDatasource.split(":")[2]
    }`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DETACH_FOR_EQUANS", accountUri)
      .then((detachForEquansFeatureEnabled) => {
        this.setState({ detachForEquansFeatureEnabled });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getDatasource = () => {
    this.setState({
      isRefreshing: true
    });
    this.props.api.datasource
      .get(this.props.match.params.uriDatasource)
      .then((items) => {
        this.setState({
          ready: true,
          datasource: items,
          isRefreshing: false
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true,
          isRefreshing: false
        });
      });
  };

  getRSDatasource = () => {
    this.setState({
      readyRsDatasource: false
    });
    this.props.api.datasource
      .getRSDatasource(this.props.match.params.uriDatasource)
      .then((dsrc) => {
        this.setState({
          readyRsDatasource: true,
          rsDatasource: dsrc
        });
      })
      .catch((error) => {
        this.setState({
          error,
          readyRsDatasource: true
        });
      });
  };

  syncDatabase = () => {
    this.setState({
      isSynchronizing: true
    });
    this.props.api.datasource
      .syncRSDatasourceDatabase(this.props.match.params.uriDatasource)
      .then(() => {
        this.setState({
          isSynchronizing: false
        });
        this.props.showGlobalNotification({
          message: "Database sync started...",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isSynchronizing: true
        });
        this.props.showGlobalNotification({
          message: "Database sync failed",
          type: "alert"
        });
      });
  };

  loadRsDatasourceStack = () => {
    this.props.api.datasource
      .getRSDatasource(this.props.match.params.uriDatasource)
      .then((dsrc) => {
        this.setState({
          rsDatasource: dsrc
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  openDatasetModal = (dataset) => this.setState({ datasetModal: { dataset } });

  closeDatasetModal = () => this.setState({ datasetModal: false });

  openDatasourceModal = () => this.setState({ datasourceModal: true });

  closeDatasourceModal = () => this.setState({ datasourceModal: false });

  openDetachDatasourceModal = () => this.setState({ detachModal: true });

  closeDetachDatasourceModal = () => this.setState({ detachModal: false });

  getTabs = (account) => {
    const tabs = [
      {
        name: "Datasets",
        index: "datasets",
        route: `${this.props.match.url}#datasets`,
        content: (
          <DatasetsList
            account={account}
            playground={(this.props.location.state || {}).playground}
            datasource={this.state.datasource}
            openDatasetModal={this.openDatasetModal}
            goTo={this.props.goTo}
          />
        )
      }
    ];

    if (
      this.state.datasource.user_roles &&
      this.state.datasource.user_roles.includes("owner")
    ) {
      tabs.push({
        name: "Shares",
        index: "shares",
        route: `${this.props.match.url}#shares`,
        content: (
          <DatasourceShares
            datasource={this.state.datasource}
            showGlobalNotification={this.props.showGlobalNotification}
          />
        )
      });
      if (isS3SourceType(this.state.datasource.source_type)) {
        tabs.push({
          name: "Loaders",
          index: "loaders",
          route: `${this.props.match.url}#loaders`,
          content: (
            <DatasourceProviders
              api={this.props.api}
              datasource={this.state.datasource}
            />
          )
        });
        tabs.push({
          name: "Access",
          index: "access",
          route: `${this.props.match.url}#access`,
          content: (
            <DatasourceAccess
              goTo={this.props.goTo}
              api={this.props.api}
              datasource={this.state.datasource}
            />
          )
        });

        tabs.push({
          name: "Data replication",
          index: "datareplication",
          route: `${this.props.match.url}#datareplication`,
          content: <DataReplication datasource={this.state.datasource} />
        });

        tabs.push({
          name: <span>SQL Lab</span>,
          index: "sqllab",
          route: `${this.props.match.url}#sqllab`,
          content: (
            <DatasourceSqlLab
              api={this.props.api}
              datasource={this.state.datasource}
              hideInfo={this.hideInfo}
              showInfo={this.showInfo}
            />
          )
        });
      }
      if (isRSSourceType(this.state.datasource.source_type)) {
        tabs.push({
          name: <span>Access</span>,
          index: "access",
          route: `${this.props.match.url}#access`,
          content: (
            <RSDatasourceAccess
              uriAccount={this.props.match.params.uriAccount}
              rsdatasource={this.state.rsDatasource}
              datasource={this.state.datasource}
            />
          )
        });
        tabs.push({
          name: <span>SQL Lab</span>,
          index: "sqllab",
          route: `${this.props.match.url}#sqllab`,
          content: (
            <RSDatasourceSqlLab
              api={this.props.api}
              datasource={this.state.datasource}
              rsDatasource={this.state.rsDatasource}
            />
          )
        });
      }
      tabs.push({
        name: "Logs",
        index: "logs",
        route: `${this.props.match.url}#logs`,
        content: (
          <DatasourceLogs
            api={this.props.api}
            datasource={this.state.datasource}
          />
        )
      });
    }

    return tabs;
  };

  hideInfo = () => {
    this.setState((prevState) => ({ hideInfoPanel: !prevState.hideInfoPanel }));
  };

  showInfo = () => {
    if (this.state.hideInfoPanel) {
      this.setState({ hideInfoPanel: false });
    }
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasourceView"} />;
    if (!this.state.ready || !this.state.readyRsDatasource)
      return <Loading message={"datasource view"} />;

    const account = (this.props.location.state || {}).account || {
      uri: getAccountUriFromAccountId(this.state.datasource.accountid)
    };
    return (
      <div className={"datasource"}>
        <div className="bread-line">
          <div className="title-content">
            <div>
              <Breadcrumb
                state={this.props.location.state}
                view={
                  <span>
                    {isS3SourceType(this.state.datasource.source_type) && (
                      <img
                        className="connector-logo-img mr-2"
                        src={logoS3}
                        alt="S3"
                        width={43}
                        height={41}
                      />
                    )}
                    {isRSSourceType(this.state.datasource.source_type) && (
                      <img
                        className="connector-logo-img mr-2"
                        src={logoRedshift}
                        alt="Redshift Serverless"
                        width={43}
                        height={41}
                      />
                    )}
                    {this.state.datasource.name || ""}
                  </span>
                }
                helpOn={{
                  content: (
                    <div>
                      <div>
                        <b>- Datasource</b>
                      </div>
                      <div>Manage your datasource.</div>
                      <br />
                      <div>
                        <b>- Loaders</b>
                      </div>
                      <div>
                        Data loaders are AWS principals you allow to WRITE to
                        your datasource
                      </div>
                      <div>
                        Everytime you add a data provider, the bucket policy and
                        kms key policy will be updated accordingly
                      </div>
                    </div>
                  ),
                  helpCenterLink: `${config.HELP_CENTER}`
                }}
              />
            </div>
          </div>
        </div>

        <div className="create-container">
          {this.state.datasource.user_roles &&
            this.state.datasource.user_roles.includes("owner") &&
            isS3SourceType(this.state.datasource.source_type) && (
              <DatasourceActions
                goTo={this.props.goTo}
                account={account}
                playground={(this.props.location.state || {}).playground}
                datasource={this.state.datasource}
                openDatasourceModal={this.openDatasourceModal}
                openDetachDatasourceModal={this.openDetachDatasourceModal}
                detachForEquansFeatureEnabled={
                  this.state.detachForEquansFeatureEnabled
                }
                userCanDeleteInSandbox={this.state.userCanDeleteInSandbox}
                showCollibra={this.state.showCollibra}
              />
            )}
          {this.state.datasource.user_roles &&
            this.state.datasource.user_roles.includes("owner") &&
            isRSSourceType(this.state.datasource.source_type) && (
              <RSDatasourceActions
                goTo={this.props.goTo}
                account={account}
                playground={(this.props.location.state || {}).playground}
                datasource={this.state.datasource}
                openDatasourceModal={this.openDatasourceModal}
                openDetachDatasourceModal={this.openDetachDatasourceModal}
                detachForEquansFeatureEnabled={
                  this.state.detachForEquansFeatureEnabled
                }
                userCanDeleteInSandbox={this.state.userCanDeleteInSandbox}
                showCollibra={this.state.showCollibra}
                refreshDatasource={this.getDatasource}
                isRefreshing={this.state.isRefreshing}
                rsdatasource={this.state.rsDatasource}
                isSynchronizing={this.state.isSynchronizing}
                syncDatabase={this.syncDatabase}
              />
            )}
        </div>
        {isRSSourceType(this.state.datasource.source_type) &&
          this.state.rsDatasource &&
          this.state.rsDatasource.stack_error && (
            <div className={"mb-3 create-container"}>
              <div className="IAM-error">
                <Alert severity="error">
                  <AlertTitle>Stack deployment failed</AlertTitle>
                  {this.state.rsDatasource.stack_error}
                </Alert>
              </div>
            </div>
          )}
        {isRSSourceType(this.state.datasource.source_type) &&
          this.state.rsDatasource &&
          this.state.rsDatasource.stack_status.includes("PROGRESS") && (
            <div className={"mb-3 create-container"}>
              <div className="IAM-error">
                <Alert severity="info">
                  <AlertTitle>
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />{" "}
                    Stack deployment in progress
                  </AlertTitle>
                  <div>
                    <span>
                      Some features are not available until the deployment ends.
                    </span>
                  </div>
                </Alert>
              </div>
            </div>
          )}
        {!this.state.hideProjectLink &&
          isRSSourceType(this.state.datasource.source_type) &&
          this.state.rsDatasource &&
          this.state.rsDatasource.project &&
          this.state.rsDatasource.project.cart_uri && (
            <div className={"mb-3 create-container"}>
              <div className="IAM-error">
                <Alert
                  severity={"info"}
                  action={
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        this.setState({ hideProjectLink: true });
                      }}
                    >
                      x
                    </span>
                  }
                >
                  This datasource was automatically created from the project{" "}
                  <b>
                    <Links.Cart.View
                      cartUri={this.state.rsDatasource.project.cart_uri}
                    >
                      {this.state.rsDatasource.project.cart_name}
                    </Links.Cart.View>
                  </b>
                </Alert>
              </div>
            </div>
          )}
        <div className={this.state.hideInfoPanel ? "" : "datasource-body"}>
          {!this.state.hideInfoPanel && (
            <div className="datasource-body-left">
              {this.state.datasource.user_roles &&
                this.state.datasource.user_roles.includes("owner") &&
                isS3SourceType(this.state.datasource.source_type) && (
                  <DatasourceDetails datasource={this.state.datasource} />
                )}
              {this.state.datasource.user_roles &&
                this.state.datasource.user_roles.includes("owner") &&
                isRSSourceType(this.state.datasource.source_type) && (
                  <RSDatasourceDetails
                    datasource={this.state.datasource}
                    rsdatasource={this.state.rsDatasource}
                  />
                )}
            </div>
          )}
          <div
            className={this.state.hideInfoPanel ? "" : "datasource-body-right"}
          >
            <Tabs
              defaultActiveIndex={"datasets"}
              isPreload={false}
              tabs={this.getTabs(account)}
            />
          </div>
        </div>

        {typeof this.state.datasetModal === "object" && (
          <DatasetModal
            dataset={this.state.datasetModal.dataset}
            hide={this.closeDatasetModal}
            getDatasource={this.getDatasource}
          />
        )}

        {this.state.datasourceModal && (
          <DatasourceModal
            datasource={this.state.datasource}
            hide={this.closeDatasourceModal}
            showGlobalNotification={this.props.showGlobalNotification}
            goBack={() => this.props.goTo({ route: routes.MyData.View })}
          />
        )}
        {this.state.detachModal && (
          <DetachDatasourceModal
            datasource={this.state.datasource}
            hide={this.closeDetachDatasourceModal}
            showGlobalNotification={this.props.showGlobalNotification}
            goBack={() => this.props.goTo({ route: routes.MyData.View })}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(DatasourceView));
