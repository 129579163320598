/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import Col from "../../../../components/col";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import routes from "../../../routes";
import Breadcrumb from "../../../../components/breadcrumb";
import Tabs from "../../../../components/tabs";
import RSNamespaceInfo from "../components/RSNamespaceInfo";
import Modal from "../../../../components/modal";
import "./view.less";
import RSNamespaceAccess from "../components/access/RSNamespaceAccess";
import RSNamespaceUsage from "../components/RSNamespaceUsage";
import { Alert, AlertTitle } from "@material-ui/lab";

type propTypes = {
  match: {
    params: {
      namespaceUri: string
    },
    url: string
  },
  location: {
    state: Object
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  auth: { getUser: Function }
};

type stateTypes = {
  ready: boolean,
  namespace: Object,
  error: Object,
  deleteError: Object,
  loadingDeleteRSNamespace: boolean,
  deleteRSNamespaceModal: boolean
};

class RSNamespaceView extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      deleteError: null,
      loadingDeleteRSNamespace: false,
      deleteRSNamespaceModal: false,
      cart: null
    };
  }

  componentDidMount() {
    this.getRSNamespace();
    this.checkRSNamespaceStackStatus();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  getRSNamespace = () => {
    this.setState({
      ready: false
    });
    this.props.api.analytics
      .getRSNamespace(this.props.match.params.namespaceUri)
      .then((namespace) => {
        this.setState({
          namespace,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  checkRSNamespaceStackStatus = () => {
    this.interval = setInterval(this.loadRsNamespaceStack, 20000);
  };

  loadRsNamespaceStack = () => {
    this.props.api.analytics
      .getRSNamespace(this.props.match.params.namespaceUri)
      .then((namespace) => {
        this.setState({
          namespace
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  openDeleteRSNamespaceModal = () =>
    this.setState({ deleteRSNamespaceModal: true, deleteError: null });

  closeDeleteRSNamespaceModal = () =>
    this.setState({ deleteRSNamespaceModal: false, deleteError: null });

  deleteRSNamespaceModal() {
    return (
      <Modal
        title={"Confirm Deletion"}
        body={`Delete the namespace : "${this.state.namespace.name}" from commonDataHub and AWS ?`}
        errorMessage={this.state.deleteError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDeleteRSNamespaceModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.deleteRSNamespace()}
          >
            Delete
          </button>
        ]}
      />
    );
  }

  deleteRSNamespace = () => {
    this.setState({
      loadingDeleteRSNamespace: true
    });
    this.props.api.analytics
      .deleteRSNamespace(this.state.namespace.uri, true)
      .then((response) => {
        this.props.showGlobalNotification({
          message: "RS Namespace deletion started",
          type: "success"
        });
        this.props.goTo({
          route: `${routes.Playground.Analytics}`,
          params: {
            uriPlayground: this.state.namespace.platform.uri,
            uriAccount: `uri:account:${this.state.namespace.platform.accountid}`
          }
        });
        this.setState({
          loadingDeleteRSNamespace: false
        });
      })
      .catch((deleteError) => {
        this.setState({
          deleteError,
          loadingDeleteRSNamespace: false
        });
      });
  };

  getTabs = () => {
    const tabs = [
      {
        name: "Information",
        index: "information",
        route: `${this.props.match.url}#information`,
        content: (
          <div id="nav-information">
            <RSNamespaceInfo
              namespace={this.state.namespace}
              ready={this.state.ready}
              error={this.state.error}
            />
          </div>
        )
      },
      {
        name: "Datasources",
        index: "datasources",
        route: `${this.props.match.url}#datasources`,
        content: (
          <div id="nav-information">
            <RSNamespaceUsage namespace={this.state.namespace} />
          </div>
        )
      },
      {
        name: "Access",
        index: "access",
        route: `${this.props.match.url}#access`,
        content: (
          <div id="nav-information">
            <RSNamespaceAccess
              namespace={this.state.namespace}
              ready={this.state.ready}
              error={this.state.error}
            />
          </div>
        )
      }
    ];
    return tabs;
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"RSNamespaceView"} />;
    if (!this.state.ready) return <Loading message={"Namespace view"} />;
    return (
      <div className="cartView">
        <Breadcrumb
          view={`Redshift Namespace - ${this.state.namespace.name || ""}`}
        />
        <div className="create-container">
          <button
            className="butn butn-delete"
            onClick={() => this.openDeleteRSNamespaceModal()}
            type={"button"}
          >
            {this.state.loadingDeleteRSNamespace && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            <i className="fas fa-trash-alt"></i>
            <span className="butn-text">Delete</span>
          </button>
        </div>

        {this.state.namespace && this.state.namespace.stack_error && (
          <div className={"mb-3"}>
            <div className="IAM-error">
              <Alert severity="error">
                <AlertTitle>Stack deployment failed</AlertTitle>
                {this.state.namespace.stack_error}
              </Alert>
            </div>
          </div>
        )}
        {this.state.namespace &&
          this.state.namespace.stack_status.includes("PROGRESS") && (
            <div className={"mb-3"}>
              <div className="IAM-error">
                <Alert severity="info">
                  <AlertTitle>
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />{" "}
                    Stack deployment in progress
                  </AlertTitle>
                  <div>
                    <span>
                      Some features are not available until the deployment ends.
                    </span>
                  </div>
                </Alert>
              </div>
            </div>
          )}
        <div className={"row justify-content-left"}>
          <Col size={12}>
            <Tabs
              isPreload={false}
              defaultActiveIndex={"information"}
              tabs={this.getTabs()}
            />
          </Col>
        </div>
        <div>
          {this.state.deleteRSNamespaceModal && this.deleteRSNamespaceModal()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(withGoTo(RSNamespaceView))
);
