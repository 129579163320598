export const queryGetShare = {
  query: `query Q ($shareId: ID!) {
        getShare(shareId: $shareId) {
            id
            createdat
            updatedat
            request_status
            message
            reject_message
            all_tables
            parent
            child
            group {
                uri
                name
                account {
                    uri
                    name
                }
            }
            dataset {
                uri
                name
                access
                expiration_date
                can_share
                owneruri
                owner{
                    uri
                    name
                }
            }
            user_role
            items {
                total
                offset
                limit
                results {
                    id
                    table_name
                    stage_id
                    stage{
                        table
                        id
                        uri
                    }
                    data_filter_id
                    data_filter {
                        id
                        name
                        columns
                        description
                    }
                }
            }
        }
    }`,
  keyOutput: "getShare"
};
