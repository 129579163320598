/* @flow */
import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../../../AppsyncHOC";
import Logger from "../../../../../utils/logger";
import { updateMessage } from "../../../../globalNotifications/actions";
import GraphQl from "../../../../../graphQL";
import Error from "../../../../../components/error";
import Col from "../../../../../components/col";
import Row from "../../../../../components/row";
import Loading from "../../../../../components/loading";
import "./style.less";
import SelectInfinite from "../../../../../components/SelectInfinite";
import SelectClassic from "../../../../../components/SelectClassic";
import { capitalize } from "../../../../../utils/string";

const Log = Logger("FeatureToggleForm");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  reloadToggles: Function
};

type stateTypes = {
  ready: boolean,
  groupReady: boolean,
  isFetching: boolean,
  error: boolean,
  featureToggle: Object,
  featureCodes: Array<string>,
  accounts: Array<Object>,
  accountUri: string,
  groups: Array<Object>,
  groupUri?: Object,
  errorSubmit: Object,
  identityType: Object,
  showNewFeatureCode: boolean,
  organisationOptionSelected?: Object,
  groupOptionSelected?: Object,
  totalGroups: number
};

class FeatureToggleForm extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      groupReady: false,
      isFetching: false,
      error: false,
      identityType: "group",
      featureToggle: {
        code: null,
        version: "BETA",
        environment: "prod",
        description: null,
        userid: null,
        status: "ENABLED",
        groupuri: null
      },
      featureCodes: [],
      groups: [],
      totalGroups: 0,
      groupUri: null,
      accounts: [],
      accountUri: "",
      errorSubmit: undefined,
      showNewFeatureCode: false,
      organisationOptionSelected: null,
      groupOptionSelected: null
    };
  }

  componentDidMount() {
    this.listFeatureCodes();
    this.getAccounts();
  }

  listFeatureCodes = () =>
    this.props.api.featureToggle
      .listFeatureCodes()
      .then((featureCodes) => {
        this.setState({
          featureCodes,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  getAccounts = () =>
    this.props.api.account
      .getAllAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  getGroupsFromAccount = (offset = 0) =>
    this.props.api.account
      .listGroups(this.state.accountUri, { offset })
      .then((response) => {
        const groups = response && response.results ? response.results : [];
        this.setState({
          groups,
          totalGroups: response.total,
          groupReady: true
        });
        return groups.map((g) => ({ label: g.name, value: g.uri }));
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  onSelectAccount = (organisationOptionSelected) => {
    this.setState(
      {
        accountUri: organisationOptionSelected.value,
        groupUri: undefined,
        organisationOptionSelected,
        groupOptionSelected: null,
        errorSubmit: undefined,
        groupReady: false
      },
      this.getGroupsFromAccount
    );
  };

  onSelectGroup = (groupOptionSelected) => {
    this.setState({
      groupOptionSelected,
      groupUri: groupOptionSelected.value,
      errorSubmit: undefined
    });
  };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const { featureToggle } = prevState;
      featureToggle[field] = value;
      return {
        featureToggle
      };
    });
  };

  handleIdentityTypeChange = (value) => {
    this.setState({ identityType: value });
    this.setState((prevState) => {
      const featureToggle = Object.assign({}, prevState.featureToggle);
      if (value === "user") {
        featureToggle.groupuri = null;
        this.setState({ groupUri: null, accountUri: "" });
      } else {
        featureToggle.gid = null;
        featureToggle.userid = null;
      }
      return { featureToggle };
    });
  };

  createFeatureToggle = () => {
    this.setState({ isFetching: true });
    const featureToggle = Object.assign({}, this.state.featureToggle);
    featureToggle.status = "ENABLED";
    featureToggle.groupuri =
      this.state.groupUri === "" || featureToggle.groupuri === ""
        ? null
        : this.state.groupUri;
    featureToggle.gid = this.state.featureToggle.gid || null;
    this.props.api.featureToggle
      .createFeatureToggle(featureToggle)
      .then(() => {
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "FeatureToggle Created",
          type: "success"
        });
        this.props.reloadToggles();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "Failed to Create Feature Toggle",
          type: "alert"
        });
        this.setState({ errorSubmit: error, isFetching: false });
      });
  };

  onSubmit = () => {
    if (!this.state.featureToggle.code) {
      return this.setState({ errorSubmit: "Feature Code is Mandatory" });
    }

    if (!this.state.groupUri && !this.state.featureToggle.gid) {
      return this.setState({ errorSubmit: "A group or user is mandatory" });
    }

    if (this.state.groupUri && this.state.featureToggle.gid) {
      return this.setState({ errorSubmit: "Choose a group or a user" });
    }

    this.setState({ errorSubmit: undefined, isFetching: true });

    return this.createFeatureToggle();
  };

  render() {
    return (
      <div className="admin-cdh-toggles">
        {this.state.error && (
          <Error error={this.state.error} path={"RequestForm"} stringOnly />
        )}
        {!this.state.ready && <Loading message={"Feature Toggle Form"} />}
        {!this.state.error && this.state.ready && (
          <div className="admin-cdh-toggles-form">
            <label className="label-form">Feature Code : </label>
            <div className="d-flex">
              <select
                className="custom-select"
                onChange={(event) =>
                  this.handleChange("code", event.target.value)
                }
                value={this.state.featureToggle.code || "default"}
              >
                ))}
                {[
                  <option key={"default"} value={"default"} disabled>
                    {"Select feature code"}
                  </option>
                ].concat(
                  this.state.featureCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))
                )}
              </select>
              <button
                className="butn butn-flat new-feature-code"
                type="button"
                onClick={() =>
                  this.setState((prevState) => ({
                    showNewFeatureCode: !prevState.showNewFeatureCode
                  }))
                }
              >
                {!this.state.showNewFeatureCode ? (
                  <i className="fas fa-plus butn-icon" />
                ) : (
                  <i className="fas fa-chevron-up butn-icon" />
                )}
              </button>
            </div>
            {this.state.showNewFeatureCode && (
              <div className="mt-4">
                <label className="label-form">New Feature Code : </label>
                <input
                  className="form-control"
                  placeholder="Feature Code 'LAKEFORMATION', 'POWERBI'"
                  onChange={(event) =>
                    this.handleChange("code", event.target.value)
                  }
                  value={this.state.featureToggle.code}
                />
              </div>
            )}
            <div className="mt-4">
              <label className="label-form">Version : </label>
              <input
                className="form-control"
                placeholder="'Alpha', 'Beta', 'Preview' ..."
                onChange={(event) =>
                  this.handleChange("version", event.target.value)
                }
                value={this.state.featureToggle.version}
              />
            </div>

            <div className="mt-4">
              <label className="label-form">User or Group : </label>
              <SelectClassic
                placeholder="Select type, user or group."
                options={[
                  { label: "Group", value: "group" },
                  { label: "User", value: "user" }
                ]}
                selectedOption={
                  this.state.identityType
                    ? {
                        label: capitalize(this.state.identityType),
                        value: this.state.identityType
                      }
                    : null
                }
                onSelectOption={(option) =>
                  this.handleIdentityTypeChange(option.value)
                }
              />
            </div>

            {this.state.identityType === "group" && (
              <div>
                <div className="mt-4">
                  <label className="label-form">
                    Select an organization :{" "}
                  </label>
                  <SelectClassic
                    placeholder="Select an organization"
                    options={this.state.accounts.map((a) => {
                      if (a.uri === "uri:account:000000")
                        return { value: a.uri, label: "All Engie" };
                      return { value: a.uri, label: a.name };
                    })}
                    selectedOption={this.state.organisationOptionSelected}
                    onSelectOption={this.onSelectAccount}
                  />
                </div>
                <div className="mt-4">
                  <label className="label-form">and Group : </label>

                  <SelectInfinite
                    isLoadingOptions={
                      !this.state.groupReady && !!this.state.accountUri
                    }
                    placeholder={
                      this.state.accountUri
                        ? "Select a group"
                        : "Select an organisation first"
                    }
                    isDisabled={!this.state.accountUri}
                    initialOptions={(this.state.accountUri
                      ? this.state.groups
                      : []
                    ).map((g) => ({ value: g.uri, label: g.name }))}
                    selectedOption={this.state.groupOptionSelected}
                    onSelectOption={this.onSelectGroup}
                    totalOptions={this.state.totalGroups}
                    loadMoreOptions={this.getGroupsFromAccount}
                    optionResults
                  />
                </div>
              </div>
            )}

            {this.state.identityType === "user" && (
              <div className="mt-4">
                <label className="label-form">User GID : </label>
                <input
                  className="form-control"
                  placeholder="User Group ID ZX0000"
                  onChange={(event) =>
                    this.handleChange("gid", event.target.value)
                  }
                  value={this.state.featureToggle.gid}
                />
              </div>
            )}
            <div className="mt-4">
              <label className="label-form">Description : </label>
              <textarea
                value={this.state.featureToggle.description}
                onChange={(event) =>
                  this.handleChange("description", event.target.value)
                }
                rows="2"
                className="form-control"
              />
            </div>

            <Row className="justify-content-center mt-4">
              {this.state.errorSubmit && (
                <Col size={12} style={{ color: "red", paddingBottom: "10px" }}>
                  <Error
                    error={this.state.errorSubmit}
                    path={"errorSubmit"}
                    stringOnly
                  />
                </Col>
              )}

              <button
                type={"button"}
                onClick={this.onSubmit}
                className={"butn"}
              >
                {this.state.isFetching && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Create
              </button>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(FeatureToggleForm));
