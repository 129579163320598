/* eslint-disable camelcase */
/* @flow */

import React from "react";
import withGoTo from "../../goToHOC";
import "./datasetInfo.less";
import { formatDate } from "../../../utils/date";
import { convertSize } from "../../../utils/size";
import HelpOn from "../../../components/helpOn";
import LevelOfMaturity from "./LevelOfMaturity";
import HelpLevelOfMaturity from "./form/components/helpLevelOfMaturity";

type propTypes = {
  dataset: Object
};

type stateTypes = {};

class DatasetInfo extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line class-methods-use-this
  standarDisplay(field, value, link) {
    // eslint-disable-line class-methods-use-this
    const v = (value && value.split("\n")) || [];
    let p = v.length > 1 ? v.map((i, key) => <p key={key}>{i}</p>) : value;
    if (typeof p !== "object") {
      if (link && p) {
        if (!p.startsWith("http")) {
          p = `https://${p}`;
        }
      }
    }

    return (
      <div className="display" style={{ width: "100%" }}>
        <div
          className="field-name"
          style={{
            display: "inline-table",
            width: "200px",
            textAlign: "left"
          }}
        >
          {field}
        </div>
        <div
          className="content text-capitalize"
          style={{
            marginLeft: "15px",
            display: "inline-table"
          }}
        >
          {link && p != null ? (
            <a href={p} target="_blank" rel="noopener noreferrer">
              {p}
            </a>
          ) : (
            p || "-"
          )}
        </div>
      </div>
    );
  }

  dataset_is_partioned = () => {
    // eslint-disable-line camelcase
    if (this.props.dataset.is_partitioned != null) {
      if (this.props.dataset.is_partitioned) {
        return "Yes";
      }
      return "No";
    }
    return null;
  };

  render() {
    const { dataset } = this.props;
    const {
      creator,
      type,
      tags,
      details,
      languages,
      size,
      visibility,
      shareable,
      is_registered_with_lakeformation, // eslint-disable-line camelcase
      update_frequency, // eslint-disable-line camelcase
      metadata_frequency, // eslint-disable-line camelcase
      maturity_level, // eslint-disable-line camelcase
      data_types, // eslint-disable-line camelcase
      s3_inventory_last_update // eslint-disable-line camelcase
    } = dataset;

    return (
      <div className={"dataset-info"}>
        <div className="display">
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Data Types
          </div>
          <div
            style={{
              marginLeft: "15px",
              display: "inline-table",
              width: "600px"
            }}
          >
            {data_types.length > 0
              ? data_types.map((dataType) => (
                  <div className="dataset-badge" key={dataType}>
                    <div className="circle-tag" />
                    {dataType}
                  </div>
                ))
              : "-"}
          </div>
        </div>

        <div className="display">
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Tags
          </div>
          <div
            style={{
              marginLeft: "15px",
              display: "inline-table",
              width: "600px"
            }}
          >
            {tags.split(",").filter((tag) => tag && tag.trim()).length > 0
              ? tags
                  .split(",")
                  .filter((tag) => tag && tag.trim())
                  .map((tag) => (
                    <div className="dataset-badge" key={tag}>
                      <div className="circle-tag"></div>
                      {tag}
                    </div>
                  ))
              : "-"}
          </div>
        </div>
        {creator && this.standarDisplay("Creator", creator.name)}
        {this.standarDisplay("Type", type)}
        {this.standarDisplay("Usage", visibility)}
        {this.standarDisplay(
          "Sharing Feature",
          shareable ? "Allowed" : "Blocked"
        )}
        {
          this.standarDisplay(
            "Registered with AWS Lake Formation",
            is_registered_with_lakeformation ? "Yes" : "No"
          ) /* eslint-disable-line camelcase */
        }
        {this.standarDisplay("Languages", languages.toString())}
        <div className="display" style={{ width: "100%" }}>
          <div
            className="field-name"
            style={{
              display: "inline-table",
              width: "200px",
              textAlign: "left"
            }}
          >
            Level of maturity
            <HelpOn content={<HelpLevelOfMaturity />} />
          </div>
          <div
            className="content"
            style={{
              marginLeft: "15px",
              display: "inline-table"
            }}
          >
            <LevelOfMaturity
              // eslint-disable-next-line camelcase
              step={maturity_level}
              isLock
            />
          </div>
        </div>
        {this.standarDisplay(
          "Last Data update",
          s3_inventory_last_update
            ? formatDate(s3_inventory_last_update)
            : "Not available"
        )}
        {this.standarDisplay("Size", convertSize(size))}
        {this.standarDisplay("Frequency quality profiling", metadata_frequency)}
        {this.standarDisplay("Update frequency", update_frequency)}
        {this.standarDisplay("Is partitioned", this.dataset_is_partioned())}
        {this.standarDisplay(
          "Link to description",
          details.external_description_url,
          true
        )}
        {this.standarDisplay("License", details.license)}
        {this.standarDisplay("Contact", details.contact)}
      </div>
    );
  }
}

export default withGoTo(DatasetInfo);
