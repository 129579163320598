/* @flow */

export const createDatasource = {
  query: `mutation Q ($accountUri: String!, $platformUri: String!, $input: NewDatasourceInput!) {
        createDatasource(accountUri: $accountUri, platformUri: $platformUri, input: $input) {
          uri
        }
      }
    `,
  keyOutput: "createDatasource"
};

export type NewDatasourceInput = {
  description: string,
  details: string,
  name: string,
  owneruri: string,
  region: string,
  resource_name: string,
  s3_custom_tags: Array<Object>,
  sharing_group_uri: string,
  bucket_owner_enforced: Boolean
};
