/* @flow */
import React from "react";
import Logger from "../../../../../utils/logger";
import GraphQl from "../../../../../graphQL";
import Error from "../../../../../components/error";
import Loading from "../../../../../components/loading";
import ProjectUsage from "../../../../carts/components/ProjectUsage";
import withAppSync from "../../../../AppsyncHOC";

const Log = Logger("PowerBIGateway");

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  error?: Object,
  ready: boolean,
  projects: Array<Object>
};

class PowerBIGateway extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      projects: []
    };
  }

  componentDidMount() {
    this.getListProjectAttachedToPowerBI();
  }

  getListProjectAttachedToPowerBI = () => {
    this.props.api.admin
      .gatewayProjectsList()
      .then((projects) => {
        this.setState({
          projects,
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"PowerBIGateway"} />;
    if (!this.state.ready)
      return <Loading message={"Projects attached to PowerBI gateway"} />;
    return (
      <div>
        Projects attached to PowerBI gateway
        <div>
          {this.state.projects.map((project) => (
            <ProjectUsage project={project} />
          ))}
        </div>
      </div>
    );
  }
}

export default withAppSync(PowerBIGateway);
