/* eslint-disable no-return-assign */
/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import "./style.less";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import NotificationSettingsRow from "./notificationSettingsRow";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  notificationsSettings: Object,
  selectedValue: Object,
  error: Object,
  isSubmitting: boolean,
  canSubmit: boolean
};

class NotificationSettings extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      isSubmitting: false,
      canSubmit: false,
      notificationsSettings: {},
      selectedValue: {}
    };
  }

  componentDidMount() {
    this.getNotificationSettings();
  }

  getNotificationSettings = () => {
    this.props.api.user
      .getNotificationSubscriptions()
      .then((notificationsSettings) => {
        this.setState({
          notificationsSettings,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  save = () => {
    this.setState({ isSubmitting: true });
    const settings = {
      dataset: {},
      customCDH: {}
    };
    this.state.selectedValue.dataset.map(
      (datasetSettings) =>
        (settings.dataset[datasetSettings.id] = datasetSettings.enable)
    );
    this.state.selectedValue.customCDH.map(
      (datasetSettings) =>
        (settings.customCDH[datasetSettings.id] = datasetSettings.enable)
    );
    this.props.api.user
      .updateNotificationSubscription(JSON.stringify(settings))
      .then(() => {
        this.setState({
          isSubmitting: false
        });
        this.props.showGlobalNotification({
          message: "Settings updated",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          error
        });
        this.props.showGlobalNotification({
          message: "Settings update failed",
          type: "alert"
        });
      });
  };

  handleChange = (field, section, value) =>
    this.setState((prevState) => {
      this.setState({ canSubmit: true });
      const selectedValue = Object.assign({}, prevState.notificationsSettings);
      const index = this.state.notificationsSettings[section].findIndex(
        (element) => element.id === field
      );
      selectedValue[section][index].enable = value;
      return { selectedValue };
    });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"NotificationSettings"} />;
    if (!this.state.ready) return <Loading message={"Notification Settings"} />;
    return (
      <div className="notification-type-container">
        <div className="notification-row">
          <div className="notifcation-text-container"></div>
          <div className="">
            <div className="notification-name">EMAIL NOTIFICATION</div>
          </div>
        </div>
        <div>
          <div className="notification-section">DATASET</div>
          {this.state.notificationsSettings.dataset.map((notificationType) => (
            <NotificationSettingsRow
              key={notificationType.name}
              notificationType={notificationType}
              handleChange={this.handleChange}
              section="dataset"
            />
          ))}
        </div>
        <div>
          <div className="notification-section">CUSTOM CDH</div>
          {this.state.notificationsSettings.customCDH.map(
            (notificationType) => (
              <NotificationSettingsRow
                key={notificationType.name}
                notificationType={notificationType}
                handleChange={this.handleChange}
                section="customCDH"
              />
            )
          )}
        </div>
        <div
          className={"row notificationSettingsAction justify-content-center"}
        >
          <button
            type="button"
            disabled={this.state.isSubmitting || !this.state.canSubmit}
            className="butn"
            onClick={this.save}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(NotificationSettings));
