/* @flow */
import React from "react";
import routes from "../../routes";

type propTypes = {
  dataset: {
    uri: string,
    access: string,
    default_table?: string,
    expiration_date?: string,
    can_share: boolean,
    access: string,
    shareable: boolean
  },
  goTo: Function,
  openDatasetModal: Function
};

const DatasetActions = ({
  goTo = () => {},
  openDatasetModal = () => {},
  dataset = {}
}: propTypes) => (
  <div className="container-actions">
    <div className="actions-left">
      {dataset.shareable && dataset.access !== "owner" && (
        <div className="container-buttons-share">
          <button
            type={"button"}
            className="butn butn-flat"
            onClick={() =>
              goTo({
                route: routes.Share.Request,
                params: { uriDataset: dataset.uri },
                state: { dataset }
              })
            }
            style={{ margin: "5px" }}
          >
            <i className={"fas fa-lock-open"} />
            <span>{" Request Access"}</span>
          </button>
        </div>
      )}
      {dataset.access !== "other" && !dataset.expiration_date && (
        <button
          className="butn butn-flat min-width-130"
          onClick={() =>
            goTo({
              route: routes.Dataset.AddToCart,
              params: {
                uriDataset: dataset.uri
              },
              state: { dataset }
            })
          }
          type={"button"}
          style={{ margin: "5px", width: "145" }}
        >
          <i className="fas fa-plus butn-icon" />
          <span className="butn-text">Add to project</span>
        </button>
      )}
    </div>
    <div className="actions-right">
      {dataset.access === "owner" && (
        <button
          className="butn"
          onClick={() =>
            goTo({
              route: routes.Dataset.Edit,
              params: { uriDataset: dataset.uri },
              state: { dataset }
            })
          }
          type={"button"}
          style={{ margin: "5px", width: "85px" }}
        >
          <span>Edit</span>
        </button>
      )}
      {dataset.access === "owner" && (
        <button
          className="butn butn-delete"
          type={"button"}
          style={{ margin: "5px" }}
          onClick={openDatasetModal}
        >
          <span>Delete</span>
        </button>
      )}
    </div>
  </div>
);

export default DatasetActions;
