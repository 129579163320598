/* @flow */
import React from "react";
import Loading from "../../../../../components/loading";
import Error from "../../../../../components/error";
import GraphQl from "../../../../../graphQL";
import Logger from "../../../../../utils/logger";
import DatasetsForm from "../../../detectNewDatasets/datasetsForm";
import { SOURCE_TYPES } from "../../../../../utils/constants";

const Log = Logger("DetectedSchemas");

type propTypes = {
  uriDatasource: string,
  setSchemas: Function,
  schemas: Array<Object>,
  myRef: Object,
  onPopulate: Function,
  editDataset: Object,
  api: GraphQl
};

type stateTypes = {
  detectSchemas: number,
  detectDatasetReady: boolean,
  detectDatasetError?: Object,
  dataset: Object,
  isAllSeleted: boolean
};

class DetectedSchemas extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      detectSchemas: 0,
      detectDatasetReady: false,
      detectDatasetError: undefined,
      isAllSeleted: false,
      dataset: {
        description: "",
        type: "structured",
        basic_metadata: true,
        metadata_frequency: "never",
        maturity_level: 0,
        external_description_url: "",
        license: "",
        contact: "",
        shareable: true,
        visibility: "Public",
        data_types: [],
        source_type: SOURCE_TYPES[1].key
      }
    };
  }

  componentDidMount() {
    this.detectDataset();
  }

  detectDataset = () =>
    this.props.api.datasource
      .detectDatasets(this.props.uriDatasource)
      .then((schemas) => {
        this.setState({
          detectSchemas: schemas.length,
          detectDatasetReady: true
        });
        this.props.setSchemas(
          schemas.map((p) => ({ value: p, isSelected: false }))
        );
      })
      .catch((detectDatasetError) => {
        Log.error(detectDatasetError);
        this.setState({
          detectDatasetError,
          detectDatasetReady: true
        });
      });

  componentWillReceiveProps(nextProps: propTypes) {
    if (
      nextProps.editDataset &&
      (!this.props.editDataset ||
        nextProps.editDataset.schema !== this.props.editDataset.schema)
    ) {
      this.setState({
        dataset: nextProps.editDataset,
        isAllSeleted: false
      });
    }
  }

  render() {
    return (
      <div className="container datatourse-detect-form-container">
        <div className="datatourse-detect-form">
          <label className="label-form title-s">Detected schemas</label>
          {!this.state.detectDatasetReady && (
            <Loading message={"dataset detection"} />
          )}
          {this.state.detectDatasetError && (
            <Error
              error={this.state.detectDatasetError}
              path={"DatasourceDetectNewDatasets"}
            />
          )}
          {this.props.schemas &&
            this.state.detectDatasetReady &&
            !this.state.detectDatasetError && (
              <div>
                <p>We found {this.state.detectSchemas} potential datasets.</p>
                {this.props.schemas.length > 0 && (
                  <div>
                    <p>
                      Select schemas and fill the form (You can fill several
                      schemas at the same time)
                    </p>

                    {this.props.schemas.length > 1 && (
                      <div className="prefixes-all-checkbox">
                        <input
                          id="all"
                          type="checkbox"
                          className={"checkbox-facet"}
                          checked={this.state.isAllSeleted}
                          onClick={() => {
                            this.props.setSchemas(
                              this.props.schemas.map((p) => ({
                                value: p.value,
                                isSelected: !this.state.isAllSeleted
                              }))
                            );
                            this.setState((prevState) => ({
                              isAllSeleted: !prevState.isAllSeleted
                            }));
                          }}
                        />
                        <label htmlFor="all" className="fas">
                          <span className="labelCustom">
                            Select all schemas
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                )}

                <div className="mb-2 prefixes-checkbox">
                  {this.props.schemas.map((schema) => (
                    <div className="prefix-checkbox" key={schema.value}>
                      <input
                        id={schema.value}
                        type="checkbox"
                        className={"checkbox-facet"}
                        checked={schema.isSelected}
                        onClick={() => {
                          const schemas = this.props.schemas.map((p) =>
                            p.value !== schema.value
                              ? p
                              : {
                                  value: p.value,
                                  isSelected: !schema.isSelected
                                }
                          );
                          this.setState({
                            isAllSeleted:
                              schemas.filter((p) => !p.isSelected).length === 0
                          });
                          this.props.setSchemas(schemas);
                        }}
                      />
                      <label htmlFor={schema.value} className="fas">
                        <span className="labelCustom">{schema.value}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <div ref={this.props.myRef}>
                  {this.props.schemas.filter((p) => p.isSelected).length >
                    0 && (
                    <DatasetsForm
                      dataset={this.state.dataset}
                      submit={() => this.props.onPopulate(this.state.dataset)}
                      errorSubmit={undefined}
                      handleChange={(field, value) => {
                        this.setState((prevState) => ({
                          dataset: {
                            ...prevState.dataset,
                            [field]: value
                          }
                        }));
                      }}
                      displayName={
                        this.props.schemas.filter((p) => p.isSelected)
                          .length === 1
                      }
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default DetectedSchemas;
