/* eslint-disable camelcase */

import { mutationCreateCollibraLink } from "./mutationCreateCollibraLink";
import { getCollibraLink } from "./queryGetCollibraLink";
import { mutationDeleteCollibraLink } from "./mutationDeleteCollibraLink";
import { listCollibraLinks } from "./queryListCollibraLinks";
import { mutationRemoveCollibraLinkDatasets } from "./mutationRemoveCollibraLinkDatasets";
import { mutationIndexCollibraLinkDatasets } from "./mutationIndexCollibraLinkDatasets";
import { mutationIndexDatasetOnCollibra } from "./mutationIndexCollibraDataset";
import { mutationRemoveDatasetFromCollibra } from "./mutationRemoveCollibraDataset";
import { getCollibraDatasets } from "./queryListCollibraDatasets";
import { getCollibraEnvironments } from "./queryGetCollibraEnvs";
import { getCollibraDomainName } from "./queryGetCollibraDomaineName";

class CollibraApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  create(accountUri, input) {
    return this.fetchAppSync(mutationCreateCollibraLink, {
      accountUri,
      input
    });
  }

  get(accountUri, id) {
    return this.fetchAppSync(getCollibraLink, {
      accountUri,
      id
    });
  }

  delete(accountUri, id) {
    return this.fetchAppSync(mutationDeleteCollibraLink, { accountUri, id });
  }

  list(accountUri) {
    return this.fetchAppSync(listCollibraLinks, {
      accountUri
    });
  }

  removeCollibraLinkDatasets(accountUri, id) {
    return this.fetchAppSync(mutationRemoveCollibraLinkDatasets, {
      accountUri,
      id
    });
  }

  indexCollibraLinkDatasets(accountUri, id) {
    return this.fetchAppSync(mutationIndexCollibraLinkDatasets, {
      accountUri,
      id
    });
  }

  indexDatasetOnCollibra(datasetUri, id) {
    return this.fetchAppSync(mutationIndexDatasetOnCollibra, {
      datasetUri,
      id
    });
  }

  removeDatasetFromCollibra(datasetUri, id) {
    return this.fetchAppSync(mutationRemoveDatasetFromCollibra, {
      datasetUri,
      id
    });
  }

  listCollibraDatasets(datasetUri) {
    return this.fetchAppSync(getCollibraDatasets, { datasetUri });
  }

  getCollibraEnvironments() {
    return this.fetchAppSync(getCollibraEnvironments);
  }

  getCollibraDomainName(domainId, collibraEnv) {
    return this.fetchAppSync(getCollibraDomainName, { domainId, collibraEnv });
  }
}

export default CollibraApi;
