/* @flow */
import React from "react";
import withAppSync from "../../AppsyncHOC";
import "./topDatasets.less";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import Logger from "../../../utils/logger";
import Links from "../../links";
import type { SearchInput } from "../../../graphQL/propstypes";
import stringcutter from "../../../components/stringcutter";

const Log = Logger("TopDatasetShared");

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  error?: Object,
  ready: boolean,
  topSharedDatasets: Array<Object>
};

class TopDatasetsShared extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      topSharedDatasets: []
    };
  }

  componentDidMount() {
    this.getTopSharedDatasets();
  }

  onError = (error) => {
    this.setState({
      error,
      ready: true
    });
  };

  getTopSharedDatasets() {
    this.setState(
      {
        ready: false
      },
      () => {
        const options: SearchInput = {
          limit: 5,
          order_by: { direction: "desc", field: "nb_shares" },
          filters: [{ field: "visibility", values: ["Public"] }]
        };
        return this.props.api.dataset
          .search(options)
          .then((searchResult) => {
            if (!searchResult) return false;
            Log.verbose("result search", options, searchResult);
            return this.setState({
              topSharedDatasets: searchResult.results,
              ready: true
            });
          })
          .catch(this.onError);
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <ul className={"list-group top-datasets-list"}>
          <li className="text-center">Not available</li>
        </ul>
      );
    }

    if (!this.state.ready) return <Loading message={"Top Datasets"} />;
    return (
      <ul className={"list-group top-datasets-list"}>
        {this.state.topSharedDatasets.map((dataset) => (
          <li className="top-dataset-element" key={dataset.uri}>
            <div className="top-dataset-left">
              <Links.Dataset.View uriDataset={dataset.uri}>
                <div className="top-dataset-title">
                  {stringcutter(dataset.name, 40)}
                </div>
              </Links.Dataset.View>
              <div className="">
                <span className="top-dataset-publisher">
                  <i className="nb-views fas fa-share-alt"></i>
                  <span className="">{dataset.nb_shares}</span> -{" "}
                  <span className="font-italic">BY</span>{" "}
                  {dataset.owner_group_name}{" "}
                </span>
              </div>
              <div className="top-dataset-description">
                {dataset.description && dataset.description.length > 125
                  ? `${dataset.description.substring(0, 125)}...`
                  : dataset.description}
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default withAppSync(TopDatasetsShared);
