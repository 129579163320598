/* @flow */

import React, { Component } from "react";
import routes from "../../../routes";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import SagemakerNotebookDetails from "./SagemakerNotebookDetails";
import Loading from "../../../../components/loading";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import "./style.less";
import Paginator from "../../../../components/paginator";

type propTypes = {
  cart: Object,
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  ready: boolean,
  error: Object,
  sagemakerNotebooks: Array<Object>,
  total: number
};

class CartDatascience extends Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      sagemakerNotebooks: [],
      ready: true,
      error: undefined,
      total: 0
    };
  }

  componentDidMount() {
    this.getSagemakerNotebooks();
  }

  getSagemakerNotebooks = (offset = 0) => {
    this.setState({ ready: false });
    return this.props.api.sagemakerNotebookInstance
      .list(this.props.cart.uri, offset, 4)
      .then((response) => {
        this.setState({
          ready: true,
          sagemakerNotebooks: response.results,
          total: response.total
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    return (
      <div className={"cart-datascience"}>
        <div>
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              this.props.goTo({
                route: routes.Cart.AddSagemakerNotebook,
                params: {
                  cartUri: this.props.cart.uri
                },
                state: {
                  cart: this.props.cart
                }
              })
            }
          >
            <i className="fas fa-plus" />
            <span className="butn-text">New Sagemaker Notebook</span>
          </button>
        </div>

        <div className="card-container">
          {this.state.error && (
            <Error error={this.state.error} path={"SagemakerNotebook"} />
          )}
          {!this.state.error && !this.state.ready && (
            <Loading message="Sagemaker Notebook" />
          )}
          {!this.state.error && this.state.sagemakerNotebooks.length > 0 && (
            <ul>
              <Paginator
                limit={4}
                initialOffset={0}
                list={this.state.sagemakerNotebooks}
                componentRender={(instance) => (
                  <SagemakerNotebookDetails
                    key={instance.id}
                    cart={this.props.cart}
                    instance={instance}
                    reload={this.getSagemakerNotebooks}
                  />
                )}
                loadPage={this.getSagemakerNotebooks}
                totalCount={this.state.total}
              />
            </ul>
          )}
          {!this.state.error &&
            this.state.ready &&
            !(this.state.sagemakerNotebooks.length > 0) && (
              <div>No Sagemaker Notebook to display.</div>
            )}
        </div>
      </div>
    );
  }
}

export default withAppSync(withGoTo(CartDatascience));
