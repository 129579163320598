/* @flow */
import React from "react";
import "./view.less";
import Modal from "../../../components/modal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";

type propTypes = {
  dataset: Object,
  hide: Function,
  getDatasource: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string
};

class DatasetModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null
    };
  }

  render() {
    const { dataset, hide, getDatasource } = this.props;
    return (
      <Modal
        title={`Delete dataset ${dataset.name}`}
        body={
          "Are you sure you want to delete this dataset ? \n The final deletion of this dataset will take place in 7 days, you will be able to cancel the deletion"
        }
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-close-${dataset.uri}`}
            type="button"
            className="butn"
            onClick={hide}
          >
            Close
          </button>,
          <button
            key={`dataset-delete-${dataset.uri}`}
            type="button"
            className="butn butn-delete"
            onClick={() =>
              this.props.api.dataset
                .deleteDataset(dataset.uri)
                .then(hide)
                .then(getDatasource)
                .catch((error) =>
                  this.setState({ error: getErrorMessageWithoutCode(error) })
                )
            }
          >
            Delete
          </button>
        ]}
      />
    );
  }
}

export default withAppSync(DatasetModal);
