import { combineReducers } from "redux";

import globalNotificationsReducer from "./containers/globalNotifications/reducers";
import notifications from "./containers/notificationBadge/reducers";

const reducers = combineReducers({
  globalNotifications: globalNotificationsReducer,
  notifications
});

export default reducers;
