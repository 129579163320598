export const queryGetDatasetCrawlers = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      uri
      accountid
      name
      resource_name
      crawlers {
        status
        details
        error
        prefix
        updatedat
      }
    }
  }
`,
  keyOutput: "getDataset.crawlers"
};
