/* @flow */
import React from "react";
import "./style.less";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";
import Links from "../../../links";
import Loading from "../../../../components/loading";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Paginator from "../../../../components/paginator";

const Log = Logger("ListAccelerationTemplatesNetwork");

type propTypes = {
  api: GraphQl,
  network: Object,
  uriAccount: string,
  uriPlayground: string
};

type stateTypes = {
  error: ?Object,
  ready: boolean,
  networkAccelerationTemplateEnvironmentGroups: Array<Object>,
  total: number
};

class ListAccelerationTemplatesNetwork extends React.PureComponent<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      ready: false,
      networkAccelerationTemplateEnvironmentGroups: [],
      total: 0
    };
  }

  componentDidMount(): void {
    this.loadAccelerationTemplatesNetwork();
  }

  loadAccelerationTemplatesNetwork = (offset = 0) =>
    this.props.api.playground
      .listNetworkAccelerationTemplateAccess(this.props.network.id, { offset })
      .then((networkAccelerationTemplateEnvironmentGroupsResponse) => {
        this.setState({
          networkAccelerationTemplateEnvironmentGroups:
            networkAccelerationTemplateEnvironmentGroupsResponse.results,
          total: networkAccelerationTemplateEnvironmentGroupsResponse.total,
          ready: true
        });
        return networkAccelerationTemplateEnvironmentGroupsResponse.results;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, ready: true });
        return [];
      });

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path="ListAccelerationTemplatesNetwork"
          stringOnly
        />
      );
    if (!this.state.ready) return <Loading message="" />;

    return (
      <div className="list-policies">
        <div className="mt-2">
          <Paginator
            container={(content) => (
              <table className="table">
                <thead>
                  <tr>
                    <th className="" scope="col">
                      Group
                    </th>
                    <th className="" scope="col">
                      Template
                    </th>
                    <th className="" scope="col">
                      Projects
                    </th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </table>
            )}
            limit={10}
            initialOffset={0}
            list={this.state.networkAccelerationTemplateEnvironmentGroups}
            componentRender={(instance) => (
              <tr className="table-row" key={instance.id}>
                <td className="table-link">
                  <Links.Group.View uriGroup={instance.group.uri}>
                    {instance.group.name}
                  </Links.Group.View>
                </td>

                <td className="table-link">
                  <Links.AccelerationTemplate.View
                    uriAccount={this.props.uriAccount}
                    uriPlayground={this.props.uriPlayground}
                    id={instance.acceleration_template.id}
                  >
                    {instance.acceleration_template.name}
                  </Links.AccelerationTemplate.View>
                </td>

                <td className="table-link">
                  {instance.template_projects
                    ? instance.template_projects.map((templateProject) => (
                        <div>{templateProject.cart.name}</div>
                      ))
                    : "-"}
                </td>
              </tr>
            )}
            loadPage={this.loadAccelerationTemplatesNetwork}
            totalCount={this.state.total}
            onNoResult={() => <div>Nothing to display.</div>}
          />
        </div>
      </div>
    );
  }
}

export default withAppSync(ListAccelerationTemplatesNetwork);
