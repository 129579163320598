/* @flow */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import Newtification from "./components/notification/newtification";
import GraphQl from "../../graphQL";
import Paginator from "../../components/paginator";
import SelectClassic from "../../components/SelectClassic";
import FilterByNewtificationTypes from "./components/filterByNewtificationTypes";
import { updateMessage } from "../globalNotifications/actions";
import { reloadNotifications } from "../notificationBadge/actions";
import "./view.less";
import Loading from "../../components/loading";

const Log = Logger("NotificationsView");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  reloadNotifications: Function
};

type stateTypes = {
  ready: boolean,
  reset: boolean,
  notifications: Array<Object>,
  error: ?Object,
  offset: number,
  total: number,
  selectedNotifs: Array<string>,
  allSelected: boolean,
  isLoadingRead: boolean,
  isLoadingUnRead: boolean,
  isDeleting: boolean,
  filterNotificationType?: string,
  filterNotificationRead?: boolean,
  version?: string
};

class NewtificationsView extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      reset: false,
      notifications: [],
      offset: 0,
      error: undefined,
      total: 0,
      selectedNotifs: [],
      allSelected: false,
      isLoadingRead: false,
      isLoadingUnRead: false,
      isDeleting: false,
      filterNotificationType: undefined,
      filterNotificationRead: undefined
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications = () => {
    const options = {
      limit: 20,
      offset: this.state.offset,
      filters: []
    };

    if (this.state.filterNotificationType) {
      options.filters.push({
        key: "subtype",
        value: this.state.filterNotificationType
      });
    }

    if (this.state.filterNotificationRead !== undefined) {
      options.filters.push({
        key: "is_read",
        value: this.state.filterNotificationRead
      });
    }

    return this.props.api.newtification
      .listMyNotifications(options)
      .then((notifications) => {
        if (notifications) {
          this.setState({
            notifications: notifications.results,
            total: notifications.total,
            ready: true,
            reset: false,
            selectedNotifs: [],
            allSelected: false,
            version: Math.random().toString()
          });
        }
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          ready: true,
          reset: false,
          error
        });
      });
  };

  batchActionNotification = (action) =>
    this.props.api.newtification
      .batchActionNotifications(this.state.selectedNotifs, action)
      .then(() => {
        this.getNotifications();
        this.props.reloadNotifications();
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });

  getNotificationsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getNotifications().then(r))
    );

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"NotificationsView"} />;

    return (
      <div className={"notifications"}>
        <div>
          <div className="list-group">
            <div className="notifications-headers">
              <div className="notifications-header-types-filters">
                <div className="notifications-header-types-filter">
                  <SelectClassic
                    isClearable
                    placeholder="All notifications"
                    options={[
                      { label: "Read", value: true },
                      { label: "Unread", value: false }
                    ]}
                    onSelectOption={(option) => {
                      this.setState(
                        {
                          offset: 0,
                          ready: false,
                          reset: true,
                          filterNotificationRead: option
                            ? option.value
                            : undefined
                        },
                        this.getNotifications
                      );
                    }}
                  />
                </div>

                <div className="notifications-header-types-filter">
                  <FilterByNewtificationTypes
                    onFilter={(typeId) => {
                      this.setState(
                        {
                          offset: 0,
                          ready: false,
                          reset: true,
                          filterNotificationType: typeId
                        },
                        this.getNotifications
                      );
                    }}
                  />
                </div>
              </div>

              <div className="notifications-header-boxes">
                <div className="notifications-header-boxes-selections">
                  <input
                    id="select-all"
                    type="checkbox"
                    className={"checkbox-facet"}
                    checked={this.state.allSelected}
                    onChange={() => {
                      this.setState((prevState) => ({
                        allSelected: !prevState.allSelected,
                        selectedNotifs: prevState.allSelected
                          ? []
                          : prevState.notifications.map((notif) => notif.id)
                      }));
                    }}
                  />
                  <label htmlFor="select-all" className="fas">
                    <span className="labelCustom" />
                    <span className="label-text">
                      {this.state.allSelected ? "Unselect all" : "Select all"}
                    </span>
                  </label>
                </div>

                <div className="notifications-header-boxes-info">
                  ({this.state.selectedNotifs.length} selected)
                </div>

                <div className="notifications-header-boxes-actions">
                  <div
                    className={classnames("notifications-header-boxes-action", {
                      "is-disabled": this.state.selectedNotifs.length === 0
                    })}
                    onClick={() => {
                      if (this.state.selectedNotifs.length === 0) {
                        return this.props.showGlobalNotification({
                          message: "0 notification selected !",
                          type: "neutral"
                        });
                      }
                      this.setState({ isLoadingRead: true });
                      return this.batchActionNotification("read").then(() => {
                        this.setState({ isLoadingRead: false });
                      });
                    }}
                  >
                    {this.state.isLoadingRead && (
                      <i className="loader fas fa-circle-notch fa-spin" />
                    )}
                    <span className="cursorPointer">
                      <i className="far fa-envelope-open" />
                      <span className="text-actions">Mark as read</span>
                    </span>
                  </div>

                  <div
                    className={classnames("notifications-header-boxes-action", {
                      "is-disabled": this.state.selectedNotifs.length === 0
                    })}
                    onClick={() => {
                      if (this.state.selectedNotifs.length === 0) {
                        return this.props.showGlobalNotification({
                          message: "0 notification selected !",
                          type: "neutral"
                        });
                      }
                      this.setState({ isLoadingUnRead: true });
                      return this.batchActionNotification("unread").then(() => {
                        this.setState({ isLoadingUnRead: false });
                      });
                    }}
                  >
                    {this.state.isLoadingUnRead && (
                      <i className="loader fas fa-circle-notch fa-spin" />
                    )}
                    <span className="cursorPointer">
                      <i className="far fa-envelope" />
                      <span className="text-actions">Mark as unread</span>
                    </span>
                  </div>

                  <div
                    className={classnames("notifications-header-boxes-action", {
                      "is-disabled": this.state.selectedNotifs.length === 0
                    })}
                    onClick={() => {
                      if (this.state.selectedNotifs.length === 0) {
                        return this.props.showGlobalNotification({
                          message: "0 notification selected !",
                          type: "neutral"
                        });
                      }
                      this.setState({ isDeleting: true });
                      return this.batchActionNotification("delete").then(() => {
                        this.setState({ isDeleting: false });
                      });
                    }}
                  >
                    {this.state.isDeleting && (
                      <i className="loader fas fa-circle-notch fa-spin" />
                    )}
                    <span className="cursorPointer">
                      <i className="fas fa-trash" />
                      <span className="text-actions">Delete</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {this.state.reset && <Loading message="" />}
            {!this.state.reset && (
              <Paginator
                isLoadingPage={!this.state.ready}
                limit={20}
                initialOffset={0}
                totalCount={this.state.total}
                list={this.state.notifications}
                version={this.state.version}
                componentRender={(notif: Object): Object => (
                  <div key={notif.id} className="notification-container">
                    <div className="notification-checkbox">
                      <input
                        id={notif.id}
                        type="checkbox"
                        className={"checkbox-facet"}
                        checked={this.state.selectedNotifs.find(
                          (v) => v === notif.id
                        )}
                        onChange={() => {
                          let index;
                          const isInList = this.state.selectedNotifs.find(
                            (v, i) => {
                              if (v === notif.id) {
                                index = i;
                                return true;
                              }
                              return false;
                            }
                          );
                          if (isInList) {
                            this.setState((prevState) => {
                              const { selectedNotifs } = prevState;
                              selectedNotifs.splice(index, 1);
                              return { selectedNotifs, allSelected: false };
                            });
                          } else {
                            this.setState((prevState) => {
                              const { selectedNotifs } = prevState;
                              selectedNotifs.push(notif.id);
                              return { selectedNotifs, allSelected: false };
                            });
                          }
                        }}
                      />
                      <label htmlFor={notif.id} className="fas">
                        <span className="labelCustom" />
                      </label>
                    </div>
                    <Newtification
                      key={notif.id}
                      notification={notif}
                      onAction={this.getNotifications}
                    />
                  </div>
                )}
                loadPage={this.getNotificationsFromPagination}
                onNoResult={() => (
                  <p className="total-search-results">
                    You don't have any notifications
                  </p>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  },
  reloadNotifications: () => {
    dispatch(reloadNotifications());
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(NewtificationsView));
