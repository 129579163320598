export const registerDatabricksWorkspace = {
  query: `
  mutation registerDBXWorkspace ($inputData: NewDBXWorkspaceInput!) {
    registerDatabricksWorkspace(inputData: $inputData) {
        cdh_env_uri
        dbx_account_uuid
        dbx_warehouse_id
        dbx_workspace_id
        dbx_workspace_name
        dbx_workspace_url
    }
  }
`,
  keyOutput: "registerDatabricksWorkspace"
};
