/* @flow */
import React from "react";

type propTypes = {
  share: Object
};

type stateTypes = {
  toggleDetails: boolean
};

class MoreDetailsNotification extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      toggleDetails: false
    };
  }

  onClickCollapseToggle = () => {
    this.setState((prevState) => ({ toggleDetails: !prevState.toggleDetails }));
  };

  render() {
    if (
      this.props.share.request_status === "rejected" &&
      this.props.share.reject_message != null &&
      this.props.share.reject_message.length > 0
    ) {
      return (
        <React.Fragment>
          <div
            className="more-details-share"
            onClick={this.onClickCollapseToggle}
          >
            See reject/revoke message&nbsp;
            <i
              className={`facet-chevron fas ${
                this.state.toggleDetails ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>
          <div>
            {this.state.toggleDetails && (
              <div className="notification-message">
                <p className="font-italic">{this.props.share.reject_message}</p>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div
          className="more-details-share"
          onClick={this.onClickCollapseToggle}
        >
          See request access message&nbsp;
          <i
            className={`facet-chevron fas ${
              this.state.toggleDetails ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </div>
        <div>
          {this.state.toggleDetails &&
            (this.props.share.message ? (
              <div className="notification-message">
                <p className="font-italic">{this.props.share.message}</p>
              </div>
            ) : (
              <div className="">
                There is no message for this request access
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default MoreDetailsNotification;
