/* @flow */
import { getLanguages } from "./queryGetLanguages";
import { getSagemakerNotebookInstanceTypes } from "./queryGetSagemakerNotebookInstancesTypes";

class UtilsApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  getLanguages() {
    return this.fetchAppSync(getLanguages, {});
  }

  getSagemakerNotebookInstanceTypes() {
    return this.fetchAppSync(getSagemakerNotebookInstanceTypes, {});
  }
}

export default UtilsApi;
