export const listDatabricksCatalogs = {
  query: `query Q ($uuid: String!) {
                listDatabricksCatalogs(uuid: $uuid) {
                results {
                  dbx_catalog_name
                }
              }
            }
          `,
  keyOutput: "listDatabricksCatalogs"
};
