export const listGroups = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        listAccountGroups (accountUri: $accountUri, options: $options) {
            total
            limit
            offset
            results {
                name
                uri
                description
                platforms {
                    uri
                    name
                    aws
                }
                carts {
                    name
                }
                responsible {
                    name
                    uri
                }
            }
        }
      }
    `,
  keyOutput: "listAccountGroups"
};
