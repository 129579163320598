/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { updateMessage } from "../globalNotifications/actions";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import routes from "../routes";
import Error from "../../components/error";
import Breadcrumb from "../../components/breadcrumb";
import "./view.less";
import GraphQl from "../../graphQL";
import config from "../../config";
import DssInstanceGroupsList from "./viewGroups";
import DeleteObjectWithFrictionModal from "../../components/DeleteObjectWithFrictionModal";

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriDssInstance: string
    }
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  dssInstance: Object,
  error: boolean,
  connectionError: Object,
  isTestingConnection: boolean,
  openDeleteModal: boolean
};

class DssInstanceView extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      dssInstance: {},
      error: false,
      connectionError: null,
      isTestingConnection: false,
      openDeleteModal: false
    };
  }

  componentDidMount() {
    this.getDssInstance();
  }

  getDssInstance = () =>
    this.props.api.dssInstance
      .getDssInstance(
        this.props.match.params.uriAccount,
        this.props.match.params.uriDssInstance
      )
      .then((response) => {
        if (!response) return false;
        this.setState({
          ready: true,
          dssInstance: response
        });
        return response;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  testConnection = () => {
    this.setState({
      isTestingConnection: true
    });
    this.props.api.dssInstance
      .testDssInstanceConnection(this.props.match.params.uriDssInstance)
      .then((response) => {
        this.getDssInstance();
        this.setState({
          isTestingConnection: false
        });
        if (!response) return false;
        return response;
      })
      .catch((error) => {
        this.setState({
          isTestingConnection: false,
          connectionError: error
        });
      });
  };

  deleteInstance = () => {
    this.props.api.dssInstance
      .delete(this.props.match.params.uriDssInstance)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Dataiku DSS instance detached",
          type: "success"
        });
        this.props.goTo({
          route: `${routes.Account.View}#dss`,
          params: {
            uriAccount: this.props.match.params.uriAccount
          }
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  actions = () => {
    const { match, goTo } = this.props;

    return (
      <div className="actions">
        <div>
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              goTo({
                route: routes.DssInstance.Groups,
                params: {
                  uriDssInstance: match.params.uriDssInstance,
                  uriAccount: match.params.uriAccount
                },
                state: { dssInstance: this.state.dssInstance }
              })
            }
          >
            Manage Groups
          </button>
        </div>
        <div>
          <div
            className="butn butn-flat"
            onClick={() =>
              !this.state.isTestingConnection && this.testConnection()
            }
            style={{ margin: "5px", width: "150" }}
          >
            {this.state.isTestingConnection && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            <i className="fas fa-sync-alt" />
            <span className="butn-text">Test Connection</span>
          </div>
          <button
            className="butn butn"
            type="button"
            onClick={() =>
              goTo({
                route: routes.DssInstance.Edit,
                params: {
                  uriDssInstance: match.params.uriDssInstance,
                  uriAccount: match.params.uriAccount
                },
                state: {
                  dssInstance: this.state.dssInstance
                }
              })
            }
          >
            <span className="btn-text">Edit</span>
          </button>
          <button
            className="butn butn-delete"
            type="button"
            onClick={() =>
              this.setState({
                openDeleteModal: true
              })
            }
          >
            <span className="btn-text">Delete</span>
          </button>
        </div>
      </div>
    );
  };

  render = () => {
    if (this.state.error)
      return <Error error={this.state.error} path={"DssInstanceView"} />;
    if (!this.state.ready) return <Loading message={"DSS Instance"} />;
    return (
      <div className="playground-view">
        <div className="header">
          <div className="title-line">
            <Breadcrumb
              view={`${this.state.dssInstance.name || ""} - DSS Instance`}
              helpOn={{
                content: (
                  <div>
                    <div>- DSS Instance</div>
                  </div>
                ),
                helpCenterLink: `${config.HELP_CENTER}`
              }}
            />
          </div>
          {this.actions()}
        </div>
        {((this.state.dssInstance.connection_error &&
          this.state.dssInstance.connection_error !== "null") ||
          this.state.connectionError) && (
          <div style={{ marginBottom: "20px" }}>
            <Alert severity="error">
              <AlertTitle>Connection failed:</AlertTitle>
              {this.state.connectionError
                ? this.state.connectionError.message
                : "" || this.state.dssInstance.connection_error}
            </Alert>
          </div>
        )}
        {this.state.dssInstance.connection_status ===
          "CONNECTION_SUCCESSFUL" && (
          <div style={{ marginBottom: "20px" }}>
            <Alert severity="success">
              <AlertTitle>Connection Successful:</AlertTitle>
              {
                "CDH can now manage DSS connections and accelerate your data project."
              }
            </Alert>
          </div>
        )}
        <div>
          <ul className="info">
            <li>
              <span className="font-weight-bold">URL :</span>{" "}
              <a
                href={`${this.state.dssInstance.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.dssInstance.url}
              </a>
            </li>
            <li>
              <span className="font-weight-bold">Name :</span>{" "}
              {this.state.dssInstance.name}
            </li>
            <li>
              <span className="font-weight-bold">Description :</span>{" "}
              {this.state.dssInstance.description || "No description provided"}
            </li>
            <li>
              <span className="font-weight-bold">API Key :</span>{" "}
              {this.state.dssInstance.api_key_secret}
            </li>
            <li>
              <span className="font-weight-bold">API Key Age :</span>{" "}
              {this.state.dssInstance.api_key_age} {" days"}
            </li>
            <li>
              <span className="font-weight-bold">IAM Role ARN :</span>{" "}
              {this.state.dssInstance.iam_role_arn || "-"}
            </li>
          </ul>
        </div>

        {!this.state.ready && <Loading message={"DSS instance view"} />}
        <div className="">
          <DssInstanceGroupsList
            uriAccount={this.props.match.params.uriAccount}
            dssInstance={this.state.dssInstance}
          />
        </div>
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={`Dataiku DSS ${this.state.dssInstance.name}`}
            deleteMessage={"Delete DSS instance ?"}
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => {
              this.setState({ openDeleteModal: false });
            }}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteInstance}
            isAWSResource={false}
          />
        )}
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DssInstanceView));
