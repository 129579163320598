/* @flow */
import React from "react";

type propTypes = {
  onClick: Function
};
type stateTypes = {
  isClicked: boolean
};

class AddUserAction extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isClicked: false
    };
  }

  onClick = () => {
    this.setState({ isClicked: true }, () => {
      this.props.onClick().then(() => {
        this.setState({ isClicked: false });
      });
    });
  };

  render() {
    return (
      <button
        disabled={this.state.isClicked}
        type={"button"}
        className={"butn butn-create"}
        onClick={() => {
          if (!this.state.isClicked) this.onClick();
        }}
      >
        {this.state.isClicked && (
          <i className="fas fa-circle-notch fa-spin fa-spacing" />
        )}
        {" Add"}
      </button>
    );
  }
}

export default AddUserAction;
