export const attachCartToPowerBI = {
  query: `mutation Q ($cartUri: String!, $envUri: String!, $ssmDocumentName: String ) {
        attachCartToPowerBI(cartUri:$cartUri, envUri: $envUri, ssmDocumentName: $ssmDocumentName)
      }
    `,
  keyOutput: "attachCartToPowerBI"
};

export const detachCartToPowerBI = {
  query: `mutation Q ($cartUri: String!) {
        detachCartToPowerBI(cartUri:$cartUri)
      }
    `,
  keyOutput: "detachCartToPowerBI"
};

export const getCommandStatus = {
  query: `query Q ($cartUri: String!) {
        getSendCommandStatus(cartUri:$cartUri)
      }
    `,
  keyOutput: "getSendCommandStatus"
};
