/* @flow */

import React from "react";
import { withAuth } from "@okta/okta-react";
import Row from "../../../../components/row";
import LogoutButton from "../../../auth/logout";
import CopyToClipboard from "../../../../components/copyToClipboard";
import Logger from "../../../../utils/logger";

const Log = Logger("MyProfileInformation");

type propTypes = {
  auth: {
    getUser: Function,
    getIdToken: Function
  }
};

type stateTypes = {
  ready1: boolean,
  ready2: boolean,
  token: string,
  user: Object
};

class MyProfileInformation extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready1: false,
      ready2: false,
      user: {},
      token: "-"
    };
  }

  componentDidMount() {
    this.getUser();
    this.getToken();
  }

  getUser = () => {
    // eslint-disable-line class-methods-use-this
    this.props.auth
      .getUser()
      .then((user = {}) => {
        this.setState({
          ready1: true,
          user
        });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getToken = () => {
    this.props.auth
      .getIdToken()
      .then((token) => {
        this.setState({ token, ready2: true });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  render() {
    return (
      <div>
        {this.state.ready1 && (
          <div>
            <Row className={"mx-2"} style={{ marginTop: "20px" }}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Name:
              </div>
              <div>{this.state.user.name}</div>
            </Row>

            <Row className={"mx-2"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Username:
              </div>
              <div>{(this.state.user || {}).preferred_username}</div>
            </Row>
          </div>
        )}

        {this.state.ready2 && (
          <Row className={"mt-4 mx-2"}>
            <label className="label-form">API TOKEN (JWT):</label>
            <div className="input-group">
              <textarea
                disabled
                name="name"
                className="form-control form-control-sm"
                placeholder="Name"
                value={this.state.token}
                rows="8"
              />
              <CopyToClipboard
                text={this.state.token}
                className={"fas fa-copy btn-carre"}
                style={{ marginTop: "10px" }}
                label={""}
              />
            </div>
          </Row>
        )}

        <Row className={"mx-2"} style={{ marginTop: "20px" }}>
          <LogoutButton />
        </Row>
      </div>
    );
  }
}

export default withAuth(MyProfileInformation);
