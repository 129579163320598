export const listSagemekerNotebookInstanceEnvironment = {
  query: `query Q ($platformUri:String!, $options:ListOptions) {
        listPlatformSagemakerNotebookInstances(platformUri:$platformUri, options:$options) {
            total
            offset
            limit
            results {
                id
                name
                instance_type
                arn
                status
                createdat
                updatedat
                error
                region
                network {
                    id
                    name
                    subnet_ids
                    security_group_ids
                }
                project {
                    name
                    uri
                }
                environment {
                    environment
                    aws
                    name
                }
            }
        }
      }
    `,
  keyOutput: "listPlatformSagemakerNotebookInstances"
};
// network {
//     subnet_id
//     security_group_ids
// }
