import React from "react";
import DBXGroupLabels from "./dbxGroupLabels";

import "./dbxGroupName.less";

const DBXGroupName = ({ group }) => (
  <div className="groupname">
    <div className="names">
      <span className="subtitle group-name">{` ${group.dbx_group_name}`}</span>
      <DBXGroupLabels group={group} />
    </div>
  </div>
);

export default DBXGroupName;
