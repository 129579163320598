export const getGroupEnvironemnts = {
  query: `query Q ($groupUri: String!,  $options: QueryOptions) {
        listGroupPlatforms(groupUri: $groupUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                environment
                aws
                default_region
                accountid
                rsNamespaces {
                  results{
                    uri
                    name
                    region
                  }
                }
            }
        }
    }`,
  keyOutput: "listGroupPlatforms"
};
