/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./style.less";
import Modal from "../../../components/modal";
import ButtonAction from "../../../components/buttonAction";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import { updateMessage } from "../../globalNotifications/actions";

type propTypes = {
  group: Object,
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  modalDisplay: boolean,
  errorModal?: string | Object
};

class DeleteGroup extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      modalDisplay: false,
      errorModal: ""
    };
  }

  openModal = () =>
    this.setState({ modalDisplay: true, errorModal: undefined });

  closeModal = () => this.setState({ modalDisplay: false });

  deleteGroup = () => {
    this.props.api.group
      .deleteGroup(this.props.group.uri)
      .then((response) => {
        if (!response) {
          throw new Error("Group cannot be removed...");
        }

        this.props.showGlobalNotification({
          message: "Group deleted",
          type: "success"
        });
        this.closeModal();
        return this.props.goTo({
          route: routes.Group.List,
          params: {
            uriAccount: this.props.group.account.uri
          }
        });
      })
      .catch((errorModal) => {
        if (
          errorModal.message.includes(
            "cannot be deleted because it currently has children `Datasource`"
          )
        ) {
          this.setState({
            errorModal: `You cannot delete a group that has datasources, 
                        please delete them first, and try again.`
          });
        } else if (
          errorModal.message.includes(
            "cannot be deleted because it currently has children `Project`"
          )
        ) {
          this.setState({
            errorModal: `You cannot delete a group that has projects, 
                        please delete it first, and try again.`
          });
        } else {
          this.setState({ errorModal: getErrorMessageWithoutCode(errorModal) });
        }
        this.props.showGlobalNotification({
          message: "Group deletion failed.",
          type: "alert"
        });
      });
  };

  renderModal = () => (
    <Modal
      title={`Delete group ${this.props.group.name}`}
      body={"Are you sure ?"}
      errorMessage={this.state.errorModal}
      actions={[
        <ButtonAction label="Close" onClick={this.closeModal} />,
        <ButtonAction
          label="Delete"
          className="butn butn-delete"
          onClick={this.deleteGroup}
        />
      ]}
    />
  );

  render() {
    if (
      this.props.group &&
      (this.props.group.uri.endsWith("_admin_group") ||
        this.props.group.uri.endsWith("administratorsgroup"))
    )
      return false;
    return (
      <div className="delete-group">
        <button
          className="butn butn-delete"
          onClick={this.openModal}
          type="button"
        >
          Delete Group
        </button>
        {this.state.modalDisplay && this.renderModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withGoTo(withAppSync(DeleteGroup)));
