/* @flow */

import React from "react";
import classnames from "classnames";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";

type propTypes = {
  redshiftCluster: Object,
  ready: boolean,
  error: Object
};

class RedshiftClusterInfo extends React.Component<propTypes> {
  standarDisplay(field: string, value: string) {
    // eslint-disable-line class-methods-use-this
    const v = (value && value.split("\n")) || [];
    const p = v.length > 1 ? v.map((i, key) => <p key={key}>{i}</p>) : value;
    return (
      <div className="display" style={{ width: "100%" }}>
        <div
          className="field-name"
          style={{
            display: "inline-table",
            width: "200px",
            textAlign: "left"
          }}
        >
          {field}
        </div>
        <div
          className={classnames("content")}
          style={{
            marginLeft: "15px",
            display: "inline-table"
          }}
        >
          {p || "-"}
        </div>
      </div>
    );
  }

  render() {
    const { redshiftCluster } = this.props;
    return (
      <div className="cluster-create">
        {this.props.error && (
          <Error error={this.props.error} path="RedshiftClusterInfo" />
        )}
        {!this.props.error && !this.props.ready && (
          <Loading message="RedshiftCluster Information" />
        )}
        {!this.props.error && this.props.ready && (
          <div className={"dataset-info"}>
            {this.standarDisplay("Name", redshiftCluster.cluster_name)}
            {this.standarDisplay(
              "AWS Resource Name",
              redshiftCluster.resource_name
            )}
            {this.standarDisplay("Environment", redshiftCluster.platform.name)}
            {this.standarDisplay("AWS Account", redshiftCluster.platform.aws)}
            {this.standarDisplay("Status", redshiftCluster.status)}
            {this.standarDisplay(
              "Endpoint Address",
              redshiftCluster.endpoint_address
            )}
            {this.standarDisplay(
              "Endpoint Port",
              redshiftCluster.endpoint_port
            )}
            {this.standarDisplay(
              "Master Database Name",
              redshiftCluster.database_name
            )}
            <div className="display">
              <div
                className="field-name"
                style={{
                  display: "inline-table",
                  width: "200px",
                  textAlign: "left"
                }}
              >
                Security Groups
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  display: "inline-table",
                  width: "600px"
                }}
              >
                {redshiftCluster.vpc_security_groups.map((sg) => (
                  <div className="dataset-badge" key={sg}>
                    <div className="circle-tag"></div>
                    {sg}
                  </div>
                ))}
              </div>
            </div>
            <div className="display">
              <div
                className="field-name"
                style={{
                  display: "inline-table",
                  width: "200px",
                  textAlign: "left"
                }}
              >
                IAM Roles
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  display: "inline-table",
                  width: "600px"
                }}
              >
                {redshiftCluster.iam_roles.map((role) => (
                  <div className="dataset-badge" key={role}>
                    <div className="circle-tag"></div>
                    {role}
                  </div>
                ))}
              </div>
            </div>
            {this.standarDisplay("Node Type", redshiftCluster.node_type)}
            {this.standarDisplay(
              "Number Of Nodes",
              redshiftCluster.number_of_nodes
            )}
            {this.standarDisplay(
              "Version Upgrade",
              redshiftCluster.allow_version_upgrade.toString()
            )}
          </div>
        )}
      </div>
    );
  }
}

export default RedshiftClusterInfo;
