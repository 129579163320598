export const getCartDatasetLight = {
  query: `query Q ($cartUri:String!, $optionsDatasets:QueryOptions) {
        getCart (cartUri:$cartUri) {
            uri
            groupuri
            name
            project_custom_tags {
                Key
                Value
            }
            region
            is_refresh_auto_enabled
            description
            resource_name
            creatoruri
            createdat
            database
            uuid
            datasets (options: $optionsDatasets) {
                uri
            }
        }
      }
    `,
  keyOutput: "getCart"
};
