/* @flow */

export const listAllMyDatasetsUris = {
  query: `query Q ($options: QueryOptions) {
        listAllMyDatasets(options:$options) {
              uri
              name
              access
        }
    }
    `,
  keyOutput: "listAllMyDatasets"
};
