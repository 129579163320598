/* @flow */
import React from "react";
import Stepper from "../../../../components/Stepper";

type propTypes = {
  isLock: boolean,
  step: number,
  onStepChange: Function
};
type stateTypes = {};

class LevelOfMaturity extends React.PureComponent<propTypes, stateTypes> {
  static defaultProps = {
    isLock: false,
    step: 0,
    onStepChange: () => {}
  };

  render() {
    return (
      <Stepper
        step={this.props.step}
        isLock={this.props.isLock}
        onStepChange={this.props.onStepChange}
        steps={[
          {
            label: "Initial",
            tooltip:
              "in construction/ draft. Can contain inconsistent data. NO COMMITMENT of the owner to keep dataset structure and expected type of data flied."
          },
          {
            label: "Managed",
            tooltip:
              "dataset in published version with RISK of inconsistent data. NO COMMITMENT of the owner to keep dataset structure and expected type of data flied"
          },
          {
            label: "Standardized",
            tooltip:
              "dataset in published version with RISK of inconsistent data. COMMITMENT of the owner to keep dataset structure and expected type of data flied"
          },
          {
            label: "Optimized",
            tooltip:
              "dataset in published version with NO RISK of inconsistent data. COMMITMENT of the owner to keep dataset structure and expected type of data flied"
          }
        ]}
      />
    );
  }
}

export default LevelOfMaturity;
