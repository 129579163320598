/* @flow */
import React from "react";
import Links from "../../../links";

type propTypes = {
  project: {
    uri: string,
    name: string,
    group: {
      uri: string,
      name: string
    }
  }
};

class ProjectUsage extends React.PureComponent<propTypes> {
  render() {
    const { project } = this.props;
    return (
      <div className={"mt-1 border log-container"}>
        <div className="log-details">
          <p>
            <i className="fas fa-tasks" />
            <span className={"ml-2 label-display"}>PROJECT : </span>
            <Links.Cart.View cartUri={project.uri}>
              {project.name}
            </Links.Cart.View>
          </p>
          <p>
            <i className="fas fa-user-friends" />
            <span className={"ml-1 label-display"}>GROUP : </span>
            <Links.Group.View
              uriGroup={project.group.uri}
              state={{ group: project.group }}
            >
              {project.group.name}
            </Links.Group.View>
          </p>
        </div>
      </div>
    );
  }
}

export default ProjectUsage;
