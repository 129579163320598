export const mutationEditStage = {
  query: `mutation M ($stageId: String!, $stageInput: StageUpdateInput, $datasetUri: String!) {
        updateStage(stageId: $stageId, stageInput: $stageInput, datasetUri: $datasetUri) {
            id
            name
            table
            description
            is_default
            location
        }
    }`,
  keyOutput: "updateStage"
};
