/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import "./style.less";
import DeleteTrustModal from "./deleteTrustModal";
import ItemFromList from "../../../../components/itemFromList";
import Col from "../../../../components/col";
import Row from "../../../../components/row";
import ButtonAction from "../../../../components/buttonAction";
import TrustName from "./trustName";
import Paginator from "../../../../components/paginator";

type propTypes = {
  api: GraphQl,
  editTrust: Function,
  accountUri: string,
  showGlobalNotification: Function
};

type stateTypes = {
  isFetchingTrust: boolean,
  trusts: Array<Object>,
  error?: Object,
  deleteTrustModal: Object | boolean,
  editTrust: Object,
  total: number,
  offset: number
};

class TrustList extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isFetchingTrust: true,
      deleteTrustModal: false,
      trusts: [],
      total: 0,
      offset: 0
    };
  }

  componentDidMount() {
    this.getTrusts();
  }

  getTrusts = () => {
    const options = {
      limit: 10,
      offset: this.state.offset
    };
    this.setState({ isFetchingTrust: true });
    return this.props.api.account
      .listTrusts(this.props.accountUri, options)
      .then((trusts) => {
        this.setState({
          isFetchingTrust: false,
          total: trusts.total,
          trusts: trusts.result.trusts
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingTrust: false,
          error
        });
      });
  };

  getGroupsTrustFromPagination = (offset: number) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getTrusts().then(r))
    );

  openDeleteTrustModal = (trust: Object) => {
    this.setState({ deleteTrustModal: { trust } });
  };

  editTrust = (trust: Object) => {
    this.props.editTrust(trust);
  };

  closeDeleteTrustModal = () => {
    this.setState({ deleteTrustModal: false });
  };

  onDeleteTrustSuccess = () => {
    this.props.showGlobalNotification({
      message: "Trust deleted",
      type: "success"
    });
    this.closeDeleteTrustModal();
    this.getTrusts();
  };

  onDeleteTrustFailure = () => {
    this.props.showGlobalNotification({
      message: "Failed to delete trust",
      type: "alert"
    });
  };

  render() {
    return (
      <div className="list-trusts">
        <div className="mt-2">
          {this.state.error && (
            <Error error={this.state.error} path="TrustManagement.list" />
          )}
          <div className="list-trusts-card">
            <Row className="mx-2 mt-4">
              <Paginator
                limit={10}
                isLoadingPage={this.state.isFetchingTrust}
                initialOffset={0}
                totalCount={this.state.total}
                list={this.state.trusts}
                componentRender={(trust) => (
                  <Col size={12}>
                    <ItemFromList
                      key={trust.arn}
                      name={<TrustName trust={trust} />}
                      actions={[
                        <ButtonAction
                          label="Edit"
                          className="butn butn-create"
                          onClick={() => this.editTrust(trust)}
                        />,
                        <ButtonAction
                          label="Delete"
                          className="butn butn-delete"
                          onClick={() => this.openDeleteTrustModal(trust)}
                        />
                      ]}
                    />
                  </Col>
                )}
                loadPage={this.getGroupsTrustFromPagination}
              />
            </Row>
          </div>
          {!this.state.isFetchingTrust &&
            this.state.trusts.length <= 0 &&
            "This organization has no trust created."}
        </div>
        {typeof this.state.deleteTrustModal === "object" && (
          <DeleteTrustModal
            api={this.props.api}
            trust={this.state.deleteTrustModal.trust}
            hide={this.closeDeleteTrustModal}
            onDeleteTrustSuccess={this.onDeleteTrustSuccess}
            onDeleteTrustFailure={this.onDeleteTrustFailure}
          />
        )}
      </div>
    );
  }
}

export default TrustList;
