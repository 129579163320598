/* @flow */
import React from "react";
import Row from "../../../components/row";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import CopyToClipboard from "../../../components/copyToClipboard";
import Breadcrumb from "../../../components/breadcrumb";
import "./style.less";
import GraphQl from "../../../graphQL";

type propTypes = {
  api: GraphQl,
  location: Object,
  match: Object
};

type stateTypes = {
  ready: boolean,
  credentials: {
    AccessKeyId: string,
    SecretKey: string,
    Token: string,
    ConsoleUrl: string
  },
  error: boolean | Object
};

class DatasourceGenerateAdminCredentials extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      credentials: {},
      error: false
    };
  }

  componentDidMount() {
    this.props.api.datasource
      .getDatasourceAdminCredentials(this.props.match.params.uriDatasource)
      .then((credentials) => {
        this.setState({
          credentials,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path={"DatasourceGenerateAdminCredentials"}
        />
      );
    if (!this.state.ready)
      return <Loading message={"datasource generate admin credentials"} />;

    const fileContent = `[default]
aws_access_key_id = ${this.state.credentials.AccessKeyId}
aws_secret_access_key = ${this.state.credentials.SecretKey}
aws_session_token = ${this.state.credentials.Token}
`;

    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={" Datasource Aws Credentials"}
        />
        <Row className={"my-4 justify-content-center"}>
          <div className="containerCredentials">
            <div className="rowCredential">
              <div className="keyCredential">{"AccessKeyId:"}</div>
              <div className="valueCredential">
                {this.state.credentials.AccessKeyId}
              </div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.AccessKeyId}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="rowCredential">
              <div className="keyCredential">{"SecretKey:"}</div>
              <div className="valueCredential">{"XXXX"}</div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.SecretKey}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="rowCredential">
              <div className="keyCredential">{"Token:"}</div>
              <div className="valueCredential">{"XXXX"}</div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.Token}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>

            <hr />

            <div className="rowCredential">
              <div className="keyCredential">{"Credentials file format:"}</div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={fileContent}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>

            <hr />

            <div className="rowCredential">
              <div className="keyCredential">{"JSON format:"}</div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={JSON.stringify(this.state.credentials)}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>

            <hr />

            <div className="rowCredential justify-content-center">
              <div
                className="butn butn-flat min-width-160"
                onClick={() =>
                  window.open(this.state.credentials.ConsoleUrl, "_blank")
                }
              >
                Go to console
              </div>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

export default withAppSync(DatasourceGenerateAdminCredentials);
