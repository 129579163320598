export const queryListMyManagedShares = {
  query: `query Q ($options: QueryOptions) {
        getMyManagedSharesList (options: $options) {
            total
            offset
            limit
            results {
                id
                createdat
                updatedat
                request_status
                message
                reject_message
                group {
                    uri
                    name
                    account {
                        uri
                        name
                    }
                }
                dataset {
                    uri
                    name
                    access
                    expiration_date
                    can_share
                    datasource {
                        uri
                        name
                    }
                }
            }
        }
    }`,
  keyOutput: "getMyManagedSharesList"
};
