/* @flow */

import React, { Component } from "react";
import "./style.less";
import MultipleValueTextInputItem from "./MultipleValueTextInputItem";

type propTypes = {
  values: Array<string>,
  onItemAdded: Function,
  onItemDeleted: Function,
  label: string,
  name: string,
  placeholder: string,
  shouldAddOnBlur: boolean,
  classNameInput: string,
  suggestions: Array<string>,
  onValueChange?: Function,
  isLoading: boolean,
  lockAddItem: boolean,
  disabled?: boolean
};

type stateTypes = {
  value: string,
  isSuggestionsOpen: boolean
};

/**
 * A text input component for React which maintains and displays a collection
 * of entered values as an array of strings.
 */
class MultipleValueTextInput extends Component<propTypes, stateTypes> {
  wrapperRef: Object;

  static defaultProps = {
    onItemAdded: () => {},
    onItemDeleted: () => {},
    placeholder: "",
    values: [],
    label: "",
    shouldAddOnBlur: false,
    classNameInput: "",
    suggestions: [],
    isLoading: false,
    lockAddItem: false,
    disabled: false
  };

  constructor(props: propTypes) {
    super(props);
    this.state = {
      value: "",
      isSuggestionsOpen: true
    };
  }

  handleKeypress = (e: Object) => {
    // 13: Enter, 44: Comma
    if ([13, 44].includes(e.charCode)) {
      e.preventDefault();
      e.stopPropagation();
      this.handleItemAdd(this.state.value);
    }
  };

  handleValueChange = (e: Object) => {
    const { value } = e.target;
    this.setState({ value });
    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  handleItemAdd = (value: string) => {
    if (!this.props.disabled) {
      if (
        !this.props.lockAddItem &&
        value.trim().length > 0 &&
        !this.props.values.includes(value)
      ) {
        this.setState({ value: "" });
        const newValues = this.props.values.concat(value);
        this.props.onItemAdded({ target: { value: newValues, added: value } });
      }
    }
  };

  handleItemRemove = (value: string) => {
    if (!this.props.disabled) {
      const newValues = this.props.values.filter((v) => v !== value);
      this.props.onItemDeleted({ target: { value: newValues } });
    }
  };

  handleBlur = (e: Object) => {
    // 13: Enter, 44: Comma
    if (this.props.shouldAddOnBlur && this.state.value.trim().length > 0) {
      e.preventDefault();
      this.handleItemAdd(this.state.value);
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node: Object) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event: Object) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isSuggestionsOpen: false });
    } else {
      this.setState({ isSuggestionsOpen: true });
    }
  };

  render() {
    const {
      placeholder,
      label,
      name,
      classNameInput,
      values,
      shouldAddOnBlur,
      onItemDeleted,
      onItemAdded,
      isLoading,
      suggestions,
      onValueChange,
      ...forwardedProps
    } = this.props;

    return (
      <div className="multiple-value-text-input" ref={this.setWrapperRef}>
        {label && (
          <legend htmlFor={name} className="label-form">
            {label}
          </legend>
        )}
        <div className="multiple-value-text-input-item-container">
          {values.length > 0 && (
            <p>
              {values
                .filter((v, i) => values.indexOf(v) === i)
                .map((v) => (
                  <MultipleValueTextInputItem
                    value={v}
                    key={v}
                    deleteButton={<span>&times;</span>}
                    handleItemRemove={this.handleItemRemove}
                  />
                ))}
            </p>
          )}
        </div>
        <input
          className={classNameInput || "form-control bg-white"}
          name={name}
          placeholder={placeholder}
          value={this.state.value}
          type="text"
          onKeyPress={this.handleKeypress}
          onChange={this.handleValueChange}
          onBlur={this.handleBlur}
          {...forwardedProps}
        />
        {isLoading && <i className="fas fa-circle-notch fa-spin" />}
        {suggestions &&
          suggestions.length > 0 &&
          this.state.isSuggestionsOpen && (
            <ul className="searchSuggestions">
              {suggestions.map((suggestion) => (
                <li key={suggestion}>
                  <div onClick={() => this.handleItemAdd(suggestion)}>
                    {suggestion}
                  </div>
                </li>
              ))}
            </ul>
          )}
      </div>
    );
  }
}

export default MultipleValueTextInput;
