export const slugifyNameForDatasourceResourceName = (name = "") =>
  name
    .trim()
    .replace(/[^a-zA-Z0-9]/g, "") // change all characters except numbers and letters
    .toLowerCase();

// This slugifier allows only characters valid for s3 key//
export const slugifyNameForDatasetPrefix = (name = "") =>
  name
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9_]/g, "");
