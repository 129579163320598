export const getEMRTemplate = {
  query: `query Q ($cartUri:String!,$platformUri:String!) {
        getEMRTemplate (cartUri:$cartUri, platformUri:$platformUri) {
            templateurl
            oneclickurl
        }
      }
    `,
  keyOutput: "getEMRTemplate"
};
