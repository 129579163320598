export const listLakeFormationRegistrations = {
  query: `query Q ($platformUri : String!) {
        listLakeFormationRegistrations(platformUri : $platformUri) {
          region
          is_registered_with_lakeformation(platformUri : $platformUri)
        }
      }
    `,
  keyOutput: "listLakeFormationRegistrations"
};
