export const mutationDeleteStage = {
  query: `mutation M ($stageId: String!, $datasetUri: String!, $deleteAwsResource: Boolean) {
        deleteStage(stageId: $stageId, datasetUri: $datasetUri, deleteAwsResource: $deleteAwsResource) {
            id
            name
            table
            description
            is_default
            location
        }
    }`,
  keyOutput: "deleteStage"
};
