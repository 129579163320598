import React from "react";
import PropTypes from "prop-types";
import Row from "../../../components/row";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import CopyToClipboard from "../../../components/copyToClipboard";
import Breadcrumb from "../../../components/breadcrumb";
import "./style.less";

class DatasourceGenerateETLCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      credentials: {},
      error: false,
      overwrite: this.props.match.params.overwrite === "true",
      description: {
        etluser_credentials_description:
          this.props.location.state.description || ""
      }
    };
  }

  componentDidMount() {
    this.props.api.datasource
      .generateCredentials(
        this.props.match.params.uriAccount,
        this.props.match.params.uriDatasource,
        this.state.description,
        this.state.overwrite
      )
      .then((response) => {
        if (!response) {
          this.setState({
            error: { message: "Credentials not found" }
          });
        }

        this.setState({
          ready: true,
          credentials: response
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path={"DatasourceGenerateETLCredentials"}
        />
      );
    if (!this.state.ready)
      return <Loading message={"datasource generate credentials"} />;

    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={" Datasource Aws Credentials"}
        />
        <Row className={"my-4 justify-content-center"}>
          <div className="containerCredentials">
            <div className="rowCredential">
              <div className="keyCredential">{"User name:"}</div>
              <div className="valueCredential">
                {this.state.credentials.user_name}
              </div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.user_name}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="rowCredential">
              <div className="keyCredential">{"Access key ID:"}</div>
              <div className="valueCredential">
                {this.state.credentials.access_key_id}
              </div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.access_key_id}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="rowCredential">
              <div className="keyCredential">{"Secret Access Key:"}</div>
              <div className="valueCredential">
                {this.state.credentials.secret_access_key}
              </div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.secret_access_key}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

DatasourceGenerateETLCredentials.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uriAccount: PropTypes.string,
      uriDatasource: PropTypes.string,
      overwrite: PropTypes.bool
    })
  }),
  api: PropTypes.shape({
    datasource: PropTypes.shape({
      generateCredentials: PropTypes.func
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      account: PropTypes.shape({
        name: PropTypes.string
      }),
      playground: PropTypes.shape({
        name: PropTypes.string
      }),
      datasource: PropTypes.shape({
        name: PropTypes.string
      }),
      description: PropTypes.shape({
        etluser_credentials_description: PropTypes.string
      })
    })
  })
};

export default withAppSync(DatasourceGenerateETLCredentials);
