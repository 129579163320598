export const getDatabrewConsoleAccess = {
  query: `query Q ($cartUri: String!, $databrewProjectId:String!) {
        getDatabrewConsoleAccess (cartUri: $cartUri, databrewProjectId:$databrewProjectId){
          AccessKeyId 
          Expiration
          SecretKey
          Token
          ConsoleUrl
        }
      }
    `,
  keyOutput: "getDatabrewConsoleAccess"
};
