export const detachEquansDatasource = {
  query: `
  mutation Q ($datasourceUri: String!) {
    detachEquansDatasource(datasourceUri: $datasourceUri){
       uri
       action_type
    }
  }
`,
  keyOutput: "detachEquansDatasource"
};
