/* @flow */
import React from "react";
import "./style.less";
import { convertNumber } from "../../../../utils/size";

type propTypes = {
  completeness: Object
};

type stateTypes = {};

class DisplayCompleteness extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.completeness) {
      return false;
    }

    return (
      <div className="mr-2">
        <div className="metadata-title">
          <span>Completeness</span>
        </div>
        <div className="completeness-container">
          <div className="completeness-valid">
            <span className="completeness-valid-title">Distinct</span>
            <span className="completeness-nbrows">
              {convertNumber(this.props.completeness.nb_valid)}
            </span>
            <span className="completeness-percent">
              {this.props.completeness.prct_valid
                ? Math.round(this.props.completeness.prct_valid * 100) / 100
                : "0"}{" "}
              %
            </span>
          </div>
          <div className="completeness-missing">
            <span className="completeness-missing-title">Missing</span>
            <span className="completeness-nbrows">
              {convertNumber(this.props.completeness.nb_missing)}
            </span>
            <span className="completeness-percent">
              {this.props.completeness.prct_missing
                ? Math.round(this.props.completeness.prct_missing * 100) / 100
                : "0"}{" "}
              %
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayCompleteness;
