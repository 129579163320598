/* @flow */
import React from "react";

import GraphQl from "../../../graphQL";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import DomainCreate from "./DomainCreate";
import Domain from "./Domain";

type propTypes = {
  api: GraphQl,
  playground: Object
};

type stateTypes = {
  ready: boolean,
  error: Object,
  openCreateDialog: boolean,
  domains: Array<Object>
};

class Domains extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      openCreateDialog: false,
      ready: true,
      domains: []
    };
  }

  componentDidMount() {
    this.getDomains();
  }

  getDomains = () => {
    this.setState({
      ready: false
    });
    this.props.api.smstudio
      .listDomains(this.props.playground.uri)
      .then((domains) => {
        this.setState({
          ready: true,
          domains: domains.results
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"Domains"} />;
    if (!this.state.ready)
      return <Loading message="Sagemaker Studio Domains" />;
    return (
      <React.Fragment>
        <div className={"environments-container"}>
          <div>
            <div
              className="butn butn-flat"
              onClick={() => this.setState({ openCreateDialog: true })}
            >
              <i className="fas fa-plus butn-icon" />
              <span className="butn-text">Create Domain</span>
            </div>
          </div>
          {this.state.error && (
            <div className="mt-3">
              <Error error={this.state.error} path={"Domains"} stringOnly />
            </div>
          )}
          <div className={"card-container"}>
            {this.state.domains.length <= 0 && (
              <div style={{ marginLeft: "15px" }}>No domains to display.</div>
            )}
            {this.state.domains.length >= 0 &&
              this.state.domains.map((d) => (
                <Domain
                  key={d.id}
                  api={this.props.api}
                  domain={d}
                  reloadDomains={this.getDomains}
                />
              ))}
          </div>
          {this.state.openCreateDialog && (
            <DomainCreate
              api={this.props.api}
              platform={this.props.playground}
              open={this.state.openCreateDialog}
              onClose={() => this.setState({ openCreateDialog: false })}
              reloadDomains={this.getDomains}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(Domains);
