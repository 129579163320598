/* @flow */

export const listFeatureToggles = {
  query: `query Q ($search: FeatureToggleSearch, $options: ListOptions){
        listFeatureToggles(search:$search, options:$options){
          id
          code
          description
          version
          environment
          status
          groupname
          gid
          groupuri
          userid
        }
    }`,
  keyOutput: "listFeatureToggles"
};
