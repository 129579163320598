/* @flow */

import type { NotificationTypePropsTypes } from "./notificationTypes";

export const queryListMyNotifications = {
  query: `query Q ($options: QueryOptions){
    listMyNotificationsList(options: $options) {
        total
        results {
            id
            type {
                code
                name
            }
            is_read
            createdat
            message
            target_uri 
            
            ... on ShareNotification {
                data {
                    group {
                        uri
                        name
                        account {
                            uri
                            name
                        }
                    }
                    dataset {
                        uri
                        name
                        access
                        expiration_date
                        can_share
                        datasource {
                            uri
                            name
                        }
                    }
                    createdat
                    updatedat
                    request_status
                    message
                    reject_message
                }
            }

            ... on TrustNotification {
                data {
                    arn
                    description
                    status
                    type
                    id
                    group {
                        name
                        uri
                    }
                    platform {
                        aws
                        name
                        uri
                    }
                }
            }
        }
    }
}
`,
  keyOutput: "listMyNotificationsList"
};

export type SharePropsTypes = {
  group: {
    uri: string,
    name: string,
    account: {
      uri: string,
      name: string
    }
  },
  dataset: {
    uri: string,
    name: string,
    access: string,
    datasource: ?{
      uri: string,
      name: string
    }
  },
  createdat: string,
  updatedat: string,
  request_status: string
};

export type NotificationPropsTypes = {
  id: string,
  type: NotificationTypePropsTypes,
  is_read: boolean,
  message: string,
  createdat: string | Date,
  updatedat: ?(string | Date),
  data: SharePropsTypes | Object,
  target_uri: ?string
};

export type ListMyNotificationsPropsTypes =
  $ReadOnlyArray<NotificationPropsTypes>;
