export const mutationSetDefaultStage = {
  query: `mutation M ($stageId: String!, $datasetUri: String!) {
        setDefaultStage(stageId: $stageId, datasetUri: $datasetUri) {
            id
            uri
            name
            table
            location
            is_default
            type
        }
    }`,
  keyOutput: "setDefaultStage"
};
