export const queryGetSagemakerNotebookInstance = {
  query: `query Q ($instanceId: String!) {
        getSagemakerNotebookInstance(instance_id:$instanceId) {
            id
            arn
            name
            instance_type
            status
            createdat
            updatedat
            error
            region
            enable_shutdown_idle_lifecycle
            network {
                id
                name
                subnet_ids
                security_group_ids
            }
            environment {
                uri
                environment
                name
                aws
            }
        }
    }`,
  keyOutput: "getSagemakerNotebookInstance"
};
