export const getRedshiftClusterCredentials = {
  query: `query Q ($clusterUri:String!, $cartUri:String) {
        getRedshiftClusterCredentials (clusterUri:$clusterUri, cartUri:$cartUri) {
          resource_name
          database_name
          database_user
          database_password
        }
    }
    `,
  keyOutput: "getRedshiftClusterCredentials"
};
