/* @flow */
import React, { useMemo } from "react";
import { connect } from "react-redux";

import HelpOn from "../../../../components/helpOn";
import { formatDate } from "../../../../utils/date";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import "./datasetSchemaStatus.less";

type propTypes = {
  customCrawler: Object,
  dataset: Object,
  isForceRefreshCustomCrawler: boolean,
  isLoadingCustom: boolean,
  setIsForceRefreshCustomCrawler: Function
};

const DatasetSchemaStatusCustomCrawler = ({
  customCrawler,
  dataset,
  isForceRefreshCustomCrawler,
  isLoadingCustom,
  setIsForceRefreshCustomCrawler
}: propTypes) => {
  const environmentName = useMemo(() => dataset.uri.split(":")[3], [dataset]);

  const statusClassName = useMemo(() => {
    if (customCrawler && customCrawler.status) {
      const { status } = customCrawler;
      switch (status) {
        case "FAILED":
          return "badge-log badge-log-danger";
        case "SUCCEEDED":
          return "badge-log badge-log-success";
        default:
          return "badge-log badge-log-primary";
      }
    }

    return "badge-log badge-log-primary";
  }, [customCrawler]);

  const hasToBeRefreshed = useMemo(() => {
    if (customCrawler) {
      return ["STARTING", "RUNNING"].includes(customCrawler.status);
    }
    return false;
  }, [customCrawler]);

  return (
    <div className="dataset-schema-status-item mt-1 border log-container">
      <div>
        <div className={statusClassName}>{customCrawler.status}</div>
      </div>
      <div className="log-details">
        <p>
          <span className="label-display">CUSTOM CRAWLER</span>
        </p>
        <p>
          <i className="pr-3 fas fa-box" />
          <span className="label-display">ENVIRONMENT : </span>
          {environmentName}
        </p>
        <p>
          <i className="pr-3 fa fa-folder" />
          <span className="label-display">PREFIX : </span>
          {customCrawler.prefix || "N/A"}
        </p>
        <p>
          <i className="pr-2 fab fa-aws" />
          <span className="label-display">S3 PATH : </span>
          {customCrawler.path}
        </p>
        <p>
          <i className="pr-3 fas fa-server" />
          <span className="label-display">STEP : </span>
          {customCrawler.step}
        </p>
      </div>
      <div className="refresh-button-container">
        {!isForceRefreshCustomCrawler && hasToBeRefreshed && (
          <div
            className="butn butn-flat"
            onClick={() => setIsForceRefreshCustomCrawler(true)}
          >
            {isLoadingCustom ? (
              <i className="fas fa-sync-alt fa-spin fa-spacing" />
            ) : (
              <i className="fas fa-sync-alt" />
            )}
            <span className="butn-text">Refresh status</span>
          </div>
        )}
      </div>
      <div className="custom-crawler-msg">
        {customCrawler.error && customCrawler.error !== "" ? (
          <div
            title={customCrawler.error}
            className="custom-crawler-error-content"
          >
            There are some crawler errors
            <HelpOn
              content={
                <div>
                  <h3>Errors</h3>
                  <div>{customCrawler.error}</div>
                </div>
              }
            />
          </div>
        ) : undefined}
        {customCrawler.warning && customCrawler.warning !== "" ? (
          <div
            title={customCrawler.warning}
            className="custom-crawler-warning-content"
          >
            There are custom crawler errors
            <HelpOn
              content={
                <div>
                  <h3>Warning</h3>
                  <div>{customCrawler.warning}</div>
                </div>
              }
            />
          </div>
        ) : undefined}
      </div>
      <div className="date-log-container">
        <p className="date-log">
          {customCrawler.createdat
            ? `Created ${formatDate(customCrawler.createdat)}`
            : ""}
        </p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasetSchemaStatusCustomCrawler)
);
