/* @flow */
import React from "react";
import "./view.less";
import DatasetCard from "../../datasets/card/DatasetCard";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Paginator from "../../../components/paginator";
import Loading from "../../../components/loading";
import Error from "../../../components/error";

type propTypes = {
  api: GraphQl,
  datasource: Object
};

type stateTypes = {
  isFetching: boolean,
  isSearch: boolean,
  term: string,
  datasets: Array<Object>,
  ready: boolean,
  error: Object,
  showDataFilters: boolean
};

class DatasetsList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isSearch: false,
      term: "",
      datasets: [],
      ready: false,
      showDataFilters: false,
      error: null
    };
  }

  componentDidMount() {
    this.isDataFiltersFeatureEnabled();
    this.getDatasets();
  }

  getDatasets = (offset = 0) => {
    this.setState({ isFetching: true, isSearch: true });
    const options = {
      limit: 10,
      offset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.datasource
      .getDatasets(this.props.datasource.uri, options)
      .then((datasets) => {
        if (!datasets) return false;
        this.setState({ datasets, isFetching: false, ready: true });
        return datasets;
      });
  };

  isDataFiltersFeatureEnabled() {
    const accountUri = `uri:account:${this.props.datasource.uri.split(":")[2]}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATA_FILTERS", accountUri)
      .then((showDataFilters) => {
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  onChangeInput = (e) => {
    const term = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          term
        },
        this.getDatasets
      );
    }, 300);
  };

  render() {
    const { datasource } = this.props;
    const { datasets } = this.state;
    if (this.state.error)
      return <Error error={this.state.error} path={"Dataset List"} />;
    return (
      <div className="card-container">
        {this.state.isSearch ? (
          <div className="search-datasource-datasets">
            <div className="search-input">
              <input
                className="form-control"
                placeholder="Search"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.isFetching && <Loading message="Dataset List" />}
        {this.state.ready && (
          <Paginator
            limit={10}
            initialOffset={0}
            list={datasets}
            componentRender={(dataset) => (
              <DatasetCard
                key={dataset.uri}
                dataset={{
                  uri: dataset.uri,
                  resourceName: dataset.resource_name,
                  datasourceRegion: datasource.region,
                  name: dataset.name,
                  description: dataset.description,
                  type: dataset.type,
                  dataSourceBucket: datasource.bucket,
                  createdat: dataset.createdat,
                  expiration_date: dataset.expiration_date,
                  datasourceOwner: datasource.owner,
                  arn: dataset.arn,
                  ...dataset
                }}
                showDataFilters={this.state.showDataFilters}
              />
            )}
            loadPage={this.getDatasets}
            onNoResult={() => (
              <p className="total-search-results">No dataset found</p>
            )}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(DatasetsList);
