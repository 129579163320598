/* @flow */

import React from "react";
import GroupLabels from "./groupLabels";

import "./groupName.less";

type propTypes = {
  group: {
    name: string,
    account: {
      name: string,
      uri: string
    },
    platformsList?: {
      total: number
    },
    cartsList?: {
      total: number
    },
    identitiesList?: {
      total: number
    }
  }
};

const GroupName = ({ group = {} }: propTypes) => (
  <div className="groupname">
    <div className="names">
      {(group.account || {}).name && (
        <span className="account-name">
          {`[${(group.account || {}).name}] `}
        </span>
      )}
      <span className="subtitle group-name">{` ${group.name}`}</span>
      <GroupLabels group={group} />
    </div>
  </div>
);

export default GroupName;
