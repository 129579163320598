export const getGroupProjects = {
  query: `query Q ($groupUri: String!,  $options: QueryOptions) {
        listGroupCarts(groupUri: $groupUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                region
                createdat
            }
        }
    }`,
  keyOutput: "listGroupCarts"
};
