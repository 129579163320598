/* @flow */

import React from "react";
import Links from "../../../links";
import "./style.less";

type propsTypes = {
  datasetUri: string,
  datasetName: string
};

const TextWithoutProjects = ({
  datasetUri = "",
  datasetName = ""
}: propsTypes) => (
  <div className="simple-notification">
    <span>The dataset </span>
    <span className="link-dataset">
      <Links.Dataset.View uriDataset={datasetUri}>
        {datasetName}
      </Links.Dataset.View>
    </span>
    <span>&nbsp;that you own is out of date</span>
  </div>
);

export default TextWithoutProjects;
