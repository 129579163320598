/* eslint-disable no-return-assign */
/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import "./style.less";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import Modal from "../../../../components/modal";

type propTypes = {
  uriPlayground: string,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  registrations: Array<Object>,
  error: ?Object,
  rollbackModal: Object | boolean,
  rollbackError: ?Object,
  rollbackRegion: string,
  isRollbacking: boolean,
  isFetchingRegistrations: boolean
};

class LakeFormationRegistrations extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      error: false,
      registrations: [],
      isRollbacking: false,
      isFetchingRegistrations: false,
      rollbackError: null,
      rollbackModal: false,
      rollbackRegion: ""
    };
  }

  componentDidMount() {
    this.listLakeFormationRegistrations();
  }

  listLakeFormationRegistrations = () => {
    this.setState({ error: null, isFetchingRegistrations: true });
    this.props.api.playground
      .listLakeFormationRegistrations(this.props.uriPlayground)
      .then((registrations) => {
        this.setState({
          ready: true,
          isFetchingRegistrations: false,
          registrations
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isFetchingRegistrations: false
        });
      });
  };

  rollbackLakeFormationMigration = (region) => {
    this.setState({ error: null, isRollbacking: true });
    this.props.api.playground
      .rollbackLakeFormationMigration(this.props.uriPlayground, region)
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Started AWS Lake Formation migration Rollback." +
            " This operation may take minutes to hours ",
          type: "success"
        });
        this.listLakeFormationRegistrations();
        this.closeRollbackModal();
        this.setState({ isRollbacking: false });
      })
      .catch((error) => {
        this.setState({
          error,
          rollbackError: error,
          isRollbacking: false
        });
      });
  };

  openRollbackModal = (region) =>
    this.setState({ rollbackModal: true, rollbackRegion: region });

  closeRollbackModal = () =>
    this.setState({
      rollbackModal: false,
      rollbackError: null,
      rollbackRegion: "",
      isRollbacking: false
    });

  static titleRollbackModal() {
    return (
      <div>
        <span>
          <i>Deregister Environment</i>
        </span>
      </div>
    );
  }

  bodyRollbackModal() {
    return (
      <div>
        <span>
          Are you sure you want to rollback{" "}
          <i>
            <b>{this.props.uriPlayground.split(":")[3]}</b>
          </i>{" "}
          AWS Lake Formation migration in region{" "}
          <i>
            <b>{this.state.rollbackRegion}</b>
          </i>{" "}
          ?
        </span>
      </div>
    );
  }

  rollbackModal() {
    return (
      <Modal
        title={LakeFormationRegistrations.titleRollbackModal()}
        body={this.bodyRollbackModal()}
        errorMessage={this.state.rollbackError}
        actions={[
          <button
            type="button"
            className="butn butn-delete"
            onClick={() =>
              this.rollbackLakeFormationMigration(this.state.rollbackRegion)
            }
          >
            {this.state.isRollbacking && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            Rollback
          </button>,
          <button
            type="button"
            className="butn"
            onClick={() => this.closeRollbackModal()}
          >
            Close
          </button>
        ]}
      />
    );
  }

  render() {
    if (!this.state.ready)
      return <Loading message={"LakeFormation Registrations"} />;
    return (
      <div>
        {this.state.error && (
          <Error
            stringOnly
            error={this.state.error}
            path={"LakeFormationRegistrations"}
          />
        )}
        <div>
          <div className="registration-actions">
            <button
              type="submit"
              onClick={() => this.listLakeFormationRegistrations()}
              className="butn refresh-table-btn"
            >
              {this.state.isFetchingRegistrations ? (
                <i className="fas fa-sync-alt fa-spin fa-spacing" />
              ) : (
                <i
                  className="fas fa-sync-alt fa-spacing"
                  onClick={() => this.listLakeFormationRegistrations()}
                />
              )}
            </button>
          </div>
        </div>
        <div>
          {this.state.isFetchingRegistrations && (
            <Loading message={"Registrations"} />
          )}
        </div>
        <div className="registration-type-container">
          <table className="table">
            <thead>
              <tr>
                <th className="col-env" scope="col">
                  REGION
                </th>
                <th className="col-env" scope="col">
                  REGISTERED WITH LAKE FORMATION
                </th>
                <th className="col-env table-action-column" scope="col">
                  ROLLBACK
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.registrations.map((registration) => (
                <tr className="table-row">
                  <th className="table-env-column">{registration.region}</th>
                  <td className="table-action-column">
                    <span
                      className={classnames("status-row", {
                        "color-green-status":
                          registration.is_registered_with_lakeformation ===
                          true,
                        "color-warning-status":
                          registration.is_registered_with_lakeformation ===
                          false
                      })}
                    >
                      {registration.is_registered_with_lakeformation
                        ? "Yes"
                        : "No"}
                    </span>
                  </td>
                  <td className="table-action-column">
                    {registration.is_registered_with_lakeformation ? (
                      <i
                        onClick={() =>
                          this.openRollbackModal(registration.region)
                        }
                        className="far fa-trash-alt"
                      ></i>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>{this.state.rollbackModal && this.rollbackModal()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(LakeFormationRegistrations));
