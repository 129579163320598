/* @flow */
import React from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "../../components/loading";
import DatasetCardAddToProject from "../datasets/card/DatasetCardAddToProject";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import Breadcrumb from "../../components/breadcrumb";
import GraphQl from "../../graphQL";
import { updateMessage } from "../globalNotifications/actions";
import withGoTo from "../goToHOC";
import "./style.less";
import routes from "../routes";

type propTypes = {
  match: {
    params: {
      uriDatasource: string,
      uriAccount: string
    }
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};
type stateTypes = {
  ready: boolean,
  isOpenAction: boolean,
  datasets: Array<Object>,
  error: Object,
  datasource: Object,
  listDatasetsToAdd: Array<String>,
  selectAll: boolean,
  collibraLinks: Array<Object>,
  collibraLinkId: string
};

class CollibraDatasource extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      datasource: {
        name: "-",
        uri: "-",
        datasets: []
      },
      isOpenAction: false,
      datasets: [],
      error: null,
      listDatasetsToAdd: [],
      selectAll: false,
      collibraLinks: [],
      collibraLinkId: ""
    };
  }

  componentDidMount() {
    this.getDatasource();
    this.getCollibraLinks();
  }

  getCollibraLinks = () => {
    this.setState({
      ready: false
    });
    this.props.api.collibra
      .list(this.props.match.params.uriAccount)
      .then((collibraLinks) => {
        this.setState({
          ready: true,
          collibraLinks: collibraLinks.map((c) => ({
            value: c.id,
            label: `${c.domain_name} - ${c.domain_id}`
          }))
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  getDatasource = () =>
    this.props.api.datasource
      .getDatasourceDatasetsLight(this.props.match.params.uriDatasource)
      .then((datasource) => {
        return new Promise((resolve) => {
          this.setState(
            {
              datasource,
              datasets: datasource.datasets,
              ready: true
            },
            resolve
          );
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  addDatasetToList = (dataset) => {
    this.setState(
      (prevState) => ({
        listDatasetsToAdd: [...prevState.listDatasetsToAdd, dataset.uri]
      }),
      () => {
        if (
          this.state.listDatasetsToAdd.length === this.state.datasets.length
        ) {
          this.setState({ selectAll: true });
        }
      }
    );
  };

  removeDatasetToList = (datasetToRemove) => {
    this.setState((prevState) => {
      const newList = prevState.listDatasetsToAdd;
      const index = this.state.listDatasetsToAdd.indexOf(datasetToRemove.uri);
      if (index > -1) {
        newList.splice(index, 1);
      }
      return { listDatasetsToAdd: newList, selectAll: false };
    });
  };

  addDatasets = () => {
    this.state.listDatasetsToAdd.forEach((d) => {
      this.publishToCollibra(d);
    });
    this.props.showGlobalNotification({
      message: "Publishing datasets to Collibra. It may take few minutes...",
      type: "success"
    });
    this.props.goTo({
      route: routes.Datasource.View,
      params: {
        uriDatasource: this.props.match.params.uriDatasource,
        uriAccount: this.props.match.params.uriAccount
      }
    });
  };

  publishToCollibra = (datasetUri) => {
    return this.props.api.collibra
      .indexDatasetOnCollibra(datasetUri, this.state.collibraLinkId)
      .catch((error) => {
        this.setState({ error });
      });
  };

  selectAll = () => {
    this.setState(
      (prevState) => ({ selectAll: !prevState.selectAll }),
      () => {
        if (this.state.selectAll) {
          const arrDatasetsUri = [];
          this.state.datasets
            .slice(0, 150)
            .map((dataset) => arrDatasetsUri.push(dataset.uri));
          this.setState({ listDatasetsToAdd: arrDatasetsUri });
        } else {
          this.setState({ listDatasetsToAdd: [] });
        }
      }
    );
  };

  isDefaultSelect = (dataset) =>
    !!this.state.listDatasetsToAdd.find(
      (datasetToAdd) => datasetToAdd === dataset.uri
    );

  handleClick =
    (callback = () => {}) =>
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      return callback();
    };

  onClick = () => {
    this.setState((prevState) => ({
      isOpenAction: !prevState.isOpenAction
    }));
  };

  getDatasets = () => (
    <div className="content">
      {this.state.error && (
        <Error error={this.state.error} path="PublishDatasourceToCollibra" />
      )}
      {!this.state.error && !this.state.ready && <Loading message="Datasets" />}
      {!this.state.error && this.state.ready && (
        <React.Fragment>
          <div className="selectAll">
            {this.state.datasets.length > 0 &&
              (!this.state.selectAll ? (
                <div className="selectAll-text" onClick={this.selectAll}>
                  <i className={"butn-icon far fa-square"} />
                  <span className="butn-text">Select All </span>
                </div>
              ) : (
                <div className="selectAll-text" onClick={this.selectAll}>
                  <i className={"butn-icon far fa-check-square"} />
                  <span className="butn-text">Unselect All</span>
                </div>
              ))}
          </div>
          <div className={"without-carousel w-100"}>
            {this.state.datasets.length <= 0 ? (
              <div className="no-items">No datasets to display.</div>
            ) : (
              this.state.datasets.map((dataset) => (
                <DatasetCardAddToProject
                  key={`dataset-card${dataset.uri}`}
                  addDatasetToList={this.addDatasetToList}
                  removeDatasetToList={this.removeDatasetToList}
                  dataset={{
                    nb_bookmarks: dataset.metric.statistic.nb_bookmarks,
                    nb_carts: dataset.metric.statistic.nb_carts,
                    nb_shares: dataset.metric.statistic.nb_shares,
                    access: "owner",
                    datasource_name: this.state.datasource.resource_name,
                    ...dataset
                  }}
                  defaultSelect={this.isDefaultSelect(dataset)}
                />
              ))
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );

  render() {
    return (
      <div className="add-dataset-to-project">
        <Breadcrumb
          view={`Publish datasets to Collibra from datasource: ${this.state.datasource.name}`}
        />
        <div className="search">
          <div className={"search-by-facet col-9"}>
            <Autocomplete
              options={this.state.collibraLinks}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return option.label;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Collibra Domain"
                  placeholder={"Select a Collibra Domain"}
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(event, value) => {
                this.setState({ collibraLinkId: value.value });
              }}
            />
          </div>
          <div className="create-container">
            <div className="new-dataset mt-2">
              <button
                type="button"
                className="butn"
                onClick={this.addDatasets}
                disabled={
                  this.state.listDatasetsToAdd.length <= 0 ||
                  this.state.collibraLinkId === ""
                }
              >
                <i className="fas fa-plus-circle butn-icon" />
                {
                  <span className="butn-text">
                    Add selected Data ({this.state.listDatasetsToAdd.length})
                  </span>
                }
              </button>
            </div>
          </div>
        </div>
        <div className={"datasource-body"}>
          <div className={"datasource-body-right"}>{this.getDatasets()}</div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CollibraDatasource)));
