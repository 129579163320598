/* @flow */
import React from "react";
import routes from "../../routes";

type propTypesPreviewAction = {
  access: "owner" | "shared" | "other",
  uri: string,
  name: string,
  type: string,
  defaultTable: string,
  goTo: Function
};
const DatasetCardPreviewAction = ({
  access,
  type,
  defaultTable,
  uri,
  name,
  goTo
}: propTypesPreviewAction) => {
  if (access === "other" || type !== "structured") {
    return false;
  }

  if (!defaultTable) {
    return (
      <div className="actionIcon tooltiped">
        <i className="previewAction fas fa-eye-slash" />
        <div className={"tooltiptext"}>No Data to preview</div>
      </div>
    );
  }

  return (
    <div className="actionIcon tooltiped">
      <div
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          return goTo({
            route: routes.Dataset.Preview,
            params: {
              uriDataset: uri
            },
            state: {
              dataset: {
                name,
                defaultTable
              }
            }
          });
        }}
      >
        <i className={"previewAction fas fa-eye"} />
      </div>
      <div className={"tooltiptext"}>Preview</div>
    </div>
  );
};

export default DatasetCardPreviewAction;
