export const isAccountGroup = (group) =>
  group.uri && group.uri.endsWith(":all");

export const isAccountAdminGroup = (group) =>
  group.name === "Organization Administrators Group";

export const isPlaygroundAdminGroup = (group) =>
  group.name.includes("Environment Administrators Group");

export const isDssInstanceAdminGroup = (group) =>
  group.name.includes("Dataiku DSS instance Administrators Group");

export const isDbxWorkspaceAdminGroup = (group) =>
  group.name.includes("Databicks Workspace Administrators Group");

export const filterGroupsService = (groups) =>
  groups.filter(
    (g) =>
      !isAccountGroup(g) &&
      !isAccountAdminGroup(g) &&
      !isPlaygroundAdminGroup(g)
  );

export const filterGroupsWithoutEnvironments = (groups) =>
  groups.filter(
    (group) => group.platformsList && group.platformsList.total > 0
  );
