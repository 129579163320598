export const queryGetDatasetCustomCrawlerStatus = {
  query: `
  query Q ($datasetUri: String!) {
    getCustomCrawlerStatus(datasetUri: $datasetUri) {
      status
      step
      createdat
      prefix
      path
      error
      warning
    }
  }
`,
  keyOutput: "getCustomCrawlerStatus"
};
