/* @flow */

import React from "react";
import classnames from "classnames";
import "./stageInfo.less";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import { isRSSourceType, isS3SourceType } from "../../../utils/constants";

type propTypes = {
  stage: Object,
  ready: boolean,
  error: Object
};

class StageInfo extends React.Component<propTypes> {
  standarDisplay(field: string, value: string) {
    // eslint-disable-line class-methods-use-this
    const v = (value && value.split("\n")) || [];

    const p = v.length > 1 ? v.map((i, key) => <p key={key}>{i}</p>) : value;
    return (
      <div className="display" style={{ width: "100%" }}>
        <div
          className="field-name"
          style={{
            display: "inline-table",
            width: "200px",
            textAlign: "left"
          }}
        >
          {field}
        </div>
        <div
          className={classnames("content")}
          style={{
            marginLeft: "15px",
            display: "inline-table"
          }}
        >
          {p || "-"}
        </div>
      </div>
    );
  }

  render() {
    const { stage, error, ready } = this.props;
    return (
      <div className="policies-create">
        {error && <Error error={error} path="StageInfo" />}
        {!error && !ready && <Loading message="Stage Info" />}
        {!error && ready && (
          <div className={"dataset-info"}>
            {this.standarDisplay("Name", stage.name)}
            {this.standarDisplay("Table name", stage.table)}
            {this.standarDisplay("Description", stage.description)}
            {this.standarDisplay("Dataset Name", stage.dataset.name)}
            {this.standarDisplay("Data Type", stage.dataset.type)}
            {this.standarDisplay("Dataset Owner", stage.dataset.owner.name)}
            {this.standarDisplay(
              "Datasource Name",
              stage.dataset.datasource.name
            )}
            {this.standarDisplay(
              "Datasource Region",
              stage.dataset.datasource.region
            )}
            {this.standarDisplay("S3 Path", stage.location)}
            {isS3SourceType(stage.source_type) &&
              this.standarDisplay("S3 Path", stage.location)}
            {isRSSourceType(stage.source_type) &&
              this.standarDisplay("Redshift database", stage.database)}
            {isRSSourceType(stage.source_type) &&
              this.standarDisplay("Redshift schema", stage.schema)}
          </div>
        )}
      </div>
    );
  }
}

export default StageInfo;
