/* @flow */
import React from "react";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import AccelerationTemplateCard from "./components/accelerationTemplateCard";
import "./style.less";
import Logger from "../../../utils/logger";
import Loading from "../../../components/loading";

const Log = Logger("PlaygroundAccelerationTemplate");

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  accelerationTemplates: Array<Object>
};

class PlaygroundAccelerationTemplate extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      accelerationTemplates: []
    };
  }

  componentDidMount() {
    this.props.api.accelerationTemplates
      .listAccelerationTemplates()
      .then((accelerationTemplates) => {
        this.setState({ accelerationTemplates, ready: true });
      })
      .catch((error) => {
        Log.error(error);
      });
  }

  render() {
    if (!this.state.ready)
      return <Loading message={"Acceleration Templates"} />;

    return (
      <React.Fragment>
        {this.state.accelerationTemplates.map((accelerationTemplate) => (
          <AccelerationTemplateCard
            uriAccount={this.props.uriAccount}
            uriPlayground={this.props.uriPlayground}
            accelerationTemplate={accelerationTemplate}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default withAppSync(PlaygroundAccelerationTemplate);
