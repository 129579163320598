/* @flow */

export const deleteDatasourceReplication = {
  query: `mutation Q ($originalDatasourceUri: String!, $replicationDatasourceUri: String!) {
        deleteDatasourceReplication(originalDatasourceUri: $originalDatasourceUri, replicationDatasourceUri: $replicationDatasourceUri) {
          source_datasource_uri
          replication_datasource_uri
          is_entire_bucket
          replication_id
          status
        }
      }
    `,
  keyOutput: "deleteDatasourceReplication"
};

export type NewReplicationRuleDatasourceInput = {
  description: string,
  details: string,
  name: string,
  owneruri: string,
  region: string,
  resource_name: string,
  s3_custom_tags: Array<Object>,
  sharing_group_uri: string
};
