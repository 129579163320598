import * as React from "react";
import { withAuth } from "@okta/okta-react";
import Logger from "../../utils/logger";
import PropTypes from "prop-types";

const Log = Logger("withMe");

type propTypes = any;

type stateTypes = {
  me: Object
};

const withMe = <propsType: Object>(
  ComposedComponent: React.AbstractComponent<propsType>
): React.AbstractComponent<$Diff<propsType, { me: Object }>> => {
  class WrappedComponent extends React.Component<propTypes, stateTypes> {
    constructor(props) {
      super(props);
      this.state = {
        me: {}
      };
    }

    componentDidMount() {
      this.props.auth
        .isAuthenticated()
        .then((isAuthenticated) => {
          if (!isAuthenticated) this.props.auth.login("/");
          return this.props.auth.getUser();
        })
        .then((me) => {
          this.setState({ me });
        })
        .catch((error) => {
          Log.error(error);
        });
    }

    render() {
      return <ComposedComponent {...this.props} me={this.state.me} />;
    }
  }
  WrappedComponent.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }),
    auth: PropTypes.shape({
      getUser: PropTypes.func.isRequired,
      getIdToken: PropTypes.func.isRequired,
      isAuthenticated: PropTypes.func.isRequired,
      login: PropTypes.func.isRequired
    })
  };
  return withAuth(WrappedComponent);
};

withMe.propTypes = {
  Component: PropTypes.element
};

export default withMe;
