export const getOktaUser = {
  query: `
  query Q ($gaia: String!) {
    getOktaUserDetails(gaia: $gaia) {
      first_name
      last_name
      email
    }
  }
`,
  keyOutput: "getOktaUserDetails"
};
