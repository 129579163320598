/* @flow */

import React from "react";
import "./groupTrustName.less";

type propTypes = {
  groupTrust: {
    arn: string,
    description: String,
    status: string,
    type: string,
    is_admin: boolean,
    id: string,
    group: {
      name: string,
      uri: string
    },
    platform: {
      name: string,
      uri: string,
      aws: string
    }
  }
};

class GroupTrustName extends React.Component<propTypes> {
  getClassStatus = () => {
    switch (this.props.groupTrust.status) {
      case "accepted":
        return "trust-status-text-accepted";
      case "pending":
        return "trust-status-text-pending";
      default:
        return "trust-status-text-rejected";
    }
  };

  render() {
    const { groupTrust } = this.props;
    return (
      <div className="group-trust-name">
        <div className="plaform-group">
          <span className="platform-group-name">
            <span className="plaform-name">
              {`[${groupTrust.platform.name}]`}
            </span>
            <span className="group-name">{groupTrust.group.name}</span>
          </span>
        </div>
        <div className="trust-arn">
          <span className="trust-arn-text">
            {groupTrust.arn} ({groupTrust.type})
          </span>
        </div>
        <div className="trust-description">
          <span className="trust-description-text">
            {groupTrust.description}
          </span>
        </div>
        <div className="trust-status status-label">
          <span className={this.getClassStatus()}>{groupTrust.status}</span>
        </div>
      </div>
    );
  }
}

export default GroupTrustName;
