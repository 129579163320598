/* @flow */
import React from "react";
import { connect } from "react-redux";
import Error from "../../components/error";
import Loading from "../../components/loading";
import Breadcrumb from "../../components/breadcrumb";
import GraphQl from "../../graphQL";
import Logger from "../../utils/logger";
import withAppSync from "../AppsyncHOC";
import SelectInfinite from "../../components/SelectInfinite";
import { updateMessage } from "../globalNotifications/actions";
import withGoTo from "../goToHOC";
import Routes from "../routes";
import "./styles.less";

const Log = Logger("AccelerationTemplateGrant");

type propTypes = {
  api: GraphQl,
  location: {
    state: {
      accelerationTemplate: Object
    }
  },
  match: {
    params: {
      id: string,
      uriPlayground: string,
      uriAccount: string
    }
  },
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  error: Object,
  errorsSubmit: Object,
  ready: boolean,
  accelerationTemplate: Object,
  totalGroups: number,
  totalNetworks: number,
  initialGroups: Array<Object>,
  initialNetworks: Array<Object>,
  isSubmitting: boolean,
  authToCreateCustomPolicy: boolean,
  selectedGroupUris: Array<Object>,
  monitoringEmail: string,
  selectedNetworkId: string
};

class AccelerationTemplateGrant extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      errorsSubmit: undefined,
      ready: false,
      accelerationTemplate: {},
      initialGroups: [],
      initialNetworks: [],
      totalNetworks: 0,
      totalGroups: 0,
      isSubmitting: false,
      authToCreateCustomPolicy: false,
      selectedGroupUris: [],
      monitoringEmail: "",
      selectedNetworkId: ""
    };
  }

  componentDidMount(): void {
    this.loadGroups().then((groups) => {
      this.setState({
        accelerationTemplate: this.props.location.state.accelerationTemplate,
        initialGroups: groups,
        ready: true
      });
    });
    this.loadNetworks().then((networks) => {
      this.setState({ initialNetworks: networks });
    });
  }

  loadGroups = (offset = 0) =>
    this.props.api.playground
      .listEnvironmentGroups(this.props.match.params.uriPlayground, { offset })
      .then((groupsResponse) => {
        this.setState({ totalGroups: groupsResponse.total });
        return groupsResponse.results.map((g) => ({
          label: g.name,
          value: g.uri
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  loadNetworks = (offset = 0) =>
    this.props.api.playground
      .listNetworks(this.props.match.params.uriPlayground, {
        offset,
        limit: 10
      })
      .then((networkResponse) => {
        this.setState({ totalNetworks: networkResponse.total });
        return networkResponse.results.map((g) => ({
          label: g.name,
          value: g.id
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  submit = () => {
    this.setState({ isSubmitting: true });

    const requests = this.state.selectedGroupUris.map((groupUri) =>
      this.props.api.accelerationTemplates
        .grantAccessToAccelerationTemplate(
          this.props.match.params.id,
          this.props.match.params.uriPlayground,
          groupUri,
          this.state.monitoringEmail,
          this.state.selectedNetworkId,
          this.state.authToCreateCustomPolicy
        )
        .then(() => true)
        .catch((error) => {
          Log.error(error);
          return error;
        })
    );

    return Promise.all(requests).then((responses) => {
      this.setState({ isSubmitting: false });

      let hasError = false;
      const errors = [];
      responses.forEach((response) => {
        if (response !== true) {
          hasError = true;
          errors.push(response);
        }
      });

      if (hasError) {
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ errorsSubmit: errors });
      } else {
        this.props.showGlobalNotification({
          message: "Added !",
          type: "success"
        });
        this.props.goTo(
          {
            route: Routes.AccelerationTemplate.View,
            params: {
              uriAccount: this.props.match.params.uriAccount,
              uriPlayground: this.props.match.params.uriPlayground,
              id: this.props.match.params.id
            },
            state: this.props.location.state
          },
          "replace"
        );
      }
    });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="AccelerationTemplate" />;
    if (!this.state.ready) return <Loading message={"Acceleration Template"} />;
    return (
      <React.Fragment>
        <div>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view={`Grant access on Acceleration Template: ${this.state.accelerationTemplate.name}`}
                helpOn={{
                  content: (
                    <div>
                      <div>
                        Grant access a group to a template will allowed the
                        group to deploy the template on its projects.
                      </div>
                      <div>_</div>
                      <div>
                        To grant access to this acceleration template, select
                        one or several groups, a network and define an email for
                        the monitoring for the stack deployment.
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>

        <div className="AT-grant-form">
          <Error
            error={this.state.errorsSubmit}
            path="AccelerationTemplate"
            stringOnly
          />

          <div className="form-group">
            <legend className="label-form">Group(s)</legend>
            <SelectInfinite
              isMulti
              placeholder={"Select group(s)"}
              initialOptions={this.state.initialGroups}
              totalOptions={this.state.totalGroups}
              loadMoreOptions={this.loadGroups}
              optionResults
              onSelectOption={(options) =>
                this.setState({
                  selectedGroupUris: (options || []).map((o) => o.value)
                })
              }
            />
          </div>

          <div className="form-group">
            <legend className="label-form">Network</legend>
            <SelectInfinite
              placeholder={"Select network"}
              initialOptions={this.state.initialNetworks}
              totalOptions={this.state.totalNetworks}
              loadMoreOptions={this.loadNetworks}
              onSelectOption={(value) =>
                this.setState({ selectedNetworkId: value })
              }
            />
          </div>

          <div className="form-group">
            <legend className="label-form">Monitoring Email</legend>
            <input
              className="form-control"
              placeholder="Monitoring email"
              onChange={(event) => {
                const monitoringEmail = event.target.value;
                this.setState({ error: undefined, monitoringEmail });
              }}
              value={this.state.monitoringEmail}
            />
          </div>

          <div className="form-group">
            <input
              id="auth_to_create_custom_policy"
              type="checkbox"
              className={"checkbox-facet"}
              checked={this.state.authToCreateCustomPolicy}
              onClick={() =>
                this.setState((prevState) => ({
                  authToCreateCustomPolicy: !prevState.authToCreateCustomPolicy
                }))
              }
            />
            <label htmlFor="auth_to_create_custom_policy" className="fas">
              <small className="attribute-label">
                Check the box to allow the CDH Team to create an IAM policy in
                your AWS account. This policy will be added as a custom policy
                for your group.{" "}
              </small>
              <a
                href={
                  "https://confluence.tools.digital.engie.com/display/CDHA/Universal+Workflows+with+CommonDataHub#UniversalWorkflowswithCommonDataHub-CDHCustomPolicytousetheuniversalpipelines"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Find the policy here
              </a>
            </label>
          </div>

          <div className="mt-3 text-center">
            <button
              disabled={
                this.state.selectedGroupUris.length === 0 ||
                !this.state.selectedNetworkId ||
                !this.state.monitoringEmail ||
                !this.state.authToCreateCustomPolicy
              }
              type="submit"
              className="butn butn-check"
              onClick={this.submit}
            >
              {this.state.isSubmitting && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              Grant access
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(AccelerationTemplateGrant)));
