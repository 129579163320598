/* @flow */

import React from "react";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import Paginator from "../../../../components/paginator";
import GroupName from "../../../users/components/groupName";
import withGoTo from "../../../goToHOC";
import GroupCard from "../../../../components/groups/group-card";
import { useDatabricksWorkspaceInviteGroups } from "./hooks/use-databricks-workspace-invite-groups";
import { CDHButton } from "../../../../components/button/cdh-button";

const DatabricksWorkspaceDetailsInviteGroupsTab = ({
  api,
  workspace,
  reloadWorkspace
}) => {
  const { error, getEnvGroups, isLoading, onRegister, platformGroups, total } =
    useDatabricksWorkspaceInviteGroups(api, workspace, reloadWorkspace);

  console.log(platformGroups);

  return error ? (
    <Error error={error} path={"GroupsList"} />
  ) : isLoading ? (
    <Loading message={"Groups List"} />
  ) : (
    <div className="organizations-list">
      <div className="header"></div>
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={0}
          totalCount={total}
          list={platformGroups}
          componentRender={(group) => (
            <GroupCard
              {...{
                group,
                key: group.name,
                rightAction: (
                  <CDHButton
                    {...{
                      faClass: "fa-plus",
                      onClick: () => onRegister(group.uri),
                      title: "Register Group"
                    }}
                  />
                )
              }}
            />
          )}
          loadPage={getEnvGroups}
          onNoResult={() => (
            <p className="total-search-results">No groups found</p>
          )}
        />
      </div>
    </div>
  );
};

export default withGoTo(DatabricksWorkspaceDetailsInviteGroupsTab);
