/* @flow */
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import Breadcrumb from "../../components/breadcrumb";
import stringcutter from "../../components/stringcutter";
import "./managePolicies.less";
import GraphQl from "../../graphQL";
import PolicyCounter from "./policyCounter";
import CartServices from "./cartServices";

const Log = Logger("ManagePolicies");

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      cartUri: string,
      uriPlayground: string
    }
  },
  location: {
    state: Object
  },
  showGlobalNotification: Function
};

type stateTypes = {
  readyPolicies: boolean,
  readyServices: boolean,
  cart: Object,
  inProgress: boolean,
  displayServices: boolean,
  cartPolicies: Array<Object>,
  cartServices: Array<Object>,
  error: boolean | Object,
  errorMaxSlot: boolean | Object,
  isSubmitting: boolean,
  hasChanged: boolean,
  maxSlots: any,
  selectedCount: number
};
class CartManagePolicies extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      readyPolicies: false,
      displayServices: false,
      inProgress: false,
      readyServices: false,
      cart: (props.location.state || {}).cart || {},
      cartPolicies: [],
      cartServices: [],
      error: false,
      errorMaxSlot: false,
      isSubmitting: false,
      hasChanged: false,
      maxSlots: undefined,
      selectedCount: 0
    };
  }

  componentDidMount() {
    this.getCartServices();
    this.getCartPolicies();
    this.setState({ errorMaxSlot: false });
    this.props.api.cart
      .getMaxSlots(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground
      )
      .then((maxSlotsResult) => {
        this.setState({ maxSlots: maxSlotsResult.max_slots });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          errorMaxSlot: error,
          maxSlots: 0
        });
      });

    if (Object.keys(this.state.cart).length === 0) {
      this.props.api.cart
        .getCart(this.props.match.params.cartUri)
        .then((cart) => {
          this.setState({ cart });
        });
    }
  }

  getCartServices = () => {
    this.props.api.cart
      .listCartServices(this.props.match.params.cartUri)
      .then((cartServices) => {
        if (cartServices) {
          this.setState({
            cartServices,
            readyServices: true
          });
        }
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  };

  getCartPolicies = () => {
    this.props.api.cart
      .getCartPolicies(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground
      )
      .then((cartPolicies) => {
        if (cartPolicies) {
          this.setState({
            cartPolicies,
            selectedCount: cartPolicies.filter((p) => p.activated).length,
            readyPolicies: true
          });
        }
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  };

  handleChange = (policyarn, activated) => {
    this.setState((state) => ({
      cartPolicies: state.cartPolicies.map((policy) => {
        if (policy.policyarn === policyarn) {
          policy.activated = activated;
        }
        return policy;
      }),
      hasChanged: true,
      selectedCount: activated
        ? state.selectedCount + 1
        : state.selectedCount - 1
    }));
  };

  applySelection = () => {
    this.setState({ isSubmitting: true });
    this.props.api.cart
      .applyPolicySelection(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground,
        this.state.cartPolicies.map((policy) => ({
          policyarn: policy.policyarn,
          activated: policy.activated
        }))
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Policies applied to project",
          type: "success"
        });
        if (this.state.cart.is_refresh_auto_enabled) {
          this.props.showGlobalNotification({
            message:
              "Policies applied to project, your role project will be automatically refreshed",
            type: "success"
          });
        }
        this.setState({ isSubmitting: false, hasChanged: false });
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to apply policies to project",
          type: "alert"
        });
        Log.error(error);
        this.setState({ error, isSubmitting: false });
      });
  };

  disableValidation = () => {
    if (!this.state.maxSlots) {
      return true;
    }
    return (
      this.state.hasChanged === false ||
      this.state.selectedCount > this.state.maxSlots
    );
  };

  exportJson = () => {
    if (this.state.inProgress) return false;
    this.setState({ inProgress: true });
    return this.props.api.cart
      .exportPolicies(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground
      )
      .then(({ url }) => {
        this.setState({ inProgress: false });
        window.open(url);
        this.props.showGlobalNotification({
          message: "Exported !",
          type: "success"
        });
      })
      .catch(() => {
        this.setState({ inProgress: false });
        this.props.showGlobalNotification({
          message: "An error occured during export",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CartManagePolicies"} />;

    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={`Manage Policies - ${this.state.cart.name}`}
        />
        <h4>
          Services{" "}
          <i
            className={classnames("fas icon-display fa-spacing", {
              "fa-plus-circle": !this.state.displayServices,
              "fa-minus-circle": this.state.displayServices
            })}
            onClick={() => {
              this.setState((prevState) => ({
                displayServices: !prevState.displayServices
              }));
            }}
          />
        </h4>
        {!this.state.readyServices && this.state.displayServices && (
          <Loading message={"Cart Services"} />
        )}
        {this.state.readyServices && this.state.displayServices && (
          <CartServices cartServices={this.state.cartServices} />
        )}

        <h4>Policies</h4>
        {this.state.errorMaxSlot && (
          <div className="mt-2 mb-2">
            <Error
              error={this.state.errorMaxSlot}
              path={"maxSlots"}
              stringOnly
            />
          </div>
        )}
        <div className="container-action">
          <PolicyCounter
            maxSlots={this.state.maxSlots}
            selectedSlots={this.state.selectedCount}
          />
          <div>
            <button
              type="button"
              className="butn mr-2 ml-2"
              onClick={() => this.exportJson()}
            >
              {this.state.inProgress && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              <i className="fas fa-download fa-spacing" />
              Export Default Policies
            </button>
            <button
              type="button"
              className="butn"
              onClick={() => this.applySelection()}
              disabled={this.disableValidation()}
            >
              {this.state.isSubmitting && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              <i className="fas fa-plus-circle fa-spacing" />
              Apply Selection
            </button>
          </div>
        </div>
        {!this.state.readyPolicies && <Loading message={"Manage Policies"} />}
        {this.state.readyPolicies && (
          <div className="list-policies-cart mt-2">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-check" scope="col" />
                  <th className="col-policy-arn" scope="col">
                    POLICY ARN
                  </th>
                  <th className="" scope="col">
                    DESCRIPTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.cartPolicies.map((policy) => (
                  <tr key={policy.policyarn} className="table-row">
                    <td>
                      <input
                        id={policy.policyarn}
                        type="checkbox"
                        className={"checkbox-facet"}
                        checked={policy.activated}
                        onChange={(event) =>
                          this.handleChange(
                            policy.policyarn,
                            event.target.checked
                          )
                        }
                      />
                      <label htmlFor={policy.policyarn} className="fas">
                        <span className="labelCustom" />
                      </label>
                    </td>
                    <td className="table-arn-column">{policy.policyarn}</td>
                    <td className="table-desc-column">
                      {stringcutter(policy.description, 100)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(CartManagePolicies));
