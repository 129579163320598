/* @flow */
import React from "react";
import Breadcrumb from "../../../components/breadcrumb";
import route from "../../routes";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import DatasourceCard from "../../users/components/DatasourceCard";
import ExportCSV from "../../../components/exportCsv";
import Paginator from "../../../components/paginator";
import "./list.less";
import SearchByFacet from "../../carts/AddDataset/searchByFacet";
import Logger from "../../../utils/logger";

const Log = Logger("ListMyDatasources");

type PropTypes = {
  goTo: Function,
  api: GraphQl
};

type StateTypes = {
  ready: boolean,
  datasources: Array<Object>,
  error: Object,
  keywords: string,
  offset: number,
  totalDatasources: number,
  options: Object,
  filterByOwner?: string,
  filterByManager?: string,
  owners: Array<{ value: string, nb: number }>,
  managers: Array<{ value: string, nb: number }>,
  hasMore: boolean
};

const GET_DATASOURCES_LIMIT = 10;
const INITIAL_OFFSET = 0;
class ListMyDatasources extends React.Component<PropTypes, StateTypes> {
  timeout: TimeoutID;

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      ready: false,
      datasources: [],
      error: undefined,
      keywords: "",
      offset: INITIAL_OFFSET,
      totalDatasources: 0,
      options: {},
      filterByOwner: undefined,
      filterByManager: undefined,
      owners: [],
      managers: [],
      hasMore: false
    };
  }

  onClickNewButton = () =>
    this.props.goTo({
      route: route.Datasource.Create,
      params: {}
    });

  componentDidMount() {
    this.searchGroups();
    this.getMyDatasources();
  }

  onChangeInputOwner = (value) => {
    this.setState(
      { filterByOwner: value, ready: false, offset: INITIAL_OFFSET },
      this.getMyDatasources
    );
  };

  onChangeInputManager = (value) => {
    this.setState(
      { filterByManager: value, ready: false, offset: INITIAL_OFFSET },
      this.getMyDatasources
    );
  };

  getMyDatasources = () => {
    const options = {
      limit: GET_DATASOURCES_LIMIT,
      offset: this.state.offset,
      search: undefined,
      filters: []
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    if (this.state.filterByOwner) {
      options.filters.push({
        key: "owner_group_name",
        value: this.state.filterByOwner
      });
    }

    if (this.state.filterByManager) {
      options.filters.push({
        key: "sharing_group",
        value: this.state.filterByManager
      });
    }

    this.setState({ options });

    return this.searchResults(options);
  };

  searchResults = (options) =>
    this.props.api.datasource
      .listAllMyDatasourcesTotal(options)
      .then((datasourceList) => {
        if (!datasourceList) return false;

        const { results, total, offset, limit } = datasourceList;
        if (results) {
          this.setState({
            datasources: results,
            offset: offset + limit,
            ready: true,
            hasMore: total - offset > 0,
            totalDatasources: total
          });
          return results;
        }
        return false;
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
        return [];
      });

  searchGroups = () =>
    this.props.api.datasource
      .listAllMyDatasourcesGroups()
      .then((searchGroups) => {
        Log.verbose("result groups search", searchGroups);
        if (searchGroups) {
          const owners = (
            searchGroups.find(
              (group) => group.category === "owner_group_name"
            ) || {}
          ).groups;
          const managers = (
            searchGroups.find((group) => group.category === "sharing_group") ||
            {}
          ).groups;
          this.setState({
            owners,
            managers
          });
        }
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  getMyDatasourcesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getMyDatasources().then(r))
    );

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords,
          offset: 0
        },
        this.getMyDatasources
      );
    }, 300);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return (
      <div className="MyData">
        <div className="bread-line">
          <div className="title-content">
            <Breadcrumb view="Datasources" hideGoBack />
          </div>
        </div>
        <div className="search">
          <SearchByFacet
            placeholder="Select Owner"
            items={this.state.owners}
            onSelectItem={this.onChangeInputOwner}
          />

          <SearchByFacet
            placeholder="Select Sharing Manager"
            items={this.state.managers}
            onSelectItem={this.onChangeInputManager}
          />

          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search datasource !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>

          <div className="create-container">
            <div className="new-dataset">
              <div className="butn butn-create" onClick={this.onClickNewButton}>
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New datasource</span>
              </div>
            </div>
          </div>
        </div>

        {this.state.error ? (
          <Error error={this.state.error} path={"MyData"} />
        ) : undefined}

        {!this.state.ready ? <Loading message={"My Data"} /> : undefined}

        {this.state.ready ? (
          <React.Fragment>
            <div className="export-data-container">
              <ExportCSV
                fetchUrlMethod={() => {
                  const searchOptions = Object.assign({}, this.state.options);
                  delete searchOptions.limit;
                  delete searchOptions.offset;
                  return this.props.api.exportCSV.getCSVExportForData(
                    searchOptions
                  );
                }}
              />
            </div>
            {this.state.hasMore ? (
              <Paginator
                limit={GET_DATASOURCES_LIMIT}
                initialOffset={INITIAL_OFFSET}
                list={this.state.datasources}
                totalCount={this.state.totalDatasources}
                componentRender={(datasource) => (
                  <DatasourceCard
                    key={datasource.uri}
                    datasource={datasource}
                  />
                )}
                loadPage={this.getMyDatasourcesFromPagination}
                onNoResult={() => (
                  <div className="total-search-results">
                    No datasource found
                  </div>
                )}
              />
            ) : undefined}
          </React.Fragment>
        ) : undefined}
      </div>
    );
  }
}

export default withAppSync(ListMyDatasources);
