export const queryGetUserProfile = {
  query: `
  query Q ($cartUri: String!, $userProfileId: String!) {
    getSagemakerStudioUserProfile(cartUri: $cartUri, userProfileId: $userProfileId) {
      id
      name
      status
      stack_id
      stack_name
      role_arn
      stack_status
      createdat
      status
      updatedat
      aws
      sm_domain_id
      jupyter_app_status
      jupyter_app_name
      jupyter_app_error
      lifecycle_conf_enabled
    }
  }
`,
  keyOutput: "getSagemakerStudioUserProfile"
};
