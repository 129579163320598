/* @flow */
import React from "react";
import { Field, Form, Formik } from "formik";
import "./view.less";
import Error from "../../../components/error";
import GraphQl from "../../../graphQL";

type propTypes = {
  datasource: {
    uri: string
  },
  api: GraphQl,
  onAdd: Function,
  onClose: Function
};

type stateTypes = {
  errorAdd: Object,
  overwrite: boolean,
  disabledField: boolean,
  isFetching: boolean,
  roleId: string,
  id: string
};

class DatasourceProvidersAdd extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      errorAdd: undefined,
      overwrite: false,
      roleId: "",
      disabledField: false,
      isFetching: false,
      id: Math.random().toString()
    };
  }

  addProvider = (
    provider: Object = {},
    setSubmitting: Function,
    resetForm: Function
  ) => {
    provider.userid = provider.userid.trim();
    return this.props.api.datasource
      .addProvider(this.props.datasource.uri, provider)
      .then((providers) => {
        this.props.onAdd(providers);
      })
      .then(() => resetForm({ arn: "", userid: "", description: "" }))
      .catch((errorAdd) => {
        this.setState({
          errorAdd
        });
      });
  };

  handleChange(value: Object) {
    return this.setState({ overwrite: !!value });
  }

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getRoleId(keywords);
    }, 500);
  };

  onChange = (e: Object) => {
    const keywords = e.target.value;
    this.setState({ roleId: keywords });
  };

  getRoleId = (keywords: string) => {
    this.setState({ roleId: "", disabledField: false, isFetching: true });
    this.props.api.datasource
      .getRoleIdFromRoleArn(keywords)
      .then((result) => {
        if (result !== "null") {
          this.setState({ roleId: result, disabledField: true });
        }
        this.setState({ isFetching: false });
        return result;
      })
      .catch((errorAdd) => {
        this.setState({
          errorAdd
        });
      });
  };

  render() {
    return (
      <div className={"datasource-providers-add datasource-providers-form"}>
        {this.state.errorAdd && (
          <Error
            error={this.state.errorAdd}
            path={"DatasourceProvidersAdd"}
            stringOnly
          />
        )}
        <Formik
          initialValues={{}}
          validate={() => {
            const errors = {};

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.overwrite = this.state.overwrite;

            if (!values.userid) {
              values.userid = this.state.roleId;
            }

            return this.addProvider(values, setSubmitting, resetForm).then(() =>
              setSubmitting(false)
            );
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <h4 className="title text-primary viewName">Add new loader</h4>
              <div className="form-group">
                <label className="label-form">ARN</label>
                <label className="tips" htmlFor="arn">
                  Principal Arn
                </label>
                <Field
                  required
                  name="arn"
                  className="form-control form-control-sm"
                  placeholder=" Arn"
                  onKeyUp={this.onChangeInput}
                />
              </div>

              <div className="form-group">
                <div className="facet-criteria-name">
                  <input
                    id={`overwrite-${this.state.id}`}
                    type="checkbox"
                    className={"checkbox-facet"}
                    onClick={(event) => this.handleChange(event.target.checked)}
                  />
                  <label htmlFor={`overwrite-${this.state.id}`} className="fas">
                    <small className="attribute-label">Allow Overwrite</small>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="label-form">RoleID</label>
                <label className="tips" htmlFor="userid">
                  AWS Role Id of this principal. You can get this role id from
                  your cli as follows: aws iam get-role --role-name &lt;Role
                  Name&gt;
                </label>
                <div className="content-field">
                  <Field
                    required
                    name="userid"
                    className="form-control form-control-sm"
                    placeholder=" RoleID"
                    onChange={this.onChange}
                    value={this.state.roleId}
                    disabled={this.state.disabledField}
                  />
                  {this.state.isFetching && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="label-form">Description</label>
                <label className="tips" htmlFor="description">
                  Short explanation of this principal
                </label>
                <Field
                  name="description"
                  component="textarea"
                  className="form-control form-control-sm"
                  placeholder=" Write a description..."
                />
              </div>

              <div className="actions">
                <button type="submit" disabled={isSubmitting} className="butn">
                  {isSubmitting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  Add Loader
                </button>
                <button
                  type="button"
                  onClick={this.props.onClose}
                  className="butn"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DatasourceProvidersAdd;
