/* @flow */
import React from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import ButtonAction from "../../../../components/buttonAction";
import TagsDatasourceFields from "../../../datasources/components/form/TagsDatasourceFields";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";

type propTypes = {
  api: GraphQl,
  onEdit: Function,
  onClose: Function,
  showGlobalNotification: Function,
  accelerationTemplate: Object
};

type stateTypes = {
  error?: Object,
  isEditing: boolean,
  tags: Array<Object>
};

class EditAccelerationTemplateModal extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isEditing: false,
      tags: this.props.accelerationTemplate.template_projects[0].custom_tags
    };
  }

  handleChange(field, value) {
    this.setState({ tags: value });
  }

  editTemplateProject = () => {
    this.setState({ error: undefined, isEditing: true });
    this.props.api.cart
      .editAccelerationTemplateFromProject(
        this.props.accelerationTemplate.template_projects[0].id,
        {
          tags: this.state.tags
        }
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Successfully Edited !",
          type: "success"
        });
        this.setState({
          isEditing: false
        });
        this.props.onEdit();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error, isEditing: false });
      });
  };

  render() {
    return (
      <Modal
        title={`Edit the template: ${this.props.accelerationTemplate.acceleration_template.name}`}
        body={
          <div>
            <div className="form-group">
              <legend className="label-form">Tags</legend>
              <TagsDatasourceFields
                tags={this.state.tags}
                onChangeTags={(field, value) => this.handleChange(field, value)}
              />
            </div>
          </div>
        }
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key="edit-template"
            label="Edit Template"
            className="butn butn-flat"
            onClick={this.editTemplateProject}
            icon={
              this.state.isEditing ? "fas fa-circle-notch fa-spin" : undefined
            }
          />,
          <button
            key="close-modal"
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(EditAccelerationTemplateModal));
