/* @flow */
import React from "react";
import Links from "../../links";

type propTypes = {
  accelerationTemplateGroup: Object,
  openEditGroupModal: Function,
  openDetachGroupModal: Function,
  uriAccount: string,
  uriPlayground: string
};

const AccelerationTemplateGroupRow = ({
  accelerationTemplateGroup,
  openEditGroupModal,
  openDetachGroupModal,
  uriAccount,
  uriPlayground
}: propTypes) => (
  <tr>
    <td className="table-link">
      <Links.Group.View uriGroup={accelerationTemplateGroup.group.uri}>
        {accelerationTemplateGroup.group.name}
      </Links.Group.View>
    </td>
    <td className="table-link">{accelerationTemplateGroup.monitoring_email}</td>
    <td className="table-link">
      <Links.Network.View
        uriAccount={uriAccount}
        uriPlayground={uriPlayground}
        networkId={accelerationTemplateGroup.network.id}
      >
        {accelerationTemplateGroup.network.name}
      </Links.Network.View>
    </td>

    <td className="table-link">
      {accelerationTemplateGroup.template_projects
        ? accelerationTemplateGroup.template_projects.map((templateProject) => (
            <div>{templateProject.cart.name}</div>
          ))
        : "-"}
    </td>

    <td className="table-link">
      <div
        className="button-action"
        onClick={() => openEditGroupModal(accelerationTemplateGroup)}
      >
        <i className="far fa-edit fa-spacing" />
        Edit
      </div>

      <div className="button-action-separator">|</div>

      <div
        className="button-action"
        onClick={() => openDetachGroupModal(accelerationTemplateGroup)}
      >
        <i className="far fa-trash-alt fa-spacing" />
        Revoke
      </div>
    </td>
  </tr>
);

export default AccelerationTemplateGroupRow;
