export const listMyGroupsWithoutShare = {
  query: `query Q ($datasetUri: String!, $options: QueryOptions){
        listMyGroupsWithoutShare(datasetUri: $datasetUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                parent
                account {
                    uri
                    name
                }
                platformsList {
                    total
                }
                cartsList {
                    total
                }
                
                
                
                platforms {
                     uri
                     name
                }
                carts {
                    name
                }
            }
        }
    }`,
  keyOutput: "listMyGroupsWithoutShare"
};
