/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../globalNotifications/actions";
import FormDataset from "../components/form";
import Routes from "../../routes";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import "./create.less";
import { slugifyNameForDatasetPrefix } from "../../../utils/slugifier";
import Breadcrumb from "../../../components/breadcrumb";
import config from "../../../config";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import GraphQl from "../../../graphQL";

const Log = Logger("DatasetCreate");

type propTypes = {
  goTo: Function,
  location: {
    pathname: string,
    state: {
      account: { uri: string },
      playground: { uri: string },
      datasource: {
        uri: string,
        owner: { name: string },
        region: string,
        is_registered_with_lakeformation: boolean
      }
    }
  },
  api: GraphQl,
  match: {
    params: {
      uriDataset: string
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  accounts: Object,
  accountPlatforms: Array<Object>,
  platformDatasources: Array<Object>,
  datasourcesList: Array<Object>,
  dataset: {
    name: string,
    type: string,
    domain: string,
    details: Object,
    basic_metadata: boolean,
    metadata_frequency: string,
    languages: Array<string>,
    data_types: Array<string>,
    tags: Array<string>,
    accountUri: string,
    platformUri: string,
    datasourceUri?: string,
    datasourceOwner: string,
    datasourceRegion: string,
    maturity_level: number,
    prefix?: string,
    is_registered_with_lakeformation: boolean,
    datasourceRegisteredWithLakeFormation: boolean,
    schema?: string
  },
  error: boolean,
  errorSubmit: boolean,
  prefilled: Object,
  totalDatasources: number,
  account: Object,
  platform: Object,
  datasource: Object,
  isLoadingDatasources: boolean,
  isLoadingAccounts: boolean,
  isLoadingPlatforms: boolean,
  isPrefilledAccount: boolean,
  isPrefilledPlatform: boolean,
  isPrefilledDatasource: boolean
};

class DatasetCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingAccounts: true,
      isLoadingPlatforms: true,
      isLoadingDatasources: false,
      isPrefilledAccount: false,
      isPrefilledPlatform: false,
      isPrefilledDatasource: false,
      accounts: [],
      accountPlatforms: [],
      platformDatasources: [],
      datasourcesList: [],
      totalDatasources: 0,
      account: {},
      platform: {},
      datasource: {},
      dataset: {
        name: "",
        type: "structured",
        domain: "",
        details: {},
        basic_metadata: true,
        shareable: true,
        metadata_frequency: "never",
        accountUri: "",
        languages: [],
        data_types: [],
        datasourceOwner: "",
        datasourceRegion: "",
        platformUri: "",
        tags: [],
        maturity_level: 0,
        is_registered_with_lakeformation: false,
        datasourceRegisteredWithLakeFormation: false,
        schema: ""
      },
      error: false,
      errorSubmit: false,
      prefilled: false
    };
  }

  componentDidMount() {
    this.props.api.account
      .listAccountsAndPlatforms()
      .then((accountsResponse) => {
        this.initFormWithAccounts(accountsResponse);
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  }

  initFormWithAccounts = (accountsResponse) => {
    const accounts = {};
    accountsResponse.forEach((account) => {
      accounts[account.uri] = {
        uri: account.uri,
        name: account.name,
        platforms: {}
      };
      account.platforms.forEach((platform) => {
        accounts[account.uri].platforms[platform.uri] = {
          uri: platform.uri,
          name: platform.name,
          datasources: {},
          groups: {}
        };
      });
    });
    this.setState({
      accounts,
      isLoadingAccounts: false,
      isLoadingPlatforms: false
    });

    if (Object.keys(accounts).length <= 0) {
      return false;
    }

    const prefilled = this.getPrefilledFields();
    let isPrefilledAccount = false;
    let isPrefilledPlatform = false;
    let isPrefilledDatasource = false;

    return this.setState((prevState) => {
      const dataset = Object.assign({}, prevState.dataset);
      if (prefilled.account.uri && accounts[prefilled.account.uri]) {
        dataset.accountUri = prefilled.account.uri;
        isPrefilledAccount = true;
      } else {
        dataset.accountUri = Object.keys(accounts)[0]; // first Account
      }
      const account = accounts[dataset.accountUri];

      const accountPlatforms = this.getPlatforms(accounts, dataset.accountUri);
      if (Object.keys(accounts[dataset.accountUri].platforms).length <= 0) {
        return {};
      }
      if (
        prefilled.platform.uri &&
        accounts[dataset.accountUri].platforms &&
        accounts[dataset.accountUri].platforms[prefilled.platform.uri]
      ) {
        dataset.platformUri = prefilled.platform.uri;
        isPrefilledPlatform = true;
      } else {
        dataset.platformUri = accountPlatforms[0].uri; // first platform
      }
      const platform =
        accounts[dataset.accountUri].platforms[dataset.platformUri];

      let datasourcePrefilled = {};
      if (
        isPrefilledAccount &&
        isPrefilledPlatform &&
        prefilled.datasource &&
        prefilled.datasource.uri
      ) {
        isPrefilledDatasource = true;
        datasourcePrefilled = prefilled.datasource;
        dataset.datasourceUri = datasourcePrefilled.uri;
      }
      return {
        account,
        platform,
        datasource: datasourcePrefilled,
        prefilled,
        accounts,
        accountPlatforms,
        dataset,
        isPrefilledAccount,
        isPrefilledPlatform,
        isPrefilledDatasource
      };
    }, this.manageDatasources);
  };

  getPrefilledFields = () => {
    const prefilled = {};
    const prefilledValues = this.props.location.state || {};
    prefilled.account =
      prefilledValues.account ||
      (prefilledValues.playground && {
        uri: getAccountUriFromUri(prefilledValues.playground.uri)
      }) ||
      {};
    prefilled.platform = prefilledValues.playground
      ? { uri: prefilledValues.playground.uri }
      : {};
    prefilled.datasource = prefilledValues.datasource || {};
    return prefilled;
  };

  getPlatforms = (accounts: Object, accountUri: string) => {
    if (
      !accountUri ||
      !accounts[accountUri] ||
      Object.keys(accounts[accountUri].platforms).length <= 0
    )
      return [];
    return Object.keys(accounts[accountUri].platforms).map(
      (uri) => this.state.accounts[accountUri].platforms[uri]
    );
  };

  getDatasources = (accounts, accountUri, platformUri) => {
    if (
      !accountUri ||
      !accounts[accountUri].platforms ||
      !accounts[accountUri].platforms[platformUri] ||
      !accounts[accountUri].platforms[platformUri].datasources ||
      Object.keys(accounts[accountUri].platforms[platformUri].datasources)
        .length <= 0
    )
      return [];
    return Object.keys(
      accounts[accountUri].platforms[platformUri].datasources
    ).map(
      (uri) => accounts[accountUri].platforms[platformUri].datasources[uri]
    );
  };

  setAccount = (accountUri) => {
    this.setState((prevState) => {
      const dataset = Object.assign({}, prevState.dataset);
      const { accounts } = prevState;

      dataset.accountUri = accountUri;
      const account = accounts[accountUri];
      const accountPlatforms = this.getPlatforms(accounts, accountUri);
      let platform = {};
      if (Object.keys(accounts[accountUri].platforms).length > 0) {
        dataset.platformUri = accountPlatforms[0].uri;
        platform = accountPlatforms[0];
      } else {
        dataset.platformUri = "";
      }

      return {
        account,
        platform,
        dataset,
        accountPlatforms
      };
    }, this.manageDatasources);
  };

  setPlatform = (platformUri) => {
    this.setState((prevState) => {
      const dataset = Object.assign({}, prevState.dataset);
      const { accountPlatforms } = prevState;
      dataset.platformUri = platformUri;
      const platform = accountPlatforms.find((p) => p.uri === platformUri);
      return {
        dataset,
        platform
      };
    }, this.manageDatasources);
  };

  setDatasource = (datasourceUri) => {
    this.setState((prevState) => {
      const dataset = Object.assign({}, prevState.dataset);
      const { platform } = prevState;
      const datasource = platform.datasources[datasourceUri];
      dataset.datasourceUri = datasource.uri;
      dataset.datasourceRegion = datasource.region;
      dataset.datasourceOwner = (datasource.owner || {}).name;
      dataset.datasourceOwnerUri = (datasource.owner || {}).uri;
      return {
        dataset,
        datasource
      };
    });
  };

  manageDatasources = () => {
    this.setState({ isLoadingDatasources: true });

    const { accounts, prefilled } = this.state;
    const dataset = Object.assign({}, this.state.dataset);
    const { platformUri } = dataset;

    if (!platformUri) {
      dataset.datasourceUri = "";
      dataset.datasourceRegion = "";
      dataset.datasourceOwner = "";
      dataset.datasourceOwnerUri = "";
      return this.setState({
        dataset,
        datasource: {},
        isLoadingDatasources: false,
        platformDatasources: []
      });
    }
    let platformDatasources = this.getDatasources(
      accounts,
      dataset.accountUri,
      dataset.platformUri
    );
    let datasource = {};

    if (prefilled.datasource && prefilled.datasource.uri) {
      const dt = prefilled.datasource;
      dataset.datasourceUri = dt.uri;
      dataset.datasourceRegion = dt.region;
      dataset.datasourceOwner = (dt.owner || {}).name;
      dataset.datasourceOwnerUri = (dt.owner || {}).uri;
      dataset.datasourceRegisteredWithLakeFormation =
        dt.is_registered_with_lakeformation;
      dataset.is_registered_with_lakeformation =
        dt.is_registered_with_lakeformation;
      return this.setState({
        dataset,
        datasource: dt,
        platformDatasources,
        isLoadingDatasources: false
      });
    }
    if (platformDatasources.length > 0) {
      datasource = platformDatasources[0];
      dataset.datasourceUri = datasource.uri;
      dataset.datasourceRegion = datasource.region;
      dataset.datasourceOwner = (datasource.owner || {}).name;
      dataset.datasourceOwnerUri = (datasource.owner || {}).uri;
      dataset.datasourceRegisteredWithLakeFormation =
        datasource.is_registered_with_lakeformation;
      dataset.is_registered_with_lakeformation =
        datasource.is_registered_with_lakeformation;

      return this.setState({
        dataset,
        datasource,
        platformDatasources,
        isLoadingDatasources: false
      });
    }
    return this.props.api.playground
      .listPlatformDatasources(platformUri)
      .then((datasourceResponse) => {
        this.setState((prevState) => {
          const accountsCopy = Object.assign({}, prevState.accounts);
          const totalDatasources = datasourceResponse.total;
          datasourceResponse.results.forEach((ds) => {
            accountsCopy[dataset.accountUri].platforms[
              dataset.platformUri
            ].datasources[ds.uri] = ds;
          });

          platformDatasources = this.getDatasources(
            accountsCopy,
            dataset.accountUri,
            dataset.platformUri
          );
          if (platformDatasources.length > 0) {
            datasource = platformDatasources[0]; // first datasource
            dataset.datasourceUri = datasource.uri;
            dataset.datasourceRegion = datasource.region;
            dataset.datasourceOwner = (datasource.owner || {}).name;
            dataset.datasourceOwnerUri = (datasource.owner || {}).uri;
            dataset.datasourceRegisteredWithLakeFormation =
              datasource.is_registered_with_lakeformation;
            dataset.is_registered_with_lakeformation =
              datasource.is_registered_with_lakeformation;
          }

          return {
            accounts: accountsCopy,
            dataset,
            datasource,
            platformDatasources,
            totalDatasources,
            isLoadingDatasources: false
          };
        });
      });
  };

  formatAccounts(accounts: Object): Array<Object> {
    // eslint-disable-line
    return Object.keys(accounts).map((uri) => ({
      uri,
      name: accounts[uri].name
    }));
  }

  handleChange = (field: string) => (event) => {
    const { value = "" } = event.target;
    Log.info(field, "<-", value);
    return this.setState(
      (prevState) => {
        const dataset = Object.assign({}, prevState.dataset);
        if (field.startsWith("details")) {
          dataset.details[field.slice(8)] = value;
        } else {
          dataset[field] = value;
        }

        return {
          dataset,
          errorSubmit: false
        };
      },
      () => {
        if (field === "accountUri") {
          this.setAccount(value);
        } else if (field === "platformUri") {
          this.setPlatform(value);
        } else if (field === "datasourceUri") {
          this.setDatasource(value);
        }
      }
    );
  };

  submit = () =>
    this.create()
      .then((d) => {
        this.props.showGlobalNotification({
          message: "Dataset created",
          type: "success"
        });
        this.props.goTo(
          {
            route: Routes.Dataset.UploadData,
            params: { uriDataset: d.uri },
            state: { dataset: this.state.dataset }
          },
          "replace"
        );
      })
      .catch((err) => {
        Log.error(err);
        this.setState({
          errorSubmit: err
        });
        this.props.showGlobalNotification({
          message: "Dataset creation failed",
          type: "alert"
        });
      });

  create = () => {
    const { accountUri, datasourceUri = "" } = this.state.dataset;

    Log.info(
      "Saving dataset to",
      accountUri,
      datasourceUri,
      "with input",
      this.state.dataset
    );
    const input = Object.assign({}, this.state.dataset);
    delete input.datasourceOwner;
    delete input.datasourceRegion;
    delete input.datasourceRegisteredWithLakeFormation;
    input.name = this.state.dataset.name.trim();
    input.owneruri = input.datasourceOwnerUri;
    delete input.datasourceOwnerUri;
    input.prefix =
      this.state.dataset.prefix ||
      slugifyNameForDatasetPrefix(this.state.dataset.name);
    input.schema =
      this.state.dataset.schema ||
      slugifyNameForDatasetPrefix(this.state.dataset.name);
    const { details } = this.state.dataset;
    if (
      details.external_description_url &&
      !details.external_description_url.startsWith("http")
    ) {
      details.external_description_url = `https://${details.external_description_url}`;
    }
    input.details = JSON.stringify(details);
    const tagsFiltered = this.state.dataset.tags.filter(
      (v, i) => this.state.dataset.tags.indexOf(v) === i
    );
    input.tags = (tagsFiltered || [])
      .filter((tag) => tag && tag.trim())
      .join(",");
    if (input.domain === "default") input.domain = "OTR";
    delete input.accountUri;
    delete input.platformUri;
    delete input.datasourceUri;
    return this.props.api.dataset.create(accountUri, datasourceUri, input);
  };

  loadMoreDatasources = (offset) =>
    this.props.api.playground
      .listPlatformDatasources(this.state.platform.uri, { offset })
      .then((responseDatasource) => {
        this.setState((prevState) => {
          const datasources = [];
          const { platform } = prevState;

          responseDatasource.results.map((v) => {
            datasources[v.uri] = v;
            return datasources;
          });

          platform.datasources = { ...platform.datasources, ...datasources };
          return {
            platform
          };
        });

        return (responseDatasource.results || []).map((d) => ({
          value: d.uri,
          label: d.name
        }));
      });

  render() {
    Log.info("state dataset form: ", this.state, this.props.location);
    if (this.state.error) {
      return <Error error={this.state.error} path={"DatasetCreate"} />;
    }
    return (
      <div className="dataset-form-creation">
        <div className="create-dataset">
          <Breadcrumb
            view="Create New Dataset"
            helpOn={{
              content: (
                <div>
                  <div>{" - Create a new dataset. "}</div>
                  <div>
                    {
                      "First, choose the location (account, playground and datasource)"
                    }
                  </div>
                  <div>
                    {
                      "Second, describe your data, this metadata will be used on open catalog !"
                    }
                  </div>
                  <hr />
                  <div>{"Requirements:"}</div>
                  <div>
                    {"- To be part of a group attached to a playground."}
                  </div>
                </div>
              ),
              helpCenterLink: `${config.HELP_CENTER}`
            }}
          />
        </div>

        {!this.state.isLoadingAccounts &&
        Object.keys(this.state.accounts).length <= 0 ? (
          <div>You have to be part of an organization to create dataset.</div>
        ) : (
          <FormDataset
            submit={this.submit}
            isNew
            prefilled={this.state.prefilled}
            dataset={this.state.dataset}
            accountSelected={this.state.account}
            environmentSelected={this.state.platform}
            handleChange={this.handleChange}
            loadMoreDatasources={this.loadMoreDatasources}
            totalDatasources={this.state.totalDatasources}
            datasourceSelected={this.state.datasource}
            accounts={this.formatAccounts(this.state.accounts)}
            isLoadingAccounts={this.state.isLoadingAccounts}
            isPrefilledAccount={this.state.isPrefilledAccount}
            accountPlatforms={this.state.accountPlatforms}
            isLoadingPlatforms={this.state.isLoadingPlatforms}
            isPrefilledPlatform={this.state.isPrefilledPlatform}
            platformDatasources={this.state.platformDatasources}
            isLoadingDatasources={this.state.isLoadingDatasources}
            isPrefilledDatasource={this.state.isPrefilledDatasource}
            errorSubmit={this.state.errorSubmit}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DatasetCreate));
