/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./view.less";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import Modal from "../../../components/modal";
import withAppSync from "../../AppsyncHOC";
import ToggleButton from "../../../components/toggleButton";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  share: {
    datasource: {
      uri: string
    },
    group: {
      uri: string
    },
    are_new_datasets_shared: boolean
  },
  api: GraphQl,
  onEdit: Function,
  hideModal: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: ?string,
  areNewDatasetsShared: boolean,
  isFetching: boolean
};

class DatasourceShareModelEdit extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: undefined,
      areNewDatasetsShared: this.props.share.are_new_datasets_shared,
      isFetching: false
    };
  }

  updateDatasourceShare = () => {
    this.setState({ error: undefined, isFetching: true });
    this.props.api.datasource
      .updateShareDatasource(
        this.props.share.datasource.uri,
        this.props.share.group.uri,
        this.state.areNewDatasetsShared
      )
      .then(() => {
        this.setState({ isFetching: true });
        this.props.showGlobalNotification({
          message: "Update succeeded",
          type: "success"
        });
      })
      .then(this.props.onEdit)
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Update failed",
          type: "alert"
        });
        this.setState({ error, isFetching: false });
      });
  };

  render() {
    return (
      <Modal
        title={"Update Datasource Share"}
        body={
          <div>
            <div
              style={{
                marginBottom: "10px"
              }}
            >
              <div className="form-group">
                <label className="label-form">Share future datasets</label>
                <div className="basic-metadata-display">
                  <ToggleButton
                    id="basic-metadata-display-id"
                    checkedValue={this.state.areNewDatasetsShared}
                    onChange={() =>
                      this.setState((prevState) => ({
                        areNewDatasetsShared: !prevState.areNewDatasetsShared
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        }
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key={`edit-datasource-share-close-${this.props.share.datasource.uri}`}
            label="Close"
            className="butn butn-flat"
            onClick={this.props.hideModal}
          />,
          <ButtonAction
            key={`edit-datasource-share-edit-${this.props.share.datasource.uri}`}
            label="Edit"
            className="butn"
            onClick={this.updateDatasourceShare}
            icon={
              this.state.isFetching ? "fas fa-circle-notch fa-spin" : undefined
            }
          />
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasourceShareModelEdit)
);
