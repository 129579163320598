/* @flow */

export const updateDatasetDetails = {
  query: `mutation Q ($accountUri: String!, $datasetUri: String!, $input: UpdateDatasetInput!) {
        updateDatasetDetails(accountUri: $accountUri, datasetUri: $datasetUri, input: $input) {
          uri
        }
      }
    `,
  keyOutput: "updateDatasetDetails"
};

export type UpdateDatasetDetailsPropsTypes = {
  uri: string
};

export type UpdateDatasetInputPropsTypes = {
  name?: string,
  description?: string,
  tags?: string,
  owneruri?: string,
  type?: string,
  domain?: string,
  confidentiality?: string,
  refresh_sla?: string,
  last_loaded?: string,
  details?: string,
  period_start_at?: string,
  period_end_at?: string,
  language?: string,
  basic_metadata?: boolean,
  metadata_frequency?: string,
  maturity_level?: number,
  prefix?: string
};
