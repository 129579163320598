/* @flow */

export const queryListShareItems = {
  query: `query Q ($datasetUri: String!, $groupUri: String!, $options: QueryOptions) {
        listShareItems(datasetUri: $datasetUri, groupUri: $groupUri, options: $options) {
            results {
              id
              share_id
              stage_id
              stage{
               table
               id 
              }
              data_filter_id
              data_filter{
               name 
               id
              }
              createdat
              updatedat
              table_name
              status
              requester
            }
            total
            offset
            limit
        }
      }
    `,
  keyOutput: "listShareItems"
};
