/* @flow */
import React from "react";
import { connect } from "react-redux";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import Breadcrumb from "../../../components/breadcrumb";
import routes from "../../routes";
import Paginator from "../../../components/paginator";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import "../styles.less";
import EditGroupModal from "./editGroupModal";
import DetachGroupModal from "./detachGroupModal";
import AccelerationTemplateGroupRow from "./accelerationTemplateGroupRow";

const Log = Logger("AccelerationTemplate");

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      id: string,
      uriPlayground: string,
      uriAccount: string
    }
  },
  goTo: Function
};

type stateTypes = {
  error: Object,
  ready: boolean,
  accelerationTemplate: Object,
  accelerationTemplateGroups: Array<Object>,
  detachGroupModal?: Object,
  editGroupModal?: Object,
  totalGroups: number,
  reset: boolean,
  isLoadingGroups: boolean
};

class AccelerationTemplate extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      ready: false,
      reset: false,
      isLoadingGroups: false,
      accelerationTemplate: {},
      accelerationTemplateGroups: [],
      detachGroupModal: undefined,
      editGroupModal: undefined,
      totalGroups: 0
    };
  }

  componentDidMount(): void {
    this.getAccelerationTemplate();
    this.loadGroups();
  }

  getAccelerationTemplate = () => {
    this.props.api.accelerationTemplates
      .getAccelerationTemplate(this.props.match.params.id)
      .then((accelerationTemplate) => {
        this.setState({
          accelerationTemplate
        });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  loadGroups = (offset = 0, reset = false) => {
    if (reset) this.setState({ reset: true, isLoadingGroups: true });
    else this.setState({ isLoadingGroups: true });

    return this.props.api.accelerationTemplates
      .listAccelerationTemplatesEnvironmentsGroups(
        this.props.match.params.uriPlayground,
        { offset }
      )
      .then((accelerationTemplatesEnvironment) => {
        this.setState({
          accelerationTemplateGroups: accelerationTemplatesEnvironment.results,
          totalGroups: accelerationTemplatesEnvironment.total,
          ready: true,
          reset: false,
          isLoadingGroups: false
        });
        return accelerationTemplatesEnvironment.results;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          accelerationTemplateGroups: [],
          ready: true,
          reset: false,
          isLoadingGroups: false
        });
        return [];
      });
  };

  openDetachGroupModal = (item) => this.setState({ detachGroupModal: item });

  closeDetachGroupModal = () => this.setState({ detachGroupModal: undefined });

  openEditGroupModal = (item) => this.setState({ editGroupModal: item });

  closeEditGroupModal = () => this.setState({ editGroupModal: undefined });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="AccelerationTemplate" />;
    if (!this.state.ready) return <Loading message={"Acceleration Template"} />;
    return (
      <div className="acceleration-template">
        <div>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view={`Acceleration Template: ${this.state.accelerationTemplate.name}`}
                helpOn={{
                  content: (
                    <div>
                      <div>
                        As environment Administrator, you can configure template
                        and manage group who can use the template on their
                        projects.
                      </div>
                      <div>_</div>
                      <div>
                        You can see below which groups have already a grant
                        access to the template
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className="create-container">
          <div className="container-actions">
            <div className="actions-right">
              <button
                className="butn grant-access-button"
                onClick={() =>
                  this.props.goTo({
                    route: routes.AccelerationTemplate.Grant,
                    params: {
                      uriAccount: this.props.match.params.uriAccount,
                      uriPlayground: this.props.match.params.uriPlayground,
                      id: this.props.match.params.id
                    },
                    state: {
                      accelerationTemplate: this.state.accelerationTemplate
                    }
                  })
                }
                type="button"
              >
                Grant Access
              </button>
            </div>
          </div>
        </div>
        <div className="network-body">
          <div className="network-body-left">
            <div className="dataset-detail-element">
              <div className="dataset-detail-title">
                <i className="fas fa-rocket dataset-detail-icon" />
                Name
              </div>
              <span>{this.state.accelerationTemplate.name}</span>
            </div>

            <div className="dataset-detail-element">
              <div className="dataset-detail-title">
                <i className="fa fa-globe-europe dataset-detail-icon" />
                Description
              </div>
              <span>{this.state.accelerationTemplate.description}</span>
            </div>

            <div className="dataset-detail-element">
              <div className="dataset-detail-title">
                <i className="fa fa-globe-europe dataset-detail-icon" />
                Version
              </div>
              <span>{this.state.accelerationTemplate.version}</span>
            </div>

            <div className="dataset-detail-element">
              <div className="dataset-detail-title">
                <i className="fa fa-globe-europe dataset-detail-icon" />
                Documentation
              </div>
              <a
                content="link"
                href={this.state.accelerationTemplate.link_to_doc}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "12px" }}
              >
                Link to the documentation
              </a>
            </div>
          </div>

          <div className="network-body-right">
            <div className="list-policies">
              {this.state.error && (
                <Error
                  error={this.state.error}
                  path="ActionInstanceSageMaker"
                />
              )}
              <div className="mt-2">
                {this.state.reset && <Loading message="" />}
                {!this.state.reset && (
                  <Paginator
                    isLoadingPage={this.state.isLoadingGroups}
                    container={(content) => (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="" scope="col">
                              Group
                            </th>
                            <th className="" scope="col">
                              Monitoring email
                            </th>
                            <th className="" scope="col">
                              Network
                            </th>
                            <th className="" scope="col">
                              Projects
                            </th>
                            <th className="" scope="col">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>{content}</tbody>
                      </table>
                    )}
                    limit={10}
                    initialOffset={0}
                    list={this.state.accelerationTemplateGroups}
                    componentRender={(accelerationTemplateGroup) => (
                      <AccelerationTemplateGroupRow
                        uriAccount={this.props.match.params.uriAccount}
                        uriPlayground={this.props.match.params.uriPlayground}
                        accelerationTemplateGroup={accelerationTemplateGroup}
                        openEditGroupModal={this.openEditGroupModal}
                        openDetachGroupModal={this.openDetachGroupModal}
                      />
                    )}
                    loadPage={this.loadGroups}
                    totalCount={this.state.totalGroups}
                    onNoResult={() => <div>No result.</div>}
                  />
                )}
              </div>
            </div>

            {this.state.detachGroupModal && (
              <DetachGroupModal
                templateEnvironementGroup={this.state.detachGroupModal}
                onDetach={() => {
                  this.loadGroups(0, true);
                  this.closeDetachGroupModal();
                }}
                onClose={this.closeDetachGroupModal}
              />
            )}

            {this.state.editGroupModal && (
              <EditGroupModal
                templateEnvironementGroup={this.state.editGroupModal}
                onEdit={() => {
                  this.loadGroups(0, true);
                  this.closeEditGroupModal();
                }}
                onClose={this.closeEditGroupModal}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(AccelerationTemplate)));
