/* @flow */

import React from "react";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import Paginator from "../../../../components/paginator";
import DatasourceCard from "../../../users/components/DatasourceCard";

type propTypes = {
  namespace: Object,
  api: GraphQl
};

type stateTypes = {
  datasources: Array<Object>,
  ready: boolean,
  error: Object,
  datasourcesTotal: Object
};

class RSNamespaceUsage extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      datasources: [],
      error: undefined,
      datasourcesTotal: null
    };
  }

  componentDidMount() {
    this.getRSNamespaceUsage();
  }

  getRSNamespaceUsage = () => {
    this.props.api.analytics
      .getRSNamespaceDatasources(this.props.namespace.uri)
      .then((response) => {
        this.setState({
          datasources: response.results,
          datasourcesTotal: response.total,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    const { ready, error, datasources, datasourcesTotal } = this.state;
    if (error) return <Error error={error} path={"RSNamespaceUsage"} />;
    if (!ready) return <Loading message={"Namespace Associated Datasources"} />;
    return (
      <div className="card-container">
        <Paginator
          isLoadingPage={!ready}
          limit={10}
          initialOffset={0}
          totalCount={datasourcesTotal}
          list={datasources}
          componentRender={(datasource) => (
            <DatasourceCard key={datasource.uri} datasource={datasource} />
          )}
          loadPage={this.getRSNamespaceUsage}
          onNoResult={() => (
            <div className="total-search-results">No datasources found</div>
          )}
        />
      </div>
    );
  }
}

export default withAppSync(RSNamespaceUsage);
