export const listGroupsForSharingGroup = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        getAccount (accountUri: $accountUri) {
            groupsList (options: $options) {
                total
                results  {
                    name
                    uri
                }
            }
        }
      }
    `,
  keyOutput: "getAccount.groupsList"
};
