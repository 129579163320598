/* @flow */
import React from "react";
import "./card.less";
import { connect } from "react-redux";
import withGoTo from "../../goToHOC";
import { formatDate } from "../../../utils/date";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import DatasetReplicationRuleAdd from "../../datasources/view/DatasetReplicationRuleAdd";
import Modal from "../../../components/modal";
import SelectAutocomplete from "../../../components/SelectAutocomplete";
import Logger from "../../../utils/logger";
import ButtonAction from "../../../components/buttonAction";

const Log = Logger("DatasourceReplicationCart");

type propTypes = {
  replication: Object,
  scopes: Array<Object>,
  datasource: Object,
  uri: string,
  api: GraphQl,
  putDatasourceReplication: Function,
  getErrorPolicies: Function,
  showGlobalNotification: Function,
  onClose: Function
};

type stateTypes = {
  modal: boolean,
  errorPolicies: boolean,
  errorDeletion: string,
  modalScopeRule: boolean,
  modalDelete: boolean,
  replication: Object,
  isFetching: boolean,
  account: Object,
  selectedDatasets: Array<{ value: string, label: string }>,
  scopeRule: any
};

class DatasourceReplicationCard extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalScopeRule: false,
      account: {},
      modalDelete: false,
      replication: {},
      isFetching: true,
      selectedDatasets: [],
      errorDeletion: "",
      scopeRule: "",
      errorPolicies: false
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = () => {
    this.setState({ isFetching: true });
    this.getSelectedDatasets();
    this.props.api.datasource
      .get(this.props.uri)
      .then((replication) => {
        this.setState({ replication }, () => {
          this.setState({ isFetching: false });
        });
        return replication;
      })
      .then((data) => {
        this.getAccount(data.accountid);
      })
      .catch((error) => {
        const status = error.message.includes(
          "This is probably due to your attached policies"
        );
        const noperm = <span className={"label-error"}>Access denied</span>;
        if (status) {
          this.setState({
            replication: {
              name: `${this.props.uri.split(":")[4]}`,
              platform: { environment: noperm },
              region: noperm,
              createdat: noperm,
              scopeRule: noperm,
              organization: noperm
            },
            account: { name: noperm }
          });
          this.props.getErrorPolicies(status);
          this.setState({ errorPolicies: status });
          this.setState({ isFetching: false });
        }
        Log.error(error);
      });
  };

  getSelectedDatasets = () => {
    const selectedDatasets = [];
    if (this.props.scopes.length > 0) {
      this.props.datasource.datasets.map((dataset) => {
        this.props.scopes.map((rules) => {
          if (rules.replicated_data === dataset.uri) {
            selectedDatasets.push({
              label: dataset.name,
              value: dataset.uri
            });
          }
          return selectedDatasets;
        });
        return selectedDatasets;
      });
      this.setState({ selectedDatasets });
    }
    const view = (
      <a
        onClick={() => this.setState({ modalScopeRule: true })}
        className={"label-view"}
      >
        View
      </a>
    );
    const scopeRule =
      selectedDatasets.length > 0 ? view : <span>Entire bucket</span>;
    this.setState({ scopeRule });
  };

  getAccount = (accountID) => {
    const accountUri = `uri:account:${accountID}`;
    this.props.api.account
      .get(accountUri)
      .then((account) => {
        this.setState({ account });
        return account;
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  bodyEditModal = () => (
    <div>
      <div className="container">
        <DatasetReplicationRuleAdd
          datasource={this.props.replication.datasource}
          replication={this.props.replication.replication}
          api={this.props.api}
          onClose={this.closeModal}
          putDatasourceReplication={this.props.putDatasourceReplication}
        />
      </div>
    </div>
  );

  getScopeRuleBody = () => {
    const datasets = [];
    this.props.datasource.datasets.map((dataset) =>
      datasets.push({
        label: dataset.name,
        value: dataset.uri
      })
    );

    return (
      <div>
        <div>
          <SelectAutocomplete
            placeholder={"Select Datasets"}
            options={datasets}
            selectedOption={this.state.selectedDatasets}
            isMulti
            disabled
            onSelectOption={""}
          />
        </div>
      </div>
    );
  };

  closeModal = () => {
    this.setState({ modal: false });
    this.props.onClose();
  };

  closeModalScopeRule = () => this.setState({ modalScopeRule: false });

  hideDeleteModal = () => {
    this.setState({ modalDelete: false });
  };

  deleteReplication = (originalDatasourceUri, replicationDatasourceUri) =>
    this.props.api.datasource
      .deleteDatasourceReplication(
        originalDatasourceUri,
        replicationDatasourceUri
      )
      .then((data) => data)
      .catch((e) => {
        Log.error(e);
      });

  fetchUrl = () => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true }, () =>
        this.props.api.datasource
          .getDatasourceAdminCredentials(this.props.datasource.uri)
          .then((credentials) => {
            if (credentials && credentials.ConsoleUrl)
              window.open(credentials.ConsoleUrl, "_blank");
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          })
      );
    }
  };

  renderBody = () => (
    <div>
      <div className="card-dataset-header">
        <div className="card-dataset-begin">
          <div className="card-dataset-begin-content">
            <i className="fas fa-book"></i>
            <span className="text-header-dataset">Replication rule</span>
          </div>
        </div>
        <div className="card-dataset-end">
          <div
            className={`cart-environment-tag ${this.state.replication.platform.environment}`}
          >
            <span>{this.state.replication.platform.environment}</span>
          </div>
        </div>
      </div>

      <div className="card-dataset-body">
        <div className="card-dataset-body-left">
          <div className={"dataset-card-name"}>
            {`cdh-${this.state.replication.name}`}
          </div>
          <div className="card-dataset-details">
            <div className="card-cart-role">
              <i className="fas fa-database"></i> Datasource destination :
              &nbsp;
              <a
                href={`/datasource/${this.state.replication.accountid}/${this.props.uri}/view`}
              >
                {this.state.replication.name}
              </a>
            </div>

            <div className="card-cart-role">
              <i className="fas fa-database"></i> Storage class : &nbsp;
              <span className="text-header-dataset">
                {this.props.replication.replication.storage_class
                  ? this.props.replication.replication.storage_class
                  : "Same as source"}
              </span>
            </div>

            <div className="card-cart-role">
              <i className="fas fa-globe"></i>
              <span className="text-header-dataset">
                Organization :&nbsp;{this.state.account.name}
              </span>
            </div>

            <div className="card-cart-role">
              <i className="fas fa-server"></i>
              <span className="text-header-dataset">
                Environment :&nbsp;{this.state.replication.platform.environment}
              </span>
            </div>

            <div className="card-cart-role">
              <i className="fa fa-inbox"></i>
              <span className="text-header-dataset">
                Scope rule : {this.state.scopeRule}
              </span>
            </div>

            <div className="card-cart-role">
              <i className="fas fa-globe-europe"></i>
              <span className="text-header-dataset">
                Region : {this.state.replication.region}
              </span>
            </div>
            <div className="card-cart-role">
              <i className="far fa-calendar-alt"></i>
              <span className="text-header-dataset">
                Created at{" "}
                {!this.state.errorPolicies
                  ? formatDate(new Date(this.state.replication.createdat))
                  : this.state.replication.createdat}
              </span>
            </div>
          </div>
        </div>

        <div className="card-dataset-body-right">
          <div className="card-dataset-body-actions">
            <div className="btn-cart">
              <i className="fas fa-key fa-spacing"></i>
              <span className="text-actions" onClick={this.fetchUrl}>
                Replication rule access
              </span>
            </div>

            <div
              className="btn-cart"
              onClick={() => this.setState({ modal: true })}
            >
              <i className="fas fa-edit fa-spacing"></i>
              <span className="text-actions">Edit</span>
            </div>

            <div
              className="btn-cart"
              onClick={() => this.setState({ modalDelete: true })}
            >
              <i className="fas fa-trash fa-spacing"></i>
              <span className="text-actions">Delete</span>
            </div>
          </div>
        </div>
      </div>

      <div className="card-dataset-footer">
        <div className="card-dataset-begin">
          <div className="card-dataset-begin-content d-flex">
            <i className="far fa-calendar-alt"></i>
            <span className="text-header-dataset">
              Last Data update{" "}
              {formatDate(new Date(this.props.replication.updatedat))}
            </span>
          </div>
        </div>
        <div className="card-dataset-end">
          <div className="text-footer-metrics">
            Status <span className="tag-metrics">Enable</span>&nbsp;
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className={"datasets-card card-shadow card bg-white"}>
        {this.state.isFetching && (
          <div
            id="cdh-s3-replication-spinner"
            className="spinner-border spinner-border-sm text-light "
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {!this.state.isFetching && this.renderBody()}
        {this.state.modal && (
          <Modal
            id={"modal-cart-integration-power-bi-attach-env"}
            title={"Edit replication rule"}
            body={this.bodyEditModal()}
          />
        )}

        {this.state.modalScopeRule && (
          <Modal
            title={"Scope rule"}
            body={this.getScopeRuleBody()}
            actions={[
              <button
                type="button"
                className="butn"
                onClick={this.closeModalScopeRule}
              >
                Close
              </button>
            ]}
          />
        )}

        {this.state.modalDelete && (
          <Modal
            id={"modal-cart-integration-power-bi-attach-env"}
            title={"Delete replication rule"}
            body={<div>Do you want to delete this replication rule ?</div>}
            errorMessage={this.state.errorDeletion}
            actions={[
              <ButtonAction
                label="Delete"
                className="butn butn-delete"
                onClick={() =>
                  this.deleteReplication(
                    this.props.datasource.uri,
                    this.props.replication.uri
                  )
                    .then(() => {
                      this.hideDeleteModal();
                      this.props.showGlobalNotification({
                        message: "Datasource replication deleted",
                        type: "success"
                      });
                      setTimeout(() => window.location.reload(), 300);
                    })
                    .catch((e) => {
                      Log.error(e);
                      this.setState({ errorDeletion: e.message });
                    })
                }
              />,
              <button
                type="button"
                className="butn"
                onClick={this.hideDeleteModal}
              >
                Close
              </button>
            ]}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceReplicationCard)));
