/* @flow */
import React from "react";
import Tabs from "../../components/tabs";
import ItemFromList from "../../components/itemFromList";
import routes from "../routes";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import { filterGroupsService } from "../../utils/groupsfilter";
import GroupName from "../users/components/groupName";
import Logger from "../../utils/logger";
import Breadcrumb from "../../components/breadcrumb";
import GraphQl from "../../graphQL";
import ButtonAction from "../../components/buttonAction";
import Paginator from "../../components/paginator";

const Log = Logger("PlaygroundGroups");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriDssInstance: string
    }
  },
  goTo: Function,
  api: GraphQl,
  location: {
    state: {
      dssInstance: {
        name: string
      }
    }
  }
};

type stateTypes = {
  activeTab: string,
  groups: Array<Object>,
  notGroups: Array<Object>,
  error: Object,
  errorAction: Object,
  notGroupsFilter?: string,
  notGroupsLimit: number,
  notGroupsTotal: number,
  groupsLimit: number,
  groupsTotal: number,
  isFetchingGroups: boolean,
  isFetchingNotGroups: boolean
};

class DssInstanceGroupsManagement extends React.Component<
  propTypes,
  stateTypes
> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "nav-groups",
      groups: [],
      notGroups: [],
      error: undefined,
      errorAction: undefined,
      notGroupsFilter: undefined,
      notGroupsLimit: 10,
      notGroupsTotal: 0,
      groupsLimit: 10,
      groupsTotal: 0,
      isFetchingGroups: false,
      isFetchingNotGroups: false
    };
  }

  componentDidMount() {
    this.getGroups();
  }

  listGroupsNotInDssInstance = (offset = 0) => {
    this.setState({ isFetchingNotGroups: true });
    const options = {
      search: {
        name: this.state.notGroupsFilter
      },
      offset
    };
    return this.props.api.dssInstance
      .listNotGroups(this.props.match.params.uriDssInstance, options)
      .then((notGroupsResponse) => {
        if (!notGroupsResponse) return false;
        this.setState({
          notGroups: filterGroupsService(notGroupsResponse.results),
          notGroupsTotal: notGroupsResponse.total,
          isFetchingNotGroups: false
        });
        return notGroupsResponse.results;
      });
  };

  listGroupsInInstance = (offset) => {
    this.setState({ isFetchingGroups: true });
    return this.props.api.dssInstance
      .listDssInstanceGroups(this.props.match.params.uriDssInstance, { offset })
      .then((groupsResponse) => {
        this.setState({
          groups: groupsResponse.results,
          groupsTotal: groupsResponse.total,
          isFetchingGroups: false
        });
        return groupsResponse.results;
      });
  };

  getGroups = () => {
    const groups = this.listGroupsInInstance();
    const notGroups = this.listGroupsNotInDssInstance();

    return Promise.all([groups, notGroups]).catch((error) => {
      this.setState({
        error
      });
    });
  };

  actionsGroupInDssInstance = (groupuri) =>
    groupuri.endsWith("_admin_group") ||
    groupuri.endsWith("administratorsgroup")
      ? []
      : [
          <span
            className="butn butn-delete"
            onClick={() => this.removeGroup(groupuri)}
          >
            {"Detach"}
          </span>
        ];

  removeGroup(groupuri) {
    const dssInstanceUri = this.props.match.params.uriDssInstance;
    this.props.api.dssInstance
      .removeGroup(dssInstanceUri, groupuri)
      .then(this.getGroups)
      .catch((error) => {
        Log.error(error);
        this.setState({
          errorAction: error
        });
      });
  }

  addGroup(groupuri) {
    const dssInstanceUri = this.props.match.params.uriDssInstance;
    return this.props.api.dssInstance
      .addGroup(dssInstanceUri, groupuri)
      .then(() => this.getGroups())
      .catch((err) => {
        Log.error(err);
        this.setState({
          errorAction: err
        });
      });
  }

  actionsGroupNotInDssInstance(groupuri) {
    // eslint-disable-line class-methods-use-this
    return [
      <ButtonAction
        label="Add"
        className="butn butn-create"
        onClick={() => this.addGroup(groupuri)}
      />
    ];
  }

  goToGroup(uriGroup) {
    return this.props.goTo({
      route: routes.Group.View,
      params: {
        uriGroup
      }
    });
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ notGroupsFilter: keywords }, () => {
        this.listGroupsNotInDssInstance().catch((errorAction) =>
          this.setState({ errorAction })
        );
      });
    }, 500);
  };

  setTab = (index) => {
    this.setState({ activeTab: index });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DssInstanceGroups"} />;

    return (
      <React.Fragment>
        <Breadcrumb
          state={{ ...this.props.location.state }}
          view={"Manage Groups attached to DSS Instance."}
        />
        {this.state.errorAction && (
          <Error
            error={this.state.errorAction}
            path={"DssInstanceGroupsAction"}
          />
        )}
        <Tabs
          setTab={this.setTab}
          defaultActiveIndex={this.state.activeTab}
          tabs={[
            {
              name: "Groups",
              index: "nav-groups",
              content: (
                <Paginator
                  isLoadingPage={this.state.isFetchingGroups}
                  limit={this.state.groupsLimit}
                  initialOffset={0}
                  totalCount={this.state.groupsTotal}
                  list={this.state.groups}
                  componentRender={(group) => (
                    <ItemFromList
                      key={group.uri}
                      name={<GroupName group={group} />}
                      description={group.description}
                      actions={this.actionsGroupInDssInstance(group.uri)}
                      onClick={() => this.goToGroup(group.uri)}
                      hideLabelName
                    />
                  )}
                  loadPage={this.listGroupsInInstance}
                  onNoResult={() => (
                    <p className="total-search-results">No group found</p>
                  )}
                  container={(content) => (
                    <ul className="list-group">{content}</ul>
                  )}
                />
              )
            },
            {
              name: "Invite Groups",
              index: "nav-invite_groups",
              content: (
                <div>
                  <div className="search">
                    <div className="search-input">
                      <input
                        className="form-control"
                        placeholder="Search Group !"
                        onChange={this.onChangeInput}
                      />
                      <i className="fas fa-search" />
                    </div>
                    <div className="create-container">
                      <div
                        className="butn butn-create"
                        onClick={() =>
                          this.props.goTo({
                            route: routes.Group.Create,
                            params: {
                              uriAccount: this.props.match.params.uriAccount
                            }
                          })
                        }
                      >
                        <i className="fas fa-plus butn-icon" />
                        <span className="butn-text">New group</span>
                      </div>
                    </div>
                  </div>

                  <Paginator
                    isLoadingPage={this.state.isFetchingNotGroups}
                    limit={this.state.notGroupsLimit}
                    initialOffset={0}
                    totalCount={this.state.notGroupsTotal}
                    list={this.state.notGroups}
                    componentRender={(notGroup) => (
                      <ItemFromList
                        key={notGroup.uri}
                        name={<GroupName group={notGroup} />}
                        description={notGroup.description}
                        actions={this.actionsGroupNotInDssInstance(
                          notGroup.uri
                        )}
                        onClick={() => this.goToGroup(notGroup.uri)}
                        hideLabelName
                      />
                    )}
                    loadPage={this.listGroupsNotInDssInstance}
                    onNoResult={() => (
                      <p className="total-search-results">No group found</p>
                    )}
                    container={(content) => (
                      <ul className="list-group">{content}</ul>
                    )}
                  />
                </div>
              )
            }
          ]}
        />
      </React.Fragment>
    );
  }
}

export default withAppSync(DssInstanceGroupsManagement);
