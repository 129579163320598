/* @flow */
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";

import routes from "../../../routes";
import { REGIONS } from "../../../../utils/constants";
import Modal from "../../../../components/modal";
import { updateMessage } from "../../../globalNotifications/actions";

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  migrations: Array<Object>,
  ready: boolean,
  error: ?Object,
  region: ?Object,
  isFetchingMigrations: boolean,
  isDryRunningMigration: boolean,
  isLaunchingMigration: boolean,
  launchModal: Object | boolean,
  launchError: ?Object
};

class LakeFormationMigrations extends React.PureComponent<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      migrations: [],
      ready: true,
      isFetchingMigrations: false,
      isDryRunningMigration: false,
      isLaunchingMigration: false,
      error: undefined,
      launchError: undefined,
      launchModal: false,
      region: REGIONS[0].key
    };
  }

  componentDidMount() {
    this.listLakeFormationMigrations();
  }

  dryRunLakeFormationMigration = (region) => {
    this.setState({ error: null, isDryRunningMigration: true });
    this.props.api.playground
      .dryRunLakeFormationMigration(this.props.uriPlayground, { region })
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Started AWS Lake Formation migration DryRun." +
            " This operation may take minutes to hours ",
          type: "success"
        });
        this.setState({
          isDryRunningMigration: false
        });
        this.listLakeFormationMigrations();
      })
      .catch((error) => {
        this.setState({
          isDryRunningMigration: false,
          error
        });
      });
  };

  launchLakeFormationMigration = (region) => {
    this.setState({
      error: null,
      launchError: null,
      isLaunchingMigration: true
    });
    this.props.api.playground
      .launchLakeFormationMigration(this.props.uriPlayground, { region })
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Started AWS Lake Formation migration Launch." +
            " This operation may take minutes to hours ",
          type: "success"
        });
        this.setState({
          isLaunchingMigration: false
        });
        this.listLakeFormationMigrations();
        this.closeLaunchModal();
      })
      .catch((error) => {
        this.setState({
          isLaunchingMigration: false,
          error,
          launchError: error
        });
      });
  };

  deleteLakeFormationMigration(id) {
    this.props.api.playground
      .deleteLakeFormationMigration(id)
      .then(() => {
        this.listLakeFormationMigrations();
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  listLakeFormationMigrations = () => {
    this.setState({ isFetchingMigrations: true });

    this.props.api.playground
      .listLakeFormationMigrations(this.props.uriPlayground)
      .then((migrations) => {
        this.setState({
          isFetchingMigrations: false,
          migrations
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingMigrations: false,
          error
        });
      });
  };

  openReport = (migrationId) => {
    this.props.goTo({
      route: routes.Playground.MigrationReport,
      params: {
        uriAccount: this.props.uriAccount,
        uriPlayground: this.props.uriPlayground,
        migrationId
      }
    });
  };

  static showReport(migration) {
    return (
      (migration.type === "DryRun" || migration.type === "Execution") &&
      (migration.status === "SUCCEEDED" || migration.status === "FAILED")
    );
  }

  handleChange(value) {
    this.setState({
      region: value
    });
  }

  openLaunchModal = () => this.setState({ launchModal: true });

  closeLaunchModal = () =>
    this.setState({ launchModal: false, launchError: null });

  static titleLaunchModal() {
    return (
      <div>
        <span>
          <i>Launch Environment Migration</i>
        </span>
      </div>
    );
  }

  bodyLaunchModal() {
    return (
      <div>
        <p>
          <span>
            Are you sure you want to upgrade{" "}
            <i>
              <b>{this.props.uriPlayground.split(":")[3]}</b>
            </i>{" "}
            in region{" "}
            <i>
              <b>{this.state.region}</b>
            </i>{" "}
            to <b>AWS Lake Formation</b> permissions model ?
          </span>
        </p>
        <p>
          <span>
            Please refer to our{" "}
            <i>
              <a
                href={"https://aws.amazon.com/lake-formation"}
                className="alert-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation center{" "}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </i>{" "}
            for more information.
          </span>
        </p>
      </div>
    );
  }

  launchModal() {
    return (
      <Modal
        title={LakeFormationMigrations.titleLaunchModal()}
        body={this.bodyLaunchModal()}
        errorMessage={this.state.launchError}
        actions={[
          <button
            type="submit"
            onClick={() => this.launchLakeFormationMigration(this.state.region)}
            disabled={false}
            className="butn butn-create"
          >
            {this.state.isLaunchingMigration && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            <i className="fas fa-angle-double-up"></i>
            <span className="butn-text">Launch</span>
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.closeLaunchModal()}
          >
            Close
          </button>
        ]}
      />
    );
  }

  render() {
    if (!this.state.ready)
      return <Loading message="Lake Formation Migrations" />;
    return (
      <div>
        <div className={"alert alert-primary"}>
          <div>
            <p>
              <b>
                You can now use&nbsp;
                <i>
                  <a
                    href={"https://aws.amazon.com/lake-formation"}
                    className="alert-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AWS Lake Formation Service{" "}
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </i>
                &nbsp; to manage permissions of the CommonDataHub structured
                data on Glue.
              </b>
            </p>
            <p>
              <b>
                Start by <i>Dry Running</i> a migration to get a complete report
                of the permissions that will be applied on Lake Formation
                service, then <i>Launch</i> a migration to upgrade your current
                permissions model.
              </b>
            </p>
            <p>
              <span>
                <b>
                  Please refer to our{" "}
                  <i>
                    <a
                      href={"https://aws.amazon.com/lake-formation"}
                      className="alert-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      documentation center{" "}
                      <i className="fas fa-external-link-alt"></i>
                    </a>
                  </i>{" "}
                  for more information.
                </b>
              </span>
            </p>
          </div>
        </div>
        <div className="mb-2">
          <label className="label-form">Region</label>
          <select
            onChange={(event) => this.handleChange(event.target.value)}
            value={this.state.region}
            className="form-control form-control-sm"
          >
            {REGIONS.map((region) => (
              <option key={region.key} value={region.key}>
                {`(${region.key})  ${region.label}`}
              </option>
            ))}
          </select>
        </div>
        <div className={"ml-1 row justify-content-center"}>
          <button
            type="submit"
            onClick={() => this.dryRunLakeFormationMigration(this.state.region)}
            disabled={false}
            className="butn"
            style={{ marginRight: "5px" }}
          >
            {this.state.isDryRunningMigration && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            <i className="far fa-file-alt"></i>
            <span className="butn-text">DryRun Migration</span>
          </button>
          <button
            type="submit"
            onClick={() => this.openLaunchModal()}
            disabled={false}
            className="butn butn-create"
          >
            {this.state.isLaunchingMigration && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            <i className="fas fa-angle-double-up"></i>
            <span className="butn-text">Launch Migration</span>
          </button>
        </div>
        <div className="list-migrations">
          <div className="mt-2">
            {this.state.error && (
              <Error
                stringOnly
                error={this.state.error}
                path={"LakeFormationRegistrations"}
              />
            )}
            <div>
              <div className="registration-actions">
                <button
                  type="submit"
                  onClick={() => this.listLakeFormationMigrations()}
                  className="butn refresh-table-btn"
                >
                  {this.state.isFetchingMigrations ? (
                    <i className="fas fa-sync-alt fa-spin fa-spacing" />
                  ) : (
                    <i
                      className="fas fa-sync-alt fa-spacing"
                      onClick={() => this.listLakeFormationMigrations()}
                    />
                  )}
                </button>
              </div>
            </div>
            <div>
              {this.state.isFetchingMigrations && (
                <Loading message={"Migrations"} />
              )}
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th className="col-env" scope="col">
                    REGION
                  </th>
                  <th className="col-env" scope="col">
                    TYPE
                  </th>
                  <th className="col-env" scope="col">
                    STATUS
                  </th>
                  <th className="col-env table-action-column" scope="col">
                    REPORT
                  </th>
                  <th className="col-env table-action-column" scope="col">
                    DELETE
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.migrations.map((migration) => (
                  <tr className="table-row">
                    <td className="table-env-column">
                      <b>{migration.region}</b>
                    </td>
                    <td className="table-env-column">{migration.type}</td>
                    <td className="table-env-column">
                      <span
                        className={classnames("status-row", {
                          "color-green-status":
                            migration.status === "SUCCEEDED",
                          "color-warning-status":
                            migration.status === "RUNNING",
                          "color-error-status": migration.status === "FAILED"
                        })}
                      >
                        {migration.status || "-"}
                      </span>
                    </td>
                    <td className="table-action-column">
                      {LakeFormationMigrations.showReport(migration) ? (
                        <i
                          onClick={() => this.openReport(migration.id)}
                          className="far fa-file-alt"
                        ></i>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td className="table-action-column">
                      <i
                        onClick={() =>
                          this.deleteLakeFormationMigration(migration.id)
                        }
                        className="far fa-trash-alt"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>{this.state.launchModal && this.launchModal()}</div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(LakeFormationMigrations)));
