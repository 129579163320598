/* @flow */

import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";
import withAppsync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import Routes from "../../../routes";
import { updateMessage } from "../../../globalNotifications/actions";
import RSLogo from "../../../logos/redshift-logo.png";
import withGoTo from "../../../goToHOC";
import ToggleButton from "../../../../components/toggleButton";

const Log = Logger("CreateDSSConnection");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  cart: Object,
  platforms: Array<Object>,
  open: boolean,
  onClose: Function,
  reloadDatasources: Function
};

type stateTypes = {
  error: boolean,
  errorSubmit: Object,
  selectedValue: Object,
  savingLink: boolean,
  networks: Array<Object>,
  namespaces: Array<Object>,
  isFetchingNamespaces: boolean
};

class LinkRSDatasource extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: false,
      errorSubmit: false,
      isFetchingNamespaces: false,
      selectedValue: {
        cartUri: props.cart.uri,
        platformUri: props.platforms[0].uri,
        networkId: "",
        namespaceUri: "",
        useNamespace: true
      },
      savingLink: false,
      networks: [],
      namespaces: []
    };
  }

  componentDidMount() {
    this.getNetworkList(this.props.platforms[0].uri);
    this.getRSNamespaces(this.props.platforms[0].uri);
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  handleUseNamespaceChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      if (value) {
        selectedValue.networkId = "";
      } else {
        selectedValue.namespaceUri = "";
      }
      return { selectedValue };
    });
  }

  save(values, setSubmitting) {
    this.setState({ errorSubmit: false, savingLink: true });
    return this.props.api.cart
      .addRSDatasourceToProject(this.state.selectedValue.cartUri, {
        rs_namespace_uri: this.state.selectedValue.namespaceUri,
        network_id: this.state.selectedValue.networkId
      })
      .then(() => {
        setSubmitting(false);
        this.setState({ savingLink: false });
        this.props.showGlobalNotification({
          message: "Redshift Serverless datasource added to project",
          type: "success"
        });
        this.props.onClose();
        this.props.reloadDatasources();
        return this.props.goTo({
          route: `${Routes.Cart.View}#rsd`,
          params: { cartUri: this.props.cart.uri }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, savingLink: false });
      });
  }

  getNetworkList = (platformUri) =>
    this.props.api.playground
      .listNetworks(platformUri, { offset: 0, limit: 1000 })
      .then((response) => {
        this.setState({
          networks: response.results.filter(
            (d) =>
              d.network_type === "REDSHIFT_SERVERLESS" &&
              d.region === this.props.cart.region
          )
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  getRSNamespaces = (platformUri) => {
    this.props.api.analytics
      .listMyEnvironmentRSNamespaces(platformUri, { offset: 0, limit: 1000 })
      .then((response) => {
        if (response) {
          const rsNamespaces = response.results.filter(
            (d) => d.region === this.props.cart.region
          );
          this.setState({
            namespaces: rsNamespaces
          });
          return response.results.map((g) => ({
            value: g.uri,
            label: g.name
          }));
        }
        return [];
      })
      .catch(() => {
        this.setState({
          isFetchingNamespaces: false
        });
      });
  };

  render() {
    const { selectedValue } = this.state;
    return (
      <div>
        <Dialog
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container>
                <Grid item md={1} sm={1} xs={1}>
                  <img
                    className="connector-logo-img"
                    src={RSLogo}
                    alt="Redshift Serverless"
                    width={35}
                    height={35}
                  />
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    Add Redshift Serverless Datasource
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <div className="IAM-error">
                <Alert severity="error">{this.state.error}</Alert>
              </div>
            )}
            <Box sx={{ p: 3 }}>
              <Formik
                initialValues={{
                  platformUri: selectedValue.platformUri,
                  networkId: selectedValue.networkId,
                  namespaceUri: selectedValue.namespaceUri,
                  useNamespace: selectedValue.useNamespace
                }}
                validate={() => {
                  const errors = {};
                  if (!selectedValue.platformUri) {
                    errors.name = "Please select an environment";
                  }
                  if (!selectedValue.networkId && !selectedValue.useNamespace) {
                    errors.networkId = "Please select a network";
                  }
                  if (
                    !selectedValue.namespaceUri &&
                    selectedValue.useNamespace
                  ) {
                    errors.networkId = "Please select a namespace";
                  }
                  Log.info("validate", selectedValue, errors);
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.save(values, setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div>
                        {Object.values(errors).map((e) => (
                          <div className="IAM-error mb-1">
                            <Alert severity="error">{e}</Alert>
                          </div>
                        ))}
                      </div>
                    )}
                    {this.state.errorSubmit && (
                      <div className="IAM-error mb-1">
                        <Alert severity="error">
                          {this.state.errorSubmit.message}
                        </Alert>
                      </div>
                    )}
                    <div className="mb-2">
                      <Alert severity={"info"}>
                        This action will automatically create a Redshift
                        Serverless datasource named{" "}
                        <b>{this.props.cart.name}</b>
                      </Alert>
                    </div>
                    <div className="form-container">
                      <div className="mb-3">
                        <label className="label-form">ENVIRONMENT</label>
                        <Autocomplete
                          options={this.props.platforms}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option.name;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={"Select an environment"}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            this.handleChange("platformUri", value.uri);
                            this.getRSNamespaces(value.uri);
                            this.getNetworkList(value.uri);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <fieldset className="form-group">
                          <label className="label-form">
                            Environment Namespace
                          </label>
                          <div>
                            <small className="attribute-label">
                              Uses an existing Redshift Serverless Namespace.
                            </small>
                          </div>
                          <div className="basic-metadata-display">
                            <ToggleButton
                              id="useNamespace"
                              disabled
                              checkedValue={
                                !!this.state.selectedValue.useNamespace
                              }
                              onChange={() =>
                                this.handleUseNamespaceChange(
                                  "useNamespace",
                                  !this.state.selectedValue.useNamespace
                                )
                              }
                            />
                          </div>
                        </fieldset>
                      </div>
                      {this.state.selectedValue.useNamespace && (
                        <div className="mb-3">
                          <label className="label-form">
                            Redshift Serverless Namespace
                          </label>
                          <Autocomplete
                            options={this.state.namespaces}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              return option.name;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                placeholder={"Select an environment namespace"}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            onChange={(event, value) => {
                              this.handleChange("namespaceUri", value.uri);
                            }}
                          />
                        </div>
                      )}
                      {!this.state.selectedValue.useNamespace && (
                        <div className="mb-3">
                          <label className="label-form">Network</label>
                          <Autocomplete
                            options={this.state.networks}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              return `${option.name} | ${
                                option.vpc_id
                              } | (${option.subnet_ids.join(", ")})`;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                placeholder={"Select a network"}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            onChange={(event, value) => {
                              this.handleChange("networkId", value.id);
                            }}
                          />
                        </div>
                      )}
                      <CardContent>
                        <button
                          type={"submit"}
                          className="butn butn-create"
                          disabled={isSubmitting}
                          style={{ margin: "5px", width: "100%" }}
                        >
                          {this.state.savingLink ? (
                            <i className="fas fa-sync-alt fa-spin" />
                          ) : (
                            <i className={"fa fa-plus-circle"} />
                          )}
                          <span>{" Add"}</span>
                        </button>
                      </CardContent>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(LinkRSDatasource)));
