/* @flow */
import React from "react";
import Row from "../../../components/row";
import Group from "./group";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import Paginator from "../../../components/paginator";

const Log = Logger("Your Groups");

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  groups: Array<Object>,
  errorGroups?: Object,
  isFetchingGroups: boolean,
  groupLimit: number,
  groupsTotal: number
};

class YourGroups extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      groupLimit: 10,
      groupsTotal: 0,
      errorGroups: undefined,
      isFetchingGroups: false
    };
  }

  componentDidMount() {
    this.getGroups();
  }

  getGroups = (offset = 0) => {
    this.setState({ isFetchingGroups: true });
    return this.props.api.identity
      .listMyGroupsAndUsers({ limit: 10, offset })
      .then((groups) => {
        this.setState({
          groupsTotal: groups.total,
          groupLimit: groups.limit,
          groups: groups.results,
          isFetchingGroups: false
        });
        return groups.results;
      })
      .catch((error) => {
        Log.warning(error);
        this.setState({
          errorGroups: error,
          isFetchingGroups: false
        });
      });
  };

  render() {
    return (
      <Row className={"mx-2 mt-4"}>
        {this.state.errorGroups && (
          <Error error={this.state.errorGroups} path={"yourgroups"} />
        )}

        <Paginator
          limit={this.state.groupLimit}
          totalCount={this.state.groupsTotal}
          initialOffset={0}
          list={this.state.groups}
          componentRender={(group) => <Group key={group.uri} group={group} />}
          loadPage={this.getGroups}
          isLoadingPage={this.state.isFetchingGroups}
          onNoResult={() => (
            <div className="total-search-results">No group found</div>
          )}
        />
      </Row>
    );
  }
}

export default withAppSync(YourGroups);
