/* @flow */
import React from "react";
import routes from "../../../routes";
import HelpOn from "../../../../components/helpOn";
import logoCollibra from "../../../collibra/collibra-logo.png";

type propTypes = {
  account: Object,
  datasource: Object,
  goTo: Function,
  openDatasourceModal: Function,
  userCanDeleteInSandbox: boolean,
  showCollibra: boolean,
  refreshDatasource: Function,
  isRefreshing: boolean,
  isSynchronizing: boolean,
  syncDatabase: Function
};
const RSDatasourceActions = ({
  goTo = () => {},
  account = {},
  datasource = {},
  openDatasourceModal = () => {},
  userCanDeleteInSandbox = false,
  showCollibra = false,
  refreshDatasource = () => {},
  isRefreshing = false,
  isSynchronizing = false,
  syncDatabase = () => {}
}: propTypes) => (
  <div className="container-actions">
    <div className="actions-datasource-left">
      <button
        className="butn butn-flat"
        type={"button"}
        onClick={() =>
          goTo({
            route: routes.Datasource.CreateDataset,
            params: {
              uriDatasource: datasource.uri,
              uriAccount: account.uri
            },
            state: {
              datasource,
              playground: {
                uri: datasource.parent
              }
            }
          })
        }
      >
        <i className={"fas fa-lg fa-plus pr-2"} />
        <span>Create Dataset</span>
      </button>
      <button
        className="butn butn-flat mr-0"
        type={"button"}
        onClick={() =>
          goTo({
            route: routes.Datasource.DetectRSDataset,
            params: {
              uriDatasource: datasource.uri,
              uriAccount: account.uri
            }
          })
        }
      >
        <i className={"fas fa-lg fa-search pr-2"} />
        <span>Detect Datasets</span>
      </button>
      <button
        className="butn butn-flat mr-0"
        type={"button"}
        onClick={() =>
          goTo({
            route: routes.Datasource.ShareDatasets,
            params: {
              uriDatasource: datasource.uri,
              uriAccount: account.uri
            }
          })
        }
      >
        <i className={"fas fa-lg fa-lock-open pr-2"} />
        <span>Share Datasets</span>
      </button>
      <button
        className="butn butn-flat mr-0"
        type={"button"}
        onClick={() => syncDatabase()}
      >
        {isSynchronizing && (
          <i className="fas fa-circle-notch fa-spin fa-spacing" />
        )}
        <i className={"fas fa-lg fa-sync-alt pr-2"} />
        <span>Sync Database</span>
      </button>
      {showCollibra && (
        <button
          className="butn butn-flat mr-0"
          type={"button"}
          onClick={() =>
            goTo({
              route: routes.Datasource.Collibra,
              params: {
                uriDatasource: datasource.uri,
                uriAccount: account.uri
              }
            })
          }
        >
          <img
            className="connector-logo-img mr-1"
            src={logoCollibra}
            alt="Collibra"
            width={18}
            height={18}
          />
          <span>Publish on Collibra</span>
        </button>
      )}
      <HelpOn
        content={
          <div>
            <h3 style={{ marginTop: "10px" }}>{"Share Datasets"}</h3>
            <div>
              {
                "Use it if you want to share several datasets at a time with one group"
              }
            </div>
            <h3 style={{ marginTop: "10px" }}>{"Publish on Collibra"}</h3>
            <div>
              {"Use it if you want to publish multiple datasource datasets"}
            </div>
          </div>
        }
      />
    </div>
    <div className="actions-right">
      <button
        className="butn butn-flat"
        onClick={() => refreshDatasource()}
        type={"button"}
      >
        {isRefreshing && (
          <i className="fas fa-circle-notch fa-spin fa-spacing" />
        )}
        <span className="butn-text">Refresh</span>
      </button>
      <button
        className="butn"
        onClick={() =>
          goTo({
            route: routes.Datasource.Edit,
            params: {
              uriDatasource: datasource.uri,
              uriAccount: account.uri
            },
            state: { datasource }
          })
        }
        type={"button"}
        style={{ marginRight: "5px", width: "85px" }}
      >
        <span>Edit</span>
      </button>
      {userCanDeleteInSandbox && (
        <button
          className="butn butn-delete"
          type={"button"}
          style={{ margin: "5px", width: "85px" }}
          onClick={openDatasourceModal}
        >
          <span>Delete</span>
        </button>
      )}
    </div>
  </div>
);

export default RSDatasourceActions;
