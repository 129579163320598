export const attachRedshiftFromCartToPowerBI = {
  query: `mutation Q ($cartUri: String!, $clusterUri: String!, $password: String!) {
        attachRedshiftFromCartToPowerBI(cartUri:$cartUri, clusterUri:$clusterUri, password:$password)
      }
    `,
  keyOutput: "attachRedshiftFromCartToPowerBI"
};

export const detachRedshiftFromCartToPowerBI = {
  query: `mutation Q ($cartUri: String!, $clusterUri: String!, $redshiftDsn: String!) {
        detachRedshiftFromCartToPowerBi(cartUri:$cartUri, clusterUri:$clusterUri, redshiftDsn:$redshiftDsn)
      }
    `,
  keyOutput: "detachRedshiftFromCartToPowerBi"
};

export const getRedshiftSendCommandStatus = {
  query: `query Q ($cartUri: String!, $clusterUri: String!) {
        getRedshiftSendCommandStatus(cartUri:$cartUri, clusterUri:$clusterUri)
      }
    `,
  keyOutput: "getRedshiftSendCommandStatus"
};
