export const DOMAINS = [
  { key: "GT", label: "Gas Transmission" },
  { key: "GD", label: "Gas Distribution" },
  { key: "ST", label: "Storage" },
  { key: "TER", label: "Terminalling" },
  { key: "SER", label: "Services" },
  { key: "PG", label: "Power Generation" },
  { key: "COR", label: "Corporate" },
  { key: "AOT", label: "Asset Optimization & Trading" },
  { key: "NRJ", label: "Energy Sales" },

  { key: "ENV", label: "Environment [DEPRECATED]" },
  { key: "IND", label: "Industrial [DEPRECATED]" },
  { key: "FI", label: "Finance [DEPRECATED]" },
  { key: "SAL", label: "Sales [DEPRECATED]" },
  { key: "MKG", label: "Marketing [DEPRECATED]" },
  { key: "HR", label: "Human Resources [DEPRECATED]" },
  { key: "OTR", label: "Other [DEPRECATED]" },
  { key: "Misc", label: "Miscellaneous [DEPRECATED]" }
];

export const findDomainName = (key) => {
  const domainsFound = DOMAINS.filter((d) => key === d.key);
  if (domainsFound.length === 1) {
    return domainsFound[0].label;
  }
  return "Other";
};

export const dataTypes = [
  "Asset - Failure",
  "Asset - Localisation",
  "Asset - Meter",
  "Asset - Operation",
  "Asset - Production",
  "Asset - Network",
  "Asset - Other",
  "Market - Price and Volume",
  "Smart cities oriented - BIM",
  "Smart cities oriented - Transportation",
  "Smart cities oriented - Geographic data",
  "Smart cities oriented - Other",
  "Customer - Customer relationship",
  "Customer - Contracts",
  "Customer - Other",
  "General - Finance",
  "General - Human resources",
  "General - Marketing",
  "General - Weather",
  "General - Procurement",
  "General - Information and Technology",
  "General - Other",
  "Energy Consumption / Production - Renewable energy",
  "Energy Consumption / Production - Fossil energy",
  "Energy Consumption / Production - Nuclear energy",
  "Energy Consumption / Production - Billing",
  "Energy Consumption / Production - Demand",
  "Energy Consumption / Production - Production",
  "Energy Consumption / Production - Meter",
  "Energy Consumption / Production - Order",
  "Energy Consumption / Production - Pricing",
  "Energy Consumption / Production - Other"
];
