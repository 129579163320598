/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../../../links";
import withAppSync from "../../../AppsyncHOC";
import { updateMessage } from "../../../globalNotifications/actions";
import DatasourceModal from "../../../datasources/view/DatasourceModal";
import Stringslider from "../../../../components/stringslider";
import {
  getAccountIdFromUri,
  getAccountUriFromAccountId
} from "../../../../utils/toolsForUri";
import "./style.less";
import RSLogo from "../../../logos/redshift-logo.png";
import S3Logo from "../../../logos/s3-logo.png";
import { isRSSourceType, isS3SourceType } from "../../../../utils/constants";

type propTypes = {
  datasource: {
    accountid: string,
    bucket: string,
    createdat: string,
    region: string,
    description: string,
    uri: string,
    parent: string,
    name: string,
    user_roles: Array<string>,
    datasets: Array<{
      uri: string,
      name: string
    }>,
    datasetsList: {
      total: number
    },
    owner: {
      uri: string,
      name: string
    },
    openDatasourceModal: Function,
    datasourceModal: false,
    source_type: string,
    database: string
  },
  showGlobalNotification: Function,
  goBack: Function
};

type stateTypes = {
  datasourceModal: boolean,
  nbDatasets: Object
};

class DatasourceCard extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      datasourceModal: false,
      nbDatasets: this.props.datasource.datasetsList
        ? this.props.datasource.datasetsList.total
        : (this.props.datasource.datasets || []).length
    };
  }

  openDatasourceModal = () => this.setState({ datasourceModal: true });

  closeDatasourceModal = () => this.setState({ datasourceModal: false });

  render() {
    return (
      <div className={"datasource-card card-shadow card bg-white"}>
        <div className="card-datasource-header">
          <div className="card-datasource-begin">
            {isS3SourceType(this.props.datasource.source_type) && (
              <div className="card-datasource-begin-content">
                <img
                  className="connector-logo-img"
                  src={S3Logo}
                  alt="Redshift Serverless"
                  width={18}
                  height={18}
                />
                <span className="text-header-datasource">S3 Bucket</span>
              </div>
            )}
            {isRSSourceType(this.props.datasource.source_type) && (
              <div className="card-datasource-begin-content">
                <img
                  className="connector-logo-img"
                  src={RSLogo}
                  alt="Redshift Serverless"
                  width={18}
                  height={18}
                />
                <span className="text-header-datasource">
                  Redshift Serverless
                </span>
              </div>
            )}
          </div>
          <div className="card-datasource-end">
            <i className="fas fa-user-friends" />
            <Links.Group.View
              uriGroup={this.props.datasource.owner.uri}
              state={{ group: this.props.datasource.owner }}
            >
              <span className="text-header-datasource-lowercase">
                {this.props.datasource.owner.name.substring(0, 50)}
              </span>
            </Links.Group.View>
          </div>
        </div>
        <div className="card-datasource-body">
          <div className="card-datasource-body-left">
            <div className={"text-capitalize datasource-card-name"}>
              <Links.Datasource.View
                uriAccount={this.props.datasource.accountid}
                uriDatasource={this.props.datasource.uri}
              >
                <Stringslider
                  s={this.props.datasource.name}
                  height={30}
                  width={275}
                />
              </Links.Datasource.View>
            </div>
            <div className="card-datasource-details">
              <p className="card-cart-description">
                {this.props.datasource.description &&
                this.props.datasource.description.length > 80
                  ? `${this.props.datasource.description.substring(0, 80)}...`
                  : this.props.datasource.description}
              </p>
              {isRSSourceType(this.props.datasource.source_type) && (
                <div className="card-cart-role">
                  <i className="fas fa-database" />
                  <span className="content-card-bucket">
                    {this.props.datasource.database}
                  </span>
                </div>
              )}
              {isS3SourceType(this.props.datasource.source_type) && (
                <div className="card-cart-role">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    s3://{this.props.datasource.bucket}
                  </span>
                </div>
              )}
              <div className="card-cart-role">
                <i className="fas fa-globe-europe"></i>
                <span className="content-card-bucket">
                  {this.props.datasource.region}
                </span>
              </div>
            </div>
          </div>
          <div className="card-datasource-body-right">
            <div className="card-datasource-body-actions">
              <div>
                <Links.Datasource.View
                  uriAccount={this.props.datasource.accountid}
                  uriDatasource={this.props.datasource.uri}
                >
                  <i className="fas fa-play" />
                  <span className="text-actions">View Details</span>
                </Links.Datasource.View>
              </div>
              {this.props.datasource.user_roles &&
                this.props.datasource.user_roles.includes("owner") && (
                  <div>
                    <Links.Datasource.CreateDataset
                      uriAccount={getAccountIdFromUri(
                        this.props.datasource.uri
                      )}
                      uriDatasource={this.props.datasource.uri}
                      state={{
                        account: {
                          uri: getAccountUriFromAccountId(
                            this.props.datasource.accountid
                          )
                        },
                        playground: { uri: this.props.datasource.parent },
                        datasource: this.props.datasource
                      }}
                    >
                      <i className="fas fa-plus-circle" />
                      <span className="text-actions">Create Dataset</span>
                    </Links.Datasource.CreateDataset>
                  </div>
                )}

              {this.props.datasource.user_roles &&
                this.props.datasource.user_roles.includes("owner") && (
                  <div>
                    <span
                      className="cursorPointer"
                      onClick={() => this.openDatasourceModal()}
                    >
                      <i className="fas fa-trash" />
                      <span className="text-actions">Delete</span>
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="card-datasource-footer">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <div className="flag-user-roles">
                {this.props.datasource.user_roles &&
                  this.props.datasource.user_roles.includes("owner") && (
                    <span className="user-role owner">OWNER</span>
                  )}
                {this.props.datasource.user_roles &&
                  this.props.datasource.user_roles.includes(
                    "share_manager"
                  ) && (
                    <span className="user-role share-manager">
                      SHARING MANAGER
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="card-datasource-end">
            <div className="text-footer-metrics">
              Contains{" "}
              <span className="tag-metrics">{this.state.nbDatasets}</span>{" "}
              datasets
            </div>
          </div>
        </div>

        <div>
          {this.state.datasourceModal && (
            <DatasourceModal
              datasource={this.props.datasource}
              hide={this.closeDatasourceModal}
              showGlobalNotification={this.props.showGlobalNotification}
              goBack={this.props.goBack}
              reload
            />
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(DatasourceCard));
