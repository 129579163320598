export const getMyEnvironmentRSNamespaces = {
  query: `query Q ($platformUri: String!, $options: QueryOptions) {
    listPlatformRSNamespaces(platformUri: $platformUri, options: $options) {
          offset
          limit
          total
          results {
            uri
            name
            aws
            region
            description
            network_id
            kms_key_alias
            kms_key_id
            default_iam_role_name
            default_iam_role_arn
            iam_roles
            namespace_name
            namespace_arn
            namespace_id
            namespace_status
            namespace_error
            workgroup_name
            workgroup_arn
            workgroup_id
            workgroup_status
            workgroup_error
            workgroup_base_capacity
            workgroup_port
            endpoint
            jdbc_url
            odbc_url
            stack_name
            stack_id
            stack_status
            stack_error
          }
        
        }
    }
    `,
  keyOutput: "listPlatformRSNamespaces"
};
