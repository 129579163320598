/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import { updateMessage } from "../../../globalNotifications/actions";
import SelectInfinite from "../../../../components/SelectInfinite";
import SelectClassic from "../../../../components/SelectClassic";
import "./style.less";
import Col from "../../../../components/col";

const Log = Logger("AttachTrust");

type propTypes = {
  api: GraphQl,
  accountUri: string,
  showGlobalNotification: Function,
  callbackSuccess: Function
};

type stateTypes = {
  isFetchingGroups: boolean,
  groups: Array<Object>,
  selectedGroup: Object,
  totalGroups: number,
  isFetchingTrust: boolean,
  trusts: Array<Object>,
  error?: Object,
  errorSubmit?: Object,
  selectedTrust: Object,
  offsetTrusts: number,
  checkAllGroups: boolean
};

class AttachTrust extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      errorSubmit: undefined,
      isFetchingGroups: true,
      groups: [],
      totalGroups: 0,
      isFetchingTrust: true,
      offsetTrusts: 0,
      trusts: [],
      selectedGroup: undefined,
      selectedTrust: undefined,
      checkAllGroups: false
    };
  }

  componentDidMount() {
    this.getTrusts();
    this.getGroups();
  }

  getGroups = (offset = 0) =>
    this.props.api.account
      .listGroups(this.props.accountUri, { offset })
      .then((response) => {
        if (!response) return [];
        const groups = response && response.results ? response.results : [];
        this.setState((prevState) => ({
          isFetchingGroups: false,
          groups: prevState.groups.concat(groups),
          totalGroups: response.total
        }));

        return groups.map((g) => ({ value: g.uri, label: g.name }));
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error,
          groups: [],
          isFetchingGroups: false
        });
      });

  getTrusts = () => {
    this.setState({ isFetchingTrust: true });
    this.props.api.account
      .listTrusts(this.props.accountUri, { offset: this.state.offsetTrusts })
      .then((trusts) => {
        const trustes = trusts && trusts.result ? trusts.result.trusts : [];
        this.setState((prevState) => ({
          isFetchingTrust: false,
          trusts: prevState.trusts.concat(trustes)
        }));
        if (this.state.trusts.length < trusts.total) {
          this.setState((prevState) => ({
            offsetTrusts: prevState.offsetTrusts + 10
          }));
          this.getTrusts();
        }
      })
      .catch((error) => {
        this.setState({
          isFetchingTrust: false,
          error
        });
      });
  };

  addTrust = () => {
    this.setState({
      errorSubmit: undefined
    });
    if (this.state.checkAllGroups) {
      return this.props.api.group
        .attachTrustToAllGroups(this.state.selectedTrust.value)
        .then(() => {
          this.props.showGlobalNotification({
            message: "Trust attached to group",
            type: "success"
          });
          this.props.callbackSuccess();
        })
        .catch((errorSubmit) => {
          Log.error(errorSubmit);
          this.props.showGlobalNotification({
            message: "Failed to attach trust to group",
            type: "alert"
          });
          this.setState({
            errorSubmit
          });
        });
    }
    return this.props.api.group
      .attachTrustToGroup(
        this.state.selectedTrust.value,
        this.state.selectedGroup
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust attached to group",
          type: "success"
        });
        this.props.callbackSuccess();
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.props.showGlobalNotification({
          message: "Failed to attach trust to group",
          type: "alert"
        });
        this.setState({
          errorSubmit
        });
      });
  };

  render() {
    return (
      <div className="add-trust">
        {this.state.error && (
          <Error error={this.state.error} path="TrustManagement" stringOnly />
        )}
        <Formik
          initialValues={{}}
          validate={() => {
            const errors = {};

            if (!this.state.selectedGroup && !this.state.checkAllGroups) {
              errors.selectedGroup = "Group is mandatory";
            }
            if (!this.state.selectedTrust) {
              errors.selectedTrust = "Trust is mandatory";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            Log.info("submit !", values);
            return this.addTrust().then(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {this.state.errorSubmit && (
                <Error
                  error={this.state.errorSubmit}
                  path={"addTrustToGroup"}
                  rawErrorMessage
                  stringOnly
                />
              )}
              {!this.state.error && (
                <div className="attach-trust-form">
                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">SELECT A TRUST</label>
                      <SelectClassic
                        isLoadingOptions={this.state.isFetchingTrust}
                        placeholder="Select a trust"
                        options={this.state.trusts.map((trust) => ({
                          label: `[${trust.platform.name}] - ${trust.arn} (${trust.type})`,
                          value: trust.id
                        }))}
                        onSelectOption={(option) =>
                          this.setState({ selectedTrust: option })
                        }
                      />
                      {errors.selectedTrust && (
                        <Col size={12} style={{ color: "red" }}>
                          {errors.selectedTrust}
                        </Col>
                      )}
                    </div>
                  </div>
                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">SELECT A GROUP</label>
                      <SelectInfinite
                        isDisabled={this.state.checkAllGroups}
                        isLoadingOptions={this.state.isFetchingGroups}
                        initialOptions={this.state.groups.map((g) => ({
                          value: g.uri,
                          label: g.name
                        }))}
                        placeholder={"Select a group"}
                        totalOptions={this.state.totalGroups}
                        loadMoreOptions={this.getGroups}
                        onSelectOption={(option) =>
                          this.setState({ selectedGroup: option })
                        }
                      />
                      {errors.selectedGroup && (
                        <Col size={12} style={{ color: "red" }}>
                          {errors.selectedGroup}
                        </Col>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="width100">
                      <input
                        id="attach_to_all_groups"
                        type="checkbox"
                        className={"checkbox-facet"}
                        checked={this.state.checkAllGroups}
                        onClick={() =>
                          this.setState((prevState) => ({
                            checkAllGroups: !prevState.checkAllGroups,
                            selectedGroup: undefined
                          }))
                        }
                      />
                      <label
                        className="fas checkbox-groups"
                        htmlFor="attach_to_all_groups"
                      >
                        <span className="label-check-attach">
                          Check to attach to all groups
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="butn butn-check"
                    >
                      {isSubmitting && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      Attach Trust
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(AttachTrust)));
