/* @flow */

import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import withAppsync from "../AppsyncHOC";
import Logger from "../../utils/logger";
import GraphQl from "../../graphQL";
import Routes from "../routes";
import Error from "../../components/error";
import { updateMessage } from "../globalNotifications/actions";
import collibraLogo from "./collibra-logo.png";
import withGoTo from "../goToHOC";
import ToggleButton from "../../components/toggleButton";

const Log = Logger("CreateCollibraLink");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  account: Object,
  open: boolean,
  onClose: Function,
  reloadLinks: Function
};

type stateTypes = {
  error: boolean,
  errorSubmit: Object,
  selectedValue: {
    accountUri: string,
    domain_id: Object,
    domain_name: Object,
    index_public_datasets: boolean,
    index_internal_datasets: boolean,
    envname: Object,
    auto_publish: boolean
  },
  savingLink: boolean,
  envnames: Array<Object>
};

class CollibraLinkCreate extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: false,
      errorSubmit: false,
      selectedValue: {
        accountUri: props.account.uri,
        domain_id: null,
        domain_name: null,
        index_public_datasets: true,
        index_internal_datasets: true,
        envname: null,
        auto_publish: true
      },
      envnames: [],

      savingLink: false
    };
  }

  componentDidMount() {
    this.getCollibraEnvs();
  }

  getCollibraEnvs = () => {
    this.props.api.collibra
      .getCollibraEnvironments()
      .then((envs) => {
        this.setState({ envnames: envs });
      })
      .catch((error) => this.setState({ error }));
  };

  getCollibraDomainName = (domainId, collibraEnv) => {
    this.props.api.collibra
      .getCollibraDomainName(domainId, collibraEnv)
      .then((collibraDomainName) => {
        this.setState((prevState) => ({
          selectedValue: {
            ...prevState.selectedValue,
            domain_name: collibraDomainName
          }
        }));
      })
      .catch((error) => {
        this.setState((prevState) => ({
          selectedValue: {
            ...prevState.selectedValue,
            domain_name: ""
          },
          error
        }));
      });
  };

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      if (
        selectedValue.domain_id !== null &&
        selectedValue.domain_id !== "" &&
        selectedValue.envname !== null &&
        selectedValue.envname !== ""
      ) {
        this.setState({ error: false });
        this.getCollibraDomainName(
          selectedValue.domain_id,
          selectedValue.envname
        );
      } else {
        selectedValue.domain_name = "";
        prevState.error = false;
      }
      return { selectedValue };
    });
  }

  handleAutoPublishChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      if (value) {
        selectedValue.index_public_datasets = true;
        selectedValue.index_internal_datasets = true;
      } else {
        selectedValue.index_public_datasets = false;
        selectedValue.index_internal_datasets = false;
      }
      return { selectedValue };
    });
  }

  handlePublishChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      if (
        (!prevState.selectedValue.index_public_datasets ||
          !prevState.selectedValue.index_internal_datasets) &&
        !value
      ) {
        selectedValue.auto_publish = false;
      }
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  save(values, setSubmitting) {
    this.setState({ errorSubmit: false, savingLink: true });
    return this.props.api.collibra
      .create(this.props.account.uri, {
        domain_id: this.state.selectedValue.domain_id,
        domain_name: this.state.selectedValue.domain_name,
        index_public_datasets: this.state.selectedValue.index_public_datasets,
        index_internal_datasets:
          this.state.selectedValue.index_internal_datasets,
        envname: this.state.selectedValue.envname,
        auto_publish: this.state.selectedValue.auto_publish
      })
      .then(() => {
        setSubmitting(false);
        this.setState({ savingLink: false });
        this.props.showGlobalNotification({
          message: "Collibra Link creation started.",
          type: "success"
        });
        this.props.onClose();
        this.props.reloadLinks();
        return this.props.goTo({
          route: `${Routes.Account.View}#collibra`,
          params: { accountUri: this.props.account.uri }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, savingLink: false });
      });
  }

  render() {
    const { selectedValue } = this.state;
    return (
      <div>
        <Dialog
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container spacing={2}>
                <Grid item md={1} sm={1} xs={1}>
                  <img
                    className="connector-logo-img"
                    src={collibraLogo}
                    alt="Collibra"
                    width={35}
                    height={35}
                  />
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    New Collibra Link
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <Error error={this.state.error} path="CreteConnection" />
            )}
            <Box sx={{ p: 3 }}>
              <Formik
                initialValues={{
                  accountUri: selectedValue.accountUri,
                  domain_id: selectedValue.domain_id,
                  domain_name: selectedValue.domain_name,
                  index_public_datasets: selectedValue.index_public_datasets,
                  index_internal_datasets:
                    selectedValue.index_internal_datasets,
                  envname: selectedValue.envname
                }}
                validate={() => {
                  const errors = {};
                  if (!selectedValue.envname) {
                    errors.envname = "Please select a Collibra Environment";
                  }
                  if (!selectedValue.domain_id) {
                    errors.domain_id = "Please fill your Collibra Domain ID";
                  }
                  if (!selectedValue.domain_name) {
                    errors.domain_id = "Please fill your Collibra Domain Name";
                  }
                  Log.info("validate", selectedValue, errors);
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.save(values, setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div>
                        {Object.values(errors).map((e) => (
                          <div className="error-msg">
                            <Error stringOnly error={e} path={"CollibraLink"} />
                          </div>
                        ))}
                      </div>
                    )}
                    {this.state.errorSubmit && (
                      <Error
                        stringOnly
                        error={this.state.errorSubmit}
                        path={"CollibraLink"}
                      />
                    )}
                    <div className="form-container">
                      <div className="mb-3">
                        <label className="label-form">
                          Collibra Environment
                        </label>
                        <div>
                          <small className="attribute-label">
                            URL to Collibra instance
                          </small>
                        </div>
                        <Autocomplete
                          options={this.state.envnames}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option.url;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={"Select a Collibra Environment"}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            this.handleChange("envname", value.env);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="label-form">Collibra Domain ID</label>
                        <div>
                          <small className="attribute-label">
                            Copy the domain identifier from your domain's page
                            link on Collibra{" "}
                          </small>
                        </div>
                        <TextField
                          placeholder={"Type your Collibra Domain ID"}
                          fullWidth
                          label=""
                          name="domain_id"
                          onChange={(event) =>
                            this.handleChange("domain_id", event.target.value)
                          }
                          value={this.state.selectedValue.domain_id}
                          variant="outlined"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="label-form disabled">
                          Collibra Domain Name
                        </label>
                        <div>
                          <small className="attribute-label disabled">
                            Automatic search in collibra once COLLIBRA
                            ENVIRONMENT and DOMAIN ID have been entered
                          </small>
                        </div>
                        <TextField
                          placeholder={"Type your Collibra Domain Name"}
                          fullWidth
                          label=""
                          name="domain_name disabled"
                          disabled
                          onChange={(event) =>
                            this.handleChange("domain_name", event.target.value)
                          }
                          value={this.state.selectedValue.domain_name}
                          variant="outlined"
                        />
                      </div>
                      <div className="mb-3">
                        <fieldset className="form-group">
                          <label className="label-form">
                            Auto Publish on Collibra
                          </label>
                          <div>
                            <small className="attribute-label">
                              Automatically publish newly created or edited
                              datasets to Collibra
                            </small>
                          </div>
                          <div className="basic-metadata-display">
                            <ToggleButton
                              id="auto_publish"
                              checkedValue={
                                !!this.state.selectedValue.auto_publish
                              }
                              onChange={() =>
                                this.handleAutoPublishChange(
                                  "auto_publish",
                                  !this.state.selectedValue.auto_publish
                                )
                              }
                            />
                          </div>
                        </fieldset>
                      </div>
                      {this.state.selectedValue.auto_publish && (
                        <div className="ml-5">
                          <div className="mb-3">
                            <fieldset className="form-group">
                              <label className="label-form">
                                Publish public datasets
                              </label>
                              <div>
                                <small className="attribute-label">
                                  Auto publish datasets with usage type "Public"
                                </small>
                              </div>
                              <div className="basic-metadata-display">
                                <ToggleButton
                                  id="public-datasets"
                                  checkedValue={
                                    !!this.state.selectedValue
                                      .index_public_datasets
                                  }
                                  onChange={() =>
                                    this.handlePublishChange(
                                      "index_public_datasets",
                                      !this.state.selectedValue
                                        .index_public_datasets
                                    )
                                  }
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div className="mb-3">
                            <fieldset className="form-group">
                              <label className="label-form">
                                Publish internal datasets
                              </label>
                              <div>
                                <small className="attribute-label">
                                  Auto publish datasets with usage type
                                  "Internal Use"
                                </small>
                              </div>
                              <div className="basic-metadata-display">
                                <ToggleButton
                                  id="internal-datasets"
                                  checkedValue={
                                    !!this.state.selectedValue
                                      .index_internal_datasets
                                  }
                                  onChange={() =>
                                    this.handlePublishChange(
                                      "index_internal_datasets",
                                      !this.state.selectedValue
                                        .index_internal_datasets
                                    )
                                  }
                                />
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      )}
                      <CardContent>
                        <button
                          type={"submit"}
                          className="butn butn-create"
                          disabled={isSubmitting}
                          style={{ margin: "5px", width: "100%" }}
                        >
                          {this.state.savingLink ? (
                            <i className="fas fa-sync-alt fa-spin" />
                          ) : (
                            <i className={"fa fa-paper-plane"} />
                          )}
                          <span>{" Create"}</span>
                        </button>
                      </CardContent>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(CollibraLinkCreate)));
