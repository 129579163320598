import { sendQuery } from "./querySendQuery";
import { getQueryStatus } from "./queryQueryStatus";
import { getQueryResults } from "./queryQueryResults";

class AthenaApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  sendQuery(accountUri, platformUri, sqlQuery) {
    return this.fetchAppSync(sendQuery, { accountUri, platformUri, sqlQuery });
  }

  getQueryStatus(accountUri, platformUri, queryId) {
    return this.fetchAppSync(getQueryStatus, {
      accountUri,
      platformUri,
      queryId
    });
  }

  getQueryResult(accountUri, platformUri, queryId) {
    return this.fetchAppSync(getQueryResults, {
      accountUri,
      platformUri,
      queryId
    });
  }
}

export default AthenaApi;
