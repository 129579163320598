/* @flow */

import React from "react";

type propTypesErrorDetails = {
  error: string
};
const ErrorDetails = ({ error }: propTypesErrorDetails) => (
  <div className={"error-details text-danger font-weight-bold"}>{error}</div>
);

export default ErrorDetails;
