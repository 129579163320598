import React, { useState, useRef } from "react";
import withAppSync from "../../../../AppsyncHOC";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import logoDss from "../../../../logos/dataiku-logo.png";

const AttachDBKSWorpsaceModal = ({
  api,
  cart,
  match,
  open,
  onClose,
  workspaces
}) => {
  const [catalogues, setCatalogue] = useState([]);
  const [errorSubmit, setErrorSubmit] = useState();
  const [savingConnection, setSavingConnection] = useState(false);
  const [loadCatalogs, setLoadCatalogs] = useState(false);
  const [error, setError] = useState();
  const workspaceUUID = useRef();
  const catalogName = useRef();

  const fetchCatalogs = async (dbksUUID) => {
    setLoadCatalogs(true);
    const catalogs = await api.databricks.listDatabricksCatalogs(dbksUUID);
    // catalogs.results.push({ dbx_catalog_name: `cdh-${cart.name}-000000` });
    // catalogs.results.push({ dbx_catalog_name: cart.group.name });

    return catalogs.results;
  };
  const save = async () => {
    setSavingConnection(true);
    await api.databricks
      .registerDatabricksWorkspaceCatalog(
        cart.uri,
        catalogName.current,
        workspaceUUID.current
      )
      .then(() => {
        setSavingConnection(false);
        location.reload();
      });
  };

  const handleChange = async (newWorkspaceValue) => {
    const dbksUUID = newWorkspaceValue.dbxWorkspaces[0].dbxAccount.uuid;
    workspaceUUID.current = newWorkspaceValue.dbxWorkspaces[0].uuid;
    const catalogs = await fetchCatalogs(dbksUUID);
    setCatalogue(catalogs);
    setLoadCatalogs(false);
  };

  const handleChangeCatalog = async (newCatalogValue) => {
    catalogName.current = newCatalogValue;
  };

  return (
    <div>
      <Dialog
        fullWidth
        onClose={onClose}
        open={open}
        style={{
          overflowY: "visible"
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box>
            <Grid container spacing={2}>
              <Grid item md={11} sm={11} xs={11}>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className={"mt-1"}
                >
                  Attach to a Databricks workspace
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ overflowY: "visible" }}>
          {error && <Error error={error} path="CreateConnection" />}
          <Box sx={{ p: 3 }}>
            <form onSubmit={() => save()}>
              <div className="form-container">
                <div className="mb-3">
                  <Autocomplete
                    options={workspaces}
                    getOptionLabel={(workspace) => {
                      if (workspace && workspace.dbxWorkspaces.length > 0) {
                        return workspace.dbxWorkspaces[0].dbx_workspace_name;
                      }
                      return "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Workspace"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      handleChange(value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  {loadCatalogs ? (
                    <i className="fas fa-sync-alt fa-spin" />
                  ) : (
                    <Autocomplete
                      disablePortal
                      freeSolo
                      options={catalogues}
                      getOptionLabel={(catalog) => {
                        return catalog.dbx_catalog_name;
                      }}
                      onInputChange={(event, value) => {
                        handleChangeCatalog(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Catalogs"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </div>
                <CardContent>
                  <button
                    type={"submit"}
                    className="butn butn-create"
                    style={{ margin: "5px", width: "100%" }}
                  >
                    {savingConnection ? (
                      <i className="fas fa-sync-alt fa-spin" />
                    ) : (
                      <i className={"fa fa-paper-plane"} />
                    )}
                    <span>{" Attach"}</span>
                  </button>
                </CardContent>
              </div>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default withAppSync(AttachDBKSWorpsaceModal);
