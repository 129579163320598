export const listPlatformGroups = {
  query: `query Q ($platformUri: String!, $options: QueryOptions) {
        listPlatformGroups (platformUri: $platformUri, options: $options) {
            total
            limit
            offset
            results {
                name
                uri
            }
        }
      }
    `,
  keyOutput: "listPlatformGroups"
};
