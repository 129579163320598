import { DateTime, Settings } from "luxon";

export const formatDate = (dateToFormat, IsDateTimeFull = true) => {
  Settings.defaultLocale = navigator.language;
  return IsDateTimeFull
    ? DateTime.fromISO(dateToFormat).toLocaleString(DateTime.DATETIME_FULL)
    : DateTime.fromISO(dateToFormat).toLocaleString(DateTime.DATE_FULL);
};

export const diffDate = (firstDate, secondDate) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);
  const diffInHour = (date2 - date1) / 1000 / 3600;

  return diffInHour;
};
