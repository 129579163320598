/* @flow */
import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../../../AppsyncHOC";
import Logger from "../../../../../utils/logger";
import Modal from "../../../../../components/modal";
import { updateMessage } from "../../../../globalNotifications/actions";
import GraphQl from "../../../../../graphQL";
import Notification from "../../../../notifications/components/notification";
import "./style.less";
import Error from "../../../../../components/error";

const Log = Logger("NotificationsForm");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  isFetching: boolean,
  error: boolean,
  errorList: boolean,
  notificationModal: boolean,
  isFetchingNotificationsTypes: boolean,
  notificationsTypes: Array<Object>,
  notification: Object,
  type: Object
};

class NotificationsForm extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isFetching: false,
      error: false,
      errorList: false,
      notificationModal: false,
      isFetchingNotificationsTypes: false,
      notificationsTypes: [],
      type: {},
      notification: {}
    };
  }

  componentDidMount() {
    this.getNotificationsTypes();
  }

  getNotificationsTypes = () =>
    !this.state.isFetchingNotificationsTypes &&
    this.setState(
      { isFetchingNotificationsTypes: true, errorList: undefined },
      () =>
        this.props.api.newtification
          .listNotificationTypes()
          .then((notificationsTypes) => {
            this.setState({
              notificationsTypes,
              isFetchingNotificationsTypes: false
            });
          })
          .catch((errorList) => {
            Log.error(errorList);
            this.setState({ errorList, isFetchingNotificationsTypes: false });
          })
    );

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const { notification, notificationsTypes } = prevState;
      notification[field] = value;

      const type =
        field !== "type"
          ? prevState.type
          : notificationsTypes.find(
              (notificationType) => notificationType.id === value
            );
      if (field === "type" && type.name === "Release" && !notification.message)
        notification.message = "-";
      return {
        notification,
        type
      };
    });
  };

  sendNotifications = () =>
    !this.state.isFetching &&
    this.setState({ isFetching: true, error: undefined }, () =>
      this.props.api.notification
        .create(this.state.notification.type, this.state.notification.message)
        .then(() => {
          this.setState({ notificationModal: false, isFetching: false });
          this.props.showGlobalNotification({
            message: "Notifications send",
            type: "success"
          });
        })
        .catch((error) => {
          Log.error(error);
          this.props.showGlobalNotification({
            message: "Failed",
            type: "alert"
          });
          this.setState({ error, isFetching: false });
        })
    );

  render() {
    return (
      <div className="admin-cdh-custom-notif">
        <div className="admin-cdh-custom-notif-form">
          <h2>Custom Notifications</h2>
          <hr />

          <div className="mt-4">
            <label className="label-form">Type: </label>
            <div className="type-field">
              <select
                className="form-control form-control-sm"
                name="type"
                onChange={(event) =>
                  this.handleChange("type", event.target.value)
                }
                value={this.state.notification.type || "default"}
              >
                {[
                  <option key={"default"} value={"default"} disabled>
                    {"Select a type"}
                  </option>
                ].concat(
                  this.state.notificationsTypes.map((notificationsType) => (
                    <option
                      key={notificationsType.id}
                      value={notificationsType.id}
                    >
                      {notificationsType.name}
                    </option>
                  ))
                )}
              </select>
            </div>
            {this.state.type.id && (
              <div className="type-info">
                <div>
                  <b>Domain: </b>
                  {this.state.type.domain}
                </div>
                <div>
                  <b>Code: </b>
                  {this.state.type.code}
                </div>
                <div>
                  <b>Description: </b>
                  {this.state.type.description}
                </div>
              </div>
            )}
            {this.state.errorList && (
              <Error
                error={this.state.errorList}
                path={"NotificationsForm.list-Types"}
                stringOnly
              />
            )}
          </div>
          <div className="mt-4">
            <label className="label-form">Message: </label>
            <textarea
              disabled={this.state.type.name === "Release"}
              value={this.state.notification.message}
              onChange={(event) =>
                this.handleChange("message", event.target.value)
              }
              rows="4"
              className="form-control"
            />
          </div>

          <hr className="mt-4" />
        </div>

        <div className="notification-preview mt-4">
          <Notification
            notification={{
              id: "preview",
              type: this.state.type,
              message: this.state.notification.message,
              is_read: false,
              createdat: new Date(),
              updatedat: null,
              target_uri: "",
              data: {}
            }}
            onAction={() => {}}
          />
        </div>

        <div className="admin-cdh-custom-notif-form">
          <div className="justify-content-center mt-4 row">
            <button
              type="button"
              className="butn"
              onClick={() =>
                this.setState({ notificationModal: true, error: undefined })
              }
            >
              Send
            </button>
          </div>
        </div>

        {this.state.notificationModal && (
          <Modal
            title={"Send the notifications"}
            body={
              "Are you sure ? (all users on cdh will receive the notification !)"
            }
            errorMessage={this.state.error}
            actions={[
              <button
                key={"notifications-send"}
                type="button"
                className="butn butn-delete"
                onClick={this.sendNotifications}
              >
                {this.state.isFetching && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Send
              </button>,
              <button
                key={"notifications-close"}
                type="button"
                className="butn"
                onClick={() => this.setState({ notificationModal: false })}
              >
                Close
              </button>
            ]}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(NotificationsForm));
