/* @flow */

import React from "react";
import { connect } from "react-redux";
import Tabs from "../../../components/tabs";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import withAppSync from "../../AppsyncHOC";
import TrustTabCreate from "./trustTabCreate";
import TrustTabAttach from "./trustTabAttach";

type propTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  isAdmin: boolean,
  isFetchingTrust: boolean
};

class TrustManagement extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: true,
      isFetchingTrust: false
    };
  }

  componentDidMount() {
    this.getTrusts();
  }

  getTrusts = () => {
    this.setState({ isFetchingTrust: true }, () => {
      this.props.api.account
        .listTrusts(this.props.match.params.uriAccount)
        .then((trusts) => {
          this.setState({
            isFetchingTrust: false,
            isAdmin: trusts.result.is_admin
          });
        })
        .catch(() => {
          this.setState({
            isFetchingTrust: false
          });
        });
    });
  };

  render() {
    if (!this.state.isFetchingTrust) {
      if (this.state.isAdmin) {
        return (
          <div className="trust-management">
            <Tabs
              isPreload={false}
              tabs={[
                {
                  name: <div>TRUSTS MANAGEMENT</div>,
                  index: "create_trust",
                  content: (
                    <TrustTabCreate
                      uriAccount={this.props.match.params.uriAccount}
                      api={this.props.api}
                      showGlobalNotification={this.props.showGlobalNotification}
                    />
                  )
                },
                {
                  name: <div>ATTACH TRUST</div>,
                  index: "attach_trust",
                  content: (
                    <TrustTabAttach
                      api={this.props.api}
                      accountUri={this.props.match.params.uriAccount}
                      showGlobalNotification={this.props.showGlobalNotification}
                    />
                  )
                }
              ]}
            />
          </div>
        );
      }

      return (
        <div className="">
          You need to be an administrator of this organization to manage trust
        </div>
      );
    }
    return false;
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(TrustManagement)));
