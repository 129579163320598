export const countDatasetCreatedPerDay = {
  query: `query Q ($accountUri: String!) {
        countDatasetCreatedPerDay (accountUri: $accountUri) {
            createdat
            count
        }
      }
    `,
  keyOutput: "countDatasetCreatedPerDay"
};
