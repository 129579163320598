/* @flow */

export const generateDatasourceCredentials = {
  query: `mutation Q ($accountUri: String!, $datasourceUri: String!, $input: ETLCredsInput!, $overwrite: Boolean!) {
        generateDatasourceCredentials(accountUri: $accountUri, datasourceUri: $datasourceUri, input:$input, overwrite: $overwrite) {
          user_name
          access_key_id
          secret_access_key
        }
      }
    `,
  keyOutput: "generateDatasourceCredentials"
};

export type ETLCredsInput = {
  etluser_credentials_description: string
};
