/* eslint-disable camelcase */
import { getPlayground } from "./queryGetPlayground";
import { getPlaygroundName } from "./queryGetPlaygroundName";
import { getNetwork } from "./queryGetNetwork";
import { listPlatformGroups } from "./queryListPlatformGroups";
import { listPlatformNotGroups } from "./queryListPlatformNotGroups";
import { addGroup } from "./mutationAddGroupToPlatform";
import { removeGroup } from "./mutationRemoveGroupFromPlatform";
import { createPlayground } from "./mutationCreatePlayground";
import { updatePlayground } from "./mutationUpdatePlayground";
import { deletePlayground } from "./mutationDeletePlayground";
import { refreshPlaygroundRole } from "./queryRefreshPlaygroundRole";
import { listSagemekerNotebookInstanceEnvironment } from "./queryListSagemakerNotebookInstancesPlayground";
import { listNetworkEnvironment } from "./queryListNetworkPlayground";
import { createNetworkEnvironment } from "./mutationCreateNetwork";
import { updateNetworkEnvironment } from "./mutationUpdateNetwork";
import { deleteNetwork } from "./mutationDeleteNetwork";
import { listNetworkSagemakerNotebookInstances } from "./queryListNetworkSagemakerNotebookInstances";
import { getLakeFormationMigrationReportRows } from "./queryGetLakeFormationMigrationReportRows";
import { getLakeFormationMigration } from "./queryGetLakeFormationMigration";
import { listLakeFormationMigrations } from "./queryListLakeFormationMigrations";
import { launchLakeFormationMigration } from "./mutationLaunchLakeFormationMigration";
import { dryRunLakeFormationMigration } from "./mutationDryRunLakeFormationMigration";
import { deleteLakeFormationMigration } from "./mutationDeleteLakeFormationMigration";
import { rollbackLakeFormationMigration } from "./mutationRollbackLakeFormationMigration";
import { listLakeFormationRegistrations } from "./queryListLakeFormationRegistrations";
import { listPlatformDatasources } from "./queryListPlatformDatasources";
import { listEnvironmentGroups } from "./queryListEnvironmentGroups";
import { listNetworkAccelerationTemplateAccess } from "./queryListNetworkAccelerationTemplateAccess";
import { listPlatformArchivedDatasources } from "./queryListPlatformArchivedDatasources";
import { detachEquansPlatform } from "./mutationDetachEquansPlatform";
import { listPlatformArchivedProjects } from "./queryListPlatformArchivedProjects";

class PlaygroundApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  create(accountUri, newPlatformInput) {
    return this.fetchAppSync(createPlayground, {
      accountUri,
      newPlatformInput
    });
  }

  createNetworkEnvironment(input, platformUri) {
    return this.fetchAppSync(createNetworkEnvironment, { input, platformUri });
  }

  listPlatformDatasources(platformUri, options = {}) {
    return this.fetchAppSync(listPlatformDatasources, { platformUri, options });
  }

  listPlatformArchivedDatasources(platformUri, options = {}) {
    return this.fetchAppSync(listPlatformArchivedDatasources, {
      platformUri,
      options
    });
  }

  listPlatformArchivedProjects(platformUri, options = {}) {
    return this.fetchAppSync(listPlatformArchivedProjects, {
      platformUri,
      options
    });
  }

  updateNetworkEnvironment(input, id) {
    return this.fetchAppSync(updateNetworkEnvironment, { input, id });
  }

  update(platformUri, platformInput) {
    return this.fetchAppSync(updatePlayground, { platformUri, platformInput });
  }

  get(accountUri, platformUri) {
    return this.fetchAppSync(getPlayground, { accountUri, platformUri });
  }

  getName(accountUri, platformUri) {
    return this.fetchAppSync(getPlaygroundName, { accountUri, platformUri });
  }

  listGroups(accountUri, platformUri, options = {}) {
    return this.fetchAppSync(listPlatformGroups, {
      accountUri,
      platformUri,
      options
    });
  }

  listNotGroups(accountUri, platformUri, options = {}) {
    return this.fetchAppSync(
      listPlatformNotGroups,
      { accountUri, platformUri, options },
      true
    );
  }

  listNetworks(platformUri, options = { offset: 0, limit: 100 }) {
    return this.fetchAppSync(listNetworkEnvironment, {
      platformUri,
      options
    });
  }

  getNetwork(id) {
    return this.fetchAppSync(getNetwork, { id });
  }

  listNetworkAccelerationTemplateAccess(id, options = {}) {
    return this.fetchAppSync(listNetworkAccelerationTemplateAccess, {
      id,
      options
    });
  }

  listNetworkSagemakerNotebookInstances(
    id,
    options = { offset: 0, limit: 100 }
  ) {
    return this.fetchAppSync(listNetworkSagemakerNotebookInstances, {
      id,
      options
    });
  }

  addGroup(accountUri, platformUri, groupUri) {
    return this.fetchAppSync(addGroup, { accountUri, platformUri, groupUri });
  }

  removeGroup(accountUri, platformUri, groupUri) {
    return this.fetchAppSync(removeGroup, {
      accountUri,
      platformUri,
      groupUri
    });
  }

  delete(accountUri, platformUri) {
    return this.fetchAppSync(deletePlayground, { accountUri, platformUri });
  }

  deleteNetwork(networkId) {
    return this.fetchAppSync(deleteNetwork, { networkId });
  }

  refreshPlaygroundRole(cartUri, platformUri) {
    return this.fetchAppSync(refreshPlaygroundRole, { cartUri, platformUri });
  }

  listSagemakerNotebookInstances(platformUri, offset = 0) {
    return this.fetchAppSync(listSagemekerNotebookInstanceEnvironment, {
      platformUri,
      options: { offset }
    });
  }

  getLakeFormationMigrationReportRows(id, options = { offset: 0, limit: 20 }) {
    return this.fetchAppSync(getLakeFormationMigrationReportRows, {
      id,
      options
    });
  }

  getLakeFormationMigration(id, offset = 0) {
    return this.fetchAppSync(getLakeFormationMigration, {
      id,
      options: { offset }
    });
  }

  listLakeFormationMigrations(platformUri) {
    return this.fetchAppSync(listLakeFormationMigrations, { platformUri });
  }

  listLakeFormationRegistrations(platformUri) {
    return this.fetchAppSync(listLakeFormationRegistrations, { platformUri });
  }

  launchLakeFormationMigration(platformUri, input) {
    return this.fetchAppSync(launchLakeFormationMigration, {
      platformUri,
      input
    });
  }

  dryRunLakeFormationMigration(platformUri, input) {
    return this.fetchAppSync(dryRunLakeFormationMigration, {
      platformUri,
      input
    });
  }

  rollbackLakeFormationMigration(platformUri, region) {
    return this.fetchAppSync(rollbackLakeFormationMigration, {
      platformUri,
      region
    });
  }

  deleteLakeFormationMigration(id) {
    return this.fetchAppSync(deleteLakeFormationMigration, { id });
  }

  listEnvironmentGroups(platformUri, options = {}) {
    return this.fetchAppSync(listEnvironmentGroups, { platformUri, options });
  }

  detachEquansPlatform(platformUri) {
    return this.fetchAppSync(detachEquansPlatform, { platformUri });
  }
}

export default PlaygroundApi;
