/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import Links from "../links";
import Loading from "../../components/loading";
import route from "../routes";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import GraphQl from "../../graphQL";
import withGoTo from "../goToHOC";
import Paginator from "../../components/paginator";
import "./list.less";
import logoDss from "../logos/dataiku-logo.png";

type propTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  goTo: Function,
  api: GraphQl,
  location: {
    state: {
      account: {
        name: string
      }
    }
  },
  auth: {
    getUser: Function
  }
};

type stateTypes = {
  ready: boolean,
  dssInstances: Array<Object>,
  error: boolean,
  keywords: string,
  total: number
};

class DssInstanceList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      dssInstances: [],
      error: false,
      total: 0,
      keywords: ""
    };
  }

  async componentDidMount() {
    this.searchDssInstances();
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords
        },
        this.searchDssInstances
      );
    }, 500);
  };

  searchDssInstances = (offset = 0) => {
    const options = {
      limit: 10,
      offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.account
      .listDssInstances(this.props.match.params.uriAccount, options)
      .then((items) => {
        if (!items) return false;
        this.setState({
          ready: true,
          dssInstances: items.results,
          total: items.total
        });
        return items;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getInstancesFromPagination = (offset) => this.searchDssInstances(offset);

  renderContent() {
    if (this.state.error)
      return <Error error={this.state.error} path="Dataiku DSS instances" />;
    if (!this.state.ready) return <Loading message="Dataiku DSS instances" />;

    return (
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={0}
          totalCount={this.state.total}
          list={this.state.dssInstances}
          componentRender={(dssInstance) => (
            <div
              key={dssInstance.uri}
              className={"cart-card card-shadow card bg-white"}
            >
              <div className="card-datasource-header">
                <div className="cdh-card-begin">
                  <div className="cdh-card-begin-content">
                    <img
                      className="connector-logo-img"
                      src={logoDss}
                      alt="Dataiku DSS"
                      width={15}
                      height={15}
                    />{" "}
                    Dataiku DSS Instance
                  </div>
                </div>
              </div>
              <div className="card-datasource-body">
                <div className="card-environment-body-left">
                  <div className={"text-capitalize datasource-card-name"}>
                    <Links.DssInstance.View
                      uriAccount={this.props.match.params.uriAccount}
                      uriDssInstance={dssInstance.uri}
                    >
                      {dssInstance.name}
                    </Links.DssInstance.View>
                  </div>
                  <div className="card-datasource-details">
                    <p className="card-cart-description">
                      {dssInstance.description &&
                      dssInstance.description.length > 250
                        ? `${dssInstance.description.substring(0, 250)}...`
                        : dssInstance.description}
                    </p>
                  </div>
                </div>
                <div className="card-environment-body-right">
                  <div className="card-datasource-body-actions">
                    <div className="btn-cart">
                      <Links.DssInstance.View
                        uriAccount={this.props.match.params.uriAccount}
                        uriDssInstance={dssInstance.uri}
                      >
                        <i className="fas fa-play fa-spacing" />
                        <span className="text-actions">View Details</span>
                      </Links.DssInstance.View>
                    </div>
                    <div className="btn-cart">
                      <Links.DssInstance.Groups
                        uriAccount={this.props.match.params.uriAccount}
                        uriDssInstance={dssInstance.uri}
                        state={{ dssInstance }}
                      >
                        <i className="fas fa-users-cog fa-spacing" />
                        <span className="text-actions">Manage Groups</span>
                      </Links.DssInstance.Groups>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          loadPage={this.getInstancesFromPagination}
          onNoResult={() => (
            <p className="total-search-results">No instances found</p>
          )}
        />
      </div>
    );
  }

  render = () => (
    <div className="organizations-list">
      <div className="header">
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search DSS instance !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={() =>
                  this.props.goTo({
                    route: route.DssInstance.Create,
                    params: {
                      uriAccount: this.props.match.params.uriAccount
                    }
                  })
                }
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New DSS instance</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.renderContent()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DssInstanceList)));
