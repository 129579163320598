/* @flow */
import React from "react";
import "./accelerationTemplateCard.less";
import { formatDate } from "../../../../utils/date";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";

const Log = Logger("AccelerationTemplateProjectPart");

type propTypes = {
  status?: string,
  templateProject: Object,
  isFetchingStatus: boolean,
  cart: Object,
  environmentUri: string,
  api: GraphQl
};

type stateTypes = {
  isFetchAccess: boolean
};

class AccelerationTemplateProjectPart extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isFetchAccess: false
    };
  }

  consoleAccess = () => {
    if (!this.state.isFetchAccess) {
      this.setState({ isFetchAccess: true });
      this.props.api.cart
        .getAWSConsoleAccess(this.props.cart.uri, this.props.environmentUri)
        .then((url) => {
          const u = url.split("&");
          const newUrl = u
            .map((param) => {
              if (param.startsWith("Destination=")) {
                const destination = encodeURIComponent(
                  `https://eu-west-1.console.aws.amazon.com/cloudformation/home?region=${this.props.cart.region}#/stacks/stackinfo?stackId=${this.props.templateProject.stack_id}`
                );
                return `Destination=${destination}`;
              }
              return param;
            })
            .join("&");
          window.open(newUrl, "_blank");
          this.setState({ isFetchAccess: false });
        })
        .catch((error) => {
          Log.error("consoleAccess error ", error);
          this.setState({ isFetchAccess: false });
        });
    }
  };

  render() {
    const { templateProject, status, isFetchingStatus } = this.props;

    return (
      <div className="text-footer-left">
        <div className="cdh-card-details">
          <div className="cdh-card-detail">
            <i className="fas fa-tasks" />
            <span className="content-card-bucket">
              &nbsp;<b>Package name: </b>
              {templateProject.package_name}
            </span>
          </div>

          <div className="cdh-card-detail">
            <i className="fas fa-tasks" />
            <span className="content-card-bucket">
              &nbsp;<b>Version: </b>
              {templateProject.version_deployed}
            </span>
          </div>

          <div className="cdh-card-detail">
            <i className="fas fa-tasks" />
            <span className="content-card-bucket">
              &nbsp;<b>Deployed the </b>
              {formatDate(templateProject.updatedat)}
            </span>
          </div>

          {status && (
            <div className="cdh-card-detail">
              <i className="fas fa-tasks" />
              <span className="content-card-bucket">
                &nbsp;<b>Status:</b>
                &nbsp;
                {isFetchingStatus && (
                  <i className="fas fa-circle-notch fa-spin" />
                )}
                &nbsp;{status}
              </span>
              {(status.includes("_FAILED") ||
                status === "ROLLBACK_COMPLETE" ||
                status === "ROLLBACK_IN_PROGRESS") && (
                <React.Fragment>
                  &nbsp;(
                  <div
                    className="btn-cart access-stack cdh-card-body-actions"
                    onClick={this.consoleAccess}
                  >
                    <span className="text-actions">Access to Stack</span>
                  </div>
                  )
                  {this.state.isFetchAccess && (
                    <i className="fas fa-spacing fa-circle-notch fa-spin" />
                  )}
                </React.Fragment>
              )}
            </div>
          )}

          <div className="cdh-card-detail">
            <i className="fab fa-github" />
            <span className="content-card-bucket">
              &nbsp;
              <b>
                <a
                  href={templateProject.github_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a>
              </b>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppSync(AccelerationTemplateProjectPart);
