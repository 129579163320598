import React from "react";

const CatalogHelpItem = () => (
  <div>
    <div>{" - The Open catalog. "}</div>
    <div>{"You can see all datasets available on CDH. "}</div>
    <div style={{ marginTop: "15px" }} />
    <div>
      <i
        className={"fas fa-lock-open"}
        style={{ color: "var(--blue-venice)", marginRight: "10px" }}
      />
      {"You can request data permission."}
    </div>
    <div style={{ marginTop: "15px" }} />
    <div>{"- The search and filters"}</div>
    <div>{"You can search for a type of data on the left hand side."}</div>
    <hr />
  </div>
);

export default CatalogHelpItem;
