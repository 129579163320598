/* @flow */

export const listDatasourceShares = {
  query: `query Q ($datasourceUri: String!, $options: QueryOptions) {
        listDatasourceShares(datasourceUri:$datasourceUri, options: $options) {
            results {
                datasource {
                    uri
                }
                group {
                    uri
                    name
                    account {
                        name
                    }
                }
                createdat
                updatedat
                are_new_datasets_shared 
            }
            total
            limit
            offset
        }
      }
    `,
  keyOutput: "listDatasourceShares"
};
