/* @flow */

import React from "react";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import ProjectUsage from "../../../carts/components/ProjectUsage";

type propTypes = {
  dataset: Object,
  api: GraphQl
};

type stateTypes = {
  projects: Array<Object>,
  ready: boolean,
  error: Object
};

class DatasetUsage extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      projects: [],
      error: undefined
    };
  }

  componentDidMount() {
    this.getDatasetProjects();
  }

  getDatasetProjects = () => {
    this.props.api.dataset
      .getDatasetProjects(this.props.dataset.uri)
      .then((projects) => {
        this.setState({
          projects,
          ready: true
        });
      })
      .catch((datasetProjectsError) => {
        this.setState({
          ready: true,
          error: datasetProjectsError
        });
      });
  };

  render() {
    const { ready, error, projects } = this.state;
    return (
      <div>
        {error && <Error error={error} path={"DatasetUsage"} />}
        {!ready && <Loading message={"Dataset Usage"} />}
        {!error &&
          ready &&
          projects.map((project) => <ProjectUsage project={project} />)}
        {!error && ready && projects.length <= 0 && (
          <div>This dataset has not been added to any project yet.</div>
        )}
      </div>
    );
  }
}

export default withAppSync(DatasetUsage);
