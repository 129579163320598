/* @flow */

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import routes from "../../../routes";
import Modal from "../../../../components/modal";
import ButtonAction from "../../../../components/buttonAction";
import Links from "../../../links";
import GraphQl from "../../../../graphQL";

type propTypes = {
  api: GraphQl,
  cart: Object,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  isLoadinglogsGroups: boolean,
  logsGroupsList: [],
  ready: boolean,
  error: Object,
  deleteLogGroupModalOpen: boolean,
  logGroupToDelete: Object
};

class LogGroups extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isLoadinglogsGroups: true,
      logsGroupsList: [],
      ready: true,
      error: null,
      deleteLogGroupModalOpen: false,
      logGroupToDelete: null
    };
  }

  async refreshLogGroupList() {
    this.setState({ isLoadinglogsGroups: true });
    const results = await this.props.api.logGroups.listLogsGroup(
      this.props.cart.uri
    );
    this.setState({ logsGroupsList: results, isLoadinglogsGroups: false });
  }

  componentDidMount() {
    this.refreshLogGroupList();
    window.that = this;
  }

  handleDeletelogGroup = () => {
    this.props.api.logGroups
      .deleteLogsGroup(this.props.cart.uri, this.state.logGroupToDelete.id)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Log group deleted",
          type: "success"
        });
        this.setState({ deleteLogGroupModalOpen: false });
        this.refreshLogGroupList();
      })
      .catch(() => {
        this.props.showGlobalNotification({
          message: "Failed to delete Log group",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.isLoadinglogsGroups) {
      return <Loading message="Logs Groups..." />;
    }

    if (this.state.error) {
      return <Error error={this.state.error} path={"EndpointsList"} />;
    }
    if (!this.state.ready) return <Loading message="Log groups" />;
    return (
      <Fragment>
        <div
          className="butn butn-flat"
          onClick={() =>
            this.props.goTo({
              route: routes.Cart.AddLogGroup,
              params: {
                cartUri: this.props.cart.uri
              },
              state: {
                cart: this.props.cart
              }
            })
          }
        >
          <i className="fas fa-plus"></i>
          <span className="butn-text">New Log group</span>
        </div>

        <div className="card-container">
          {this.state.logsGroupsList.map((logsGroup) => (
            <div
              key={logsGroup.id}
              className={"cart-card card-shadow card bg-white"}
            >
              <div className="card-datasource-header">
                <div className="card-datasource-begin">
                  <div className="card-datasource-begin-content">
                    <i className="fab fa-aws"></i>
                    <span className="text-header-datasource">
                      {logsGroup.platform.aws}
                    </span>
                  </div>
                </div>
                <div className="card-datasource-end">
                  <div className={"cart-environment-tag"}>
                    <span>{logsGroup.platform.environment}</span>
                  </div>
                </div>
              </div>
              <div className="card-datasource-body">
                <div className="card-cart-body-left">
                  <Links.Cart.EditLogGroup
                    cartUri={this.props.cart.uri}
                    logsGroupId={logsGroup.id}
                    state={{
                      cart: this.props.cart
                    }}
                  >
                    <span className="endpoint-name">{logsGroup.name}</span>
                  </Links.Cart.EditLogGroup>
                  <div className="card-datasource-details">
                    <small>{logsGroup.resource_name}</small>
                    <p style={{ marginTop: "10px" }}>
                      {logsGroup.platform.name}
                    </p>
                    <div className="card-dataset-tags">
                      {logsGroup.tags.map((tag) => (
                        <div className="" key={tag.Key}>
                          <div className="dataset-badge">
                            {tag.Key} : {tag.Value}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="card-cart-body-right">
                  <div className="card-datasource-body-actions"></div>
                </div>
              </div>
              <div className="card-datasource-footer">
                <div className="card-datasource-begin">
                  <div className="card-datasource-begin-content">
                    <i className="fas fa-globe-europe"></i>
                    <span className="text-header-datasource">
                      {this.props.cart.region}
                    </span>
                  </div>
                </div>
                <div className="card-datasource-end">
                  <div
                    className="btn-cart"
                    onClick={() =>
                      this.setState({
                        logGroupToDelete: logsGroup,
                        deleteLogGroupModalOpen: true
                      })
                    }
                  >
                    <i className="fas fa-trash fa-spacing"></i>
                    <span className="text-actions">Delete from CDH</span>
                  </div>
                  {/* <div
                    className="status"
                    style="display: flex; flex-direction: row;"
                  >
                    <button
                      type="button"
                      className="button-refresh-status butn butn-flat"
                    >
                      <i className="fas fa-sync-alt"></i>
                    </button>
                    <div className="text-footer-metrics">
                      Status <span className="tag-metrics">NotFound</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>

        {this.state.deleteLogGroupModalOpen && (
          <Modal
            title={`Delete log group ${this.state.logGroupToDelete.name}`}
            body={"Are you sure you want to delete this log group ?"}
            // errorMessage={errorDatasourceModal}
            actions={[
              <ButtonAction
                key="close"
                label="Close"
                onClick={() =>
                  this.setState({ deleteLogGroupModalOpen: false })
                }
              />,
              <ButtonAction
                key="delete"
                label="Delete"
                className="butn butn-delete"
                onClick={this.handleDeletelogGroup}
              />
            ]}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

const connector = connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(LogGroups)));
export default connector;
