/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Col from "../../components/col";
import Row from "../../components/row";
import Links from "../links";
import Routes from "../routes";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";
import Loading from "../../components/loading";
import ToggleButton from "../../components/toggleButton";
import HelpOn from "../../components/helpOn";
import GraphQl from "../../graphQL";
import SelectClassic from "../../components/SelectClassic";

const Log = Logger("AccountForm");

type propTypes = {
  goTo: Function,
  showGlobalNotification: Function,
  match: {
    path: string,
    params: {
      uriAccount: string
    }
  },
  location: {
    state: {
      account: Object
    }
  },
  api: GraphQl,
  auth: {
    getUser: Function
  }
};
type stateTypes = {
  errorSubmit: boolean,
  isNew: boolean,
  account: Object,
  gbus: Array<Object>,
  gbu: Object,
  user: Object,
  isLoading: boolean
};

class AccountForm extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isNew: true,
      gbus: [],
      gbu: {},
      account: {
        is_group_responsible_managed: false
      },
      user: {},
      isLoading: true
    };
  }

  componentDidMount() {
    this.listAllGbus();
    return this.props.auth.getUser().then((user = {}) => {
      const isNew = this.props.match.path !== Routes.Account.Edit;

      if (!isNew) {
        if (this.props.location.state && !this.props.location.state.account) {
          const gbu = {
            label: this.props.location.state.account.gbu.name,
            value: this.props.location.state.account.gbu.id
          };
          return this.setState({
            isNew,
            account: this.props.location.state.account,
            gbu,
            isLoading: false,
            user
          });
        }
        return this.props.api.account
          .get(this.props.match.params.uriAccount)
          .then((account) => {
            const gbu = {
              label: account.gbu.name,
              value: account.gbu.id
            };
            this.setState({
              isNew,
              account,
              gbu,
              isLoading: false,
              user
            });
          });
      }

      return this.setState({
        isNew,
        isLoading: false,
        user
      });
    });
  }

  listAllGbus() {
    return this.props.api.account.listAllGbus().then((data) => {
      const gbus = [];
      data.map((e) =>
        gbus.push({
          label: e.name,
          value: e.id
        })
      );
      this.setState({ gbus });
      return gbus;
    });
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const { account } = prevState;
      account[field] = value;
      Log.info("handleChange, account:", account);
      return { account };
    });
  };

  save = (values) => {
    const input = { ...values };
    if (this.state.isNew) {
      return this.props.api.account.create({
        name: input.name,
        gbu_id: this.state.gbu.value,
        resource_name: input.name,
        is_group_responsible_managed:
          this.state.account.is_group_responsible_managed,
        user_name: this.state.user.name,
        user_email: this.state.user.preferred_username,
        user_gid: (this.state.user.preferred_username || "").split("@")[0]
      });
    }
    return this.props.api.account.update(this.state.account.uri, {
      name: input.name,
      gbu_id: this.state.gbu.value,
      is_group_responsible_managed:
        this.state.account.is_group_responsible_managed
    });
  };

  render() {
    if (this.state.isLoading) return <Loading message={"form and data"} />;
    return (
      <React.Fragment>
        <Row className={"my-4"}>
          <Col size={1}>
            <Links.Account.List>
              <i
                style={{ verticalAlign: "middle" }}
                className={"mx-0 fas fa-chevron-left fa-2x"}
              />
            </Links.Account.List>
          </Col>
          <Col size={10}>
            <h4 className={"mx-0 text-primary"}>
              {this.state.isNew
                ? "Create a new organization"
                : "Edit an organization"}
            </h4>
          </Col>
        </Row>

        <Row>
          <Col size={1} />
          <Col size={10}>
            <Formik
              initialValues={{
                name: this.state.account.name,
                gbu: this.state.gbu
              }}
              onSubmit={(values, { setSubmitting }) => {
                Log.info("submit !", values);
                this.save(values)
                  .then(() => {
                    if (this.state.isNew) {
                      this.props.showGlobalNotification({
                        message: "Organization created",
                        type: "success"
                      });
                    } else {
                      this.props.showGlobalNotification({
                        message: "Organization Updated",
                        type: "success"
                      });
                    }

                    setSubmitting(false);
                    return this.props.goTo({
                      route: Routes.Account.List
                    });
                  })
                  .catch((errorSubmit) => {
                    if (this.state.isNew) {
                      this.props.showGlobalNotification({
                        message: "Organization creation failed",
                        type: "alert"
                      });
                    } else {
                      this.props.showGlobalNotification({
                        message: "Update of the organization failed",
                        type: "alert"
                      });
                    }

                    setSubmitting(false);
                    this.setState({ errorSubmit });
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      error={this.state.errorSubmit}
                      path={"AccountCreate"}
                      stringOnly
                    />
                  )}
                  <fieldset className="form-group">
                    <legend className="label-form">name</legend>
                    <Field
                      type="name"
                      name="name"
                      className="form-control bg-white"
                      placeholder="organization name..."
                    />
                    <ErrorMessage name="name" component="div" />
                  </fieldset>

                  <fieldset className="form-group">
                    <legend className="label-form">GBU attachment</legend>
                    <SelectClassic
                      placeholder={"Select a GBU"}
                      options={this.state.gbus}
                      selectedOption={this.state.gbu}
                      onSelectOption={(option) => {
                        this.setState({ gbu: option });
                      }}
                    />
                    <ErrorMessage name="gbu" component="div" />
                  </fieldset>

                  <fieldset className="form-group">
                    <label className="label-form">
                      Group Responsible Management
                    </label>
                    <div className="basic-metadata-display">
                      <ToggleButton
                        id="basic-metadata-display-id"
                        checkedValue={
                          this.state.account.is_group_responsible_managed
                        }
                        onChange={() =>
                          this.handleChange(
                            "is_group_responsible_managed",
                            !this.state.account.is_group_responsible_managed
                          )
                        }
                      />
                      <div>
                        <HelpOn
                          content={
                            <div>
                              <h3>{"Group Responsible Management"}</h3>
                              <div>
                                {
                                  "If activated, only organization admins and group responsible will be authorized to manage CDH groups of the organization"
                                }
                              </div>
                              <div>
                                {
                                  "(add / remove user, update / delete group ...)"
                                }
                              </div>
                              {!this.state.isNew && (
                                <b>
                                  {
                                    "Please be sure that every group has a responsible before activating this feature !"
                                  }
                                </b>
                              )}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </fieldset>

                  <div className={"row justify-content-center"}>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="butn"
                    >
                      {this.state.isNew
                        ? "Create organization"
                        : "Edit organization"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(AccountForm)));
