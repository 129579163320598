export const queryGetRSDatasource = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      RSDatasource{
        id
        network_id
        network{
            subnet_ids
            security_group_ids
            vpc_id
        }
        db_name
        db_username
        db_password
        db_group
        db_write_role
        db_read_role
        kms_key_alias
        kms_key_id
        datasource_iam_role_name
        datasource_iam_role_arn
        iam_roles
        namespace_name
        namespace_arn
        namespace_id
        namespace_status
        namespace_error
        workgroup_name
        workgroup_arn
        workgroup_id
        workgroup_status
        workgroup_error
        workgroup_base_capacity
        workgroup_port
        endpoint
        jdbc_url
        odbc_url
        stack_name
        stack_id
        stack_status
        stack_error
        etl_stack_name
        etl_stack_status
        etl_stack_error
        project {
         cart_uri
         cart_name
         cart_role_name
         datasource_uri
         cart_database
        }
      }
    }
  }
`,
  keyOutput: "getDatasource.RSDatasource"
};
