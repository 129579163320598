/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import Breadcrumb from "../../../../components/breadcrumb";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import Tabs from "../../../../components/tabs";
import routes from "../../../routes";
import NetworkSubDetails from "./networkSubDetails";
import NetworkModal from "./networkModal";
import ListNotebooksNetwork from "./listNotebooksNetwork";
import "./style.less";
import ListAccelerationTemplatesNetwork from "./listAccelerationTemplatesNetwork";
import RSNamespaces from "../../../analytics/redshiftserverless/list/RSNamespaces";

const Log = Logger("NetworkView");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function,
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string,
      networkId: string
    }
  }
};

type stateTypes = {
  error: Object,
  network: Object,
  sagemakerNotebookInstances: Object,
  ready: boolean,
  networkModal: boolean
};

class NetworkDetails extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      network: {},
      sagemakerNotebookInstances: {},
      error: undefined,
      networkModal: false
    };
  }

  componentDidMount() {
    this.getNetwork();
  }

  getNetwork = () => {
    this.setState({ ready: false });
    return this.props.api.playground
      .getNetwork(this.props.match.params.networkId)
      .then((response) => {
        this.setState({
          network: response,
          sagemakerNotebookInstances: response.sagemakerNotebookInstances,
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, ready: true });
      });
  };

  listNetworkSagemakerNotebookInstances = (offset) =>
    this.props.api.playground
      .listNetworkSagemakerNotebookInstances(
        this.props.match.params.networkId,
        { offset, limit: 10 }
      )
      .then((sagemakerNotebookInstances) => {
        this.setState({
          sagemakerNotebookInstances,
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, ready: true });
      });

  getTabs = () => {
    let tabs = [];
    if (this.state.network.network_type === "SAGEMAKER") {
      tabs = [
        {
          name: "Sagemaker Instance",
          index: "sagemakerInstance",
          route: "",
          content: (
            <div id="nav-information">
              <ListNotebooksNetwork
                sagemakerInstances={this.state.sagemakerNotebookInstances}
                withNetworkCol={false}
                loadPage={this.listNetworkSagemakerNotebookInstances}
              />
            </div>
          )
        },
        {
          name: "Acceleration Templates",
          index: "accelerationTemplate",
          route: "",
          content: (
            <div id="nav-information">
              <ListAccelerationTemplatesNetwork
                network={this.state.network}
                uriAccount={this.props.match.params.uriAccount}
                uriPlayground={this.props.match.params.uriPlayground}
              />
            </div>
          )
        }
      ];
    }
    if (this.state.network.network_type === "REDSHIFT_SERVERLESS") {
      tabs = [
        {
          name: "Namespaces",
          index: "namespaces",
          route: "",
          content: (
            <div id="nav-information">
              <RSNamespaces
                uriAccount={this.props.match.params.uriAccount}
                environmentUri={this.props.match.params.uriPlayground}
                goTo={this.props.goTo}
              />
            </div>
          )
        }
      ];
    }

    return tabs;
  };

  openNetworkModal = () => this.setState({ networkModal: true });

  closeNetworkModal = () => this.setState({ networkModal: false });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="NetworkView" />;
    if (!this.state.ready) return <Loading message={"Network details"} />;
    return (
      <React.Fragment>
        <div>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb view={`Network ${this.state.network.name}`} />
            </div>
          </div>
        </div>
        <div className="create-container">
          <div className="container-actions">
            <div className="actions-right">
              <button
                className="butn"
                onClick={() =>
                  this.props.goTo({
                    route: routes.Network.Edit,
                    params: {
                      uriAccount: this.props.match.params.uriAccount,
                      uriPlayground: this.props.match.params.uriPlayground,
                      networkId: this.props.match.params.networkId
                    },
                    state: { network: this.state.network }
                  })
                }
                type={"button"}
                style={{ marginRight: "5px", width: "85px" }}
              >
                <span>Edit</span>
              </button>
              <button
                className="butn butn-delete"
                type={"button"}
                style={{ margin: "5px", width: "85px" }}
                onClick={this.openNetworkModal}
              >
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
        <div className="network-body">
          <div className="network-body-left">
            <NetworkSubDetails network={this.state.network} />
          </div>
          <div className="network-body-right">
            <Tabs isPreload={false} tabs={this.getTabs()} />
          </div>
        </div>
        <div>
          {this.state.networkModal && (
            <NetworkModal
              network={this.state.network}
              hide={this.closeNetworkModal}
              showGlobalNotification={this.props.showGlobalNotification}
              goBack={() =>
                this.props.goTo({
                  route: routes.Playground.Network,
                  params: {
                    uriAccount: this.props.match.params.uriAccount,
                    uriPlayground: this.props.match.params.uriPlayground
                  }
                })
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(NetworkDetails)));
