/* @flow */

import React from "react";
import {
  isAccountGroup,
  isAccountAdminGroup,
  isPlaygroundAdminGroup,
  isDssInstanceAdminGroup,
  isDbxWorkspaceAdminGroup
} from "../../../utils/groupsfilter";

import "./groupLabels.less";

const AllAccountLabel = () => (
  <div className="badge badge-pill badge-success tooltiped">
    <span>All Account</span>
    <div className="tooltiptext">
      All users invite in the account is automatically add in this group.
    </div>
  </div>
);

const AdminAccountLabel = () => (
  <div className="badge badge-pill badge-warning tooltiped">
    <span>Admin</span>
    <div className="tooltiptext">
      This group can manage the account, the users, the playgrounds, the groups.
    </div>
  </div>
);

const AdminPlaygroundLabel = () => (
  <div className="badge badge-pill badge-warning tooltiped">
    <span>Admin</span>
    <div className="tooltiptext">
      This group can manage the playground, attached groups.
    </div>
  </div>
);

const AdminDBXWorkspaceLabel = () => (
  <div className="badge badge-pill badge-warning tooltiped">
    <span>Admin</span>
    <div className="tooltiptext">
      This group can manage the Databricks Workspace attached groups.
    </div>
  </div>
);

type labelProps = { number: number };

const GroupUsersLabel = ({ number }: labelProps) => (
  <div className={"users-label badge badge-pill badge-light"}>
    {number}
    <i className="fas fa-user" />
  </div>
);

const PlaygroundAttachedLabel = ({ number }: labelProps) => (
  <div className="playground-attached-label badge badge-pill badge-primary tooltiped">
    <i className="fas fa-thumbtack" />
    <span>
      Attached to {number} environment{number > 1 && "s"}
    </span>
  </div>
);

const CartAttachedLabel = ({ number }: labelProps) => (
  <div
    className={"cart-attached-label badge badge-pill badge-primary tooltiped"}
  >
    <i className="fas fa-shopping-basket" />
    <span>
      Attached to {number} project{number > 1 && "s"}
    </span>
  </div>
);

type groupPropTypes = {
  group: {
    name: string,
    account: {
      name: string,
      uri: string
    },
    platformsList?: {
      total: number
    },
    cartsList?: {
      total: number
    },
    identitiesList?: {
      total: number
    }
  }
};

const GroupLabels = ({ group = {} }: groupPropTypes) => (
  <div className="grouplabels">
    {group.identitiesList && group.identitiesList.total && (
      <GroupUsersLabel number={group.identitiesList.total} />
    )}
    {isAccountGroup(group) && <AllAccountLabel />}
    {isAccountAdminGroup(group) && <AdminAccountLabel />}
    {isPlaygroundAdminGroup(group) && <AdminPlaygroundLabel />}
    {isDssInstanceAdminGroup(group) && <AdminPlaygroundLabel />}
    {isDbxWorkspaceAdminGroup(group) && <AdminDBXWorkspaceLabel />}
    {group.platformsList && group.platformsList.total > 0 && (
      <PlaygroundAttachedLabel number={group.platformsList.total} />
    )}
    {group.cartsList && group.cartsList.total > 0 && (
      <CartAttachedLabel number={group.cartsList.total} />
    )}
  </div>
);

export default GroupLabels;
