/* @flow */

export const mutationDeleteNotification = {
  query: `mutation Q ($notificationId: String!) {
    deleteNotification(notificationId: $notificationId)
  }
`,
  keyOutput: "deleteNotification"
};

export type DeleteNotificationPropsTypes = boolean;
