/* @flow */

import React from "react";
import { dataTypes } from "../../constants";
import SelectAutocomplete from "../../../../../components/SelectAutocomplete";

type propTypes = {
  handleChange: Function,
  defaultValue: Array<string>
};

const AutoCompleteDataTypes = ({ defaultValue, handleChange }: propTypes) => (
  <SelectAutocomplete
    placeholder={"Select Data types"}
    options={dataTypes.map((v) => ({ value: v, label: v }))}
    selectedOption={defaultValue.map((v) => ({ value: v, label: v }))}
    onSelectOption={(options) =>
      handleChange({
        target: { value: options ? options.map((o) => o.value) : [] }
      })
    }
    isMulti
  />
);

export default AutoCompleteDataTypes;
