/* @flow */

import React from "react";
import Links from "../../../links";
import "./style.less";

type propsTypes = {
  datasetUri: string,
  datasetName: string,
  endMessage: string
};

const TextWithProjects = ({
  datasetUri = "",
  datasetName = "",
  endMessage = ""
}: propsTypes) => (
  <div className="simple-notification">
    <span>The dataset </span>
    <span className="link-dataset">
      <Links.Dataset.View uriDataset={datasetUri}>
        {datasetName}
      </Links.Dataset.View>
    </span>
    <span> {endMessage}</span>
  </div>
);

export default TextWithProjects;
