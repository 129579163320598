export const getCartForAccount = {
  query: `query Q ($cartUri: String!) {
          getCart (cartUri: $cartUri) {
              dbx_workspace {
              dbx_workspace_name
                  uuid
                  dbx_workspace_url
                  dbxAccount {
                      dbx_account_name
                      uuid
                  }
                }
              }
        }
      `,
  keyOutput: "getCart"
};
