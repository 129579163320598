export const queryGetDomain = {
  query: `query Q ($platformUri: String!, $domainId: String!) {
    getSagemakerStudioDomain(platformUri: $platformUri, domainId: $domainId) {
      id
      name
      network {
          id
          name
          vpc_id
          subnet_ids
          security_group_ids
      }
      sm_domain_id
      sm_domain_name
      createdat
      updatedat
      status
      aws
      role_arn
      region
      platform_uri
      stack_name
      stack_id
      stack_status
      lifecycle_conf_enabled
    }
  }`,
  keyOutput: "getSagemakerStudioDomain"
};
