import React from "react";
import PropTypes from "prop-types";
import Row from "../../../components/row";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import CopyToClipboard from "../../../components/copyToClipboard";
import Breadcrumb from "../../../components/breadcrumb";
import "./style.less";

class DatasourceGenerateETLSessionCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      credentials: {},
      error: false,
      overwrite: this.props.match.params.overwrite === "true"
    };
  }

  componentDidMount() {
    this.props.api.datasource
      .getDatasourceETLSessionCredentials(
        this.props.match.params.uriDatasource,
        this.state.overwrite
      )
      .then((response) => {
        if (!response) {
          this.setState({
            error: { message: "Credentials not found" }
          });
        }

        this.setState({
          ready: true,
          credentials: response
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path={"DatasourceGenerateETLSessionCredentials"}
        />
      );
    if (!this.state.ready)
      return <Loading message={"datasource get session credentials"} />;

    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={" Datasource Aws Credentials"}
        />
        <div className="warning-info">
          <i className="fas fa-exclamation-circle fa-spacing"></i>
          Your session credentials expires in 12 hours.
        </div>
        <Row className={"my-4 justify-content-center"}>
          <div className="rowCredential">
            <div className="keyCredential">{"Access key ID:"}</div>
            <div className="valueCredential">
              {this.state.credentials.AccessKeyId}
            </div>
            <div className="clipboardCredential">
              <CopyToClipboard
                text={this.state.credentials.AccessKeyId}
                className={"fas fa-copy btn-carre"}
                label={""}
              />
            </div>
          </div>
          <div className="rowCredential">
            <div className="keyCredential">{"Secret Access Key:"}</div>
            <div className="valueCredential">
              {this.state.credentials.SecretKey}
            </div>
            <div className="clipboardCredential">
              <CopyToClipboard
                text={this.state.credentials.SecretKey}
                className={"fas fa-copy btn-carre"}
                label={""}
              />
            </div>
          </div>
          <div className="containerCredentials">
            <div className="rowCredential">
              <div className="keyCredential">{"Session Token:"}</div>
              <div className="valueCredential">
                {this.state.credentials.Token}
              </div>
              <div className="clipboardCredential">
                <CopyToClipboard
                  text={this.state.credentials.Token}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

DatasourceGenerateETLSessionCredentials.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uriDatasource: PropTypes.string,
      overwrite: PropTypes.bool
    })
  }),
  api: PropTypes.shape({
    datasource: PropTypes.shape({
      getDatasourceETLSessionCredentials: PropTypes.func
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      datasource: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

export default withAppSync(DatasourceGenerateETLSessionCredentials);
