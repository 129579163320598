export const mutationCreate = {
  query: `mutation Q (
        $cartUri: String!,
        $input: SagemakerNotebookInstanceInput!
    ) {
        createSagemakerNotebookInstance(cartUri: $cartUri, input: $input) {
          arn
        }
      }
    `,
  keyOutput: "createSagemakerNotebookInstance"
};
