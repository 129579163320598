/* @flow */

import React from "react";
import { Field, Form, Formik } from "formik";
import "./view.less";
import Error from "../../../components/error";
import SelectClassic from "../../../components/SelectClassic";
import Col from "../../../components/col";
import GraphQl from "../../../graphQL";
import HelpOn from "../../../components/helpOn";

type propTypes = {
  cart: Object,
  api: GraphQl,
  onAdd: Function
};

type stateTypes = {
  errorAdd: Object,
  isSubmitting: boolean,
  selectedType: Object
};

class CartProviderAdd extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      errorAdd: undefined,
      isSubmitting: false,
      selectedType: {
        label: "AssumeRole",
        value: "AssumeRole"
      }
    };
  }

  addProvider = (provider: Object = {}) => {
    this.setState({
      errorAdd: undefined,
      isSubmitting: true
    });
    return this.props.api.cart
      .addProvider(this.props.cart.uri, provider)
      .then((providers) => {
        this.setState({
          isSubmitting: false
        });
        this.props.onAdd(providers);
      })
      .catch((errorAdd) => {
        this.setState({
          errorAdd,
          isSubmitting: false
        });
      });
  };

  createTrustFromCart = (trust: Object = {}) => {
    this.setState({
      errorAdd: undefined,
      isSubmitting: true
    });

    trust.is_admin = false;
    trust.type = this.state.selectedType.value;

    return this.props.api.cart
      .createTrustFromCart(this.props.cart.uri, trust)
      .then(() => {
        this.setState({
          isSubmitting: false
        });
        this.props.onAdd();
      })
      .catch((errorAdd) => {
        this.setState({
          errorAdd,
          isSubmitting: false
        });
      });
  };

  render() {
    return (
      <div className={"cart-providers-add cart-providers-form"}>
        <div className={"cart-providers-add-title"}>
          Add a new trustee to this project trust relationship:
        </div>
        {this.state.errorAdd && (
          <Error
            error={this.state.errorAdd}
            path={"CartProvidersAdd"}
            stringOnly
          />
        )}
        <Formik
          initialValues={{
            arn: "arn:aws:iam::123456789012:role/some/sample-role"
          }}
          validate={(values) => {
            const errors = {};
            const pattern = new RegExp(
              "^(arn:aws:iam::[0-9]{12}:(role|user)/[\\w+=,.\\/@-]+)|([a-z0-9]+\\.amazonaws\\.com)$"
            );
            if (pattern.test(values.arn) !== true) {
              errors.arn = `Unable to validate trustee. Please, check that the ARN matches the pattern ${pattern.source}`;
            }

            if (
              values.arn === "arn:aws:iam::123456789012:role/some/sample-role"
            ) {
              errors.arn =
                '"arn:aws:iam::123456789012:role/some/sample-role" is a sample ...';
            }

            if (!this.state.selectedType)
              errors.selectedType = "Type is mandatory";

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            this.createTrustFromCart(values)
              .then(() => setSubmitting(false))
              .then(() =>
                resetForm({
                  arn: "arn:aws:iam::123456789012:role/some/sample-role",
                  description: "",
                  policy_condition: ""
                })
              )
          }
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="from-group">
                <label className="label-form">Type *</label>
                <SelectClassic
                  placeholder={"Select a type"}
                  options={["AssumeRole", "AssumeRoleWithWebIdentity"].map(
                    (elt) => ({
                      label: elt,
                      value: elt
                    })
                  )}
                  selectedOption={
                    this.state.selectedType
                      ? {
                          value: this.state.selectedType.value,
                          label: this.state.selectedType.label
                        }
                      : null
                  }
                  onSelectOption={(option) =>
                    this.setState({ selectedType: option })
                  }
                />
                {errors.selectedType && (
                  <Col size={12} style={{ color: "red" }}>
                    {errors.selectedType}
                  </Col>
                )}
              </div>

              <div className="form-group">
                <div style={{ height: "16px" }}></div>
                <label className="label-form">Service or ARN *</label>
                <Field
                  required
                  name="arn"
                  className="form-control form-control-sm"
                  placeholder="Principal Arn"
                />
                {errors.arn && (
                  <Col size={12} style={{ color: "red" }}>
                    {errors.arn}
                  </Col>
                )}
              </div>

              <div className="from-group mt-3">
                <div style={{ display: "flex", gap: "10px" }}>
                  <label className="label-form">Condition</label>
                  <div style={{ position: "relative", top: "-1px" }}>
                    <HelpOn
                      content={
                        <div>
                          <h3>Policy condition</h3>
                          <div>
                            For more information about conditions in trust
                            policy, please
                            <a href="https://aws.amazon.com/blogs/security/how-to-use-trust-policies-with-iam-roles/">
                              read the documentation here
                            </a>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <Field
                  component="textarea"
                  name="policy_condition"
                  className="form-control bg-white mt-1"
                  placeholder="Policy condition..."
                />
              </div>

              <div className="form-group">
                <div style={{ height: "6px" }}></div>
                <label className="label-form">Description</label>
                <Field
                  name="description"
                  component="textarea"
                  className="form-control form-control-sm"
                  placeholder=" Write a description..."
                />
              </div>

              <div className="actions">
                <button type="submit" disabled={isSubmitting} className="butn">
                  {this.state.isSubmitting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  Add trust relationship
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CartProviderAdd;
