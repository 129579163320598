/* eslint-disable */
import React from "react";
import Row from "../../components/row";
import Col from "../../components/col";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";

/*
query Q {
    listMyCarts {
        access uri groupuri name creatoruri description resource_name region createdat
        datasets {
            uri name description
        }
        platforms {
            uri accountid aws name
        }
        group {
            uri name
        }
    }
}
 */

class GraphQLEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      pending: false,
      error: false,
      query: this.getQueryInit(),
      response: ""
    };
  }

  componentDidMount() {
    const self = this;
    this.props.auth.getIdToken().then((t) => {
      console.log("t = ", t);
      self.setState({
        ready: true,
        idtoken: t
      });
    });
  }

  getQueryInit() {
    return `query Q {
        
        }`;
  }

  getMutationInit() {
    return `mutation Q {
        
        }`;
  }

  handleChangeQuery = (event) => {
    this.setState({
      query: event.target.value
    });
  };

  run = () => {
    this.setState({
      pending: true,
      error: false
    });
    return this.props.api.utils
      .fetchAppSync({ query: this.state.query }, {})
      .then((data) => {
        console.log("data = ", data);
        this.setState({
          pending: false,
          response: JSON.stringify(data, null, 4)
        });
      })
      .catch((error) => {
        this.setState({
          pending: false,
          error: error,
          response: JSON.stringify(error)
        });
      });
  };

  render() {
    if (!this.state.ready) return <Loading message={"Wait"} />;

    return (
      <React.Fragment>
        <Row style={{ marginTop: "15px" }}>
          <Col size={12}>
            <textarea
              rows="1"
              value={this.state.idtoken}
              disabled
              className="bg-white text-black form-control"
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "15px" }}>
          <Col size={4}>
            <div className="d-flex">
              <button
                onClick={() => this.setState({ query: this.getQueryInit() })}
                className={"butn"}
              >
                Init Query
              </button>
              <button
                onClick={() => this.setState({ query: this.getMutationInit() })}
                className={"butn ml-2"}
              >
                Init Mutation
              </button>
            </div>
          </Col>

          <Col size={2}>
            <button onClick={this.run} className={"butn send-gpql-editor"}>
              Send
            </button>
          </Col>
        </Row>

        <Row style={{ marginTop: "5px" }}>
          <Col size={6}>
            <Row>
              <Col size={12}>
                <div className="input-group">
                  <textarea
                    value={this.state.query}
                    onChange={this.handleChangeQuery}
                    rows="15"
                    className="bg-dark text-white form-control"
                    aria-label="With textarea"
                  ></textarea>
                </div>
              </Col>
            </Row>
          </Col>
          <Col size={6}>
            <Row>
              <Col size={12}>
                {this.state.pending ? (
                  <Loading message={"Running"} />
                ) : this.state.error ? (
                  <div className="input-group">
                    <textarea
                      value={this.state.error}
                      disabled
                      rows="15"
                      className="bg-light text-danger form-control"
                      aria-label="With textarea"
                    />
                  </div>
                ) : (
                  this.state.response && (
                    <div className="input-group">
                      <textarea
                        value={this.state.response}
                        disabled
                        rows="15"
                        className="bg-light text-dark form-control"
                        aria-label="With textarea"
                      />
                    </div>
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withAppSync(GraphQLEditor);
