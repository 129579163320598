/* @flow */
import React from "react";
import Breadcrumb from "../../../../../components/breadcrumb";
import Loading from "../../../../../components/loading";
import withAppSync from "../../../../AppsyncHOC";
import Error from "../../../../../components/error";
import GraphQl from "../../../../../graphQL";
import RedshiftClusterSearch from "./redshiftClusterSearch";
import "./redshiftClustersImport.less";

type propTypes = {
  match: {
    params: {
      uriPlayground: string,
      uriAccount: string
    }
  },
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  platform: Object,
  error?: Object
};

class RedshiftClustersImport extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      platform: {},
      error: undefined
    };
  }

  componentDidMount() {
    this.getEnvironment();
  }

  getEnvironment = () =>
    this.props.api.playground
      .get(
        this.props.match.params.uriAccount,
        this.props.match.params.uriPlayground
      )
      .then((response) => {
        this.setState({
          platform: response.playground,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"RedshiftClustersImport"} />;
    if (!this.state.ready)
      return (
        <Loading className={"my-3"} message={"Redshift Clusters Import"} />
      );

    return (
      <div className="detected-clusters">
        <Breadcrumb view={"Import Redshift Clusters"} />
        {this.state.platform && (
          <div>
            <RedshiftClusterSearch platform={this.state.platform} />
          </div>
        )}
      </div>
    );
  }
}

export default withAppSync(RedshiftClustersImport);
