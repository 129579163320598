/* @flow */

import React from "react";
import config from "../../../../config";
import { formatDate } from "../../../../utils/date";
import "./style.less";

type propsTypes = {
  date: string | Date
};

const ReleaseNotification = ({ date }: propsTypes) => (
  <div className="release-notification">
    <p>
      A new version of the CommonDataHub has been released the{" "}
      {formatDate(date, false)}.
    </p>
    For more detail, please visite&nbsp;
    <a
      href={`${config.HELP_CENTER}/release`}
      target="_blank"
      rel="noopener noreferrer"
    >
      the release note.
    </a>
  </div>
);

export default ReleaseNotification;
