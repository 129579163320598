export const launchLakeFormationMigration = {
  query: `mutation Q ($platformUri : String!, $input: NewLakeFormationMigrationInput!) {
        launchLakeFormationMigration(platformUri : $platformUri, input: $input) {
          id
          region
          report {
           id
          }
          type
          status
          stepfunctions
          platform_uri
        }
      }
    `,
  keyOutput: "launchLakeFormationMigration"
};
