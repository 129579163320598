export const mutationRemoveShareItem = {
  query: `mutation Q ($datasetUri: String!, $groupUri: String!, $shareItemId: String!) {
        removeShareItem(datasetUri: $datasetUri, groupUri: $groupUri, shareItemId: $shareItemId) {
          id
          share_id
          stage_id
          stage{
           table
           id 
          }
          data_filter_id
          data_filter{
           name 
          }
          createdat
          updatedat
          table_name
          status
          requester
        }
      }
    `,
  keyOutput: "removeShareItem"
};
