export const listAllMyDatasourcesTotal = {
  query: `query Q ($options: QueryOptions) {
        listAllMyDatasourcesTotal(options: $options) {
            results {
              parent
              uri
              name
              region
              bucket
              description
              accountid
              createdat
              user_roles
              source_type
              database
              datasetsList {
                total
              }
              owner {
                name
                resource_name
                uri
              }
              shares_count
              tags
            }
            total
            offset
            limit
          }
      }
    `,
  keyOutput: "listAllMyDatasourcesTotal"
};
