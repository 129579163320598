/* @flow */

import React from "react";
import Loading from "../../components/loading";
import CartCard from "./card";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import route from "../routes";
import Breadcrumb from "../../components/breadcrumb";
import config from "../../config";
import GraphQl from "../../graphQL";
import Paginator from "../../components/paginator";
import SearchByFacet from "./AddDataset/searchByFacet";
import "./list.less";
import Logger from "../../utils/logger";

const Log = Logger("CartList");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  location: {
    state: {
      account: {
        name: string
      }
    }
  }
};

type stateTypes = {
  isFetchingCarts: boolean,
  reset: boolean,
  carts: Array<Object>,
  error: boolean,
  keywords: "",
  offset: number,
  groupsNames: Array<{ value: string, nb: number }>,
  filters: Array<{ key: string, value: string }>,
  cartsTotal: number
};

class CartList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      isFetchingCarts: false,
      reset: true,
      carts: [],
      cartsTotal: 0,
      error: false,
      keywords: "",
      offset: 0,
      groupsNames: [],
      filters: []
    };
  }

  componentDidMount() {
    this.searchCarts();
    this.listCartOwner();
  }

  renderContent = () => {
    if (this.state.error)
      return <Error error={this.state.error} path="CartsList" />;
    if (this.state.reset) return <Loading message="Projects List" />;
    return (
      <div className="card-container">
        <Paginator
          isLoadingPage={this.state.isFetchingCarts}
          loadingMessage={<Loading message="Projects List" />}
          limit={10}
          initialOffset={0}
          list={this.state.carts}
          totalCount={this.state.cartsTotal}
          componentRender={(cart) => <CartCard key={cart.uri} cart={cart} />}
          loadPage={this.searchCartsFromPagination}
          onNoResult={() => (
            <p className="total-search-results">No project found</p>
          )}
        />
      </div>
    );
  };

  onChangeInput = (e) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          keywords,
          offset: 0,
          reset: true
        },
        this.searchCarts
      );
    }, 300);
  };

  searchCarts = () => {
    this.setState({ isFetchingCarts: true });
    const options = {
      limit: 10,
      offset: this.state.offset,
      search: undefined,
      filters: this.state.filters
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.cart
      .getCarts(options)
      .then((items) => {
        if (!items) return false;
        this.setState({
          isFetchingCarts: false,
          carts: items.results,
          cartsTotal: items.total,
          reset: false
        });
        return items.results;
      })
      .catch((error) => {
        this.setState({
          error,
          isFetchingCarts: false,
          reset: false
        });
        return [];
      });
  };

  listCartOwner = () =>
    this.props.api.cart
      .listMyCartsGroupNames({})
      .then((items) => {
        if (!items) return false;
        return this.setState({
          groupsNames: items.results.map((g) => ({
            value: g.uri,
            label: g.name,
            nb: 1
          }))
        });
      })
      .catch((error) => {
        Log.error(error);
      });

  onFilterGroups = (value) => {
    this.setState(
      {
        filters: value ? [{ key: "group_uri", value }] : [],
        offset: 0,
        reset: true
      },
      this.searchCarts
    );
  };

  searchCartsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.searchCarts().then(r))
    );

  render() {
    return (
      <div className="carts-list">
        <div className="bread-line">
          <div className="title-content">
            <Breadcrumb
              state={this.props.location.state}
              view="My Projects"
              helpOn={{
                content: (
                  <div>
                    <div>- Data Projects</div>
                    <div>
                      Find the different projects that you will have created.
                    </div>
                    <div>
                      A project is a collection of dataset you have selected to
                      play with.
                    </div>
                    <hr />
                    <div>Requirements:</div>
                    <div>
                      - You can only create a project if you part of a group
                      linked to an environment. If you don’t contact your
                      account admin.
                    </div>
                  </div>
                ),
                helpCenterLink: `${config.HELP_CENTER}`
              }}
              hideGoBack
            />
          </div>
        </div>
        <div className="header-container-projects">
          <div className="search-project">
            <SearchByFacet
              placeholder="Select a group"
              items={this.state.groupsNames}
              onSelectItem={this.onFilterGroups}
            />

            <div className="search-input">
              <input
                className="form-control"
                placeholder="Search Project !"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={() =>
                  this.props.goTo({
                    route: route.Cart.Create,
                    params: {}
                  })
                }
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New project</span>
              </div>
            </div>
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default withAppSync(CartList);
