export const getRedshiftClusterPermanentCredentials = {
  query: `query Q ($clusterUri:String!, $cartUri:String!) {
      getRedshiftClusterPermanentCredentials (clusterUri:$clusterUri, cartUri:$cartUri) {
          resource_name
          database_name
          database_user
          database_password
          expiration
        }
    }
    `,
  keyOutput: "getRedshiftClusterPermanentCredentials"
};
