export const listPlatformNotGroups = {
  query: `query Q ($platformUri:String!, $options: QueryOptions) {
        listPlatformNotGroups(platformUri:$platformUri, options: $options) {
            total
            offset
            limit
            results {
                name
                uri
                account {
                    uri
                    name
                }
            }
        }
      }
    `,
  keyOutput: "listPlatformNotGroups"
};
