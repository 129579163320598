export const getConnectionProperties = {
  query: `query Q ($cartUri:String!, $platformUri:String!){
        getConnectionProperties (cartUri:$cartUri, platformUri:$platformUri) {
            accessKeyId
            athenaOutputLocation
            athenaWorkGroupName
            datasourceName
            roleArn
            region
            s3EncryptionOption
            secretKey
            token
            driver
            authenticationType
            awsProfile
            awsRegion
            binaryColumnLength
            catalog
            complexTypeColumnLength
            description
            encryptedPWD
            endpointOverride
            idp_port
            maxCatalogNameLen
            maxCatalogNameLength
            maxColumnNameLen
            maxColumnNameLength
            maxSchemaNameLen
            maxSchemaNameLength
            maxTableNameLen
            maxTableNameLength
            metadataRetrievalMethod
            rowsToFetchPerBlock
            s3OutputEncKMSKey
            s3OutputEncOption
            s3OutputLocation
            schema
            token
            sessionToken
            ssl_insecure
            streamingEndpointOverride
            stringColumnLength
            uID
            useProxy
            useProxyForIdP
            useResultsetStreaming
            useSQLUnicodeTypes
            workgroup
            secretKey
            roleArn
        }
      }
    `,
  keyOutput: "getConnectionProperties"
};
