import { NOTIFICATIONS_RELOAD } from "./types";

const initialState = {
  type: ""
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_RELOAD:
      return {
        ...state,
        type: action.type,
        date: new Date().getTime()
      };

    default:
      return state;
  }
}
