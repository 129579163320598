export const listCartProviders = {
  query: `query Q ($cartUri: String!) {
        listCartTrustRelationship(cartUri:$cartUri) {
          arn
          description
        }
      }
    `,
  keyOutput: "listCartTrustRelationship"
};
