/* @flow */

import { getHomepageKPIs } from "./queryGetHomepageKPIs";

class KpiApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  getKpi() {
    return this.fetchAppSync(getHomepageKPIs, {});
  }
}

export default KpiApi;
