/* @flow */

import React from "react";
import { connect } from "react-redux";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Links from "../../../links";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";
import { updateMessage } from "../../../globalNotifications/actions";
import Modal from "../../../../components/modal";
import "./redshiftClusterDetails.less";
import routes from "../../../routes";

const Log = Logger("RedshiftClusterDetails");

type propTypes = {
  platform: Object,
  cluster: Object,
  reloadRedshiftClusters: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  error: Object,
  deleteError: Object,
  detachError: Object,
  loadingDetachRedshiftCluster: boolean,
  detachRedshiftClusterModal: boolean,
  loadingDeleteRedshiftCluster: boolean,
  deleteRedshiftClusterModal: boolean
};

class RedshiftClusterDetails extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      deleteError: null,
      detachError: null,
      loadingDeleteRedshiftCluster: false,
      deleteRedshiftClusterModal: false,
      loadingDetachRedshiftCluster: false,
      detachRedshiftClusterModal: false
    };
  }

  openDeleteRedshiftClusterModal = () =>
    this.setState({ deleteRedshiftClusterModal: true });

  closeDeleteRedshiftClusterModal = () =>
    this.setState({ deleteRedshiftClusterModal: false, deleteError: null });

  openDetachRedshiftClusterModal = () =>
    this.setState({ detachRedshiftClusterModal: true });

  closeDetachRedshiftClusterModal = () =>
    this.setState({ detachRedshiftClusterModal: false, detachError: null });

  deleteRedshiftClusterModal() {
    return (
      <Modal
        title={"Confirm Deletion"}
        body={`Delete the cluster : "${this.props.cluster.cluster_name}" from commonDataHub and AWS ?`}
        errorMessage={this.state.deleteError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDeleteRedshiftClusterModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.deleteRedshiftCluster()}
          >
            Delete
          </button>
        ]}
      />
    );
  }

  detachRedshiftClusterModal() {
    return (
      <Modal
        title={"Confirm Detach Action"}
        body={`Detach the cluster "${this.props.cluster.cluster_name}" from commonDataHub ?`}
        errorMessage={this.state.detachError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDetachRedshiftClusterModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.detachRedshiftCluster()}
          >
            Detach
          </button>
        ]}
      />
    );
  }

  deleteRedshiftCluster = () => {
    this.setState({
      loadingDeleteRedshiftCluster: true
    });
    this.props.api.analytics
      .deleteRedshiftCluster(this.props.cluster.uri, true)
      .then((response) => {
        Log.info("Redshift removed:", response);
        this.props.showGlobalNotification({
          message: "Redshift cluster deleted",
          type: "success"
        });
        this.props.reloadRedshiftClusters();
        this.setState({
          loadingDeleteRedshiftCluster: false
        });
      })
      .catch((deleteError) => {
        this.setState({
          deleteError,
          loadingDeleteRedshiftCluster: false
        });
      });
  };

  detachRedshiftCluster = () => {
    this.setState({
      loadingDetachRedshiftCluster: true
    });
    this.props.api.analytics
      .detachRedshiftCluster(this.props.cluster.uri)
      .then((response) => {
        Log.info("Redshift cluster  detached:", response);
        this.props.showGlobalNotification({
          message: "Redshift cluster detached from commonDatahub",
          type: "success"
        });
        this.props.reloadRedshiftClusters();
        this.setState({
          loadingDetachRedshiftCluster: false
        });
      })
      .catch((detachError) => {
        this.setState({
          detachError,
          loadingDetachRedshiftCluster: false
        });
      });
  };

  onGenerateCredentials = () =>
    this.props.goTo({
      route: `${routes.Analytics.View}#credentials`,
      params: {
        clusterUri: this.props.cluster.uri
      }
    });

  render() {
    const { platform, cluster } = this.props;
    const {
      error,
      loadingDeleteRedshiftCluster,
      loadingDetachRedshiftCluster,
      deleteRedshiftClusterModal,
      detachRedshiftClusterModal
    } = this.state;
    return (
      <div className={"cluster-card card-shadow card bg-white"}>
        {error && <Error error={error} path="RedshiftClusterDetails" />}
        <div className="card-cluster-header">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fab fa-aws" />
              <span className="text-header-datasource">{platform.aws}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className={`cart-environment-tag ${platform.environment}`}>
              <span>{platform.environment}</span>
            </div>
          </div>
        </div>
        <div className="card-cluster-body">
          <div className="card-cluster-body-left">
            {cluster.status !== "NotFound" ? (
              <Links.Analytics.View clusterUri={cluster.uri}>
                <span className={"endpoint-name"}>{cluster.cluster_name}</span>
              </Links.Analytics.View>
            ) : (
              <span className={"endpoint-name"}>{cluster.cluster_name}</span>
            )}

            <div className="card-cluster-details">
              <p className="card-cluster-description">
                {cluster.description && cluster.description.length > 100
                  ? `${cluster.description.substring(0, 50)}...`
                  : cluster.description}
              </p>
              <div className="card-cluster-role">
                <i className="fab fa-aws" />
                <span className="content-cluster-bucket">
                  {cluster.resource_name}
                </span>
              </div>
              {cluster.cluster_arn && (
                <div className="card-cluster-role">
                  <i className="fab fa-aws" />
                  <span className="content-card-cluster">
                    {cluster.cluster_arn}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="card-cluster-body-right">
            <div className="card-cluster-body-actions">
              {cluster.status !== "NotFound" &&
                cluster.status !== "deleting" && (
                  <div>
                    <Links.Analytics.View clusterUri={cluster.uri}>
                      <i className="fas fa-play fa-spacing" />
                      <span className="text-actions">View Details</span>
                    </Links.Analytics.View>
                  </div>
                )}
              {cluster.status !== "NotFound" &&
                cluster.status !== "deleting" && (
                  <div
                    className="btn-cart"
                    onClick={this.onGenerateCredentials}
                  >
                    <i className="fas fa-key fa-spacing" />
                    <span className="text-actions">Generate Credentials</span>
                  </div>
                )}
              {(cluster.status === "available" ||
                cluster.status === "NotFound" ||
                cluster.status === "deleting") && (
                <div
                  className="btn-cart"
                  onClick={this.openDetachRedshiftClusterModal}
                >
                  {loadingDetachRedshiftCluster && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fa fa-expand fa-spacing" />
                  <span className="text-actions">Detach from CDH</span>
                </div>
              )}
              {(cluster.status === "available" ||
                cluster.status === "NotFound" ||
                cluster.status === "deleting") && (
                <div
                  className="btn-cart"
                  onClick={this.openDeleteRedshiftClusterModal}
                >
                  {loadingDeleteRedshiftCluster && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-trash fa-spacing" />
                  <span className="text-actions">Delete Cluster</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-cluster-footer">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fas fa-globe-europe" />
              <span className="text-header-datasource">{cluster.region}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className="text-footer-metrics">
              Status{" "}
              <span
                className="tag-metrics"
                style={
                  cluster.status === "NotFound"
                    ? { backgroundColor: "red" }
                    : {}
                }
              >
                {cluster.status}
              </span>
            </div>
          </div>
        </div>
        <div>
          {deleteRedshiftClusterModal && this.deleteRedshiftClusterModal()}
        </div>
        <div>
          {detachRedshiftClusterModal && this.detachRedshiftClusterModal()}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(RedshiftClusterDetails)));
