/* @flow */
import React from "react";
import "./view.less";
import { formatDate } from "../../../utils/date";

type propTypes = {
  log: {
    id: string,
    createdat: string,
    updatedat: string,
    status: string,
    output: string,
    action: string,
    operation: string,
    triggered_by: string,
    creator: {
      name: string,
      gid: string
    },
    endedat: string
  }
};

const CartLogItem = ({ log }: propTypes) => {
  let statusClassName = "";
  switch (log.status) {
    case "FAILED":
      statusClassName = "badge-log badge-log-danger";
      break;
    case "SUCCEEDED":
      statusClassName = "badge-log badge-log-success";
      break;
    default:
      statusClassName = "badge-log badge-log-primary";
  }

  return (
    <div className={"mt-1 border log-container"}>
      <div>
        <div className={statusClassName}>{log.status}</div>
      </div>
      <div className="log-details">
        <p>
          <i className="pr-2 fas fa-list-alt"></i>
          <span className="label-display ml-1px">OPERATION : </span>
          <strong>{log.operation}</strong>
        </p>
        {log.status === "FAILED" && (
          <p className="output-container">
            <i className="pr-2 fas fa-exclamation-triangle"></i>
            <span className="label-display">OUTPUT : </span>
            <span className="log-output">{log.output}</span>
          </p>
        )}
        {log.status === "SUCCEEDED" && (
          <span className="log-output">{log.output}</span>
        )}
      </div>
      <div className="date-log-cart-container">
        <span className="user-log">
          <span className="user-log-made">Made by:</span>
          <span className="user-log-name">
            {log.creator.name} ({log.creator.gid})
          </span>
        </span>
        <span className="date-log">{formatDate(log.endedat)}</span>
      </div>
    </div>
  );
};

export default CartLogItem;
