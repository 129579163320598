export const addIamPolicyToMultipleGroups = {
  query: `mutation Q (
            $platformUri: String!,
            $groupUriList: [String!]!,
            $policyArn: String!,
            $addToExistingProjects: Boolean!,
            $description: String!
        ) {
            addIamPolicyToMultipleGroups(
                platformUri:$platformUri,
                groupUriList:$groupUriList,
                policyArn:$policyArn,
                addToExistingProjects:$addToExistingProjects,
                description:$description
            ) 
        }
    `,
  keyOutput: "addIamPolicyToMultipleGroups"
};
