import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compile } from "path-to-regexp";

const withGoTo = (ComposedComponent) => {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.goTo = this.goTo.bind(this);
      this.goBack = this.goBack.bind(this);
    }

    goBack() {
      this.props.history.goBack();
    }

    goTo({ route, params = {}, state = {} }, action = "push") {
      if (!route || typeof route !== "string") {
        throw new Error("goTo need route/params args !");
      }
      const to = decodeURIComponent(compile(route)(params));
      if (action === "replace") return this.props.history.replace(to, state);
      return this.props.history.push(to, state);
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          goTo={this.goTo}
          goBack={this.goBack}
        />
      );
    }
  }
  WrappedComponent.propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    }),
    auth: PropTypes.shape({
      getIdToken: PropTypes.func.isRequired
    })
  };
  WrappedComponent.displayName = `withGoTo(${
    ComposedComponent.displayName || ComposedComponent.name
  })`;
  return withRouter(WrappedComponent);
};

withGoTo.propTypes = {
  Component: PropTypes.element
};

export default withGoTo;
