/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Routes from "../routes";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";

import "./create.less";
import GraphQl from "../../graphQL";
import Breadcrumb from "../../components/breadcrumb";
import { haveSpecialCharactersCDH } from "../../utils/string";
import { REGIONS } from "../../utils/constants";
import SelectClassic from "../../components/SelectClassic";
import ToggleButton from "../../components/toggleButton";
import HelpOn from "../../components/helpOn";

const Log = Logger("PlaygroundCreate");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  errorSubmit: boolean,
  isCreating: boolean,
  autoRefreshStages: boolean
};

class PlaygroundCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isCreating: false,
      autoRefreshStages: false
    };
  }

  save = (values, setSubmitting) => {
    this.setState({ isCreating: true });
    const accountUri = this.props.match.params.uriAccount;
    const input = { ...values };
    input.resource_name = values.name;
    input.auto_refresh_stages = this.state.autoRefreshStages;
    return this.props.api.playground
      .create(accountUri, input)
      .then((playground) => {
        this.props.showGlobalNotification({
          message: "Environment created",
          type: "success"
        });
        setSubmitting(false);
        this.setState({ isCreating: false });
        return this.props.goTo({
          route: Routes.Playground.View,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriPlayground: playground.uri
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isCreating: false });
        this.props.showGlobalNotification({
          message: "Environment creation failed",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({
          errorSubmit
        });
      });
  };

  handleChange = () => {
    this.setState((prevState) => ({
      autoRefreshStages: !prevState.autoRefreshStages
    }));
  };

  render() {
    return (
      <div className="playgrounds-create">
        <div className="title">
          <Breadcrumb view={"Create a new environment"} />
        </div>
        <div className="form-container">
          <Formik
            initialValues={{
              name: "",
              aws: "",
              environment: "Prod",
              default_region: REGIONS[0].key
            }}
            validate={(values) => {
              Log.info("validate", values);
              const errors = {};
              if (!values.environment) {
                errors.environment = "This field is mandatory";
              }

              if (!values.aws) {
                errors.aws = "This field is mandatory";
              }
              if (values.aws && isNaN(values.aws)) {
                // eslint-disable-line no-restricted-globals
                errors.aws = "The value is not a number.";
              }

              if (!values.name) {
                errors.name = "This field is mandatory";
              }
              if (haveSpecialCharactersCDH(values.name))
                errors.name =
                  "Name must not contain these special characters ( & / ' ; )";
              // @TODO : do field validation
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              Log.info("submit !", values);
              this.save(values, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {this.state.errorSubmit && (
                  <Error
                    error={this.state.errorSubmit}
                    path={"PlaygroundCreate"}
                    rawErrorMessage
                    stringOnly
                  />
                )}
                <fieldset className="form-group">
                  <legend className="label-form">name</legend>
                  <Field
                    type="name"
                    name="name"
                    className="form-control bg-white"
                    placeholder="Environment name..."
                    required
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">description</legend>
                  <Field
                    component="textarea"
                    name="description"
                    className="form-control bg-white"
                    placeholder="Description..."
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Environment</legend>
                  <Field
                    component="select"
                    name="environment"
                    className="form-control bg-white"
                    required
                  >
                    <option value="Prod">Prod</option>
                    <option value="NoProd">NoProd</option>
                  </Field>
                  <ErrorMessage
                    name="environment"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">AWS Account Id</legend>
                  <div className={"alert alert-light"}>
                    <i className="fas fa-exclamation-circle" />
                    &nbsp;Requirements:
                    <div>
                      You need to add AWS IAM Role on your AWS account
                      called&nbsp;
                      <span className={"font-weight-bold"}>
                        powerupmanagement
                      </span>
                      .
                      <div>
                        <a
                          href="https://confluence.tools.digital.engie.com/pages/viewpage.action?pageId=103191880"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info on Helpcenter
                        </a>
                      </div>
                    </div>
                  </div>
                  <Field
                    type="text"
                    name="aws"
                    className="form-control bg-white"
                    placeholder="ex: 123456789101"
                    required
                  />
                  <ErrorMessage
                    name="aws"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Default Region</legend>
                  <Field
                    component={({ form, field }) => (
                      <SelectClassic
                        placeholder="Select a region"
                        options={REGIONS.map((region) => ({
                          value: region.key,
                          label: `(${region.key})  ${region.label}`
                        }))}
                        selectedOption={{
                          value: field.value,
                          label: field.value
                        }}
                        onSelectOption={(option) =>
                          form.setFieldValue(field.name, option.value)
                        }
                      />
                    )}
                    name="default_region"
                    required
                  />
                  <ErrorMessage
                    name="default_region"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label className="label-form">
                    Enable Auto Refresh Stages
                  </label>
                  <div className="auto-refresh-stages">
                    <div className="d-flex">
                      <ToggleButton
                        id="auto-refresh-stages-id"
                        checkedValue={!!this.state.autoRefreshStages}
                        onChange={() => this.handleChange()}
                      />
                      <div>
                        <HelpOn
                          content={
                            <div>
                              <h3>{"Auto Refresh Stages"}</h3>
                              <div>
                                <b>{"Activated:"}</b>
                              </div>
                              <div>
                                {
                                  "Will allow CDH to discover new stages added to datasets in this "
                                }
                              </div>
                              <div>
                                {"environment and display them in the catalog."}
                              </div>
                              <div>{""}</div>
                              <div>
                                <b>{"Deactivated:"}</b>
                              </div>
                              <div>
                                {
                                  "CHD will only display stages created through the platform."
                                }
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn"
                  >
                    {this.state.isCreating && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Create Environment
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(PlaygroundCreate)));
