/* @flow */
/* eslint-disable camelcase */

import React from "react";
import FilterPolicy from "./filterPolicy";
import "../style.less";

type propTypes = {
  getPolicies: Function,
  policies: Array<Object>
};

type stateTypes = {
  search: Object,
  isFetchingPolicies: boolean
};

class FiltersPolicies extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      search: {
        group_uri: undefined,
        platform_uri: undefined,
        policyarn: undefined,
        description: undefined
      },
      isFetchingPolicies: false
    };
  }

  render() {
    const gs = this.props.policies.map((p) => p.group);
    const es = this.props.policies.map((p) => p.environment);
    const ps = this.props.policies.map((p) => p.policyarn);
    const uniqueItemUri = (a) => [
      ...new Map(a.map((item) => [item.uri, item])).values()
    ];
    const uniqueItemString = (a) => [
      ...new Map(a.map((item) => [item, item])).values()
    ];
    const groups = uniqueItemUri(gs);
    const environements = uniqueItemUri(es);
    const policyArns = uniqueItemString(ps);

    return (
      <React.Fragment>
        <tr className="table-row-filters">
          <th className="table-th-filters">
            <FilterPolicy
              onChangeTerm={(group_uri) =>
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.group_uri = group_uri;
                  return { search };
                })
              }
              runSearch={() => this.props.getPolicies(this.state.search)}
              onClear={() => {
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.group_uri = "";
                  return { search };
                });
                this.props.getPolicies(this.state.search);
              }}
              term={this.state.search.group_uri}
              isLoading={this.state.isFetchingPolicies}
              suggestions={groups}
              placeholder="Select group"
            />
          </th>
          <th className="table-th-filters">
            <FilterPolicy
              onChangeTerm={(platform_uri) =>
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.platform_uri = platform_uri;
                  return { search };
                })
              }
              runSearch={() => this.props.getPolicies(this.state.search)}
              onClear={() => {
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.platform_uri = "";
                  return { search };
                });
                this.props.getPolicies(this.state.search);
              }}
              term={this.state.search.platform_uri}
              isLoading={this.state.isFetchingPolicies}
              suggestions={environements}
              placeholder="Select environment"
            />
          </th>
          <th className="table-th-filters">
            <FilterPolicy
              onChangeTerm={(policyarn) =>
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.policyarn = policyarn;
                  return { search };
                })
              }
              runSearch={() => this.props.getPolicies(this.state.search)}
              onClear={() => {
                this.setState((prevState) => {
                  const { search } = prevState;
                  search.policyarn = "";
                  return { search };
                });
                this.props.getPolicies(this.state.search);
              }}
              term={this.state.search.policyarn}
              isLoading={this.state.isFetchingPolicies}
              suggestions={policyArns}
              placeholder="Select policy arn"
            />
          </th>
        </tr>
      </React.Fragment>
    );
  }
}

export default FiltersPolicies;
