import React from "react";
import withAppSync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import config from "../../../config";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import Tabs from "../../../components/tabs";
import DatabricksWorkspaceDetailsTab from "./tabs/databricks-workspace-details-tab";
import DatabricksWorkspaceDetailsGroupsTab from "./tabs/databricks-workspace-details-groups-tab";
import { useDatabricksWorkspaceDetails } from "./hooks/use-databricks-workspace-details";
import DatabricksWorkspaceDetailsInviteGroupsTab from "./tabs/databricks-workspace-details-invite-groups-tab";
const Log = Logger("DatabricksWorkspaceDetailsView");

const databricksWorkspaceDetails = ({ api, match }) => {
  const {
    error,
    groupOffset,
    isLoading,
    projectOffset,
    retrieveWorkspaceDetails,
    unregisterWorkspace,
    updateGroups,
    updateProjects,
    workspace
  } = useDatabricksWorkspaceDetails(api, match);

  return error !== null ? (
    <Error error={error} path="Databricks workspaces" />
  ) : isLoading || workspace === null ? (
    <Loading message="Databricks instances" />
  ) : (
    <div className="playground-view">
      <div className="header">
        <div className="title-line">
          <Breadcrumb
            view={`${workspace.dbx_workspace_name || ""} - workspace Workspace`}
            helpOn={{
              content: (
                <div>
                  <div>- workspace Workspace</div>
                </div>
              ),
              helpCenterLink: `${config.HELP_CENTER}`
            }}
          />
        </div>
      </div>
      <Tabs
        {...{
          isPreload: false,
          defaultActiveIndex: "users",
          tabs: [
            {
              name: "Details",
              index: "details",
              route: `${match.url}#details`,
              content: (
                <DatabricksWorkspaceDetailsTab
                  {...{ projectOffset, updateProjects, workspace }}
                />
              )
            },
            {
              name: "Manage Groups",
              index: "groups",
              route: `${match.url}#groups`,
              content: (
                <DatabricksWorkspaceDetailsGroupsTab
                  {...{
                    dbx_groups: workspace.dbx_groups,
                    error,
                    groupOffset,
                    isLoading,
                    unregisterWorkspace: unregisterWorkspace,
                    updateGroups
                  }}
                />
              )
            },
            {
              name: "Invite Group",
              index: "invitegroup",
              route: `${match.url}#invitegroups`,
              content: (
                <DatabricksWorkspaceDetailsInviteGroupsTab
                  {...{
                    api,
                    error,
                    isLoading,
                    reloadWorkspace: retrieveWorkspaceDetails,
                    workspace
                  }}
                />
              )
            }
          ]
        }}
      />
    </div>
  );
};

export default withAppSync(databricksWorkspaceDetails);
