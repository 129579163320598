/* @flow */
import React from "react";
import { connect } from "react-redux";
import ButtonAction from "../../../components/buttonAction";
import Modal from "../../../components/modal";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";

const Log = Logger("RemoveAllShareItemsModal");

type propTypes = {
  api: GraphQl,
  share: Object,
  onRemove: Function,
  onClose: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error?: Object
};

class RemoveAllShareItemsModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  submitRemoveAll = () => {
    this.setState({ error: undefined });
    this.props.api.share
      .removeAllDatasetShareItems(
        this.props.share.dataset.uri,
        this.props.share.group.uri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "All Share Items Removed",
          type: "success"
        });
        this.props.onRemove();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error });
      });
  };

  modalTitle = () => (
    <div>
      Remove all shared items for dataset: {this.props.share.dataset.name}
    </div>
  );

  render() {
    return (
      <Modal
        title={this.modalTitle()}
        body={!this.state.error && "Confirm action ?"}
        errorMessage={this.state.error}
        actions={[
          !this.state.error && (
            <ButtonAction
              key={`remove-${this.props.share.id}`}
              label="Remove"
              className="butn butn-delete"
              onClick={this.submitRemoveAll}
            />
          ),
          <button
            key={`close-${this.props.share.id}`}
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(RemoveAllShareItemsModal));
