/* @flow */
import React from "react";
import "./view.less";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import DatasetCard from "../../datasets/card/DatasetCard";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Paginator from "../../../components/paginator";
import Logger from "../../../utils/logger";

const Log = Logger("CartDatasets");

type propTypes = {
  errorDeleteDataset: Object,
  loadingDeleteDataset: boolean,
  cart: Object,
  actionsDataset: Function,
  setNbDataset: Function,
  api: GraphQl
};

type stateTypes = {
  isFetching: boolean,
  isSearch: boolean,
  term: string,
  datasets: Array<Object>,
  error: Object,
  ready: boolean,
  totalDatasets: number
};

class CartDatasets extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      error: this.props.errorDeleteDataset,
      ready: false,
      isFetching: false,
      isSearch: false,
      term: "",
      totalDatasets: 0
    };
  }

  componentDidMount() {
    this.getDatasets();
  }

  getDatasets = (offset = 0) => {
    this.setState({ isFetching: true, isSearch: true });
    const options = {
      limit: 10,
      offset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.cart
      .getDatasets(this.props.cart.uri, options)
      .then((datasets) => {
        if (!datasets) return false;
        this.props.setNbDataset(datasets.total);
        this.setState({
          datasets: datasets.results,
          totalDatasets: datasets.total,
          isFetching: false,
          ready: true
        });
        return datasets;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, isFetching: false });
      });
  };

  onChangeInput = (e) => {
    const term = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          term
        },
        this.getDatasets
      );
    }, 300);
  };

  render() {
    return (
      <div className="card-container">
        {this.state.isSearch ? (
          <div className="search-datasource-datasets">
            <div className="search-input">
              <input
                className="form-control"
                placeholder="Search"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.error && (
          <Error error={this.state.error} path={"CartView"} stringOnly />
        )}
        {this.props.loadingDeleteDataset && (
          <Loading message={"wait during deletion of dataset from project"} />
        )}
        {this.state.isFetching && <Loading message="Dataset List" />}
        {this.state.ready && (
          <Paginator
            limit={10}
            initialOffset={0}
            totalCount={this.state.totalDatasets}
            list={this.state.datasets}
            componentRender={(dataset) => (
              <DatasetCard
                key={dataset.uri}
                dataset={{
                  uri: dataset.uri,
                  resourceName: dataset.resource_name,
                  arn: dataset.arn,
                  datasourceRegion: dataset.datasource.region,
                  name: dataset.name,
                  description: dataset.description,
                  type: dataset.type,
                  dataSourceBucket: dataset.datasource.bucket,
                  createdat: dataset.createdat,
                  datasourceOwner: dataset.datasource.owner,
                  ...dataset
                }}
                removeFromProject={this.props.actionsDataset}
                project={this.props.cart}
              />
            )}
            loadPage={this.getDatasets}
            onNoResult={() => (
              <p className="total-search-results">No dataset found</p>
            )}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(CartDatasets);
