import React from "react";
import PropTypes from "prop-types";
import Error from "../../components/error";
import Logger from "../../utils/logger";

const Log = Logger("withErrorBoundary");

const withErrorBoundary = (ComposedComponent, isVisible = true) => {
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      return { hasError: error };
    }

    componentDidCatch(error, info) {
      // Call an error reporting service
      Log.error("ErrorBoundary, catched error: ", error, info);
    }

    render() {
      if (this.state.hasError) {
        if (isVisible) {
          return (
            <Error
              title={"Something went wrong"}
              error={this.state.hasError}
              path={"withErrorBoundary"}
            />
          );
        }
        Log.error("withErrorBoundary meHOC");
        return false;
      }

      return <ComposedComponent {...this.props} />;
    }
  }

  ErrorBoundary.propTypes = {
    children: PropTypes.object
  };

  return ErrorBoundary;
};

export default withErrorBoundary;
