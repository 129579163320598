/* @flow */
import React from "react";
import { Field, Form, Formik } from "formik";
import Logger from "../../../../utils/logger";
import {
  isRSSourceType,
  isS3SourceType,
  REGIONS,
  RS_BASE_CAPACITIES,
  SOURCE_TYPES
} from "../../../../utils/constants";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import { haveSpecialCharactersCDH } from "../../../../utils/string";
import HelpOn from "../../../../components/helpOn";
import TagsDatasourceFields from "./TagsDatasourceFields";
import SelectInfinite from "../../../../components/SelectInfinite";
import SelectClassic from "../../../../components/SelectClassic";
import withGoTo from "../../../goToHOC";
import routes from "../../../routes";
import "./style.less";
import { Alert } from "@material-ui/lab";
import ToggleButton from "../../../../components/toggleButton";

const Log = Logger("DatasourceForm");

const validationName = (name = "") => {
  if (!name || name.trim().length < 1) {
    return { isValid: false, message: "Can't be empty." };
  }
  const maxSize = 44;
  if (name.length > maxSize) {
    return {
      isValid: false,
      message: `Can't exceed ${maxSize} characters (${name.length}/${maxSize})`
    };
  }
  return { isValid: true };
};

type propTypes = {
  isNew: boolean,
  handleChange: Function,
  groups?: Array<Object>,
  loadMoreGroups?: Function,
  totalGroups?: number,
  totalGroupsForSearchLimit?: number,
  totalEnvironments?: number,
  isFetchingEnvironments?: boolean,
  isFetchingFirstGroups?: boolean,
  datasource: {
    region: string,
    platformUri: string,
    name: string,
    description: string,
    resource_name: string,
    owneruri: string,
    source_type: string,
    owner: { name: string },
    platform: {
      uri: string,
      name: string,
      aws: string
    },
    s3_custom_tags: Object,
    bucket_owner_enforced: boolean,
    sharingManagementGroupUri: string,
    sharingManagementGroup: { uri: string, name: string },
    network: Object,
    workgroup_base_capacity: Object,
    vpc_id: Object,
    subnet_ids: Array<Object>,
    security_group_ids: Array<Object>,
    namespace: Object,
    useNamespace: boolean
  },
  group: {
    uri: string,
    account: {
      uri: string
    },
    platforms: Array<{
      uri: string,
      name: string,
      aws: string
    }>
  },
  api: GraphQl,
  submit: Function,
  environmentSelected: {
    uri: string,
    name: string,
    aws: string,
    default_region: string,
    rsNamespaces: Object
  },
  match: {
    params: {
      uriPlayground?: string,
      uriAccount: string,
      uriDatasource: string
    }
  },
  goTo: Function
};

type stateTypes = {
  sharingManagementGroups: Array<Object>,
  sharingManagementGroupsTotal: number,
  sharingManagementGroupsTotalForSearchLimit: number,
  sharingManagementGroup?: Object,
  isFetchingSharingManagementGroups: boolean,
  isFetchingNetworks: boolean,
  networks: Array<Object>,
  selectedNetwork: Object,
  totalNetworks: number,
  isFetchingNamespaces: boolean,
  namespaces: Array<Object>,
  selectedNamespace: Object,
  totalNamespaces: number
};

class DatasourceForm extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    isNew: false,
    datasource: {},
    groups: [],
    group: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      sharingManagementGroups: [],
      sharingManagementGroupsTotal: 0,
      sharingManagementGroupsTotalForSearchLimit: 0,
      isFetchingSharingManagementGroups: false,
      isFetchingNetworks: false,
      networks: [],
      selectedNetwork: null,
      totalNetworks: 0,
      isFetchingNamespaces: false,
      namespaces: [],
      selectedNamespace: null,
      totalNamespaces: 0
    };
  }

  componentDidMount() {
    if (this.props.group && this.props.group.account) {
      this.setState({
        isFetchingSharingManagementGroups: true,
        sharingManagementGroup: this.props.datasource.sharingManagementGroup,
        isFetchingNetworks: true,
        isFetchingNamespaces: true
      });
      this.getSharingManagementGroups(this.props.group.account.uri);
      this.getSharingManagementGroupsTotal(this.props.group.account.uri);
      if (this.props.isNew) {
        this.getNetworkList();
        this.getRSNamespaces();
      }
    }
  }

  getSharingManagementGroupsTotal = (accountUri) =>
    this.props.api.account
      .listGroupsForSharingGroup(accountUri)
      .then((sharingManagementGroups) => {
        this.setState({
          sharingManagementGroupsTotalForSearchLimit:
            sharingManagementGroups.total
        });
        return sharingManagementGroups;
      });

  getSharingManagementGroups = (accountUri, search, offset, reset = false) => {
    if (search && search.length < 3) {
      return false;
    }
    let queryOptions = {};
    if (search) {
      reset = true;
      queryOptions = {
        offset: 0,
        limit: this.state.sharingManagementGroupsTotalForSearchLimit,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset,
        limit: 10
      };
    }
    return this.props.api.account
      .listGroupsForSharingGroup(accountUri, queryOptions)
      .then((sharingManagementGroups) => {
        if (sharingManagementGroups) {
          this.setState((prevState) => ({
            sharingManagementGroups: reset
              ? sharingManagementGroups.results
              : prevState.sharingManagementGroups.concat(
                  sharingManagementGroups.results
                ),
            sharingManagementGroupsTotal: sharingManagementGroups.total,
            isFetchingSharingManagementGroups: false
          }));
          return sharingManagementGroups.results.map((g) => ({
            value: g.uri,
            label: g.name
          }));
        }
        return [];
      })
      .catch(() => {
        this.setState({
          isFetchingSharingManagementGroups: false
        });
      });
  };

  loadMoreManagerGroups = (offset, search) =>
    this.getSharingManagementGroups(
      this.props.group.account.uri,
      search,
      offset
    );

  getPlaceholderEnvironmentSelect = () => {
    if (this.props.match.params.uriPlayground) {
      return `${this.props.environmentSelected.name} (${this.props.environmentSelected.aws})`;
    }
    if (!this.props.group.uri) {
      return "Select a group before choosing an environment";
    }
    if (this.props.isFetchingEnvironments) return "Loading...";
    if (this.props.group.platforms && this.props.group.platforms.length <= 0) {
      return "This group is not attached to any environment";
    }
    return "Select an environment";
  };

  isDisabledEnvironmentSelect = () => {
    if (this.props.match.params.uriPlayground) return true;
    if (!this.props.group.uri) return true;
    if (this.props.isFetchingEnvironments) return true;
    return this.props.group.platforms && this.props.group.platforms.length <= 0;
  };

  getNetworkList = (uri) => {
    const platformUri =
      uri ||
      this.props.datasource.platformUri ||
      this.props.environmentSelected.uri;
    if (platformUri) {
      this.props.api.playground
        .listNetworks(platformUri)
        .then((response) => {
          if (response) {
            const rsNetworks = response.results.filter(
              (d) => d.network_type === "REDSHIFT_SERVERLESS"
            );
            this.setState({
              networks: rsNetworks,
              totalNetworks: response.total,
              selectedNetwork: rsNetworks[0],
              isFetchingNetworks: false
            });
            this.props.handleChange("network", response.results[0].id);
            this.props.datasource.network = response.results[0].id;
            return response.results.map((g) => ({
              value: g.uri,
              label: g.name
            }));
          }
          return [];
        })
        .catch(() => {
          this.setState({
            isFetchingNetworks: false
          });
        });
    }
  };

  getRSNamespaces = (uri) => {
    const platformUri =
      uri ||
      this.props.datasource.platformUri ||
      this.props.environmentSelected.uri;
    if (platformUri) {
      this.props.api.analytics
        .listMyEnvironmentRSNamespaces(platformUri, { offset: 0, limit: 1000 })
        .then((response) => {
          if (response) {
            const rsNamespaces = response.results.filter(
              (d) => d.region === this.props.datasource.region
            );
            this.setState({
              namespaces: rsNamespaces,
              totalNamespaces: response.total,
              selectedNamespace: rsNamespaces[0],
              isFetchingNamespaces: false
            });
            this.props.handleChange("namespace", response.results[0].uri);
            this.props.datasource.namespace = response.results[0].uri;
            return rsNamespaces.map((g) => ({
              value: g.uri,
              label: g.name
            }));
          }
          return [];
        })
        .catch(() => {
          this.setState({
            isFetchingNamespaces: false
          });
        });
    }
  };

  handleUseNamespaceChange(field, value) {
    this.props.handleChange("useNamespace", !!value);
  }

  render() {
    const { isNew, datasource, group, handleChange, submit } = this.props;

    return (
      <Formik
        initialValues={{}}
        validate={() => {
          Log.info("validate", datasource);
          const errors = {};

          const nameValidation = validationName(datasource.name);
          if (haveSpecialCharactersCDH(datasource.name)) {
            errors.name =
              "Name must not contain these special characters ( & / ' ; )";
          }
          if (!nameValidation.isValid) errors.name = nameValidation.message;

          if (!datasource.owneruri) errors.group = "Group is mandatory ";
          if (!datasource.sharingManagementGroupUri) {
            errors.sharingManagementGroupUri = "Group Sharing is mandatory ";
          }
          if (!datasource.platformUri) {
            errors.platformUri = "Environment is mandatory ";
          }

          if (isRSSourceType(datasource.source_type)) {
            if (datasource.useNamespace && !datasource.namespace) {
              errors.namespace = "Please review your namespace choice ";
            }
            if (!datasource.useNamespace && !datasource.network) {
              errors.network = "Please review your networking settings ";
            }
            if (
              !datasource.useNamespace &&
              !datasource.workgroup_base_capacity
            ) {
              errors.workgroup_base_capacity =
                "Please review your RPUs base capacity ";
            }
          }

          if (datasource.s3_custom_tags) {
            datasource.s3_custom_tags.map((tag) => {
              if (tag.Key.length > 128 || tag.Value.length > 256) {
                errors.s3_custom_tags =
                  "The tag key must not exceed 128 unicode characters and the tag value must not exceed 256 characters ";
              }
              const patternTags = /^[\w.:/=+\-@]*$/;
              const testKey = patternTags.test(tag.Key);
              if (!testKey) {
                errors.s3_custom_tags_key_unicode = `The key (${tag.Key}) contains illegal characters. Non-unicode characters are not allowed`;
              }

              const testValue = patternTags.test(tag.Value);
              if (!testValue) {
                errors.s3_custom_tags_value_unicode = `The value (${tag.Value}) contains illegal characters. Non-unicode characters are not allowed`;
              }

              return errors;
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) =>
          submit().then(() => setSubmitting(false))
        }
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="mb-3">
              <div>
                <label className="label-form">Owner Group</label>
                {isNew ? (
                  <SelectInfinite
                    key={"select-infinite-datasource-groups"}
                    isDisabled={this.props.totalGroupsForSearchLimit === 0}
                    placeholder={
                      (this.props.groups || []).length > 0
                        ? "Select a group"
                        : "0 group found"
                    }
                    totalOptions={this.props.totalGroups || 0}
                    isSearchable
                    loadMoreOptions={this.props.loadMoreGroups}
                    onSelectOption={(value) => {
                      const groupSM = (this.props.groups || []).find(
                        (g) => g.uri === value
                      );
                      handleChange("owneruri", value);
                      handleChange("sharingManagementGroupUri", value);
                      handleChange("namespace", null);
                      if (groupSM) {
                        this.setState({
                          sharingManagementGroup: groupSM,
                          isFetchingSharingManagementGroups: true
                        });
                        this.getSharingManagementGroupsTotal(
                          groupSM.account.uri
                        );
                        this.getSharingManagementGroups(
                          groupSM.account.uri,
                          null,
                          0,
                          true
                        );
                      }
                    }}
                    initialOptions={(this.props.groups || []).map((g) => ({
                      value: g.uri,
                      label: g.name
                    }))}
                    isLoadingOptions={this.props.isFetchingFirstGroups}
                  />
                ) : (
                  <div className="datasource-owner-field">
                    <div className="datasource-owner">
                      <SelectClassic
                        placeholder={datasource.owner.name}
                        isDisabled
                      />
                    </div>
                    <div className="datasource-tranfer-ownership">
                      <button
                        className="butn butn-flat"
                        type="button"
                        onClick={() =>
                          this.props.goTo({
                            route: routes.Datasource.Transfer,
                            params: {
                              uriAccount: this.props.match.params.uriAccount,
                              uriDatasource:
                                this.props.match.params.uriDatasource
                            },
                            state: { datasource }
                          })
                        }
                      >
                        <i className={"fas fa-sync-alt fa-spacing"} />
                        Change
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {errors.group && (
                <div style={{ color: "red" }}>{errors.group}</div>
              )}
            </div>

            <div className="mb-3">
              <div className="sharing-management-group">
                <div className="label-form">Sharing Management Group</div>
                <HelpOn
                  content={
                    <div>
                      <h3>Sharing Group Management</h3>
                      <div>
                        If Owner Group and Sharing Group Management are
                        different, owner should belong to the Sharing Group in
                        order to keep the sharing management right.
                      </div>
                    </div>
                  }
                />
              </div>
              <SelectInfinite
                key={"select-infinite-datasource-platform"}
                isLoadingOptions={this.state.isFetchingSharingManagementGroups}
                placeholder={
                  datasource.owneruri
                    ? "Select a Sharing Management Group"
                    : "Select an Owner Group before"
                }
                isDisabled={!datasource.owneruri}
                isSearchable
                selectedOption={
                  this.state.sharingManagementGroup &&
                  this.state.sharingManagementGroup.uri
                    ? {
                        value: this.state.sharingManagementGroup.uri,
                        label: this.state.sharingManagementGroup.name
                      }
                    : undefined
                }
                totalOptions={this.state.sharingManagementGroupsTotal}
                initialOptions={
                  this.state.sharingManagementGroups
                    ? this.state.sharingManagementGroups.map((g) => ({
                        value: g.uri,
                        label: g.name
                      }))
                    : []
                }
                loadMoreOptions={this.loadMoreManagerGroups}
                onSelectOption={(value) => {
                  this.setState((prevState) => ({
                    sharingManagementGroup:
                      prevState.sharingManagementGroups.find(
                        (g) => g.uri === value
                      )
                  }));
                  handleChange("sharingManagementGroupUri", value);
                }}
              />

              {errors.sharingManagementGroupUri && (
                <div style={{ color: "red" }}>
                  {errors.sharingManagementGroupUri}
                </div>
              )}
            </div>

            <div className="mb-3">
              <label className="label-form">Environment</label>
              {isNew ? (
                <div>
                  <SelectInfinite
                    key={"select-infinite-datasource-platform"}
                    isLoadingOptions={
                      this.props.isFetchingEnvironments &&
                      !this.props.match.params.uriPlayground
                    }
                    placeholder={this.getPlaceholderEnvironmentSelect()}
                    isDisabled={this.isDisabledEnvironmentSelect()}
                    selectedOption={
                      this.props.environmentSelected &&
                      this.props.environmentSelected.uri
                        ? {
                            value: this.props.environmentSelected.uri,
                            label: `${this.props.environmentSelected.name} (${this.props.environmentSelected.aws})`
                          }
                        : undefined
                    }
                    totalOptions={this.props.totalEnvironments || 0}
                    initialOptions={
                      group && group.platforms
                        ? group.platforms.map((p) => ({
                            value: p.uri,
                            label: `${p.name} (${p.aws})`
                          }))
                        : []
                    }
                    loadMoreOptions={false}
                    onSelectOption={(value) => {
                      this.setState({ isFetchingNetworks: true });
                      this.getNetworkList(value);
                      handleChange("namespace", null);
                      handleChange("platformUri", value);
                    }}
                  />
                </div>
              ) : (
                <SelectClassic
                  placeholder={datasource.platform.name}
                  isDisabled
                />
              )}

              {errors.platformUri && (
                <div style={{ color: "red" }}>{errors.platformUri}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="label-form">AWS Region</label>
              {isNew ? (
                <SelectClassic
                  placeholder="select a region"
                  options={REGIONS.map((region) => ({
                    value: region.key,
                    label: `(${region.key})  ${region.label}`
                  }))}
                  selectedOption={{
                    value: datasource.region,
                    label: datasource.region
                  }}
                  onSelectOption={(option) =>
                    handleChange("region", option.value)
                  }
                />
              ) : (
                <SelectClassic placeholder={datasource.region} isDisabled />
              )}
            </div>

            <fieldset className="form-group  mb-3">
              <legend className="label-form">Name</legend>
              <Field
                onChange={(event) => handleChange("name", event.target.value)}
                value={datasource.name}
                type="name"
                name="name"
                className="form-control bg-white"
                placeholder="datasource name..."
              />
              <div className="input-group mt-2">
                <div className="input-group-prepend">
                  <label className="input-group-text">Resource name</label>
                </div>
                <input
                  className="form-control"
                  disabled
                  value={datasource.resource_name}
                />
              </div>
              {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
            </fieldset>
            <fieldset className="form-group mb-3">
              <legend className="label-form">Description</legend>
              <Field
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
                value={datasource.description}
                type="description"
                name="description"
                className="form-control bg-white"
                placeholder="datasource description..."
              />
              {errors.description && (
                <div style={{ color: "red" }}>{errors.description}</div>
              )}
            </fieldset>

            <div className="mb-3">
              <label className="label-form">Source Type</label>
              {isNew ? (
                <SelectClassic
                  placeholder="select a source type"
                  options={SOURCE_TYPES.map((src) => ({
                    value: src.key,
                    label: src.label
                  }))}
                  selectedOption={{
                    value: datasource.source_type,
                    label: SOURCE_TYPES.find(
                      (x) => x.key === datasource.source_type
                    ).label
                  }}
                  onSelectOption={(option) => {
                    handleChange("source_type", option.value);
                    if (option.value !== "S3") {
                      datasource.s3_custom_tags = null;
                    }
                  }}
                />
              ) : (
                <SelectClassic
                  placeholder={datasource.source_type}
                  isDisabled
                />
              )}
            </div>
            {isS3SourceType(datasource.source_type) && (
              <fieldset className="form-group mb-3">
                <legend className="label-form">S3 Tags</legend>
                <TagsDatasourceFields
                  tags={datasource.s3_custom_tags}
                  onChangeTags={(field, value) => handleChange(field, value)}
                />
                {errors.s3_custom_tags && (
                  <div style={{ color: "red" }}>{errors.s3_custom_tags}</div>
                )}
                {errors.s3_custom_tags_key_unicode && (
                  <div style={{ color: "red" }}>
                    {errors.s3_custom_tags_key_unicode}
                  </div>
                )}
                {errors.s3_custom_tags_value_unicode && (
                  <div style={{ color: "red" }}>
                    {errors.s3_custom_tags_value_unicode}
                  </div>
                )}
              </fieldset>
            )}
            <div>
              <fieldset className="form-group mb-3">
                <legend className="label-form">Object Ownership</legend>
                <div className="d-flex">
                  <b>Default (ACL enabled) Bucket Owner Preferred&nbsp;</b>
                  <ToggleButton
                    id="bucketownerenforcedid"
                    checkedValue={!!datasource.bucket_owner_enforced}
                    onChange={() =>
                      handleChange(
                        "bucket_owner_enforced",
                        !datasource.bucket_owner_enforced
                      )
                    }
                  />
                  <b>&nbsp;Bucket Owner Enforced (ACL disabled)</b>
                </div>
              </fieldset>
            </div>

            {isRSSourceType(datasource.source_type) && (
              <div>
                {isNew && datasource.useNamespace && (
                  <div className="mb-3">
                    <label className="label-form">
                      Redshift Serverless Namespace
                    </label>
                    <div>
                      <SelectClassic
                        placeholder="Select a namespace"
                        options={
                          this.props.environmentSelected &&
                          this.props.environmentSelected.rsNamespaces &&
                          this.props.environmentSelected.rsNamespaces.results
                            ? this.props.environmentSelected.rsNamespaces.results
                                .filter((ns) => ns.region === datasource.region)
                                .map((ns) => ({
                                  value: ns,
                                  label: ns.name
                                }))
                            : []
                        }
                        selectedOption={
                          datasource.namespace
                            ? {
                                value: datasource.namespace.uri,
                                label: datasource.namespace.name
                              }
                            : { label: "Select a namespace", value: "" }
                        }
                        onSelectOption={(option) => {
                          handleChange("namespace", option.value);
                        }}
                      />
                      {(!this.props.environmentSelected ||
                        !this.props.environmentSelected.rsNamespaces ||
                        this.props.environmentSelected.rsNamespaces.results
                          .length === 0) && (
                        <div className={"mt-2 mb-1"}>
                          <Alert severity={"error"}>
                            No namespaces found, please select an environment or
                            contact your administrator to configure a Redshift
                            Serverless namespace.
                          </Alert>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isNew && !datasource.useNamespace && (
                  <div className="form-group">
                    <div className="mb-3">
                      <label className="label-form">Network</label>
                      <div>
                        <SelectInfinite
                          isLoadingOptions={this.state.isFetchingNetworks}
                          placeholder={
                            this.state.isFetchingNetworks
                              ? "Loading..."
                              : "Select a network"
                          }
                          isDisabled={!!this.state.isFetchingNetworks}
                          selectedOption={
                            this.state.selectedNetwork
                              ? {
                                  value: this.state.selectedNetwork,
                                  label: `${
                                    this.state.selectedNetwork.name
                                  } | ${
                                    this.state.selectedNetwork.vpc_id
                                  } | (${this.state.selectedNetwork.subnet_ids.join(
                                    ", "
                                  )})`
                                }
                              : undefined
                          }
                          initialOptions={
                            this.state.networks
                              ? this.state.networks.map((n) => ({
                                  value: n,
                                  label: `${n.name} | ${
                                    n.vpc_id
                                  } | (${n.subnet_ids.join(", ")})`
                                }))
                              : []
                          }
                          totalOptions={this.state.totalNetworks}
                          loadMoreOptions={this.getNetworkList}
                          onSelectOption={(value) => {
                            this.setState({ selectedNetwork: value });
                          }}
                        />
                        {((datasource.platformUri && !this.state.networks) ||
                          this.state.networks.length === 0) && (
                          <div className={"mt-2 mb-1"}>
                            <Alert severity={"info"}>
                              No networks found, please select an environment or
                              contact your administrator to configure a Redshift
                              Serverless network.
                            </Alert>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!isNew && datasource.network && (
                  <div className="form-group">
                    <div className="mb-3">
                      <label className="label-form">Network</label>
                      <SelectClassic
                        placeholder={`${datasource.network.name}`}
                        isDisabled
                      />
                    </div>
                  </div>
                )}

                {isNew && !datasource.useNamespace && (
                  <div className="mb-3">
                    <label className="label-form">RPU Base Capacity</label>
                    <div>
                      <SelectClassic
                        placeholder="Select a base capacity"
                        options={RS_BASE_CAPACITIES.map((src) => ({
                          value: src.key,
                          label: src.label
                        }))}
                        selectedOption={{
                          value: datasource.workgroup_base_capacity,
                          label: RS_BASE_CAPACITIES.find(
                            (x) => x.key === datasource.workgroup_base_capacity
                          ).label
                        }}
                        onSelectOption={(option) => {
                          handleChange("workgroup_base_capacity", option.value);
                        }}
                      />
                      <div className={"mt-2"}>
                        <Alert severity={"info"}>
                          The minimum number of required IP addresses scales
                          higher as the number of Base Redshift Processing Units
                          (RPUs) for your workgroup increases. You must have the
                          minimum number (9 per 8 RPUs) of IP addresses
                          available for each subnet in each workgroup that you
                          want to create
                        </Alert>
                      </div>
                    </div>
                  </div>
                )}
                {!isNew && datasource.workgroup_base_capacity && (
                  <div className="mb-3">
                    <label className="label-form">RPU Base Capacity</label>
                    <SelectClassic
                      placeholder={datasource.workgroup_base_capacity}
                      isDisabled
                    />
                  </div>
                )}
              </div>
            )}
            <div className={"ml-8"}>
              <button type="submit" disabled={isSubmitting} className="butn">
                {isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                {isNew ? "Create Datasource" : "Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withGoTo(withAppSync(DatasourceForm));
