export const getDatasource = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      uri
      resource_name
      name
      bucket
      description
      tags
      region
      accountid
      parent
      createdat
      aws
      user_roles
      etluser_credentials_expiration_date
      etluser_credentials_creator
      etluser_credentials_description
      is_registered_with_lakeformation
      kmsalias
      kmsid
      platform {
        uri
        name
        aws
        environment
      }
      datasets {
          uri
          accountid
          name
          resource_name
          description
          tags
          doc
          type
          confidentiality
          domain
          refresh_sla
          last_loaded
          details
          createdat
          updatedat
          period_start_at
          period_end_at
          expiration_date
          language
          default_table
          access
          arn
          source_type
          schema
          database
      }
      s3_custom_tags {
        Key
        Value
      }
      owner {
          uri
          name
      }
      sharingManagementGroup {
          uri
          name
      }
      source_type
    }
  }
`,
  keyOutput: "getDatasource"
};
