/* @flow */
import React from "react";
import { Field, Form, Formik } from "formik";
import "./view.less";
import Error from "../../../components/error";
import GraphQl from "../../../graphQL";

type propTypes = {
  provider: {
    id: string,
    arn: string,
    overwrite: boolean,
    userid: string,
    description: string,
    status: string
  },
  datasource: {
    uri: string
  },
  api: GraphQl,
  onEdit: Function,
  onClose: Function
};
type stateTypes = {
  errorEdit: Object,
  overwrite: boolean,
  roleId: string,
  disabledField: boolean,
  isFetching: boolean,
  id: string
};

class DatasourceProviderEditItem extends React.Component<
  propTypes,
  stateTypes
> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      errorEdit: undefined,
      overwrite: this.props.provider.overwrite,
      roleId: this.props.provider.userid,
      isFetching: false,
      disabledField: false,
      id: Math.random().toString()
    };
  }

  editProvider = (provider: Object = {}) =>
    this.props.api.datasource
      .editProvider(this.props.datasource.uri, {
        id: provider.id,
        arn: provider.arn,
        overwrite: this.state.overwrite,
        userid: this.state.roleId,
        description: provider.description,
        status: provider.status
      })
      .then((providers) => {
        this.props.onEdit(providers);
      })
      .catch((errorEdit) => {
        this.setState({
          errorEdit
        });
      });

  handleChange(value: Object) {
    return this.setState({ overwrite: !!value });
  }

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getRoleId(keywords);
    }, 500);
  };

  onChange = (e: Object) => {
    const keywords = e.target.value;
    this.setState({ roleId: keywords });
  };

  getRoleId = (keywords: string) => {
    this.setState({ roleId: "", disabledField: false, isFetching: true });
    this.props.api.datasource
      .getRoleIdFromRoleArn(keywords)
      .then((result) => {
        if (result !== "null") {
          this.setState({ roleId: result, disabledField: true });
        }
        this.setState({ isFetching: false });
        return result;
      })
      .catch((errorEdit) => {
        this.setState({
          errorEdit
        });
      });
  };

  render() {
    const { provider, onClose } = this.props;
    return (
      <div className={"datasource-providers-form"}>
        {this.state.errorEdit && (
          <Error
            error={this.state.errorEdit}
            path={"DatasourceProviderEditItem"}
            stringOnly
          />
        )}
        <Formik
          initialValues={provider}
          validate={() => {
            const errors = {};

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) =>
            this.editProvider(values).then(() => setSubmitting(false))
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <h4 className="title text-primary viewName">Edit loader</h4>
              <div className="form-group">
                <label className="label-form">ARN</label>
                <Field
                  name="arn"
                  className="form-control form-control-sm"
                  placeholder=" Arn"
                  onKeyUp={this.onChangeInput}
                />
              </div>

              <div className="form-group">
                <div className="facet-criteria-name">
                  <input
                    id={`overwrite-${this.state.id}`}
                    type="checkbox"
                    className={"checkbox-facet"}
                    checked={this.state.overwrite}
                    onChange={(event) =>
                      this.handleChange(event.target.checked)
                    }
                  />
                  <label htmlFor={`overwrite-${this.state.id}`} className="fas">
                    <small className="attribute-label">Allow Overwrite</small>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="label-form">RoleID</label>
                <div className="content-field">
                  <Field
                    name="userid"
                    className="form-control form-control-sm"
                    placeholder=" RoleID"
                    value={this.state.roleId}
                    disabled={this.state.disabledField}
                    onChange={this.onChange}
                  />
                  {this.state.isFetching && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="label-form">Description</label>
                <Field
                  name="description"
                  component="textarea"
                  className="form-control form-control-sm"
                  placeholder=" Write a description..."
                />
              </div>

              <div className="actions mt-3 mb-3">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="butn butn-full ml-2"
                >
                  {isSubmitting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  Edit
                </button>
                <button type="button" onClick={onClose} className="butn">
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DatasourceProviderEditItem;
