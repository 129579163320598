/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* @flow */

import React from "react";
import "./view.less";
import { formatDate } from "../../../utils/date";
import Links from "../../links";
import TextField from "../../../components/textField";

type propTypes = {
  datasource: Object
};

class DatasourceDetails extends React.PureComponent<propTypes> {
  render() {
    const { datasource } = this.props;
    const {
      uri,
      createdat,
      aws,
      region,
      name,
      bucket,
      description,
      owner,
      sharingManagementGroup,
      is_registered_with_lakeformation,
      s3_custom_tags,
      kmsalias,
      kmsid
    } = datasource;

    return (
      <div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fas fa-link dataset-detail-icon"></i>
            URI
          </div>
          <TextField text={`${uri}`} />
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-inbox datasource-detail-icon" />
            S3 Bucket
          </div>
          <TextField text={`s3://${bucket}`} />
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-folder-open datasource-detail-icon" />
            Glue Database
          </div>
          <TextField text={`${uri.split(":")[4]}`} />
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fas fa-calendar-day cart-detail-icon" />
            Created on
          </div>
          <span>{formatDate(createdat)}</span>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-key datasource-detail-icon" />
            KMS
          </div>
          <div className="datasource-detail-kms">
            <span className="datasource-kms-title">Alias</span>
            <TextField text={`${kmsalias}`} />
          </div>
          <div className="datasource-detail-kms">
            <span className="datasource-kms-title">Id</span>
            <TextField text={`${kmsid}`} />
          </div>
        </div>
        <div className="datasource-detail-double-element">
          <div className="datasource-detail-element-left">
            <div className="datasource-detail-title">
              <i className="fab fa-aws datasource-detail-icon" />
              AWS Account
            </div>
            <span>{aws}</span>
          </div>
          <div className="datasource-detail-element-right">
            <div className="datasource-detail-title">
              <i className="fas fa-globe-europe datasource-detail-icon" />
              AWS Region
            </div>
            <span>{region}</span>
          </div>
        </div>
        <div className="datasource-detail-double-element">
          <div className="datasource-detail-element-left">
            <div className="datasource-detail-title">Owner Group</div>
            <span className="datasource-info-detail">
              <Links.Group.View uriGroup={(owner || {}).uri || "null"}>
                {(owner || {}).name || "-"}
              </Links.Group.View>
            </span>
          </div>
          {is_registered_with_lakeformation && (
            <div className="datasource-detail-element-right">
              <div className="datasource-detail-title">
                <i className="fab fa-aws datasource-detail-icon" />
                Aws lake formation
              </div>
              <span>Yes</span>
            </div>
          )}
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            Sharing Management Group
          </div>
          <span className="datasource-info-detail">
            <Links.Group.View
              uriGroup={(sharingManagementGroup || {}).uri || "null"}
            >
              {(sharingManagementGroup || {}).name || "-"}
            </Links.Group.View>
          </span>
        </div>
        {s3_custom_tags && s3_custom_tags.length > 0 && (
          <div className="datasource-detail-element">
            <div className="datasource-detail-title">
              <i className="fab fa-aws datasource-detail-icon" />
              s3 Tags
            </div>
            <div>
              <div className="content">
                <table>
                  <thead>
                    <tr>
                      <th className="datasource-kms-title">Key</th>
                      <th className="datasource-kms-title">
                        Value<br></br>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {datasource.s3_custom_tags.map((tag) => (
                      <tr>
                        <td className="TagKey">{tag.Key}</td>
                        <td className="TagValue">{tag.Value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">Description</div>
          <p>{description || "No description"}</p>
        </div>
      </div>
    );
  }
}

export default DatasourceDetails;
