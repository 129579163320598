/* @flow */
import React from "react";
import GraphQl from "../../../graphQL";
import Tabs from "../../../components/tabs";
import withGoTo from "../../goToHOC";
import Logger from "../../../utils/logger";
import CartDatasets from "./CartDatasets";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import CartDatasources from "./CartDatasources";

const Log = Logger("CartData");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      cartUri: string
    },
    url: string
  },
  cart: Object,
  showGlobalNotification: Function,
  reloadCart: Function
};

type stateTypes = {
  cart: Object,
  loadingDeleteDataset: boolean,
  errorDeleteDataset: boolean,
  nbDataset: number,
  nbDatasource: number
};

class CartData extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      cart: this.props.cart,
      loadingDeleteDataset: false,
      errorDeleteDataset: false,
      nbDataset: 0,
      nbDatasource: 0
    };
  }

  setNbDataset = (value) => {
    this.setState({ nbDataset: value });
  };

  setNbDatasource = (value) => {
    this.setState({ nbDatasource: value });
  };

  actionsDataset = (dataset) => {
    this.setState({
      loadingDeleteDataset: true,
      errorDeleteDataset: false
    });
    this.props.api.cart
      .removeDataset(this.props.match.params.cartUri, dataset.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Dataset removed",
          type: "success"
        });
        if (this.state.cart.is_refresh_auto_enabled) {
          this.props.showGlobalNotification({
            message:
              "Dataset removed, your role project will be automatically refreshed",
            type: "success"
          });
        }
        this.props.reloadCart();
      })
      .catch((error) => {
        Log.error("cart.removeDataset error: ", error);
        this.props.showGlobalNotification({
          message: getErrorMessageWithoutCode(error),
          type: "alert",
          popupDuration: 5000
        });
        this.setState({
          loadingDeleteDataset: false,
          errorDeleteDataset: error
        });
      });
  };

  getNbdatasource = () =>
    this.props.api.datasource
      .listDatasourcesFromProject(this.props.cart.uri)
      .then((datasources) => {
        if (!datasources) return false;
        this.setNbDatasource(datasources.total);
        return datasources;
      })
      .catch((error) => {
        Log.error(error);
      });

  getNbDataset = () =>
    this.props.api.cart
      .getDatasets(this.props.cart.uri)
      .then((datasets) => {
        if (!datasets) return false;
        this.setNbDataset(datasets.total);
        return datasets;
      })
      .catch((error) => {
        Log.error(error);
      });

  getTabs = () => {
    const { api, goTo, match } = this.props;

    const { cart, errorDeleteDataset, loadingDeleteDataset } = this.state;

    return [
      {
        name: `Datasets (${this.state.nbDataset})`,
        index: "data-datasets",
        route: `${match.url}#tata-datasets`,
        content: (
          <CartDatasets
            cart={cart}
            goTo={goTo}
            errorDeleteDataset={errorDeleteDataset}
            loadingDeleteDataset={loadingDeleteDataset}
            actionsDataset={this.actionsDataset}
            setNbDataset={this.setNbDataset}
          />
        )
      },
      {
        name: `Datasources (${this.state.nbDatasource})`,
        index: "data-datasources",
        route: `${match.url}#data-datasources`,
        content: (
          <CartDatasources
            cart={cart}
            api={api}
            setNbDatasource={this.setNbDatasource}
          />
        )
      }
    ];
  };

  componentDidMount() {
    this.getNbdatasource();
    this.getNbDataset();
  }

  render() {
    return (
      <Tabs
        isPreload={false}
        defaultActiveIndex={"data-datasets"}
        tabs={this.getTabs()}
      />
    );
  }
}

export default withGoTo(CartData);
