/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import "./view.less";
import Modal from "../../components/modal";
import { getErrorMessageWithoutCode } from "../../utils/error";
import withAppSync from "../AppsyncHOC";
import GraphQl from "../../graphQL";
import ButtonAction from "../../components/buttonAction";

type propTypes = {
  playground: Object,
  showGlobalNotification: Function,
  goBack: Function,
  hide: Function,
  reload?: boolean,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  confirmationText: string
};

class DetachPlaygroundModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      confirmationText: ""
    };
  }

  hideModalAndReload = () => {
    this.props.hide();
    window.location.reload();
  };

  detachEquansPlatform = () => {
    const { playground, goBack, reload } = this.props;
    return this.props.api.playground
      .detachEquansPlatform(playground.uri)
      .then(() => {
        this.props.hide();
        this.props.showGlobalNotification({
          message: "Environment detached",
          type: "success"
        });
      })
      .then(() => (reload ? this.hideModalAndReload() : goBack()))
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to detach environment",
          type: "alert"
        });
        this.setState({ error: getErrorMessageWithoutCode(error) });
      });
  };

  handleChange = (event) => {
    this.setState({ confirmationText: event.target.value });
  };

  renderTitle = () => (
    <div>
      Detach environment <b>{this.props.playground.name}</b>
    </div>
  );

  renderBody = () => (
    <div>
      <div className={"alert alert-warning"} style={{ fontWeight: 600 }}>
        <p>
          <span>
            CommonDataHub will cease running operations on your AWS account once
            you confirm this action.
          </span>
        </p>
        <p>
          <span>The powerupmanagement role remains available.</span>
        </p>
        <p>
          <span>
            Please refer to our{" "}
            <i>
              <a
                href={"https://confluence.tools.digital.engie.com/"}
                className="alert-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation center{" "}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </i>{" "}
            for more information.
          </span>
        </p>
        <p>
          {" "}
          <i className="fas fa-exclamation-circle fa-lg"></i>{" "}
          <span>
            WARNING: this action is <b>IRREVERSIBLE</b>.
          </span>{" "}
        </p>
      </div>
      <div>
        To confirm detach action, type <b>permanently detach</b> in the text
        input field.
      </div>
      <div className="mt-2">
        <input
          onChange={this.handleChange}
          value={this.state.confirmationText}
          className="form-control bg-white"
          placeholder="permanently detach"
        />
      </div>
    </div>
  );

  render() {
    const { playground, hide } = this.props;
    return (
      <Modal
        title={this.renderTitle()}
        body={this.renderBody()}
        errorMessage={this.state.error}
        classModalLarge={"modal-lg"}
        actions={[
          <button
            key={`dataset-close-${playground.uri}`}
            type="button"
            className="butn butn-flat"
            onClick={hide}
          >
            Close
          </button>,
          <ButtonAction
            key={`dataset-delete-${playground.uri}`}
            disabled={this.state.confirmationText !== "permanently detach"}
            label="Detach"
            className="butn butn-check"
            onClick={this.detachEquansPlatform}
          />
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DetachPlaygroundModal)
);
