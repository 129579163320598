import { useEffect, useState } from "react";

const useDatabricksWorkspaceDetails = (api, match) => {
  const [workspace, setWorkspace] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projectOffset, setProjectOffset] = useState(0);
  const [groupOffset, setGroupOffset] = useState(0);

  const retrieveWorkspaceDetails = async () => {
    setIsLoading(true);
    try {
      const workspace = await api.databricks.getDatabricksWorkspaceDetails(
        match.params.uuid,
        {
          groups_offset: groupOffset,
          projects_offset: projectOffset
        }
      );
      setWorkspace(workspace);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const unregisterWorkspace = async (groupuri) => {
    try {
      await api.databricks.revokeGroupFromDatabricksWorkspace(
        groupuri,
        workspace.uuid
      );
      await retrieveWorkspaceDetails();
    } catch (error) {
      setError(error);
    }
  };

  const updateGroups = async (value) => {
    setGroupOffset(value);
    await retrieveWorkspaceDetails();

    return workspace.dbx_groups;
  };

  const updateProjects = async (value) => {
    setProjectOffset(value);
    await retrieveWorkspaceDetails();

    return workspace.projects;
  };

  useEffect(() => {
    if (!isLoading && !error && workspace === null) {
      retrieveWorkspaceDetails();
    }
  }, [workspace, projectOffset, groupOffset]);

  return {
    error,
    groupOffset,
    isLoading,
    projectOffset,
    retrieveWorkspaceDetails,
    unregisterWorkspace,
    updateGroups,
    updateProjects,
    workspace
  };
};

export { useDatabricksWorkspaceDetails };
