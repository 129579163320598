/* @flow */
import React from "react";
import "../view.less";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import CartRSDatasource from "./CartRSDatasource";
import LinkRSDatasource from "./LinkRSDatasource";
import Loading from "../../../../components/loading";

type propTypes = {
  cart: Object,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  error: Object,
  openCreateDialog: boolean,
  datasources: Array<Object>
};
class CartRSDatasources extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      openCreateDialog: false,
      datasources: [],
      ready: false
    };
  }

  componentDidMount() {
    this.listProjectRSDatasources();
  }

  listProjectRSDatasources = (offset: number = 0) => {
    const options = {
      limit: 1000,
      offset
    };
    return this.props.api.cart
      .listProjectRSDatasources(this.props.cart.uri, options)
      .then((datasources) => {
        this.setState({
          datasources: datasources.results,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });
  };

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path={"RedshiftServerlessDatasources"}
        />
      );
    if (!this.state.ready)
      return <Loading message="Redshift Serverless Datasources" />;
    return (
      <div className={"environments-container"}>
        <div>
          <div
            className="butn butn-flat"
            onClick={() => this.setState({ openCreateDialog: true })}
          >
            <i className="fas fa-plus butn-icon" />
            <span className="butn-text">
              Add Redshift Serverless Datasource
            </span>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error
              error={this.state.error}
              path={"CartDssInstances"}
              stringOnly
            />
          </div>
        )}
        <div className={"card-container"}>
          {this.state.datasources.length <= 0 && (
            <div style={{ marginLeft: "15px" }}>No datasources to display.</div>
          )}
          {this.state.datasources &&
            this.state.datasources.map((datasource) => (
              <CartRSDatasource
                key={`${this.props.cart.uri}.${datasource.uri}`}
                api={this.props.api}
                cart={this.props.cart}
                datasource={datasource}
                reloadDatasources={this.listProjectRSDatasources}
              />
            ))}
        </div>
        {this.state.openCreateDialog && (
          <LinkRSDatasource
            api={this.props.api}
            cart={this.props.cart}
            platforms={this.props.cart.platforms}
            open={this.state.openCreateDialog}
            onClose={() => this.setState({ openCreateDialog: false })}
            reloadDatasources={this.listProjectRSDatasources}
          />
        )}
      </div>
    );
  }
}

export default CartRSDatasources;
