import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Security } from "@okta/okta-react";
import LayoutRoute from "./layout";
import Routes from "./routes";
import Homepage from "./homepage";
import MyData from "./datasources/list";
import DatasetCatalog from "./datasets/catalog";
import DatasetView from "./datasets/view";
import DatasetCreate from "./datasets/create";
import DatasetEdit from "./datasets/edit";
import AddToCart from "./datasets/AddToCart";
import DatasourceView from "./datasources/view";
import DatasourceCreate from "./datasources/create";
import DatasourceEdit from "./datasources/edit";
import DatasourceGenerateETLSessionCredentials from "./datasources/generateETLSessionCredentials";
import DatasourceGenerateETLCredentials from "./datasources/generateETLCredentials";
import DatasourceGenerateAdminCredentials from "./datasources/generateAdminCredentials";
import DatasourceDetectNewDatasets from "./datasources/detectNewDatasets";
import DatasourceDetectNewRSDatasets from "./datasources/RSDatasource/detectNewDatasets";
import DatasourceShareDatasets from "./datasources/shareDatasets";
import PlaygroundGroups from "./playgrounds/groups";
import PlaygroundView from "./playgrounds/view";
import Dashboard from "./users/dashboard";
import UserView from "./users/user";
import AccountForm from "./accounts/form";
import AccountList from "./accounts/list";
import AccountView from "./accounts/view";
import GlobalAnalyze from "./accounts/globalAnalyse";
import ManageGroupUsers from "./groups/userManagement";
import GroupView from "./groups/view";
import GroupsList from "./groups/list";
import UsersList from "./users/userList";
import PlaygroundsList from "./playgrounds/list";
import PlaygroundCreate from "./playgrounds/create";
import PlaygroundEdit from "./playgrounds/edit";
import UserCreate from "./users/create";
import GroupForm from "./groups/form";
import CartList from "./carts/list";
import CartForm from "./carts/form";
import CartView from "./carts/view";
import CartConnectionProperties from "./carts/connectionProperties";
import CartManagePolicies from "./carts/managePolicies";
import CartTransfertOwnership from "./carts/view/CartTransfertOwnership";
import RequestForm from "./share/RequestForm";
import NotFound404 from "./NotFound/404";
import {
  CallbackOktaComponent,
  callbackOktaUrl,
  configOkta
} from "./auth/okta";
import AddDataset from "./carts/AddDataset";
import AddLogGroup from "./carts/AddLogGroup/AddLogGroup";
import EditLogGroup from "./carts/EditLogGroup/EditLogGroup";
import AddAnalytics from "./carts/AddAnalytics";
import AddSagemakerNotebook from "./carts/AddSagemakerNotebook";
import EditSagemakerNotebook from "./carts/EditSagemakerNotebook";
import RedshiftClusterForm from "./analytics/redshift/create/redshiftClusterForm";
import StageView from "./stages/view";
import StageEdit from "./stages/edit";
import RedshiftClusterView from "./analytics/redshift/view";
import NetworkCreate from "./playgrounds/admin/components/forms/networkCreate";
import NetworkEdit from "./playgrounds/admin/components/forms/networkEdit";
import PlaygroundAdmin from "./playgrounds/admin";
import RedshiftClustersImport from "./analytics/redshift/components/import/redshiftClustersImport";
import ProjectGenerateCredentials from "./carts/generateCredentials";
import NetworkDetails from "./playgrounds/admin/components/networkDetails";
import LakeFormationMigrationReport from "./playgrounds/admin/components/lakeFormationMigrationReport";
import AccelerationTemplate from "./accelerationTemplate/view";
import AccelerationTemplateGrant from "./accelerationTemplate/grant";
import DatasourceTransferOwnership from "./datasources/transferOnwership";
import CreateDatabrewProject from "./carts/components/databrew/CreateDatabrewProject";
import EditDatabrewProject from "./carts/components/databrew/EditDatabrewProject";
import CartGlueJobParameters from "./carts/view/CartGlueJobParameters";
import DataFilterCreate from "./datafilters/DataFilterCreate";
import ShareManagement from "./share/components/ShareManagement";
import ShareDetail from "./share/components/ShareDetail";
import ShareAddItem from "./share/components/ShareAddItem";
import AddStageDataFilter from "./stages/datafilter";
import CatalogComponent from "./catalog";
import OwnedDatasetsList from "./datasets/list";
import DssInstanceCreate from "./dataikudss/create";
import DssInstanceView from "./dataikudss/view";
import DssInstanceEdit from "./dataikudss/edit";
import DssInstanceGroupsManagement from "./dataikudss/groups";
import CollibraDatasource from "./collibra/CollibraDatasource";
import CreateRSNamespaceForm from "./analytics/redshiftserverless/create/CreateRSNamespaceForm";
import RSNamespaceView from "./analytics/redshiftserverless/view/index";
import DetailsDatabricksAccount from "./databricks/detailsDatabricksAccount";
import DatabricksInstanceList from "./databricks/list";
import NewDatabricksAccount from "./databricks/forms/newDatabricksAccount";
import DatabricksWorkspaceDetails from "./databricks/workspace/databricks-workspace-details";

const AppRoute = () => (
  <Router>
    <Security {...configOkta}>
      <Switch>
        <Route path={callbackOktaUrl} exact component={CallbackOktaComponent} />
        <LayoutRoute
          path={Routes.Homepage.View}
          exact
          component={Homepage}
          classname="home"
        />
        <LayoutRoute
          path={Routes.Databricks.DetailsAccount}
          exact
          component={DetailsDatabricksAccount}
        />
        <LayoutRoute
          path={Routes.Databricks.WorkspaceDetails}
          exact
          component={DatabricksWorkspaceDetails}
        />
        <LayoutRoute
          path={Routes.Databricks.Create}
          exact
          component={NewDatabricksAccount}
        />
        <LayoutRoute
          path={Routes.Databricks.List}
          exact
          component={DatabricksInstanceList}
        />
        <LayoutRoute
          path={Routes.Dataset.Catalog}
          exact
          component={DatasetCatalog}
          classname="catalog"
        />
        <LayoutRoute
          path={Routes.Dataset.CatalogV2}
          exact
          component={CatalogComponent}
          classname="catalog"
        />
        <LayoutRoute path={Routes.MyData.View} exact component={MyData} />
        <LayoutRoute
          path={Routes.MyDataV2.View}
          exact
          component={OwnedDatasetsList}
        />
        <LayoutRoute
          path={Routes.Dataset.View}
          component={DatasetView}
          classname="datasetView"
        />
        <LayoutRoute
          path={Routes.Dataset.Create}
          component={DatasetCreate}
          classname="datasetCreate"
        />
        <LayoutRoute
          path={Routes.Dataset.Edit}
          component={DatasetEdit}
          classname="datasetCreate"
        />
        <LayoutRoute path={Routes.Dataset.AddToCart} component={AddToCart} />
        <LayoutRoute
          path={Routes.Dataset.CreateDataFilter}
          component={DataFilterCreate}
        />
        <LayoutRoute
          path={Routes.Datasource.View}
          component={DatasourceView}
          classname="datasourceView"
        />
        <LayoutRoute
          path={Routes.Datasource.Datasets}
          component={DatasourceView}
        />
        <LayoutRoute path={Routes.Datasource.Edit} component={DatasourceEdit} />
        <LayoutRoute
          path={Routes.Datasource.CreateDataset}
          component={DatasetCreate}
        />
        <LayoutRoute
          path={Routes.Datasource.Create}
          exact
          component={DatasourceCreate}
        />
        <LayoutRoute
          path={Routes.Datasource.GenerateETLCredentials}
          component={DatasourceGenerateETLCredentials}
        />
        <LayoutRoute
          path={Routes.Datasource.GenerateETLSessionCredentials}
          component={DatasourceGenerateETLSessionCredentials}
        />
        <LayoutRoute
          path={Routes.Datasource.GenerateAdminCredentials}
          component={DatasourceGenerateAdminCredentials}
        />
        <LayoutRoute
          path={Routes.Datasource.DetectDataset}
          component={DatasourceDetectNewDatasets}
          classname="datasource-detect-datasets"
        />
        <LayoutRoute
          path={Routes.Datasource.DetectRSDataset}
          component={DatasourceDetectNewRSDatasets}
          classname="datasource-detect-rsdatasets"
        />
        <LayoutRoute
          path={Routes.Datasource.ShareDatasets}
          component={DatasourceShareDatasets}
          classname="datasource-share-datasets"
        />
        <LayoutRoute
          path={Routes.Datasource.Transfer}
          component={DatasourceTransferOwnership}
        />
        <LayoutRoute
          path={Routes.Datasource.Collibra}
          component={CollibraDatasource}
        />
        <LayoutRoute
          path={Routes.Playground.Groups}
          component={PlaygroundGroups}
        />
        <LayoutRoute path={Routes.User.MyProfile} exact component={Dashboard} />
        <LayoutRoute path={Routes.User.ShareNew} exact component={Dashboard} />
        <LayoutRoute
          path={Routes.User.NotificationSettings}
          exact
          component={Dashboard}
        />
        <LayoutRoute
          path={Routes.User.RecentActivities}
          exact
          component={Dashboard}
        />
        <LayoutRoute
          path={Routes.User.ShareWithMe}
          exact
          component={Dashboard}
        />
        <LayoutRoute
          path={Routes.User.ShareOwned}
          exact
          component={Dashboard}
        />
        <LayoutRoute
          path={Routes.User.Notifications}
          exact
          component={Dashboard}
        />
        <LayoutRoute
          path={Routes.User.Newtifications}
          exact
          component={Dashboard}
        />
        <LayoutRoute path={Routes.User.CDHAdmin} exact component={Dashboard} />
        <LayoutRoute path={Routes.User.View} component={UserView} />
        <LayoutRoute
          path={Routes.Account.List}
          exact
          component={AccountList}
          classname="accountList"
        />
        <LayoutRoute
          path={Routes.Account.Create}
          exact
          component={AccountForm}
        />
        <LayoutRoute
          path={Routes.Account.View}
          exact
          component={AccountView}
          classname="accountView"
        />
        <LayoutRoute path={Routes.Account.Edit} exact component={AccountForm} />
        <LayoutRoute path={Routes.User.List} exact component={UsersList} />
        <LayoutRoute path={Routes.User.Create} exact component={UserCreate} />
        <LayoutRoute path={Routes.Group.View} exact component={GroupView} />
        <LayoutRoute
          path={Routes.Group.EditUsers}
          exact
          component={ManageGroupUsers}
          classname="manageUsers"
        />
        <LayoutRoute path={Routes.Group.List} exact component={GroupsList} />
        <LayoutRoute path={Routes.Group.Create} exact component={GroupForm} />
        <LayoutRoute path={Routes.Group.Edit} exact component={GroupForm} />
        <LayoutRoute
          path={Routes.Playground.List}
          exact
          component={PlaygroundsList}
        />
        <LayoutRoute
          path={Routes.Playground.View}
          exact
          component={PlaygroundView}
        />
        <LayoutRoute
          path={Routes.Playground.Create}
          exact
          component={PlaygroundCreate}
        />
        <LayoutRoute
          path={Routes.Playground.Edit}
          exact
          component={PlaygroundEdit}
        />
        <LayoutRoute
          path={Routes.Playground.CreateDataset}
          exact
          component={DatasetCreate}
        />
        <LayoutRoute
          path={Routes.Playground.CreateDatasource}
          exact
          component={DatasourceCreate}
        />
        <LayoutRoute
          path={Routes.Playground.Admin}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.Network}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.NotebooksAdmin}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.Analytics}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.AccelerationTemplate}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.LakeFormation}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.SmStudio}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.DbxWorkspace}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.Archives}
          exact
          component={PlaygroundAdmin}
        />
        <LayoutRoute
          path={Routes.Playground.MigrationReport}
          exact
          component={LakeFormationMigrationReport}
        />
        <LayoutRoute
          path={Routes.Playground.CreateCluster}
          exact
          component={RedshiftClusterForm}
        />
        <LayoutRoute
          path={Routes.Playground.CreateRSNamespace}
          exact
          component={CreateRSNamespaceForm}
        />
        <LayoutRoute
          path={Routes.Playground.ImportClusters}
          exact
          component={RedshiftClustersImport}
        />
        <LayoutRoute
          path={Routes.AccelerationTemplate.View}
          exact
          component={AccelerationTemplate}
          classname="accelerationTemplateView"
        />
        <LayoutRoute
          path={Routes.AccelerationTemplate.Grant}
          exact
          component={AccelerationTemplateGrant}
        />
        <LayoutRoute path={Routes.Cart.List} exact component={CartList} />
        <LayoutRoute
          path={Routes.Cart.View}
          exact
          component={CartView}
          classname="projectView"
        />
        <LayoutRoute path={Routes.Cart.Create} exact component={CartForm} />
        <LayoutRoute path={Routes.Cart.Edit} exact component={CartForm} />
        <LayoutRoute
          path={Routes.Network.Create}
          exact
          component={NetworkCreate}
        />
        <LayoutRoute path={Routes.Network.Edit} exact component={NetworkEdit} />
        <LayoutRoute
          path={Routes.Network.View}
          exact
          component={NetworkDetails}
          classname="networkView"
        />
        <LayoutRoute
          path={Routes.Cart.ConnectionProperties}
          exact
          component={CartConnectionProperties}
        />
        <LayoutRoute
          path={Routes.Cart.ManagePolicies}
          exact
          component={CartManagePolicies}
        />
        <LayoutRoute
          path={Routes.Cart.AddDataset}
          exact
          component={AddDataset}
        />
        <LayoutRoute
          path={Routes.Cart.AddLogGroup}
          exact
          component={AddLogGroup}
        />
        <LayoutRoute
          path={Routes.Cart.EditLogGroup}
          exact
          component={EditLogGroup}
        />
        <LayoutRoute
          path={Routes.Cart.CreateDatabrewProject}
          exact
          component={CreateDatabrewProject}
        />
        <LayoutRoute
          path={Routes.Cart.GlueJobParameters}
          exact
          component={CartGlueJobParameters}
        />
        <LayoutRoute
          path={Routes.Cart.EditDatabrewProject}
          exact
          component={EditDatabrewProject}
        />
        <LayoutRoute
          path={Routes.Cart.AddAnalytics}
          exact
          component={AddAnalytics}
        />
        <LayoutRoute
          path={Routes.Cart.AddSagemakerNotebook}
          exact
          component={AddSagemakerNotebook}
        />
        <LayoutRoute
          path={Routes.Cart.EditSagemakerNotebook}
          exact
          component={EditSagemakerNotebook}
        />
        <LayoutRoute
          path={Routes.Cart.GenerateCredentials}
          exact
          component={ProjectGenerateCredentials}
        />
        <LayoutRoute
          path={Routes.Cart.Transfer}
          exact
          component={CartTransfertOwnership}
        />
        <LayoutRoute
          path={Routes.Share.Request}
          exact
          component={RequestForm}
        />
        <LayoutRoute
          path={Routes.Share.Manage}
          exact
          component={ShareManagement}
        />
        <LayoutRoute path={Routes.Share.View} exact component={ShareDetail} />
        <LayoutRoute
          path={Routes.Share.AddItem}
          exact
          component={ShareAddItem}
        />
        <LayoutRoute
          path={Routes.Analyze.Global}
          exact
          component={GlobalAnalyze}
        />
        <LayoutRoute path={Routes.Stage.View} exact component={StageView} />
        <LayoutRoute path={Routes.Stage.Edit} exact component={StageEdit} />
        <LayoutRoute
          path={Routes.Stage.AddDataFilter}
          exact
          component={AddStageDataFilter}
        />
        <LayoutRoute
          path={Routes.Analytics.View}
          exact
          component={RedshiftClusterView}
        />
        <LayoutRoute
          path={Routes.Analytics.CartView}
          exact
          component={RedshiftClusterView}
        />
        <LayoutRoute
          path={Routes.DssInstance.Create}
          exact
          component={DssInstanceCreate}
        />
        <LayoutRoute
          path={Routes.DssInstance.View}
          exact
          component={DssInstanceView}
        />
        <LayoutRoute
          path={Routes.DssInstance.Edit}
          exact
          component={DssInstanceEdit}
        />
        <LayoutRoute
          path={Routes.DssInstance.Groups}
          exact
          component={DssInstanceGroupsManagement}
        />
        <LayoutRoute
          path={Routes.RSNamespace.View}
          exact
          component={RSNamespaceView}
        />
        <LayoutRoute path={""} component={NotFound404} />
      </Switch>
    </Security>
  </Router>
);

export default AppRoute;
