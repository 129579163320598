/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../../links";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import Stringslider from "../../../components/stringslider";
import { formatDate } from "../../../utils/date";
import "../style.less";

type propTypes = {
  datasource: {
    accountid: string,
    bucket: string,
    createdat: string,
    region: string,
    description: string,
    uri: string,
    parent: string,
    name: string,
    owneruri: string,
    owner_group_uri: string,
    owner_group_name: string,
    sharing_group_name: string,
    sharing_group_uri: string,
    aws: string,
    action_type: string,
    ds_archivedat: string,
    creatoruri: string,
    resource_name: string,
    kmsalias: string
  }
};

class DatasourceArchiveCard extends React.PureComponent<propTypes> {
  render() {
    return (
      <div className={"archive-card card-shadow card bg-white"}>
        <div className="card-archive-header">
          <div className="card-archive-begin">
            <div className="card-archive-begin-content">
              <i className="fas fa-user-friends" />
              <Links.Group.View
                uriGroup={this.props.datasource.owneruri}
                state={{ group: this.props.datasource.owneruri }}
              >
                <span className="text-header-archive">
                  {this.props.datasource.owner_group_name.substring(0, 50)}
                </span>
              </Links.Group.View>
            </div>
          </div>
          <div className="card-archive-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-archive">
              {formatDate(this.props.datasource.ds_archivedat, false)}
            </span>
          </div>
        </div>
        <div className="card-archive-body">
          <div className="card-archive-body-left">
            <div className={"text-capitalize archive-card-name"}>
              <Stringslider
                s={this.props.datasource.name}
                height={30}
                width={275}
              />
            </div>
            <div className="card-archive-details">
              <div className="cdh-card-detail">
                <i className="fas fa-user-shield" />
                <span className="content-card-bucket">
                  <b>Archived by:</b>{" "}
                  {this.props.datasource.creatoruri.split(":").pop()}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-user-friends" />
                <span className="content-card-bucket">
                  <b>Sharing group:</b>{" "}
                  {this.props.datasource.sharing_group_name}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>AWS Account:</b> {this.props.datasource.aws}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fab fa-bitbucket" />
                <span className="content-card-bucket">
                  <b>S3 Bucket:</b> s3://{this.props.datasource.bucket}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-database" />
                <span className="content-card-bucket">
                  <b>Glue database:</b> {this.props.datasource.resource_name}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-key" />
                <span className="content-card-bucket">
                  <b>KMS key:</b> {this.props.datasource.kmsalias}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-globe-europe" />
                <span className="content-card-bucket">
                  <b>Region:</b> {this.props.datasource.region}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-archive-footer">
          <div className="card-archive-begin">
            <div className="card-archive-begin-content">
              <div className="flag-user-roles">
                <span className="tag-metrics user-role">
                  {this.props.datasource.action_type}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DatasourceArchiveCard));
