/* @flow */
import React from "react";
import { Formik, Form, Field } from "formik";

import withAppSync from "../../../../AppsyncHOC";
import "./style.less";
import SelectClassic from "../../../../../components/SelectClassic";
import GraphQl from "../../../../../graphQL";
import Logger from "../../../../../utils/logger";
import ToggleButton from "../../../../../components/toggleButton";
import HelpOn from "../../../../../components/helpOn";
import SelectAutocomplete from "../../../../../components/SelectAutocomplete";
import ButtonAction from "../../../../../components/buttonAction";
import SelectInfinite from "../../../../../components/SelectInfinite/SelectInfinite2";

const Log = Logger("DatasourceReplicationForm");

type propTypes = {
  api: GraphQl,
  onClose: Function,
  putDatasourceReplication: Function,
  datasource: Object
};

type stateTypes = {
  errorOrganizationStatus: boolean,
  errorGroupStatus: boolean,
  errorEnvironmentStatus: boolean,
  errorRegionStatus: boolean,
  errorDatasourceDestinationStatus: boolean,
  replicationRuleName: string,
  selectedOrganization: Object,
  totalGroups: number,
  isLoadingGroups: boolean,
  isStorageClassSelected: boolean,
  organizations: Array<Object>,
  datasourceDestinations: Object,
  groups: Array<Object>,
  environments: Array<Object>,
  regions: Array<Object>,
  selectedGroup: Object,
  selectedEnvironment: Object,
  selectedRegion: Object,
  selectedDatasourceDestination: Object,
  datasets: Array<Object>,
  selectedDatasets: Array<{ value: string, label: string }>,
  selectAll: boolean,
  selectedStorageClass: Object
};

const availableStorageClasses = [
  { label: "STANDARD", value: "STANDARD" },
  { label: "REDUCED_REDUNDANCY", value: "REDUCED_REDUNDANCY" },
  { label: "STANDARD_IA", value: "STANDARD_IA" },
  { label: "ONEZONE_IA", value: "ONEZONE_IA" },
  { label: "INTELLIGENT_TIERING", value: "INTELLIGENT_TIERING" },
  { label: "GLACIER", value: "GLACIER" },
  { label: "DEEP_ARCHIVE", value: "DEEP_ARCHIVE" },
  { label: "OUTPOSTS", value: "OUTPOSTS" },
  { label: "GLACIER_IR", value: "GLACIER_IR" }
];

class DatasourceReplicationForm extends React.PureComponent<
  propTypes,
  stateTypes
> {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      datasets: [],
      organizations: [],
      groups: [],
      environments: [],
      regions: [],
      selectedDatasets: [],
      selectedGroup: {},
      selectedOrganization: {},
      selectedEnvironment: {},
      selectedRegion: {},
      selectedDatasourceDestination: {},
      selectedStorageClass: availableStorageClasses[0],
      datasourceDestinations: {},
      replicationRuleName: "",
      isLoadingGroups: false,
      isStorageClassSelected: false,
      totalGroups: 0,
      errorOrganizationStatus: false,
      errorGroupStatus: false,
      errorEnvironmentStatus: false,
      errorRegionStatus: false,
      errorDatasourceDestinationStatus: false
    };
  }

  componentDidMount() {
    this.initForm();
  }

  getPlaceholderGroups = () => {
    if (this.state.selectedGroup) return this.state.selectedGroup.name;
    if (this.state.totalGroups <= 0) return "No group found";
    return "select a group";
  };

  loadMoreGroups = (offset) =>
    this.props.api.account
      .listGroups(this.state.selectedOrganization.value, { offset })
      .then((data) =>
        (data.results || []).map((d) => ({
          value: d.uri,
          label: d.name
        }))
      );

  initForm = () => {
    this.getOrganizations();
    this.getDatasets();
  };

  getSelectedDatasets() {
    const datasets = [];
    if (this.state.selectAll) {
      return datasets;
    }
    this.state.selectedDatasets.map((o) => datasets.push(o.value));
    return datasets;
  }

  getDatasets = () => {
    const datasets = [];
    this.props.api.datasource
      .getDatasets(this.props.datasource.uri)
      .then((data) => {
        data.map((o) =>
          datasets.push({
            label: o.name,
            value: o.uri
          })
        );
        this.setState({ datasets });
      })
      .catch((error) => {
        Log.error(error);
      });
    return datasets;
  };

  getOrganizations = () => {
    this.props.api.account
      .getAccounts()
      .then((accounts) => {
        const organizations = [];
        accounts.map((o) =>
          organizations.push({
            label: o.name,
            value: o.uri
          })
        );
        this.setState({ organizations });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getGroups(accountUri) {
    this.setState({ isLoadingGroups: true });
    this.props.api.account
      .listGroups(accountUri)
      .then((data) => {
        const groups = [];
        data.results.map((o) =>
          groups.push({
            label: o.name,
            value: o.uri
          })
        );
        this.setState({ totalGroups: data.total });
        this.setState({ groups });
        this.setState({ isLoadingGroups: false });
      })
      .catch((error) => {
        Log.error(error);
      });
  }

  getEnvironments = (groupUri) => {
    this.props.api.group
      .getGroupEnvironments(groupUri)
      .then((data) => {
        const environments = [];
        data.results.map((o) => {
          environments.push({
            label: o.name,
            value: o.uri
          });
          return environments;
        });

        this.setState({ environments }, this.getRegions);
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getRegions = () => {
    const options = {
      limit: 50,
      filters: []
    };

    if (this.state.selectedGroup) {
      options.filters.push({
        key: "owner_group_name",
        value: this.state.selectedGroup.label
      });
    }

    return this.props.api.datasource
      .listAllMyDatasources(options)
      .then((data) => {
        const tmpRegions = {};
        const regions = [];
        data.map((o) => {
          tmpRegions[o.region] = {
            label: o.region,
            value: o.region
          };
          return tmpRegions;
        });
        Object.keys(tmpRegions).map((o) =>
          regions.push({
            label: o,
            value: o
          })
        );
        this.setState({ regions });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getDatasourceDestinations = () => {
    const options = {
      limit: 50,
      filters: []
    };

    if (this.state.selectedGroup) {
      options.filters.push({
        key: "owner_group_name",
        value: this.state.selectedGroup.label
      });
    }

    return this.props.api.datasource
      .listAllMyDatasources(options)
      .then((data) => {
        const datasourceDestinations = [];
        data.map((o) => {
          if (
            o.region === this.state.selectedRegion.label &&
            o.uri !== this.props.datasource.uri
          ) {
            datasourceDestinations.push({
              label: o.name,
              value: o.uri
            });
          }
          return datasourceDestinations;
        });
        this.setState({ datasourceDestinations });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getFieldReplicationRuleName = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Replication rule name</label>
      <Field
        required
        disabled
        value={`${this.state.replicationRuleName}`}
        className="form-control form-control-sm"
        placeholder="Replicate name"
      />
    </div>
  );

  getFieldSourceDatasource = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Source Datasource</label>
      <Field
        required
        disabled
        value={`s3://${this.props.datasource.bucket}`}
        name="datasource"
        className="form-control form-control-sm"
        placeholder="Datasource"
      />
    </div>
  );

  getFieldOrganisation = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Organization *</label>
      <SelectClassic
        className={this.state.errorOrganizationStatus ? "error-input" : ""}
        options={this.state.organizations}
        selectedOption={this.state.selectedOrganization}
        onSelectOption={(option) => {
          this.setState({ errorOrganizationStatus: this.fieldIsEmpty(option) });
          this.setState({ selectedOrganization: option });
          this.setState(
            {
              selectedGroup: {},
              selectedEnvironment: {},
              selectedRegion: {},
              selectedDatasourceDestination: {}
            },
            this.getGroups(option.value)
          );
        }}
      />

      {this.state.errorOrganizationStatus ? this.getErrorMessage() : ""}
    </div>
  );

  getFieldGroups = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Groups *</label>
      <SelectInfinite
        key={"select-infinite-groups"}
        className={this.state.errorGroupStatus ? "error-input" : ""}
        isLoadingOptions={this.state.isLoadingGroups}
        placeholder={this.getPlaceholderGroups()}
        isDisabled={false}
        selectedOption={this.state.selectedGroup}
        totalOptions={this.state.totalGroups || 0}
        initialOptions={this.state.groups}
        loadMoreOptions={this.loadMoreGroups}
        onSelectOption={(option) => {
          this.setState({ selectedGroup: option }, () => {
            this.setState({ errorGroupStatus: this.fieldIsEmpty(option) });
            this.setState(
              {
                selectedEnvironment: {},
                selectedRegion: {},
                selectedDatasourceDestination: {}
              },
              this.getEnvironments(option.value)
            );
          });
        }}
      />
      {this.state.errorGroupStatus ? this.getErrorMessage() : ""}
    </div>
  );

  getFieldEnvironments = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Environment *</label>
      <SelectClassic
        className={this.state.errorEnvironmentStatus ? "error-input" : ""}
        options={this.state.environments}
        selectedOption={this.state.selectedEnvironment}
        onSelectOption={(option) => {
          this.setState({ errorEnvironmentStatus: this.fieldIsEmpty(option) });
          this.setState({ selectedEnvironment: option }, () => {
            this.setState({
              selectedRegion: {},
              selectedDatasourceDestination: {}
            });
          });
        }}
      />
      {this.state.errorEnvironmentStatus ? this.getErrorMessage() : ""}
    </div>
  );

  getFieldRegions = () => (
    <div className="mb-3 my-2">
      <label className="label-form">Regions *</label>
      <SelectClassic
        className={this.state.errorRegionStatus ? "error-input" : ""}
        options={this.state.regions}
        selectedOption={this.state.selectedRegion}
        onSelectOption={(option) => {
          this.setState({ errorRegionStatus: this.fieldIsEmpty(option) });
          this.setState({ selectedRegion: option }, () => {
            this.setState(
              { selectedDatasourceDestination: {} },
              this.getDatasourceDestinations
            );
          });
        }}
      />
      {this.state.errorRegionStatus ? this.getErrorMessage() : ""}
    </div>
  );

  getComputedName = (name) => `${name}`;

  getfieldDatasourceDestinations = () => (
    <div className="mb-3">
      <label className="label-form">Datasource destination *</label>
      <SelectClassic
        className={
          this.state.errorDatasourceDestinationStatus ? "error-input" : ""
        }
        options={this.state.datasourceDestinations}
        selectedOption={this.state.selectedDatasourceDestination}
        onSelectOption={(option) => {
          this.setState({
            errorDatasourceDestinationStatus: this.fieldIsEmpty(option)
          });
          this.setState({ selectedDatasourceDestination: option });
          this.setState({
            replicationRuleName: `cdh-${this.getComputedName(option.label)}`
          });
        }}
      />
      `
      {this.state.errorDatasourceDestinationStatus
        ? this.getErrorMessage()
        : ""}
    </div>
  );

  getFieldDatasets = () => (
    <div className="mb-3">
      <legend className="label-form">Scope rule</legend>
      <div className="d-flex">
        <ToggleButton
          id="select-all-datasets-id"
          checkedValue={this.state.selectAll}
          onChange={() =>
            this.setState((prevState) => {
              const selectAll = !prevState.selectAll;
              return { selectAll };
            })
          }
        />
        <HelpOn
          content={
            <div>
              <h3>{"Select all datasets "}</h3>
              <div>
                <b>{""}</b>
              </div>
              <div>{"This option is used to select the entire bucket."}</div>
            </div>
          }
        />
        <span>Select all datasets</span>
      </div>
      {!this.state.selectAll && (
        <SelectAutocomplete
          placeholder={"Select Datasets"}
          isMulti
          options={this.state.datasets}
          selectedOption={this.state.selectedDatasets}
          onSelectOption={(options) => {
            this.setState({ selectedDatasets: options || [] });
          }}
        />
      )}
    </div>
  );

  getFieldStorageClass = () => (
    <div className="mb-3">
      <legend className="label-form">Storage class</legend>
      <div className="d-flex">
        <ToggleButton
          id="is-storage-class-selected-toggle"
          checkedValue={this.state.isStorageClassSelected}
          onChange={() =>
            this.setState((prevState) => {
              const isStorageClassSelected = !prevState.isStorageClassSelected;
              return { isStorageClassSelected };
            })
          }
        />
        <HelpOn
          content={
            <div>
              <a
                href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/storage-class-intro.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                {"See Storage Class documentation"}
              </a>
            </div>
          }
        />
        Change the replication storage class
      </div>
      {this.state.isStorageClassSelected && (
        <SelectClassic
          className={""}
          options={availableStorageClasses}
          selectedOption={this.state.selectedStorageClass}
          onSelectOption={(option) => {
            this.setState({ selectedStorageClass: option });
          }}
        />
      )}
    </div>
  );

  getErrorMessage = () => (
    <div className={"error-input-value"}>A value must be selected</div>
  );

  getConfirmBodyModal = () => (
    <div className="cdh-replication-disclaimer">
      <ul>
        <li>This operation may take several minutes.</li>
        <li>
          Data that has already been replicated in the datasource destination,
          will not be replicated once again.
        </li>
        <li>
          There's an additional cost to be expected in the event of cross-region
          replication. (
          <a
            href={"https://aws.amazon.com/s3/pricing/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            More details
          </a>
          )
        </li>
      </ul>
    </div>
  );

  fieldIsEmpty = (obj) => JSON.stringify(obj) === JSON.stringify({});

  handleCreateReplication = () =>
    new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          errorOrganizationStatus: this.fieldIsEmpty(
            prevState.selectedOrganization
          ),
          errorGroupStatus: this.fieldIsEmpty(prevState.selectedGroup),
          errorEnvironmentStatus: this.fieldIsEmpty(
            prevState.selectedEnvironment
          ),
          errorRegionStatus: this.fieldIsEmpty(prevState.selectedRegion),
          errorDatasourceDestinationStatus: this.fieldIsEmpty(
            prevState.selectedDatasourceDestination
          )
        }),
        async () => {
          let result = false;
          if (
            !this.state.errorOrganizationStatus &&
            !this.state.errorGroupStatus &&
            !this.state.errorEnvironmentStatus &&
            !this.state.errorRegionStatus &&
            !this.state.errorDatasourceDestinationStatus
          ) {
            result = this.props.putDatasourceReplication({
              sourceUri: this.props.datasource.uri,
              destinationUri: this.state.selectedDatasourceDestination.value,
              datasetsUriList: this.getSelectedDatasets(),
              selectedStorageClass: this.state.selectedStorageClass.value,
              isStorageClassSelected: this.state.isStorageClassSelected
            });
          }
          resolve(result);
        }
      );
    });

  render() {
    const fieldReplicationRuleName = this.getFieldReplicationRuleName();
    const fieldSourceDatasource = this.getFieldSourceDatasource();
    const fieldOrganizations = this.getFieldOrganisation();
    const fieldGroups = this.getFieldGroups();
    const fieldEnvironments = this.getFieldEnvironments();
    const fieldRegions = this.getFieldRegions();
    const fieldDatasourceDestinations = this.getfieldDatasourceDestinations();
    const fieldDatasets = this.getFieldDatasets();
    const fieldStorageClass = this.getFieldStorageClass();

    return (
      <div>
        <Formik
          initialValues={{
            organizations: [],
            groups: [],
            environments: [],
            regions: [],
            datasourceDestinations: []
          }}
        >
          {() => (
            <Form>
              {fieldReplicationRuleName}
              {fieldSourceDatasource}
              {fieldStorageClass}
              {fieldDatasets}
              {fieldOrganizations}
              {fieldGroups}
              {fieldEnvironments}
              {fieldRegions}
              {fieldDatasourceDestinations}
              <div className="form-group">
                <ButtonAction
                  label={"Create"}
                  className="butn butn-create mr-1"
                  onClick={() => this.handleCreateReplication()}
                />
                <ButtonAction label="Close" onClick={this.props.onClose} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withAppSync(DatasourceReplicationForm);
