export const mutationUpdate = {
  query: `mutation Q (
        $instance_id: String!,
        $input: UpdateSagemakerNotebookInstanceInput!
    ) {
      updateSagemakerNotebookInstance(instance_id: $instance_id, input: $input) {
          arn
        }
      }
    `,
  keyOutput: "updateSagemakerNotebookInstance"
};
