/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */

import * as React from "react";
import PropTypes from "prop-types";
import { withAuth } from "@okta/okta-react";
import { withRouter } from "react-router";
import GraphQlQueries from "../../graphQL";
import config from "../../config";
import Logger from "../../utils/logger";

const Log = Logger("withAppSync");

type propTypes = any;

type stateTypes = {
  queries: Array<Object>
};

const withAppSync = <propsType: Object>(
  ComposedComponent: React.AbstractComponent<propsType>
): React.AbstractComponent<$Diff<propsType, { api: GraphQlQueries }>> => {
  class WrappedComponent extends React.Component<propTypes, stateTypes> {
    graphQlQueries: Object;

    constructor(props) {
      super(props);
      if (!window.debugGraphQl) {
        window.debugGraphQl = this;
      }
      this.state = {
        queries: []
      };
      this.graphQlQueries = new GraphQlQueries(this.fetchAppSync);
    }

    shouldComponentUpdate(nextProps, nextState) {
      return !(nextState.queries !== this.state.queries);
    }

    getData(data, key) {
      let response = data;
      key.split(".").forEach((part) => {
        response = response[part];
      });
      return response;
    }

    fetchAppSync = (
      { query, keyOutput },
      variables = {},
      isUnique,
      canHaveErrors = false
    ) => {
      if (!query) throw new Error("queryGraphQL not define");
      if (!config.APPSYNC_ENDPOINT) {
        throw new Error("APPSYNC_ENDPOINT not define");
      }
      if (this.state.queries.length > 10) {
        Log.error("too many queries !!");
        throw new Error("Too Many Requests");
      }

      // abort same old query, only queries with 'isUnique'=true can be aborted
      const idQuery = Math.random();
      let controller;
      if (isUnique) {
        controller = new AbortController();
        this.state.queries
          .filter((q) => q.query === query)
          .map((q) => {
            q.controller.abort();
            return true;
          });
      }

      // add this fetch to the query list
      this.setState((prevstate) => ({
        queries: prevstate.queries.concat({
          id: idQuery,
          query,
          controller
        })
      }));

      return this.props.auth
        .getUser()
        .then((user) => {
          const exp = !user || !user.exp ? 0 : user.exp;
          const tokenExpiresAt = exp * 1000;
          // Log.verbose(`token expired at ${(new Date(tokenExpiresAt)).toISOString()} || now: ${(new Date()).toISOString()}`);
          if (tokenExpiresAt < new Date().getTime()) return false;
          return this.props.auth.isAuthenticated();
        })
        .then((isAuthenticated) => {
          if (!isAuthenticated) {
            Log.info("not authenticated, auth login time");
            return this.props.auth.login(this.props.location.pathname);
          }

          return this.props.auth.getIdToken().then((token) => {
            const params = {
              method: "POST",
              signal: isUnique && controller ? controller.signal : undefined,
              headers: {
                "Content-Type": "application/json",
                Authorization: token
              },
              body: JSON.stringify({
                query,
                variables
              })
            };

            return fetch(config.APPSYNC_ENDPOINT, params)
              .then((response) => response.json())
              .then((response) => {
                this.setState((prevState) => ({
                  queries: prevState.queries.filter((q) => q.id !== idQuery)
                })); // remove query from the list

                if (response.errors && !canHaveErrors) {
                  throw new Error(
                    response.errors.length === 1
                      ? JSON.stringify(response.errors[0])
                      : JSON.stringify(response.errors)
                  );
                }

                Log.info("result fetch", query, variables, response.data);
                if (response.data && keyOutput) {
                  if (typeof keyOutput === "object") {
                    const result = {};
                    Object.keys(keyOutput).map((key) => {
                      result[key] = response.data[keyOutput[key]];
                      return false;
                    });
                    return result;
                  }
                  return this.getData(response.data, keyOutput);
                }
                return response.data;
              })
              .catch((error) => {
                if (error.name === "AbortError") {
                  Log.info("Fetch aborted", query, variables);
                  this.setState((prevState) => ({
                    queries: prevState.queries.filter((q) => q.id !== idQuery)
                  })); // remove query from the list
                  return null;
                }

                if (error.TypeError === "Failed to fetch") {
                  Log.warning("Failed to fetch", query, variables);
                  return this.props.auth
                    .login(this.props.location.pathname)
                    .then(() =>
                      this.fetchAppSync(
                        { query, keyOutput },
                        variables,
                        isUnique,
                        canHaveErrors
                      )
                    );
                }

                const getCompName = (comp: Object) => {
                  if (comp.name.length > 1) {
                    if (comp.name === "WrappedComponent") {
                      return comp.displayName;
                    }
                    return comp.name;
                  }
                  if (comp.WrappedComponent) {
                    return getCompName(comp.WrappedComponent);
                  }
                  return comp.displayName || comp.name;
                };
                const removeHOC = (compName: string) =>
                  compName
                    .replace("withRouter(", "")
                    .replace("withGoTo(", "")
                    .replace("withAuth(", "")
                    .replace(")", "");
                const compName = removeHOC(getCompName(ComposedComponent));

                Log.error("BACKEND-ERROR", compName, error);

                let e;
                try {
                  e = JSON.parse(error.message);
                  e.errorType = "BACKEND-ERROR";
                } catch (err) {
                  e = error;
                }

                throw e;
              });
          });
        });
    };

    render() {
      return <ComposedComponent {...this.props} api={this.graphQlQueries} />;
    }
  }
  WrappedComponent.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }),
    auth: PropTypes.shape({
      getUser: PropTypes.func.isRequired,
      getIdToken: PropTypes.func.isRequired,
      isAuthenticated: PropTypes.func.isRequired,
      login: PropTypes.func.isRequired
    })
  };
  return withRouter(withAuth(WrappedComponent));
};

withAppSync.propTypes = {
  Component: PropTypes.element
};

export default withAppSync;
