/* @flow */

import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

type AutoCompletePoliciesPropTypes = {
  policies: Array<Object>,
  handleInputArn: Function,
  environmentId: string
};

const AutoCompletePolicies = ({
  policies,
  handleInputArn,
  environmentId
}: AutoCompletePoliciesPropTypes) => {
  const policiesIsNotEmpty = policies.length !== 0;
  return (
    <div className="w-100">
      <label className="label-form">Policy ARN</label>
      <div className="mb-2">
        <small>
          Format : arn:aws:iam::<b>{environmentId}</b>:policy/cdh-<b>name</b>
        </small>
      </div>
      {policiesIsNotEmpty ? (
        <Autocomplete
          freeSolo
          options={policies}
          autoComplete={policiesIsNotEmpty}
          noOptionsText="No environment attached to this group"
          getOptionLabel={(option) => option.policyarn}
          renderInput={(params) => (
            <TextField
              label=""
              placeholder="Type or select an arn"
              variant="outlined"
              fullWidth
              {...params}
            />
          )}
          onInputChange={handleInputArn}
        />
      ) : (
        <Autocomplete
          freeSolo
          options={policies}
          noOptionsText="No environment attached to this group"
          renderInput={(params) => (
            <TextField
              label=""
              placeholder="Type or select an arn"
              variant="outlined"
              fullWidth
              {...params}
            />
          )}
          onInputChange={handleInputArn}
        />
      )}
    </div>
  );
};

export default AutoCompletePolicies;
