/* @flow */

import React from "react";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import config from "../../../config";
import GraphQl from "../../../graphQL";
import "./style.less";
import Tabs from "../../../components/tabs";
import UserList from "../../users/userList";
import GroupsList from "../../groups/list";
import PlaygroundsList from "../../playgrounds/list";
import AccountDatasources from "../datasources/list";
import PolicyManagement from "../../groups/policyManagement";
import TrustManagement from "../../groups/trustManagement";
import DssInstanceList from "../../dataikudss/list";
import AccountInfo from "./accountInfo";
import CollibraLinks from "../../collibra/CollibraLinks";
import DatabricksInstanceList from "../../databricks/list";

const Log = Logger("AccountView");

type propTypes = {
  api: GraphQl,
  match: {
    path: string,
    url: string,
    params: {
      uriAccount: string
    }
  }
};

type stateTypes = {
  ready: boolean,
  error: boolean,
  account: Object,
  showDataikuDSS: boolean,
  showCollibra: boolean,
  showDatabricks: boolean
};

class AccountView extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      account: undefined,
      showDataikuDSS: false,
      showCollibra: false,
      showDatabricks: false
    };
  }

  componentDidMount() {
    this.isDataikuFeatureEnabled();
    this.isCollibraFeatureEnabled();
    this.isDatabricksFeatureEnabled();
    this.getAccount();
  }

  isDataikuFeatureEnabled() {
    const accountUri = this.props.match.params.uriAccount;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATAIKU", accountUri)
      .then((showDataikuDSS) => {
        this.setState({ showDataikuDSS });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  isCollibraFeatureEnabled() {
    const accountUri = this.props.match.params.uriAccount;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("COLLIBRA", accountUri)
      .then((showCollibra) => {
        this.setState({ showCollibra });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  isDatabricksFeatureEnabled() {
    const accountUri = this.props.match.params.uriAccount;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATABRICKS", accountUri)
      .then((showDatabricks) => {
        this.setState({ showDatabricks });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getAccount = () =>
    this.props.api.account
      .get(this.props.match.params.uriAccount)
      .then((account) =>
        this.setState({
          ready: true,
          account
        })
      )
      .catch((error) => {
        Log.error(error);
        return this.setState({ error });
      });

  getTabs = () => {
    const tabs = [
      {
        name: "Users",
        index: "users",
        route: `${this.props.match.url}#users`,
        content: <UserList uriAccount={this.state.account.uri} />
      },
      {
        name: "Groups",
        index: "groups",
        route: `${this.props.match.url}#groups`,
        content: <GroupsList uriAccount={this.state.account.uri} />
      },
      {
        name: "Environments",
        index: "environments",
        route: `${this.props.match.url}#environments`,
        content: <PlaygroundsList uriAccount={this.state.account.uri} />
      },
      {
        name: "Datasources",
        index: "datasources",
        route: `${this.props.match.url}#datasources`,
        content: <AccountDatasources uriAccount={this.state.account.uri} />
      },
      {
        name: "Policies",
        index: "policies",
        route: `${this.props.match.url}#policies`,
        content: <PolicyManagement uriAccount={this.state.account.uri} />
      },
      {
        name: "Trusts",
        index: "trust",
        route: `${this.props.match.url}#trust`,
        content: <TrustManagement uriAccount={this.state.account.uri} />
      },
      {
        name: "Info",
        index: "info",
        route: `${this.props.match.url}#info`,
        content: <AccountInfo account={this.state.account} />
      }
    ];

    if (this.state.showDataikuDSS) {
      tabs.push({
        name: (
          <span>
            Dataiku DSS<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "dss",
        route: `${this.props.match.url}#dss`,
        content: <DssInstanceList uriAccount={this.state.account.uri} />
      });
    }

    if (this.state.showCollibra) {
      tabs.push({
        name: (
          <span>
            Collibra<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "collibra",
        route: `${this.props.match.url}#collibra`,
        content: <CollibraLinks account={this.state.account} />
      });
    }

    if (this.state.showDatabricks) {
      tabs.push({
        name: (
          <span>
            Databricks<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "databricks",
        route: `${this.props.match.url}#databricks`,
        content: <DatabricksInstanceList account={this.state.account.uri} />
      });
    }

    return tabs;
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"AccountsList"} />;
    if (!this.state.ready) return <Loading message={"Organization"} />;

    return (
      <div className="list-accounts">
        <div className={"accounts"}>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view={`Organization, ${(this.state.account || {}).name || "-"}`}
                helpOn={{
                  content: (
                    <div>
                      <div>
                        GBU:{" "}
                        {this.state.account.gbu.name
                          ? this.state.account.gbu.name
                          : "Unknown"}
                      </div>
                      <div>URI: {this.state.account.uri}</div>
                      <div>
                        Group Responsible Management :{" "}
                        <span className="wrap-word">
                          {this.state.account.is_group_responsible_managed
                            ? "Activated"
                            : "Desactivated"}
                        </span>
                      </div>
                    </div>
                  ),
                  helpCenterLink: `${config.HELP_CENTER}`
                }}
              />
            </div>
          </div>
        </div>
        <div className={"account-blocs"}>
          <Tabs
            isPreload={false}
            defaultActiveIndex={"users"}
            tabs={this.getTabs()}
          />
        </div>
      </div>
    );
  }
}

export default withAppSync(AccountView);
