/* @flow */
import React from "react";
import "../../../datasets/card/card.less";
import routes from "../../../routes";
import withGoTo from "../../../goToHOC";
import withErrorBoundary from "../../../errorBoundaryHOC";
import { findDomainName } from "../../../datasets/components/constants";
import { formatDate } from "../../../../utils/date";
import { convertSize } from "../../../../utils/size";
import DatasetCardTitle from "../../../datasets/card/DatasetCardTitle";
import DatasetCardPublisher from "../../../datasets/card/DatasetCardPublisher";
import DatasetCardContainer from "../../../datasets/card/DatasetCardContainer";
import DatasetCardShared from "../../../datasets/card/DatasetCardShared";

type propTypes = {
  dataset: Object,
  goTo: Function,
  style: Object,
  addDatasetToList: Function,
  removeDatasetToList: Function,
  defaultSelect: boolean
};

type stateTypes = {
  isSelected: boolean
};

class ShareDatasetAddCard extends React.Component<propTypes, stateTypes> {
  DOMRef: Object;

  constructor(props: propTypes) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {
      isSelected: false
    };
  }

  addDatasetToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.addDatasetToList(this.props.dataset);
  };

  removeDatasetToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeDatasetToList(this.props.dataset);
  };

  renderActions() {
    if (this.props.defaultSelect) {
      return (
        <div
          className="butn butn-flat min-width-130"
          onClick={(e) => this.removeDatasetToList(e)}
        >
          <i className={"butn-icon fas fa-check-square"} />
          <span className="butn-text">Unselect this dataset</span>
        </div>
      );
    }
    return (
      <div
        className="butn butn-flat min-width-130"
        onClick={(e) => this.addDatasetToList(e)}
      >
        <i className={"butn-icon far fa-square"} />
        <span className="butn-text">Select this dataset</span>
      </div>
    );
  }

  render() {
    return (
      <DatasetCardContainer
        style={this.props.style}
        ref={this.DOMRef}
        isSelected={this.state.isSelected}
        access={this.props.dataset.access}
        goToDataset={() =>
          this.props.goTo({
            route: routes.Dataset.View,
            params: {
              uriDataset: this.props.dataset.uri
            },
            state: {
              dataset: {
                uri: this.props.dataset.uri,
                name: this.props.dataset.name
              }
            }
          })
        }
        dataset={{
          key: Math.random(),
          uri: this.props.dataset.uri,
          name: this.props.dataset.name
        }}
      >
        <div className="card-dataset-header">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content">
              <i className="far fa-suitcase" />
              <span className="text-header-dataset">
                {findDomainName(this.props.dataset.domain)}
              </span>
            </div>
          </div>
          <div className="card-dataset-end">
            <span className="text-type-dataset">
              {this.props.dataset.type} Data
            </span>
            <DatasetCardShared access={this.props.dataset.access} />
          </div>
        </div>
        <div className="card-dataset-body">
          <div className="card-dataset-body-left">
            <div>
              <DatasetCardTitle
                name={this.props.dataset.name}
                uri={this.props.dataset.uri}
                access={this.props.dataset.access}
                defaultTable={this.props.dataset.default_table}
                type={this.props.dataset.type}
                sourceType={this.props.dataset.source_type}
              />
              <DatasetCardPublisher
                publisher={this.props.dataset.owner.account.name}
                ownergroupname={this.props.dataset.owner.name}
                size={250}
              />
              <div className="card-size-details">
                <p className="card-size-updateDate">
                  <span className="font-weight-bold">Last Data Update : </span>
                  {formatDate(
                    this.props.dataset.s3_inventory_last_update
                  )} | <span className="font-weight-bold">Size : </span>
                  {convertSize(this.props.dataset.size)}
                </p>
              </div>
            </div>
            <div className="card-dataset-details">
              <p className="card-dataset-description">
                {this.props.dataset.description}
              </p>
            </div>
            <div>
              <div className="card-dataset-tags">
                <div className="">
                  {this.props.dataset.tags
                    .split(",")
                    .filter((tag) => tag && tag.trim())
                    .map((tag) => (
                      <div className="dataset-badge" key={tag}>
                        <div className="circle-tag" />
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">{this.renderActions()}</div>
        </div>
        <div className="card-dataset-footer">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content d-flex">
              <i className="fas fa-database"></i>
              <span className="text-header-dataset">
                {this.props.dataset.datasource.name}
              </span>
            </div>
          </div>
        </div>
      </DatasetCardContainer>
    );
  }
}

export default withErrorBoundary(withGoTo(ShareDatasetAddCard), false);
