/* @flow */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withAppSync from "../../../AppsyncHOC";
import { formatDate } from "../../../../utils/date";
import GraphQl from "../../../../graphQL";
import Links from "../../../links";
import Modal from "../../../../components/modal";
import Logger from "../../../../utils/logger";
import { updateMessage } from "../../../globalNotifications/actions";
import "./style.less";

const Log = Logger("SagemakerInstanceRow");

type propTypes = {
  instance: Object,
  withNetworkCol: boolean,
  showGlobalNotification: Function,
  api: GraphQl,
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string,
      networkId: string
    }
  }
};

type stateTypes = {
  loadingStartInstance: boolean,
  loadingStopInstance: boolean,
  autorefreshing: boolean,
  refreshing: boolean,
  stopInstanceModal: boolean,
  status: string,
  errorStop: ?Object
};

class SagemakerInstanceRow extends React.PureComponent<propTypes, stateTypes> {
  interval: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      status: this.props.instance.status,
      loadingStartInstance: false,
      loadingStopInstance: false,
      autorefreshing: false,
      refreshing: false,
      stopInstanceModal: false,
      errorStop: undefined
    };
  }

  componentDidMount() {
    if (
      ["Pending", "Stopping", "Updating"].includes(this.state.status) ||
      this.instanceIsNotUpToDate()
    ) {
      this.autoRefresh();
    }
  }

  instanceIsNotUpToDate = () => {
    const ONE_HOUR = 60 * 60 * 1000;
    return (
      new Date().getTime() - new Date(this.props.instance.updatedat).getTime() <
      ONE_HOUR
    );
  };

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true });

      this.props.api.sagemakerNotebookInstance
        .refreshInstance(this.props.instance.id)
        .then((response) => {
          this.setState({
            status: response.status,
            refreshing: false
          });
          if (["Pending", "Stopping", "Updating"].includes(response.status)) {
            this.autoRefresh();
          } else {
            if (this.interval) {
              clearInterval(this.interval);
              this.interval = undefined;
            }
            this.setState({ autorefreshing: false });
          }
        })
        .catch(() => {
          this.setState({
            refreshing: false,
            autorefreshing: false
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }

          this.props.showGlobalNotification({
            message: "Sagemaker Notebook refresh failed",
            type: "alert"
          });
        });
    }
  };

  startInstance = () => {
    this.setState({ loadingStartInstance: true });

    return this.props.api.sagemakerNotebookInstance
      .start(this.props.instance.id)
      .then((response) => {
        Log.info("Sagemaker Notebook is starting:", response);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook is starting",
          type: "success"
        });
        this.setState({ loadingStartInstance: false, status: response.status });
        this.refreshStatus();
      })
      .catch(() => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook start failed",
          type: "alert"
        });
        this.setState({
          loadingStartInstance: false
        });
      });
  };

  openStopInstanceModal = () =>
    this.setState({ stopInstanceModal: true, errorStop: undefined });

  closeStopInstanceModal = () =>
    this.setState({ stopInstanceModal: false, errorStop: undefined });

  stopInstanceModal = () => (
    <Modal
      title={`Stop the instance Sagemaker Notebook: ${this.props.instance.name}`}
      body={"Are you sure ?"}
      errorMessage={this.state.errorStop}
      actions={
        this.state.errorStop
          ? [
              <button
                type="button"
                className="butn"
                onClick={this.closeStopInstanceModal}
              >
                Close
              </button>
            ]
          : [
              <button
                type="button"
                className="butn butn-delete"
                onClick={this.stopInstance}
              >
                Stop
              </button>,
              <button
                type="button"
                className="butn"
                onClick={this.closeStopInstanceModal}
              >
                Close
              </button>
            ]
      }
    />
  );

  stopInstance = () => {
    this.setState({ loadingStopInstance: true, errorStop: undefined });

    return this.props.api.sagemakerNotebookInstance
      .stop(this.props.instance.id)
      .then((response) => {
        Log.info("Sagemaker Notebook stopped:", response);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook is stopping",
          type: "success"
        });
        this.setState({ loadingStopInstance: false, status: response.status });
        this.refreshStatus();
        this.closeStopInstanceModal();
      })
      .catch((errorStop) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook stop failed",
          type: "alert"
        });
        this.setState({
          loadingStopInstance: false,
          errorStop
        });
      });
  };

  getAction = () => {
    if (this.state.status === "Stopped" || this.state.status === "Failed") {
      return (
        <div onClick={this.startInstance}>
          {this.state.loadingStartInstance && (
            <i className="fas fa-circle-notch fa-spin fa-spacing" />
          )}
          <span className="start-action">Start</span>
        </div>
      );
    }

    if (this.state.status === "InService") {
      return (
        <div onClick={this.openStopInstanceModal}>
          {this.state.loadingStopInstance && (
            <i className="fas fa-circle-notch fa-spin fa-spacing" />
          )}
          <span className="stop-action">Stop</span>
        </div>
      );
    }

    return "";
  };

  render() {
    const { instance } = this.props;
    return (
      <tr className="table-row" key={instance.id}>
        <th className="">{instance.name}</th>
        {!this.props.withNetworkCol && (
          <td className="table-link">
            <Links.Playground.View
              uriAccount={this.props.match.params.uriAccount}
              uriPlayground={this.props.match.params.uriPlayground}
            >
              {instance.environment.name}
            </Links.Playground.View>
          </td>
        )}
        <td className="table-link">
          <Links.Cart.View cartUri={instance.project.uri}>
            {instance.project.name}
          </Links.Cart.View>
        </td>
        <td className="">{instance.instance_type}</td>
        {this.props.withNetworkCol && (
          <td className="table-link">
            <Links.Network.View
              uriAccount={this.props.match.params.uriAccount}
              uriPlayground={this.props.match.params.uriPlayground}
              networkId={instance.network.id}
            >
              {instance.network.name}
            </Links.Network.View>
          </td>
        )}
        <td className="">{formatDate(instance.createdat)}</td>
        <td className="">
          <span
            className={classnames("status-row", {
              "color-green-status": this.state.status === "InService",
              "color-warning-status": this.state.status === "Pending",
              "color-stopped-status": this.state.status === "Stopped",
              "color-error-status": ["Failed", "NotFound", "Error"].includes(
                this.state.status
              )
            })}
          >
            {this.state.status}
          </span>
        </td>
        <td className="">{this.getAction()}</td>
        <td>
          <button
            type="button"
            className="button-refresh-status butn butn-flat"
            onClick={this.refreshStatus}
          >
            {this.state.refreshing || this.state.autorefreshing ? (
              <i className="fas fa-sync-alt fa-spin" />
            ) : (
              <i className="fas fa-sync-alt" />
            )}
          </button>
        </td>
        <div>{this.state.stopInstanceModal && this.stopInstanceModal()}</div>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(SagemakerInstanceRow));
