/* @flow */

import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import Row from "../../../components/row";
import Col from "../../../components/col";
import Routes from "../../routes";
import Error from "../../../components/error";
import { updateMessage } from "../../globalNotifications/actions";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import "./style.less";

const Log = Logger("AddSagemakerNotebook");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      cart: Object
    }
  },
  match: {
    params: {
      cartUri: string
    }
  }
};

type stateTypes = {
  cart: Object,
  platforms: Array<Object>,
  networks: Array<Object>,
  ready: boolean,
  error: boolean,
  errorSubmit: Object,
  selectedValue: {
    name: string,
    environment_uri: string,
    instance_type: string,
    network_id: string,
    enable_shutdown_idle_lifecycle: boolean
  },
  instanceTypes: Array<string>,
  isSubmitting: boolean,
  isFetchingNetworks: boolean
};

class AddSagemakerNotebook extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      instanceTypes: [],
      cart: {},
      isFetchingNetworks: false,
      platforms: [],
      error: false,
      errorSubmit: undefined,
      selectedValue: {},
      networks: [],
      isSubmitting: false
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.cart) {
      this.initiateState(this.props.location.state.cart);
    } else {
      this.props.api.cart
        .getCart(this.props.match.params.cartUri)
        .then((cart) => {
          this.initiateState(cart);
        })
        .catch((error) => {
          Log.error(error);
          this.setState({ error });
        });
    }

    this.props.api.utils
      .getSagemakerNotebookInstanceTypes()
      .then((instanceTypes) => {
        this.setState({ instanceTypes });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  }

  getNetworkList = (platformUri) => {
    this.setState({ isFetchingNetworks: true });
    this.props.api.playground
      .listNetworks(platformUri)
      .then((response) => {
        this.setState({
          networks: response.results,
          isFetchingNetworks: false
        });
        if (response.results.length > 0) {
          this.setState((prevState) => {
            const selectedValue = Object.assign({}, prevState.selectedValue);
            selectedValue.network_id = response.results[0].id;
            return { selectedValue };
          });
        }
      })
      .catch((error) => {
        this.setState({
          error,
          isFetchingNetworks: false
        });
      });
  };

  initiateState = (cart) => {
    const platforms = cart.platforms || [];
    this.setState({
      cart,
      platforms,
      ready: true,
      error: false,
      errorSubmit: false,
      selectedValue: {
        name: "",
        environment_uri: platforms[0] ? platforms[0].uri : "",
        instance_type: "ml.t2.medium",
        network_id: "",
        enable_shutdown_idle_lifecycle: true
      }
    });

    this.getNetworkList(platforms[0].uri);
  };

  handleChange = (field, value) => {
    if (field === "environment_uri") {
      this.setState((prevState) => {
        const selectedValue = Object.assign({}, prevState.selectedValue);
        selectedValue.network_id = "";
        return { selectedValue };
      });
      this.getNetworkList(value);
    }

    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  };

  save = (values, setSubmitting) => {
    const { cart, selectedValue } = this.state;
    this.setState({ errorSubmit: false, isSubmitting: true });

    return this.props.api.sagemakerNotebookInstance
      .create(cart.uri, selectedValue)
      .then(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook created",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-datasciences`,
          params: { cartUri: cart.uri }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook creation failed",
          type: "alert"
        });
      });
  };

  renderNetworkField = () => {
    if (this.state.isFetchingNetworks) {
      return (
        <select
          disabled
          value={"default"}
          className="form-control form-control-sm"
        >
          <option key={"default"} value={"default"} disabled>
            {"Loading..."}
          </option>
        </select>
      );
    }

    if (this.state.networks && this.state.networks.length < 1) {
      return (
        <span className="error-message">
          No networks found on this environment, please contact your
          administrator or select an other environment.{" "}
        </span>
      );
    }

    return (
      <select
        name="network_id"
        onChange={(event) =>
          this.handleChange("network_id", event.target.value)
        }
        className="form-control form-control-sm"
        value={this.state.selectedValue.network_id}
      >
        {[
          <option key={"default"} value={"default"} disabled>
            {"Select a network"}
          </option>
        ].concat(
          this.state.networks.map((network) => {
            if (network.region === this.state.cart.region) {
              return (
                <option key={network.id} value={network.id}>
                  {`${network.subnet_id} | ${network.name}`}
                </option>
              );
            }
            return "";
          })
        )}
      </select>
    );
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"AddSagemakerNotebook"} />;
    if (!this.state.ready)
      return <Loading message={"Sagemaker Notebook form"} />;

    const { selectedValue } = this.state;

    return (
      <div className="add-sagemaker-notebook">
        <Breadcrumb view={"Create a new Sagemaker Notebook"} />
        <Row>
          <Col size={1} />
          <Col size={9}>
            <Formik
              initialValues={{
                name: selectedValue.name,
                environment_uri: selectedValue.environment_uri,
                instance_type: selectedValue.instance_type,
                network_id: selectedValue.network_id,
                enable_shutdown_idle_lifecycle:
                  selectedValue.enable_shutdown_idle_lifecycle
              }}
              validate={() => {
                const errors = {};
                if (!selectedValue.environment_uri) {
                  errors.environment_uri = "An environment is mandatory";
                }

                if (!selectedValue.name) {
                  errors.name = "A name is mandatory";
                }

                if (
                  selectedValue.name &&
                  !/^[a-zA-Z0-9][-a-zA-Z0-9]*[a-zA-Z0-9]$/.test(
                    selectedValue.name
                  )
                ) {
                  errors.name =
                    "The name can only be single characters and digits";
                }

                if (!selectedValue.instance_type) {
                  errors.instance_type = "An instance type is mandatory";
                }

                if (!selectedValue.network_id) {
                  errors.network_id = "The network is mandatory";
                }

                Log.info("validate", selectedValue, errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(values, setSubmitting);
              }}
            >
              {({ errors }) => (
                <Form>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-msg">
                      {"You have some errors. Please, verify all fields."}
                    </div>
                  )}
                  {this.state.errorSubmit && (
                    <Error
                      stringOnly
                      error={this.state.errorSubmit}
                      path={"AddSagemakerNotebook"}
                    />
                  )}
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <div className="label-form-name">
                        <input
                          disabled
                          className="form-control bg-white label-form-name-cdh"
                          value="cdh-"
                        />
                        <Field
                          type="name"
                          name="name"
                          className="form-control bg-white label-form-name-value"
                          placeholder="Name"
                          onChange={(event) =>
                            this.handleChange("name", event.target.value)
                          }
                          value={selectedValue.name}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <div className="mb-3">
                      <label className="label-form">Instance type:</label>
                      <select
                        name="environment"
                        onChange={(event) =>
                          this.handleChange("instance_type", event.target.value)
                        }
                        className="form-control form-control-sm"
                        value={selectedValue.instance_type}
                      >
                        {[
                          <option key={"default"} value={"default"} disabled>
                            {"Select a type"}
                          </option>
                        ].concat(
                          this.state.instanceTypes.map((instanceType) => (
                            <option key={instanceType} value={instanceType}>
                              {instanceType}
                            </option>
                          ))
                        )}
                      </select>
                      <ErrorMessage
                        name="instance_type"
                        component="div"
                        className="error-msg"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-form">Environment:</label>
                      <select
                        name="environment_uri"
                        onChange={(event) =>
                          this.handleChange(
                            "environment_uri",
                            event.target.value
                          )
                        }
                        className="form-control form-control-sm"
                        value={selectedValue.environment_uri}
                      >
                        {[
                          <option key={"default"} value={"default"} disabled>
                            {"Select an environment"}
                          </option>
                        ].concat(
                          this.state.platforms.map((environment) => (
                            <option
                              key={environment.uri}
                              value={environment.uri}
                            >
                              {environment.name}
                            </option>
                          ))
                        )}
                      </select>
                      <ErrorMessage
                        name="environment_uri"
                        component="div"
                        className="error-msg"
                      />
                    </div>

                    <fieldset className="form-group">
                      <legend className="label-form cluster-label-form">
                        Network
                      </legend>
                      {this.renderNetworkField()}
                      <ErrorMessage
                        name="network_id"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <div className="form-group">
                      <div className="facet-criteria-name">
                        <input
                          id="enable_shutdown_idle_lifecycle"
                          type="checkbox"
                          className={"checkbox-facet"}
                          checked={
                            this.state.selectedValue
                              .enable_shutdown_idle_lifecycle
                          }
                          onClick={() =>
                            this.setState((prevState) => ({
                              selectedValue: {
                                ...prevState.selectedValue,
                                enable_shutdown_idle_lifecycle:
                                  !prevState.selectedValue
                                    .enable_shutdown_idle_lifecycle
                              }
                            }))
                          }
                        />
                        <label
                          htmlFor="enable_shutdown_idle_lifecycle"
                          className="fas"
                        >
                          <small className="attribute-label">
                            Automatically stop the instance after inactivity
                          </small>
                        </label>
                      </div>
                    </div>

                    <div className={"ml-1 row justify-content-center"}>
                      <button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        className="butn"
                      >
                        {this.state.isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Create
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(AddSagemakerNotebook));
