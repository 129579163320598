export const getCartPolicies = {
  query: `query Q ($projectUri: String!, $platformUri: String! ) {
        listProjectPlatformPolicies(projectUri: $projectUri, platformUri: $platformUri) {
            policyarn
            description
            activated
        }
    }
    `,
  keyOutput: "listProjectPlatformPolicies"
};
