/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Form, Field, Formik } from "formik";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import { updateMessage } from "../../../globalNotifications/actions";
import SelectClassic from "../../../../components/SelectClassic";
import "./style.less";
import Col from "../../../../components/col";

const Log = Logger("CreateTrustAdmin");

type propTypes = {
  api: GraphQl,
  accountUri: string,
  showGlobalNotification: Function,
  callbackSuccess: Function,
  editTrust: Object
};

type stateTypes = {
  isFetchingPlatforms: boolean,
  platforms: Array<Object>,
  error?: Object,
  errorSubmit?: Object,
  selectedEnv: Object,
  editMode: boolean,
  selectedType: Object,
  checkAllGroups: boolean
};

class CreateTrust extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      errorSubmit: undefined,
      isFetchingPlatforms: true,
      platforms: [],
      selectedEnv: "",
      editMode: false,
      selectedType: {
        label: "AssumeRole",
        value: "AssumeRole"
      },
      checkAllGroups: false
    };
  }

  componentDidMount() {
    const { editTrust } = this.props;
    if (Object.prototype.hasOwnProperty.call(editTrust, "platform")) {
      this.setState({ editMode: true });
      this.setState({
        selectedEnv: {
          label: `${editTrust.platform.name} (${editTrust.platform.aws})`,
          value: editTrust.platform.uri
        }
      });
      this.setState({
        selectedType: { label: editTrust.type, value: editTrust.type }
      });
      this.setState({ checkAllGroups: editTrust.attach_by_default });
    }
    this.getPlatforms();
  }

  getPlatforms = () => {
    this.setState({ isFetchingPlatforms: true, platforms: [] }, () => {
      this.props.api.account
        .listPlatforms(this.props.accountUri, { offset: 0, limit: 10 })
        .then((platforms) => {
          this.setState({
            isFetchingPlatforms: false,
            platforms: platforms.results
          });
        })
        .catch((error) => {
          this.setState({
            isFetchingPlatforms: false,
            error
          });
        });
    });
  };

  createTrust = (values) => {
    this.setState({
      errorSubmit: undefined
    });

    let input;
    if (this.state.editMode) {
      input = {
        attach_by_default: this.state.checkAllGroups,
        type: this.state.selectedType.value,
        description: values.description,
        policy_condition: values.policy_condition
      };

      return this.props.api.cart
        .updateTrustFromCart(this.props.editTrust.id, input)
        .then(() => {
          this.props.showGlobalNotification({
            message: "Trust updated !",
            type: "success"
          });
          this.props.callbackSuccess();
        })
        .catch((errorSubmit) => {
          Log.error(errorSubmit);
          this.props.showGlobalNotification({
            message: "Failed to update trust",
            type: "alert"
          });
          this.setState({
            errorSubmit
          });
        });
    }
    input = { ...values };
    input.platform_uri = this.state.selectedEnv.value;
    input.type = this.state.selectedType.value;
    input.is_admin = true;
    input.groups = [];
    input.attach_by_default = this.state.checkAllGroups;
    return this.props.api.account
      .createTrust(this.props.accountUri, input)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust created !",
          type: "success"
        });
        this.props.callbackSuccess();
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.props.showGlobalNotification({
          message: "Failed to create trust",
          type: "alert"
        });
        this.setState({
          errorSubmit
        });
      });
  };

  render() {
    return (
      <div className="create-trust">
        {this.state.error && (
          <Error error={this.state.error} path="TrustManagement" stringOnly />
        )}
        <Formik
          initialValues={{
            arn: Object.prototype.hasOwnProperty.call(
              this.props.editTrust,
              "platform"
            )
              ? this.props.editTrust.arn
              : "arn:aws:iam::123456789012:role/some/sample-role",
            description: this.props.editTrust.description,
            policy_condition: this.props.editTrust.policy_condition
          }}
          validate={(values) => {
            const errors = {};
            const pattern = new RegExp(
              "^(arn:aws:iam::[0-9]{12}:(role|user|oidc-provider)/[\\w+=,.\\/\\@-]+)|([a-z0-9]+\\.amazonaws\\.com)$"
            );
            if (!pattern.test(values.arn)) {
              errors.arn = `Unable to validate trustee. Please, check that the ARN matches the pattern ${pattern.source}`;
            }
            if (
              values.arn === "arn:aws:iam::123456789012:role/some/sample-role"
            ) {
              errors.arn =
                '"arn:aws:iam::123456789012:role/some/sample-role" is a sample ...';
            }

            if (!this.state.selectedType) {
              errors.selectedType = "Type is mandatory";
            }
            if (!this.state.selectedEnv) {
              errors.selectedEnv = "Environment is mandatory";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            Log.info("Submit, create trust", values);
            return this.createTrust(values).then(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {this.state.errorSubmit && (
                <Error
                  error={this.state.errorSubmit}
                  path={"createTrust"}
                  rawErrorMessage
                  stringOnly
                />
              )}
              {!this.state.error && (
                <div className="create-trust">
                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">
                        SELECT AN ENVIRONMENT *
                      </label>
                      {this.state.editMode && (
                        <Field
                          required
                          className="form-control bg-grey-smooth"
                          placeholder="Select an environment"
                          disabled={this.state.editMode}
                          value={this.state.selectedEnv.label}
                        />
                      )}
                      {!this.state.editMode && (
                        <SelectClassic
                          disabled={this.state.editMode}
                          isLoadingOptions={this.state.isFetchingPlatforms}
                          placeholder="Select an environment"
                          selectedOption={this.state.selectedEnv}
                          options={this.state.platforms.map((p) => ({
                            label: `${p.name} (${p.aws})`,
                            value: p.uri
                          }))}
                          onSelectOption={(option) =>
                            this.setState({ selectedEnv: option })
                          }
                        />
                      )}
                      {errors.selectedEnv && (
                        <Col size={12} style={{ color: "red" }}>
                          {errors.selectedEnv}
                        </Col>
                      )}
                    </div>
                  </div>

                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">SERVICE OR ARN *</label>
                      <Field
                        required
                        name="arn"
                        className={
                          this.state.editMode
                            ? "form-control bg-grey-smooth"
                            : "form-control bg-white"
                        }
                        placeholder="Type service or arn"
                        disabled={this.state.editMode}
                      />
                      {errors.arn && (
                        <Col size={12} style={{ color: "red" }}>
                          {errors.arn}
                        </Col>
                      )}
                    </div>
                  </div>
                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">DESCRIPTION</label>
                      <Field
                        Component="textarea"
                        name="description"
                        className="form-control bg-white"
                        placeholder="Type a description"
                      />
                    </div>
                  </div>

                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">Condition</label>
                      <Field
                        component="textarea"
                        name="policy_condition"
                        className="form-control bg-white mt-1"
                        placeholder="Policy condition..."
                      />
                    </div>
                  </div>
                  <div className="container-form">
                    <div className="width100">
                      <label className="label-form">TYPE *</label>
                      <SelectClassic
                        placeholder={"Select a type"}
                        option={this.state.selectedType}
                        options={[
                          "AssumeRole",
                          "AssumeRoleWithWebIdentity"
                        ].map((elt) => ({
                          label: elt,
                          value: elt
                        }))}
                        selectedOption={
                          this.state.selectedType
                            ? {
                                value: this.state.selectedType.value,
                                label: this.state.selectedType.label
                              }
                            : null
                        }
                        onSelectOption={(option) =>
                          this.setState({ selectedType: option })
                        }
                      />
                      {errors.selectedType && (
                        <Col size={12} style={{ color: "red" }}>
                          {errors.selectedType}
                        </Col>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="width100">
                      <input
                        id="attach_to_all_groups"
                        type="checkbox"
                        checked={this.state.checkAllGroups}
                        onClick={() =>
                          this.setState((prevState) => ({
                            checkAllGroups: !prevState.checkAllGroups
                          }))
                        }
                      />
                      <label
                        className="fas checkbox-groups"
                        htmlFor="attach_to_all_groups"
                      >
                        <span className="label-check-attach">
                          Check to attach by default
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="butn butn-create"
                    >
                      {isSubmitting && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      {this.state.editMode
                        ? "Update Trust"
                        : "Create New Trust"}
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(CreateTrust));
