/* @flow */

import React from "react";
import withErrorBoundary from "../../errorBoundaryHOC";
import withGoTo from "../../goToHOC";
import Logger from "../../../utils/logger";

import "./searchDatasets.less";

const Log = Logger("SearchDataset");

type propTypes = {
  onChangeTerm: Function,
  runSearch: Function,
  term: string
};

type stateTypes = {
  search: Object,
  state: boolean,
  toUpdate: boolean
};

class SearchDataset extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    onChangeTerm: () => {
      Log.warning("onChangeTerm not defined !");
    },
    runSearch: () => {
      Log.warning("runSearch not defined !");
    }
  };

  timeout: TimeoutID;

  onChangeTerm = (e) => {
    const search = e.target.value;
    this.props.onChangeTerm(search);

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.runSearch, 500);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return (
      <div className="search-datasets">
        <div className="search-input">
          <input
            className="form-control"
            placeholder="What data are you looking for ?"
            onChange={this.onChangeTerm}
            value={this.props.term}
          />
          <i className="fas fa-search" />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(withGoTo(SearchDataset));
