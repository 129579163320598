/* @flow */

import React from "react";
import { connect } from "react-redux";
import ItemFromList from "../../../components/itemFromList";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Breadcrumb from "../../../components/breadcrumb";
import Logger from "../../../utils/logger";
import routes from "../../routes";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import LabelMemberGroup from "../../../components/labelMemberGroup";
import GraphQl from "../../../graphQL";
import "./userManagement.less";
import AddUserAction from "./addUserAction";
import ButtonAction from "../../../components/buttonAction";
import ListUsers from "./listUsers";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import { toHex } from "../../../utils/encodeHex";

const Log = Logger("ManageGroupUsers");

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      uriGroup: string
    }
  },
  goTo: Function,
  location: {
    state: {
      group: {
        name: string
      }
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  users: Array<Object>,
  notUsers: Array<Object>,
  error: Object,
  errorFetchNotUsers: ?Object,
  errorFetchUsers: ?Object,
  readyFetchNotUsers: boolean,
  readyFetchUsers: boolean,
  searchUser: string,
  searchNotUser: string,
  group: Object
};
class ManageGroupUsers extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  accountUri: string;

  groupUri: string;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      readyFetchUsers: true,
      readyFetchNotUsers: true,
      errorFetchUsers: undefined,
      errorFetchNotUsers: undefined,
      searchUser: "",
      searchNotUser: "",
      users: [],
      notUsers: [],
      error: undefined,
      group: {
        creator: {
          email: ""
        }
      }
    };

    this.groupUri = props.match.params.uriGroup;
    this.accountUri = getAccountUriFromUri(this.groupUri);
  }

  componentDidMount() {
    this.fetchListUsers();
    this.fetchListNotUsers();
    this.getGroup();
  }

  getGroup() {
    this.props.api.group
      .get(this.groupUri)
      .then((group) => this.setState({ group }));
  }

  fetchListUsers = (searchUser = this.state.searchUser) => {
    this.setState({ readyFetchUsers: false });
    return this.props.api.group
      .listUsers(this.accountUri, this.groupUri, {
        search: { name: searchUser, username: searchUser }
      })
      .then((users) =>
        this.setState({ users, readyFetchUsers: true, searchUser })
      )
      .catch((error) => {
        this.setState({
          errorFetchUsers: error,
          readyFetchUsers: true
        });
      });
  };

  fetchListNotUsers = (searchUser = this.state.searchNotUser) => {
    this.setState({ readyFetchNotUsers: false });
    return this.props.api.group
      .listNotUsers(this.accountUri, this.groupUri, {
        search: { name: searchUser, username: searchUser }
      })
      .then((notUsers) => {
        if (!notUsers) return false;
        this.setState({
          notUsers,
          readyFetchNotUsers: true,
          searchNotUser: searchUser
        });
        return notUsers;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          errorFetchNotUsers: error,
          readyFetchNotUsers: true
        });
      });
  };

  onSearchNotUser = (event) => {
    const keywords = event.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchListNotUsers(keywords);
    }, 500);
  };

  onSearchUser = (event) => {
    const keywords = event.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchListUsers(keywords);
    }, 500);
  };

  removeUserFromGroup = (user) =>
    this.props.api.group
      .removeUser(this.accountUri, this.groupUri, user.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "User removed",
          type: "success"
        });
        this.setState((prevState) => {
          const newNotUsers = prevState.notUsers;
          newNotUsers.push(user);
          const newUsers = prevState.users.filter(
            (element) => element.resource_name !== user.resource_name
          );
          return { notUsers: newNotUsers, users: newUsers };
        });
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "Failed to remove user",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  addUserToGroup = (user) =>
    this.props.api.group
      .addUser(this.accountUri, this.groupUri, user.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "User added",
          type: "success"
        });
        this.setState((prevState) => {
          const newUsers = prevState.users;
          newUsers.push(user);
          const newNotUsers = prevState.notUsers.filter(
            (element) => element.resource_name !== user.resource_name
          );
          return { users: newUsers, notUsers: newNotUsers };
        });
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "Failed to add user",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  render() {
    return (
      <div className="users-management">
        <Breadcrumb
          state={{ ...this.props.location.state }}
          view={`${this.state.group.name || ""} / Manage Users`}
        />

        <div>
          {this.state.error && (
            <Error error={this.state.error} path={"userManagement"} />
          )}
          <div className="double-bloc">
            <div className="left-bloc">
              <div className="label-form">Members:</div>
              <ListUsers
                users={this.state.users.map((user) => (
                  <ItemFromList
                    hideLabelName
                    key={user.uri}
                    name={<LabelMemberGroup user={user} />}
                    actions={[
                      <ButtonAction
                        label="remove"
                        className="butn butn-delete"
                        onClick={() => this.removeUserFromGroup(user)}
                      />
                    ]}
                    onClick={() =>
                      this.props.goTo({
                        route: routes.User.View,
                        params: { uri: toHex(user.uri) }
                      })
                    }
                  />
                ))}
                error={this.state.errorFetchUsers}
                errorPath={"fetchListUsers"}
                isReady={this.state.readyFetchUsers}
                loadingMessage={"users"}
                onSearch={this.onSearchUser}
                className="listUsers"
              />
            </div>
            <div className="right-bloc">
              <div className="label-form">Users available:</div>
              <ListUsers
                users={this.state.notUsers.map((user) => (
                  <ItemFromList
                    hideLabelName
                    key={user.uri}
                    name={<LabelMemberGroup user={user} />}
                    actions={[
                      <AddUserAction
                        onClick={() => this.addUserToGroup(user)}
                      />
                    ]}
                  />
                ))}
                error={this.state.errorFetchNotUsers}
                errorPath={"fetchListNotUsers"}
                isReady={this.state.readyFetchNotUsers}
                loadingMessage={"users not in this group"}
                onSearch={this.onSearchNotUser}
                className="listUsers"
                componentUsersListEmpty={
                  <div>
                    <div className="noResults">0 user found !</div>
                    <button
                      type={"button"}
                      className={"butn"}
                      onClick={() =>
                        this.props.goTo({
                          route: routes.User.Create,
                          params: {
                            uriAccount: this.accountUri
                          }
                        })
                      }
                    >
                      {"Invite a new user"}
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withGoTo(withAppSync(ManageGroupUsers)));
