/* @flow */
import React from "react";
import "./view.less";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  share: {
    datasource: {
      uri: string
    },
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    are_new_datasets_shared: boolean,
    createdat: string,
    updatedat: string
  },
  onEdit: Function,
  onRevoke: Function
};

type stateTypes = {};

class DatasourceShareItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"shares-items card-shadow card bg-white"}>
        <div className="share-item-header">
          <div className="share-item-begin">
            <div className="share-item-begin-content">
              <i className="fas fa-share-alt"></i>
              <span>Datasource Share</span>
            </div>
          </div>
          <div className="card-dataset-end"></div>
        </div>
        <div className="share-item-body">
          <div className="share-item-body-left">
            <div className="share-item-body-details">
              <div className="organization-details">
                <i className="fas fa-globe-europe"></i>
                <span className="organization-name">
                  Organization:
                  <span>{this.props.share.group.account.name}</span>
                </span>
              </div>
              <div className="group-details">
                <i className="fas fa-user-friends"></i>
                <span className="group-name">
                  Group:
                  <span>{this.props.share.group.name}</span>
                </span>
              </div>
              <div className="sharing-future-details">
                <i className="fas fa-check"></i>
                <span className="sharing-future-name">
                  Sharing of future datasets:
                  <span>
                    {this.props.share.are_new_datasets_shared
                      ? "Activated"
                      : "Desactivated"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="share-item-body-right">
            <div className="buttons-actions">
              <div>
                <ButtonAction
                  key={`share-item-edit-${this.props.share.group.uri}`}
                  label="Edit"
                  className="butn"
                  onClick={this.props.onEdit}
                />
              </div>
              <div>
                <ButtonAction
                  key={`share-item-delete-${this.props.share.group.uri}`}
                  label="Revoke"
                  className="butn butn-delete"
                  onClick={this.props.onRevoke}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DatasourceShareItem;
