/* @flow */
import React from "react";
import ToggleButton from "../../../components/toggleButton";

type Props = {
  allColumns: boolean,
  setFieldValue: Function
};

function FormFieldAllColumns({ allColumns, setFieldValue }: Props) {
  return (
    <fieldset>
      <legend className="label-form">All Columns</legend>
      <ToggleButton
        id="all_columns-id"
        checkedValue={!!allColumns}
        onChange={(event) => {
          setFieldValue("all_columns", event.target.checked);
        }}
      />
    </fieldset>
  );
}

export default FormFieldAllColumns;
