/* @flow */
import React from "react";
import classnames from "classnames";
import withAppSync from "../../../AppsyncHOC";
import Links from "../../../links";
import "./style.less";
import Error from "../../../../components/error";
import Paginator from "../../../../components/paginator";
import GraphQl from "../../../../graphQL";
import Breadcrumb from "../../../../components/breadcrumb";
import Loading from "../../../../components/loading";

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string,
      migrationId: string
    }
  },
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  error: ?Object,
  rows: { results: Array<Object>, offset: number, limit: number, total: number }
};

class LakeFormationMigrationReport extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      rows: {
        results: [],
        offset: 0,
        limit: 10,
        total: 0
      }
    };
  }

  componentDidMount() {
    this.getLakeFormationMigrationReportRows();
  }

  getLakeFormationMigrationReportRows = (offset) =>
    this.props.api.playground
      .getLakeFormationMigrationReportRows(
        this.props.match.params.migrationId,
        { offset, limit: 20 }
      )
      .then((rows) => {
        this.setState({
          ready: true,
          rows
        });
        return rows.results;
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
        return [];
      });

  render() {
    if (!this.state.ready) return <Loading message="Migration Report" />;
    return (
      <div className="report-create">
        <div className="title">
          <Breadcrumb view={"Migration Report"} />
        </div>
        {this.state.error && (
          <Error error={this.state.error} path="MigrationReport" />
        )}
        <div className="mt-2">
          <Paginator
            container={(content) => (
              <table className="table">
                <thead>
                  <tr>
                    <th className="" scope="col">
                      PROJECT
                    </th>
                    <th className="" scope="col">
                      ROLE NAME
                    </th>
                    <th className="" scope="col">
                      RESOURCE NAME
                    </th>
                    <th className="" scope="col">
                      RESOURCE TYPE
                    </th>
                    <th className="" scope="col">
                      LAKEFORMATION GRANTS
                    </th>
                    <th className="" scope="col">
                      GRANT STATUS
                    </th>
                    <th className="" scope="col">
                      REFRESH ROLE
                    </th>
                    <th className="" scope="col">
                      ERRORS
                    </th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </table>
            )}
            limit={20}
            initialOffset={0}
            list={this.state.rows.results}
            componentRender={(row) => (
              <tr className="table-row" key={row.role_arn}>
                <td className="">
                  {row.project_uri && row.project_name ? (
                    <Links.Cart.View cartUri={row.project_uri}>
                      {row.project_name}{" "}
                    </Links.Cart.View>
                  ) : (
                    row.role_arn.split("cdh_")[1].split("_")[0]
                  )}
                </td>
                <td className="">{row.role_arn.split("/")[1]}</td>
                <td className="">{row.resource_name}</td>
                <td className="">{row.resource_type}</td>
                <td className="">{row.grants}</td>
                <td className="">
                  <span
                    className={classnames("status-row", {
                      "color-green-status": row.lf_grant_status === "SUCCEEDED",
                      "color-error-status": row.lf_grant_status === "FAILED"
                    })}
                  >
                    {row.lf_grant_status || "-"}
                  </span>
                </td>
                <td className="">
                  {!row.refresh_role ? (
                    "-"
                  ) : (
                    <span
                      className={classnames("status-row", {
                        "color-green-status": row.refresh_role === "SUCCEEDED",
                        "color-error-status": row.refresh_role !== "SUCCEEDED"
                      })}
                    >
                      {row.refresh_role === "SUCCEEDED"
                        ? "SUCCEEDED"
                        : "FAILED"}
                    </span>
                  )}
                </td>
                <td className="">
                  {!row.error && row.refresh_role === "SUCCEEDED"
                    ? "-"
                    : (row.error || " ") + (row.refresh_role || "")}
                </td>
              </tr>
            )}
            totalCount={this.state.rows.total}
            loadPage={this.getLakeFormationMigrationReportRows}
            onNoResult={() => <div>Report is empty.</div>}
          />
        </div>
      </div>
    );
  }
}

export default withAppSync(LakeFormationMigrationReport);
