/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import classnames from "classnames";
import GraphQl from "../../graphQL";
import { updateMessage } from "../globalNotifications/actions";
import Modal from "../../components/modal";
import SelectClassic from "../../components/SelectClassic";
import withAppSync from "../AppsyncHOC";

type propTypes = {
  dataset: {
    uri: string,
    name: string
  },
  datafilter: {
    id: string,
    name: string,
    description: string
  },
  api: GraphQl,
  onEdit: Function,
  hideModal: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: ?string,
  selectedValue: {
    description: string
  },
  isSubmitting: boolean
};

class DataFilterEditModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      selectedValue: {
        description: this.props.datafilter.description
      },
      isSubmitting: false
    };
  }

  handleChange(field, options) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = options;
      return { selectedValue };
    });
  }

  updateDataFilter = () => {
    this.setState({ error: undefined, isSubmitting: true });
    return this.props.api.dataset
      .updateDataFilter(
        this.props.dataset.uri,
        this.props.datafilter.id,
        this.state.selectedValue
      )
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Update succeeded",
          type: "success"
        });
      })
      .then(this.props.onEdit)
      .catch((error) => {
        this.setState({ error, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Update failed",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <Modal
        title={"Update Data Filter"}
        body={
          <div>
            <div
              style={{
                marginBottom: "10px"
              }}
            >
              <Formik
                initialValues={{}}
                validate={() => {}}
                onSubmit={() => {
                  this.updateDataFilter();
                }}
              >
                <Form>
                  <div>
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <SelectClassic
                        placeholder={this.props.datafilter.name}
                        isDisabled
                        selectedOption={{
                          value: this.props.datafilter.id,
                          label: this.props.datafilter.name
                        }}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <legend className="label-form">
                        description:
                        <div
                          className={classnames("filter-description", {
                            "description-is-too-short":
                              (this.state.selectedValue.description || "")
                                .length < 30,
                            "description-is-good":
                              (this.state.selectedValue.description || "")
                                .length >= 30
                          })}
                        >
                          ({(this.state.selectedValue.description || "").length}
                          /{30} characters min)
                        </div>
                      </legend>
                      <div className="label-form-name">
                        <Field
                          name="description"
                          className="form-control bg-white"
                          placeholder="Description"
                          onChange={(event) =>
                            this.handleChange("description", event.target.value)
                          }
                          value={this.state.selectedValue.description}
                          rows={4}
                          component="textarea"
                          validate={() =>
                            (!this.state.selectedValue.description ||
                              this.state.selectedValue.description.length <
                                30) &&
                            `Description is too short (${30} characters min)`
                          }
                        />
                      </div>
                    </fieldset>
                    <div className={"ml-1 row justify-content-center"}>
                      <button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        className="butn"
                        style={{ marginRight: "5px" }}
                      >
                        {this.state.isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Update
                      </button>
                      <button
                        type="button"
                        className="butn butn-flat"
                        onClick={this.props.hideModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        }
        errorMessage={this.state.error}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DataFilterEditModal)
);
