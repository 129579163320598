/* @flow */

import React from "react";
import { withAuth } from "@okta/okta-react";
import YourGroups from "../components/yourGroups";
import "./style.less";
import Tabs from "../../../components/tabs";
import ShareList from "../../share/List";
import Breadcrumb from "../../../components/breadcrumb";
import routes from "../../routes";
import AdminCDH from "../components/admincdh";
import { isUserFromCDHTeam } from "../../../utils/cdhTeam";
import Logger from "../../../utils/logger";
import MyProfile from "../components/MyProfile";
import YourGroupTrusts from "../components/myTrusts/yourGroupTrusts";
import NewtificationsView from "../../notifications/newtificationsView";
import NewtificationBadge from "../../notificationBadge/newtificationBadge";
import UserRecentHistory from "../components/userRecentHistory";

const Log = Logger("dashboard");

type propTypes = {
  auth: {
    getUser: Function
  }
};
type stateTypes = {
  isUserFromCDHTeam: boolean
};

class Dashboard extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isUserFromCDHTeam: false
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    this.props.auth
      .getUser()
      .then((user = {}) => {
        this.setState({
          isUserFromCDHTeam: isUserFromCDHTeam(
            (user.preferred_username || "").split("@")[0]
          )
        });
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  getTabs = () => {
    const tabs = [
      {
        name: "My profile",
        index: "info",
        route: `${routes.User.MyProfile}#info`,
        content: <MyProfile />
      },
      {
        name: "My groups",
        index: "groups",
        route: `${routes.User.MyProfile}#groups`,
        content: (
          <Tabs
            tabs={[
              {
                name: "Groups",
                index: "your_groups",
                content: <YourGroups />
              },
              {
                name: "Trusts",
                index: "your_trusts",
                content: <YourGroupTrusts />
              }
            ]}
          />
        )
      },
      {
        name: (
          <div>
            My Notifications{" "}
            <NewtificationBadge className="notification-profile-badge" />
          </div>
        ),
        route: routes.User.Notifications,
        index: "notifications",
        content: <NewtificationsView />
      },
      {
        name: "My shares",
        index: "shares",
        route: `${routes.User.ShareWithMe}`,
        content: <ShareList />
      },
      {
        name: "My activities",
        index: "activities",
        route: `${routes.User.RecentActivities}`,
        content: <UserRecentHistory />
      }
    ];

    if (this.state.isUserFromCDHTeam) {
      tabs.push({
        name: "Admin cdh",
        index: "cdhadmin",
        route: `${routes.User.CDHAdmin}`,
        content: <AdminCDH />
      });
    }

    return tabs;
  };

  render() {
    return (
      <div className={"dashboard"}>
        <div className="bread-line">
          <div className="title-content">
            <Breadcrumb view="My Profile" hideGoBack />
          </div>
        </div>

        <Tabs
          isPreload={false}
          defaultActiveIndex={"info"}
          noBackgroundWhite
          tabs={this.getTabs()}
        />
      </div>
    );
  }
}

export default withAuth(Dashboard);
