/* @flow */
import React from "react";
import "./view.less";
import GraphQl from "../../../graphQL";
import CartEnvironment from "./CartEnvironment";
import routes from "../../routes";
import Error from "../../../components/error";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";

type propTypes = {
  cart: {
    uri: string,
    region: string,
    platforms: Array<Object>,
    datasets: Array<Object>
  },
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  error: Object
};
class CartEnvironments extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null
    };
  }

  setError = (error: Object) => {
    this.setState({ error });
  };

  render() {
    return (
      <div className={"environments-container"}>
        <div>
          <div
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Playground.Create,
                params: {
                  uriAccount: getAccountUriFromUri(this.props.cart.uri)
                },
                state: {
                  cart: this.props.cart
                }
              })
            }
          >
            <i className="fas fa-plus butn-icon" />
            <span className="butn-text">New Environment</span>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error
              error={this.state.error}
              path={"DatasourceProvidersAdd"}
              stringOnly
            />
          </div>
        )}
        <input type="hidden" id="copyClipboard"></input>
        <div className={"card-container"}>
          {this.props.cart.platforms.length <= 0 && (
            <div style={{ marginLeft: "15px" }}>
              No environments to display.
            </div>
          )}
          {this.props.cart.platforms.map((environment) => (
            <CartEnvironment
              envNumber={this.props.cart.platforms.length}
              key={`${this.props.cart.uri}.${environment.uri}`}
              cart={this.props.cart}
              environment={environment}
              api={this.props.api}
              setError={(error) => this.setError(error)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CartEnvironments;
