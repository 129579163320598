export const getRSNamespaceGroups = {
  query: `query Q ($namespaceUri:String!) {
        getRSNamespace (namespaceUri:$namespaceUri) {
          groups{
            uri 
            name
            description
            tags
            resource_name
            account {
                    uri
                    name
                }
          }
        }
    }
    `,
  keyOutput: "getRSNamespace.groups"
};
