/* @flow */

import React from "react";
import { connect } from "react-redux";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import CreateTrust from "./createTrust";
import TrustList from "./listTrust";
import "./style.less";

type propTypes = {
  uriAccount: string,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  newTrust: boolean,
  editTrust: Object,
  isFetchingTrust: boolean,
  error?: Object
};

class TrustTabCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      newTrust: false,
      editTrust: {},
      isFetchingTrust: false
    };
  }

  editTrust = (trust) => {
    this.setState({ editTrust: trust });
    this.setState((prevState) => ({
      newTrust: !prevState.newTrust
    }));
  };

  render() {
    return (
      <div className="manage-trust">
        {this.state.error && (
          <Error error={this.state.error} path="TrustManagement.list" />
        )}
        {!this.state.isFetchingTrust && (
          <div>
            <div className="create-container">
              <button
                type="button"
                className={!this.state.newTrust ? "butn butn-create" : "butn"}
                onClick={() => {
                  this.setState((prevState) => ({
                    newTrust: !prevState.newTrust
                  }));
                  this.setState({ editTrust: {} });
                }}
              >
                {!this.state.newTrust
                  ? "Create new Trust"
                  : "Return to the list"}
              </button>
            </div>
            <div className="">
              {this.state.newTrust && (
                <CreateTrust
                  api={this.props.api}
                  accountUri={this.props.uriAccount}
                  editTrust={this.state.editTrust}
                  callbackSuccess={() => {
                    this.setState({ newTrust: false });
                  }}
                  showGlobalNotification={this.props.showGlobalNotification}
                />
              )}
              {!this.state.newTrust && (
                <TrustList
                  api={this.props.api}
                  accountUri={this.props.uriAccount}
                  showGlobalNotification={this.props.showGlobalNotification}
                  editTrust={this.editTrust}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(TrustTabCreate)));
