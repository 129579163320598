export const getCSVExportForDatasources = {
  query: `query Q ($input: QueryOptions) {
        getCSVExportForDatasources(input: $input) {
            url
            headers
        }
    }
    `,
  keyOutput: "getCSVExportForDatasources"
};
