export const queryGetStage = {
  query: `query Q ($stageId: String!, $datasetUri: String!) {
        getStage(stageId: $stageId, datasetUri: $datasetUri) {
            id
            name
            table
            description
            is_default
            location
            preview_data_filter_id
            is_table_existing_in_glue_catalog
            profiling_nb_rows
            source_type
            database
            schema
            source_type
            dataset {
                uri
                name
                resource_name
                description
                access
                type
                basic_metadata
                owner {
                    uri
                    name
                }
                datasource {
                    name
                    aws
                    region
                }
            }
            profiling_execution {
                status
                error
                alerts
                statistics
            }
            columns {
                Name
                Type
                Comment
                Description
                Metadata { 
                    Maximum
                    Mean
                    Minimum
                    StdDeviation
                    Completeness{
                        nb_valid
                        prct_valid
                        nb_missing
                        prct_missing
                    }
                    Unique
                    MostCommon{
                        name
                        prct
                    }
                    Histogram{
                        name
                        prct
                        nbtimes
                    }
                }
            }
            preview_data_filter {
                name
                id
                resource_name
            }
        }
    }`,
  keyOutput: "getStage"
};
