/* @flow */
import React from "react";
import { Formik } from "formik";
import Col from "../../../../components/col";
import FormMainPart from "./formMainPart";
import Row from "../../../../components/row";
import Error from "../../../../components/error";
import "./style.less";

type propTypes = {
  isNew: boolean,
  dataset: Object,
  submit: Function,
  errorSubmit: Object,
  handleChange: Function,
  accounts?: Array<Object>,
  platformDatasources?: Array<Object>,
  accountPlatforms?: Array<Object>,
  prefilled?: boolean,
  accountSelected?: Object,
  environmentSelected?: Object,
  datasourceSelected?: Object,
  isLoadingAccounts?: boolean,
  isLoadingPlatforms?: boolean,
  isLoadingDatasources?: boolean,
  totalDatasources?: number,
  loadMoreDatasources?: Function,
  isPrefilledAccount?: boolean,
  isPrefilledPlatform?: boolean,
  isPrefilledDatasource?: boolean
};

class DatasetForm extends React.PureComponent<propTypes> {
  render() {
    const {
      isNew,
      dataset,
      submit,
      errorSubmit,
      handleChange,
      accounts = [],
      accountSelected,
      environmentSelected,
      datasourceSelected,
      isLoadingAccounts = false,
      platformDatasources = [],
      isLoadingPlatforms = false,
      accountPlatforms = [],
      isLoadingDatasources = false,
      totalDatasources,
      loadMoreDatasources,
      prefilled = false,
      isPrefilledAccount = false,
      isPrefilledPlatform = false,
      isPrefilledDatasource = false
    } = this.props;
    return (
      <Formik
        initialValues={{
          name: "",
          description: "",
          tags: [],
          domain: "",
          update_frequency: "",
          accountUri: "",
          platformUri: "",
          languages: "",
          data_types: []
        }}
        validate={() => {
          const errors = {};
          if (!dataset.name || dataset.name.length <= 0)
            errors.name = "Name can not be empty";
          if (!dataset.tags || dataset.tags.length <= 0)
            errors.tags = "List of Tags can not be empty";
          if (
            /^\s*$/.test(dataset.description) &&
            dataset.description.length >= 30
          )
            errors.description = "Description must contain text";
          if (!dataset.languages || dataset.languages.length <= 0)
            errors.languages = "Language is mandatory";
          if (!dataset.domain || dataset.domain === "default")
            errors.domain = "Domain is mandatory";
          if (!dataset.update_frequency)
            errors.update_frequency = "Update frequency is mandatory";
          if (!dataset.languages || dataset.languages.length <= 0)
            errors.languages = "Language is mandatory";
          if (isNew && (!dataset.accountUri || dataset.accountUri.length <= 0))
            errors.accountUri = "Account can not be empty";
          if (
            isNew &&
            (!dataset.platformUri || dataset.platformUri.length <= 0)
          )
            errors.platformUri = "Environment can not be empty";
          if (
            isNew &&
            (!dataset.datasourceUri || dataset.datasourceUri.length <= 0)
          )
            errors.datasourceUri = "Datasource can not be empty";
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) =>
          submit().then(() => setSubmitting(false))
        }
      >
        {({ isSubmitting, handleSubmit, errors, touched }) => (
          <form className="dataset-form" onSubmit={handleSubmit}>
            {errorSubmit && (
              <div className="dataset-form-main-part">
                <Error
                  title={"Create a new dataset, error..."}
                  error={errorSubmit}
                  path={"DatasetForm"}
                  stringOnly
                />
              </div>
            )}
            <FormMainPart
              handleChange={handleChange}
              accounts={accounts}
              isLoadingAccounts={isLoadingAccounts}
              accountPlatforms={accountPlatforms}
              isLoadingPlatforms={isLoadingPlatforms}
              platformDatasources={platformDatasources}
              isLoadingDatasources={isLoadingDatasources}
              loadMoreDatasources={loadMoreDatasources}
              totalDatasources={totalDatasources}
              accountSelected={accountSelected}
              environmentSelected={environmentSelected}
              datasourceSelected={datasourceSelected}
              isNew={isNew}
              dataset={dataset}
              errors={errors}
              prefilled={prefilled}
              isPrefilledAccount={isPrefilledAccount}
              isPrefilledPlatform={isPrefilledPlatform}
              isPrefilledDatasource={isPrefilledDatasource}
              touched={touched}
            />
            <Row style={{ margin: 0 }}>
              <Col size={12}>
                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn"
                  >
                    {isSubmitting && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    {isNew ? "Create Dataset" : "Update"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    );
  }
}

export default DatasetForm;
