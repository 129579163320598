export const listMyEmailNotificationSubscriptionConfig = {
  query: `query Q {
        listMyEmailNotificationSubscriptionConfig {
            dataset {
                id
                name
                description
                enable
            }
            customCDH {
                id
                name
                description
                enable
            }
        }
    }
    `,
  keyOutput: "listMyEmailNotificationSubscriptionConfig"
};
