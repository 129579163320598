export const getPlatformWorkspace = {
  query: `query Q ($platformUri: String!) {
        getWorkspaceByPlatform(uri: $platformUri) {
          uuid
          dbx_workspace_url
          dbx_workspace_name
          dbx_workspace_id
          dbx_warehouse_id
          dbx_account_name
          cdh_env_uri
        }
      }
    `,
  keyOutput: "getWorkspaceByPlatform"
};
