export const listDatasourcesFromProject = {
  query: `
  query Q ($cartUri: String!, $options: QueryOptions) {
            listDatasourcesFromProject(cartUri:$cartUri, options:$options){
                total
                offset
                limit
                results {
                    uri      
                    resource_name      
                    name      
                    bucket      
                    description      
                    tags
                    s3_custom_tags {
                        Key
                        Value
                    }
                    region      
                    accountid      
                    parent      
                    aws      
                    user_roles   
                    kmsalias
                    kmsid 
                    createdat
                    datasetsList {
                        total
                    }
                    owner {          
                        uri          
                        name      
                    }      
                }
            }
        }
`,
  keyOutput: "listDatasourcesFromProject"
};
