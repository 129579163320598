export const listIdentities = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        listAccountIdentities (accountUri: $accountUri, options: $options) {
            total
            results {
                name
                uri
            }
        }
    }
    `,
  keyOutput: "listAccountIdentities"
};
