/* @flow */

import React from "react";
import { understandableErrorMessage } from "../../utils/error";

type propTypesErrorComment = {
  comment?: string,
  rawErrorMessage?: boolean
};

const ErrorComment = ({
  comment = "You can't do this...",
  rawErrorMessage
}: propTypesErrorComment) => (
  <div className="text-dark font-weight-bold">
    {rawErrorMessage ? comment : understandableErrorMessage(comment)}
  </div>
);

export default ErrorComment;
