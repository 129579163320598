/* @flow */
import React from "react";
import route from "../../routes";
import withGoTo from "../../goToHOC";

type propstypes = {
  goTo: Function
};

const NewDataset = ({ goTo }: propstypes) => (
  <div className="create-container">
    <div className="new-dataset">
      <div
        className="butn butn-create"
        onClick={() =>
          goTo({
            route: route.Dataset.Create,
            params: {}
          })
        }
      >
        <i className="fas fa-plus butn-icon" />
        <span className="butn-text">New dataset</span>
      </div>
    </div>
  </div>
);

export default withGoTo(NewDataset);
