/* @flow */

export const addDatasourceProvider = {
  query: `mutation Q ($datasourceUri: String!, $input: CreateDatasourceProviderInput!) {
        addDatasourceProvider(datasourceUri:$datasourceUri,input:$input) {
          arn
          overwrite
          userid
          description
          status
        }
      }
    `,
  keyOutput: "addDatasourceProvider"
};

export type DatasourceProviderInput = {
  arn: string,
  overwrite: boolean,
  description: string,
  userid: string,
  status?: string
};
