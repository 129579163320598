export const listDatasourceLogs = {
  query: `query Q ($datasourceUri: String!, $options: QueryOptions) {
        getRecentResourceJobHistory(uri:$datasourceUri, options: $options) {
          total
          results {
            actionid
            createdat
            updatedat
            status
            aws
            targeturi
            type
          }
        }
      }
    `,
  keyOutput: "getRecentResourceJobHistory"
};
