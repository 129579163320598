/* @flow */

import React from "react";
import Select from "react-select";

type propTypes = {
  className?: string,
  placeholder?: string,
  options?: Array<{ value: string | boolean, label: string }>,
  selectedOption?: { value: string | boolean, label: string } | null,
  onSelectOption?: Function,
  isDisabled?: boolean,
  isSearchable?: boolean,
  isLoadingOptions?: boolean,
  isMulti?: boolean,
  isClearable?: boolean
};

const SelectClassic = ({
  placeholder,
  className,
  options,
  selectedOption,
  onSelectOption = () => {},
  isDisabled = false,
  isSearchable = false,
  isLoadingOptions = false,
  isMulti = false,
  isClearable = false
}: propTypes) => {
  if (isLoadingOptions) {
    return <Select placeholder={"Loading..."} isDisabled />;
  }
  if (isDisabled) {
    return (
      <Select
        key={Math.random()} // use different keys, to build a new select component
        placeholder={placeholder}
        isDisabled
      />
    );
  }
  return (
    <Select
      className={className}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      value={selectedOption}
      onChange={onSelectOption}
      isMulti={isMulti}
      isClearable={!!isClearable}
    />
  );
};

export default SelectClassic;
