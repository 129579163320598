export const getNetwork = {
  query: `query Q ($id:String!) {
        getNetwork(id:$id) {
            id
            name
            security_group_ids
            subnet_ids
            vpc_id
            region
            description
            createdat
            updatedat
            platform
            proxy
            noproxy
            network_type
            sagemakerNotebookInstances {
                total
                results {
                    id
                    name
                    instance_type
                    arn
                    status
                    createdat
                    updatedat
                    error
                    region
                    network {
                        id
                        name
                        subnet_ids
                        security_group_ids
                    }
                    project {
                        name
                        uri
                    }
                    environment {
                        environment
                        aws
                        name
                    }
                }
            }
        }
      }
    `,
  keyOutput: "getNetwork"
};
