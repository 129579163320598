/* @flow */

import React from "react";
import { connect } from "react-redux";
import Loading from "../../../../../components/loading";
import Row from "../../../../../components/row";
import Col from "../../../../../components/col";
import withAppSync from "../../../../AppsyncHOC";
import Error from "../../../../../components/error";
import GraphQl from "../../../../../graphQL";
import { updateMessage } from "../../../../globalNotifications/actions";
import { getErrorMessageWithoutCode } from "../../../../../utils/error";
import SelectInfinite from "../../../../../components/SelectInfinite";
import SelectClassic from "../../../../../components/SelectClassic";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  match: {
    params: {
      clusterUri: string
    }
  },
  redshiftCluster: Object,
  reloadGroups: Function
};

type stateTypes = {
  ready: boolean,
  groupReady: boolean,
  isFetching: boolean,
  accounts: Array<Object>,
  groups: Array<Object>,
  groupUri: string,
  accountUri: string,
  error: Object,
  errorSubmit: Object,
  totalGroups: number,
  groupOptionSelected: Object | null,
  orgaSelected: Object | null
};

class RedshiftClusterGrantAccess extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      groupReady: false,
      isFetching: false,
      accounts: [],
      groups: [],
      totalGroups: 0,
      groupUri: "",
      accountUri: "",
      error: undefined,
      errorSubmit: undefined,
      orgaSelected: null,
      groupOptionSelected: null
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () =>
    this.props.api.account
      .getAllAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  getGroupsFromAccount = (offset = 0) =>
    this.props.api.account
      .listGroups(this.state.accountUri, { offset, limit: 10 })
      .then((response) => {
        const groups = response && response.results ? response.results : [];
        this.setState({
          groups,
          totalGroups: response.total,
          groupReady: true
        });
        return groups.map((g) => ({ label: g.name, value: g.uri }));
      })
      .catch((error) => {
        this.setState({ error });
        return [];
      });

  onSelectAccount = (orgaSelected) => {
    this.setState(
      {
        orgaSelected,
        accountUri: orgaSelected.value,
        groups: [],
        groupUri: undefined,
        errorSubmit: undefined,
        groupReady: false,
        groupOptionSelected: null
      },
      this.getGroupsFromAccount
    );
  };

  onSelectGroup = (groupOptionSelected) => {
    this.setState({
      groupOptionSelected,
      groupUri: groupOptionSelected.value,
      errorSubmit: undefined
    });
  };

  onSubmit = () => {
    if (!this.state.groupUri) {
      return this.setState({ errorSubmit: "Choose a group before to share." });
    }

    this.setState({ errorSubmit: undefined, isFetching: true });

    return this.props.api.analytics
      .grantRedshiftClusterAccess(
        this.state.groupUri,
        this.props.redshiftCluster.uri
      )
      .then(() => {
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Group granted Redshift cluster access",
          type: "success"
        });
        this.props.reloadGroups();
      })
      .catch((error) => {
        this.setState({
          errorSubmit: getErrorMessageWithoutCode(error),
          isFetching: false
        });
        this.props.showGlobalNotification({
          message: "Failed to grant group access to Redshift cluster",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div className={"share-cluster-by-owner"}>
        {this.state.error && (
          <Error error={this.state.error} path={"RequestForm"} stringOnly />
        )}
        {!this.state.ready && <Loading message={"Groups data"} />}
        {!this.state.error && this.state.ready && (
          <div>
            <div>
              <div className="mt-4">
                <label className="label-form">Select an organization : </label>
                <SelectClassic
                  placeholder="Select an organization"
                  options={this.state.accounts.map((a) => {
                    if (a.uri === "uri:account:000000")
                      return { value: a.uri, label: "All Engie" };
                    return { value: a.uri, label: a.name };
                  })}
                  selectedOption={this.state.orgaSelected || null}
                  onSelectOption={this.onSelectAccount}
                />
              </div>

              <div className="mt-4">
                <label className="label-form">and Group : </label>
                <SelectInfinite
                  isLoadingOptions={
                    !this.state.groupReady && !!this.state.accountUri
                  }
                  placeholder={
                    this.state.accountUri
                      ? "Select a group"
                      : "Select an organisation first"
                  }
                  isDisabled={!this.state.accountUri}
                  initialOptions={(this.state.accountUri
                    ? this.state.groups
                    : []
                  ).map((g) => ({ value: g.uri, label: g.name }))}
                  selectedOption={this.state.groupOptionSelected}
                  onSelectOption={this.onSelectGroup}
                  totalOptions={this.state.totalGroups}
                  loadMoreOptions={this.getGroupsFromAccount}
                  optionResults
                />
              </div>
            </div>

            <Row className="justify-content-center mt-4">
              {this.state.errorSubmit && (
                <Col size={12} style={{ color: "red", paddingBottom: "10px" }}>
                  {this.state.errorSubmit}
                </Col>
              )}

              <button
                type={"button"}
                onClick={this.onSubmit}
                className={"butn"}
              >
                {this.state.isFetching && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Grant access to cluster
              </button>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(RedshiftClusterGrantAccess)
);
