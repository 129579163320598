import { getDatabricksAccount } from "./queryGetDatabricksAccount";
import { getDatabricksAccountCredentials } from "./queryGetDatabricksAccountCredentials";
import { updateDatabricksAccountCredentialsManual } from "./mutationUpdateDatabricksAccountCredentialsManual";
import { updateDatabricksAccountCredentialsAuto } from "./mutationUpdatDatabricksAccountCredentialsAuto";
import { getPlatformWorkspace } from "./queryGetPlatformWorkspace";
import { registerDatabricksAccount } from "./mutationRegisterDatabricksAccount";
import { getDatabricksWorkspaceDetails } from "./queryGetDatabricksWorkspaceDetails";
import { registerDatabricksWorkspace } from "./mutationRegisterDatabricksWorkspace";
import { registerGroupOnDatabricksWorkspace } from "./mutation-register-group-on-dbx-workspace";
import { revokeGroupFromDatabricksWorkspace } from "./mutation-revoke-group-on-dbx-workspace";
import { updateDatabricksAccountName } from "./mutationUpdateDatabricksAccountName";
import { listDatabricksAccountOrgs } from "./queryListDatabricksAccountOrgs";
import { unregisterDatabricksAccount } from "./mutationUnregisterDatabricksAccount";
import { getDatabricksWorkspace } from "./queryGetDatabricksWorkspace";
import { listDatabricksAccountWorkspaces } from "./queryListDatabricksAccountWorkspaces";
import { listDatabricksAccountGroups } from "./queryListDatabricksGroups";
import { listAccountDatabricksAccounts } from "./queryListAccountDatabricksAccounts";
import { getWorkspacesAssociatedToGroups } from "./queryGetWorkspacesAssociatedToGroups";
import { getWorkspaceFromCart } from "./queryGetWorkspaceFromCart";
import { registerDatabricksWorkspaceCatalog } from "./mutationRegisterDatabricksWorkspaceCatalog";
import { registerDatabricksData } from "./mutationRegisterDatabricksData";
import { unregisterDatabricksData } from "./mutationUnregisterDatabricksData";
import { listDatabricksCatalogs } from "./queryListDatabricksCatalogs";
import { getCartForAccount } from "./queryGetCartForAccount";

class DatabricksApi {
  fetchAppSync;

  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  getCartForAccount(cartUri) {
    return this.fetchAppSync(getCartForAccount, { cartUri });
  }

  getWorkspaceFromCart(cartUri) {
    return this.fetchAppSync(getWorkspaceFromCart, { cartUri });
  }

  getWorkspacesAssociatedToGroups(groupUri) {
    return this.fetchAppSync(getWorkspacesAssociatedToGroups, { groupUri });
  }

  getDatabricksAccount(uuid: string) {
    return this.fetchAppSync(getDatabricksAccount, { uuid });
  }

  getDatabricksAccountCredentials(uuid) {
    return this.fetchAppSync(getDatabricksAccountCredentials, { uuid });
  }

  getDatabricksPlatformWorkspace(platformUri) {
    return this.fetchAppSync(getPlatformWorkspace, { platformUri });
  }

  getDatabricksWorkspaceDetails(dbx_workspace_uuid, options = {}) {
    return this.fetchAppSync(getDatabricksWorkspaceDetails, {
      dbx_workspace_uuid,
      options
    });
  }

  getDatabricksWorkspace(uuid) {
    return this.fetchAppSync(getDatabricksWorkspace, { uuid });
  }

  unregisterDatabricksData(
    cart_uri,
    catalog_name,
    dataset_uri,
    dbx_workspace_uuid
  ) {
    return this.fetchAppSync(unregisterDatabricksData, {
      cart_uri,
      catalog_name,
      dataset_uri,
      dbx_workspace_uuid
    });
  }

  registerDatabricksWorkspaceCatalog(
    cart_uri,
    catalog_name,
    dbx_workspace_uuid
  ) {
    return this.fetchAppSync(registerDatabricksWorkspaceCatalog, {
      cart_uri,
      catalog_name,
      dbx_workspace_uuid
    });
  }

  registerDatabricksData(cart_uri, catalog_name, dbx_workspace_uuid) {
    return this.fetchAppSync(registerDatabricksData, {
      cart_uri,
      catalog_name,
      dbx_workspace_uuid
    });
  }

  updateDatabricksAccountCredentialsAuto(uuid) {
    return this.fetchAppSync(updateDatabricksAccountCredentialsAuto, { uuid });
  }

  updateDatabricksAccountCredentialsManual(
    dbx_credentials_client_secret_id,
    dbx_credentials_client_secret_value,
    dbx_credentials_service_principal_id,
    uuid
  ) {
    return this.fetchAppSync(updateDatabricksAccountCredentialsManual, {
      dbx_credentials_client_secret_id,
      dbx_credentials_client_secret_value,
      dbx_credentials_service_principal_id,
      uuid
    });
  }

  // @params : uuid of dbks_account
  listDatabricksCatalogs(uuid) {
    return this.fetchAppSync(listDatabricksCatalogs, { uuid });
  }

  listDatabricksAccountGroups(uuid: string) {
    return this.fetchAppSync(listDatabricksAccountGroups, { uuid });
  }

  listDatabricksAccountWorkspaces(uuid: string, options) {
    return this.fetchAppSync(listDatabricksAccountWorkspaces, {
      uuid,
      options
    });
  }

  updateDatabricksAccountName(name, uuid) {
    return this.fetchAppSync(updateDatabricksAccountName, { name, uuid });
  }

  listDatabricksAccountOrgs(uuid: string, options: QueryOptions) {
    return this.fetchAppSync(listDatabricksAccountOrgs, {
      uuid,
      options
    });
  }

  listAccountDatabricksAccounts(accountUri, options) {
    return this.fetchAppSync(listAccountDatabricksAccounts, {
      accountUri,
      options
    });
  }

  registerDatabricksAccount(inputData) {
    return this.fetchAppSync(registerDatabricksAccount, { inputData });
  }

  registerDatabricksWorkspace(inputData) {
    return this.fetchAppSync(registerDatabricksWorkspace, { inputData });
  }

  registerGroupOnDatabricksWorkspace(groupuri, uuid) {
    return this.fetchAppSync(registerGroupOnDatabricksWorkspace, {
      groupuri,
      uuid
    });
  }

  revokeGroupFromDatabricksWorkspace(groupuri, uuid) {
    return this.fetchAppSync(revokeGroupFromDatabricksWorkspace, {
      groupuri,
      uuid
    });
  }

  unregisterDatabricksAccount(cdh_org_uri, uuid) {
    return this.fetchAppSync(unregisterDatabricksAccount, {
      cdh_org_uri,
      uuid
    });
  }
}

export default DatabricksApi;
