export const dryRunLakeFormationMigration = {
  query: `mutation Q ($platformUri : String!, $input: NewLakeFormationMigrationInput!) {
        dryRunLakeFormationMigration(platformUri : $platformUri, input: $input) {
          id
          region
          report {
           id
          }
          type
          status
          stepfunctions
          platform_uri
        }
      }
    `,
  keyOutput: "dryRunLakeFormationMigration"
};
