/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../globalNotifications/actions";
import FormDataset from "../components/form";
import Routes from "../../routes";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import GraphQl from "../../../graphQL";

const Log = Logger("DatasetEdit");

type propTypes = {
  goTo: Function,
  location: {
    pathname: string
  },
  api: GraphQl,
  match: {
    params: {
      uriDataset: string
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  dataset: Object,
  error?: Object,
  errorSubmit?: Object
};

class DatasetEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      dataset: {
        name: "",
        type: "structured",
        details: {
          status: "Active"
        },
        datasource: {
          is_registered_with_lakeformation: false
        }
      },
      error: false,
      errorSubmit: false
    };
  }

  componentDidMount() {
    this.props.api.dataset
      .get(this.props.match.params.uriDataset)
      .then((dataset) => {
        let details = {};
        try {
          details = JSON.parse(dataset.details);
        } catch (error) {
          details = {};
        }

        this.setState({
          dataset: {
            ...dataset,
            details,
            tags: (dataset.tags || "")
              .split(",")
              .filter((tag) => tag && tag.trim()),
            confidentiality: "",
            refresh_sla: "",
            datasourceRegisteredWithLakeFormation:
              dataset.datasource.is_registered_with_lakeformation
          },
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  handleChange = (field: string) => (event) => {
    const { value = "" } = event.target;
    Log.info(field, "<-", value);
    return this.setState((prevState) => {
      const dataset = Object.assign({}, prevState.dataset);
      if (field.startsWith("details")) {
        dataset.details[field.slice(8)] = value;
      } else {
        dataset[field] = value;
      }

      return {
        dataset,
        errorSubmit: false
      };
    });
  };

  submit = () =>
    this.update()
      .then(() => {
        this.props.showGlobalNotification({
          message: "Dataset updated",
          type: "success"
        });
        this.props.goTo({
          route: Routes.Dataset.View,
          params: { uriDataset: this.props.match.params.uriDataset }
        });
      })
      .catch((err) => {
        Log.error(err);
        this.setState({
          errorSubmit: err
        });
        this.props.showGlobalNotification({
          message: "Dataset update failed",
          type: "alert"
        });
      });

  update = () => {
    const { details } = this.state.dataset;
    if (
      details.external_description_url &&
      !details.external_description_url.startsWith("http")
    ) {
      details.external_description_url = `https://${details.external_description_url}`;
    }
    const input = {
      name: this.state.dataset.name,
      description: this.state.dataset.description,
      tags: (this.state.dataset.tags || [])
        .filter((tag) => tag && tag.trim())
        .join(","),
      owneruri: this.state.dataset.owner.uri,
      type: this.state.dataset.type,
      domain:
        this.state.dataset.domain !== "default"
          ? this.state.dataset.domain
          : "OTR",
      confidentiality: this.state.dataset.confidentiality,
      refresh_sla: this.state.dataset.refresh_sla,
      last_loaded: this.state.dataset.last_loaded,
      details: JSON.stringify(details),
      period_start_at: this.state.dataset.period_start_at,
      period_end_at: this.state.dataset.period_end_at,
      languages: this.state.dataset.languages,
      data_types: this.state.dataset.data_types,
      basic_metadata: this.state.dataset.basic_metadata,
      metadata_frequency: this.state.dataset.metadata_frequency || "never",
      update_frequency: this.state.dataset.update_frequency,
      maturity_level: this.state.dataset.maturity_level || 0,
      visibility: this.state.dataset.visibility,
      shareable: this.state.dataset.shareable,
      is_registered_with_lakeformation:
        this.state.dataset.is_registered_with_lakeformation
    };
    return this.props.api.dataset.update(
      this.props.match.params.uriDataset,
      input
    );
  };

  render() {
    Log.info("state dataset form: ", this.state);
    if (this.state.error) {
      return <Error error={this.state.error} path={"DatasetEdit"} />;
    }
    if (!this.state.ready) {
      return <Loading className={"my-3"} message={"Dataset"} />;
    }
    return (
      <React.Fragment>
        <Breadcrumb view="Edit Dataset" />
        <FormDataset
          submit={this.submit}
          isNew={false}
          dataset={this.state.dataset}
          handleChange={this.handleChange}
          errorSubmit={this.state.errorSubmit}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DatasetEdit));
