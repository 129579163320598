/* @flow */

import React from "react";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import CopyToClipboard from "../../../../components/copyToClipboard";
import Error from "../../../../components/error";
import Logger from "../../../../utils/logger";

const Log = Logger("ODBCConnection");

type propTypes = {
  match: {
    params: {
      clusterUri: string,
      cartUri: string
    },
    url: string
  },
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  ODBCInfos: Object,
  error: boolean
};

class RedshiftODBCConnection extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      ODBCInfos: {}
    };
  }

  componentDidMount() {
    this.getODBCInfos();
  }

  getODBCInfos = () => {
    this.props.api.analytics
      .getOBDCConnection(
        this.props.match.params.cartUri,
        this.props.match.params.clusterUri
      )
      .then((ODBCInfos) => {
        this.setState({ ODBCInfos, ready: true });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, ready: true });
      });
  };

  render() {
    if (!this.state.ready) return <Loading message="ODBC Informations" />;
    if (this.state.error)
      return <Error error={this.state.error} path={"ODBCInfo"} />;
    return (
      <div>
        <h4>Connection Settings</h4>
        <div className="form-group">
          <label className="label-form">Data Source Name (DSN)</label>
          <div className="input-group">
            <input
              disabled
              name="dsn"
              className="form-control form-control-sm"
              placeholder="Data source name"
              value={this.state.ODBCInfos.redshift_dsn}
            />
            <CopyToClipboard
              text={this.state.ODBCInfos.redshift_dsn}
              className={"fas fa-copy btn-carre"}
              label={""}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-form">Server</label>
          <div className="input-group">
            <input
              disabled
              name="server"
              className="form-control form-control-sm"
              placeholder="Server"
              value={this.state.ODBCInfos.server}
            />
            <CopyToClipboard
              text={this.state.ODBCInfos.server}
              className={"fas fa-copy btn-carre"}
              label={""}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-form">Port</label>
          <div className="input-group">
            <input
              disabled
              name="port"
              className="form-control form-control-sm"
              placeholder="Port"
              value={this.state.ODBCInfos.port}
            />
            <CopyToClipboard
              text={this.state.ODBCInfos.port}
              className={"fas fa-copy btn-carre"}
              label={""}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-form">Database Name</label>
          <div className="input-group">
            <input
              disabled
              name="DatabaseName"
              className="form-control form-control-sm"
              placeholder="Database Name"
              value={this.state.ODBCInfos.database_name}
            />
            <CopyToClipboard
              text={this.state.ODBCInfos.database_name}
              className={"fas fa-copy btn-carre"}
              label={""}
            />
          </div>
        </div>
        <h4>Authentification</h4>
        <div className="form-group">
          <label className="label-form">Authentification Type</label>
          <div className="input-group">
            <input
              disabled
              name="Authentificationtype"
              className="form-control form-control-sm"
              placeholder="Authentification Type"
              value="Standard"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-form">User</label>
          <div className="input-group">
            <input
              disabled
              name="User"
              className="form-control form-control-sm"
              placeholder="User"
              value={this.state.ODBCInfos.database_user}
            />
            <CopyToClipboard
              text={this.state.ODBCInfos.database_user}
              className={"fas fa-copy btn-carre"}
              label={""}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="label-form">Password</label>
          <div className="input-group">
            <input
              disabled
              name="Password"
              className="form-control form-control-sm"
              placeholder=""
              value="Last active password from redshift credentials"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withAppSync(RedshiftODBCConnection);
