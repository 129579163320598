/* @flow */
import React from "react";
import Col from "../../../components/col";
import ItemFromList from "../../../components/itemFromList";
import "./group.less";
import GroupName from "./groupName";
import withGoTo from "../../goToHOC";
import routes from "../../routes";

type propTypes = {
  group: {
    uri: string,
    name: string,
    description: string,
    account: {
      uri: string,
      name: string
    },
    platformsList?: {
      total: number
    },
    cartsList?: {
      total: number
    },
    identitiesList?: {
      total: number
    }
  },
  goTo: Function
};

class Group extends React.Component<propTypes> {
  goTo = () => {
    this.props.goTo({
      route: routes.Group.View,
      params: {
        uriGroup: this.props.group.uri
      }
    });
  };

  render() {
    return (
      <Col size={12} className={"mgroup"}>
        <ItemFromList
          key={this.props.group.uri}
          name={<GroupName group={this.props.group} />}
          hideLabelName
          description={this.props.group.description}
          onClick={this.goTo}
          className={"mgroup-container"}
        />
      </Col>
    );
  }
}

export default withGoTo(Group);
