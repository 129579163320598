/* @flow */
import React from "react";
import Links from "../../links/index";
import "./view.less";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import GraphQl from "../../../graphQL";
import Row from "../../../components/row";
import Col from "../../../components/col";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import DatasourceETLCredentials from "./DatasourceETLCredentials";
import HelpOn from "../../../components/helpOn";
import DatasourceETLSessionCredentials from "./DatasourceETLSessionCredentials";

type propTypes = {
  datasource: {
    uri: string,
    parent: string,
    etluser_credentials_description: string,
    etluser_credentials_creator: string,
    etluser_credentials_expiration_date: string
  },
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  isFetching: boolean
};

class DatasourceAccess extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false
    };
  }

  fetchUrl = () => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true }, () =>
        this.props.api.datasource
          .getDatasourceAdminCredentials(this.props.datasource.uri)
          .then((credentials) => {
            if (credentials && credentials.ConsoleUrl)
              window.open(credentials.ConsoleUrl, "_blank");
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          })
      );
    }
  };

  render() {
    const { datasource } = this.props;
    return (
      <React.Fragment>
        <div className={"datasource-providers"}>
          <div className="d-flex">
            <h5>ETL Credentials</h5>
            <HelpOn
              content={
                <div>
                  <h3>ETL Credentials</h3>
                  <div>
                    Check our documentation on Confluence (
                    <a
                      href="https://confluence.tools.digital.engie.com/display/CDHA/ETL+credentials+tutorial"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ETL Credentials
                    </a>
                    )
                  </div>
                </div>
              }
            />
          </div>

          <ul className={"list-group"}>
            <li className={"list-group-item"}>
              <DatasourceETLCredentials
                datasource={datasource}
                api={this.props.api}
                goTo={this.props.goTo}
              />
            </li>
            <li className={"list-group-item"}>
              <DatasourceETLSessionCredentials
                datasource={datasource}
                api={this.props.api}
                goTo={this.props.goTo}
              />
            </li>
          </ul>
          <div className="administrator-access">
            <div className={"title"}>
              <h2>Administrator zone</h2>
              <p>
                This administrative access gives you rights only on your bucket
                in S3 service. If you would like to use Amazon Athena, AWS Glue,
                etc, you will need to create a CDH project and access your
                datasets within the project.
              </p>
            </div>
            <ul>
              <li className={"list-group-item"}>
                <Row>
                  <Col
                    size={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    Get administrator temporary credentials
                  </Col>
                  <Col size={6} className="datasource-temporary-credentials">
                    <Links.Datasource.GenerateAdminCredentials
                      style={{ float: "right" }}
                      uriAccount={getAccountUriFromUri(datasource.uri)}
                      uriDatasource={datasource.uri}
                      state={{
                        account: {
                          uri: getAccountUriFromUri(datasource.uri)
                        },
                        playground: { uri: datasource.parent },
                        datasource
                      }}
                    >
                      <div className="butn butn-flat">
                        Temporary credentials
                      </div>
                    </Links.Datasource.GenerateAdminCredentials>
                  </Col>
                </Row>
              </li>
              <li className={"list-group-item"}>
                <Row>
                  <Col
                    size={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    Get administrator temporary console access
                  </Col>
                  <Col size={6}>
                    <div
                      style={{ float: "right" }}
                      className="butn butn-flat min-width-160"
                      onClick={this.fetchUrl}
                    >
                      {this.state.isFetching && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      Console Access
                    </div>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(withGoTo(DatasourceAccess));
