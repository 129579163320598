export const listProjectRSDatasources = {
  query: `query Q ($cartUri:String!, $options: QueryOptions) {
        listProjectRSDatasources (cartUri: $cartUri, options: $options) {
            results {
                parent
                uri 
                name
                bucket
                region
                description
                accountid
                createdat
                user_roles
                database
                aws
                owner {
                    name
                    resource_name
                    uri
                }
                datasetsList {
                    total
                }
                source_type
                RSDatasource{
                 endpoint
                 namespace_status
                 namespace_error
                 namespace_id
                 namespace_name
                 jdbc_url
                 odbc_url
                 db_name
                 db_group
                 db_username
                 db_password
                }
            }
            total
            offset
            limit
                
        }
    }`,
  keyOutput: "listProjectRSDatasources"
};
