/* @flow */

import React from "react";
import "./trustName.less";

type propTypes = {
  trust: {
    arn: string,
    description: String,
    type: string,
    is_admin: boolean,
    id: string,
    nb_groups: number,
    attach_by_default: boolean,
    platform: {
      name: string,
      uri: string,
      aws: string
    },
    cart: {
      uri: string,
      name: string,
      group: {
        name: string
      }
    }
  }
};

const TrustName = ({ trust = {} }: propTypes) => (
  <div className="trust-name">
    {trust.is_admin && <div className="trust-admin-text">Admin Trust</div>}
    <div className="trust-platorm">
      <span>
        <span className="platform-name">{`[${trust.platform.name}]`}</span>
        <span className="trust-arn">{trust.arn}</span>
      </span>
    </div>
    <div className="trust-description">
      <span>
        <span className="description-title">Description :</span>
        <span className="description">{trust.description}</span>
      </span>
    </div>
    <div className="trust-type">
      <span>
        <span className="type-title">Trust Type :</span>
        <span className="type-text">{trust.type}</span>
      </span>
    </div>
    <div className="trust-groups">
      <span>
        <span className="nb-groups-title">Groups attached :</span>
        <span className="nb-groups">{trust.nb_groups}</span>
      </span>
    </div>
    <div className="trust-groups">
      <span>
        <span className="nb-groups-title">Attach by default :</span>
        <span className="nb-groups">
          {trust.attach_by_default ? "yes" : "no"}
        </span>
      </span>
    </div>
    {!trust.is_admin && (
      <div className="trust-cart">
        <span className="cart-infos">
          - Trust created from project
          <span className="cart-info-bold">{trust.cart.name}</span>(
          {trust.cart.uri}) by group
          <span className="cart-info-bold">{trust.cart.group.name}</span> -
        </span>
      </div>
    )}
  </div>
);

export default TrustName;
