/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import classnames from "classnames";
import GraphQl from "../../graphQL";
import withGoTo from "../goToHOC";
import { updateMessage } from "../globalNotifications/actions";
import withAppSync from "../AppsyncHOC";
import DeleteObjectWithFrictionModal from "../../components/DeleteObjectWithFrictionModal";
import logoCollibra from "./collibra-logo.png";
import "./style.less";
import { formatDate } from "../../utils/date";

type propTypes = {
  collibraLink: Object,
  api: GraphQl,
  reloadLinks: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  errorConsoleAccess?: Object,
  openDeleteModal: boolean,
  openDeleteDatasetsModal: boolean,
  errorDelete: Object,
  error: Object,
  refreshing: boolean
};

class CollibraLink extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      openDeleteModal: false,
      openDeleteDatasetsModal: false,
      errorDelete: null,
      error: null,
      refreshing: false
    };
  }

  getCollibraConsoleAccess = () => {
    window.open(this.props.collibraLink.url, "_blank");
  };

  deleteCollibraLink = () => {
    this.props.api.collibra
      .delete(this.props.collibraLink.account_uri, this.props.collibraLink.id)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Collibra link planned for deletion",
          type: "success"
        });
        this.setState({ openDeleteModal: false });
        this.props.reloadLinks();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  indexCollibraLinkDatasets = () => {
    this.props.api.collibra
      .indexCollibraLinkDatasets(
        this.props.collibraLink.account_uri,
        this.props.collibraLink.id
      )
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Organization Datasets planned for indexing on Collibra. " +
            "It may take few minutes before datasets are available on Collibra!",
          type: "success"
        });
        this.props.reloadLinks();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  removeCollibraLinkDatasets = () => {
    this.props.api.collibra
      .removeCollibraLinkDatasets(
        this.props.collibraLink.account_uri,
        this.props.collibraLink.id
      )
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Organization Datasets planned for removal from Collibra. " +
            "It may take few minutes before datasets are completely deleted!",
          type: "success"
        });
        this.setState({ openDeleteDatasetsModal: false });
        this.props.reloadLinks();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  render() {
    const { collibraLink } = this.props;

    return (
      <li className="cart-card card-shadow card bg-white">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <img
                className="connector-logo-img mr-2"
                src={logoCollibra}
                alt="Collibra"
                width={15}
                height={15}
              />
              Collibra Link
            </div>
          </div>
          <div className="cdh-cart-end mr-2">
            <div className={`cart-environment-tag ${collibraLink.envname}`}>
              <span>{collibraLink.envname}</span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <div>
              <img
                className="connector-logo-img mr-1"
                src={logoCollibra}
                alt="Collibra"
                width={26}
                height={26}
              />
              <span className="cdh-connection-name">
                <a
                  href={`${collibraLink.url}/domain/${collibraLink.domain_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {collibraLink.domain_name || collibraLink.domain_id}
                </a>
              </span>
            </div>
            <div className="cdh-card-details">
              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>
                    Last publish:{" "}
                    {collibraLink.last_indexedat
                      ? formatDate(collibraLink.last_indexedat)
                      : "NOT_LAUNCHED"}
                  </b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-heartbeat" />
                <span className="content-card-bucket">
                  <b>Last publish status: {collibraLink.last_index_status}</b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>
                    Last unpublish:{" "}
                    {collibraLink.last_wipedat
                      ? formatDate(collibraLink.last_wipedat)
                      : "NOT_LAUNCHED"}
                  </b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-heartbeat" />
                <span className="content-card-bucket">
                  <b>Last unpublish status:</b>{" "}
                  <b>{collibraLink.last_wipe_status}</b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-paper-plane" />
                <span className="content-card-bucket">
                  <b>Publish public datasets:</b>{" "}
                  <b>{collibraLink.index_public_datasets ? "Yes" : "No"}</b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-paper-plane" />
                <span className="content-card-bucket">
                  <b>
                    Publish internal datasets:{" "}
                    <b>{collibraLink.index_internal_datasets ? "Yes" : "No"}</b>
                  </b>
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>Created: {formatDate(collibraLink.createdat)}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div className="btn-cart" onClick={this.getCollibraConsoleAccess}>
                <img
                  className="connector-logo-img"
                  src={logoCollibra}
                  alt="Collibra"
                  width={15}
                  height={15}
                />
                <span className="text-actions"> Collibra Access</span>
              </div>
              <div
                className="btn-cart"
                onClick={this.indexCollibraLinkDatasets}
              >
                <i className="fas fa-bars fa-spacing" />
                <span className="text-actions"> Publish Datasets</span>
              </div>
              <div
                className="btn-cart"
                onClick={() => this.setState({ openDeleteDatasetsModal: true })}
              >
                <i className="fa fa-eraser fa-spacing" />
                <span className="text-actions"> Delete Datasets</span>
              </div>
              <div
                className="btn-cart"
                onClick={() => this.setState({ openDeleteModal: true })}
              >
                {this.state.openDeleteModal && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete Link</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <div className="text-footer-metrics text-uppercase">
              Auto Publish
              <span
                className={classnames("auto-publish-tag", {
                  "green-status": collibraLink.auto_publish,
                  "warning-status": !collibraLink.auto_publish
                })}
              >
                {collibraLink.auto_publish ? "Enabled" : "Disabled"}
              </span>
            </div>
          </div>
          <div className="cdh-card-end">
            <button
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={() => {
                this.setState({ refreshing: true });
                this.props.reloadLinks();
                this.setState({ refreshing: false });
              }}
            >
              {this.state.refreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
          </div>
        </div>
        {this.state.openDeleteDatasetsModal && (
          <DeleteObjectWithFrictionModal
            objectName={"Indexed Datasets"}
            deleteMessage={
              "This will delete all your CDH organization datasets from Collibra!"
            }
            onApply={() => this.setState({ openDeleteDatasetsModal: false })}
            onClose={() => this.setState({ openDeleteDatasetsModal: false })}
            open={() => this.setState({ openDeleteDatasetsModal: true })}
            deleteFunction={this.removeCollibraLinkDatasets}
            isAWSResource={false}
          />
        )}
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={"Collibra Link"}
            deleteMessage={
              "Deleting Collibra link does not delete already indexed datasets !"
            }
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => this.setState({ openDeleteModal: false })}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteCollibraLink}
            isAWSResource={false}
          />
        )}
        {this.state.error && (
          <div className="IAM-error">
            <Alert severity="error">{this.state.error.message}</Alert>
          </div>
        )}
        {this.state.errorDelete && (
          <div className="IAM-error">
            <Alert severity="error">{this.state.errorDelete.message}</Alert>
          </div>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CollibraLink)));
