/* @flow */

import React, { Component } from "react";

type propTypes = {
  defaultData: Array<Object>,
  onChange: Function,
  showLabel: boolean
};

type stateTypes = {
  data: Array<Object>
};

class CheckBoxList extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      data: this.props.defaultData || []
    };
  }

  handleItemChange = (e: Object) => {
    const selectedValues = [];
    const newData = [];

    this.state.data.forEach((item) => {
      if (item.value === e.target.value) {
        item.checked = e.target.checked;
      }
      if (item.checked) {
        selectedValues.push(item.value);
      }
      newData.push(item);
    });

    this.setState({ data: newData });

    if (this.props.onChange) {
      this.props.onChange(selectedValues);
    }
  };

  reset = () => {
    const newData = [];
    this.state.data.forEach((item) => {
      item.checked = false;
      newData.push(item);
    });

    this.setState({ data: newData });
  };

  render() {
    const options = this.state.data.map((item) => (
      <div>
        <label>
          <input
            type="checkbox"
            className={"checkbox-import"}
            value={item.value}
            onChange={this.handleItemChange}
            checked={!!item.checked}
          />{" "}
          {this.props.showLabel && item.label}
        </label>
      </div>
    ));

    return <div>{options}</div>;
  }
}

export default CheckBoxList;
