/* eslint-disable camelcase */
/* @flow */

import React from "react";
import "./style.less";
import { formatDate } from "../../../../utils/date";

type propTypes = {
  network: Object
};

type stateTypes = {};

class NetworkSubDetails extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    const { network } = this.props;
    const {
      name,
      region,
      subnet_ids,
      security_group_ids,
      createdat,
      updatedat,
      description,
      proxy,
      noproxy,
      network_type
    } = network;

    return (
      <div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fa fa-network-wired dataset-detail-icon"></i>
            Name
          </div>
          <span>{name}</span>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fa fa-globe-europe dataset-detail-icon"></i>
            Region
          </div>
          <span>{region}</span>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fa fa-box dataset-detail-icon"></i>
            Type
          </div>
          <span>{network_type}</span>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fab fa-aws dataset-detail-icon"></i>
            Subnet Ids
          </div>
          <span>{subnet_ids.join(", ")}</span>
        </div>
        {proxy && (
          <div className="dataset-detail-element">
            <div className="dataset-detail-title">
              <i className="fas fa-network-wired dataset-detail-icon"></i>
              Proxy
            </div>
            <span>{proxy}</span>
          </div>
        )}
        {noproxy && (
          <div className="dataset-detail-element">
            <div className="dataset-detail-title">
              <i className="fas fa-network-wired dataset-detail-icon"></i>
              Proxy exclusions
            </div>
            <span>{noproxy}</span>
          </div>
        )}
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fab fa-aws dataset-detail-icon"></i>
            Security Group Ids
          </div>
          <span>{security_group_ids.join(", ")}</span>
        </div>
        <div className="dataset-detail-double-element">
          <div className="dataset-detail-element-left">
            <div className="dataset-detail-title">
              <i className="fas fa-calendar-day dataset-detail-icon"></i>
              Created on
            </div>
            <span>{formatDate(createdat)}</span>
          </div>
          <div className="dataset-detail-element-right">
            <div className="dataset-detail-title">
              <i className="fas fa-calendar-day dataset-detail-icon"></i>
              Updated on
            </div>
            <span>{formatDate(updatedat)}</span>
          </div>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">Description</div>
          <p className="dataset-detail-description">
            {description || "No description"}
          </p>
        </div>
      </div>
    );
  }
}

export default NetworkSubDetails;
