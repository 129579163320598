export const listMyCarts = {
  query: `query Q ($options: QueryOptions $datasetUri: String) {
        getMyCartsList (options: $options, datasetUri: $datasetUri) {
            offset
            limit
            total
            results {
                access
                uri
                groupuri
                name
                project_custom_tags {
                    Key
                    Value
                }
                creatoruri
                description
                resource_name
                region
                createdat
                datasetsList {
                    total
                }
                group {
                    uri
                    name
                }
            }
        }
    }
    `,
  keyOutput: "getMyCartsList"
};
