export const getDataConsumptionDashboard = {
  query: `query Q {
        getDataConsumptionDashboard {
            id
            gid
            createdat
            updatedat
            email_config
        }
    }
    `,
  keyOutput: "getDataConsumptionDashboard"
};
