export const queryGetDatasetPreview = {
  query: `query  Q ($datasetUri:String!, $defaultTable:String, $stageId: String){
         getDatasetPreview(datasetUri:$datasetUri, defaultTable:$defaultTable, stageId: $stageId) 
       }
     `,
  keyOutput: "getDatasetPreview"
};

export const queryGetDatasetPreviewStatus = {
  query: `query  Q ($datasetUri:String!, $queryId:String!){
         getDatasetPreviewStatus(datasetUri:$datasetUri, queryId:$queryId) 
       }
     `,
  keyOutput: "getDatasetPreviewStatus"
};

export const queryGetDatasetPreviewResult = {
  query: `query  Q ($datasetUri:String!, $queryId:String!){
         getDatasetPreviewResult(datasetUri:$datasetUri, queryId:$queryId) 
       }
     `,
  keyOutput: "getDatasetPreviewResult"
};
