export const addToBookmark = {
  query: `mutation Q ($datasetUri: String!) {
        createBookmark(datasetUri: $datasetUri)
      }
    `,
  keyOutput: "createBookmark"
};

export const removeFromBookmark = {
  query: `mutation Q ($datasetUri: String!) {
        deleteBookmark(datasetUri: $datasetUri)
      }
    `,
  keyOutput: "deleteBookmark"
};
