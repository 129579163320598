export const listAllMyDatasources = {
  query: `query Q ($options: QueryOptions) {
        listAllMyDatasources(options:$options){
            parent
            uri 
            name
            bucket
            region
            description
            accountid
            createdat
            user_roles
            owner {
                name
                resource_name
                uri
            }
            datasetsList {
                total
            }
        }
      }
    `,
  keyOutput: "listAllMyDatasources"
};
