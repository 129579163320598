/* @flow */

import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import SelectClassic from "../SelectClassic";
import Logger from "../../utils/logger";

const Log = Logger("SelectInfinite");

type propstypesSelect = {
  totalOptions: number,
  loadMoreOptions: Function,
  onSelectOption: Function,
  selectedOption?: Object,
  placeholder?: string,
  initialOptions?: Array<{ value: string, label: string }>,
  isDisabled?: boolean,
  isLoadingOptions?: boolean,
  isSearchable?: boolean,
  isClearable?: boolean,
  isMulti?: boolean,
  optionResults?: boolean
};
class SelectInfinite extends React.PureComponent<propstypesSelect> {
  render() {
    if (this.props.isLoadingOptions || this.props.isDisabled) {
      return (
        <SelectClassic
          placeholder={this.props.placeholder}
          isLoadingOptions={this.props.isLoadingOptions}
          isDisabled={this.props.isDisabled}
        />
      );
    }

    return (
      <AsyncPaginate
        options={this.props.initialOptions}
        value={this.props.selectedOption}
        placeholder={this.props.placeholder}
        isDisabled={this.props.isDisabled}
        loadOptions={(search, page) => {
          if (this.props.loadMoreOptions) {
            return this.props
              .loadMoreOptions(page.length, search)
              .then((newOptions) => ({
                options: newOptions,
                hasMore:
                  search || newOptions.length === 0
                    ? false
                    : page.length + newOptions.length <= this.props.totalOptions
              }));
          }
          Log.warning("loadMoreOptions not define");
          return {
            options: [],
            hasMore: false
          };
        }}
        loadOptionsOnMenuOpen={this.props.initialOptions === undefined} // Checker le comportement
        onChange={(optionSelected) =>
          optionSelected
            ? this.props.onSelectOption(
                this.props.optionResults ? optionSelected : optionSelected.value
              )
            : this.props.onSelectOption("")
        }
        isSearchable={!!this.props.isSearchable}
        isClearable={!!this.props.isClearable}
        isMulti={!!this.props.isMulti}
      />
    );
  }
}

export default SelectInfinite;
