/* @flow */
import React from "react";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import routes from "../../routes";
import NetworkCard from "./components/networkCard";
import "./style.less";
import Paginator from "../../../components/paginator";

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  goTo: Function,
  api: GraphQl
};

type stateTypes = {
  networks: Array<Object>,
  ready: boolean,
  error: ?Object,
  total: number,
  limit: number
};

class PlaygroundNetwork extends React.PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      networks: [],
      ready: false,
      error: undefined,
      total: 0,
      limit: 10
    };
  }

  componentDidMount() {
    this.getNetworkList();
  }

  getNetworkList = (offset = 0) =>
    this.props.api.playground
      .listNetworks(this.props.uriPlayground, { offset, limit: 10 })
      .then((response) => {
        this.setState({
          ready: true,
          networks: response.results,
          total: response.total,
          limit: response.limit
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="networkList" />;
    if (!this.state.ready) return <Loading message="Networks List" />;
    return (
      <React.Fragment>
        <div className="actions-network">
          <button
            className="butn butn-flat"
            type="button"
            onClick={() =>
              this.props.goTo({
                route: routes.Network.Create,
                params: {
                  uriAccount: this.props.uriAccount,
                  uriPlayground: this.props.uriPlayground
                }
              })
            }
          >
            <i className="fas fa-plus" />
            <span className="butn-text">Create</span>
          </button>
        </div>
        <div className="list-network">
          <div className="list-network-flex" style={{ marginTop: "10px" }}>
            {this.state.networks.length > 0 ? (
              <Paginator
                limit={this.state.limit}
                initialOffset={0}
                list={this.state.networks}
                componentRender={(network) => (
                  <NetworkCard
                    uriAccount={this.props.uriAccount}
                    uriPlayground={this.props.uriPlayground}
                    network={network}
                    reload={this.getNetworkList}
                  />
                )}
                loadPage={this.getNetworkList}
                totalCount={this.state.total}
              />
            ) : (
              <div>No networks to display.</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(PlaygroundNetwork);
