/* @flow */
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./style.less";
import { formatDate } from "../../../../utils/date";

type propTypes = {
  metadata: Object
};

class DisplayMetadataDate extends React.PureComponent<propTypes> {
  render() {
    if (!this.props.metadata) {
      return false;
    }
    const options = {
      chart: { type: "column", height: 200, width: 400 },
      title: { text: "" },
      xAxis: {
        categories: this.props.metadata.Histogram.map((r) => r.name)
      },
      yAxis: {
        title: ""
      },
      series: [
        {
          name: "Frequency",
          data: this.props.metadata.Histogram.map((r) => [
            r.name,
            parseFloat(r.nbtimes)
          ])
        }
      ]
    };
    return (
      <div className="content-analysis-container">
        <div>
          <div className="metadata-title">
            <span>Content analysis</span>
          </div>
          <div className="metadata-container">
            <div className="metadata-date-container">
              <div>
                <span className="metadata-prop">Minimum</span>
                <span className="metadata-value">
                  {formatDate(this.props.metadata.Minimum)}
                </span>
              </div>
              <div>
                <span className="metadata-prop">Maximum</span>
                <span className="metadata-value">
                  {formatDate(this.props.metadata.Maximum)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="metadata-graph-container">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayMetadataDate;
