/* @flow */
import React from "react";
import Modal from "../../../../components/modal";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import ButtonAction from "../../../../components/buttonAction";

type propTypes = {
  network: Object,
  showGlobalNotification: Function,
  goBack: Function,
  hide: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string
};

class NetworkModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null
    };
  }

  deleteNetwork = () => {
    const { network, goBack, showGlobalNotification } = this.props;
    return this.props.api.playground
      .deleteNetwork(network.id)
      .then(() =>
        showGlobalNotification({
          message: "Network deleted",
          type: "success"
        })
      )
      .then(goBack)
      .catch((error) => {
        showGlobalNotification({
          message: "Network deletion failed",
          type: "alert"
        });
        this.setState({ error });
      });
  };

  render() {
    const { network, hide } = this.props;
    const { error } = this.state;
    return (
      <Modal
        title={`Delete network ${network.name}`}
        body={!error && "Are you sure ?"}
        errorMessage={error}
        actions={[
          <button
            key={`network-delete-${network.subnet_id}`}
            type="button"
            className="butn"
            onClick={hide}
          >
            Close
          </button>,
          !error && (
            <ButtonAction
              key={`network-delete-${network.subnet_id}`}
              label="Delete"
              className="butn butn-delete"
              onClick={this.deleteNetwork}
            />
          )
        ]}
      />
    );
  }
}

export default withAppSync(NetworkModal);
