/* @flow */
import React from "react";
import { connect } from "react-redux";
import { formatDate } from "../../../../utils/date";
import "./datasetSchemaStatus.less";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";

type propTypes = {
  dataset: Object,
  crawler: Object,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  isRefreshingStatus: boolean,
  isLoading: boolean,
  schemaStatusItem: Object,
  error: Object
};

const s3PathFormating = (s3Path) => {
  const temp = s3Path.split("/");
  temp.splice(-1, 1);
  return temp.join("/");
};

class DatasetSchemaStatusItem extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  constructor(props) {
    super(props);
    this.state = {
      isRefreshingStatus: false,
      isLoading: false,
      error: undefined,
      schemaStatusItem: {
        environmentName: this.props.dataset.uri.split(":")[3],
        crawler: this.props.crawler,
        details: DatasetSchemaStatusItem.getDetails(this.props.crawler),
        S3Path: DatasetSchemaStatusItem.getS3Path(this.props.crawler)
      }
    };
  }

  componentDidMount() {
    if (this.hasToBeRefreshed(this.state.schemaStatusItem.crawler.status)) {
      this.autoRefresh();
    }
  }

  static getS3Path(crawler: Object) {
    const details = DatasetSchemaStatusItem.getDetails(crawler);
    let pathS3 = "";
    if (details.Targets) {
      if (details.Targets.S3Targets.length > 0) {
        pathS3 = s3PathFormating(details.Targets.S3Targets[0].Path);
      } else if (details.Targets.DeltaTargets.length > 0) {
        pathS3 = s3PathFormating(
          details.Targets.DeltaTargets[0].DeltaTables[0]
        );
      }
    }
    return pathS3;
  }

  componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  hasToBeRefreshed = (statusCrawler) =>
    ["pending", "RUNNING", "STOPPING", "READY"].includes(statusCrawler);

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ isRefreshingStatus: true });
      this.interval = setInterval(this.getDatasetCrawlerStatus, 5000);
    }
  };

  static getStatusClassName(status: string) {
    let statusClassName = "";

    switch (status) {
      case "NOT FOUND":
      case "fail":
      case "DELETED":
        statusClassName = "badge-log badge-log-danger";
        break;
      case "success":
      case "READY":
      case "RUNNING":
        statusClassName = "badge-log badge-log-success";
        break;
      default:
        statusClassName = "badge-log badge-log-primary";
    }
    return statusClassName;
  }

  static getDetails(crawler: Object) {
    let details = JSON.parse(crawler.details);
    if (
      !crawler.details ||
      !details ||
      details === "null" ||
      details === "false"
    ) {
      details = {
        Name: "N/A",
        DatabaseName: "N/A",
        LastUpdated: "N/A",
        Targets: {
          DeltaTargets: [{ DeltaTables: "N/A" }],
          S3Targets: [{ Path: "N/A" }]
        }
      };
    }
    return details;
  }

  getDatasetCrawlerStatus = () => {
    if (this.state.isLoading) return false;

    this.setState({ isRefreshingStatus: true, isLoading: true });
    return this.props.api.dataset
      .getDatasetCrawlerStatus(
        this.props.dataset.uri,
        this.state.schemaStatusItem.crawler.prefix
      )
      .then((crawler) => {
        this.setState({
          isLoading: false,
          schemaStatusItem: {
            environmentName: this.props.dataset.uri.split(":")[3],
            crawler,
            details: DatasetSchemaStatusItem.getDetails(crawler),
            S3Path: DatasetSchemaStatusItem.getS3Path(crawler)
          },
          isRefreshingStatus: this.hasToBeRefreshed(crawler.status)
        });

        if (!this.hasToBeRefreshed(crawler.status)) {
          this.props.showGlobalNotification({
            message: "Schema status refreshed",
            type: "success"
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        }
      })
      .catch((datasetCrawlerStatusError) => {
        this.props.showGlobalNotification({
          message: "Schema status refresh failed",
          type: "alert"
        });
        this.setState({
          error: datasetCrawlerStatusError,
          isRefreshingStatus: false,
          isLoading: false
        });
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      });
  };

  render() {
    const { isRefreshingStatus, error } = this.state;
    const { crawler, environmentName, details, S3Path } =
      this.state.schemaStatusItem;

    return (
      <div className={"dataset-schema-status-item mt-1 border log-container"}>
        <div>
          <div>
            <div
              className={DatasetSchemaStatusItem.getStatusClassName(
                crawler.status
              )}
            >
              {crawler.status}
            </div>
          </div>
          <div className="log-details">
            <p>
              <i className="pr-3 fas fa-box" />
              <span className="label-display">ENVIRONMENT : </span>
              {environmentName}
            </p>
            <p>
              <i className="pr-3 fa fa-folder" />
              <span className="label-display">PREFIX : </span>
              {crawler.prefix || "N/A"}
            </p>
            <p>
              <i className="pr-3 fas fa-bug" />
              <span className="label-display">CRAWLER NAME : </span>
              {details.Name}
            </p>
            <p>
              <i className="pr-3 fas fa-database" />
              <span className="label-display">DATABASE NAME : </span>
              {details.DatabaseName}
            </p>
            <p>
              <i className="pr-2 fab fa-aws" />
              <span className="label-display">S3 PATH : </span>
              {S3Path}
            </p>
          </div>
          <div className="refresh-button-container">
            {error && (
              <Error
                error={error}
                path={"DatasetSchemaStatusItem"}
                stringOnly
              />
            )}
            <div
              className="butn butn-flat"
              onClick={this.getDatasetCrawlerStatus}
            >
              {isRefreshingStatus ? (
                <i className="fas fa-sync-alt fa-spin fa-spacing" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
              <span className="butn-text">Refresh status</span>
            </div>
          </div>
          <div className="date-log-container">
            <p className="date-log">
              {crawler.updatedat ? formatDate(crawler.updatedat) : ""}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasetSchemaStatusItem)
);
