/* @flow */

export const putDatasourceReplication = {
  query: `mutation Q ($originalDatasourceUri: String!, $replicationDatasourceUri: String!, $datasetsUriList: [String], $replicationStorageClass: String) {
                putDatasourceReplication(originalDatasourceUri: $originalDatasourceUri, replicationDatasourceUri: $replicationDatasourceUri, datasetsUriList: $datasetsUriList, replicationStorageClass: $replicationStorageClass) {
                    source_datasource_uri
                    replication_datasource_uri
                    is_entire_bucket
                    replication_id
                    status
                    storage_class
                }
            }
    `,
  keyOutput: "putDatasourceReplication"
};

export const putDatasourceReplicationWithoutStorageClass = {
  query: `mutation Q ($originalDatasourceUri: String!, $replicationDatasourceUri: String!, $datasetsUriList: [String]) {
                putDatasourceReplication(originalDatasourceUri: $originalDatasourceUri, replicationDatasourceUri: $replicationDatasourceUri, datasetsUriList: $datasetsUriList) {
                    source_datasource_uri
                    replication_datasource_uri
                    is_entire_bucket
                    replication_id
                    status
                }
            }
    `,
  keyOutput: "putDatasourceReplication"
};
