export const getPlaygroundName = {
  query: `
  query Q ($accountUri: String!, $platformUri: String!) {
    getPlatform(accountUri: $accountUri, platformUri: $platformUri) {
      name
      uri
    }
  }
`,
  keyOutput: { playground: "getPlatform" }
};
