/* @flow */
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Logger from "../../../../../utils/logger";
import { updateMessage } from "../../../../globalNotifications/actions";
import withAppSync from "../../../../AppsyncHOC";
import withGoTo from "../../../../goToHOC";
import GraphQl from "../../../../../graphQL";
import logoPowerBI from "./powerbi.svg";
import logoAthena from "./athena-logo.png";
import Error from "../../../../../components/error";
import ButtonAction from "../../../../../components/buttonAction";
import Modal from "../../../../../components/modal";
import ToggleButton from "../../../../../components/toggleButton";
import HelpOn from "../../../../../components/helpOn";

const Log = Logger("CartIntegrationsPowerBI");

type propTypes = {
  cart: {
    uri: string,
    name: string,
    is_attached_to_powerbi: boolean,
    platforms: Array<Object>,
    platformAttachedPowerBi: Object
  },
  api: GraphQl,
  showGlobalNotification: Function,
  reloadCart: Function
};

type stateTypes = {
  error?: Object,
  errorDetach?: Object,
  isAttached: boolean,
  isFetching: boolean,
  modal: boolean,
  modalEnv: boolean,
  envs: Array<Object>,
  envSelected: Object,
  currentEnv: Object,
  status?: string,
  enablePowerBiAthenaConnector: boolean,
  ssmDocumentName: ?string
};

class CartIntegrationsPowerBI extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      errorDetach: undefined,
      isFetching: false,
      isAttached: props.cart.is_attached_to_powerbi,
      modal: false,
      modalEnv: false,
      envs: [],
      envSelected: null,
      currentEnv: null,
      status: undefined,
      enablePowerBiAthenaConnector: true,
      ssmDocumentName: null
    };
  }

  async componentDidMount() {
    this.getSSMDocumentName();
    this.getEnvs();
    if (this.props.cart.is_attached_to_powerbi) {
      this.checkStatus();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  getSSMDocumentName = async () => {
    const ssmDocumentName = await this.props.api.cart.getSSMDocumentName(
      this.props.cart.uri
    );
    this.setState({
      ssmDocumentName
    });
  };

  getStatusPBI = () => {
    this.props.api.cart
      .getCommandStatus(this.props.cart.uri)
      .then((status) => {
        if (["InProgress", "Pending"].includes(status)) {
          return false;
        }

        return this.setState(
          {
            status
          },
          () => {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        );
      })
      .catch((error) => {
        Log.error(error);
        return this.setState(
          {
            status: "FAILED"
          },
          () => {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        );
      });
  };

  checkStatus = () => {
    this.interval = setInterval(this.getStatusPBI, 5000);
  };

  getEnvs = () => {
    if (Number(this.props.cart.platforms.length) >= Number(1)) {
      this.setState({ envSelected: this.props.cart.platforms[0].uri });
    }
    this.setState({ envs: this.props.cart.platforms });
    this.setState({ currentEnv: this.props.cart.platformAttachedPowerBi });
  };

  attachPBI = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        const defaultDocumentName = "DocumentName/AddProject";
        const ssmDocumentName =
          this.state.enablePowerBiAthenaConnector === false
            ? defaultDocumentName
            : "DocumentName/AddProjectAmazonAthena";
        this.props.api.cart
          .attachCartToPowerBI(
            this.props.cart.uri,
            this.state.envSelected,
            ssmDocumentName
          )
          .then(() => {
            this.props.showGlobalNotification({
              message: "PowerBI is being attached !",
              type: "success"
            });
            this.setState(
              {
                isFetching: false,
                isAttached: true,
                status: "InProgress"
              },
              this.checkStatus
            );
            if (this.props.reloadCart) this.props.reloadCart();
          })
          .catch((error) => {
            Log.error(error);
            this.setState({
              isFetching: false,
              // error,
              isAttached: true
            });
          });
      }
    );
  };

  detachPBI = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.cart
          .detachCartToPowerBI(this.props.cart.uri)
          .then(() => {
            this.props.showGlobalNotification({
              message: "PowerBI is detached.",
              type: "success"
            });
            this.setState({
              isFetching: false,
              isAttached: false,
              status: undefined
            });
            if (this.props.reloadCart) this.props.reloadCart();
          })
          .catch((errorDetach) => {
            Log.error(errorDetach);
            this.setState({
              isFetching: false,
              errorDetach
            });
          });
      }
    );
  };

  openModal = () => this.setState({ modal: true });

  closeModal = () => this.setState({ modal: false });

  openModalEnv = () => {
    this.setState({ modalEnv: true });
  };

  closeModalEnv = () => this.setState({ modalEnv: false });

  onSelectEnv = (envSelected) => {
    this.setState({ envSelected: envSelected.value });
  };

  toggleAthenaConnector = () => {
    this.setState((prevState) => ({
      enablePowerBiAthenaConnector: !prevState.enablePowerBiAthenaConnector
    }));
  };

  bodySelectModal = () => (
    <div>
      <div className="mt-2">
        <fieldset className="form-group">
          <label className="label-form">Environment</label>
          <Select
            placeholder="Select an environment"
            options={this.state.envs.map((a) => ({
              value: a.uri,
              label: a.name
            }))}
            value={this.state.envs
              .map((a) => ({
                value: a.uri,
                label: a.name
              }))
              .find((i) => i.value === this.state.envSelected)}
            onChange={this.onSelectEnv}
          />
        </fieldset>
        <fieldset className="form-group">
          <label className="label-form">
            USE THE NEW AMAZON ATHENA POWER BI CONNECTOR
          </label>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <ToggleButton
              id="powerbi-athena-toggle"
              checkedValue={this.state.enablePowerBiAthenaConnector}
              onChange={this.toggleAthenaConnector}
            />
            <div style={{ position: "relative", top: "-6px" }}>
              <HelpOn
                content={
                  <div>
                    <h3>Information</h3>
                    <div>
                      When you enable the toggle bar, you use the new Power BI
                      Athena Connector, otherwise you use the old CDH Power BI
                      Athena. <br />
                      Please find in the link more information about the
                      visualization with PowerBI for Athena : &nbsp;
                      <a
                        href="https://confluence.tools.digital.engie.com/display/CDHA/Visualization+with+PowerBI+for+Athena"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More informations here
                      </a>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );

  render() {
    let button;

    // const handler = this.state.envs.length > 1 ? this.openModalEnv : this.attachPBI;

    const bt1 = (
      <div id="connector-attach" className="connector">
        <div className="connector-block card-shadow">
          <div className="connector-attached">
            {this.state.isFetching && (
              <div className="connector-loading">
                <i className="fa-spacing fas fa-circle-notch fa-spin" />
              </div>
            )}
          </div>
          <div className="connector-logo connector-logo-orange">
            <img
              className="connector-logo-img"
              src={logoPowerBI}
              alt="PowerBI"
              width={35}
            />
          </div>
          <div className="detail-logo">
            <img
              className="connector-logo-img"
              src={logoAthena}
              alt="Athena"
              width={35}
              height={35}
            />
          </div>
          <div className="text-desc min-width">PowerBI Athena Connector</div>
          <div className="connector-button">
            <button type="button" className="butn" onClick={this.openModalEnv}>
              Attach PowerBI
            </button>
          </div>
        </div>
        {this.state.error && (
          <Error
            error={this.state.error}
            path={"CartIntegrationsPowerBI"}
            stringOnly
          />
        )}

        {this.state.modalEnv && (
          <Modal
            id={"modal-cart-integration-power-bi-attach-env"}
            title={
              "Which environment would you like to attach to the Power BI Gateway?"
            }
            body={this.bodySelectModal()}
            errorMessage={this.state.errorDetach}
            actions={[
              <ButtonAction
                label="Attach"
                className="butn butn-create"
                onClick={this.attachPBI}
              />,
              <ButtonAction label="Close" onClick={this.closeModalEnv} />
            ]}
          />
        )}
      </div>
    );
    const bt2 = (
      <div id="connector-detach" className="connector">
        <div
          className="connector-block card-shadow"
          style={{ flexDirection: "column", alignItems: "start" }}
        >
          <div className="connector-block-detach">
            <div className="connector-attached">
              {(!this.state.status || this.state.status === "InProgress") && (
                <div className="connector-loading tooltiped">
                  <i className="fa-spacing fas fa-circle-notch fa-spin" />
                  <div className="tooltiptext">
                    We are connecting your project to PowerBI, wait a moment.
                  </div>
                </div>
              )}
              {this.state.status === "Success" && (
                <div className="connector-good">
                  <i className="fas fa-check" />
                </div>
              )}
              {this.state.status &&
                this.state.status !== "InProgress" &&
                this.state.status !== "Success" && (
                  <div className="connector-failure">
                    <i className="fas fa-times" />
                  </div>
                )}
            </div>
            <div className="connector-logo connector-logo-orange">
              <img
                className="connector-logo-img"
                src={logoPowerBI}
                alt="PowerBI"
                width={35}
              />
            </div>
            <div className="detail-logo">
              <img
                className="connector-logo-img"
                src={logoAthena}
                alt="Athena"
                width={35}
                height={35}
              />
            </div>
            <div className="text-desc">PowerBI Athena Connector</div>
            <div className="connector-button">
              <button
                type="button"
                className="butn butn-delete"
                onClick={this.openModal}
              >
                Detach PowerBI
              </button>
            </div>
          </div>
          {this.state.ssmDocumentName ? (
            <div className="connector-ssm-document-name">
              Connector in use :<b>&nbsp;{this.state.ssmDocumentName}</b>
            </div>
          ) : null}
        </div>
        <div className="connector-message align-content-center">
          The attached environment is :{" "}
          {this.state.currentEnv ? this.state.currentEnv.name : "Unknown"}
        </div>
        <div className="connector-message">
          Next step: Use PowerBI Desktop !
        </div>

        {this.state.modal && (
          <Modal
            title={`Detach project ${this.props.cart.name} from PowerBI`}
            body={"Are you sure ?"}
            errorMessage={this.state.errorDetach}
            actions={[
              <ButtonAction
                label="Detach"
                className="butn butn-delete"
                onClick={this.detachPBI}
              />,
              <ButtonAction label="Close" onClick={this.closeModal} />
            ]}
          />
        )}
      </div>
    );

    if (!this.state.isAttached) {
      button = bt1;
    } else {
      button = bt2;
    }
    return <div className="cart-visualization">{button}</div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartIntegrationsPowerBI)));
