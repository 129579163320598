import React, { Component, useState } from "react";
import Modal from "../../components/modal";
import { ErrorMessage, Field, Form, Formik } from "formik";

const FormSetCredentialsModal = ({ onClose, updateManuallyCrendentials }) => {
  const [clientSecretId, setClientSecretId] = useState("");
  const [clientValue, setClientValue] = useState("");
  const [servicePrincipal, setServicePrincipal] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const save = () => {
    setIsSubmitting(true);
    updateManuallyCrendentials(clientSecretId, clientValue, servicePrincipal);
    onClose();
  };

  return (
    <Modal
      classname=""
      title="set Mannually Credentials Databricks"
      body={
        <Formik
          initialValues={{
            clientSecretId: clientSecretId,
            clientValue: clientValue,
            servicePrincipal: servicePrincipal
          }}
          validate={() => {
            const errors = {};
            if (!clientSecretId || clientSecretId <= 0) {
              errors.clientSecretId = "Client Secret ID is mandatory";
            }
            if (!clientValue || clientValue <= 0) {
              errors.clientValue = "Client Value is mandatory";
            }
            if (!servicePrincipal || servicePrincipal <= 0) {
              errors.servicePrincipal = "Service Principal ID is mandatory";
            }
            return errors;
          }}
          onSubmit={() => {
            save();
          }}
        >
          {
            <Form>
              <div className="form-container">
                <fieldset className="form-group">
                  <legend className="label-form cluster-label-form">
                    Client ID
                  </legend>
                  <Field
                    type="text"
                    name="clientSecretId"
                    className="form-control bg-white"
                    onChange={(event) => setClientSecretId(event.target.value)}
                    value={clientSecretId}
                  />
                  <ErrorMessage
                    name="clientSecretId"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form cluster-label-form">
                    client Value
                  </legend>
                  <Field
                    type="text"
                    name="clientValue"
                    className="form-control bg-white"
                    onChange={(event) => setClientValue(event.target.value)}
                    value={clientValue}
                  />
                  <ErrorMessage
                    name="clientValue"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form cluster-label-form">
                    service Principal
                  </legend>
                  <Field
                    type="text"
                    name="servicePrincipal"
                    className="form-control bg-white"
                    onChange={(event) =>
                      setServicePrincipal(event.target.value)
                    }
                    value={servicePrincipal}
                  />
                  <ErrorMessage
                    name="servicePrincipal"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <div className={"ml-1 row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn  butn-create"
                  >
                    {isSubmitting && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Submit
                  </button>
                  <button
                    type="button"
                    className="butn"
                    key="accept"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          }
        </Formik>
      }
      classModalLarge="modal-lg"
    />
  );
};

export default FormSetCredentialsModal;
