/* @flow */

import React from "react";
import "./style.less";
import CategoryFacets from "./CategoryFacets";
import SortDataset from "../sortDatasets";
import type { FacetFilter } from "../../../../graphQL/propstypes";
import Loading from "../../../../components/loading";

type propTypes = {
  categoriesFacets: Array<Object>,
  activeFilters: Array<FacetFilter>,
  onChangeFilters: Function,
  runSearch: Function,
  isLoading: boolean,
  onChangeSort: Function,
  field: string,
  direction: string,
  errorOnFacets?: Object
};

type stateTypes = {
  hasChange: boolean
};

class Facets extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      hasChange: false
    };
  }

  onClickFacet = (category: string, facet: string, isChecked: boolean) => {
    const filters = this.props.activeFilters;

    if (isChecked) {
      if (filters.filter((f) => f.field === category).length > 0) {
        // category already filtered
        filters.map((f) => {
          if (f.field === category) f.values.push(facet);
          return f;
        });
      } else {
        // new category filtered
        filters.push({
          field: category,
          values: [facet]
        });
      }

      this.setState({
        hasChange: true
      });
      this.props.onChangeFilters(filters);
    } else {
      const newFilters = filters
        .map((f) => {
          if (f.field === category) {
            const values = f.values.filter((v) => v !== facet);
            if (values.length <= 0) return false;
            return {
              field: f.field,
              values
            };
          }
          return f;
        })
        .filter((f) => f); // remove category without filter

      this.setState({
        hasChange: true
      });
      this.props.onChangeFilters(newFilters);
    }
    // {
    //     filters: [
    //         { field: '', values: [] },
    //     ],
    // }
  };

  onClickApply = () => {
    this.setState({
      hasChange: false
    });
    this.props.runSearch();
  };

  onClickReset = () => {
    this.setState({
      hasChange: true
    });
    this.props.onChangeFilters([{ field: "visibility", values: ["Public"] }]);
    this.props.runSearch();
  };

  render() {
    if (this.props.errorOnFacets) {
      return (
        <div className="catalog-facets catalog-facets-error">
          <div className="catalog-facets-loading catalog-error">
            <div className="catalog-error-title">Facets not available</div>
          </div>
        </div>
      );
    }

    if (this.props.isLoading) {
      return (
        <div className={"catalog-facets catalog-facets-loading"}>
          <Loading message={"Catalog Facets"} />
        </div>
      );
    }
    return (
      <div
        className={"catalog-facets"}
        style={{ visibility: this.props.isLoading ? "hidden" : "visible" }}
      >
        <div className="facet-home">
          <h3 className="title-sort">Sort</h3>
        </div>
        <SortDataset
          onChangeSort={this.props.onChangeSort}
          field={this.props.field}
          direction={this.props.direction}
        />
        <div className="facet-home">
          <h3 className="title-search">Search Criteria</h3>
        </div>
        <div className="actions-container">
          <div className="actions" onClick={this.onClickReset}>
            <i className="fas fa-minus-circle" />
            <span> Reset filters</span>
          </div>
          <div
            className={`actions${this.state.hasChange ? "" : "disabled"}`}
            onClick={this.onClickApply}
          >
            <i className="fas fa-check-circle" />
            <span> Apply</span>
          </div>
        </div>
        <ul className="facet-list">
          {this.props.categoriesFacets &&
            this.props.categoriesFacets.map((categoryFacets) => (
              <CategoryFacets
                key={categoryFacets.name}
                categoryFacets={categoryFacets}
                activeFilters={this.props.activeFilters}
                onClickFacet={this.onClickFacet}
              />
            ))}
        </ul>
        <div className="back-top">
          <a href="#layout" className="to-top">
            <i className="fas fa-angle-double-up" /> back to top
          </a>
        </div>
      </div>
    );
  }
}

export default Facets;
