/* @flow */
import React from "react";
import GraphQl from "../../../../../graphQL";
import Error from "../../../../../components/error";
import Loading from "../../../../../components/loading";
import withAppSync from "../../../../AppsyncHOC";
import ItemFromList from "../../../../../components/itemFromList";
import "./style.less";
import Activity from "../../userRecentHistory/activity";
import Links from "../../../../links";

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  error?: Object,
  ready: boolean,
  userInfo: Object,
  keywords: string
};

class Support extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      userInfo: undefined,
      keywords: ""
    };
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  get = (gid: String) => {
    this.props.api.admin
      .getInfoFromGid(gid)
      .then((userInfo) => {
        this.setState({
          userInfo,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.setState(
          {
            ready: false,
            keywords,
            error: undefined
          },
          () => {
            this.get(keywords);
          }
        );
      },
      keywords.length === 6 ? 300 : 800
    );
  };

  render() {
    return (
      <div className="support">
        <span>
          Type a gaia to retrieve the user's information (identity, recent
          activities, organizations, datasources, projects)
        </span>

        <div>
          <input
            className="form-control"
            placeholder="GAIA"
            onChange={this.onChangeInput}
          />
          {this.state.error && (
            <Error error={this.state.error} path={"Support"} stringOnly />
          )}
          {!this.state.ready && this.state.keywords && (
            <Loading message={"Support"} />
          )}
          {this.state.ready && this.state.keywords && (
            <div>
              <div className="block card-shadow">
                <div className="block-title">Identity:</div>
                {this.state.userInfo.identities &&
                  this.state.userInfo.identities.length > 0 && (
                    <ItemFromList
                      key={this.state.userInfo.identities[0].gid}
                      name={this.state.userInfo.identities[0].email}
                      description={this.state.userInfo.identities[0].name}
                      hideLabelName
                    />
                  )}
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="block card-shadow">
                    <div className="block-title">Organizations:</div>
                    {this.state.userInfo.accounts.map((account) => (
                      <ItemFromList
                        key={account.uri}
                        name={account.name}
                        description={""}
                        hideLabelName
                      />
                    ))}
                  </div>

                  <div className="block card-shadow">
                    <div className="block-title">Datasources:</div>
                    {this.state.userInfo.datasources.map((datasource) => (
                      <ItemFromList
                        key={datasource.uri}
                        name={datasource.name}
                        description={
                          <div>
                            <Links.Group.View uriGroup={datasource.owneruri}>
                              {">> owner <<"}
                            </Links.Group.View>
                            <Links.Group.View
                              className="ml-4"
                              uriGroup={datasource.sharing_group_uri}
                            >
                              {">> sharing group <<"}
                            </Links.Group.View>
                          </div>
                        }
                        hideLabelName
                      />
                    ))}
                  </div>

                  <div className="block card-shadow">
                    <div className="block-title">Projects:</div>
                    {this.state.userInfo.projects.map((project) => (
                      <ItemFromList
                        key={project.uri}
                        name={project.name}
                        description={
                          <Links.Group.View uriGroup={project.groupuri}>
                            {">> owner <<"}
                          </Links.Group.View>
                        }
                        hideLabelName
                      />
                    ))}
                  </div>

                  <div className="block card-shadow">
                    <div className="block-title">Groups:</div>
                    {this.state.userInfo.groups.map((group) => (
                      <ItemFromList
                        key={group.uri}
                        name={group.name}
                        description={
                          <Links.Group.View
                            uriGroup={group.uri}
                            state={{ group }}
                          >
                            {">> see group <<"}
                          </Links.Group.View>
                        }
                        hideLabelName
                      />
                    ))}
                  </div>
                </div>

                <div className="col-6">
                  <div className="block card-shadow">
                    <div className="block-title">Activities:</div>
                    {this.state.userInfo.activities.map((activity) => (
                      <Activity key={activity.activityid} activity={activity} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppSync(Support);
