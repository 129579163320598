/* @flow */

import React, { Component } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Alert, AlertTitle } from "@material-ui/lab";
import withAppsync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Routes from "../../routes";
import Error from "../../../components/error";
import { updateMessage } from "../../globalNotifications/actions";
import SmStudioLogo from "../smstudio-logo.png";
import withGoTo from "../../goToHOC";
import ToggleButton from "../../../components/toggleButton";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  platform: Object,
  open: boolean,
  onClose: Function,
  reloadDomains: Function
};

type stateTypes = {
  error: boolean,
  platform: Object,
  errorSubmit: Object,
  networks: Array<Object>,
  isFetchingNetworks: boolean,
  selectedValue: {
    region: string,
    name: string,
    network_id: Object,
    lifecycle_conf_enabled: Object
  },
  savingDomain: boolean
};

class DomainCreate extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      platform: this.props.platform,
      error: false,
      errorSubmit: false,
      isFetchingNetworks: false,
      networks: [],
      selectedValue: {},
      savingDomain: false
    };
  }

  componentDidMount() {
    if (this.state.platform) {
      this.initiateState(this.state.platform);
    }
  }

  initiateState = (platform) => {
    this.setState({
      error: false,
      errorSubmit: false,
      isFetchingNetworks: false,
      networks: [],
      selectedValue: {
        network_id: "",
        name: "",
        region: "",
        lifecycle_conf_enabled: false
      },
      savingDomain: false
    });

    this.getNetworkList(platform.uri);
  };

  getNetworkList = (platformUri) => {
    this.setState({ isFetchingNetworks: true });
    this.props.api.playground
      .listNetworks(platformUri)
      .then((response) => {
        this.setState({
          networks: response.results,
          isFetchingNetworks: false
        });
        if (this.state.networks.length > 0) {
          this.setState((prevState) => {
            const selectedValue = Object.assign({}, prevState.selectedValue);
            selectedValue.network_id = response.results[0].id;
            selectedValue.region = response.results[0].region;
            selectedValue.name = "";
            selectedValue.lifecycle_conf_enabled = false;
            return { selectedValue };
          });
        }
      })
      .catch((error) => {
        this.setState({
          error,
          isFetchingNetworks: false
        });
      });
  };

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  save(values, setSubmitting) {
    this.setState({ errorSubmit: false, savingDomain: true });
    return this.props.api.smstudio
      .createDomain(this.props.platform.uri, {
        region: this.state.selectedValue.region,
        name: this.state.selectedValue.name,
        lifecycle_conf_enabled: this.state.selectedValue.lifecycle_conf_enabled,
        network_id: this.state.selectedValue.network_id
      })
      .then(() => {
        setSubmitting(false);
        this.setState({ savingDomain: false });
        this.props.showGlobalNotification({
          message: "Sagemaker Studio domain creation started.",
          type: "success"
        });
        this.props.onClose();
        this.props.reloadDomains();
        return this.props.goTo({
          route: `${Routes.Playground.View}#smstudio`,
          params: { playgroundUri: this.props.platform.uri }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, savingDomain: false });
      });
  }

  renderNetworkField = () => {
    if (this.state.isFetchingNetworks) {
      return (
        <select
          disabled
          value={"default"}
          className="form-control form-control-sm"
        >
          <option key={"default"} value={"default"} disabled>
            {"Loading..."}
          </option>
        </select>
      );
    }

    if (this.state.networks && this.state.networks.length < 1) {
      return (
        <span className="error-message">
          No networks found on this environment, please contact your
          administrator or select an other environment.{" "}
        </span>
      );
    }

    return (
      <select
        name="network_id"
        onChange={(event) =>
          this.handleChange("network_id", event.target.value)
        }
        className="form-control form-control-sm"
        value={this.state.selectedValue.network_id}
      >
        {[
          <option key={"default"} value={"default"} disabled>
            {"Select a network"}
          </option>
        ].concat(
          this.state.networks.map((network) => {
            return (
              <option key={network.id} value={network.id}>
                {`${network.name}`}
              </option>
            );
          })
        )}
      </select>
    );
  };

  render() {
    const { selectedValue } = this.state;
    return (
      <div>
        <Dialog
          maxWidth="md"
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container spacing={2}>
                <Grid item md={1} sm={1} xs={1}>
                  <img
                    className="connector-logo-img"
                    src={SmStudioLogo}
                    alt="Sagemaker Studio"
                    width={35}
                    height={35}
                  />
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    New Sagemaker Studio Domain
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <Error error={this.state.error} path="CreteConnection" />
            )}
            <div className="mb-3">
              <Alert severity="info">
                <AlertTitle>Important</AlertTitle>
                <div>
                  <span>
                    Sagemaker service-linked role is required to create a
                    domain. To create it run the following command (AWS CLI):
                  </span>
                </div>
                <div className="mt-1">
                  <code>
                    aws iam create-service-linked-role --aws-service-name
                    sagemaker.amazonaws.com
                  </code>
                </div>
              </Alert>
            </div>
            <Box sx={{ p: 3 }}>
              <Formik
                initialValues={{
                  network_id: selectedValue.network_id,
                  region: selectedValue.region,
                  name: selectedValue.name,
                  lifecycle_conf_enabled: selectedValue.lifecycle_conf_enabled
                }}
                validate={() => {
                  const errors = {};
                  if (!selectedValue.network_id) {
                    errors.network_id = "Please fill your Network";
                  }
                  if (!selectedValue.name) {
                    errors.name = "Please fill your domain name";
                  }
                  if (!selectedValue.region) {
                    errors.region = "An instance type is mandatory";
                  }
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.save(values, setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div>
                        {Object.values(errors).map((e) => (
                          <div className="error-msg">
                            <Error
                              stringOnly
                              error={e}
                              path={"SmStudioDomain"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {this.state.errorSubmit && (
                      <Error
                        stringOnly
                        error={this.state.errorSubmit}
                        path={"SmStudioDomain"}
                      />
                    )}
                    <div className="form-container">
                      <div className="mb-3">
                        <label className="label-form">Domain Name</label>
                        <div>
                          <small className="attribute-label">
                            A unique domain name
                          </small>
                        </div>
                        <TextField
                          placeholder={"Type your domain name"}
                          fullWidth
                          label=""
                          name="name"
                          onChange={(event) =>
                            this.handleChange("name", event.target.value)
                          }
                          value={this.state.selectedValue.name}
                          variant="outlined"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="label-form">Network</label>
                        {this.renderNetworkField()}
                        <ErrorMessage
                          name="network_id"
                          component="div"
                          className="error-msg"
                        />
                      </div>
                      <div className="mb-3">
                        <fieldset className="form-group">
                          <label className="label-form">
                            Lifecycle Configuration
                          </label>
                          <div>
                            <small className="attribute-label">
                              Installs auto shutdown extension for all the
                              jupyter servers created on this domain
                            </small>
                          </div>
                          <div className="basic-metadata-display">
                            <ToggleButton
                              id="auto_publish"
                              checkedValue={
                                !!this.state.selectedValue
                                  .lifecycle_conf_enabled
                              }
                              onChange={() =>
                                this.handleChange(
                                  "lifecycle_conf_enabled",
                                  !this.state.selectedValue
                                    .lifecycle_conf_enabled
                                )
                              }
                            />
                          </div>
                        </fieldset>
                      </div>
                      <CardContent>
                        <button
                          type={"submit"}
                          className="butn butn-create"
                          disabled={isSubmitting}
                          style={{ margin: "5px", width: "100%" }}
                        >
                          {this.state.savingDomain ? (
                            <i className="fas fa-sync-alt fa-spin" />
                          ) : (
                            <i className={"fa fa-paper-plane"} />
                          )}
                          <span>{" Create"}</span>
                        </button>
                      </CardContent>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(DomainCreate)));
