/* @flow */

import React from "react";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import GroupName from "../users/components/groupName";
import route from "../routes";
import "./list.less";
import GraphQl from "../../graphQL";
import Paginator from "../../components/paginator";
import { Card, CardContent, Typography } from "@material-ui/core";

type propTypes = {
  uriAccount: Object,
  goTo: Function,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  workspaces: Array<Object>,
  error: boolean,
  offset: number,
  keywords: string,
  total: number
};

class DatabricksWorkspaceList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      workspaces: [],
      error: false,
      offset: 0,
      total: 0,
      keywords: ""
    };
  }

  componentDidMount() {
    this.listDatabricksWorkspaces();
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        ready: false,
        offset: 0,
        keywords
      });
      this.listDatabricksWorkspaces();
    }, 500);
  };

  listDatabricksWorkspaces = () => {
    const options = {
      limit: 10,
      offset: this.state.offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.databricks
      .listDatabricksAccountWorkspaces(this.props.uriAccount, options)
      .then((items) => {
        if (!items) return false;
        this.setState({
          ready: true,
          workspaces: items.results
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getWorkspacesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listDatabricksWorkspaces().then(r))
    );

  renderContent = () => {
    const { error, ready } = this.state;

    if (error) return <Error error={error} path={"Workspace List"} />;
    if (!ready) return <Loading message={"Workspace List"} />;

    const { goTo } = this.props;

    return (
      <div className="card-container">
        {!error && (
          <Paginator
            limit={10}
            initialOffset={0}
            totalCount={this.state.total}
            list={this.state.workspaces}
            componentRender={(workspace, index) => (
              <Card variant="outlined" key={workspace.dbx_workspace_id + index}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {workspace.dbx_workspace_name}
                  </Typography>
                  <Typography color="textSecondary">
                    {workspace.dbx_workspace_id}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {workspace.dbx_workspace_url}
                    <br />
                    {'"a benevolent smile"'}
                  </Typography>
                </CardContent>
              </Card>
            )}
            loadPage={this.getWorkspacesFromPagination}
            onNoResult={() => (
              <p className="total-search-results">No Workspace found</p>
            )}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="organizations-list">
        <div className="header">
          <div className="search">
            <div className="search-input">
              <input
                className="bg-white form-control"
                placeholder="Search Workspaces !"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        </div>

        {this.renderContent()}
      </div>
    );
  }
}

export default withAppSync(withGoTo(DatabricksWorkspaceList));
