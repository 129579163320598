export const mutationRefreshInstance = {
  query: `mutation Q ($instanceId: String!) {
        refreshSagemakerNotebookInstance(instance_id: $instanceId) {
          status
          error
          updatedat
          instance_type
        }
      }
    `,
  keyOutput: "refreshSagemakerNotebookInstance"
};
