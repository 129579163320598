export const mutationSyncStagesWithGlue = {
  query: `mutation M ($datasetUri: String!) {
        refreshDatasetStages(datasetUri: $datasetUri){
            id
            name
            table
        }
    }`,
  keyOutput: "refreshDatasetStages"
};
