/* @flow */

import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import Row from "../../../components/row";
import Col from "../../../components/col";
import Routes from "../../routes";
import Error from "../../../components/error";
import { updateMessage } from "../../globalNotifications/actions";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import "./style.less";

const Log = Logger("AddSagemakerNotebook");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      instance: Object
    }
  },
  match: {
    params: {
      cartUri: string,
      instanceId: string
    }
  }
};

type stateTypes = {
  instance: Object,
  networkName: string,
  ready: boolean,
  error: boolean,
  errorSubmit: Object,
  selectedValue: {
    name: string,
    environment_uri: string,
    instance_type: string,
    network_id: string,
    enable_shutdown_idle_lifecycle: boolean
  },
  instanceTypes: Array<string>,
  isSubmitting: boolean
};

class EditSagemakerNotebook extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      instanceTypes: [],
      instance: {},
      networkName: "",
      error: false,
      errorSubmit: undefined,
      selectedValue: {},
      isSubmitting: false
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.instance) {
      this.initiateState(this.props.location.state.instance);
    } else {
      this.props.api.sagemakerNotebookInstance
        .getSagemakerNotebookInstance(this.props.match.params.instanceId)
        .then((instance) => {
          this.initiateState(instance);
        })
        .catch((error) => {
          Log.error(error);
          this.setState({ error });
        });
    }

    this.props.api.utils
      .getSagemakerNotebookInstanceTypes()
      .then((instanceTypes) => {
        this.setState({ instanceTypes });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  }

  initiateState = (instance) => {
    this.setState({
      ready: true,
      error: false,
      errorSubmit: false,
      instance,
      networkName: `${instance.network.subnet_id} | ${instance.network.name}`,
      selectedValue: {
        name: instance.name,
        environment_uri: instance.environment_uri,
        instance_type: instance.instance_type,
        network_id: instance.network.name,
        enable_shutdown_idle_lifecycle: instance.enable_shutdown_idle_lifecycle
      }
    });
  };

  handleChange = (field, value) =>
    this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });

  save = (values, setSubmitting) => {
    const { selectedValue } = this.state;
    this.setState({ errorSubmit: false, isSubmitting: true });

    const updatedInstance = {
      instance_type: selectedValue.instance_type,
      enable_shutdown_idle_lifecycle:
        selectedValue.enable_shutdown_idle_lifecycle
    };

    return this.props.api.sagemakerNotebookInstance
      .update(this.state.instance.id, updatedInstance)
      .then(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook edited",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-datasciences`,
          params: { cartUri: this.props.match.params.cartUri }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook edition failed",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"AddSagemakerNotebook"} />;
    if (!this.state.ready)
      return <Loading message={"Sagemaker Notebook form"} />;

    const { selectedValue } = this.state;

    return (
      <div className="add-sagemaker-notebook">
        <Breadcrumb view={"Edit Sagemaker Notebook"} />
        <Row>
          <Col size={1} />
          <Col size={9}>
            {this.state.errorSubmit && (
              <Error
                stringOnly
                error={this.state.errorSubmit}
                path={"EditSagemakerNotebook"}
              />
            )}
            <Formik
              initialValues={{
                name: selectedValue.name,
                environment_uri: selectedValue.environment_uri,
                instance_type: selectedValue.instance_type,
                network_id: selectedValue.network_id,
                enable_shutdown_idle_lifecycle:
                  selectedValue.enable_shutdown_idle_lifecycle
              }}
              validate={() => {
                const errors = {};

                if (!selectedValue.instance_type) {
                  errors.instance_type = "An instance type is mandatory";
                }

                Log.info("validate", selectedValue, errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(values, setSubmitting);
              }}
            >
              {({ errors }) => (
                <Form>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-msg">
                      {"You have some errors. Please, verify all fields."}
                    </div>
                  )}
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <div className="label-form-name">
                        <input
                          disabled
                          className="form-control label-form-name-cdh"
                          value="cdh-"
                        />
                        <Field
                          type="name"
                          name="name"
                          className="form-control label-form-name-value"
                          placeholder="Name"
                          onChange={(event) =>
                            this.handleChange("name", event.target.value)
                          }
                          value={selectedValue.name}
                          disabled
                        />
                      </div>
                    </fieldset>
                    <div className="mb-3">
                      <label className="label-form">Instance type:</label>
                      <select
                        name="environment"
                        onChange={(event) =>
                          this.handleChange("instance_type", event.target.value)
                        }
                        className="form-control form-control-sm"
                        value={selectedValue.instance_type}
                      >
                        {[
                          <option key={"default"} value={"default"} disabled>
                            {"Select a type"}
                          </option>
                        ].concat(
                          this.state.instanceTypes.map((instanceType) => (
                            <option key={instanceType} value={instanceType}>
                              {instanceType}
                            </option>
                          ))
                        )}
                      </select>
                      <ErrorMessage
                        name="instance_type"
                        component="div"
                        className="error-msg"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-form">Environment:</label>
                      <select
                        name="environment_uri"
                        onChange={(event) =>
                          this.handleChange(
                            "environment_uri",
                            event.target.value
                          )
                        }
                        className="form-control form-control-sm"
                        value={selectedValue.environment_uri}
                        disabled
                      >
                        <option value={this.state.instance.environment.uri}>
                          {this.state.instance.environment.name}
                        </option>
                      </select>
                      <ErrorMessage
                        name="environment_uri"
                        component="div"
                        className="error-msg"
                      />
                    </div>

                    <fieldset className="form-group">
                      <legend className="label-form cluster-label-form">
                        Network
                      </legend>
                      <select
                        name="network_id"
                        onChange={(event) =>
                          this.handleChange("network_id", event.target.value)
                        }
                        className="form-control form-control-sm"
                        value={selectedValue.network_id}
                        disabled
                      >
                        <option value={selectedValue.network_id}>
                          {this.state.networkName}
                        </option>
                      </select>
                    </fieldset>

                    <div className="form-group">
                      <div className="facet-criteria-name">
                        <input
                          id="enable_shutdown_idle_lifecycle"
                          type="checkbox"
                          className={"checkbox-facet"}
                          checked={
                            this.state.selectedValue
                              .enable_shutdown_idle_lifecycle
                          }
                          onClick={() =>
                            this.setState((prevState) => ({
                              selectedValue: {
                                ...prevState.selectedValue,
                                enable_shutdown_idle_lifecycle:
                                  !prevState.selectedValue
                                    .enable_shutdown_idle_lifecycle
                              }
                            }))
                          }
                        />
                        <label
                          htmlFor="enable_shutdown_idle_lifecycle"
                          className="fas"
                        >
                          <small className="attribute-label">
                            Automatically stop the instance after inactivity
                          </small>
                        </label>
                      </div>
                    </div>

                    <div className={"ml-1 row justify-content-center"}>
                      <button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        className="butn"
                      >
                        {this.state.isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Edit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(EditSagemakerNotebook));
