import React from "react";
import PropTypes from "prop-types";

const BackButton = ({ goBack }) => (
  <span className="back-button" onClick={goBack} style={{ cursor: "pointer" }}>
    <i
      style={{ verticalAlign: "middle" }}
      className={"mx-0 fas fa-chevron-left fa-2x text-primary"}
    />
  </span>
);

BackButton.propTypes = {
  goBack: PropTypes.func
};

export default BackButton;
