/* @flow */
import React from "react";
import "./style.less";
import Error from "../../../../components/error";
import SagemakerInstanceRow from "./SagemakerInstanceRow";
import Paginator from "../../../../components/paginator";

type propTypes = {
  sagemakerInstances: {
    results: Array<Object>,
    offset: number,
    limit: number,
    total: number
  },
  withNetworkCol: boolean,
  loadPage: Function
};

type stateTypes = {
  error: ?Object
};

class ListNotebooksNetwork extends React.PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  render() {
    return (
      <div className="list-policies">
        {this.state.error && (
          <Error error={this.state.error} path="ActionInstanceSageMaker" />
        )}
        <div className="mt-2">
          <Paginator
            container={(content) => (
              <table className="table">
                <thead>
                  <tr>
                    <th className="" scope="col">
                      NAME
                    </th>
                    {!this.props.withNetworkCol && (
                      <th className="" scope="col">
                        ENVIRONMENT
                      </th>
                    )}
                    <th className="" scope="col">
                      PROJECT
                    </th>
                    <th className="" scope="col">
                      TYPE
                    </th>
                    {this.props.withNetworkCol && (
                      <th className="" scope="col">
                        NETWORK
                      </th>
                    )}
                    <th className="" scope="col center-column">
                      CREATION DATE
                    </th>
                    <th className="" scope="col center-column">
                      STATUS
                    </th>
                    <th className="" scope="col center-column">
                      ACTION
                    </th>
                    <th className="" scope="col center-column" />
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </table>
            )}
            limit={10}
            initialOffset={0}
            list={this.props.sagemakerInstances.results}
            componentRender={(instance) => (
              <SagemakerInstanceRow
                instance={instance}
                withNetworkCol={this.props.withNetworkCol}
              />
            )}
            loadPage={this.props.loadPage}
            totalCount={this.props.sagemakerInstances.total}
            onNoResult={() => <div>No Sagemaker instances to display.</div>}
          />
        </div>
      </div>
    );
  }
}

export default ListNotebooksNetwork;
