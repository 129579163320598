/* @flow */
import React from "react";
import withAppSync from "../AppsyncHOC";
import Loading from "../../components/loading";
import Error from "../../components/error";
import Row from "../../components/row";
import Breadcrumb from "../../components/breadcrumb";
import ItemFromList from "../../components/itemFromList";
import LabelMemberGroup from "../../components/labelMemberGroup";
import GraphQl from "../../graphQL";
import routes from "../routes";
import "./view.less";
import { getAccountUriFromAccountId } from "../../utils/toolsForUri";
import Links from "../links";
import withGoTo from "../goToHOC";
import DeleteGroup from "./deleteGroup";
import Paginator from "../../components/paginator";
import Logger from "../../utils/logger";
import { formatDate } from "../../utils/date";

const Log = Logger("GroupView");

type propTypes = {
  match: {
    params: {
      uriGroup: string
    }
  },
  api: GraphQl,
  auth: {
    getUser: Function
  },
  goTo: Function
};
type stateTypes = {
  group: Object,
  groupMembers: Object,
  groupProjects: Object,
  groupEnvironments: Object,
  error?: Object,
  errorMembers?: Object,
  errorProjects?: Object,
  errorEnvironments?: Object,
  isFetching: boolean,
  isMembersReady: boolean,
  isProjectsReady: boolean,
  isEnvironmentsReady: boolean
};
class GroupView extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);

    this.state = {
      group: false,
      groupMembers: {},
      groupProjects: {},
      groupEnvironments: {},
      isMembersReady: false,
      isProjectsReady: false,
      isEnvironmentsReady: false,
      error: false,
      errorMembers: undefined,
      errorProjects: undefined,
      errorEnvironments: undefined,
      isFetching: false
    };
  }

  componentDidMount() {
    this.getGroup();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.uriGroup !== prevProps.match.params.uriGroup) {
      this.getGroup();
    }
  }

  getGroup = () => {
    this.setState({ isFetching: true, group: false, error: false });
    this.props.api.group
      .get(this.props.match.params.uriGroup)
      .then((group) => {
        this.setState({
          group,
          isFetching: false
        });
        this.getMembers();
        this.getProjects();
        this.getEnvironments();
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, isFetching: false });
      });
  };

  getMembers = (offset = 0) =>
    this.props.api.group
      .getGroupMembers(this.props.match.params.uriGroup, { offset, limit: 8 })
      .then((groupMembers) => {
        this.setState({ groupMembers, isMembersReady: true });
        return groupMembers.results;
      })
      .catch((errorMembers) => {
        Log.error(errorMembers);
        this.setState({ errorMembers });
        return [];
      });

  getProjects = (offset = 0) =>
    this.props.api.group
      .getGroupProjects(this.props.match.params.uriGroup, { offset, limit: 8 })
      .then((groupProjects) => {
        this.setState({ groupProjects, isProjectsReady: true });
        return groupProjects.results;
      })
      .catch((errorProjects) => {
        Log.error(errorProjects);
        this.setState({ errorProjects });
        return [];
      });

  getEnvironments = (offset = 0) =>
    this.props.api.group
      .getGroupEnvironments(this.props.match.params.uriGroup, {
        offset,
        limit: 8
      })
      .then((groupEnvironments) => {
        this.setState({ groupEnvironments, isEnvironmentsReady: true });
        return groupEnvironments.results;
      })
      .catch((errorEnvironments) => {
        Log.error(errorEnvironments);
        this.setState({ errorEnvironments });
        return [];
      });

  render() {
    const { error, isFetching, group } = this.state;

    return (
      <div className={"container group-view"}>
        <Breadcrumb
          state={{ account: group.account }}
          view={`Group, ${group.name || "..."}`}
        />
        <div className="actions-bar">
          <div className="actions-left" />
          <div className="actions-right">
            <button
              className="butn"
              onClick={() =>
                this.props.goTo({
                  route: routes.Group.Edit,
                  params: {
                    uriGroup: group.uri,
                    uriAccount: group.account.uri
                  }
                })
              }
              type={"button"}
              style={{ marginRight: "5px", width: "85px" }}
            >
              <span>Edit</span>
            </button>
            <button
              className="butn"
              onClick={() =>
                this.props.goTo({
                  route: routes.Group.EditUsers,
                  params: {
                    uriGroup: group.uri
                  }
                })
              }
              type={"button"}
              style={{ marginRight: "5px" }}
            >
              <span>Manage users</span>
            </button>
            <DeleteGroup group={group} />
          </div>
        </div>
        {error && <Error error={error} path={"Group"} />}
        {isFetching && <Loading message={"Group"} />}
        {group && (
          <React.Fragment>
            <Row className={"mx-1 mt-2"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                URI:
              </div>
              <div>{group.uri}</div>
            </Row>
            <Row className={"mx-1 mt-2"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Name:
              </div>
              <div>{group.name}</div>
            </Row>

            <Row className={"mx-1 mt-2"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Description:
              </div>
              <div>{group.description}</div>
            </Row>

            <Row className={"mx-1 mt-2"}>
              <ul className="info-group">
                <div className={"label-form"} style={{ marginRight: "5px" }}>
                  Mailing list:
                </div>

                {(!this.state.group.mailing_list ||
                  this.state.group.mailing_list.length <= 0) && (
                  <div>Empty list</div>
                )}
                {this.state.group.mailing_list &&
                  this.state.group.mailing_list.length > 0 && (
                    <li>
                      <div className="group-tags">
                        {this.state.group.mailing_list
                          .filter((mail) => mail && mail.trim())
                          .map((mail) => (
                            <div className="group-tag-badge" key={mail}>
                              <div className="group-circle-tag" />
                              {mail}
                            </div>
                          ))}
                      </div>
                    </li>
                  )}
              </ul>
            </Row>

            <Row className={"mx-1 mt-2"}>
              <ul className="info-group">
                <div className={"label-form"} style={{ marginRight: "5px" }}>
                  Tags:
                </div>
                {this.state.group.tags && this.state.group.tags.length > 0 && (
                  <li>
                    <div className="group-tags">
                      {this.state.group.tags &&
                        this.state.group.tags
                          .split(",")
                          .filter((tag) => tag && tag.trim())
                          .map((tag) => (
                            <div className="group-tag-badge" key={tag}>
                              <div className="group-circle-tag" />
                              {tag}
                            </div>
                          ))}
                    </div>
                  </li>
                )}
              </ul>
            </Row>

            <Row className={"mx-1 mt-2"}>
              <div>
                <div className={"label-form"} style={{ marginRight: "5px" }}>
                  Responsible:
                </div>
                <div>{group.responsible.name}</div>
              </div>
            </Row>

            <Row className={"mx-1 mt-2 w-100"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Members:
              </div>
              {this.state.errorMembers && (
                <Error
                  error={this.state.errorMembers}
                  path={"Group"}
                  stringOnly
                />
              )}
              <Paginator
                container={(content) => (
                  <ul className="info-group w-100">{content}</ul>
                )}
                isLoadingPage={!this.state.isMembersReady}
                limit={this.state.groupMembers.limit}
                initialOffset={0}
                totalCount={this.state.groupMembers.total}
                list={this.state.groupMembers.results || []}
                componentRender={(identity) => (
                  <ItemFromList
                    hideLabelName
                    key={identity.uri}
                    name={<LabelMemberGroup user={identity} />}
                  />
                )}
                loadPage={this.getMembers}
                onNoResult={() => <div> - 0 member</div>}
              />
            </Row>

            <Row className={"mx-1 mt-2 w-100"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Projects attached:
              </div>
              {this.state.errorProjects && (
                <Error
                  error={this.state.errorProjects}
                  path={"Group"}
                  stringOnly
                />
              )}
              <Paginator
                container={(content) => (
                  <ul className="info-group w-100">{content}</ul>
                )}
                isLoadingPage={!this.state.isProjectsReady}
                limit={this.state.groupProjects.limit}
                initialOffset={0}
                totalCount={this.state.groupProjects.total}
                list={this.state.groupProjects.results || []}
                componentRender={(project) => (
                  <ItemFromList
                    hideLabelName
                    key={project.uri}
                    name={
                      <div>
                        <div
                          style={{ fontWeight: 600, overflowWrap: "anywhere" }}
                        >
                          <Links.Cart.View cartUri={project.uri}>
                            {project.name}
                          </Links.Cart.View>
                        </div>
                        <div style={{ color: "grey" }}>
                          <div>region: {project.region}</div>
                          <div>
                            createdat: {formatDate(project.createdat, false)}
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
                loadPage={this.getProjects}
                onNoResult={() => <div> - 0 project attached</div>}
              />
            </Row>

            <Row className={"mx-1 mt-2 w-100"}>
              <div className={"label-form"} style={{ marginRight: "5px" }}>
                Environments attached:
              </div>
              {this.state.errorEnvironments && (
                <Error
                  error={this.state.errorEnvironments}
                  path={"Group"}
                  stringOnly
                />
              )}
              <Paginator
                container={(content) => (
                  <ul className="info-group w-100">{content}</ul>
                )}
                isLoadingPage={!this.state.isEnvironmentsReady}
                limit={this.state.groupEnvironments.limit}
                initialOffset={0}
                totalCount={this.state.groupEnvironments.total}
                list={this.state.groupEnvironments.results || []}
                componentRender={(environment) => (
                  <ItemFromList
                    hideLabelName
                    key={environment.uri}
                    name={
                      <div>
                        <div
                          style={{ fontWeight: 600, overflowWrap: "anywhere" }}
                        >
                          <Links.Playground.View
                            uriAccount={getAccountUriFromAccountId(
                              environment.accountid
                            )}
                            uriPlayground={environment.uri}
                          >
                            {environment.name}
                          </Links.Playground.View>
                        </div>
                        <div style={{ color: "grey" }}>
                          <div>environment: {environment.environment}</div>
                          <div>aws: {environment.aws}</div>
                        </div>
                      </div>
                    }
                  />
                )}
                loadPage={this.getEnvironments}
                onNoResult={() => <div> - 0 environment attached</div>}
              />
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withGoTo(withAppSync(GroupView));
