export const listProjectUserProfiles = {
  query: `query Q ($cartUri:String!) {
        listSagemakerStudioUserProfiles(cartUri:$cartUri) {
            total
            offset
            limit
            results {
                id
                name
                status
                stack_id
                stack_name
                role_arn
                stack_status
                createdat
                status
                updatedat
                sm_domain_id
                aws
            }
        }
    }
    `,
  keyOutput: "listSagemakerStudioUserProfiles"
};
