/* @flow */

export const updateTrustFromCart = {
  query: `mutation Q ($trustId: String!, $input: UpdateTrustInput!) {
        updateTrust(trustId:$trustId, input:$input) {
          arn
          attach_by_default
          description
        }
      }
    `,
  keyOutput: "updateTrust"
};
