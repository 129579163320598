/* @flow */
import React from "react";
import Loading from "../../components/loading";
import AccountCard from "./card";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import "./list.less";
import Logger from "../../utils/logger";
import NewAccountBlock from "./components/NewAccountBlock";
import { isUserFromCDHTeam } from "../../utils/cdhTeam";
import Modal from "../../components/modal";
import Breadcrumb from "../../components/breadcrumb";
import config from "../../config";

const Log = Logger("AccountsList");

type propTypes = {
  api: Object,
  auth: {
    getUser: Function
  }
};

type stateTypes = {
  isUserFromCDHTeam: boolean,
  ready: boolean,
  error: boolean,
  items: Array<Object>,
  deleteAccountModal: Object,
  errorDeletion: ?Object
};

export class AccountsList extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      errorDeletion: false,
      items: [],
      isUserFromCDHTeam: false,
      deleteAccountModal: false
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () =>
    this.props.auth
      .getUser()
      .then((user = {}) =>
        this.props.api.account.getAccounts().then((items) =>
          this.setState({
            ready: true,
            items,
            isUserFromCDHTeam: isUserFromCDHTeam(
              (user.preferred_username || "").split("@")[0]
            )
          })
        )
      )
      .catch((error) => {
        Log.error(error);
        return this.setState({ error });
      });

  deleteAccount = (uriAccount: Object) =>
    this.props.api.account.delete(uriAccount);

  showAccountModel = (account: Object) =>
    this.setState({ deleteAccountModal: { account } });

  hideAccountModel = () => this.setState({ deleteAccountModal: false });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"AccountsList"} />;
    if (!this.state.ready) return <Loading message={"Organizations"} />;

    return (
      <div className="list-accounts">
        <div className={"accounts"}>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view="My Organizations"
                hideGoBack
                helpOn={{
                  content: (
                    <div>
                      <div> - My Organizations </div>
                      <div>
                        You can see the BU accounts you are registered to.
                      </div>
                      <hr />
                      <div>Requirement:</div>
                      <div>
                        - If you don’t see any account, contact your account
                        admin.
                      </div>
                    </div>
                  ),
                  helpCenterLink: `${config.HELP_CENTER}`
                }}
              />
            </div>
          </div>
        </div>
        {this.state.isUserFromCDHTeam && <NewAccountBlock />}
        {this.state.items.length <= 0 && (
          <div style={{ marginLeft: "30px" }}>
            You aren&rsquo;t apart of any cdh organization
          </div>
        )}
        <ul className="list-group" style={{ marginTop: "10px" }}>
          {this.state.items.map((account) => (
            <AccountCard
              key={account.uri}
              {...account}
              deleteAccount={() => this.showAccountModel(account)}
            />
          ))}
        </ul>

        {this.state.deleteAccountModal && (
          <Modal
            title={`Delete account ${this.state.deleteAccountModal.account.name}`}
            body={"Are you sure ?"}
            errorMessage={this.state.errorDeletion}
            actions={[
              <button
                key={`account-close-${this.state.deleteAccountModal.account.uri}`}
                type="button"
                className="butn"
                onClick={this.hideAccountModel}
              >
                Close
              </button>,
              <button
                key={`account-delete-${this.state.deleteAccountModal.account.uri}`}
                type="button"
                className="butn butn-delete"
                onClick={() =>
                  this.deleteAccount(this.state.deleteAccountModal.account.uri)
                    .then(this.hideAccountModel)
                    .then(this.getAccounts)
                    .catch((errorDeletion) => {
                      this.setState({ errorDeletion });
                    })
                }
              >
                Delete
              </button>
            ]}
          />
        )}
        <div class="info-msg">
          <i class="fa fa-info-circle"></i>
          &nbsp;If you don't see any organization, or if you don't see the
          organization you'd like to access, please contact the representatives
          for&nbsp;<br></br>
          <a href="https://app.powerbi.com/links/_y9B7kuA1r?ctid=24139d14-c62c-4c47-8bdd-ce71ea1d50cf&pbi_source=linkShare">
            <strong>your organization</strong>
          </a>
          , or&nbsp;
          <a href="https://org-it.myengie.com/">
            <strong>your AWS champion</strong>
          </a>
          .
        </div>
      </div>
    );
  }
}

export default withAppSync(AccountsList);
