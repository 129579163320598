export const listProjectsAttachedToGateway = {
  query: `query Q {
        listProjectsAttachedToGateway {
            uri
            name
            group { uri name }
        }
      }
    `,
  keyOutput: "listProjectsAttachedToGateway"
};
