import { ImplicitCallback } from "@okta/okta-react";
import config from "../../config";

export const configOkta = {
  issuer: config.OKTA_URL,
  redirect_uri: config.OKTA_REDIRECT_URI,
  client_id: config.OKTA_CLIENT_ID,
  response_type: "id_token",
  scope: ["openid", "profile"]
};

export const CallbackOktaComponent = ImplicitCallback;

export const callbackOktaUrl = "/implicit/callback";
