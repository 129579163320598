/* @flow */

import React from "react";
import Links from "../links/index";
import Loading from "../../components/loading";
import ItemFromList from "../../components/itemFromList";
import withGoTo from "../goToHOC";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import routes from "../routes";
import LabelMemberGroup from "../../components/labelMemberGroup";
import "./list.less";
import GraphQl from "../../graphQL";
import ButtonAction from "../../components/buttonAction";
import { toHex } from "../../utils/encodeHex";
import Paginator from "../../components/paginator";

type propTypes = {
  match: {
    params: {
      uriAccount: string
    }
  },
  api: GraphQl,
  location: {
    state: {
      account: {
        name: string
      }
    }
  },
  goTo: Function
};

type stateTypes = {
  ready: boolean,
  identities: Array<Object>,
  error: boolean,
  errorDeletion: boolean,
  offset: number,
  keywords: string,
  total: number
};

class UsersList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      identities: [],
      error: false,
      errorDeletion: false,
      offset: 0,
      keywords: "",
      total: 0
    };
  }

  componentDidMount() {
    this.listUsers();
  }

  listUsers = () => {
    const options = {
      limit: 10,
      offset: this.state.offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords,
        username: this.state.keywords
      };
    }

    return this.props.api.account
      .listIdentities(this.props.match.params.uriAccount, options)
      .then((items) => {
        if (!items) return false;
        this.setState({
          ready: true,
          identities: items.results,
          total: items.total
        });
        return items;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  onChangeInput = (e) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        ready: false,
        offset: 0,
        keywords
      });
      this.listUsers();
    }, 500);
  };

  getUsersFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listUsers().then(r))
    );

  unregisterUser = (user) =>
    this.props.api.identity
      .deleteIdentity(user.uri)
      .then(() => this.listUsers())
      .catch((errorDeletion) => {
        this.setState({
          errorDeletion
        });
      });

  renderContent() {
    if (this.state.error)
      return <Error error={this.state.error} path={"usersList"} />;
    if (!this.state.ready) return <Loading message={"Users list"} />;

    return (
      <div className="w-100">
        {!this.state.error && this.state.identities.length > 0 && (
          <ul className="list-group">
            <Paginator
              limit={10}
              initialOffset={0}
              totalCount={this.state.total}
              list={this.state.identities}
              componentRender={(user) => (
                <ItemFromList
                  hideLabelName
                  key={user.uri}
                  name={<LabelMemberGroup user={user} />}
                  actions={[
                    <ButtonAction
                      label="unregister"
                      className="butn butn-delete"
                      onClick={() => this.unregisterUser(user)}
                    />
                  ]}
                  onClick={() =>
                    this.props.goTo({
                      route: routes.User.View,
                      params: {
                        uri: toHex(user.uri)
                      }
                    })
                  }
                />
              )}
              loadPage={this.getUsersFromPagination}
            />
          </ul>
        )}
        {!this.state.error &&
          this.state.ready &&
          !(this.state.identities.length > 0) && (
            <div>No users to display.</div>
          )}
      </div>
    );
  }

  render = () => (
    <div className="organizations-list users-list">
      <div className="header">
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search User !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <Links.User.Create
                uriAccount={this.props.match.params.uriAccount}
              >
                <div className="butn butn-create">
                  <i className="fas fa-plus butn-icon" />
                  <span className="butn-text">Invite new User</span>
                </div>
              </Links.User.Create>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        {this.state.errorDeletion && (
          <Error error={this.state.errorDeletion} path={"usersList"} />
        )}
        {this.renderContent()}
      </div>
    </div>
  );
}

export default withAppSync(withGoTo(UsersList));
