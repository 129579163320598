/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Links from "../../../links";
import { updateMessage } from "../../../globalNotifications/actions";
import "./stageDetails.less";
import routes from "../../../routes";
import GraphQl from "../../../../graphQL";
import DisplayStage from "../displayStage";
import { formatDate } from "../../../../utils/date";
import DeleteObjectWithFrictionModal from "../../../../components/DeleteObjectWithFrictionModal";
import { isRSSourceType, isS3SourceType } from "../../../../utils/constants";

type propTypes = {
  stage: {
    id: string,
    name: string,
    table: string,
    location: string,
    type: string,
    description: string,
    profiling_execution: {
      status: string
    },
    is_default: boolean,
    createdat: string,
    profiling_nb_rows: string,
    is_table_existing_in_glue_catalog: boolean,
    source_type: string,
    schema: string,
    im_size: number
  },
  dataset_uri: string,
  dataset: Object,
  access: string,
  reloadStages: Function,
  listStages: Function,
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  dataFiltersCount: number,
  showDataFilters: boolean
};

type stateTypes = {
  error: Object,
  statusProfiling: string,
  reloadingProfilingStatus: boolean,
  openDeleteDialog: boolean,
  customCrawlerIsLaunching: boolean
};

class NewStageCardDetails extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      reloadingProfilingStatus: false,
      statusProfiling: (props.stage.profiling_execution || {}).status,
      openDeleteDialog: false,
      customCrawlerIsLaunching: false
    };
  }

  componentDidMount() {
    if (this.props.stage.profiling_execution.status === "RUNNING") {
      this.setState({ reloadingProfilingStatus: true });
      this.timeout = setTimeout(this.reloadProfilingStatus, 5000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentDidUpdate() {
    if (
      !this.state.reloadingProfilingStatus &&
      this.state.statusProfiling === "RUNNING"
    ) {
      this.timeout = setTimeout(() => {
        this.setState({ reloadingProfilingStatus: true });
        return this.reloadProfilingStatus();
      }, 10000);
    }
  }

  onPreviewStage = () =>
    this.props.goTo({
      route: `${routes.Stage.View}#preview`,
      params: {
        datasetUri: this.props.dataset_uri,
        outputId: this.props.stage.id
      }
    });

  onAnalyzeMetadata = () =>
    this.props.goTo({
      route: `${routes.Stage.View}#metadata`,
      params: {
        datasetUri: this.props.dataset_uri,
        outputId: this.props.stage.id
      }
    });

  reloadProfilingStatus = () =>
    this.props.api.dataset
      .getRecentStageProfilingExecution(
        this.props.stage.id,
        this.props.dataset_uri
      )
      .then((statusProfiling) =>
        this.setState({ statusProfiling, reloadingProfilingStatus: false })
      )
      .catch(() => this.setState({ reloadingProfilingStatus: false }));

  onOpenDeleteStageDialog = () => {
    this.setState({ openDeleteDialog: true });
  };

  onCloseDeleteStageDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  deleteStage = (deleteAwsResource) =>
    this.props.api.dataset
      .deleteStage(
        this.props.stage.id,
        this.props.dataset_uri,
        deleteAwsResource
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Stage deleted",
          type: "success"
        });
        this.onCloseDeleteStageDialog();
        this.props.listStages();
      })
      .catch((error) => this.setState({ error }));

  handleLaunchCustomCrawler = async () => {
    const delay = 5;
    const maxAttempts = 3;
    this.setState({ customCrawlerIsLaunching: true });
    await this.props.api.dataset.runStageNormalizing(
      this.props.dataset.uri,
      this.props.stage.location,
      delay,
      maxAttempts
    );
    this.setState({ customCrawlerIsLaunching: false });
    this.props.showGlobalNotification({
      message: "Custom crawler has been launched",
      type: "success"
    });
  };

  render() {
    const { stage, dataset } = this.props;
    return (
      <div className={"stage-mcard card-shadow card bg-white"}>
        {this.state.error && (
          <Error error={this.state.error} path="StageDetails" />
        )}
        <div className="mcard-header">
          <div className="mcard-begin">
            <div className="mcard-begin-content">
              <i className="fab fa-buromobelexperte fa-spacing" />
              Stage
            </div>
          </div>

          <div className="mcard-end">
            <DisplayStage
              isDefault={stage.is_default}
              reloadStages={this.props.reloadStages}
              access={this.props.access}
            />
          </div>
        </div>
        <div className="mcard-body">
          <div className="mcard-body-left">
            {stage.is_table_existing_in_glue_catalog ? (
              <Links.Stage.View
                datasetUri={this.props.dataset_uri}
                outputId={stage.id}
              >
                <span className={"endpoint-name"}>{stage.name}</span>
              </Links.Stage.View>
            ) : (
              <span className={"stage-deleted-name"}>{stage.name}</span>
            )}
            <div className="card-size-details">
              {stage.is_table_existing_in_glue_catalog && (
                <div>
                  <p className="card-size-updateDate">
                    <span className="font-weight-bold">Last update :</span>{" "}
                    {formatDate(stage.createdat)} |{" "}
                    <span className="font-weight-bold">Size :</span>{" "}
                    <span>
                      {stage.im_size > 0
                        ? `${stage.im_size} MB`
                        : "Not available"}
                    </span>
                  </p>
                  <p className="card-dataset-description">
                    {stage.description}
                  </p>
                </div>
              )}
            </div>
            {isS3SourceType(stage.source_type) && (
              <div>
                <div className="card-cart-role">
                  <i className="far fa-table-list fa-xl glue-table" />
                  <span className="content-card-bucket">
                    Glue Table: {stage.table}{" "}
                  </span>
                </div>
                <div className="card-cart-role">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    Location: {stage.location}{" "}
                  </span>
                </div>
              </div>
            )}
            {isRSSourceType(stage.source_type) && (
              <div>
                <div className="card-cart-role">
                  <i className="fas fa-database fa-xl glue-table" />
                  <span className="content-card-bucket">
                    Schema: {stage.schema}
                  </span>
                </div>
                <div className="card-cart-role">
                  <i className="fas fa-table" />
                  <span className="content-card-bucket">
                    Table: {stage.table}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="card-cart-body-right">
            <div className="card-datasource-body-actions">
              {isS3SourceType(stage.source_type) &&
                !stage.name.includes("_normalized") && (
                  <div
                    className="btn-cart"
                    onClick={this.handleLaunchCustomCrawler}
                  >
                    {this.state.customCrawlerIsLaunching ? (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    ) : (
                      <i className="fas fa-spider fa-spacing" />
                    )}
                    <span className="text-actions">Launch custom crawler</span>
                  </div>
                )}
              <div className="btn-cart" onClick={this.onPreviewStage}>
                <i className="fas fa-eye fa-spacing" />
                <span className="text-actions">Preview</span>
              </div>
              <div className="btn-cart" onClick={this.onAnalyzeMetadata}>
                <i className="fas fa-chart-bar fa-spacing" />
                <span className="text-actions">Metadata</span>
              </div>
              {this.props.access === "owner" && (
                <div
                  className="btn-cart"
                  onClick={this.onOpenDeleteStageDialog}
                >
                  <i className="fas fa-trash fa-spacing" />
                  <span className="text-actions">Delete</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mcard-footer">
          <div className="mcard-begin">
            <div className="mcard-begin-content">
              <div>
                <i className="fas fa-table fa-spacing" />
                Profiling
                <span
                  className={classnames("mcard-text-header", {
                    "is-running": this.state.statusProfiling === "RUNNING"
                  })}
                >
                  {this.state.statusProfiling || "-"}
                </span>
              </div>
              <div className="ml-2">
                <i className="fas fa-file-code" />
                Basic Metadata
                <span className="mcard-text-header">
                  {dataset.basic_metadata ? "ENABLED" : "DISABLED"}
                </span>
              </div>
              {stage.is_table_existing_in_glue_catalog != null && (
                <div className="ml-2">
                  <i className="fas fa-heartbeat dataset-detail-icon" />
                  Status
                  <span
                    className={classnames(
                      "mcard-text-header text-uppercase",
                      {
                        "is-deleted": !stage.is_table_existing_in_glue_catalog
                      },
                      {
                        "is-available": stage.is_table_existing_in_glue_catalog
                      }
                    )}
                  >
                    {stage.is_table_existing_in_glue_catalog
                      ? "Available"
                      : "Deleted"}
                  </span>
                </div>
              )}
            </div>
          </div>
          {this.props.showDataFilters && (
            <div className="mcard-end">
              Data filters :{" "}
              <span className="mcard-text-header">
                {this.props.dataFiltersCount}
              </span>
            </div>
          )}
        </div>
        {this.props.stage && this.state.openDeleteDialog && (
          <DeleteObjectWithFrictionModal
            objectName={this.props.stage.name}
            deleteMessage={"Delete associated AWS Glue table ?"}
            onApply={this.onOpenDeleteStageDialog}
            onClose={this.onCloseDeleteStageDialog}
            open={this.state.openDeleteDialog}
            deleteFunction={this.deleteStage}
            isAWSResource
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(NewStageCardDetails)));
