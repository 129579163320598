import React from 'react';
import { Global, css } from '@emotion/core';

const ListenSvg = props => (
	<React.Fragment>
		<Global
			styles={css`
				@-webkit-keyframes kf_el_6WKby7wXqV_an_qqO-rxbNc {
					0% {
						opacity: 0;
					}
					13.89% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_6WKby7wXqV_an_qqO-rxbNc {
					0% {
						opacity: 0;
					}
					13.89% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_Wi-my975tM_an_XhXP1epXB {
					0% {
						opacity: 0;
					}
					27.78% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_Wi-my975tM_an_XhXP1epXB {
					0% {
						opacity: 0;
					}
					27.78% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_DkfFFTaFxy8_an_T2XxzvIaA {
					0% {
						opacity: 0;
					}
					41.67% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_DkfFFTaFxy8_an_T2XxzvIaA {
					0% {
						opacity: 0;
					}
					41.67% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_34IgwiMB5rf_an_TPom3H2LI {
					0% {
						opacity: 0;
					}
					55.56% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_34IgwiMB5rf_an_TPom3H2LI {
					0% {
						opacity: 0;
					}
					55.56% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_DeebuCsPTGA_an_aYTRBE7Na {
					0% {
						opacity: 0;
					}
					69.44% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_DeebuCsPTGA_an_aYTRBE7Na {
					0% {
						opacity: 0;
					}
					69.44% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_ZOjjrPTvyrv_an_l_BjBNzXw {
					0% {
						opacity: 0;
					}
					83.33% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_ZOjjrPTvyrv_an_l_BjBNzXw {
					0% {
						opacity: 0;
					}
					83.33% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@-webkit-keyframes kf_el_2FATegVmf0K_an_wLg4ofuFx {
					0% {
						opacity: 0;
					}
					97.22% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				@keyframes kf_el_2FATegVmf0K_an_wLg4ofuFx {
					0% {
						opacity: 0;
					}
					97.22% {
						opacity: 1;
					}
					100% {
						opacity: 1;
					}
				}
				#el_hiibMG0x- * {
					-webkit-animation-duration: 1.2s;
					animation-duration: 1.2s;
					-webkit-animation-iteration-count: infinite;
					animation-iteration-count: infinite;
					-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
					animation-timing-function: cubic-bezier(0, 0, 1, 1);
				}
				#el_QJeJ_2CDw5 {
					stroke: none;
					stroke-width: 1;
					fill: none;
				}
				#el_UYYCfubTRf {
					-webkit-transform: translate(163px, 123px);
					transform: translate(163px, 123px);
				}
				#el_uzZNtK32Zi {
					fill: #d8d8d8;
				}
				#el_EYKQ2N9Kgy {
					fill: #d8d8d8;
				}
				#el_6SDP2LAgKC {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
				}
				#el_-Vm65Ltfy7 {
					fill: #2196f3;
				}
				#el_q04iZcSim4 {
					fill: #d8d8d8;
				}
				#el_6WKby7wXqV {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_6WKby7wXqV_an_qqO-rxbNc;
					animation-name: kf_el_6WKby7wXqV_an_qqO-rxbNc;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_9bggsfQOtU {
					fill: #2196f3;
				}
				#el_NKxqi9eIym {
					fill: #d8d8d8;
				}
				#el_Wi-my975tM {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_Wi-my975tM_an_XhXP1epXB;
					animation-name: kf_el_Wi-my975tM_an_XhXP1epXB;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_zclQ34fvf7 {
					fill: #2196f3;
				}
				#el_1OsvRT8HkeZ {
					fill: #d8d8d8;
				}
				#el_DkfFFTaFxy8 {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_DkfFFTaFxy8_an_T2XxzvIaA;
					animation-name: kf_el_DkfFFTaFxy8_an_T2XxzvIaA;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_aa9sjx4H0vA {
					fill: #2196f3;
				}
				#el_tea114vWg0J {
					fill: #d8d8d8;
				}
				#el_34IgwiMB5rf {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_34IgwiMB5rf_an_TPom3H2LI;
					animation-name: kf_el_34IgwiMB5rf_an_TPom3H2LI;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_z5u6RAFhx7d {
					fill: #2196f3;
				}
				#el_7nfuWmA5Uhy {
					fill: #d8d8d8;
				}
				#el_DeebuCsPTGA {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_DeebuCsPTGA_an_aYTRBE7Na;
					animation-name: kf_el_DeebuCsPTGA_an_aYTRBE7Na;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el__ZcqlS20zcw {
					fill: #2196f3;
				}
				#el_8DnEQnD7VWV {
					fill: #d8d8d8;
				}
				#el_ZOjjrPTvyrv {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_ZOjjrPTvyrv_an_l_BjBNzXw;
					animation-name: kf_el_ZOjjrPTvyrv_an_l_BjBNzXw;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_FYYKCI_u24e {
					fill: #2196f3;
				}
				#el_XZty4MnTp5Y {
					fill: #d8d8d8;
				}
				#el_2FATegVmf0K {
					-webkit-transform: translate(37.846924px, 0px);
					transform: translate(37.846924px, 0px);
					-webkit-animation-fill-mode: backwards;
					animation-fill-mode: backwards;
					opacity: 0;
					-webkit-animation-name: kf_el_2FATegVmf0K_an_wLg4ofuFx;
					animation-name: kf_el_2FATegVmf0K_an_wLg4ofuFx;
					-webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				#el_RMT1KUfbdF8 {
					fill: #2196f3;
				}
				#el_RgLcovvFiO1 {
					fill: #d8d8d8;
				}
			`}
		/>
		<svg
			viewBox="0 0 480 480"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			id="el_hiibMG0x-"
			width={28}
			height={29}
			{...props}
			style={{ transform: 'scale(1.5)' }}
		>
			<defs>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-1"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-3"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-5"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-7"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-9"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-11"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-13"
				/>
				<path
					d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
					id="path-15"
				/>
			</defs>
			<g id="el_QJeJ_2CDw5" fillRule="evenodd">
				<g id="el_UYYCfubTRf">
					<path
						d="M142.731204,111 C137.280427,111 132.719573,114.852 131.82965,120.095 C127.268796,145.24 104.464526,164.5 76.9881611,164.5 C49.5117965,164.5 26.7075263,145.24 22.1466723,120.095 C21.2567496,114.852 16.6958955,111 11.2451187,111 C4.45945784,111 -0.880078594,116.778 0.121084488,123.198 C5.57186127,155.298 32.2695435,180.443 65.8641269,185.044 L65.8641269,207.3 C65.8641269,213.185 70.8699423,218 76.9881611,218 C83.10638,218 88.1121954,213.185 88.1121954,207.3 L88.1121954,185.044 C121.706779,180.443 148.404461,155.298 153.855238,123.198 C154.967641,116.778 149.516864,111 142.731204,111 Z"
						id="el_uzZNtK32Zi"
						fillRule="nonzero"
						style={{ fill: '#2196F3' }}
					/>
					<path
						d="M76.9864699,147.789474 C98.090352,147.789474 115.126016,131.286316 115.126016,110.842105 L115.126016,36.9473684 C115.126016,16.5031579 98.090352,0 76.9864699,0 C55.8825877,0 38.8469239,16.5031579 38.8469239,36.9473684 L38.8469239,110.842105 C38.8469239,131.286316 55.8825877,147.789474 76.9864699,147.789474 Z"
						id="el_EYKQ2N9Kgy"
						fillRule="nonzero"
					/>
					<g id="el_6SDP2LAgKC">
						<mask id="mask-2" fill="#fff">
							<use xlinkHref="#path-1" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_-Vm65Ltfy7"
							fillRule="nonzero"
							mask="url(#mask-2)"
						/>
						<rect
							id="el_q04iZcSim4"
							mask="url(#mask-2)"
							x="0.279"
							width="77"
							height="130"
						/>
					</g>
					<g id="el_6WKby7wXqV">
						<mask id="mask-4" fill="#fff">
							<use xlinkHref="#path-3" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_9bggsfQOtU"
							fillRule="nonzero"
							mask="url(#mask-4)"
						/>
						<rect
							id="el_NKxqi9eIym"
							mask="url(#mask-4)"
							x="0.279"
							width="77"
							height="115"
						/>
					</g>
					<g id="el_Wi-my975tM">
						<mask id="mask-6" fill="#fff">
							<use xlinkHref="#path-5" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_zclQ34fvf7"
							fillRule="nonzero"
							mask="url(#mask-6)"
						/>
						<rect
							id="el_1OsvRT8HkeZ"
							mask="url(#mask-6)"
							x="0.279"
							width="77"
							height="100"
						/>
					</g>
					<g id="el_DkfFFTaFxy8">
						<mask id="mask-8" fill="#fff">
							<use xlinkHref="#path-7" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_aa9sjx4H0vA"
							fillRule="nonzero"
							mask="url(#mask-8)"
						/>
						<rect
							id="el_tea114vWg0J"
							mask="url(#mask-8)"
							x="0.279"
							width="77"
							height="85"
						/>
					</g>
					<g id="el_34IgwiMB5rf">
						<mask id="mask-10" fill="#fff">
							<use xlinkHref="#path-9" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_z5u6RAFhx7d"
							fillRule="nonzero"
							mask="url(#mask-10)"
						/>
						<rect
							id="el_7nfuWmA5Uhy"
							mask="url(#mask-10)"
							x="0.279"
							width="77"
							height="70"
						/>
					</g>
					<g id="el_DeebuCsPTGA">
						<mask id="mask-12" fill="#fff">
							<use xlinkHref="#path-11" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el__ZcqlS20zcw"
							fillRule="nonzero"
							mask="url(#mask-12)"
						/>
						<rect
							id="el_8DnEQnD7VWV"
							mask="url(#mask-12)"
							x="0.279"
							width="77"
							height="55"
						/>
					</g>
					<g id="el_ZOjjrPTvyrv">
						<mask id="mask-14" fill="#fff">
							<use xlinkHref="#path-13" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_FYYKCI_u24e"
							fillRule="nonzero"
							mask="url(#mask-14)"
						/>
						<rect
							id="el_XZty4MnTp5Y"
							mask="url(#mask-14)"
							x="0.279"
							width="77"
							height="40"
						/>
					</g>
					<g id="el_2FATegVmf0K">
						<mask id="mask-16" fill="#fff">
							<use xlinkHref="#path-15" />
						</mask>
						<path
							d="M38.779092,147.789474 C60.0824253,147.789474 77.279092,131.286316 77.279092,110.842105 L77.279092,36.9473684 C77.279092,16.5031579 60.0824253,0 38.779092,0 C17.4757586,0 0.279091964,16.5031579 0.279091964,36.9473684 L0.279091964,110.842105 C0.279091964,131.286316 17.4757586,147.789474 38.779092,147.789474 Z"
							id="el_RMT1KUfbdF8"
							fillRule="nonzero"
							mask="url(#mask-16)"
						/>
						<rect
							id="el_RgLcovvFiO1"
							mask="url(#mask-16)"
							x="0.279"
							width="77"
							height="25"
						/>
					</g>
				</g>
			</g>
		</svg>
	</React.Fragment>
);

export default ListenSvg;
