export const listRedshiftClustersFromAws = {
  query: `query Q ($platformUri: String!, $region:String!) {
        listRedshiftClustersFromAws (platformUri:$platformUri, region:$region) {
          cluster_arn
          description
          resource_name
          database_name
          subnet_group_name
          master_username
          endpoint_address
          endpoint_port
          public_key
          iam_roles
          number_of_nodes
          vpc_security_groups
          node_type
        }
    }
    `,
  keyOutput: "listRedshiftClustersFromAws"
};
