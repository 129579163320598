export const listPlatformDatasources = {
  query: `query Q ($platformUri:String!, $options: QueryOptions) {
        listPlatformDatasources(platformUri:$platformUri, options: $options) {
            results {
                uri
                name
                region
                accountid
                bucket
                description
                createdat
                owner {
                    uri
                    name
                }
                datasetsList {
                    total
                }
                source_type 
            }
            total
            limit
            offset
        }
      }
    `,
  keyOutput: "listPlatformDatasources"
};
