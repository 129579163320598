/* @flow */

export const listPolicies = {
  query: `query Q ($accountUri: String!, $options: PoliciesSearchOptions) {
        listAccountPolicies(accountUri: $accountUri, options: $options) {
            total
            results {
                is_admin
                policies {
                    group {
                        name
                        uri
                    }
                    environment {
                        name
                        uri
                        aws
                    }
                    policyarn
                    description
                    nb_project
                }
            }
        }
      }
    `,
  keyOutput: "listAccountPolicies"
};

export type PoliciesSearchOptionsPropsTypes = {
  group_uri?: string,
  platform_uri?: string,
  policyarn?: string,
  description?: string,
  nb_projects?: number,
  invert_group_relation?: boolean,
  limit?: number,
  offset?: number
};
