/* @flow */

import React from "react";
import "./style.less";
import TextWithoutProjects from "./textWithoutProjects";
import TextWithProjects from "./textWithProjects";

type propTypes = {
  datasetUri: string,
  message: string
};

type stateTypes = {
  datasetName: string,
  endMessage: string
};

class DatasetUpdateOutdatedNotification extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      datasetName: "",
      endMessage: ""
    };
  }

  componentDidMount() {
    this.manipulateMessage();
  }

  manipulateMessage = () => {
    try {
      if (this.props.message.length > 0) {
        const arrMessage = this.props.message.split("'");
        const endMessageProjects = arrMessage.splice(2).join(" ");
        this.setState({
          datasetName: this.props.message.split("'")[1],
          endMessage: endMessageProjects
        });
      }
    } catch (error) {
      this.setState({
        datasetName: "undefined",
        endMessage: ""
      });
    }
  };

  render() {
    if (this.props.message.includes("project")) {
      return (
        <TextWithProjects
          datasetName={this.state.datasetName}
          datasetUri={this.props.datasetUri}
          endMessage={this.state.endMessage}
        />
      );
    }

    return (
      <TextWithoutProjects
        datasetName={this.state.datasetName}
        datasetUri={this.props.datasetUri}
      />
    );
  }
}

export default DatasetUpdateOutdatedNotification;
