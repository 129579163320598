export const getCSVExportForCatalog = {
  query: `query Q ($input: SearchInput) {
        getCSVExportForCatalog(input: $input) {
            url
            headers
        }
    }
    `,
  keyOutput: "getCSVExportForCatalog"
};
