/* @flow */

import React from "react";
import "./modal.css";
import Error from "./error";

type modalPropTypes = {
  id?: string,
  classname?: string,
  title: string,
  body: string | Object,
  actions: Array<Object>,
  errorMessage?: string,
  classModalLarge?: string
};

const Modal = ({
  title,
  body,
  actions,
  errorMessage,
  classname = "",
  classModalLarge = "",
  id = ""
}: modalPropTypes) => (
  <div className={classname}>
    <div id={id} className="my-modal">
      <div className={`modal-dialog ${classModalLarge}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body">
            {body}
            {errorMessage && (
              <Error error={errorMessage} path={"modal"} stringOnly />
            )}
          </div>
          <div className="modal-footer">{actions}</div>
        </div>
      </div>
    </div>
    <div className={"my-modal-backdrop"} />
  </div>
);

export default Modal;
