/* @flow */
import React from "react";
import { connect } from "react-redux";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import SelectInfinite from "../../../components/SelectInfinite";
import "./styles.less";
import withAppSync from "../../AppsyncHOC";
import ToggleButton from "../../../components/toggleButton";
import SelectClassic from "../../../components/SelectClassic";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import routes from "../../routes";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";

const Log = Logger("TransferOwnership");

type propTypes = {
  location: {
    state: {
      datasource: Object
    }
  },
  match: {
    params: {
      uriAccount: string,
      uriDatasource: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  ready: boolean,
  managerGroupReady: boolean,
  datasource: Object,
  groupSelected: Object,
  sharingManagementGroupSelected: Object,
  totalGroups: number,
  totalGroupsForSearchLimit: number,
  totalSharingManagementGroups: number,
  totalSharingManagementGroupsForSearchLimit: number,
  initialGroups: Array<Object>,
  initialManagerGroups: Array<Object>,
  isSubmitting: boolean,
  offsetGroups: number,
  inputs: Object,
  errorSubmit: Object
};

class DatasourceTransferOwnership extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      managerGroupReady: false,
      datasource: this.props.location.state.datasource,
      groupSelected: null,
      totalGroups: 0,
      totalGroupsForSearchLimit: 0,
      totalSharingManagementGroups: 0,
      totalSharingManagementGroupsForSearchLimit: 0,
      initialGroups: [],
      initialManagerGroups: [],
      isSubmitting: false,
      offsetGroups: 0,
      sharingManagementGroupSelected: {
        value: this.props.location.state.datasource.sharingManagementGroup.uri,
        label: this.props.location.state.datasource.sharingManagementGroup.name
      },
      inputs: {
        owning_group_uri: "",
        sharing_group_uri:
          this.props.location.state.datasource.sharingManagementGroup.uri,
        share_with_previous_owner: false,
        delete_etl_creds: false,
        delete_loaders: false,
        revoke_shares: false
      },
      errorSubmit: undefined
    };
  }

  componentDidMount() {
    this.fetchGroups().then((groups) => {
      this.setState((prevState) => ({
        initialGroups: groups,
        ready: true,
        totalGroupsForSearchLimit: prevState.totalGroups
      }));
    });
    this.fetchManagerGroups().then((groups) => {
      this.setState((prevState) => ({
        initialManagerGroups: groups,
        managerGroupReady: true,
        totalSharingManagementGroupsForSearchLimit:
          prevState.totalSharingManagementGroups
      }));
    });
  }

  fetchGroups = (offset, search) => {
    let queryOptions = {};
    const filters = [];
    filters.push({ key: "exclude_service_groups", value: true });
    if (search) {
      queryOptions = {
        offset: 0,
        limit: this.state.totalGroupsForSearchLimit,
        filters,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset: this.state.offsetGroups,
        limit: 10,
        filters
      };
    }
    return this.props.api.playground
      .listGroups(
        this.props.match.params.uriAccount,
        this.state.datasource.platform.uri,
        queryOptions
      )
      .then((listGroupsResponse) => {
        const groups = listGroupsResponse.results.filter(
          (g) => g.uri !== this.state.datasource.owner.uri
        );
        this.setState((prevState) => ({
          totalGroups:
            (prevState.offsetGroups === 0
              ? listGroupsResponse.total
              : prevState.totalGroups) -
            (listGroupsResponse.results.length - groups.length),
          offsetGroups: prevState.offsetGroups + listGroupsResponse.limit
        }));
        return groups.map((g) => ({ value: g.uri, label: g.name }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });
  };

  loadMoreGroups = (offset, search) => {
    if (search && search.length < 3) {
      return false;
    }
    return this.fetchGroups(offset, search);
  };

  fetchManagerGroups = (offset = 0, search) => {
    let queryOptions = {};
    if (search) {
      queryOptions = {
        offset: 0,
        limit: this.state.totalSharingManagementGroupsForSearchLimit,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset,
        limit: 10
      };
    }
    return this.props.api.account
      .listGroupsForSharingGroup(
        this.props.match.params.uriAccount,
        queryOptions
      )
      .then((groupsResponse) => {
        if (groupsResponse) {
          this.setState({
            totalSharingManagementGroups: groupsResponse.total
          });
          return groupsResponse.results.map((g) => ({
            value: g.uri,
            label: g.name
          }));
        }
        return [];
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });
  };

  loadMoreManagerGroups = (offset = 0, search) => {
    if (search && search.length < 3) {
      return false;
    }
    return this.fetchManagerGroups(offset, search);
  };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const { inputs } = prevState;
      inputs[field] = value;
      Log.info("handleChange, inputs:", inputs);
      return { inputs };
    });
  };

  submit = () => {
    this.setState({ isSubmitting: true });
    this.props.api.datasource
      .transferOwnership(this.state.datasource.uri, this.state.inputs)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Transfer done !",
          type: "success"
        });
        this.props.goTo({ route: routes.MyData.View });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ errorSubmit: error, isSubmitting: false });
      });
  };

  render() {
    return (
      <div className="datasource-transfer-ownership">
        <Breadcrumb view="Datasource, transfer ownership" />

        <div className="datasource-transfer">
          <div className="datasource-transfer-info">
            <div className={"alert alert-light"}>
              <i className="fas fa-exclamation-circle" />
              &nbsp;Warning:&nbsp;
              <div>
                Once the transfer is done, you lose ownership on the
                datasource.&nbsp; So no way to rollback if you are not member of
                the new owner group.
              </div>
            </div>
          </div>

          <hr />

          <div className="datasource-info">
            <div>
              <label className={"label-form"}>Name:</label>
              <SelectClassic
                isDisabled
                placeholder={this.state.datasource.name}
              />
            </div>
            <div>
              <label className={"label-form"}>Environment:</label>
              <SelectClassic
                isDisabled
                placeholder={`${this.state.datasource.platform.name} (${this.state.datasource.platform.aws})`}
              />
            </div>
            <div>
              <label className={"label-form"}>Current Owner group:</label>
              <SelectClassic
                isDisabled
                placeholder={this.state.datasource.owner.name}
              />
            </div>
          </div>

          <hr />

          <div className="datasource-transfer-form">
            <div>
              <label className="label-form">Choose the new owner group</label>
              <SelectInfinite
                key={"select-infinite--datasource-new-owner"}
                isLoadingOptions={!this.state.ready}
                placeholder="Select a group"
                isDisabled={false}
                isSearchable
                selectedOption={this.state.groupSelected}
                totalOptions={this.state.totalGroups}
                initialOptions={this.state.initialGroups}
                loadMoreOptions={this.loadMoreGroups}
                onSelectOption={(option) => {
                  this.setState({ groupSelected: option });
                  this.handleChange("owning_group_uri", option.value);
                }}
                optionResults
              />
            </div>

            <div className="datasource-transfer-form-options-title">
              Transfer options:
            </div>

            <div className="datasource-transfer-form-field">
              <label className="label-form">
                Change Sharing group manager ?
              </label>
              <SelectInfinite
                key={"select-infinite-datasource-sharing-group"}
                isLoadingOptions={!this.state.managerGroupReady}
                placeholder={"Select a Sharing Management Group"}
                selectedOption={this.state.sharingManagementGroupSelected}
                totalOptions={this.state.totalSharingManagementGroups}
                initialOptions={this.state.initialManagerGroups}
                isSearchable
                loadMoreOptions={this.loadMoreManagerGroups}
                onSelectOption={(option) => {
                  this.setState({ sharingManagementGroupSelected: option });
                  this.handleChange("sharing_group_uri", option.value);
                }}
                optionResults
              />
            </div>

            <div className="datasource-transfer-form-options">
              <div className="datasource-transfer-form-field">
                <label className="label-form">
                  Share with previous owner group ?
                </label>
                <div className="datasource-transfer-form-field-value">
                  <ToggleButton
                    id="id-share_with_previous_owner"
                    checkedValue={this.state.inputs.share_with_previous_owner}
                    onChange={() =>
                      this.handleChange(
                        "share_with_previous_owner",
                        !this.state.inputs.share_with_previous_owner
                      )
                    }
                  />
                </div>
              </div>

              <div className="datasource-transfer-form-field">
                <label className="label-form">
                  Delete datasource etl credentials ?
                </label>
                <div className="datasource-transfer-form-field-value">
                  <ToggleButton
                    id="id-delete_etl_creds"
                    checkedValue={this.state.inputs.delete_etl_creds}
                    onChange={() =>
                      this.handleChange(
                        "delete_etl_creds",
                        !this.state.inputs.delete_etl_creds
                      )
                    }
                  />
                </div>
              </div>

              <div className="datasource-transfer-form-field">
                <label className="label-form">Delete loaders ?</label>
                <div className="datasource-transfer-form-field-value">
                  <ToggleButton
                    id="id-delete_loaders"
                    checkedValue={this.state.inputs.delete_loaders}
                    onChange={() =>
                      this.handleChange(
                        "delete_loaders",
                        !this.state.inputs.delete_loaders
                      )
                    }
                  />
                </div>
              </div>

              <div className="datasource-transfer-form-field">
                <label className="label-form">Revoke all shares ?</label>
                <div className="datasource-transfer-form-field-value">
                  <ToggleButton
                    id="id-revoke_shares"
                    checkedValue={this.state.inputs.revoke_shares}
                    onChange={() =>
                      this.handleChange(
                        "revoke_shares",
                        !this.state.inputs.revoke_shares
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {this.state.errorSubmit && (
              <Error
                error={this.state.errorSubmit}
                path={"DatasourceTransferOwnership"}
                stringOnly
              />
            )}

            <div className="datasource-transfer-form-submit">
              <button
                type="submit"
                disabled={this.state.isSubmitting}
                className="butn"
                onClick={this.submit}
              >
                {this.state.isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Transfer ownership
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceTransferOwnership)));
