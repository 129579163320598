export const queryListMySharesRequests = {
  query: `query Q ($options: QueryOptions) {
        getMyShareRequestsList (options: $options) {
            total
            offset
            limit
            results {
                id
                createdat
                updatedat
                request_status
                last_request_date
                message
                reject_message
                group {
                    uri
                    name
                    account {
                        uri
                        name
                    }
                }
                dataset {
                    uri
                    name
                }
            }
        }
    }`,
  keyOutput: "getMyShareRequestsList"
};
