export const listDatasourceProviders = {
  query: `query Q ($datasourceUri: String!) {
        listDatasourceProviders(datasourceUri:$datasourceUri) {
          arn
          overwrite
          status
          userid
          description
        }
      }
    `,
  keyOutput: "listDatasourceProviders"
};
