/* @flow */
import React from "react";
import "./view.less";
import Modal from "../../../components/modal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  datasource: Object,
  showGlobalNotification: Function,
  goBack: Function,
  hide: Function,
  reload?: boolean,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  confirmationText: string
};

class DetachDatasourceModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      confirmationText: ""
    };
  }

  hideModalAndReload = () => {
    this.props.hide();
    window.location.reload();
  };

  detachEquansDatasource = () => {
    const { datasource, goBack, showGlobalNotification, reload } = this.props;
    return this.props.api.datasource
      .detachEquansDatasource(datasource.uri)
      .then(() =>
        showGlobalNotification({
          message: "Datasource detached",
          type: "success"
        })
      )
      .then(() => (reload ? this.hideModalAndReload() : goBack()))
      .catch((error) => {
        showGlobalNotification({
          message: "Failed to detach datasource",
          type: "alert"
        });
        const errorMessage = getErrorMessageWithoutCode(error);
        this.setState({ error: errorMessage });
      })
      .finally(() => this.setState({ confirmationText: "" }));
  };

  handleChange = (event) => {
    this.setState({ confirmationText: event.target.value });
  };

  renderTitle = () => (
    <div>
      Detach datasource <b>{this.props.datasource.name}</b>
    </div>
  );

  renderBody = () => (
    <div>
      <div className={"alert alert-warning"} style={{ fontWeight: 600 }}>
        <p>
          <span>
            Detaching this datasource <b>will NOT DELETE</b> any of its related
            AWS resources from your AWS account.
          </span>
        </p>
        <p>
          <span>
            AWS resources like S3 bucket, KMS key and Glue database{" "}
            <b> will CEASE to be managed</b> by the CommonDataHub once you
            confirm this action.
          </span>
        </p>
        <p>
          <span>
            Please refer to our{" "}
            <i>
              <a
                href={"https://confluence.tools.digital.engie.com/"}
                className="alert-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation center{" "}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </i>{" "}
            for more information.
          </span>
        </p>
        <p>
          {" "}
          <i className="fas fa-exclamation-circle fa-lg"></i>{" "}
          <span>
            WARNING: this action is <b>IRREVERSIBLE</b>.
          </span>{" "}
        </p>
      </div>
      <div>
        To confirm detach action, type <b>permanently detach</b> in the text
        input field.
      </div>
      <div className="mt-2">
        <input
          onChange={this.handleChange}
          value={this.state.confirmationText}
          className="form-control bg-white"
          placeholder="permanently detach"
        />
      </div>
    </div>
  );

  render() {
    const { datasource, hide } = this.props;

    const { error } = this.state;

    return (
      <Modal
        title={this.renderTitle()}
        body={!error && this.renderBody()}
        errorMessage={error}
        classModalLarge={"modal-lg"}
        actions={[
          <button
            key={`dataset-close-${datasource.uri}`}
            type="button"
            className="butn butn-flat"
            onClick={hide}
          >
            Close
          </button>,
          !error && (
            <ButtonAction
              key={`dataset-delete-${datasource.uri}`}
              disabled={this.state.confirmationText !== "permanently detach"}
              label="Detach"
              className="butn butn-check"
              onClick={this.detachEquansDatasource}
            />
          )
        ]}
      />
    );
  }
}

export default withAppSync(DetachDatasourceModal);
