/* @flow */

import React from "react";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";

type propstypes = {
  users: Array<Object>,
  error?: Object,
  errorPath: string,
  isReady: boolean,
  loadingMessage: string,
  onSearch: Function,
  componentUsersListEmpty?: Object,
  className?: string
};

const ListUsers = ({
  users = [],
  error = undefined,
  errorPath = "listUsers",
  isReady = false,
  loadingMessage = "",
  onSearch = () => {},
  componentUsersListEmpty = undefined,
  className = ""
}: propstypes) => (
  <div className={className}>
    <div className="search">
      <div className="search-input">
        <input
          className="form-control"
          placeholder="Search User"
          onChange={onSearch}
        />
        <i className="fas fa-search" />
      </div>
    </div>
    {error && <Error error={error} path={errorPath} />}
    {!isReady && <Loading message={loadingMessage} />}
    {isReady &&
      users.length <= 0 &&
      (componentUsersListEmpty || (
        <div style={{ marginLeft: "30px" }}>No user to display.</div>
      ))}
    {isReady && <ul className="list-group">{users}</ul>}
  </div>
);

export default ListUsers;
