/* @flow */
import React from "react";
import Pagination from "react-js-pagination";
import "./style.less";
import Loading from "../loading";

type PropTypes = {
  limit: number,
  initialOffset: number,
  list: Array<Object>,
  componentRender: Function,
  loadPage: Function,
  onNoResult?: Function,
  totalCount?: number,
  container?: Function,
  isLoadingPage?: boolean,
  loadingMessage?: Object | string,
  version?: string
};

type StateTypes = {
  hasNext: boolean,
  currentOffset: number,
  currentPage: number,
  nbPageEstimated: number
};

class Paginator extends React.Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props);
    let hasNext;
    if (props.totalCount) {
      hasNext = props.totalCount - props.initialOffset > 0;
    } else {
      hasNext = props.list.length === props.limit && props.limit !== 0;
    }
    this.state = {
      hasNext,
      currentOffset: props.initialOffset,
      currentPage: Math.trunc(props.initialOffset / props.limit) + 1 || 1,
      nbPageEstimated: this.calculNbPageEstimated()
    };
  }

  componentDidUpdate(prevProps: PropTypes, prevState: StateTypes): void {
    if (
      (prevState.currentPage === 1 &&
        prevProps.isLoadingPage !== this.props.isLoadingPage) ||
      prevProps.version !== this.props.version
    ) {
      this.setHasNext();
    }
  }

  calculNbPageEstimated = () => {
    let nbPageEstimated = 1;
    if (this.props.totalCount) {
      nbPageEstimated = Math.trunc(this.props.totalCount / this.props.limit);
    } else if (this.props.list.length === this.props.limit) {
      nbPageEstimated = 5;
    } else {
      nbPageEstimated = 1;
    }

    return nbPageEstimated;
  };

  setHasNext = () => {
    this.setState({
      hasNext: this.props.list.length === this.props.limit,
      nbPageEstimated: this.calculNbPageEstimated()
    });
  };

  render() {
    return (
      <div className="paginator w-100">
        {this.props.isLoadingPage
          ? this.props.loadingMessage || <Loading message=" " />
          : undefined}
        {!this.props.isLoadingPage ? (
          <div className="content">
            {this.props.container && this.props.list // $FlowIgnore
              ? this.props.container(
                  this.props.list.map((item) =>
                    this.props.componentRender(item)
                  )
                )
              : undefined}
            {!this.props.container && this.props.list
              ? this.props.list.map((item) => this.props.componentRender(item))
              : undefined}
          </div>
        ) : undefined}

        {!this.props.isLoadingPage &&
          this.props.list.length === 0 &&
          this.props.onNoResult &&
          this.props.onNoResult()}

        {/* hide pagination if only one page */}
        {(this.state.currentPage !== 1 ||
          (this.props.totalCount
            ? this.props.totalCount > this.props.limit
            : false) ||
          this.state.hasNext ||
          this.state.nbPageEstimated > 1) && (
          <div className="row justify-content-center">
            {this.props.totalCount ? (
              <div>
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={this.props.limit}
                  prevPageText="<"
                  nextPageText=">"
                  firstPageText="<<"
                  lastPageText=">>"
                  hideFirstLastPages={this.state.nbPageEstimated < 3}
                  totalItemsCount={this.props.totalCount}
                  onChange={(pageNumber) =>
                    this.props
                      .loadPage((pageNumber - 1) * this.props.limit)
                      .then(() => {
                        this.setState({
                          currentPage: pageNumber,
                          currentOffset: (pageNumber - 1) * this.props.limit
                        });
                      })
                  }
                />
              </div>
            ) : (
              <div>
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={this.props.limit}
                  prevPageText="<"
                  nextPageText=">"
                  hideFirstLastPages
                  totalItemsCount={
                    this.state.nbPageEstimated * this.props.limit
                  }
                  onChange={(pageNumber) =>
                    this.props
                      .loadPage((pageNumber - 1) * this.props.limit)
                      .then((list) => {
                        if (list.length === 0) {
                          this.setState({ hasNext: false });
                          return this.props.loadPage(this.state.currentOffset);
                        }

                        return this.setState((previousState) => ({
                          currentPage: pageNumber,
                          currentOffset: (pageNumber - 1) * this.props.limit,
                          nbPageEstimated:
                            previousState.nbPageEstimated <= pageNumber
                              ? pageNumber +
                                (list.length === this.props.limit ? 1 : 0)
                              : previousState.nbPageEstimated,
                          hasNext:
                            previousState.nbPageEstimated < pageNumber
                              ? list.length === this.props.limit
                              : previousState.hasNext
                        }));
                      })
                  }
                />
              </div>
            )}
            <div className="col-12 pagination-current-elements text-center">
              {this.state.currentOffset + (this.props.list.length ? 1 : 0)} -{" "}
              {this.state.currentOffset + this.props.list.length}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Paginator;
