export const getDatasetNameFromLocation = ({ location, match }) =>
  (location.state && location.state.dataset && location.state.dataset.name) ||
  (match.params && match.params.uriDataset) ||
  "-";

export const getAccountNameFromLocation = ({ location, match }) =>
  (location.state && location.state.account && location.state.account.name) ||
  (match.params && match.params.uriAccount) ||
  "-";

export const getGroupNameFromLocation = ({ location, match }) =>
  (location.state && location.state.group && location.state.group.name) ||
  (match.params && match.params.uriGroup) ||
  "-";

export const getPlaygroundNameFromLocation = ({ location, match }) =>
  (location.state &&
    location.state.playground &&
    location.state.playground.name) ||
  (match.params && match.params.uriPlayground) ||
  "-";

export const getDatasourceNameFromLocation = ({ location, match }) =>
  (location.state &&
    location.state.datasource &&
    location.state.datasource.name) ||
  (match.params && match.params.uriDatasource) ||
  "-";
