/* @flow */

export const getDataset = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      uri
      accountid
      name
      resource_name
      description
      tags
      doc
      type
      confidentiality
      domain
      visibility
      shareable
      is_registered_with_lakeformation
      refresh_sla
      last_loaded
      details
      createdat
      updatedat
      period_start_at
      period_end_at
      expiration_date
      languages
      data_types
      default_table
      access
      basic_metadata
      metadata_frequency
      is_partitioned
      maturity_level
      can_share
      update_frequency
      size
      arn
      s3_inventory_last_update
      last_metadata_update
      source_type
      schema
      database
      datasource {
        uri
        bucket
        name
        resource_name
        aws
        region
        is_registered_with_lakeformation
        source_type
        sharingManagementGroup {
          uri
          name
        }
        platform{
            uri
            auto_refresh_stages
            name
        }
      }
      owner {
        uri
        name
      }
      creator {
        uri
        name
      }
      sharesList {
        total
      }
      stages {
        id
        table
        description
        updatedat
        is_default
      }
    }
  }
`,
  keyOutput: "getDataset"
};

export type GetDatasetPropTypes = {
  uri: string,
  accountid: string,
  name: string,
  resource_name: string,
  description: string,
  tags: string,
  doc: string,
  type: string,
  confidentiality: string,
  domain: string,
  refresh_sla: string,
  last_loaded: string,
  details: string,
  createdat: string,
  updatedat: string,
  period_start_at: string,
  period_end_at: string,
  expiration_date: string,
  language: string,
  default_table: string,
  access: string,
  basic_metadata: string,
  metadata_frequency: string,
  maturity_level: string,
  arn: string,
  shareable: boolean,
  datasource: {
    uri: string,
    bucket: string,
    name: string,
    resource_name: string,
    aws: string,
    region: string,
    is_registered_with_lakeformation: boolean
  },
  owner: {
    uri: string,
    name: string
  },
  creator: {
    uri: string,
    name: string
  },
  stages: {
    id: string,
    table: string,
    description: string,
    updatedat: string,
    tags: string,
    is_default: string
  },
  sharesList: {
    total: number
  }
};
