/* @flow */

export const updateDataFilter = {
  query: `mutation Q ($datasetUri: String!, $dataFilterId: String!, $input: UpdateDataFilterInput!) {
        updateDataFilter(datasetUri: $datasetUri, dataFilterId: $dataFilterId, inputData: $input) {
            id
            name
            resource_name
            creator
            status
            createdat
            updatedat
            description
            database_name
            table_name
            columns
            column_level_access
            row_filter_expression
            region
            aws
        }
      }
    `,
  keyOutput: "updateDataFilter"
};
