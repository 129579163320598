/* @flow */

import React from "react";
import "./style.less";
import Modal from "../../../../components/modal";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";

const Log = Logger("DeleteTrustModal");

type propTypes = {
  trust: Object,
  hide: Function,
  onDeleteTrustSuccess: Function,
  onDeleteTrustFailure: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  isFetching: boolean
};

class DeleteTrustModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      isFetching: false
    };
  }

  deleteTrust = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.account
          .deleteTrust(this.props.trust.id)
          .then(() => {
            this.setState({
              isFetching: false
            });
            this.props.onDeleteTrustSuccess();
          })
          .catch((error) => {
            Log.error(error);
            this.setState({
              error,
              isFetching: false
            });
            this.props.onDeleteTrustFailure();
          });
      }
    );
  };

  render() {
    return (
      <Modal
        title={"Delete Trust"}
        body={`Do you really want to delete this trust (${this.props.trust.arn}) ?`}
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-close-${this.props.trust.arn}`}
            type="button"
            className="butn"
            onClick={this.props.hide}
          >
            Close
          </button>,
          <button
            key={`dataset-delete-${this.props.trust.arn}`}
            type="button"
            className="butn butn-delete"
            onClick={() => this.deleteTrust()}
          >
            {this.state.isFetching && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            Delete
          </button>
        ]}
      />
    );
  }
}

export default withAppSync(DeleteTrustModal);
