/* eslint-disable quote-props */
/* @flow */
import React from "react";
import "./card.less";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import DatasetCardPublisher from "./DatasetCardPublisher";
import withErrorBoundary from "../../errorBoundaryHOC";
import DatasourceCardContainer from "./DatasourceCardContainer";
import DatasourceCardTitle from "./DatasourceCardTitle";

type propTypes = {
  datasource: Object,
  goTo: Function,
  style: Object,
  addDatasourceToList: Function,
  removeDatasourceToList: Function,
  defaultSelect: boolean
};

type stateTypes = {
  isSelected: boolean
};

const classEnvironment = {
  NoProd: "NoProd",
  Prod: "Prod"
};

class DatasourceCardAddToProject extends React.Component<
  propTypes,
  stateTypes
> {
  DOMRef: Object;

  constructor(props) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {
      isSelected: false
    };
  }

  addDatasourceToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.addDatasourceToList(this.props.datasource);
  };

  removeDatasourceToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeDatasourceToList(this.props.datasource);
  };

  renderActions() {
    if (this.props.datasource.access !== "other") {
      if (this.props.defaultSelect) {
        return [
          <div
            className="butn butn-flat min-width-130"
            onClick={(e) => this.removeDatasourceToList(e)}
          >
            <i className={"butn-icon fas fa-check-square"} />
            <span className="butn-text">Unselect</span>
          </div>
        ];
      }
      return [
        <div
          className="butn butn-flat min-width-130"
          onClick={(e) => this.addDatasourceToList(e)}
        >
          <i className={"butn-icon far fa-square"} />
          <span className="butn-text">Select</span>
        </div>
      ];
    }
    return false;
  }

  render() {
    return (
      <DatasourceCardContainer
        style={this.props.style}
        ref={this.DOMRef}
        isSelected={this.state.isSelected}
        goToDataset={() =>
          this.props.goTo({
            route: routes.Datasource.View,
            params: {
              uriDataset: this.props.datasource.uri
            },
            state: {
              dataset: {
                uri: this.props.datasource.uri,
                name: this.props.datasource.name
              }
            }
          })
        }
        dataset={{
          key: Math.random(),
          uri: this.props.datasource.uri,
          name: this.props.datasource.name
        }}
      >
        <div className="card-dataset-header">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content">
              <i className="far fa-database" />
              <span className="text-header-dataset">Datasource</span>
            </div>
          </div>
          <div className="card-dataset-end">
            <div
              className={`dataset-card-environment ${
                classEnvironment[this.props.datasource.platform_environment]
              }`}
            >
              <span>{this.props.datasource.platform_environment}</span>
            </div>
            {this.props.datasource.access === "owner" ? (
              <span className="card-dataset-access owner">OWNED</span>
            ) : (
              <span className="card-dataset-access shared">SHARED</span>
            )}
          </div>
        </div>
        <div className="card-dataset-body">
          <div className="card-dataset-body-left">
            <div>
              <DatasourceCardTitle
                name={this.props.datasource.name}
                accountid={this.props.datasource.accountid}
                uri={this.props.datasource.uri}
                access={this.props.datasource.access}
              />
              <DatasetCardPublisher
                publisher={this.props.datasource.account_name}
                ownergroupname={this.props.datasource.owner_group_name}
                size={250}
              />
            </div>
            <div className="card-dataset-details">
              <p className="card-dataset-description">
                {this.props.datasource.description}
              </p>
            </div>
            <div>
              <div className="card-dataset-tags">
                <div className="">
                  {this.props.datasource.s3_custom_tags &&
                    this.props.datasource.s3_custom_tags.map((tag) => (
                      <div className="dataset-badge" key={tag.Key}>
                        <div className="circle-tag" />
                        {tag.Value}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">{this.renderActions()}</div>
        </div>
        <div className="card-dataset-footer">
          <div className="card-dataset-end">
            <div className="text-footer-metrics">
              Contains{" "}
              <span className="tag-metrics">
                {this.props.datasource.datasetsTotal}
              </span>{" "}
              datasets
            </div>
          </div>
        </div>
      </DatasourceCardContainer>
    );
  }
}

export default withErrorBoundary(withGoTo(DatasourceCardAddToProject), false);
