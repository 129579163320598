/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./view.less";
import Error from "../../../components/error";
import DatasourceProviderModal from "./DatasourceProviderModal";
import { updateMessage } from "../../globalNotifications/actions";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import DatasourceProviderItem from "./DatasourceProviderItem";
import DatasourceProviderEditItem from "./DatasourceProviderEditItem";
import DatasourceProvidersAdd from "./DatasourceProviderAdd";
import GraphQl from "../../../graphQL";

type propTypes = {
  datasource: {
    uri: string
  },
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  isCheckingAvailability: boolean,
  isLoadingProviders: boolean,
  datasourceProviderModal: Object,
  providers: Array<Object>,
  openForm: boolean,
  addingNewLoader: boolean,
  displayLoaderWarning: boolean
};

class DatasourceProviders extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isCheckingAvailability: false,
      isLoadingProviders: false,
      providers: [],
      datasourceProviderModal: undefined,
      displayLoaderWarning: false,
      openForm: false,
      addingNewLoader: false
    };
  }

  componentDidMount() {
    this.listProviders();
  }

  listProviders = () => {
    this.setState({ isLoadingProviders: true });
    return this.props.api.datasource
      .listProviders(this.props.datasource.uri)
      .then((providers) => {
        this.setState({
          providers,
          isLoadingProviders: false
        });
        providers.map((provider) => {
          if (provider.status === "UNAVAILABLE") {
            this.setState({ displayLoaderWarning: true });
          }
          return true;
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoadingProviders: false
        });
      });
  };

  checkAvailabilty = () => {
    this.setState({ isCheckingAvailability: true });
    return this.props.api.datasource
      .checkAvailabiltyLoaders(this.props.datasource.uri)
      .then(() => {
        this.setState({ isCheckingAvailability: false });
        this.listProviders();
      })
      .catch((error) => {
        this.setState({ error, isCheckingAvailability: false });
      });
  };

  openDatasourceProviderModal = (datasouceProvider) =>
    this.setState({ datasourceProviderModal: { datasouceProvider } });

  closeDatasourceProviderModal = () =>
    this.setState({ datasourceProviderModal: undefined });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasourceProviders"} />;
    return (
      <div className={"datasource-providers"}>
        <div className="datasource-loaders-actions">
          {this.state.openForm ? undefined : (
            <div
              className="butn butn-create"
              onClick={() =>
                this.setState({ openForm: true, addingNewLoader: true })
              }
            >
              <i className={"fas fa-lg fa-plus pr-2"} />
              Add Loader
            </div>
          )}

          {this.state.providers && this.state.providers.length > 0 && (
            <div className="check-availability-container">
              <div className="butn" onClick={() => this.checkAvailabilty()}>
                {this.state.isCheckingAvailability && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Check availability
              </div>
            </div>
          )}
        </div>

        {this.state.isLoadingProviders ? (
          <Loading message="Loaders List" />
        ) : undefined}
        {this.state.openForm && this.state.addingNewLoader ? (
          <DatasourceProvidersAdd
            datasource={this.props.datasource}
            api={this.props.api}
            onClose={() => {
              this.setState({ openForm: false, addingNewLoader: false });
            }}
            onAdd={(providers) => {
              this.setState({
                providers,
                openForm: false,
                addingNewLoader: false
              });
              this.listProviders();
            }}
          />
        ) : undefined}
        {this.state.isLoadingProviders ? undefined : (
          <React.Fragment>
            <div>
              {this.state.providers && this.state.providers.length === 0 && (
                <div className="mb-4">
                  You dont have any loader configured yet. start adding one by
                  clicking the + icon below.
                </div>
              )}
            </div>
            {this.state.displayLoaderWarning && (
              <div className="mt-2 mb-2">
                <Error
                  stringOnly
                  error={
                    "Some dataloaders are not available because the role has been deleted on AWS Console. Please make sure to delete them to be able to have an updated policy of your bucket."
                  }
                  path={"loaderunavailable"}
                />
              </div>
            )}
            <div>
              {this.state.providers && this.state.providers.length > 0 ? (
                <div className={"datasource-providers-list"}>
                  <ul className="list-group">
                    {this.state.providers.map((provider) =>
                      provider.isEditMode ? (
                        <DatasourceProviderEditItem
                          provider={provider}
                          datasource={this.props.datasource}
                          onEdit={(providers) => this.setState({ providers })}
                          onClose={() =>
                            this.setState((previousState) => {
                              const providers = previousState.providers.map(
                                (p) => {
                                  if (
                                    p.arn === provider.arn &&
                                    p.userid === provider.userid
                                  ) {
                                    return Object.assign({}, p, {
                                      isEditMode: false
                                    });
                                  }
                                  return Object.assign({}, p);
                                }
                              );
                              return { openForm: false, providers };
                            })
                          }
                          api={this.props.api}
                        />
                      ) : (
                        <DatasourceProviderItem
                          provider={provider}
                          onEdit={() =>
                            this.setState((previousState) => {
                              const providers = previousState.providers.map(
                                (p) => {
                                  if (
                                    p.arn === provider.arn &&
                                    p.userid === provider.userid
                                  ) {
                                    return Object.assign({}, p, {
                                      isEditMode: true
                                    });
                                  }
                                  return Object.assign({}, p, {
                                    isEditMode: false
                                  });
                                }
                              );
                              return { openForm: true, providers };
                            })
                          }
                          onDelete={() =>
                            this.openDatasourceProviderModal(provider)
                          }
                        />
                      )
                    )}
                  </ul>
                </div>
              ) : undefined}
            </div>

            {this.state.datasourceProviderModal ? (
              <DatasourceProviderModal
                datasource={this.props.datasource}
                datasourceProvider={
                  this.state.datasourceProviderModal.datasouceProvider
                }
                hide={this.closeDatasourceProviderModal}
                showGlobalNotification={this.props.showGlobalNotification}
                onAction={() => {
                  this.setState({ datasourceProviderModal: undefined });
                  this.listProviders();
                }}
              />
            ) : undefined}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceProviders)));
