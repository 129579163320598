/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../../../links";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import routes from "../../../routes";
import NetworkModal from "./networkModal";
import { formatDate } from "../../../../utils/date";
import withGoTo from "../../../goToHOC";

type propTypes = {
  network: Object,
  uriAccount: string,
  uriPlayground: string,
  goTo: Function,
  reload: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  networkModal: boolean
};

class NetworkCard extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      networkModal: false
    };
  }

  openNetworkModal = () => this.setState({ networkModal: true });

  closeNetworkModal = () => this.setState({ networkModal: false });

  hideAndReload = () => {
    this.closeNetworkModal();
    this.props.reload();
  };

  render() {
    return (
      <React.Fragment>
        <div className="cdh-card card-shadow card bg-white">
          <div className="cdh-card-header">
            <div className="cdh-card-begin">
              <div className="cdh-card-begin-content">
                <i className="fas fa-network-wired fa-spacing" />
                Network
              </div>
            </div>
          </div>
          <div className="cdh-card-body">
            <div className="cdh-card-body-left">
              <Links.Network.View
                uriAccount={this.props.uriAccount}
                uriPlayground={this.props.uriPlayground}
                networkId={this.props.network.id}
              >
                <span className="text-capitalize cdh-card-name">
                  {this.props.network.name}
                </span>
              </Links.Network.View>
              <div className="network cdh-cart-description">
                <span className="">
                  {this.props.network.description ||
                  (this.props.network.description &&
                    this.props.network.description > 125)
                    ? `${this.props.network.description.substring(0, 125)}`
                    : "No description"}
                </span>
              </div>
              <div className="cdh-card-details">
                <div className="cdh-card-detail">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    <b>VPC_ID:</b> {this.props.network.vpc_id}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="fas fa-globe-europe" />
                  <span className="content-card-bucket">
                    &nbsp;<b>REGION: </b>
                    {this.props.network.region}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    <b>SUBNETS:</b> {this.props.network.subnet_ids.join(", ")}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="fab fa-aws" />
                  <span className="content-card-bucket">
                    <b>SECURITY GROUPS:</b>{" "}
                    {this.props.network.security_group_ids.join(", ")}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="fas fa-network-wired" />
                  <span className="content-card-bucket">
                    <b>PROXY:</b> {this.props.network.proxy}
                  </span>
                </div>

                <div className="cdh-card-detail">
                  <i className="fas fa-network-wired" />
                  <span className="content-card-bucket">
                    <b>PROXY EXCLUSIONS:</b> {this.props.network.noproxy}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="fas fa-network-wired" />
                  <span className="content-card-bucket">
                    <b>TYPE:</b> {this.props.network.network_type}
                  </span>
                </div>
                <div className="cdh-card-detail">
                  <i className="far fa-calendar-alt" />
                  <span className="content-card-bucket">
                    <b>&nbsp;created:</b>{" "}
                    {formatDate(this.props.network.createdat)}
                  </span>
                </div>
              </div>
            </div>
            <div className="cdh-card-body-right">
              <div className="cdh-card-body-actions">
                <div
                  className="btn-cart"
                  onClick={() =>
                    this.props.goTo({
                      route: routes.Network.Edit,
                      params: {
                        uriAccount: this.props.uriAccount,
                        uriPlayground: this.props.uriPlayground,
                        networkId: this.props.network.id
                      },
                      state: { network: this.props.network }
                    })
                  }
                >
                  <i className="fas fa-edit fa-spacing" />
                  <span className="text-actions">Edit</span>
                </div>
                <div className="btn-cart" onClick={this.openNetworkModal}>
                  <i className="fas fa-trash fa-spacing" />
                  <span className="text-actions">Delete</span>
                </div>
              </div>
            </div>
          </div>
          <div className="cdh-card-footer">
            <div className="cdh-card-begin">
              <i className="far fa-calendar-alt fa-spacing" />
              <span className="text-footer-left">
                last update: {formatDate(this.props.network.updatedat)}
              </span>
            </div>

            <div className="cdh-card-end"></div>
          </div>
        </div>
        <div>
          {this.state.networkModal && (
            <NetworkModal
              network={this.props.network}
              hide={this.closeNetworkModal}
              showGlobalNotification={this.props.showGlobalNotification}
              goBack={() => this.hideAndReload()}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(NetworkCard)));
