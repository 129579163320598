/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import classnames from "classnames";
import "./style.less";

type propTypes = {
  isPreload: boolean,
  tabs: Array<Object>,
  setTab: Function,
  defaultActiveIndex: string,
  location: {
    hash: string,
    pathname: string
  }
};

type stateTypes = {
  defaultActiveIndex: string,
  active: string
};

class Tabs extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    isPreload: true
  };

  constructor(props) {
    super(props);
    this.state = {
      defaultActiveIndex: props.defaultActiveIndex,
      active:
        this.getHash(props) ||
        (props.tabs && props.tabs[0] && props.tabs[0].index) ||
        0
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.defaultActiveIndex !== state.defaultActiveIndex) {
      return {
        defaultActiveIndex: props.defaultActiveIndex,
        active: props.defaultActiveIndex
      };
    }
    return {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tabs.length !== this.props.tabs.length) {
      this.initActiveTab();
    } else {
      const active =
        this.getHash(this.props) ||
        (this.props.tabs && this.props.tabs[0] && this.props.tabs[0].index) ||
        0;
      if (active !== prevState.active) {
        this.initActiveTab();
      }
    }
  }

  initActiveTab = () => {
    this.setState({
      active:
        this.getHash(this.props) ||
        (this.props.tabs && this.props.tabs[0] && this.props.tabs[0].index) ||
        0
    });
  };

  onClick = (index) => {
    const { tabs, setTab } = this.props;

    if (!tabs.filter((t) => t.index === index)[0].isDisabled) {
      this.setState({ active: index });
      if (setTab) {
        setTab(index);
      }
    }
  };

  getHash = (props) => {
    const getTabIndex = (hash) => {
      const tabsFind = props.tabs.filter((tab) => hash.includes(tab.index));
      if (tabsFind.length > 0) {
        const tabFind = tabsFind[0];
        return tabFind.index;
      }
      return false;
    };

    const getTabIndexFromHash = () => getTabIndex(props.location.hash.slice(1));

    const getTabIndexFromPathname = () => {
      if (props.location.pathname) {
        const spl = props.location.pathname.split("#");
        if (spl.length >= 2) return getTabIndex(spl[spl.length - 1]);
      }
      return false;
    };

    const tabIndex = props.location.hash
      ? getTabIndexFromHash()
      : getTabIndexFromPathname();

    if (tabIndex) return tabIndex;
    return props.defaultActiveIndex;
  };

  isActiveTab = (tab) => this.state.active.indexOf(tab.index) !== -1;

  render() {
    const { tabs } = this.props;

    return (
      <div className="tabs">
        <ul className="nav nav-tabs">
          {tabs.map((tab) => {
            const active = this.isActiveTab(tab) ? " active " : "";
            return (
              <li key={`tab-${tab.index}`} className={"nav-item tab-link"}>
                {tab.route ? (
                  <Link
                    onClick={() => this.onClick(tab.index)}
                    to={tab.route}
                    className={classnames(`text-tab nav-link ${active}`, {
                      disabled: tab.isDisabled
                    })}
                    replace
                  >
                    {active && tab.activeName ? tab.activeName : tab.name}
                  </Link>
                ) : (
                  <a
                    onClick={() => this.onClick(tab.index)}
                    className={classnames(`text-tab nav-link ${active}`, {
                      disabled: tab.isDisabled
                    })}
                    data-toggle="tab"
                  >
                    {active && tab.activeName ? tab.activeName : tab.name}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
        <div className="content-tabs-container">
          {tabs.map((tab) => (
            <div
              key={`tab-content-${tab.index}`}
              className={classnames("tab-content", {
                "is-active": this.isActiveTab(tab)
              })}
            >
              {(this.props.isPreload || this.isActiveTab(tab)) && tab.content}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

// $FlowIgnore
export default withRouter(Tabs);
