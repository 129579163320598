/* eslint-disable no-confusing-arrow */
/* @flow */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import GraphQl from "../../graphQL";
import withAppSync from "../AppsyncHOC";
import { updateMessage } from "../globalNotifications/actions";
import Error from "../../components/error";
import Breadcrumb from "../../components/breadcrumb";
import Row from "../../components/row";
import Col from "../../components/col";
import Loading from "../../components/loading";
import Routes from "../routes";
import { haveSpecialCharacters } from "../../utils/string";
import FormFieldAllColumns from "./create-form/FormFieldAllColumns";
import FormFieldColumns from "./create-form/FormFieldColumns";
import FormFieldColumnAccessLevel from "./create-form/FormFieldColumnAccessLevel";
import FormFieldDataset from "./create-form/FormFieldDataset";
import FormFieldDescription from "./create-form/FormFieldDescription";
import FormFieldName from "./create-form/FormFieldName";
import RowFilterExpression from "./create-form/RowFilterExpression";
import "./create-data-filter.css";

type Props = {
  api: GraphQl,
  goTo: Function,
  location: {
    state: {
      dataset: { name: string, uri: string }
    }
  },
  match: { params: { stageId: string } },
  showGlobalNotification: Function
};

const DESCRIPTION_CHARACTERS_MIN = 30;

function DataFilterCreate({
  api,
  goTo,
  location,
  match,
  showGlobalNotification
}: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(undefined);
  const [errorSubmit, setErrorSubmit] = useState(undefined);
  const stageIdFromMatchParam = useMemo(
    () =>
      match.params && match.params.stageId ? match.params.stageId : undefined,
    [match]
  );
  const [stage, setStage] = useState(undefined);

  const datasetUriFromLocation = useMemo(
    () =>
      location.state && location.state.dataset && location.state.dataset.uri
        ? location.state.dataset.uri
        : undefined,
    [location]
  );

  const datasetNameFromLocation = useMemo(
    () =>
      location.state && location.state.dataset && location.state.dataset.name
        ? location.state.dataset.name
        : undefined,
    [location]
  );

  const columns = useMemo(
    () =>
      stage && stage.columns
        ? stage.columns.map((col) => ({ label: col.Name, value: col.Name }))
        : [],
    [stage]
  );
  const columnTypes = useMemo(
    () => (stage && stage.columns ? stage.columns : []),
    [stage]
  );
  const [rowExpressionMode, setRowExpressionMode] = useState("easy");

  const initialFormValues = useMemo(
    () => ({
      name: "",
      description: "",
      column_level_access: "",
      all_columns: true,
      columns: [],
      row_filter_expression: "",
      row_filter_expression_simple_mode: "",
      dataset_uri: datasetUriFromLocation,
      stage_id: stageIdFromMatchParam
    }),
    [datasetUriFromLocation, stageIdFromMatchParam]
  );

  async function getDatasetOutputs({ stageId, datasetUri }) {
    setIsFetching(true);
    return api.dataset
      .getStage(stageId, datasetUri)
      .then((stageData) => {
        setStage(stageData);
        setIsFetching(false);
        return stageData;
      })
      .catch((apiError) => {
        setError(apiError);
        setIsFetching(false);
      });
  }

  useEffect(() => {
    if (stageIdFromMatchParam && datasetUriFromLocation) {
      getDatasetOutputs({
        stageId: stageIdFromMatchParam,
        datasetUri: datasetUriFromLocation
      });
    }
  }, [stageIdFromMatchParam, datasetUriFromLocation]);

  const validation = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is mandatory";
    }
    if (haveSpecialCharacters(values.name)) {
      errors.name =
        "Name must not contain these special characters ( & / ' _ ; )";
    }
    if (!values.description) {
      errors.description =
        "Description is mandatory to understand the filter's purpose";
    }
    if (
      values.description &&
      values.description.length < DESCRIPTION_CHARACTERS_MIN
    ) {
      errors.description = `Description is too short (${DESCRIPTION_CHARACTERS_MIN} characters min)`;
    }
    if (!values.column_level_access) {
      errors.column_level_access = "Column Level Access is mandatory";
    }
    if (!values.row_filter_expression) {
      errors.row_filter_expression = "Row Filter Expression is mandatory";
    }
    return errors;
  };

  const onSubmit = (values) => {
    const {
      all_columns,
      columns: columnsFromValues,
      column_level_access,
      dataset_uri,
      description,
      name,
      row_filter_expression,
      row_filter_expression_simple_mode,
      stage_id
    } = values;
    const input = {
      dataset_uri,
      stage_id,
      name,
      description,
      column_level_access,
      all_columns,
      columns: columnsFromValues,
      row_filter_expression,
      row_filter_expression_simple_mode
    };

    setErrorSubmit(undefined);
    setIsSubmitting(true);

    return api.dataset
      .createDataFilter(dataset_uri, input)
      .then(() => {
        setIsSubmitting(false);
        showGlobalNotification({
          message: "DataFilter created",
          type: "success"
        });
        return goTo({
          route: `${Routes.Stage.View}#datafilters`,
          params: {
            datasetUri: dataset_uri,
            outputId: stage_id
          }
        });
      })
      .catch((apiError) => {
        setIsSubmitting(false);
        setErrorSubmit(apiError);
        showGlobalNotification({
          message: "DataFilter creation failed",
          type: "alert"
        });
      });
  };

  return (
    <div className="datafilter-create">
      {isFetching && <Loading message="Create Data Filter" />}
      {error && <Error error={error} path={"DataFilterCreate"} stringOnly />}
      {!isFetching && !error && (
        <div>
          <Breadcrumb view={"Create a new DataFilter"} />
          <Row>
            <Col size={1} />
            <Col className={"my-4"} size={9}>
              {datasetUriFromLocation && stageIdFromMatchParam ? (
                <Formik
                  initialValues={initialFormValues}
                  validate={validation}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ setFieldValue, errors, values }) => (
                    <Form>
                      {errorSubmit && (
                        <Error
                          stringOnly
                          error={errorSubmit}
                          path={"Create Data Filter"}
                        />
                      )}
                      {Object.keys(errors).length > 0 && (
                        <div className="error-msg">
                          {"You have some errors. Please, verify all fields."}
                        </div>
                      )}
                      <fieldset className="form-group">
                        <legend className="label-form">Table:</legend>
                        {stage ? stage.table : ""}
                      </fieldset>

                      <FormFieldName
                        errors={errors}
                        setFieldValue={setFieldValue}
                        name={values.name}
                      />
                      <FormFieldDescription
                        description={values.description}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        characterslimit={DESCRIPTION_CHARACTERS_MIN}
                      />
                      {datasetNameFromLocation && datasetUriFromLocation ? (
                        <FormFieldDataset
                          datasetName={datasetNameFromLocation}
                          datasetUri={datasetUriFromLocation}
                        />
                      ) : undefined}
                      <FormFieldColumnAccessLevel
                        columnLevelAccess={values.column_level_access}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                      <FormFieldAllColumns
                        allColumns={values.all_columns}
                        setFieldValue={setFieldValue}
                      />
                      {!values.all_columns && (
                        <FormFieldColumns
                          columns={columns}
                          selectedColumns={values.columns}
                          setFieldValue={setFieldValue}
                        />
                      )}

                      <RowFilterExpression
                        columns={columns}
                        columnTypes={columnTypes}
                        errors={errors}
                        rowExpressionMode={rowExpressionMode}
                        setFieldValue={setFieldValue}
                        setRowExpressionMode={setRowExpressionMode}
                        values={values}
                      />

                      <div className={"ml-1 row justify-content-center"}>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="butn"
                        >
                          {isSubmitting && (
                            <i className="fas fa-circle-notch fa-spin fa-spacing" />
                          )}
                          Create
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : undefined}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DataFilterCreate));
