/* @flow */

import React from "react";
import classnames from "classnames";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";

type propTypes = {
  namespace: Object,
  ready: boolean,
  error: Object
};

class RSNamespaceInfo extends React.Component<propTypes> {
  standarDisplay(field: string, value: string) {
    // eslint-disable-line class-methods-use-this
    const v = (value && value.split("\n")) || [];
    const p = v.length > 1 ? v.map((i, key) => <p key={key}>{i}</p>) : value;
    return (
      <div className="display" style={{ width: "100%" }}>
        <div
          className="field-name"
          style={{
            display: "inline-table",
            width: "200px",
            textAlign: "left"
          }}
        >
          {field || "-"}
        </div>
        <div
          className={classnames("content")}
          style={{
            marginLeft: "15px",
            display: "inline-table"
          }}
        >
          {p || "-"}
        </div>
      </div>
    );
  }

  render() {
    const { namespace } = this.props;
    return (
      <div className="cluster-create">
        {this.props.error && (
          <Error error={this.props.error} path="RSNamespaceInfo" />
        )}
        {!this.props.error && !this.props.ready && (
          <Loading message="RS Namespace Information" />
        )}
        {!this.props.error && this.props.ready && (
          <div className={"dataset-info"}>
            {this.standarDisplay("Namespace ARN", namespace.namespace_arn)}
            {this.standarDisplay("Namespace Name", namespace.namespace_name)}
            {this.standarDisplay(
              "Namespace Status",
              namespace.namespace_status
            )}
            {this.standarDisplay("Workgroup ARN", namespace.workgroup_arn)}
            {this.standarDisplay("Workgroup Name", namespace.workgroup_name)}
            {this.standarDisplay(
              "Workgroup Status",
              namespace.workgroup_status
            )}
            {this.standarDisplay("Workgroup Port", namespace.workgroup_port)}
            {this.standarDisplay("Environment", namespace.platform.name)}
            {this.standarDisplay("AWS Account", namespace.platform.aws)}
            {this.standarDisplay("VPC", namespace.network.vpc_id)}
            <div className="display">
              <div
                className="field-name"
                style={{
                  display: "inline-table",
                  width: "200px",
                  textAlign: "left"
                }}
              >
                Subnets
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  display: "inline-table",
                  width: "600px"
                }}
              >
                {namespace.network.subnet_ids.map((sg) => (
                  <div className="dataset-badge" key={sg}>
                    <div className="circle-tag"></div>
                    {sg}
                  </div>
                ))}
              </div>
            </div>
            <div className="display">
              <div
                className="field-name"
                style={{
                  display: "inline-table",
                  width: "200px",
                  textAlign: "left"
                }}
              >
                IAM Roles
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  display: "inline-table",
                  width: "600px"
                }}
              >
                {namespace.iam_roles.map((role) => (
                  <div className="dataset-badge" key={role}>
                    <div className="circle-tag"></div>
                    {role}
                  </div>
                ))}
              </div>
            </div>
            {this.standarDisplay("CFN Stack Name", namespace.stack_name)}
            {this.standarDisplay("CFN Stack Status", namespace.stack_status)}
          </div>
        )}
      </div>
    );
  }
}

export default RSNamespaceInfo;
