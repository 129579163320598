export const getCollibraLink = {
  query: `
  query Q ($accountUri: String!, $id: String!) {
    getCollibraLink(accountUri: $accountUri, $id: id) {
      url
      id
      domain_id
      domain_name
      last_index_status
      last_wipe_status
      last_indexedat
      last_wipedat
      envname
      createdat
    }
  }
`,
  keyOutput: "getCollibraLink"
};
