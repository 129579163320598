/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./view.less";
import GraphQl from "../../../graphQL";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import SelectClassic from "../../../components/SelectClassic";
import SelectInfinite from "../../../components/SelectInfinite";
import TermsConditionsModal from "../../../components/termsConditionsModal";
import Row from "../../../components/row";
import Col from "../../../components/col";
import ToggleButton from "../../../components/toggleButton";
import { updateMessage } from "../../globalNotifications/actions";
import { getErrorMessageWithoutCode } from "../../../utils/error";

type propTypes = {
  datasource: {
    uri: string
  },
  api: GraphQl,
  reload: Function,
  showGlobalNotification: Function,
  onClose: Function
};

type stateTypes = {
  error: Object,
  errorSubmit: Object,
  isFetching: boolean,
  ready: boolean,
  groupReady: boolean,
  accounts: Array<Object>,
  accountSelected?: Object,
  accountUri: string,
  groups: Array<Object>,
  groupSelected?: Object,
  groupUri: string,
  totalGroups: number,
  offsetGroups: number,
  complianceModal: boolean,
  areNewDatasetsShared: boolean
};

class DatasourceShareCreate extends React.Component<propTypes, stateTypes> {
  termsCheckboxRef;

  constructor(props: propTypes) {
    super(props);
    this.termsCheckboxRef = React.createRef();
    this.state = {
      error: undefined,
      errorSubmit: undefined,
      isFetching: false,
      ready: false,
      groupReady: false,
      accounts: [],
      accountSelected: null,
      accountUri: "",
      groups: [],
      groupSelected: null,
      groupUri: "",
      totalGroups: 0,
      offsetGroups: 0,
      complianceModal: false,
      areNewDatasetsShared: true
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () =>
    this.props.api.account
      .getAllAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  onSelectAccount = (accountSelected) => {
    this.setState(
      {
        accountSelected,
        groupSelected: null,
        accountUri: accountSelected.value,
        groupUri: undefined,
        errorSubmit: undefined,
        groupReady: false
      },
      this.getGroupsFromAccount
    );
  };

  getGroupsFromAccount = (offset, search) => {
    let queryOptions = {};
    if (search) {
      queryOptions = {
        offset: 0,
        limit: 1000,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset,
        limit: 10
      };
    }
    return this.props.api.account
      .listGroups(this.state.accountUri, queryOptions)
      .then((response) => {
        if (response) {
          const groups = response && response.results ? response.results : [];
          if (!search) {
            this.setState((prevState) => ({
              offsetGroups: prevState.offsetGroups + response.limit
            }));
          }
          this.setState({
            groups,
            totalGroups: response.total,
            groupReady: true
          });
          return groups.map((g) => ({ label: g.name, value: g.uri }));
        }

        return false;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  onSelectGroup = (groupSelected) => {
    this.setState({
      groupSelected,
      groupUri: groupSelected.value,
      errorSubmit: undefined
    });
  };

  loadMoreGroupsFromAccount = (offset, search) => {
    if (search && search.length < 3) {
      return false;
    }
    return this.getGroupsFromAccount(offset, search);
  };

  openComplianceModal = () => {
    this.setState({ complianceModal: true });
  };

  closeComplianceModal = () => {
    this.setState({ complianceModal: false });
  };

  onSubmit = () => {
    if (!this.state.groupUri) {
      return this.setState({ errorSubmit: "Pick a group before to share." });
    }

    if (
      this.termsCheckboxRef &&
      this.termsCheckboxRef.current &&
      !this.termsCheckboxRef.current.checked
    ) {
      return this.setState({
        errorSubmit: "You must accept the terms & conditions"
      });
    }

    this.setState({ errorSubmit: undefined, isFetching: true });

    return this.props.api.datasource
      .shareDatasource(
        this.props.datasource.uri,
        this.state.groupUri,
        this.state.areNewDatasetsShared
      )
      .then(() => {
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Share Datasource Success",
          type: "success"
        });
        this.props.reload();
      })
      .catch((error) => {
        this.setState({
          errorSubmit: getErrorMessageWithoutCode(error),
          isFetching: false
        });
        this.props.showGlobalNotification({
          message: "Share dataset failure",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div className={"share-dataset-by-owner"}>
        {this.state.error && (
          <Error error={this.state.error} path={"ShareDatasource"} stringOnly />
        )}
        {!this.state.ready && <Loading message={"Groups data"} />}
        {!this.state.error && this.state.ready && (
          <div>
            <div>
              <div className="mt-4">
                <label className="label-form">Select an organization : </label>
                <SelectClassic
                  placeholder="Select an organization"
                  options={this.state.accounts.map((a) => {
                    if (a.uri === "uri:account:000000")
                      return { value: a.uri, label: "All Engie" };
                    return { value: a.uri, label: a.name };
                  })}
                  selectedOption={this.state.accountSelected}
                  isSearchable
                  onSelectOption={this.onSelectAccount}
                />
              </div>
              <div className="mt-4">
                <label className="label-form">and Group : </label>

                <SelectInfinite
                  isLoadingOptions={
                    !this.state.groupReady && !!this.state.accountUri
                  }
                  placeholder={
                    this.state.accountUri
                      ? "Select a group"
                      : "Select an organisation first"
                  }
                  isDisabled={!this.state.accountUri}
                  initialOptions={(this.state.accountUri
                    ? this.state.groups
                    : []
                  ).map((g) => ({ value: g.uri, label: g.name }))}
                  isClearable
                  isSearchable
                  selectedOption={this.state.groupSelected}
                  onSelectOption={this.onSelectGroup}
                  totalOptions={this.state.totalGroups}
                  loadMoreOptions={this.loadMoreGroupsFromAccount}
                  optionResults
                />
              </div>
              <div className="mt-4">
                <label className="label-form">Share future datasets</label>
                <div className="basic-metadata-display">
                  <ToggleButton
                    id="basic-metadata-display-id"
                    checkedValue={this.state.areNewDatasetsShared}
                    onChange={() =>
                      this.setState((prevState) => ({
                        areNewDatasetsShared: !prevState.areNewDatasetsShared
                      }))
                    }
                  />
                </div>
              </div>
              <div className="mt-4 pl-3 form-group row">
                <input
                  id="termsconditions"
                  name="termsconditions"
                  type="checkbox"
                  className={"checkbox-facet"}
                  ref={this.termsCheckboxRef}
                />
                <label className="fas" htmlFor="termsconditions">
                  <span className="labelTermsCustom">
                    I acknowledge having read and accept the general{" "}
                    <span
                      className="terms-highlight"
                      onClick={this.openComplianceModal}
                    >
                      terms & conditions
                    </span>{" "}
                    of access and use of the service
                  </span>
                </label>
              </div>
            </div>

            <Row className="justify-content-center mt-4">
              {this.state.errorSubmit && (
                <Col size={12} style={{ color: "red", paddingBottom: "10px" }}>
                  {this.state.errorSubmit}
                </Col>
              )}
              <button
                type={"button"}
                onClick={this.onSubmit}
                className={"butn"}
              >
                {this.state.isFetching && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Share Datasource !
              </button>
              <button
                type={"button"}
                onClick={this.props.onClose}
                className={"butn butn-flat"}
              >
                Close
              </button>
            </Row>
          </div>
        )}
        {this.state.complianceModal && (
          <TermsConditionsModal onClose={this.closeComplianceModal} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasourceShareCreate)
);
