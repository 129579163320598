export const registerDatabricksAccount = {
  query: `
  mutation Q ($inputData: NewDBXAccountInput!) {
    registerDatabricksAccount(inputData: $inputData) {
        dbx_account_id
        dbx_account_name
        uuid
    }
  }
`,
  keyOutput: "registerDatabricksAccount"
};
