/* @flow */
import React from "react";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import AccelerationTemplateCard from "./AccelerationTemplateCard";
import "./style.less";

const Log = Logger("CartAccelerationTemplates");

type propTypes = {
  api: GraphQl,
  cart: Object
};

type stateTypes = {
  ready: boolean,
  error: Object,
  accelerationTemplates: Array<Object>
};

class CartAccelerationTemplates extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      accelerationTemplates: []
    };
  }

  componentDidMount() {
    this.loadAccelerationTemplates();
  }

  loadAccelerationTemplates = (offset: number = 0) =>
    this.props.api.cart
      .loadAccelerationTemplates(this.props.cart.uri, { offset })
      .then((accelerationTemplatesResponse) => {
        this.setState({
          accelerationTemplates: accelerationTemplatesResponse.results,
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error, ready: true });
      });

  render() {
    return (
      <div className="acceleration-templates-project">
        {!this.state.ready && <Loading message={"Acceleration Templates"} />}
        {this.state.error && (
          <Error
            error={this.state.error}
            path={"Acceleration Templates Project"}
            stringOnly
          />
        )}
        {this.state.ready &&
          this.state.accelerationTemplates.length <= 0 &&
          "0 template available for now."}
        <div className="acceleration-templates-project-list">
          {this.state.accelerationTemplates.map((accelerationTemplate) => (
            <AccelerationTemplateCard
              cart={this.props.cart}
              accelerationTemplate={accelerationTemplate}
              reloadPage={this.loadAccelerationTemplates}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CartAccelerationTemplates;
