import { TextField } from "@mui/material";
import React from "react";

const TextInput = ({
  description,
  label,
  name,
  placeholder,
  value,
  onChange
}) => (
  <div className="mb-3">
    <label className="label-form">{label}</label>
    <div>
      <small className="attribute-label">{description}</small>
    </div>
    <TextField
      placeholder={placeholder}
      fullWidth
      label=""
      name={name}
      onChange={onChange}
      value={value}
      variant="outlined"
    />
  </div>
);

export { TextInput };
