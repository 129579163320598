/* @flow */

export const mutationReadNotification = {
  query: `mutation Q ($notificationId: String!) {
    readNotification(notificationId:$notificationId) {
        id
        is_read
    }
  }
`,
  keyOutput: "readNotification"
};

type NotificationPropsTypes = {
  id: string,
  is_read: boolean
};
export type ReadNotificationPropsTypes = NotificationPropsTypes;
