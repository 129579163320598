export const transferOwnership = {
  query: `mutation Q ($datasourceUri: String!, $inputs: UpdateDatasourceOwnershipInput!) {
        updateDatasourceOwnership(datasourceUri: $datasourceUri, input: $inputs) {
            uri
            name
            owner { 
                uri 
                name 
            }
        }
      }
    `,
  keyOutput: "updateDatasourceOwnership"
};
