/* @flow */
import React from "react";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import GraphQl from "../../../graphQL";
import "./AddAnalytics.less";
import AddRedshiftCluster from "./AddRedshiftCluster";
import Routes from "../../routes";

const Log = Logger("AddAnalytics");

type propTypes = {
  match: {
    params: {
      cartUri: string,
      groupUri: string
    }
  },
  api: GraphQl,
  goTo: Function
};
type stateTypes = {
  cartLoaded: boolean,
  ready: boolean,
  cart: Object,
  clusters: Array<Object>,
  error: Object
};
class AddAnalytics extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      cartLoaded: false,
      ready: false,
      cart: {
        name: "-",
        uri: "-",
        redshiftClusters: []
      },
      clusters: [],
      error: null
    };
  }

  componentDidMount() {
    this.getCart().then(this.getRedshiftClusters);
  }

  getCart = () =>
    this.props.api.cart
      .getCart(this.props.match.params.cartUri)
      .then((cart) => {
        Log.info("cart:", cart);
        this.setState({
          cart,
          cartLoaded: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          cartLoaded: true
        });
      });

  getRedshiftClusters = () =>
    this.props.api.analytics
      .getProjectPlatformsRedshiftClusters(this.props.match.params.cartUri)
      .then((clusters) => {
        Log.info("clusters:", clusters);
        this.setState({
          clusters,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  handleAddCluster = () => {
    this.props.api.analytics
      .getProjectPlatformsRedshiftClusters(this.props.match.params.cartUri)
      .then((clusters) => {
        Log.info("clusters:", clusters);
        if (clusters.length > 0) {
          this.setState({
            clusters,
            ready: true
          });
        } else {
          this.props.goTo({
            route: `${Routes.Cart.View}#tools-analytics`,
            params: {
              cartUri: this.state.cart.uri
            }
          });
        }
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Breadcrumb
          view={`Add Redshift cluster to project : ${
            this.state.cart.name || ""
          }`}
        />
        <div className="AddAnalytics">
          {this.state.error && (
            <Error error={this.state.error} path="AddAnalytics" />
          )}
          {!this.state.error &&
            (!this.state.cartLoaded || !this.state.ready) && (
              <Loading message="Redshift Clusters List" />
            )}
          {!this.state.error && this.state.cartLoaded && this.state.ready && (
            <div className="card-container">
              {this.state.clusters.length <= 0 ? (
                <div className="no-items">No Redshift clusters to display.</div>
              ) : (
                this.state.clusters.map((cluster) => (
                  <AddRedshiftCluster
                    cluster={cluster}
                    handleAddCluster={this.handleAddCluster}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(withGoTo(AddAnalytics));
