/* @flow */

import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import withAppsync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import logoRedshift from "../../../logos/redshift-logo.png";
import { updateMessage } from "../../../globalNotifications/actions";
import withGoTo from "../../../goToHOC";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";
import { isRSSourceType, isS3SourceType } from "../../../../utils/constants";
import logoS3 from "../../../logos/s3-logo.png";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  dataset: Object,
  open: boolean,
  onClose: Function,
  reloadStages: Function
};

type stateTypes = {
  error: Object,
  errorSubmit: Object,
  savingStage: boolean,
  unPublishedStages: Array<Object>,
  isFetchingUnpublishedStages: boolean,
  formValues: Object
};

class PublishRSStage extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: false,
      errorSubmit: false,
      savingStage: false,
      unPublishedStages: [],
      isFetchingUnpublishedStages: false,
      formValues: {
        stages: []
      }
    };
  }

  componentDidMount() {
    this.getUnpublishedStages();
  }

  getUnpublishedStages = () => {
    this.setState({
      isFetchingUnpublishedStages: true
    });
    this.props.api.dataset
      .listUnpublishedDatasetStages(this.props.dataset.uri)
      .then((response) => {
        this.setState({
          unPublishedStages: response,
          isFetchingUnpublishedStages: false
        });
      })
      .catch(() => {
        this.setState({
          isFetchingUnpublishedStages: false
        });
      });
  };

  save(setSubmitting) {
    this.setState({ errorSubmit: false, savingStage: true });
    return this.props.api.dataset
      .publishDatasetStages(
        this.props.dataset.uri,
        this.state.formValues.stages
      )
      .then(() => {
        setSubmitting(false);
        this.setState({ savingStage: false });
        this.props.showGlobalNotification({
          message: "Stages published",
          type: "success"
        });
        this.props.onClose();
        this.props.reloadStages();
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, savingStage: false });
      });
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const formValues = { ...prevState.formValues };
      formValues[field] = value;
      return { formValues };
    });
  }

  render() {
    const { formValues } = this.state;
    return (
      <div>
        <Dialog
          maxWidth="md"
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container>
                <Grid item md={1} sm={1} xs={1}>
                  {isS3SourceType(this.props.dataset.source_type) && (
                    <div className="card-dataset-begin-content">
                      <img
                        className="connector-logo-img"
                        src={logoS3}
                        alt="S3"
                        width={35}
                        height={35}
                      />
                    </div>
                  )}
                  {isRSSourceType(this.props.dataset.source_type) && (
                    <div className="card-dataset-begin-content">
                      <img
                        className="connector-logo-img"
                        src={logoRedshift}
                        alt="Redshift Serverless"
                        width={35}
                        height={35}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    Publish Stages
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <div className="IAM-error">
                <Alert severity="error">{this.state.error.message}</Alert>
              </div>
            )}
            <Box sx={{ p: 3 }}>
              <Formik
                initialValues={{
                  stages: formValues.stages
                }}
                validate={() => {
                  const errors = {};
                  if (!formValues.stages || formValues.stages.length === 0) {
                    errors.stages = "Choose at least one table to publish";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.save(setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div>
                        {Object.values(errors).map((e) => (
                          <div className="error-msg mb-1">
                            <div className="IAM-error">
                              <Alert severity="error">{e}</Alert>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {this.state.errorSubmit && (
                      <div className="IAM-error">
                        <Alert severity="error">
                          {this.state.errorSubmit.message}
                        </Alert>
                      </div>
                    )}
                    <div className="form-container">
                      <div className="mb-3">
                        <label className="label-form">Unpublished Tables</label>
                        <Autocomplete
                          multiple
                          options={this.state.unPublishedStages}
                          noOptionsText="No tables available"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option.name;
                          }}
                          loading={this.state.isFetchingUnpublishedStages}
                          renderInput={(params) => (
                            <TextField
                              label=""
                              name="stages"
                              placeholder="Type or select a table"
                              variant="outlined"
                              fullWidth
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.isFetchingUnpublishedStages ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                          onChange={(event, newValue) => {
                            this.handleChange("stages", newValue);
                          }}
                        />
                      </div>
                      <CardContent>
                        <button
                          type={"submit"}
                          className="butn butn-create"
                          disabled={isSubmitting}
                          style={{ margin: "5px", width: "100%" }}
                        >
                          {this.state.savingStage ? (
                            <i className="fas fa-sync-alt fa-spin" />
                          ) : (
                            <i className={"fa fa-paper-plane"} />
                          )}
                          <span>{" Publish"}</span>
                        </button>
                      </CardContent>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(PublishRSStage)));
