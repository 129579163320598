/* eslint-disable flowtype/no-types-missing-file-annotation */

import React from "react";
import Loading from "../../../../components/loading";
import withErrorBoundary from "../../../errorBoundaryHOC";
import Error from "../../../../components/error";
import DatasetShare from "../../../share/components/managedShare/datasetShare";
import GraphQl from "../../../../graphQL";
import ShareAction from "./shareAction";
import ShareDatasetRequestRequester from "../../../share/components/shareRequest/datasetShare";
import Paginator from "../../../../components/paginator";

type propTypes = {
  dataset: {
    access: string,
    expiration_date?: string,
    uri: string,
    can_share: boolean
  },
  api: GraphQl,
  uriDataset: string,
  goTo: Function
};

type stateTypes = {
  shares: Array<Object>,
  sharesTotal: number,
  isLoading: boolean,
  sharesError: boolean
};

class DatasetShares extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isLoading: true,
      shares: [],
      sharesTotal: 0,
      sharesError: false
    };
  }

  componentDidMount() {
    this.getShares();
  }

  getShares = (offset = 0) =>
    this.props.api.dataset
      .getShares(this.props.uriDataset, {
        offset,
        limit: 5,
        order_by: { value: "desc", key: "createdat" }
      })
      .then((sharesList) => {
        this.setState({
          sharesTotal: sharesList.total,
          shares: sharesList.results,
          isLoading: false
        });
        return sharesList.results;
      })
      .catch((sharesError) => {
        this.setState({
          sharesError
        });
        return [];
      });

  render() {
    if (this.state.sharesError) {
      return (
        <Error
          title={"An error occured with Shares, try later."}
          error={this.state.sharesError}
          path={"DatasetShares"}
        />
      );
    }

    if (this.state.isLoading) {
      return (
        <div>
          <Loading message={"Shares"} />
        </div>
      );
    }

    if (this.props.dataset.access === "owner" || this.props.dataset.can_share) {
      return (
        <div className="dataset-share">
          <ShareAction
            dataset={this.props.dataset}
            goTo={this.props.goTo}
            reload={() => {
              this.setState({ shares: [] });
              this.getShares();
            }}
          />

          <Paginator
            container={(content) => <ul className="list-group">{content}</ul>}
            limit={5}
            initialOffset={0}
            totalCount={this.state.sharesTotal}
            list={this.state.shares}
            componentRender={(share) => (
              <DatasetShare
                key={`share.${this.props.dataset.uri}.${share.group.uri}`}
                share={{ ...share, dataset: this.props.dataset }}
                dataset={this.props.dataset}
                onAction={this.getShares}
              />
            )}
            loadPage={this.getShares}
            onNoResult={() => (
              <div className="ml-2 mt-3">No share to display.</div>
            )}
          />
        </div>
      );
    }

    return (
      <Paginator
        container={(content) => (
          <div className="dataset-share">
            <ul className="list-group">{content}</ul>
          </div>
        )}
        limit={10}
        initialOffset={0}
        totalCount={this.state.sharesTotal}
        list={this.state.shares}
        componentRender={(share) => (
          <ShareDatasetRequestRequester
            key={`share.${this.props.dataset.uri}.${share.group.uri}`}
            share={share}
            dataset={this.props.dataset}
            onAction={this.getShares}
          />
        )}
        loadPage={this.getShares}
        onNoResult={() => <div className="ml-2 mt-3">No share to display.</div>}
      />
    );
  }
}

export default withErrorBoundary(DatasetShares);
