/* @flow */

import React, { Component } from "react";
import withAppSync from "../../AppsyncHOC";
import { formatDate } from "../../../utils/date";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import Loading from "../../../components/loading";

type propTypes = {
  stage: {
    id: string,
    dataset: Object
  },
  api: GraphQl
};

type stateTypes = {
  profilingExecutions: Array<Object>,
  ready: boolean,
  error: Object
};

class ProfilingExecutionHistory extends Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      profilingExecutions: [],
      ready: false,
      error: null
    };
  }

  componentDidMount() {
    this.getProfilingExecution();
  }

  getProfilingExecution = () =>
    this.props.api.dataset
      .getStageProfilingExecution(
        this.props.stage.id,
        this.props.stage.dataset.uri
      )
      .then((profilingExecutions) => {
        this.setState({
          ready: true,
          profilingExecutions
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });

  static getStatusClassName(runStatus: string) {
    let statusClassName = "";
    switch (runStatus) {
      case "PENDING":
        statusClassName = "badge-log badge-log-primary";
        break;
      case "FAILED":
        statusClassName = "badge-log badge-log-danger";
        break;
      case "SUCCEEDED":
        statusClassName = "badge-log badge-log-success";
        break;
      default:
        statusClassName = "badge-log badge-log-primary";
    }
    return statusClassName;
  }

  render() {
    if (this.state.error)
      return (
        <Error
          error={this.state.error}
          path={"StageView-ProfilingExecutionHistory"}
        />
      );
    if (!this.state.ready) return <Loading message={"Profiling History"} />;
    return (
      <div className="playgrounds-list">
        <div className="content">
          <ul className="list-group">
            {this.state.profilingExecutions &&
            this.state.profilingExecutions.length <= 0 ? (
              <li className="no-items">No executions to display.</li>
            ) : (
              this.state.profilingExecutions.map((run) => (
                <div className={"mt-1 border log-container"} key={run.run_id}>
                  <div>
                    <div
                      className={ProfilingExecutionHistory.getStatusClassName(
                        run.status
                      )}
                    >
                      {run.status}
                    </div>
                  </div>
                  <div className="log-details">
                    <p>
                      <i className="pr-3 fas fa-play" />
                      <span className="label-display">EXECUTION ID : </span>
                      <strong>{run.run_id}</strong>
                    </p>
                    <p>
                      <i className="pr-3 fas fa-cog" />
                      <span className="label-display">GLUE JOB NAME : </span>
                      <strong>{run.job_name}</strong>
                    </p>
                    <p>
                      <i className="pr-3 fas fa-box" />
                      <span className="label-display">ENVIRONMENT : </span>
                      <strong>{run.platform_uri}</strong>
                    </p>
                    <p>
                      <i className="pr-2 fab fa-aws" />
                      <span className="label-display">AWS ACCOUNT ID : </span>
                      <strong>{run.aws}</strong>
                    </p>
                  </div>
                  <div className="date-log-container">
                    <p className="date-log">{formatDate(run.createdat)}</p>
                  </div>
                </div>
              ))
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default withAppSync(ProfilingExecutionHistory);
