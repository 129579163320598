import React from "react";
import routes from "../../containers/routes";
import GroupName from "../../containers/users/components/groupName";
import withGoTo from "../../containers/goToHOC";

const GroupCard = ({ group, goTo, rightAction }) => (
  <div
    key={group.uri}
    className="card group-card card-shadow noPointer"
    style={{ width: "100%" }}
  >
    <div
      className="card-group-left"
      onClick={() =>
        goTo({
          route: routes.Group.View,
          params: {
            uriGroup: group.uri
          }
        })
      }
    >
      <GroupName group={group} />
      <div>{group.description}</div>
    </div>
    <div className="card-group-right">{rightAction}</div>
  </div>
);

export default withGoTo(GroupCard);
