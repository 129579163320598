/* @flow */

import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { formatDate } from "../../../../utils/date";
import HelpOn from "../../../../components/helpOn";
import "./style.less";

type stateTypes = {
  toggleDetails: boolean,
  gridItemSize: string
};

type propTypes = {
  callbackUpdateButton: Function,
  cartPlatformRole: Object
};

class CartPlatformRole extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      toggleDetails: false,
      gridItemSize: "4"
    };
  }

  componentDidMount() {
    this.automaticRefreshStatus();
    this.setGridItemSize();
  }

  setGridItemSize = () => {
    if (this.props.cartPlatformRole.rs_status) {
      this.setState({ gridItemSize: "4" });
    } else {
      this.setState({ gridItemSize: "6" });
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps: any) {
    this.automaticRefreshStatus();
  }

  automaticRefreshStatus = () => {
    const runningValues = ["UNKNOWN", "RUNNING", "QUEUED", "SENT"];
    if (runningValues.includes(this.props.cartPlatformRole.global_status)) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.props.callbackUpdateButton, 10000);
    }
  };

  getStatusClass = (status: string) => {
    switch (status) {
      case "SUCCEEDED":
        return "status-green";
      case "FAILED":
        return "status-red";
      default:
        return "status-blue";
    }
  };

  getRefreshRoleStatusIcon = () => {
    switch (this.props.cartPlatformRole.global_status) {
      case "QUEUED":
        return "fa-spacing fas fa-circle-notch fa-spin";
      case "RUNNING":
        return "fa-spacing fas fa-circle-notch fa-spin";
      case "SENT":
        return "fa-spacing fas fa-circle-notch fa-spin";
      case "SUCCEEDED":
        return "fas fa-check";
      case "FAILED":
        return "fas fa-times";
      default:
        return "";
    }
  };

  getRefreshRoleStatusIconColor = () => {
    switch (this.props.cartPlatformRole.global_status) {
      case "QUEUED":
        return "status-icon-blue";
      case "RUNNING":
        return "status-icon-blue";
      case "SENT":
        return "status-icon-blue";
      case "SUCCEEDED":
        return "status-icon-green";
      case "FAILED":
        return "status-icon-red";
      default:
        return "status-icon-transparent";
    }
  };

  printRefreshRoleStatus = (status: string) => {
    const runningValues = ["RUNNING", "QUEUED", "SENT"];
    if (status) {
      if (runningValues.includes(status)) {
        return "RUNNING";
      }
      return status;
    }
    return "UNKNOWN";
  };

  onClickMoreDetailsToggle = () => {
    this.setState((prevState) => ({ toggleDetails: !prevState.toggleDetails }));
  };

  render() {
    return (
      <div className="refresh-role-card">
        <div className="title-part">
          <div className="refresh-role-part">
            <span className="refresh-role-title">REFRESH ROLE STATUS</span>
            <span className="refresh-role-status">
              <span
                className={this.getStatusClass(
                  this.props.cartPlatformRole.global_status
                )}
              >
                {this.printRefreshRoleStatus(
                  this.props.cartPlatformRole.global_status
                )}
              </span>
            </span>
            <span className="refresh-role-status-icon">
              <span className={this.getRefreshRoleStatusIconColor()}>
                <i className={this.getRefreshRoleStatusIcon()} />
              </span>
            </span>
          </div>
          <div className="last-refresh-part">
            <span className="last-role-refresh">LAST ROLE REFRESH</span>
            <span className="last-role-refresh-date">
              <span className="tag-metrics">
                {this.props.cartPlatformRole.last_refresh_role_date
                  ? formatDate(
                      this.props.cartPlatformRole.last_refresh_role_date
                    )
                  : "NEVER"}
              </span>
            </span>
            <button
              type="submit"
              onClick={this.props.callbackUpdateButton}
              className="butn update-refresh-button"
            >
              <i className="fas fa-sync-alt" />
            </button>
          </div>
        </div>
        {/* </div> */}
        <div className="details-part">
          <div
            className="more-details-toggle"
            onClick={this.onClickMoreDetailsToggle}
          >
            See Refresh Role details&nbsp;
            <i
              className={`facet-chevron fas ${
                this.state.toggleDetails ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>

          {this.state.toggleDetails && (
            <div className="refresh-role-details">
              <Grid
                container
                spacing={6}
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                <Grid item xs={this.state.gridItemSize}>
                  <div className="IAM-details mr-1">
                    <div className="IAM-main-data">
                      <div className="IAM-title">
                        <span className="IAM-title-text">IAM Permissions</span>
                        <HelpOn
                          content={
                            <div>
                              <h3>IAM Permissions</h3>
                              <div>
                                This operation creates the IAM Role of your
                                project and calculate its IAM Permissions.
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <div className="IAM-status">
                        <span
                          className={this.getStatusClass(
                            this.props.cartPlatformRole.iam_status
                          )}
                        >
                          {this.printRefreshRoleStatus(
                            this.props.cartPlatformRole.iam_status
                          )}
                        </span>
                        <span className="tag-metrics">
                          {this.props.cartPlatformRole.iam_updatedat
                            ? formatDate(
                                this.props.cartPlatformRole.iam_updatedat
                              )
                            : "NEVER"}
                        </span>
                      </div>
                      {this.props.cartPlatformRole.iam_error && (
                        <div className="IAM-error">
                          <Alert severity="error">
                            {this.props.cartPlatformRole.iam_error}
                          </Alert>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.gridItemSize}>
                  <div className="Glue-details mr-1">
                    <div className="Glue-main-data">
                      <div className="Glue-title">
                        <span className="Glue-title-text">
                          Sync Glue Tables
                        </span>
                        <HelpOn
                          content={
                            <div>
                              <h3>Sync Glue Tables</h3>
                              <div>
                                This operation copies the datasets tables and
                                partitions to your project database in Glue.
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <div className="Glue-status">
                        <span
                          className={this.getStatusClass(
                            this.props.cartPlatformRole.glue_status
                          )}
                        >
                          {this.printRefreshRoleStatus(
                            this.props.cartPlatformRole.glue_status
                          )}
                        </span>
                        <span className="tag-metrics">
                          {this.props.cartPlatformRole.glue_updatedat
                            ? formatDate(
                                this.props.cartPlatformRole.glue_updatedat
                              )
                            : "NEVER"}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                {this.props.cartPlatformRole.rs_status && (
                  <Grid item xs={this.state.gridItemSize}>
                    <div className="Glue-details mr-1">
                      <div className="Glue-main-data">
                        <div className="Glue-title">
                          <span className="Glue-title-text">
                            Sync Redshift Serverless Tables
                          </span>
                          <HelpOn
                            content={
                              <div>
                                <h3>Sync Redshift Tables</h3>
                                <div>
                                  All Redshift Serverless dataset tables are
                                  shared with the Redshift Serverless datasource
                                  added to your project. (Redshift Serverless
                                  Tab)
                                </div>
                              </div>
                            }
                          />
                        </div>
                        <div className="Glue-status">
                          <span
                            className={this.getStatusClass(
                              this.props.cartPlatformRole.rs_status
                            )}
                          >
                            {this.printRefreshRoleStatus(
                              this.props.cartPlatformRole.rs_status
                            )}
                          </span>
                          <span className="tag-metrics">
                            {this.props.cartPlatformRole.rs_updatedat
                              ? formatDate(
                                  this.props.cartPlatformRole.rs_updatedat
                                )
                              : "NEVER"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
              <div className={"refresh-role-details"}>
                {this.props.cartPlatformRole.iam_error && (
                  <div className={"mt-2"}>
                    <Alert severity="error">
                      <AlertTitle>IAM Permissions Error</AlertTitle>
                      {this.props.cartPlatformRole.iam_error}
                    </Alert>
                  </div>
                )}
                {this.props.cartPlatformRole.glue_error && (
                  <div className={"mt-2"}>
                    <Alert severity="error">
                      <AlertTitle>Sync Glue Tables Error</AlertTitle>
                      {this.props.cartPlatformRole.glue_error}
                    </Alert>
                  </div>
                )}
                {this.props.cartPlatformRole.rs_error && (
                  <div className={"mt-2"}>
                    <Alert severity="error">
                      <AlertTitle>
                        Sync Redshift Serverless Tables Error
                      </AlertTitle>
                      {this.props.cartPlatformRole.rs_error}
                    </Alert>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CartPlatformRole;
