/* @flow */
import React, { Fragment, useMemo, useState } from "react";
import { Field } from "formik";

import HelpOn from "../../../components/helpOn";
import DataFilterExpressionQueryBuilder from "../expression-query-builder/DataFilterExpressionQueryBuilder";

type Props = {
  columns: Array<Object>,
  columnTypes: Array<Object>,
  errors: {
    row_filter_expression: string
  },
  rowExpressionMode: string,
  setFieldValue: Function,
  setRowExpressionMode: Function,
  values: {
    all_columns: boolean,
    columns: Array<Object>,
    column_level_access: string,
    row_filter_expression: string
  }
};

function RowFilterExpression({
  columnTypes,
  columns,
  errors,
  rowExpressionMode,
  setFieldValue,
  setRowExpressionMode,
  values
}: Props) {
  const [rules, setRules] = useState([]);
  const availableColumns = useMemo(() => {
    const filteredColumns = columns.filter((column) => {
      if (values.column_level_access.toLowerCase() === "exclude") {
        if (values.all_columns === true) {
          return false;
        }
        return !values.columns.includes(column.value);
      }
      if (values.column_level_access.toLowerCase() === "include") {
        if (values.all_columns === true) {
          return true;
        }
        return values.columns.includes(column.value);
      }
      return true;
    });
    return filteredColumns;
  }, [columns, values]);

  return (
    <fieldset className="form-group">
      <legend className="label-form">Row Filter Expression:</legend>
      <div className="row-filter-expression-container">
        <div className="row-filter-expression-tabs">
          <div
            className={
              rowExpressionMode === "easy" ? "tab tab--selected" : "tab"
            }
            onClick={() => setRowExpressionMode("easy")}
          >
            Simple mode
          </div>
          <div
            className={
              rowExpressionMode === "advanced" ? "tab tab--selected" : "tab"
            }
            onClick={() => setRowExpressionMode("advanced")}
          >
            Advanced mode
          </div>
        </div>

        {rowExpressionMode === "advanced" ? (
          <Fragment>
            <div
              style={{
                color: "grey",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                gap: "20px"
              }}
            >
              <HelpOn
                content={
                  <div>
                    <h3>Information</h3>
                    <div>
                      See AWS documentation{" "}
                      <a
                        href="https://docs.aws.amazon.com/lake-formation/latest/dg/data-filters-about.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                    </div>
                  </div>
                }
              />
              <div>
                Enter the rest of the following query statement "SELECT * FROM
                orders WHERE..."
                <br />
                Please see the documentation for examples of filter expressions.
                You can find more informations{" "}
                <a href="https://docs.aws.amazon.com/lake-formation/latest/dg/data-filters-about.html">
                  here
                </a>
                .
              </div>
            </div>
            <div className="label-form-name" style={{ marginTop: "15px" }}>
              <Field
                name="row_filter_expression"
                className="form-control bg-white"
                placeholder="Row filter expression"
                onChange={(event) =>
                  setFieldValue("row_filter_expression", event.target.value)
                }
                value={values.row_filter_expression}
              />
            </div>
          </Fragment>
        ) : null}

        {rowExpressionMode === "easy" ? (
          <DataFilterExpressionQueryBuilder
            availableColumns={availableColumns}
            columnTypes={columnTypes}
            rules={rules}
            setRules={setRules}
            setFieldValue={setFieldValue}
          />
        ) : null}
        {errors.row_filter_expression && (
          <div className="error-msg">{errors.row_filter_expression}</div>
        )}
      </div>
    </fieldset>
  );
}

export default RowFilterExpression;
