/* @flow */
import React from "react";
import { Grid } from "@material-ui/core";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Paginator from "../../../components/paginator";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import Breadcrumb from "../../../components/breadcrumb";
import "./list.less";
import NewDataset from "../catalog/NewDataset";
import MyDataDatasetCard from "./MyDataDatasetCard";
import Tabs from "../../../components/tabs";
import ExportCSV from "../../../components/exportCsv";
import HelpOn from "../../../components/helpOn";

type propTypes = {
  api: GraphQl,
  match: {
    url: string
  }
};

type stateTypes = {
  hasBeenSearchedOnce: boolean,
  isSearch: boolean,
  term: string,
  datasets: Object,
  ownedDatasets: Object,
  sharedDatasets: Object,
  ready: boolean,
  error: Object,
  isFetchingSharedDatasets: boolean,
  readySharedDatasets: boolean,
  isFetchingOwnedDatasets: boolean,
  readyOwnedDatasets: boolean,
  ownedDatasetsOffset: number,
  sharedDatasetsOffset: number,
  isFetchingBookmarkedDatasets: boolean,
  readyBookmarkedDatasets: boolean,
  bookmarkedDatasetsOffset: number,
  bookmarkedDatasets: Object
};

class OwnedDatasetsList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      hasBeenSearchedOnce: false,
      isSearch: false,
      term: "",
      datasets: null,
      ready: false,
      error: null,
      isFetchingSharedDatasets: false,
      readySharedDatasets: false,
      isFetchingOwnedDatasets: false,
      readyOwnedDatasets: false,
      ownedDatasetsOffset: 0,
      sharedDatasetsOffset: 0,
      ownedDatasets: null,
      sharedDatasets: null,
      bookmarkedDatasets: null,
      readyBookmarkedDatasets: false,
      isFetchingBookmarkedDatasets: false,
      bookmarkedDatasetsOffset: 0
    };
  }

  componentDidMount() {
    this.listAllMyOwnedDataset();
    this.listSharedWithMeDatasets();
    this.listBookmarkedDatasets();
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  listAllIdentityDatasets = (offset = 0) => {
    this.setState({
      datasets: null
    });
    const options = {
      limit: 20,
      offset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.dataset
      .listAllMyDatasets(options)
      .then((datasetsListResult) => {
        if (!datasetsListResult) return false;
        this.setState({
          hasBeenSearchedOnce: true,
          datasets: datasetsListResult.results,
          ready: true,
          isSearch: false
        });
        return datasetsListResult.results;
      });
  };

  listAllMyOwnedDataset = () => {
    this.setState({
      isFetchingOwnedDatasets: true
    });
    const options = {
      limit: 4,
      offset: this.state.ownedDatasetsOffset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.dataset
      .listMyOwnedDatasets(options)
      .then((ownedDatasets) => {
        if (!ownedDatasets) return false;
        this.setState({
          ownedDatasets,
          isFetchingOwnedDatasets: false,
          readyOwnedDatasets: true
        });
        return ownedDatasets;
      });
  };

  listPaginatedMyOwnedDatasets = (ownedDatasetsOffset) =>
    new Promise((r) =>
      this.setState({ ownedDatasetsOffset }, () =>
        this.listAllMyOwnedDataset().then(r)
      )
    );

  listSharedWithMeDatasets = () => {
    this.setState({
      isFetchingSharedDatasets: true
    });
    const options = {
      limit: 4,
      offset: this.state.sharedDatasetsOffset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.dataset
      .listSharedWithMeDatasets(options)
      .then((sharedDatasets) => {
        if (!sharedDatasets) return false;
        this.setState({
          sharedDatasets,
          isFetchingSharedDatasets: false,
          readySharedDatasets: true
        });
        return sharedDatasets;
      });
  };

  listPaginatedSharedWithMeDatasets = (sharedDatasetsOffset) =>
    new Promise((r) =>
      this.setState({ sharedDatasetsOffset }, () =>
        this.listSharedWithMeDatasets().then(r)
      )
    );

  listBookmarkedDatasets = () => {
    this.setState({
      isFetchingBookmarkedDatasets: true
    });
    const options = {
      limit: 4,
      offset: this.state.bookmarkedDatasetsOffset,
      search: undefined
    };

    if (this.state.term) {
      options.search = {
        name: this.state.term,
        description: this.state.term,
        tags: this.state.term
      };
    }
    return this.props.api.dataset
      .listMyBookmarkedDatasets(options)
      .then((bookmarkedDatasets) => {
        if (!bookmarkedDatasets) return false;
        this.setState({
          bookmarkedDatasets,
          isFetchingBookmarkedDatasets: false,
          readyBookmarkedDatasets: true
        });
        return bookmarkedDatasets;
      });
  };

  listPaginatedBookmarkedDatasets = (bookmarkedDatasetsOffset) =>
    new Promise((r) =>
      this.setState({ bookmarkedDatasetsOffset }, () =>
        this.listBookmarkedDatasets().then(r)
      )
    );

  onChangeInput = (e) => {
    const term = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    if (!term || term === "") {
      this.setState({
        isSearch: false,
        ready: false,
        datasets: null,
        term: ""
      });
      return;
    }
    if (term !== "") {
      this.setState({ isSearch: true });
      this.timeout = setTimeout(() => {
        this.setState(
          {
            ready: false,
            term
          },
          this.listAllIdentityDatasets
        );
      }, 300);
      return;
    }
    if (this.state.hasBeenSearchedOnce && term === "") {
      this.setState({
        isSearch: false,
        ready: false,
        datasets: null,
        term: ""
      });
      this.listAllMyOwnedDataset();
      this.listSharedWithMeDatasets();
      this.listBookmarkedDatasets();
    }
  };

  render() {
    const { ownedDatasets, sharedDatasets, bookmarkedDatasets } = this.state;
    if (this.state.error) {
      return <Error error={this.state.error} path={"Dataset List"} />;
    }
    return (
      <div className="MyData">
        <div className="bread-line">
          <div className="title-content" style={{ marginBottom: "10px" }}>
            <Breadcrumb view="Datasets" hideGoBack />
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Grid container spacing={1}>
            <Grid item md={8} sm={8} xs={8}>
              <div className="my-data-v2-search-input">
                <input
                  className="form-control"
                  placeholder="Search"
                  onChange={this.onChangeInput}
                />
                <i className="fas fa-search" />
              </div>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <div className="d-flex">
                <NewDataset />
                <div className="d-flex ml-1">
                  <ExportCSV
                    fetchUrlMethod={() => {
                      return this.props.api.exportCSV.getCSVExportForCatalog(
                        {}
                      );
                    }}
                  />
                  <HelpOn
                    content={
                      <div>
                        <div>
                          Exports all your <b>Owned</b> and{" "}
                          <b>Shared With You</b> datasets
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {this.state.isSearch && <Loading message="Searching Datasets" />}
        {this.state.datasets && this.state.term && (
          <div className="card-container" style={{ marginBottom: "40px" }}>
            {this.state.ready && (
              <Paginator
                limit={8}
                initialOffset={0}
                list={this.state.datasets} // /
                componentRender={(dataset) => (
                  <MyDataDatasetCard
                    key={dataset.uri}
                    dataset={dataset}
                    reloadBookmarks={this.listPaginatedBookmarkedDatasets}
                  />
                )}
                loadPage={this.listAllIdentityDatasets}
                onNoResult={() => (
                  <p className="total-search-results">No datasets found</p>
                )}
              />
            )}
          </div>
        )}
        {!this.state.datasets && !this.state.isSearch && (
          <Tabs
            noBackgroundWhite
            defaultActiveIndex={"owned"}
            tabs={[
              {
                name: (
                  <div>
                    <i className="fa fa-folder-open fa-spacing" />
                    Datasets You Own
                  </div>
                ),
                index: "owned",
                route: `${this.props.match.url}#owned`,
                content: (
                  <div
                    className="card-container"
                    style={{ marginBottom: "30px" }}
                  >
                    {this.state.isFetchingOwnedDatasets && (
                      <Loading message="Your Owned Datasets" />
                    )}
                    {this.state.readyOwnedDatasets && (
                      <Paginator
                        limit={4}
                        initialOffset={0}
                        list={ownedDatasets}
                        componentRender={(dataset) => (
                          <MyDataDatasetCard
                            key={dataset.uri}
                            dataset={dataset}
                            reloadBookmarks={
                              this.listPaginatedBookmarkedDatasets
                            }
                          />
                        )}
                        loadPage={this.listPaginatedMyOwnedDatasets}
                        onNoResult={() => (
                          <p className="total-search-results">
                            No datasets found.
                          </p>
                        )}
                      />
                    )}
                  </div>
                )
              },
              {
                name: (
                  <div>
                    <i className="fas fa-share-alt fa-spacing" />
                    Shared With You
                  </div>
                ),
                index: "shared",
                route: `${this.props.match.url}#shared`,
                content: (
                  <div
                    className="card-container"
                    style={{ marginBottom: "30px" }}
                  >
                    {this.state.isFetchingSharedDatasets && (
                      <Loading message="Datasets Shared With You" />
                    )}
                    {this.state.readySharedDatasets && (
                      <Paginator
                        limit={4}
                        initialOffset={0}
                        list={sharedDatasets}
                        componentRender={(dataset) => (
                          <MyDataDatasetCard
                            key={dataset.uri}
                            dataset={dataset}
                            reloadBookmarks={
                              this.listPaginatedBookmarkedDatasets
                            }
                          />
                        )}
                        loadPage={this.listPaginatedSharedWithMeDatasets}
                        onNoResult={() => (
                          <p className="total-search-results">
                            No shared datasets found !
                          </p>
                        )}
                      />
                    )}
                  </div>
                )
              },
              {
                name: (
                  <div>
                    <i className="fa fa-star fa-spacing" />
                    Bookmarked
                  </div>
                ),
                index: "bookmarks",
                route: `${this.props.match.url}#bookmarks`,
                content: (
                  <div
                    className="card-container"
                    style={{ marginBottom: "30px" }}
                  >
                    {this.state.isFetchingBookmarkedDatasets && (
                      <Loading message="Bookmarked Datasets" />
                    )}
                    {this.state.readyBookmarkedDatasets && (
                      <Paginator
                        limit={4}
                        initialOffset={0}
                        list={bookmarkedDatasets}
                        componentRender={(dataset) => (
                          <MyDataDatasetCard
                            key={dataset.uri}
                            dataset={dataset}
                            reloadBookmarks={
                              this.listPaginatedBookmarkedDatasets
                            }
                          />
                        )}
                        loadPage={this.listPaginatedBookmarkedDatasets}
                        onNoResult={() => (
                          <p className="total-search-results">
                            You haven't bookmarked any datasets.
                          </p>
                        )}
                      />
                    )}
                  </div>
                )
              }
            ]}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(OwnedDatasetsList);
