import { getCSVExportForCatalog } from "./queryGetCSVExportForCatalog";
import { getCSVExportForDatasources } from "./queryGetCSVExportForData";

class ExportCSVApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  getCSVExportForCatalog(input = {}) {
    return this.fetchAppSync(getCSVExportForCatalog, { input }, true);
  }

  getCSVExportForData(input = {}) {
    return this.fetchAppSync(getCSVExportForDatasources, { input }, true);
  }
}

export default ExportCSVApi;
