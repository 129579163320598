/* @flow */

export const mutationUpdateGroup = {
  query: `mutation Q ($accountUri: String!, $groupUri: String!, $input: UpdateGroupInput!) {
        updateGroupDetails(accountUri:$accountUri, groupUri: $groupUri, input:$input) {
            uri
        }
      }
    `,
  keyOutput: "updateGroupDetails"
};

export type UpdateGroupInputPropsTypes = {
  name: string,
  responsible: string,
  description: string,
  tags: string,
  mailing_list: Array<string>
};
