/* @flow */
import React from "react";
import "./view.less";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Paginator from "../../../components/paginator";
import Logger from "../../../utils/logger";
import DatasourceProjectCard from "../../users/components/DatasourceProjectCard";

const Log = Logger("CartDatasources");

type propTypes = {
  api: GraphQl,
  setNbDatasource: Function,
  match?: Object,
  cart: Object
};

type stateTypes = {
  isFetching: boolean,
  isSearch: boolean,
  datasources: Array<Object>,
  error: Object,
  ready: boolean,
  totalDatasources: number,
  cartUri: string,
  groupUri: string,
  offset: number,
  keywords: string
};

class CartDatasources extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);

    this.state = {
      datasources: [],
      error: {},
      ready: false,
      isFetching: false,
      isSearch: false,
      keywords: "",
      totalDatasources: 0,
      offset: 0,
      cartUri: props.match.params.cartUri,
      groupUri: props.cart.group.uri
    };
  }

  componentDidMount() {
    this.listDatasourcesFromProject();
  }

  getDatasourcesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listDatasourcesFromProject().then(r))
    );

  listDatasourcesFromProject = () => {
    this.setState({ isFetching: true, isSearch: true });
    const options = {
      limit: 10,
      filters: [],
      offset: this.state.offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.datasource
      .listDatasourcesFromProject(this.state.cartUri, options)
      .then((datasources) => {
        if (!datasources) return false;
        this.props.setNbDatasource(datasources.total);
        this.setState({
          datasources: datasources.results,
          totalDatasources: datasources.total,
          isFetching: false,
          ready: true
        });
        return datasources;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ isFetching: false, isSearch: false, ready: true });
      });
  };

  onChangeInput = (e) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords
        },
        this.listDatasourcesFromProject
      );
    }, 300);
  };

  render() {
    return (
      <div className="card-container">
        {this.state.isSearch ? (
          <div className="search-datasource-datasets">
            <div className="search-input">
              <input
                className="form-control"
                placeholder="Search"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.error && (
          <Error error={this.state.error} path={"CartView"} stringOnly />
        )}
        {this.state.isFetching && <Loading message="Datasource List" />}
        {this.state.ready && (
          <Paginator
            limit={10}
            initialOffset={0}
            totalCount={this.state.totalDatasources}
            list={this.state.datasources}
            componentRender={(datasource) => (
              <DatasourceProjectCard
                key={datasource.uri}
                api={this.props.api}
                datasource={datasource}
                cartUri={this.state.cartUri}
                projectGroupUri={this.state.groupUri}
                reloadDadasources={this.listDatasourcesFromProject}
              />
            )}
            loadPage={this.getDatasourcesFromPagination}
            onNoResult={() => (
              <p className="total-search-results">No datasource found</p>
            )}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(CartDatasources);
