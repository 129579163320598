/* @flow */
import React from "react";
import Modal from "../../../components/modal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";

type propTypes = {
  cart: Object,
  showGlobalNotification: Function,
  hide: Function,
  api: GraphQl,
  goBack: Function
};

type stateTypes = {
  error: ?string,
  confirmationText: string
};

class DetachProjectModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      confirmationText: ""
    };
  }

  hideModalAndReload = () => {
    this.props.hide();
    window.location.reload();
  };

  detachEquansProject = () =>
    this.props.api.cart
      .detachEquansProject(this.props.cart.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Project detached",
          type: "success"
        });
        this.props.hide();
        if (this.props.goBack) {
          return this.props.goBack();
        }
        return true;
      })
      .catch((errorDetachCartModal) => {
        this.setState({
          error: getErrorMessageWithoutCode(errorDetachCartModal)
        });
        this.props.showGlobalNotification({
          message: "Failed to detach project",
          type: "alert"
        });
      })
      .finally(() => this.setState({ confirmationText: "" }));

  handleChange = (event) => {
    this.setState({ confirmationText: event.target.value });
  };

  detachCartModalTitle = () => (
    <div>
      Detach project <b>{this.props.cart.name}</b>
    </div>
  );

  detachCartModalBody = () => (
    <div>
      <div className={"alert alert-warning"} style={{ fontWeight: 600 }}>
        <p>
          <span>
            Detaching this project <b>will not delete</b> any of its related AWS
            resources from your AWS account.
          </span>
        </p>
        <p>
          <span>
            The project's AWS IAM role permissions{" "}
            <b>will cease to be managed</b> by the CommonDataHub once you
            confirm this action.
          </span>
        </p>
        <p>
          <span>
            Please refer to our{" "}
            <i>
              <a
                href={"https://confluence.tools.digital.engie.com/"}
                className="alert-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation center{" "}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </i>{" "}
            for more information.
          </span>
        </p>
        <p>
          {" "}
          <i className="fas fa-exclamation-circle fa-lg"></i>{" "}
          <span>
            WARNING: this action is <b>IRREVERSIBLE</b>.
          </span>{" "}
        </p>
      </div>
      <div>
        To confirm detach action, type <b>permanently detach</b> in the text
        input field.
      </div>
      <div className="mt-2">
        <input
          onChange={this.handleChange}
          value={this.state.confirmationText}
          className="form-control bg-white"
          placeholder="permanently detach"
        />
      </div>
    </div>
  );

  render() {
    const { cart, hide } = this.props;

    const { error } = this.state;

    return (
      <Modal
        title={this.detachCartModalTitle()}
        body={!error && this.detachCartModalBody()}
        errorMessage={error}
        classModalLarge={"modal-lg"}
        actions={[
          <button
            key={`cart-close-${cart.uri}`}
            type="button"
            className="butn butn-flat"
            onClick={() => hide()}
          >
            Close
          </button>,
          !error && (
            <button
              key={`cart-detach-${cart.uri}`}
              type="button"
              disabled={this.state.confirmationText !== "permanently detach"}
              className="butn butn-check"
              onClick={this.detachEquansProject}
            >
              Detach
            </button>
          )
        ]}
      />
    );
  }
}

export default withAppSync(DetachProjectModal);
