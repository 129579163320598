/* @flow */
import React from "react";
import "./view.less";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import CartLogItem from "./CartLogItem";
import Paginator from "../../../components/paginator";

type propTypes = {
  cart: {
    uri: string
  },
  api: GraphQl
};

type stateTypes = {
  error: Object,
  logs: Array<Object>,
  ready: boolean,
  offset: number,
  total: number
};

class CartLogs extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      ready: false,
      offset: 0,
      total: 0,
      logs: []
    };
  }

  componentDidMount() {
    this.listLogs();
  }

  listLogs = () => {
    const queryOptions = {
      order_by: { value: "desc", key: "createdat" },
      limit: 10,
      offset: this.state.offset
    };
    return this.props.api.cart
      .listCartLogs(this.props.cart.uri, queryOptions)
      .then((items) => {
        this.setState({
          logs: items.results,
          total: items.total,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getLogsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listLogs().then(r))
    );

  render() {
    return (
      <div>
        {this.state.error && (
          <Error error={this.state.error} path={"CartLogs"} />
        )}
        {!this.state.ready && <Loading message={"logs"} />}
        {this.state.ready && (
          <div className="cart-logs-container">
            <Paginator
              limit={10}
              initialOffset={0}
              list={this.state.logs}
              totalCount={this.state.total}
              componentRender={(logCart) => <CartLogItem log={logCart} />}
              loadPage={this.getLogsFromPagination}
              onNoResult={() => (
                <p className="total-search-results">No logs found</p>
              )}
            />
          </div>
        )}
      </div>
    );
  }
}
export default withAppSync(withGoTo(CartLogs));
