/* @flow */

import React, { Component } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import withAppsync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import { updateMessage } from "../../globalNotifications/actions";
import SmStudioLogo from "../smstudio-logo.png";
import withGoTo from "../../goToHOC";

const Log = Logger("CartUserProfileCreate");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  cart: Object,
  domains: Array<Object>,
  open: boolean,
  onClose: Function,
  reloadUserProfiles: Function
};

type stateTypes = {
  error: boolean,
  errorSubmit: Object,
  selectedValue: {
    domainId: string,
    cartUri: string
  },
  savingUserProfile: boolean
};

class CartUserProfileCreate extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: false,
      errorSubmit: false,
      selectedValue: {
        domainId: "",
        cartUri: props.cart.uri
      },
      savingUserProfile: false
    };
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  save(values, setSubmitting) {
    this.setState({ errorSubmit: false, savingUserProfile: true });
    return this.props.api.smstudio
      .createUserProfile(this.props.cart.uri, this.state.selectedValue.domainId)
      .then(() => {
        setSubmitting(false);
        this.setState({ savingUserProfile: false });
        this.props.showGlobalNotification({
          message: "Sagemaker Studio user profile creation started.",
          type: "success"
        });
        this.props.onClose();
        this.props.reloadUserProfiles();
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.setState({ errorSubmit, savingUserProfile: false });
      });
  }

  render() {
    const { selectedValue } = this.state;
    return (
      <div>
        <Dialog
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container spacing={2}>
                <Grid item md={1} sm={1} xs={1}>
                  <img
                    className="connector-logo-img"
                    src={SmStudioLogo}
                    alt="Sagemaker Studio"
                    width={35}
                    height={35}
                  />
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    New Sagemaker Studio User Profile
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <Error error={this.state.error} path="CreteConnection" />
            )}
            <Box sx={{ p: 3 }}>
              <Formik
                initialValues={{
                  domainId: selectedValue.domainId
                }}
                validate={() => {
                  const errors = {};
                  if (!selectedValue.domainId) {
                    errors.name = "Please select a Sagemaker Studio domain";
                  }
                  Log.info("validate", selectedValue, errors);
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.save(values, setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {Object.keys(errors).length > 0 && (
                      <div>
                        {Object.values(errors).map((e) => (
                          <div className="error-msg">
                            <Error
                              stringOnly
                              error={e}
                              path={"DSSProjectConnection"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {this.state.errorSubmit && (
                      <Error
                        stringOnly
                        error={this.state.errorSubmit}
                        path={"DSSProjectConnection"}
                      />
                    )}
                    <div className="form-container">
                      <div className="mb-3">
                        <label className="label-form">
                          Sagemaker Studio Domain
                        </label>
                        <Autocomplete
                          options={this.props.domains}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            return option.name;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={"Select a domain"}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            this.handleChange("domainId", value.id);
                          }}
                        />
                      </div>
                      <CardContent>
                        <button
                          type={"submit"}
                          className="butn butn-create"
                          disabled={isSubmitting}
                          style={{ margin: "5px", width: "100%" }}
                        >
                          {this.state.savingUserProfile ? (
                            <i className="fas fa-sync-alt fa-spin" />
                          ) : (
                            <i className={"fa fa-paper-plane"} />
                          )}
                          <span>{" Create"}</span>
                        </button>
                      </CardContent>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(CartUserProfileCreate)));
