/* @flow */

import React from "react";
import withErrorBoundary from "../../../../errorBoundaryHOC";
import withGoTo from "../../../../goToHOC";
import Logger from "../../../../../utils/logger";

import "./searchDataset.less";

const Log = Logger("SearchDataset");

type propTypes = {
  onChangeTerm: Function,
  runSearch: Function,
  onClear: Function,
  term: string,
  isLoading: boolean
};

type stateTypes = {
  search: Object,
  state: boolean,
  toUpdate: boolean
};

class SearchDataset extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    onChangeTerm: () => {
      Log.warning("onChangeTerm not defined !");
    },
    runSearch: () => {
      Log.warning("runSearch not defined !");
    }
  };

  timeout: TimeoutID;

  onChangeTerm = (e) => {
    const search = e.target.value;
    this.props.onChangeTerm(search);

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.runSearch, 500);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return (
      <div className="search-dataset">
        <label className="label-form">Search for a dataset to share : </label>
        <div className="search-input-dataset">
          <input
            className="form-control"
            onChange={this.onChangeTerm}
            value={this.props.term}
            placeholder={"search dataset"}
          />
          {this.props.isLoading && (
            <i className="fas fa-circle-notch fa-spin" />
          )}
          {this.props.term ? (
            <i
              className="fa fa-times-circle"
              onClick={() => this.props.onClear(true)}
            />
          ) : (
            <i className="fas fa-search" />
          )}
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(withGoTo(SearchDataset));
