/* eslint-disable object-curly-newline */
/* @flow */
import React from "react";
import classnames from "classnames";
import { Card, CardContent, Divider, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import GraphQl from "../../graphQL";
import withGoTo from "../goToHOC";
import { updateMessage } from "../globalNotifications/actions";
import withAppSync from "../AppsyncHOC";
import DeleteObjectWithFrictionModal from "../../components/DeleteObjectWithFrictionModal";
import logoCollibra from "./collibra-logo.png";
import "./style.less";
import { formatDate } from "../../utils/date";

type propTypes = {
  collibraDataset: Object,
  api: GraphQl,
  reloadCollibraDatasets: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  errorConsoleAccess?: Object,
  openDeleteModal: boolean,
  error: Object,
  status: string,
  refreshing: boolean
};

class CollibraDataset extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      openDeleteModal: false,
      error: null,
      status: props.collibraDataset.status,
      refreshing: false
    };
  }

  getCollibraConsoleAccess = () => {
    window.open(this.props.collibraDataset.asset_url, "_blank");
  };

  deleteCollibraDataset = () => {
    this.props.api.collibra
      .removeDatasetFromCollibra(
        this.props.collibraDataset.dataset_uri,
        this.props.collibraDataset.collibra_link_id
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Dataset planned for deletion from Collibra",
          type: "success"
        });
        this.setState({ openDeleteModal: false });
        this.props.reloadCollibraDatasets();
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  publishToCollibra = () => {
    this.setState({ refreshing: true });
    this.props.api.collibra
      .indexDatasetOnCollibra(
        this.props.collibraDataset.dataset_uri,
        this.props.collibraDataset.collibra_link_id
      )
      .then(() => {
        this.setState({ refreshing: false });
        this.props.showGlobalNotification({
          message:
            "Republishing CDH dataset to Collibra. It may take few minutes...",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({ refreshing: false, error });
      });
  };

  render() {
    const { collibraDataset } = this.props;

    return (
      <Card
        key={collibraDataset.id}
        sx={{
          mt: 2
        }}
      >
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <img
                className="connector-logo-img mr-2"
                src={logoCollibra}
                alt="Collibra"
                width={15}
                height={15}
              />
              Asset on Domain: {collibraDataset.collibra_link.domain_name}
            </div>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item md={10} xs={6}>
              <div>
                <img
                  className="connector-logo-img mr-1"
                  src={logoCollibra}
                  alt="Collibra"
                  width={26}
                  height={26}
                />
                <span className="cdh-connection-name">
                  <a
                    href={`${collibraDataset.asset_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {collibraDataset.asset_url}
                  </a>
                </span>
              </div>
            </Grid>
            <Grid item md={2} xs={6}>
              {(collibraDataset.status !== "Available" ||
                collibraDataset.status === "PUBLISH_COMPLETED") && (
                <button
                  type="button"
                  className="butn butn-delete"
                  onClick={this.deleteCollibraDataset}
                >
                  <i className="fas fa-eye-slash fa-spacing" />
                  <span className=""> Unpublish</span>
                </button>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <i className="far fa-calendar-alt fa-spacing" />
            <span className="text-footer-left">
              Last updated: {formatDate(this.props.collibraDataset.updatedat)}
            </span>
          </div>
          <div className="cdh-card-end">
            <button
              disabled={[
                "PENDING_DELETION",
                "DELETE_IN_PROGRESS",
                "DELETE_COMPLETED"
              ].includes(this.state.status)}
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.props.reloadCollibraDatasets}
            >
              {this.state.refreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
            <div className="text-footer-metrics">
              Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": ["PUBLISH_COMPLETED"].includes(
                    this.state.status
                  ),
                  "warning-status": [
                    "PUBLISH_PENDING",
                    "PENDING_INDEXING"
                  ].includes(this.state.status),
                  "error-status": [
                    "PENDING_DELETION",
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETED"
                  ].includes(this.state.status)
                })}
              >
                {this.state.status}
              </span>
            </div>
          </div>
        </div>
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={"Collibra Dataset"}
            deleteMessage={"This action will delete the asset on Collibra"}
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => this.setState({ openDeleteModal: false })}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteCollibraDataset}
            isAWSResource={false}
          />
        )}
        {this.state.error && (
          <div className="IAM-error">
            <Alert severity="error">{this.state.error.message}</Alert>
          </div>
        )}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CollibraDataset)));
