/* @flow */
import React from "react";
import { ErrorMessage, Field } from "formik";
import classnames from "classnames";
import Row from "../../../../components/row";
import Col from "../../../../components/col";
import Logger from "../../../../utils/logger";
import HelpOn from "../../../../components/helpOn";
import ToggleButton from "../../../../components/toggleButton";
import { DOMAINS } from "../constants";
import LevelOfMaturity from "../LevelOfMaturity";
import TagsField from "../TagsField";
import { slugifyNameForDatasetPrefix } from "../../../../utils/slugifier";
import AutoCompleteLanguages from "./components/autoCompleteLanguages";
import HelpLevelOfMaturity from "./components/helpLevelOfMaturity";
import AutoCompleteDataTypes from "./components/autoCompleteDataTypes";
import SelectInfinite from "../../../../components/SelectInfinite";
import SelectClassic from "../../../../components/SelectClassic";
import { capitalize } from "../../../../utils/string";
import Modal from "../../../../components/modal";

const Log = Logger("FormMainPart");

const MIN = 30;

type propTypes = {
  isNew: boolean,
  dataset: Object,
  handleChange: Function,
  accounts: Array<Object>,
  platformDatasources: Array<Object>,
  accountPlatforms: Array<Object>,
  errors: Object,
  touched: Object,
  isPrefilledAccount: boolean,
  isPrefilledPlatform: boolean,
  isPrefilledDatasource: boolean,
  accountSelected: Object,
  environmentSelected: Object,
  datasourceSelected: Object,
  isLoadingAccounts: boolean,
  isLoadingPlatforms: boolean,
  isLoadingDatasources: boolean,
  loadMoreDatasources: Function,
  totalDatasources?: number
};

type stateTypes = {
  confirmLakeFormationDesactivationModalOpened: boolean
};
class FormMainPart extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      confirmLakeFormationDesactivationModalOpened: false
    };
  }

  static defaultProps = {
    handleChange: () => {
      Log.warning("handleChange not defined !");
    },
    accounts: [],
    accountPlatforms: [],
    platformDatasources: [],
    dataset: {},
    errors: {}
  };

  renderNewPart = () => {
    const {
      handleChange,
      accounts,
      isLoadingAccounts,
      accountPlatforms,
      isLoadingPlatforms,
      platformDatasources,
      isLoadingDatasources,
      isPrefilledAccount,
      isPrefilledPlatform,
      isPrefilledDatasource,
      accountSelected,
      environmentSelected,
      datasourceSelected,
      totalDatasources,
      loadMoreDatasources,
      dataset,
      errors
    } = this.props;

    const getPlaceholderAccount = () => {
      if (isPrefilledAccount && accountSelected && accountSelected.uri)
        return accountSelected.name;
      return "select an organization";
    };

    const getPlaceholderPlatform = () => {
      if (
        isPrefilledAccount &&
        isPrefilledPlatform &&
        environmentSelected &&
        environmentSelected.uri
      )
        return environmentSelected.name;
      if (!accountPlatforms || accountPlatforms.length <= 0)
        return "No environment found";
      return "select an environment";
    };

    const getPlaceholderDatasource = () => {
      if (
        isPrefilledAccount &&
        isPrefilledPlatform &&
        isPrefilledDatasource &&
        datasourceSelected &&
        datasourceSelected.uri
      )
        return datasourceSelected.name;
      if (!platformDatasources || platformDatasources.length <= 0)
        return "No datasource found";
      return "select an environment";
    };

    return (
      <div>
        <div className="mb-3 my-2">
          <label className="label-form">Organization *</label>

          <SelectClassic
            isLoadingOptions={isLoadingAccounts}
            isDisabled={isPrefilledAccount}
            placeholder={getPlaceholderAccount()}
            options={accounts.map((a) => ({ value: a.uri, label: a.name }))}
            selectedOption={
              accountSelected && accountSelected.uri
                ? {
                    value: accountSelected.uri,
                    label: accountSelected.name
                  }
                : null
            }
            onSelectOption={(option) =>
              handleChange("accountUri")({ target: { value: option.value } })
            }
          />

          {errors.accountUri && (
            <Col size={12} style={{ color: "red" }}>
              {errors.accountUri}
            </Col>
          )}
        </div>

        <div className="mb-3">
          <label className="label-form">Environment *</label>

          <SelectClassic
            isLoadingOptions={isLoadingAccounts || isLoadingPlatforms}
            isDisabled={isPrefilledAccount && isPrefilledPlatform}
            placeholder={getPlaceholderPlatform()}
            options={accountPlatforms.map((a) => ({
              value: a.uri,
              label: a.name
            }))}
            selectedOption={
              environmentSelected && environmentSelected.uri
                ? {
                    value: environmentSelected.uri,
                    label: environmentSelected.name
                  }
                : null
            }
            onSelectOption={(option) =>
              handleChange("platformUri")({ target: { value: option.value } })
            }
          />

          {errors.platformUri && (
            <Col size={12} style={{ color: "red" }}>
              {errors.platformUri}
            </Col>
          )}
        </div>

        <div className="mb-3">
          <label className="label-form">Datasource *</label>
          <SelectInfinite
            key={"select-infinite-dataset-datasource"}
            isLoadingOptions={
              isLoadingAccounts || isLoadingPlatforms || isLoadingDatasources
            }
            placeholder={getPlaceholderDatasource()}
            isDisabled={
              (isPrefilledAccount &&
                isPrefilledPlatform &&
                isPrefilledDatasource) ||
              (!isLoadingDatasources && platformDatasources.length <= 0)
            }
            selectedOption={
              datasourceSelected && datasourceSelected.uri
                ? {
                    value: datasourceSelected.uri,
                    label: datasourceSelected.name
                  }
                : undefined
            }
            totalOptions={totalDatasources || 0}
            initialOptions={
              platformDatasources
                ? platformDatasources.map((d) => ({
                    value: d.uri,
                    label: d.name
                  }))
                : []
            }
            loadMoreOptions={loadMoreDatasources}
            onSelectOption={(value) =>
              handleChange("datasourceUri")({ target: { value } })
            }
          />

          {errors.datasourceUri && (
            <Col size={12} style={{ color: "red" }}>
              {errors.datasourceUri}
            </Col>
          )}
        </div>

        <Row>
          <Col size={6}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text">Region</label>
              </div>
              <select
                value={dataset.datasourceRegion}
                className="custom-select"
                disabled
              >
                <option
                  key={dataset.datasourceRegion}
                  value={dataset.datasourceRegion}
                >
                  {" "}
                  {dataset.datasourceRegion}
                </option>
              </select>
            </div>
          </Col>
          <Col size={6}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text">Owner</label>
              </div>
              <select
                value={dataset.datasourceOwner}
                className="custom-select"
                disabled
              >
                <option
                  key={dataset.datasourceOwner}
                  value={dataset.datasourceOwner}
                >
                  {" "}
                  {dataset.datasourceOwner}
                </option>
              </select>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  handleLakeFormationActivationToggle = ({ dataset }: { dataset: Object }) => {
    const newValue = !dataset.is_registered_with_lakeformation;
    if (newValue === false) {
      this.setState({ confirmLakeFormationDesactivationModalOpened: true });
      return;
    }
    this.props.handleChange("is_registered_with_lakeformation")({
      target: { value: newValue }
    });
  };

  onLakeFormationDesactivationConfirmed = () => {
    this.setState({ confirmLakeFormationDesactivationModalOpened: false });
    this.props.handleChange("is_registered_with_lakeformation")({
      target: { value: false }
    });
  };

  render() {
    const {
      handleChange,
      isNew,
      dataset,
      errors,
      touched,
      datasourceSelected
    } = this.props;
    const sourceType = datasourceSelected
      ? datasourceSelected.source_type
      : dataset.source_type;
    return (
      <div className="dataset-form-main-part">
        {isNew && this.renderNewPart()}

        <div>
          <fieldset className="form-group">
            <legend className="label-form">name *</legend>
            <Field
              onChange={(e) => handleChange("name")(e)}
              value={dataset.name}
              type="name"
              name="name"
              className="form-control bg-white"
              placeholder="Dataset name..."
            />
            {dataset.name.length > 0 && dataset.name.length <= 3 && (
              <div className="info-msg">
                Choosing a dataset name that is too short may result in bad
                discoverability or reuse of your data. Choosing a longer,
                self-explanatory name will ensure a better discoverability or
                usage of your data.
              </div>
            )}

            {errors.name && touched.name && (
              <div className="error-msg">{errors.name}</div>
            )}
          </fieldset>

          {isNew && sourceType === "S3" && (
            <fieldset className="form-group">
              <legend className="label-form">s3 prefix</legend>
              <Field
                onChange={(e) => handleChange("prefix")(e)}
                value={
                  isNew
                    ? slugifyNameForDatasetPrefix(
                        dataset.prefix || dataset.name
                      )
                    : dataset.prefix
                }
                type="prefix"
                name="prefix"
                className="form-control bg-white"
                placeholder="Prefix location where the data will be expected"
                disabled={!isNew}
              />
            </fieldset>
          )}

          {sourceType === "S3" && (
            <fieldset className="form-group">
              <legend className="label-form">Type of dataset *</legend>
              <SelectClassic
                placeholder={"Select a type"}
                options={[
                  { value: "structured", label: "Structured" },
                  { value: "unstructured", label: "Unstructured" }
                ]}
                selectedOption={
                  dataset.type
                    ? {
                        value: dataset.type,
                        label: capitalize(dataset.type)
                      }
                    : null
                }
                onSelectOption={(option) =>
                  handleChange("type")({ target: { value: option.value } })
                }
              />
              <ErrorMessage name="type" component="div" />
            </fieldset>
          )}

          {isNew && sourceType === "REDSHIFT_SERVERLESS" && (
            <fieldset className="form-group">
              <legend className="label-form">Schema *</legend>
              <Field
                onChange={(e) => handleChange("schema")(e)}
                value={
                  isNew
                    ? slugifyNameForDatasetPrefix(
                        dataset.schema || dataset.name
                      )
                    : dataset.schema
                }
                type="schema"
                name="schema"
                className="form-control disabled"
                placeholder="Schema name on Redshift Serverless database"
                disabled
              />
            </fieldset>
          )}

          <fieldset className="form-group">
            <legend className="label-form">
              description *
              <div
                className={classnames("dataset-description", {
                  "description-is-too-short":
                    (dataset.description || "").length < MIN,
                  "description-is-good":
                    (dataset.description || "").length >= MIN
                })}
              >
                ({(dataset.description || "").length}/{MIN} characters min)
              </div>
            </legend>
            <Field
              onChange={(e) => handleChange("description")(e)}
              value={dataset.description}
              component="textarea"
              name="description"
              className="form-control bg-white"
              placeholder="Description..."
              rows={4}
              validate={() =>
                (!dataset.description || dataset.description.length < MIN) &&
                `Description is too short (${MIN} characters min)`
              }
            />
            {errors.description && touched.description && (
              <div className="error-msg">{errors.description}</div>
            )}
          </fieldset>

          <fieldset className="form-group">
            <label className="label-form">Basic metadata display *</label>
            <div className="basic-metadata-display">
              <ToggleButton
                id="basic-metadata-display-id"
                checkedValue={!!dataset.basic_metadata}
                onChange={() =>
                  handleChange("basic_metadata")({
                    target: { value: !dataset.basic_metadata }
                  })
                }
              />

              <div>
                <HelpOn
                  content={
                    <div>
                      <h3>{"Basic metadata display"}</h3>
                      <div>
                        <b>{"Activated:"}</b>
                      </div>
                      <div>
                        {
                          "Displays an overview of the dataset structure and its quality (metadata visibility)"
                        }
                      </div>
                      <div>{""}</div>
                      <div>
                        <b>{"Deactivated:"}</b>
                      </div>
                      <div>
                        {
                          "Displays a limited access to the metadata for a CDH user with no access to the dataset."
                        }
                      </div>
                      <div>
                        {
                          "(visibility limited to Field name, Field Type and Field Completeness analysis)"
                        }
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </fieldset>

          <fieldset className="form-group">
            <label className="label-form">
              Level of maturity *
              <HelpOn content={<HelpLevelOfMaturity />} />
            </label>
            <LevelOfMaturity
              step={dataset.maturity_level || 0}
              onStepChange={(step) =>
                handleChange("maturity_level")({ target: { value: step } })
              }
            />
          </fieldset>

          <fieldset className="form-group">
            <div className="tags">
              <div className="multiple-elements">
                <TagsField
                  tags={dataset.tags}
                  onChangeTags={(e) => handleChange("tags")(e)}
                />
              </div>
            </div>
            {errors.tags && touched.tags && (
              <div className="error-msg"> {errors.tags}</div>
            )}
          </fieldset>
          <fieldset className="form-group">
            <div className="d-flex">
              <div className="multiple-elements">
                <legend className="label-form">Usage</legend>
                <SelectClassic
                  placeholder={"Select usage"}
                  options={[
                    { value: "Public", label: "Public" },
                    { value: "Internal Use", label: "Internal Use" }
                  ]}
                  selectedOption={
                    dataset.visibility
                      ? {
                          value: dataset.visibility,
                          label: capitalize(dataset.visibility)
                        }
                      : null
                  }
                  onSelectOption={(option) =>
                    handleChange("visibility")({
                      target: { value: option.value }
                    })
                  }
                />
              </div>
              <div className="ml-3">
                <label className="label-form">Sharing Feature</label>
                <div className="d-flex">
                  <ToggleButton
                    id="shareable-id"
                    checkedValue={!!dataset.shareable}
                    onChange={() =>
                      handleChange("shareable")({
                        target: { value: !dataset.shareable }
                      })
                    }
                  />
                  <HelpOn
                    content={
                      <div>
                        <h3>{"Shareable"}</h3>
                        <div>
                          <b>{""}</b>
                        </div>
                        <div>
                          {
                            "This option is used to define whether the dataset can be shared or not."
                          }
                        </div>
                        <div>
                          {
                            "If this option is set as unshareable, the dataset will continue to appear in the catalog but will no longer have a request access or share option"
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </fieldset>
          {sourceType === "S3" && (
            <fieldset>
              <legend className="label-form">
                Register With AWS Lake Formation
              </legend>
              {dataset.datasourceRegisteredWithLakeFormation && (
                <small className="attribute-label">
                  Your Environment is registered with LakeFormation. This
                  dataset {isNew ? "will be" : "is"} automatically registered
                  with LakeFormation
                </small>
              )}
              <div className="d-flex">
                <ToggleButton
                  id="isregisteredwithlakeformationid"
                  checkedValue={!!dataset.is_registered_with_lakeformation}
                  onChange={() =>
                    this.handleLakeFormationActivationToggle({ dataset })
                  }
                  disabled={dataset.datasourceRegisteredWithLakeFormation}
                />
                <HelpOn
                  content={
                    <div>
                      <h3>{"Register With Lake Formation"}</h3>
                      <div>
                        <b>{""}</b>
                      </div>
                      <div>
                        {
                          "When enabled CDH relies on AWS Lake Formation and IAM to share and control access to the dataset stages."
                        }
                      </div>
                      <div>
                        {
                          "This feature is disabled by default, which means CDH relies only on AWS IAM to manage access and sharing of the dataset stages."
                        }
                      </div>
                    </div>
                  }
                />
                {this.state.confirmLakeFormationDesactivationModalOpened ? (
                  <Modal
                    title={"Confirm Lake Formation desactivation"}
                    body={
                      <div>
                        <i className="fas fa-exclamation-triangle"></i>
                        &nbsp; Deactivating LF from a consumed dataset should
                        not be possible except by agreeing the disclaimer
                        stating that access to partitions will be lost
                      </div>
                    }
                    actions={[
                      <button
                        type="button"
                        onClick={() =>
                          this.onLakeFormationDesactivationConfirmed()
                        }
                        disabled={false}
                        className="butn butn-create"
                      >
                        <i className="fas fa-check-circle"></i>
                        <span className="butn-text">Confirm desactivation</span>
                      </button>,
                      <button
                        type="button"
                        className="butn butn-delete"
                        onClick={() =>
                          this.setState({
                            confirmLakeFormationDesactivationModalOpened: false
                          })
                        }
                      >
                        Cancel
                      </button>
                    ]}
                  />
                ) : null}
              </div>
            </fieldset>
          )}
          <fieldset className="form-group">
            <legend className="label-form">Domain *</legend>
            <SelectClassic
              placeholder={"Select a domain"}
              options={DOMAINS.map((domain) => ({
                value: domain.key,
                label: domain.label
              }))}
              selectedOption={
                dataset.domain
                  ? {
                      value: dataset.domain,
                      label: DOMAINS.find((d) => d.key === dataset.domain).label
                    }
                  : null
              }
              onSelectOption={(option) =>
                handleChange("domain")({ target: { value: option.value } })
              }
            />
            {errors.domain && touched.domain && (
              <div className="error-msg"> {errors.domain}</div>
            )}
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">Data Types</legend>
            <AutoCompleteDataTypes
              defaultValue={dataset.data_types}
              handleChange={(e, v) => handleChange("data_types")(e, v)}
            />
            {errors.data_types && touched.data_types && (
              <div className="error-msg"> {errors.data_types}</div>
            )}
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">Languages *</legend>
            <AutoCompleteLanguages
              defaultValue={dataset.languages}
              handleChange={(e, v) => handleChange("languages")(e, v)}
            />
            {errors.languages && touched.languages && (
              <div className="error-msg"> {errors.languages}</div>
            )}
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">Update frequency *</legend>
            <SelectClassic
              placeholder={"Select a frequency"}
              options={[
                { value: "Unfrequent", label: "Unfrequent" },
                { value: "Daily", label: "Daily" },
                { value: "Weekly", label: "Weekly" },
                { value: "Monthly", label: "Monthly" }
              ]}
              selectedOption={
                dataset.update_frequency
                  ? {
                      value: dataset.update_frequency,
                      label: capitalize(dataset.update_frequency)
                    }
                  : null
              }
              onSelectOption={(option) =>
                handleChange("update_frequency")({
                  target: { value: option.value }
                })
              }
            />
            {errors.update_frequency && touched.update_frequency && (
              <div className="error-msg"> {errors.update_frequency}</div>
            )}
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">Link to description</legend>
            <div className={"input-group"}>
              <div className={"input-group-prepend"}>
                <span className="input-group-text" style={{ padding: "5px" }}>
                  URL
                </span>
              </div>
              <Field
                onChange={(e) =>
                  handleChange("details.external_description_url")(e)
                }
                value={dataset.details.external_description_url || ""}
                type="text"
                name="external_description_url"
                className="form-control bg-white"
              />
            </div>
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">License</legend>
            <Field
              onChange={(e) => handleChange("details.license")(e)}
              value={dataset.details.license || ""}
              type="text"
              name="license"
              className="form-control bg-white"
            />
          </fieldset>

          <fieldset className="form-group">
            <legend className="label-form">Contact</legend>
            <Field
              onChange={(e) => handleChange("details.contact")(e)}
              value={dataset.details.contact || ""}
              type="text"
              name="contact"
              className="form-control bg-white"
            />
          </fieldset>
        </div>
      </div>
    );
  }
}

export default FormMainPart;
