/* eslint-disable quote-props */
/* @flow */
import React from "react";
import "./card.less";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import DatasetCardTitle from "./DatasetCardTitle";
import DatasetCardPublisher from "./DatasetCardPublisher";
import DatasetCardActions from "./DatasetCardActions";
import DatasetCardContainer from "./DatasetCardContainer";
import DatasetCardShared from "./DatasetCardShared";
import { findDomainName } from "../components/constants";
import BookmarkStar from "../components/bookmarkStar";
import { formatDate } from "../../../utils/date";
import { convertSize } from "../../../utils/size";
import NbBookmarksStar from "../components/nbBookmarksStar";
import withErrorBoundary from "../../errorBoundaryHOC";

type propTypes = {
  uri: string,
  name: string,
  account_name: string,
  description: string,
  environment: string,
  default_table: string,
  domain: string,
  tags: string,
  goTo: Function,
  access: "owner" | "shared" | "other",
  type: string,
  account: { name: string, uri: string },
  owner_group_name: string,
  datasource_name: string,
  nb_views: number,
  nb_shares: number,
  nb_carts: number,
  nb_bookmarks: number,
  is_bookmarked: boolean,
  updatedat: string,
  size: number,
  can_share: boolean,
  shareable: boolean,
  actions: ?Object,
  style: Object
};

type stateTypes = {
  isSelected: boolean,
  nb_bookmarks: number
};

const classEnvironment = {
  NoProd: "NoProd",
  Prod: "Prod"
};

class DatasetCard extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    name: "-",
    creator: { name: "-" },
    description: "",
    tags: "",
    type: "",
    createdat: "",
    environment: "",
    domain: "other",
    account: {},
    datasource_name: "",
    owner_group_name: "",
    nb_carts: 0,
    nb_shares: 0,
    nb_views: 0,
    nb_bookmarks: 0,
    is_bookmarked: false,
    updatedat: "",
    size: 0,
    can_share: false,
    shareable: false
  };

  DOMRef: Object;

  constructor(props) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {
      isSelected: false,
      nb_bookmarks: props.nb_bookmarks
    };
  }

  shouldComponentUpdate(nextProps): boolean {
    return nextProps.uri !== this.props.uri;
  }

  componentWillUnmount() {}

  renderActions() {
    if (this.props.actions) {
      return this.props.actions;
    }

    return (
      <DatasetCardActions
        access={this.props.access}
        name={this.props.name}
        type={this.props.type}
        uri={this.props.uri}
        canShare={this.props.can_share}
        shareable={this.props.shareable}
        defaultTable={this.props.default_table}
        goTo={this.props.goTo}
      />
    );
  }

  render() {
    return (
      <DatasetCardContainer
        style={this.props.style}
        ref={this.DOMRef}
        isSelected={this.state.isSelected}
        access={this.props.access}
        goToDataset={() =>
          this.props.goTo({
            route: routes.Dataset.View,
            params: {
              uriDataset: this.props.uri
            },
            state: {
              dataset: { uri: this.props.uri, name: this.props.name }
            }
          })
        }
        dataset={{
          key: Math.random(),
          uri: this.props.uri,
          name: this.props.name
        }}
      >
        <div className="card-dataset-header">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content">
              <BookmarkStar
                datasetUri={this.props.uri}
                isBookmarked={this.props.is_bookmarked}
                onChange={(value) =>
                  this.setState((prevState) => ({
                    nb_bookmarks: prevState.nb_bookmarks + value
                  }))
                }
              />
            </div>
            <div className="card-dataset-begin-content">
              <i className="far fa-suitcase" />
              <span className="text-header-dataset">
                {findDomainName(this.props.domain)}
              </span>
            </div>
          </div>
          <div className="card-dataset-end">
            <span className="text-type-dataset">{this.props.type} Data</span>
            <div
              className={`dataset-card-environment ${
                classEnvironment[this.props.environment]
              }`}
            >
              <span>{this.props.environment}</span>
            </div>
            <DatasetCardShared access={this.props.access} />
          </div>
        </div>
        <div className="card-dataset-body">
          <div className="card-dataset-body-left">
            <div>
              <DatasetCardTitle
                name={this.props.name}
                uri={this.props.uri}
                access={this.props.access}
                defaultTable={this.props.default_table}
                type={this.props.type}
              />
              <DatasetCardPublisher
                publisher={this.props.account_name}
                ownergroupname={this.props.owner_group_name}
                size={250}
              />
              <div className="card-size-details">
                <p className="card-size-updateDate">
                  <span className="font-weight-bold">Last Data Update : </span>
                  {formatDate(this.props.updatedat)} |{" "}
                  <span className="font-weight-bold">Size : </span>
                  {convertSize(this.props.size)}
                </p>
              </div>
            </div>
            <div className="card-dataset-details">
              <p className="card-dataset-description">
                {this.props.description}
              </p>
            </div>
            <div>
              <div className="card-dataset-tags">
                <div className="">
                  {this.props.tags
                    .split(",")
                    .filter((tag) => tag && tag.trim())
                    .map((tag) => (
                      <div className="dataset-badge" key={tag}>
                        <div className="circle-tag" />
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">{this.renderActions()}</div>
        </div>
        <div className="card-dataset-footer">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content d-flex">
              <i className="fas fa-database"></i>
              <span className="text-header-dataset">
                {this.props.datasource_name}
              </span>
            </div>
          </div>
          <div className="card-dataset-end">
            <div className="text-footer-metrics">
              Seen&nbsp;
              <span className="tag-metrics">
                {this.props.nb_views === null ? "0" : this.props.nb_views}
              </span>
              , Shared with&nbsp;
              <span className="tag-metrics">
                {this.props.nb_shares === null ? "0" : this.props.nb_shares}
              </span>
              &nbsp;groups, in&nbsp;
              <span className="tag-metrics">
                {this.props.nb_carts === null ? "0" : this.props.nb_carts}
              </span>
              &nbsp;projects&nbsp;
              <NbBookmarksStar
                nb={!this.state.nb_bookmarks ? 0 : this.state.nb_bookmarks}
              />
            </div>
          </div>
        </div>
      </DatasetCardContainer>
    );
  }
}

export default withErrorBoundary(withGoTo(DatasetCard), false);
