export const detachEquansPlatform = {
  query: `mutation M ($platformUri : String!) {
        detachEquansPlatform(platformUri : $platformUri ) {
            uri
            accountid
            resource_name
            description
            doc
            parent
            tags
            creatoruri
            name
            aws
            environment
            default_region
            groups
            trusts
            networks
            templates
        }
    }
    `,
  keyOutput: "detachEquansPlatform"
};
