/* @flow */
import React from "react";
import "./view.less";
import { formatDate } from "../../../utils/date";

type propTypes = {
  log: {
    createdat: string,
    updatedat: string,
    status: string,
    type: string,
    aws: string
  }
};

const DatasourceLogItem = ({ log }: propTypes) => {
  let statusClassName = "";
  switch (log.status) {
    case "fail":
    case "ROLLBACK_COMPLETE":
    case "ROLLBACK_FAILED":
    case "ROLLBACK_IN_PROGRESS":
    case "DELETE_FAILED":
    case "DELETE_COMPLETED":
    case "DELETE_IN_PROGRESS":
      statusClassName = "badge-log badge-log-danger";
      break;
    case "success":
    case "CREATE_COMPLETE":
      statusClassName = "badge-log badge-log-success";
      break;
    default:
      statusClassName = "badge-log badge-log-primary";
  }

  return (
    <div className={"mt-1 border log-container"}>
      <div>
        <div className={statusClassName}>{log.status}</div>
      </div>
      <div className="log-details">
        <p>
          <i className="pr-2 fas fa-list-alt"></i>
          <span className="label-display">TYPE : </span>
          <strong>{log.type}</strong>
        </p>
        <p>
          <i className="pr-2 fab fa-aws" />
          <span className="label-display">ACCOUNT : </span>
          {log.aws}
        </p>
      </div>
      <div className="date-log-container">
        <p className="date-log">{formatDate(log.createdat)}</p>
      </div>
    </div>
  );
};

export default DatasourceLogItem;
