export const getRedshiftClusterProjectDetails = {
  query: `query Q ($cartUri:String!) {
        getCart (cartUri:$cartUri) {
            uri
            groupuri
            name
            region
            description
            resource_name
            createdat
            database
        }
      }
    `,
  keyOutput: "getCart"
};
