/* @flow */
import React from "react";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import DatasetsForm from "../datasetsForm";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";

const Log = Logger("DetectedPrefixes");

type propTypes = {
  uriDatasource: string,
  setPrefixes: Function,
  prefixes: Array<Object>,
  myRef: Object,
  onPopulate: Function,
  editDataset: Object,
  api: GraphQl
};

type stateTypes = {
  detectPrefixes: number,
  detectDatasetReady: boolean,
  detectDatasetError?: Object,
  dataset: Object,
  isAllSeleted: boolean
};

class DetectedPrefixes extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      detectPrefixes: 0,
      detectDatasetReady: false,
      detectDatasetError: undefined,
      isAllSeleted: false,
      dataset: {
        description: "",
        type: "structured",
        basic_metadata: true,
        metadata_frequency: "never",
        maturity_level: 0,
        external_description_url: "",
        license: "",
        contact: "",
        shareable: true,
        is_registered_with_lakeformation: false,
        visibility: "Public",
        data_types: []
      }
    };
  }

  componentDidMount() {
    this.detectDataset();
    this.isDatasourceRegisteredWithLakeFormation();
  }

  detectDataset = () =>
    this.props.api.datasource
      .detectDatasets(this.props.uriDatasource)
      .then((prefixesFound) => {
        this.setState({
          detectPrefixes: prefixesFound.length,
          detectDatasetReady: true
        });
        this.props.setPrefixes(
          prefixesFound.map((p) => ({ value: p, isSelected: false }))
        );
      })
      .catch((detectDatasetError) => {
        Log.error(detectDatasetError);
        this.setState({
          detectDatasetError,
          detectDatasetReady: true
        });
      });

  isDatasourceRegisteredWithLakeFormation = () =>
    this.props.api.datasource
      .isRegisteredWithLakeFormation(this.props.uriDatasource)
      .then((datasource) => {
        this.setState((prevState) => {
          const dataset = Object.assign({}, prevState.dataset);
          dataset.is_registered_with_lakeformation =
            datasource.is_registered_with_lakeformation;
          dataset.datasourceRegisteredWithLakeFormation =
            datasource.is_registered_with_lakeformation;
          return { dataset };
        });
      })
      .catch((error) => {
        this.setState({
          detectDatasetError: error
        });
      });

  componentWillReceiveProps(nextProps: propTypes) {
    if (
      nextProps.editDataset &&
      (!this.props.editDataset ||
        nextProps.editDataset.prefix !== this.props.editDataset.prefix)
    ) {
      this.setState({
        dataset: nextProps.editDataset,
        isAllSeleted: false
      });
    }
  }

  render() {
    return (
      <div className="container datatourse-detect-form-container">
        <div className="datatourse-detect-form">
          <label className="label-form title-s">Detected prefix(es)</label>
          {!this.state.detectDatasetReady && (
            <Loading message={"dataset detection"} />
          )}
          {this.state.detectDatasetError && (
            <Error
              error={this.state.detectDatasetError}
              path={"DatasourceDetectNewDatasets"}
            />
          )}
          {this.props.prefixes &&
            this.state.detectDatasetReady &&
            !this.state.detectDatasetError && (
              <div>
                <p>We find {this.state.detectPrefixes} potential datasets.</p>
                {this.props.prefixes.length > 0 && (
                  <div>
                    <p>
                      Select prefix and fill the form (You can fill several
                      prefixes at the same time)
                    </p>

                    {this.props.prefixes.length > 1 && (
                      <div className="prefixes-all-checkbox">
                        <input
                          id="all"
                          type="checkbox"
                          className={"checkbox-facet"}
                          checked={this.state.isAllSeleted}
                          onClick={() => {
                            this.props.setPrefixes(
                              this.props.prefixes.map((p) => ({
                                value: p.value,
                                isSelected: !this.state.isAllSeleted
                              }))
                            );
                            this.setState((prevState) => ({
                              isAllSeleted: !prevState.isAllSeleted
                            }));
                          }}
                        />
                        <label htmlFor="all" className="fas">
                          <span className="labelCustom">
                            Select all prefixes
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                )}

                <div className="mb-2 prefixes-checkbox">
                  {this.props.prefixes.map((prefix) => (
                    <div className="prefix-checkbox" key={prefix.value}>
                      <input
                        id={prefix.value}
                        type="checkbox"
                        className={"checkbox-facet"}
                        checked={prefix.isSelected}
                        onClick={() => {
                          const prefixes = this.props.prefixes.map((p) =>
                            p.value !== prefix.value
                              ? p
                              : {
                                  value: p.value,
                                  isSelected: !prefix.isSelected
                                }
                          );
                          this.setState({
                            isAllSeleted:
                              prefixes.filter((p) => !p.isSelected).length === 0
                          });
                          this.props.setPrefixes(prefixes);
                        }}
                      />
                      <label htmlFor={prefix.value} className="fas">
                        <span className="labelCustom">{prefix.value}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <div ref={this.props.myRef}>
                  {this.props.prefixes.filter((p) => p.isSelected).length >
                    0 && (
                    <DatasetsForm
                      dataset={this.state.dataset}
                      submit={() => this.props.onPopulate(this.state.dataset)}
                      errorSubmit={undefined}
                      handleChange={(field, value) => {
                        this.setState((prevState) => ({
                          dataset: {
                            ...prevState.dataset,
                            [field]: value
                          }
                        }));
                      }}
                      displayName={
                        this.props.prefixes.filter((p) => p.isSelected)
                          .length === 1
                      }
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default DetectedPrefixes;
