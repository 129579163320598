import React from "react";

function removeAllStyles() {
  document
    .querySelectorAll('link[rel="stylesheet"]')
    .forEach((el) => el.parentNode.removeChild(el));
  document
    .querySelectorAll("style")
    .forEach((el) => el.parentNode.removeChild(el));
}

function loadV2() {
  removeAllStyles();
  const appNode = document.querySelector("#app");
  // console.log(appNode.umount()) // unmountComponentAtNode
  document.body.removeChild(appNode);
  const newAppNode = document.createElement("div");
  newAppNode.setAttribute("id", "root");
  document.body.appendChild(newAppNode);
  fetch(`/v2/index.html.txt?kill-cache=${Math.random()}`).then((res) => {
    res.text().then((html) => {
      const xml = new DOMParser().parseFromString(html, "text/html");

      // steal auth
      const {
        idToken: { idToken: token }
      } = JSON.parse(localStorage.getItem("okta-token-storage"));
      localStorage.setItem("okta-auth-token", token);

      // reset router
      window.onpopstate = undefined;

      xml.querySelectorAll("head link").forEach((link) => {
        const linkElement = document.createElement("link");
        linkElement.href = link.href;
        linkElement.rel = "stylesheet";
        document.head.appendChild(linkElement);
      });
      xml.querySelectorAll("script").forEach((script) => {
        const scriptElement = document.createElement("script");
        scriptElement.src = script.src;
        document.body.appendChild(scriptElement);
      });
    });
  });
}

export default () => {
  return (
    <div onClick={loadV2} className="butn butn-small">
      Load v2
    </div>
  );
};
