export const queryGetDatasetCrawlerStatus = {
  query: `
  query Q ($datasetUri: String!, $prefix: String!) {
    getCrawlerStatus(datasetUri: $datasetUri, prefix:$prefix) {
        status
        details
        error
        prefix
        updatedat
    }
  }
`,
  keyOutput: "getCrawlerStatus"
};
