/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import { updateMessage } from "../../globalNotifications/actions";
import Col from "../../../components/col";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Routes from "../../routes";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import "./style.less";
import SelectInfinite from "../../../components/SelectInfinite";

const Log = Logger("StageEdit");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      datasetUri: string,
      id: string
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  error: Object,
  errorSubmit: Object,
  stage: Object,
  initialDataFilters: Array<{ value: string, label: string }>,
  totalDataFilters: number,
  selectedDataFilterId: any,
  showDataFilters: boolean
};

class StageEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      errorSubmit: undefined,
      stage: {
        id: "",
        dataset: {
          uri: ""
        },
        name: "",
        description: "",
        type: ""
      },
      initialDataFilters: [],
      totalDataFilters: 0,
      selectedDataFilterId: null,
      showDataFilters: false
    };
  }

  componentDidMount() {
    this.getStage();
    this.loadDataFilters();
  }

  getStage = () =>
    this.props.api.dataset
      .getStage(this.props.match.params.id, this.props.match.params.datasetUri)
      .then((stage) => {
        this.setState({
          stage,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });

  isDataFiltersFeatureEnabled(datasetUri) {
    const accountUri = `uri:account:${datasetUri.split(":")[2]}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATA_FILTERS", accountUri)
      .then((showDataFilters) => {
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const stage = Object.assign({}, prevState.stage);
      stage[field] = value;
      return { stage };
    });
  };

  save = (setSubmitting) => {
    const input = {
      name: this.state.stage.name,
      type: this.state.stage.type,
      description: this.state.stage.description,
      preview_data_filter_id: this.state.selectedDataFilterId
    };
    return this.props.api.dataset
      .updateStage(
        this.state.stage.id,
        input,
        this.props.match.params.datasetUri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "stage updated",
          type: "success"
        });
        setSubmitting(false);
        return this.props.goTo(
          {
            route: Routes.Stage.View,
            params: {
              outputId: this.state.stage.id,
              datasetUri: this.state.stage.dataset.uri
            }
          },
          "replace"
        );
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.props.showGlobalNotification({
          message: "stage updated fail",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({ errorSubmit });
      });
  };

  loadDataFilters = (offset = 0) =>
    this.props.api.dataset
      .listDataFilters(
        this.props.match.params.datasetUri,
        this.props.match.params.id,
        {
          offset,
          limit: 10000
        }
      )
      .then((response) => {
        this.setState({ totalDataFilters: response.total });
        return response.results.map((g) => ({
          label: g.name,
          value: g.id
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"stage-edit"} />;
    if (!this.state.ready)
      return <Loading className={"my-3"} message={"stage"} />;
    return (
      <div className="stage-edit">
        <Breadcrumb view={"Update stage"} />

        <Formik
          initialValues={{}}
          validate={() => {
            Log.info("validate", this.state.stage);
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.save(setSubmitting);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form className="stage-edit-form">
              {this.state.errorSubmit && (
                <Error
                  error={this.state.errorSubmit}
                  displayError
                  path={"StageEdit"}
                  stringOnly
                />
              )}

              <fieldset className="form-group">
                <legend className="label-form">name</legend>
                <Field
                  onChange={(event) =>
                    this.handleChange("name", event.target.value)
                  }
                  value={this.state.stage.name}
                  type="name"
                  name="name"
                  className="form-control bg-white"
                  placeholder="Dataset name..."
                />
                {errors.name && <div className="error-msg">{errors.name}</div>}
              </fieldset>

              <fieldset className="form-group mb-3">
                <legend className="label-form">description</legend>
                <Field
                  onChange={(event) =>
                    this.handleChange("description", event.target.value)
                  }
                  value={this.state.stage.description}
                  type="description"
                  name="description"
                  className="form-control bg-white"
                  placeholder="datasource description..."
                />
                {errors.description && (
                  <Col size={12} style={{ color: "red" }}>
                    {errors.description}
                  </Col>
                )}
              </fieldset>

              <fieldset className="form-group mb-3">
                <legend className="label-form">type</legend>
                <Field
                  onChange={(event) =>
                    this.handleChange("type", event.target.value)
                  }
                  value={this.state.stage.type}
                  type="type"
                  name="type"
                  className="form-control bg-white"
                  placeholder="Type..."
                />
                {errors.type && (
                  <Col size={12} style={{ color: "red" }}>
                    {errors.type}
                  </Col>
                )}
              </fieldset>

              {this.state.showDataFilters && (
                <div className="form-group">
                  <legend className="label-form">Data Filter</legend>
                  <SelectInfinite
                    placeholder={"Select Data Filter"}
                    initialOptions={this.state.initialDataFilters}
                    totalOptions={this.state.totalDataFilters}
                    loadMoreOptions={this.loadDataFilters}
                    onSelectOption={(value) =>
                      this.setState({
                        selectedDataFilterId: value
                      })
                    }
                  />
                </div>
              )}

              <div className={"ml-8"}>
                <button type="submit" disabled={isSubmitting} className="butn">
                  {isSubmitting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  {"Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(StageEdit));
