export const haveSpecialCharacters = (text) => {
  const pattern = /[&'_;/]/;
  if (pattern.test(text)) {
    return true;
  }
  return false;
};

export const haveSpecialCharactersCDH = (text) => {
  const pattern = /[&';/]/;
  if (pattern.test(text)) {
    return true;
  }
  return false;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
