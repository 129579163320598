/* @flow */
import React from "react";
import "../view.less";
import { connect } from "react-redux";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import GraphQl from "../../../../graphQL";
import withGoTo from "../../../goToHOC";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import DeleteObjectWithFrictionModal from "../../../../components/DeleteObjectWithFrictionModal";
import "./style.less";
import PowerBiLogo from "../../../logos/powerbi-logo.svg";
import RSLogo from "../../../logos/redshift-logo.png";
import logoRedshift from "../../../logos/redshift-logo.png";
import Links from "../../../links";
import Stringslider from "../../../../components/stringslider";
import RSDatasourceCredentials from "../../../datasources/RSDatasource/view/RSDatasourceCredentials";
import Modal from "../../../../components/modal";

type propTypes = {
  cart: Object,
  datasource: Object,
  api: GraphQl,
  reloadDatasources: Function,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  errorConsoleAccess?: Object,
  openDeleteModal: boolean,
  errorDelete: Object,
  status: string,
  error: Object,
  refreshing: boolean,
  isAttachingToPowerBi: boolean,
  isDetachingFromPowerBi: boolean,
  autorefreshing: boolean,
  openRSDatasourceAccess: boolean,
  isOpeningSqlWorkbench: boolean
};

class CartRSDatasource extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      openDeleteModal: false,
      errorDelete: null,
      status: "pending",
      error: null,
      refreshing: false,
      isAttachingToPowerBi: false,
      isDetachingFromPowerBi: false,
      autorefreshing: false,
      openRSDatasourceAccess: false,
      isOpeningSqlWorkbench: false
    };
  }

  componentDidMount() {
    if (this.state.status === "pending") this.autoRefresh();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  attachToPowerBi = () => {
    this.setState({
      isAttachingToPowerBi: true
    });
    this.props.api.cart
      .attachRSDatasourceToPowerBi(
        this.props.cart.uri,
        this.props.datasource.uri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Datasource is being attached to PowerBI. It may take a few seconds",
          type: "success"
        });
        this.setState({
          isAttachingToPowerBi: false
        });
        this.refreshStatus();
      })
      .catch((error) => {
        this.setState({
          error,
          isAttachingToPowerBi: false
        });
      });
  };

  detachFromPowerBi = () => {
    this.setState({
      isDetachingFromPowerBi: true
    });
    this.props.api.cart
      .detachRSDatasourceFromPowerBi(
        this.props.cart.uri,
        this.props.datasource.uri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "Datasource is being detached from PowerBI. It may take a few seconds",
          type: "success"
        });
        this.setState({
          isDetachingFromPowerBi: false
        });
        this.refreshStatus();
      })
      .catch((error) => {
        this.setState({
          isDetachingFromPowerBi: false,
          error
        });
      });
  };

  removeDatasource = () => {
    this.props.api.cart
      .removeRSDatasourceFromProject(
        this.props.cart.uri,
        this.props.datasource.uri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Datasource removed from project",
          type: "success"
        });
        this.setState({ openDeleteModal: false });
        this.props.reloadDatasources();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({
        refreshing: true,
        error: null
      });
      this.props.api.cart
        .getRSDatasourcePowerBiStatus(
          this.props.cart.uri,
          this.props.datasource.uri
        )
        .then((response) => {
          this.setState({
            status: response,
            refreshing: false,
            autorefreshing: false
          });
        })
        .catch((error) => {
          this.setState({
            refreshing: false,
            autorefreshing: false,
            error
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        });
    }
  };

  openSqlWorkbench = () => {
    this.setState({ isOpeningSqlWorkbench: true });
    this.props.api.datasource
      .openRSDatasourceSqlWorkbench(
        this.props.datasource.uri,
        this.props.cart.uri
      )
      .then((url) => {
        this.setState({ isOpeningSqlWorkbench: false });
        this.props.showGlobalNotification({
          message: "Redirecting to Redshift Serverless SQLWorkbench...",
          type: "success"
        });
        window.open(url, "_blank");
      })
      .catch((error) => {
        this.setState({ isOpeningSqlWorkbench: false });
        this.props.showGlobalNotification({
          message: error.message,
          type: "alert"
        });
      });
  };

  render() {
    const { datasource } = this.props;

    return (
      <li className="cdh-card card-shadow card bg-white card-sagemaker-details">
        <div className="card-datasource-header">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <img
                className="connector-logo-img"
                src={RSLogo}
                alt="Redshift Serverless"
                width={18}
                height={18}
              />
              <span className="text-header-datasource">
                Redshift Serverless
              </span>
            </div>
          </div>
          <div className="card-datasource-end">
            <i className="fas fa-user-friends" />
            <Links.Group.View
              uriGroup={this.props.datasource.owner.uri}
              state={{ group: this.props.datasource.owner }}
            >
              <span className="text-header-datasource-lowercase">
                {this.props.datasource.owner.name.substring(0, 50)}
              </span>
            </Links.Group.View>
          </div>
        </div>
        <div className="card-datasource-body">
          <div className="card-datasource-body-left">
            <div className={"text-capitalize datasource-card-name"}>
              <Links.Datasource.View
                uriAccount={this.props.datasource.accountid}
                uriDatasource={this.props.datasource.uri}
              >
                <Stringslider
                  s={this.props.datasource.name}
                  height={30}
                  width={275}
                />
              </Links.Datasource.View>
            </div>
            <div className="card-datasource-details">
              <p className="card-cart-description">
                {this.props.datasource.description &&
                this.props.datasource.description.length > 80
                  ? `${this.props.datasource.description.substring(0, 80)}...`
                  : this.props.datasource.description}
              </p>
              <div className="card-cart-role">
                <i className="fas fa-database mr-1" />
                <span className="content-card-bucket">
                  {this.props.datasource.database}
                </span>
              </div>
              <div className="card-cart-role">
                <i className="fas fa-globe-europe mr-1"></i>
                <span className="content-card-bucket">
                  {this.props.datasource.region}
                </span>
              </div>
              <div className="card-cart-role">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  {this.props.datasource.aws}
                </span>
              </div>
            </div>
          </div>
          <div className="card-datasource-body-right">
            <div className="card-datasource-body-actions">
              <div>
                <span className="cursorPointer" onClick={this.openSqlWorkbench}>
                  {this.state.isOpeningSqlWorkbench && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-play mb-1 ml-1" />
                  <span className="text-actions">Open SQLWorkbench</span>
                </span>
              </div>
              {this.state.status !== "attached" && (
                <div>
                  {this.state.isAttachingToPowerBi && (
                    <i className="fas fa-sync fa-spin fa-spacing" />
                  )}
                  <div className="btn-cart" onClick={this.attachToPowerBi}>
                    <img
                      className="connector-logo-img"
                      src={PowerBiLogo}
                      alt="Redshift Serverless"
                      width={17}
                      height={17}
                    />
                    <span className="text-actions">Attach to PowerBI</span>
                  </div>
                </div>
              )}
              {this.state.status === "attached" && (
                <div>
                  <div className="btn-cart" onClick={this.detachFromPowerBi}>
                    {this.state.isDetachingFromPowerBi && (
                      <i className="fas fa-sync fa-spin fa-spacing" />
                    )}
                    <img
                      className="connector-logo-img"
                      src={PowerBiLogo}
                      alt="Redshift Serverless"
                      width={17}
                      height={17}
                    />
                    <span className="text-actions">Detach from PowerBI</span>
                  </div>
                </div>
              )}
              <div>
                <div
                  className="btn-cart"
                  onClick={() =>
                    this.setState({ openRSDatasourceAccess: true })
                  }
                >
                  <i className="fas fa-link mb-1 ml-1" />
                  <span className="text-actions">Connection Properties</span>
                </div>
              </div>
              <div>
                <span
                  className="cursorPointer"
                  onClick={() => this.setState({ openDeleteModal: true })}
                >
                  <i className="fas fa-trash mb-1 ml-1" />
                  <span className="text-actions">Remove from project</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-datasource-footer">
          <div className="card-datasource-begin">
            <div className="text-footer-metrics">
              Contains{" "}
              <span className="tag-metrics">
                {datasource.datasetsList.total}
              </span>{" "}
              datasets
            </div>
          </div>
          <div className="card-datasource-end">
            <div className="cdh-card-end">
              <button
                type="button"
                className="button-refresh-status butn butn-flat"
                onClick={() => this.refreshStatus()}
              >
                {this.state.refreshing || this.state.autorefreshing ? (
                  <i className="fas fa-sync-alt fa-spin" />
                ) : (
                  <i className="fas fa-sync-alt" />
                )}
              </button>
              <div className="text-footer-metrics">
                PowerBI Status
                <span
                  className={classnames("tag-metrics", {
                    "green-status": ["attached"].includes(this.state.status),
                    "warning-status": this.state.status === "pending",
                    "error-status": ["failed-to-attach"].includes(
                      this.state.status
                    )
                  })}
                >
                  {this.state.status}
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={`${datasource.name}`}
            deleteMessage={"Remove datasource from project ?"}
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => this.setState({ openDeleteModal: false })}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.removeDatasource}
            isAWSResource={false}
          />
        )}
        {this.state.openRSDatasourceAccess && (
          <Modal
            title={
              <span>
                <img
                  className="connector-logo-img mr-2"
                  src={logoRedshift}
                  alt="Redshift Serverless"
                  width={35}
                  height={35}
                />
                Connection Properties
              </span>
            }
            body={
              <RSDatasourceCredentials
                rsdatasource={this.props.datasource.RSDatasource}
              />
            }
            actions={[
              <button
                key={"group-close"}
                type="button"
                className="butn"
                onClick={() => this.setState({ openRSDatasourceAccess: false })}
              >
                Close
              </button>
            ]}
          />
        )}
        {(this.state.error || this.state.errorDelete) && (
          <div className="IAM-error">
            <Alert severity="error">
              {this.state.error.message || this.state.errorDelete}
            </Alert>
          </div>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartRSDatasource)));
