export const isRegisteredWithLakeFormation = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      is_registered_with_lakeformation
    }
  }
`,
  keyOutput: "getDatasource"
};
