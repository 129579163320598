export const publishDatasetStages = {
  query: `
        mutation Q (
            $datasetUri: String!,
            $stageInputs: [StageCreateInput]
        ) 
        {
            publishDatasetStages(
                datasetUri: $datasetUri, stageInputs: $stageInputs
            ){
                id
                name
                table
            }
        }
    `,
  keyOutput: "publishDatasetStages"
};
