/* @flow */

export const createTrust = {
  query: `mutation Q ($accountUri: String!, $input: NewTrustInput!) {
        createTrust (accountUri: $accountUri, input: $input) {
            arn
        }
    }
    `,
  keyOutput: "createTrust"
};
