/* @flow */
import React from "react";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import CopyToClipboard from "../../components/copyToClipboard";
import Breadcrumb from "../../components/breadcrumb";
import "../../utils.less";
import GraphQl from "../../graphQL";
import "./connectionProperties.less";
import config from "../../config";

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      cartUri: string,
      uriPlayground: string
    }
  },
  location: {
    state: Object
  }
};

type stateTypes = {
  ready: boolean,
  connectionProperties: Object,
  authTypes: Array<Object>,
  proxyOptions: Array<Object>,
  formOptions: Object,
  error: boolean | Object,
  fileDownloadUrl: string,
  file_name: string
};
class CartConnectionProperties extends React.Component<propTypes, stateTypes> {
  dofileDownload;

  downloadRegFile;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      connectionProperties: {},
      fileDownloadUrl: "",
      authTypes: [
        { key: "", value: "Choose an authentication type" },
        { key: "iamUser", value: "IAM Credentials" }
      ],
      proxyOptions: [
        { key: "", value: "Choose a proxy option" },
        { key: "oneWifi", value: "ONE Wifi", host: "127.0.0.1", port: "9000" }
      ],
      error: false,
      formOptions: {
        authType: "iamUser",
        proxy: ""
      },
      file_name: ""
    };
    this.downloadRegFile = this.downloadRegFile.bind(this);
  }

  componentDidMount() {
    this.props.api.cart
      .getConnectionProperties(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground
      )
      .then((response) => {
        if (!response) {
          this.setState({
            error: { message: "Connection properties not found" }
          });
        }

        this.setState({
          ready: true,
          connectionProperties: response,
          fileDownloadUrl: "",
          file_name: response.datasourceName
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  downloadRegFile(event) {
    event.preventDefault();
    const regFile = {
      accessKeyId: this.state.connectionProperties.accessKeyId,
      athenaOutputLocation:
        this.state.connectionProperties.athenaOutputLocation,
      athenaWorkGroupName: this.state.connectionProperties.athenaWorkGroupName,
      datasourceName: this.state.connectionProperties.datasourceName,
      roleArn: this.state.connectionProperties.roleArn,
      region: this.state.connectionProperties.region,
      s3EncryptionOption: this.state.connectionProperties.s3EncryptionOption,
      secretKey: this.state.connectionProperties.secretKey,
      token: this.state.connectionProperties.token,
      driver: this.state.connectionProperties.driver,
      authenticationType: this.state.connectionProperties.authenticationType,
      awsProfile: this.state.connectionProperties.awsProfile,
      awsRegion: this.state.connectionProperties.awsRegion,
      binaryColumnLength: this.state.connectionProperties.binaryColumnLength,
      catalog: this.state.connectionProperties.catalog,
      complexTypeColumnLength:
        this.state.connectionProperties.complexTypeColumnLength,
      description: this.state.connectionProperties.description,
      PWD: this.state.connectionProperties.secretKey,
      encryptedPWD: this.state.connectionProperties.encryptedPWD,
      EndpointOverride: this.state.connectionProperties.endpointOverride,
      idp_port: this.state.connectionProperties.idp_port,
      maxCatalogNameLen: this.state.connectionProperties.maxCatalogNameLen,
      maxCatalogNameLength:
        this.state.connectionProperties.maxCatalogNameLength,
      maxColumnNameLen: this.state.connectionProperties.maxColumnNameLen,
      maxColumnNameLength: this.state.connectionProperties.maxColumnNameLength,
      maxSchemaNameLen: this.state.connectionProperties.maxSchemaNameLen,
      maxSchemaNameLength: this.state.connectionProperties.maxSchemaNameLength,
      maxTableNameLen: this.state.connectionProperties.maxTableNameLen,
      maxTableNameLength: this.state.connectionProperties.maxTableNameLength,
      MetadataRetrievalMethod:
        this.state.connectionProperties.metadataRetrievalMethod,
      rowsToFetchPerBlock: this.state.connectionProperties.rowsToFetchPerBlock,
      s3OutputEncKMSKey: this.state.connectionProperties.s3OutputEncKMSKey,
      s3OutputEncOption: this.state.connectionProperties.s3OutputEncOption,
      s3OutputLocation: this.state.connectionProperties.s3OutputLocation,
      schema: this.state.connectionProperties.schema,
      sessionToken: this.state.connectionProperties.sessionToken,
      StreamingEndpointOverride:
        this.state.connectionProperties.streamingEndpointOverride,
      ssl_insecure: this.state.connectionProperties.ssl_insecure,
      stringColumnLength: this.state.connectionProperties.stringColumnLength,
      uID: this.state.connectionProperties.uID,
      useProxy: this.state.connectionProperties.useProxy,
      useProxyForIdP: this.state.connectionProperties.useProxyForIdP,
      proxyOptions: this.state.formOptions.proxy,
      proxyHost: this.state.connectionProperties.proxyHost,
      proxyPort: this.state.connectionProperties.proxyPort,
      useResultsetStreaming:
        this.state.connectionProperties.useResultsetStreaming,
      useSQLUnicodeTypes: this.state.connectionProperties.useSQLUnicodeTypes,
      workgroup: this.state.connectionProperties.workgroup
    };

    let output = "";
    output += "Windows Registry Editor Version 5.00 \n\n";
    // eslint-disable-next-line no-useless-escape
    output += `[HKEY_CURRENT_USER\\SOFTWARE\\ODBC\\ODBC.INI\\${this.state.connectionProperties.datasourceName}] \n`;

    for (const propertiesName in regFile) {
      if (typeof regFile[propertiesName] === "string") {
        const toUpperCasePropsName =
          propertiesName.charAt(0).toUpperCase() + propertiesName.slice(1);
        output += `"${toUpperCasePropsName}"="${regFile[propertiesName]}"\n`;
      }
    }
    output += "\n";
    output +=
      "[HKEY_CURRENT_USER\\SOFTWARE\\ODBC\\ODBC.INI\\ODBC Data Sources] \n";
    output += `"${this.state.connectionProperties.datasourceName}"="Simba Athena ODBC Driver"`;

    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState({ fileDownloadUrl }, () => {
      if (this.dofileDownload !== null) {
        this.dofileDownload.click();
      }
      URL.revokeObjectURL(fileDownloadUrl);
      this.setState({ fileDownloadUrl: "" });
    });
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const formOptions = Object.assign({}, prevState.formOptions);
      formOptions[field] = value;
      return { formOptions };
    });
  }

  setUseProxyState() {
    this.state.connectionProperties.useProxy = "1";
  }

  getProxyProperties(value) {
    const proxy = this.state.proxyOptions.filter((e) => e.key == value)[0];
    this.state.connectionProperties.proxyHost = proxy.host;
    this.state.connectionProperties.proxyPort = proxy.port;
  }

  static athenaDocumentation() {
    window.open(
      "https://docs.aws.amazon.com/athena/latest/ug/athena-bi-tools-jdbc-odbc.html",
      "_blank"
    );
  }

  render() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}${month}${day}_${hours}${minutes}${seconds}${milliseconds}`;

    if (this.state.error) {
      return (
        <Error error={this.state.error} path={"CartConnectionProperties"} />
      );
    }
    if (!this.state.ready) return <Loading message={"Connection Properties"} />;
    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={" Athena Connection Properties"}
          helpOn={{
            content: (
              <div>
                <div>Fore more info about Amazon Athena Connections : </div>
                <a
                  href={
                    "https://docs.aws.amazon.com/athena/latest/ug/athena-bi-tools-jdbc-odbc.html"
                  }
                  target={"_black"}
                >
                  Connecting to Amazon Athena using ODBC and JDBC drivers
                </a>
              </div>
            ),
            helpCenterLink: `${config.HELP_CENTER}`
          }}
        />
        <div className="connections-form">
          <div className="butn float-left" onClick={this.downloadRegFile}>
            Download connection file
          </div>
          <a
            style={{ display: "none" }}
            download={`${this.state.file_name}-${formattedDate}.reg`}
            href={this.state.fileDownloadUrl}
            // eslint-disable-next-line no-return-assign
            ref={(e) => (this.dofileDownload = e)}
          >
            download it
          </a>
          <div
            className="butn float-right"
            onClick={CartConnectionProperties.athenaDocumentation}
          >
            Connecting to Amazon Athena using ODBC and JDBC drivers
          </div>
          <div className="form-container">
            <div className="form-group">
              <label className="label-form">Data source name (DSN)</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Datasource Name"
                  value={this.state.connectionProperties.datasourceName}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.datasourceName}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Aws region</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Datasource Name"
                  value={this.state.connectionProperties.awsRegion}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.awsRegion}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Athena work group</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Name"
                  value={this.state.connectionProperties.workgroup}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.workgroup}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">s3 output location</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Name"
                  value={this.state.connectionProperties.s3OutputLocation}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.s3OutputLocation}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Output Encryption Options</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Datasource Name"
                  value={this.state.connectionProperties.s3OutputEncOption}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.s3OutputEncOption}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Authentication Type</label>
              <div className="input-group">
                <select
                  onChange={(event) =>
                    this.handleChange("authType", event.target.value)
                  }
                  className="custom-select"
                  value={this.state.formOptions.authType}
                >
                  {this.state.authTypes.map((item) => (
                    <option key={item.key} value={item.key}>
                      {`${item.value} `}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.formOptions.authType === "iamUser" && (
              <div>
                <div className="form-group">
                  <label className="label-form">User (aws_access_key_id)</label>
                  <div className="input-group">
                    <input
                      disabled
                      name="name"
                      className="form-control form-control-sm"
                      placeholder="Name"
                      value={this.state.connectionProperties.uID}
                    />
                    <CopyToClipboard
                      text={this.state.connectionProperties.uID}
                      className={"fas fa-copy btn-carre"}
                      label={""}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-form">
                    Password (aws_secret_access_key)
                  </label>
                  <div className="input-group">
                    <input
                      disabled
                      name="name"
                      className="form-control form-control-sm"
                      placeholder="Name"
                      value={this.state.connectionProperties.secretKey}
                    />
                    <CopyToClipboard
                      text={this.state.connectionProperties.secretKey}
                      className={"fas fa-copy btn-carre"}
                      label={""}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-form">
                    Session Token (aws_session_token)
                  </label>
                  <div className="input-group">
                    <textarea
                      disabled
                      name="name"
                      className="form-control form-control-sm"
                      placeholder="Name"
                      value={this.state.connectionProperties.token}
                      rows="5"
                    />
                    <CopyToClipboard
                      text={this.state.connectionProperties.token}
                      className={"fas fa-copy btn-carre"}
                      style={{ marginTop: "10px" }}
                      label={""}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="label-form">Proxy Options</label>
              <small className="attribute-label">
                {" (If you are using a Skynote laptop in the Engie network (ONE WIFI) **you must configure " +
                  "Proxy Options by typing the zcaler proxy host and port**)"}
              </small>
              <div className="input-group">
                <select
                  onChange={(event) => {
                    this.handleChange("proxy", event.target.value);
                    this.getProxyProperties(event.target.value);
                    this.setUseProxyState();
                  }}
                  className="custom-select"
                  value={this.state.formOptions.proxy}
                >
                  {this.state.proxyOptions.map((proxy) => (
                    <option key={proxy.key} value={proxy.key}>
                      {`${proxy.value} `}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.formOptions.proxy === "oneWifi" && (
              <div>
                <div className="form-group">
                  <label className="label-form">Proxy host</label>
                  <div className="input-group">
                    <input
                      disabled
                      name="proxyHost"
                      className="form-control form-control-sm"
                      placeholder="Proxy host"
                      value={this.state.connectionProperties.proxyHost}
                    />
                    <CopyToClipboard
                      text={this.state.connectionProperties.proxyHost}
                      className={"fas fa-copy btn-carre"}
                      label={""}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-form">Proxy port</label>
                  <div className="input-group">
                    <input
                      disabled
                      name="proxyPort"
                      className="form-control form-control-sm"
                      placeholder="Proxy port"
                      value={this.state.connectionProperties.proxyPort}
                    />
                    <CopyToClipboard
                      text={this.state.connectionProperties.proxyPort}
                      className={"fas fa-copy btn-carre"}
                      label={""}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="label-form">Project role ARN</label>
              <div className="input-group">
                <input
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Name"
                  value={this.state.connectionProperties.roleArn}
                />
                <CopyToClipboard
                  text={this.state.connectionProperties.roleArn}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAppSync(CartConnectionProperties);
