export const countGroupCreatedPerDay = {
  query: `query Q ($accountUri: String!) {
        countGroupCreatedPerDay (accountUri: $accountUri) {
            createdat
            count
        }
      }
    `,
  keyOutput: "countGroupCreatedPerDay"
};
