export const getInfoFromGid = {
  query: `query Q ($gid:String!) {
        getInfoFromGid (gid:$gid) {
            gid
            accounts { uri name createdat }
            datasources { uri name owneruri sharing_group_uri createdat }
            projects { uri name groupuri createdat }
            groups { uri name createdat }
            activities { activityid createdat accountid description name target_uri createdat }
            identities { gid email username name createdat }
        }
      }
    `,
  keyOutput: "getInfoFromGid"
};
