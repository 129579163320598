/* @flow */
import React from "react";
import { formatDate } from "../../utils/date";
import { isUserFromCDHTeam } from "../../utils/cdhTeam";
import LabelCDH from "../labelCDH";
import "./style.less";
import { getGidFromUri } from "../../utils/toolsForUri";

type propTypes = {
  user: {
    uri: string,
    name: string,
    added_at: string
  }
};
class LabelMemberGroup extends React.Component<propTypes> {
  render() {
    return (
      <div>
        <div className="label-member">
          <div className="member-info">
            <div className={"member-name"}>{this.props.user.name}</div>
          </div>
          <div>
            {isUserFromCDHTeam(getGidFromUri(this.props.user.uri)) && (
              <LabelCDH />
            )}
          </div>
        </div>
        {this.props.user.added_at && (
          <div className="member-since">
            {`added the ${formatDate(this.props.user.added_at, {
              withHours: false,
              withMinutes: false,
              withSeconds: false
            })}`}
          </div>
        )}
      </div>
    );
  }
}

export default LabelMemberGroup;
