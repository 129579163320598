export const getWorkspacesAssociatedToGroups = {
  query: `query Q ($groupUri: String!) {
              getGroup(groupUri: $groupUri) {
              name
              dbx_groups {
                results {
                  dbxWorkspaces {
                    dbx_workspace_name
                    uuid
                    dbx_workspace_url
                    dbxAccount {
                      dbx_account_name
                      uuid
                    }
                  }
                }
                total
                offset
                limit
              }
            }
          }
        `,
  keyOutput: "getGroup"
};
