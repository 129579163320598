export const getRedshiftClusterUsage = {
  query: `
  query Q ($clusterUri: String!) {
    getRedshiftClusterUsage(clusterUri: $clusterUri) {
      uri
      name
      group{
        uri
        name
      }
    }
  }
`,
  keyOutput: "getRedshiftClusterUsage"
};
