export const getDatasourceDatasetsLight = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      uri
      resource_name
      name
      datasets {
          uri
          accountid
          name
          resource_name
          description
          tags
          doc
          type
          confidentiality
          domain
          refresh_sla
          last_loaded
          details
          createdat
          updatedat
          period_start_at
          period_end_at
          expiration_date
          language
          default_table
          access
          arn
          is_bookmarked
          nb_views
          metric {
            parent
            statistic{
             nb_bookmarks
             nb_carts
             nb_shares
            }
          }
      }
      owner {
          uri
          name
      }
    }
  }
`,
  keyOutput: "getDatasource"
};
