export const queryGetRecentStageProfilingExecution = {
  query: `query Q ($stageId: String!, $datasetUri: String!) {
        getStage(stageId: $stageId, datasetUri: $datasetUri) {
            profiling_execution {
                status
            }
        }
    }`,
  keyOutput: "getStage.profiling_execution.status"
};
