import React from "react";
import PropTypes from "prop-types";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import GraphComponent from "./graphComponent";
import Logger from "../../../utils/logger";

const Log = Logger("DatasetAnalyse");

class DatasetAnalyse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      countDatasetCreatedPerDay: [],
      error: false
    };
  }

  componentDidMount() {
    this.props.api.account
      .countDatasetCreatedPerDay(this.props.uriAccount)
      .then((countDatasetCreatedPerDay) =>
        this.setState({
          ready: true,
          countDatasetCreatedPerDay
        })
      )
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  }

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasetAnalyse"} />;
    if (!this.state.ready) return <Loading message={"Datasets statistics"} />;

    return (
      <div
        style={{
          marginTop: "50px",
          display: "inline-block",
          margin: "5px"
        }}
      >
        <div
          style={{ marginLeft: "10px", fontWeight: 600, marginBottom: "5px" }}
        >
          Statistics, Datasets Creation:
        </div>
        <div
          style={{
            height: "270px",
            width: "400px",
            backgroundColor: "white"
          }}
          className={"shadow"}
        >
          <GraphComponent data={this.state.countDatasetCreatedPerDay} />
        </div>
      </div>
    );
  }
}

DatasetAnalyse.propTypes = {
  api: PropTypes.shape({
    account: PropTypes.shape({
      countDatasetCreatedPerDay: PropTypes.func
    })
  }),
  uriAccount: PropTypes.string
};

export default DatasetAnalyse;
