/* @flow */
import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Col from "../../components/col";
import Row from "../../components/row";
import Loading from "../../components/loading";
import Breadcrumb from "../../components/breadcrumb";
import withAppsync from "../AppsyncHOC";
import Routes from "../routes";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";
import { REGIONS } from "../../utils/constants";
import { haveSpecialCharactersCDH } from "../../utils/string";
import GraphQl from "../../graphQL";
import SelectInfinite from "../../components/SelectInfinite";
import SelectClassic from "../../components/SelectClassic";
import ToggleButton from "../../components/toggleButton";
import HelpOn from "../../components/helpOn";
import TagsDatasourceFields from "../datasources/components/form/TagsDatasourceFields";

const Log = Logger("CartCreate");

type propTypes = {
  api: GraphQl,
  goTo: Function,
  location: {
    state: {
      cart: Object
    }
  },
  showGlobalNotification: Function
};

type stateTypes = {
  isNew: boolean,
  group: Object,
  groups: Array<Object>,
  offsetGroups: number,
  totalGroups: number,
  isDisabled: boolean,
  totalGroupsForSearchLimit: number,
  cart: Object,
  error: Object,
  ready: boolean,
  errorSubmit: Object,
  totalRedshift: number,
  totalDatabrew: number
};

class CartCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isDisabled: false,
      cart: (props.location.state && props.location.state.cart) || {
        region: REGIONS[0].key,
        is_refresh_auto_enabled: true,
        is_database_enabled: true,
        project_custom_tags: []
      },
      isNew: !props.location.state || !props.location.state.cart,
      group: {},
      groups: [],
      error: false,
      errorSubmit: false,
      totalGroups: 0,
      totalGroupsForSearchLimit: 0,
      offsetGroups: 0,
      totalRedshift: 0,
      totalDatabrew: 0
    };
  }

  getDatabrewProjects = () => {
    const options = {
      limit: 4,
      offset: 0,
      search: undefined
    };

    return this.props.api.cart
      .listDatabrewProjects(this.state.cart.uri, options)
      .then((response) => {
        if (!response) {
          return false;
        }
        this.setState(
          {
            totalDatabrew: response.total
          },
          () => {
            this.setState((prevState) => ({
              isDisabled:
                (prevState.totalDatabrew > 0 ||
                  prevState.totalRedshift > 0 ||
                  prevState.cart.is_attached_to_powerbi) &&
                prevState.cart.is_database_enabled
            }));
          }
        );
        // const isDisabled = ;
        // this.setState({ isDisabled });
        // this.setState((prevState) => ({ isDisabled: true }));
        return response.total;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getProjectRedshiftClusters = () =>
    this.props.api.analytics
      .getProjectRedshiftClusters(this.state.cart.uri)
      .then((clusters) => {
        this.setState({ totalRedshift: clusters.length });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  loadMoreGroups = (a, search) => {
    if (search && search.length < 3) {
      return false;
    }
    let queryOptions = {};
    const filters = [];
    filters.push({
      key: "exclude_service_groups",
      value: true
    });
    filters.push({
      key: "exclude_groups_without_env",
      value: true
    });
    if (search) {
      queryOptions = {
        offset: 0,
        limit: this.state.totalGroupsForSearchLimit,
        filters,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        limit: 10,
        filters,
        offset: this.state.offsetGroups
      };
    }
    return this.fetchGroups(queryOptions)
      .then(({ groups }) =>
        groups.map((g) => ({
          value: g.uri,
          label:
            g.account.name === "service"
              ? `${g.name}`
              : `${g.name} (in organization #${g.account.name})`
        }))
      )
      .catch((error) => {
        Log.error(error);
      });
  };

  fetchGroupsTotal = () =>
    this.props.api.identity
      .listMyGroupsForProjectCreationTotal()
      .then((response) => {
        this.setState({
          totalGroups: response.total,
          totalGroupsForSearchLimit: response.total
        });
      })
      .catch((error) => {
        Log.error(error);
      });

  fetchGroups = (queryOptions) =>
    this.props.api.identity
      .listMyGroupsForProjectCreation(queryOptions)
      .then((groupsResponse) => {
        const groups = groupsResponse.results;

        return new Promise((r) => {
          this.setState((prevState) => {
            const totalGroups = prevState.totalGroups || groupsResponse.total;
            const offsetGroups = prevState.offsetGroups + groupsResponse.limit;
            r({
              groups,
              realTotal: groupsResponse.total,
              offsetGroups
            });
            return {
              totalGroups,
              offsetGroups,
              groups
            };
          });
        });
      })
      .catch((error) => {
        Log.error(error);
        return { groups: [] };
      });

  initGroups = () => {
    const filters = [];
    filters.push({
      key: "exclude_service_groups",
      value: true
    });
    filters.push({
      key: "exclude_groups_without_env",
      value: true
    });
    const queryOptions = {
      limit: 10,
      offset: this.state.offsetGroups,
      filters
    };
    this.fetchGroupsTotal();
    this.fetchGroups(queryOptions)
      .then(({ groups, realTotal, offsetGroups }) => {
        if (groups.length <= 0 && realTotal > offsetGroups) {
          return this.initGroups();
        }

        if (groups.length <= 0) {
          return this.setState({
            ready: true
          });
        }

        if (this.state.isNew) {
          const group = groups[0];
          return this.setState((prevState) => ({
            ready: true,
            group,
            cart: {
              ...prevState.cart,
              groupUri: group.uri
            }
          }));
        }

        return this.setState({
          ready: true
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });
  };

  componentDidMount() {
    this.initGroups();
    if (!this.state.isNew) {
      this.getDatabrewProjects();
      this.getProjectRedshiftClusters();
    }
  }

  handleChange = (field, value) =>
    this.setState((prevState) => {
      const cart = Object.assign({}, prevState.cart);
      cart[field] = value;
      if (field === "groupUri") {
        const group = prevState.groups.find((g) => g.uri === value);
        return {
          cart,
          group
        };
      }
      return { cart };
    });

  save = () => {
    Log.info(
      "Saving project for group",
      this.state.cart.groupUri,
      " with name",
      this.state.cart.name
    );
    if (this.state.isNew) {
      return this.props.api.cart.create(this.state.cart.groupUri, {
        name: this.state.cart.name,
        region: this.state.cart.region,
        description: this.state.cart.description,
        is_refresh_auto_enabled: this.state.cart.is_refresh_auto_enabled,
        is_database_enabled: this.state.cart.is_database_enabled,
        project_custom_tags: this.state.cart.project_custom_tags
      });
    }

    return this.props.api.cart.update(this.state.cart.uri, {
      name: this.state.cart.name,
      description: this.state.cart.description,
      is_refresh_auto_enabled: this.state.cart.is_refresh_auto_enabled,
      is_database_enabled: this.state.cart.is_database_enabled,
      project_custom_tags: this.state.cart.project_custom_tags
    });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CartCreate"} />;
    if (!this.state.ready)
      return (
        <Loading
          message={this.state.isNew ? "create Project" : "edit Project"}
        />
      );

    return (
      <React.Fragment>
        {this.state.isNew ? (
          <Breadcrumb view="Create new project" />
        ) : (
          <Breadcrumb view="Edit project" />
        )}

        <Row>
          <Col size={1} />
          <Col className={"my-5"} size={9}>
            <Formik
              initialValues={{}}
              validate={(values) => {
                Log.info("validate", values);
                const errors = {};
                if (!this.state.cart.name)
                  errors.name = "The name is mandatory ";
                if (haveSpecialCharactersCDH(this.state.cart.name))
                  errors.name =
                    "Name must not contain these special characters ( & / ' ; )";

                if (this.state.cart.project_custom_tags) {
                  this.state.cart.project_custom_tags.map((tag) => {
                    if (tag.Key.length > 128 || tag.Value.length > 256) {
                      errors.project_custom_tags =
                        "The tag key must not exceed 128 unicode characters and the tag value must not exceed 256 characters ";
                    }
                    const patternTags = /^[\w.:/=+\-@]*$/;
                    const testKey = patternTags.test(tag.Key);
                    if (!testKey) {
                      errors.project_custom_tags_key_unicode = `The key (${tag.Key}) contains illegal characters. Non-unicode characters are not allowed`;
                    }
                    const testValue = patternTags.test(tag.Value);
                    if (!testValue) {
                      errors.project_custom_tags_value_unicode = `The value (${tag.Value}) contains illegal characters. Non-unicode characters are not allowed`;
                    }
                    return errors;
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) =>
                this.save()
                  .then((cart) => {
                    setSubmitting(false);
                    if (this.state.isNew) {
                      this.props.showGlobalNotification({
                        message: "Project created",
                        type: "success"
                      });
                      if (this.state.cart.is_refresh_auto_enabled) {
                        this.props.showGlobalNotification({
                          message:
                            "Project created, your role project will be automatically refreshed",
                          type: "success"
                        });
                      }
                    } else {
                      this.props.showGlobalNotification({
                        message: "Project updated",
                        type: "success"
                      });
                      if (this.state.cart.is_refresh_auto_enabled) {
                        this.props.showGlobalNotification({
                          message:
                            "Project updated, your role project will be automatically refreshed",
                          type: "success"
                        });
                      }
                    }

                    setSubmitting(false);
                    return this.props.goTo({
                      route: Routes.Cart.View,
                      params: {
                        cartUri: cart.uri
                      }
                    });
                  })
                  .catch((errorSubmit) => {
                    this.setState({ errorSubmit });
                    if (this.state.isNew) {
                      this.props.showGlobalNotification({
                        message: "Project creation failed",
                        type: "alert"
                      });
                    } else {
                      this.props.showGlobalNotification({
                        message: "Project update failed",
                        type: "alert"
                      });
                    }
                    setSubmitting(false);
                  })
              }
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      stringOnly
                      error={this.state.errorSubmit}
                      path={"CartCreation"}
                    />
                  )}
                  <div>
                    {this.state.isNew && (
                      <div>
                        <div className="mb-3">
                          <label className="label-form">
                            Create project for group
                          </label>

                          <SelectInfinite
                            key={"select-infinite-project-group"}
                            placeholder={"Select a group"}
                            selectedOption={
                              this.state.group && this.state.group.uri
                                ? {
                                    value: this.state.group.uri,
                                    label:
                                      this.state.group.account.name ===
                                      "service"
                                        ? `${this.state.group.name}`
                                        : `${this.state.group.name} (in organization #${this.state.group.account.name})`
                                  }
                                : undefined
                            }
                            totalOptions={this.state.totalGroups}
                            initialOptions={
                              this.state.groups
                                ? this.state.groups.map((g) => ({
                                    value: g.uri,
                                    label:
                                      g.account.name === "service"
                                        ? `${g.name}`
                                        : `${g.name} (in organization #${g.account.name})`
                                  }))
                                : []
                            }
                            loadMoreOptions={this.loadMoreGroups}
                            isSearchable
                            onSelectOption={(value) =>
                              this.handleChange("groupUri", value)
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="mb-3">
                        <label className="label-form">AWS Region</label>
                        {this.state.isNew ? (
                          <SelectClassic
                            placeholder="select a region"
                            options={REGIONS.map((region) => ({
                              value: region.key,
                              label: `(${region.key})  ${region.label}`
                            }))}
                            selectedOption={{
                              value: this.state.cart.region,
                              label: this.state.cart.region
                            }}
                            onSelectOption={(option) =>
                              this.handleChange("region", option.value)
                            }
                          />
                        ) : (
                          <input
                            className="form-control"
                            disabled
                            value={this.state.cart.region}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <fieldset className="mb-3">
                    <legend className="label-form">name</legend>
                    <Field
                      type="name"
                      name="name"
                      className="form-control bg-white"
                      placeholder="project name..."
                      onChange={(event) =>
                        this.handleChange("name", event.target.value)
                      }
                      value={this.state.cart.name}
                    />
                    {errors.name && (
                      <Col size={12} style={{ color: "red" }}>
                        {errors.name}
                      </Col>
                    )}
                  </fieldset>
                  <fieldset className="form-group mb-3">
                    <legend className="label-form">Tags</legend>
                    <TagsDatasourceFields
                      customField="project_custom_tags"
                      tags={this.state.cart.project_custom_tags}
                      onChangeTags={(field, value) =>
                        this.handleChange(field, value)
                      }
                    />
                    {errors.project_custom_tags && (
                      <div style={{ color: "red" }}>
                        {errors.project_custom_tags}
                      </div>
                    )}
                    {errors.project_custom_tags_key_unicode && (
                      <div style={{ color: "red" }}>
                        {errors.project_custom_tags_key_unicode}
                      </div>
                    )}
                    {errors.project_custom_tags_value_unicode && (
                      <div style={{ color: "red" }}>
                        {errors.project_custom_tags_value_unicode}
                      </div>
                    )}
                  </fieldset>
                  <div className="row">
                    <div className="col-6">
                      <fieldset className="form-group">
                        <label className="label-form">
                          Automatic refresh role
                        </label>
                        <div className="basic-metadata-display-2">
                          <ToggleButton
                            id="basic-metadata-display-2-id"
                            checkedValue={
                              this.state.cart.is_refresh_auto_enabled
                            }
                            onChange={() =>
                              this.handleChange(
                                "is_refresh_auto_enabled",
                                !this.state.cart.is_refresh_auto_enabled
                              )
                            }
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-6">
                      <fieldset className="form-group">
                        <label className="label-form">
                          Database project use
                        </label>

                        <div className="basic-metadata-display database-use-project-toggle">
                          <ToggleButton
                            id="basic-metadata-display-id"
                            disabled={this.state.isDisabled}
                            checkedValue={this.state.cart.is_database_enabled}
                            onChange={() =>
                              this.handleChange(
                                "is_database_enabled",
                                !this.state.cart.is_database_enabled
                              )
                            }
                          />
                          {this.state.isDisabled ? (
                            <HelpOn
                              content={
                                <div>
                                  <div
                                    className="btn btn-outline-danger btn-sm btn-block"
                                    role="alert"
                                  >
                                    We can't update the project configuration
                                    because you are using one or all of the
                                    following tools: data preparation,
                                    analytics, and data visualization. Please
                                    make sure to detach all concerned resources.
                                  </div>
                                </div>
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <fieldset className="mb-3">
                    <legend className="label-form">description</legend>
                    <Field
                      component="textarea"
                      name="description"
                      className="form-control bg-white"
                      placeholder="description..."
                      onChange={(event) =>
                        this.handleChange("description", event.target.value)
                      }
                      value={this.state.cart.description}
                    />
                    <ErrorMessage name="description" component="div" />
                  </fieldset>
                  <div className={"ml-1 row justify-content-center"}>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="butn"
                    >
                      {isSubmitting && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      {this.state.isNew ? "Create Project" : "Update"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppsync(CartCreate));
