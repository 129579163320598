export const listAvailableCartDatasources = {
  query: `query Q ($cartUri:String!, $options: QueryOptions) {
        listAvailableCartDatasources (cartUri: $cartUri, options: $options) {
            results {
                access
                accountid
                account_name
                account_uri
                aws
                bucket
                createdat
                datasetsTotal
                description
                name
                owneruri
                owner_group_name
                parent
                platform_name
                platform_uri
                platform_environment
                region
                resource_name
                s3_custom_tags {
                    Key
                    Value
                }
                uri
            }
            total
            offset
            limit
        }
    }`,
  keyOutput: "listAvailableCartDatasources"
};
