import React from "react";
import routes from "../../containers/routes";
import withGoTo from "../../containers/goToHOC";
import "../users/components/groupName.less";

const ProjectCard = ({ project, goTo }) => (
  <div key={project.uri} className="card group-card card-shadow noPointer">
    <div
      className="card-group-left"
      onClick={() =>
        goTo({
          route: routes.Cart.View,
          params: {
            cartUri: project.uri
          }
        })
      }
    >
      <div className="groupname">
        <div className="names">
          <span className="subtitle group-name">{` ${project.name}`}</span>
        </div>
      </div>
      <div>{project.description}</div>
      <div>{project.group.name}</div>
      <div>{project.region}</div>
    </div>
    <div className="card-group-right"></div>
  </div>
);

export default withGoTo(ProjectCard);
