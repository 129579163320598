/* @flow */

export const deleteLogsGroup = {
  query: `mutation Q (
        $cartUri: String!,
        $logsGroupId: String!,
        ) {
            deleteLogsGroup(
                cartUri: $cartUri,
                logsGroupId: $logsGroupId,
            )
        }
     `,
  keyOutput: "deleteLogsGroup"
};
