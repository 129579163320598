/* @flow */
import React from "react";
import SelectAutocomplete from "../../../components/SelectAutocomplete";

type Props = {
  columns: Array<Object>,
  selectedColumns: Array<Object>,
  setFieldValue: Function
};
function FormFieldColumns({ columns, selectedColumns, setFieldValue }: Props) {
  return (
    <fieldset className="form-group">
      <legend className="label-form">Columns:</legend>
      <SelectAutocomplete
        placeholder={"Select Columns"}
        options={columns}
        selectedOption={
          selectedColumns && selectedColumns.length > 0
            ? selectedColumns.map((col) => ({
                value: col,
                label: col
              }))
            : []
        }
        onSelectOption={(options) => {
          setFieldValue(
            "columns",
            options.map((opt) => opt.value)
          );
        }}
        isMulti
      />
    </fieldset>
  );
}

export default FormFieldColumns;
