/* @flow */
import React from "react";
import Stringslider from "../../../components/stringslider";

type propTypes = {
  publisher: string,
  ownergroupname: string,
  size: number
};

const DatasetCardPublisher = ({
  publisher,
  ownergroupname,
  size = 250
}: propTypes) => (
  <div className={"dataset-card-publisher"}>
    <div className={"text-card-publisher"}>
      <Stringslider s={`By ${publisher}`} width={size} /> - {ownergroupname}
    </div>
  </div>
);

DatasetCardPublisher.defaultProps = {
  publisher: "-"
};

export default DatasetCardPublisher;
