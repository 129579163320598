/* @flow */
import React from "react";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import Paginator from "../../../components/paginator";
import "./view.less";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import DatasourceShareCreate from "./DatasourceShareCreate";
import DatasourceShareItem from "./DatasourceShareItem";
import DatasourceShareModalEdit from "./DatasourceShareModalEdit";
import DatasourceShareModalDelete from "./DatasourceShareModalDelete";

type propTypes = {
  datasource: {
    uri: string
  },
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  isFetching: boolean,
  openShareDatasourceForm: boolean,
  offset: number,
  shares: Array<Object>,
  sharesTotal: number,
  datasourceShareModalEdit: Object,
  datasourceShareModalDelete: Object
};

class DatasourceShares extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isFetching: false,
      openShareDatasourceForm: false,
      offset: 0,
      shares: [],
      sharesTotal: 0,
      datasourceShareModalEdit: undefined,
      datasourceShareModalDelete: undefined
    };
  }

  componentDidMount() {
    this.listDatasourcesShares();
  }

  reload = () => {
    this.setState({ shares: [], openShareDatasourceForm: false });
    this.listDatasourcesShares();
  };

  listDatasourcesShares = () => {
    this.setState({ isFetching: true });
    const options = {
      limit: 4,
      offset: this.state.offset
    };
    return this.props.api.datasource
      .listDatasourceShares(this.props.datasource.uri, options)
      .then((sharesList) => {
        if (!sharesList) return false;
        this.setState({
          shares: sharesList.results,
          sharesTotal: sharesList.total,
          isFetching: false
        });
        return sharesList;
      })
      .catch((error) => {
        this.setState({
          error,
          isFetching: false
        });
      });
  };

  listDatasourcesSharesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listDatasourcesShares().then(r))
    );

  openDatasourceShareModalEdit = (share) =>
    this.setState({ datasourceShareModalEdit: { share } });

  closeDatasourceShareModalEdit = () =>
    this.setState({ datasourceShareModalEdit: undefined });

  openDatasourceShareModalDelete = (share) =>
    this.setState({ datasourceShareModalDelete: { share } });

  closeDatasourceShareModalDelete = () =>
    this.setState({ datasourceShareModalDelete: undefined });

  onClose = () => {
    this.setState({ openShareDatasourceForm: false });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DatasourceShares"} />;
    return (
      <div className="datasource-shares">
        <div className="datasource-shares-create-container">
          {!this.state.openShareDatasourceForm && (
            <div
              className="butn butn-flat"
              onClick={() => this.setState({ openShareDatasourceForm: true })}
            >
              <i className={"fas fa-lock-open"} />
              <span className="button-share-datasource">
                {"Share Datasource with a group"}
              </span>
            </div>
          )}
          {this.state.openShareDatasourceForm && (
            <DatasourceShareCreate
              datasource={this.props.datasource}
              api={this.props.api}
              reload={this.reload}
              onClose={this.onClose}
            />
          )}
        </div>
        {!this.state.openShareDatasourceForm && (
          <div>
            {this.state.isFetching && <Loading message="Shares List" />}
            {!this.state.isFetching && (
              <React.Fragment>
                <div className="datasource-shares-list-container">
                  {this.state.shares && (
                    <div className="datasource-shares-list">
                      <Paginator
                        limit={4}
                        initialOffset={this.state.offset}
                        isLoadingPage={this.state.isFetching}
                        loadingMessage={<Loading message="Datasource Shares" />}
                        list={this.state.shares}
                        totalCount={this.state.sharesTotal}
                        componentRender={(share) => (
                          <DatasourceShareItem
                            share={share}
                            onEdit={() =>
                              this.openDatasourceShareModalEdit(share)
                            }
                            onRevoke={() =>
                              this.openDatasourceShareModalDelete(share)
                            }
                          />
                        )}
                        loadPage={this.listDatasourcesSharesFromPagination}
                        onNoResult={() => (
                          <p className="total-search-results">No share found</p>
                        )}
                      />
                    </div>
                  )}
                </div>

                {this.state.datasourceShareModalEdit && (
                  <DatasourceShareModalEdit
                    share={this.state.datasourceShareModalEdit.share}
                    api={this.props.api}
                    hideModal={this.closeDatasourceShareModalEdit}
                    showGlobalNotification={this.props.showGlobalNotification}
                    onEdit={() => {
                      this.setState({ datasourceShareModalEdit: undefined });
                      this.listDatasourcesShares();
                    }}
                  />
                )}
                {this.state.datasourceShareModalDelete && (
                  <DatasourceShareModalDelete
                    share={this.state.datasourceShareModalDelete.share}
                    api={this.props.api}
                    hideModal={this.closeDatasourceShareModalDelete}
                    showGlobalNotification={this.props.showGlobalNotification}
                    onDelete={() => {
                      this.setState({ datasourceShareModalDelete: undefined });
                      this.listDatasourcesShares();
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withAppSync(DatasourceShares);
