export const getLakeFormationMigrationReportRows = {
  query: `query Q ($id:String!, $options: ListOptions) {
        getLakeFormationMigrationReportRows(id:$id, options:$options) {
           results {
             role_arn
             resource_name
             resource_type
             lf_grant_status
             error
             grants
             refresh_role
             project_uri
             project_name
            }
            total
            offset
            limit
          }
    }
    `,
  keyOutput: "getLakeFormationMigrationReportRows"
};
