export const enableFeatureToggle = {
  query: `mutation Q ($id: String!) {
        enableFeatureToggle(id: $id) {
          id
          code
          description
          version
          environment
          status
    }
  }
`,
  keyOutput: "enableFeatureToggle"
};
