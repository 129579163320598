/* @flow */
import React from "react";
import "./DatasourceETLCredentials.less";
import { connect } from "react-redux";
import Row from "../../../components/row";
import Col from "../../../components/col";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import routes from "../../routes";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";

type propTypes = {
  datasource: {
    uri: string,
    parent: string
  },
  goTo: Function
};

type stateTypes = {
  overwrite: boolean
};

class DatasourceETLSessionCredentials extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      overwrite: false
    };
  }

  handleChange = (value: Object) => this.setState({ overwrite: !!value });

  render() {
    return (
      <Row>
        <Col
          size={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          Get ETL Session credentials
          <div className="checkbox-overwrite">
            <div className="facet-criteria-name">
              <input
                id="overwriteiam"
                type="checkbox"
                className={"checkbox-facet"}
                onClick={(event) => this.handleChange(event.target.checked)}
              />
              <label htmlFor="overwriteiam" className="fas">
                <small className="attribute-label">Allow Overwrite</small>
              </label>
            </div>
          </div>
        </Col>
        <Col
          size={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div>
            <div
              style={{ float: "right", marginTop: "5px" }}
              className="butn butn-flat min-width-160"
              onClick={() => {
                this.props.goTo({
                  route: routes.Datasource.GenerateETLSessionCredentials,
                  params: {
                    uriDatasource: this.props.datasource.uri,
                    uriAccount: getAccountUriFromUri(this.props.datasource.uri),
                    overwrite: this.state.overwrite.toString()
                  },
                  state: {
                    account: {
                      uri: getAccountUriFromUri(this.props.datasource.uri)
                    },
                    playground: { uri: this.props.datasource.parent },
                    datasource: this.props.datasource
                  }
                });
              }}
            >
              ETL Credentials
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceETLSessionCredentials)));
