/* @flow */
import React from "react";
import "./view.less";
import { connect } from "react-redux";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import DatasourceReplicationCard from "../../datasets/card/DatasourceReplicationCard";
import DatasourceReplicationForm from "../components/form/replication";
import { updateMessage } from "../../globalNotifications/actions";
import Modal from "../../../components/modal";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  api: GraphQl,
  datasource: Object,
  showGlobalNotification: Function
};

type stateTypes = {
  error: string,
  closeModal: boolean,
  errorPolicies: boolean,
  replications: Array<Object>,
  openForm: boolean,
  replicationStatus: boolean
};

class DataReplication extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      replications: [],
      openForm: false,
      replicationStatus: true,
      error: "",
      errorPolicies: false,
      closeModal: true
    };
    this.closeForm = this.closeForm.bind(this);
  }

  componentDidMount() {
    this.getDatasourceReplicationStatus();
    this.getReplications();
  }

  getReplications = () =>
    this.props.api.datasource
      .getDatasourceReplication(this.props.datasource.uri)
      .then((replications) => {
        this.setState({
          replications: replications.results
        });
        return replications;
      });

  getDatasourceReplicationStatus = () => {
    this.props.api.datasource
      .getDatasourceReplicationStatus(this.props.datasource.uri)
      .then(
        (data) => {
          this.setState({ replicationStatus: data });
        },
        (e) => {
          this.setState({ error: e.message });
        }
      );
  };

  getErrorPolicies = (status) => {
    this.setState({ errorPolicies: status });
  };

  renderReplications = () => {
    const replications = [];
    this.state.replications.map((o) => {
      replications.push(
        <DatasourceReplicationCard
          key={o.replication_datasource_uri}
          datasource={this.props.datasource}
          uri={o.replication_datasource_uri}
          scopes={o.replication_rules}
          replication={{
            uri: o.replication_datasource_uri,
            createdat: o.createdat,
            updatedat: o.updatedat,
            replication: o,
            datasource: this.props.datasource
          }}
          onClose={this.closeForm}
          putDatasourceReplication={this.putDatasourceReplication}
          getErrorPolicies={this.getErrorPolicies}
        />
      );
      return replications;
    });
    return replications;
  };

  renderDatasourceReplicationForm = () => (
    <div className="dataset-form-creation">
      <div className="create-dataset">
        <h4>Create a new replication rule</h4>
      </div>
      <DatasourceReplicationForm
        api={this.props.api}
        onClose={this.closeForm}
        datasource={this.props.datasource}
        putDatasourceReplication={this.putDatasourceReplication}
      />
    </div>
  );

  closeForm = () => {
    this.setState({ openForm: false });
    this.setState({ error: "" });
  };

  putDatasourceReplication = ({
    sourceUri,
    destinationUri,
    datasetsUriList,
    selectedStorageClass,
    isStorageClassSelected,
    currentStorageClass
  }) => {
    const promise = this.props.api.datasource
      .putDatasourceReplication({
        sourceUri,
        destinationUri,
        datasetsUriList,
        selectedStorageClass,
        isStorageClassSelected,
        currentStorageClass
      })
      .then(() => {
        this.closeForm();
        this.props.showGlobalNotification({
          message: "Datasource replication created",
          type: "success"
        });
        setTimeout(() => window.location.reload(), 300);
      })
      .catch((e) => {
        this.setState({ error: e.message });
      });

    return promise;
  };

  getConfirmBodyModal = () => (
    <div className="cdh-replication-disclaimer">
      <ul>
        <li>This operation may take several minutes.</li>
        <li>
          Data that has already been replicated in the datasource destination,
          will not be replicated once again.
        </li>
        <li>
          There's an additional cost to be expected in the event of cross-region
          replication. <br />
          For more details, please visit the following{" "}
          <a
            href={"https://aws.amazon.com/s3/pricing/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
          .
        </li>
      </ul>
    </div>
  );

  confirmModal = () => (
    <Modal
      title={"Disclaimer"}
      body={this.getConfirmBodyModal()}
      actions={[
        <ButtonAction
          label="Accept"
          className="butn butn-create"
          onClick={() => {
            this.setState({ openForm: true });
            this.setState({ closeModal: true });
          }}
        />,
        <ButtonAction
          label="Deny"
          onClick={() => {
            this.setState({ closeModal: true });
          }}
        />
      ]}
    />
  );

  render() {
    let AddReplicationRuleButton;

    if (!this.state.openForm) {
      AddReplicationRuleButton = (
        <button
          disabled={!this.state.replicationStatus}
          className="butn butn-create"
          onClick={() => this.setState({ closeModal: false })}
        >
          <i className={"fas fa-lg fa-plus pr-2"} />
          Create a replication rule
        </button>
      );
    }

    const errorMessage = (
      <div className={"label-error"}>Error : Replication status failure</div>
    );

    const replicationsList = this.renderReplications();

    return (
      <div className="card-container">
        {this.state.error && (
          <div className="container mb-3">
            <div className={"label-error"}> {this.state.error}</div>
            {!this.state.replicationStatus && errorMessage}
          </div>
        )}
        {this.state.errorPolicies && !this.state.openForm && (
          <div className="container mb-3">
            <p className="btn btn-outline-danger btn-block" role="alert">
              <i className="fa fa-exclamation-triangle orange-icon"></i> Some
              replication rule information might not be accessible because You
              don't own the destination datasource.
            </p>
          </div>
        )}

        <div
          className="container mb-3 flex"
          style={{ display: "flex", alignItems: "center", gap: "16px" }}
        >
          {AddReplicationRuleButton}
          {!this.state.replicationStatus && (
            <div>
              A replication rule already exists for your datasource in your aws
              account. Please remove it manually before activating CDH
              replication feature.
            </div>
          )}
        </div>
        <div className="container mt-3">
          {this.state.openForm && this.renderDatasourceReplicationForm()}
        </div>
        <div className="container mt-3">
          {!this.state.openForm && replicationsList}
        </div>
        {!this.state.closeModal && this.confirmModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(DataReplication));
