export const getDatabricksAccountCredentials = {
  query: `query Q ($uuid: String!) {
    getDatabricksAccountCredentials(uuid: $uuid) {
            dbx_credentials_client_id
            dbx_credentials_client_secret_id
            dbx_credentials_client_secret_value
            dbx_credentials_updatedat
            dbx_service_principal_id
          }
        }
      `,
  keyOutput: "getDatabricksAccountCredentials"
};
