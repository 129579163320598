/* @flow */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import GraphQl from "../../../graphQL";
import withGoTo from "../../goToHOC";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import DeleteObjectWithFrictionModal from "../../../components/DeleteObjectWithFrictionModal";
import SmStudioLogo from "../smstudio-logo.png";
import { formatDate } from "../../../utils/date";
import { getErrorMessage } from "../../../utils/error";
import "../style.less";

type propTypes = {
  domain: Object,
  api: GraphQl,
  reloadDomains: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  domain: Object,
  errorConsoleAccess?: Object,
  openDeleteModal: boolean,
  errorDelete: Object,
  error: Object,
  refreshing: boolean
};

class Domain extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      domain: this.props.domain,
      openDeleteModal: false,
      errorDelete: null,
      error: null,
      refreshing: false
    };
  }

  componentDidMount() {
    this.getDomain();
  }

  getDomain = () => {
    this.setState({
      refreshing: true
    });
    this.props.api.smstudio
      .getDomain(this.props.domain.platform_uri, this.props.domain.id)
      .then((domain) => {
        this.setState({
          domain,
          refreshing: false
        });
      })
      .catch((error) => {
        this.setState({
          error,
          refreshing: false
        });
      });
  };

  deleteDomain = (deleteFromAws) => {
    this.props.api.smstudio
      .deleteDomain(
        this.props.domain.platform_uri,
        this.props.domain.id,
        deleteFromAws
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Domain planned for deletion...",
          type: "success"
        });
        this.setState({ openDeleteModal: false });
        this.props.reloadDomains();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  render() {
    const { domain } = this.state;

    return (
      <li className="cdh-card card-shadow card bg-white">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <img
                className="connector-logo-img mr-1"
                src={SmStudioLogo}
                alt="Sagemaker Studio"
                width={18}
                height={18}
              />
              Sagemaker Studio Domain
            </div>
          </div>
          <div className="card-cart-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-datasource">
              {formatDate(domain.createdat, false)}
            </span>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <span className="text-capitalize cdh-card-name">{domain.name}</span>

            <div className="cdh-card-details">
              <p>
                <b>Environment:</b> {domain.aws}/{domain.region}
              </p>

              <div className="cdh-card-detail">
                <i className="fas fa-box" />
                <span className="content-card-bucket">
                  <b>Domain ID:</b> {domain.sm_domain_id}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-table" />
                <span className="content-card-bucket">
                  <b>CloudFormation Stack:</b> {domain.stack_name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-box" />
                <span className="content-card-bucket">
                  <b>Network:</b> {domain.network.name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-network-wired" />
                <span className="content-card-bucket">
                  <b>VPC:</b> {domain.network.vpc_id}
                </span>
              </div>
              <div className="cdh-card-detail">
                <i className="fas fa-network-wired" />
                <span className="content-card-bucket">
                  <b>Subnets:</b> {domain.network.subnet_ids.join(", ")}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-stop-circle" />
                <span className="content-card-bucket">
                  <b>Lifecycle configuration:</b>{" "}
                  {domain.lifecycle_conf_enabled ? "Enabled" : "Disabled"}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div
                className={"btn-cart"}
                onClick={() => this.setState({ openDeleteModal: true })}
              >
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin" style={{ display: "flex" }}>
            <div className="text-footer-metrics">
              <i className="fas fa-box fa-spacing" />
              Domain Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": ["InService"].includes(domain.status),
                  "warning-status": domain.status === "pending",
                  "error-status": ["Deleted", "Failed", "NOT_FOUND"].includes(
                    domain.status
                  )
                })}
              >
                {domain.status}
              </span>
            </div>
            <div className="text-footer-metrics ml-2">
              <i className="fas fa-table fa-spacing" />
              CloudFormation Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": [
                    "CREATE_COMPLETE",
                    "UPDATE_COMPLETE"
                  ].includes(domain.stack_status),
                  "warning-status":
                    domain.stack_status === "CREATE_IN_PROGRESS",
                  "error-status": [
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETE",
                    "NOT_FOUND"
                  ].includes(domain.stack_status)
                })}
              >
                {domain.stack_status}
              </span>
            </div>
          </div>
          <div className="cdh-card-end">
            <button
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.getDomain}
            >
              {this.state.refreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
          </div>
        </div>
        {this.state.error && (
          <Alert severity="error">{getErrorMessage(this.state.error)}</Alert>
        )}
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={`Domain: ${domain.name}`}
            deleteMessage={
              "Remove any associated apps to this domain before deleting it."
            }
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => this.setState({ openDeleteModal: false })}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteDomain}
            isAWSResource
          />
        )}
        {this.state.error && (
          <div className="IAM-error">
            <Alert severity="error">{this.state.error.message}</Alert>
          </div>
        )}
        {this.state.errorDelete && (
          <div className="IAM-error">
            <Alert severity="error">{this.state.errorDelete.message}</Alert>
          </div>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(withGoTo(Domain)));
