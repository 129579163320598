/* @flow */
import React from "react";
import { formatDate } from "../../../../utils/date";

type propTypes = {
  activity: {
    activityid: string,
    name: string,
    createdat: string,
    description: string
  }
};

const IconMap = {
  "GROUP:CREATE": <i className={"fas fa-users mr-2"} />,
  "GROUP:ADD_USER": <i className={"fas fa-users-cog mr-2"} />,
  "ACCOUNT:INVITE": <i className={"fas fa-user-plus mr-2"} />,
  "DATASOURCE:CREATE": <i className={"fas fa-folder-plus mr-2"} />,
  "DATASOURCE:UPDATE": <i className={"fas fa-file mr-2"} />,
  "DATASET:CREATE": <i className={"fas fa-file mr-2"} />,
  "DATASET:REQUEST_ACCESS": <i className={"fas fa-lock mr-2"} />,
  "DATASET:GRANT_ACCESS": <i className={"fas fa-lock mr-2"} />,
  "DATASET:REVOKE_ACCESS": <i className={"fas fa-lock mr-2"} />,
  "PROJECT:CREATE": <i className="fas fa-project-diagram mr-2"></i>,
  DEFAULT: <i className={"fas fa-info mr-2"} />
};

const Activity = ({ activity }: propTypes) => (
  <li key={activity.activityid} className="list-group-item activity">
    <div className="activity-date">
      <span>{formatDate(activity.createdat)}</span>
    </div>
    <div className="activity-title">
      {IconMap[activity.name] || IconMap.DEFAULT}
      <code className="activity-name">{activity.name}</code>
    </div>
    <div>
      <small className="detail-activity">{activity.description}</small>
    </div>
  </li>
);

export default Activity;
