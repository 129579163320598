/* @flow */
import React from "react";
import "../style.less";
import Modal from "../../../../components/modal";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";

const Log = Logger("DatasourceView");

type propTypes = {
  policy: Object,
  hide: Function,
  onDetachPolicySuccess: Function,
  onDetachPolicyFailure: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  isFetching: boolean
};

class DeletePolicyModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      isFetching: false
    };
  }

  deletePolicy() {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.group
          .detachPolicy(
            this.props.policy.environment.uri,
            this.props.policy.group.uri,
            this.props.policy.policyarn
          )
          .then(() => {
            this.setState({
              isFetching: false
            });
            this.props.onDetachPolicySuccess();
          })
          .catch((error) => {
            Log.error(error);
            this.setState({
              error,
              isFetching: false
            });
            this.props.onDetachPolicyFailure();
          });
      }
    );
  }

  render() {
    return (
      <Modal
        title={"Delete policy"}
        body={`Do you really want to delete this policy  (${this.props.policy.policyarn}) ?`}
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-close-${this.props.policy.arn}`}
            type="button"
            className="butn"
            onClick={this.props.hide}
          >
            {this.state.isFetching && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            Close
          </button>,
          <button
            key={`dataset-delete-${this.props.policy.arn}`}
            type="button"
            className="butn butn-delete"
            onClick={() => this.deletePolicy()}
          >
            Delete
          </button>
        ]}
      />
    );
  }
}

export default withAppSync(DeletePolicyModal);
