export const getLakeFormationMigration = {
  query: `query Q ($id:String!, $options: ListOptions) {
        getLakeFormationMigration(id:$id) {
          id
          region
          type
          status
          stepfunctions
          platform_uri
          report(options:$options){
           id
           rows {
            results {
             role_arn
             resource_name
             resource_type
             lf_grant_status
             error
             grants
             refresh_role
             project_uri
             project_name
            }
            total
            offset
            limit
           }
          }
          platform {
           aws
           name
          }
        }
    }
    `,
  keyOutput: "getLakeFormationMigration"
};
