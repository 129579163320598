/* @flow */
import React from "react";
import { getErrorMessageWithoutCode } from "../../utils/error";
import ErrorContent from "./ErrorContent";
import ErrorFeedback from "./ErrorFeedback";

const permissionList = {
  "datasource:get":
    "To get this datasource, you need to be a part of the group owner.",
  "account:create_group":
    "To create a group, you need to be admin of the account.",
  "account:create_identity":
    "To invite new user, you need to be be admin of the account.",
  "group:list_platforms": "You can't see the groups list."
};

type propTypesPermissionError = {
  error: string | Object,
  sendFeedBack: Function,
  handleToggle: Function,
  isDetailsOpen: boolean
};
const PermissionError = ({
  error,
  sendFeedBack,
  isDetailsOpen,
  handleToggle
}: propTypesPermissionError) => {
  let message = "You are not allowed to do this action.";
  Object.keys(permissionList).some((key) => {
    if (getErrorMessageWithoutCode(error).includes(key)) {
      message = permissionList[key];
      return true;
    }
    return false;
  });
  return (
    <div className={"ooops-error-permissions bg-white border border-info"}>
      <div className={"text-dark font-weight-bold"}>{message}</div>
      <ErrorContent
        error={error}
        isDetailsOpen={isDetailsOpen}
        handleToggle={handleToggle}
        displayError={false}
      />
      <ErrorFeedback sendFeedBack={sendFeedBack} />
    </div>
  );
};

export default PermissionError;
