import React, { useState } from "react";
import withAppSync from "../../../../AppsyncHOC";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  TextField
} from "@material-ui/core";

const RefreshTablesModal = ({ api, cart, match, open, onClose }) => {
  const [savingRefresh, setSavingRefresh] = useState(false);

  const save = async () => {
    setSavingRefresh(true);
    await api.databricks
      .registerDatabricksData(
        cart.uri,
        cart.dbx_catalog.dbx_catalog_name,
        cart.dbx_workspace.uuid
      )
      .then(() => {
        setSavingRefresh(false);
        location.reload();
      });
  };
  return (
    <div>
      <Dialog
        fullWidth
        onClose={onClose}
        open={open}
        style={{
          overflowY: "visible"
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box>
            <Grid container spacing={2}>
              <Grid item md={11} sm={11} xs={11}>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className={"mt-1"}
                >
                  Refresh Tables
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ overflowY: "visible" }}>
          <Box sx={{ p: 3 }}>
            <form onSubmit={() => save()}>
              <div className="form-container">
                <div className="mb-3">
                  <TextField
                    label="Workspace"
                    variant="outlined"
                    value={cart.dbx_workspace.uuid}
                    fullWidth
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <TextField
                    label="Catalogue"
                    variant="outlined"
                    value={cart.dbx_catalog.dbx_catalog_name}
                    fullWidth
                    disabled
                  />
                </div>
                <CardContent>
                  <button
                    type={"submit"}
                    className="butn butn-create"
                    style={{ margin: "5px", width: "100%" }}
                  >
                    {savingRefresh ? (
                      <i className="fas fa-sync-alt fa-spin" />
                    ) : (
                      <i className={"fa fa-sync-alt"} />
                    )}
                    <span>{" Refresh Tables"}</span>
                  </button>
                </CardContent>
              </div>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default withAppSync(RefreshTablesModal);
