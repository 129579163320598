import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./globalNotifications.less";

class GlobalNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.popupDuration = 2000;
    this.timeout = null;
    this.state = {
      isVisible: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.message !== prevProps.message ||
        this.props.date !== prevProps.date) &&
      !this.state.isVisible
    ) {
      this.setHide();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  setHide() {
    this.setState({ isVisible: true });
    this.timeout = window.setTimeout(() => {
      this.setState({
        isVisible: false
      });
    }, this.props.popupDuration || this.popupDuration);
  }

  render() {
    const { message, type } = this.props;
    return (
      <div
        className={classnames("globalNotifications", {
          "is-visible": this.state.isVisible,
          "type-success": type === "success",
          "type-alert": type === "alert",
          "type-neutral": type === "neutral"
        })}
      >
        {message}
      </div>
    );
  }
}

GlobalNotifications.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  type: PropTypes.string,
  popupDuration: PropTypes.number,
  date: PropTypes.number
};

const mapStateToProps = (state) => {
  const { globalNotifications = {} } = state;
  return {
    message: globalNotifications.message,
    type: globalNotifications.type,
    popupDuration: globalNotifications.popupDuration,
    date: globalNotifications.date
  };
};

export default connect(mapStateToProps)(GlobalNotifications);
