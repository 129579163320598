import { listMyGroups } from "./queryListMyGroups";
import { listMyGroupsWithoutShare } from "./queryListMyGroupsWithoutShare";
import { listMyGroupsAndUsers } from "./queryListMyGroupsAndUsers";
import { createIdentity } from "./mutationCreateIdentity";
import { deleteIdentity } from "./mutationDeleteIdentity";
import { getOktaUser } from "./queryGetOktaUser";
import { findByGid } from "./queryFindByGid";
import {
  listMyGroupsForDatasourceCreation,
  listMyGroupsForDatasourceCreationTotal
} from "./queryListMyGroupsForDatasourceCreation";
import {
  listMyGroupsForProjectCreation,
  listMyGroupsForProjectCreationTotal
} from "./queryListMyGroupsForProjectCreation";
import { listGroupsIdentity } from "./queryListGroupsIdentity";
import { listMyGroupTrusts } from "./queryListMyGroupTrusts";

class IdentityApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  findByGid(gid) {
    return this.fetchAppSync(findByGid, { gid }, false, true);
  }

  listGroupsIdentity(identityUri, options = {}) {
    return this.fetchAppSync(listGroupsIdentity, { identityUri, options });
  }

  listMyGroups(options = {}) {
    return this.fetchAppSync(listMyGroups, { options });
  }

  listMyGroupsForDatasourceCreationTotal(options = {}) {
    return this.fetchAppSync(listMyGroupsForDatasourceCreationTotal, {
      options
    });
  }

  listMyGroupsForDatasourceCreation(options = {}) {
    return this.fetchAppSync(listMyGroupsForDatasourceCreation, { options });
  }

  listMyGroupsForProjectCreation(options = {}) {
    return this.fetchAppSync(listMyGroupsForProjectCreation, { options });
  }

  listMyGroupsForProjectCreationTotal(options = {}) {
    return this.fetchAppSync(listMyGroupsForProjectCreationTotal, { options });
  }

  listMyGroupsWithoutShare(datasetUri, options = {}) {
    return this.fetchAppSync(listMyGroupsWithoutShare, { datasetUri, options });
  }

  listMyGroupsAndUsers(options = {}) {
    return this.fetchAppSync(listMyGroupsAndUsers, { options });
  }

  listMyGroupTrusts(options = {}) {
    return this.fetchAppSync(listMyGroupTrusts, { options });
  }

  create(accountUri, input) {
    return this.fetchAppSync(createIdentity, { accountUri, input });
  }

  deleteIdentity(identityUri) {
    return this.fetchAppSync(deleteIdentity, { accountUri: "", identityUri });
  }

  getOktaUser(gaia) {
    return this.fetchAppSync(getOktaUser, { gaia });
  }
}

export default IdentityApi;
