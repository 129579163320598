/* @flow */

import {
  createDatasource,
  type NewDatasourceInput
} from "./mutationCreateDatasource";
import {
  putDatasourceReplication,
  putDatasourceReplicationWithoutStorageClass
} from "./mutationCreateDatasourceReplication";
import { deleteDatasourceReplication } from "./mutationDeleteDatasourceReplication";
import { getDatasourceReplication } from "./queryGetDatasourceReplication";
import { controlDatasourceReplication } from "./queryGetDatasourceReplicationStatus";
import { getDatasource } from "./queryGetDatasource";
import { deleteDatasource } from "./mutationDeleteDatasource";
import {
  updateDatasourceDetails,
  type UpdateDatasourceInput
} from "./mutationUpdateDatasource";
import {
  generateDatasourceCredentials,
  type ETLCredsInput
} from "./mutationGenerateDatasourceCredentials";
import {
  addDatasourceProvider,
  type DatasourceProviderInput
} from "./mutationAddDatasourceProvider";
import { listDatasourceProviders } from "./queryListDatasourceProviders";
import { deleteDatasourceProvider } from "./mutationDeleteDatasourceProvider";
import { editDatasourceProvider } from "./mutationEditDatasourceProvider";
import { listAllMyDatasources } from "./queryListMyDatasources";
import { listAllMyDatasourcesTotal } from "./queryListAllMyDatasourcesTotal";
import { listDatasourceLogs } from "./queryListDatasourceLogs";
import { listNewDatasourceLogs } from "./queryListNewDatasourceLogs";
import { getDatasourceAdminCredentials } from "./queryGetDatasourceAdminCredentials";
import { getDatasourceForEdit } from "./queryGetDatasourceForEdit";
import { getRoleIdFromRoleArn } from "./queryGetRoleIDFromArn";
import type { QueryOptions } from "../propstypes";
import { detectDatasets } from "./queryDetectDatasets";
import { createDatasetsFromDetection } from "./mutationCreateDatasetsFromDetection";
import { getDatasetsOfDatasource } from "./queryGetDatasetsOfDatasource";
import { deleteDatasourceCredentials } from "./mutationDeleteDatasourceCredentials";
import { listAllMyDatasourcesGroups } from "./queryListMyDatasourcesGroups";
import { isRegisteredWithLakeFormation } from "./queryIsRegisteredWithLakeFormation";
import { transferOwnership } from "./mutationDatasourceTransferOwnership";
import { detachEquansDatasource } from "./mutationDetachDatasourceForEquans";
import { checkAvailabiltyLoaders } from "./mutationCheckAvailabilityLoaders";
import { getDatasourceETLSessionCredentials } from "./queryGetDatasourceETLSessionCredentials";
import { shareDatasource } from "./mutationShareDatasource";
import { updateShareDatasource } from "./mutationUpdateShareDatasource";
import { deleteShareDatasource } from "./mutationDeleteShareDatasource";
import { listDatasourceShares } from "./queryListDatasourceShares";
import { listDatasetsShareableWithGroup } from "./queryListDatasetsShareableWithGroup";
import { shareSubsetDatasetsWithGroup } from "./mutationShareSubsetDatasetsWithGroup";
import { getMyAccessibleDatasources } from "./queryGetMyAccessibleDatasources";
import { listDatasourcesFromProject } from "./queryListDatasourcesFromProject";
import { addDatasourceToProject } from "./mutationAddDatasourceToProject";
import { addDatasourcesToProject } from "./mutationAddDatasourcesToProject";
import { removeDatasourceFromProject } from "./mutationRemoveDatasourceFromProject";
import { getDatasourceDatasetsLight } from "./queryGetDatasourceDatasetsLight";
import { openRSDatasourceSqlWorkbench } from "./queryOpenRSDatasourceSqlWorkbench";
import { queryGetRSDatasource } from "./queryGetRSDatasource";
import { syncRSDatasourceDatabase } from "./mutationSyncRSDatasourceDatabase";

class DatasourceApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  create(accountUri: string, platformUri: string, input: NewDatasourceInput) {
    return this.fetchAppSync(createDatasource, {
      accountUri,
      platformUri,
      input
    });
  }

  putDatasourceReplication({
    sourceUri,
    destinationUri,
    datasetsUriList,
    selectedStorageClass,
    isStorageClassSelected,
    currentStorageClass
  }: {
    sourceUri: string,
    destinationUri: string,
    datasetsUriList: string[],
    selectedStorageClass: string,
    isStorageClassSelected: string,
    currentStorageClass: string
  }) {
    let options = {
      originalDatasourceUri: sourceUri,
      replicationDatasourceUri: destinationUri,
      datasetsUriList
    };
    if (isStorageClassSelected) {
      options = { ...options, replicationStorageClass: selectedStorageClass };
      return this.fetchAppSync(putDatasourceReplication, options);
    }
    if (!isStorageClassSelected && currentStorageClass) {
      options = { ...options, replicationStorageClass: currentStorageClass };
      return this.fetchAppSync(putDatasourceReplication, options);
    }
    return this.fetchAppSync(
      putDatasourceReplicationWithoutStorageClass,
      options
    );
  }

  get(datasourceUri: string) {
    return this.fetchAppSync(getDatasource, {
      accountUri: "",
      datasourceUri
    });
  }

  getDatasets(datasourceUri: string, options: Object) {
    return this.fetchAppSync(
      getDatasetsOfDatasource,
      {
        accountUri: "",
        datasourceUri,
        options
      },
      true
    );
  }

  getDatasourceReplication(datasourceUri: string) {
    return this.fetchAppSync(getDatasourceReplication, { datasourceUri });
  }

  getDatasourceReplicationStatus(datasourceUri: string) {
    return this.fetchAppSync(controlDatasourceReplication, { datasourceUri });
  }

  getDatasourceForEdit(datasourceUri: string) {
    return this.fetchAppSync(getDatasourceForEdit, {
      accountUri: "",
      datasourceUri
    });
  }

  getDatasourceDatasetsLight(datasourceUri: string) {
    return this.fetchAppSync(getDatasourceDatasetsLight, {
      accountUri: "",
      datasourceUri
    });
  }

  deleteDatasource(datasourceUri: string, deleteKMSKey: boolean) {
    return this.fetchAppSync(deleteDatasource, {
      accountUri: "",
      datasourceUri,
      deleteKMSKey
    });
  }

  deleteDatasourceReplication(
    originalDatasourceUri: string,
    replicationDatasourceUri: string
  ) {
    return this.fetchAppSync(deleteDatasourceReplication, {
      originalDatasourceUri,
      replicationDatasourceUri
    });
  }

  update(datasourceUri: string, input: UpdateDatasourceInput) {
    return this.fetchAppSync(updateDatasourceDetails, {
      accountUri: "",
      datasourceUri,
      input
    });
  }

  getDatasourceETLSessionCredentials(
    datasourceUri: string,
    overwrite: boolean
  ) {
    return this.fetchAppSync(getDatasourceETLSessionCredentials, {
      datasourceUri,
      overwrite
    });
  }

  generateCredentials(
    accountUri: string,
    datasourceUri: string,
    input: ETLCredsInput,
    overwrite: boolean
  ) {
    return this.fetchAppSync(generateDatasourceCredentials, {
      accountUri,
      datasourceUri,
      input,
      overwrite
    });
  }

  getMyAccessibleDatasources(
    ownership: string,
    cartUri: string,
    options: ?QueryOptions
  ) {
    return this.fetchAppSync(getMyAccessibleDatasources, {
      ownership,
      cartUri,
      options
    });
  }

  removeDatasourceFromProject(datasourceUri: string, cartUri: string) {
    return this.fetchAppSync(removeDatasourceFromProject, {
      datasourceUri,
      cartUri
    });
  }

  addDatasourceToProject(datasourceUri: string, cartUri: string) {
    return this.fetchAppSync(addDatasourceToProject, {
      datasourceUri,
      cartUri
    });
  }

  addDatasourcesToProject(datasourcesUri: Array<string>, cartUri: string) {
    return this.fetchAppSync(addDatasourcesToProject, {
      datasourcesUri,
      cartUri
    });
  }

  listDatasourcesFromProject(cartUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(listDatasourcesFromProject, {
      cartUri,
      options
    });
  }

  deleteCredentials(datasourceUri: string) {
    return this.fetchAppSync(deleteDatasourceCredentials, { datasourceUri });
  }

  getRoleIdFromRoleArn(roleArn: string) {
    return this.fetchAppSync(getRoleIdFromRoleArn, { roleArn });
  }

  listProviders(datasourceUri: string) {
    return this.fetchAppSync(listDatasourceProviders, { datasourceUri });
  }

  addProvider(datasourceUri: string, input: DatasourceProviderInput) {
    return this.fetchAppSync(addDatasourceProvider, {
      datasourceUri,
      input
    });
  }

  checkAvailabiltyLoaders(datasourceUri: string) {
    return this.fetchAppSync(checkAvailabiltyLoaders, { datasourceUri });
  }

  editProvider(datasourceUri: string, input: DatasourceProviderInput) {
    return this.fetchAppSync(editDatasourceProvider, {
      datasourceUri,
      input
    });
  }

  deleteDatasourceProvider(datasourceUri: string, id: string) {
    return this.fetchAppSync(deleteDatasourceProvider, {
      datasourceUri,
      id
    });
  }

  listAllMyDatasources(options: ?QueryOptions) {
    return this.fetchAppSync(listAllMyDatasources, { options }, true);
  }

  listAllMyDatasourcesTotal(options: ?QueryOptions) {
    return this.fetchAppSync(listAllMyDatasourcesTotal, { options }, true);
  }

  listAllMyDatasourcesGroups() {
    return this.fetchAppSync(listAllMyDatasourcesGroups, {}, true);
  }

  listDatasourceLogs(datasourceUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(listDatasourceLogs, {
      datasourceUri,
      options
    });
  }

  listNewDatasourceLogs(datasourceUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(listNewDatasourceLogs, { datasourceUri, options });
  }

  getDatasourceAdminCredentials(datasourceUri: string) {
    return this.fetchAppSync(getDatasourceAdminCredentials, { datasourceUri });
  }

  detectDatasets(datasourceUri: string) {
    return this.fetchAppSync(detectDatasets, { datasourceUri });
  }

  createDatasetsFromDetection(datasourceUri: string, input: Object) {
    return this.fetchAppSync(createDatasetsFromDetection, {
      datasourceUri,
      input
    });
  }

  isRegisteredWithLakeFormation(datasourceUri: string) {
    return this.fetchAppSync(isRegisteredWithLakeFormation, {
      accountUri: "",
      datasourceUri
    });
  }

  transferOwnership(datasourceUri: string, inputs: Object) {
    return this.fetchAppSync(transferOwnership, {
      datasourceUri,
      inputs
    });
  }

  detachEquansDatasource(datasourceUri: string) {
    return this.fetchAppSync(detachEquansDatasource, { datasourceUri });
  }

  shareDatasource(
    datasourceUri: string,
    groupUri: string,
    areNewDatasetsShared: boolean
  ) {
    return this.fetchAppSync(shareDatasource, {
      datasourceUri,
      groupUri,
      areNewDatasetsShared
    });
  }

  updateShareDatasource(
    datasourceUri: string,
    groupUri: string,
    areNewDatasetsShared: boolean
  ) {
    return this.fetchAppSync(updateShareDatasource, {
      datasourceUri,
      groupUri,
      areNewDatasetsShared
    });
  }

  deleteShareDatasource(datasourceUri: string, groupUri: string) {
    return this.fetchAppSync(deleteShareDatasource, {
      datasourceUri,
      groupUri
    });
  }

  listDatasourceShares(datasourceUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(listDatasourceShares, { datasourceUri, options });
  }

  listDatasetsShareableWithGroup(
    datasourceUri: string,
    options: ?QueryOptions
  ) {
    return this.fetchAppSync(listDatasetsShareableWithGroup, {
      datasourceUri,
      options
    });
  }

  shareSubsetDatasetsWithGroup(
    datasourceUri: string,
    groupUri: string,
    datasetList: Array<string>
  ) {
    return this.fetchAppSync(shareSubsetDatasetsWithGroup, {
      datasourceUri,
      groupUri,
      datasetList
    });
  }

  openRSDatasourceSqlWorkbench(datasourceUri: string, cartUri: ?string) {
    return this.fetchAppSync(openRSDatasourceSqlWorkbench, {
      datasourceUri,
      cartUri
    });
  }

  getRSDatasource(datasourceUri: string) {
    return this.fetchAppSync(queryGetRSDatasource, {
      accountUri: "",
      datasourceUri
    });
  }

  syncRSDatasourceDatabase(datasourceUri: string) {
    return this.fetchAppSync(syncRSDatasourceDatabase, {
      datasourceUri
    });
  }
}

export default DatasourceApi;
