/* @flow */

import React from "react";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import GroupName from "../users/components/groupName";
import route from "../routes";
import "./list.less";
import GraphQl from "../../graphQL";
import Paginator from "../../components/paginator";
import DBXGroupName from "./dbxGroupName";

type propTypes = {
  uriAccount: Object,
  goTo: Function,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  groups: Array<Object>,
  error: boolean,
  offset: number,
  keywords: string,
  total: number
};

class DatabricksGroupList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      groups: [],
      error: false,
      offset: 0,
      total: 0,
      keywords: ""
    };
  }

  componentDidMount() {
    this.listDatabricksGroups();
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        ready: false,
        offset: 0,
        keywords
      });
      this.listDatabricksGroups();
    }, 500);
  };

  listDatabricksGroups = () => {
    // const options = {
    //     limit: 10,
    //     offset: this.state.offset,
    //     search: undefined,
    // };

    // if (this.state.keywords) {
    //     options.search = {
    //         name: this.state.keywords,
    //         description: this.state.keywords,
    //         tags: this.state.keywords,
    //     };
    // }

    return this.props.api.databricks
      .listDatabricksAccountGroups(this.props.uriAccount)
      .then((groupsResult) => {
        if (!groupsResult) return false;
        this.setState({
          ready: true,
          groups: groupsResult.results
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getGroupsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listDatabricksGroups().then(r))
    );

  renderContent = () => {
    const { error, ready } = this.state;

    if (error) return <Error error={error} path={"GroupsList"} />;
    if (!ready) return <Loading message={"Groups List"} />;

    const { goTo } = this.props;

    return (
      <div className="card-container">
        {!error && (
          <Paginator
            limit={10}
            initialOffset={0}
            totalCount={this.state.total}
            list={this.state.groups}
            componentRender={(group) => (
              <div
                key={group.cdh_group_uri}
                className="card group-card card-shadow noPointer"
              >
                <div
                  className="card-group-left"
                  onClick={() =>
                    goTo({
                      route: route.Group.View,
                      params: {
                        uriGroup: group.cdh_group_uri
                      }
                    })
                  }
                >
                  <DBXGroupName group={group} />
                  <div>
                    {`${
                      group.is_account_admin ? "Administrators" : "Users"
                    } for databricks :${group.cdh_group_uri}`}
                  </div>
                </div>
                <div className="card-group-right"></div>
              </div>
            )}
            loadPage={this.getGroupsFromPagination}
            onNoResult={() => (
              <p className="total-search-results">No groups found</p>
            )}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="organizations-list">
        <div className="header">
          <div className="search">
            <div className="search-input">
              <input
                className="bg-white form-control"
                placeholder="Search Group !"
                onChange={this.onChangeInput}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        </div>

        {this.renderContent()}
      </div>
    );
  }
}

export default withAppSync(withGoTo(DatabricksGroupList));
