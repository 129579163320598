/* @flow */
import React from "react";
import { Link } from "react-router-dom";
// $FlowIgnore
import { compile } from "path-to-regexp";
import Routes from "../routes";

type PropTypes = {
  search: string,
  state: Object,
  className: string,
  style: Object,
  children: Array<any> // eslint-disable-line
};

const Links: Object = {}; // Provides functions to create links

const l = (props: PropTypes) =>
  React.Children.map(props.children, (child) => child);

// For each Key in Routes, creates the same key in Link
Object.keys(Routes).forEach((keyRoute) => {
  Links[keyRoute] = {};

  // For each path in Routes.Key, creates a path in Link
  Object.keys(Routes[keyRoute]).forEach((path) => {
    Links[keyRoute][path] = (props: PropTypes) => {
      const to = decodeURIComponent(compile(Routes[keyRoute][path])(props));
      const thelink = {
        pathname: to,
        search: props.search || "",
        state: props.state
      };
      return (
        <Link className={props.className} to={thelink} style={props.style}>
          {l(props)}
        </Link>
      );
    };
  });
});

export default Links;
