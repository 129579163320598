/* @flow */

import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../../../AppsyncHOC";
import Logger from "../../../../../utils/logger";
import GraphQl from "../../../../../graphQL";
import Col from "../../../../../components/col";
import Error from "../../../../../components/error";
import { updateMessage } from "../../../../globalNotifications/actions";
import Loading from "../../../../../components/loading";
import { REGIONS } from "../../../../../utils/constants";
import RedshiftListAwsClusters from "./redshiftListAwsClusters";
import "./redshiftClusterSearch.less";

const Log = Logger("RedshiftClusterForm");

type propTypes = {
  api: GraphQl,
  platform: Object,
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  }
};

type stateTypes = {
  ready: boolean,
  error: Object,
  errorSubmit: Object,
  selectedValue: {
    region: string
  }
};

class RedshiftClusterSearch extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: true,
      error: null,
      errorSubmit: false,
      selectedValue: {
        region: REGIONS[0].key
      }
    };
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      return { selectedValue };
    });
  }

  render() {
    const { selectedValue, error, ready } = this.state;
    if (error) return <Error error={error} path={"RedshiftClusterSearch"} />;
    if (!ready || !this.props.platform)
      return <Loading message={"Redshift Cluster Search"} />;
    return (
      <div className="RedshiftClusterSearch">
        <div className={"cluster-detect-form"}>
          <label className="label-form searchTitle">
            Lookup Redshift clusters on AWS account {this.props.platform.aws}
          </label>
          <Col className={"my-2"} size={12}>
            <Formik
              initialValues={{
                region: selectedValue.region
              }}
              validate={() => {
                const errors = {};
                if (!selectedValue.region || selectedValue.region.length <= 0) {
                  errors.region = "Clusters region is mandatory";
                }
                Log.info("validate", selectedValue, errors);
                return errors;
              }}
            >
              {
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      stringOnly
                      error={this.state.errorSubmit}
                      path={"create"}
                    />
                  )}
                  <fieldset className="form-group">
                    <legend className="label-form cluster-label-form">
                      AWS Account ID
                    </legend>
                    <Field
                      name="account_id"
                      disabled
                      className="form-control bg-grey"
                      placeholder="AWS Account ID"
                      value={this.props.platform.aws}
                    />
                  </fieldset>
                  <div className="cluster-detect-form">
                    <label className="label-form cluster-label-form">
                      Cluster Region
                    </label>
                    <select
                      onChange={(event) =>
                        this.handleChange("region", event.target.value)
                      }
                      value={selectedValue.region}
                      className="form-control form-control-sm"
                    >
                      {REGIONS.map((region) => (
                        <option key={region.key} value={region.key}>
                          {`(${region.key})  ${region.label}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form>
              }
            </Formik>
          </Col>
        </div>
        <div className={"listClusters"}>
          <RedshiftListAwsClusters
            region={this.state.selectedValue.region}
            platform={this.props.platform}
            api={this.props.api}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(RedshiftClusterSearch));
