export const getDatasourceETLSessionCredentials = {
  query: `
  query Q ($datasourceUri: String!, $overwrite: Boolean!) {
    getDatasourceEtlSessionCredentials( datasourceUri: $datasourceUri, overwrite: $overwrite) {
        AccessKeyId
        SecretKey
        Token
        Expiration
        DbUserName
        DbPassword
    }
  }
`,
  keyOutput: "getDatasourceEtlSessionCredentials"
};
