import { useEffect, useState } from "react";

const useDbxWorkspacesTabs = ({ api, playground, match }) => {
  const accountURI = match.params.uriAccount;
  const [error, setError] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [workspace, setWorkspace] = useState(null);

  const openCreationDialog = () => setOpenCreateDialog(true);
  const closeCreationDialog = () => setOpenCreateDialog(false);

  const getWorkspace = async () => {
    if (loading) return;
    setLoading(true);
    setReady(false);
    try {
      const wpc = await api.databricks.getDatabricksPlatformWorkspace(
        playground.uri
      );
      if (wpc.uuid !== null) {
        const wpcDtls = await api.databricks.getDatabricksWorkspaceDetails(
          wpc.uuid
        );
        setWorkspace(wpcDtls);
      }
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setReady(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getWorkspace();
  }, [ready]);

  return {
    accountURI,
    closeCreationDialog,
    error,
    getWorkspace,
    loading,
    openCreateDialog,
    openCreationDialog,
    ready,
    workspace
  };
};

export { useDbxWorkspacesTabs };
