export const listAvailableCartDatasets = {
  query: `query Q ($cartUri:String!, $options: QueryOptions) {
        listAvailableCartDatasets (cartUri: $cartUri, options: $options) {
            results {
                access
                accountid
                aws
                arn
                createdat
                account_name
                account_uri
                datasource_name
                datasource_uri
                owner_group_name
                default_table
                description
                domain
                environment
                name
                parent
                resource_name
                tags
                type
                updatedat
                uri
                size
                s3_inventory_last_update
                last_metadata_update
                nb_shares
                nb_carts
                nb_bookmarks
                nb_views
                is_bookmarked
            }
            total
            offset
            limit
        }
    }`,
  keyOutput: "listAvailableCartDatasets"
};
