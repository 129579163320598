/* @flow */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import HelpOn from "../../../../components/helpOn";

import "./style.less";

const Log = Logger("ProfilingAction");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  stage: {
    id: string,
    table: string,
    createdat: string,
    profiling_execution: {
      status: string
    },
    dataset: {
      uri: string,
      name: string,
      access: string
    },
    columns: Array<{
      Type: string,
      Name: string
    }>
  },
  reloadStage: Function
};

type stateTypes = {
  profilingRunLaunched: boolean,
  recentProfilingExecution: Object,
  reloadingStatus: boolean,
  runProfilingDropdownOpen: boolean,
  dataset?: Object,
  updateFrequencyIsLoading: boolean
};

class ProfilingAction extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    reloadStage: () => {
      Log.warning("ProfilingAction.reloadStage undefined");
    }
  };

  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      dataset: null,
      profilingRunLaunched: false,
      recentProfilingExecution: props.stage.profiling_execution,
      reloadingStatus: false,
      runProfilingDropdownOpen: false,
      updateFrequencyIsLoading: false
    };
  }

  componentDidMount() {
    this.fetchDataset();
    if (this.state.recentProfilingExecution.status === "RUNNING") {
      this.timeout = setTimeout(() => {
        this.setState({ reloadingStatus: true });
        return this.reloadProfilingStatus();
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentDidUpdate() {
    if (
      !this.state.reloadingStatus &&
      this.state.recentProfilingExecution.status === "RUNNING"
    ) {
      this.timeout = setTimeout(() => {
        this.setState({ reloadingStatus: true });
        return this.reloadProfilingStatus();
      }, 5000);
    }
  }

  reloadProfilingStatus = () =>
    this.props.api.dataset
      .getRecentStageProfilingExecution(
        this.props.stage.id,
        this.props.stage.dataset.uri
      )
      .then((statusProfiling) => {
        if (statusProfiling !== "RUNNING") {
          if (statusProfiling === "FAILED") {
            this.props.showGlobalNotification({
              message: "The profiling failed",
              type: "alert"
            });
          } else {
            this.props.showGlobalNotification({
              message: "The profiling ended",
              type: "success"
            });
          }

          return this.setState(
            {
              recentProfilingExecution: {
                status: statusProfiling
              },
              reloadingStatus: false
            },
            this.props.reloadStage
          );
        }
        return this.setState({
          recentProfilingExecution: {
            status: statusProfiling
          },
          reloadingStatus: false
        });
      })
      .catch(() => this.setState({ reloadingStatus: false }));

  runProfiling = () => {
    this.setState({
      runProfilingDropdownOpen: false
    });
    if (this.state.profilingRunLaunched === false) {
      this.setState({ profilingRunLaunched: true });
      this.props.api.dataset
        .mutationRunDataProfilingJob(this.props.stage.id)
        .then(() => {
          this.setState({
            profilingRunLaunched: false,
            recentProfilingExecution: { status: "RUNNING" }
          });
          this.props.showGlobalNotification({
            message: "The profiling started",
            type: "success"
          });
        })
        .catch((errorRunProfiling) => {
          if (
            errorRunProfiling &&
            errorRunProfiling.message.includes("job is already running")
          ) {
            this.props.showGlobalNotification({
              message: "A profiling job is already running for this stage",
              type: "alert"
            });
            this.setState({ profilingRunLaunched: false });
          } else {
            this.props.showGlobalNotification({
              message: "Failed to start profiling ",
              type: "alert"
            });
          }
        });
    }
  };

  updateProfilingFrequency = ({ frequency }) => {
    this.setState({
      runProfilingDropdownOpen: false,
      updateFrequencyIsLoading: true
    });
    this.props.api.dataset
      .update(this.props.stage.dataset.uri, { metadata_frequency: frequency })
      .then(() => {
        this.props.showGlobalNotification({
          message: "Profiling frequency changed",
          type: "success"
        });
        this.fetchDataset();
        this.setState({
          updateFrequencyIsLoading: false
        });
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "Profiling frequency failed",
          type: "alert"
        });
        this.setState({
          updateFrequencyIsLoading: false
        });
      });
  };

  fetchDataset = async () => {
    const dataset = await this.props.api.dataset.get(
      this.props.stage.dataset.uri
    );
    this.setState(() => {
      return {
        dataset
      };
    });
  };

  toggleProfilingDropdown = () => {
    this.setState((prevState) => {
      return {
        runProfilingDropdownOpen: !prevState.runProfilingDropdownOpen
      };
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.recentProfilingExecution.status !== "RUNNING" && (
          <button
            className="butn"
            type="button"
            onClick={() => this.runProfiling()}
          >
            <i className="fas fa-play" /> &nbsp; Run profiling
          </button>
        )}
        {this.state.recentProfilingExecution.status === "RUNNING" && (
          <button className="butn" type="button">
            <i className="fas fa-circle-notch fa-spin fa-spacing" />
            &nbsp; Profiling running ...
          </button>
        )}

        <div
          className="dropdown"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <button
            className="butn butn-flat dropdown-toggle"
            type="button"
            onClick={this.toggleProfilingDropdown}
          >
            {this.state.updateFrequencyIsLoading ? (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            ) : (
              <i className="fas fa-calendar fa-spacing" />
            )}
            Automatic profiling (
            {this.state.dataset
              ? this.state.dataset.metadata_frequency
              : "loading..."}
            )
          </button>
          <div
            className={`dropdown-menu ${
              this.state.runProfilingDropdownOpen ? "is-active" : ""
            }`}
            style={{
              right: 0,
              top: "46px",
              boxShadow: "0 2px 19px rgb(0 0 0 / 10%)",
              cursor: "pointer"
            }}
          >
            {["never", "daily", "weekly", "monthly"].map((frequency) => (
              <div
                key={frequency}
                className="dropdown-item"
                onClick={() => this.updateProfilingFrequency({ frequency })}
              >
                {this.state.dataset &&
                this.state.dataset.metadata_frequency === frequency ? (
                  <i className="fas fa-check fa-spacing" />
                ) : (
                  <span style={{ width: "24px", display: "inline-block" }} />
                )}
                {frequency}
              </div>
            ))}
          </div>
          <HelpOn
            content={
              <div>
                <h3>Caution</h3>
                <div>
                  This function uses the glue service and generates additional
                  costs
                </div>
              </div>
            }
          />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(ProfilingAction));
