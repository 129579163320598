export const listCollibraLinks = {
  query: `
  query Q ($accountUri: String!) {
    getAccount(accountUri: $accountUri) {
      collibraLinks{
          url
          id
          domain_id
          domain_name
          last_index_status
          last_wipe_status
          last_indexedat
          last_wipedat
          envname
          account_uri
          createdat
          auto_publish
          index_internal_datasets
          index_public_datasets
       }
    }
  }
`,
  keyOutput: "getAccount.collibraLinks"
};
