/* @flow */

export const getLastDataUpdate = {
  query: `query getLastDataUpdate ($uris: [String!]) {
        getLastDataUpdate(uris: $uris) {
            uri
            last_data_update
            size
            nb_views
        }
      }
    `,
  keyOutput: "getLastDataUpdate"
};
