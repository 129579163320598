export const getRSNamespaceDatasources = {
  query: `query Q ($namespaceUri:String!, $options:QueryOptions) {
        listRSNamespaceDatasources (namespaceUri:$namespaceUri, options:$options) {
          limit
          offset
          total
          results{
            parent
              uri
              name
              region
              bucket
              description
              accountid
              createdat
              user_roles
              source_type
              database
              datasetsList {
                total
              }
              owner {
                name
                resource_name
                uri
              }
              tags
          }
        }
    }
    `,
  keyOutput: "listRSNamespaceDatasources"
};
