/* @flow */

import React from "react";
import { connect } from "react-redux";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Links from "../../../links";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import Modal from "../../../../components/modal";
import "./redshiftClusterDetails.less";

type propTypes = {
  namespace: Object,
  reloadNamespaces: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  error: Object,
  deleteError: Object,
  loadingDeleteRSNamespace: boolean,
  deleteRSNamespaceModal: boolean
};

class RSNamespaceItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      deleteError: null,
      loadingDeleteRSNamespace: false,
      deleteRSNamespaceModal: false,
      loadingDetachRSNamespace: false
    };
  }

  openDeleteRSNamespaceModal = () =>
    this.setState({ deleteRSNamespaceModal: true });

  closeDeleteRSNamespaceModal = () =>
    this.setState({ deleteRSNamespaceModal: false, deleteError: null });

  deleteRSNamespaceModal() {
    return (
      <Modal
        title={"Confirm Deletion"}
        body={`Delete the namespace : "${this.props.namespace.name}" from commonDataHub and AWS ?`}
        errorMessage={this.state.deleteError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDeleteRSNamespaceModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.deleteRSNamespace()}
          >
            Delete
          </button>
        ]}
      />
    );
  }

  deleteRSNamespace = () => {
    this.setState({
      loadingDeleteRSNamespace: true
    });
    this.props.api.analytics
      .deleteRSNamespace(this.props.namespace.uri, true)
      .then((response) => {
        this.props.showGlobalNotification({
          message: "Redshift namespace deletion started",
          type: "success"
        });
        this.props.reloadNamespaces();
        this.setState({
          loadingDeleteRSNamespace: false
        });
      })
      .catch((deleteError) => {
        this.setState({
          deleteError,
          loadingDeleteRSNamespace: false
        });
      });
  };

  render() {
    const { namespace } = this.props;
    const { error, loadingDeleteRSNamespace, deleteRSNamespaceModal } =
      this.state;
    return (
      <div className={"cluster-card card-shadow card bg-white"}>
        {error && <Error error={error} path="RSNamespaceDetails" />}
        <div className="card-cluster-header">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fab fa-aws" />
              <span className="text-header-datasource">{namespace.aws}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className={`cart-environment-tag ${namespace.namespace}`}>
              <span>{namespace.environment}</span>
            </div>
          </div>
        </div>
        <div className="card-cluster-body">
          <div className="card-cluster-body-left">
            {namespace.namespace_status !== "NotFound" ? (
              <Links.RSNamespace.View namespaceUri={namespace.uri}>
                <span className={"endpoint-name"}>{namespace.name}</span>
              </Links.RSNamespace.View>
            ) : (
              <span className={"endpoint-name"}>{namespace.name}</span>
            )}

            <div className="card-cluster-details">
              <p className="card-cluster-description">
                {namespace.description && namespace.description.length > 100
                  ? `${namespace.description.substring(0, 50)}...`
                  : namespace.description}
              </p>
              {namespace.namespace_arn && (
                <div className="card-cluster-role">
                  <i className="fas fa-box" />
                  <span className="content-card-cluster">
                    <b> Namespace</b>: {namespace.namespace_name}
                  </span>
                </div>
              )}
              {namespace.workgroup_arn && (
                <div className="card-cluster-role">
                  <i className="fas fa-user-friends" />
                  <span className="content-card-cluster">
                    <b>Workgroup</b>: {namespace.workgroup_name}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="card-cluster-body-right">
            <div className="card-cluster-body-actions">
              {namespace.namespace_status !== "NotFound" &&
                namespace.namespace_status !== "deleting" && (
                  <div>
                    <Links.RSNamespace.View namespaceUri={namespace.uri}>
                      <i className="fas fa-play fa-spacing" />
                      <span className="text-actions">View Details</span>
                    </Links.RSNamespace.View>
                  </div>
                )}
              {namespace.namespace_status === "AVAILABLE" && (
                <div
                  className="btn-cart"
                  onClick={this.openDeleteRSNamespaceModal}
                >
                  {loadingDeleteRSNamespace && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-trash fa-spacing" />
                  <span className="text-actions">Delete</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-cluster-footer">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fas fa-globe-europe" />
              <span className="text-header-datasource">{namespace.region}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className="text-footer-metrics">
              Status{" "}
              <span
                className="tag-metrics"
                style={
                  namespace.namespace_status === "NotFound"
                    ? { backgroundColor: "red" }
                    : { backgroundColor: "green" }
                }
              >
                {namespace.namespace_status}
              </span>
            </div>
          </div>
        </div>
        <div>{deleteRSNamespaceModal && this.deleteRSNamespaceModal()}</div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(RSNamespaceItem)));
