/* @flow */

import React from "react";

type propTypesErrorTitle = {
  title?: string
};

const ErrorTitle = ({ title = "Ooops !" }: propTypesErrorTitle) => (
  <h4 className={"alert-heading text-primary font-weight-bold"}>{title}</h4>
);

export default ErrorTitle;
