/* eslint-disable quote-props */
/* @flow */
import React from "react";
import "./card.less";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import DatasetCardTitle from "./DatasetCardTitle";
import DatasetCardPublisher from "./DatasetCardPublisher";
import DatasetCardContainer from "./DatasetCardContainer";
import DatasetCardShared from "./DatasetCardShared";
import { findDomainName } from "../components/constants";
import BookmarkStar from "../components/bookmarkStar";
import { formatDate } from "../../../utils/date";
import { convertSize } from "../../../utils/size";
import NbBookmarksStar from "../components/nbBookmarksStar";
import withErrorBoundary from "../../errorBoundaryHOC";

type propTypes = {
  dataset: Object,
  goTo: Function,
  style: Object,
  addDatasetToList: Function,
  removeDatasetToList: Function,
  defaultSelect: boolean
};

type stateTypes = {
  isSelected: boolean,
  nb_bookmarks: number
};

const classEnvironment = {
  NoProd: "NoProd",
  Prod: "Prod"
};

class DatasetCardAddToProject extends React.Component<propTypes, stateTypes> {
  DOMRef: Object;

  constructor(props) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {
      isSelected: false,
      nb_bookmarks: props.dataset.nb_bookmarks
    };
  }

  addDatasetToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.addDatasetToList(this.props.dataset);
  };

  removeDatasetToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeDatasetToList(this.props.dataset);
  };

  renderActions() {
    if (this.props.dataset.access !== "other") {
      if (this.props.defaultSelect) {
        return [
          <div
            className="butn butn-flat min-width-130"
            onClick={(e) => this.removeDatasetToList(e)}
          >
            <i className={"butn-icon fas fa-check-square"} />
            <span className="butn-text">Unselect</span>
          </div>
        ];
      }
      return [
        <div
          className="butn butn-flat min-width-130"
          onClick={(e) => this.addDatasetToList(e)}
        >
          <i className={"butn-icon far fa-square"} />
          <span className="butn-text">Select</span>
        </div>
      ];
    }
    return false;
  }

  render() {
    return (
      <DatasetCardContainer
        style={this.props.style}
        ref={this.DOMRef}
        isSelected={this.state.isSelected}
        access={this.props.dataset.access}
        goToDataset={() =>
          this.props.goTo({
            route: routes.Dataset.View,
            params: {
              uriDataset: this.props.dataset.uri
            },
            state: {
              dataset: {
                uri: this.props.dataset.uri,
                name: this.props.dataset.name
              }
            }
          })
        }
        dataset={{
          key: Math.random(),
          uri: this.props.dataset.uri,
          name: this.props.dataset.name
        }}
      >
        <div className="card-dataset-header">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content">
              <BookmarkStar
                datasetUri={this.props.dataset.uri}
                isBookmarked={this.props.dataset.is_bookmarked}
                onChange={(value) =>
                  this.setState((prevState) => ({
                    nb_bookmarks: prevState.nb_bookmarks + value
                  }))
                }
              />
            </div>
            <div className="card-dataset-begin-content">
              <i className="far fa-suitcase" />
              <span className="text-header-dataset">
                {findDomainName(this.props.dataset.domain)}
              </span>
            </div>
          </div>
          <div className="card-dataset-end">
            <span className="text-type-dataset">
              {this.props.dataset.type} Data
            </span>
            <div
              className={`dataset-card-environment ${
                classEnvironment[this.props.dataset.environment]
              }`}
            >
              <span>{this.props.dataset.environment}</span>
            </div>
            <DatasetCardShared access={this.props.dataset.access} />
          </div>
        </div>
        <div className="card-dataset-body">
          <div className="card-dataset-body-left">
            <div>
              <DatasetCardTitle
                name={this.props.dataset.name}
                uri={this.props.dataset.uri}
                access={this.props.dataset.access}
                defaultTable={this.props.dataset.default_table}
                type={this.props.dataset.type}
              />
              <DatasetCardPublisher
                publisher={this.props.dataset.account_name}
                ownergroupname={this.props.dataset.owner_group_name}
                size={250}
              />
              <div className="card-size-details">
                <p className="card-size-updateDate">
                  <span className="font-weight-bold">Last Data Update : </span>
                  {formatDate(
                    this.props.dataset.s3_inventory_last_update
                  )} | <span className="font-weight-bold">Size : </span>
                  {convertSize(this.props.dataset.size)}
                </p>
              </div>
            </div>
            <div className="card-dataset-details">
              <p
                className="card-dataset-description"
                title={this.props.dataset.description}
              >
                {this.props.dataset.description}
              </p>
            </div>
            <div>
              <div className="card-dataset-tags">
                <div className="">
                  {this.props.dataset.tags
                    .split(",")
                    .filter((tag) => tag && tag.trim())
                    .map((tag) => (
                      <div className="dataset-badge" key={tag}>
                        <div className="circle-tag" />
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">{this.renderActions()}</div>
        </div>
        <div className="card-dataset-footer">
          <div className="card-dataset-begin">
            <div className="card-dataset-begin-content d-flex">
              <i className="fas fa-database"></i>
              <span className="text-header-dataset">
                {this.props.dataset.datasource_name}
              </span>
            </div>
          </div>
          <div className="card-dataset-end">
            <div className="text-footer-metrics">
              Seen&nbsp;
              <span className="tag-metrics">
                {this.props.dataset.nb_views === null
                  ? "0"
                  : this.props.dataset.nb_views}
              </span>
              , Shared with&nbsp;
              <span className="tag-metrics">
                {this.props.dataset.nb_shares === null
                  ? "0"
                  : this.props.dataset.nb_shares}
              </span>
              &nbsp;groups, in&nbsp;
              <span className="tag-metrics">
                {this.props.dataset.nb_carts === null
                  ? "0"
                  : this.props.dataset.nb_carts}
              </span>
              &nbsp;projects&nbsp;
              <NbBookmarksStar
                nb={!this.state.nb_bookmarks ? 0 : this.state.nb_bookmarks}
              />
            </div>
          </div>
        </div>
      </DatasetCardContainer>
    );
  }
}

export default withErrorBoundary(withGoTo(DatasetCardAddToProject), false);
