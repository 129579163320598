/* @flow */

export const updateDatasourceDetails = {
  query: `
  mutation Q ($accountUri: String!, $datasourceUri: String!, $input: UpdateDatasourceInput!) {
    updateDatasourceDetails(accountUri: $accountUri, datasourceUri: $datasourceUri, input: $input) {
      uri
    }
  }
`,
  keyOutput: "updateDatasourceDetails"
};

export type UpdateDatasourceInput = {
  description: string,
  details: string,
  name: string,
  owneruri: string,
  s3_custom_tags: Array<Object>,
  sharing_group_uri: string
};
