export const findByGid = {
  query: `query Q ($gid: String!) {
        findByGid(gid: $gid) {
            id
            identities {
                uri
                name
                account { uri name }
                groupsList {
                    total
                    limit
                    offset
                    results {
                        uri
                        name
                    }
                }
            }
        }
    }`,
  keyOutput: "findByGid"
};
