export const updateDatabricksAccountCredentialsManual = {
  query: `mutation Q($dbx_credentials_client_secret_id: String!, $dbx_credentials_client_secret_value: String!, $dbx_credentials_service_principal_id: String!, $uuid: String!) {
            updateDatabricksAccountCredentialsManual(
            dbx_credentials_client_id: $dbx_credentials_service_principal_id, 
            dbx_credentials_client_secret_id: $dbx_credentials_client_secret_id, 
            dbx_credentials_client_secret_value: $dbx_credentials_client_secret_value, 
            dbx_credentials_service_principal_id: $dbx_credentials_service_principal_id, 
            uuid: $uuid) {
              error
              status
        }
      }
      `,
  keyOutput: "updateDatabricksAccountCredentialsManual"
};
