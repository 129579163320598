/* @flow */
import React from "react";

type propTypes = {
  datasource: {
    owner: {
      name: string
    },
    database: string
  }
};

const DatasourceInformation = ({ datasource }: propTypes) => (
  <div className="mb-5 container datasource-detect-datasets-info">
    <div className="datasource-detect-datasets-1">
      <label className="label-form title-s">Datasource information</label>

      <div className="mb-1">
        <label className="label-form">Group owner</label>
        <input
          className="form-control"
          value={datasource.owner.name}
          disabled
        />
      </div>

      <div className="mb-1">
        <label className="label-form">Database</label>
        <input className="form-control" value={datasource.database} disabled />
      </div>
    </div>
  </div>
);

export default DatasourceInformation;
