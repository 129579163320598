/* @flow */

import React from "react";
import classnames from "classnames";
import "./style.less";
import { formatDate } from "../../../../utils/date";

type propTypes = {
  notification: any,
  content: any,
  label: any
};

type stateTypes = {
  isRead: boolean
};

class NotificationSkeleton extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isRead: props.notification.is_read
    };
  }

  componentDidUpdate(): void {
    if (this.state.isRead !== this.props.notification.is_read) {
      this.updateNotif();
    }
  }

  updateNotif = () => {
    this.setState({ isRead: this.props.notification.is_read });
  };

  static defaultProps = {
    notification: {
      id: "",
      is_read: false,
      createdat: "00-00-0000 00:00:00",
      updatedat: undefined,
      message: "",
      target_uri: "",
      data: {},
      type: {
        id: "",
        name: "",
        code: "",
        domain: "",
        description: ""
      }
    }
  };

  render() {
    return (
      <div
        key={this.props.notification.id}
        className={classnames("notification", { "is-read": this.state.isRead })}
      >
        {this.props.label && (
          <div className="notification-left-part">{this.props.label}</div>
        )}

        <div className={"notification-right-part"}>
          <div className={"notification-main"}>
            <div className={"notification-content"}>{this.props.content}</div>
          </div>

          <div className={"notification-footer"}>
            <div className={"notification-date"}>
              Received the {formatDate(this.props.notification.createdat)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationSkeleton;
