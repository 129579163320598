export const getCredentials = {
  query: `query Q ($cartUri:String!, $platformUri:String!){
        getSecureToken (cartUri:$cartUri, platformUri:$platformUri) {
            SecretKey
            Token
            AccessKeyId
        }
      }
    `,
  keyOutput: "getSecureToken"
};
