/* @flow */

import React from "react";
import { connect } from "react-redux";
import GraphQl from "../../graphQL";
import { updateMessage } from "../globalNotifications/actions";
import Modal from "../../components/modal";
import ButtonAction from "../../components/buttonAction";
import withAppSync from "../AppsyncHOC";

type propTypes = {
  dataset: {
    uri: string,
    name: string
  },
  datafilter: {
    id: string,
    name: string
  },
  api: GraphQl,
  onDelete: Function,
  hideModal: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: ?string,
  isSubmitting: boolean
};

class DataFilterDeleteModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isSubmitting: false
    };
  }

  deleteDataFilter = () => {
    this.setState({ error: undefined, isSubmitting: true });
    return this.props.api.dataset
      .deleteDataFilter(this.props.dataset.uri, this.props.datafilter.id)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Delete succeeded",
          type: "success"
        });
      })
      .then(this.props.onDelete)
      .catch((error) => {
        this.setState({ error, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Delete failed",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <Modal
        title={"Delete Data Filter"}
        body={"Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key={`delete-data-filter-delete-${this.props.datafilter.id}`}
            label="Delete"
            className="butn butn-delete"
            onClick={this.deleteDataFilter}
            icon={
              this.state.isSubmitting
                ? "fas fa-circle-notch fa-spin"
                : undefined
            }
          />,
          <ButtonAction
            key={`delete-data-filter-close-${this.props.datafilter.id}`}
            label="Close"
            className="butn butn-flat"
            onClick={this.props.hideModal}
          />
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DataFilterDeleteModal)
);
