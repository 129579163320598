export const disableFeatureToggle = {
  query: `mutation Q ($id: String!) {
    disableFeatureToggle(id: $id) {
      id
      code
      description
      version
      environment
      status
    }
  }
`,
  keyOutput: "disableFeatureToggle"
};
