const routes = {
  Homepage: {
    View: "/"
  },
  MyData: {
    View: "/my-data"
  },
  MyDataV2: {
    View: "/datasets"
  },
  SqlLab: {
    View: "/sqllab"
  },
  User: {
    MyProfile: "/myprofile",
    Notifications: "/myprofile#notifications",
    Newtifications: "/myprofile#newtifications",
    NotificationSettings: "/myprofile#info-notifications",
    List: "/organization/:uriAccount/users",
    Create: "/organization/:uriAccount/user/create",
    Edit: "/organization/:uriAccount/user/edit",
    View: "/user/:uri",
    ShareNew: "/myprofile#shares-new",
    ShareWithMe: "/myprofile#shares-requested",
    RecentActivities: "/myprofile#recent-activities",
    ShareOwned: "/myprofile#shares-owner",
    CDHAdmin: "/myprofile#cdhadmin"
  },
  Group: {
    List: "/organization/:uriAccount/groups",
    View: "/group/:uriGroup/view",
    Create: "/organization/:uriAccount/group/create",
    Edit: "/organization/:uriAccount/group/:uriGroup/edit",
    EditUsers: "/group/:uriGroup/edit-users"
  },
  Playground: {
    All: "/organizations",
    List: "/organization/:uriAccount/organizations",
    View: "/organization/:uriAccount/environment/:uriPlayground/view",
    Create: "/organization/:uriAccount/environment/create",
    Edit: "/organization/:uriAccount/environment/:uriPlayground/edit",
    Groups: "/organization/:uriAccount/environment/:uriPlayground/groups",
    CreateDatasource:
      "/organization/:uriAccount/environment/:uriPlayground/datasource/create",
    CreateDataset:
      "/organization/:uriAccount/environment/:uriPlayground/dataset/create",
    Admin: "/organization/:uriAccount/environment/:uriPlayground/admin",
    Network:
      "/organization/:uriAccount/environment/:uriPlayground/admin#networks",
    NotebooksAdmin:
      "/organization/:uriAccount/environment/:uriPlayground/admin#notebooks",
    Analytics:
      "/organization/:uriAccount/environment/:uriPlayground/admin#analytics",
    AccelerationTemplate:
      "/organization/:uriAccount/environment/:uriPlayground/admin#acceleration-template",
    LakeFormation:
      "/organization/:uriAccount/environment/:uriPlayground/admin#lakeformation",
    Archives:
      "/organization/:uriAccount/environment/:uriPlayground/admin#archives",
    MigrationReport:
      "/organization/:uriAccount/environment/:uriPlayground/migration/:migrationId",
    CreateCluster:
      "/organization/:uriAccount/environment/:uriPlayground/analytics/create",
    ImportClusters:
      "/organization/:uriAccount/environment/:uriPlayground/analytics/import",
    SmStudio:
      "/organization/:uriAccount/environment/:uriPlayground/admin#smstudio",
    DbxWorkspace:
      "/organization/:uriAccount/environment/:uriPlayground/admin#dbxworkspace",
    CreateRSNamespace:
      "/organization/:uriAccount/environment/:uriPlayground/analytics/createnamespace"
  },
  Network: {
    Create:
      "/organization/:uriAccount/environment/:uriPlayground/network/create",
    Edit: "/organization/:uriAccount/environment/:uriPlayground/network/:networkId/edit",
    View: "/organization/:uriAccount/environment/:uriPlayground/network/:networkId"
  },
  Dataset: {
    Catalog: "/catalog",
    CatalogV2: "/catalogV2",
    View: "/dataset/:uriDataset/view",
    Create: "/dataset/create",
    Edit: "/dataset/:uriDataset/edit",
    Preview: "/dataset/:uriDataset/view#preview",
    Share: "/dataset/:uriDataset/view#shares",
    UploadData: "/dataset/:uriDataset/view#upload",
    Stages: "/dataset/:uriDataset/view#stages",
    DataFilters: "/dataset/:uriDataset/view#datafilters",
    CreateDataFilter: "/dataset/:uriDataset/stage/:stageId/createDataFilter",
    AddToCart: "/dataset/:uriDataset/addtoproject",
    Discuss: "/dataset/:uriDataset/discuss"
  },
  Datasource: {
    View: "/datasource/:uriAccount/:uriDatasource/view",
    Datasets: "/datasource/:uriAccount/:uriDatasource/view#datasets",
    Logs: "/datasource/:uriAccount/:uriDatasource/view#logs",
    Create: "/datasource/create",
    CreateDataset: "/datasource/:uriAccount/:uriDatasource/dataset/create",
    Edit: "/datasource/:uriAccount/:uriDatasource/edit",
    Transfer: "/datasource/:uriAccount/:uriDatasource/transfer",
    Groups: "/datasource/:uriAccount/:uriDatasource/groups",
    GenerateETLCredentials:
      "/datasource/:uriAccount/:uriDatasource/generateetlcredentials&overwrite=:overwrite",
    GenerateETLSessionCredentials:
      "/datasource/:uriAccount/:uriDatasource/generateetlsessioncredentials&overwrite=:overwrite",
    GenerateAdminCredentials:
      "/datasource/:uriAccount/:uriDatasource/generateadmincredentials",
    DetectDataset: "/datasource/:uriAccount/:uriDatasource/detect-dataset",
    DetectRSDataset: "/datasource/:uriAccount/:uriDatasource/detect-rsdataset",
    ShareDatasets: "/datasource/:uriAccount/:uriDatasource/share-datasets",
    Collibra: "/datasource/:uriAccount/:uriDatasource/collibra"
  },
  Cart: {
    List: "/projects",
    Create: "/project/create",
    Edit: "/project/:cartUri/edit",
    View: "/project/:cartUri/view",
    GenerateCredentials: "/project/:cartUri/:uriPlayground/generatecredentials",
    ConnectionProperties:
      "/project/:cartUri/:uriPlayground/connectionproperties",
    GlueJobParameters: "/project/:cartUri/:uriPlayground/gluejobparameters",
    ManagePolicies: "/project/:cartUri/:uriPlayground/managepolicies",
    AddDataset: "/project/:cartUri/adddataset/:groupUri",
    CreateDatabrewProject: "/project/:cartUri/createDatabrewProject",
    EditDatabrewProject: "/project/:cartUri/updateDatabrewProject/:databrewId",
    AddAnalytics: "/project/:cartUri/addanalytics",
    AddSagemakerNotebook: "/project/:cartUri/addsagemakernotebook",
    EditSagemakerNotebook:
      "/project/:cartUri/editsagemakernotebook/:instanceId",
    AddLogGroup: "/project/:cartUri/addloggroup",
    EditLogGroup: "/project/:cartUri/editlogsgroup/:logsGroupId",
    Transfer: "/project/:cartUri/transfer"
  },
  App: {
    List: "/apps/",
    Play: "/apps/:app"
  },
  Account: {
    View: "/organization/:uriAccount",
    ViewUsers: "/organization/:uriAccount#users",
    ViewGroups: "/organization/:uriAccount#groups",
    ViewEnvironments: "/organization/:uriAccount#environments",
    ViewDatasources: "/organization/:uriAccount#datasources",
    ViewPolicies: "/organization/:uriAccount#policies",
    ViewTrust: "/organization/:uriAccount#trust",
    List: "/organizations",
    Create: "/organization/create",
    Edit: "/organization/:uriAccount/edit",
    ViewDSS: "/organization/:uriAccount#dss",
    ViewCollibra: "/organization/:uriAccount#collibra",
    ViewDatabricks: "/organization/:uriAccount#databricks",
    DatabricksWorkspaces: "/organization/:uriAccount#databricks/workspaces"
  },
  Share: {
    Request: "/share/request/:uriDataset",
    Manage: "/share",
    View: "/share/:shareId/view",
    ShareNew: "/share#shares-new",
    ShareWithMe: "/share#shares-requested",
    ShareOwned: "/share#shares-owner",
    AddItem: "/share/:shareId/additem"
  },
  Analyze: {
    Global: "/:uriAccount/analyze"
  },
  Stage: {
    View: "/dataset/:datasetUri/stage/:outputId/view",
    Edit: "/dataset/:datasetUri/stage/:id/edit",
    AddDataFilter: "/dataset/:datasetUri/stage/:id/adddatafilter"
  },
  Analytics: {
    View: "/analytics/:clusterUri/view",
    Create: "/analytics/:clusterUri/view",
    CartView: "/analytics/:clusterUri/:cartUri/view"
  },
  AccelerationTemplate: {
    View: "/organization/:uriAccount/environment/:uriPlayground/acceleration-template/:id",
    Grant:
      "/organization/:uriAccount/environment/:uriPlayground/acceleration-template/:id/grant"
  },
  DssInstance: {
    List: "/organization/:uriAccount/dssInstances",
    View: "/organization/:uriAccount/dssinstance/:uriDssInstance/view",
    Create: "/organization/:uriAccount/dssinstance/create",
    Edit: "/organization/:uriAccount/dssinstance/:uriDssInstance/edit",
    Groups: "/organization/:uriAccount/dssinstance/:uriDssInstance/groups"
  },
  Databricks: {
    List: "/organization/:uriAccount/databricksInstances",
    Groups:
      "/organization/:uriAccount/dssinstance/:uriDatabricksInstance/groups",
    DetailsAccount:
      "/organization/:uriAccount/databricks/account/:uuid/details",
    Create: "/organization/:uriAccount/databricks/create",
    WorkspaceDetails:
      "/organization/:uriAccount/databricks/workspace/:uuid/details"
  },
  RSNamespace: {
    View: "/rsnamespace/:namespaceUri/view"
  }
};

export default routes;
