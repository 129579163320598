/* @flow */
import React from "react";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import ItemFromList from "../../../components/itemFromList";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import { getDatasetNameFromLocation } from "../../../utils/getValueFromLocation";
import Logger from "../../../utils/logger";
import Breadcrumb from "../../../components/breadcrumb";
import route from "../../routes";
import { updateMessage } from "../../globalNotifications/actions";
import GraphQl from "../../../graphQL";
import ButtonAction from "../../../components/buttonAction";
import Links from "../../links";
import Paginator from "../../../components/paginator";

const Log = Logger("AddToCart");

type propTypesShareRequestAction = {
  uri: string,
  name: string,
  groupuri: string
};
const ShareRequestAction = ({
  uri,
  name,
  groupuri
}: propTypesShareRequestAction) => (
  <div className="actionIcon tooltiped">
    <Links.Share.Request
      uriDataset={uri}
      state={{ dataset: { uri, name }, groupUri: groupuri }}
    >
      <div className="butn butn-flat">
        <i className="fas fa-lock-open butn-icon" />
        <span className="butn-text">Request Access</span>
      </div>
    </Links.Share.Request>
  </div>
);

type propTypes = {
  match: {
    params: {
      uriDataset: string
    }
  },
  location: {
    state: {
      dataset: {
        name: string
      }
    }
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};
type stateTypes = {
  ready: boolean,
  isFetching: boolean,
  reset: boolean,
  carts: Array<Object>,
  dataset: Object,
  error: any,
  searchFilter: string,
  cartsTotal: number
};
class AddToCart extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isFetching: false,
      reset: false,
      carts: [],
      error: false,
      dataset: {},
      searchFilter: "",
      cartsTotal: 0
    };
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.getDataset().then(this.searchCarts);
    } else {
      this.setState(
        { dataset: this.props.location.state.dataset },
        this.searchCarts
      );
    }
  }

  getDataset = () =>
    this.props.api.dataset
      .get(this.props.match.params.uriDataset)
      .then((dataset) => {
        this.setState({ dataset });
      })
      .catch((error) => {
        this.setState({ error });
        return {};
      });

  onChangeInput = (e) => {
    const newFilter = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        ready: false,
        searchFilter: newFilter,
        reset: true
      });
      this.searchCarts();
    }, 500);
  };

  searchCarts = (offset = 0) => {
    this.setState({ isFetching: true });
    const { searchFilter } = this.state;
    const options = searchFilter
      ? {
          search: {
            name: searchFilter,
            description: searchFilter,
            tags: searchFilter
          },
          limit: 10,
          offset
        }
      : {
          limit: 10,
          offset
        };

    return this.props.api.cart
      .getCartsForDataset(this.props.match.params.uriDataset, options, {
        search: { uri: this.state.dataset.uri }
      })
      .then((items) => {
        if (!items) return false;
        const foundCarts = this.setActions(items.results);
        this.setState({
          ready: true,
          carts: foundCarts,
          cartsTotal: items.total,
          isFetching: false,
          reset: false
        });
        return foundCarts;
      })
      .catch((error) => {
        this.setState({
          error,
          isFetching: false,
          reset: false
        });
        return [];
      });
  };

  setActions = (items) => {
    const cartsResult = [];
    items.map((cart) => {
      const dataseturis = cart.datasetsList.results.map((d) => d.uri);
      const dataseturi = this.props.match.params.uriDataset;
      const has = dataseturis.indexOf(dataseturi) !== -1;

      if (cart.access === "other") {
        cart.action = 3; // REQUEST ACCESS
      }

      if (cart.access !== "other") {
        cart.action = 2; // ADD
      }

      if (has) {
        cart.action = 1; // REMOVE
      }

      cartsResult.push(cart);
      return false;
    });

    return cartsResult;
  };

  cartActions = (cart) => {
    if (cart.action === 1) {
      return [
        <ButtonAction
          label="Remove"
          className="butn butn-delete"
          onClick={() => this.removeDatasetToCart(cart.uri)}
        />
      ];
    }

    if (cart.action === 2) {
      return [
        <ButtonAction
          label="Add"
          className="butn butn-create"
          onClick={() => this.addDatasetToCart(cart.uri)}
        />
      ];
    }

    if (cart.action === 3) {
      const dataseturi = this.props.match.params.uriDataset;
      return [
        <ShareRequestAction
          uri={dataseturi}
          name={this.state.dataset.name}
          groupuri={cart.groupuri}
        />
      ];
    }

    return false;
  };

  addDatasetToCart = (cartUri) => {
    const dataseturi = this.props.match.params.uriDataset;
    return this.props.api.cart
      .addDataset(cartUri, dataseturi)
      .then(() => {
        this.toggleAction(cartUri);
        this.props.showGlobalNotification({
          message: "Dataset added to the project",
          type: "success"
        });
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error has occured during dataset addition",
          type: "alert"
        });
        this.setState({
          error
        });
      });
  };

  removeDatasetToCart = (cartUri) => {
    const dataseturi = this.props.match.params.uriDataset;
    return this.props.api.cart
      .removeDataset(cartUri, dataseturi)
      .then(() => {
        this.toggleAction(cartUri);
        this.props.showGlobalNotification({
          message: "Dataset removed from the project",
          type: "success"
        });
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error has occured during dataset removal",
          type: "alert"
        });
        this.setState({
          error
        });
      });
  };

  toggleAction = (cartUri) => {
    let index = 0;
    this.state.carts.filter((e, i) => {
      // find cart index in carts list
      if (e.uri === cartUri) index = i;
      return e.uri === cartUri;
    });

    this.setState((prevState) => {
      const carts = [].concat(prevState.carts);
      if (carts[index].action === 1) {
        carts[index].action = 2;
        return { carts };
      }

      if (carts[index].action === 2) {
        carts[index].action = 1;
        return { carts };
      }

      return { carts };
    });
  };

  render() {
    return (
      <React.Fragment>
        <Breadcrumb
          view={`Add dataset \`${getDatasetNameFromLocation({
            location: this.props.location,
            match: this.props.match
          })}\` to one of your projects`}
        />
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search Project !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={() =>
                  this.props.goTo({
                    route: route.Cart.Create,
                    params: {}
                  })
                }
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New Project</span>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {this.state.error && (
            <Error error={this.state.error} path={"AddToCart"} />
          )}
          {this.state.reset && <Loading message="Projects List" />}
          {!this.state.error && !this.state.reset && (
            <Paginator
              key="list-add-dataset-cart"
              container={(content) => <ul className="list-group">{content}</ul>}
              limit={10}
              totalCount={this.state.cartsTotal}
              initialOffset={0}
              isLoadingPage={!this.state.ready || this.state.isFetching}
              list={this.state.carts}
              componentRender={(cart) => (
                <ItemFromList
                  key={cart.uri}
                  name={`${cart.name}`}
                  description={cart.description}
                  uri={cart.uri}
                  isLink
                  actions={this.cartActions(cart)}
                />
              )}
              loadPage={this.searchCarts}
              onNoResult={() => (
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px"
                  }}
                >
                  No project to display.
                </div>
              )}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(AddToCart));
