/* @flow */
import React from "react";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import ListNotebooksNetwork from "./components/listNotebooksNetwork";
import "./style.less";

type propTypes = {
  playground: Object,
  api: GraphQl
};

type stateTypes = {
  list: {
    results: Array<Object>,
    offset: number,
    limit: number,
    total: number
  },
  ready: boolean,
  error: ?Object
};

class PlaygroundNotebooks extends React.PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      list: {},
      ready: false,
      error: undefined
    };
  }

  componentDidMount() {
    this.listSagemakerNotebookInstances();
  }

  listSagemakerNotebookInstances = (offset) => {
    this.props.api.playground
      .listSagemakerNotebookInstances(this.props.playground.uri, offset)
      .then((list) => {
        this.setState({
          ready: true,
          list
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="PlaygroundNotebooks" />;
    if (!this.state.ready) return <Loading message="instances" />;
    return (
      <div className="list-notebooks">
        <div className="list-notebooks-flex" style={{ marginTop: "10px" }}>
          {this.state.list.results.length > 0 ? (
            <ListNotebooksNetwork
              sagemakerInstances={this.state.list}
              loadPage={this.listSagemakerNotebookInstances}
              withNetworkCol
            />
          ) : (
            <div>No instance to display.</div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppSync(PlaygroundNotebooks);
