export const listCarts = {
  query: `query Q ($options: QueryOptions $datasetUri: String,, $datasetOptions: QueryOptions) {
        getMyCartsList (options: $options, datasetUri: $datasetUri) {
            offset
            limit
            total
            results {
                access
                uri
                groupuri
                name
                project_custom_tags {
                    Key
                    Value
                }
                creatoruri
                description
                resource_name
                region
                createdat
                datasetsList (options: $datasetOptions) {
                    results {
                        uri
                        name
                        description
                        source_type
                    }
                }
                group {
                    uri
                    name
                }
            }
        }
    }
    `,
  keyOutput: "getMyCartsList"
};
