export const getCartTables = {
  query: `query Q ($cartUri: String!, $platformUri: String!) {
        getCartDatabaseTables(cartUri: $cartUri, platformUri:$platformUri)
            {
                aws
                region
                database
                tablename
                columns{
                    Name
                    Type
                }
            }
    }
    `,
  keyOutput: "getCartDatabaseTables"
};
