/* @flow */
import React from "react";

type propTypes = {
  isDefault: boolean,
  access: string,
  reloadStages?: Function
};

class DisplayStage extends React.Component<propTypes> {
  render() {
    if (this.props.isDefault) {
      return (
        <div className="default-tag">
          <span>DEFAULT STAGE</span>
        </div>
      );
    }

    if (this.props.access === "owner") {
      return (
        <div className="set-default-tag" onClick={this.props.reloadStages}>
          <span className="text-set-default">Set As Default</span>
        </div>
      );
    }
    return false;
  }
}

export default DisplayStage;
