export const listPolicySlots = {
  query: `query Q ($projectUri: String!, $platformUri: String!) {
        listProjectPolicySlots(projectUri: $projectUri, platformUri: $platformUri) {
            max_slots
            selected {
                platform_uri
                slots
            }
        }
    }
    `,
  keyOutput: "listProjectPolicySlots"
};
