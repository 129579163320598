/* @flow */

export const createDataset = {
  query: `mutation Q ($accountUri: String!, $datasourceUri: String!, $input: NewDatasetInput!) {
        createDataset(accountUri: $accountUri, datasourceUri: $datasourceUri, input: $input) {
          uri
        }
      }
    `,
  keyOutput: "createDataset"
};

export type CreateDatasetPropsTypes = {
  uri: string
};
