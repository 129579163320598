import React from "react";
import PropTypes from "prop-types";
import "./style.less";

const LabelCDH = ({ style = {}, className = "", ...rest }) => (
  <div style={style} className={`label-cdh ${className}`} {...rest}>
    Team CDH
  </div>
);

LabelCDH.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default LabelCDH;
