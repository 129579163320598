export const getCartRedshiftCluster = {
  query: `query Q ($clusterUri:String!,$cartUri:String!) {
        getCartRedshiftCluster (clusterUri:$clusterUri,cartUri:$cartUri) {
            redshift_dsn
            redshiftcluster_uri
            database_user
        }
    }
    `,
  keyOutput: "getCartRedshiftCluster"
};
