export const toHex = (s) => {
  // utf8 to latin1
  const string = unescape(encodeURIComponent(s));
  let h = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < string.length; i++) {
    h += s.charCodeAt(i).toString(16);
  }
  return h;
};

export const fromHex = (h) => {
  let s = "";
  for (let i = 0; i < h.length; i += 2) {
    s += String.fromCharCode(parseInt(h.substr(i, 2), 16));
  }
  return decodeURIComponent(escape(s));
};
