/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../links";
import withAppSync from "../AppsyncHOC";
import { updateMessage } from "../globalNotifications/actions";
import GraphQl from "../../graphQL";
import Stringslider from "../../components/stringslider";
import Modal from "../../components/modal";
import ButtonAction from "../../components/buttonAction";
import "./card.less";
import { getErrorMessageWithoutCode } from "../../utils/error";
import { formatDate } from "../../utils/date";

type propTypes = {
  api: GraphQl,
  cart: {
    uri: string,
    groupuri: string,
    group: {
      uri: string,
      name: string
    },
    name: string,
    region: string,
    datasetsList: { total: number },
    description: string,
    createdat: string
  },
  showGlobalNotification: Function
};

type stateTypes = {
  cartModal: boolean,
  errorDatasourceModal: string
};

class CartCard extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      cartModal: false,
      errorDatasourceModal: ""
    };
  }

  openCartModal = () =>
    this.setState({ cartModal: true, errorDatasourceModal: undefined });

  closeCartModal = () => this.setState({ cartModal: false });

  deleteCart = () =>
    this.props.api.cart
      .deleteCart(this.props.cart.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Project deleted",
          type: "success"
        });
        this.closeCartModal();
        window.location.reload();
      })
      .catch((errorDatasourceModal) => {
        this.setState({
          errorDatasourceModal: getErrorMessageWithoutCode(errorDatasourceModal)
        });
        this.props.showGlobalNotification({
          message: "Project deletion failed.",
          type: "alert"
        });
      });

  cartModal() {
    return (
      <Modal
        title={`Delete project ${this.props.cart.name}`}
        body={"Are you sure ?"}
        errorMessage={this.state.errorDatasourceModal}
        actions={[
          <ButtonAction label="Close" onClick={() => this.closeCartModal()} />,
          <ButtonAction
            label="Delete"
            className="butn butn-delete"
            onClick={() => this.deleteCart()}
          />
        ]}
      />
    );
  }

  render() {
    return (
      <div className={"cart-card card-shadow card bg-white"} onClick={() => {}}>
        <div className="card-cart-header">
          <div className="card-cart-begin">
            <div className="card-cart-begin-content">
              <i className="fas fa-user-friends" />
              <Links.Group.View uriGroup={this.props.cart.groupuri}>
                <span className="text-header-cart">
                  {this.props.cart.group.name}
                </span>
              </Links.Group.View>
            </div>
          </div>
          <div className="card-cart-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-datasource">
              {formatDate(this.props.cart.createdat, false)}
            </span>
          </div>
        </div>
        <div className="card-cart-body">
          <div className="card-cart-body-left">
            <div className={"text-capitalize cart-card-name"}>
              <Links.Cart.View cartUri={this.props.cart.uri}>
                <Stringslider
                  s={this.props.cart.name}
                  height={25}
                  width={315}
                />
              </Links.Cart.View>
            </div>
            <div className="card-cart-details">
              <p className="card-cart-description">
                {this.props.cart.description ||
                (this.props.cart.description &&
                  this.props.cart.description > 125)
                  ? `${this.props.cart.description.substring(0, 125)}`
                  : "No description"}
              </p>
            </div>
          </div>
          <div className="card-cart-body-right">
            <div className="card-cart-body-actions">
              <div>
                <Links.Cart.View cartUri={this.props.cart.uri}>
                  <i className="fas fa-play" />
                  <span className="text-actions">View Details</span>
                </Links.Cart.View>
              </div>
              <div>
                <Links.Cart.AddDataset
                  cartUri={this.props.cart.uri}
                  groupUri={this.props.cart.groupuri}
                >
                  <i className="fas fa-plus-circle" />
                  <span className="text-actions">Add Dataset</span>
                </Links.Cart.AddDataset>
              </div>
              <div>
                <span
                  className="cursorPointer"
                  onClick={() => this.openCartModal()}
                >
                  <i className="fas fa-trash" />
                  <span className="text-actions">Delete</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-cart-footer">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <i className="fas fa-globe-europe" />
              <span className="text-header-datasource">
                {this.props.cart.region}
              </span>
            </div>
          </div>
          <div className="card-cart-end">
            <div className="text-footer-metrics">
              Contains{" "}
              <span className="tag-metrics">
                {this.props.cart.datasetsList.total}
              </span>{" "}
              datasets
            </div>
          </div>
        </div>

        <div>{this.state.cartModal && this.cartModal()}</div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(CartCard));
