/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./style.less";
import GraphQl from "../../../graphQL";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import Error from "../../../components/error";
import Row from "../../../components/row";
import Col from "../../../components/col";
import Paginator from "../../../components/paginator";
import Loading from "../../../components/loading";
import SelectClassic from "../../../components/SelectClassic";
import SelectInfinite from "../../../components/SelectInfinite";
import TermsConditionsModal from "../../../components/termsConditionsModal";
import Breadcrumb from "../../../components/breadcrumb";
import ShareDatasetAddCard from "./shareDatasetAddCard";

type propTypes = {
  match: {
    params: {
      uriDatasource: string,
      uriAccount: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  ready: boolean,
  isFetchingDatasets: boolean,
  isFetchingShares: boolean,
  groupReady: boolean,
  error: Object,
  errorSubmit: Object,
  accounts: Array<Object>,
  accountSelected?: Object,
  accountUri: string,
  groups: Array<Object>,
  groupSelected?: Object,
  groupUri: string,
  totalGroups: number,
  offsetGroups: number,
  datasets: Array<Object>,
  datasetsTotal: number,
  complianceModal: boolean,
  datasetsToShare: Array<Object>,
  offset: number
};

class DatasourceShareDatasets extends React.Component<propTypes, stateTypes> {
  termsCheckboxRef;

  constructor(props) {
    super(props);
    this.termsCheckboxRef = React.createRef();
    this.state = {
      ready: false,
      isFetchingDatasets: false,
      isFetchingShares: false,
      groupReady: false,
      error: undefined,
      errorSubmit: undefined,
      accounts: [],
      accountSelected: null,
      accountUri: "",
      groups: [],
      groupSelected: null,
      groupUri: "",
      totalGroups: 0,
      offsetGroups: 0,
      datasets: [],
      datasetsTotal: 0,
      complianceModal: false,
      datasetsToShare: [],
      offset: 0
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () =>
    this.props.api.account
      .getAllAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  onSelectAccount = (accountSelected) => {
    this.setState(
      {
        accountSelected,
        groupSelected: null,
        accountUri: accountSelected.value,
        groupUri: undefined,
        errorSubmit: undefined,
        groupReady: false
      },
      this.getGroupsFromAccount
    );
  };

  getGroupsFromAccount = (offset, search) => {
    let queryOptions = {};
    if (search) {
      queryOptions = {
        offset: 0,
        limit: 1000,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset,
        limit: 10
      };
    }
    return this.props.api.account
      .listGroups(this.state.accountUri, queryOptions)
      .then((response) => {
        if (response) {
          const groups = response && response.results ? response.results : [];
          if (!search) {
            this.setState((prevState) => ({
              offsetGroups: prevState.offsetGroups + response.limit
            }));
          }
          this.setState({
            groups,
            totalGroups: response.total,
            groupReady: true
          });
          return groups.map((g) => ({ label: g.name, value: g.uri }));
        }

        return false;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  onSelectGroup = (groupSelected) => {
    this.setState(
      {
        groupSelected,
        groupUri: groupSelected.value,
        errorSubmit: undefined
      },
      this.getDatasets
    );
  };

  getDatasets = () => {
    this.setState({ isFetchingDatasets: true, datasetsToShare: [] });
    const options = {
      limit: 5,
      offset: this.state.offset,
      filters: [{ key: "group_uri", value: this.state.groupUri }]
    };

    return this.props.api.datasource
      .listDatasetsShareableWithGroup(
        this.props.match.params.uriDatasource,
        options
      )
      .then((datasetsList) => {
        if (!datasetsList) return false;
        this.setState({
          datasets: datasetsList.results,
          datasetsTotal: datasetsList.total,
          isFetchingDatasets: false
        });
        return datasetsList;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  loadMoreGroupsFromAccount = (offset, search) => {
    if (search && search.length < 3) {
      return false;
    }
    return this.getGroupsFromAccount(offset, search);
  };

  openComplianceModal = () => {
    this.setState({ complianceModal: true });
  };

  closeComplianceModal = () => {
    this.setState({ complianceModal: false });
  };

  onSubmit = () => {
    if (!this.state.groupUri) {
      return this.setState({ errorSubmit: "Pick a group before to share." });
    }

    if (
      this.termsCheckboxRef &&
      this.termsCheckboxRef.current &&
      !this.termsCheckboxRef.current.checked
    ) {
      return this.setState({
        errorSubmit: "You must accept the terms & conditions"
      });
    }

    if (this.state.datasetsToShare.length === 0) {
      return this.setState({
        errorSubmit: "You must select at least one dataset"
      });
    }

    this.setState({ isFetchingShares: true, errorSubmit: undefined });
    return this.props.api.datasource
      .shareSubsetDatasetsWithGroup(
        this.props.match.params.uriDatasource,
        this.state.groupUri,
        this.state.datasetsToShare
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Datasets shared with success",
          type: "success"
        });
        this.setState({
          isFetchingShares: false,
          datasetsToShare: [],
          datasets: [],
          datasetsTotal: 0,
          offset: 0,
          errorSubmit: undefined
        });
        this.getDatasets();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to share datasets",
          type: "alert"
        });
        this.setState({
          error,
          errorSubmit: undefined,
          isFetchingShares: false
        });
      });
  };

  isDefaultSelect = (dataset) =>
    !!this.state.datasetsToShare.find(
      (datasetToShare) => datasetToShare === dataset.uri
    );

  addDatasetToList = (dataset) => {
    this.setState((prevState) => ({
      datasetsToShare: [...prevState.datasetsToShare, dataset.uri]
    }));
  };

  removeDatasetToList = (dataset) => {
    this.setState((prevState) => {
      const newList = prevState.datasetsToShare;
      const index = this.state.datasetsToShare.indexOf(dataset.uri);
      if (index > -1) {
        newList.splice(index, 1);
      }
      return { datasetsToShare: newList };
    });
  };

  getDatasetsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getDatasets().then(r))
    );

  render() {
    return (
      <div className={"datasource-share-datasets"}>
        {this.state.error && (
          <Error error={this.state.error} path={"ShareDatasets"} stringOnly />
        )}
        <Breadcrumb view={"Datasource, share datasets"} />
        {!this.state.ready && <Loading message={"Groups data"} />}
        {!this.state.error && this.state.ready && (
          <div>
            <div className="select-orga-group-form">
              <div className="two-selects">
                <div className="mt-4">
                  <label className="label-form">
                    Select an organization :{" "}
                  </label>
                  <SelectClassic
                    placeholder="Select an organization"
                    options={this.state.accounts.map((a) => {
                      if (a.uri === "uri:account:000000")
                        return { value: a.uri, label: "All Engie" };
                      return { value: a.uri, label: a.name };
                    })}
                    selectedOption={this.state.accountSelected}
                    isSearchable
                    onSelectOption={this.onSelectAccount}
                  />
                </div>
                <div className="mt-4">
                  <label className="label-form">and Group : </label>

                  <SelectInfinite
                    isLoadingOptions={
                      !this.state.groupReady && !!this.state.accountUri
                    }
                    placeholder={
                      this.state.accountUri
                        ? "Select a group"
                        : "Select an organisation first"
                    }
                    isDisabled={!this.state.accountUri}
                    initialOptions={(this.state.accountUri
                      ? this.state.groups
                      : []
                    ).map((g) => ({ value: g.uri, label: g.name }))}
                    isSearchable
                    selectedOption={this.state.groupSelected}
                    onSelectOption={this.onSelectGroup}
                    totalOptions={this.state.totalGroups}
                    loadMoreOptions={this.loadMoreGroupsFromAccount}
                    optionResults
                  />
                </div>
              </div>
              <div className="terms-select">
                <div className="mt-4 pl-3 form-group row">
                  <input
                    id="termsconditions"
                    name="termsconditions"
                    type="checkbox"
                    className={"checkbox-facet"}
                    ref={this.termsCheckboxRef}
                  />
                  <label className="fas" htmlFor="termsconditions">
                    <span className="labelTermsCustom">
                      I acknowledge having read and accept the general{" "}
                      <span
                        className="terms-highlight"
                        onClick={this.openComplianceModal}
                      >
                        terms & conditions
                      </span>{" "}
                      of access and use of the service
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {this.state.groupUri && this.state.isFetchingDatasets && (
              <Loading message="Dataset List" />
            )}
            {this.state.groupUri && !this.state.isFetchingDatasets && (
              <div>
                <Paginator
                  limit={5}
                  initialOffset={this.state.offset}
                  list={this.state.datasets}
                  totalCount={this.state.datasetsTotal}
                  isLoadingPage={this.state.isFetchingDatasets}
                  loadingMessage={<Loading message="Datasets" />}
                  componentRender={(dataset) => (
                    <ShareDatasetAddCard
                      key={dataset.uri}
                      dataset={dataset}
                      addDatasetToList={this.addDatasetToList}
                      removeDatasetToList={this.removeDatasetToList}
                      defaultSelect={this.isDefaultSelect(dataset)}
                    />
                  )}
                  loadPage={this.getDatasetsFromPagination}
                  onNoResult={() => (
                    <p className="total-search-results">No dataset found</p>
                  )}
                />
                <Row className="justify-content-center mt-4">
                  {this.state.errorSubmit && (
                    <Col
                      size={12}
                      style={{ color: "red", paddingBottom: "10px" }}
                    >
                      {this.state.errorSubmit}
                    </Col>
                  )}

                  <button
                    type={"button"}
                    onClick={this.onSubmit}
                    className={"butn"}
                  >
                    {this.state.isFetchingShares && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Share Datasets !
                  </button>
                </Row>
              </div>
            )}
          </div>
        )}
        {this.state.complianceModal && (
          <TermsConditionsModal onClose={this.closeComplianceModal} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasourceShareDatasets)
);
