import { sendQuery } from "./querySendQuery";
import { getQueryStatus } from "./queryQueryStatus";
import { getQueryResults } from "./queryQueryResults";
import { queryGetTables } from "./queryGetTables";
import { queryGetDatabases } from "./queryGetDatabases";

class SqlLabApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  getSqlLabDatabaseTables(targetType, targetUri, platformUri) {
    return this.fetchAppSync(queryGetTables, {
      targetType,
      targetUri,
      platformUri
    });
  }

  getSqlLabDatabases(platformUri) {
    return this.fetchAppSync(queryGetDatabases, { platformUri });
  }

  runSqlLabQuery(query, targetType, targetUri, platformUri) {
    return this.fetchAppSync(sendQuery, {
      query,
      targetType,
      targetUri,
      platformUri
    });
  }

  getSqlLabQueryStatus(queryId, targetType, targetUri, platformUri) {
    return this.fetchAppSync(getQueryStatus, {
      queryId,
      targetType,
      targetUri,
      platformUri
    });
  }

  getSqlLabQueryResults(queryId, targetType, targetUri, platformUri) {
    return this.fetchAppSync(getQueryResults, {
      queryId,
      targetType,
      targetUri,
      platformUri
    });
  }
}

export default SqlLabApi;
