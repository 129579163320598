/* @flow */

import React from "react";
import { connect } from "react-redux";
import withAppSync from "../AppsyncHOC";
import GraphQl from "../../graphQL";
import { updateMessage } from "../globalNotifications/actions";
import Error from "../../components/error";
import Loading from "../../components/loading";
import Paginator from "../../components/paginator";
import DataFilterItem from "./DataFilterItem";
import routes from "../routes";
import DataFilterEditModal from "./DataFilterEditModal";
import DataFilterDeleteModal from "./DataFilterDeleteModal";

type propTypes = {
  dataset: {
    uri: string,
    name: string
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function,
  stageId: string
};

type stateTypes = {
  error: Object,
  isFetching: boolean,
  offset: number,
  dataFilters: Array<Object>,
  totalDataFilters: number,
  editDataFilterModal: Object,
  deleteDataFilterModal: Object
};

class DataFiltersCard extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isFetching: false,
      offset: 0,
      dataFilters: [],
      totalDataFilters: 0,
      editDataFilterModal: undefined,
      deleteDataFilterModal: undefined
    };
  }

  componentDidMount() {
    this.listDatafilters();
  }

  listDatafilters = () => {
    this.setState({ isFetching: true });
    const options = {
      limit: 4,
      offset: this.state.offset,
      order_by: { value: "desc", key: "createdat" }
    };

    return this.props.api.dataset
      .listDataFilters(this.props.dataset.uri, this.props.stageId, options)
      .then((dataFiltersList) => {
        if (!dataFiltersList) return false;
        this.setState({
          dataFilters: dataFiltersList.results,
          totalDataFilters: dataFiltersList.total,
          isFetching: false
        });
        return dataFiltersList;
      })
      .catch((error) => {
        this.setState({
          error,
          isFetching: false
        });
      });
  };

  listDatafiltersFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listDatafilters().then(r))
    );

  openEditDataFilterModal = (datafilter) =>
    this.setState({ editDataFilterModal: { datafilter } });

  closeEditDataFilterModal = () =>
    this.setState({ editDataFilterModal: undefined });

  openDeleteDataFilterModal = (datafilter) =>
    this.setState({ deleteDataFilterModal: { datafilter } });

  closeDeleteDataFilterModal = () =>
    this.setState({ deleteDataFilterModal: undefined });

  syncDataFilter = () => {
    this.setState({ isFetching: true, error: undefined });
    return this.props.api.dataset
      .syncDataFiltersFromLakeFormation(this.props.dataset.uri)
      .then(() => {
        this.listDatafilters();
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Data Filters synchronized from Lake Formation",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({ isFetching: false, error });
        this.props.showGlobalNotification({
          message: "Failed to synchronize data filter from Lake Formation",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DataFilters"} />;
    return (
      <div className="datafilters">
        <div>
          <div className="datafilters-create">
            <div
              className="butn butn-flat"
              onClick={() => this.syncDataFilter()}
            >
              {this.state.isFetching && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              <i className="fas fa-sync-alt" />
              <span className="butn-text">Synchronize Data Filters</span>
            </div>
            <div
              className="butn butn-flat"
              onClick={() =>
                this.props.goTo({
                  route: routes.Dataset.CreateDataFilter,
                  params: {
                    uriDataset: this.props.dataset.uri,
                    stageId: this.props.stageId
                  },
                  state: {
                    dataset: this.props.dataset
                  }
                })
              }
              style={{ marginLeft: "5px" }}
            >
              <i className="fas fa-plus"></i>
              <span className="butn-text">New DataFilter</span>
            </div>
          </div>
          {this.state.isFetching && <Loading message="Data Filters" />}
          {!this.state.isFetching && (
            <React.Fragment>
              <div className="datafilters-container">
                {this.state.dataFilters && (
                  <div className="datafilters-list">
                    <Paginator
                      limit={4}
                      initialOffset={this.state.offset}
                      isLoadingPage={this.state.isFetching}
                      loadingMessage={<Loading message="Data Filters" />}
                      list={this.state.dataFilters}
                      totalCount={this.state.totalDataFilters}
                      componentRender={(datafilter) => (
                        <DataFilterItem
                          datafilter={datafilter}
                          onEdit={() =>
                            this.openEditDataFilterModal(datafilter)
                          }
                          onDelete={() =>
                            this.openDeleteDataFilterModal(datafilter)
                          }
                        />
                      )}
                      loadPage={this.listDatafiltersFromPagination}
                      onNoResult={() => (
                        <p className="total-search-results">
                          No data filter found
                        </p>
                      )}
                    />
                  </div>
                )}
              </div>

              {this.state.editDataFilterModal && (
                <DataFilterEditModal
                  dataset={this.props.dataset}
                  datafilter={this.state.editDataFilterModal.datafilter}
                  api={this.props.api}
                  hideModal={this.closeEditDataFilterModal}
                  showGlobalNotification={this.props.showGlobalNotification}
                  onEdit={() => {
                    this.setState({ editDataFilterModal: undefined });
                    this.listDatafilters();
                  }}
                />
              )}

              {this.state.deleteDataFilterModal && (
                <div>
                  <DataFilterDeleteModal
                    dataset={this.props.dataset}
                    datafilter={this.state.deleteDataFilterModal.datafilter}
                    api={this.props.api}
                    hideModal={this.closeDeleteDataFilterModal}
                    showGlobalNotification={this.props.showGlobalNotification}
                    onDelete={() => {
                      this.setState({ deleteDataFilterModal: undefined });
                      this.listDatafilters();
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(DataFiltersCard));
