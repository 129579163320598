export const listAccountDatabricksAccounts = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
          listAccountDatabricksAccounts(accountUri: $accountUri, options: $options) {
                limit
                offset
                total
                results {
                    dbx_account_id
                    dbx_account_name
                    uuid
                }
          }
      }
      `,
  keyOutput: "listAccountDatabricksAccounts"
};
