/* eslint-disable camelcase */
/* @flow */
import React from "react";
import "./view.less";
import GraphQl from "../../../graphQL";
import Breadcrumb from "../../../components/breadcrumb";
import withAppSync from "../../AppsyncHOC";
import config from "../../../config";
import TextField from "../../../components/textField";
import CopyToClipboard from "../../../components/copyToClipboard";
import SelectClassic from "../../../components/SelectClassic";
import Loading from "../../../components/loading";

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      cartUri: string,
      uriPlayground: string
    }
  },
  location: {
    state: {
      cart: Object
    }
  }
};

type stateTypes = {
  selectedDataset: Object,
  roleName: string,
  selectedDatasetarn: string,
  isReady: boolean,
  platform: Object
};

class CartGlueJobParameters extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataset: undefined,
      selectedDatasetarn: "",
      roleName: "",
      isReady: false,
      platform: this.props.location.state.cart.platforms.find(
        (platform) => platform.uri === this.props.match.params.uriPlayground
      )
    };
  }

  componentDidMount() {
    this.getCartPlatformRole();
  }

  getCartPlatformRole = () => {
    this.setState({ isReady: false });
    this.props.api.cart
      .getCartPlatformRole(
        this.props.match.params.cartUri,
        this.props.match.params.uriPlayground
      )
      .then((cartPlatformRole) => {
        this.setState({ roleName: cartPlatformRole.role_name, isReady: true });
      })
      .catch(() => {
        this.setState({ isReady: true });
      });
  };

  handleChange(value) {
    const datasetSelected = this.props.location.state.cart.datasets.find(
      (dataset) => dataset.name === value.target.value
    );
    return this.setState({
      selectedDataset: value.target.value,
      selectedDatasetarn: datasetSelected.arn
    });
  }

  render() {
    const { cart } = this.props.location.state;
    const { group, uuid, resource_name, region } = cart;
    return (
      <React.Fragment>
        <Breadcrumb
          state={this.props.location.state}
          view={"Glue Job Parameters"}
          helpOn={{
            content: (
              <div>
                <div>Fore more info about Glue Job Parameters : </div>
                <a
                  href={
                    "https://confluence.tools.digital.engie.com/display/CDHA/Glue+Quickstart"
                  }
                  target={"_black"}
                >
                  Confluence documentation
                </a>
              </div>
            ),
            helpCenterLink: `${config.HELP_CENTER}`
          }}
        />
        {!this.state.isReady && <Loading message={"Glue Parameters"} />}
        {this.state.isReady && (
          <div>
            <div className="form-group">
              <label className="label-form">IAM Role</label>
              <div className="input-group">
                <input
                  disabled
                  name="iam-role"
                  className="form-control form-control-sm"
                  placeholder="IAM Role"
                  value={this.state.roleName}
                />
                <CopyToClipboard
                  text={this.state.roleName}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">
                Recommended S3 Path (where the script will be stored)
              </label>
              <div className="input-group">
                <input
                  disabled
                  name="s3-path"
                  className="form-control form-control-sm"
                  placeholder="S3 Path"
                  value={`s3://aws-glue-scripts-${this.state.platform.aws}-${region}/${this.state.roleName}`}
                />
                <CopyToClipboard
                  text={`s3://aws-glue-scripts-${this.state.platform.aws}-${region}/${this.state.roleName}`}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="title-cost">
              <label className="label-form">Temporary directory</label>
            </div>
            {this.props.location.state.cart.datasets.length > 0 && (
              <div>
                <div className="mb-2">
                  <label className="label-form">Dataset</label>
                  <SelectClassic
                    placeholder="Select a dataset"
                    options={this.props.location.state.cart.datasets.map(
                      (a) => ({ value: a.name, label: a.name })
                    )}
                    onSelectOption={(option) =>
                      this.handleChange({ target: { value: option.value } })
                    }
                  />
                </div>
                {this.state.selectedDataset && (
                  <div className="input-group mt-3 mb-3">
                    <input
                      disabled
                      name="dataset"
                      className="form-control form-control-sm"
                      placeholder=""
                      value={`${this.state.selectedDatasetarn.replace(
                        "arn:aws:s3:::",
                        "s3://"
                      )}/_temporary`}
                    />
                    <CopyToClipboard
                      text={`${this.state.selectedDatasetarn.replace(
                        "arn:aws:s3:::",
                        "s3://"
                      )}/_temporary`}
                      className={"fas fa-copy btn-carre"}
                      label={""}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="title-cost">
              <label className="label-form">CDH Role Tag</label>
            </div>
            <div className="cart-detail-double-element">
              <div className="cart-detail-element-left">
                <div className="cart-detail-title">Key</div>
                <TextField text="cdh-cart" />
              </div>
              <div className="cart-detail-element-right">
                <div className="cart-detail-title">Value</div>
                <TextField text={resource_name} />
              </div>
            </div>
            <div className="title-cost">
              <label className="label-form">CDH Owner Tag</label>
            </div>
            <div className="cart-detail-double-element">
              <div className="cart-detail-element-left">
                <div className="cart-detail-title">Key</div>
                <TextField text="cdh-owner" />
              </div>
              <div className="cart-detail-element-right">
                <div className="cart-detail-title">Value</div>
                <TextField text={group.uuid} />
              </div>
            </div>
            <div className="title-cost">
              <label className="label-form">CDH Application Tag</label>
            </div>
            <div className="cart-detail-double-element">
              <div className="cart-detail-element-left">
                <div className="cart-detail-title">Key</div>
                <TextField text="cdh-application" />
              </div>
              <div className="cart-detail-element-right">
                <div className="cart-detail-title">Value</div>
                <TextField text={uuid} />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withAppSync(CartGlueJobParameters);
