/* @flow */
import React from "react";
import Links from "../../../links";
import routes from "../../../routes";
import withGoTo from "../../../goToHOC";

type propTypes = {
  uriAccount: string,
  uriPlayground: string,
  goTo: Function,
  accelerationTemplate: Object
};

const AccelerationTemplateCard = ({
  accelerationTemplate,
  goTo,
  uriAccount,
  uriPlayground
}: propTypes) => (
  <div className="cdh-card card-shadow card bg-white">
    <div className="cdh-card-header">
      <div className="cdh-card-begin">
        <div className="cdh-card-begin-content">
          <i className="fas fa-rocket fa-spacing" />
          Acceleration Template
        </div>
      </div>
    </div>
    <div className="cdh-card-body">
      <div className="cdh-card-body-left">
        <Links.AccelerationTemplate.View
          uriAccount={uriAccount}
          uriPlayground={uriPlayground}
          id={accelerationTemplate.id}
          state={{ accelerationTemplate }}
        >
          <span className="text-capitalize cdh-card-name">
            {accelerationTemplate.name}
          </span>
        </Links.AccelerationTemplate.View>
        <div className="network cdh-cart-description">
          <span className="">
            {accelerationTemplate.description ||
            (accelerationTemplate.description &&
              accelerationTemplate.description > 125)
              ? `${accelerationTemplate.description.substring(0, 125)}`
              : "No description"}
          </span>
        </div>
      </div>
      <div className="cdh-card-body-right">
        <div className="cdh-card-body-actions">
          <div
            className="btn-cart"
            onClick={() =>
              goTo({
                route: routes.AccelerationTemplate.View,
                params: {
                  uriAccount,
                  uriPlayground,
                  id: accelerationTemplate.id
                },
                state: { accelerationTemplate }
              })
            }
          >
            <i className="fas fa-edit fa-spacing" />
            <span className="text-actions">View more</span>
          </div>
        </div>
      </div>
    </div>
    <div className="cdh-card-footer">
      <div className="cdh-card-end" />
    </div>
  </div>
);

export default withGoTo(AccelerationTemplateCard);
