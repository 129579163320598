export const getCartEnvironmentStatusrole = {
  query: `query Q ($platformUri: String!, $cartUri: String!) {
        getRefreshRoleStatus(cartUri: $cartUri, platformUri: $platformUri) {
            cart_uri
            platform_uri
            global_status
            last_refresh_role_date
            global_duration
            iam_status
            iam_updatedat
            iam_duration
            iam_error
            glue_status
            glue_updatedat
            glue_duration
            glue_error
            execution_name
            rs_status
            rs_updatedat
            rs_duration
            rs_error
        }
    }
    `,
  keyOutput: "getRefreshRoleStatus"
};
