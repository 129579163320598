export const mutationAddShareItem = {
  query: `mutation Q ($datasetUri: String!, $groupUri: String!, $input: AddShareItemInput!) {
        addShareItem(datasetUri: $datasetUri, groupUri: $groupUri, input: $input) {
          id
          share_id
          stage_id
          stage{
           table
           id 
          }
          data_filter_id
          data_filter{
           name 
          }
          createdat
          updatedat
          table_name
          status
          requester
        }
    }
    `,
  keyOutput: "addShareItem"
};
