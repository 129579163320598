import React from "react";
import InfoListItem from "./info-list-item";

const InfoList = ({ informationList }) => (
  <div>
    <ul className="info">
      {informationList.map(({ label, content }) => (
        <InfoListItem
          {...{
            key: label,
            label,
            content
          }}
        />
      ))}
    </ul>
  </div>
);

export default InfoList;
