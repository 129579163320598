export const listAllGbus = {
  query: `query Q {
        listAllEngieGbus {
          id
          name
          code
        }
      }
    `,
  keyOutput: "listAllEngieGbus"
};
