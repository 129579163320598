/* @flow */

export const listMyOwnedDatasetsUris = {
  query: `query Q ($options: QueryOptions) {
        listMyOwnedDatasets(options:$options) {
              uri
              name
              owneruri
        }
    }
    `,
  keyOutput: "listMyOwnedDatasets"
};
