/* @flow */
import React from "react";
import { connect } from "react-redux";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import Breadcrumb from "../../../components/breadcrumb";
import GraphQl from "../../../graphQL";
import Logger from "../../../utils/logger";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import withGoTo from "../../goToHOC";
import Routes from "../../routes";
import SelectClassic from "../../../components/SelectClassic";

const Log = Logger("ShareAddItem");

type propTypes = {
  api: GraphQl,
  location: {
    state: {
      share: Object
    }
  },
  match: {
    params: {
      shareId: string
    }
  },
  showGlobalNotification: Function,
  goTo: Function
};

type stateTypes = {
  error: Object,
  errorsSubmit: Object,
  ready: boolean,
  share: Object,
  initialStages: Array<Object>,
  initialDataFilters: Array<Object>,
  isSubmitting: boolean,
  selectedDataFilterId: Array<Object>,
  selectedStageId: any,
  selectedDataFilterId: any,
  selectedDataFilterOption: Object,
  selectedStageOption: Object
};

class ShareAddItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      errorsSubmit: undefined,
      ready: false,
      share: this.props.location.state.share,
      initialStages: [],
      initialDataFilters: [],
      selectedStageOption: null,
      selectedDataFilterOption: null,
      isSubmitting: false,
      selectedStageId: null,
      selectedDataFilterId: null
    };
  }

  componentDidMount(): void {
    this.loadStages().then((stages) => {
      this.setState({
        initialStages: stages,
        ready: true
      });
    });
  }

  loadStages = (offset = 0) =>
    this.props.api.dataset
      .listStages(this.state.share.dataset.uri, { offset, limit: 10000 })
      .then((response) =>
        response.results.map((g) => ({
          label: g.name,
          value: g.id
        }))
      )
      .catch((error) => {
        Log.error(error);
        return [];
      });

  loadDataFilters = (selectedStageId = null) =>
    this.props.api.dataset
      .listDataFilters(
        this.state.share.dataset.uri,
        selectedStageId || this.state.selectedStageId,
        {
          offset: 0,
          limit: 10000
        }
      )
      .then((response) => {
        this.setState({
          initialDataFilters: response.results.map((g) => ({
            label: g.name,
            value: g.id
          }))
        });
        return response.results.map((g) => ({
          label: g.name,
          value: g.id
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  submit = () => {
    this.setState({ isSubmitting: true });

    return this.props.api.share
      .addShareItem(this.state.share.dataset.uri, this.state.share.group.uri, {
        stage_id: this.state.selectedStageId,
        data_filter_id: this.state.selectedDataFilterId
      })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Item added to share request",
          type: "success"
        });
        return this.props.goTo({
          route: Routes.Share.View,
          params: {
            shareId: this.state.share.id
          }
        });
      })
      .catch((errorsSubmit) => {
        this.setState({ isSubmitting: false });
        this.setState({ errorsSubmit });
        this.props.showGlobalNotification({
          message: "Failed to add item to share request",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="AddShareItem" />;
    if (!this.state.ready) return <Loading message={"Add Share Item"} />;
    return (
      <React.Fragment>
        <div>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view={`Add new item to dataset share: ${this.state.share.dataset.name}`}
              />
            </div>
          </div>
        </div>

        <div className="AT-grant-form">
          <Error
            error={this.state.errorsSubmit}
            path="AddShareItems"
            stringOnly
          />

          <div className="form-group">
            <legend className="label-form">Stage</legend>
            <SelectClassic
              placeholder={"Select Stage"}
              options={this.state.initialStages}
              selectedOption={this.state.selectedStageOption}
              onSelectOption={(option) => {
                this.setState({
                  initialDataFilters: [],
                  selectedDataFilterOption: null,
                  selectedDataFilterId: null,
                  selectedStageOption: option,
                  selectedStageId: option.value
                });
                this.loadDataFilters(option.value);
              }}
            />
          </div>
          {this.props.location.state.share &&
            this.props.location.state.share.user_role === "DataSteward" && (
              <div className="form-group">
                <legend className="label-form">Data Filter</legend>
                <SelectClassic
                  placeholder={"Select Data Filter"}
                  options={this.state.initialDataFilters}
                  selectedOption={this.state.selectedDataFilterOption}
                  onSelectOption={(option) => {
                    this.setState({
                      selectedDataFilterOption: option,
                      selectedDataFilterId: option.value
                    });
                  }}
                />
              </div>
            )}

          <div className="mt-3 text-center">
            <button
              disabled={!this.state.selectedStageId}
              type="submit"
              className="butn butn-full"
              onClick={this.submit}
            >
              {this.state.isSubmitting && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              Add Share Item
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(ShareAddItem)));
