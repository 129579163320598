/* eslint-disable camelcase */

import { mutationCreateDomain } from "./mutationCreateDomain";
import { queryGetDomain } from "./queryGetDomain";
import { mutationDeleteDomain } from "./mutationDeleteDomain";
import { listDomains } from "./queryListDomains";
import { mutationCreateUserProfile } from "./mutationCreateUserProfile";
import { listProjectUserProfiles } from "./queryListProjectUserProfiles";
import { mutationDeleteUserProfile } from "./mutationDeleteUserProfile";
import { queryGetUserProfile } from "./queryGetUserProfile";
import { queryGetUserProfilePresignedUrl } from "./queryGetUserProfilePresignedUrl";
import { mutationUpdateLifecycle } from "./mutationUpdateLifecycle";

class SmStudioApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  createDomain(platformUri, input) {
    return this.fetchAppSync(mutationCreateDomain, {
      platformUri,
      input
    });
  }

  getDomain(platformUri, domainId) {
    return this.fetchAppSync(queryGetDomain, {
      platformUri,
      domainId
    });
  }

  deleteDomain(platformUri, domainId, deleteFromAws) {
    return this.fetchAppSync(mutationDeleteDomain, {
      platformUri,
      domainId,
      deleteFromAws
    });
  }

  listDomains(platformUri, options = {}) {
    return this.fetchAppSync(listDomains, { platformUri, options });
  }

  createUserProfile(cartUri, domainId) {
    return this.fetchAppSync(mutationCreateUserProfile, {
      cartUri,
      domainId
    });
  }

  listUserProfiles(cartUri) {
    return this.fetchAppSync(listProjectUserProfiles, { cartUri }, true);
  }

  deleteUserProfile(cartUri, userProfileId, deleteFromAws) {
    return this.fetchAppSync(mutationDeleteUserProfile, {
      cartUri,
      userProfileId,
      deleteFromAws
    });
  }

  updateLlc(cartUri, userProfileId, enableLlc) {
    return this.fetchAppSync(mutationUpdateLifecycle, {
      cartUri,
      userProfileId,
      enableLlc
    });
  }

  getUserProfile(cartUri, userProfileId) {
    return this.fetchAppSync(queryGetUserProfile, { cartUri, userProfileId });
  }

  getPresignedUrl(cartUri, userProfileId) {
    return this.fetchAppSync(queryGetUserProfilePresignedUrl, {
      cartUri,
      userProfileId
    });
  }
}

export default SmStudioApi;
