/* @flow */

export const searchFacets = {
  query: `query Q ($search: SearchInput) {
        searchDatasetFacets(search: $search) {
            category
            name
            facets {
                value
                nb
            }
        }
      }
    `,
  keyOutput: "searchDatasetFacets"
};

export type SearchFacetsPropsTypes = Array<{
  category: string,
  name: string,
  facets: Array<{
    value: string,
    nb: number
  }>
}>;
