/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import Error from "../../../../components/error";
import Links from "../../../links";
import "./CartAnalyticsClusters.less";
import routes from "../../../routes";
import GraphQl from "../../../../graphQL";

type propTypes = {
  cart: Object,
  cluster: Object,
  goTo: Function,
  reloadRedshiftClusters: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object,
  removingFromProject: boolean
};

class CartAnalyticsClusters extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      removingFromProject: false
    };
  }

  onGenerateCredentials = () =>
    this.props.goTo({
      route: `${routes.Analytics.CartView}#credentials`,
      params: {
        clusterUri: this.props.cluster.uri,
        cartUri: this.props.cart.uri,
        database: this.props.cart.database
      }
    });

  goODBCConnection = () =>
    this.props.goTo({
      route: `${routes.Analytics.CartView}#ODBC`,
      params: {
        clusterUri: this.props.cluster.uri,
        cartUri: this.props.cart.uri,
        database: this.props.cart.database
      }
    });

  removeRedshiftClusterFromProject = (cartUri, clusterUri) => {
    this.setState({ removingFromProject: true });
    this.props.api.analytics
      .removeRedshiftClusterFromProject(cartUri, clusterUri)
      .then(() => {
        this.setState({ removingFromProject: false });
        this.props.showGlobalNotification({
          message: "Redshift removed from project",
          type: "success"
        });
        if (this.props.cart.is_refresh_auto_enabled) {
          this.props.showGlobalNotification({
            message:
              "Redshift removed from project , your role project will be automatically refreshed",
            type: "success"
          });
        }
        this.props.reloadRedshiftClusters();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to remove Redshift cluster from project",
          type: "alert"
        });
        this.setState({
          removingFromProject: false,
          error
        });
      });
  };

  render() {
    const { cluster, cart } = this.props;
    const { error, removingFromProject } = this.state;
    return (
      <div className={"cart-cluster-card card-shadow card bg-white"}>
        {error && <Error error={error} path="RedshiftClusters" />}
        <div className="card-cluster-header">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fab fa-aws"></i>
              <span className="text-header-cluster">
                {cluster.platform.aws}
              </span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div
              className={`cart-environment-tag ${cluster.platform.environment}`}
            >
              <span>{cluster.platform.environment}</span>
            </div>
          </div>
        </div>
        <div className="cart-card-cluster-body">
          <div className="card-cluster-body-left">
            <Links.Analytics.CartView
              clusterUri={cluster.uri}
              cartUri={cart.uri}
            >
              <span className={"cluster-name"}>{cluster.cluster_name}</span>
            </Links.Analytics.CartView>

            <div className="card-cluster-details">
              <p className="card-cluster-description">
                {cluster.description && cluster.description.length > 100
                  ? `${cluster.description.substring(0, 50)}...`
                  : cluster.description}
              </p>
              <div className="card-cluster-role">
                <i className="fab fa-aws"></i>
                <span className="content-card-cluster">
                  {cluster.resource_name}
                </span>
              </div>
              {cluster.cluster_arn && (
                <div className="card-cluster-role">
                  <i className="fab fa-aws"></i>
                  <span className="content-card-cluster">
                    {cluster.cluster_arn}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="card-cluster-body-right">
            <div className="card-cluster-body-actions">
              {cluster.status !== "NotFound" &&
                cluster.status !== "deleting" && (
                  <div>
                    <Links.Analytics.CartView
                      clusterUri={cluster.uri}
                      cartUri={cart.uri}
                    >
                      <i className="fas fa-play fa-spacing"></i>
                      <span className="text-actions">View Details</span>
                    </Links.Analytics.CartView>
                  </div>
                )}
              {cluster.status !== "NotFound" &&
                cluster.status !== "deleting" && (
                  <div
                    className="btn-cart"
                    onClick={() => this.onGenerateCredentials()}
                  >
                    <i className="fas fa-key fa-spacing"></i>
                    <span className="text-actions">Generate Credentials</span>
                  </div>
                )}
              {cluster.credentials_expiration_date && (
                <div
                  className="btn-cart"
                  style={{ position: "relative" }}
                  onClick={() => this.goODBCConnection()}
                >
                  <i className="fas fa-info-circle fa-spacing"></i>
                  <span className="text-actions">ODBC Connection</span>
                </div>
              )}
              <div
                className="btn-cart"
                onClick={() =>
                  this.removeRedshiftClusterFromProject(cart.uri, cluster.uri)
                }
              >
                {removingFromProject && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing"></i>
                <span className="text-actions">Remove From Project</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-cluster-footer">
          <div className="card-cluster-begin">
            <div className="card-cluster-begin-content">
              <i className="fas fa-globe-europe"></i>
              <span className="text-header-cluster">{cluster.region}</span>
            </div>
          </div>
          <div className="card-cluster-end">
            <div className="text-footer-metrics">
              Status <span className="tag-metrics">{cluster.status}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartAnalyticsClusters)));
