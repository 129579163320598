export const convertSize = (sizeOctet) => {
  if (!sizeOctet || sizeOctet <= 0) return "Not available";
  sizeOctet = Math.abs(parseInt(sizeOctet, 10));
  const def = [
    [1, "bytes"],
    [1024, "KB"],
    [1024 * 1024, "MB"],
    [1024 * 1024 * 1024, "GB"],
    [1024 * 1024 * 1024 * 1024, "TB"]
  ];
  if (sizeOctet > def[4][0])
    return `${(sizeOctet / def[4][0]).toFixed(2)} ${def[4][1]}`;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < def.length; i++) {
    if (sizeOctet < def[i][0])
      return `${(sizeOctet / def[i - 1][0]).toFixed(2)} ${def[i - 1][1]}`;
  }
  return "Not available";
};

export const convertSize2 = (sizeOctet) => {
  if (!sizeOctet || sizeOctet <= 0) return "Not available";
  sizeOctet = Math.abs(parseInt(sizeOctet, 10));
  const def = [
    [1, "bytes"],
    [1024, "KB"],
    [1024 * 1024, "MB"],
    [1024 * 1024 * 1024, "GB"],
    [1024 * 1024 * 1024 * 1024, "TB"]
  ];
  if (sizeOctet > def[4][0]) return `${(sizeOctet / def[4][0]).toFixed(2)}`;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < def.length; i++) {
    if (sizeOctet < def[i][0])
      return `${(sizeOctet / def[i - 1][0]).toFixed(2)}`;
  }
  return "Not available";
};

export const convertSize3 = (sizeOctet) => {
  if (!sizeOctet || sizeOctet <= 0) return "Not available";
  sizeOctet = Math.abs(parseInt(sizeOctet, 10));
  const def = [
    [1, "bytes"],
    [1024, "KB"],
    [1024 * 1024, "MB"],
    [1024 * 1024 * 1024, "GB"],
    [1024 * 1024 * 1024 * 1024, "TB"]
  ];
  if (sizeOctet > def[4][0]) return `${def[4][1]}`;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < def.length; i++) {
    if (sizeOctet < def[i][0]) return `${def[i - 1][1]}`;
  }
  return "Not available";
};

export const convertNumber = (number, round = false) => {
  const numberParsed = parseInt(number, 10);
  if (!numberParsed) return "0";

  if (numberParsed > 999) {
    if (round) {
      return `${Math.floor(numberParsed / 1000)}K+`;
    }
    return `${numberParsed / 1000}K`;
  }

  if (numberParsed > 999999) {
    return `${numberParsed / 1000000}M`;
  }
  return numberParsed;
};
