/* @flow */
import React from "react";

import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import GraphQl from "../../graphQL";
import withAppsync from "../AppsyncHOC";
import Error from "../../components/error";
import Loading from "../../components/loading";
import CollibraDataset from "./CollibraDataset";
import { getAccountUriFromUri } from "../../utils/toolsForUri";
import { updateMessage } from "../globalNotifications/actions";
import withGoTo from "../goToHOC";
import logoCollibra from "./collibra-logo.png";
import CollibraDatasetPublish from "./CollibraDatasetPublish";

type propTypes = {
  api: GraphQl,
  dataset: Object
};

type stateTypes = {
  ready: boolean,
  error: Object,
  collibraDatasets: Array<Object>,
  collibraLinks: Array<Object>,
  openPublishDialog: boolean
};

class CollibraDatasets extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      ready: true,
      collibraDatasets: [],
      collibraLinks: [],
      openPublishDialog: false
    };
  }

  componentDidMount() {
    this.getCollibraDatasets();
    this.getCollibraLinks();
  }

  getCollibraDatasets = () => {
    this.setState({
      ready: false
    });
    this.props.api.collibra
      .listCollibraDatasets(this.props.dataset.uri)
      .then((collibraDatasets) => {
        this.setState({
          ready: true,
          collibraDatasets
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  getCollibraLinks = () => {
    const accountUri = getAccountUriFromUri(this.props.dataset.uri);
    this.setState({
      ready: false
    });
    this.props.api.collibra
      .list(accountUri)
      .then((collibraLinks) => {
        this.setState({
          ready: true,
          collibraLinks
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CollibraDatasets"} />;
    if (!this.state.ready) return <Loading message="Collibra Datasets" />;
    return (
      <div className={"environments-container"}>
        <div className={"mb-3"}>
          {this.state.collibraLinks && (
            <div
              className="butn butn-flat"
              onClick={() => this.setState({ openPublishDialog: true })}
            >
              <img
                className="connector-logo-img mr-1"
                src={logoCollibra}
                alt="Collibra"
                width={15}
                height={15}
              />
              <span className="butn-text">Publish on Collibra</span>
            </div>
          )}
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error error={this.state.error} path={"CollibraLinks"} stringOnly />
          </div>
        )}
        <div>
          {this.state.collibraDatasets.length <= 0 && (
            <div className={"mt-2"}>
              <Alert severity={"info"}>
                Dataset not published yet on Collibra.
              </Alert>
            </div>
          )}
          {this.state.collibraDatasets.map((d) => (
            <div className={"mb-3"}>
              <CollibraDataset
                key={d.id}
                api={this.props.api}
                collibraDataset={d}
                reloadCollibraDatasets={this.getCollibraDatasets}
              />
            </div>
          ))}
        </div>
        {this.state.openPublishDialog && (
          <CollibraDatasetPublish
            uriAccount={getAccountUriFromUri(this.props.dataset.uri)}
            api={this.props.api}
            dataset={this.props.dataset}
            open={this.state.openPublishDialog}
            onClose={() => this.setState({ openPublishDialog: false })}
            reloadPublishedDatasets={this.getCollibraDatasets}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(CollibraDatasets)));
