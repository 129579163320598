import React from "react";
import "./styles.less";
import PropTypes from "prop-types";

class HelpOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.openHelp = this.openHelp.bind(this);
    this.closeHelp = this.closeHelp.bind(this);
  }

  openHelp(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isOpen: true });
  }

  closeHelp(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="HelpOn">
        <div className={"HelpOn-container-icon"}>
          <div
            className={"HelpOn-container HelpOn-anim"}
            onClick={!this.state.isOpen ? this.openHelp : this.closeHelp}
          >
            <i className="fas fa-info fa-2x HelpOn-icon" />
          </div>
        </div>
        {this.state.isOpen && (
          <div className={"HelpOn-modal"}>
            <div className={"HelpOn-header"}>
              <div className={"HelpOn-close"} onClick={this.closeHelp}>
                <i className="fa fa-times-circle" />
              </div>
              <div className={"HelpOn-title"}>
                <div className={"HelpOn-container-bloc"}>
                  <i className="fas fa-info HelpOn-icon" />
                </div>
                <div className={"HelpOn-title-content"}>Information</div>
              </div>
            </div>
            <div className={"HelpOn-body"}>{this.props.content}</div>
            {this.props.helpCenterLink && [
              <div
                className={"HelpOn-footer"}
                onClick={() => window.open(this.props.helpCenterLink, "_blank")}
              >
                {"> For more information, go to Help Center <"}
              </div>
            ]}
          </div>
        )}
      </div>
    );
  }
}

HelpOn.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  helpCenterLink: PropTypes.string
};

export default HelpOn;
