import React, { useEffect, useMemo, useState } from "react";
import withAppSync from "../../../AppsyncHOC";
import "./datasetSchemaStatus.less";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import DatasetSchemaStatusItem from "./datasetSchemaStatusItem";
import DatasetSchemaStatusCustomCrawler from "./datasetSchemaStatusCustomCrawler";

function DatasetSchemaStatus({ dataset, ...props }) {
  const [crawlers, setCrawlers] = useState(undefined);
  const [customCrawler, setCustomCrawler] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAlreadyBeenFetched, setHasAlreadyBeenFetched] = useState(false);
  const [isLoadingCustom, setIsLoadingCustom] = useState(false);
  const [error, setError] = useState(null);
  const [errorCustom, setErrorCustom] = useState(null);
  const [isForceRefreshCustomCrawler, setIsForceRefreshCustomCrawler] =
    useState(false);

  async function getDatasetCrawlers() {
    setHasAlreadyBeenFetched(false);
    setIsLoading(true);
    props.api.dataset
      .getDatasetCrawlers(dataset.uri)
      .then((fetchedCrawlers) => {
        setCrawlers(fetchedCrawlers);
      })
      .catch((datasetCrawlersError) => {
        setError(datasetCrawlersError);
      });
    setIsLoading(false);
  }

  async function getDatasetCustomCrawler() {
    setHasAlreadyBeenFetched(false);
    setIsLoadingCustom(true);
    props.api.dataset
      .getDatasetCustomCrawlerStatus(dataset.uri)
      .then((fetchedCustomCrawler) => {
        setCustomCrawler(fetchedCustomCrawler);
      })
      .catch((datasetCustomCrawlerError) => {
        setErrorCustom(datasetCustomCrawlerError);
      });
    setIsLoadingCustom(false);
    if (isForceRefreshCustomCrawler) {
      setIsForceRefreshCustomCrawler(false);
    }
  }

  useEffect(() => {
    getDatasetCrawlers();
    getDatasetCustomCrawler();
  }, []);

  useEffect(() => {
    let checkIfAlreadyFetched;
    if (crawlers && customCrawler) {
      checkIfAlreadyFetched = setTimeout(() => {
        setHasAlreadyBeenFetched(true);
      }, 2000);
    }

    return () => {
      if (checkIfAlreadyFetched) {
        clearTimeout(checkIfAlreadyFetched);
      }
    };
  }, [crawlers, customCrawler]);

  useEffect(() => {
    if (!isForceRefreshCustomCrawler) return;
    if (isForceRefreshCustomCrawler) {
      getDatasetCustomCrawler();
    }
  }, [isForceRefreshCustomCrawler]);

  const isCustomCrawlerEmpty = useMemo(() => {
    function checkIfEmtyParams(obj) {
      return Object.values(obj).every((value) => {
        if (value === "") {
          return true;
        }
        return false;
      });
    }
    return customCrawler ? checkIfEmtyParams(customCrawler) : true;
  }, [customCrawler]);

  return (
    <div>
      {error && <Error error={error} path="DatasetSchemaStatus" />}
      {(isLoading || isLoadingCustom) && <Loading message="Schema Status" />}
      {!isLoading && crawlers
        ? crawlers.map((crawler, index) => (
            <DatasetSchemaStatusItem
              key={index}
              crawler={crawler}
              dataset={dataset}
              {...props}
            />
          ))
        : undefined}
      {errorCustom && <Error error={errorCustom} path="DatasetSchemaStatus" />}
      {!errorCustom && !isLoadingCustom && !isCustomCrawlerEmpty && (
        <DatasetSchemaStatusCustomCrawler
          customCrawler={customCrawler}
          dataset={dataset}
          isLoadingCustom={isLoadingCustom}
          isForceRefreshCustomCrawler={isForceRefreshCustomCrawler}
          setIsForceRefreshCustomCrawler={setIsForceRefreshCustomCrawler}
          {...props}
        />
      )}
      {hasAlreadyBeenFetched &&
      !error &&
      !isLoading &&
      crawlers.length === 0 &&
      isCustomCrawlerEmpty ? (
        <div>Empty.</div>
      ) : undefined}
    </div>
  );
}

export default withAppSync(DatasetSchemaStatus);
