/* @flow */

export const countUnreadNotifications = {
  query: `query Q {
    countUnreadNewtifications
}
`,
  keyOutput: "countUnreadNewtifications"
};

export type CountUnreadNotificationsPropsTypes = number;
