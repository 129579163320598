/* @flow */

export const mutationReadNotification = {
  query: `mutation Q ($notificationId: String!) {
    readNewtification(notificationId:$notificationId)
  }
`,
  keyOutput: "readNotification"
};

type NotificationPropsTypes = {
  id: string,
  is_read: boolean
};
export type ReadNotificationPropsTypes = NotificationPropsTypes;
