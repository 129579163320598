export const getDatasetShares = {
  query: `
  query Q ($datasetUri: String!, $options: QueryOptions) {
    listDatasetShares(datasetUri: $datasetUri, options: $options) {
        offset
        limit
        total
        results {
            id
            items {
              total
              results {
                  id
                  data_filter {
                    id
                    name
                    resource_name
                    description
                    status
                    stage_id
                    createdat
                    updatedat
                    table_name
                    all_columns
                    column_level_access
                    row_filter_expression
                    row_filter_expression_simple_mode
                  }
                  stage {
                    id
                    name
                  }
              }
            }
            group {
              uri
              name
              account {
                name
              }
            }
            dataset {
                uri
                name
                access
                can_share
                datasource {
                    uri
                    name
                }
            }
            createdat
            updatedat
            request_status
            message
            reject_message
        }
    }
  }
`,
  keyOutput: "listDatasetShares"
};
