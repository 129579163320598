/* @flow */
import React from "react";
import Tabs from "../../../../../components/tabs";

type propTypes = {};

class CDHAdminArchives extends React.PureComponent<propTypes> {
  render() {
    return (
      <div>
        <Tabs
          tabs={[
            {
              name: <div>Datasources</div>,
              index: "datasources",
              content: <div>No archived datasources</div>
            },
            {
              name: <div>Projects</div>,
              index: "projects",
              content: <div>No archived projects</div>
            }
          ]}
        />
      </div>
    );
  }
}

export default CDHAdminArchives;
