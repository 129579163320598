import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MultipleValueTextInput from "../../../../../components/multipleValueTextInput";
import withAppSync from "../../../../AppsyncHOC";
import withGoTo from "../../../../goToHOC";
import Error from "../../../../../components/error";
import Logger from "../../../../../utils/logger";
import { updateMessage } from "../../../../globalNotifications/actions";
import { REGIONS } from "../../../../../utils/constants";
import GraphQl from "../../../../../graphQL";
import Loading from "../../../../../components/loading";
import Breadcrumb from "../../../../../components/breadcrumb";

const Log = Logger("NetworkUpdate");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string,
      networkId: string
    }
  },
  goBack: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  location: {
    state: {
      network: Object
    }
  }
};

type stateTypes = {
  errorSubmit: boolean,
  isSubmitting: boolean,
  network: Object,
  disableField: boolean,
  ready: boolean
};

class NetworkEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      ready: false,
      isSubmitting: false,
      disableField: false,
      network: {}
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.network) {
      this.setState({
        network: this.props.location.state.network,
        ready: true,
        disableField:
          this.props.location.state.network.sagemakerNotebookInstances.total > 0
      });
    } else {
      this.getNetwork();
    }
  }

  getNetwork = () =>
    this.props.api.playground
      .getNetwork(this.props.match.params.networkId)
      .then((response) => {
        this.setState({
          network: response,
          ready: true,
          disableField: response.sagemakerNotebookInstances.total > 0
        });
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.setState({ errorSubmit });
      });

  save = () => {
    this.setState({ errorSubmit: false, isSubmitting: true });
    let UpdatedNetwork = {};
    if (this.state.disableField) {
      UpdatedNetwork = {
        name: this.state.network.name,
        description: this.state.network.description,
        proxy: this.state.network.proxy,
        noproxy: this.state.network.noproxy,
        network_type: this.state.network.network_type
      };
    } else {
      UpdatedNetwork = {
        name: this.state.network.name,
        security_group_ids: this.state.network.security_group_ids,
        subnet_ids: this.state.network.subnet_ids,
        region: this.state.network.region,
        vpc_id: this.state.network.vpc_id,
        description: this.state.network.description,
        proxy: this.state.network.proxy,
        noproxy: this.state.network.noproxy,
        network_type: this.state.network.network_type
      };
    }

    this.props.api.playground
      .updateNetworkEnvironment(
        UpdatedNetwork,
        this.props.match.params.networkId
      )
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Network updated",
          type: "success"
        });
        return this.props.goBack();
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.setState({ errorSubmit, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Network update failed",
          type: "alert"
        });
      });
  };

  handleChange(field, value) {
    return this.setState((prevState) => {
      const network = Object.assign({}, prevState.network);
      network[field] = value;
      if (field === "proxy" && !value) {
        network.noproxy = "";
      }
      return { network };
    });
  }

  render() {
    if (!this.state.ready) return <Loading message={"form and data"} />;
    return (
      <div className="network-create">
        <div className="title">
          <Breadcrumb view={`Update network ${this.state.network.name}`} />
        </div>
        <div className="form-container-network">
          <Formik
            initialValues={{
              name: this.state.network.name,
              description: this.state.network.description,
              region: this.state.network.region,
              vpc_id: this.state.network.vpc_id,
              subnet_ids: this.state.network.subnet_ids,
              security_group_ids: this.state.network.security_group_ids,
              proxy: this.state.network.proxy,
              noproxy: this.state.network.noproxy,
              network_type: this.state.network.network_type
            }}
            validate={(values) => {
              Log.info("validate", values);
              const errors = {};

              const patternSubnet = new RegExp("^subnet-[a-zA-Z0-9_]*$");
              const patternSecurity = new RegExp("^sg-[a-zA-Z0-9_]+$");
              const patternVpc = new RegExp("^vpc-[a-zA-Z0-9_]+$");
              const patternCidr = String.raw`(\/((3[0-2])|([1-2][0-9])|([1-9])))`;
              const patternIp = String.raw`((?!169\.254\.169\.254)(?!127\.0\.0\.1)(?!255\.255\.255\.255)(25[0-5]|2[0-4]\d|[1]\d\d|[1-9]\d|[1-9])(\.(25[0-5]|2[0-4]\d|[1]\d\d|[1-9]\d|\d)){3})`;
              const patternDomain = String.raw`(([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,})`;
              const patternPort = String.raw`(:(\d{1,5}))`;
              const patternProxy = new RegExp(
                String.raw`^((?:(\w+)(?::(\w+))?@)|(?:(https?):\/\/))?(((${patternIp}${patternCidr}?)|${patternDomain})${patternPort}?)$`
              );
              const patternNoproxy = String.raw`((((${patternIp}${patternCidr}?)|(\.)?${patternDomain})${patternPort}?))`;
              const patternNoproxys = new RegExp(
                String.raw`^${patternNoproxy}(,${patternNoproxy})*$`
              );

              if (!this.state.network.name)
                errors.name = "The name is mandatory ";
              if (!this.state.network.region)
                errors.region = "The region is mandatory ";
              if (
                this.state.network.subnet_ids.length < 1 ||
                !this.state.network.subnet_ids
              ) {
                errors.subnet_ids = "The security subnet Ids are mandatory ";
              }
              if (this.state.network.subnet_ids.length > 0) {
                this.state.network.subnet_ids.map((subnet) => {
                  const test = patternSubnet.test(subnet);
                  if (!test) {
                    errors.subnet_ids =
                      "The subnet Ids must begin by 'subnet-' and must contains only letters and numbers";
                  }

                  return errors;
                });
              }
              if (
                this.state.network.security_group_ids.length < 1 ||
                !this.state.network.security_group_ids
              ) {
                errors.security_group_ids =
                  "The security group Ids are mandatory ";
              }
              if (this.state.network.security_group_ids.length > 0) {
                this.state.network.security_group_ids.map((securityGroup) => {
                  const test = patternSecurity.test(securityGroup);
                  if (!test) {
                    errors.security_group_ids =
                      "The security group Ids must begin by 'sg-' and must contains only letters and numbers";
                  }

                  return errors;
                });
              }
              if (this.state.network.vpc_id) {
                const test = patternVpc.test(this.state.network.vpc_id);
                if (!test) {
                  errors.vpc_id =
                    "The vpc Id must begin by 'vpc-' and must contains only letters and numbers";
                }
              }
              if (this.state.network.noproxy) {
                const test = patternNoproxys.test(
                  this.state.network.noproxy.replace(/\s/g, "")
                );
                if (!test) {
                  errors.noproxy = "Invalid proxy URLs";
                }
              }
              if (this.state.network.proxy) {
                const test = patternProxy.test(this.state.network.proxy);
                if (!test) {
                  errors.proxy = "Invalid proxy URL";
                }
              }
              return errors;
            }}
            onSubmit={() => {
              this.save();
            }}
          >
            {({ errors }) => (
              <Form>
                {Object.keys(errors).length > 0 && (
                  <div className="error-msg">
                    {"You have some errors. Please, verify all fields."}
                  </div>
                )}
                {this.state.errorSubmit && (
                  <Error
                    error={this.state.errorSubmit}
                    path={"NetworkCreate"}
                    rawErrorMessage
                    stringOnly
                  />
                )}
                <fieldset className="form-group">
                  <legend className="label-form">name</legend>
                  <Field
                    type="name"
                    name="name"
                    className="form-control"
                    placeholder="Network name..."
                    onChange={(event) =>
                      this.handleChange("name", event.target.value)
                    }
                    value={this.state.network.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">description</legend>
                  <Field
                    component="textarea"
                    name="description"
                    className="form-control"
                    onChange={(event) =>
                      this.handleChange("description", event.target.value)
                    }
                    placeholder="Description..."
                    value={this.state.network.description}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <div className="mb-3">
                  <label className="label-form cluster-label-form">
                    Region
                  </label>
                  <select
                    onChange={(event) =>
                      this.handleChange("region", event.target.value)
                    }
                    className="form-control form-control-sm"
                    value={this.state.network.region}
                    disabled={this.state.disableField}
                  >
                    {REGIONS.map((region) => (
                      <option key={region.key} value={region.key}>
                        {`(${region.key})  ${region.label}`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={"alert alert-light"}>
                  <i className="fas fa-exclamation-circle" />
                  &nbsp;Requirements:&nbsp;
                  <div>
                    <div>The network must have a specific configuration:</div>
                    <div>
                      {" "}
                      - To be compliant with Engie Security and to have access
                      to S3 data, you need to attach a S3 VPC Endpoint to the
                      VPC containing your subnet
                    </div>
                    <div>
                      {" "}
                      - To be able to stop your sagemaker notebook after 1 hour
                      of inactivity, you need to attach a Sagemaker VPC Endpoint
                      to the VPC containing your subnet
                    </div>
                    <div>
                      {" "}
                      - Three subnets in three different availability zones are
                      required for Redshift Serverless
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="label-form cluster-label-form">
                    Network Type
                  </label>
                  <select
                    onChange={(event) =>
                      this.handleChange("network_type", event.target.value)
                    }
                    className="form-control form-control-sm"
                    value={this.state.network.network_type}
                  >
                    {[
                      { label: "Sagemaker", key: "SAGEMAKER" },
                      {
                        label: "Redshift Serverless",
                        key: "REDSHIFT_SERVERLESS"
                      }
                    ].map((nType) => (
                      <option key={nType.key} value={nType.key}>
                        {`${nType.label}`}
                      </option>
                    ))}
                  </select>
                </div>
                <fieldset className="form-group">
                  <legend className="label-form">VPC_ID</legend>
                  <Field
                    type="vpc_id"
                    name="vpc_id"
                    className="form-control"
                    placeholder="Vpc ID..."
                    onChange={(event) =>
                      this.handleChange("vpc_id", event.target.value)
                    }
                    value={this.state.network.vpc_id}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <MultipleValueTextInput
                    onItemAdded={(e) =>
                      this.handleChange("subnet_ids", e.target.value)
                    }
                    onItemDeleted={(e) =>
                      this.handleChange("subnet_ids", e.target.value)
                    }
                    values={this.state.network.subnet_ids}
                    label={"Subnets"}
                    name={"subnet_ids"}
                    placeholder="Subnet Ids..."
                    classNameInput={"form-control"}
                    shouldAddOnBlur
                    disabled={this.state.disableField}
                  />
                  <ErrorMessage
                    name="subnet_ids"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <MultipleValueTextInput
                    onItemAdded={(e) =>
                      this.handleChange("security_group_ids", e.target.value)
                    }
                    onItemDeleted={(e) =>
                      this.handleChange("security_group_ids", e.target.value)
                    }
                    values={this.state.network.security_group_ids}
                    label={"Security Groups"}
                    name={"security_group_ids"}
                    placeholder={
                      "Add security group ids, separate them by COMMA or ENTER"
                    }
                    classNameInput={"form-control"}
                    shouldAddOnBlur
                    disabled={this.state.disableField}
                  />
                  <ErrorMessage
                    name="security_group_ids"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Proxy</legend>
                  <Field
                    type="proxy"
                    name="proxy"
                    className="form-control bg-white"
                    placeholder="Proxy..."
                    onChange={(event) =>
                      this.handleChange("proxy", event.target.value)
                    }
                    value={this.state.network.proxy}
                  />
                  <ErrorMessage
                    name="proxy"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Proxy exclusions</legend>
                  <Field
                    type="noproxy"
                    name="noproxy"
                    className="form-control"
                    placeholder="Proxy exclusions (Type Urls separated by commas)"
                    onChange={(event) =>
                      this.handleChange("noproxy", event.target.value)
                    }
                    value={this.state.network.noproxy}
                    disabled={!this.state.network.proxy}
                  />
                  <ErrorMessage
                    name="noproxy"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <div className={"alert alert-light"}>
                  <i className="fas fa-exclamation-circle" />
                  &nbsp;Informations:&nbsp;
                  <div>
                    <div>The following URLs are excluded by default:</div>
                    <div> - 127.0.0.1</div>
                    <div> - localhost</div>
                    <div> - 169.254.169.254</div>
                  </div>
                </div>
                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={this.state.isSubmitting}
                    className="butn"
                  >
                    {this.state.isSubmitting && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Update Network
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(NetworkEdit)));
