/* @flow */
import React from "react";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import Loading from "../../../../components/loading";
import StageMetadata from "../profiling/stageMetadata";
import GraphQl from "../../../../graphQL";
import ToggleBasicMetadataDisplay from "../toggleBasicMetadataDisplay";
import "./style.less";
import ProfilingAction from "../../../stages/components/profilingAction";

type propTypes = {
  dataset: Object,
  outputId: string,
  api: GraphQl
};

type stateTypes = {
  stage: Object,
  ready: boolean,
  error?: Object
};

class DefaultStageMetadata extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      stage: undefined
    };
  }

  componentDidMount() {
    if (
      ["owner", "shared"].includes(this.props.dataset.access) ||
      this.props.dataset.basic_metadata
    ) {
      this.getStage();
    } else {
      this.setState({ ready: true });
    }
  }

  getStage = () => {
    this.props.api.dataset
      .getStage(this.props.outputId, this.props.dataset.uri)
      .then((stage) => {
        this.setState({
          stage,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"Metadata"} />;
    if (!this.state.ready) return <Loading message={"Metadata View"} />;
    if (!this.state.stage) {
      return <div className="default-stage-metadata">Nothing to display</div>;
    }

    return (
      <div className="default-stage-metadata">
        <div className="header-metadata">
          <div className="header-metadata-left">
            {(this.state.stage || this.props.dataset) && (
              <div className="metadata-container">
                <span className="metadata-title">Stage name </span>
                {this.state.stage.name || this.props.dataset.default_table}
              </div>
            )}
            {this.state.stage.profiling_nb_rows && (
              <div className="metadata-container">
                <span className="metadata-title">Stage rows count </span>
                {this.state.stage.profiling_nb_rows || "Not available"}
              </div>
            )}
          </div>
          {this.props.dataset.access === "owner" && (
            <div className="header-metadata-right">
              <ToggleBasicMetadataDisplay dataset={this.props.dataset} />
              <ProfilingAction stage={this.state.stage} />
            </div>
          )}
        </div>

        <StageMetadata
          stage={this.state.stage}
          dataset={this.props.dataset}
          reloadData={this.getStage}
        />
      </div>
    );
  }
}

export default withAppSync(DefaultStageMetadata);
