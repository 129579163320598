/* @flow */
import React from "react";

type propTypes = {
  onClick: Function,
  className?: string,
  disabled?: boolean,
  icon?: string,
  label: string,
  tooltipText?: string
};
type stateTypes = {
  isClicked: boolean
};

class ButtonAction extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isClicked: false
    };
  }

  onClick = () => {
    this.setState({ isClicked: true }, () => {
      Promise.resolve(this.props.onClick()).then(() => {
        this.setState({ isClicked: false });
      });
    });
  };

  render() {
    return (
      <div className="tooltiped">
        <button
          disabled={this.props.disabled || this.state.isClicked}
          type={"button"}
          className={this.props.className ? this.props.className : "butn"}
          onClick={() => {
            if (!this.state.isClicked) {
              this.onClick();
            }
          }}
        >
          {this.state.isClicked && (
            <i className="fas fa-circle-notch fa-spin fa-spacing" />
          )}
          {this.props.icon ? (
            <i className={`fas fa-spacing ${this.props.icon}`} />
          ) : (
            ""
          )}
          {this.props.label}
        </button>
        {this.props.tooltipText && (
          <div className="tooltiptext">{this.props.tooltipText}</div>
        )}
      </div>
    );
  }
}

export default ButtonAction;
