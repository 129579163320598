/* @flow */

export const listGroupPolicies = {
  query: `query Q ($accountUri: String!,$groupUri:String!, $platformUri: String!)  {
        getGroup(accountUri: $accountUri, groupUri:$groupUri) {
            attachedIamPolicies (platformUri: $platformUri) {
                policyarn
                description
                createdat
            }
        }
     }
    `,
  keyOutput: "getGroup.attachedIamPolicies"
};

export type GroupPoliciesPropsTypes = Array<{
  policyarn: string,
  description: string,
  createdat: string
}>;
