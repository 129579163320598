/* @flow */
import React from "react";
import classnames from "classnames";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import "./accelerationTemplateCard.less";
import DeployAccelerationTemplateModal from "./DeployAccelerationTemplateModal";
import withAppSync from "../../../AppsyncHOC";
import DeleteAccelerationTemplateModal from "./DeleteAccelerationTemplateModal";
import EditAccelerationTemplateModal from "./EditAccelerationTemplateModal";
import AccelerationTemplateProjectPart from "./AccelerationTemplateProjectPart";

const Log = Logger("CartAccelerationTemplates");

type propTypes = {
  api: GraphQl,
  cart: Object,
  accelerationTemplate: Object,
  reloadPage: Function
};

type stateTypes = {
  deployTemplateModal: boolean,
  editTemplateModal: boolean,
  deleteTemplateModal: boolean,
  isFetchingStatus: boolean,
  status?: string
};

class AccelerationTemplateCard extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      deployTemplateModal: false,
      editTemplateModal: false,
      deleteTemplateModal: false,
      isFetchingStatus: false
    };
  }

  componentDidMount(): void {
    this.getStatus();
  }

  componentWillUnmount(): void {
    if (this.timeout) clearTimeout(this.timeout);
  }

  openToDeployTemplateModal = () =>
    this.setState({ deployTemplateModal: true });

  closeToDeployTemplateModal = () =>
    this.setState({ deployTemplateModal: false });

  openToDeleteTemplateModal = () =>
    this.setState({ deleteTemplateModal: true });

  closeToDeleteTemplateModal = () =>
    this.setState({ deleteTemplateModal: false });

  openToEditTemplateModal = () => this.setState({ editTemplateModal: true });

  closeToEditTemplateModal = () => this.setState({ editTemplateModal: false });

  getStatus = () => {
    if (
      this.props.accelerationTemplate.template_projects &&
      this.props.accelerationTemplate.template_projects.length > 0
    ) {
      this.setState({ isFetchingStatus: true });
      this.props.api.cart
        .getAccelerationTemplateStackStatusFromProject(
          this.props.accelerationTemplate.template_projects[0].id
        )
        .then((status) => {
          this.setState({ status });
          if (status.includes("_IN_PROGRESS")) {
            this.timeout = setTimeout(this.getStatus, 5000);
          } else {
            this.setState({ isFetchingStatus: false });
          }
        })
        .catch((error) => {
          Log.error(error);
          this.setState({ status: error.message, isFetchingStatus: false });
        });
    }
  };

  render() {
    const {
      acceleration_template: accelerationTemplate,
      network,
      platform,
      monitoring_email: monitoringEmail,
      template_projects: templateProjects
    } = this.props.accelerationTemplate;
    return (
      <div className="acceleration-template-card">
        <div className="cdh-card card-shadow card bg-white">
          <div className="cdh-card-header">
            <div className="cdh-card-begin">
              <div className="cdh-card-begin-content">
                <i className="fas fa-rocket fa-spacing" />
                Acceleration Template
              </div>
            </div>
          </div>
          <div className="cdh-card-body">
            <div className="cdh-card-body-left">
              <span className="text-capitalize cdh-card-name">
                {accelerationTemplate.name}
              </span>
              <div className="network cdh-cart-description">
                <span className="">
                  {accelerationTemplate.description ||
                  (accelerationTemplate.description &&
                    accelerationTemplate.description > 125)
                    ? `${accelerationTemplate.description.substring(0, 125)}`
                    : "No description"}
                </span>
                <div>
                  <a
                    content="link"
                    href={accelerationTemplate.link_to_doc}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More details
                  </a>
                </div>
              </div>

              <div className="cdh-card-details">
                <div className="cdh-card-detail">
                  <i className="fas fa-globe-europe" />
                  <span className="content-card-bucket">
                    &nbsp;<b>Environment: </b>
                    {platform.name} ({platform.aws})
                  </span>
                </div>

                <div className="cdh-card-detail">
                  <i className="fas fa-globe-europe" />
                  <span className="content-card-bucket">
                    &nbsp;<b>Monitoring Email: </b>
                    {monitoringEmail}
                  </span>
                </div>

                <div className="cdh-card-detail">
                  <i className="fas fa-globe-europe" />
                  <span className="content-card-bucket">
                    &nbsp;<b>Network: </b>
                    {network.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="cdh-card-body-right">
              <div className="cdh-card-body-actions">
                {templateProjects && templateProjects.length > 0 ? (
                  <div>
                    <div
                      className={classnames("btn-cart", {
                        disabled: this.state.isFetchingStatus
                      })}
                      onClick={() => {
                        if (!this.state.isFetchingStatus)
                          this.openToEditTemplateModal();
                      }}
                    >
                      <i className="fas fa-edit fa-spacing" />
                      <span className="text-actions">Edit</span>
                    </div>
                    <div
                      className={classnames("btn-cart", {
                        disabled: this.state.isFetchingStatus
                      })}
                      onClick={() => {
                        if (!this.state.isFetchingStatus)
                          this.openToDeleteTemplateModal();
                      }}
                    >
                      <i className="fas fa-trash-alt fa-spacing" />
                      <span className="text-actions">Delete</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="btn-cart"
                    onClick={this.openToDeployTemplateModal}
                  >
                    <i className="fas fa-rocket fa-spacing" />
                    <span className="text-actions">Deploy</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cdh-card-footer">
            <div className="cdh-card-begin">
              {templateProjects && templateProjects.length > 0 ? (
                <AccelerationTemplateProjectPart
                  templateProject={templateProjects[0]}
                  status={this.state.status}
                  isFetchingStatus={this.state.isFetchingStatus}
                  cart={this.props.cart}
                  environmentUri={platform.uri}
                />
              ) : (
                <div className="available-for-deployment">
                  <i className="fas fa-tasks fa-spacing" />
                  <span className="text-footer-left">
                    Available for deployment
                  </span>
                </div>
              )}
            </div>

            <div className="cdh-card-end" />
          </div>
        </div>

        {this.state.deployTemplateModal && (
          <DeployAccelerationTemplateModal
            key={this.props.accelerationTemplate.id}
            cart={this.props.cart}
            accelerationTemplate={this.props.accelerationTemplate}
            onDeployment={() => {
              this.props.reloadPage().then(this.getStatus);
              this.closeToDeployTemplateModal();
            }}
            onClose={this.closeToDeployTemplateModal}
          />
        )}

        {this.state.editTemplateModal && (
          <EditAccelerationTemplateModal
            key={this.props.accelerationTemplate.id}
            accelerationTemplate={this.props.accelerationTemplate}
            onEdit={() => {
              this.props.reloadPage().then(this.getStatus);
              this.closeToEditTemplateModal();
            }}
            onClose={this.closeToEditTemplateModal}
          />
        )}

        {this.state.deleteTemplateModal && (
          <DeleteAccelerationTemplateModal
            key={this.props.accelerationTemplate.id}
            cart={this.props.cart}
            accelerationTemplate={this.props.accelerationTemplate}
            onDelete={() => {
              this.props.reloadPage();
              this.closeToDeleteTemplateModal();
            }}
            onClose={this.closeToDeleteTemplateModal}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(AccelerationTemplateCard);
