/* @flow */

export const getGroupMembers = {
  query: `query Q ($groupUri: String!,  $options: QueryOptions) {
        listGroupMembers (groupUri: $groupUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                added_at
            }
        }
    }`,
  keyOutput: "listGroupMembers"
};
