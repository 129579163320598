/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import stringcutter from "../../../components/stringcutter";
import "./style.less";

type propTypes = {
  policy: Object,
  openDeletePolicyModal: Function
};

type stateTypes = {};

class PolicyRow extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <tr className="table-row">
        <th className="table-group-column" scope="row">
          {this.props.policy.group.name}
        </th>
        <td className="table-env-column">{`${this.props.policy.environment.name} (${this.props.policy.environment.aws})`}</td>
        <td className="table-arn-column">{this.props.policy.policyarn}</td>
        <td className="table-desc-column">
          {stringcutter(this.props.policy.description, 40)}
        </td>
        <td className="table-projects-column">
          {this.props.policy.nb_project}
        </td>
        <td className="table-action-column">
          <i
            onClick={() => this.props.openDeletePolicyModal(this.props.policy)}
            className="far fa-trash-alt"
          ></i>
        </td>
      </tr>
    );
  }
}

export default PolicyRow;
