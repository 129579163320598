export const listDatabricksAccountOrgs = {
  query: `query Q ($uuid: String!, $options: QueryOptions) {
        listDatabricksAccountOrgs(uuid: $uuid, options: $options) {
            results {
              dbxAccounts {
                results {
                  uuid
                  dbx_account_name
                  dbx_account_id
                }
                total
                limit
                offset
              }
            }
            limit
            offset
            total
        }
    }
    `,
  keyOutput: "listDatabricksAccountOrgs"
};
