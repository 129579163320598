/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import { connect } from "react-redux";
import Logger from "../../../../../utils/logger";
import stringcutter from "../../../../../components/stringcutter";
import { updateMessage } from "../../../../globalNotifications/actions";
import withAppSync from "../../../../AppsyncHOC";
import withGoTo from "../../../../goToHOC";
import GraphQl from "../../../../../graphQL";
import logoPowerBI from "./powerbi.svg";
import logoRedshift from "./redshift-logo.png";
import Error from "../../../../../components/error";
import ButtonAction from "../../../../../components/buttonAction";
import Modal from "../../../../../components/modal";

const Log = Logger("CartIntegrationsPowerBI");

type propTypes = {
  cart: {
    uri: string,
    name: string,
    is_attached_to_powerbi: boolean,
    redshiftClusters: Array<Object>,
    platforms: Array<Object>
  },
  api: GraphQl,
  showGlobalNotification: Function,
  reloadCart: Function,
  redshiftCluster: Object
};

type stateTypes = {
  error?: Object,
  errorAttach?: Object,
  errorDetach?: Object,
  isAttached: boolean,
  isFetching: boolean,
  modal: boolean,
  modalPassword: boolean,
  password: string,
  envSelected: string,
  status?: string,
  passwordEmpty: boolean,
  clusterName: string
};

class CartIntegrationsPowerBI extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      errorAttach: undefined,
      errorDetach: undefined,
      isFetching: false,
      isAttached: !!["attached", "pending"].includes(
        props.redshiftCluster.redshift_attached_to_powerbi_status
      ),
      modal: false,
      modalPassword: false,
      envSelected: null,
      password: "",
      status: props.redshiftCluster.redshift_attached_to_powerbi_status,
      passwordEmpty: true,
      clusterName: ""
    };
  }

  componentDidMount() {
    if (this.props.redshiftCluster.cluster_name) {
      this.setState({ clusterName: this.props.redshiftCluster.cluster_name });
    } else if (this.props.cart) {
      this.getNameCluster();
    } else {
      this.timeout = setTimeout(this.getNameCluster, 1500);
    }
    if (this.state.status === "pending") {
      this.checkStatus();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  getNameCluster = () => {
    if (this.props.cart) {
      const cluster = this.props.cart.redshiftClusters.find(
        (element) =>
          element.uri === this.props.redshiftCluster.redshiftcluster_uri
      );
      this.setState({ clusterName: cluster.cluster_name });
    }
  };

  getStatusPBI = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.cart
          .getRedshiftSendCommandStatus(
            this.props.cart.uri,
            this.props.redshiftCluster.redshiftcluster_uri
          )
          .then((status) => {
            this.setState({ isFetching: false });
            if (["pending"].includes(status)) {
              return false;
            }

            return this.setState(
              {
                status
              },
              () => {
                clearInterval(this.interval);
                this.interval = undefined;
              }
            );
          })
          .catch((error) => {
            Log.error(error);
            return this.setState(
              {
                status: "failed-to-attach",
                isFetching: false
              },
              () => {
                clearInterval(this.interval);
                this.interval = undefined;
              }
            );
          });
      }
    );
  };

  checkStatus = () => {
    this.interval = setInterval(this.getStatusPBI, 5000);
  };

  attachPBI = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.cart
          .attachRedhsiftPowerbi(
            this.props.cart.uri,
            this.props.redshiftCluster.redshiftcluster_uri,
            this.state.password,
            this.state.envSelected
          )
          .then(() => {
            this.props.showGlobalNotification({
              message: "PowerBI is being attached !",
              type: "success"
            });
            this.setState(
              {
                isFetching: false,
                isAttached: true,
                status: "pending",
                modal: false,
                modalPassword: false
              },
              this.checkStatus
            );
            if (this.props.reloadCart) this.props.reloadCart();
          })
          .catch((errorAttach) => {
            Log.error(errorAttach);
            this.setState({
              isFetching: false,
              errorAttach,
              isAttached: true
            });
          });
      }
    );
  };

  detachPBI = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.cart
          .detachRedhsiftPowerbi(
            this.props.cart.uri,
            this.props.redshiftCluster.redshiftcluster_uri,
            this.props.redshiftCluster.redshift_dsn
          )
          .then(() => {
            this.props.showGlobalNotification({
              message: "PowerBI is detached.",
              type: "success"
            });
            this.setState({
              isFetching: false,
              isAttached: false,
              modal: false,
              modalPassword: false,
              status: undefined
            });
            if (this.props.reloadCart) this.props.reloadCart();
          })
          .catch((errorDetach) => {
            Log.error(errorDetach);
            this.setState({
              isFetching: false,
              errorDetach
            });
          });
      }
    );
  };

  openModal = () => this.setState({ modal: true });

  closeModal = () => this.setState({ modal: false });

  openPasswordModal = () => this.setState({ modalPassword: true });

  closePasswordModal = () => this.setState({ modalPassword: false });

  onChange = (event) => {
    const { value } = event.target;
    if (value.length <= 0) {
      this.setState({ passwordEmpty: true });
    } else {
      this.setState({
        password: value,
        passwordEmpty: false
      });
    }
  };

  onSelectEnv = (envSelected) => {
    this.setState({ envSelected: envSelected.value });
  };

  bodyPasswordModal() {
    return (
      <div>
        <div>Type your Redshift Cluster password</div>

        <div className="mt-2">
          <input
            onChange={this.onChange}
            className="form-control bg-white"
            type="password"
            placeholder="Password"
          />
        </div>

        {this.state.passwordEmpty && (
          <span className="error-confirm">Password cannot be empty</span>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="cart-visualization">
        {!this.state.isAttached ? (
          <div className="connector">
            <div className="connector-block card-shadow">
              <div className="connector-attached">
                {this.state.isFetching && (
                  <div className="connector-loading">
                    <i className="fa-spacing fas fa-circle-notch fa-spin" />
                  </div>
                )}
              </div>
              <div className="connector-logo connector-logo-orange">
                <img
                  className="connector-logo-img"
                  src={logoPowerBI}
                  alt="PowerBI"
                  width={50}
                />
              </div>
              <div className="detail-logo">
                <img
                  className="connector-logo-img"
                  src={logoRedshift}
                  alt="Athena"
                  width={35}
                  height={35}
                />
              </div>
              <div className="connector-text min-width">
                {stringcutter(this.state.clusterName, 22)}
                <div className="text-desc">PowerBI Redshift connector</div>
              </div>
              <div className="connector-button">
                <button
                  type="button"
                  className="butn"
                  onClick={this.openPasswordModal}
                >
                  Attach PowerBI
                </button>
              </div>
            </div>
            {this.state.modalPassword && (
              <Modal
                id={"modal-cart-integration-power-bi-attach-env-with-password"}
                title={"Password Redshift Cluster"}
                body={this.bodyPasswordModal()}
                errorMessage={this.state.errorAttach}
                actions={[
                  <ButtonAction
                    label="Attach"
                    className="butn butn-create"
                    disabled={this.state.passwordEmpty}
                    onClick={this.attachPBI}
                  />,
                  <ButtonAction
                    label="Close"
                    onClick={this.closePasswordModal}
                  />
                ]}
              />
            )}
          </div>
        ) : (
          <div className="connector">
            <div className="connector-block no-flex card-shadow">
              <div className="card-redshift-PBI">
                <div className="connector-attached">
                  {(!this.state.status || this.state.status === "pending") && (
                    <div className="connector-loading tooltiped">
                      <i className="fa-spacing fas fa-circle-notch fa-spin" />
                      <div className="tooltiptext">
                        We are connecting your project to PowerBI, wait a
                        moment.
                      </div>
                    </div>
                  )}
                  {this.state.status === "attached" && (
                    <div className="connector-good">
                      <i className="fas fa-check" />
                    </div>
                  )}
                  {this.state.status === "failed-to-attach" && (
                    <div className="connector-failure tooltiped">
                      <i className="fas fa-times" />
                      <div className="tooltiptext">
                        Please check your redshift password, credentials or
                        network.
                      </div>
                    </div>
                  )}
                </div>
                <div className="connector-logo connector-logo-orange">
                  <img
                    className="connector-logo-img"
                    src={logoPowerBI}
                    alt="PowerBI"
                    width={50}
                  />
                </div>
                <div className="detail-logo">
                  <img
                    className="connector-logo-img"
                    src={logoRedshift}
                    alt="Athena"
                    width={35}
                    height={35}
                  />
                </div>
                <div className="connector-text min-width">
                  {stringcutter(this.state.clusterName, 22)}
                  <div className="text-desc">PowerBI Redshift connector</div>
                </div>
                <div className="connector-button">
                  <button
                    type="button"
                    className="butn butn-delete"
                    disabled={
                      this.state.status === "pending" || this.state.isFetching
                    }
                    onClick={this.openModal}
                  >
                    Detach PowerBI
                  </button>
                </div>
              </div>
              {this.state.status === "attached" && (
                <div className="connector-message">
                  Next step: Use PowerBI Desktop !
                </div>
              )}
              {this.state.errorAttach && (
                <Error
                  stringOnly
                  error={this.state.errorAttach}
                  path={"AttachPBIRedshift"}
                />
              )}
            </div>

            {this.state.modal && (
              <Modal
                title={`Detach project ${this.props.cart.name} from PowerBI`}
                body={"Are you sure ?"}
                errorMessage={this.state.errorDetach}
                actions={[
                  <ButtonAction
                    label="Detach"
                    className="butn butn-delete"
                    onClick={this.detachPBI}
                  />,
                  <ButtonAction label="Close" onClick={this.closeModal} />
                ]}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartIntegrationsPowerBI)));
