/* @flow */
import React from "react";
import Tabs from "../../components/tabs";
import routes from "../routes";
import ManagedShareList from "./components/managedShareList";
import ShareRequestList from "./components/shareRequestList";
import NewShare from "./components/NewShare";

type propTypes = {};

type stateTypes = {};

class ShareList extends React.PureComponent<propTypes, stateTypes> {
  render() {
    return (
      <Tabs
        noBackgroundWhite
        defaultActiveIndex={"requester"}
        tabs={[
          {
            name: (
              <div>
                <i className="fas fa-share" />
                New Share
              </div>
            ),
            index: "shares-new",
            route: `${routes.User.MyProfile}#shares-new`,
            content: <NewShare />
          },
          {
            name: (
              <div>
                <i className="fas fa-paper-plane" />
                Sent Requests Access
              </div>
            ),
            index: "shares-requested",
            route: `${routes.User.MyProfile}#shares-requested`,
            content: <ShareRequestList />
          },
          {
            name: (
              <div>
                <i className="fas fa-inbox" />
                Received Requests Access
              </div>
            ),
            index: "shares-owner",
            route: `${routes.User.MyProfile}#shares-owner`,
            content: <ManagedShareList />
          }
        ]}
      />
    );
  }
}

export default ShareList;
