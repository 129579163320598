/* @flow */

export const getDatasetOwner = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      uri
      owner {
        uri
        name
        account {
            uri
            name
        }
      }
      access
      datasource {
        sharingManagementGroup {
            uri
            name
        }
      }
    }
  }
`,
  keyOutput: "getDataset"
};

export type GetDatasetSharingManagementGroupPropTypes = {
  uri: string,
  access: string,
  owner: {
    uri: string,
    name: string,
    account: {
      uri: string,
      name: string
    }
  },
  datasource: {
    sharingManagementGroup: {
      uri: string,
      name: string
    }
  }
};
