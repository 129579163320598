export const getDatasourceForEdit = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      uri
      resource_name
      name
      description
      tags
      region
      accountid
      bucket
      source_type
      database
      RSDatasource{
        network_id
        network{
         id
         name
         subnet_ids
         vpc_id
        }
        workgroup_base_capacity
        rs_namespace_uri
      }
      platform {
        uri
        name
        aws
      }
      owner {
        uri
        name
        account {
            uri
            name
        }
      }
      s3_custom_tags {
        Key
        Value
      }
      sharingManagementGroup {
          uri
          name
      }
    }
  }
`,
  keyOutput: "getDatasource"
};
