/* @flow */

import React from "react";
import { connect } from "react-redux";
import Logger from "../../utils/logger";
import "./style.less";
import { updateMessage } from "../../containers/globalNotifications/actions";
import withAppSync from "../../containers/AppsyncHOC";

const Log = Logger("ExportCSV");

type propTypes = {
  fetchUrlMethod: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  inProgress: boolean
};

class ExportCSV extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false
    };
  }

  fetchCSV = (url, headers) => {
    Log.info(url, headers);
    return window.open(url);
  };

  exportCSV = () => {
    if (this.state.inProgress) return false;
    this.setState({ inProgress: true });
    return this.props
      .fetchUrlMethod()
      .then(({ url, headers }) => {
        this.setState({ inProgress: false });
        this.fetchCSV(url, headers);
        this.props.showGlobalNotification({
          message: "Exported !",
          type: "success"
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ inProgress: false });
        this.props.showGlobalNotification({
          message: "An error occured during export",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div className="export-csv-button">
        <button
          type="button"
          onClick={this.exportCSV}
          className="butn butn-flat"
        >
          {this.state.inProgress && (
            <i className="fas fa-circle-notch fa-spin fa-spacing" />
          )}
          Export to CSV
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(ExportCSV));
