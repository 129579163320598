import React from "react";

const HelpItem = () => (
  <div>
    <div>{" - The Open catalog. "}</div>
    <div>{"You can see all datasets available on cdh. "}</div>
    <div>
      {"You can only see data if you are "}
      <span style={{ color: "var(--color-dataset-owner)" }}>{"owner"}</span>
      {" or you have "}
      <span style={{ color: "var(--color-dataset-shared)" }}>
        {"received the permission"}
      </span>
      {", or you can "}
      <span style={{ color: "var(--color-dataset-other)" }}>
        {"request the permission"}
      </span>
      {" by clicked on the pad lock."}
    </div>
    <div style={{ marginTop: "15px" }} />
    <div>
      <i
        className={"fas fa-eye"}
        style={{ color: "var(--blue-venice)", marginRight: "10px" }}
      />
      {"You can preview the data if you have the permission."}
    </div>
    <div>
      <i
        className={"fas fa-plus"}
        style={{ color: "var(--blue-venice)", marginRight: "10px" }}
      />
      {"You can add the dataset to a project to play with them."}
    </div>
    <div>
      <i
        className={"fas fa-lock-open"}
        style={{ color: "var(--blue-venice)", marginRight: "10px" }}
      />
      {"You can request data permission."}
    </div>
    <div style={{ marginTop: "15px" }} />
    <div>{"- The search and filters"}</div>
    <div>{"You can search for a type of data on the left hand side."}</div>
    <div>{"You can filter and sort datasets, take a look on Filters tab."}</div>
    <hr />
    <div>{"Requirement:"}</div>
    <div>
      {
        "- If no dataset is available, you made need to be added to a group, contact your account admin."
      }
    </div>
  </div>
);

export default HelpItem;
