/* @flow */

import React from "react";
import "./style.less";

type propTypes = {
  datasets?: Array<Object>,
  onChoose: Function,
  onClear: Function
};

type stateTypes = {};

class SearchDatasetsSugestion extends React.PureComponent<
  propTypes,
  stateTypes
> {
  render() {
    if (!Array.isArray(this.props.datasets)) return false;

    if (this.props.datasets.length <= 0) {
      return (
        <ul className="searchDatasetsSugestion">
          <li>
            <div>0 dataset found</div>
          </li>
        </ul>
      );
    }
    return (
      <ul className="searchDatasetsSugestion">
        {this.props.datasets.map((dataset) => (
          <li key={dataset.uri}>
            <div
              onClick={() => {
                this.props.onChoose(dataset);
                this.props.onClear();
              }}
            >
              {dataset.name}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default SearchDatasetsSugestion;
