import React from "react";
import PropTypes from "prop-types";

const Row = ({ className = "", style = {}, children = [], ...rest }) => (
  <div style={style} className={`${className} row`} {...rest}>
    {children}
  </div>
);

Row.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

export default Row;
