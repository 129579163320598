/* @flow */
import React from "react";

import GraphQl from "../../graphQL";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import Loading from "../../components/loading";
import CollibraLink from "./CollibraLink";
import CollibraLinkCreate from "./CollibraLinkCreate";

type propTypes = {
  api: GraphQl,
  account: Object
};

type stateTypes = {
  ready: boolean,
  error: Object,
  openCreateDialog: boolean,
  collibraLinks: Array<Object>
};

class CollibraLinks extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      openCreateDialog: false,
      ready: true,
      collibraLinks: []
    };
  }

  componentDidMount() {
    this.getCollibraLinks();
  }

  getCollibraLinks = () => {
    this.setState({
      ready: false
    });
    this.props.api.collibra
      .list(this.props.account.uri)
      .then((collibraLinks) => {
        this.setState({
          ready: true,
          collibraLinks
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CollibraLinks"} />;
    if (!this.state.ready) return <Loading message="Collibra Links" />;
    return (
      <div className={"environments-container"}>
        <div>
          <div
            className="butn butn-flat"
            onClick={() => this.setState({ openCreateDialog: true })}
          >
            <i className="fas fa-plus butn-icon" />
            <span className="butn-text">Link to Collibra</span>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error error={this.state.error} path={"CollibraLinks"} stringOnly />
          </div>
        )}
        <div className={"card-container"}>
          {this.state.collibraLinks.length <= 0 && (
            <div style={{ marginLeft: "15px" }}>
              Organization not linked to Collibra.
            </div>
          )}
          {this.state.collibraLinks.map((link) => (
            <CollibraLink
              key={link.id}
              api={this.props.api}
              collibraLink={link}
              reloadLinks={this.getCollibraLinks}
            />
          ))}
        </div>
        {this.state.openCreateDialog && (
          <CollibraLinkCreate
            api={this.props.api}
            account={this.props.account}
            open={this.state.openCreateDialog}
            onClose={() => this.setState({ openCreateDialog: false })}
            reloadLinks={this.getCollibraLinks}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(CollibraLinks);
