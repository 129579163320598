/* @flow */
import React from "react";
import { connect } from "react-redux";
import GraphQl from "../../../../../graphQL";
import { updateMessage } from "../../../../globalNotifications/actions";
import withAppSync from "../../../../AppsyncHOC";
import Error from "../../../../../components/error";
import "./redshiftClusterAccess.less";
import Links from "../../../../links";

type propTypes = {
  redshiftCluster: Object,
  groups: Array<Object>,
  api: GraphQl,
  showGlobalNotification: Function,
  reloadGroups: Function
};

type stateTypes = {
  isRemovingGroup: boolean,
  error: Object
};

class RedshiftClusterGroups extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isRemovingGroup: false,
      error: undefined
    };
  }

  revokeRedshiftClusterAccess = (groupUri, clusterUri) => {
    this.setState({
      isRemovingGroup: true
    });
    this.props.api.analytics
      .revokeRedshiftClusterAccess(groupUri, clusterUri)
      .then(() => {
        this.setState({
          isRemovingGroup: false
        });
        this.props.showGlobalNotification({
          message: "Group access revoked",
          type: "success"
        });
        this.props.reloadGroups();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to revoke group access",
          type: "alert"
        });
        this.setState({
          error,
          isRemovingGroup: false
        });
      });
  };

  render() {
    const { isRemovingGroup, error } = this.state;
    const { redshiftCluster, groups } = this.props;
    return (
      <div>
        {error && <Error error={error} path={"RedshiftClusterGroups"} />}
        {!error &&
          groups.length > 0 &&
          groups.map((group) => (
            <div className={"group-item mt-1 border log-container"}>
              <div className="log-details">
                <p>
                  <i className="pr-3 fas fa-user-friends" />
                  <span className="label-display">Group : </span>
                  <Links.Group.View
                    uriGroup={group.uri}
                    state={{ group: group.name }}
                  >
                    {group.name}
                  </Links.Group.View>
                </p>
                <p>
                  <i className="pr-3 fas fa-sitemap" />
                  <span className="label-display">Organization : </span>
                  {group.account.name}
                </p>
              </div>
              <div className="revoke-button-container">
                {group.uri !== redshiftCluster.owneruri && (
                  <div
                    className="butn butn-delete"
                    onClick={() =>
                      this.revokeRedshiftClusterAccess(
                        group.uri,
                        redshiftCluster.uri
                      )
                    }
                  >
                    {isRemovingGroup && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    <i className="fas fa-lock"></i>
                    <span className="butn-text">Revoke Access</span>
                  </div>
                )}
                {group.uri === redshiftCluster.owneruri && (
                  <div
                    className="butn butn-flat"
                    style={{ width: "130px", cursor: "text" }}
                  >
                    <span className="butn-text">Owner</span>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(RedshiftClusterGroups)
);
