/* @flow */

import React from "react";
import { withAuth } from "@okta/okta-react";
import {
  getErrorMessage,
  getErrorMessageWithoutCode,
  isPermissionError,
  understandableErrorMessage
} from "../../utils/error";
import PermissionError from "./PermissionError";
import StandardError from "./StandardError";
import "./style.less";
import conf from "../../config";

type propTypes = {
  title?: string,
  error: Object | string,
  path: string,
  auth: {
    getUser: Function
  },
  comment?: string,
  hideComment?: boolean,
  rawErrorMessage?: boolean, // don't translate the technical message into user friendly message
  stringOnly?: boolean, // return only the error message
  customMessage?: string
};

type stateTypes = {
  isDetailsOpen: boolean
};

class Error extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    hideComment: false,
    rawErrorMessage: false,
    stringOnly: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isDetailsOpen: true
    };
  }

  componentDidMount() {
    if (
      !(typeof this.props.error === "object") ||
      !this.props.error.message ||
      this.props.error.errorType !== "BACKEND-ERROR"
    ) {
      this.sendError();
    }
  }

  handleToggle = () => {
    this.setState((previousState) => ({
      isDetailsOpen: !previousState.isDetailsOpen
    }));
  };

  // eslint-disable-next-line no-console
  sendError = () => console.error(this.props.error, this.props.path);

  sendFeedBack = () => {
    window.open(conf.JIRA_DESK_URL, "_blank");
  };

  render() {
    const {
      error,
      title,
      hideComment,
      comment,
      rawErrorMessage,
      stringOnly,
      customMessage
    } = this.props;
    if (!error) return false;

    if (customMessage) {
      return <div className={"error-inline"}>{customMessage}</div>;
    }

    if (stringOnly) {
      // return only the error message
      return (
        <div className={"error-inline"}>
          {rawErrorMessage
            ? getErrorMessageWithoutCode(error)
            : understandableErrorMessage(getErrorMessage(error))}
        </div>
      );
    }

    if (isPermissionError(error)) {
      return (
        <div className="ooops-error">
          <PermissionError
            error={error}
            handleToggle={this.handleToggle}
            isDetailsOpen={this.state.isDetailsOpen}
            sendFeedBack={this.sendFeedBack}
          />
        </div>
      );
    }

    return (
      <div className="ooops-error">
        <StandardError
          title={title}
          comment={comment}
          hideComment={hideComment}
          error={error}
          handleToggle={this.handleToggle}
          isDetailsOpen={this.state.isDetailsOpen}
          sendFeedBack={this.sendFeedBack}
          rawErrorMessage={rawErrorMessage}
        />
      </div>
    );
  }
}

export default withAuth(Error);
