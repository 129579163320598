import React from "react";
import Cookies from "js-cookie";
import "./style.less";
import logoEngie from "./engie.png";
import config from "../../config";
import TermsConditionsModal from "../../components/termsConditionsModal";
import LoadV2Button from "../../components/load-v2-button/LoadV2Button";
import viva from "./viva.png";
import Tooltip from "@mui/material/Tooltip";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    const hasAcceptedTermsConditions =
      Cookies.get("acceptedTermsConditions") === "true";
    this.state = {
      choiceMode: !hasAcceptedTermsConditions,
      complianceModal: !hasAcceptedTermsConditions
    };
  }

  openComplianceModal = () => {
    this.setState({
      complianceModal: true
    });
  };

  closeComplianceModal = () => {
    this.setState({
      complianceModal: false
    });
  };

  canDisplayV2Button = () => {
    if (window.location.hostname === "commondatahub.engie.com") {
      return false;
    }
    if (window.location.hostname === "sandbox.commondatahub.engie.com") {
      return false;
    }
    return true;
  };

  render = () => (
    <div className="footer">
      <div className="products">
        <p className="firstLabel">One Data Team</p>
        <ul className="projects">
          <li>
            <div className="title">
              <a
                className="default-color"
                href="https://agora.engie.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Agora</b>
              </a>
            </div>
            <div className="sub-title">
              Learn, collaborate and act on data uses
            </div>
          </li>
          <li>
            <div className="title">
              <a className="default-color" href="#">
                common<b>DataHub</b>
              </a>
            </div>
            <div className="sub-title">Store, share & play with data</div>
          </li>
          <li>
            <div className="title">
              <a
                className="default-color"
                href="https://common.api.engie.com/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                common<b>API</b>
              </a>
            </div>
            <div className="sub-title">Learn, use & expose APIs</div>
          </li>
          {this.canDisplayV2Button() && (
            <li>
              <div className="title">CDH v2</div>
              <div className="sub-title">
                <LoadV2Button />
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="links">
        <ul className="social">
          <li>
            <a
              href="https://twitter.com/engiegroup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/EngieFR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/engie/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1NzUzMzAyMjIwOCJ9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip
                describeChild
                title="Follow commonDataHub on Viva engage !"
                placement="top-end"
              >
                <img className="viva" src={viva} alt="viva" />
              </Tooltip>
            </a>
          </li>
        </ul>
        <ul className="terms">
          <li>
            <a
              href="https://confluence.tools.digital.engie.com/display/CDHA/13-CDH+releases"
              target="_blank"
              rel="noopener noreferrer"
            >
              Releases
            </a>
          </li>
          <li>
            <a className="pointer" onClick={this.openComplianceModal}>
              Terms & conditions
            </a>
          </li>
          <li>
            <a
              href={config.HELP_CENTER}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help center
            </a>
          </li>
        </ul>
      </div>
      <div className="brand">
        <img className="logo" src={logoEngie} alt="ENGIE" />
        Energy is data & data is our common wealth
      </div>
      {this.state.complianceModal && (
        <TermsConditionsModal
          choiceMode={this.state.choiceMode}
          onClose={this.closeComplianceModal}
        />
      )}
    </div>
  );
}

export default Footer;
