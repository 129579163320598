/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import Col from "../../../../components/col";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import routes from "../../../routes";
import Breadcrumb from "../../../../components/breadcrumb";
import Logger from "../../../../utils/logger";
import Tabs from "../../../../components/tabs";
import RedshiftClusterInfo from "../components/redshiftClusterInfo";
import Modal from "../../../../components/modal";
import RedshiftClusterPublicKeys from "../components/redshiftClusterPublicKeys";
import RedshiftClusterCredentials from "../components/redshiftClusterCredentials";
import RedshiftClusterUsage from "../components/redshiftClusterUsage";
import RedshiftClusterAccess from "../components/access/redshiftClusterAccess";
import CartIntegrationRedshiftPowerBI from "../../../carts/view/CartVisualization/CartIntegrationsRedshiftPowerBI";
import "./view.less";
import RedshiftODBCConnection from "../components/redshiftODBCConnection";

const Log = Logger("RedshiftClusterView");

type propTypes = {
  match: {
    params: {
      clusterUri: string,
      cartUri: string
    },
    url: string
  },
  location: {
    state: Object
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  auth: { getUser: Function }
};

type stateTypes = {
  cart: Object,
  ready: boolean,
  redshiftCluster: Object,
  redshiftClusterToAttach: Object,
  error: Object,
  deleteError: Object,
  detachError: Object,
  loadingDeleteRedshiftCluster: boolean,
  deleteRedshiftClusterModal: boolean,
  loadingDetachRedshiftCluster: boolean,
  detachRedshiftClusterModal: boolean
};

class RedshiftClusterView extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      redshiftCluster: {},
      redshiftClusterToAttach: {},
      error: undefined,
      deleteError: null,
      detachError: null,
      loadingDeleteRedshiftCluster: false,
      deleteRedshiftClusterModal: false,
      loadingDetachRedshiftCluster: false,
      detachRedshiftClusterModal: false,
      cart: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.cartUri) {
      this.getCart();
      this.getRedshiftClusterwithCartUri();
    } else {
      this.getRedshiftCluster();
    }
  }

  getCart = () => {
    this.props.api.cart
      .getCart(this.props.match.params.cartUri)
      .then((cart) => {
        Log.info("cart:", cart);
        this.setState({
          cart,
          redshiftClusterToAttach: cart.cartRedshiftClusters.find(
            (element) =>
              element.redshiftcluster_uri === this.props.match.params.clusterUri
          )
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });
  };

  getRedshiftClusterProjectDetails = () => {
    this.setState({
      ready: false
    });
    this.props.api.analytics
      .getRedshiftClusterProjectDetails(this.props.match.params.cartUri)
      .then((cart) => {
        Log.info("cart:", cart);
        this.setState({
          cart
        });
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  getRedshiftClusterwithCartUri = () => {
    this.props.api.analytics
      .getRedshiftCluster(
        this.props.match.params.clusterUri,
        this.props.match.params.cartUri
      )
      .then((redshiftCluster) => {
        this.setState({
          redshiftCluster,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  getRedshiftCluster = () => {
    this.props.api.analytics
      .getRedshiftCluster(this.props.match.params.clusterUri, "")
      .then((redshiftCluster) => {
        this.setState({
          redshiftCluster,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  openDetachRedshiftClusterModal = () =>
    this.setState({ detachRedshiftClusterModal: true });

  closeDetachRedshiftClusterModal = () =>
    this.setState({ detachRedshiftClusterModal: false, detachError: null });

  openDeleteRedshiftClusterModal = () =>
    this.setState({ deleteRedshiftClusterModal: true, deleteError: null });

  closeDeleteRedshiftClusterModal = () =>
    this.setState({ deleteRedshiftClusterModal: false });

  deleteRedshiftClusterModal() {
    return (
      <Modal
        title={"Confirm Deletion"}
        body={`Delete the cluster : "${this.state.redshiftCluster.cluster_name}" from commonDataHub and AWS ?`}
        errorMessage={this.state.deleteError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDeleteRedshiftClusterModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.deleteRedshiftCluster()}
          >
            Delete
          </button>
        ]}
      />
    );
  }

  detachRedshiftClusterModal() {
    return (
      <Modal
        title={"Confirm Detach Action"}
        body={`Detach the cluster "${this.state.redshiftCluster.cluster_name}" from commonDataHub ?`}
        errorMessage={this.state.detachError}
        actions={[
          <button
            type="button"
            className="butn"
            onClick={() => this.closeDetachRedshiftClusterModal()}
          >
            Close
          </button>,
          <button
            type="button"
            className="butn butn-delete"
            onClick={() => this.detachRedshiftCluster()}
          >
            Detach
          </button>
        ]}
      />
    );
  }

  deleteRedshiftCluster = () => {
    this.setState({
      loadingDeleteRedshiftCluster: true
    });
    this.props.api.analytics
      .deleteRedshiftCluster(this.state.redshiftCluster.uri, true)
      .then((response) => {
        Log.info("Redshift removed:", response);
        this.props.showGlobalNotification({
          message: "Redshift cluster deleted",
          type: "success"
        });
        this.props.goTo({
          route: `${routes.Playground.Analytics}`,
          params: {
            uriPlayground: this.state.redshiftCluster.platform.uri,
            uriAccount: `uri:account:${this.state.redshiftCluster.platform.accountid}`
          }
        });
        this.setState({
          loadingDeleteRedshiftCluster: false
        });
      })
      .catch((deleteError) => {
        this.setState({
          deleteError,
          loadingDeleteRedshiftCluster: false
        });
      });
  };

  detachRedshiftCluster = () => {
    this.setState({
      loadingDetachRedshiftCluster: true
    });
    this.props.api.analytics
      .detachRedshiftCluster(this.state.redshiftCluster.uri)
      .then((response) => {
        Log.info("Redshift cluster  detached:", response);
        this.props.showGlobalNotification({
          message: "Redshift cluster detached from commonDataHub",
          type: "success"
        });
        this.props.goTo({
          route: `${routes.Playground.Analytics}`,
          params: {
            uriPlayground: this.state.redshiftCluster.platform.uri,
            uriAccount: `uri:account:${this.state.redshiftCluster.platform.accountid}`
          }
        });
        this.setState({
          loadingDetachRedshiftCluster: false
        });
      })
      .catch((detachError) => {
        this.setState({
          detachError,
          loadingDetachRedshiftCluster: false
        });
      });
  };

  getTabs = () => {
    const tabs = [
      {
        name: "Information",
        index: "information",
        route: `${this.props.match.url}#information`,
        content: (
          <div id="nav-information">
            <RedshiftClusterInfo
              redshiftCluster={this.state.redshiftCluster}
              ready={this.state.ready}
              error={this.state.error}
            />
          </div>
        )
      },
      {
        name: "Credentials",
        index: "credentials",
        route: `${this.props.match.url}#credentials`,
        content: (
          <div id="nav-clustercredentials">
            <RedshiftClusterCredentials
              cart={this.state.cart}
              redshiftCluster={this.state.redshiftCluster}
              ready={this.state.ready}
            />
          </div>
        )
      },
      {
        name: "ssh public key",
        index: "keys",
        route: `${this.props.match.url}#keys`,
        content: (
          <div id="nav-clusterkeys">
            <RedshiftClusterPublicKeys
              redshiftCluster={this.state.redshiftCluster}
              ready={this.state.ready}
            />
          </div>
        )
      }
    ];
    if (
      this.props.match.params.cartUri &&
      this.state.redshiftCluster.credentials_expiration_date
    ) {
      tabs.push({
        name: <div>{"ODBC Connection"}</div>,
        index: "ODBC",
        route: `${this.props.match.url}#ODBC`,
        content: (
          <div id="nav-ODBC">
            <RedshiftODBCConnection
              redshiftCluster={this.state.redshiftCluster}
              ready={this.state.ready}
            />
          </div>
        )
      });
    }
    if (!this.props.match.params.cartUri) {
      tabs.push({
        name: "Cluster usage",
        index: "usage",
        route: `${this.props.match.url}#usage`,
        content: (
          <div id="nav-usage">
            <RedshiftClusterUsage
              redshiftCluster={this.state.redshiftCluster}
            />
          </div>
        )
      });
      tabs.push({
        name: "Access Management",
        index: "access",
        route: `${this.props.match.url}#access`,
        content: (
          <div id="nav-access">
            <RedshiftClusterAccess
              redshiftCluster={this.state.redshiftCluster}
              match={this.props.match}
            />
          </div>
        )
      });
    }
    return tabs;
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"RedshiftClusterView"} />;
    if (!this.state.ready) return <Loading message={"Redshift Cluster view"} />;
    return (
      <div className="cartView">
        <Breadcrumb
          view={`Redshift Cluster - ${
            this.state.redshiftCluster.cluster_name || ""
          }`}
        />
        {!this.props.match.params.cartUri &&
          this.state.redshiftCluster.status === "available" && (
            <div className="create-container">
              <button
                className="butn butn-delete"
                onClick={() => this.openDeleteRedshiftClusterModal()}
                type={"button"}
              >
                {this.state.loadingDeleteRedshiftCluster && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash-alt"></i>
                <span className="butn-text">Delete</span>
              </button>
              <button
                className="butn butn-check"
                onClick={() => this.openDetachRedshiftClusterModal()}
                type={"button"}
                style={{ marginRight: "5px" }}
              >
                {this.state.loadingDetachRedshiftCluster && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fa fa-expand"></i>
                <span className="butn-text">Detach</span>
              </button>
            </div>
          )}
        {this.props.match.params.cartUri && (
          <div className="container-action">
            <div className={"cart-visualizations"}>
              <CartIntegrationRedshiftPowerBI
                cart={this.state.cart}
                redshiftCluster={this.state.redshiftClusterToAttach}
                reloadCart={this.getCart}
              />
            </div>
          </div>
        )}
        <div className={"row justify-content-left"}>
          <Col size={12}>
            <Tabs
              isPreload={false}
              defaultActiveIndex={"information"}
              tabs={this.getTabs()}
            />
          </Col>
        </div>
        <div>
          {this.state.deleteRedshiftClusterModal &&
            this.deleteRedshiftClusterModal()}
        </div>
        <div>
          {this.state.detachRedshiftClusterModal &&
            this.detachRedshiftClusterModal()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(withGoTo(RedshiftClusterView))
);
