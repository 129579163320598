/* @flow */
import React from "react";

type propTypes = {
  notificationType: Object,
  handleChange: Function,
  section: string
};

type stateTypes = {};

class NotificationSettingRow extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="notification-row">
        <div className="notifcation-text-container">
          <div className="notification-name">
            {this.props.notificationType.name}
          </div>
          <div className="notification-desc">
            {this.props.notificationType.description}
          </div>
        </div>
        <div className="notifcation-check-container">
          <input
            id={this.props.notificationType.name}
            type="checkbox"
            className={"checkbox-facet"}
            checked={this.props.notificationType.enable}
            onClick={(event) =>
              this.props.handleChange(
                this.props.notificationType.id,
                this.props.section,
                event.target.checked
              )
            }
          />
          <label
            htmlFor={this.props.notificationType.name}
            className="fas"
          ></label>
        </div>
      </div>
    );
  }
}

export default NotificationSettingRow;
