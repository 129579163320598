import React from "react";
import PropTypes from "prop-types";

class Stringslider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      neverRunning: false,
      isRunning: false,
      direction: "left",
      position: 0,
      style: {
        left: "0px",
        position: "relative",
        height: props.height,
        whiteSpace: "nowrap",
        width: "max-content"
      },
      sliderSize: 0
    };
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.slide = this.slide.bind(this);
    this.slideLoop = this.slideLoop.bind(this);
    this.moveToLeft = this.moveToLeft.bind(this);
    this.moveToRight = this.moveToRight.bind(this);
    this.stopSlider = this.stopSlider.bind(this);
    this.startSlider = this.startSlider.bind(this);

    this.getSliderSize = this.getSliderSize.bind(this);
    this.attachRef = this.attachRef.bind(this);
  }

  componentDidMount() {
    this.getSliderSize();
  }

  componentDidUpdate() {
    if (this.state.sliderSize === 0) this.getSliderSize();
    return true;
  }

  componentWillUnmount() {
    this.stopSlider();
    if (this.timeout) clearTimeout(this.timeout);
  }

  onMouseEnter() {
    this.getSliderSize().then(() => this.startSlider());
  }

  onMouseLeave() {
    this.stopSlider();
  }

  getSliderSize() {
    // get the width of the children
    if (this.element) {
      const data = this.element.getBoundingClientRect();
      return new Promise((r) => {
        if (data.width === 0) return r();
        return this.setState(
          {
            sliderSize: data.width,
            neverRunning: data.width < this.props.width
          },
          r()
        );
      });
    }
    return new Promise((r) => r());
  }

  attachRef(element) {
    // save ref of children component
    this.element = element;
    this.getSliderSize();
  }

  startSlider() {
    if (this.state.sliderSize < this.props.width) return;
    this.setState({ isRunning: true }, this.slideLoop);
  }

  stopSlider() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  moveToLeft() {
    if (!this.state.isRunning) return false;
    return this.setState((prevState) => {
      const style = Object.assign({}, prevState.style);
      style.left = `${prevState.position - 1}px`;
      return {
        style,
        position: prevState.position - 1
      };
    });
  }

  moveToRight() {
    if (!this.state.isRunning) return false;
    return this.setState((prevState) => {
      const style = Object.assign({}, prevState.style);
      style.left = `${prevState.position + 1}px`;
      return {
        style,
        position: prevState.position + 1
      };
    });
  }

  slideLoop() {
    this.interval = setInterval(this.slide, this.props.speed);
  }

  slide() {
    if (this.state.sliderSize !== 0 && this.state.isRunning) {
      if (this.state.direction === "left") {
        if (this.props.width <= this.state.sliderSize + this.state.position) {
          this.moveToLeft();
        } else {
          this.setState(
            {
              direction: "right",
              isRunning: false
            },
            () => {
              this.timeout = setTimeout(
                () => this.setState({ isRunning: true }),
                1800
              );
            }
          );
        }
      } else if (this.state.position <= 0) {
        this.moveToRight();
      } else {
        this.setState(
          {
            direction: "left",
            isRunning: false
          },
          () => {
            this.timeout = setTimeout(
              () => this.setState({ isRunning: true }),
              1800
            );
          }
        );
      }
    }
  }

  render() {
    if (typeof this.props.s !== "string" || this.state.neverRunning)
      return this.props.s;
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{
          width: this.props.width,
          overflow: "hidden",
          height: this.props.height,
          justifyContent: "center"
        }}
      >
        <div ref={this.attachRef} style={this.state.style}>
          {this.props.s}
        </div>
      </div>
    );
  }
}

Stringslider.propTypes = {
  s: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  speed: PropTypes.number
};

Stringslider.defaultProps = {
  s: "",
  width: 200,
  height: 25,
  speed: 50
};

export default Stringslider;
