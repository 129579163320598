export const listPlatformArchivedProjects = {
  query: `query Q ($platformUri:String!, $options: QueryOptions) {
        listPlatformArchivedProjects(platformUri:$platformUri, options: $options) {
            results {
                uri
                name
                region
                description
                group_name
                groupuri
                creatoruri
                database
                role_name
                cart_archivedat
                datasets {
                 uri
                }
            }
            total
            limit
            offset
        }
      }
    `,
  keyOutput: "listPlatformArchivedProjects"
};
