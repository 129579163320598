export const listPlatforms = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        listAccountPlatforms (accountUri: $accountUri, options: $options) {
            total
            limit
            offset
            results {
                name
                aws
                uri
                description
                auto_refresh_stages
            }
        }
    }
    `,
  keyOutput: "listAccountPlatforms"
};
