import React from "react";
import "./style.less";

const NavbarTitle = () => (
  <div className={"title-navbar"}>
    <div className={"title-navbar-project"}>
      <div className={"title-navbar-project-name"}>
        common<b>DataHub</b>
      </div>
    </div>
  </div>
);

export default NavbarTitle;
