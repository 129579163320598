/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* @flow */

import React from "react";
import classnames from "classnames";
import { formatDate } from "../../../../utils/date";
import Links from "../../../links";
import TextField from "../../../../components/textField";

type propTypes = {
  datasource: Object,
  rsdatasource: Object
};

class RSDatasourceDetails extends React.PureComponent<propTypes> {
  render() {
    const { datasource, rsdatasource } = this.props;

    return (
      <div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fas fa-link dataset-detail-icon"></i>
            URI
          </div>
          <TextField text={`${datasource.uri}`} />
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-inbox datasource-detail-icon" />
            Database
          </div>
          <TextField text={`${rsdatasource.db_name}`} />
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">Owner Group</div>
          <span className="datasource-info-detail">
            <Links.Group.View uriGroup={(datasource.owner || {}).uri || "null"}>
              {(datasource.owner || {}).name || "-"}
            </Links.Group.View>
          </span>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            Sharing Management Group
          </div>
          <span className="datasource-info-detail">
            <Links.Group.View
              uriGroup={(datasource.sharingManagementGroup || {}).uri || "null"}
            >
              {(datasource.sharingManagementGroup || {}).name || "-"}
            </Links.Group.View>
          </span>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-database datasource-detail-icon" />
            Redshift Serverless
          </div>
          <div className="datasource-detail-double-element">
            <div className="datasource-detail-element-left">
              <div className="datasource-detail-title">Namespace</div>
              <span>{rsdatasource.namespace_name}</span>
            </div>
            <div className="datasource-detail-element-right">
              <div className="datasource-detail-title">Workgroup</div>
              <span>{rsdatasource.workgroup_name}</span>
            </div>
          </div>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fas fa-table mr-1" />
            CloudFormation
          </div>
          <div className="datasource-detail-double-element">
            <div className="datasource-detail-element-left">
              <div className="datasource-detail-title">Stack</div>
              <a
                href={`https://${datasource.region}.console.aws.amazon.com/cloudformation/home?region=${datasource.region}#/stacks/stackinfo?stackId=${rsdatasource.stack_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{rsdatasource.stack_name}</span>
              </a>
            </div>
            <div className="datasource-detail-element-right">
              <div className="datasource-detail-title">Status</div>
              <span
                style={{ fontSize: "11px", fontWeight: 500 }}
                className={classnames("tag-metrics", {
                  "green-status": [
                    "CREATE_COMPLETE",
                    "UPDATE_COMPLETE"
                  ].includes(rsdatasource.stack_status),
                  "warning-status":
                    rsdatasource.stack_status === "CREATE_IN_PROGRESS",
                  "error-status": [
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETE",
                    "NOT_FOUND",
                    "ROLLBACK_COMPLETE",
                    "ROLLBACK_FAILED"
                  ].includes(rsdatasource.stack_status)
                })}
              >
                {rsdatasource.stack_status}
              </span>
            </div>
          </div>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">
            <i className="fa fa-lock datasource-detail-icon" />
            Security
          </div>
          <div className="datasource-detail-double-element">
            <div className="datasource-detail-element-left">
              <div className="datasource-detail-title">IAM Role</div>
              <span>{rsdatasource.datasource_iam_role_name}</span>
            </div>
            <div className="datasource-detail-element-right">
              <div className="datasource-detail-title">Database Role</div>
              <span>{rsdatasource.db_write_role}</span>
            </div>
          </div>
        </div>
        <div className="datasource-detail-double-element">
          <div className="datasource-detail-element-left">
            <div className="datasource-detail-title">
              <i className="fab fa-aws datasource-detail-icon" />
              AWS Account
            </div>
            <span>{datasource.aws}</span>
          </div>
          <div className="datasource-detail-element-right">
            <div className="datasource-detail-title">
              <i className="fas fa-globe-europe datasource-detail-icon" />
              AWS Region
            </div>
            <span>{datasource.region}</span>
          </div>
        </div>

        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fas fa-calendar-day cart-detail-icon" />
            Created on
          </div>
          <span>{formatDate(datasource.createdat)}</span>
        </div>
        <div className="datasource-detail-element">
          <div className="datasource-detail-title">Description</div>
          <p>{datasource.description || "No description"}</p>
        </div>
      </div>
    );
  }
}

export default RSDatasourceDetails;
