import PropTypes from "prop-types";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";

const DeleteObjectWithFrictionModal = (props) => {
  const {
    objectName,
    deleteMessage,
    onApply,
    onClose,
    open,
    deleteFunction,
    isAWSResource = true,
    ...other
  } = props;
  const [confirmValue, setConfirmValue] = useState(null);
  const [deleteFromAWS, setDeleteFromAWS] = useState(false);
  const handleChange = (event) => {
    setConfirmValue(event.target.value);
  };
  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} open={open} {...other}>
      <DialogTitle id="alert-dialog-title">
        <Box>
          <Typography color="textSecondary" variant="h5" align={"center"}>
            Delete {objectName} ?
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isAWSResource && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <FormGroup>
              <FormControlLabel
                color="primary"
                control={
                  <Switch
                    color="primary"
                    onChange={() => {
                      setDeleteFromAWS(!deleteFromAWS);
                    }}
                    edge="start"
                    name="deleteFromAWS"
                  />
                }
                label={
                  <Typography color="textPrimary" variant="body1">
                    Delete the associated AWS resources ?
                  </Typography>
                }
                labelPlacement="end"
                value={deleteFromAWS}
              />
            </FormGroup>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          {deleteMessage && (
            <Box sx={{ mb: 2 }}>
              <Typography
                align="center"
                variant="subtitle1"
                color="textSecondary"
              >
                {deleteMessage}
              </Typography>
            </Box>
          )}
          <Typography align="center" variant="subtitle2" color="textSecondary">
            To confirm deletion, type{" "}
            <i>
              <b>permanently delete</b>
            </i>{" "}
            in the text input field.
          </Typography>
          <CardContent>
            <TextField
              fullWidth
              label="permanently delete"
              name="confirm"
              onChange={handleChange}
              value={confirmValue}
              variant="outlined"
            />
          </CardContent>
          <CardContent>
            <button
              type={"button"}
              className="butn butn-delete"
              disabled={confirmValue !== "permanently delete"}
              onClick={() => {
                deleteFunction(deleteFromAWS);
              }}
              style={{ margin: "5px", width: "100%" }}
            >
              <i className={"fa fa-trash"} />
              <span>{" Delete"}</span>
            </button>
          </CardContent>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DeleteObjectWithFrictionModal.propTypes = {
  objectName: PropTypes.string,
  deleteMessage: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  deleteFunction: PropTypes.func,
  open: PropTypes.bool,
  isAWSResource: PropTypes.bool
};

export default DeleteObjectWithFrictionModal;
