/* @flow */
import React from "react";
import "./view.less";

type propTypes = {
  provider: {
    id: string,
    arn: string,
    userid: string,
    description: string,
    status: string
  },
  onEdit: Function,
  onDelete: Function
};

type stateTypes = {
  error?: Object
};

class DatasourceProviderItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  getStatusClass = (status: string) => {
    switch (status) {
      case "AVAILABLE":
        return "status-green";
      case "UNAVAILABLE":
        return "status-red";
      default:
        return "status-blue";
    }
  };

  printRefreshRoleStatus = (status: string) => {
    if (status !== "AVAILABLE" && status !== "UNAVAILABLE") {
      return "UNKNOWN";
    }
    return status;
  };

  render() {
    return (
      <div className={"loader-card card-shadow card bg-white"}>
        <div className="card-loader-header">
          <div className="card-loader-begin">
            <div className="card-loader-begin-content">
              <i className="fas fa-ring"></i>
              Loader
            </div>
          </div>
          <div className="card-dataset-end"></div>
        </div>
        <div className="card-loader-body">
          <div className="card-loader-body-left">
            <div className="card-loader-details">
              <div>
                <i className="fab fa-aws"></i>
                <span className="content-card-bucket">
                  {this.props.provider.arn}
                </span>
              </div>
              <div>
                <i className="fas fa-address-card"></i>
                <span className="content-card-region">
                  {this.props.provider.userid}
                </span>
              </div>
              <div className="card-loader-desc">
                {this.props.provider.description &&
                this.props.provider.description.length > 50
                  ? `${this.props.provider.description.substring(0, 50)}...`
                  : this.props.provider.description}
              </div>
            </div>
          </div>
          <div className="card-dataset-body-right">
            <div className="card-dataset-body-actions">
              <div className="btn-cart" onClick={this.props.onEdit}>
                <i className="fas fa-edit fa-spacing"></i>
                <span className="text-actions">Edit</span>
              </div>
              <div className="btn-cart" onClick={this.props.onDelete}>
                <i className="fas fa-trash fa-spacing"></i>
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-loader-footer">
          <div className="availability-loader-container">
            <span className="availability-loader-title">LOADER STATUS</span>
            <span className="loader-status">
              <span className={this.getStatusClass(this.props.provider.status)}>
                {this.printRefreshRoleStatus(this.props.provider.status)}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default DatasourceProviderItem;
