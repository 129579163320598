export const getCollibraEnvironments = {
  query: `
  query Q {
    getCollibraEnvironments {
      url
      env
    }
  }
`,
  keyOutput: "getCollibraEnvironments"
};
