/* @flow */
import React from "react";
import Tabs from "../../../../components/tabs";
import MyProfileInformation from "../MyProfileInformation";
import NotificationSettings from "../NotificationSettings";
import routes from "../../../routes";

type propTypes = {};

type stateTypes = {};

class MyProfile extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  getTabs = () => {
    const tabs = [
      {
        name: "General",
        index: "info-general",
        route: `${routes.User.MyProfile}#info-general`,
        content: <MyProfileInformation />
      },
      {
        name: "Notifications",
        index: "info-notifications",
        route: `${routes.User.MyProfile}#info-notifications`,
        content: <NotificationSettings />
      }
    ];

    return tabs;
  };

  render() {
    return (
      <div className={"myprofile-blocs"}>
        <Tabs
          isPreload={false}
          defaultActiveIndex={"info-general"}
          tabs={this.getTabs()}
        />
      </div>
    );
  }
}

export default MyProfile;
