export const getEnvironmentRedshiftClusters = {
  query: `query Q ($accountUri: String!, $platformUri: String!) {
    getPlatform(accountUri: $accountUri, platformUri: $platformUri) {
            name
            aws
            environment
            redshiftClusters{
              uri
              cluster_arn
              aws
              cluster_name
              description
              resource_name
              database_name
              createdat
              region
              updatedat
              status
              subnet_group_name
            }
        }
    }
    `,
  keyOutput: "getPlatform"
};
