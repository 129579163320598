/* eslint-disable no-return-assign */
/* @flow */

import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";
import "./style.less";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import Paginator from "../../../../components/paginator";
import ProjectArchiveCard from "../../../archives/project/card";

type propTypes = {
  uriPlayground: string,
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  archivedProjects: Array<Object>,
  error: ?Object,
  isFetchingArchivedProjects: boolean,
  total: number,
  limit: number,
  keywords?: string
};

class DatasourceArchives extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      archivedProjects: [],
      total: 0,
      limit: 10,
      keywords: "",
      isFetchingArchivedProjects: false
    };
  }

  componentDidMount() {
    this.listPlatformArchivedProjects();
  }

  listPlatformArchivedProjects = (offset = 0) => {
    this.setState({ isFetchingArchivedProjects: true });
    const options = { offset, search: {} };
    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }
    return this.props.api.playground
      .listPlatformArchivedProjects(this.props.uriPlayground, options)
      .then((response) => {
        this.setState({
          ready: true,
          isFetchingArchivedProjects: false,
          archivedProjects: response.results,
          total: response.total,
          limit: response.limit
        });
        return response.results;
      })
      .catch((error) => {
        this.setState({ error, isFetchingArchivedProjects: false });
        return [];
      });
  };

  onChangeInput = (e: Object) => {
    const keywords = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          keywords
        },
        this.listPlatformArchivedProjects
      );
    }, 500);
  };

  render() {
    if (!this.state.ready) return <Loading message={"Archived Projects"} />;
    return (
      <div>
        {this.state.error && (
          <Error
            stringOnly
            error={this.state.error}
            path={"ArchivedProjects"}
          />
        )}
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search Project"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
        </div>
        <div className="card-container">
          <Paginator
            isLoadingPage={this.state.isFetchingArchivedProjects}
            limit={this.state.limit}
            initialOffset={0}
            totalCount={this.state.total}
            list={this.state.archivedProjects}
            componentRender={(project) => (
              <ProjectArchiveCard key={project.uri} project={project} />
            )}
            loadPage={this.listPlatformArchivedProjects}
            onNoResult={() => (
              <div className="total-search-results">No projects found</div>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DatasourceArchives));
