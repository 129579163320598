/* @flow */

import React from "react";
import "./style.less";
import Loading from "../../../components/loading";
import Row from "../../../components/row";
import Col from "../../../components/col";
import withAppSync from "../../AppsyncHOC";
import routes from "../../routes";
import Error from "../../../components/error";
import { getDatasetNameFromLocation } from "../../../utils/getValueFromLocation";
import Breadcrumb from "../../../components/breadcrumb";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import TermsConditionsModal from "../../../components/termsConditionsModal";
import ButtonAction from "../../../components/buttonAction";
import ListPendingShares from "./components/listPendingShares";
import SelectClassic from "../../../components/SelectClassic";
import SelectInfinite from "../../../components/SelectInfinite";

const Log = Logger("RequestForm");

type propTypes = {
  goTo: Function,
  api: GraphQl,
  match: {
    params: {
      uriDataset: string
    }
  },
  location: {
    state: {
      dataset: {
        name: string
      },
      groupUri: string
    }
  }
};

type stateTypes = {
  accounts: Array<Object>,
  totalGroups: number,
  dataset: Object,
  message: string,
  groupUri?: string,
  accountUri?: string,
  error: Object,
  errorSubmit: Object,
  complianceModal: boolean,
  isFetchingSharingGroup: boolean,
  isFetchingGroup: boolean,
  isFetchingAccount: boolean,
  groups: Array<Object>,
  orgaSelected: Object,
  groupSelected: Object
};

class RequestForm extends React.Component<propTypes, stateTypes> {
  termsCheckboxRef;

  constructor(props) {
    super(props);
    this.termsCheckboxRef = React.createRef();
    this.state = {
      accounts: [],
      message: "",
      dataset: {
        owner: {
          name: "",
          account: {
            name: ""
          }
        }
      },
      error: undefined,
      errorSubmit: undefined,
      complianceModal: false,
      isFetchingAccount: false,
      isFetchingSharingGroup: true,
      groups: [],
      totalGroups: 0,
      orgaSelected: null,
      groupSelected: undefined,
      groupUri: undefined,
      isFetchingGroup: false
    };
  }

  componentDidMount() {
    this.getSharingManagementGroup();
    this.getMyOrgas();
    if (this.props.location.state && this.props.location.state.groupUri) {
      this.getThisGroup();
    }
  }

  openComplianceModal = () => {
    this.setState({
      complianceModal: true
    });
  };

  closeComplianceModal = () => {
    this.setState({
      complianceModal: false
    });
  };

  getMyOrgas = () => {
    this.setState({ isFetchingAccount: true });
    return this.props.api.account
      .getAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          isFetchingAccount: false
        });
      })
      .catch((error) => {
        Log.error(error);
        this.setState({
          isFetchingAccount: false,
          error
        });
      });
  };

  getThisGroup = () =>
    this.props.api.group
      .get(this.props.location.state.groupUri)
      .then((group) =>
        this.setState({
          groupUri: group.uri,
          accountUri: group.account.uri
        })
      )
      .catch((error) => {
        Log.error(error);
        this.setState({
          error
        });
      });

  // joinWithoutDupes = (A, B) => {
  //     const a = new Set(A.map((x: any) => x.item));
  //     const b = new Set(B.map((x: any) => x.item));
  //     return [...A.filter((x) => !b.has(x.item)), ...B.filter((x: any) => !a.has(x.item))];
  // }

  // Don't know why it was really used in any case so I commented it
  // joinWithoutDupes = (A, B, key) => [].concat(A).concat(B).filter((v, i, a) => a.findIndex((v2) => (v2[key] === v[key])) === i)

  fetchGroups = (search, offset) => {
    if (search && search.length < 3) {
      return false;
    }
    const options = {
      filters: [
        {
          key: "parent",
          value: this.state.orgaSelected.value
        }
      ],
      offset,
      search: undefined
    };

    if (search) {
      options.search = { name: search };
    }

    return this.props.api.identity
      .listMyGroupsWithoutShare(this.props.match.params.uriDataset, options)
      .then((groupsList) =>
        groupsList.results.map((o) => ({ label: o.name, value: o.uri }))
      )
      .catch((error) => {
        Log.error(error);
        return [];
      });
  };

  loadMoreGroups = (offset, search) => this.fetchGroups(search, offset);

  getSharingManagementGroup = () =>
    this.props.api.dataset
      .getSharingManagementGroup(this.props.match.params.uriDataset)
      .then((dataset) => {
        this.setState({
          dataset,
          isFetchingSharingGroup: false
        });
      })
      .catch(() => {
        this.setState({ isFetchingSharingGroup: false });
      });

  save = () => {
    if (
      this.termsCheckboxRef &&
      this.termsCheckboxRef.current &&
      !this.termsCheckboxRef.current.checked
    ) {
      return this.setState({
        errorSubmit: { message: "You must accept the terms & conditions" }
      });
    }

    if (!this.state.groupUri) {
      return this.setState({
        errorSubmit: { message: "Pick a group to request access." }
      });
    }

    this.setState({
      errorSubmit: false
    });
    return this.props.api.share
      .createRequest(
        this.props.match.params.uriDataset,
        this.state.groupUri || "",
        this.state.message
      )
      .then(
        this.props.goTo({
          route: routes.User.ShareWithMe
        })
      )
      .catch((error) => {
        this.setState({
          errorSubmit: error
        });
        Log.error(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Breadcrumb
            view={`Request Access for Dataset ${getDatasetNameFromLocation({
              location: this.props.location,
              match: this.props.match
            })}.`}
          />
        </div>

        {this.state.error && (
          <Error error={this.state.error} path={"RequestForm"} stringOnly />
        )}

        {this.state.isFetchingAccount && <Loading message={"Groups data"} />}

        {!this.state.error &&
          !this.state.isFetchingAccount &&
          !this.state.isFetchingSharingGroup &&
          !this.state.isFetchingGroup && (
            <div>
              <div className="mt-4 justify-content-center col-10 ml-6">
                <div>
                  <h5>The request will be sent to</h5>
                  <div className="mt-4 form-group row">
                    <label className="col-sm-5 col-form-label label-form">
                      Sharing Management Group :
                    </label>
                    <div className="col-sm-7">
                      <input
                        disabled
                        type="text"
                        name="group"
                        className="form-control form-control-sm"
                        value={
                          this.state.dataset.datasource.sharingManagementGroup
                            .name
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-4 form-group row">
                    <label className="col-sm-5 col-form-label label-form">
                      in Organization :{" "}
                    </label>
                    <div className="col-sm-7">
                      <input
                        disabled
                        type="text"
                        name="account"
                        className="form-control form-control-sm"
                        value={this.state.dataset.owner.account.name}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h5>The request will be done on behalf</h5>
                  <div className="mt-4 form-group row">
                    <label className="col-sm-5 col-form-label label-form">
                      of Organization :{" "}
                    </label>
                    <div className="col-sm-7">
                      <SelectClassic
                        placeholder="Select an organization"
                        options={this.state.accounts.map((a) => {
                          if (a.uri === "uri:account:000000") {
                            return {
                              value: a.uri,
                              label: "All Engie"
                            };
                          }
                          return {
                            value: a.uri,
                            label: a.name
                          };
                        })}
                        selectedOption={this.state.orgaSelected || null}
                        onSelectOption={(orgaSelected) => {
                          this.setState(
                            {
                              orgaSelected,
                              accountUri: orgaSelected.value,
                              groupUri: undefined
                            },
                            this.fetchGroups
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-4 form-group row">
                    <label className="col-sm-5 col-form-label label-form">
                      and Group :{" "}
                    </label>
                    <div className="col-sm-7">
                      <SelectInfinite
                        key={JSON.stringify(this.state.orgaSelected)}
                        placeholder={
                          this.state.accountUri
                            ? "Select a group"
                            : "Select an organization first"
                        }
                        totalOptions={this.state.totalGroups}
                        initialOptions={this.state.groups}
                        loadMoreOptions={this.loadMoreGroups}
                        isSearchable
                        isClearable
                        isDisabled={!this.state.orgaSelected}
                        selectedOption={this.state.groupSelected}
                        onSelectOption={(groupSelectedValue) => {
                          this.setState(
                            {
                              // groupSelected: this.state.groups.find(i => i.value === groupSelectedValue),
                              groupUri: groupSelectedValue
                            },
                            this.fetchGroups
                          );
                        }}
                        cacheUniqs={[this.state.groups]}
                      />
                    </div>
                  </div>

                  <div>
                    <h5>Type in a message to motivate your request</h5>
                    <textarea
                      rows="7"
                      className="col-12"
                      name="message"
                      value={this.state.message}
                      onChange={(e) =>
                        this.setState({ message: e.target.value })
                      }
                    />
                  </div>

                  <div className="mt-2 justify-content-center">
                    <input
                      id="termsconditions"
                      name="termsconditions"
                      type="checkbox"
                      className={"checkbox-facet"}
                      ref={this.termsCheckboxRef}
                    />
                    <label className="fas" htmlFor="termsconditions">
                      <span className="labelTermsCustom">
                        I acknowledge having read and accept the general{" "}
                        <span
                          className="terms-highlight"
                          onClick={this.openComplianceModal}
                        >
                          terms & conditions
                        </span>{" "}
                        of access and use of the service
                      </span>
                    </label>
                  </div>
                </div>

                <Row className="justify-content-center mt-2">
                  {this.state.errorSubmit && (
                    <Col
                      size={12}
                      style={{
                        color: "red",
                        paddingBottom: "10px"
                      }}
                    >
                      {this.state.errorSubmit.message}
                    </Col>
                  )}
                </Row>
                <Row className="justify-content-center mt-4">
                  <ButtonAction onClick={this.save} label="Request Access" />
                </Row>
              </div>
              <div className="list-shares justify-content-center col-10 ml-6">
                <ListPendingShares
                  uriDataset={this.props.match.params.uriDataset}
                />
              </div>
            </div>
          )}
        {this.state.complianceModal && (
          <TermsConditionsModal onClose={this.closeComplianceModal} />
        )}
      </React.Fragment>
    );
  }
}

export default withAppSync(RequestForm);
