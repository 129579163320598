export const listLakeFormationMigrations = {
  query: `query Q ($platformUri : String!) {
        listLakeFormationMigrations(platformUri : $platformUri) {
          id
          region
          type
          status
          stepfunctions
          platform_uri
        }
      }
    `,
  keyOutput: "listLakeFormationMigrations"
};
