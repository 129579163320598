/* @flow */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withAppSync from "../AppsyncHOC";
import Links from "../links";
import Logger from "../../utils/logger";
import GraphQl from "../../graphQL";
import "./notificationBadge.less";
import { convertNumber } from "../../utils/size";

const Log = Logger("NewNotificationBadge");

type propTypes = {
  api: GraphQl,
  className: string,
  action: Object
};

type stateTypes = {
  badge: number,
  time: ?number,
  class: string
};

class NewtificationBadge extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      badge: 0,
      time: null,
      class: ""
    };
  }

  componentDidMount() {
    this.getCountUnreadNotifications();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.action.message !== prevProps.action.message ||
      this.props.action.date !== prevProps.action.date
    ) {
      this.getCountUnreadNotifications();
    } else if (
      this.state.time &&
      this.state.time + 30000 < new Date().getTime()
    ) {
      // only update after 30s...
      this.getCountUnreadNotifications();
    }
  }

  getCountUnreadNotifications = () => {
    this.setState({
      time: null
    });
    return this.props.api.newtification
      .countUnreadNotifications()
      .then((response) => {
        Log.info("badge", response);
        if (response > 99) {
          this.setState({ class: "big" });
        }

        this.setState({
          badge: response,
          time: new Date().getTime()
        });
      })
      .catch(() => {});
  };

  render() {
    return (
      <div className="notification-container">
        {this.state.badge > 0 && (
          <Links.User.Notifications
            className={`${this.props.className} ${this.state.class}`}
          >
            {convertNumber(this.state.badge, true)}
          </Links.User.Notifications>
        )}
      </div>
    );
  }

  // goToNotificationsPage(e) {
  //     e.preventDefault()
  //     this.props.history.push('/myprofile#newtifications')
  // }

  // render() {
  //     return (
  //         <div className="notification-container">
  //             {!!this.state.badge && (
  //                 <div
  //                     className={`${this.props.classname} ${this.state.class}`}
  //                     onClick={(e) => this.goToNotificationsPage(e)}
  //                 >
  //                     { convertNumber(this.state.badge, true) }
  //                 </div>
  //             )}
  //         </div>
  //     );
  // }
}

const mapStateToProps = (state) => {
  const { notifications = {} } = state;
  return {
    action: {
      message: notifications.message,
      type: notifications.type,
      date: notifications.date
    }
  };
};

export default connect(mapStateToProps)(
  withAppSync(withRouter(NewtificationBadge))
);
