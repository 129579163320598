export const getDatabricksAccount = {
  query: `query Q ($uuid: String!) {
        getDatabricksAccount(uuid: $uuid) {
          dbx_account_name
          dbx_account_id
          dbxWorkspaces {
          results {
            dbx_workspace_name
            uuid
            dbx_workspace_id
          }
          offset
          limit
          total
        }
        }
      }
    `,
  keyOutput: "getDatabricksAccount"
};
