/* @flow */

export const listCartTrusts = {
  query: `query Q ($cartUri: String!) {
        listCartTrusts(cartUri:$cartUri) {
          arn
          description
          policy_condition
          status
          type
          is_admin
          id
          platform {
            name
            aws
            uri
          }
        }
      }
    `,
  keyOutput: "listCartTrusts"
};
