/* @flow */
import { refreshSQLToken } from "./mutationRefreshSQLToken";
import { getRecentUserHistory } from "./queryGetRecenthistory";
import { getDataConsumptionDashboard } from "./queryGetDataConsumptionDashboard";
import { listMyEmailNotificationSubscriptionConfig } from "./queryGetNotificationSettings";
import { updateMyEmailNotificationSubscriptionConfig } from "./mutationUpdateNotificationSubscription";

class UserApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  refreshSQLToken() {
    return this.fetchAppSync(refreshSQLToken, {});
  }

  getRecentUserHistory(options: Object = {}) {
    return this.fetchAppSync(getRecentUserHistory, { options });
  }

  getDataConsumptionDashboard() {
    return this.fetchAppSync(getDataConsumptionDashboard, {});
  }

  getNotificationSubscriptions() {
    return this.fetchAppSync(listMyEmailNotificationSubscriptionConfig, {});
  }

  updateNotificationSubscription(config: string) {
    return this.fetchAppSync(updateMyEmailNotificationSubscriptionConfig, {
      config
    });
  }
}

export default UserApi;
