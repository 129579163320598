/* @flow */
import React from "react";
import { connect } from "react-redux";
import ButtonAction from "../../../../components/buttonAction";
import Modal from "../../../../components/modal";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";

const Log = Logger("DeployAccelerationTemplateModal");

type propTypes = {
  api: GraphQl,
  onDelete: Function,
  onClose: Function,
  showGlobalNotification: Function,
  cart: Object,
  accelerationTemplate: Object
};

type stateTypes = {
  error?: Object,
  isDeleting: boolean
};

class DeleteAccelerationTemplateModal extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isDeleting: false
    };
  }

  delete = () => {
    this.setState({ error: undefined, isDeleting: true });
    this.props.api.cart
      .deleteAccelerationTemplateFromProject(
        this.props.accelerationTemplate.template_projects[0].id
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Template/stack deleted !",
          type: "success"
        });
        this.setState({
          isDeleting: false
        });
        this.props.onDelete();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error, isDeleting: false });
      });
  };

  render() {
    return (
      <Modal
        title={`Delete the template/stack: ${this.props.accelerationTemplate.acceleration_template.name}`}
        body={"Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          <button
            key="close-modal"
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>,
          <ButtonAction
            key="deploy-template"
            label="Delete"
            className="butn butn-flat"
            onClick={this.delete}
            icon={
              this.state.isDeleting ? "fas fa-circle-notch fa-spin" : undefined
            }
          />
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DeleteAccelerationTemplateModal));
