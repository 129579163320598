/* @flow */

import React from "react";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import withGoTo from "../goToHOC";
import withAppSync from "../AppsyncHOC";
import { updateMessage } from "../globalNotifications/actions";
import "./style.less";
import GraphQl from "../../graphQL";
import Error from "../../components/error";
import Row from "../../components/row";
import Col from "../../components/col";
import Links from "../links";
import { getAccountUriFromUri } from "../../utils/toolsForUri";

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      cartUri: string
    }
  },
  datasource: Object,
  rsDatasource: Object,
  showGlobalNotification: Function
};

type stateTypes = {
  isOpeningSqlWorkbench: boolean,
  isOpeningProjectSqlWorkbench: boolean,
  error: Object
};

class RSDatasourceSqlLab extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      isOpeningSqlWorkbench: false,
      isOpeningProjectSqlWorkbench: false,
      error: undefined
    };
  }
  openProjectSqlWorkbench = () => {
    this.setState({ isOpeningProjectSqlWorkbench: true });
    this.props.api.datasource
      .openRSDatasourceSqlWorkbench(
        this.props.datasource.uri,
        this.props.rsDatasource.project.cart_uri
      )
      .then((url) => {
        this.setState({ isOpeningSqlWorkbench: false });
        this.props.showGlobalNotification({
          message:
            "Redirecting to Redshift Serverless SQLWorkbench with project IAM Role...",
          type: "success"
        });
        window.open(url, "_blank");
      })
      .catch((error) => {
        this.setState({ isOpeningProjectSqlWorkbench: false });
        this.props.showGlobalNotification({
          message: error.message,
          type: "alert"
        });
      });
  };

  openSqlWorkbench = () => {
    this.setState({ isOpeningSqlWorkbench: true });
    this.props.api.datasource
      .openRSDatasourceSqlWorkbench(this.props.datasource.uri)
      .then((url) => {
        this.setState({ isOpeningSqlWorkbench: false });
        this.props.showGlobalNotification({
          message: "Redirecting to Redshift Serverless SQLWorkbench...",
          type: "success"
        });
        window.open(url, "_blank");
      })
      .catch((error) => {
        this.setState({ isOpeningSqlWorkbench: false });
        this.props.showGlobalNotification({
          message: error.message,
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div className={"environments-container"}>
        <div>
          <div className={"mb-2"}>
            {!this.props.rsDatasource ||
              (this.props.rsDatasource.project &&
                !this.props.rsDatasource.project.cart_uri && (
                  <Alert severity={"info"}>
                    Amazon Redshift Query Editor v2.0 is a web-based analyst
                    workbench for you to explore, share, and collaborate on data
                    with your teams in SQL through a common interface. Click the
                    button below to start using it.
                  </Alert>
                ))}
            {this.props.rsDatasource &&
              this.props.rsDatasource.project &&
              this.props.rsDatasource.project.cart_uri && (
                <Alert severity={"info"}>
                  <p>
                    This datasource was created from the project{" "}
                    <b>
                      <Links.Cart.View
                        cartUri={this.props.rsDatasource.project.cart_uri}
                      >
                        {this.props.rsDatasource.project.cart_name}
                      </Links.Cart.View>
                    </b>
                    . Access to the Redshift SQL Workbench is available through
                    the project role, enabling access to the project's S3 data
                    via Redshift Spectrum.
                    <br />
                    The project's Glue database{" "}
                    <b>{this.props.rsDatasource.project.cart_database}</b> is
                    available as an external schema within the datasource
                    database <b>{this.props.rsDatasource.db_name}</b>.
                  </p>
                </Alert>
              )}
          </div>
          <div>
            {this.props.rsDatasource &&
              this.props.rsDatasource.project &&
              this.props.rsDatasource.project.cart_uri && (
                <div
                  className="butn butn-icon mr-3"
                  onClick={this.openProjectSqlWorkbench}
                >
                  {this.state.isOpeningProjectSqlWorkbench && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-external-link-alt butn-icon" />
                  <span className="butn-text">Project SQLWorkbench Access</span>
                </div>
              )}
            <div className="butn butn-flat" onClick={this.openSqlWorkbench}>
              {this.state.isOpeningSqlWorkbench && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              <i className="fas fa-external-link-alt butn-icon" />
              <span className="butn-text">Datasource SQLWorkbench Access </span>
            </div>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error
              error={this.state.error}
              path={"RSDatasourceSqlLab"}
              stringOnly
            />
          </div>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(RSDatasourceSqlLab)));
