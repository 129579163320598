import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import loggerRedux from "redux-logger";
import allReducers from "./allReducers";
import "bootswatch/dist/yeti/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import "handsontable/dist/handsontable.css";
import "./style.less";
import AppRoutes from "./containers/approutes";
import Logger from "./utils/logger";

const Log = Logger("APP");
Log.verbose("Project start !");

const store = createStore(allReducers, applyMiddleware(loggerRedux));

document.title = `${
  process.env.NODE_ENV !== "production" ? process.env.NODE_ENV : ""
} commonDataHub`;

ReactDOM.render(
  <Provider store={store}>
    <AppRoutes />
  </Provider>,
  document.getElementById("app") // eslint-disable-line no-undef
);
