/* @flow */

import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import withErrorBoundary from "../../../../../errorBoundaryHOC";
import withGoTo from "../../../../../goToHOC";
import Logger from "../../../../../../utils/logger";
import "./style.less";

const Log = Logger("FilterTrust");

type propTypes = {
  onChangeTerm: Function,
  runSearch: Function,
  onClear: Function,
  term: string,
  isLoading: boolean,
  suggestions: Array<Object | string>,
  placeholder: string
};

type stateTypes = {
  search: Object,
  state: boolean,
  toUpdate: boolean
};

class FilterTrust extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    onChangeTerm: () => {
      Log.warning("onChangeTerm not defined !");
    },
    runSearch: () => {
      Log.warning("runSearch not defined !");
    }
  };

  timeout: TimeoutID;

  onChangeTerm = (e, v) => {
    const search = typeof v === "string" ? v : v && v.uri;
    this.props.onChangeTerm(search);

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.runSearch, 500);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return (
      <div className="search-input-trust">
        <Autocomplete
          options={this.props.suggestions}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return option.name;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder={this.props.placeholder}
              variant="outlined"
              fullWidth
            />
          )}
          onChange={this.onChangeTerm}
        />
      </div>
    );
  }
}

export default withErrorBoundary(withGoTo(FilterTrust));
