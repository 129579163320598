/* @flow */
import React from "react";
import { Alert } from "@material-ui/lab";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import withAppSync from "../../AppsyncHOC";
import Breadcrumb from "../../../components/breadcrumb";
import Tabs from "../../../components/tabs";
import "./style.less";
import StageInfo from "../components/stageInfo";
import DatasetPreview from "../../datasets/preview";
import GraphQl from "../../../graphQL";
import StageMetadata from "../../datasets/components/profiling/stageMetadata";
import ProfilingExecutionHistory from "../components/profilingExecutionHistory";
import ProfilingAction from "../components/profilingAction";
import ToggleBasicMetadataDisplay from "../../datasets/components/toggleBasicMetadataDisplay";
import routes from "../../routes";
import DataFiltersCard from "../../datafilters/DataFiltersCard";
import { isS3SourceType } from "../../../utils/constants";

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    params: {
      datasetUri: string,
      outputId: string
    },
    url: string
  },
  location: {
    state: Object,
    hash: Object
  }
};

type stateTypes = {
  ready: boolean,
  error: Object,
  stage: Object,
  showDataFilters: boolean
};

class StageView extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      ready: false,
      stage: {
        createdat: "",
        name: "",
        table: "",
        id: "",
        profiling_nb_rows: null,
        profiling_execution: {},
        dataset: {
          uri: "",
          name: "",
          access: "other"
        },
        columns: []
      },
      error: undefined,
      showDataFilters: false
    };
  }

  componentDidMount() {
    this.isDataFiltersFeatureEnabled(this.props.match.params.datasetUri);
    this.getStage();
  }

  getStage = () =>
    this.props.api.dataset
      .getStage(
        this.props.match.params.outputId,
        this.props.match.params.datasetUri
      )
      .then((stage) => {
        this.setState({
          stage,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });

  isDataFiltersFeatureEnabled(datasetUri) {
    const accountUri = `uri:account:${datasetUri.split(":")[2]}`;
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATA_FILTERS", accountUri)
      .then((showDataFilters) => {
        this.setState({ showDataFilters });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getTabs = () => {
    const tabs = [];
    tabs.push(
      {
        name: "Information",
        index: "information",
        route: `${this.props.match.url}#information`,
        content: (
          <div id="nav-information">
            <StageInfo
              stage={this.state.stage}
              error={this.state.error}
              ready={this.state.ready}
            />
          </div>
        )
      },
      {
        name: "Preview",
        index: "preview",
        route: `${this.props.match.url}#preview`,
        content: (
          <div id="nav-preview">
            <DatasetPreview
              dataset={this.state.stage.dataset}
              stage={this.state.stage}
              goTo={this.props.goTo}
              showDataFilters={
                this.state.showDataFilters &&
                this.state.stage.dataset.access === "owner"
              }
            />
          </div>
        )
      }
    );
    if (
      this.state.showDataFilters &&
      this.state.stage.dataset.access === "owner" &&
      isS3SourceType(this.state.stage.source_type)
    ) {
      tabs.push({
        name: (
          <span>
            DataFilters<span className="beta-tab-flag">BETA</span>
          </span>
        ),
        index: "datafilters",
        route: `${this.props.match.url}#datafilters`,
        content: (
          <React.Fragment>
            <DataFiltersCard
              dataset={this.state.stage.dataset}
              stageId={this.state.stage.id}
              goTo={this.props.goTo}
            />
          </React.Fragment>
        )
      });
    }
    tabs.push(
      {
        name: "Metadata",
        index: "metadata",
        route: `${this.props.match.url}#metadata`,
        content: (
          <div id="nav-metadata">
            <div className="profiling">
              <div className="header-metadata">
                <div className="header-metadata-left">
                  <div>
                    <span className="metadata-title">Dataset name </span>
                    {this.state.stage.dataset.name}
                  </div>
                  <div>
                    <span className="metadata-title">Stage rows count </span>
                    {this.state.stage.profiling_nb_rows || "Not available"}
                  </div>
                </div>
                {this.state.stage.dataset.access === "owner" && (
                  <div className="header-metadata-right">
                    <ProfilingAction
                      stage={this.state.stage}
                      reloadStage={this.getStage}
                    />
                    <div className="p-2" />
                    <ToggleBasicMetadataDisplay
                      dataset={this.state.stage.dataset}
                    />
                  </div>
                )}
              </div>
              {this.state.stage.profiling_execution.error &&
                this.state.stage.profiling_execution.error !== "null" && (
                  <div className="Glue-error mb-3">
                    <Alert severity="error">
                      {this.state.stage.profiling_execution.error}
                    </Alert>
                  </div>
                )}
              <StageMetadata
                stage={this.state.stage}
                dataset={this.state.stage.dataset}
                reloadData={this.getStage}
              />
            </div>
          </div>
        )
      },
      {
        name: "Profiling History",
        index: "profiling",
        route: `${this.props.match.url}#profiling`,
        content: (
          <div id="nav-profiling">
            <ProfilingExecutionHistory stage={this.state.stage} />
          </div>
        )
      }
    );
    return tabs;
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"StageView"} />;
    if (!this.state.ready) return <Loading message={"Stage View"} />;
    return (
      <div className="stage-view">
        <Breadcrumb view={`Stage - ${this.state.stage.name || ""}`} />

        {this.state.stage.dataset.access === "owner" && (
          <div className="header-metadata-right">
            <div
              className="butn butn-full m-1"
              onClick={() =>
                this.props.goTo({
                  route: routes.Stage.Edit,
                  params: {
                    id: this.props.match.params.outputId,
                    datasetUri: this.state.stage.dataset.uri
                  }
                })
              }
            >
              <i className="fas fa-pencil-alt butn-icon" />
              <span className="butn-text">Edit</span>
            </div>
          </div>
        )}

        <Tabs
          isPreload={false}
          defaultActiveIndex={"information"}
          tabs={this.getTabs()}
        />
      </div>
    );
  }
}

export default withAppSync(StageView);
