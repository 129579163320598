/* @flow */

import React from "react";
import CopyToClipboard from "../../../../components/copyToClipboard";
import "./RSDatasourceCredentials.less";

type propTypes = {
  rsdatasource: Object
};

class RSDatasourceCredentials extends React.PureComponent<propTypes> {
  render() {
    const { rsdatasource } = this.props;
    return (
      <div>
        <div>
          <div className="form-container">
            <div className="form-group">
              <label className="label-form">Endpoint</label>
              <div className="input-group">
                <input
                  style={{ backgroundColor: "#FFF" }}
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  value={rsdatasource.endpoint}
                />
                <CopyToClipboard
                  text={rsdatasource.endpoint}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Port</label>
              <div className="input-group">
                <input
                  style={{ backgroundColor: "#FFF" }}
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  value={5439}
                />
                <CopyToClipboard
                  text={5439}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="label-form">Database Name</label>
                <div className="input-group">
                  <input
                    style={{ backgroundColor: "#FFF" }}
                    disabled
                    name="name"
                    className="form-control form-control-sm"
                    value={rsdatasource.db_name}
                  />
                  <CopyToClipboard
                    text={rsdatasource.db_name}
                    className={"fas fa-copy btn-carre"}
                    label={""}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="label-form">Database User</label>
                <div className="input-group">
                  <input
                    style={{ backgroundColor: "#FFF" }}
                    disabled
                    name="name"
                    className="form-control form-control-sm"
                    value={rsdatasource.db_username}
                  />
                  <CopyToClipboard
                    text={rsdatasource.db_username}
                    className={"fas fa-copy btn-carre"}
                    label={""}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="label-form">Database Password</label>
                <div className="input-group">
                  <input
                    style={{ backgroundColor: "#FFF" }}
                    disabled
                    name="name"
                    className="form-control form-control-sm"
                    value={rsdatasource.db_password}
                  />
                  <CopyToClipboard
                    text={rsdatasource.db_password}
                    className={"fas fa-copy btn-carre"}
                    label={""}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">JDBC</label>
              <div className="input-group">
                <input
                  style={{ backgroundColor: "#FFF" }}
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  value={rsdatasource.jdbc_url}
                />
                <CopyToClipboard
                  text={rsdatasource.jdbc_url}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">ODBC</label>
              <div className="input-group">
                <input
                  style={{ backgroundColor: "#FFF" }}
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  value={rsdatasource.odbc_url}
                />
                <CopyToClipboard
                  text={rsdatasource.odbc_url}
                  className={"fas fa-copy btn-carre"}
                  label={""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RSDatasourceCredentials;
