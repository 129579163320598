/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withAppSync from "../../../AppsyncHOC";
import ItemFromList from "../../../../components/itemFromList";
import { formatDate } from "../../../../utils/date";
import Links from "../../../links";
import { updateMessage } from "../../../globalNotifications/actions";
import Error from "../../../../components/error";
import { getAccountUriFromUri } from "../../../../utils/toolsForUri";
import Modal from "../../../../components/modal";
import ButtonAction from "../../../../components/buttonAction";
import MoreDetailsNotification from "../MoreDetailsNotification";

type sharedItem = {
  id: string,
  table_name: string,
  stage_id: string,
  stage: {
    table: string,
    id: string,
    uri: string
  },
  data_filter_id: string,
  data_filter: {
    id: string,
    name: string,
    columns: string[],
    description: string
  }
};

type propTypes = {
  share: {
    id: string,
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    dataset: {
      access: string,
      uri: string,
      name: string,
      expiration_date?: string,
      datasource: {
        uri: string,
        name: string
      }
    },
    items: {
      total: number,
      offset: number,
      limit: number,
      results: sharedItem[]
    },
    updatedat: ?string,
    message: string
  },
  revokeShare: Function,
  onAction: Function,
  showGlobalNotification: Function
};
type stateTypes = {
  error?: Object,
  revokeModal: boolean,
  revokeMessage: string
};

class DatasetShareRequestAccepted extends React.Component<
  propTypes,
  stateTypes
> {
  static defaultProps = {
    share: {
      id: "-",
      group: {
        uri: ":::::::",
        name: "-",
        account: {
          name: "-"
        }
      },
      dataset: {
        access: "read",
        uri: ":::::::",
        name: "-",
        datasource: {
          uri: ":::::::",
          name: "-"
        }
      },
      items: {
        total: 1,
        offset: 0,
        limit: 10,
        results: [
          {
            id: "",
            table_name: "",
            stage_id: "",
            stage: {
              table: "",
              id: "",
              uri: ""
            },
            data_filter_id: "",
            data_filter: {
              id: "",
              name: "",
              columns: ["id", "survived"],
              description: ""
            }
          }
        ]
      },
      updatedat: undefined,
      message: ""
    }
  };

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      revokeModal: false,
      revokeMessage: ""
    };
  }

  openRevokeModal = () => this.setState({ revokeModal: true });

  closeRevokeModal = () => this.setState({ revokeModal: false });

  onChange(event) {
    const { value } = event.target;
    this.setState({
      revokeMessage: value
    });
  }

  contentRevokeModal() {
    return (
      <div>
        <fieldset className="form-group">
          <legend className="label-form">
            Revoke Message
            <div
              className={classnames("reject-message", {
                "message-is-too-long":
                  (this.state.revokeMessage || "").length > 300,
                "message-is-good":
                  (this.state.revokeMessage || "").length <= 300
              })}
            >
              ({(this.state.revokeMessage || "").length}/300 characters max)
            </div>
          </legend>
          <textarea
            onChange={(event) => this.onChange(event)}
            rows="4"
            className="form-control bg-white"
            placeholder="Add a revoke message..."
          />
          {this.state.revokeMessage.length > 300 && (
            <div className="error-msg">
              Revoke message is too long (300 characters max)
            </div>
          )}
        </fieldset>
      </div>
    );
  }

  revokeModal() {
    return (
      <Modal
        title={"Revoke Share"}
        body={this.contentRevokeModal()}
        actions={[
          <button
            type="button"
            className="butn butn-delete"
            disabled={this.state.revokeMessage.length > 300}
            onClick={() => this.revokeShare()}
          >
            Revoke
          </button>,
          <button
            type="button"
            className="butn"
            onClick={() => this.closeRevokeModal()}
          >
            Close
          </button>
        ]}
      />
    );
  }

  displayDataFilterName = () => {
    const { share } = this.props;
    if (share.items !== undefined) {
      if (share.items.results !== undefined && share.items.results.length > 0) {
        return share.items.results[0].data_filter
          ? `with datafilter: ${share.items.results[0].data_filter.name}`
          : "";
      }
    }
    return "";
  };

  getTitle = () => {
    const { share } = this.props;
    const { dataset } = share;

    const accessType = "read";
    const accountUri = getAccountUriFromUri(dataset.uri);

    return (
      <React.Fragment>
        <div className="share-title">
          {share && share.id && (
            <div className="share-title mb-2">
              <Links.Share.View
                shareId={(share || {}).id || "null"}
                state={{ share }}
              >
                {`${share.group.name} share request for dataset ${share.dataset.name}`}
              </Links.Share.View>
            </div>
          )}
          {"The group "}
          <Links.Group.View
            uriGroup={share.group.uri}
            state={{ group: share.group }}
          >
            {share.group.name}
          </Links.Group.View>
          {`, from ${share.group.account.name}, has a ${accessType} access on `}
          <Links.Dataset.View uriDataset={dataset.uri}>
            {dataset.name}
          </Links.Dataset.View>
          {" ("}
          <Links.Datasource.View
            uriAccount={accountUri}
            uriDatasource={(dataset.datasource || {}).uri}
          >
            {(dataset.datasource || {}).name}
          </Links.Datasource.View>
          {") "}
          {this.displayDataFilterName()}
        </div>
      </React.Fragment>
    );
  };

  getDescription = () => {
    const { share } = this.props;

    return (
      <React.Fragment>
        <div className="share-description">
          <span style={{ color: "green", fontWeight: "600" }}>
            {"Accepted "}
          </span>
          {`the ${formatDate(share.updatedat, false)}`}
          {this.state.error && (
            <Error
              error={this.state.error}
              path={"DatasetShareRequestAccepted"}
              stringOnly
            />
          )}
        </div>
        <MoreDetailsNotification share={share} />
      </React.Fragment>
    );
  };

  revokeShare = () => {
    if (this.state.revokeMessage.length <= 300) {
      const { share, revokeShare, onAction } = this.props;

      return revokeShare(
        share.dataset.uri,
        share.group.uri,
        this.state.revokeMessage
      )
        .then(() => {
          this.closeRevokeModal();
          this.props.showGlobalNotification({
            message: "The access request has been successfully revoked",
            type: "success"
          });
          onAction();
        })
        .catch((error) => {
          this.props.showGlobalNotification({
            message:
              "An error occurred during the revoke of the access request",
            type: "success"
          });
          this.setState({
            error
          });
        });
    }

    return false;
  };

  getActions = () => {
    const { share } = this.props;

    const { dataset = {} } = share;
    if (dataset.can_share) {
      return [
        <ButtonAction
          label="revoke"
          className="butn butn-delete"
          onClick={this.openRevokeModal}
        />
      ];
    }

    return [];
  };

  render() {
    return (
      <div>
        <ItemFromList
          className={"a-share"}
          name={this.getTitle()}
          actions={this.getActions()}
          description={this.getDescription()}
          hideLabelName
          hideLabelDescription
        />
        <div>{this.state.revokeModal && this.revokeModal()}</div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasetShareRequestAccepted)
);
