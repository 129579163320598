/* @flow */
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Col from "../../components/col";
import Row from "../../components/row";
import withAppSync from "../AppsyncHOC";
import routes from "../routes";
import Error from "../../components/error";
import MultipleValueTextInput from "../../components/multipleValueTextInput";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";
import Breadcrumb from "../../components/breadcrumb";
import GraphQl from "../../graphQL";
import Loading from "../../components/loading";
import { isValidEmail } from "../../utils/email";
import "./form.less";
import { haveSpecialCharactersCDH } from "../../utils/string";
import SelectInfinite from "../../components/SelectInfinite";

const Log = Logger("GroupCreate");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriGroup: string
    },
    path: string
  },
  location: {
    state: {
      group: {
        uri: string,
        name: string,
        responsible: string,
        description: string,
        tags: string,
        mailing_list: Array<string>
      }
    }
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  usersList: Array<{ name: string, uri: string }>,
  usersTotal: number,
  environments: Array<Object>,
  errorSubmit: boolean,
  isNew: boolean,
  isFetchingGroup: boolean,
  isFetchingUsers: boolean,
  isFetchingEnvironments: boolean,
  group: {
    name: string,
    description: string,
    tags: Array<string>,
    mailing_list: Array<string>,
    responsible: Object,
    environments?: Array<string>
  },
  isSubmitting: boolean,
  isValidEmailOnMaillingListInput: boolean,
  totalEnvironments: number
};

class GroupForm extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    const isNew = this.props.match.path !== routes.Group.Edit;
    this.state = {
      environments: [],
      isNew,
      isFetchingUsers: true,
      isFetchingGroup: false,
      isFetchingEnvironments: true,
      totalEnvironments: 0,
      errorSubmit: false,
      usersList: [],
      usersTotal: 0,
      group: {
        name: "",
        description: "",
        responsible: {},
        tags: [],
        mailing_list: [],
        environments: []
      },
      isSubmitting: false,
      isValidEmailOnMaillingListInput: false
    };
  }

  componentDidMount() {
    this.getUsers();
    if (!this.state.isNew) {
      this.getGroup();
    } else {
      this.getEnvironments();
    }
  }

  getUsers = (offset = 0, search) =>
    this.props.api.account
      .listIdentities(this.props.match.params.uriAccount, {
        offset,
        limit: 10,
        search: { name: search }
      })
      .then((items) => {
        if (!items) return [];
        this.setState((prevState) => ({
          isFetchingUsers: false,
          usersList: prevState.usersList.concat(items.results),
          usersTotal: items.total
        }));
        return items.results.map((user) => ({
          value: user.uri,
          label: user.name
        }));
      })
      .catch((error) => {
        this.setState({
          isFetchingUsers: false,
          errorSubmit: error
        });
        return [];
      });

  getGroup = () =>
    this.setState({ isFetchingGroup: true }, () =>
      this.props.api.group
        .get(this.props.match.params.uriGroup)
        .then((g) => {
          const group = {
            name: g.name,
            description: g.description,
            tags: g.tags.split(",").filter((f) => f),
            mailing_list: g.mailing_list || [],
            responsible: g.responsible
          };
          this.setState({ group, isFetchingGroup: false });
        })
        .catch(() => {
          this.setState({ isFetchingGroup: false });
        })
    );

  getEnvironments = (offset = 0) =>
    this.props.api.account
      .listPlatforms(this.props.match.params.uriAccount, { offset })
      .then((items) => {
        if (!items) return [];

        this.setState({
          environments: items.results,
          totalEnvironments: items.total,
          isFetchingEnvironments: false
        });

        return items.results.map((e) => ({
          value: e.uri,
          label: e.name
        }));
      })
      .catch((errorSubmit) => {
        this.setState({
          errorSubmit,
          isFetchingEnvironments: false
        });
        return [];
      });

  saveGroup = (values, setSubmitting) => {
    this.setState({ isSubmitting: true });

    if (this.state.isNew) {
      const accounturi = this.props.match.params.uriAccount;
      return this.props.api.group
        .createGroup(accounturi, {
          name: values.name,
          resource_name: values.name,
          responsible: this.state.group.responsible.uri,
          description: values.description || "No description available",
          tags: this.state.group.tags.toString(),
          mailing_list: this.state.group.mailing_list,
          environments: this.state.group.environments || []
        })
        .then((group) => {
          this.setState({ isSubmitting: false });
          setSubmitting(false);
          this.props.showGlobalNotification({
            message: "New group created",
            type: "success"
          });
          return this.props.goTo(
            {
              route: routes.Group.EditUsers,
              params: {
                uriGroup: group.uri
              }
            },
            "replace"
          );
        })
        .catch((errorSubmit) => {
          setSubmitting(false);
          this.props.showGlobalNotification({
            message: "Group creation failed",
            type: "alert"
          });
          this.setState({
            errorSubmit,
            isSubmitting: false
          });
        });
    }

    return this.props.api.group
      .update(this.props.match.params.uriGroup, {
        name: values.name,
        responsible: this.state.group.responsible.uri,
        description: values.description || "No description available",
        tags: this.state.group.tags.toString(),
        mailing_list: this.state.group.mailing_list
      })
      .then((group) => {
        this.setState({ isSubmitting: false });
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Group updated",
          type: "success"
        });
        return this.props.goTo(
          {
            route: routes.Group.View,
            params: {
              uriGroup: group.uri
            }
          },
          "replace"
        );
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Group update failed",
          type: "alert"
        });
        this.setState({
          errorSubmit,
          isSubmitting: false
        });
      });
  };

  handleChange = (field) => (event) => {
    const { value } = event.target;
    Log.info(field, "<-", value);
    return this.setState((prevState) => {
      const group = Object.assign({}, prevState.group);
      group[field] = value;

      return {
        group,
        errorSubmit: false
      };
    });
  };

  handleChangeResponsible = (uri) =>
    this.setState((prevState) => {
      const group = Object.assign({}, prevState.group);
      const userResponsible = prevState.usersList.find(
        (user) => user.uri === uri
      );
      if (userResponsible) {
        group.responsible = userResponsible;
      } else {
        group.responsible = {};
      }

      return {
        group,
        errorSubmit: false
      };
    });

  render() {
    return (
      <div className="group-form">
        <div className="bread-line">
          <div className={"title-line"}>
            <Breadcrumb
              view={
                this.state.isNew
                  ? "Create a new group"
                  : `Edit group ${(this.state.group || {}).name || "..."}`
              }
            />
          </div>
        </div>

        {this.state.isFetchingGroup && <Loading message={"group"} />}
        {!this.state.isFetchingGroup && this.state.group && (
          <Row>
            <Col size={1} />
            <Col className={"py-4 my-3"} size={10}>
              <Formik
                initialValues={{ ...this.state.group }}
                validate={(values) => {
                  Log.info("validate", this.state.group);
                  const errors = {};
                  if (!values.name || values.name.trim().length < 1)
                    errors.name = "Can't be empty.";
                  if (haveSpecialCharactersCDH(values.name))
                    errors.name =
                      "Name must not contain these special characters ( & / ' ;)";
                  if (
                    this.state.group &&
                    this.state.group.mailing_list.find(
                      (email) => !isValidEmail(email)
                    )
                  )
                    errors.mailing_list = "Only email accepted";
                  if (!this.state.isNew) {
                    if (!this.state.group.responsible.uri)
                      errors.responsible = "Can't be empty.";
                  }
                  Log.info("errors", errors);
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.saveGroup(values, setSubmitting);
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {this.state.errorSubmit && (
                      <Error
                        title={"..."}
                        error={this.state.errorSubmit}
                        path={"GroupCreate"}
                        stringOnly
                      />
                    )}
                    {Object.keys(errors).length > 0 && (
                      <div className="error-msg">
                        {this.state.isNew &&
                          "You have some errors, we can't create a new group. Please, verify all fields."}
                        {!this.state.isNew &&
                          "You have some errors, we can't edit this group. Please, verify all fields."}
                      </div>
                    )}

                    <fieldset className="form-group">
                      <legend className="label-form">name</legend>
                      <Field
                        type="name"
                        name="name"
                        className="form-control bg-white"
                        placeholder="group name..."
                      />
                      {errors.name && (
                        <div className="error-msg">{errors.name}</div>
                      )}
                    </fieldset>
                    {this.state.isNew && (
                      <fieldset className="form-group">
                        <legend className="label-form">
                          Attach the group to environments
                        </legend>
                        <SelectInfinite
                          isLoadingOptions={this.state.isFetchingEnvironments}
                          placeholder={"Select environments"}
                          isMulti
                          initialOptions={this.state.environments.map((e) => ({
                            value: e.uri,
                            label: e.name
                          }))}
                          totalOptions={this.state.totalEnvironments}
                          loadMoreOptions={this.getEnvironments}
                          optionResults
                          onSelectOption={(options) =>
                            this.handleChange("environments")({
                              target: {
                                value: (options || []).map(
                                  (option) => option.value
                                )
                              }
                            })
                          }
                        />
                      </fieldset>
                    )}

                    <fieldset className="form-group">
                      <label className="label-form">Responsible</label>
                      <SelectInfinite
                        isSearchable
                        isClearable
                        isLoadingOptions={this.state.isFetchingUsers}
                        placeholder={"Select a user"}
                        initialOptions={this.state.usersList.map((user) => ({
                          value: user.uri,
                          label: user.name
                        }))}
                        totalOptions={this.state.usersTotal}
                        loadMoreOptions={this.getUsers}
                        selectedOption={
                          this.state.group &&
                          this.state.group.responsible &&
                          this.state.group.responsible.uri
                            ? {
                                value: this.state.group.responsible.uri,
                                label: this.state.group.responsible.name
                              }
                            : undefined
                        }
                        onSelectOption={(value) =>
                          this.handleChangeResponsible(value)
                        }
                      />
                      {errors.responsible && (
                        <div className="error-msg">{errors.responsible}</div>
                      )}
                    </fieldset>

                    <fieldset className="form-group">
                      <MultipleValueTextInput
                        onItemAdded={(e) => {
                          this.setState({
                            isValidEmailOnMaillingListInput: false
                          });
                          this.handleChange("mailing_list")(e);
                        }}
                        onItemDeleted={(e) =>
                          this.handleChange("mailing_list")(e)
                        }
                        onValueChange={(value) => {
                          if (isValidEmail(value))
                            this.setState({
                              isValidEmailOnMaillingListInput: true
                            });
                          else
                            this.setState({
                              isValidEmailOnMaillingListInput: false
                            });
                        }}
                        lockAddItem={
                          !this.state.isValidEmailOnMaillingListInput
                        }
                        values={(this.state.group || {}).mailing_list || []}
                        label={"Mailing List"}
                        name={"mailing_list"}
                        placeholder={
                          "Add email, separate them by COMMA or ENTER"
                        }
                        classNameInput={classnames("form-control bg-white", {
                          "email-is-valid":
                            this.state.isValidEmailOnMaillingListInput
                        })}
                        shouldAddOnBlur
                      />
                      {errors.mailing_list && (
                        <div className="error-msg">{errors.mailing_list}</div>
                      )}
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">description</legend>
                      <Field
                        component="textarea"
                        name="description"
                        className="form-control bg-white"
                        placeholder="description..."
                      />
                      <ErrorMessage name="description" component="div" />
                    </fieldset>

                    <fieldset className="form-group">
                      <MultipleValueTextInput
                        onItemAdded={(e) => this.handleChange("tags")(e)}
                        onItemDeleted={(e) => this.handleChange("tags")(e)}
                        values={(this.state.group || {}).tags || []}
                        label={"Tags"}
                        name={"tags"}
                        placeholder={
                          "Add tags, separate them by COMMA or ENTER"
                        }
                        classNameInput={"form-control bg-white"}
                        shouldAddOnBlur
                      />
                      <ErrorMessage name="tags" component="div" />
                    </fieldset>

                    <Row className="justify-content-center">
                      <Col size={2}>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="butn"
                        >
                          {this.state.isSubmitting && (
                            <i className="fas fa-circle-notch fa-spin fa-spacing" />
                          )}
                          {this.state.isNew ? "Create Group" : "Update group"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(GroupForm));
