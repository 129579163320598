/* @flow */

import React from "react";
import { connect } from "react-redux";
import ItemFromList from "../../../../components/itemFromList";
import ButtonAction from "../../../../components/buttonAction";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import "./style.less";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  onAction: Function,
  message: string,
  data: {
    arn: string,
    description: string,
    status: string,
    type: string,
    id: string,
    group: {
      uri: string,
      name: string
    },
    platform: {
      uri: string,
      name: string,
      aws: string
    }
  }
};

type stateTypes = {
  error?: Object
};

class TrustNotification extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  getClassStatus = () => {
    switch (this.props.data.status) {
      case "accepted":
        return "trust-status-text-accepted";
      case "pending":
        return "trust-status-text-pending";
      default:
        return "trust-status-text-rejected";
    }
  };

  acceptTrustAttachToGroup = (groupTrust: Object) =>
    this.props.api.group
      .acceptTrustAttachToGroup(groupTrust.id, groupTrust.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust accepted !",
          type: "success"
        });
        this.props.onAction();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to accept trust",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  rejectTrustAttachToGroup = (groupTrust: Object) =>
    this.props.api.group
      .rejectTrustAttachToGroup(groupTrust.id, groupTrust.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust rejected !",
          type: "success"
        });
        this.props.onAction();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to reject trust",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  getActions = (groupTrust) => {
    if (groupTrust.status === "accepted") {
      return [
        <ButtonAction
          label="Reject"
          className="butn butn-delete"
          onClick={() => this.rejectTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    if (groupTrust.status === "pending") {
      return [
        <ButtonAction
          label="Accept"
          className="butn"
          onClick={() => this.acceptTrustAttachToGroup(groupTrust)}
        />,
        <ButtonAction
          label="Reject"
          className="butn butn-delete"
          onClick={() => this.rejectTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    if (groupTrust.status === "rejected") {
      return [
        <ButtonAction
          label="Accept"
          className="butn"
          onClick={() => this.acceptTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    return false;
  };

  render() {
    return (
      <div className="trust-notification">
        <ItemFromList
          name={
            <div>
              {this.state.error && (
                <Error
                  error={this.state.error}
                  path="TrustNotification"
                  stringOnly
                />
              )}
              <div>
                <span className="platform-name">
                  {`[${this.props.data.platform.name}]`}
                </span>
                <span className="group-uri">{this.props.data.group.name}</span>
              </div>
              <div>
                <span className="trust-arn">
                  {this.props.data.arn} ({this.props.data.type})
                </span>
              </div>
            </div>
          }
          description={
            <div>
              <div>
                {this.props.message.split("\n").map((c) => (
                  <div key={c}>{c}</div>
                ))}
              </div>
              <div>
                <span>Status:</span>
                <span className={this.getClassStatus()}>
                  <span className="status-label">{this.props.data.status}</span>
                </span>
              </div>
            </div>
          }
          actions={this.getActions(this.props.data)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(TrustNotification));
