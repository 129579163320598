/* @flow */

export const countUnreadNotifications = {
  query: `query Q {
    countUnreadNotifications
}
`,
  keyOutput: "countUnreadNotifications"
};

export type CountUnreadNotificationsPropsTypes = number;
