/* @flow */

import React from "react";
import "./style.less";

type propTypes = {
  nb: number
};

class NbBookmarksStar extends React.PureComponent<propTypes> {
  render() {
    return (
      <span className="nb-bookmarks">
        <i className="nb-bookmarks-star fas fa-star" />
        {this.props.nb}
      </span>
    );
  }
}

export default NbBookmarksStar;
