/* @flow */
import React from "react";
import { connect } from "react-redux";
import Row from "../../../../components/row";
import Col from "../../../../components/col";
import { getAccountUriFromUri } from "../../../../utils/toolsForUri";
import GraphQl from "../../../../graphQL";
import { diffDate, formatDate } from "../../../../utils/date";
import ButtonAction from "../../../../components/buttonAction";
import Modal from "../../../../components/modal";
import routes from "../../../routes";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import withGoTo from "../../../goToHOC";
import "./RSDatasourceCredentials.less";
import logoRedshift from "../../../logos/redshift-logo.png";
import RSDatasourceCredentials from "./RSDatasourceCredentials";
import { Alert, AlertTitle } from "@material-ui/lab";
import classnames from "classnames";

type propTypes = {
  uriAccount: string,
  datasource: {
    uri: string,
    parent: string,
    etluser_credentials_expiration_date: string,
    etluser_credentials_creator: string,
    etluser_credentials_description: string
  },
  rsdatasource: Object,
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  isDeleting: boolean,
  overwrite: boolean,
  deleted: boolean,
  description: string,
  createNewCredsModal: boolean,
  createCredsModal: boolean,
  deleteCredsModal: boolean,
  errorDeleteCreds: Object,
  userCredsModal: boolean,
  etl_stack_status: string,
  etl_stack_error: string,
  etl_stack_name: string
};

class RSSDatasourceETLCredentials extends React.Component<
  propTypes,
  stateTypes
> {
  interval: ?IntervalID;
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isDeleting: false,
      overwrite: false,
      description: "",
      deleted: false,
      createNewCredsModal: false,
      createCredsModal: false,
      deleteCredsModal: false,
      errorDeleteCreds: undefined,
      userCredsModal: false,
      etl_stack_status: this.props.rsdatasource.etl_stack_status,
      etl_stack_error: this.props.rsdatasource.etl_stack_error,
      etl_stack_name: this.props.rsdatasource.etl_stack_name
    };
  }

  componentDidMount() {
    this.checkETLStackStatus();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  checkETLStackStatus = () => {
    this.interval = setInterval(this.getRSDatasource, 20000);
  };

  handleChange = (value: Object) => this.setState({ overwrite: !!value });

  getRSDatasource = () => {
    this.props.api.datasource
      .getRSDatasource(this.props.datasource.uri)
      .then((dsrc) => {
        this.setState({
          etl_stack_status: dsrc.etl_stack_status,
          etl_stack_error: dsrc.etl_stack_error,
          etl_stack_name: dsrc.etl_stack_name
        });
      })
      .catch((error) => {
        this.setState({
          etl_stack_error: error.message
        });
      });
  };

  generateRSETLCreds = () => {
    const input = {
      etluser_credentials_description: this.state.description || ""
    };
    this.props.api.datasource
      .generateCredentials(
        this.props.uriAccount,
        this.props.datasource.uri,
        input,
        true
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "ETL database user creation started...",
          type: "success"
        });
        this.setState({
          createNewCredsModal: false,
          createCredsModal: false
        });
        this.getRSDatasource();
      })
      .catch((error) => {
        this.setState({
          etl_stack_error: error.message
        });
        this.setState({
          createNewCredsModal: false,
          createCredsModal: false
        });
      });
  };

  deleteCredentials = () => {
    if (!this.state.isDeleting) {
      this.setState({ isDeleting: true }, () =>
        this.props.api.datasource
          .deleteCredentials(this.props.datasource.uri)
          .then(() => {
            this.setState({ isDeleting: false, deleted: true });
            this.getRSDatasource();
          })
          .catch((errorDeleteCreds) => {
            this.props.showGlobalNotification({
              message:
                "Could not find matching user on AWS account. ETL credentials have been removed from CDH",
              type: "success",
              popupDuration: 6000
            });
            this.setState({
              isDeleting: false,
              deleted: true,
              errorDeleteCreds
            });
          })
      );
    }
  };

  displayExpiration = () => {
    const expiration =
      this.props.datasource.etluser_credentials_expiration_date;
    const expirationDate = formatDate(expiration, false);
    const dateNow = new Date();
    if (diffDate(dateNow.toString(), expiration) < 0) {
      return (
        <div className="red-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Warning : Expired on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) < 240) {
      return (
        <div className="red-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Warning : Will expire on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) < 1080) {
      return (
        <div className="yellow-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Will expire on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) > 1080) {
      return (
        <div className="green-warning">
          <i className="fas fa-check-circle fa-spacing" />
          Will expire on {expirationDate}
        </div>
      );
    }
    return false;
  };

  onChangeDescription = (event: any) => {
    const { value } = event.target;
    if (value) {
      this.setState({ description: value });
    }
  };

  render() {
    return (
      <Row>
        <Col
          size={8}
          style={{
            marginTop: "8px",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          Get ETL User credentials
          {this.props.datasource.etluser_credentials_creator &&
            this.props.datasource.etluser_credentials_creator !== "null" &&
            !this.state.deleted && (
              <div className="description-container">
                <span className="description-container-label">Created by:</span>
                <span className="elt-creator">
                  {this.props.datasource.etluser_credentials_creator}
                </span>
              </div>
            )}
          {this.props.datasource.etluser_credentials_description &&
            this.props.datasource.etluser_credentials_description.length > 0 &&
            !this.state.deleted && (
              <div className="description-container">
                <span className="description-container-label">
                  Description:
                </span>
                <div className="description-etl">
                  {this.props.datasource.etluser_credentials_description}
                </div>
              </div>
            )}
          {this.state.etl_stack_status && (
            <div className="description-container">
              <span className="description-container-label">Stack status:</span>
              <span
                style={{ fontSize: "11px", fontWeight: 500 }}
                className={classnames("tag-metrics", {
                  "green-status": [
                    "CREATE_COMPLETE",
                    "UPDATE_COMPLETE"
                  ].includes(this.state.etl_stack_status),
                  "warning-status":
                    this.state.etl_stack_status === "CREATE_IN_PROGRESS",
                  "error-status": [
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETE",
                    "NOT_FOUND",
                    "ROLLBACK_COMPLETE",
                    "ROLLBACK_FAILED"
                  ].includes(this.state.etl_stack_status)
                })}
              >
                {this.state.etl_stack_status}
              </span>
            </div>
          )}
          {this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted &&
            this.displayExpiration()}
          {this.state.etl_stack_error && (
            <div className={"mt-3 mb-3"}>
              <div className="IAM-error">
                <Alert severity="error">
                  <AlertTitle>Stack deployment failed</AlertTitle>
                  {this.state.etl_stack_error}
                </Alert>
              </div>
            </div>
          )}
          {this.state.etl_stack_name &&
            this.state.etl_stack_status.includes("PROGRESS") && (
              <div className={"mt-3 mb-3"}>
                <div className="IAM-error">
                  <Alert severity="info">
                    <AlertTitle>
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />{" "}
                      Stack deployment in progress
                    </AlertTitle>
                    <div>
                      <span>
                        Some features are not available until the deployment
                        ends.
                      </span>
                    </div>
                  </Alert>
                </div>
              </div>
            )}
        </Col>
        <Col
          size={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div>
            {this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted ? (
              <div>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ userCredsModal: true })}
                >
                  View
                </div>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ createCredsModal: true })}
                >
                  Reset
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ createNewCredsModal: true })}
                >
                  ETL Credentials
                </div>
                {this.state.createNewCredsModal && (
                  <Modal
                    title="Generate ETL credentials"
                    body={
                      <div>
                        <div className="mt-2">
                          <textarea
                            onChange={this.onChangeDescription}
                            className="form-control bg-white"
                            placeholder="Describe your use of this credential"
                          ></textarea>
                        </div>
                      </div>
                    }
                    actions={[
                      <ButtonAction
                        label="Generate ETL credentials"
                        className="butn butn-flat"
                        onClick={this.generateRSETLCreds}
                      />,
                      <ButtonAction
                        label="Close"
                        onClick={() =>
                          this.setState({ createNewCredsModal: false })
                        }
                      />
                    ]}
                  />
                )}
              </React.Fragment>
            )}
            {this.state.createCredsModal && (
              <Modal
                title="Generate ETL credentials"
                body={
                  <div>
                    <div>
                      <div className="mt-2">
                        <textarea
                          onChange={this.onChangeDescription}
                          className="form-control bg-white"
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </div>
                    <div>
                      <hr />
                      <span style={{ color: "var(--red)" }}>
                        <i className="fas fa-exclamation-triangle fa-spacing" />
                        Warning:
                      </span>
                      &nbsp;The generation of new credentials will invalidate
                      the current ones.
                    </div>
                  </div>
                }
                actions={[
                  <ButtonAction
                    label="Generate ETL credentials"
                    className="butn butn-flat"
                    onClick={this.generateRSETLCreds}
                  />,
                  <ButtonAction
                    label="Close"
                    onClick={() => this.setState({ createCredsModal: false })}
                  />
                ]}
              />
            )}
            {this.state.userCredsModal && (
              <Modal
                title={
                  <span>
                    <img
                      className="connector-logo-img mr-2"
                      src={logoRedshift}
                      alt="Redshift Serverless"
                      width={35}
                      height={35}
                    />
                    ETL Database User Credentials
                  </span>
                }
                body={
                  <RSDatasourceCredentials
                    rsdatasource={this.props.rsdatasource}
                  />
                }
                actions={[
                  <button
                    key={"group-close"}
                    type="button"
                    className="butn"
                    onClick={() => this.setState({ userCredsModal: false })}
                  >
                    Close
                  </button>
                ]}
              />
            )}
          </div>

          {this.state.etl_stack_status &&
            !this.state.etl_stack_status.includes("PROGRESS") &&
            this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted && (
              <div>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ deleteCredsModal: true })}
                >
                  {this.state.isDeleting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  Delete
                </div>

                {this.state.deleteCredsModal && (
                  <Modal
                    title="Delete ETL credentials"
                    body="Are you sure ?"
                    errorMessage={this.state.errorDeleteCreds}
                    actions={[
                      <ButtonAction
                        label="Close"
                        onClick={() =>
                          this.setState({ deleteCredsModal: false })
                        }
                      />,
                      <ButtonAction
                        label="Delete"
                        className="butn butn-delete"
                        onClick={() => {
                          this.deleteCredentials();
                          this.setState({ deleteCredsModal: false });
                        }}
                      />
                    ]}
                  />
                )}
              </div>
            )}
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(RSSDatasourceETLCredentials)));
