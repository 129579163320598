export const countDatasourceCreatedPerDay = {
  query: `query Q ($accountUri: String!) {
        countDatasourceCreatedPerDay (accountUri: $accountUri) {
            createdat
            count
        }
      }
    `,
  keyOutput: "countDatasourceCreatedPerDay"
};
