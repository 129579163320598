/* @flow */

import React from "react";
import "./style.less";
import StageMetadataFullOverview from "./stageMetadataFullOverview";
import StageMetadataLimitedOverview from "./stageMetadataLimitedOverview";

type propTypes = {
  dataset: Object,
  stage: {
    id: string,
    columns: Array<{
      Type: string,
      Name: string,
      Metadata: Object,
      Description: string,
      Comment: string
    }>,
    profiling_execution: {
      status: string
    }
  },
  reloadData: Function
};

class StageMetadata extends React.PureComponent<propTypes> {
  render() {
    if (["owner", "shared"].includes(this.props.dataset.access)) {
      return (
        <StageMetadataFullOverview
          stage={this.props.stage}
          reloadData={this.props.reloadData}
        />
      );
    }

    if (this.props.dataset.basic_metadata) {
      return <StageMetadataLimitedOverview stage={this.props.stage} />;
    }

    return <div>The metadata is hidden.</div>;
  }
}

export default StageMetadata;
