/* @flow */

import React from "react";
import { withAuth } from "@okta/okta-react";
import "./style.less";
import Tabs from "../../../components/tabs";
import Breadcrumb from "../../../components/breadcrumb";
import routes from "../../routes";
import NewShare from "./NewShare";
import ShareRequestList from "./shareRequestList";
import ManagedShareList from "./managedShareList";

function ShareManagement() {
  return (
    <div className={"dashboard"}>
      <div className="bread-line">
        <div className="title-content">
          <Breadcrumb view="My Shares" hideGoBack />
        </div>
      </div>

      <Tabs
        noBackgroundWhite
        defaultActiveIndex={"shares-owner"}
        tabs={[
          {
            name: (
              <div>
                <i className="fas fa-inbox" />
                Received Requests Access
              </div>
            ),
            index: "shares-owner",
            route: routes.Share.ShareOwned,
            content: <ManagedShareList />
          },
          {
            name: (
              <div>
                <i className="fas fa-paper-plane" />
                Sent Requests Access
              </div>
            ),
            index: "shares-requested",
            route: routes.Share.ShareWithMe,
            content: <ShareRequestList />
          },
          {
            name: (
              <div>
                <i className="fas fa-share" />
                New Share
              </div>
            ),
            index: "shares-new",
            route: routes.Share.ShareNew,
            content: <NewShare />
          }
        ]}
      />
    </div>
  );
}

export default withAuth(ShareManagement);
