/* @flow */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import Error from "../../../../components/error";
import Routes from "../../../routes";
import { findDomainName } from "../../../datasets/components/constants";
import { updateMessage } from "../../../globalNotifications/actions";
import GraphQl from "../../../../graphQL";
import withGoTo from "../../../goToHOC";
import { isRSSourceType, isS3SourceType } from "../../../../utils/constants";

type propTypes = {
  datasets: Array<Object>,
  datasource: Object,
  onDelete: Function,
  onEdit: Function,
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  uriAccount: string
};

type stateTypes = {
  isSubmitting: boolean,
  errorSubmit?: Object,
  datasetsNameCreated: Array<String>
};

class DatasetsToCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      errorSubmit: undefined,
      datasetsNameCreated: []
    };
  }

  rowRender = (dataset) => (
    <tr
      className={classnames("table-row", {
        "is-created": this.state.datasetsNameCreated.includes(dataset.name)
      })}
    >
      {this.state.datasetsNameCreated.includes(dataset.name) ? (
        <React.Fragment>
          <th className="">CREATED</th>
          <th className="row-action"></th>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <th
            className="row-action"
            onClick={() => this.props.onDelete(dataset)}
          >
            <i className="fas fa-trash fa-spacing" />
          </th>
          <th className="row-action" onClick={() => this.props.onEdit(dataset)}>
            <i className="far fa-edit" />
          </th>
        </React.Fragment>
      )}
      {isS3SourceType(this.props.datasource.source_type) && (
        <th scope="col">{dataset.prefix}</th>
      )}
      {isRSSourceType(this.props.datasource.source_type) && (
        <th scope="col">{dataset.schema}</th>
      )}
      <td className="row-important">{dataset.name}</td>
      <td>{dataset.type}</td>
      <td className="row-light">{dataset.description}</td>
      <td>{`${dataset.basic_metadata}`}</td>
      <td>{dataset.metadata_frequency}</td>
      <td>{dataset.maturity_level}</td>
      <td>{dataset.tags.join(", ")}</td>
      <td>{dataset.visibility}</td>
      <td>{dataset.shareable ? "Allowed" : "Blocked"}</td>
      <td>{dataset.is_registered_with_lakeformation ? "Yes" : "No"}</td>
      <td>{findDomainName(dataset.domain)}</td>
      <td>{dataset.languages.toString()}</td>
      <td>{dataset.data_types.toString()}</td>
      <td>{dataset.update_frequency}</td>
      <td>{dataset.external_description_url || "-"}</td>
      <td>{dataset.license || "-"}</td>
      <td>{dataset.contact || "-"}</td>
    </tr>
  );

  create = (datasets, datasetsRemaining) =>
    this.props.api.datasource
      .createDatasetsFromDetection(this.props.datasource.uri, datasets)
      .then(() => {
        if (!datasetsRemaining) {
          this.props.showGlobalNotification({
            message: "Datasets created",
            type: "success"
          });
        }
      });

  initializeCreate = (datasets) => {
    if (datasets.length > 10) {
      const datasetsGrouped = datasets.slice(0, 10);
      datasets.splice(0, 10);
      return this.create(datasetsGrouped, true).then(() => {
        this.setState((prevState) => ({
          datasetsNameCreated: prevState.datasetsNameCreated.concat(
            datasetsGrouped.map((dataset) => dataset.name)
          )
        }));
        return this.initializeCreate(datasets);
      });
    }

    if (datasets.length > 0) {
      return this.create(datasets, false);
    }

    return Promise.resolve();
  };

  saveDetectedDatasets = () => {
    this.setState({ isSubmitting: true });
    const datasets = this.props.datasets.map((dataset) => ({
      name: dataset.name,
      description: dataset.description,
      tags: (dataset.tags || []).join(","),
      type: dataset.type,
      domain: dataset.domain,
      details: JSON.stringify({
        external_description_url: dataset.external_description_url,
        license: dataset.license,
        contact: dataset.contact
      }),
      update_frequency: dataset.update_frequency,
      languages: dataset.languages,
      data_types: dataset.data_types,
      basic_metadata: dataset.basic_metadata,
      metadata_frequency: dataset.metadata_frequency,
      maturity_level: dataset.maturity_level,
      visibility: dataset.visibility,
      shareable: dataset.shareable,
      is_registered_with_lakeformation:
        dataset.is_registered_with_lakeformation,
      owneruri: this.props.datasource.owner.uri,
      prefix: dataset.prefix,
      schema: dataset.schema
    }));
    const datasetsToCreate = datasets.filter(
      (dataset) => !this.state.datasetsNameCreated.includes(dataset.name)
    );
    this.initializeCreate(datasetsToCreate)
      .then(() => {
        this.setState({ isSubmitting: false });
        return this.props.goTo({
          route: Routes.Datasource.View,
          params: {
            uriDatasource: this.props.datasource.uri,
            uriAccount: this.props.uriAccount
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({
          errorSubmit,
          isSubmitting: false
        });
        this.props.showGlobalNotification({
          message: "Some errors detected",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div>
        <div>
          <label className="label-form title-s">Datasets to create</label>
          {this.props.datasets && (
            <div>
              <div className="detected-datasets-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="row-action" />
                      <th scope="col" className="row-action" />
                      {isS3SourceType(this.props.datasource.source_type) && (
                        <th scope="col">Prefix</th>
                      )}
                      {isRSSourceType(this.props.datasource.source_type) && (
                        <th scope="col">Schema</th>
                      )}
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Basic metadata display</th>
                      <th scope="col">Schedule frequency profiling</th>
                      <th scope="col">Level of maturity</th>
                      <th scope="col">Tags</th>
                      <th scope="col">Usage</th>
                      <th scope="col">Sharing Feature</th>
                      <th scope="col">Registered with AWS Lake Formation</th>
                      <th scope="col">Domain</th>
                      <th scope="col">Languages</th>
                      <th scope="col">Data Types</th>
                      <th scope="col">Update frequency</th>
                      <th scope="col">Link to description</th>
                      <th scope="col">License</th>
                      <th scope="col">Contact</th>
                    </tr>
                  </thead>
                  <tbody>{this.props.datasets.map(this.rowRender)}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {this.state.errorSubmit && (
          <Error
            stringOnly
            error={this.state.errorSubmit}
            path={"AddEndpoint"}
          />
        )}

        <div className={"ml-1 mt-2 row justify-content-center"}>
          <button
            type="button"
            className="butn"
            onClick={this.saveDetectedDatasets}
          >
            {this.state.isSubmitting && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            Create datasets
          </button>
        </div>
        {(this.state.isSubmitting ||
          this.state.datasetsNameCreated.length > 0) && (
          <div>
            <div className="mt-2 text-center">
              Please wait, creating several datasets can take a few minutes
            </div>
            <div className="mt-2 text-center">
              Datasets created: {this.state.datasetsNameCreated.length} /{" "}
              {this.props.datasets.length}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withGoTo(DatasetsToCreate));
