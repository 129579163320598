/* @flow */
import React from "react";
import "../view.less";
import { connect } from "react-redux";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import GraphQl from "../../../../graphQL";
import withGoTo from "../../../goToHOC";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import DeleteObjectWithFrictionModal from "../../../../components/DeleteObjectWithFrictionModal";
import { formatDate } from "../../../../utils/date";
import logoDss from "../../../logos/dataiku-logo.png";
import Links from "../../../links";
import logoS3 from "../../../logos/s3-logo.png";
import logoAthena from "../../../logos/athena-logo.png";
import "./style.less";

type propTypes = {
  connection: Object,
  api: GraphQl,
  reloadConnections: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  errorConsoleAccess?: Object,
  openDeleteModal: boolean,
  errorDelete: Object,
  status: string,
  updatedat: string,
  error: Object,
  autorefreshing: boolean,
  refreshing: boolean
};

class CartDssProjectConnection extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  intervalurl: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      openDeleteModal: false,
      errorDelete: null,
      status: props.connection.status,
      updatedat: props.connection.updatedat,
      error: null,
      autorefreshing: false,
      refreshing: false
    };
  }

  componentWillUnmount() {
    if (this.intervalurl) {
      clearInterval(this.intervalurl);
      this.intervalurl = undefined;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  getDSSConsoleAccess = () => {
    window.open(this.props.connection.dss_instance.url, "_blank");
  };

  deleteConnection = () => {
    this.props.api.dssInstance
      .deleteConnection(this.props.connection.id)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Connection planned for deletion",
          type: "success"
        });
        this.setState({ openDeleteModal: false });
        this.props.reloadConnections();
      })
      .catch((errorDelete) => {
        this.setState({
          errorDelete
        });
      });
  };

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true });

      this.props.api.dssInstance
        .getProjectConnection(this.props.connection.id)
        .then((response) => {
          this.setState({
            status: response.status,
            error: response.error,
            updatedat: response.updatedat,
            refreshing: false
          });
          if (
            ["PENDING", "CREATE_IN_PROGRESS", "DELETE_IN_PROGRESS"].includes(
              response.status
            )
          ) {
            this.autoRefresh();
          } else {
            if (this.interval) {
              clearInterval(this.interval);
              this.interval = undefined;
            }
            this.setState({ autorefreshing: false });
          }
        })
        .catch((error) => {
          this.setState({
            refreshing: false,
            autorefreshing: false,
            error
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        });
    }
  };

  render() {
    const { connection } = this.props;

    return (
      <li className="cdh-card card-shadow card bg-white card-sagemaker-details">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <img
                className="connector-logo-img"
                src={logoDss}
                alt="Dataiku DSS"
                width={15}
                height={15}
              />{" "}
              Dataiku DSS Connection
            </div>
          </div>
          <div className="cdh-cart-end">
            <div
              className={`cart-environment-tag ${connection.platform.environment}`}
            >
              <span>{connection.platform.environment}</span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <div>
              <img
                className="connector-logo-img mr-1"
                src={logoS3}
                alt="S3"
                width={26}
                height={26}
              />
              <span className="cdh-connection-name">{connection.name}</span>
            </div>

            <div>
              <img
                className="connector-logo-img mr-1"
                src={logoAthena}
                alt="Athena"
                width={26}
                height={26}
              />
              <span className="cdh-connection-name">
                {connection.name.concat("_athena")}
              </span>
            </div>

            <div className="cdh-card-details">
              <p>
                <b>Environment:</b>
                <Links.Playground.View
                  uriPlayground={connection.platform_uri}
                  uriAccount={connection.dss_instance.accountid}
                >
                  {" "}
                  {connection.platform.name} ({connection.platform.aws})
                </Links.Playground.View>
              </p>

              <div className="cdh-card-detail">
                <img
                  className="connector-logo-img"
                  src={logoDss}
                  alt="Dataiku DSS"
                  width={15}
                  height={15}
                />
                <span className="content-card-bucket">
                  <b>URL:</b>
                  <a
                    href={`${connection.dss_instance.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {connection.dss_instance.url}
                  </a>
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fa fa-database" />
                <span className="content-card-bucket">
                  <b>Output Datasource:</b>
                  <Links.Datasource.View
                    uriDatasource={connection.datasource_uri}
                    uriAccount={connection.dss_instance.accountid}
                  >
                    {" "}
                    {connection.output_bucket}
                  </Links.Datasource.View>
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fa fa-folder-open" />
                <span className="content-card-bucket">
                  <b>Output Dataset:</b>
                  <Links.Dataset.View uriDataset={connection.dataset_uri}>
                    {" "}
                    {connection.output_prefix}
                  </Links.Dataset.View>
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>&nbsp;created:</b> {formatDate(connection.createdat)}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div className="btn-cart" onClick={this.getDSSConsoleAccess}>
                <img
                  className="connector-logo-img"
                  src={logoDss}
                  alt="Dataiku DSS"
                  width={15}
                  height={15}
                />
                <span className="text-actions"> Dataiku Access</span>
              </div>
              {this.state.status !== "PENDING_DELETION" && (
                <div
                  className="btn-cart"
                  onClick={() => this.setState({ openDeleteModal: true })}
                >
                  {this.state.openDeleteModal && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-trash fa-spacing" />
                  <span className="text-actions">Delete</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <i className="far fa-calendar-alt fa-spacing" />
            <span className="text-footer-left">
              last update: {formatDate(this.state.updatedat)}
            </span>
          </div>

          <div className="cdh-card-end">
            <button
              disabled={[
                "PENDING_DELETION",
                "DELETE_IN_PROGRESS",
                "DELETE_COMPLETED"
              ].includes(this.state.status)}
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.refreshStatus}
            >
              {this.state.refreshing || this.state.autorefreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
            <div className="text-footer-metrics">
              Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": [
                    "CONNECTION_SUCCESSFUL",
                    "CREATE_COMPLETED"
                  ].includes(this.state.status),
                  "warning-status": this.state.status === "CONNECTION_FAILED",
                  "error-status": [
                    "PENDING_DELETION",
                    "DELETE_IN_PROGRESS",
                    "DELETE_COMPLETED"
                  ].includes(this.state.status)
                })}
              >
                {this.state.status}
              </span>
            </div>
          </div>
        </div>
        {this.state.openDeleteModal && (
          <DeleteObjectWithFrictionModal
            objectName={`Connection ${connection.name}`}
            deleteMessage={"Delete DSS instance connection ?"}
            onApply={() => this.setState({ openDeleteModal: false })}
            onClose={() => this.setState({ openDeleteModal: false })}
            open={() => this.setState({ openDeleteModal: true })}
            deleteFunction={this.deleteConnection}
            isAWSResource={false}
          />
        )}
        {(this.state.error || this.state.errorDelete) && (
          <div className="IAM-error">
            <Alert severity="error">
              {this.state.error || this.state.errorDelete}
            </Alert>
          </div>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartDssProjectConnection)));
