export const getDatasourceAdminCredentials = {
  query: `
  query Q ($datasourceUri: String!) {
    getDatasourceAdminCredentials(datasourceUri: $datasourceUri) {
      AccessKeyId Expiration SecretKey Token ConsoleUrl
    }
  }
`,
  keyOutput: "getDatasourceAdminCredentials"
};
