/* @flow */
import React from "react";
import "./newAccountBlock.less";
import withGoTo from "../../../goToHOC";
import Routes from "../../../routes";

type propTypes = {
  goTo: Function
};

const NewAccountBlock = ({ goTo }: propTypes) => (
  <div className="create-container">
    <div
      className="butn butn-create"
      onClick={() =>
        goTo({
          route: Routes.Account.Create
        })
      }
    >
      <i className="fas fa-plus butn-icon" />
      <span className="butn-text">New Organization</span>
    </div>
  </div>
);

export default withGoTo(NewAccountBlock);
