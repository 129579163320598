export const getDssInstance = {
  query: `
  query Q ($dssInstanceUri: String!) {
    getDssInstance(dssInstanceUri: $dssInstanceUri) {
      uri
      name
      url
      api_key_secret
      description
      tags
      iam_role_arn
      api_key_age
      connection_status
      connection_error
    }
  }
`,
  keyOutput: "getDssInstance"
};
