/* @flow */
import React from "react";
import GraphQl from "../../../../../graphQL";
import withAppSync from "../../../../AppsyncHOC";
import Error from "../../../../../components/error";
import Loading from "../../../../../components/loading";
import Tabs from "../../../../../components/tabs";
import RedshiftClusterGrantAccess from "./redshiftClusterGrantAccess";
import RedshiftClusterGroups from "./redshiftClusterGroups";

type propTypes = {
  redshiftCluster: Object,
  api: GraphQl,
  match: {
    params: {
      clusterUri: string,
      cartUri: string
    },
    url: string
  }
};

type stateTypes = {
  ready: boolean,
  error: Object,
  groups: Array<Object>
};

class RedshiftClusterAccess extends React.PureComponent<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: undefined,
      groups: []
    };
  }

  componentDidMount() {
    this.getRedshiftClusterGroups();
  }

  getRedshiftClusterGroups = () => {
    this.setState({
      ready: false
    });
    this.props.api.analytics
      .getRedshiftClusterGroups(this.props.redshiftCluster.uri)
      .then((groups) => {
        this.setState({
          groups,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });
  };

  render() {
    return (
      <div>
        {this.state.error && (
          <Error error={this.state.error} path={"RedshiftClusterAccess"} />
        )}
        {!this.state.ready && <Loading message={"Redshift cluster access"} />}
        {!this.state.error && this.state.ready && (
          <Tabs
            noBackgroundWhite
            defaultActiveIndex={"groups"}
            tabs={[
              {
                name: (
                  <div>
                    <i className="fas fa-share-alt"></i>
                    Shared with
                  </div>
                ),
                index: "groups",
                route: `${this.props.match.url}#access/groups`,
                content: (
                  <RedshiftClusterGroups
                    redshiftCluster={this.props.redshiftCluster}
                    groups={this.state.groups}
                    reloadGroups={this.getRedshiftClusterGroups}
                  />
                )
              },
              {
                name: (
                  <div>
                    <i className="fas fa-lock-open"></i>
                    Grant Access
                  </div>
                ),
                index: "grant-access",
                route: `${this.props.match.url}#access/grant`,
                content: (
                  <RedshiftClusterGrantAccess
                    redshiftCluster={this.props.redshiftCluster}
                    reloadGroups={this.getRedshiftClusterGroups}
                  />
                )
              }
            ]}
          />
        )}
      </div>
    );
  }
}

export default withAppSync(RedshiftClusterAccess);
