/* @flow */
import React from "react";
import Tabs from "../../../components/tabs";
import DatasourceArchives from "./components/datasourceArchives";
import ProjectArchives from "./components/projectArchives";

type propTypes = {
  uriAccount: string,
  uriPlayground: string
};

class PlaygroundArchives extends React.PureComponent<propTypes> {
  render() {
    return (
      <div>
        <Tabs
          tabs={[
            {
              name: <div>Datasources</div>,
              index: "datasources",
              content: (
                <DatasourceArchives
                  uriPlayground={this.props.uriPlayground}
                  uriAccount={this.props.uriAccount}
                />
              )
            },
            {
              name: <div>Projects</div>,
              index: "projects",
              content: (
                <ProjectArchives
                  uriPlayground={this.props.uriPlayground}
                  uriAccount={this.props.uriAccount}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}

export default PlaygroundArchives;
