/* @flow */

import React from "react";
import Select from "react-select";

type propTypes = {
  placeholder: string,
  options: Array<{ value: string, label: string }>,
  selectedOption:
    | { value: string, label: string }
    | Array<{ value: string, label: string }>,
  onSelectOption: Function,
  isMulti?: boolean
};

const SelectAutocomplete = ({
  placeholder,
  options,
  selectedOption,
  onSelectOption = () => {},
  isMulti = false
}: propTypes) => (
  <Select
    placeholder={placeholder}
    isSearchable
    options={options}
    value={selectedOption}
    onChange={onSelectOption}
    isMulti={isMulti}
  />
);

export default SelectAutocomplete;
