/* @flow */
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import withAppSync from "../../../../AppsyncHOC";
import withGoTo from "../../../../goToHOC";
import Error from "../../../../../components/error";
import GraphQl from "../../../../../graphQL";
import { updateMessage } from "../../../../globalNotifications/actions";
import Loading from "../../../../../components/loading";
import FeatureToggleForm from "./create";
import ToggleButton from "../../../../../components/toggleButton";
import "./style.less";
import Paginator from "../../../../../components/paginator";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  toggles: Array<Object>,
  ready: boolean,
  error: ?Object,
  showCreateForm: boolean,
  isFetchingToggles: boolean
};

class FeatureToggles extends React.PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      toggles: [],
      ready: false,
      isFetchingToggles: false,
      showCreateForm: false,
      error: undefined
    };
  }

  componentDidMount() {
    this.listFeatureToggles();
  }

  listFeatureToggles = (offset = 0) => {
    this.setState({ isFetchingToggles: true });
    return this.props.api.featureToggle
      .listFeatureToggles({}, { offset, limit: 10 })
      .then((toggles) => {
        this.setState({
          isFetchingToggles: false,
          toggles,
          showCreateForm: false,
          ready: true
        });
        return toggles;
      })
      .catch((error) => {
        this.setState({
          isFetchingToggles: false,
          error
        });
        return [];
      });
  };

  manageFeatureToggle = (toggle) => {
    if (toggle.status === "ENABLED") {
      this.disableFeatureToggle(toggle);
      return false;
    }

    this.enableFeatureToggle(toggle);
    return true;
  };

  enableFeatureToggle = (toggle) => {
    this.setState({ ready: false });
    this.props.api.featureToggle
      .enableFeatureToggle(toggle.id)
      .then(() =>
        this.props.showGlobalNotification({
          message: `${
            toggle.groupname === "null" ? toggle.gid : toggle.groupname
          } enabled !`,
          type: "success"
        })
      )
      .then(() => this.listFeatureToggles())
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  disableFeatureToggle = (toggle) => {
    this.setState({ ready: false });
    this.props.api.featureToggle
      .disableFeatureToggle(toggle.id)
      .then(() =>
        this.props.showGlobalNotification({
          message: `${
            toggle.groupname === "null" ? toggle.gid : toggle.groupname
          } disabled !`,
          type: "success"
        })
      )
      .then(() => this.listFeatureToggles())
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  deleteFeatureToggle = (toggle) => {
    this.setState({ ready: false });
    this.props.api.featureToggle
      .deleteFeatureToggle(toggle.id)
      .then(() =>
        this.props.showGlobalNotification({
          message: `${
            toggle.groupname === "null" ? toggle.gid : toggle.groupname
          } deleted !`,
          type: "success"
        })
      )
      .then(() => this.listFeatureToggles())
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  handleCreationForm = () => {
    this.setState((prevState) => ({
      showCreateForm: !prevState.showCreateForm
    }));
  };

  render() {
    return (
      <div className="list-toggles">
        <div className="toggles-actions">
          <div
            className="butn butn-flat"
            onClick={this.handleCreationForm}
            style={{ marginRight: "5px" }}
          >
            <i className="fas fa-arrow-circle-down" />
            <span className="butn-text">Create</span>
          </div>
        </div>
        {this.state.showCreateForm && (
          <div>
            <FeatureToggleForm
              reloadToggles={() => this.listFeatureToggles()}
            />
          </div>
        )}
        <div className="mt-2">
          {this.state.error && (
            <Error
              stringOnly
              error={this.state.error}
              path={"FeatureToggles"}
            />
          )}

          {!this.state.ready ? (
            <Loading message={"Feature Toggles"} />
          ) : (
            <Paginator
              key="paginator-features-toggles"
              isLoadingPage={!this.state.ready || this.state.isFetchingToggles}
              container={(content) => (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-env" scope="col">
                        CODE
                      </th>
                      <th className="col-env" scope="col">
                        ORG-GROUP
                      </th>
                      <th className="col-env" scope="col">
                        USER
                      </th>
                      <th className="col-env" scope="col">
                        STATUS
                      </th>
                      <th className="col-env table-action-column" scope="col">
                        MANAGE
                      </th>
                      <th className="col-env table-action-column" scope="col">
                        DELETE
                      </th>
                    </tr>
                  </thead>
                  <tbody>{content}</tbody>
                </table>
              )}
              limit={10}
              initialOffset={0}
              list={this.state.toggles}
              componentRender={(toggle) => (
                <tr className="table-row">
                  <td className="table-env-column">
                    <b>{toggle.code}</b>
                  </td>
                  <td className="table-env-column">
                    {toggle.groupname === "null" ? "-" : toggle.groupname}
                  </td>
                  <td className="table-env-column">
                    {toggle.gid === "null" ? "-" : toggle.gid}
                  </td>
                  <td className="table-env-column">
                    <span
                      className={classnames("status-row", {
                        "color-green-status": toggle.status === "ENABLED",
                        "color-error-status": toggle.status === "DISABLED"
                      })}
                    >
                      {toggle.status || "-"}
                    </span>
                  </td>
                  <td className="table-action-column">
                    <ToggleButton
                      id={toggle.id}
                      checkedValue={toggle.status === "ENABLED"}
                      onChange={() => this.manageFeatureToggle(toggle)}
                    />
                  </td>
                  <td className="table-action-column">
                    <i
                      onClick={() => this.deleteFeatureToggle(toggle)}
                      className="far fa-trash-alt"
                    />
                  </td>
                </tr>
              )}
              loadPage={this.listFeatureToggles}
              onNoResult={() => "You dont have any share."}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(FeatureToggles)));
