export const getRecentUserHistory = {
  query: `query Q ($options: QueryOptions) {
    getRecentUserHistory(options: $options) {
        activityid 
        name
        description
        createdat 
        accountid
    }
  }
`,
  keyOutput: "getRecentUserHistory"
};
