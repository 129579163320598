/* @flow */

import React, { Component } from "react";
import withAppSync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import routes from "../../../routes";
import "./style.less";
import RSNamespaceItem from "./RSNamespaceItem";

type propTypes = {
  uriAccount: string,
  environmentUri: string,
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  namespaces: Array<Object>,
  platform: Object,
  ready: boolean,
  error: ?Object
};

class RSNamespaces extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      platform: null,
      namespaces: [],
      ready: false,
      error: null
    };
  }

  componentDidMount() {
    this.getEnvironmentRSNamespaces();
  }

  getEnvironmentRSNamespaces = () => {
    this.setState({
      ready: false
    });
    this.props.api.analytics
      .listEnvironmentRSNamespace(
        this.props.uriAccount,
        this.props.environmentUri
      )
      .then((namspaces) => {
        this.setState({
          ready: true,
          namespaces: namspaces.results
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    const { uriAccount, environmentUri } = this.props;
    const { namespaces, error, ready, platform } = this.state;
    if (error) return <Error error={error} path={"RedshiftSeverlessList"} />;
    if (!ready) return <Loading message="RedshiftServerlessNamespaces" />;
    return (
      <div className={"analytics-container"}>
        <div className="analytics-actions">
          <div
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Playground.CreateRSNamespace,
                params: {
                  uriAccount,
                  uriPlayground: environmentUri
                }
              })
            }
            style={{ marginRight: "5px" }}
          >
            <i className="fas fa-plus"></i>
            <span className="butn-text">Create</span>
          </div>
        </div>
        <div className="card-container">
          {namespaces && namespaces.length > 0 ? (
            namespaces.map((namespace) => (
              <RSNamespaceItem
                key={namespace.uri}
                namespace={namespace}
                reloadNamespaces={this.getEnvironmentRSNamespaces}
              />
            ))
          ) : (
            <div>No Redshift Serverless namespaces to display.</div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppSync(RSNamespaces);
