export const listMyCartsGroupNames = {
  query: `query Q ($options:QueryOptions) {
        listMyCartsGroupNamesUris(options: $options) {
            results {
                name
                uri
            }
            total
        }
      }
    `,
  keyOutput: "listMyCartsGroupNamesUris"
};
