/* @flow */
import React from "react";
import "../view.less";
import GraphQl from "../../../../graphQL";
import Error from "../../../../components/error";
import CartDssProjectConnection from "./CartDssProjectConnection";
import CartDssConnectionCreate from "./CartDssConnectionCreate";
import Loading from "../../../../components/loading";
import Logger from "../../../../utils/logger";

const Log = Logger("CartList");

type propTypes = {
  cart: Object,
  api: GraphQl,
  platforms: Array<Object>,
  dssInstances: Array<Object>
};

type stateTypes = {
  ready: boolean,
  error: Object,
  openCreateDialog: boolean,
  connections: Array<Object>,
  datasources: Array<Object>
};
class CartDssProjectConnections extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: null,
      openCreateDialog: false,
      connections: [],
      datasources: [],
      ready: false
    };
  }

  componentDidMount() {
    this.listAllMyDatassources();
    this.getConnections();
  }

  getConnections = () => {
    this.setState({
      ready: false
    });
    this.props.api.dssInstance
      .listProjectConnections(this.props.cart.uri)
      .then((connections) => {
        this.setState({
          ready: true,
          connections
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  listAllMyDatassources = (offset: number = 0) => {
    const options = {
      limit: 1000,
      offset
    };
    return this.props.api.datasource
      .listAllMyDatasources(options)
      .then((datasources) => {
        const filteredDatasources = datasources.filter(
          (d) => d.owner.uri === this.props.cart.groupuri
        );
        this.setState({
          datasources: filteredDatasources
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
        Log.error(error);
      });
  };

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} path={"DSSnConnections"} />;
    }
    if (!this.state.ready) return <Loading message="Dataiku DSS Connections" />;
    return (
      <div className={"environments-container"}>
        <div>
          <div
            className="butn butn-flat"
            onClick={() => this.setState({ openCreateDialog: true })}
          >
            <i className="fas fa-plus butn-icon" />
            <span className="butn-text">New Dataiku DSS Connection</span>
          </div>
        </div>
        {this.state.error && (
          <div className="mt-3">
            <Error
              error={this.state.error}
              path={"CartDssInstances"}
              stringOnly
            />
          </div>
        )}
        <div className={"card-container"}>
          {this.state.connections.length <= 0 && (
            <div style={{ marginLeft: "15px" }}>
              No Dataiku DSS connections to display.
            </div>
          )}
          {this.state.connections.map((connection) => (
            <CartDssProjectConnection
              key={`${this.props.cart.uri}.${connection.id}`}
              api={this.props.api}
              connection={connection}
              reloadConnections={this.getConnections}
            />
          ))}
        </div>
        {this.state.openCreateDialog && (
          <CartDssConnectionCreate
            api={this.props.api}
            cart={this.props.cart}
            dssInstances={this.props.dssInstances}
            platforms={this.props.platforms}
            datasources={this.state.datasources}
            open={this.state.openCreateDialog}
            onClose={() => this.setState({ openCreateDialog: false })}
            reloadConnections={this.getConnections}
          />
        )}
      </div>
    );
  }
}

export default CartDssProjectConnections;
