/* @flow */

import React, { Component } from "react";
import withAppsync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import routes from "../../../routes";
import Paginator from "../../../../components/paginator";
import DatabrewDetails from "./DatabrewDetails";
import "./listDatabrew.less";

type propTypes = {
  cart: Object,
  warningMessage: any,
  isDisabled: boolean,
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  listDatabrews: Array<Object>,
  isFetchingDatabrews: boolean,
  error: ?Object,
  total: number,
  offset: number
};

class ListDatabrews extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      listDatabrews: [],
      isFetchingDatabrews: false,
      total: 0,
      offset: 0,
      error: null
    };
  }

  componentDidMount() {
    this.getDatabrewProjects();
  }

  getDatabrewProjects = () => {
    this.setState({
      isFetchingDatabrews: true
    });
    const options = {
      limit: 4,
      offset: this.state.offset,
      search: undefined
    };

    return this.props.api.cart
      .listDatabrewProjects(this.props.cart.uri, options)
      .then((response) => {
        if (!response) {
          this.setState({ isFetchingDatabrews: false });
          return false;
        }
        this.setState({
          isFetchingDatabrews: false,
          total: response.total,
          listDatabrews: response.results
        });
        return response.results;
      })
      .catch((error) => {
        this.setState({
          isFetchingDatabrews: false,
          error
        });
      });
  };

  getDatabrewProjectsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.getDatabrewProjects().then(r))
    );

  render() {
    const { cart } = this.props;

    const { warningMessage, isDisabled } = this.props;
    const disableClass = isDisabled ? " btn-disable" : "";

    return (
      <div className={"databrews-container"}>
        {isDisabled && warningMessage}
        <div className={disableClass}>
          <div
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Cart.CreateDatabrewProject,
                params: {
                  cartUri: cart.uri
                },
                state: {
                  cart
                }
              })
            }
          >
            <i className="fas fa-plus"></i>
            <span className="butn-text">New Databrew Project</span>
          </div>
        </div>
        <div className={`card-container ${disableClass}`}>
          {this.state.error && (
            <Error error={this.state.error} path={"DatabrewProject"} />
          )}
          <ul>
            <Paginator
              isLoadingPage={this.state.isFetchingDatabrews}
              loadingMessage={<Loading message="Databrew Projects" />}
              limit={4}
              initialOffset={0}
              list={this.state.listDatabrews}
              componentRender={(databrew) => (
                <DatabrewDetails
                  key={databrew.id}
                  cart={this.props.cart}
                  databrew={databrew}
                  reload={this.getDatabrewProjects}
                />
              )}
              loadPage={this.getDatabrewProjectsFromPagination}
              totalCount={this.state.total}
              onNoResult={() => <div>No Databrews Project to display.</div>}
            />
          </ul>
        </div>
      </div>
    );
  }
}

export default withAppsync(ListDatabrews);
