/* @flow */
import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import ToggleButton from "../../../../components/toggleButton";
import HelpOn from "../../../../components/helpOn";
import "./style.less";
import { updateMessage } from "../../../globalNotifications/actions";

const Log = Logger("ToggleBasicMetadataDisplay");

type propTypes = {
  dataset: Object,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateType = {
  isOpen: boolean,
  isFetching: boolean
};

class ToggleBasicMetadataDisplay extends React.Component<propTypes, stateType> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: !!this.props.dataset.basic_metadata,
      isFetching: false
    };
  }

  handleOnChange = () => {
    if (!this.state.isFetching) {
      this.setState(
        (prevState) => ({ isFetching: true, isOpen: !prevState.isOpen }),
        () => {
          this.props.api.dataset
            .update(this.props.dataset.uri, {
              basic_metadata: this.state.isOpen
            })
            .then(() => {
              this.props.showGlobalNotification({
                message: "Display metadata change",
                type: "success"
              });
              this.setState({ isFetching: false });
            })
            .catch((error) => {
              Log.error(error);
              this.props.showGlobalNotification({
                message: "Display metadata failed",
                type: "alert"
              });
              this.setState((prevState) => ({
                isFetching: false,
                isOpen: !prevState.isOpen
              }));
            });
        }
      );
    }
  };

  render() {
    return (
      <div className="basic-metadata-display">
        <div className="basic-metadata-display-left">
          <div style={{ position: "relative", top: "3px" }}>
            <HelpOn
              content={
                <div>
                  <h3>{"Basic metadata display"}</h3>
                  <div>
                    <b>{"Activated:"}</b>
                  </div>
                  <div>
                    {
                      "Displays an overview of the dataset structure and its quality (metadata visibility)"
                    }
                  </div>
                  <div>{""}</div>
                  <div>
                    <b>{"Deactivated:"}</b>
                  </div>
                  <div>
                    {
                      "Displays a limited access to the metadata for a CDH user with no access to the dataset."
                    }
                  </div>
                  <div>
                    {
                      "(visibility limited to Field name, Field Type and Field Completeness analysis)"
                    }
                  </div>
                </div>
              }
            />
          </div>
          <span className="label-metadata">Basic Metadata</span>
        </div>
        <ToggleButton
          id="Basic_Metadata"
          checkedValue={this.state.isOpen}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(ToggleBasicMetadataDisplay));
