/* @flow */
import React from "react";
import withGoTo from "../../goToHOC";
import stringcutter from "../../../components/stringcutter";
import Links from "../../links";

type propTypes = {
  uri: string,
  accountid: string,
  name: string
};

const DatasourceCardTitle = ({ name, uri, accountid }: propTypes) => (
  <div className={"dataset-card-name"}>
    <Links.Datasource.View uriAccount={accountid} uriDatasource={uri}>
      {stringcutter(name, 50)}
    </Links.Datasource.View>
  </div>
);

DatasourceCardTitle.defaultProps = {
  name: "-"
};

export default withGoTo(DatasourceCardTitle);
