/* @flow */

export const getCollibraDatasets = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      collibraDatasets{
        id
        dataset_uri
        asset_id
        asset_url
        collibra_link_id
        domain_id
        status
        type_id
        updatedat
        collibra_link{
         domain_id
         domain_name
         auto_publish
        }
      }
    }
  }
`,
  keyOutput: "getDataset.collibraDatasets"
};
