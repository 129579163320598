/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import stringcutter from "../../components/stringcutter";
import "./managePolicies.less";
import GraphQl from "../../graphQL";

type propTypes = {
  api: GraphQl,
  cartServices: Array<Object>,
  match: {
    params: {
      cartUri: string,
      uriPlayground: string
    }
  },
  location: {
    state: Object
  },
  showGlobalNotification: Function
};

type stateTypes = {
  error: boolean | Object,
  isSubmitting: boolean,
  hasChanged: boolean,
  services: Array<Object>
};
class CartServices extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      hasChanged: false,
      isSubmitting: false,
      services: this.props.cartServices
    };
  }

  handleChange = (id, value) => {
    this.setState((prevState) => {
      const services = Object.assign([], prevState.services);
      const index = services.findIndex((service) => service.id === id);
      if (index) {
        services[index].active = value;
      }
      return { services, hasChanged: true };
    });
  };

  disableValidation = () => {
    if (this.state.hasChanged) {
      return false;
    }

    return true;
  };

  activateServices = () => {
    const includedServices = this.state.services.filter(
      (service) => service.active === true && !service.mandatory
    );
    const includedServicesName = includedServices.map(
      (service) => service.name
    );
    if (includedServicesName.length > 0) {
      return this.props.api.cart
        .includeCartServices(
          this.props.match.params.cartUri,
          includedServicesName
        )
        .then(() => true)
        .catch((error) => {
          this.setState({ error });
          return false;
        });
    }
    return true;
  };

  desactivateServices = () => {
    const excludedServices = this.state.services.filter(
      (service) => service.active === false
    );
    const excludeServicesName = excludedServices.map((service) => service.name);
    if (excludeServicesName.length > 0) {
      return this.props.api.cart
        .excludeCartServices(
          this.props.match.params.cartUri,
          excludeServicesName
        )
        .then(() => true)
        .catch((error) => {
          this.setState({ error });
          return false;
        });
    }
    return true;
  };

  applySelection = () => {
    const a = this.activateServices();
    const b = this.desactivateServices();
    return Promise.all([a, b])
      .then(() => {
        this.props.showGlobalNotification({
          message: "Services updated",
          type: "success"
        });
      })
      .catch(() => {
        this.props.showGlobalNotification({
          message: "Failed to update services",
          type: "alert"
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"Services"} />;

    return (
      <React.Fragment>
        {this.state.services && (
          <React.Fragment>
            <div className="action-services">
              <button
                type="button"
                className="butn"
                onClick={() => this.applySelection()}
                disabled={this.disableValidation()}
              >
                {this.state.isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-plus-circle fa-spacing" />
                Apply Selection
              </button>
            </div>
            <div className="list-policies-cart mt-2">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-check" scope="col" />
                    <th className="col-policy-arn" scope="col">
                      NAME
                    </th>
                    <th className="" scope="col">
                      DESCRIPTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.services.map((service) => {
                    if (service.name !== "EMR") {
                      return (
                        <tr key={service.id} className="table-row">
                          <td>
                            <input
                              id={service.id}
                              type="checkbox"
                              className={"checkbox-facet"}
                              checked={service.active}
                              disabled={service.mandatory}
                              onChange={(event) =>
                                this.handleChange(
                                  service.id,
                                  event.target.checked
                                )
                              }
                            />
                            <label htmlFor={service.id} className="fas">
                              <span className="labelCustom" />
                            </label>
                          </td>
                          <td
                            className={classnames(
                              "table-arn-column text-uppercase",
                              { "is-disabled": service.mandatory }
                            )}
                          >
                            {service.name}
                          </td>
                          <td className="table-desc-column">
                            {stringcutter(service.description, 100)}
                          </td>
                        </tr>
                      );
                    }
                    return "";
                  })}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(CartServices));
