/* @flow */

export const createDataFilter = {
  query: `mutation Q ($datasetUri: String!, $input: NewDataFilter!) {
        createDataFilter(datasetUri: $datasetUri, inputData: $input) {
            id
            name
            resource_name
            creator
            status
            dataset {
                uri
                name
            }
            stage {
                id
                name
            }
            createdat
            updatedat
            description
            database_name
            table_name
            columns
            column_level_access
            row_filter_expression
            region
            aws
        }
      }
    `,
  keyOutput: "createDataFilter"
};
