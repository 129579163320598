import React from "react";
import PropTypes from "prop-types";
import config from "../../config";

const TermsConditions = ({ baseUrl }) => (
  <ul className="termsConditions">
    <li>
      <h3>Taxation</h3>
      <p>
        As long as there is not any defined business model nor value assessment,
        and provided that legal regulations are respected, entities can share
        data in a prospective approach to identify new use cases.
      </p>
      <p>
        If you already have a formalized business model, please contact the
        target BU / entity (that consumes data) to agree on a data transfer
        price.
      </p>
    </li>
    <li>
      <h3>Privacy</h3>
      <p>
        Vision from the entity source that owns the Dataset: By default, we
        recommend you to not share personal data (
        <a
          href={`${baseUrl}/resources/terms-conditions/Data@Engie - Compliance - Personal Data Definition.pdf`}
        >
          One Data Team - Compliance - Personal Data Definition
        </a>
        &nbsp;or contact your local DPM / DPO in case of any doubts).
      </p>
      <p>
        If you feel that dataset needs to be shared, please make sure to
        anonymize it with the recommended tool to be implemented in the CDH.
      </p>
      <p>
        If you think that personal data needs to be kept normative in the
        Dataset: The DPO / DPM of your entity accountable for this data has to
        ensure that sharing the Dataset is compliant with the user request.
      </p>
      <p>
        Vision from the user that consumes the Dataset: At the time of
        retrieving the Dataset, you need to make sure that your BU has
        associated a DPO / DPM to this dataset and you need to follow any
        recommendation the DPO/DPM could issue.
      </p>
      <p>Please refer to available documents on Privacy:</p>
      <ul>
        <li>
          <a
            href={`${baseUrl}/resources/terms-conditions/Data@Engie - FAQ Compliance & Legal - Privacy.VF.pdf`}
          >
            FAQ (One Data Team - FAQ Compliance & Legal - Privacy)
          </a>
        </li>
        <li>
          <a
            href={`${baseUrl}/resources/terms-conditions/DPM (Data Privacy Manager) Directory.pdf`}
          >
            DPM repository
          </a>
        </li>
        <li>
          Group Privacy Policy&nbsp;(
          <a
            href={`${baseUrl}/resources/terms-conditions/Group Data Privacy Policy_ 2017_FR_Version.pdf`}
          >
            FR
          </a>
          &nbsp;-&nbsp;
          <a
            href={`${baseUrl}/resources/terms-conditions/Group Data Privacy Policy_ 2017_EN_Version.pdf`}
          >
            EN
          </a>
          )
        </li>
        <li>
          BCR&nbsp;(
          <a
            href={`${baseUrl}/resources/terms-conditions/BCR (Binding Corporate Rules)_FR_Version.pdf`}
          >
            FR
          </a>
          &nbsp;-&nbsp;
          <a
            href={`${baseUrl}/resources/terms-conditions/BCR (Binding Corporate Rules)_EN_Version.pdf`}
          >
            EN
          </a>
          )
        </li>
        <li>
          DPIA&nbsp;(
          <a
            href={`${baseUrl}/resources/terms-conditions/Data Protection Impact Assessment (DPIA) - Guidelines.pdf`}
          >
            Guidelines
          </a>
          &nbsp;-&nbsp;
          <a
            href={`${baseUrl}/resources/terms-conditions/CNIL - Operations requiring DPIA.pdf`}
          >
            CNIL
          </a>
          )
        </li>
      </ul>
      <br></br>
    </li>
    <li>
      <h3>Intellectual Property (IP)</h3>
      <p>
        The free access / access to a Dataset with a poor transfer price can
        lead to a responsibility disengagement of the BU source regarding the
        future Dataset usage. In case of any doubt,&nbsp;
        <a
          href={`${baseUrl}/resources/terms-conditions/Data@ENGIE_QuestionnaireIP_ VF_EN.pdf`}
        >
          please refer to the IP questionnaire
        </a>
        , contact the Group NewCorp Legal Direction, and if necessary contact
        the BU consuming this data to formalize an agreement related to the
        dataset guarantee, responsibility and IP ( except costs related to the
        Cloud).
      </p>
    </li>
    <li>
      <h3>Embargo</h3>
      <p>
        Any data to be posted on the CDH shall comply with our embargo group
        policy: Any data related to countries listed in the black list shall be
        strictly banned (Iran, Syria, North Korea, Sudan and Cuba) and any data
        related to countries listed in the red list (Please refer to the Embargo
        Group Policy&nbsp;
        <a
          href={`${baseUrl}/resources/terms-conditions/EMBARGO countries policy.pdf`}
        >
          FR
        </a>
        &nbsp;-&nbsp;
        <a
          href={`${baseUrl}/resources/terms-conditions/ENGIE Embargo Policy-EN4_221118 - 2018.pdf`}
        >
          EN
        </a>
        ) &nbsp;shall be strictly subject to previous approval of the legal line
        (Please contact your legal counsel).
      </p>
      <p>
        Control Export: Any data related to the following items services and
        technologies shall be strictly subject to the previous approval of the
        legal line (For any question:&nbsp;
        <a href="mailto:seyrade.derudelle@engie.com">
          Please contact the legal department – Group Ethics & Compliance
        </a>
        ): dual use, military, aerospace and communication.
      </p>
    </li>
    <li>
      <h3>Competition Law</h3>
      <p>
        You need to ensure that the dataset access / sharing does not represent
        a competitive advantage not compliant with the Competition law.{" "}
        <a href={`${config.HELP_CENTER}`}>
          {" "}
          Please refer to the available FAQ on Competition Law
        </a>
        .
      </p>
    </li>
    <li>
      <h3>More information about Data Privacy</h3>
      <p>
        <a href="https://www.yammer.com/engie.com/#/Threads/show?threadId=223865884590080&search_origin=global&scoring=linear1Y-prankie-group-private-higher&match=any-exact&search_sort=relevance&page=1&search=personal%20data%20is%20crucial">
          On Yammer
        </a>
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=-6s3d-IEXKM&feature=youtu.be">
          On Youtube
        </a>
      </p>
    </li>
  </ul>
);

TermsConditions.propTypes = {
  baseUrl: PropTypes.string
};

TermsConditions.defaultProps = {
  baseUrl: "https://help.commondatahub.engie.com/resources"
};

export default TermsConditions;
