import React from "react";
import classnames from "classnames";

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
type propTypes = {
  maxSlots?: any,
  selectedSlots: number
};

class PolicyCounter extends React.Component<propTypes> {
  reachMax() {
    return (
      !!this.props.maxSlots && this.props.selectedSlots > this.props.maxSlots
    );
  }

  render() {
    return (
      <div className="policy-counter">
        <div className="mb-3">
          {this.props.maxSlots || this.props.maxSlots >= 0 ? (
            <span>
              You can add {this.props.maxSlots} policies maximum on your current
              project.
            </span>
          ) : (
            <span>
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
              We are calculating the maximum policies...
            </span>
          )}
        </div>
        <span
          className={classnames("policy-counter-count", {
            "policy-counter-count-error": this.reachMax()
          })}
        >
          {this.props.selectedSlots}
        </span>
        <span> Selected policies.</span>
        {this.reachMax() && (
          <span className="policy-counter-error-message">
            Maximum selected policies reached.
          </span>
        )}
      </div>
    );
  }
}

export default PolicyCounter;
