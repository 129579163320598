import { LOGGER_LEVEL } from "../utils/constants";

let conf;

switch (process.env.NODE_ENV) {
  case "staging":
    conf = {
      OKTA_URL: "https://dev-545796.oktapreview.com",
      OKTA_CLIENT_ID: "0oafkkiqzzVq03zh00h7",
      OKTA_REDIRECT_URI:
        "https://staging.commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT: "https://api.staging.commondatahub.engie.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.INFO,
      HELP_CENTER: "https://help.sandbox.commondatahub.engie.com"
    };
    break;
  case "preprod":
    conf = {
      OKTA_URL: "https://dev-545796.oktapreview.com",
      OKTA_CLIENT_ID: "0oafkkiqzzVq03zh00h7",
      OKTA_REDIRECT_URI:
        "https://preprod.commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT: "https://api.preprod.commondatahub.engie.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.WARNING,
      HELP_CENTER: "https://help.sandbox.commondatahub.engie.com"
    };
    break;
  case "lakeformation":
    conf = {
      OKTA_URL: "https://dev-545796.oktapreview.com",
      OKTA_CLIENT_ID: "0oafkkiqzzVq03zh00h7",
      OKTA_REDIRECT_URI:
        "https://testing.commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT:
        "https://wpdfucuqmzbbtohyety2alujia.appsync-api.eu-west-1.amazonaws.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.WARNING,
      HELP_CENTER: "https://help.sandbox.commondatahub.engie.com"
    };
    break;
  case "sandbox":
    conf = {
      OKTA_URL: "https://engie.okta-emea.com",
      OKTA_CLIENT_ID: "0oa2089fiyJusZ0Sn0i7",
      OKTA_REDIRECT_URI:
        "https://sandbox.commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT: "https://api.sandbox.commondatahub.engie.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.ERROR,
      HELP_CENTER: "https://help.sandbox.commondatahub.engie.com"
    };
    break;
  case "drp":
    conf = {
      OKTA_URL: "https://engie.okta-emea.com",
      OKTA_CLIENT_ID: "0oa61qdsl0BtI0Oib0i7",
      OKTA_REDIRECT_URI:
        "https://drp.commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT: "https://api.drp.commondatahub.engie.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.ERROR,
      HELP_CENTER: "https://help.drp.commondatahub.engie.com"
    };
    break;
  case "production":
    conf = {
      OKTA_URL: "https://engie.okta-emea.com",
      OKTA_CLIENT_ID: "0oa2089fiyJusZ0Sn0i7",
      OKTA_REDIRECT_URI: "https://commondatahub.engie.com/implicit/callback",
      APPSYNC_ENDPOINT: "https://api.commondatahub.engie.com/graphql",
      LOGGER_LEVEL: LOGGER_LEVEL.ERROR
    };
    break;
  case "developer":
  default:
    conf = {
      OKTA_URL: "https://dev-545796.oktapreview.com",
      OKTA_CLIENT_ID: "0oafkkiqzzVq03zh00h7",
      OKTA_REDIRECT_URI: "http://localhost:1234/implicit/callback",
      // APPSYNC_ENDPOINT: 'https://wpdfucuqmzbbtohyety2alujia.appsync-api.eu-west-1.amazonaws.com/graphql', // Lakeformation
      APPSYNC_ENDPOINT: "https://api.preprod.commondatahub.engie.com/graphql", // PREPROD
      // APPSYNC_ENDPOINT: 'https://api.staging.commondatahub.engie.com/graphql', // STAGING
      // APPSYNC_ENDPOINT: 'https://api-skh.developer.commondatahub.engie.com/graphql', // Sohaib
      // APPSYNC_ENDPOINT: 'https://api-mhlo.developer.commondatahub.engie.com/graphql', // Hervé
      // APPSYNC_ENDPOINT: 'https://api-haz.developer.commondatahub.engie.com/graphql', // Hecham
      // APPSYNC_ENDPOINT: 'https://api-mha.developer.commondatahub.engie.com/graphql', // Mohamed
      //APPSYNC_ENDPOINT: "https://api-msa.developer.commondatahub.engie.com/graphql", // Mohamed S
      // APPSYNC_ENDPOINT: 'https://api-ala.developer.commondatahub.engie.com/graphql', // Anthony
      // APPSYNC_ENDPOINT:
      //   "https://api-yel2.developer.commondatahub.engie.com/graphql", // Younes
      // APPSYNC_ENDPOINT:
      //   "https://api-abe.developer.commondatahub.engie.com/graphql", // Alexandre
      LOGGER_LEVEL: LOGGER_LEVEL.VERBOSE,
      HELP_CENTER: "https://help.sandbox.commondatahub.engie.com"
    };
    break;
}

export default {
  JIRA_DESK_URL:
    "https://jira.tools.digital.engie.com/servicedesk/customer/portal/8/group/216",
  HELP_CENTER: "https://help.commondatahub.engie.com",
  HELP_CENTER_ENDPOINT:
    "https://confluence.tools.digital.engie.com/pages/viewpage.action?pageId=144337818#PLAYProject(F5F6F7F8F14)-Endpoints",
  ...conf
};
