export const getProjectRedshiftClusters = {
  query: `query Q ($cartUri:String!) {
        getCart (cartUri:$cartUri) {
            redshiftClusters{
              uri
              cluster_arn
              aws
              cluster_name
              description
              resource_name
              database_name
              createdat
              owneruri
              region
              updatedat
              status
              subnet_group_name
              master_username
              endpoint_address
              endpoint_port
              public_key
              iam_roles
              number_of_nodes
              vpc_security_groups
              node_type
              credentials_expiration_date
              redshift_attached_to_powerbi_status
              platform {
                uri
                aws
                environment
                name
              }
            }
        }
      }
    `,
  keyOutput: "getCart.redshiftClusters"
};
