/* @flow */

export const listAccounts = {
  query: `query Q {
        listAccounts {
            uri
            accountid
            name
            creator{
                name
            }
            engieEntities {
                aws_account
                org_id
                scope_id
                scope
                sub_scope
                gbus
                name
            }
       }
      }
    `,
  keyOutput: "listAccounts"
};

type IdentityPropsTypes = {
  name: string
};

export type listAccountsPropsTypes = {
  uri: string,
  accountid: string,
  name: string,
  creator: IdentityPropsTypes,
  gbu: Object
};
