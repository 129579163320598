export const listEnvironmentGroups = {
  query: `query Q ($platformUri:String!, , $options: QueryOptions) {
        listPlatformGroups(platformUri: $platformUri, options: $options) {
            total
            offset
            limit
            results {
                uri
                name
                description
            }
        }
      }
    `,
  keyOutput: "listPlatformGroups"
};
