/* @flow */

import React from "react";
import "./styles.less";
import conf from "../../config";

const onReportClick = () => {
  window.open(conf.JIRA_DESK_URL, "_blank");
};

const ReportProblemButton = () => (
  <div className="report-problem-container" onClick={onReportClick}>
    <i className="far fa-comment-dots" />
    <div className="report-problem-content">Contact us on our service desk</div>
  </div>
);

export default ReportProblemButton;
