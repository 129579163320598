/* @flow */

export const getDatasetBookmark = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      is_bookmarked
      access
    }
  }
`,
  keyOutput: "getDataset"
};
