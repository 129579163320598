/* @flow */

export const listDataFilters = {
  query: `query Q ($datasetUri: String!, $stageId: String, $options: QueryOptions) {
        listDataFilters(datasetUri: $datasetUri, stageId: $stageId, options: $options) {
            results {
                id
                name
                resource_name
                creator
                status
                dataset {
                    uri
                    name
                }
                stage {
                    id
                    name
                }
                createdat
                updatedat
                description
                database_name
                table_name
                all_columns
                columns
                column_level_access
                row_filter_expression
                row_filter_expression_simple_mode
                region
                aws
                shares {
                    id
                    group{
                        name
                    }
                }
            }
            total
            offset
            limit
        }
      }
    `,
  keyOutput: "listDataFilters"
};
