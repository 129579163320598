/* @flow */
import React from "react";
import withAppSync from "../../AppsyncHOC";
import Breadcrumb from "../../../components/breadcrumb";
import GraphQl from "../../../graphQL";
import Tabs from "../../../components/tabs";
import PlaygroundAnalytics from "./analytics";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import PlaygroundNetwork from "./network";
import PlaygroundNotebooks from "./notebooks";
import PlaygroundLakeFormation from "./lakeformation";
import PlaygroundAccelerationTemplate from "./accelerationTemplate";
import HelpOn from "../../../components/helpOn";
import PlaygroundArchives from "./archives";
import Domains from "../../smstudio/domain/Domains";
import DbxWorkspacesTab from "../../databricks/workspace/dbx-workspaces-tab";

type propTypes = {
  api: GraphQl,
  goTo: Function,
  match: {
    path: string,
    url: string,
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  }
};

type stateTypes = {
  playground: Object,
  ready: boolean,
  error: ?Object,
  showArchives: boolean
};

class PlaygroundAdmin extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      playground: undefined,
      ready: false,
      error: null,
      showArchives: false
    };
  }

  componentDidMount() {
    this.isDetachForEquansFeatureEnabled();
    this.getPlayground();
  }

  isDetachForEquansFeatureEnabled() {
    return this.props.api.featureToggle
      .isFeatureToggleEnabled(
        "DETACH_FOR_EQUANS",
        this.props.match.params.uriAccount
      )
      .then((showArchives) => {
        this.setState({ showArchives });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getPlayground() {
    return this.props.api.playground
      .getName(
        this.props.match.params.uriAccount,
        this.props.match.params.uriPlayground
      )
      .then((response) => {
        this.setState({
          ready: true,
          playground: response.playground
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getTabs = () => {
    const tabs = [
      {
        name: "Networks",
        index: "networks",
        route: `${this.props.match.url}#networks`,
        content: (
          <PlaygroundNetwork
            uriAccount={this.props.match.params.uriAccount}
            uriPlayground={this.props.match.params.uriPlayground}
            goTo={this.props.goTo}
          />
        )
      },
      {
        name: "Notebooks",
        index: "notebooks",
        route: `${this.props.match.url}#notebooks`,
        content: <PlaygroundNotebooks playground={this.state.playground} />
      },
      {
        name: "Analytics",
        index: "analytics",
        route: `${this.props.match.url}#analytics`,
        content: (
          <PlaygroundAnalytics
            uriAccount={this.props.match.params.uriAccount}
            uriPlayground={this.props.match.params.uriPlayground}
            goTo={this.props.goTo}
          />
        )
      }
    ];

    tabs.push({
      name: "Lake Formation",
      index: "lakeformation",
      route: `${this.props.match.url}#lakeformation`,
      content: (
        <PlaygroundLakeFormation
          uriAccount={this.props.match.params.uriAccount}
          uriPlayground={this.props.match.params.uriPlayground}
          goTo={this.props.goTo}
        />
      )
    });

    tabs.push({
      name: "Acceleration templates",
      activeName: (
        <div style={{ display: "flex" }}>
          Acceleration Templates
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "175px",
              textTransform: "initial",
              color: "initial",
              fontWeight: "400",
              backgroundColor: "#fff",
              borderRadius: "3px"
            }}
          >
            {!this.state.showArchives && (
              <HelpOn
                content={
                  <div>
                    <div>
                      Acceleration Templates are AWS Cloudformation templates to
                      accelerate implementation of data use cases.
                    </div>
                    <div>_</div>
                    <div>Benefits:</div>
                    <div>- Its provide common technical solutions</div>
                    <div>- Accelerate project development</div>
                    <div>- Aligns with Engie Group security practices</div>
                    <div>_</div>
                    <div>These solutions are proposed by cdh</div>
                    <div>
                      But have to be configured/enabled by the environment admin
                      to be available on project.
                    </div>
                    <div>_</div>
                    <div>See available acceleration templates below.</div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      ),
      index: "acceleration-template",
      route: `${this.props.match.url}#acceleration-template`,
      content: (
        <PlaygroundAccelerationTemplate
          uriAccount={this.props.match.params.uriAccount}
          uriPlayground={this.props.match.params.uriPlayground}
          goTo={this.props.goTo}
        />
      )
    });
    if (this.state.showArchives) {
      tabs.push({
        name: "Archives",
        index: "archives",
        route: `${this.props.match.url}#archives`,
        content: (
          <PlaygroundArchives
            uriAccount={this.props.match.params.uriAccount}
            uriPlayground={this.props.match.params.uriPlayground}
            goTo={this.props.goTo}
          />
        )
      });
    }

    tabs.push({
      name: "Sagemaker Studio",
      index: "smstudio",
      route: `${this.props.match.url}#smstudio`,
      content: (
        <Domains playground={this.state.playground} api={this.props.api} />
      )
    });

    tabs.push({
      name: "Databricks Workspace",
      index: "dbxworkspace",
      route: `${this.props.match.url}#dbxworkspace`,
      content: (
        <DbxWorkspacesTab
          playground={this.state.playground}
          api={this.props.api}
          match={this.props.match}
        />
      )
    });

    return tabs;
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path="Adminenvironment" />;
    if (!this.state.ready)
      return <Loading message="Administration Environment" />;
    return (
      <div>
        <div>
          <div className="bread-line">
            <div className={"title-line"}>
              <Breadcrumb
                view={`Administration Environment ${this.state.playground.name}`}
              />
            </div>
          </div>
        </div>
        <div className={"admin-blocs"}>
          <Tabs
            isPreload={false}
            defaultActiveIndex={"networks"}
            tabs={this.getTabs()}
          />
        </div>
      </div>
    );
  }
}

export default withAppSync(PlaygroundAdmin);
