/* @flow */

export const createLogsGroup = {
  query: `mutation Q (
        $cartUri: String!,
        $platformUri: String!,
        $input: NewLogsGroupInput!
        ) {
            createLogsGroup(
                cartUri: $cartUri,
                platformUri: $platformUri,
                input: $input
            ) {
                id
            }
        }
     `,
  keyOutput: "createLogsGroup"
};
