/* eslint-disable camelcase */

import { mutationCreateDssInstance } from "./mutationCreateDssInstance";
import { getDssInstance } from "./queryGetDssInstance";
import { mutationUpdateDssInstance } from "./mutationUpdateDssInstance";
import { addGroup } from "./mutationAddGroupToDssInstance";
import { removeGroup } from "./mutationRemoveGroupFromDssInstance";
import { listDssInstanceGroups } from "./queryListDssInstanceGroups";
import { listDssInstanceNotGroups } from "./queryListDssInstanceNotGroups";
import { mutationTestDssInstanceConnection } from "./mutationTestDssInstanceConnection";
import { mutationCreateProjectConnection } from "./mutationCreateProjectConnection";
import { listDssProjectConnections } from "./queryListProjectConnections";
import { mutationDeleteProjectConnection } from "./mutationDeleteProjectConnection";
import { queryGetProjectConnection } from "./queryGetProjectConnection";
import { mutationDeleteDssInstance } from "./mutationDeleteDssInstance";

class DssInstanceApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  create(accountUri, input) {
    return this.fetchAppSync(mutationCreateDssInstance, {
      accountUri,
      input
    });
  }

  getDssInstance(accountUri, dssInstanceUri) {
    return this.fetchAppSync(getDssInstance, {
      accountUri,
      dssInstanceUri
    });
  }

  update(dssInstanceUri, input) {
    return this.fetchAppSync(mutationUpdateDssInstance, {
      dssInstanceUri,
      input
    });
  }

  delete(dssInstanceUri) {
    return this.fetchAppSync(mutationDeleteDssInstance, { dssInstanceUri });
  }

  addGroup(dssInstanceUri, groupUri) {
    return this.fetchAppSync(addGroup, { dssInstanceUri, groupUri });
  }

  removeGroup(dssInstanceUri, groupUri) {
    return this.fetchAppSync(removeGroup, { dssInstanceUri, groupUri });
  }

  listDssInstanceGroups(dssInstanceUri, options = {}) {
    return this.fetchAppSync(listDssInstanceGroups, {
      dssInstanceUri,
      options
    });
  }

  listNotGroups(dssInstanceUri, options = {}) {
    return this.fetchAppSync(
      listDssInstanceNotGroups,
      { dssInstanceUri, options },
      true
    );
  }

  testDssInstanceConnection(dssInstanceUri) {
    return this.fetchAppSync(
      mutationTestDssInstanceConnection,
      { dssInstanceUri },
      true
    );
  }

  createProjectConnection(input) {
    return this.fetchAppSync(mutationCreateProjectConnection, {
      input
    });
  }

  listProjectConnections(cartUri) {
    return this.fetchAppSync(listDssProjectConnections, { cartUri }, true);
  }

  deleteConnection(connectionId) {
    return this.fetchAppSync(mutationDeleteProjectConnection, { connectionId });
  }

  getProjectConnection(connectionId) {
    return this.fetchAppSync(queryGetProjectConnection, {
      connectionId
    });
  }
}

export default DssInstanceApi;
