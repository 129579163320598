/* @flow */
import React from "react";
import "./DatasourceETLCredentials.less";
import { connect } from "react-redux";
import Row from "../../../components/row";
import Col from "../../../components/col";
import { getAccountUriFromUri } from "../../../utils/toolsForUri";
import GraphQl from "../../../graphQL";
import { diffDate, formatDate } from "../../../utils/date";
import ButtonAction from "../../../components/buttonAction";
import Modal from "../../../components/modal";
import routes from "../../routes";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";

type propTypes = {
  datasource: {
    uri: string,
    parent: string,
    etluser_credentials_expiration_date: string,
    etluser_credentials_creator: string,
    etluser_credentials_description: string
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  isDeleting: boolean,
  overwrite: boolean,
  deleted: boolean,
  description: string,
  createNewCredsModal: boolean,
  createCredsModal: boolean,
  deleteCredsModal: boolean,
  errorDeleteCreds: Object
};

class DatasourceETLCredentials extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      isDeleting: false,
      overwrite: false,
      description: "",
      deleted: false,
      createNewCredsModal: false,
      createCredsModal: false,
      deleteCredsModal: false,
      errorDeleteCreds: undefined
    };
  }

  handleChange = (value: Object) => this.setState({ overwrite: !!value });

  deleteCredentials = () => {
    if (!this.state.isDeleting) {
      this.setState({ isDeleting: true }, () =>
        this.props.api.datasource
          .deleteCredentials(this.props.datasource.uri)
          .then(() => {
            this.setState({ isDeleting: false, deleted: true });
          })
          .catch((errorDeleteCreds) => {
            this.props.showGlobalNotification({
              message:
                "Could not find matching user on AWS account. ETL credentials have been removed from CDH",
              type: "success",
              popupDuration: 6000
            });
            this.setState({
              isDeleting: false,
              deleted: true,
              errorDeleteCreds
            });
          })
      );
    }
  };

  displayExpiration = () => {
    const expiration =
      this.props.datasource.etluser_credentials_expiration_date;
    const expirationDate = formatDate(expiration, false);
    const dateNow = new Date();
    if (diffDate(dateNow.toString(), expiration) < 0) {
      return (
        <div className="red-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Warning : Expired on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) < 240) {
      return (
        <div className="red-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Warning : Will expire on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) < 1080) {
      return (
        <div className="yellow-warning">
          <i className="fas fa-exclamation-circle fa-spacing" />
          Will expire on {expirationDate}
        </div>
      );
    }
    if (diffDate(dateNow.toString(), expiration) > 1080) {
      return (
        <div className="green-warning">
          <i className="fas fa-check-circle fa-spacing" />
          Will expire on {expirationDate}
        </div>
      );
    }
    return false;
  };

  onChangeDescription = (event: any) => {
    const { value } = event.target;
    if (value) {
      this.setState({ description: value });
    }
  };

  render() {
    return (
      <Row>
        <Col
          size={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          Get ETL User credentials
          <div className="checkbox-overwrite">
            <div className="facet-criteria-name">
              <input
                id="overwrite"
                type="checkbox"
                className={"checkbox-facet"}
                onClick={(event) => this.handleChange(event.target.checked)}
              />
              <label htmlFor="overwrite" className="fas">
                <small className="attribute-label">Allow Overwrite</small>
              </label>
            </div>
          </div>
          {this.props.datasource.etluser_credentials_creator &&
            this.props.datasource.etluser_credentials_creator !== "null" &&
            !this.state.deleted && (
              <div className="description-container">
                <span className="description-container-label">Created by:</span>
                <span className="elt-creator">
                  {this.props.datasource.etluser_credentials_creator}
                </span>
              </div>
            )}
          {this.props.datasource.etluser_credentials_description &&
            this.props.datasource.etluser_credentials_description.length > 0 &&
            !this.state.deleted && (
              <div className="description-container">
                <span className="description-container-label">
                  Description:
                </span>
                <div className="description-etl">
                  {this.props.datasource.etluser_credentials_description}
                </div>
              </div>
            )}
          {this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted &&
            this.displayExpiration()}
        </Col>
        <Col
          size={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div>
            {this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted ? (
              <div
                style={{ float: "right", marginTop: "5px" }}
                className="butn butn-flat min-width-160"
                onClick={() => this.setState({ createCredsModal: true })}
              >
                ETL Credentials
              </div>
            ) : (
              <React.Fragment>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ createNewCredsModal: true })}
                >
                  ETL Credentials
                </div>
                {this.state.createNewCredsModal && (
                  <Modal
                    title="Generate ETL credentials"
                    body={
                      <div>
                        <div className="mt-2">
                          <textarea
                            onChange={this.onChangeDescription}
                            className="form-control bg-white"
                            placeholder="Describe your use of this credential"
                          ></textarea>
                        </div>
                      </div>
                    }
                    actions={[
                      <ButtonAction
                        label="Generate ETL credentials"
                        className="butn butn-flat"
                        onClick={() => {
                          this.props.goTo({
                            route: routes.Datasource.GenerateETLCredentials,
                            params: {
                              uriDatasource: this.props.datasource.uri,
                              uriAccount: getAccountUriFromUri(
                                this.props.datasource.uri
                              ),
                              overwrite: this.state.overwrite.toString()
                            },
                            state: {
                              account: {
                                uri: getAccountUriFromUri(
                                  this.props.datasource.uri
                                )
                              },
                              playground: { uri: this.props.datasource.parent },
                              datasource: this.props.datasource,
                              description: this.state.description
                            }
                          });
                          this.setState({ createNewCredsModal: false });
                        }}
                      />,
                      <ButtonAction
                        label="Close"
                        onClick={() =>
                          this.setState({ createNewCredsModal: false })
                        }
                      />
                    ]}
                  />
                )}
              </React.Fragment>
            )}

            {this.state.createCredsModal && (
              <Modal
                title="Generate ETL credentials"
                body={
                  <div>
                    <div>
                      <div className="mt-2">
                        <textarea
                          onChange={this.onChangeDescription}
                          className="form-control bg-white"
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </div>
                    <div>
                      <hr />
                      <span style={{ color: "var(--red)" }}>
                        <i className="fas fa-exclamation-triangle fa-spacing" />
                        Warning:
                      </span>
                      &nbsp;The generation of new credentials will invalidate
                      the current ones.
                    </div>
                  </div>
                }
                actions={[
                  <ButtonAction
                    label="Generate ETL credentials"
                    className="butn butn-flat"
                    onClick={() => {
                      this.props.goTo({
                        route: routes.Datasource.GenerateETLCredentials,
                        params: {
                          uriDatasource: this.props.datasource.uri,
                          uriAccount: getAccountUriFromUri(
                            this.props.datasource.uri
                          ),
                          overwrite: this.state.overwrite.toString()
                        },
                        state: {
                          account: {
                            uri: getAccountUriFromUri(this.props.datasource.uri)
                          },
                          playground: { uri: this.props.datasource.parent },
                          datasource: this.props.datasource,
                          description: this.state.description
                        }
                      });
                      this.setState({ createCredsModal: false });
                    }}
                  />,
                  <ButtonAction
                    label="Close"
                    onClick={() => this.setState({ createCredsModal: false })}
                  />
                ]}
              />
            )}
          </div>

          {this.props.datasource.etluser_credentials_expiration_date &&
            !this.state.deleted && (
              <div>
                <div
                  style={{ float: "right", marginTop: "5px" }}
                  className="butn butn-flat min-width-160"
                  onClick={() => this.setState({ deleteCredsModal: true })}
                >
                  {this.state.isDeleting && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  Delete
                </div>

                {this.state.deleteCredsModal && (
                  <Modal
                    title="Delete ETL credentials"
                    body="Are you sure ?"
                    errorMessage={this.state.errorDeleteCreds}
                    actions={[
                      <ButtonAction
                        label="Close"
                        onClick={() =>
                          this.setState({ deleteCredsModal: false })
                        }
                      />,
                      <ButtonAction
                        label="Delete"
                        className="butn butn-delete"
                        onClick={() => {
                          this.deleteCredentials();
                          this.setState({ deleteCredsModal: false });
                        }}
                      />
                    ]}
                  />
                )}
              </div>
            )}
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceETLCredentials)));
