/* @flow */
import { listAccelerationTemplates } from "./queryListAccelerationTemplates";
import { listAccessesToTemplates } from "./queryListAccessesToTemplates";
import { grantAccessToAccelerationTemplate } from "./mutationGrantAccessToAccelerationTemplate";
import { revokeAccessToAccelerationTemplate } from "./mutationRevokeAccessToAccelerationTemplate";
import { getAccelerationTemplate } from "./queryGetAccelerationTemplate";
import { updateAccessToAccelerationTemplate } from "./mutationUpdateAccessToAccelerationTemplate";

class AccelerationTemplatesApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  listAccelerationTemplates() {
    return this.fetchAppSync(listAccelerationTemplates, {});
  }

  getAccelerationTemplate(templateId: string) {
    return this.fetchAppSync(getAccelerationTemplate, { templateId });
  }

  listAccelerationTemplatesEnvironmentsGroups(
    environmentUri: string,
    options: Object = {}
  ) {
    return this.fetchAppSync(listAccessesToTemplates, {
      environmentUri,
      options
    });
  }

  grantAccessToAccelerationTemplate(
    templateId: string,
    environmentUri: string,
    groupUri: string,
    monitoringEmail: string,
    networkId: string,
    authToCreateCustomPolicy: boolean
  ) {
    return this.fetchAppSync(grantAccessToAccelerationTemplate, {
      environmentUri,
      input: {
        acceleration_template_id: templateId,
        group_uri: groupUri,
        monitoring_email: monitoringEmail,
        network_id: networkId,
        auth_to_create_custom_policy: authToCreateCustomPolicy
      }
    });
  }

  updateAccessToAccelerationTemplate(grantAccessId: string, input: Object) {
    return this.fetchAppSync(updateAccessToAccelerationTemplate, {
      grantAccessId,
      input
    });
  }

  revokeAccessToAccelerationTemplate(grantAccessId: string) {
    return this.fetchAppSync(revokeAccessToAccelerationTemplate, {
      grantAccessId
    });
  }
}

export default AccelerationTemplatesApi;
