/* eslint-disable camelcase */
/* @flow */

import React from "react";
import "./style.less";
import { formatDate } from "../../../utils/date";
import Links from "../../links";
import { getAccountUriFromAccountId } from "../../../utils/toolsForUri";
import { findDomainName } from "../components/constants";
import TextField from "../../../components/textField";
import { isRSSourceType } from "../../../utils/constants";

type propTypes = {
  dataset: Object
};

type stateTypes = {};

const maturity = ["Initial", "Managed", "Standardized", "Optimized"];

class DatasetDetails extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    const { dataset } = this.props;
    const {
      uri,
      can_share, // eslint-disable-line camelcase
      access,
      description,
      createdat,
      datasource,
      accountid,
      domain,
      maturity_level, // eslint-disable-line camelcase
      last_metadata_update
    } = dataset;

    return (
      <div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="fas fa-link dataset-detail-icon"></i>
            URI
          </div>
          <span>{uri}</span>
        </div>
        <div className="dataset-detail-double-element">
          <div className="dataset-detail-element-left">
            <div className="dataset-detail-title">
              <i className="fa fa-suitcase dataset-detail-icon"></i>
              Domain
            </div>
            <span>{findDomainName(domain)}</span>
          </div>
          <div className="dataset-detail-element-right">
            <div className="dataset-detail-title">
              <i className="fas fa-star-half-alt dataset-detail-icon"></i>
              Maturity
            </div>
            <span>{maturity[maturity_level]}</span>
          </div>
        </div>
        <div className="dataset-detail-double-element">
          <div className="dataset-detail-element-left">
            <div className="dataset-detail-title">
              <i className="fas fa-calendar-day dataset-detail-icon"></i>
              Created on
            </div>
            <span>{formatDate(createdat)}</span>
          </div>
          <div className="dataset-detail-element-right">
            <div className="dataset-detail-title">
              <i className="fas fa-calendar-day dataset-detail-icon"></i>
              Metadata Updated On
            </div>
            <span>
              {last_metadata_update
                ? formatDate(last_metadata_update)
                : formatDate(createdat)}
            </span>
          </div>
        </div>
        {dataset.arn && (
          <div className="dataset-detail-element">
            <div className="dataset-detail-title">
              <i className="fab fa-aws dataset-detail-icon"></i>
              Location
            </div>
            <TextField text={dataset.arn.replace("arn:aws:s3:::", "s3://")} />
          </div>
        )}
        {isRSSourceType(dataset.source_type) && (
          <div className="dataset-detail-element">
            <div className="dataset-detail-title">
              <i className="fas fa-table dataset-detail-icon" />
              Database Schema
            </div>
            <span>{dataset.schema}</span>
          </div>
        )}
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">
            <i className="far fa-folder-open dataset-detail-icon"></i>
            Datasource
          </div>
          <span className="dataset-detail-link">
            {["owner"].includes(access) || can_share ? (
              <Links.Datasource.View
                uriAccount={getAccountUriFromAccountId(accountid)}
                uriDatasource={(datasource || {}).uri}
              >
                {datasource.name}
              </Links.Datasource.View>
            ) : (
              datasource.name
            )}
          </span>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">Sharing Management Group</div>
          <span className="dataset-detail-link">
            <Links.Group.View
              uriGroup={(datasource.sharingManagementGroup || {}).uri || "null"}
            >
              {(datasource.sharingManagementGroup || {}).name || "-"}
            </Links.Group.View>
          </span>
        </div>
        <div className="dataset-detail-double-element">
          <div className="dataset-detail-element-left">
            <div className="dataset-detail-title">
              <i className="fab fa-aws dataset-detail-icon" />
              AWS Account
            </div>
            <span>{datasource.aws}</span>
          </div>
          <div className="dataset-detail-element-right">
            <div className="dataset-detail-title">
              <i className="fas fa-globe-europe dataset-detail-icon" />
              AWS Region
            </div>
            <span>{datasource.region}</span>
          </div>
        </div>
        <div className="dataset-detail-element">
          <div className="dataset-detail-title">Description</div>
          <p className="dataset-detail-description">
            {description || "No description"}
          </p>
        </div>
      </div>
    );
  }
}

export default DatasetDetails;
