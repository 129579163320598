/* @flow */

export const listMyGroupTrusts = {
  query: `query Q ($options: QueryOptions) {
        listMyTrusts(options: $options) {
            total
            limit
            offset
            results {
                arn
                description
                status
                type
                is_admin
                id
                group {
                    name
                    uri
                }
                platform {
                    name
                    aws
                    uri
                }
            }
        }
    }`,
  keyOutput: "listMyTrusts"
};
