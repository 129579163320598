/* @flow */

export const listCartLogsGroup = {
  query: `query Q (
        $cartUri: String!,
        ) {
            listCartLogsGroup(
                cartUri: $cartUri,
            ) {
                id
                name
                resource_name
                description
                platform {
                    aws
                    uri
                    name
                    environment
                }
                retention_policy
                tags {
                    Key
                    Value
                }
            }
        }
     `,
  keyOutput: "listCartLogsGroup"
};
