/* @flow */
import React from "react";
import { connect } from "react-redux";
import withGoTo from "../../../../goToHOC";
import { updateMessage } from "../../../../globalNotifications/actions";
import GraphQl from "../../../../../graphQL";
import Routes from "../../../../routes";
import withAppsync from "../../../../AppsyncHOC";
import Loading from "../../../../../components/loading";
import Error from "../../../../../components/error";
import Logger from "../../../../../utils/logger";
import CheckBoxList from "../../../../../components/checkBoxList";
import { filterGroupsService } from "../../../../../utils/groupsfilter";
import "./redshiftListAwsClusters.less";
import SelectInfinite from "../../../../../components/SelectInfinite";

const Log = Logger("RedshiftListAwsClusters");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  },
  api: GraphQl,
  goTo: Function,
  showGlobalNotification: Function,
  platform: Object,
  region: string
};

type stateTypes = {
  detectedClusters: number,
  listClustersReady: boolean,
  listClustersError?: Object,
  region: string,
  isSubmitting: boolean,
  redshiftClusters: Array<Object>,
  clustersCheckboxValues: Array<Object>,
  owneruri: string,
  importClustersError: Object,
  totalGroups: number
};

class RedshiftListAwsClusters extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      detectedClusters: 0,
      listClustersReady: false,
      listClustersError: undefined,
      isSubmitting: false,
      region: this.props.region,
      redshiftClusters: [],
      clustersCheckboxValues: [],
      totalGroups: 0,
      owneruri: "",
      importClustersError: null
    };
  }

  componentDidMount() {
    this.listRedshiftClustersFromAws(this.props.region);
  }

  componentWillReceiveProps(nextProps: propTypes) {
    if (nextProps.region !== this.props.region) {
      this.setState({
        region: nextProps.region
      });
      this.listRedshiftClustersFromAws(nextProps.region);
    }
  }

  listRedshiftClustersFromAws = (region) => {
    this.setState({
      detectedClusters: 0,
      listClustersReady: false,
      redshiftClusters: []
    });
    this.props.api.analytics
      .listRedshiftClustersFromAws(this.props.platform.uri, region)
      .then((redshiftClusters) => {
        this.setState({
          detectedClusters: redshiftClusters.length,
          listClustersReady: true,
          redshiftClusters: redshiftClusters.map((cluster) => ({
            value: cluster,
            isSelected: false
          }))
        });
      })
      .catch((listClustersError) => {
        this.setState({
          listClustersError,
          listClustersReady: true
        });
      });
  };

  rowRender = (redshiftCluster: Object) => (
    <tr className="table-row">
      <th scope="col" className="row-action">
        <div>
          <CheckBoxList
            showLabel={false}
            defaultData={[
              {
                value: redshiftCluster.value.resource_name,
                label: redshiftCluster.value.resource_name,
                checked: redshiftCluster.isSelected
              }
            ]}
            onChange={(values) =>
              this.setState({ clustersCheckboxValues: values })
            }
          />
        </div>
      </th>
      <td className="row-important">{redshiftCluster.value.resource_name}</td>
      <td>{redshiftCluster.value.node_type}</td>
      <td>{redshiftCluster.value.number_of_nodes}</td>
      <td>{redshiftCluster.value.database_name}</td>
      <td>{redshiftCluster.value.endpoint_address}</td>
      <td>{redshiftCluster.value.endpoint_port}</td>
    </tr>
  );

  importRedshiftClusters = () => {
    this.setState({
      isSubmitting: true
    });

    this.props.api.analytics
      .importRedshiftClusters(this.props.platform.uri, {
        owneruri: this.state.owneruri,
        region: this.state.region,
        clustersIdentifiers: this.state.clustersCheckboxValues
      })
      .then((clusters) => {
        Log.info("Redshift clusters imported:", clusters);
        this.props.showGlobalNotification({
          message: "RedshiftCluster clusters imported",
          type: "success"
        });
        this.props.goTo({
          route: `${Routes.Playground.Analytics}#analytics`,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriPlayground: this.props.match.params.uriPlayground
          }
        });
        this.setState({
          isSubmitting: false
        });
      })
      .catch((importClustersError) => {
        this.props.showGlobalNotification({
          message: "Redshift cluster import failed",
          type: "alert"
        });
        this.setState({
          isSubmitting: false,
          importClustersError
        });
      });
  };

  getGroups = (offset = 0) =>
    this.props.api.playground
      .listGroups(
        this.props.match.params.uriAccount,
        this.props.match.params.uriPlayground,
        { offset }
      )
      .then((listGroupsResponse) => {
        const groups = filterGroupsService(listGroupsResponse.results);

        if (groups.length <= 0) {
          Log.error(
            "You need to be part of a group before creating an analytics tool...",
            "DatasourceCreate"
          );
        }

        this.setState((prevState) => ({
          totalGroups:
            (prevState.totalGroups || listGroupsResponse.total) -
            (listGroupsResponse.results.length - groups.length)
        }));
        return groups.map((g) => ({
          value: g.uri,
          label:
            g.account.name === "service"
              ? `${g.name}`
              : `${g.name} (in organization #${g.account.name})`
        }));
      })
      .catch((error) => {
        Log.error(error);
        return [];
      });

  render() {
    return (
      <div>
        {this.state.listClustersError && (
          <Error
            error={this.state.listClustersError}
            path={"RedshiftListAwsClusters"}
          />
        )}
        {this.state.importClustersError && (
          <Error
            error={this.state.importClustersError}
            path={"RedshiftListAwsClusters"}
          />
        )}
        <div className="cluster-detect-form">
          <label className="label-form listTitle">
            Redshift clusters on AWS account {this.props.platform.aws}
          </label>
          {!this.state.listClustersReady && (
            <Loading message={"Redshift clusters from AWS"} />
          )}
          {this.state.redshiftClusters &&
            this.state.listClustersReady &&
            !this.state.listClustersError && (
              <div>
                <p>
                  Found {this.state.detectedClusters} Redshift cluster on AWS
                  account {this.props.platform.aws} in the region{" "}
                  {this.state.region}
                </p>
                {this.state.redshiftClusters.length > 0 && (
                  <div>
                    <div className="detected-clusters-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="row-action"></th>
                            <th scope="col">Name</th>
                            <th scope="col">Node Type</th>
                            <th scope="col">Number of Nodes</th>
                            <th scope="col">Master Database Name</th>
                            <th scope="col">Endpoint Address</th>
                            <th scope="col">Endpoint Port</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.redshiftClusters.map(this.rowRender)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          {this.state.listClustersReady &&
            !this.state.listClustersError &&
            this.state.clustersCheckboxValues &&
            this.state.clustersCheckboxValues.length > 0 && (
              <div>
                <div className="mb-3">
                  <div>
                    <label className="label-form">Owner Group</label>

                    <SelectInfinite
                      key={"select-infinite-redshift-group"}
                      placeholder={"Select a group"}
                      totalOptions={this.state.totalGroups}
                      loadMoreOptions={this.getGroups}
                      onSelectOption={(value) =>
                        this.setState({ owneruri: value })
                      }
                    />
                  </div>
                </div>
                <div className={"ml-1 row justify-content-center"}>
                  <button
                    type="button"
                    className="butn"
                    disabled={
                      this.state.clustersCheckboxValues.length <= 0 ||
                      this.state.region === "" ||
                      this.state.owneruri === ""
                    }
                    onClick={this.importRedshiftClusters}
                  >
                    {this.state.isSubmitting && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Import Redshift Clusters
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(RedshiftListAwsClusters)));
