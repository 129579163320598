/* @flow */

import React from "react";
import "./style.less";
import Facet from "./Facet";

type propTypes = {
  categoryFacets: {
    category: string,
    name: string,
    facets: Array<Object>
  },
  activeFilters: Array<Object>,
  onClickFacet: Function
};

/*
 * Add facets from activeFilters not present in facets search result
 */
const FacetsFromActiveFilters = ({
  categoryFacets,
  activeFilters,
  onClickFacet
}: propTypes) => {
  let facets: Array<{ value: string, nb: number }> = [];
  const facetsFromActiveFilters = activeFilters.filter(
    (filter) => filter.field === categoryFacets.category
  );
  if (facetsFromActiveFilters.length > 0) {
    facets = facetsFromActiveFilters[0].values
      .filter((v) => !categoryFacets.facets.find((vv) => vv.value === v))
      .map((value: string) => ({ value, nb: 0 }));
  }

  return (
    <div>
      {facets.map((facet) => (
        <Facet
          category={categoryFacets.category}
          facet={facet}
          isActive
          onClickFacet={onClickFacet}
        />
      ))}
    </div>
  );
};

export default FacetsFromActiveFilters;
