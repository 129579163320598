/* @flow */

export const listGroupIdentities = {
  query: `query Q ($accountUri: String!,$groupUri:String!, $options: QueryOptions) {
        getGroup(accountUri: $accountUri, groupUri:$groupUri) {
            identities (options: $options) {
                name
                resource_name
                uri
                added_at
            }
        }
      }
    `,
  keyOutput: "getGroup.identities"
};

export type GroupIdentitiesPropsTypes = Array<{
  name: string,
  resource_name: string,
  uri: string,
  added_at: string
}>;
