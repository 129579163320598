/* @flow */
import React from "react";
import Links from "../../links";
import routes from "../../routes";

type propTypesAddToCartAction = {
  uri: string,
  name: string
};
const AddToCartAction = ({ uri, name }: propTypesAddToCartAction) => (
  <div className="actionIcon tooltiped">
    <Links.Dataset.AddToCart
      uriDataset={uri}
      state={{ dataset: { uri, name } }}
    >
      <div className="butn butn-flat min-width-130">
        <i className="fas fa-plus butn-icon"></i>
        <span className="butn-text">Add to project</span>
      </div>
    </Links.Dataset.AddToCart>
  </div>
);

type propTypesShareRequestAction = {
  uri: string,
  name: string
};
const ShareRequestAction = ({ uri, name }: propTypesShareRequestAction) => (
  <div className="actionIcon tooltiped">
    <Links.Share.Request uriDataset={uri} state={{ dataset: { uri, name } }}>
      <div className="butn butn-flat min-width-130">
        <i className="fas fa-lock-open butn-icon"></i>
        <span className="butn-text">Request Access</span>
      </div>
    </Links.Share.Request>
  </div>
);

type propTypesShareAction = {
  uri: string,
  name: string,
  goTo: Function
};
const ShareAction = ({ uri, name, goTo }: propTypesShareAction) => (
  <div className="actionIcon tooltiped">
    <div
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        return goTo({
          route: routes.Dataset.Share,
          params: {
            uriDataset: uri
          },
          state: {
            dataset: {
              name,
              uri
            }
          }
        });
      }}
    >
      <div className="butn butn-flat min-width-130">
        <i className="fas fa-share-alt butn-icon"></i>
        <span className="butn-text-share">Share</span>
      </div>
    </div>
  </div>
);

type propTypesDatasetCardActions = {
  access: "owner" | "shared" | "other",
  uri: string,
  name: string,
  goTo: Function,
  canShare: boolean,
  shareable: boolean
};
const DatasetCardActions = ({
  uri = "-",
  access = "other",
  name = "-",
  goTo,
  canShare,
  shareable
}: propTypesDatasetCardActions) => (
  <div
    className="actionBar"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {access !== "other" && (
      <AddToCartAction uri={uri} name={name} access={access} />
    )}
    {canShare && shareable && <ShareAction uri={uri} name={name} goTo={goTo} />}
    {!canShare && ["other", "shared"].includes(access) && shareable && (
      <ShareRequestAction uri={uri} name={name} access={access} />
    )}
  </div>
);

export default DatasetCardActions;
