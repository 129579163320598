import React from "react";
import withGoTo from "../../goToHOC";

import "./dbx-workspaces-card.scss";
import { compile } from "path-to-regexp";
import routes from "../../routes";

const DbxWorkspaceCard = ({ uriAccount, workspace }) => {
  return (
    <div className="cdh-card card-shadow card bg-white">
      <div className="cdh-card-header">
        <div className="cdh-card-begin">
          <div className="cdh-card-begin-content">
            <i className="fas fa-briefcase fa-spacing" />
            Databricks Workspace
          </div>
        </div>
      </div>
      <div className="cdh-card-body">
        <div className="cdh-card-body-left">
          <a
            href={decodeURIComponent(
              compile(routes.Databricks.WorkspaceDetails + "#details")({
                uriAccount,
                uuid: workspace.uuid
              })
            )}
          >
            <span className="text-capitalize cdh-card-name">
              {workspace.dbx_workspace_name}
            </span>
          </a>
          <div className="cdh-cart-description dbx-workspace-card-body">
            <span>
              <b>Databricks account name:</b> {workspace.dbx_account_name}
            </span>
            <span>
              <b>Environment:</b> {workspace.platform.name}
            </span>
          </div>
          <div className="cdh-card-details">
            <div className="cdh-card-detail">
              <i className="fas fa-box" />
              <span className="content-card-bucket">
                <b>Workspace ID:</b> {workspace.dbx_workspace_id}
              </span>
            </div>
            <div className="cdh-card-detail">
              <i className="fas fa-link" />
              <span className="content-card-bucket">
                <b>Workspace URL:</b> {workspace.dbx_workspace_url}
              </span>
            </div>
            <div className="cdh-card-detail">
              <i className="fas fa-box" />
              <span className="content-card-bucket">
                <b>SQL Warehouse ID:</b> {workspace.dbx_warehouse_id}
              </span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body-right">
          <div className="cdh-card-body-actions">
            <div className="btn-cart" onClick={() => console.log("OK")}>
              <i className="fas fa-trash fa-spacing" />
              <span className="text-actions">Delete</span>
            </div>
          </div>
        </div>
      </div>
      <div className="cdh-card-footer">
        <div className="cdh-card-begin" style={{ display: "flex" }}>
          <div className="text-footer-metrics">
            <i className="fas fa-box fa-spacing" />
            Groups
            <span className="tag-metrics green-status">
              {workspace.dbx_groups.total}
            </span>
          </div>
          <div className="text-footer-metrics">
            <i className="fas fa-table fa-spacing" />
            Projects
            <span className="tag-metrics green-status">
              {workspace.projects.total}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withGoTo(DbxWorkspaceCard);
