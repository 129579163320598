import React from "react";
// import PropTypes from 'prop-types';

// class StringCutter extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             x: 0,
//             y: 0,
//         };
//         this.onMouseHover = this.onMouseHover.bind(this);
//     }
//
//     onMouseHover(e) {
//         console.log(e.screenX, e.screenY)
//         this.setState({
//             x: e.screenX,
//             y: e.screenY,
//         });
//     }
//
//     render() {
//         return (
//             <div className="tooltiped" onMouseMove={this.onMouseHover} onMouseEnter={(e) => console.log('enter',e, e.screenX, e.screenY)}>
//                 {`${this.props.s.substring(0, this.props.charMax - 3)}...`}
//                 <span
//                     className={'tooltiptext'}
//                     style={{
//                         position: 'fixed',
//                         top: this.state.y,
//                         left: this.state.x,
//                         index: 1050,
//                     }}
//                 >
//                     {this.props.s}
//                 </span>
//             </div>
//         );
//     }
// }
// StringCutter.propTypes = {
//     s: PropTypes.string,
//     charMax: PropTypes.number,
// };

export default (s, charMax = 15, style = {}) => {
  if (typeof s !== "string") return s;
  return s.length > charMax ? (
    <div className="tooltiped">
      {`${s.substring(0, charMax - 3)}...`}
      <div className={"tooltiptext-container"}>
        <span
          className={"tooltiptext"}
          style={{
            display: "fixed",
            marginTop: "20px",
            marginLeft: "30px",
            ...style
          }}
        >
          {s}
        </span>
      </div>
    </div>
  ) : (
    s
  );
};
