/* eslint-disable quote-props */
/* @flow */
import React from "react";
import "./card.less";
import withErrorBoundary from "../../errorBoundaryHOC";

type propTypes = {
  datasource: Object
};

type stateTypes = {};

class DatasourceCardAddedToProject extends React.Component<
  propTypes,
  stateTypes
> {
  DOMRef: Object;

  constructor(props) {
    super(props);
    this.DOMRef = React.createRef();
    this.state = {};
  }

  render() {
    return (
      <div className="dataset-cart-added">
        <div className="dataset-cart-added-header">
          <div className="dataset-cart-added-header-left">
            <i className={"far fa-check-square"}></i>
          </div>
          <div className="dataset-cart-added-header-right">
            {this.props.datasource.access === "owner" ? (
              <span className="dataset-cart-added-owner">OWNED</span>
            ) : (
              <span className="dataset-cart-added-shared">SHARED</span>
            )}
          </div>
        </div>
        <div className="dataset-cart-added-body">
          <div className="dataset-cart-added-body-name">
            {this.props.datasource.name}
          </div>
          <div className="dataset-cart-added-body-producer">
            By {this.props.datasource.account_name} -{" "}
            {this.props.datasource.owner_group_name}
          </div>
        </div>
        <div className="dataset-cart-added-footer">
          <div className="dataset-cart-added-footer-left">
            <i className="fas fa-database"></i>
            <span className="text-header-dataset">Datasource</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(DatasourceCardAddedToProject, false);
