/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import withAppsync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import { RETENTION_POLICIES } from "../AddLogGroup/AddLogGroup";
import Breadcrumb from "../../../components/breadcrumb";
import Row from "../../../components/row";
import Col from "../../../components/col";
import TagsDatasourceFields from "../../datasources/components/form/TagsDatasourceFields";
import Loading from "../../../components/loading";
import Logger from "../../../utils/logger";
import Routes from "../../routes";
import GraphQl from "../../../graphQL";

const Log = Logger("EditLogGroup");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  match: Object,
  location: Object
};

type stateTypes = {
  isLoadingCurrentlogsGroup: boolean,
  cart: Object,
  editionDisabled: boolean,
  // availablePlatforms: [{
  //   name: String,
  //   environment: String,
  //   uri: String,
  // }],
  availablePlatforms: [
    {
      name: String,
      environment: String,
      uri: String
    }
  ],
  formValues: Object
};

class EditLogGroup extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    const { location } = props;
    const cart = (location.state && location.state.cart) || {};
    const platforms = cart.platforms || [];
    this.state = {
      isLoadingCurrentlogsGroup: true,
      cart,
      editionDisabled: false,
      availablePlatforms: platforms,
      formValues: {
        name: "",
        platformUri: platforms[0] ? platforms[0].uri : "",
        description: "",
        tags: [], // [{ Key, Value }]
        retentionPolicy: 3
      }
    };
  }

  async componentDidMount() {
    this.setState({ isLoadingCurrentlogsGroup: true });
    const currentLogsGroup = await this.getLogsGroup({
      cartUri: this.props.match.params.cartUri,
      id: this.props.match.params.logsGroupId
    });
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        name: currentLogsGroup.name,
        resourceName: currentLogsGroup.resource_name,
        description: currentLogsGroup.description,
        platformUri: currentLogsGroup.platform.uri,
        tags: currentLogsGroup.tags,
        retentionPolicy: currentLogsGroup.retention_policy
      },
      isLoadingCurrentlogsGroup: false
    }));
  }

  async getLogsGroup({ cartUri, id }) {
    const cartLogGroups = await this.props.api.logGroups.listLogsGroup(cartUri);
    return cartLogGroups.find((i) => i.id === id);
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const formValues = { ...prevState.formValues };
      formValues[field] = value;
      return { formValues };
    });
  }

  save(setSubmitting) {
    setSubmitting(true);
    const { cartUri, logsGroupId } = this.props.match.params;
    const { description, tags, retentionPolicy } = this.state.formValues;
    return this.props.api.logGroups
      .updateLogsGroup(cartUri, logsGroupId, {
        description,
        tags,
        retention_policy: retentionPolicy
      })
      .then(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Log group updated successfully",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-logs-groups`,
          params: { cartUri: this.state.cart.uri }
        });
      })
      .catch(() => {
        setSubmitting(false);
        this.props.showGlobalNotification({
          message: "Log group update failed",
          type: "alert"
        });
      });
  }

  render() {
    const { formValues } = this.state;
    const currentPlatform = this.state.availablePlatforms.find(
      (i) => i.uri === this.state.formValues.platformUri
    );

    if (this.state.isLoadingCurrentlogsGroup) {
      return <Loading message="Logs Group..." />;
    }

    return (
      <div className="edit-logs-group">
        <Breadcrumb view={"Edit Logs Group"} />
        <Row>
          <Col size={1} />
          <Col className={"my-5"} size={9}>
            <Formik
              initialValues={{
                name: formValues.name,
                platformUri: formValues.platformUri,
                description: formValues.description
              }}
              validate={() => {
                const errors = {};
                Log.info("validate", formValues, errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      {formValues.name}
                    </fieldset>
                    <fieldset className="form-group">
                      <legend className="label-form">AWS Name:</legend>
                      {formValues.resourceName}
                    </fieldset>

                    <div className="row">
                      <fieldset className="col form-group">
                        <legend className="label-form">
                          Environement URI:
                        </legend>
                        {currentPlatform ? (
                          <div>
                            {currentPlatform.name} (
                            {currentPlatform.environment})
                          </div>
                        ) : null}
                      </fieldset>

                      <fieldset className="col form-group">
                        <legend className="label-form">
                          Retention policy (days):
                        </legend>
                        <select
                          className="form-control form-control-sm"
                          disabled={this.state.editionDisabled}
                          value={this.state.formValues.retentionPolicy}
                          onChange={(event) =>
                            this.handleChange(
                              "retentionPolicy",
                              event.target.value
                            )
                          }
                        >
                          {RETENTION_POLICIES.map((i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="retentionPolicy"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>
                    </div>

                    <fieldset className="form-group">
                      <legend className="label-form">Description:</legend>
                      <Field
                        component="textarea"
                        name="description"
                        className="form-control bg-white"
                        placeholder="Description (optional) ..."
                        onChange={(event) =>
                          this.handleChange("description", event.target.value)
                        }
                        value={formValues.description}
                        rows={"5"}
                        disabled={this.state.editionDisabled}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group mb-3">
                      <legend className="label-form">Tags</legend>
                      <div
                        style={{
                          border: "1px solid #e5ecf7",
                          borderRadius: "4px",
                          padding: "20px"
                        }}
                      >
                        <TagsDatasourceFields
                          tags={this.state.formValues.tags}
                          customField="tags"
                          // onChangeTags={(field, value) => handleChange(field, value)}
                          onChangeTags={(field, tags) =>
                            this.handleChange("tags", tags)
                          }
                          isDisabled={this.state.editionDisabled}
                        />
                      </div>
                    </fieldset>

                    <div className={"ml-1 row justify-content-end pt-4 mr-1"}>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="butn"
                      >
                        {isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Update log group
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppsync(EditLogGroup));
