export const listAllMyDatasourcesGroups = {
  query: `query Q {
        getDatasourceGroups {
            category
            name
            groups {
                value
                nb
            }
        }
      }
    `,
  keyOutput: "getDatasourceGroups"
};
