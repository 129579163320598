import { useEffect, useState } from "react";

const useDatabricksWorkspaceInviteGroups = (
  api,
  workspace,
  reloadWorkspace
) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [platformGroups, setPlatformGroups] = useState([]);
  const [total, setTotal] = useState(0);

  const checkIfGroupAlreadyInWorkspaceGroups = (group) =>
    workspace.dbx_groups.groups.findIndex(
      (wpGrp) => wpGrp.name === group.name
    ) === -1;
  const filterNotLinkedToWorkspaceGroups = (groups) => {
    return groups.results.filter(checkIfGroupAlreadyInWorkspaceGroups);
  };

  const getEnvGroups = async (pageNumber = 0) => {
    if (isLoading === true) return;
    try {
      setIsLoading(true);
      const rawGroups = await api.account.listPlatformGroups(
        workspace.cdh_env_uri,
        { offset: pageNumber }
      );
      setPlatformGroups(filterNotLinkedToWorkspaceGroups(rawGroups));
      setTotal(rawGroups.total - workspace.dbx_groups.total);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRegister = async (groupuri) => {
    try {
      await api.databricks.registerGroupOnDatabricksWorkspace(
        groupuri,
        workspace.uuid
      );
      await reloadWorkspace();
      await getEnvGroups();
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getEnvGroups();
  }, [workspace.dbx_groups.groups]);

  return {
    error,
    isLoading,
    getEnvGroups,
    onRegister,
    platformGroups,
    total
  };
};

export { useDatabricksWorkspaceInviteGroups };
