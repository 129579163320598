/* @flow */

import React from "react";
import { getErrorMessage } from "../../utils/error";
import ErrorDetails from "./ErrorDetails";

type propTypesErrorContent = {
  error: string | Object,
  handleToggle: Function,
  isDetailsOpen?: boolean
};
const ErrorContent = ({
  error,
  handleToggle,
  isDetailsOpen
}: propTypesErrorContent) => (
  <div className={"error-content"}>
    <div
      data-testid="errorToggle"
      className={"error-details-button"}
      onClick={handleToggle}
    >
      Details{" "}
      <i
        className={`fas ${isDetailsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
      />
    </div>
    {isDetailsOpen && <ErrorDetails error={getErrorMessage(error)} />}
  </div>
);

export default ErrorContent;
