export const updateCartProvider = {
  query: `mutation Q ($cartUri: String!, $input: CartProviderInput!) {
        updateCartTrustRelationship(cartUri:$cartUri,input:$input) {
          arn
          description
        }
      }
    `,
  keyOutput: "updateCartTrustRelationship"
};
