import { UPDATE_MESSAGE } from "./types";

const initialState = {
  message: "",
  type: ""
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MESSAGE:
      return {
        ...state,
        message: action.value.message,
        type: action.value.type,
        popupDuration: action.value.popupDuration,
        date: new Date().getTime()
      };

    default:
      return state;
  }
}
