/* @flow */
import React from "react";
import SelectClassic from "../../../components/SelectClassic";

type Props = {
  columnLevelAccess: string,
  errors: {
    column_level_access: String
  },
  setFieldValue: Function
};

const COLUMN_ACCESS_LEVEL = ["Include", "Exclude"];

function FormFieldColumnAccessLevel({
  columnLevelAccess,
  errors,
  setFieldValue
}: Props) {
  return (
    <fieldset className="form-group">
      <legend className="label-form">Column Level Access:</legend>
      <SelectClassic
        placeholder={"Select a type"}
        options={COLUMN_ACCESS_LEVEL.map((levelAccess) => ({
          value: levelAccess,
          label: levelAccess
        }))}
        selectedOption={
          columnLevelAccess
            ? {
                value: columnLevelAccess,
                label: columnLevelAccess
              }
            : null
        }
        onSelectOption={(option) => {
          setFieldValue("column_level_access", option.value);
        }}
      />
      {errors.column_level_access && (
        <div className="error-msg">{errors.column_level_access}</div>
      )}
    </fieldset>
  );
}

export default FormFieldColumnAccessLevel;
