/* @flow */

import React from "react";
import { connect } from "react-redux";
import GraphQl from "../../../../graphQL";
import Col from "../../../../components/col";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Error from "../../../../components/error";
import ItemFromList from "../../../../components/itemFromList";
import ButtonAction from "../../../../components/buttonAction";
import GroupTrustName from "./groupTrustName";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  onSuccess: Function,
  groupTrust: {
    arn: string,
    description: String,
    status: string,
    type: string,
    is_admin: boolean,
    id: string,
    group: {
      name: string,
      uri: string
    },
    platform: {
      name: string,
      uri: string,
      aws: string
    }
  }
};

type stateTypes = {
  error?: Object
};

class GroupTrust extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  acceptTrustAttachToGroup = (groupTrust: Object) =>
    this.props.api.group
      .acceptTrustAttachToGroup(groupTrust.id, groupTrust.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust accepted !",
          type: "success"
        });
        this.props.onSuccess();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to accept trust",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  rejectTrustAttachToGroup = (groupTrust: Object) =>
    this.props.api.group
      .rejectTrustAttachToGroup(groupTrust.id, groupTrust.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message: "Trust rejected !",
          type: "success"
        });
        this.props.onSuccess();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to reject trust",
          type: "alert"
        });
        this.setState({
          error
        });
      });

  getActions = (groupTrust) => {
    if (groupTrust.status === "accepted") {
      return [
        <ButtonAction
          label="Reject"
          className="butn butn-delete"
          onClick={() => this.rejectTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    if (groupTrust.status === "pending") {
      return [
        <ButtonAction
          label="Accept"
          className="butn"
          onClick={() => this.acceptTrustAttachToGroup(groupTrust)}
        />,
        <ButtonAction
          label="Reject"
          className="butn butn-delete"
          onClick={() => this.rejectTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    if (groupTrust.status === "rejected") {
      return [
        <ButtonAction
          label="Accept"
          className="butn"
          onClick={() => this.acceptTrustAttachToGroup(groupTrust)}
        />
      ];
    }
    return false;
  };

  render() {
    return (
      <Col size={12}>
        {this.state.error && (
          <Error error={this.state.error} path="GroupTrusts" stringOnly />
        )}
        <ItemFromList
          key={
            this.props.groupTrust.arn +
            this.props.groupTrust.type +
            this.props.groupTrust.group.name
          }
          name={<GroupTrustName groupTrust={this.props.groupTrust} />}
          actions={this.getActions(this.props.groupTrust)}
        />
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(GroupTrust));
