/* @flow */

import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import GraphQl from "../../../../graphQL";
import Row from "../../../../components/row";
import Col from "../../../../components/col";
import Routes from "../../../routes";
import Error from "../../../../components/error";
import { updateMessage } from "../../../globalNotifications/actions";
import Breadcrumb from "../../../../components/breadcrumb";
import MultipleValueTextInput from "../../../../components/multipleValueTextInput";
import config from "../../../../config";
import Loading from "../../../../components/loading";
import { REGIONS } from "../../../../utils/constants";
import ToggleButton from "../../../../components/toggleButton";
import "./redshiftClusterForm.less";
import SelectInfinite from "../../../../components/SelectInfinite";

const Log = Logger("RedshiftClusterForm");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  match: {
    params: {
      uriAccount: string,
      uriPlayground: string
    }
  }
};

type stateTypes = {
  ready: boolean,
  error: Object,
  confirmPassword: string,
  personalNetworkSettings: boolean,
  groupError: boolean,
  isSingleNode: boolean,
  errorSubmit: Object,
  isSubmitting: boolean,
  selectedValue: {
    cluster_name: string,
    description: string,
    resource_name: string,
    platform_uri: string,
    cluster_type: string,
    node_type: string,
    number_of_nodes: Object,
    db_name: string,
    master_username: string,
    network_settings?: boolean,
    master_user_password: string,
    subnet_group_name: string,
    vpc_security_group_ids: Array<string>,
    iam_roles: Array<string>,
    kmsalias: string,
    owneruri: string,
    region: string,
    allow_version_upgrade: boolean
  },
  clusterTypes: Array<string>,
  nodeTypes: Array<{ label: string, value: string }>,
  totalGroups: number,
  groupsOffset: number
};

class RedshiftClusterForm extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    const clusterTypes = ["single-node", "multi-node"];
    const nodeTypes = [
      { label: "dc2.large", value: "dc2.large" },
      { label: "dc2.8xlarge", value: "dc2.8xlarge" },
      { label: "ra3.xlplus", value: "ra3.xlplus" },
      { label: "ra3.4xlarge", value: "ra3.4xlarge" },
      { label: "ra3.16xlarge", value: "ra3.16xlarge" },

      { label: "ds2.xlarge (deprecated)", value: "ds2.xlarge" },
      { label: "ds2.8xlarge (deprecated)", value: "ds2.8xlarge" },
      { label: "dc1.large (deprecated)", value: "dc1.large" },
      { label: "dc1.8xlarge (deprecated)", value: "dc1.8xlarge" }
    ];
    this.state = {
      clusterTypes,
      nodeTypes,
      ready: true,
      error: null,
      personalNetworkSettings: false,
      groupError: false,
      errorSubmit: false,
      isSubmitting: false,
      isSingleNode: true,
      confirmPassword: "",
      selectedValue: {
        cluster_name: "",
        description: "",
        resource_name: "",
        platform_uri: this.props.match.params.uriPlayground,
        cluster_type: clusterTypes[0],
        node_type: nodeTypes[0].value,
        number_of_nodes: 1,
        db_name: "",
        master_username: "",
        master_user_password: "",
        subnet_group_name: "",
        vpc_security_group_ids: [],
        iam_roles: [],
        kmsalias: "",
        owneruri: "",
        region: REGIONS[0].key,
        allow_version_upgrade: true
      },
      totalGroups: 0,
      groupsOffset: 0
    };

    if (
      this.state.selectedValue.subnet_group_name !== "" ||
      this.state.selectedValue.vpc_security_group_ids.length > 0
    ) {
      this.state.personalNetworkSettings = true;
    }
  }

  getGroups = (offset = 0) =>
    this.props.api.identity
      .listMyGroups({
        offset,
        filters: [
          { key: "account_uri", value: this.props.match.params.uriAccount },
          { key: "platform_uri", value: this.props.match.params.uriPlayground },
          { key: "exclude_service_groups", value: true }
        ]
      })
      .then((allMyGroups) => {
        const groups = allMyGroups.results;
        const newTotal = allMyGroups.total;

        if (this.state.groupsOffset === 0 && groups.length <= 0) {
          Log.error(
            "You need to be part of a group before creating a cluster...",
            "ClusterCreate"
          );
          this.setState({ groupError: true });
          return [];
        }

        this.setState({
          totalGroups: newTotal,
          groupsOffset: allMyGroups.offset + allMyGroups.limit
        });

        return groups.map((g) => ({ value: g.uri, label: g.name }));
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
      });

  handleChange(field, value) {
    if (field === "network_settings") {
      if (value) {
        this.setState({ personalNetworkSettings: true });
      } else {
        this.setState({ personalNetworkSettings: false });
        this.setState((prevState) => {
          const selectedValue = { ...prevState.selectedValue };
          selectedValue.subnet_group_name = "";
          selectedValue.vpc_security_group_ids = [];
          return { selectedValue };
        });
      }
    }
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      if (field === "cluster_name") {
        selectedValue.resource_name = this.slugifyClusterResourceName(value);
      }

      if (field === "cluster_type") {
        if (selectedValue[field] === "single-node") {
          selectedValue.number_of_nodes = 1;
          this.setState({ isSingleNode: true });
        } else {
          selectedValue.number_of_nodes = 2;
          this.setState({ isSingleNode: false });
        }
      }
      return { selectedValue };
    });
  }

  handleToggleChange() {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue.allow_version_upgrade =
        !selectedValue.allow_version_upgrade;
      return { selectedValue };
    });
  }

  slugifyClusterResourceName = (name = "") => {
    if (name === "") {
      return "";
    }
    const CDH_PREFIX = "cdh";
    return CDH_PREFIX.concat(
      name
        .trim()
        .replace(/[^a-zA-Z]/g, "")
        .toLowerCase()
    )
      .substring(0, 50)
      .concat(Math.random().toString(36).substring(8));
  };

  save() {
    const { selectedValue } = this.state;
    delete selectedValue.network_settings;

    this.setState({ errorSubmit: null, isSubmitting: true });

    return this.props.api.analytics
      .createRedshiftCluster(selectedValue.platform_uri, selectedValue)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Redshift cluster created",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Playground.Analytics}#analytics`,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriPlayground: this.props.match.params.uriPlayground
          }
        });
      })
      .catch((errorSubmit) => {
        Log.error(errorSubmit);
        this.setState({ errorSubmit, isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Redshift cluster creation failed",
          type: "alert"
        });
      });
  }

  render() {
    const { selectedValue, isSingleNode, error, ready, isSubmitting } =
      this.state;
    if (error) return <Error error={error} path={"RedshiftClusterForm"} />;
    if (!ready) return <Loading message={"Redshift Cluster Creation Form"} />;
    return (
      <div className="RedshiftClusterForm">
        <Breadcrumb view={"New Redshift Cluster"} />
        {this.state.ready && this.state.groupError ? (
          <div style={{ marginLeft: "30px" }}>
            {
              "You need to be part of a group before creating an analytics tool..."
            }
          </div>
        ) : (
          <Row>
            <Col size={1} />
            <Col size={9}>
              <Formik
                initialValues={{
                  cluster_name: selectedValue.cluster_name,
                  description: selectedValue.description,
                  resource_name: selectedValue.resource_name,
                  platform_uri: selectedValue.platform_uri,
                  cluster_type: selectedValue.cluster_type,
                  node_type: selectedValue.node_type,
                  number_of_nodes: selectedValue.number_of_nodes,
                  db_name: selectedValue.db_name,
                  master_username: selectedValue.master_username,
                  master_user_password: selectedValue.master_user_password,
                  subnet_group_name: selectedValue.subnet_group_name,
                  vpc_security_group_ids: selectedValue.vpc_security_group_ids,
                  iam_roles: selectedValue.iam_roles,
                  owneruri: selectedValue.owneruri,
                  region: selectedValue.region,
                  allow_version_upgrade: selectedValue.allow_version_upgrade
                }}
                validate={() => {
                  const errors = {};
                  if (
                    !selectedValue.owneruri ||
                    selectedValue.owneruri.length <= 0
                  ) {
                    errors.owneruri = "Owner is mandatory";
                  }
                  if (
                    !selectedValue.cluster_name ||
                    selectedValue.cluster_name.length <= 0
                  ) {
                    errors.cluster_name = "Cluster name is mandatory";
                  }
                  if (
                    !selectedValue.cluster_type ||
                    selectedValue.cluster_type.length <= 0
                  ) {
                    errors.cluster_type = "Cluster type is mandatory";
                  }

                  if (
                    !selectedValue.node_type ||
                    selectedValue.node_type.length <= 0
                  ) {
                    errors.node_type = "Cluster node type is mandatory";
                  }

                  if (
                    !selectedValue.region ||
                    selectedValue.region.length <= 0
                  ) {
                    errors.region = "Cluster region type is mandatory";
                  }

                  if (
                    !selectedValue.number_of_nodes ||
                    selectedValue.number_of_nodes.length <= 0
                  ) {
                    errors.number_of_nodes =
                      "Cluster number of nodes is mandatory";
                  }

                  if (
                    selectedValue.number_of_nodes > 1 &&
                    selectedValue.cluster_type === "single-node"
                  ) {
                    errors.number_of_nodes =
                      "Single node cluster can have only 1 node";
                  }

                  if (
                    selectedValue.number_of_nodes < 2 &&
                    selectedValue.cluster_type === "multi-node"
                  ) {
                    errors.number_of_nodes =
                      "Multi node cluster must have more than 1 node";
                  }

                  if (
                    !selectedValue.db_name ||
                    selectedValue.db_name.length <= 0
                  ) {
                    errors.db_name = "Database name is mandatory";
                  }

                  if (
                    !selectedValue.master_username ||
                    selectedValue.master_username.length <= 0
                  ) {
                    errors.master_username = "Username is mandatory";
                  }

                  // eslint-disable-next-line no-useless-escape
                  const pattern =
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!#\$%\^\&*\)\(+=._-]{8,64}$/;
                  if (
                    !selectedValue.master_user_password ||
                    selectedValue.master_user_password.length <= 0
                  ) {
                    errors.master_user_password = "Password is mandatory";
                  } else if (
                    pattern.test(selectedValue.master_user_password) !== true
                  ) {
                    errors.master_user_password =
                      "The password must follow the requirements";
                  }

                  if (
                    selectedValue.master_user_password !==
                    this.state.confirmPassword
                  ) {
                    errors.master_user_password =
                      "The two passwords must match";
                  }

                  if (selectedValue.iam_roles.length <= 0) {
                    errors.iam_roles = "IAM Role is mandatory";
                  }
                  Log.info("validate", selectedValue, errors);
                  return errors;
                }}
                onSubmit={() => {
                  this.save();
                }}
              >
                {
                  <Form>
                    {this.state.errorSubmit && (
                      <Error
                        stringOnly
                        error={this.state.errorSubmit}
                        path={"create"}
                      />
                    )}
                    <div className={"alert alert-light"}>
                      <i className="fas fa-exclamation-circle" />
                      &nbsp;Requirements:&nbsp;
                      <div>
                        Prerequisites for Amazon Redshift cluster
                        creation,&nbsp;
                        <a
                          href={`${config.HELP_CENTER}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          see on Helpcenter{" "}
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                    <div className={"alert alert-light"}>
                      <i className="fas fa-exclamation-circle" />
                      &nbsp;Warning:&nbsp;
                      <div>
                        By default, the cluster will be created on commonDataHub
                        shared subnets.
                        <a
                          href={`${config.HELP_CENTER}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info on Helpcenter{" "}
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <div>
                        If you dont want use commonDataHub shared subnets, you
                        can configure your own network settings in this form.
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="mb-3">
                        <div>
                          <label className="label-form">Owner Group</label>

                          <SelectInfinite
                            key={"select-infinite-redshift-group"}
                            placeholder={"Select a group"}
                            totalOptions={this.state.totalGroups}
                            loadMoreOptions={this.getGroups}
                            onSelectOption={(groupUri) =>
                              this.handleChange("owneruri", groupUri)
                            }
                          />
                        </div>
                        <ErrorMessage
                          name="owneruri"
                          component="div"
                          className="error-msg"
                        />
                      </div>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Cluster Name
                        </legend>
                        <Field
                          type="cluster_name"
                          name="cluster_name"
                          className="form-control bg-white"
                          placeholder="Cluster Name"
                          onChange={(event) =>
                            this.handleChange(
                              "cluster_name",
                              event.target.value
                            )
                          }
                          value={selectedValue.cluster_name}
                        />
                        <ErrorMessage
                          name="cluster_name"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          AWS Redshift cluster identifier
                        </legend>
                        <small className="attribute-label">
                          This is the unique key that identifies a cluster on
                          AWS.
                        </small>
                        <Field
                          name="cluster_resource_name"
                          disabled
                          className="form-control bg-grey"
                          placeholder="Cluster Identifier"
                          maxlength="63"
                          value={selectedValue.resource_name}
                        />
                        <small className="attribute-label">
                          The identifier must be from 1 to 63 characters. Valid
                          characters are a-z (lowercase only) and - (hyphen).
                        </small>
                        <ErrorMessage
                          name="resource_name"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Cluster Description
                        </legend>
                        <Field
                          component="textarea"
                          name="description"
                          className="form-control bg-white"
                          placeholder="Description..."
                          onChange={(event) =>
                            this.handleChange("description", event.target.value)
                          }
                          value={selectedValue.description}
                          rows={"3"}
                        />
                      </fieldset>

                      <div className="mb-3">
                        <label className="label-form cluster-label-form">
                          Cluster Type
                        </label>
                        <select
                          name="cluster_type"
                          onChange={(event) =>
                            this.handleChange(
                              "cluster_type",
                              event.target.value
                            )
                          }
                          className="form-control form-control-sm"
                          value={selectedValue.cluster_type}
                        >
                          {[
                            <option key={"default"} value={"default"} disabled>
                              {"Select a cluster type"}
                            </option>
                          ].concat(
                            this.state.clusterTypes.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="mb-3">
                        <label className="label-form cluster-label-form">
                          Node Type
                        </label>
                        <div>
                          <small className="attribute-label">
                            Choose a node type that meets your CPU, RAM, storage
                            capacity, and drive type requirements.
                            <a
                              href={
                                "https://docs.aws.amazon.com/redshift/latest/mgmt/working-with-clusters.html#rs-about-clusters-and-nodes"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              See AWS node types documentation{" "}
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </small>
                        </div>
                        <select
                          name="node_type"
                          onChange={(event) =>
                            this.handleChange("node_type", event.target.value)
                          }
                          className="form-control form-control-sm"
                          value={selectedValue.node_type}
                        >
                          {[
                            <option key={"default"} value={"default"} disabled>
                              {"Select a node type"}
                            </option>
                          ].concat(
                            this.state.nodeTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Number of Nodes
                        </legend>
                        <div>
                          <small className="attribute-label">
                            <a
                              href={"https://aws.amazon.com/redshift/pricing/"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Learn more about Redshift pricing{" "}
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </small>
                        </div>
                        <Field
                          type="number"
                          name="number_of_nodes"
                          disabled={isSingleNode}
                          className="form-control bg-white"
                          placeholder="Number of Nodes"
                          onChange={(event) =>
                            this.handleChange(
                              "number_of_nodes",
                              event.target.value
                            )
                          }
                          value={selectedValue.number_of_nodes}
                        />
                        <small className="attribute-label">Range (1-128)</small>
                        <ErrorMessage
                          name="number_of_nodes"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Version upgrade
                        </legend>
                        <div className="allow-upgrade-version">
                          <ToggleButton
                            id="allow_version_upgrade_id"
                            checkedValue={!!selectedValue.allow_version_upgrade}
                            onChange={() => this.handleToggleChange()}
                          />
                        </div>
                        <div className="help-version-upgrade">
                          <small className="attribute-label">
                            Major version upgrades are applied during
                            maintenance window to the engine that is running on
                            the cluster when enabled
                          </small>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <legend className="label-form cluster-label-form">
                          Network Settings
                        </legend>
                        <div className={"alert alert-light"}>
                          <i className="fas fa-exclamation-circle" />
                          &nbsp;Warning:&nbsp;
                          <div>
                            It's not recommended by Engie security team to use
                            public subnets
                          </div>
                        </div>
                        <fieldset className="form-group">
                          <div className="facet-criteria-name">
                            <input
                              id="network"
                              type="checkbox"
                              className={"checkbox-facet"}
                              onClick={(event) =>
                                this.handleChange(
                                  "network_settings",
                                  event.target.checked
                                )
                              }
                            />
                            <label htmlFor="network" className="fas">
                              <small className="attribute-label">
                                I want to use my personal network settings
                              </small>
                            </label>
                          </div>
                        </fieldset>
                        {this.state.personalNetworkSettings && (
                          <div className="network-settings">
                            <fieldset className="form-group">
                              <legend className="label-form cluster-label-form">
                                Subnet Group Name
                              </legend>
                              <Field
                                type="subnet_group_name"
                                name="subnet_group_name"
                                className="form-control bg-white"
                                placeholder="Subnet Group Name"
                                onChange={(event) =>
                                  this.handleChange(
                                    "subnet_group_name",
                                    event.target.value
                                  )
                                }
                                value={selectedValue.subnet_group_name}
                              />
                              <ErrorMessage
                                name="subnet_group_name"
                                component="div"
                                className="error-msg"
                              />
                            </fieldset>

                            <fieldset className="form-group">
                              <MultipleValueTextInput
                                onItemAdded={(e) =>
                                  this.handleChange(
                                    "vpc_security_group_ids",
                                    e.target.value
                                  )
                                }
                                onItemDeleted={(e) =>
                                  this.handleChange(
                                    "vpc_security_group_ids",
                                    e.target.value
                                  )
                                }
                                values={selectedValue.vpc_security_group_ids}
                                label={"VPC Security Groups"}
                                name={"vpc_security_group_ids"}
                                placeholder={
                                  "Add security group ids, separate them by COMMA or ENTER"
                                }
                                classNameInput={"form-control bg-white"}
                                shouldAddOnBlur
                              />
                              <ErrorMessage
                                name="vpc_security_group_ids"
                                component="div"
                                className="error-msg"
                              />
                            </fieldset>
                          </div>
                        )}
                      </div>

                      {this.state.personalNetworkSettings ? (
                        <div className="mb-3">
                          <label className="label-form cluster-label-form">
                            Cluster Region
                          </label>
                          <select
                            onChange={(event) =>
                              this.handleChange("region", event.target.value)
                            }
                            value={selectedValue.region}
                            className="form-control form-control-sm"
                          >
                            {REGIONS.map((region) => (
                              <option key={region.key} value={region.key}>
                                {`(${region.key})  ${region.label}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="mb-3">
                          <label className="label-form cluster-label-form">
                            Cluster Region
                          </label>
                          <select
                            disabled
                            value="eu-west-1"
                            className="form-control form-control-sm"
                          >
                            <option value="eu-west-1">
                              (eu-west-1) Ireland
                            </option>
                          </select>
                        </div>
                      )}

                      <fieldset className="form-group">
                        <MultipleValueTextInput
                          onItemAdded={(e) =>
                            this.handleChange("iam_roles", e.target.value)
                          }
                          onItemDeleted={(e) =>
                            this.handleChange("iam_roles", e.target.value)
                          }
                          values={selectedValue.iam_roles}
                          label={"IAM Roles"}
                          name={"iam_roles"}
                          placeholder={
                            "Add IAM roles, separated by COMMA or ENTER"
                          }
                          classNameInput={"form-control bg-white"}
                          shouldAddOnBlur
                        />
                        <small className="attribute-label">
                          The role must be arn (example:
                          arn:aws:iam::52250xxxxxxxxxx)
                        </small>
                        <ErrorMessage
                          name="iam_roles"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Database Name
                        </legend>
                        <Field
                          type="db_name"
                          name="db_name"
                          className="form-control bg-white"
                          placeholder="Database Name"
                          maxlength="64"
                          onChange={(event) =>
                            this.handleChange("db_name", event.target.value)
                          }
                          value={selectedValue.db_name}
                        />
                        <small className="attribute-label">
                          The name must be 1-64 alphanumeric characters
                          (lowercase only), and it can't be a reserved word.
                        </small>
                        <ErrorMessage
                          name="db_name"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Master Username
                        </legend>
                        <small className="attribute-label">
                          Enter a login ID for the master user of your DB
                          instance.
                        </small>
                        <Field
                          type="master_username"
                          name="master_username"
                          className="form-control bg-white"
                          placeholder="Master Username"
                          maxlength="128"
                          onChange={(event) =>
                            this.handleChange(
                              "master_username",
                              event.target.value
                            )
                          }
                          value={selectedValue.master_username}
                        />
                        <small className="attribute-label">
                          The name must be 1-128 alphanumeric characters, and it
                          can't be a reserved word.
                        </small>
                        <ErrorMessage
                          name="master_username"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="label-form cluster-label-form">
                          Master User Password
                        </legend>
                        <Field
                          type="password"
                          name="master_user_password"
                          className="form-control bg-white"
                          placeholder="Master User Password"
                          maxlength="64"
                          onChange={(event) =>
                            this.handleChange(
                              "master_user_password",
                              event.target.value
                            )
                          }
                          value={selectedValue.master_user_password}
                        />
                        <div className="mt-2">
                          <Field
                            type="password"
                            name="confirmPassword"
                            className="form-control bg-white"
                            placeholder="Confirm Password"
                            maxlength="64"
                            onChange={(event) =>
                              this.setState({
                                confirmPassword: event.target.value
                              })
                            }
                          />
                        </div>
                        <div>
                          <div>
                            <small className="attribute-label">
                              The value must be 8-64 characters.
                            </small>
                          </div>
                          <div>
                            <small className="attribute-label">
                              The value must contain at least one uppercase
                              letter and at least one lowercase letter and at
                              least one number.
                            </small>
                          </div>
                          <div>
                            <small className="attribute-label">
                              The master password can only contain ASCII
                              characters (ASCII codes 33-126), except ' (single
                              quotation mark), " (double quotation mark), /, \,
                              or @.
                            </small>
                          </div>
                        </div>
                        <ErrorMessage
                          name="master_user_password"
                          component="div"
                          className="error-msg"
                        />
                      </fieldset>

                      <div className={"ml-1 row justify-content-center"}>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="butn"
                        >
                          {isSubmitting && (
                            <i className="fas fa-circle-notch fa-spin fa-spacing" />
                          )}
                          Create
                        </button>
                      </div>
                    </div>
                  </Form>
                }
              </Formik>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(RedshiftClusterForm));
