export const updateDatabricksAccountCredentialsAuto = {
  query: `mutation Q($uuid: String!) {
          updateDatabricksAccountCredentialsAuto(uuid: $uuid) {
            error
            status
          }
        }
        `,
  keyOutput: "updateDatabricksAccountCredentialsAuto"
};
