/* @flow */
import React from "react";

type propTypes = {
  message: string
};

class Loading extends React.Component<propTypes> {
  message() {
    if (!this.props.message) return false;
    return `Loading ${this.props.message}`;
  }

  render() {
    return (
      <div
        className={"default-color my-3"}
        style={{ width: "100%", marginTop: "20px" }}
      >
        <div
          style={{
            margin: "0 auto",
            width: "50px",
            opacity: "0.5"
          }}
        >
          <i className="fas fa-circle-notch fa-spin fa-2x" />
        </div>
        <div
          style={{
            margin: "0 auto",
            width: "200px",
            textAlign: "center"
          }}
        >
          {this.message()}
        </div>
      </div>
    );
  }
}

export default Loading;
