/* @flow */
import React from "react";
import "./style.less";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

type propTypes = {
  metadata: Object
};

class DisplayMetadataString extends React.PureComponent<propTypes> {
  render() {
    if (!this.props.metadata) {
      return false;
    }
    const options = {
      chart: { type: "column", height: 200, width: 400 },
      title: { text: "" },
      xAxis: {
        categories: this.props.metadata.Histogram.map((r) => r.name)
      },
      yAxis: {
        title: ""
      },
      series: [
        {
          name: "Frequency",
          data: this.props.metadata.Histogram.map((r) => [
            r.name,
            parseFloat(r.nbtimes)
          ])
        }
      ]
    };
    return (
      <div className="content-analysis-container">
        <div>
          <div className="metadata-title">
            <span>Content analysis</span>
          </div>
          <div className="metadata-container">
            <div className="metadata-date-container">
              {this.props.metadata.MostCommon && (
                <div>
                  <span className="metadata-prop">Most common</span>
                  <span className="metadata-value">
                    {this.props.metadata.MostCommon.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="metadata-graph-container">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayMetadataString;
