export const getHomepageKPIs = {
  query: `query Q {
        getHomepageKPIs {
           id
           code
           name
           category
           value
        }
        
        }
 
    `,
  keyOutput: "getHomepageKPIs"
};
