import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../globalNotifications/actions";
import Links from "../links";
import Loading from "../../components/loading";
import routes from "../routes";
import withAppSync from "../AppsyncHOC";
import Error from "../../components/error";
import GraphQl from "../../graphQL";
import withGoTo from "../goToHOC";
import Paginator from "../../components/paginator";
import "./list.less";
import logoDatabricks from "../logos/databricks-logo-engie.png";

class DatabricksInstanceList extends React.Component {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      databricksInstances: [],
      error: false,
      total: 0
    };
  }

  async componentDidMount() {
    this.searchDatabricks();
  }

  onChangeInput = (e) => {
    const keywords = e.target.value;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          ready: false,
          keywords
        },
        this.searchDatabricks
      );
    }, 500);
  };

  searchDatabricks = (offset = 0) => {
    const options = {
      limit: 10,
      offset,
      search: undefined
    };

    if (this.state.keywords) {
      options.search = {
        name: this.state.keywords,
        description: this.state.keywords,
        tags: this.state.keywords
      };
    }

    return this.props.api.databricks
      .listAccountDatabricksAccounts(
        this.props.match.params.uriAccount,
        options
      )
      .then((items) => {
        if (!items) return false;
        this.setState({
          ready: true,
          databricksInstances: items.results,
          total: items.total
        });
        return items;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getInstancesFromPagination = (offset) => this.searchDatabricks(offset);

  renderContent() {
    if (this.state.error)
      return <Error error={this.state.error} path="Databricks instances" />;
    if (!this.state.ready) return <Loading message="Databricks instances" />;

    return (
      <div className="card-container">
        <Paginator
          limit={10}
          initialOffset={0}
          totalCount={this.state.total}
          list={this.state.databricksInstances}
          componentRender={(databricksInstance) => (
            <div
              key={databricksInstance.uri}
              className={"cart-card card-shadow card bg-white"}
            >
              <div className="card-datasource-header">
                <div className="cdh-card-begin">
                  <div className="cdh-card-begin-content">
                    <img
                      className="connector-logo-img"
                      src={logoDatabricks}
                      alt="Databricks"
                      width={15}
                      height={15}
                    />{" "}
                    Databricks Instance
                  </div>
                </div>
              </div>
              <div className="card-datasource-body">
                <div className="card-environment-body-left">
                  <div className={"text-capitalize datasource-card-name"}>
                    <Links.Databricks.DetailsAccount
                      uriAccount={this.props.match.params.uriAccount}
                      uuid={`${databricksInstance.uuid}`}
                    >
                      {databricksInstance.dbx_account_name}
                    </Links.Databricks.DetailsAccount>
                  </div>
                </div>
                <div className="card-environment-body-right">
                  <div className="card-datasource-body-actions">
                    <div className="btn-cart">
                      <Links.Databricks.DetailsAccount
                        uriAccount={this.props.match.params.uriAccount}
                        uuid={`${databricksInstance.uuid}`}
                      >
                        <i className="fas fa-play fa-spacing" />
                        <span className="text-actions">View Details</span>
                      </Links.Databricks.DetailsAccount>
                    </div>
                    <div className="btn-cart">
                      <Links.Databricks.Groups
                        uriAccount={this.props.match.params.uriAccount}
                        uriDatabricksInstance={`${databricksInstance.uri}`}
                        state={{ databricksInstance }}
                      >
                        <i className="fas fa-users-cog fa-spacing" />
                        <span className="text-actions">Manage Groups</span>
                      </Links.Databricks.Groups>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          loadPage={this.getInstancesFromPagination}
          onNoResult={() => (
            <p className="total-search-results">No instances found</p>
          )}
        />
      </div>
    );
  }
  render = () => (
    <div className="organizations-list">
      <div className="header">
        <div className="search">
          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search Databricks instance !"
              onChange={this.onChangeInput}
            />
            <i className="fas fa-search" />
          </div>
          <div className="create-container">
            <div className="new-dataset">
              <div
                className="butn butn-create"
                onClick={() =>
                  this.props.goTo({
                    route: routes.Databricks.Create,
                    params: {
                      uriAccount: this.props.match.params.uriAccount
                    }
                  })
                }
              >
                <i className="fas fa-plus butn-icon" />
                <span className="butn-text">New Databricks instance</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.renderContent()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatabricksInstanceList)));
