export const listAccessesToTemplates = {
  query: `query Q ($environmentUri: String!, $options: QueryOptions) {
        listAccessesToTemplates(platformUri: $environmentUri, options: $options) {
            offset
            limit
            total
            results {
                id
                monitoring_email
                platform {
                    uri 
                    name
                }
                group {
                    uri
                    name
                }
                network {
                    id
                    name
                    description
                }
                template_projects {
                    cart {
                        uri
                        name
                    }
                }
            }
        }
      }
    `,
  keyOutput: "listAccessesToTemplates"
};
