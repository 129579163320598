/* @flow */

import React from "react";
import config from "../../../config";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import SearchDataset from "../components/searchDatasets";
import "./style.less";
import HelpItem from "./HelpItem";
import GraphQl from "../../../graphQL";
import Breadcrumb from "../../../components/breadcrumb";
import Col from "../../../components/col";
import Facets from "../components/Facets";
import NewDataset from "./NewDataset";
import ListDatasets from "./List";
import Logger from "../../../utils/logger";
import type {
  FacetFilter,
  SearchInput,
  SearchSortOption
} from "../../../graphQL/propstypes";

const Log = Logger("Catalog");

type propTypes = {
  api: GraphQl,
  location: {
    state: Object,
    search: string,
    pathname: string
  },
  history: {
    replace: Function,
    push: Function
  }
};

type stateTypes = {
  ready: boolean,
  readyFacets: boolean,
  error?: Object,
  errorOnFacets?: Object,
  datasets: Array<Object>,
  activeFilters: Array<FacetFilter>,
  categoriesFacets: Array<Object>,
  total: number,
  term: string,
  sort: SearchSortOption,
  offset: number,
  searchOptions: Object
};

class Catalog extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      readyFacets: false,
      error: false,
      errorOnFacets: false,
      datasets: [],
      categoriesFacets: [],
      total: 0,
      term: this.getTerm(this.props.location),
      activeFilters: this.getActiveFilters(this.props.location),
      sort: (this.props.location.state && this.props.location.state.sort) || {
        field: "nb_shares",
        direction: "desc"
      },
      offset: 0,
      searchOptions: {}
    };
  }

  splitUrlParams = (location) =>
    location.search
      .slice(1)
      .split("&")
      .map((split1) => split1.split("="))
      .filter((el) => el.length === 2) // when malformed parameters like "catalog?&s=search"
      .map((filter) => ({
        field: filter[0],
        values: filter[1].split(",").map((r) => decodeURIComponent(r.trim()))
      }));

  getActiveFilters = (location) => {
    if (location.search) {
      return this.splitUrlParams(location).filter(
        (params) => params.field !== "s"
      );
    }

    if (location.state && location.state.activeFilters.length > 0) {
      return location.state.activeFilters;
    }

    return [{ field: "visibility", values: ["Public"] }];
  };

  getTerm = (location) => {
    if (location.search) {
      const term = this.splitUrlParams(location).find(
        (params) => params.field === "s"
      );
      if (term && term.values) {
        return term.values[0];
      }
    }

    if (location.state && location.state.term) {
      return location.state.activeFilters;
    }

    return "";
  };

  componentDidMount() {
    this.search();
  }

  onChangeFilters = (activeFilters) => {
    this.setState({
      activeFilters,
      offset: 0
    });
  };

  onError = (error) => {
    this.setState({
      error,
      ready: true
    });
  };

  onChangeTerm = (term) => {
    this.setState({
      term,
      offset: 0
    });
  };

  onChangeSort = (field, direction) => {
    this.setState(
      {
        sort: { field, direction },
        offset: 0
      },
      this.search
    );
  };

  onChangeOffset = (offset) =>
    new Promise((r) => this.setState({ offset }, () => this.search().then(r)));

  updateSearchUrl = () => {
    const { activeFilters, term } = this.state;
    let search = activeFilters
      .filter(
        (activeFilter) =>
          !["is_bookmarked", "access"].includes(activeFilter.field)
      )
      .map(
        (activeFilter) =>
          `${activeFilter.field}=${activeFilter.values.join(",")}`
      )
      .join("&");
    if (term) {
      if (search) {
        search = `${search}&s=${term}`;
      } else {
        search = `s=${term}`;
      }
    }
    const to = `${this.props.location.pathname}?${search}`;
    this.props.history.push(to, {
      term: this.state.term,
      activeFilters: this.state.activeFilters,
      sort: this.state.sort
    });
  };

  search = () =>
    new Promise((r) =>
      this.setState({ ready: false, readyFacets: false }, () => {
        Log.verbose("search dataset start...");
        const options: SearchInput = {
          limit: 10
        };

        if (this.state.offset) {
          options.offset = this.state.offset;
        }

        if (this.state.term) {
          options.term = this.state.term;
        }

        if (this.state.activeFilters) {
          options.filters = this.state.activeFilters;
        }

        if (this.state.sort) {
          options.order_by = this.state.sort;
        }

        this.updateSearchUrl();

        this.setState({ searchOptions: options });

        this.searchFacets(options);
        r(this.searchResult(options));
      })
    );

  searchResult = (options) =>
    this.props.api.dataset
      .search(options)
      .then((searchResult) => {
        if (!searchResult) return false;
        Log.verbose("result search", options, searchResult);
        this.setState({
          datasets: searchResult.results,
          total: searchResult.total,
          ready: true
        });
        return searchResult.results;
      })
      .catch((error) => {
        this.onError(error);
        return [];
      });

  searchFacets = (options) =>
    this.props.api.dataset
      .searchFacets(options)
      .then((searchFacets) => {
        Log.verbose("result facets search", options, searchFacets);
        if (searchFacets) {
          this.setState({
            categoriesFacets: searchFacets,
            readyFacets: true
          });
        }
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ errorOnFacets: error });
        return [];
      });

  render() {
    if (this.state.error) {
      return (
        <div className="catalog-error">
          <div className="catalog-error-title">Catalog not available</div>
          <br />
          <div className="catalog-error-comment">
            Reload page or Retry in a moment, Please.
          </div>
        </div>
      );
    }

    return (
      <div className="dataset-list">
        <div className="d-flex">
          <Facets
            categoriesFacets={this.state.categoriesFacets}
            activeFilters={this.state.activeFilters}
            onChangeFilters={this.onChangeFilters}
            runSearch={this.search}
            isLoading={!this.state.readyFacets}
            errorOnFacets={this.state.errorOnFacets}
            onChangeSort={this.onChangeSort}
            field={this.state.sort.field}
            direction={this.state.sort.direction}
          />
          <Col size={7} className={"col-md-8 noPadRight"}>
            <Breadcrumb
              view="Dataset Catalog"
              helpOn={{
                content: <HelpItem />,
                helpCenterLink: `${config.HELP_CENTER}`
              }}
              hideGoBack
            />
            <div className="search-line">
              <SearchDataset
                onChangeTerm={this.onChangeTerm}
                term={this.state.term}
                runSearch={this.search}
              />
              <NewDataset />
            </div>
            <div className="ml-2">
              {!this.state.ready ? (
                <Loading message={"Catalog"} />
              ) : (
                <ListDatasets
                  datasets={this.state.datasets}
                  total={this.state.total}
                  onChangeOffset={this.onChangeOffset}
                  offset={this.state.offset}
                  searchOptions={this.state.searchOptions}
                />
              )}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default withAppSync(Catalog);
