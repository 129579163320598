/* @flow */
import React from "react";
import classnames from "classnames";
import { Field } from "formik";

type Props = {
  description: string,
  errors: Object,
  setFieldValue: Function,
  characterslimit: number
};

function FormFieldDescription({
  description,
  errors,
  setFieldValue,
  characterslimit
}: Props) {
  return (
    <fieldset className="form-group">
      <legend className="label-form">
        description:
        <div
          className={classnames("filter-description", {
            "description-is-too-short":
              (description || "").length < characterslimit,
            "description-is-good": (description || "").length >= characterslimit
          })}
        >
          {`(${(description || "").length}/${characterslimit} characters
      min)`}
        </div>
      </legend>
      <div className="label-form-name">
        <Field
          name="description"
          className="form-control bg-white"
          placeholder="Description"
          onChange={(event) => setFieldValue("description", event.target.value)}
          value={description}
          rows={4}
          component="textarea"
        />
      </div>
      {errors.description && (
        <div className="error-msg">{errors.description}</div>
      )}
    </fieldset>
  );
}

export default FormFieldDescription;
