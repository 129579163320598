/* @flow */
import React, { useState, Fragment } from "react";
import ExpressionRule from "./ExpressionRule";
import "./datafilter-expression-builder.css";

// type Rule = {
//   column: string,
//   operator: string,
//   columnValue: string,
//   booleanOperator: string
// }

type Props = {
  availableColumns: Array<Object>,
  columnTypes: Array<Object>,
  rules: Array<Object>,
  setFieldValue: Function,
  setRules: Function
};

function removeArrayItemAtIndex(arr, at) {
  return arr.filter((value, i) => i !== at);
}

export default function DataFilterExpressionQueryBuilder({
  availableColumns,
  columnTypes,
  rules,
  setFieldValue,
  setRules
}: Props) {
  const [expressionStringPreview, setExpressionStringPreview] = useState("");

  function getExpressionString(rulesToConvert) {
    return rulesToConvert
      .map((r) => {
        const nonStringTypes = [
          "int",
          "long",
          "bigint",
          "float",
          "decimal",
          "double",
          "boolean"
        ];
        const seekType = columnTypes.find((i) => i.Name === r.column);
        // no string type
        if (seekType && nonStringTypes.includes(seekType.Type.toLowerCase())) {
          return `${r.booleanOperator} "${r.column}" ${r.operator} ${r.columnValue}`;
        }
        // string value
        return `${r.booleanOperator} "${r.column}" ${r.operator} '${r.columnValue}'`;
      })
      .join(" ");
  }

  function addNew() {
    setRules(
      rules.concat([
        {
          column: "",
          operator: "=",
          columnValue: "",
          columnType: null,
          booleanOperator: rules.length === 0 ? "" : "AND"
        }
      ])
    );
  }

  function updateRule({ rule, index }) {
    const newRules = rules.map((r, i) => {
      if (i === index) {
        return rule;
      }
      return r;
    });
    setRules(newRules);
    setExpressionStringPreview(getExpressionString(newRules));

    const rowFilterExpression = getExpressionString(newRules);
    const rowFilterExpressionSimpleMode = JSON.stringify(newRules);
    setFieldValue("row_filter_expression", rowFilterExpression);
    setFieldValue(
      "row_filter_expression_simple_mode",
      rowFilterExpressionSimpleMode
    );
  }

  function removeRule(index) {
    const newRules = [...removeArrayItemAtIndex(rules, index)].map(
      (rule, localIndex) => {
        if (localIndex === 0) {
          rule.booleanOperator = "";
        }
        return rule;
      }
    );
    setRules(newRules);
    setExpressionStringPreview(getExpressionString(newRules));

    const rowFilterExpression = getExpressionString(newRules);
    const rowFilterExpressionSimpleMode = JSON.stringify(newRules);
    setFieldValue("row_filter_expression", rowFilterExpression);
    setFieldValue(
      "row_filter_expression_simple_mode",
      rowFilterExpressionSimpleMode
    );
  }

  return (
    <Fragment>
      <div className="expression-builder">
        {rules.map((rule, index) => {
          return (
            <ExpressionRule
              key={`rule-${index}`}
              columnTypes={columnTypes}
              rule={rule}
              availableColumns={availableColumns}
              onRuleChange={(r) => updateRule({ rule: r, index })}
              onRemove={() => removeRule(index)}
            />
          );
        })}
      </div>
      <div className="expression-builder-add-rule-container">
        <div className="expression-builder-add-rule" onClick={addNew}>
          <div className="expression-builder-add-rule-icon"></div>
          ADD EXPRESSION
        </div>
      </div>
      {expressionStringPreview ? (
        <div style={{ paddingTop: "15px" }}>
          Result : <br />
          <pre
            style={{
              lineHeight: "30px",
              background: "#ccc",
              borderBottom: "2px solid #00518c"
            }}
          >
            {expressionStringPreview}
          </pre>
        </div>
      ) : null}
    </Fragment>
  );
}
