export const publishRedshiftServerlessStages = {
  query: `
        mutation Q (
            $datasetUri: String!
        ) 
        {
            refreshDatasetStages(
                datasetUri: $datasetUri
            ){
                id
                name
                table
            }
        }
    `,
  keyOutput: "refreshDatasetStages"
};
