import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Links from "../../containers/links";
import Row from "../row";
import Col from "../col";
import "./style.less";

class ItemFromList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAction: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.state.isOpenAction &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({ isOpenAction: false });
    }
  };

  handleClick =
    (callback = () => {}) =>
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      return callback();
    };

  onClick = () => {
    this.setState((prevState) => ({
      isOpenAction: !prevState.isOpenAction
    }));
  };

  actionsButtonDropdown() {
    if (this.props.actions.length <= 0) return false;

    if (this.props.actions.length === 1) return this.props.actions[0];

    return (
      <div ref={this.setWrapperRef} className="dropdown">
        <button
          className="butn butn-flat dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          onClick={this.handleClick(this.onClick)}
        >
          <span>Actions</span>
        </button>
        <div
          className={classnames("dropdown-menu", {
            "is-active": this.state.isOpenAction
          })}
        >
          {this.props.actions.map((action) => (
            <div className="dropdown-item" key={Math.random()}>
              {action}
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { className, onClick, name, description, dataset } = this.props;

    return (
      <li
        className={classnames("list-group-item bg-white", className)}
        style={{ padding: 0 }}
      >
        <div
          style={{
            heigth: "30px",
            padding: "10px",
            cursor: onClick ? "pointer" : "default"
          }}
          onClick={this.handleClick(() => {
            if (onClick) onClick();
          })}
        >
          {this.props.fasIcon && <i className={this.props.fasIcon} />}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex" }}>
                {this.props.isLink && (
                  <Links.Cart.View cartUri={this.props.uri}>
                    <span className="text-header-cart">{name}</span>
                  </Links.Cart.View>
                )}
                {!this.props.isLink && (
                  <div style={{ flex: 1 }}>
                    <span className="subtitle">{name} </span>
                  </div>
                )}
              </div>
            </div>
            {this.props.actions.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  marginLeft: "40px"
                }}
                onClick={this.handleClick()}
              >
                {this.actionsButtonDropdown()}
              </div>
            )}
          </div>
          {description &&
            ((typeof description === "string" &&
              description.trim().length > 0) ||
              typeof description === "object") && (
              <Row>
                <Col size={9} className={"wrap-word"}>
                  {typeof description === "object" || description.length < 125
                    ? description
                    : `${description.substring(0, 125)}..`}
                </Col>
              </Row>
            )}

          {dataset && (
            <div className="moreInfoList">
              <span className="titleMoreInfo">Datasource Info: </span>
              <span>
                <b>Name:</b> {dataset.datasource.name} -{" "}
              </span>
              <span>
                <b>Owner:</b> {dataset.datasource.owner.name} -{" "}
              </span>
              <span>
                <b>Bucket:</b> S3://{dataset.datasource.bucket}
              </span>
            </div>
          )}
        </div>
      </li>
    );
  }
}

ItemFromList.propTypes = {
  actions: PropTypes.array,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataset: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  uri: PropTypes.string,
  isLink: PropTypes.bool,
  fasIcon: PropTypes.string
};

ItemFromList.defaultProps = {
  actions: [],
  name: "-",
  description: "",
  uri: "",
  isLink: false
};

export default ItemFromList;
