import { useState } from "react";

const useDatabricksWorkspaceCreationModal = (
  api,
  platform,
  reloadWorkspaces
) => {
  const [error, setError] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSaving, setIsSaving] = useState(null);
  const [workspace, setWorkspace] = useState({
    cdh_env_uri: platform.uri,
    dbx_account_uuid: "",
    dbx_warehouse_id: "",
    dbx_workspace_id: "",
    dbx_workspace_name: "",
    dbx_workspace_url: ""
  });

  const save = async () => {
    if (isSaving) return;

    try {
      setIsSaving(true);
      const registeredWP = await api.databricks.registerDatabricksWorkspace(
        workspace
      );

      reloadWorkspaces();

      return registeredWP;
    } catch (error) {
      setSubmissionError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    error,
    isSaving,
    save,
    setWorkspace,
    submissionError,
    workspace
  };
};

export { useDatabricksWorkspaceCreationModal };
