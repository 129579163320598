/* @flow */

import React from "react";
import Loading from "../../../../components/loading";
import CopyToClipboard from "../../../../components/copyToClipboard";

type propTypes = {
  redshiftCluster: Object
};

class RedshiftClusterPublicKeys extends React.PureComponent<propTypes> {
  render() {
    const { redshiftCluster } = this.props;
    return (
      <div className="cluster-create">
        {!redshiftCluster && <Loading message="Redshift Cluster Public Key" />}
        {redshiftCluster && (
          <div className="form-container">
            <div className="form-group">
              <label className="label-form">SSH public key</label>
              <CopyToClipboard
                text={redshiftCluster.public_key}
                className={"fas fa-copy btn-carre"}
                label={""}
              />
              <div className="input-group">
                <textarea
                  disabled
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Public key"
                  value={redshiftCluster.public_key}
                  rows="5"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RedshiftClusterPublicKeys;
