/* @flow */

import React from "react";
import { connect } from "react-redux";
import withGoTo from "../goToHOC";
import withAppSync from "../AppsyncHOC";
import { updateMessage } from "../globalNotifications/actions";

type propTypes = {
  table: Object,
  columns: Array<Object>,
  buildQuery: Function
};

type stateTypes = {
  open: boolean
};

class TablesList extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      open: false
    };
  }

  onClickCollapseToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const { table, columns } = this.props;
    return (
      <div>
        <ul className="facet-list">
          <li className="facet-category" key={table.tablename}>
            <div className="facet-title">
              <i
                onClick={this.onClickCollapseToggle}
                className={`mb-2 mr-1 facet-chevron fas ${
                  this.state.open ? "fa-chevron-down" : "fa-chevron-right"
                }`}
              ></i>
              <div title={table.tablename} className={"table-name"}>
                {table.tablename}
              </div>
              <i
                className={"facet-chevron fa fa-search mr-3"}
                onClick={() =>
                  this.props.buildQuery(table.database, table.tablename)
                }
              ></i>
            </div>
            {columns.map((column) => (
              <ul
                className={`facet-list-criteria collapse ${
                  this.state.open ? "show" : " in"
                }`}
              >
                <li className="facet-criteria" key={column.Name}>
                  <div className="facet-criteria-name">
                    <label
                      htmlFor={`${column}-${column.value}`}
                      className="fas"
                    >
                      <span className="labelCustom">{`${column.Name} (${column.Type})`}</span>
                    </label>
                  </div>
                </li>
              </ul>
            ))}
          </li>
        </ul>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(TablesList)));
