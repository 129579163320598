/* @flow */

import { createLogsGroup } from "./mutationCreateLogsGroup";
import { updateLogsGroup } from "./mutationUpdateLogsGroup";
import { deleteLogsGroup } from "./mutationDeleteLogsGroup";
import { listCartLogsGroup } from "./queryListCartLogsGroup";

class LogGroupsApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  createLogsGroup(cartUri: string, platformUri: string, input: Object) {
    return this.fetchAppSync(createLogsGroup, { cartUri, platformUri, input });
  }

  updateLogsGroup(cartUri: string, logsGroupId: string, input: Object) {
    return this.fetchAppSync(updateLogsGroup, { cartUri, logsGroupId, input });
  }

  deleteLogsGroup(cartUri: string, logsGroupId: string) {
    return this.fetchAppSync(deleteLogsGroup, { cartUri, logsGroupId });
  }

  listLogsGroup(cartUri: string) {
    return this.fetchAppSync(listCartLogsGroup, { cartUri });
  }
}

export default LogGroupsApi;
