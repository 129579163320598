/* eslint-disable no-nested-ternary */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../../../AppsyncHOC";
import Logger from "../../../../../utils/logger";
import GraphQl from "../../../../../graphQL";
import Row from "../../../../../components/row";
import Col from "../../../../../components/col";
import Error from "../../../../../components/error";
import Routes from "../../../../routes";
import { updateMessage } from "../../../../globalNotifications/actions";
import Breadcrumb from "../../../../../components/breadcrumb";
import TagsDatasourceFields from "../../../../datasources/components/form/TagsDatasourceFields";
import { haveSpecialCharacters } from "../../../../../utils/string";
import SelectClassic from "../../../../../components/SelectClassic";
import SelectInfinite from "../../../../../components/SelectInfinite/SelectInfinite2";

const Log = Logger("CreateDatabrewProject");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      cart: Object
    }
  }
};

type stateTypes = {
  cart: Object,
  platforms: Object,
  error: boolean,
  isSubmitting: boolean,
  stages: Array<Object>,
  sampleType: Array<string>,
  errorSubmit: Object,
  originalDatasets: Array,
  datasets: Array,
  totalDatasets: number,
  isLoadingDatasets: boolean,
  selectedDataset: {},
  selectedValue: {
    name: string,
    dataset_uri: string,
    dataset_output_id: string,
    sample_type: string,
    sample_size: number,
    platform_uri: string,
    tags: Array<Object>
  }
};

class CreateDatabrewProject extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    const { location } = props;

    const cart = (location.state && location.state.cart) || {};
    const platforms = cart.platforms || [];
    this.state = {
      cart,
      platforms,
      stages: [],
      isSubmitting: false,
      error: false,
      errorSubmit: false,
      sampleType: ["FIRST_N", "LAST_N", "RANDOM"],
      datasets: [],
      originalDatasets: [],
      totalDatasets: 0,
      isLoadingDatasets: false,
      selectedValue: {
        name: "",
        dataset_uri: "",
        dataset_output_id: "",
        sample_type: "",
        sample_size: 1000,
        platform_uri: "",
        tags: []
      }
    };
  }

  componentDidMount() {
    this.handleChange("sample_type", "FIRST_N");
    this.getDatasets();
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const selectedValue = Object.assign({}, prevState.selectedValue);
      selectedValue[field] = value;
      if (field === "sample_size") {
        selectedValue[field] = parseInt(value, 10);
      }

      if (field === "name") {
        selectedValue[field] = value.split(" ").join("");
      }

      if (field === "dataset_uri") {
        const datasetSelected = this.state.originalDatasets.find(
          (o) => o.uri === value
        );
        this.setState({ stages: datasetSelected.stages });
      }
      return { selectedValue };
    });
  }

  save() {
    const { cart, selectedValue } = this.state;

    this.setState({ errorSubmit: false, isSubmitting: true });

    return this.props.api.cart
      .createDatabrewProject(cart.uri, selectedValue)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Databrew Project created",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-preparation`,
          params: { cartUri: cart.uri }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isSubmitting: false });
        this.setState({ errorSubmit });
        this.props.showGlobalNotification({
          message: "Databrew Project creation failed",
          type: "alert"
        });
      });
  }

  findDatasetNameFromValue() {
    const datasetSelected = this.state.originalDatasets.find(
      (dataset) => dataset.uri === this.state.selectedValue.dataset_uri
    );
    if (datasetSelected) {
      return datasetSelected.name;
    }
    return "Select a dataset";
  }

  findStageNameFromValue() {
    const stageSelected = this.state.stages.find(
      (stage) => stage.id === this.state.selectedValue.dataset_output_id
    );
    if (stageSelected) {
      return stageSelected.name;
    }
    return "Select a stage";
  }

  findEnvNameFromValue() {
    const envSelected = this.state.platforms.find(
      (platform) => platform.uri === this.state.selectedValue.platform_uri
    );
    if (envSelected) {
      return envSelected.name;
    }
    return "Select an environment";
  }

  getOptionsForStageSelect() {
    if (this.state.selectedValue.dataset_uri) {
      const id = this.state.originalDatasets.findIndex(
        (dataset) => dataset.uri === this.state.selectedValue.dataset_uri
      );
      if (id !== -1) {
        return this.state.originalDatasets[id].stages.map((stage) => ({
          value: stage.id,
          label: stage.name
        }));
      }
    }
    return false;
  }

  selectDefaultEnv = () => {
    this.setState((prevState) => {
      const selectedValue = { ...prevState.selectedValue };
      selectedValue.platform_uri = prevState.platforms[0].uri;
      return { selectedValue };
    });
    return {
      value: this.state.platforms[0].uri,
      label: this.state.platforms[0].name
    };
  };

  loadMoreDatasets = (offset, searchValue) => {
    const search = { name: searchValue };
    return this.props.api.cart
      .getDatasets(this.props.location.state.cart.uri, { offset, search })
      .then((data) => {
        const datasets = [];
        data.results.map((o) =>
          datasets.push({
            label: o.name,
            value: o.uri
          })
        );
        const source = this.state.originalDatasets;
        const destination = data.results;
        const originalDatasets = source.concat(destination);
        this.setState({ originalDatasets });
        return datasets;
      });
  };

  getDatasets = () => {
    this.setState({
      isLoadingDatasets: true
    });

    const options = {
      limit: 10,
      offset: 0,
      search: undefined
    };

    return this.props.api.cart
      .getDatasets(this.props.location.state.cart.uri, options)
      .then((response) => {
        if (!response) {
          this.setState({ isLoadingDatasets: false });
          return false;
        }

        const datasets = [];
        response.results.map((o) =>
          datasets.push({
            label: o.name,
            value: o.uri
          })
        );

        const originalDatasets = response.results;

        this.setState({
          isLoadingDatasets: false,
          totalDatasets: response.total,
          originalDatasets,
          datasets
        });
        return response.results;
      })
      .catch((error) => {
        this.setState({
          isLoadingDatasets: false,
          error
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CreateDatabrew"} />;

    const { selectedValue } = this.state;

    return (
      <div className="create-databrew">
        <Breadcrumb view={"Create a new Databrew Project"} />
        <Row>
          <Col size={1} />
          <Col className={"my-4"} size={9}>
            <Formik
              initialValues={{
                name: selectedValue.name,
                dataset_uri: selectedValue.dataset_uri,
                dataset_output_id: selectedValue.dataset_output_id,
                sample_type: selectedValue.sample_type,
                sample_size: selectedValue.sample_size,
                platform_uri: selectedValue.platform_uri,
                tags: selectedValue.tags
              }}
              validate={() => {
                const errors = {};
                if (!selectedValue.name) {
                  errors.name = "Name is mandatory";
                }

                if (haveSpecialCharacters(selectedValue.name))
                  errors.name =
                    "Name must not contain these special characters ( & / ' _ ; )";

                if (!selectedValue.dataset_uri) {
                  errors.dataset_uri = "Dataset is mandatory";
                }

                if (!selectedValue.dataset_output_id) {
                  errors.dataset_output_id = "Stage is mandatory";
                }

                if (!selectedValue.sample_size) {
                  errors.sample_size = "Sample size is mandatory";
                }

                if (!selectedValue.sample_type) {
                  errors.sample_type = "Sample type is mandatory";
                }
                if (!selectedValue.platform_uri) {
                  errors.platform_uri = "Environment is mandatory";
                }

                if (selectedValue.tags) {
                  selectedValue.tags.map((tag) => {
                    if (tag.Key.length > 128 || tag.Value.length > 256) {
                      errors.s3_custom_tags =
                        "The tag key must not exceed 128 unicode characters and the tag value must not exceed 256 characters ";
                    }
                    const patternTags = /^[\w.:/=+\-@]*$/;
                    const testKey = patternTags.test(tag.Key);
                    if (!testKey) {
                      errors.s3_custom_tags_key_unicode = `The key (${tag.Key}) contains illegal characters. Non-unicode characters are not allowed`;
                    }

                    const testValue = patternTags.test(tag.Value);
                    if (!testValue) {
                      errors.s3_custom_tags_value_unicode = `The value (${tag.Value}) contains illegal characters. Non-unicode characters are not allowed`;
                    }

                    return errors;
                  });
                }
                Log.info("validate", selectedValue, errors);
                return errors;
              }}
              onSubmit={() => {
                this.save();
              }}
            >
              {({ errors }) => (
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      stringOnly
                      error={this.state.errorSubmit}
                      path={"CreateDatabrew"}
                    />
                  )}
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <div className="label-form-name">
                        <input
                          disabled
                          className="form-control bg-white label-form-name-cdh"
                          value="cdh-"
                        />
                        <Field
                          type="name"
                          name="name"
                          className="form-control bg-white"
                          placeholder="Name"
                          onChange={(event) =>
                            this.handleChange("name", event.target.value)
                          }
                          value={selectedValue.name}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Dataset:</legend>
                      <SelectInfinite
                        isLoadingOptions={this.state.isLoadingDatasets}
                        placeholder="Select a dataset"
                        isDisabled={false}
                        selectedOption={
                          selectedValue.dataset_uri
                            ? {
                                value: this.state.selectedValue.dataset_uri,
                                label: this.findDatasetNameFromValue()
                              }
                            : false
                        }
                        totalOptions={this.state.totalDatasets || 0}
                        initialOptions={this.state.datasets}
                        loadMoreOptions={this.loadMoreDatasets}
                        isSearchable
                        onSelectOption={(option) => {
                          this.handleChange("dataset_uri", option.value);
                        }}
                      />
                      <small className="attribute-label">
                        The dataset to use must be in your Databrew project
                        region.
                      </small>
                      <ErrorMessage
                        name="dataset_uri"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Stage:</legend>
                      <SelectClassic
                        placeholder={
                          selectedValue.dataset_uri
                            ? "Select a stage"
                            : "Select a dataset before"
                        }
                        isDisabled={!selectedValue.dataset_uri}
                        options={this.state.stages.map((stage) => ({
                          value: stage.id,
                          label: stage.name
                        }))}
                        selectedOption={
                          selectedValue.dataset_output_id
                            ? {
                                value:
                                  this.state.selectedValue.dataset_output_id,
                                label: this.findStageNameFromValue()
                              }
                            : false
                        }
                        onSelectOption={(option) =>
                          this.handleChange("dataset_output_id", option.value)
                        }
                        isSearchable
                      />
                      <ErrorMessage
                        name="dataset_output_id"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Sampling Type:</legend>
                      <SelectClassic
                        placeholder={"Select a sampling type"}
                        options={this.state.sampleType.map((sampleType) => ({
                          value: sampleType,
                          label: sampleType
                        }))}
                        selectedOption={
                          selectedValue.sample_type
                            ? {
                                value: selectedValue.sample_type,
                                label: selectedValue.sample_type
                              }
                            : false
                        }
                        onSelectOption={(option) =>
                          this.handleChange("sample_type", option.value)
                        }
                      />
                      <ErrorMessage
                        name="sample_type"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Sampling Size:</legend>
                      <input
                        id="sample_size"
                        name="sample_size"
                        className="form-control bg-white"
                        value={selectedValue.sample_size}
                        placeholder={"Select a sampling size"}
                        type="number"
                        min="1"
                        max="5000"
                        onChange={(event) =>
                          this.handleChange("sample_size", event.target.value)
                        }
                      />
                      <small className="attribute-label">
                        Minimum value of 1. Maximum value of 5000 rows.
                      </small>
                      <ErrorMessage
                        name="sample_size"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Environment:</legend>
                      <SelectClassic
                        placeholder={"Select an environment"}
                        options={this.state.platforms.map((platform) => ({
                          value: platform.uri,
                          label: platform.name
                        }))}
                        selectedOption={
                          selectedValue.platform_uri
                            ? {
                                value: this.state.selectedValue.platform_uri,
                                label: this.findEnvNameFromValue()
                              }
                            : this.state.platforms.length === 1
                            ? this.selectDefaultEnv()
                            : false
                        }
                        onSelectOption={(option) =>
                          this.handleChange("platform_uri", option.value)
                        }
                      />
                      <ErrorMessage
                        name="platform_uri"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group mb-3">
                      <legend className="label-form">Tags</legend>
                      <TagsDatasourceFields
                        tags={selectedValue.tags}
                        onChangeTags={(field, value) =>
                          this.handleChange(field, value)
                        }
                        customField="tags"
                      />
                      {errors.s3_custom_tags && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags}
                        </div>
                      )}
                      {errors.s3_custom_tags_key_unicode && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags_key_unicode}
                        </div>
                      )}
                      {errors.s3_custom_tags_value_unicode && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags_value_unicode}
                        </div>
                      )}
                    </fieldset>

                    <div className={"ml-1 row justify-content-center"}>
                      <button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        className="butn"
                      >
                        {this.state.isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Create
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(CreateDatabrewProject));
