export const listAccountDssInstances = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        listAccountDssInstances (accountUri: $accountUri, options: $options) {
            total
            limit
            offset
            results {
                uri
                name
                url
                description
                connection_status
            }
        }
    }
    `,
  keyOutput: "listAccountDssInstances"
};
