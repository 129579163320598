/* @flow */
import React from "react";
import { connect } from "react-redux";
import ButtonAction from "../../../components/buttonAction";
import Modal from "../../../components/modal";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import Logger from "../../../utils/logger";

const Log = Logger("AccelerationTemplateDetachGroupModal");

type propTypes = {
  api: GraphQl,
  templateEnvironementGroup: Object,
  onDetach: Function,
  onClose: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error?: Object
};

class DetachGroupModal extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  submitDetach = () => {
    this.setState({ error: undefined });
    this.props.api.accelerationTemplates
      .revokeAccessToAccelerationTemplate(
        this.props.templateEnvironementGroup.id
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: "Revoked !",
          type: "success"
        });
        this.props.onDetach();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error });
      });
  };

  render() {
    return (
      <Modal
        title={`Revoke access to group: ${this.props.templateEnvironementGroup.group.name}`}
        body={!this.state.error && "Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          !this.state.error && (
            <ButtonAction
              key={`group-delete-${this.props.templateEnvironementGroup.group.uri}`}
              label="Revoke"
              className="butn butn-delete"
              onClick={this.submitDetach}
            />
          ),
          <button
            key={`group-close-${this.props.templateEnvironementGroup.group.uri}`}
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(DetachGroupModal));
