/* @flow */

export const listSharedWithMeDatasets = {
  query: `query Q ($options: QueryOptions) {
        listSharedWithMeDatasets(options:$options) {
              uri
              accountid
              name
              resource_name
              description
              tags
              doc
              type
              confidentiality
              domain
              visibility
              shareable
              is_registered_with_lakeformation
              refresh_sla
              last_loaded
              details
              createdat
              updatedat
              period_start_at
              period_end_at
              expiration_date
              languages
              data_types
              default_table
              access
              basic_metadata
              metadata_frequency
              is_partitioned
              maturity_level
              can_share
              update_frequency
              size
              arn
              s3_inventory_last_update
              last_metadata_update
              is_bookmarked
              nb_views
              datasource {
                uri
                bucket
                name
                resource_name
                aws
                region
              }
              owner {
                uri
                name
                account{
                 name
                }
              }
              metric {
                parent
                statistic{
                 nb_bookmarks
                 nb_carts
                 nb_shares
                }
              }
        }
    }
    `,
  keyOutput: "listSharedWithMeDatasets"
};
