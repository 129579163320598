/* @flow */
import { isFeatureToggleEnabled } from "./queryIsFeatureToggleEnabled";
import { createFeatureToggle } from "./mutationCreateFeatureToggle";
import { enableFeatureToggle } from "./mutationEnableFeatureToggle";
import { disableFeatureToggle } from "./mutationDisableFeatureToggle";
import { deleteFeatureToggle } from "./mutationDeleteFeatureToggle";
import { listFeatureToggles } from "./queryListFeatureToggles";
import { listFeatureCodes } from "./queryListFeatureCodes";

class FeatureToggleApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  isFeatureToggleEnabled(featureCode: string, accountUri: string) {
    return this.fetchAppSync(isFeatureToggleEnabled, {
      featureCode,
      accountUri
    });
  }

  listFeatureToggles(search: Object, options: Object) {
    return this.fetchAppSync(listFeatureToggles, { search, options });
  }

  listFeatureCodes() {
    return this.fetchAppSync(listFeatureCodes, {});
  }

  createFeatureToggle(input: Object) {
    return this.fetchAppSync(createFeatureToggle, { input });
  }

  enableFeatureToggle(id: string) {
    return this.fetchAppSync(enableFeatureToggle, { id });
  }

  disableFeatureToggle(id: string) {
    return this.fetchAppSync(disableFeatureToggle, { id });
  }

  deleteFeatureToggle(id: string) {
    return this.fetchAppSync(deleteFeatureToggle, { id });
  }
}

export default FeatureToggleApi;
