/* @flow */
import React from "react";
import classnames from "classnames";
import "./view.less";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import routes from "../../routes";
import withGoTo from "../../goToHOC";
import Links from "../../links";
import Stringslider from "../../../components/stringslider";
import { updateMessage } from "../../globalNotifications/actions";
import withAppSync from "../../AppsyncHOC";
import { getAccountUriFromAccountId } from "../../../utils/toolsForUri";
import { getErrorMessage } from "../../../utils/error";
import CartPlatformRole from "./CartPlatformRole";

const Log = Logger("CartEnvironment");

type propTypes = {
  cart: {
    uri: string,
    region: string,
    database: string,
    platforms: Array<Object>,
    datasets: Array<Object>
  },
  environment: {
    uri: string,
    name: string,
    aws: string,
    accountid: string,
    environment: string,
    description: string,
    default_region: string
  },
  goTo: Function,
  setError: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  awsAccessPending: boolean,
  isSubmitting: boolean,
  roleId: string,
  errorConsoleAccess?: Object,
  cartRoleArn: string,
  cartPlatformRole: {
    global_status: string,
    last_refresh_role_date: string,
    global_duration: string,
    iam_status: string,
    iam_updatedat: string,
    iam_duration: string,
    iam_error: string,
    glue_status: string,
    glue_updatedat: string,
    glue_duration: string,
    glue_error: string,
    execution_name: string,
    rs_status: string,
    rs_updatedat: string,
    rs_duration: string,
    rs_error: string
  },
  maxSlots: number,
  selectedSlots: Object
};

class CartEnvironment extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  intervalurl: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      awsAccessPending: false,
      isSubmitting: false,
      roleId: "",
      cartRoleArn: "",
      cartPlatformRole: {
        global_status: "",
        last_refresh_role_date: "",
        global_duration: "",
        iam_status: "",
        iam_updatedat: "",
        iam_duration: "",
        iam_error: "",
        glue_status: "",
        glue_updatedat: "",
        glue_duration: "",
        glue_error: "",
        execution_name: "",
        rs_status: "",
        rs_updatedat: "",
        rs_duration: "",
        rs_error: ""
      },
      maxSlots: 0,
      selectedSlots: {}
    };
  }

  componentDidMount() {
    this.getCartPlatformRole();
    this.getRoleId();
  }

  assignPolicySlots(policySlots: Object) {
    this.setState({
      maxSlots: policySlots.max_slots,
      selectedSlots: policySlots.selected.reduce(
        (o, v) => ({ ...o, [v.platform_uri]: v.slots }),
        {}
      )
    });
  }

  componentWillUnmount() {
    if (this.intervalurl) {
      clearInterval(this.intervalurl);
      this.intervalurl = undefined;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  fetchUrl = () => {
    const {
      cart: { uri },
      api
    } = this.props;

    return api.cart
      .getAWSConsoleAccess(uri, this.props.environment.uri)
      .then((url) => {
        this.setState({ awsAccessPending: false });
        window.open(url, "_blank");
      })
      .then(() => {
        clearTimeout(this.timeout);
        clearInterval(this.intervalurl);
      })
      .catch((errorConsoleAccess) => {
        this.setState({ errorConsoleAccess });
        clearInterval(this.intervalurl);
        Log.error("getAWSConsoleAccess failed");
        Log.error(errorConsoleAccess);
      });
  };

  getAwsConsoleAccess = () => {
    if (this.roleHasErrorForConsoleAccess()) {
      this.props.showGlobalNotification({
        message:
          "This role cannot be used. Please check refresh role details for more information",
        type: "alert"
      });
    } else if (!this.roleIsReadyForConsoleAccess()) {
      this.props.showGlobalNotification({
        message: "You need to refresh your role.",
        type: "neutral"
      });
    } else if (!this.state.awsAccessPending) {
      Log.info("GetAwsConsoleAccess()");
      this.setState({ awsAccessPending: true });

      this.fetchUrl();
      this.intervalurl = setInterval(() => {
        Log.info("New try of getting AWS console access");
        this.fetchUrl();
      }, 5000);

      this.timeout = setTimeout(() => {
        Log.info("AWS attempts timeout");
        if (this.state.awsAccessPending) {
          clearInterval(this.intervalurl);
          this.setState({ awsAccessPending: false });
        }
      }, 30000);
    }
  };

  getRoleId = () => {
    this.props.api.datasource
      .getRoleIdFromRoleArn(
        `arn:aws:iam::${this.props.environment.aws}:role/${this.props.cart.database}`
      )
      .then((result) => {
        if (result !== "null") {
          this.setState({ roleId: result });
        }
        return result;
      })
      .catch((error) => {
        this.props.setError(error);
      });
  };

  goToConnectionPropertiesView = () => {
    if (this.roleHasErrorForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message:
          "This role cannot be used. Please check refresh role details for more information",
        type: "alert"
      });
    }

    if (!this.roleIsReadyForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message: "You need to refresh your role.",
        type: "neutral"
      });
    }

    return this.props.goTo({
      route: routes.Cart.ConnectionProperties,
      params: {
        uriPlayground: this.props.environment.uri,
        cartUri: this.props.cart.uri
      },
      state: {
        playground: { uri: this.props.environment.uri },
        cart: this.props.cart
      }
    });
  };

  goToGlueJobParametersView = () => {
    if (this.roleHasErrorForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message:
          "This role cannot be used. Please check refresh role details for more information",
        type: "alert"
      });
    }

    if (!this.roleIsReadyForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message: "You need to refresh your role.",
        type: "neutral"
      });
    }
    return this.props.goTo({
      route: routes.Cart.GlueJobParameters,
      params: {
        uriPlayground: this.props.environment.uri,
        cartUri: this.props.cart.uri
      },
      state: {
        playground: { uri: this.props.environment.uri },
        cart: this.props.cart
      }
    });
  };

  goToManagePolicies = () => {
    this.props.goTo({
      route: routes.Cart.ManagePolicies,
      params: {
        uriPlayground: this.props.environment.uri,
        cartUri: this.props.cart.uri
      },
      state: {
        playground: { uri: this.props.environment.uri },
        cart: this.props.cart,
        maxSlots: this.state.maxSlots
      }
    });
  };

  goToCredentialsView = () => {
    if (this.roleHasErrorForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message:
          "This role cannot be used. Please check refresh role details for more information",
        type: "alert"
      });
    }

    if (!this.roleIsReadyForConsoleAccess()) {
      return this.props.showGlobalNotification({
        message: "You need to refresh your role.",
        type: "neutral"
      });
    }

    return this.props.goTo({
      route: routes.Cart.GenerateCredentials,
      params: {
        uriPlayground: this.props.environment.uri,
        cartUri: this.props.cart.uri
      },
      state: {
        playground: { uri: this.props.environment.uri },
        cart: this.props.cart
      }
    });
  };

  getCartPlatformRole = () =>
    this.props.api.cart
      .getEnvironmentStatusRole(this.props.cart.uri, this.props.environment.uri)
      .then((cartPlatformRole) => {
        this.props.api.cart
          .getCartPlatformRole(
            cartPlatformRole.cart_uri,
            cartPlatformRole.platform_uri
          )
          .then((data) => {
            this.setState({
              cartRoleArn: `arn:aws:iam::${this.props.environment.aws}:role/${data.role_name}`
            });
          });
        this.setState({ cartPlatformRole });
        if (cartPlatformRole.global_status === "SUCCEEDED") {
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        } else if (cartPlatformRole.global_status === "FAILED") {
          const errorMessage =
            cartPlatformRole.iam_status === "FAILED"
              ? "Environment role could not be refreshed ! Please check your trust and custom group policies if any"
              : "Environment role could not be refreshed ! Some tables could not be copied";
          this.props.showGlobalNotification({
            message: errorMessage,
            type: "alert",
            popupDuration: 4000
          });
          Log.error(
            "Project Environment role could not be refreshed",
            errorMessage
          );
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        }
        this.setState({
          cartPlatformRole
        });
        this.getRoleId();
        return true;
      })
      .catch((error) => {
        this.props.setError(error);
      });

  refreshRole = () => {
    if (this.state.selectedSlots > this.state.maxSlots) {
      this.props.showGlobalNotification({
        message: "Too much policies are selected for this project",
        type: "alert"
      });
    } else if (!this.state.isSubmitting) {
      this.setState({ isSubmitting: true });
      this.props.api.playground
        .refreshPlaygroundRole(this.props.cart.uri, this.props.environment.uri)
        .then(() => {
          Log.info("role refresh for environment:", this.props.environment.uri);
          this.props.showGlobalNotification({
            message: "Refresh Role launched !",
            type: "success"
          });
          this.getCartPlatformRole();
          this.setState({ isSubmitting: false });
        })
        .catch((errorRefreshRole) => {
          this.props.showGlobalNotification({
            message: "Failed to refresh role",
            type: "alert"
          });
          Log.error(errorRefreshRole);
          this.setState({ isSubmitting: false });
        });
    }
  };

  checkPolicySlots() {
    if (this.state.selectedSlots) {
      return this.state.selectedSlots > this.state.maxSlots;
    }
    return false;
  }

  render() {
    const { environment } = this.props;

    return (
      <div
        className={"pipeline-card card-shadow card bg-white"}
        onClick={() => {}}
      >
        <div className="card-datasource-header">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <i className="fab fa-aws" />
              <span className="text-header-datasource">{environment.aws}</span>
            </div>
          </div>
          <div className="card-datasource-end">
            <div className={`cart-environment-tag ${environment.environment}`}>
              <span>{environment.environment}</span>
            </div>
          </div>
        </div>
        <div className="card-datasource-body">
          <div className="card-cart-body-left">
            <div className={"text-capitalize datasource-card-name"}>
              <Links.Playground.View
                uriAccount={getAccountUriFromAccountId(environment.accountid)}
                uriPlayground={environment.uri}
              >
                <Stringslider s={environment.name} height={25} />
              </Links.Playground.View>
            </div>
            <div className="card-datasource-details">
              <p className="card-cart-description">
                {environment.description && environment.description.length > 125
                  ? `${environment.description.substring(0, 125)}...`
                  : environment.description}
              </p>
              <div className="card-cart-role">
                <div>
                  <i className="fas fa-key" />
                  <span className="content-card-bucket">
                    &nbsp;{this.state.cartRoleArn}
                  </span>
                </div>
                {this.state.roleId && (
                  <div>
                    <i className="fab fa-aws" />
                    <span className="content-card-bucket">
                      Role ID: {this.state.roleId}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-cart-body-right">
            <div className="card-datasource-body-actions">
              <div
                className={classnames("btn-cart", {
                  "is-disabled":
                    !this.roleIsReadyForConsoleAccess() ||
                    this.roleHasErrorForConsoleAccess()
                })}
                onClick={this.getAwsConsoleAccess}
              >
                {this.state.awsAccessPending && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-play fa-spacing" />
                <span className="text-actions">Console Access</span>
              </div>
              <div
                className={classnames("btn-cart", {
                  "is-disabled":
                    !this.roleIsReadyForConsoleAccess() ||
                    this.roleHasErrorForConsoleAccess()
                })}
                onClick={this.goToCredentialsView}
              >
                <i className="fa fa-clipboard fa-spacing" />
                <span className="text-actions">Generate Credentials</span>
              </div>
              <div
                className={classnames("btn-cart", {
                  "is-disabled": this.state.selectedSlots > this.state.maxSlots
                })}
                onClick={this.refreshRole}
              >
                {this.state.isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-sync-alt fa-spacing" />
                <span className="text-actions">Refresh Role</span>
              </div>
              <div
                className={classnames("btn-cart", {
                  "is-disabled":
                    !this.roleIsReadyForConsoleAccess() ||
                    this.roleHasErrorForConsoleAccess()
                })}
                onClick={() => this.goToConnectionPropertiesView()}
              >
                <i className="fas fa-chart-bar fa-spacing" />
                <span className="text-actions">Athena Connection</span>
              </div>
              <div
                className={classnames("btn-cart", {
                  "is-disabled":
                    !this.roleIsReadyForConsoleAccess() ||
                    this.roleHasErrorForConsoleAccess()
                })}
                onClick={() => this.goToGlueJobParametersView()}
              >
                <i className="fas fa-clipboard fa-spacing" />
                <span className="text-actions">Glue Job Parameters</span>
              </div>
              <div
                className="btn-cart"
                onClick={() => this.goToManagePolicies()}
              >
                <i className="fas fa-location-arrow fa-spacing" />
                <span className="text-actions">Manage Policies</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-datasource-footer">
          <div>
            {this.state.errorConsoleAccess && (
              <Alert severity="error">
                <AlertTitle>Console Access failed:</AlertTitle>
                {getErrorMessage(this.state.errorConsoleAccess)}
              </Alert>
            )}
            {this.checkPolicySlots() && (
              <Alert severity="error">
                <AlertTitle>Policies maximum reached</AlertTitle>
                You have reached the maximum number of activated policies for
                this project ({this.state.maxSlots}).
                <br />
                Go to "Manage policies" to adjust the selection.
              </Alert>
            )}
          </div>
          {this.state.cartPlatformRole.global_status !== "" && (
            <CartPlatformRole
              callbackUpdateButton={this.getCartPlatformRole}
              cartPlatformRole={this.state.cartPlatformRole}
            />
          )}
        </div>
      </div>
    );
  }

  roleHasError = () => this.state.cartPlatformRole.global_status === "FAILED";

  roleIsReady = () => this.state.cartPlatformRole.global_status === "SUCCEEDED";

  roleHasErrorForConsoleAccess = () =>
    this.state.cartPlatformRole.iam_status === "FAILED";

  roleIsReadyForConsoleAccess = () =>
    this.state.cartPlatformRole.iam_status === "SUCCEEDED";
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(CartEnvironment)));
