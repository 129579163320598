import React from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import { updateMessage } from "../../../containers/globalNotifications/actions";
import Error from "../../error";
import withAppSync from "../../../containers/AppsyncHOC";
import withGoTo from "../../../containers/goToHOC";
import { TextInput } from "../../form/text-input";
import { useDatabricksWorkspaceCreationModal } from "./hooks/use-databricks-workspace-creation-modal";

const DatabricksWorkspaceCreationModal = ({
  api,
  onClose,
  open,
  platform,
  reloadWorkspaces
}) => {
  const { error, isSaving, save, setWorkspace, submissionError, workspace } =
    useDatabricksWorkspaceCreationModal(api, platform, reloadWorkspaces);

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={onClose}
        open={open}
        style={{
          overflowY: "visible"
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box>
            <Grid container spacing={2}>
              <Grid item md={1} sm={1} xs={1}>
                <i className="fas fa-briefcase fa-spacing" />
              </Grid>
              <Grid item md={11} sm={11} xs={11}>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className={"mt-1"}
                >
                  New Databricks workspace
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ overflowY: "visible" }}>
          {error && <Error error={error} path="CreateConnection" />}
          <Box sx={{ p: 3 }}>
            <Formik
              initialValues={workspace}
              validate={() => {
                const errors = {};
                if (!workspace.dbx_account_uuid) {
                  errors.dbx_account_uuid =
                    "Please fill your Databricks Account id";
                }
                if (!workspace.dbx_workspace_id) {
                  errors.dbx_workspace_id =
                    "Please fill your Databricks Workspace id";
                }
                if (!workspace.dbx_workspace_name) {
                  errors.dbx_workspace_name =
                    "Please fill your Databricks Workspace name";
                }
                if (!workspace.dbx_workspace_url) {
                  errors.dbx_workspace_url =
                    "Please fill your Databricks Workspace url";
                }
                if (!workspace.dbx_warehouse_id) {
                  errors.dbx_warehouse_id =
                    "Please fill your Databricks Warehouse id";
                }

                return errors;
              }}
              onSubmit={(values, {}) => {
                save();
              }}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  {Object.keys(errors).length > 0 && (
                    <div>
                      {Object.values(errors).map((e) => (
                        <div className="error-msg">
                          <Error
                            stringOnly
                            error={e}
                            path={"DBXWorkspaceCreation"}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {submissionError && (
                    <Error
                      stringOnly
                      error={submissionError}
                      path={"DBXWorkspaceCreation"}
                    />
                  )}
                  <div className="form-container">
                    <TextInput
                      {...{
                        label: "Databricks Account",
                        description: "Databricks account uuid",
                        onChange: (event) =>
                          setWorkspace({
                            ...workspace,
                            dbx_account_uuid: event.target.value
                          }),
                        placeholder: "Type your databricks account uuid",
                        value: workspace.dbx_account_uuid
                      }}
                    />
                    <TextInput
                      {...{
                        label: "Databricks Workspace",
                        description: "Databricks workspace id",
                        onChange: (event) =>
                          setWorkspace({
                            ...workspace,
                            dbx_workspace_id: event.target.value
                          }),
                        placeholder: "Type your databricks workspace id",
                        value: workspace.dbx_workspace_id
                      }}
                    />
                    <TextInput
                      {...{
                        description: "Databricks workspace name",
                        onChange: (event) =>
                          setWorkspace({
                            ...workspace,
                            dbx_workspace_name: event.target.value
                          }),
                        placeholder: "Type your databricks workspace name",
                        value: workspace.dbx_workspace_name
                      }}
                    />
                    <TextInput
                      {...{
                        description: "Databricks workspace url",
                        onChange: (event) =>
                          setWorkspace({
                            ...workspace,
                            dbx_workspace_url: event.target.value
                          }),
                        placeholder: "Type your databricks workspace url",
                        value: workspace.dbx_workspace_url
                      }}
                    />

                    <TextInput
                      {...{
                        label: "Databricks Warehouse",
                        description: "Databricks warehouse id",
                        onChange: (event) =>
                          setWorkspace({
                            ...workspace,
                            dbx_warehouse_id: event.target.value
                          }),
                        placeholder: "Type your databricks warehouse id",
                        value: workspace.dbx_warehouse_id
                      }}
                    />
                    <CardContent>
                      <button
                        type={"submit"}
                        className="butn butn-create"
                        disabled={isSaving}
                        style={{ margin: "5px", width: "100%" }}
                      >
                        {isSaving ? (
                          <i className="fas fa-sync-alt fa-spin" />
                        ) : (
                          <i className={"fa fa-paper-plane"} />
                        )}
                        <span>{" Create"}</span>
                      </button>
                    </CardContent>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatabricksWorkspaceCreationModal)));
