export const mutationStageNormalizing = {
  query: `
        mutation Q (
            $datasetUri: String!,
            $s3Location: String!,
            $delay: Int,
            $maxAttempts: Int) 
        {
            startDatasetCustomCrawler(
                datasetUri: $datasetUri,
                s3Location: $s3Location,
                delay: $delay,
                maxAttempts: $maxAttempts,
            )
        }
    `,
  keyOutput: "startDatasetCustomCrawler"
};
