/* @flow */

import React from "react";
import "./style.less";

type propsTypes = {
  message: string
};

const SimpleNotificationMessage = ({ message = "" }: propsTypes) => (
  <div className="simple-notification">
    {message.split("\n").map((c) => (
      <div>{c}</div>
    ))}
  </div>
);

export default SimpleNotificationMessage;
