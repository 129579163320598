export const createIdentity = {
  query: `
  mutation Q ($accountUri: String!, $input: NewIdentityInput!) {
    registerIdentity(accountUri: $accountUri, input: $input) {
      uri
    }
  }
`,
  keyOutput: "registerIdentity"
};
