/* @flow */

import React from "react";
import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Routes from "../routes";
import Loading from "../../components/loading";
import withAppSync from "../AppsyncHOC";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";
import "./create.less";
import GraphQl from "../../graphQL";
import Breadcrumb from "../../components/breadcrumb";
import { haveSpecialCharactersCDH } from "../../utils/string";

const Log = Logger("PlaygroundCreate");

type propTypes = {
  match: {
    params: {
      uriAccount: string,
      uriDssInstance: string
    }
  },
  goTo: Function,
  api: GraphQl,
  showGlobalNotification: Function
};

type stateTypes = {
  errorSubmit: boolean,
  dssInstance: Object,
  isUpdating: boolean,
  ready: boolean,
  error: Object
};

class DssInstanceEdit extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isUpdating: false,
      ready: false,
      dssInstance: {},
      error: undefined
    };
  }

  componentDidMount() {
    this.getDssInstance();
  }

  getDssInstance() {
    return this.props.api.dssInstance
      .getDssInstance(
        this.props.match.params.uriAccount,
        this.props.match.params.uriDssInstance
      )
      .then((response) => {
        this.setState({
          ready: true,
          dssInstance: response
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  save = (values, setSubmitting) => {
    this.setState({ isUpdating: true });
    const dssInstanceUri = this.props.match.params.uriDssInstance;
    const input = {
      name: values.name,
      description: values.description,
      url: values.url,
      api_key_secret: values.apiKeySecret
    };
    return this.props.api.dssInstance
      .update(dssInstanceUri, input)
      .then(() => {
        this.props.showGlobalNotification({
          message: "DSS Instance updated",
          type: "success"
        });
        setSubmitting(false);
        this.setState({ isUpdating: false });
        return this.props.goTo({
          route: Routes.DssInstance.View,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uriDssInstance: this.props.match.params.uriDssInstance
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isUpdating: false });
        this.props.showGlobalNotification({
          message: "DSS Instance update failed",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({
          errorSubmit
        });
      });
  };

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"DssInstanceEdit"} />;
    if (!this.state.ready) return <Loading message={"DssInstance Edit Form"} />;
    return (
      <div className="playgrounds-create">
        <div className="title">
          <Breadcrumb view={"Edit DSS Instance"} />
        </div>
        <div className="form-container">
          <Formik
            initialValues={{
              name: this.state.dssInstance.name,
              description: this.state.dssInstance.description,
              url: this.state.dssInstance.url,
              apiKeySecret: this.state.dssInstance.api_key_secret
            }}
            validate={(values) => {
              Log.info("validate", values);
              const errors = {};
              if (!values.name) {
                errors.name = "This field is mandatory";
              }
              if (haveSpecialCharactersCDH(values.name))
                errors.name =
                  "Name must not contain these special characters ( & / ' ; )";
              if (!values.url) {
                errors.url = "This field is mandatory";
              }
              if (!values.apiKeySecret) {
                errors.apiKeySecret = "This field is mandatory";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              Log.info("submit !", values);
              this.save(values, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {this.state.errorSubmit && (
                  <Error
                    error={this.state.errorSubmit}
                    path={"DssInstanceEdit"}
                    rawErrorMessage
                    stringOnly
                  />
                )}
                <fieldset className="form-group">
                  <legend className="label-form">name</legend>
                  <Field
                    type="name"
                    name="name"
                    className="form-control bg-white"
                    placeholder="DSS instance name..."
                    required
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">description</legend>
                  <Field
                    component="textarea"
                    name="description"
                    className="form-control bg-white"
                    placeholder="Description..."
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">DSS Host URL</legend>
                  <div className={"alert alert-light"}>
                    <i className="fas fa-exclamation-circle" />
                    &nbsp;Requirements:
                    <div>
                      You need to add an HTTPS link to a Dataiku DSS instance;
                      <div>
                        <a
                          href="https://confluence.tools.digital.engie.com/display/CDHA/CDH+Feature+Focus%3A+Dataiku+DSS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info in One Data Team documentation center in
                          confluence
                        </a>
                      </div>
                    </div>
                  </div>
                  <Field
                    type="text"
                    name="url"
                    className="form-control bg-white"
                    placeholder="https://<dssinstance-host-endpoint>"
                    required
                  />
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">DSS API Key</legend>
                  <div className={"alert alert-light"}>
                    <i className="fas fa-exclamation-circle" />
                    &nbsp;Requirements:
                    <div>
                      API Key with DSS admin privileges is required to create
                      connections to CDH;
                      <div>
                        <a
                          href="https://confluence.tools.digital.engie.com/display/CDHA/CDH+Feature+Focus%3A+Dataiku+DSS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info in One Data Team documentation center in
                          confluence
                        </a>
                      </div>
                    </div>
                  </div>
                  <Field
                    type="password"
                    name="apiKeySecret"
                    className="form-control bg-white"
                    placeholder="DSS API Key"
                    required
                  />
                  <ErrorMessage
                    name="apiKeySecret"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>

                <fieldset className="form-group">
                  <legend className="label-form">
                    DSS Instance IAM Role ARN
                  </legend>
                  <div className={"alert alert-light"}>
                    <i className="fas fa-exclamation-circle" />
                    &nbsp;Requirements:
                    <div>
                      IAM Role ARN is required for Dataiku on AWS EC2.
                      <div>
                        <a
                          href="https://confluence.tools.digital.engie.com/display/CDHA/CDH+Feature+Focus%3A+Dataiku+DSS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          More info in One Data Team documentation center in
                          confluence
                        </a>
                      </div>
                    </div>
                  </div>
                  <Field
                    disabled
                    name="iam_role_arn"
                    className="form-control bg-grey-smooth"
                    value={this.state.dssInstance.iam_role_arn}
                  />
                </fieldset>

                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn"
                  >
                    {this.state.isUpdating && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Edit DSS Instance
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DssInstanceEdit)));
