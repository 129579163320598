/* eslint-disable camelcase */

/* @flow */
import React from "react";
import "./view.less";
import { formatDate } from "../../../utils/date";
import Links from "../../links";
import TextField from "../../../components/textField";
import withGoTo from "../../goToHOC";
import routes from "../../routes";

type propTypes = {
  cart: Object,
  goTo: Function
};

class CartDetails extends React.PureComponent<propTypes> {
  render() {
    const { cart } = this.props;
    const {
      uri,
      region,
      resource_name, // eslint-disable-line camelcase
      createdat,
      description,
      group,
      database,
      uuid,
      is_refresh_auto_enabled,
      is_database_enabled,
      project_custom_tags
    } = cart;

    return (
      <div>
        <div className="cart-detail-element">
          <div className="cart-detail-title">
            <i className="fas fa-link cart-detail-icon" />
            URI
          </div>
          <TextField text={uri} />
        </div>
        <div className="cart-detail-double-element">
          <div className="cart-detail-element-left">
            <div className="cart-detail-title">
              <i className="fas fa-link cart-detail-icon" />
              Group
            </div>
            <span className="datasource-info-detail">
              <Links.Group.View uriGroup={(group || {}).uri || "null"}>
                {(group || {}).name || "-"}
              </Links.Group.View>
            </span>
          </div>
          <div className="cart-detail-element-right">
            <button
              className="butn butn-flat"
              type="button"
              onClick={() =>
                this.props.goTo({
                  route: routes.Cart.Transfer,
                  params: {
                    cartUri: cart.uri
                  },
                  state: { cart }
                })
              }
            >
              <i className={"fas fa-sync-alt fa-spacing"} />
              Change
            </button>
          </div>
        </div>
        <div className="cart-detail-double-element">
          <div className="cart-detail-element-left">
            <div className="cart-detail-title">
              <i className="fas fa-calendar-day cart-detail-icon" />
              Created on
            </div>
            <span>{formatDate(createdat)}</span>
          </div>
          <div className="cart-detail-element-right">
            <div className="cart-detail-title">
              <i className="fas fa-globe-europe cart-detail-icon" />
              AWS Region
            </div>
            <span>{region}</span>
          </div>
        </div>
        <div className="title-cost">
          <h3>CDH Role Tag</h3>
        </div>
        <div className="cart-detail-double-element">
          <div className="cart-detail-element-left">
            <div className="cart-detail-title">Key</div>
            <TextField text="cdh-cart" />
          </div>
          <div className="cart-detail-element-right">
            <div className="cart-detail-title">Value</div>
            <TextField text={resource_name} />
          </div>
        </div>
        <div className="cart-detail-element">
          <div className="cart-detail-title">Project database</div>
          <TextField text={database} />
        </div>
        <div className="cart-detail-element">
          <div className="cart-detail-title">Description</div>
          <p className="wrap-word">{description || "No description"}</p>
        </div>
        <div className="cart-detail-element">
          <div className="cart-detail-title">
            Automatic Refresh Role Enabled
          </div>
          <p className="wrap-word">{is_refresh_auto_enabled ? "Yes" : "No"}</p>
        </div>
        <div className="cart-detail-element">
          <div className="cart-detail-title">Database project use</div>
          <p className="wrap-word">{is_database_enabled ? "Yes" : "No"}</p>
        </div>
        <div className="title-cost">
          <h3>Cost monitoring</h3>
        </div>
        <div className="cart-detail-double-element">
          <div className="cart-detail-element-left">
            <div className="cart-detail-title">Key</div>
            <TextField text="cdh-owner" />
          </div>
          <div className="cart-detail-element-right">
            <div className="cart-detail-title">Value</div>
            <TextField text={group.uuid} />
          </div>
        </div>
        <div className="cart-detail-double-element">
          <div className="cart-detail-element-left">
            <div className="cart-detail-title">Key</div>
            <TextField text="cdh-application" />
          </div>
          <div className="cart-detail-element-right">
            <div className="cart-detail-title">Value</div>
            <TextField text={uuid} />
          </div>
        </div>
        {project_custom_tags && project_custom_tags.length > 0 && (
          <div>
            <div className="title-cost">
              <h3>Custom Tags</h3>
            </div>
            {project_custom_tags.map((tag) => (
              <div className="cart-detail-double-element">
                <div className="cart-detail-element-left">
                  <div className="cart-detail-title">Key</div>
                  <TextField text={tag.Key} />
                </div>
                <div className="cart-detail-element-right">
                  <div className="cart-detail-title">Value</div>
                  <TextField text={tag.Value} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default withGoTo(CartDetails);
