export const getDatasetsOfCart = {
  query: `query Q ($cartUri:String!,$options: QueryOptions) {
        getCart (cartUri:$cartUri) {
            datasetsList (options: $options) {
                results {
                    uri
                    resource_name
                    name
                    description
                    tags
                    type
                    arn
                    owneruri
                    source_type
                    schema
                    database
                    datasource {
                        uri
                        name
                        bucket
                        region
                        owner {
                            uri
                            name
                        }
                    }
                    createdat
                    expiration_date
                    creator {
                        uri
                        name
                    }
                    stages {
                       id
                       name
                    }
                }
                total
            }
        }
      }
    `,
  keyOutput: "getCart.datasetsList"
};
