/* @flow */
import React from "react";
import { connect } from "react-redux";
import { updateMessage } from "../../globalNotifications/actions";
import Col from "../../../components/col";
import Row from "../../../components/row";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Routes from "../../routes";
import Error from "../../../components/error";
import withGoTo from "../../goToHOC";
import Logger from "../../../utils/logger";
import {
  isRSSourceType,
  REGIONS,
  RS_BASE_CAPACITIES,
  SOURCE_TYPES
} from "../../../utils/constants";
import { slugifyNameForDatasourceResourceName } from "../../../utils/slugifier";
import DatasourceForm from "../components/form";
import GraphQl from "../../../graphQL";

const Log = Logger("DatasourceCreate");

type propTypes = {
  match: {
    params: {
      uriPlayground: string,
      uriAccount: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      account?: { uri: string },
      playground: { uri: string, default_region: string }
    }
  }
};

type stateTypes = {
  ready: boolean,
  datasource: Object,
  groups: Array<Object>,
  group: Object,
  error?: Object,
  errorSubmit?: Object,
  groupError?: Object,
  platform: Object,
  totalGroups: number,
  totalGroupsForSearchLimit: number,
  groupsOffset: number,
  totalEnvironments: number,
  isFetchingEnvironments: boolean,
  isFetchingFirstGroups: boolean
};

class DatasourceCreate extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isFetchingFirstGroups: true,
      groups: [],
      totalGroups: 0,
      totalGroupsForSearchLimit: 0,
      groupsOffset: 0,
      totalEnvironments: 0,
      isFetchingEnvironments: false,
      datasource: {
        region:
          props.match.params.uriPlayground && this.props.location.state
            ? this.props.location.state.playground.default_region
            : REGIONS[0].key,
        platformUri: props.match.params.uriPlayground,
        source_type: SOURCE_TYPES[0].key,
        workgroup_base_capacity: RS_BASE_CAPACITIES[0].key,
        useNamespace: true,
        namespace: ""
      },
      platform:
        props.match.params.uriPlayground && this.props.location.state
          ? this.props.location.state.playground
          : {},
      group: {
        platforms: []
      },
      error: undefined,
      errorSubmit: undefined,
      groupError: undefined
    };
  }

  getFiltersGroups = () => {
    const filters = [];
    filters.push({ key: "exclude_service_groups", value: true });
    if (
      this.props.location.state &&
      this.props.location.state.playground &&
      this.props.location.state.playground.uri
    ) {
      filters.push({
        key: "platform_uri",
        value: this.props.location.state.playground.uri
      });
    } else if (
      this.props.location.state &&
      this.props.location.state.account &&
      this.props.location.state.account.uri
    ) {
      filters.push({
        key: "account_uri",
        value: this.props.location.state.account.uri
      });
    }
    return filters;
  };

  getMyGroups = (a, search) => {
    if (search && search.length < 3) {
      return false;
    }
    const filters = this.getFiltersGroups();
    let queryOptions = {};
    if (search) {
      queryOptions = {
        offset: 0,
        limit: this.state.totalGroupsForSearchLimit,
        filters,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset: this.state.groupsOffset,
        limit: 10,
        filters
      };
    }

    return this.props.api.identity
      .listMyGroupsForDatasourceCreation(queryOptions)
      .then((allMyGroups) => {
        const groups = allMyGroups.results;
        const { totalGroups } = this.state;
        const newTotal = totalGroups;

        if (this.state.groupsOffset === 0 && groups.length <= 0) {
          if (!search) {
            Log.error(
              "You need to be part of a group before creating a datasource...",
              "DatasourceCreate"
            );
            this.setState({ groupError: true });
          }
          this.setState({ isFetchingFirstGroups: false });
          return [];
        }
        if (search) {
          this.setState(() => {
            const newGroupOffset = 0;
            return {
              groups: allMyGroups.results,
              totalGroups: allMyGroups.results.length,
              groupsOffset: newGroupOffset,
              isFetchingFirstGroups: false
            };
          });
        } else {
          this.setState((prevState) => {
            const groupLoaded = prevState.groups.concat(groups);
            if (groupLoaded.length === 1) {
              const group = groupLoaded[0];
              return {
                groups: groupLoaded,
                totalGroups: newTotal,
                groupsOffset: allMyGroups.offset + allMyGroups.limit,
                datasource: {
                  ...prevState.datasource,
                  owneruri: group.uri,
                  sharingManagementGroupUri: group.uri
                },
                group,
                isFetchingFirstGroups: false
              };
            }
            return {
              groups: groupLoaded,
              totalGroups: newTotal,
              groupsOffset: allMyGroups.offset + allMyGroups.limit,
              isFetchingFirstGroups: false
            };
          });
        }

        if (!search && this.state.groupsOffset === 0) {
          this.getEnvironments();
        }

        return groups.map((g) => ({ value: g.uri, label: g.name }));
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
        return [];
      });
  };

  getMyGroupsTotal = () =>
    this.props.api.identity
      .listMyGroupsForDatasourceCreationTotal({
        filters: this.getFiltersGroups()
      })
      .then((totalGroups) => {
        if (totalGroups === 0) {
          Log.error(
            "You need to be part of a group before creating a datasource...",
            "DatasourceCreate"
          );
          this.setState({ groupError: true, ready: true });
          return 0;
        }
        this.setState({
          totalGroups,
          totalGroupsForSearchLimit: totalGroups,
          ready: true
        });
        return totalGroups;
      })
      .catch((error) => {
        Log.error(error);
        this.setState({ error });
        return false;
      });

  componentDidMount() {
    this.getMyGroupsTotal().then(this.getMyGroups);
  }

  getEnvironments = () =>
    this.setState({ isFetchingEnvironments: true }, () =>
      this.props.api.group
        .getGroupEnvironments(this.state.group.uri)
        .then((platformsResponse) => {
          this.setState((prevState) => {
            const group = Object.assign({}, prevState.group);
            const datasource = Object.assign({}, prevState.datasource);
            let platform = {};
            const totalEnvironments = platformsResponse.total;

            if (
              platformsResponse &&
              platformsResponse.results &&
              platformsResponse.results.length === 1
            ) {
              platform = platformsResponse.results[0];
              datasource.platformUri = platformsResponse.results[0].uri;
              datasource.region = platformsResponse.results[0].default_region;
            } else if (!this.props.match.params.uriPlayground) {
              datasource.platformUri = undefined;
            } else {
              platform = prevState.platform; // eslint-disable-line prefer-destructuring
            }
            group.platforms = platformsResponse.results;

            return {
              group,
              datasource,
              platform,
              totalEnvironments,
              isFetchingEnvironments: false
            };
          });
        })
        .catch(() => {
          this.setState({
            isFetchingEnvironments: false
          });
        })
    );

  handleChange = (field, value) => {
    this.setState(
      (prevState) => {
        const datasource = Object.assign({}, prevState.datasource);
        if (field === "name") {
          datasource.resource_name =
            slugifyNameForDatasourceResourceName(value);
        }

        let { platform, group } = prevState;

        if (field === "owneruri") {
          group = prevState.groups.find((g) => g.uri === value);
        }

        if (field === "platformUri") {
          platform = prevState.group.platforms.find((g) => g.uri === value);
          datasource.region = platform.default_region;
        }

        datasource[field] = value;
        return {
          datasource,
          group,
          platform
        };
      },
      () => {
        if (field === "owneruri" && this.state.group) {
          this.getEnvironments();
        }
      }
    );
  };

  save = () => {
    const datasource = Object.assign({}, this.state.datasource);
    datasource.name = (this.state.datasource.name || "").trim();
    datasource.sharing_group_uri = datasource.sharingManagementGroupUri;
    if (isRSSourceType(datasource.source_type)) {
      datasource.rs_input = {
        network_id: datasource.network,
        workgroup_base_capacity: datasource.workgroup_base_capacity,
        rs_namespace_uri: datasource.namespace.uri
      };
    }
    delete datasource.platformUri;
    delete datasource.sharingManagementGroupUri;
    delete datasource.resource_name;
    delete datasource.useNamespace;
    delete datasource.namespace;
    delete datasource.network;
    delete datasource.workgroup_base_capacity;
    return this.props.api.datasource.create(
      this.state.group.account.uri,
      this.state.datasource.platformUri,
      datasource
    );
  };

  onSubmit = () =>
    this.save()
      .then((datasource) => {
        this.props.showGlobalNotification({
          message: "Datasource created",
          type: "success"
        });
        this.props.goTo({
          route: Routes.Datasource.Logs,
          params: {
            uriAccount: this.state.group.account.uri,
            uriDatasource: datasource.uri
          },
          state: {
            account: { uri: this.state.group.account.uri },
            playground: { uri: this.state.datasource.platformUri },
            datasource
          }
        });
      })
      .catch((errorSubmit) => {
        this.props.showGlobalNotification({
          message: "Datasource creation failed",
          type: "alert"
        });
        this.setState({
          errorSubmit
        });
      });

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} path={"DatasourceCreate"} />;
    }
    if (!this.state.ready) {
      return <Loading className={"my-3"} message={"datasource creation "} />;
    }

    return (
      <div className="datasource">
        <Breadcrumb view={"Create a new datasource"} />

        {this.state.groupError && (
          <div style={{ marginLeft: "30px", marginBottom: "30px" }}>
            {"You need to be part of a group before creating a datasource..."}
          </div>
        )}

        <Row>
          <Col size={1} />
          <Col size={10}>
            {this.state.errorSubmit && (
              <Error
                title={"..."}
                error={this.state.errorSubmit}
                displayError
                path={"DatasourceCreate"}
                stringOnly
              />
            )}

            <DatasourceForm
              isNew
              handleChange={this.handleChange}
              groups={this.state.groups}
              loadMoreGroups={this.getMyGroups}
              totalGroups={this.state.totalGroups}
              totalGroupsForSearchLimit={this.state.totalGroupsForSearchLimit}
              datasource={this.state.datasource}
              totalEnvironments={this.state.totalEnvironments}
              isFetchingEnvironments={this.state.isFetchingEnvironments}
              environmentSelected={this.state.platform}
              group={this.state.group}
              submit={this.onSubmit}
              errorSubmit={this.state.errorSubmit}
              isFetchingFirstGroups={this.state.isFetchingFirstGroups}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceCreate)));
