/* @flow */
import React from "react";
import { connect } from "react-redux";
import "./listPendingShares.less";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import { formatDate, diffDate } from "../../../../utils/date";
import ButtonAction from "../../../../components/buttonAction";

type propTypes = {
  api: GraphQl,
  share: Object,
  onError: Function,
  onSuccess: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object
};

class PendingShareElement extends React.Component<propTypes, stateTypes> {
  resendNotification = (share) =>
    this.props.api.share
      .resendShareRequest(share.dataset.uri, share.group.uri)
      .then(() => {
        this.props.showGlobalNotification({
          message:
            "A new notification was send to the owner, you can resend notification all the 24 hours",
          type: "success"
        });
        setTimeout(() => {
          this.props.onSuccess();
        }, 3000);
      })
      .catch((error) => {
        this.props.onError(error);
        this.props.showGlobalNotification({
          message: "Failed to resend request",
          type: "alert"
        });
      });

  getActions = (share) => {
    const dateNow = new Date();
    if (diffDate(share.updatedat, dateNow.toString()) >= 24) {
      return [
        <ButtonAction
          label="Send a reminder"
          className="butn butn-flat"
          icon="fa-bell"
          onClick={() => this.resendNotification(share)}
        />
      ];
    }
    return [
      <ButtonAction
        label="Send a reminder"
        disabled
        tooltipText="You can resend a reminder one time every 24 hours"
        className="butn butn-flat"
        icon="fa-bell"
        onClick={() => false}
      />
    ];
  };

  render() {
    return (
      <React.Fragment>
        <tr className="table-row">
          <th className="table-bold-column" scope="row">
            {this.props.share.group.account.name}
          </th>
          <td className="table-bold-column">{this.props.share.group.name}</td>
          <td className="table-date-column">
            {formatDate(this.props.share.updatedat, false)}
          </td>
          <td className="text-center">{this.getActions(this.props.share)}</td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(PendingShareElement)
);
