/* @flow */
import React from "react";
import "./topDatasets.less";
import Tabs from "../../../components/tabs";
import TopDatasetsSeen from "./topDatasetsSeen";
import TopDatasetsShared from "./topDatasetsShared";
import TopDatasetsBookmarked from "./topDatasetsBookmarked";
import TopDatasetsMostUsed from "./topDatasetsMostUsed";

type propTypes = {};

type stateTypes = {};

class TopDatasets extends React.PureComponent<propTypes, stateTypes> {
  render() {
    return (
      <div>
        <div className="title-top-datasets">
          <h5 className="border-title">TOP DATASETS</h5>
        </div>
        <Tabs
          isPreload={false}
          defaultActiveIndex={"mostSeen"}
          tabs={[
            {
              name: "Most Seen",
              index: "mostSeen",
              route: "#mostSeen",
              content: <TopDatasetsSeen />
            },
            {
              name: "Most Shared",
              index: "mostShared",
              route: "#mostShared",
              content: <TopDatasetsShared />
            },
            {
              name: "Most Bookmarked",
              index: "moreBookmarked",
              route: "#moreBookmarked",
              content: <TopDatasetsBookmarked />
            },
            {
              name: "Most Used",
              index: "mostUsed",
              route: "#mostUsed",
              content: <TopDatasetsMostUsed />
            }
          ]}
        />
      </div>
    );
  }
}

export default TopDatasets;
