/* @flow */

import React from "react";
import "./style.less";
import { connect } from "react-redux";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  stage: {
    id: string,
    dataset: {
      uri: string
    },
    columns: Array<{
      Type: string,
      Name: string,
      Metadata: Object,
      Description: string
    }>,
    recentProfilingExecution: {
      status: string
    }
  },
  column: {
    Type: string,
    Name: string,
    Metadata: Object,
    Description: string
  },
  reloadData: Function,
  closeEditionMode: Function
};

type stateTypes = {
  description: string,
  hasChanged: boolean,
  isSubmitting: boolean
};

class StageMetadataUpdate extends React.PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      description: this.props.column.Description,
      hasChanged: false,
      isSubmitting: false
    };
  }

  handleChange(value) {
    return this.setState({
      description: value,
      hasChanged: true
    });
  }

  saveEdit = () => {
    this.setState({ isSubmitting: true }, () => {
      this.props.api.dataset
        .updateStageColumnDescription(
          this.props.stage.dataset.uri,
          this.props.stage.id,
          this.props.column.Name,
          this.state.description
        )
        .then(() => {
          this.props.showGlobalNotification({
            message: "Column description updated",
            type: "success"
          });
          this.setState({ isSubmitting: false });
          this.props.closeEditionMode();
          this.props.reloadData();
        })
        .catch(() => {
          this.props.showGlobalNotification({
            message: "Failed to update column description",
            type: "alert"
          });
          this.setState({ isSubmitting: false });
          this.props.reloadData();
        });
    });
  };

  render() {
    return (
      <div>
        <legend className="label-form">description</legend>
        <textarea
          name="description"
          className="form-control bg-white"
          placeholder="Description..."
          onChange={(event) => this.handleChange(event.target.value)}
          defaultValue={this.props.column.Description}
        />
        <div className="actions-container">
          <div>
            <button
              type="button"
              onClick={() => this.saveEdit()}
              disabled={this.state.isSubmitting || !this.state.hasChanged}
              className="butn"
            >
              {this.state.isSubmitting && (
                <i className="fas fa-circle-notch fa-spin fa-spacing" />
              )}
              Apply
            </button>
          </div>
          <div className="ml-2">
            <button
              type="button"
              onClick={() => this.props.closeEditionMode()}
              className="butn-delete butn mr-2"
            >
              Exit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(StageMetadataUpdate)
);
