/* @flow */

import React from "react";
import "./style.less";
import Cookies from "js-cookie";
import Modal from "../modal";
import TermsConditionsContent from "./termsConditionsContent";
import config from "../../config";

type termsConditionsModalPropTypes = {
  choiceMode?: boolean,
  onClose: Function
};

const TermsConditionsModal = ({
  choiceMode,
  onClose
}: termsConditionsModalPropTypes) => {
  function setCookie(value: boolean) {
    Cookies.set("acceptedTermsConditions", value);
  }

  const actions = choiceMode
    ? [
        <button
          type="button"
          className="butn butn-create"
          key="accept"
          onClick={() => {
            setCookie(true);
            onClose();
          }}
        >
          Accept
        </button>,
        <a
          className="butn butn-delete"
          href="https://www.engie.com/"
          key="deny"
          onClick={() => {
            setCookie(false);
          }}
        >
          Deny
        </a>
      ]
    : [
        <button type="button" className="butn" key="close" onClick={onClose}>
          Close
        </button>
      ];

  return (
    <Modal
      classname="termsConditionsModal"
      title="Terms & conditions"
      body={<TermsConditionsContent baseUrl={config.HELP_CENTER} />}
      actions={actions}
      classModalLarge="modal-lg"
    />
  );
};

export default TermsConditionsModal;
