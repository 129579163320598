/* @flow */

import React, { Component } from "react";
import withAppSync from "../../../AppsyncHOC";
import Loading from "../../../../components/loading";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import RedshiftClusterDetails from "./redshiftClusterDetails";
import routes from "../../../routes";
import "./style.less";

type propTypes = {
  uriAccount: string,
  environmentUri: string,
  api: GraphQl,
  goTo: Function
};

type stateTypes = {
  clusters: Array<Object>,
  platform: Object,
  ready: boolean,
  error: ?Object
};

class RedshiftClustersList extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      platform: null,
      clusters: [],
      ready: false,
      error: null
    };
  }

  componentDidMount() {
    this.getEnvironmentRedshiftClusters();
  }

  getEnvironmentRedshiftClusters = () => {
    this.setState({
      ready: false
    });
    this.props.api.analytics
      .getEnvironmentRedshiftClusters(
        this.props.uriAccount,
        this.props.environmentUri
      )
      .then((platform) => {
        this.setState({
          ready: true,
          platform,
          clusters: platform.redshiftClusters
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
          error
        });
      });
  };

  render() {
    const { uriAccount, environmentUri } = this.props;
    const { clusters, error, ready, platform } = this.state;
    if (error) return <Error error={error} path={"RedshiftClustersList"} />;
    if (!ready) return <Loading message="RedshiftClusters" />;
    return (
      <div className={"analytics-container"}>
        <div className="analytics-actions">
          <div
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Playground.CreateCluster,
                params: {
                  uriAccount,
                  uriPlayground: environmentUri
                }
              })
            }
            style={{ marginRight: "5px" }}
          >
            <i className="fas fa-plus"></i>
            <span className="butn-text">Create</span>
          </div>
          <div
            className="butn butn-flat"
            onClick={() =>
              this.props.goTo({
                route: routes.Playground.ImportClusters,
                params: {
                  uriAccount,
                  uriPlayground: environmentUri
                }
              })
            }
          >
            <i className="fas fa-cloud-upload-alt"></i>
            <span className="butn-text">Import</span>
          </div>
        </div>
        <div className="card-container">
          {clusters.length > 0 ? (
            clusters.map((cluster) => (
              <RedshiftClusterDetails
                key={cluster.uri}
                platform={platform}
                cluster={cluster}
                reloadRedshiftClusters={this.getEnvironmentRedshiftClusters}
              />
            ))
          ) : (
            <div>No Redshift clusters to display.</div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppSync(RedshiftClustersList);
