/* @flow */
import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Links from "../links";
import "./style.less";
import Routes from "../routes";
import withErrorBoundary from "../errorBoundaryHOC";
import withGoTo from "../goToHOC";
import NewtificationBadge from "../notificationBadge/newtificationBadge";
import NavbarTitle from "./navbarTitle";
import withMe from "../meHOC";
import config from "../../config";

type propTypes = {
  location: {
    pathname: string
  },
  me: Object,
  isUserFromCDHTeam: boolean
};

const Navbar = ({ location, me, isUserFromCDHTeam }: propTypes) => (
  <div className="m-navbar navbar">
    {process.env.NODE_ENV === "developer" && (
      <span className="info-env badge badge-secondary">
        Appsync: {config.APPSYNC_ENDPOINT}
      </span>
    )}
    <Link to={Routes.Homepage.View} className="logo-part">
      <NavbarTitle />
    </Link>

    <div className={"navbar-right"}>
      <div className="actions">
        <Links.Homepage.View
          className={classnames("", {
            "is-active": location.pathname === Routes.Homepage.View
          })}
        >
          <div className={"action-label"}>Home</div>
        </Links.Homepage.View>

        <Links.Dataset.CatalogV2
          className={classnames("", {
            "is-active":
              location.pathname.indexOf("catalogv2") !== -1 ||
              location.pathname.startsWith("/catalogV2")
          })}
        >
          <div className={"action-label"}>
            {" "}
            <span>
              Catalog<span className="beta-menu-flag">BETA</span>
            </span>
          </div>
        </Links.Dataset.CatalogV2>

        <Links.MyData.View
          className={classnames("", {
            "is-active":
              location.pathname.indexOf("my-data") !== -1 ||
              location.pathname.startsWith("/datasource")
          })}
        >
          <div className={"action-label"}>My Data</div>
        </Links.MyData.View>
        {isUserFromCDHTeam && (
          <Links.MyDataV2.View
            className={classnames("", {
              "is-active":
                location.pathname.indexOf("datasets") !== -1 ||
                location.pathname.startsWith("/dataset")
            })}
          >
            <div className={"action-label"}>
              {" "}
              <span>
                Datasets<span className="beta-menu-flag">BETA</span>
              </span>
            </div>
          </Links.MyDataV2.View>
        )}
        {isUserFromCDHTeam && (
          <Links.Share.Manage
            className={classnames("", {
              "is-active":
                location.pathname.indexOf("my-shares") !== -1 ||
                location.pathname.startsWith("/share")
            })}
          >
            <div className={"action-label"}>
              {" "}
              <span>
                My Shares<span className="beta-menu-flag">BETA</span>
              </span>
            </div>
          </Links.Share.Manage>
        )}
        <Links.Cart.List
          className={classnames("", {
            "is-active":
              location.pathname.indexOf("projects") !== -1 ||
              location.pathname.startsWith("/project")
          })}
        >
          <div className={"action-label"}>My Projects</div>
        </Links.Cart.List>

        <Links.Account.List
          className={classnames("", {
            "is-active":
              location.pathname.indexOf("organizations") !== -1 ||
              location.pathname.startsWith("/organization")
          })}
        >
          <div className={"action-label"}>My Organizations</div>
        </Links.Account.List>
      </div>

      <div className="help">
        <NewtificationBadge className="notification-badge" />
        <Links.User.MyProfile
          className={classnames("", {
            "is-active": location.pathname.indexOf("profile") !== -1
          })}
        >
          <div className={"action-label"}>
            <div className={"circle myinbox"}>
              <b>{(me.name || "-")[0].toLowerCase()}</b>
            </div>
          </div>
          <span>{me.name}</span>
        </Links.User.MyProfile>
      </div>
    </div>
  </div>
);

export default withMe(withGoTo(withErrorBoundary(Navbar)));
