export const getCart = {
  query: `query Q ($cartUri:String!, $optionsDatasets:QueryOptions) {
        getCart (cartUri:$cartUri) {
            uri
            groupuri
            name
            dbx_catalog {
                dbx_catalog_name
            }
            dbx_workspace {
                dbx_workspace_name
                uuid
            }
            project_custom_tags {
                Key
                Value
            }
            region
            description
            resource_name
            creatoruri
            createdat
            database
            uuid
            is_refresh_auto_enabled
            is_database_enabled
            platformAttachedPowerBi {
                name
                uri
                aws
            }
            datasets (options: $optionsDatasets) {
                uri
                resource_name
                name
                description
                tags
                type
                arn
                source_type
                datasource {
                    uri
                    name
                    bucket
                    region
                    source_type
                    owner {
                        uri
                        name
                    }
                }
                createdat
                expiration_date
                creator {
                    uri
                    name
                }
            }
            platforms{
                uri
                description
                accountid
                aws 
                name
                environment
                default_region
            }
            dssInstances{
                uri
                description
                name
                url
            }
            redshiftClusters{
                uri
                cluster_name
            }
            cartRedshiftClusters{
                redshift_dsn
                redshift_attached_to_powerbi_status
                redshiftcluster_uri
            }
            group {
                uri
                name
                uuid
            }
            is_attached_to_powerbi
        }
      }
    `,
  keyOutput: "getCart"
};
