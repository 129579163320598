export const listDatabrews = {
  query: `query Q ($cartUri:String!, $options: QueryOptions) {
        listCartDatabrewProjects(cartUri:$cartUri, options: $options) {
            total
            results {
                id
                arn
                name
                creator
                status
                sample_type
                sample_size
                dataset_uri
                dataset {
                    uri
                    name
                }
                dataset_output_id
                dataset_output{
                    id
                    name
                }
                platform {
                    uri
                    name
                    aws
                    environment
                }
                cart_uri
                cart {
                    uri
                    name
                }
                tags { 
                    Key
                    Value
                }
                createdat
                updatedat
            }
        }
      }
    `,
  keyOutput: "listCartDatabrewProjects"
};
