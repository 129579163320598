/* @flow */

export const createDatasetsFromDetection = {
  query: `mutation Q ($datasourceUri: String!, $input: [NewDatasetInput!]!) {
        createDatasets(datasourceUri: $datasourceUri, input: $input) {
            uri
            name
        }
      }
    `,
  keyOutput: "createDatasets"
};
