/* @flow */

import React from "react";
import "./style.less";
import Modal from "../../../../components/modal";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";
import Logger from "../../../../utils/logger";

const Log = Logger("DetachGroupTrustModal");

type propTypes = {
  groupTrust: Object,
  hide: Function,
  onDetachTrustSuccess: Function,
  onDetachTrustFailure: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  isFetching: boolean
};

class DetachGroupTrustModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      isFetching: false
    };
  }

  detachTrust = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.props.api.group
          .detachTrustFromGroup(
            this.props.groupTrust.id,
            this.props.groupTrust.group.uri
          )
          .then(() => {
            this.setState({
              isFetching: false
            });
            this.props.onDetachTrustSuccess();
          })
          .catch((error) => {
            Log.error(error);
            this.setState({
              error,
              isFetching: false
            });
            this.props.onDetachTrustFailure();
          });
      }
    );
  };

  render() {
    return (
      <Modal
        title={"Detach Trust from group"}
        body={`Detach trust (${this.props.groupTrust.arn}) from group (${this.props.groupTrust.group.name})?`}
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-delete-${this.props.groupTrust.arn}`}
            type="button"
            className="butn butn-check"
            onClick={() => this.detachTrust()}
          >
            {this.state.isFetching && (
              <i className="fas fa-circle-notch fa-spin fa-spacing" />
            )}
            Detach
          </button>,
          <button
            key={`dataset-close-${this.props.groupTrust.arn}`}
            type="button"
            className="butn"
            onClick={this.props.hide}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

export default withAppSync(DetachGroupTrustModal);
