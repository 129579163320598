export const queryGetTables = {
  query: `query Q ($targetType:String!, $targetUri:String!, $platformUri:String!){
        getSqlLabTables(targetType:$targetType, targetUri:$targetUri, platformUri:$platformUri) {
            aws
            tablename
            region
            database
            columns {
                Name
                Type
            }
        }
      }
    `,
  keyOutput: "getSqlLabTables"
};
