/* @flow */
import React from "react";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import GraphQl from "../../../graphQL";
import "./style.less";
import DatasourceInformation from "./datasourceInformation";
import DetectedPrefixes from "./detectedPrefixes";
import DatasetsToCreate from "./datasetsToCreate";

type propTypes = {
  match: {
    params: {
      uriDatasource: string,
      uriAccount: string
    }
  },
  api: GraphQl
};

type stateTypes = {
  ready: boolean,
  datasource: Object,
  dataset?: Object,
  error?: Object,
  datasets: Array<Object>,
  prefixes: Array<Object>
};

class DatasourceDetectNewDatasets extends React.Component<
  propTypes,
  stateTypes
> {
  myRef: { current: any };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      datasource: {},
      error: undefined,
      datasets: [],
      prefixes: [],
      dataset: undefined
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getDatasource();
  }

  getDatasource = () =>
    this.props.api.datasource
      .getDatasourceForEdit(this.props.match.params.uriDatasource)
      .then((datasource) => {
        this.setState({
          datasource,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });

  onPopulate = (dataset) => {
    const prefixeselected = this.state.prefixes.filter((p) => p.isSelected);

    let { datasets } = this.state;
    datasets = datasets.filter(
      (d) => !(prefixeselected.filter((p) => p.value === d.prefix).length > 0)
    ); // before, remove updated dataset

    if (
      dataset.external_description_url &&
      !dataset.external_description_url.startsWith("http")
    ) {
      dataset.external_description_url = `https://${dataset.external_description_url}`;
    }

    if (prefixeselected.length === 1) {
      datasets.push({
        prefix: prefixeselected[0].value,
        name: dataset.name,
        type: dataset.type,
        description: dataset.description,
        basic_metadata: dataset.basic_metadata,
        metadata_frequency: dataset.metadata_frequency,
        maturity_level: dataset.maturity_level,
        tags: dataset.tags,
        domain: dataset.domain,
        visibility: dataset.visibility,
        shareable: dataset.shareable,
        is_registered_with_lakeformation:
          dataset.is_registered_with_lakeformation,
        languages: dataset.languages,
        data_types: dataset.data_types || [],
        update_frequency: dataset.update_frequency,
        external_description_url: dataset.external_description_url,
        license: dataset.license,
        contact: dataset.contact
      });
    } else {
      datasets = datasets.concat(
        prefixeselected.map((p) => ({
          prefix: p.value,
          name: p.value,
          type: dataset.type,
          description: dataset.description,
          basic_metadata: dataset.basic_metadata,
          metadata_frequency: dataset.metadata_frequency,
          maturity_level: dataset.maturity_level,
          tags: dataset.tags,
          domain: dataset.domain,
          visibility: dataset.visibility,
          shareable: dataset.shareable,
          is_registered_with_lakeformation:
            dataset.is_registered_with_lakeformation,
          languages: dataset.languages,
          data_types: dataset.data_types || [],
          update_frequency: dataset.update_frequency,
          external_description_url: dataset.external_description_url,
          license: dataset.license,
          contact: dataset.contact
        }))
      );
    }

    this.setState((prevState) => ({
      prefixes: prevState.prefixes.filter((p) => !p.isSelected),
      datasets
    }));
    return new Promise((r) => r());
  };

  render() {
    if (this.state.error)
      return (
        <Error error={this.state.error} path={"DatasourceDetectNewDatasets"} />
      );
    if (!this.state.ready)
      return <Loading className={"my-3"} message={"datasource"} />;

    return (
      <div className="datasource-detect-datasets">
        <Breadcrumb view={"Datasource, detect new datasets"} />

        <div>
          <DatasourceInformation datasource={this.state.datasource} />

          <DetectedPrefixes
            uriDatasource={this.props.match.params.uriDatasource}
            api={this.props.api}
            setPrefixes={(prefixes) => this.setState({ prefixes })}
            prefixes={this.state.prefixes}
            myRef={this.myRef}
            onPopulate={this.onPopulate}
            onCancel={() => this.setState({ dataset: undefined })}
            editDataset={this.state.dataset}
          />

          {(this.state.prefixes.filter((p) => p.isSelected).length > 0 ||
            this.state.datasets.length > 0) && (
            <DatasetsToCreate
              api={this.props.api}
              datasets={this.state.datasets}
              datasource={this.state.datasource}
              uriAccount={this.props.match.params.uriAccount}
              onEdit={(dataset) => {
                this.setState(
                  (prevState) => ({
                    prefixes: prevState.prefixes
                      .map((p) => ({ value: p.value, isSelected: false }))
                      .concat([{ value: dataset.prefix, isSelected: true }]),
                    dataset
                  }),
                  () => {
                    window.scrollTo(0, this.myRef.current.offsetTop);
                  }
                );
              }}
              onDelete={(dataset) =>
                this.setState((prevState) => ({
                  prefixes: prevState.prefixes.concat([
                    { value: dataset.prefix, isSelected: false }
                  ]),
                  datasets: prevState.datasets.filter(
                    (d) => d.prefix !== dataset.prefix
                  )
                }))
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default withAppSync(DatasourceDetectNewDatasets);
