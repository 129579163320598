/* @flow */

export const listGroupNotIdentities = {
  query: `query Q ($accountUri: String!,$groupUri:String!, $options: QueryOptions) {
        getGroup(accountUri: $accountUri, groupUri:$groupUri) {
            notIdentities (options: $options) {
                name
                resource_name
                uri
            }
        }
      }
    `,
  keyOutput: "getGroup.notIdentities"
};

export type GroupNotIdentitiesPropsTypes = Array<{
  name: string,
  resource_name: string,
  uri: string
}>;
