/* @flow */

export const listAccountsAndPlatforms = {
  query: `query Q {
        listAccounts {
            name
            uri
            platforms {
                name
                aws
                uri
                description
            }
        }
      }
    `,
  keyOutput: "listAccounts"
};

export type listAccountsAndPlatformsPropsTypes = Array<{
  uri: string,
  name: string,
  platforms: Array<{
    uri: string,
    name: string,
    aws: string,
    description: string
  }>
}>;
