/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import withAppSync from "../AppsyncHOC";
import { formatDate } from "../../utils/date";
import { updateMessage } from "../globalNotifications/actions";
import "./style.less";
import GraphQl from "../../graphQL";

type propTypes = {
  datafilter: {
    id: string,
    name: string,
    creator: string,
    status: string,
    dataset: {
      uri: string,
      name: string
    },
    stage: {
      id: string,
      name: string
    },
    createdat: string,
    updatedat: string,
    database_name: string,
    table_name: string,
    description: string,
    all_columns: boolean,
    columns: Array<string>,
    column_level_access: string,
    row_filter_expression: string,
    region: string,
    aws: string,
    shares: [
      {
        id: string,
        group: {
          name: string
        }
      }
    ]
  },
  api: GraphQl,
  projects: Object,
  onEdit: Function,
  onDelete: Function
};

type stateTypes = {
  loadingDeleteDatafilter: boolean,
  projects: Array<Object>
};

class DataFilterItem extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      projects: [],
      loadingDeleteDatafilter: false
    };
  }

  componentDidMount() {
    this.getDatasetProjects();
  }

  getDatasetProjects = () => {
    this.props.api.dataset
      .getDatasetProjects(this.props.datafilter.dataset.uri)
      .then((projects) => {
        this.setState({
          projects
        });
      });
  };
  renderColumns = (datafilter) =>
    datafilter.all_columns === false
      ? datafilter.columns.toString()
      : "All Columns";

  render() {
    const { datafilter } = this.props;
    return (
      <li className="cdh-cart card-shadow card bg-white card-datafilter-details">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content-datafilter">
              <i className="fas fa-filter fa-spacing" />
              Data Filter
            </div>
          </div>
          <div className="cdh-cart-end">
            <div className="cdh-cart-end-content">
              <i className="fab fa-aws" />
              <span>{datafilter.aws}</span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <span className="text-capitalize cdh-card-name">
              {this.props.datafilter.name}
            </span>

            <div className="cdh-card-details">
              <div className="cdh-card-description">
                <span>{datafilter.description}</span>
              </div>

              {/* <p>
                                <b>Dataset:</b> {datafilter.dataset.name}
                            </p> */}

              <div className="card-datafilter-details-stage">
                <b>Stage:</b> {datafilter.stage.name}
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-globe-europe" />
                <span className="content-card-bucket">
                  <b>Region:</b> {datafilter.region}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-database" />
                <span className="content-card-bucket">
                  <b>Database:</b> {datafilter.database_name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-layer-group" />
                <span className="content-card-bucket">
                  <b>Table:</b> {datafilter.table_name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-columns" />
                <span className="content-card-bucket">
                  <b>Column Level Access:</b> {datafilter.column_level_access}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fas fa-columns" />
                <span className="content-card-bucket">
                  <b>Columns:</b> {this.renderColumns(datafilter)}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fa fa-code" />
                <span className="content-card-bucket">
                  <b>Row Filter Expression:</b>{" "}
                  {datafilter.row_filter_expression}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div className={"btn-cart"} onClick={this.props.onEdit}>
                <i className="fas fa-pencil-alt fa-spacing" />
                <span className="text-actions">Edit</span>
              </div>
              <div className="btn-cart" onClick={this.props.onDelete}>
                {this.state.loadingDeleteDatafilter && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <i className="far fa-calendar-alt fa-spacing" />
            <span className="text-footer-left">
              {formatDate(datafilter.createdat, false)}
            </span>
          </div>

          <div className="cdh-card-end">
            <div className="text-footer-metrics">
              Shared with
              <span className="tag-metrics">
                {this.props.datafilter.shares.length || "0"}
              </span>
              &nbsp;groups, in
              <span className="tag-metrics">
                {this.state.projects.length || "0"}
              </span>
              &nbsp;projects&nbsp; Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": [
                    "creating",
                    "create_complete",
                    "available"
                  ].includes(datafilter.status.toLowerCase()),
                  "error-status": [
                    "deleted",
                    "create_failed",
                    "notfound"
                  ].includes(datafilter.status.toLowerCase())
                })}
              >
                {datafilter.status === "Creating"
                  ? "CREATE_COMPLETE"
                  : datafilter.status}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(DataFilterItem));
