/* @flow */
import React from "react";
import routes from "../routes";
import "./card.less";
import withGoTo from "../goToHOC";
import withAppSync from "../AppsyncHOC";
import GraphQl from "../../graphQL";

type propTypes = {
  api: GraphQl,
  uri: string,
  name: string,
  creator: {
    name: string
  },
  goTo: Function,
  deleteAccount: Function
};

type stateTypes = {
  showDataikuDSS: boolean,
  showCollibra: boolean,
  showDatabricks: boolean
};

class AccountCard extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      showDataikuDSS: false,
      showCollibra: false,
      showDatabricks: false
    };
  }

  componentDidMount() {
    this.isDataikuFeatureEnabled();
    this.isCollibraFeatureEnabled();
    this.isDatabricksFeatureEnabled();
  }

  isDataikuFeatureEnabled() {
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATAIKU", this.props.uri)
      .then((showDataikuDSS) => {
        this.setState({ showDataikuDSS });
      });
  }

  isCollibraFeatureEnabled() {
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("COLLIBRA", this.props.uri)
      .then((showCollibra) => {
        this.setState({ showCollibra });
      });
  }

  isDatabricksFeatureEnabled() {
    return this.props.api.featureToggle
      .isFeatureToggleEnabled("DATABRICKS", this.props.uri)
      .then((showDatabricks) => {
        this.setState({ showDatabricks });
      });
  }

  actions() {
    return (
      <React.Fragment>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewUsers,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Users
        </div>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewGroups,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Groups
        </div>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewEnvironments,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Environments
        </div>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewDatasources,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Datasources
        </div>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewPolicies,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Policies
        </div>
        <div
          className={"account-button"}
          onClick={() =>
            this.props.goTo({
              route: routes.Account.ViewTrust,
              params: { uriAccount: this.props.uri },
              state: { account: { name: this.props.name } }
            })
          }
        >
          Trusts
        </div>
        {this.state.showDataikuDSS && (
          <div
            className={"account-button"}
            onClick={() =>
              this.props.goTo({
                route: routes.Account.ViewDSS,
                params: { uriAccount: this.props.uri },
                state: { account: { name: this.props.name } }
              })
            }
          >
            <span>
              Dataiku DSS<span className="beta-dku-flag">BETA</span>
            </span>
          </div>
        )}
        {this.state.showCollibra && (
          <div
            className={"account-button"}
            onClick={() =>
              this.props.goTo({
                route: routes.Account.ViewCollibra,
                params: { uriAccount: this.props.uri },
                state: { account: { name: this.props.name } }
              })
            }
          >
            <span>
              Collibra<span className="beta-collibra-flag">BETA</span>
            </span>
          </div>
        )}
        {this.state.showDatabricks && (
          <div
            className={"account-button"}
            onClick={() =>
              this.props.goTo({
                route: routes.Account.ViewDatabricks,
                params: { uriAccount: this.props.uri },
                state: { account: { name: this.props.name } }
              })
            }
          >
            <span>
              Databricks<span className="beta-databricks-flag">BETA</span>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="card account-card bg-white card-shadow card-rounded">
        <div className="account-name-container">
          <div
            onClick={() =>
              this.props.goTo({
                route: routes.Account.View,
                params: { uriAccount: this.props.uri },
                state: {
                  account: { name: this.props.name, uri: this.props.uri }
                }
              })
            }
          >
            <h4 className="account-name text-primary text-capitalize">
              {this.props.name}
            </h4>
          </div>
          <div className={"text-primary account-icon"}>
            <i
              className="fas fa-edit account-edit"
              onClick={() =>
                this.props.goTo({
                  route: routes.Account.Edit,
                  params: { uriAccount: this.props.uri },
                  state: {
                    account: { name: this.props.name, uri: this.props.uri }
                  }
                })
              }
            />
            <i
              className="far fa-trash-alt account-delete"
              onClick={() => this.props.deleteAccount()}
            />
          </div>
        </div>
        <div className="account-button-container">{this.actions()}</div>
      </div>
    );
  }
}

export default withGoTo(withAppSync(AccountCard));
