export const rollbackLakeFormationMigration = {
  query: `mutation Q ($platformUri : String!, $region: String!) {
        rollbackLakeFormationMigration(platformUri : $platformUri, region: $region) {
          id
          region
          report {
           id
          }
          type
          status
          stepfunctions
          platform_uri
        }
      }
    `,
  keyOutput: "rollbackLakeFormationMigration"
};
