export const listNewDatasourceLogs = {
  query: `query Q ($datasourceUri: String!, $options: QueryOptions) {
        getDataSourceLogs(datasourceUri:$datasourceUri, options: $options) {
          total
          results {
            id
            createdat
            updatedat
            status
            output
            action
            operation
            datasource_uri
            triggered_by
            creator {
                name
                gid
            }
            endedat
          }
        }
      }
    `,
  keyOutput: "getDataSourceLogs"
};
