/* @flow */

import React from "react";
import "./style.less";
import { Box, Divider } from "@material-ui/core";
import StageMetadataFullOverviewContent from "./stageMetadataFullOverviewContent";
import Tabs from "../../../../components/tabs";
import withGoTo from "../../../goToHOC";
import Col from "../../../../components/col";
import Row from "../../../../components/row";

type propTypes = {
  match: { url: string },
  stage: {
    id: string,
    columns: Array<{
      Type: string,
      Name: string,
      Metadata: Object,
      Description: string,
      Comment: string
    }>,
    profiling_execution: Object,
    profiling_nb_rows: Object
  },
  reloadData: Function
};

class StageMetadataFullOverview extends React.PureComponent<propTypes> {
  render() {
    const { stage } = this.props;

    const { columns = [] } = stage;

    let statistics;
    let tAlerts = [];
    const cTypes = [];
    if (stage.profiling_execution) {
      if (
        stage.profiling_execution.statistics &&
        stage.profiling_execution.statistics !== "null"
      ) {
        statistics = JSON.parse(stage.profiling_execution.statistics);
        Object.entries(statistics.types).forEach(([k, v]) =>
          cTypes.push({ key: k, value: v })
        );
      }
      tAlerts = stage.profiling_execution.alerts.map((a) => {
        const alert = {
          columnName: a.split("has")[0],
          alertText: `has ${a.split("has")[1]}`,
          badgeName: "UNKNOWN",
          className: "badge badge-pill badge-primary mt-2 mb-2"
        };
        if (a.includes("missing")) {
          alert.badgeName = "MISSING";
          alert.className = "badge badge-pill badge-danger mt-2 mb-2";
          return alert;
        }
        if (a.includes("constant")) {
          alert.badgeName = "CONSTANT";
          alert.className = "badge badge-pill badge-warning mt-2 mb-2";
          return alert;
        }
        if (a.includes("distinct")) {
          alert.badgeName = "HIGH CARDINALITY";
          alert.className = "badge badge-pill badge-primary mt-2 mb-2";
          return alert;
        }
        return alert;
      });
    }

    if (!columns || columns.length <= 0) {
      return <div>No Column to display</div>;
    }
    return (
      <Tabs
        isPreload={false}
        defaultActiveIndex={"columns"}
        tabs={[
          {
            name: (
              <div key={"columns"}>
                <i className="fa fa-columns"></i>
                Columns
              </div>
            ),
            route: `${this.props.match.url}#metadata/columns`,
            index: "columns",
            content: (
              <ul className="output-details list-group">
                {columns.map((column) => (
                  <li className="list-group-item" key={column.Name}>
                    <StageMetadataFullOverviewContent
                      stage={this.props.stage}
                      column={column}
                      reloadData={this.props.reloadData}
                    />
                  </li>
                ))}
              </ul>
            )
          },
          {
            name: (
              <div key={"statistics"}>
                <i className="fa fa-list-ol"></i>
                Statistics
              </div>
            ),
            route: `${this.props.match.url}#metadata/statistics`,
            index: "statistics",
            content: (
              <li className="list-group-item">
                {statistics ? (
                  <Box>
                    <Row>
                      <Col size={6}>
                        <div className="stats-title">
                          <span>Table Statistics</span>
                        </div>
                        <Divider />
                        <Row>
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-primary">
                                Number of columns
                              </span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span>{statistics.n_var}</span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Divider />
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-primary">Total rows</span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span>{stage.profiling_nb_rows}</span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Divider />
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-primary">
                                Profiled rows
                              </span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span>{statistics.n}</span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Divider />
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-danger">Missing cells</span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-danger">
                                {statistics.n_cells_missing}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Divider />
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-danger">
                                Missing cells (%)
                              </span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-danger">
                                {Math.round(statistics.p_cells_missing * 100)}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-warning">
                                Duplicate rows
                              </span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-warning">
                                {statistics.n_duplicates}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row>
                          <Col size={6}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-warning">
                                Duplicate rows (%)
                              </span>
                            </div>
                          </Col>
                          <Col size={4}>
                            <div className="text-metadata-alerts mt-2 mb-2">
                              <span className="text-warning">
                                {Math.round(statistics.p_duplicates * 100) /
                                  100}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col size={4}>
                        <div className="stats-title">
                          <span>Column Types</span>
                        </div>
                        {statistics &&
                          statistics.types &&
                          cTypes.map((t) => (
                            <Box>
                              <Divider />
                              <Row>
                                <Col size={6}>
                                  <div className="text-metadata-alerts mt-2 mb-2">
                                    <span className="text-primary">
                                      {t.key}
                                    </span>
                                  </div>
                                </Col>
                                <Col size={4}>
                                  <div className="text-metadata-alerts mt-2 mb-2">
                                    {/* $FlowIgnore */}
                                    <span>{t.value}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Box>
                          ))}
                      </Col>
                    </Row>
                  </Box>
                ) : (
                  <div className="text-metadata-notfound mt-2 mb-2">
                    <span>No Table Statistics to display.</span>
                  </div>
                )}
              </li>
            )
          },
          {
            name: (
              <div key={"alerts"}>
                <i className="fas fa-exclamation-triangle"></i>
                Alerts
              </div>
            ),
            route: `${this.props.match.url}#metadata/alerts`,
            index: "alerts",
            content: (
              <li className="list-group-item">
                {tAlerts &&
                  tAlerts.length > 0 &&
                  tAlerts.map((a) => (
                    <Box>
                      <Divider />
                      <Row>
                        <Col size={10}>
                          <div className="text-metadata-alerts mt-2 mb-2">
                            <span className="text-primary">{a.columnName}</span>
                            <span>{a.alertText}</span>
                          </div>
                        </Col>
                        <Col size={2}>
                          <div className={a.className}>
                            <span>{a.badgeName}</span>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  ))}
                {(!tAlerts || tAlerts.length === 0) && (
                  <div className="text-metadata-notfound mt-2 mb-2">
                    <span>No Alerts to display.</span>
                  </div>
                )}
              </li>
            )
          }
        ]}
      />
    );
  }
}

export default withGoTo(StageMetadataFullOverview);
