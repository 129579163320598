/* @flow */

import React from "react";
import NotificationSkeleton from "../notificationSkeleton";
import ShareDatasetRequestManaged from "../../../share/components/managedShare/datasetShare";
import ShareDatasetRequestRequester from "../../../share/components/shareRequest/datasetShare";
import ReleaseNotification from "../releaseNotification";
import SimpleNotificationMessage from "../simpleNotificationMessage";
import TrustNotification from "../trustNotification";
import DatasetUpdateOutdatedNotification from "../datasetUpdateOutdated";

type propTypes = {
  notification: Object,
  onAction: Function
};

type stateTypes = {
  error: ?Object
};

class Newtification extends React.Component<propTypes, stateTypes> {
  static defaultProps = {
    onAction: () => {},
    notification: {
      id: "",
      content: "",
      is_read: false,
      resources: {},
      createdat: "00-00-0000 00:00:00",
      updatedat: undefined,
      subtype: {
        id: "",
        code: "",
        name: "",
        description: ""
      }
    }
  };

  getContent = () => {
    if (
      this.props.notification.subtype.code ===
        "share_dataset_request_managed" ||
      this.props.notification.subtype.code === "share_dataset_reminder_managed"
    ) {
      return (
        <ShareDatasetRequestManaged
          dataset={this.props.notification.resources.dataset}
          share={this.props.notification.resources}
          onAction={this.props.onAction}
        />
      );
    }

    if (
      this.props.notification.subtype.code === "dataset_update_non_compliant"
    ) {
      return (
        <DatasetUpdateOutdatedNotification
          datasetUri={this.props.notification.resources.uri}
          message={this.props.notification.content}
        />
      );
    }

    if (
      this.props.notification.subtype.code === "share_dataset_request_requester"
    ) {
      return (
        <ShareDatasetRequestRequester
          dataset={this.props.notification.resources.dataset}
          share={this.props.notification.resources}
        />
      );
    }

    if (this.props.notification.subtype.code === "OPSRLS") {
      return <ReleaseNotification date={this.props.notification.createdat} />;
    }

    if (this.props.notification.subtype.code === "attach_trust_to_group") {
      return (
        <TrustNotification
          message={this.props.notification.content}
          data={this.props.notification.resources}
          onAction={this.props.onAction}
        />
      );
    }

    return (
      <SimpleNotificationMessage message={this.props.notification.content} />
    );
  };

  getLabel = () => {
    if (
      this.props.notification.subtype.code === "share_dataset_request_managed"
    ) {
      return (
        <div className="notification-label">Manage shares with my datasets</div>
      );
    }

    if (
      this.props.notification.subtype.code === "share_dataset_request_requester"
    ) {
      return <div className="notification-label">My shares Requests</div>;
    }

    if (this.props.notification.subtype.code === "OPSRLS") {
      return <div className="notification-label">New Version</div>;
    }

    return (
      <div className="notification-label">
        {this.props.notification.subtype.name}
      </div>
    );
  };

  render() {
    return (
      <NotificationSkeleton
        notification={this.props.notification}
        content={this.getContent()}
        label={this.getLabel()}
      ></NotificationSkeleton>
    );
  }
}

export default Newtification;
