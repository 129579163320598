/* @flow */
export const listTrusts = {
  query: `query Q ($accountUri: String!, $options: QueryOptions) {
        listTrusts(accountUri: $accountUri, options: $options) {
            total
            result {
                is_admin
                trusts {
                    id
                    arn
                    description
                    policy_condition
                    type
                    is_admin
                    nb_groups
                    attach_by_default
                    ... on AdminTrust {
                        platform {
                            uri
                            name
                            aws
                        }
                    }
                    ... on CustomTrust {
                        platform {
                            uri
                            name
                            aws
                        }
                        cart {
                            uri
                            name
                            group {
                                name
                            }
                        }
                    }
                }
            }
        }
      }
    `,
  keyOutput: "listTrusts"
};
