/* @flow */
import React from "react";
import { connect } from "react-redux";
import Links from "../../../links";
import withAppSync from "../../../AppsyncHOC";
import { updateMessage } from "../../../globalNotifications/actions";
import DatasourceModal from "../../../datasources/view/DatasourceModal";
import Stringslider from "../../../../components/stringslider";
import { formatDate } from "../../../../utils/date";
import "./style.less";
import GraphQL from "../../../../graphQL";

type propTypes = {
  api: GraphQL,
  cartUri: string,
  projectGroupUri: string,
  reloadDadasources: Function,
  datasource: {
    accountid: string,
    bucket: string,
    createdat: string,
    region: string,
    description: string,
    uri: string,
    parent: string,
    name: string,
    user_roles: Array<string>,
    datasets: Array<{
      uri: string,
      name: string
    }>,
    datasetsList: {
      total: number
    },
    owner: {
      uri: string,
      name: string
    },
    openDatasourceModal: Function,
    datasourceModal: false
  },
  showGlobalNotification: Function,
  goBack: Function
};

type stateTypes = {
  datasourceModal: boolean,
  submitDatasourceRemove: boolean,
  nbDatasets: Object
};

class DatasourceProjectCard extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);

    this.state = {
      datasourceModal: false,
      submitDatasourceRemove: false,
      nbDatasets: this.props.datasource.datasetsList
        ? this.props.datasource.datasetsList.total
        : (this.props.datasource.datasets || []).length
    };
  }

  openDatasourceModal = () => this.setState({ datasourceModal: true });

  closeDatasourceModal = () => this.setState({ datasourceModal: false });

  removeFromProject = () => {
    this.setState({ submitDatasourceRemove: true });
    this.props.api.datasource
      .removeDatasourceFromProject(
        this.props.datasource.uri,
        this.props.cartUri
      )
      .then(() => {
        this.setState({ submitDatasourceRemove: false });
        this.props.showGlobalNotification({
          message: "Datasource removed from a project",
          type: "success"
        });
        this.props.reloadDadasources();
      })
      .catch(() => {
        this.setState({ submitDatasourceRemove: false });
        this.props.showGlobalNotification({
          message: "Failed to remove datasource from a project",
          type: "alert"
        });
      });
  };

  render() {
    return (
      <div className={"datasource-card-project card-shadow card bg-white"}>
        <div className="card-datasource-header">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <i className="fas fa-user-friends" />
              <Links.Group.View
                uriGroup={this.props.datasource.owner.uri}
                state={{ group: this.props.datasource.owner }}
              >
                <span className="text-header-datasource">
                  {this.props.datasource.owner.name.substring(0, 50)}
                </span>
              </Links.Group.View>
            </div>
          </div>
          <div className="card-datasource-end">
            <i className="far fa-calendar-alt" />
            <span className="text-header-datasource">
              {formatDate(this.props.datasource.createdat, false)}
            </span>
          </div>
        </div>
        <div className="card-datasource-body">
          <div className="card-datasource-body-left">
            <div className={"text-capitalize datasource-card-name"}>
              <Links.Datasource.View
                uriAccount={this.props.datasource.accountid}
                uriDatasource={this.props.datasource.uri}
              >
                <Stringslider
                  s={this.props.datasource.name}
                  height={30}
                  width={275}
                />
              </Links.Datasource.View>
            </div>
            <div className="card-datasource-details">
              <p className="card-cart-description">
                {this.props.datasource.description &&
                this.props.datasource.description.length > 80
                  ? `${this.props.datasource.description.substring(0, 80)}...`
                  : this.props.datasource.description}
              </p>
              <div className="card-cart-role">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  s3://{this.props.datasource.bucket}
                </span>
              </div>
              <div className="card-cart-role">
                <i className="fas fa-globe-europe"></i>
                <span className="content-card-bucket">
                  {this.props.datasource.region}
                </span>
              </div>
            </div>
          </div>
          <div className="card-datasource-body-right">
            <div className="card-datasource-body-actions">
              {this.props.datasource.user_roles &&
                this.props.datasource.user_roles.includes("owner") && (
                  <div>
                    <Links.Datasource.View
                      uriAccount={this.props.datasource.accountid}
                      uriDatasource={this.props.datasource.uri}
                    >
                      <i className="fas fa-play" />
                      <span className="text-actions">View Details</span>
                    </Links.Datasource.View>
                  </div>
                )}

              <div>
                <span
                  className="cursorPointer"
                  onClick={() => this.removeFromProject()}
                >
                  {this.state.submitDatasourceRemove && (
                    <i className="fas fa-circle-notch fa-spin fa-spacing" />
                  )}
                  <i className="fas fa-trash" />
                  <span className="text-actions">Remove from Project</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-datasource-footer">
          <div className="card-datasource-begin">
            <div className="card-datasource-begin-content">
              <div className="flag-user-roles">
                {this.props.datasource.owner.uri ===
                this.props.projectGroupUri ? (
                  <span className="user-role owner">OWNER</span>
                ) : (
                  <span className="user-role shared">SHARED</span>
                )}
              </div>
            </div>
          </div>
          <div className="card-datasource-end">
            <div className="text-footer-metrics">
              Contains{" "}
              <span className="tag-metrics">{this.state.nbDatasets}</span>{" "}
              datasets
            </div>
          </div>
        </div>

        <div>
          {this.state.datasourceModal && (
            <DatasourceModal
              datasource={this.props.datasource}
              hide={this.closeDatasourceModal}
              showGlobalNotification={this.props.showGlobalNotification}
              goBack={this.props.goBack}
              reload
            />
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DatasourceProjectCard));
