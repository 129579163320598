export const getDatabricksWorkspaceDetails = {
  query: `query GetDatabricksWorkspaceDetails($dbx_workspace_uuid: String!, $options: DatabricksDetailedWorkspaceOptions) {
        getDatabricksWorkspaceDetails(dbx_workspace_uuid: $dbx_workspace_uuid, options: $options) {
            cdh_env_uri
            dbx_account_uuid
            dbx_warehouse_id
            dbx_workspace_id
            dbx_workspace_name
            dbx_workspace_url
            uuid
            projects {
                projects {
                    access
                    createdat
                    creatoruri
                    database
                    description
                    resource_name
                    is_refresh_auto_enabled
                    is_database_enabled
                    is_attached_to_powerbi
                    uuid
                    uri
                    region
                    name
                    group {
                        name
                        uri
                    }
                }
                total
            }
            dbx_groups {
                groups {
                    accountid
                    creatoruri
                    name
                    description
                    parent
                    uuid
                    uri
                }
                total
            }
        }
    }`,
  keyOutput: "getDatabricksWorkspaceDetails"
};
