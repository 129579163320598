/* @flow */

import React from "react";
import withAppSync from "../../../../AppsyncHOC";
import GraphQl from "../../../../../graphQL";
import SelectAutocomplete from "../../../../../components/SelectAutocomplete";

type propTypes = {
  api: GraphQl,
  handleChange: Function,
  defaultValue?: Array<string>
};
type stateTypes = {
  languages: Array<{ value: string, label: string }>
};

class AutoCompleteLanguages extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      languages: []
    };
  }

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages() {
    this.props.api.utils
      .getLanguages()
      .then((languages) => {
        this.setState({
          languages: languages.map((l) => ({ value: l, label: l }))
        });
      })
      .catch(() => {});
  }

  render() {
    return (
      <SelectAutocomplete
        placeholder={"Select Languages"}
        options={this.state.languages}
        selectedOption={
          this.props.defaultValue
            ? this.props.defaultValue.map((v) => ({ value: v, label: v }))
            : []
        }
        onSelectOption={(options) =>
          this.props.handleChange({
            target: { value: options ? options.map((o) => o.value) : [] }
          })
        }
        isMulti
      />
    );
  }
}

export default withAppSync(AutoCompleteLanguages);
