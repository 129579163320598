export const listAllAccounts = {
  query: `query Q {
        listAllAccounts {
            uri
            accountid
            name
            gbu {
              id
              code
              name
            }
       }
      }
    `,
  keyOutput: "listAllAccounts"
};
