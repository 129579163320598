/* @flow */
import React from "react";
import "./style.less";

type propTypes = {
  onClick: Function,
  handleIsHovered: Function,
  handleIsNotHovered: Function,
  classname: string
};

class Step extends React.PureComponent<propTypes> {
  render() {
    return (
      <div
        className="step"
        onClick={this.props.onClick}
        onMouseEnter={this.props.handleIsHovered}
        onMouseLeave={this.props.handleIsNotHovered}
      >
        <div className={`step-bloc ${this.props.classname}`} />
      </div>
    );
  }
}

export default Step;
