/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import GraphQl from "../../../graphQL";
import DeletePolicyModal from "./components/deletePolicyModal";
import "./style.less";
import FiltersPolicies from "./filtersPolicies";
import Paginator from "../../../components/paginator";
import PolicyRow from "./policyRow";

type propTypes = {
  api: GraphQl,
  accountUri: string,
  showGlobalNotification: Function
};

type stateTypes = {
  policies: Array<Object>,
  policiesFilter: Array<Object>,
  offset: number,
  total: number,
  deletePolicyModal: Object | boolean,
  search?: Object
};

class PoliciesList extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      deletePolicyModal: false,
      policies: [],
      policiesFilter: [],
      offset: 0,
      total: 0,
      search: {
        limit: 10
      }
    };
  }

  componentDidMount() {
    this.getPolicies(this.state.search);
    setTimeout(() => {
      this.getPoliciesFilter();
    }, 3000);
  }

  getPolicies = (search: Object) => {
    search.offset = this.state.offset;
    this.setState({ policies: [], search });
    return this.props.api.account
      .listPolicies(this.props.accountUri, search)
      .then((policies) => {
        this.setState({
          policies: policies.results.policies,
          total: policies.total
        });
      })
      .catch(() => {});
  };

  getPoliciesFilter(offsetFilter = 0) {
    const search = {
      limit: 10,
      offset: offsetFilter
    };
    return this.props.api.account
      .listPolicies(this.props.accountUri, search)
      .then((policies) => {
        this.setState({
          total: policies.total
        });

        if (policies.results.policies.length > 0) {
          this.setState((prevState) => {
            const policiesFilter = [...prevState.policiesFilter];
            policies.results.policies.map((policy) =>
              policiesFilter.push(policy)
            );
            return { policiesFilter };
          });
        }

        if (policies.total > offsetFilter) {
          this.getPoliciesFilter(offsetFilter + 10);
        }
      })
      .catch(() => {});
  }

  getPoliciesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () =>
        this.getPolicies(this.state.search).then(r)
      )
    );

  openDeletePolicyModal = (policy: Object) =>
    this.setState({ deletePolicyModal: { policy } });

  closeDeletePolicyModal = () => this.setState({ deletePolicyModal: false });

  onDetachPolicySuccess = () => {
    this.props.showGlobalNotification({
      message: "Policy detach success",
      type: "success"
    });
    this.closeDeletePolicyModal();
    this.getPolicies(this.state.search);
  };

  onDetachPolicyFailure = () => {
    this.props.showGlobalNotification({
      message: "Policy detach failed",
      type: "alert"
    });
    this.closeDeletePolicyModal();
    this.getPolicies(this.state.search);
  };

  render() {
    return (
      <div className="list-policies">
        <div className="mt-2">
          <Paginator
            container={(content) => (
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-group" scope="col">
                      GROUP
                    </th>
                    <th className="col-env" scope="col">
                      ENVIRONMENT
                    </th>
                    <th className="col-policy" scope="col">
                      POLICY ARN
                    </th>
                    <th className="col-desc" scope="col">
                      DESCRIPTION
                    </th>
                    <th className="col-projects" scope="col center-column">
                      PROJECTS
                    </th>
                    <th
                      className="col-action text-center"
                      scope="col center-column"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <FiltersPolicies
                    getPolicies={this.getPolicies}
                    policies={this.state.policiesFilter}
                  />
                  {content}
                </tbody>
              </table>
            )}
            limit={10}
            initialOffset={0}
            totalCount={this.state.total}
            list={this.state.policies}
            componentRender={(policy) => (
              <PolicyRow
                policy={policy}
                openDeletePolicyModal={(policyToDelete) => {
                  this.openDeletePolicyModal(policyToDelete);
                }}
              />
            )}
            loadPage={this.getPoliciesFromPagination}
          />
        </div>
        {typeof this.state.deletePolicyModal === "object" && (
          <DeletePolicyModal
            policy={this.state.deletePolicyModal.policy}
            hide={this.closeDeletePolicyModal}
            onDetachPolicySuccess={this.onDetachPolicySuccess}
            onDetachPolicyFailure={this.onDetachPolicyFailure}
          />
        )}
      </div>
    );
  }
}

export default PoliciesList;
