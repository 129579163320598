/* @flow */

import React from "react";
import DisplayCompleteness from "./displayCompleteness";
import "./style.less";

type propTypes = {
  stage: {
    id: string,
    columns: Array<{
      Type: string,
      Name: string,
      Metadata: Object,
      Description: string,
      Comment: string
    }>,
    profiling_execution: {
      status: string
    }
  }
};

class StageMetadataLimitedOverview extends React.PureComponent<propTypes> {
  // eslint-disable-next-line class-methods-use-this
  renderCompleteness(column: Object) {
    if (column.Metadata) {
      return (
        <DisplayCompleteness completeness={column.Metadata.Completeness} />
      );
    }

    return false;
  }

  render() {
    const { stage } = this.props;

    const { columns = [] } = stage;

    if (!columns || columns.length <= 0) {
      return <div>No Column to display</div>;
    }

    return (
      <ul className="output-details-limited list-group">
        {columns.map((column) => (
          <li className="list-group-item" key={column.Name}>
            <div className="info mb-2">
              <h2>{column.Name}</h2>
              <code>{column.Type}</code>
            </div>
            <div className="info-description">
              {column.Description === "" && !column.Comment
                ? "No description available"
                : column.Comment || column.Description}
            </div>
            {["NOT LAUNCHED", "RUNNING", "FAILED"].includes(
              stage.profiling_execution.status
            ) && <div className="values">Profiling not available.</div>}
            {!["NOT LAUNCHED", "RUNNING", "FAILED"].includes(
              stage.profiling_execution.status
            ) && (
              <div className="valuesLimited">
                {this.renderCompleteness(column)}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

export default StageMetadataLimitedOverview;
