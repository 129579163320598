/* @flow */
import React from "react";
import "./view.less";
import Modal from "../../../components/modal";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import ButtonAction from "../../../components/buttonAction";

type propTypes = {
  datasource: Object,
  datasourceProvider: Object,
  showGlobalNotification: Function,
  onAction: Function,
  hide: Function,
  api: GraphQl
};

type stateTypes = {
  error: ?string
};

class DatasourceProviderModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  render() {
    const {
      datasource,
      datasourceProvider,
      hide,
      showGlobalNotification,
      onAction
    } = this.props;
    return (
      <Modal
        title={`Delete loader "${datasourceProvider.arn}"`}
        body={"Are you sure ?"}
        errorMessage={this.state.error}
        actions={[
          <button
            key={`dataset-close-${datasource.uri}`}
            type="button"
            className="butn"
            onClick={hide}
          >
            Close
          </button>,
          <ButtonAction
            key={`provider-delete-${datasourceProvider.arn}`}
            label="Delete"
            className="butn butn-delete"
            onClick={() =>
              this.props.api.datasource
                .deleteDatasourceProvider(
                  datasource.uri,
                  datasourceProvider.arn
                )
                .then(() => {
                  showGlobalNotification({
                    message: "Loader deleted",
                    type: "success"
                  });
                })
                .then(onAction)
                .catch((error) => {
                  showGlobalNotification({
                    message: "Loader deletetion failed",
                    type: "alert"
                  });
                  this.setState({ error });
                })
            }
          />
        ]}
      />
    );
  }
}

export default withAppSync(DatasourceProviderModal);
