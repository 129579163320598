/* @flow */
import React from "react";
import { InfiniteLoader, List } from "react-virtualized";
import Logger from "../../../utils/logger";

const Log = Logger("Scroll");

type propstypes = {
  itemComponent: Object,
  list: Array<Object>,
  itemComponentHeight: number,
  noRowsRenderer?: Object,
  loadMore?: Function
};

class Scroll extends React.PureComponent<propstypes> {
  rowRenderer = ({ key, index, style }: Object) => {
    Log.verbose(
      "rowRenderer scroll, index:",
      index,
      this.props.list[index],
      " ,position:",
      style.top
    );
    return this.props.itemComponent(this.props.list[index], key, style);
  };

  isRowLoaded = ({ index }: Object) => {
    Log.verbose("_isRowLoaded scroll for index: ", index);
    return !!this.props.list[index]; // todo and to test
  };

  loadMoreRows = ({ startIndex, stopIndex }: Object) => {
    Log.verbose(
      "_loadMoreRows scroll ; start: ",
      startIndex,
      " , stop: ",
      stopIndex
    );
    if (this.props.loadMore) this.props.loadMore(startIndex, stopIndex); // todo and to test
  };

  render() {
    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={this.props.list.length}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={registerChild}>
            <List
              width={278}
              height={400}
              rowCount={this.props.list.length}
              rowHeight={this.props.itemComponentHeight}
              overscanRowCount={0}
              noRowsRenderer={() =>
                this.props.noRowsRenderer || <div>No rows.</div>
              }
              rowRenderer={this.rowRenderer}
              onRowsRendered={onRowsRendered}
            />
          </div>
        )}
      </InfiniteLoader>
    );
  }
}

export default Scroll;
