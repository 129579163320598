export const resendShareRequest = {
  query: `mutation Q ($datasetUri: String!, $groupUri: String!) {
        resend_share_request(datasetUri: $datasetUri, groupUri: $groupUri) {
          group {
                uri
                name
                account {
                    uri
                    name
                }
            }
            dataset {
                uri
                name
                access
                can_share
                datasource {
                    uri
                    name
                }
            }
            createdat
            updatedat
            request_status
            last_request_date
        }
      }
    `,
  keyOutput: "resendShareRequest"
};
