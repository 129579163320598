/* @flow */

export const updateLogsGroup = {
  query: `mutation Q (
        $cartUri: String!,
        $logsGroupId: String!,
        $input: UpdateLogsGroupInput!
        ) {
            updateLogsGroup(
                cartUri: $cartUri,
                logsGroupId: $logsGroupId,
                input: $input
            ) {
                id
            }
        }
     `,
  keyOutput: "updateLogsGroup"
};
