/* @flow */

import React from "react";
import MultipleValueTextInput from "../../../../components/multipleValueTextInput";
import withAppSync from "../../../AppsyncHOC";
import GraphQl from "../../../../graphQL";

type propTypes = {
  api: GraphQl,
  tags: Array<string>,
  onChangeTags: Function
};

type stateTypes = {
  isFetching: boolean,
  suggestions: Array<string>
};

class TagsField extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      suggestions: []
    };
  }

  getTagsSuggestions = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ isFetching: true }, () => {
        const options = {
          term: value,
          limit: 10
        };
        return this.props.api.dataset
          .searchTags(options)
          .then((suggestions) => {
            this.setState({
              isFetching: false,
              suggestions
            });
          })
          .catch(() => {
            this.setState({
              isFetching: false
            });
          });
      });
    }, 500);
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    return (
      <div className="tags-field">
        <MultipleValueTextInput
          onItemAdded={(e) => {
            this.props.onChangeTags(e);
            if (this.timeout) clearTimeout(this.timeout);
            this.setState({ suggestions: [] });
          }}
          onItemDeleted={this.props.onChangeTags}
          onValueChange={this.getTagsSuggestions}
          suggestions={this.state.suggestions}
          isLoading={this.state.isFetching}
          values={this.props.tags || []}
          label={"Tags *"}
          name={"tags"}
          placeholder={"Add tags, separate them by COMMA or ENTER"}
          classNameInput={"form-control bg-white"}
        />
      </div>
    );
  }
}

export default withAppSync(TagsField);
