/* @flow */

import React from "react";
import { connect } from "react-redux";
import Error from "../../../../components/error";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withGoTo from "../../../goToHOC";
import withAppSync from "../../../AppsyncHOC";
import "./style.less";
import DetachGroupTrustModal from "./detachGroupTrustModal";
import FiltersGroupTrusts from "./filtersTrusts";
import Paginator from "../../../../components/paginator";

type propTypes = {
  api: GraphQl,
  accountUri: string,
  showGlobalNotification: Function
};

type stateTypes = {
  groupTrusts: Array<Object>,
  groupTrustsFilter: Array<Object>,
  error?: Object,
  total: number,
  detachGroupTrustModal: Object | boolean,
  search?: Object
};

class GroupTrustList extends React.Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      detachGroupTrustModal: false,
      groupTrusts: [],
      groupTrustsFilter: [],
      total: 0,
      search: undefined
    };
  }

  componentDidMount() {
    this.getGroupTrusts();
    this.getGroupTrustsFilter();
  }

  getClassStatus = (status) => {
    switch (status) {
      case "accepted":
        return "trust-status-text-accepted";
      case "pending":
        return "trust-status-text-pending";
      default:
        return "trust-status-text-rejected";
    }
  };

  getGroupTrustsFilter(offsetFilter = 0) {
    const search = {
      limit: 10,
      offset: offsetFilter
    };
    return this.props.api.account
      .listGroupTrusts(this.props.accountUri, search)
      .then((groupTrusts) => {
        this.setState({
          total: groupTrusts.total
        });
        this.setState((prevState) => {
          const groupTrustsFilter = [...prevState.groupTrustsFilter];
          groupTrusts.results.trusts.map((trust) =>
            groupTrustsFilter.push(trust)
          );
          return { groupTrustsFilter };
        });
        if (groupTrusts.total > this.state.groupTrustsFilter.length) {
          this.getGroupTrustsFilter(offsetFilter + 10);
        }
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  }

  getGroupTrusts = (search: Object) => {
    if (!search) {
      search = {
        limit: 10,
        offset: 0
      };
    }
    this.setState({ groupTrusts: [], search });
    return this.props.api.account
      .listGroupTrusts(this.props.accountUri, search)
      .then((groupTrusts) => {
        this.setState({
          total: groupTrusts.total,
          groupTrusts: groupTrusts.results.trusts
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getGroupsTrustFromPagination = async (offset) => {
    const search = { ...this.state.search, offset };
    await this.getGroupTrusts(search);
  };

  openDetachGroupTrustModal = (groupTrust: Object) => {
    this.setState({ detachGroupTrustModal: { groupTrust } });
  };

  closeDetachGroupTrustModal = () => {
    this.setState({ detachGroupTrustModal: false });
  };

  onDetachTrustSuccess = () => {
    this.props.showGlobalNotification({
      message: "Trust detach success !",
      type: "success"
    });
    this.closeDetachGroupTrustModal();
    this.getGroupTrusts(this.state.search);
  };

  onDetachTrustFailure = () => {
    this.props.showGlobalNotification({
      message: "Trust detach failed !",
      type: "alert"
    });
    this.closeDetachGroupTrustModal();
    this.getGroupTrusts(this.state.search);
  };

  render() {
    return (
      <div className="list-group-trusts">
        <div className="mt-2">
          {this.state.error && (
            <Error error={this.state.error} path="PolicyManagement.list" />
          )}
          <Paginator
            container={(content) => (
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-env" scope="col">
                      ENVIRONMENT
                    </th>
                    <th className="col-trust" scope="col">
                      TRUST
                    </th>
                    <th className="col-group" scope="col">
                      GROUP
                    </th>
                    <th className="col-projects" scope="col center-column">
                      PROJECTS
                    </th>
                    <th className="col-status" scope="col center-column">
                      STATUS
                    </th>
                    <th
                      className="col-action text-center"
                      scope="col center-column"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <FiltersGroupTrusts
                    getGroupTrusts={this.getGroupTrusts}
                    groupTrusts={this.state.groupTrustsFilter}
                  />
                  {content}
                </tbody>
              </table>
            )}
            limit={10}
            initialOffset={0}
            totalCount={this.state.total}
            list={this.state.groupTrusts}
            componentRender={(groupTrust) => (
              <tr className="table-row">
                <td className="table-env-trust-column">{`${groupTrust.platform.name} (${groupTrust.platform.aws})`}</td>
                <td className="table-group-turst-column">{`${groupTrust.arn} (${groupTrust.type})`}</td>
                <td className="table-group-column">{groupTrust.group.name}</td>
                <td className="table-projects-column">{groupTrust.nb_carts}</td>
                <td className="table-status-column">
                  <span className={this.getClassStatus(groupTrust.status)}>
                    {groupTrust.status}
                  </span>
                </td>
                <td className="table-action-column">
                  <i
                    onClick={() => this.openDetachGroupTrustModal(groupTrust)}
                    className="far fa-trash-alt"
                  ></i>
                </td>
              </tr>
            )}
            loadPage={this.getGroupsTrustFromPagination}
          />
        </div>
        {typeof this.state.detachGroupTrustModal === "object" && (
          <DetachGroupTrustModal
            api={this.props.api}
            groupTrust={this.state.detachGroupTrustModal.groupTrust}
            hide={this.closeDetachGroupTrustModal}
            accountUri={this.props.accountUri}
            onDetachTrustSuccess={this.onDetachTrustSuccess}
            onDetachTrustFailure={this.onDetachTrustFailure}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(GroupTrustList)));
