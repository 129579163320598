/* @flow */
import React from "react";
import "./style.less";

type propTypes = {
  checkedValue: boolean,
  onChange: Function,
  id: string,
  disabled?: boolean
};

const ToggleButton = ({ id, checkedValue, onChange, disabled }: propTypes) => (
  <label className="switch" htmlFor={id}>
    <input
      id={id}
      type="checkbox"
      className={"checkbox-facet"}
      checked={checkedValue}
      onChange={onChange}
      disabled={disabled || false}
    />
    <span className="slider round" />
  </label>
);

export default ToggleButton;
