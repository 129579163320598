/* @flow */

import React, { PureComponent } from "react";
import { withAuth } from "@okta/okta-react";
import CartIntegrationsPowerBI from "./CartIntegrationsPowerBI";
import CartIntegrationRedshiftPowerBI from "./CartIntegrationsRedshiftPowerBI";
import "./style.less";

type propTypes = {
  cart: Object,
  warningMessage: string,
  isDisabled: boolean,
  reloadCart: Function
};

type stateTypes = {};

class CartVisualization extends PureComponent<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {};
  }

  render() {
    const { warningMessage, isDisabled } = this.props;
    const disableClass = isDisabled ? " btn-disable" : "";

    return (
      <div>
        <div className={"cart-visualizations"}>
          {isDisabled && warningMessage}
        </div>
        <div className={`cart-visualizations ${disableClass}`}>
          <CartIntegrationsPowerBI
            cart={this.props.cart}
            reloadCart={this.props.reloadCart}
          />
          {this.props.cart.cartRedshiftClusters.map((redshiftCluster) => (
            <CartIntegrationRedshiftPowerBI
              cart={this.props.cart}
              redshiftCluster={redshiftCluster}
              reloadCart={this.props.reloadCart}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withAuth(CartVisualization);
