import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Col from "../../components/col";
import Row from "../../components/row";
import withAppSync from "../AppsyncHOC";
import Routes from "../routes";
import withGoTo from "../goToHOC";
import Error from "../../components/error";
import Logger from "../../utils/logger";
import { updateMessage } from "../globalNotifications/actions";

const Log = Logger("UserCreate");

const validationGid = (gid) => {
  if (!gid || gid.trim().length < 1) {
    return {
      isValid: false,
      message: "Can't be empty."
    };
  }

  const isValid =
    gid.match(/^[A-Z]{2}\d{4}$/i) != null ||
    gid.match(/^[A-Z]{3}\d{3}$/i) != null || // belgium okta
    gid.match(/^[A-Z]{4}\d{2}$/i) != null; // technical okta

  return {
    isValid,
    message:
      "Invalid format. Group Id should be 2 capital letters and 4 digits or 3 capital letters and 3 digits."
  };
};

const validationEmail = (email) => {
  if (!email || email.trim().length < 1) {
    return {
      isValid: false,
      message: "Can't be empty."
    };
  }

  const isValid = email.match(/^([\w.'%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return {
    isValid,
    message: "Invalid format."
  };
};

const validationName = (name) => {
  if (!name || name.trim().length < 1) {
    return {
      isValid: false,
      message: "Can't be empty."
    };
  }

  return {
    isValid: true
  };
};

class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isFetching: false,
      oktaUser: {
        email: "",
        first_name: "",
        last_name: ""
      },
      gaia: "",
      userGaiaNotFound: false,
      isCreating: false
    };
    this.create = this.create.bind(this);
    this.handleGaia = this.handleGaia.bind(this);
    this.getOktaUser = this.getOktaUser.bind(this);
  }

  getOktaUser(gaia) {
    this.setState({ isFetching: true });
    this.props.api.identity
      .getOktaUser(gaia)
      .then((oktaUser) => {
        if (!oktaUser || !oktaUser.email) {
          return this.setState({
            isFetching: false,
            oktaUser: {
              email: "",
              first_name: "",
              last_name: ""
            },
            gaia,
            userGaiaNotFound: true
          });
        }

        return this.setState({
          isFetching: false,
          oktaUser,
          gaia,
          userGaiaNotFound: false
        });
      })
      .catch(() => {
        this.setState({
          isFetching: false,
          oktaUser: {
            email: "",
            first_name: "",
            last_name: ""
          },
          gaia,
          userGaiaNotFound: true
        });
      });
  }

  handleGaia(event) {
    const { value } = event.target;
    const gidValidation = validationGid(value);
    if (gidValidation.isValid) {
      this.getOktaUser(value);
    }
  }

  create(values, setSubmitting) {
    this.setState({ isCreating: true });
    const input = Object.assign({}, values);
    input.resource_name = values.gid;
    return this.props.api.identity
      .create(this.props.match.params.uriAccount, input)
      .then(() => setSubmitting(false))
      .then(() => {
        this.props.showGlobalNotification({
          message: "New user created",
          type: "success"
        });
        this.setState({ isCreating: false });
        this.props.goTo({
          route: Routes.Account.ViewUsers,
          params: {
            uriAccount: this.props.match.params.uriAccount
          },
          state: { dataset: values }
        });
      })
      .catch((errorSubmit) => {
        setSubmitting(false);
        Log.error(errorSubmit);
        if (errorSubmit.message.includes("Resource Already Exists")) {
          if (
            errorSubmit.message.includes(
              "exists with same value for attribute `email`:"
            )
          ) {
            this.setState({
              errorSubmit: "This email is already used for a user."
            });
          } else {
            this.setState({
              errorSubmit: "This user is already part of the organization"
            });
          }
        } else {
          this.setState({ errorSubmit });
        }
        this.props.showGlobalNotification({
          message: "Invitation failed",
          type: "alert"
        });
        this.setState({
          isCreating: false
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <Row className={"my-4"} style={{ color: "var(--blue-venice)" }}>
          <Col size={1}>
            <a onClick={this.props.goBack} style={{ cursor: "pointer" }}>
              <i
                style={{ verticalAlign: "middle" }}
                className={"mx-0 fas fa-chevron-left fa-2x"}
              />
            </a>
          </Col>
          <Col size={10}>
            <h4 className={"mx-0"}>Invite a new user</h4>
          </Col>
        </Row>

        <Row>
          <Col size={1} />
          <Col className={"my-3"} size={10}>
            <Formik
              initialValues={{
                name: `${this.state.oktaUser.last_name} ${this.state.oktaUser.first_name}`,
                email: this.state.oktaUser.email,
                gid: this.state.gaia
              }}
              enableReinitialize
              validate={(values) => {
                Log.info("validate", values);
                const errors = {};

                const nameValidation = validationName(values.name);
                if (!nameValidation.isValid)
                  errors.name = nameValidation.message;

                const gidValidation = validationGid(values.gid);
                if (!gidValidation.isValid) errors.gid = gidValidation.message;

                const emailValidation = validationEmail(values.email);
                if (!emailValidation.isValid)
                  errors.email = emailValidation.message;

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                Log.info("submit !", values);
                this.create(Object.assign({}, values), setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      title={"Error"}
                      error={this.state.errorSubmit}
                      stringOnly
                      displayError
                      path={"UserCreate"}
                    />
                  )}

                  <fieldset className="form-group">
                    <label>Group ID (GAIA)</label>
                    <Field
                      name="gid"
                      type={"gid"}
                      render={({ field }) => (
                        <input
                          {...field}
                          placeholder="AA1234 or AAA1234"
                          onChange={(event) => {
                            this.handleGaia(event);
                            field.onChange(event);
                          }}
                          className="form-control bg-white"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="gid"
                      render={(msg) => (
                        <div className={"text-danger"}>{msg}</div>
                      )}
                    />
                    {this.state.isFetching && (
                      <i className="fas fa-circle-notch fa-spin" />
                    )}
                    {this.state.userGaiaNotFound && (
                      <div className={"text-danger"}>Gaia not found</div>
                    )}
                  </fieldset>

                  <fieldset className="form-group">
                    <Field
                      name="name"
                      render={({ name }) => (
                        <input
                          value={
                            this.state.oktaUser.last_name
                              ? `${this.state.oktaUser.last_name} ${this.state.oktaUser.first_name}`
                              : ""
                          }
                          name={name}
                          type="name"
                          placeholder="name from okta"
                          className="form-control"
                          readOnly
                        />
                      )}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                    <Field
                      name="email"
                      render={({ name }) => (
                        <input
                          placeholder="email from okta"
                          value={this.state.oktaUser.email}
                          name={name}
                          type="email"
                          className="form-control"
                          readOnly
                        />
                      )}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                    <label>Enter a description (optional)</label>
                    <Field
                      component="textarea"
                      name="description"
                      className="form-control bg-white"
                      placeholder=""
                    />
                    <ErrorMessage name="description" component="div" />
                  </fieldset>

                  <div className={"row justify-content-center"}>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="butn"
                    >
                      {this.state.isCreating && (
                        <i className="fas fa-circle-notch fa-spin fa-spacing" />
                      )}
                      Invite User
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

UserCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uriAccount: PropTypes.string.isRequired
    })
  }),
  api: PropTypes.shape({
    identity: PropTypes.shape({
      create: PropTypes.func,
      getOktaUser: PropTypes.func
    })
  }),
  goTo: PropTypes.func,
  goBack: PropTypes.func,
  showGlobalNotification: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(UserCreate)));
