export const getPlayground = {
  query: `
  query Q ($accountUri: String!, $platformUri: String!) {
    getPlatform(accountUri: $accountUri, platformUri: $platformUri) {
      uri
      name
      environment
      default_region
      description
      tags
      aws
      auto_refresh_stages
      creator {
        uri
        name
      }
      engieEntity {
        aws_account
        org_id
        scope_id
        scope
        sub_scope
        gbus
        name
      }
    }
    getAccount (accountUri: $accountUri) {
        name
        uri
    }
  }
`,
  keyOutput: { playground: "getPlatform", account: "getAccount" }
};
