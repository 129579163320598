/* @flow */
import React from "react";
import { connect } from "react-redux";
import ButtonAction from "../../../../components/buttonAction";
import Modal from "../../../../components/modal";
import GraphQl from "../../../../graphQL";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import Logger from "../../../../utils/logger";
import TagsDatasourceFields from "../../../datasources/components/form/TagsDatasourceFields";

const Log = Logger("DeployAccelerationTemplateModal");

type propTypes = {
  api: GraphQl,
  onDeployment: Function,
  onClose: Function,
  showGlobalNotification: Function,
  cart: Object,
  accelerationTemplate: Object
};

type stateTypes = {
  error?: Object,
  package_name: string,
  github_token: string,
  github_url: string,
  isDeploying: boolean,
  tags: Array<Object>
};

class DeployAccelerationTemplateModal extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      isDeploying: false,
      package_name: "",
      github_token: "",
      github_url: "",
      tags: []
    };
  }

  deploy = () => {
    this.setState({ error: undefined, isDeploying: true });
    this.props.api.cart
      .createAccelerationTemplate(this.props.cart.uri, {
        access_to_template_id: this.props.accelerationTemplate.id,
        package_name: this.state.package_name,
        github_token: this.state.github_token,
        github_url: this.state.github_url,
        tags: this.state.tags
      })
      .then(() => {
        this.props.showGlobalNotification({
          message: "deployment started !",
          type: "success"
        });
        this.setState({
          isDeploying: false
        });
        this.props.onDeployment();
      })
      .catch((error) => {
        Log.error(error);
        this.props.showGlobalNotification({
          message: "An error occurred",
          type: "alert"
        });
        this.setState({ error, isDeploying: false });
      });
  };

  handleChange(field, value) {
    this.setState({ tags: value });
  }

  render() {
    return (
      <Modal
        title={`Deploy the template: ${this.props.accelerationTemplate.acceleration_template.name}`}
        body={
          <div>
            <div
              style={{
                marginBottom: "10px"
              }}
            >
              <div className={"alert alert-light"}>
                <i className="fas fa-exclamation-circle" />
                &nbsp;Requirements:&nbsp;
                <div>
                  <div>The project role has to be up to date.</div>
                </div>
              </div>

              <div>Before the deployment, we need some parameters.</div>
            </div>

            <div className="form-group">
              <legend className="label-form">Package name</legend>
              <input
                className="form-control"
                placeholder="Package name"
                value={this.state.package_name}
                onChange={(event) =>
                  this.setState({ package_name: event.target.value })
                }
              />
            </div>

            <div className="form-group">
              <legend className="label-form">Github token</legend>
              <input
                className="form-control"
                placeholder="Github token"
                value={this.state.github_token}
                onChange={(event) =>
                  this.setState({ github_token: event.target.value })
                }
              />
            </div>

            <div className="form-group">
              <legend className="label-form">Github url</legend>
              <input
                className="form-control"
                placeholder="Github url"
                value={this.state.github_url}
                onChange={(event) =>
                  this.setState({ github_url: event.target.value })
                }
              />
            </div>

            <div className="form-group">
              <legend className="label-form">Tags</legend>
              <TagsDatasourceFields
                tags={null}
                onChangeTags={(field, value) => this.handleChange(field, value)}
              />
            </div>
          </div>
        }
        errorMessage={this.state.error}
        actions={[
          <ButtonAction
            key="deploy-template"
            label="Start deployment"
            className="butn butn-flat"
            onClick={this.deploy}
            icon={
              this.state.isDeploying ? "fas fa-circle-notch fa-spin" : undefined
            }
          />,
          <button
            key="close-modal"
            type="button"
            className="butn"
            onClick={this.props.onClose}
          >
            Close
          </button>
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(DeployAccelerationTemplateModal));
