/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from "react";
import "./view.less";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import DatasourceLogItem from "./DatasourceLogItem";
import GraphQl from "../../../graphQL";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import ToggleButton from "../../../components/toggleButton";
import Paginator from "../../../components/paginator";
import CartLogItem from "../../carts/view/CartLogItem";

type propTypes = {
  datasource: {
    uri: string
  },
  api: GraphQl
};

type stateTypes = {
  error: Object,
  logs: Array<Object>,
  ready: boolean,
  offset: number,
  offsetNew: number,
  totalNew: number,
  total: number,
  newLogs: Array<Object>,
  newDatasourceLogs: boolean
};

class DatasourceLogs extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined,
      ready: false,
      offset: 0,
      offsetNew: 0,
      totalNew: 0,
      total: 0,
      newDatasourceLogs: false,
      logs: [],
      newLogs: []
    };
  }

  componentDidMount() {
    this.listAllLogs();
    this.autoRefresh();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  listAllLogs() {
    this.listLogs();
    this.listNewLogs();
  }

  autoRefresh = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.listAllLogs();
      }, 5000);
    }
  };

  listLogs = () => {
    const queryOptions = {
      order_by: { value: "desc", key: "createdat" },
      limit: 10,
      offset: this.state.offset
    };

    return this.props.api.datasource
      .listDatasourceLogs(this.props.datasource.uri, queryOptions)
      .then((logs) => {
        this.setState({
          logs: logs.results,
          total: logs.total,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      });
  };

  listNewLogs = () => {
    const queryOptions = {
      order_by: { value: "desc", key: "createdat" },
      limit: 10,
      offset: this.state.offsetNew
    };
    return this.props.api.datasource
      .listNewDatasourceLogs(this.props.datasource.uri, queryOptions)
      .then((logs) => {
        this.setState({
          newLogs: logs.results,
          totalNew: logs.total,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      });
  };

  getLogsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offset }, () => this.listLogs().then(r))
    );

  getNewLogsFromPagination = (offsetNew) =>
    new Promise((r) =>
      this.setState({ offsetNew }, () => this.listNewLogs().then(r))
    );

  render() {
    return (
      <div>
        {this.state.error && (
          <Error error={this.state.error} path={"DatasourceLogs"} />
        )}
        {!this.state.ready && <Loading message={"logs"} />}
        {this.state.ready && (
          <React.Fragment>
            <div className="container-toggle-buttons">
              <span className="title-toggle">AWS Resources</span>
              <ToggleButton
                id="toggle-new-datasource-log"
                checkedValue={this.state.newDatasourceLogs}
                onChange={() => {
                  this.setState((prevState) => {
                    const newDatasourceLogs = !prevState.newDatasourceLogs;
                    return { newDatasourceLogs };
                  });
                }}
              />
              <span className="title-toggle">User operations</span>
            </div>
            {!this.state.newDatasourceLogs ? (
              <div className="cart-logs-container">
                <Paginator
                  limit={10}
                  initialOffset={0}
                  list={this.state.logs}
                  totalCount={this.state.total}
                  componentRender={(logCart) => (
                    <DatasourceLogItem key={logCart.actionid} log={logCart} />
                  )}
                  loadPage={this.getLogsFromPagination}
                  onNoResult={() => (
                    <p className="total-search-results">No logs found</p>
                  )}
                />
              </div>
            ) : (
              <div className="cart-logs-container">
                <Paginator
                  limit={10}
                  initialOffset={0}
                  list={this.state.newLogs}
                  totalCount={this.state.totalNew}
                  componentRender={(logCart) => (
                    <CartLogItem key={logCart.actionid} log={logCart} />
                  )}
                  loadPage={this.getNewLogsFromPagination}
                  onNoResult={() => (
                    <p className="total-search-results">No logs found</p>
                  )}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withAppSync(withGoTo(DatasourceLogs));
