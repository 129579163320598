/* @flow */

import React from "react";
import { connect } from "react-redux";
import ItemFromList from "../../../../components/itemFromList";
import Links from "../../../links";
import { formatDate, diffDate } from "../../../../utils/date";
import { updateMessage } from "../../../globalNotifications/actions";
import withAppSync from "../../../AppsyncHOC";
import ButtonAction from "../../../../components/buttonAction";
import Error from "../../../../components/error";
import MoreDetailsNotification from "../MoreDetailsNotification";

type propTypes = {
  pendingShareRequest: {
    id: string,
    group: {
      name: string,
      uri: string,
      account: {
        name: string
      }
    },
    dataset: {
      access: string,
      name: string,
      uri: string
    },
    createdat: string,
    last_request_date: string,
    message: string
  },
  api: Object,
  onAction: Function,
  showGlobalNotification: Function
};

type stateTypes = {
  error: Object
};

class DatasetShareRequestPending extends React.Component<
  propTypes,
  stateTypes
> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  getTitle() {
    return (
      <div className="share-title">
        {this.props.pendingShareRequest &&
          this.props.pendingShareRequest.id && (
            <div className="share-title mb-2">
              <Links.Share.View
                shareId={(this.props.pendingShareRequest || {}).id || "null"}
              >
                {`${this.props.pendingShareRequest.group.name} share request for dataset ${this.props.pendingShareRequest.dataset.name}`}
              </Links.Share.View>
            </div>
          )}
        {"You requested a share for the dataset "}
        <Links.Dataset.View
          uriDataset={this.props.pendingShareRequest.dataset.uri}
        >
          {this.props.pendingShareRequest.dataset.name}
        </Links.Dataset.View>
      </div>
    );
  }

  getDescription() {
    return (
      <React.Fragment>
        <div className="share-description">
          <span style={{ color: "orange", fontWeight: "600" }}>
            {"Pending "}
          </span>
          {", for the group "}
          <Links.Group.View
            uriGroup={this.props.pendingShareRequest.group.uri}
            state={{ group: this.props.pendingShareRequest.group }}
          >
            {this.props.pendingShareRequest.group.name}
          </Links.Group.View>
          {`, from ${this.props.pendingShareRequest.group.account.name}`}
          {`, the  ${formatDate(
            this.props.pendingShareRequest.createdat,
            false
          )}`}
        </div>
        <MoreDetailsNotification share={this.props.pendingShareRequest} />
      </React.Fragment>
    );
  }

  resendNotification = () => {
    const { pendingShareRequest, api, onAction } = this.props;

    return api.share
      .resendShareRequest(
        pendingShareRequest.dataset.uri,
        pendingShareRequest.group.uri
      )
      .then(() => {
        onAction();
        this.props.showGlobalNotification({
          message:
            "A new notification was send to the owner, you can resend notification all the 24 hours",
          type: "success"
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
        this.props.showGlobalNotification({
          message: "Failed to resend request",
          type: "alert"
        });
      });
  };

  getActions = () => {
    const { pendingShareRequest } = this.props;
    const dateNow = new Date();

    if (
      diffDate(pendingShareRequest.last_request_date, dateNow.toString()) >= 24
    ) {
      return [
        <ButtonAction
          label="Send a reminder"
          className="butn butn-flat"
          icon="fa-bell"
          onClick={() => this.resendNotification()}
        />
      ];
    }
    return [];
  };

  render() {
    return (
      <div>
        <div>
          {this.state.error && (
            <Error
              error={this.state.error}
              path={"DatasetShareRequestPending"}
            />
          )}
        </div>
        <ItemFromList
          className={"a-share"}
          name={this.getTitle()}
          actions={this.getActions()}
          description={this.getDescription()}
          hideLabelName
          hideLabelDescription
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(
  connect(null, mapDispatchToProps)(DatasetShareRequestPending)
);
