import React from "react";
import { VictoryChart, VictoryBar, VictoryLine } from "victory";
import PropTypes from "prop-types";

const GraphComponent = ({ data = [] }) => (
  <VictoryChart
    height={400}
    width={600}
    domainPadding={{ x: 50, y: [0, 20] }}
    scale={{ x: "time" }}
  >
    <VictoryBar
      style={{
        data: { fill: "var(--blue-engie)" }
      }}
      labels={(datum) => datum.y}
      barWidth={10}
      data={data.map((item) => {
        const date = new Date(item.createdat);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const x = `${day}-${monthIndex + 1}`;
        return {
          x,
          y: item.count
        };
      })}
    />
    <VictoryLine
      style={{
        data: {
          stroke: "var(--blue-engie)",
          strokeWidth: 5,
          strokeLinecap: "round"
        },
        parent: { border: "1px solid #ccc" }
      }}
      data={data.map((item) => {
        const date = new Date(item.createdat);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const x = `${day}-${monthIndex + 1}`;
        return {
          x,
          y: item.count
        };
      })}
    />
  </VictoryChart>
);

GraphComponent.propTypes = {
  data: PropTypes.array
};

export default GraphComponent;
