import React from "react";
import {
  isAccountGroup,
  isAccountAdminGroup,
  isPlaygroundAdminGroup,
  isDssInstanceAdminGroup
} from "../../utils/groupsfilter";

import "./dbxGroupLabels.less";

const AdminAccountLabel = () => (
  <div className="badge badge-pill badge-warning tooltiped">
    <span>Admin</span>
  </div>
);

const UserAccountLabel = () => (
  <div className="badge badge-pill badge-primary tooltiped">
    <span>User</span>
  </div>
);

const GroupLabels = ({ group }) => (
  <div className="grouplabels">
    {group.is_account_admin ? <AdminAccountLabel /> : <UserAccountLabel />}
  </div>
);

export default GroupLabels;
