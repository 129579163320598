export const queryGetDatabases = {
  query: `query Q ($platformUri:String!){
        getSqlLabDatabases(platformUri:$platformUri) {
            database
            platformUri
            targetType
            targetUri
            aws
            region
        }
      }
    `,
  keyOutput: "getSqlLabDatabases"
};
