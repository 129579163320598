/* eslint-disable flowtype/no-types-missing-file-annotation */
import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import withAppsync from "../../../../AppsyncHOC";
import Logger from "../../../../../utils/logger";
import GraphQl from "../../../../../graphQL";
import Row from "../../../../../components/row";
import Col from "../../../../../components/col";
import Error from "../../../../../components/error";
import Routes from "../../../../routes";
import { updateMessage } from "../../../../globalNotifications/actions";
import Breadcrumb from "../../../../../components/breadcrumb";
import TagsDatasourceFields from "../../../../datasources/components/form/TagsDatasourceFields";
import SelectClassic from "../../../../../components/SelectClassic";

const Log = Logger("CreateDatabrewProject");

type propTypes = {
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  location: {
    state: {
      databrew: Object
    }
  },
  match: {
    params: {
      cartUri: string,
      databrewId: string
    }
  }
};

type stateTypes = {
  cart: Object,
  platforms: Object,
  error: boolean,
  isSubmitting: boolean,
  outputs: Array<Object>,
  sampleType: Array<string>,
  errorSubmit: Object
};

class EditDatabrewProject extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      databrew: props.location.state.databrew,
      error: false,
      isSubmitting: false,
      errorSubmit: false,
      sampleType: ["FIRST_N", "LAST_N", "RANDOM"]
    };
  }

  handleChange(field, value) {
    return this.setState((prevState) => {
      const databrew = Object.assign({}, prevState.databrew);
      databrew[field] = value;
      if (field === "sample_size") {
        databrew[field] = parseInt(value, 10);
      }
      return { databrew };
    });
  }

  save() {
    const { databrew } = this.state;
    const databrewUpdate = {
      sample_type: databrew.sample_type,
      sample_size: databrew.sample_size
    };

    this.setState({ errorSubmit: false, isSubmitting: true });

    return this.props.api.cart
      .editDatabrewProject(databrew.cart.uri, databrew.id, databrewUpdate)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showGlobalNotification({
          message: "Databrew Project edited",
          type: "success"
        });
        return this.props.goTo({
          route: `${Routes.Cart.View}#tools-preparation`,
          params: { cartUri: databrew.cart.uri }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isSubmitting: false });
        this.setState({ errorSubmit });
        this.props.showGlobalNotification({
          message: "Databrew Project edition failed",
          type: "alert"
        });
      });
  }

  render() {
    if (this.state.error)
      return <Error error={this.state.error} path={"CreateDatabrew"} />;

    const { databrew } = this.state;

    return (
      <div className="create-databrew">
        <Breadcrumb view={"Create a new Databrew Project"} />
        <Row>
          <Col size={1} />
          <Col className={"my-4"} size={9}>
            <Formik
              initialValues={{
                name: databrew.name,
                dataset_uri: databrew.dataset_uri,
                dataset_output: databrew.dataset_output,
                sample_type: databrew.sample_type,
                sample_size: databrew.sample_size,
                platform_uri: databrew.platform_uri,
                tags: databrew.tags
              }}
              validate={() => {
                const errors = {};

                if (!databrew.sample_type) {
                  errors.sample_type = "Sample type is mandatory";
                }

                Log.info("validate", databrew, errors);
                return errors;
              }}
              onSubmit={() => {
                this.save();
              }}
            >
              {({ errors }) => (
                <Form>
                  {this.state.errorSubmit && (
                    <Error
                      stringOnly
                      error={this.state.errorSubmit}
                      path={"CreateDatabrew"}
                    />
                  )}
                  <div className="form-container">
                    <fieldset className="form-group">
                      <legend className="label-form">Name:</legend>
                      <Field
                        type="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        onChange={(event) =>
                          this.handleChange("name", event.target.value)
                        }
                        value={this.state.databrew.name}
                        disabled
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Dataset:</legend>
                      <SelectClassic
                        placeholder={this.state.databrew.dataset.name}
                        isDisabled
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Stage:</legend>
                      <SelectClassic
                        placeholder={this.state.databrew.dataset_output.name}
                        isDisabled
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Sampling Type:</legend>
                      <SelectClassic
                        placeholder={"Select a sampling type"}
                        options={this.state.sampleType.map((sampleType) => ({
                          value: sampleType,
                          label: sampleType
                        }))}
                        selectedOption={
                          databrew.sample_type
                            ? {
                                value: databrew.sample_type,
                                label: databrew.sample_type
                              }
                            : false
                        }
                        onSelectOption={(option) =>
                          this.handleChange("sample_type", option.value)
                        }
                      />
                      <ErrorMessage
                        name="sample_type"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Sampling Size:</legend>
                      <input
                        id="sample_size"
                        name="sample_size"
                        className="form-control bg-white"
                        value={databrew.sample_size}
                        placeholder={"Select a sampling size"}
                        type="number"
                        min="1"
                        max="5000"
                        onChange={(event) =>
                          this.handleChange("sample_size", event.target.value)
                        }
                      ></input>
                      <small className="attribute-label">
                        Minimum value of 1. Maximum value of 5000 rows.
                      </small>
                      <ErrorMessage
                        name="sample_size"
                        component="div"
                        className="error-msg"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend className="label-form">Environment:</legend>
                      <SelectClassic
                        placeholder={this.state.databrew.platform.name}
                        isDisabled
                      />
                    </fieldset>

                    <fieldset className="form-group mb-3">
                      <legend className="label-form">Tags</legend>
                      <TagsDatasourceFields
                        isDisabled
                        tags={databrew.tags}
                        onChangeTags={(field, value) =>
                          this.handleChange(field, value)
                        }
                        customField="tags"
                      />
                      {errors.s3_custom_tags && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags}
                        </div>
                      )}
                      {errors.s3_custom_tags_key_unicode && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags_key_unicode}
                        </div>
                      )}
                      {errors.s3_custom_tags_value_unicode && (
                        <div style={{ color: "red" }}>
                          {errors.s3_custom_tags_value_unicode}
                        </div>
                      )}
                    </fieldset>

                    <div className={"ml-1 row justify-content-center"}>
                      <button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        className="butn"
                      >
                        {this.state.isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin fa-spacing" />
                        )}
                        Edit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(EditDatabrewProject));
