/* @flow */
import {
  createRedshiftCluster,
  type NewRedshiftClusterInput
} from "./mutationCreateRedshiftCluster";
import { getEnvironmentRedshiftClusters } from "./queryGetEnvironmentRedshiftClusters";
import { getRedshiftCluster } from "./queryGetRedshiftCluster";
import { getCartRedshiftCluster } from "./queryGetCartRedshiftCluster";
import { getRedshiftClusterCredentials } from "./queryGetRedshiftClusterCredentials";
import { deleteRedshiftCluster } from "./mutationDeleteRedshiftCluster";
import { detachRedshiftCluster } from "./mutationDetachRedshiftCluster";
import { addRedshiftClusterToProject } from "./mutationAddRedshiftClusterToProject";
import { removeRedshiftClusterFromProject } from "./mutationRemoveRedshiftClusterFromProject";
import { getProjectPlatformsRedshiftClusters } from "./queryGetProjectPlatformsRedshiftClusters";
import { getProjectRedshiftClusters } from "./queryGetProjectRedshiftClusters";
import { getRedshiftClusterUsage } from "./queryGetRedshiftClusterUsage";
import { getOBDCConnection } from "./queryGetODBCInfos";
import { grantRedshiftClusterAccess } from "./mutationGrantRedshiftClusterAccess";
import { revokeRedshiftClusterAccess } from "./mutationRevokeRedshiftClusterAccess";
import { getRedshiftClusterGroups } from "./queryGetRedshiftClusterGroups";
import { getRedshiftClusterProjectDetails } from "./queryGetRedshiftClusterProjectDetails";
import { getRedshiftClusterPermanentCredentials } from "./queryGetRedshiftClusterPermanentCredentials";
import { listRedshiftClustersFromAws } from "./queryListRedshiftClustersFromAws";
import {
  importRedshiftClusters,
  type ImportRedshiftClustersInput
} from "./mutationImportRedshiftClusters";
import {
  type CartRedshiftClusterTypePropsTypes,
  type EnvironmentRedshiftClustersTypePropsType,
  type ODBCConnectionTypePropsType,
  type RedshiftClusterCredentialsTypePropsTypes,
  type RedshiftClusterProjectDetailsTypePropsType,
  type RedshiftClusterTypePropsTypes,
  type RedshiftClusterUsageTypePropsType
} from "./RedshiftClusterTypes";
import { deleteRSNamespace } from "./mutationDeleteRSNamespace";
import { revokeRSNamespaceAccess } from "./mutationRevokeRSNamespaceAccess";
import { grantRSNamespaceAccess } from "./mutationGrantRSNamespaceAccess";
import { getRSNamespaceGroups } from "./queryGetRSNamespaceGroups";
import type { QueryOptions } from "../propstypes";
import { getRSNamespaceDatasources } from "./queryGetRSNamespaceDatasources";
import { getRSNamespace } from "./queryGetRSNamespace";
import { getEnvironmentRSNamespaces } from "./queryGetEnvironmentRSNamespaces";
import { createRSNamespace } from "./mutationCreateRSNamespace";
import { updateRSNamespace } from "./mutationUpdateRSNamespace";
import { getMyEnvironmentRSNamespaces } from "./queryGetMyEnvironmentRSNamespaces";

class AnalyticsApi {
  fetchAppSync: Function;

  constructor(fetchAppSync: Function) {
    this.fetchAppSync = fetchAppSync;
  }

  createRedshiftCluster(
    platformUri: string,
    newRedshiftClusterInput: NewRedshiftClusterInput
  ): Promise<RedshiftClusterTypePropsTypes> {
    return this.fetchAppSync(createRedshiftCluster, {
      platformUri,
      newRedshiftClusterInput
    });
  }

  getEnvironmentRedshiftClusters(
    accountUri: string,
    platformUri: string
  ): Promise<EnvironmentRedshiftClustersTypePropsType> {
    return this.fetchAppSync(getEnvironmentRedshiftClusters, {
      accountUri,
      platformUri
    });
  }

  getRedshiftCluster(
    clusterUri: string,
    cartUri: string
  ): Promise<RedshiftClusterTypePropsTypes> {
    return this.fetchAppSync(getRedshiftCluster, { clusterUri, cartUri });
  }

  getOBDCConnection(
    cartUri: string,
    clusterUri: string
  ): Promise<ODBCConnectionTypePropsType> {
    return this.fetchAppSync(getOBDCConnection, { cartUri, clusterUri });
  }

  getCartRedshiftCluster(
    clusterUri: string,
    cartUri: string
  ): Promise<CartRedshiftClusterTypePropsTypes> {
    return this.fetchAppSync(getCartRedshiftCluster, { clusterUri, cartUri });
  }

  getRedshiftClusterCredentials(
    clusterUri: string,
    cartUri: string
  ): Promise<RedshiftClusterCredentialsTypePropsTypes> {
    return this.fetchAppSync(getRedshiftClusterCredentials, {
      clusterUri,
      cartUri
    });
  }

  getRedshiftClusterPermanentCredentials(
    clusterUri: string,
    cartUri: string
  ): Promise<RedshiftClusterCredentialsTypePropsTypes> {
    return this.fetchAppSync(getRedshiftClusterPermanentCredentials, {
      clusterUri,
      cartUri
    });
  }

  deleteRedshiftCluster(
    clusterUri: string,
    deleteOnAws: boolean
  ): Promise<boolean> {
    return this.fetchAppSync(deleteRedshiftCluster, {
      clusterUri,
      deleteOnAws
    });
  }

  detachRedshiftCluster(clusterUri: string): Promise<boolean> {
    return this.fetchAppSync(detachRedshiftCluster, { clusterUri });
  }

  addRedshiftClusterToProject(
    cartUri: string,
    clusterUri: string,
    iamRole: string
  ): Promise<boolean> {
    return this.fetchAppSync(addRedshiftClusterToProject, {
      cartUri,
      clusterUri,
      iamRole
    });
  }

  removeRedshiftClusterFromProject(
    cartUri: string,
    clusterUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(removeRedshiftClusterFromProject, {
      cartUri,
      clusterUri
    });
  }

  getProjectPlatformsRedshiftClusters(
    cartUri: string
  ): Promise<Array<RedshiftClusterTypePropsTypes>> {
    return this.fetchAppSync(getProjectPlatformsRedshiftClusters, { cartUri });
  }

  getProjectRedshiftClusters(
    cartUri: string
  ): Promise<Array<RedshiftClusterTypePropsTypes>> {
    return this.fetchAppSync(getProjectRedshiftClusters, { cartUri });
  }

  getRedshiftClusterProjectDetails(
    cartUri: string
  ): Promise<RedshiftClusterProjectDetailsTypePropsType> {
    return this.fetchAppSync(getRedshiftClusterProjectDetails, { cartUri });
  }

  getRedshiftClusterUsage(
    clusterUri: string
  ): Promise<Array<RedshiftClusterUsageTypePropsType>> {
    return this.fetchAppSync(getRedshiftClusterUsage, { clusterUri });
  }

  grantRedshiftClusterAccess(
    groupUri: string,
    clusterUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(grantRedshiftClusterAccess, {
      groupUri,
      clusterUri
    });
  }

  getRedshiftClusterGroups(clusterUri: string) {
    return this.fetchAppSync(getRedshiftClusterGroups, { clusterUri });
  }

  revokeRedshiftClusterAccess(
    groupUri: string,
    clusterUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(revokeRedshiftClusterAccess, {
      groupUri,
      clusterUri
    });
  }

  listRedshiftClustersFromAws(
    platformUri: string,
    region: string
  ): Promise<Array<RedshiftClusterTypePropsTypes>> {
    return this.fetchAppSync(listRedshiftClustersFromAws, {
      platformUri,
      region
    });
  }

  importRedshiftClusters(
    platformUri: string,
    importRedshiftClustersInput: ImportRedshiftClustersInput
  ): Promise<Array<RedshiftClusterTypePropsTypes>> {
    return this.fetchAppSync(importRedshiftClusters, {
      platformUri,
      importRedshiftClustersInput
    });
  }

  createRSNamespace(platformUri: string, input: Object) {
    return this.fetchAppSync(createRSNamespace, { platformUri, input });
  }

  updateRSNamespace(namespaceUri: string, input: Object) {
    return this.fetchAppSync(updateRSNamespace, { namespaceUri, input });
  }

  grantRSNamespaceAccess(
    groupUri: string,
    namespaceUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(grantRSNamespaceAccess, {
      groupUri,
      namespaceUri
    });
  }

  revokeRSNamespaceAccess(
    groupUri: string,
    namespaceUri: string
  ): Promise<boolean> {
    return this.fetchAppSync(revokeRSNamespaceAccess, {
      groupUri,
      namespaceUri
    });
  }

  deleteRSNamespace(
    namespaceUri: string,
    deleteFromAws: boolean
  ): Promise<boolean> {
    return this.fetchAppSync(deleteRSNamespace, {
      namespaceUri,
      deleteFromAws
    });
  }

  getRSNamespaceGroups(namespaceUri: string) {
    return this.fetchAppSync(getRSNamespaceGroups, { namespaceUri });
  }

  getRSNamespaceDatasources(namespaceUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(getRSNamespaceDatasources, {
      namespaceUri,
      options
    });
  }

  getRSNamespace(namespaceUri: string) {
    return this.fetchAppSync(getRSNamespace, { namespaceUri });
  }

  listEnvironmentRSNamespace(
    accountUri: string,
    platformUri: string,
    options: ?QueryOptions
  ) {
    return this.fetchAppSync(getEnvironmentRSNamespaces, {
      accountUri,
      platformUri,
      options
    });
  }

  listMyEnvironmentRSNamespaces(platformUri: string, options: ?QueryOptions) {
    return this.fetchAppSync(getMyEnvironmentRSNamespaces, {
      platformUri,
      options
    });
  }
}

export default AnalyticsApi;
