/* @flow */
import React from "react";

type propTypesErrorFeedback = {
  sendFeedBack: Function
};
const ErrorFeedback = ({ sendFeedBack }: propTypesErrorFeedback) => (
  <div className={"error-feedback text-primary"}>
    <a href="#" className="butn" onClick={sendFeedBack}>
      <i className="fas fa-exclamation" /> {" Report a problem"}
    </a>
  </div>
);

export default ErrorFeedback;
