export const getProjectPlatformsRedshiftClusters = {
  query: `query Q ($cartUri:String!) {
        getProjectPlatformsRedshiftClusters (cartUri:$cartUri) {
          uri
          cluster_arn
          aws
          cluster_name
          description
          resource_name
          database_name
          createdat
          owneruri
          region
          updatedat
          status
          subnet_group_name
          master_username
          endpoint_address
          endpoint_port
          public_key
          iam_roles
          number_of_nodes
          vpc_security_groups
          node_type
          platform {
            aws
            environment
            name
            uri
          }
        }
    }
    `,
  keyOutput: "getProjectPlatformsRedshiftClusters"
};
