/* @flow */
import React from "react";
import "./view.less";
import Modal from "../../../components/modal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import withAppSync from "../../AppsyncHOC";
import GraphQl from "../../../graphQL";
import ButtonAction from "../../../components/buttonAction";
import { isS3SourceType } from "../../../utils/constants";

type propTypes = {
  datasource: Object,
  showGlobalNotification: Function,
  goBack: Function,
  hide: Function,
  reload?: boolean,
  api: GraphQl
};

type stateTypes = {
  error: ?string,
  deleteKMSKey: boolean
};

class DatasourceModal extends React.Component<propTypes, stateTypes> {
  constructor(props: Object) {
    super(props);
    this.state = {
      error: null,
      deleteKMSKey: false
    };
  }

  hideModalAndReload = () => {
    this.props.hide();
    window.location.reload();
  };

  deleteDatasource = () => {
    const { datasource, goBack, showGlobalNotification, reload } = this.props;
    return this.props.api.datasource
      .deleteDatasource(datasource.uri, this.state.deleteKMSKey)
      .then(() =>
        showGlobalNotification({
          message: "Datasource deleted",
          type: "success"
        })
      )
      .then(() => (reload ? this.hideModalAndReload() : goBack()))
      .catch((error) => {
        showGlobalNotification({
          message: "Datasource deletion failed",
          type: "alert"
        });
        const errorMessage = getErrorMessageWithoutCode(error);
        if (
          errorMessage.includes(
            "cannot be deleted because it currently has children `Dataset` resources"
          )
        ) {
          this.setState({
            error:
              "You can't delete the datasource because it contains one or more dataset(s)"
          });
        } else {
          this.setState({ error: errorMessage });
        }
      });
  };

  handleChange(value) {
    this.setState({ deleteKMSKey: value });
  }

  renderBody = () => (
    <React.Fragment>
      <div className="first-part-modal">Are you sure ? </div>
      {isS3SourceType(this.props.datasource.source_type) && (
        <div className="delete-kms-container">
          <input
            id="deleteKMSKey"
            type="checkbox"
            className={"checkbox-facet"}
            onClick={(event) => this.handleChange(event.target.checked)}
          />
          <label htmlFor="deleteKMSKey" className="fas">
            <small className="attribute-label">
              Check if you want to delete KMS key
            </small>
          </label>
        </div>
      )}
    </React.Fragment>
  );

  render() {
    const { datasource, hide } = this.props;

    let { error } = this.state;
    if (
      (this.props.datasource.datasets &&
        this.props.datasource.datasets.length) ||
      (this.props.datasource.datasetsList &&
        this.props.datasource.datasetsList.total > 0)
    ) {
      error =
        "You can't delete the datasource because it contains one or more dataset(s)";
    }

    return (
      <Modal
        title={`Delete datasource ${datasource.name}`}
        body={!error && this.renderBody()}
        errorMessage={error}
        actions={[
          <button
            key={`dataset-close-${datasource.uri}`}
            type="button"
            className="butn"
            onClick={hide}
          >
            Close
          </button>,
          !error && (
            <ButtonAction
              key={`dataset-delete-${datasource.uri}`}
              label="Delete"
              className="butn butn-delete"
              onClick={this.deleteDatasource}
            />
          )
        ]}
      />
    );
  }
}

export default withAppSync(DatasourceModal);
