export const listPlatformArchivedDatasources = {
  query: `query Q ($platformUri:String!, $options: QueryOptions) {
        listPlatformArchivedDatasources(platformUri:$platformUri, options: $options) {
            results {
                uri
                name
                region
                accountid
                resource_name
                bucket
                description
                createdat
                owner_group_name
                owneruri
                kmsalias
                sharing_group_name
                aws
                action_type
                ds_archivedat
                creatoruri
            }
            total
            limit
            offset
        }
      }
    `,
  keyOutput: "listPlatformArchivedDatasources"
};
