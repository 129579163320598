export const rejectRequest = {
  query: `mutation Q ($datasetUri: String!, $groupUri: String!, $rejectMessage: String) {
        rejectShare(datasetUri: $datasetUri, groupUri: $groupUri, rejectMessage: $rejectMessage) {
          group {
                uri
                name
                account {
                    uri
                    name
                }
            }
            dataset {
                uri
                name
                access
                can_share
                datasource {
                    uri
                    name
                }
            }
            createdat
            updatedat
            request_status
        }
      }
    `,
  keyOutput: "rejectShare"
};
