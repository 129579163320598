import React from "react";
import PropTypes from "prop-types";

const Col = ({
  style = {},
  className = "",
  size = 1,
  children = [],
  ...rest
}) => (
  <div
    style={style}
    className={`${className || ""} col-${size}` || "1"}
    {...rest}
  >
    {children}
  </div>
);

Col.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Col;
