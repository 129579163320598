import debug from "debug";
import { BASE, COLOURS, LOGGER_LEVEL } from "./constants";
import config from "../config";

const LoggerConfig = {
  setLevelToVerbose: () => {
    debug.enable(
      `${BASE}:EXCEPTION,${BASE}:ERROR,${BASE}:WARNING,${BASE}:INFO,${BASE}:DEBUG,${BASE}:VERBOSE`
    );
  },
  setLevelToDebug: () => {
    debug.enable(
      `${BASE}:EXCEPTION,${BASE}:ERROR,${BASE}:WARNING,${BASE}:INFO,${BASE}:DEBUG`
    );
  },
  setLevelToInfo: () => {
    debug.enable(
      `${BASE}:EXCEPTION,${BASE}:ERROR,${BASE}:WARNING,${BASE}:INFO`
    );
  },
  setLevelToWarning: () => {
    debug.enable(`${BASE}:EXCEPTION,${BASE}:ERROR,${BASE}:WARNING`);
  },
  setLevelToError: () => {
    debug.enable(`${BASE}:EXCEPTION,${BASE}:ERROR`);
  }
};

const setLoggerLevel = () => {
  switch (config.LOGGER_LEVEL) {
    case LOGGER_LEVEL.VERBOSE:
      LoggerConfig.setLevelToVerbose();
      break;
    case LOGGER_LEVEL.DEBUG:
      LoggerConfig.setLevelToDebug();
      break;
    case LOGGER_LEVEL.INFO:
      LoggerConfig.setLevelToInfo();
      break;
    case LOGGER_LEVEL.WARNING:
      LoggerConfig.setLevelToWarning();
      break;
    case LOGGER_LEVEL.ERROR:
      LoggerConfig.setLevelToError();
      break;
    default:
      LoggerConfig.setLevelToInfo();
  }
};

class Logger {
  constructor(source) {
    this.source = source;
    setLoggerLevel();
  }

  generateMessage(level, messages) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    if (this.source) createDebug(this.source, messages);
    else createDebug(messages);
  }

  verbose(...messages) {
    return this.generateMessage("VERBOSE", messages);
  }

  debug(...messages) {
    return this.generateMessage("DEBUG", messages);
  }

  info(...messages) {
    return this.generateMessage("INFO", messages);
  }

  warning(...messages) {
    return this.generateMessage("WARNING", messages);
  }

  error(...messages) {
    return this.generateMessage("ERROR", messages);
  }

  exception(...messages) {
    return this.generateMessage("EXCEPTION", messages);
  }
}

export default (source) => new Logger(source);
