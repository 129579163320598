/* @flow */
import React from "react";
import NotificationsForm from "./NotificationsForm";
import Tabs from "../../../../components/tabs";
import routes from "../../../routes";
import PowerBIGateway from "./PowerBIGateway";
import Support from "./Support";
import GraphQLEditor from "../../../gqleditor/editor";
import FeatureToggles from "./FeatureToggles/list";
import CDHAdminArchives from "./Archives";

type propTypes = {};

type stateTypes = {};

class AdminCDH extends React.Component<propTypes, stateTypes> {
  getTabs = () => [
    {
      name: "Notifications",
      index: "cdhadmin-sendnotif",
      route: `${routes.User.MyProfile}#cdhadmin-sendnotif`,
      content: <NotificationsForm />
    },
    {
      name: "PowerBI",
      index: "cdhadmin-powerBI",
      route: `${routes.User.MyProfile}#cdhadmin-powerBI`,
      content: <PowerBIGateway />
    },
    {
      name: "Support",
      index: "cdhadmin-support",
      route: `${routes.User.MyProfile}#cdhadmin-support`,
      content: <Support />
    },
    {
      name: "GraphQL",
      index: "cdhadmin-graphql",
      route: `${routes.User.MyProfile}#cdhadmin-graphql`,
      content: <GraphQLEditor />
    },
    {
      name: "Feature Toggles",
      index: "cdhadmin-toggles",
      route: `${routes.User.MyProfile}#cdhadmin-toggles`,
      content: <FeatureToggles />
    },
    {
      name: "Archives",
      index: "archives",
      route: `${routes.User.MyProfile}#cdhadmin-archives`,
      content: <CDHAdminArchives />
    }
  ];

  render() {
    return (
      <div className="admin-cdh">
        <Tabs
          isPreload={false}
          defaultActiveIndex={"cdhadmin-sendnotif"}
          tabs={this.getTabs()}
        />
      </div>
    );
  }
}

export default AdminCDH;
