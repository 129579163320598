/* @flow */
export const createRedshiftCluster = {
  query: `mutation Q ($platformUri: String!, $newRedshiftClusterInput: NewRedshiftClusterInput!) {
        createRedshiftCluster(platformUri:$platformUri, newRedshiftClusterInput:$newRedshiftClusterInput) {
          uri
          cluster_name
        }
      }
    `,
  keyOutput: "createRedshiftCluster"
};

export type NewRedshiftClusterInput = {
  cluster_name: string,
  description: string,
  resource_name: string,
  owneruri: string,
  region: string,
  platform_uri: string,
  cluster_type: string,
  node_type: string,
  number_of_nodes: Object,
  db_name: string,
  master_username: string,
  master_user_password: string,
  subnet_group_name: string,
  vpc_security_group_ids: Array<Object>,
  iam_roles: Array<Object>,
  kmsalias: string,
  allow_version_upgrade: boolean
};
