export const queryGetProjectConnection = {
  query: `
  query Q ($connectionId: String!) {
    getDssProjectConnection(connectionId: $connectionId) {
      id
      name
      platform_uri
      datasource_uri
      dataset_uri
      instance_uri
      connection_type
      custom_class
      output_bucket
      output_encryption_key
      output_prefix
      createdat
      status
      error
      updatedat
    }
  }
`,
  keyOutput: "getDssProjectConnection"
};
