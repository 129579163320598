export const getCDHTeam = () => ({
  PF5260: "MORHAIN Didier",
  AL0001: "Alice Postman",
  DGA341: "STEPHANONI Laurent",
  JD5941: "Amine EL MAlLEM",
  AAK253: "Wolowiec Gregory",
  JB6015: "Sarah Regba",
  BO0001: "Bob Postman",
  WH5848: "PERESSINI Bertrand",
  ZZ1010: "Zinedine SDK",
  XBNW59: "Technical User",
  RM6408: "RAGHBA Amani",
  VQ5962: "TRAORE Abdoulaye",
  JM6376: "LOKOSSOU Mofiala Hervé",
  ND6384: "SAOUD Zakaria",
  JW6460: "AZRAK Hecham",
  LB5695: "LAVAL Anthony",
  KK6473: "MENARD Axel",
  VQ5373: "HAMOUCH Mohamed",
  GJ6472: "ELACHQAR Younes",
  VS6278: "AHDA Nassima",
  KZ6467: "DOUMBIA Mamadou",
  QC6466: "NINI Malik"
});

export const gidFromCDHTeam = (gid) => gid in getCDHTeam();

export const isUserFromCDHTeam = (gid) => gid && !!gidFromCDHTeam(gid);
