import React from "react";
import PropTypes from "prop-types";
import Loading from "../../components/loading";
import Error from "../../components/error";
import { getErrorMessage } from "../../utils/error";
import Logger from "../../utils/logger";

const Log = Logger("RedshiftClusterForm");
class AsyncQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      queryId: null,
      data: []
    };
  }

  componentDidMount() {
    this.startQuery();
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  getResult = () =>
    this.props
      .getStatus(this.state.queryId)
      .then((status) => {
        if (["SUCCEEDED", "FINISHED"].includes(status)) {
          return this.props.getResult(this.state.queryId).then((res) => {
            try {
              const data =
                typeof res === "string" ? JSON.parse(res) : undefined;
              this.setState({
                ready: true,
                data
              });
              return data;
            } catch (e) {
              Log.error("AsyncQuery.getResult", e);
              return this.setState({
                ready: true,
                error: "Failed to parse data"
              });
            }
          });
        }

        if (["RUNNING", "QUEUED", "STARTED"].includes(status)) {
          console.info("Response not ready"); // eslint-disable-line no-console
          this.timeout = setTimeout(this.getResult, 5000);
          return this.timeout;
        }

        if (["FAILED", "CANCELLED"].includes(status)) {
          return this.setState({
            ready: true,
            error: "The query has failed or has been cancelled"
          });
        }

        return this.setState({
          ready: true,
          error: "An error occured"
        });
      })
      .catch((err) => {
        Log.error(err);
        this.setState({
          ready: true,
          error: err.message
        });
      });

  startQuery = () =>
    this.props
      .requestQuery()
      .then((queryId) => {
        this.setState(
          {
            ready: false,
            queryId
          },
          this.getResult
        );
      })
      .catch((err) => {
        this.setState({
          ready: true,
          error: getErrorMessage(err)
        });
      });

  render() {
    if (!this.state.ready) return this.props.onPending();
    if (this.state.error) return this.props.onError(this.state.error);
    return this.props.onResult(this.state.data);
  }
}

AsyncQuery.propTypes = {
  onResult: PropTypes.func,
  onPending: PropTypes.func,
  onError: PropTypes.func,
  requestQuery: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  getResult: PropTypes.func.isRequired
};

AsyncQuery.defaultProps = {
  onPending: () => <Loading />,
  onError: (error) => <Error error={error} />,
  onResult: (data) => console.log(data) && false, // eslint-disable-line no-console
  getResult: () => new Promise((r) => r())
};

export default AsyncQuery;
