import { listCarts } from "./queryListCarts";
import { getCart } from "./queryGetCart";
import { getCartPolicies } from "./queryGetCartPolicies";
import { applyPolicySelection } from "./mutationApplyPolicySelection";
import { listPolicySlots } from "./queryListPolicySlots";
import { getMaxSlots } from "./queryGetMaxSlots";
import { getCartDatasetLight } from "./queryGetCartDatasetsLight";
import { addDatasetToCart } from "./mutationAddDatasetToCart";
import { addDatasetsToCart } from "./mutationAddDatasetsToCart";
import { removeDatasetFromCart } from "./mutationrRemoveDatasetFromCart";
import { removeCart } from "./mutationRemoveCart";
import { createCart } from "./mutationCreateCart";
import { updateCart } from "./mutationUpdateCart";
import { listAvailableCartDatasets } from "./queryListAvailableCartDatasets";
import { listAvailableCartDatasources } from "./queryListAvailableCartDatasources";
import { listAvailableGroupsForChangeOwnership } from "./queryListAvailableGroupsForChangeOwnership";
import { updateCartOwnership } from "./mutationUpdateCartOwnership";
import { getAWSConsoleAccess } from "./queryGetAWSConsoleAccess";
import { getCredentials } from "./queryGetCredentials";
import { listCartProviders } from "./queryListCartProviders";
import { addCartProvider } from "./mutationAddCartProvider";
import { deleteCartProvider } from "./mutationDeleteCartProvider";
import { updateCartProvider } from "./mutationUpdateCartProvider";
import { getEMRTemplate } from "./queryGetEMRTemplate";
import { getConnectionProperties } from "./queryConnectionProperties";
import { getCartEnvironmentStatusrole } from "./queryGetCartEnvrironementStatusRoles";
import { getCartPlatformRole } from "./queryGetCartPlatformRole";
import {
  attachCartToPowerBI,
  detachCartToPowerBI,
  getCommandStatus
} from "./mutationCartToPowerBI";
import {
  attachRedshiftFromCartToPowerBI,
  detachRedshiftFromCartToPowerBI,
  getRedshiftSendCommandStatus
} from "./mutationCartToRedshiftPowerBI";
import { getDatasetsOfCart } from "./queryGetDatasetsOfCart";
import { listMyCarts } from "./queryListMyCarts";
import { listMyCartsGroupNames } from "./queryListMyCartsGroupNames";
import { listAccelerationTemplatesProject } from "./queryListAccelerationTemplates";
import { deleteAccelerationTemplateFromProject } from "./mutationDeleteAccelerationTemplateFromProject";
import { editAccelerationTemplateFromProject } from "./mutationEditAccelerationTemplateFromProject";
import { createAccelerationTemplateFromProject } from "./mutationCreateAccelerationTemplateFromProject";
import { getAccelerationTemplateStackStatusFromProject } from "./queryGetAccelerationTemplateStackStatusFromProject";
import { listCartTrusts } from "./queryListCartTrusts";
import { createTrustFromCart } from "./mutationCreateTrustFromCart";
import { acceptTrustAttachToCart } from "./mutationAcceptTrustAttachToCart";
import { rejectTrustAttachToCart } from "./mutationRejectTrustAttachToCart";
import { removeTrustFromCart } from "./mutationRemoveTrustFromCart";
import { updateTrustFromCart } from "./mutationUpdateTrustFromCart";
import { listCartLogs } from "./queryGetCartLogs";
import { listCartServices } from "./queryListCartServices";
import { includeServicesCart } from "./mutationIncludeServicesCart";
import { excludeServicesCart } from "./mutationExcludeServicesCart";
import { getExportCartPolicies } from "./queryGetExportPolicies";
import { listDatabrews } from "./queryListDatabrews";
import { createDatabrewProject } from "./mutationCreateDatabrewProject";
import { deleteDatabrew } from "./mutationDeleteDatabrew";
import { refreshDatabrewProjectsStatus } from "./mutationRefreshDatabrewProjectsStatus";
import { getDatabrewConsoleAccess } from "./queryDatabrewConsoleAccess";
import { editDatabrewProject } from "./mutationEditDatabrewProject";
import { mutationDetachEquansCart } from "./mutationDetachEquansCart";
import { getSSMDocumentName } from "./queryGetSSMDocumentName";
import { getCartTables } from "./queryGetCartTables";
import { listAvailableRSDatasources } from "./queryListAvailableRSDatasources";
import { listProjectRSDatasources } from "./queryListProjectRSDatasources";
import { getRSDatasourcePowerBiStatus } from "./queryGetRSDatasourcePowerBiStatus";
import { mutationAttachRSDatasourceToPowerBi } from "./mutationAttachRSDatasourceToPowerBi";
import { mutationDetachRSDatasourceFromPowerBi } from "./mutationDetachRSDatasourceFromPowerBi";
import { mutationAddRSDatasourceToProject } from "./mutationLinkRSDatasourceToCart";
import { mutationRemoveRSDatasourceFromProject } from "./mutationUnlinkRSDatasourceToCart";

class CartApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  getCarts(options) {
    return this.fetchAppSync(listMyCarts, { options }, true);
  }

  listMyCartsGroupNames(options = {}) {
    return this.fetchAppSync(listMyCartsGroupNames, { options }, true);
  }

  getCartsForDataset(datasetUri, options, datasetOptions = {}) {
    return this.fetchAppSync(
      listCarts,
      { options, datasetUri, datasetOptions },
      true
    );
  }

  getCart(cartUri, optionsDatasets = { limit: 250 }) {
    return this.fetchAppSync(getCart, { cartUri, optionsDatasets });
  }

  getCartDatasetLight(cartUri, optionsDatasets = { limit: 250 }) {
    return this.fetchAppSync(getCartDatasetLight, { cartUri, optionsDatasets });
  }

  getCartPolicies(projectUri, platformUri) {
    return this.fetchAppSync(getCartPolicies, { projectUri, platformUri });
  }

  applyPolicySelection(projectUri, platformUri, policies) {
    return this.fetchAppSync(applyPolicySelection, {
      projectUri,
      platformUri,
      policies
    });
  }

  listPolicySlots(projectUri, platformUri) {
    return this.fetchAppSync(listPolicySlots, { projectUri, platformUri });
  }

  getMaxSlots(projectUri, platformUri) {
    return this.fetchAppSync(getMaxSlots, { projectUri, platformUri });
  }

  getDatasets(cartUri, options) {
    return this.fetchAppSync(getDatasetsOfCart, { cartUri, options }, true);
  }

  create(groupUri, input) {
    return this.fetchAppSync(createCart, { groupUri, input });
  }

  update(cartUri, updateCartInput) {
    return this.fetchAppSync(updateCart, { cartUri, updateCartInput });
  }

  listAvailableGroupsForChangeOwnership(cartUri) {
    return this.fetchAppSync(listAvailableGroupsForChangeOwnership, {
      cartUri
    });
  }

  updateCartOwnership(cartUri, newGroupUri, updateCartOwnershipOptions) {
    return this.fetchAppSync(updateCartOwnership, {
      cartUri,
      newGroupUri,
      updateCartOwnershipOptions
    });
  }

  exportPolicies(cartUri, platformUri) {
    return this.fetchAppSync(getExportCartPolicies, { cartUri, platformUri });
  }

  listDatabrewProjects(cartUri, options) {
    return this.fetchAppSync(listDatabrews, { cartUri, options });
  }

  createDatabrewProject(cartUri, inputData) {
    return this.fetchAppSync(createDatabrewProject, { cartUri, inputData });
  }

  editDatabrewProject(cartUri, databrewProjectId, inputData) {
    return this.fetchAppSync(editDatabrewProject, {
      cartUri,
      databrewProjectId,
      inputData
    });
  }

  deleteDatabrew(cartUri, databrewProjectId) {
    return this.fetchAppSync(deleteDatabrew, { cartUri, databrewProjectId });
  }

  refreshDatabrewProjectsStatus(cartUri, databrewProjectId) {
    return this.fetchAppSync(refreshDatabrewProjectsStatus, {
      cartUri,
      databrewProjectId
    });
  }

  getDatabrewConsoleAccess(cartUri, databrewProjectId) {
    return this.fetchAppSync(getDatabrewConsoleAccess, {
      cartUri,
      databrewProjectId
    });
  }

  deleteCart(cartUri, deleteLogsGroups = false) {
    return this.fetchAppSync(removeCart, { cartUri, deleteLogsGroups });
  }

  detachEquansProject(cartUri, platformUri) {
    return this.fetchAppSync(mutationDetachEquansCart, {
      cartUri,
      platformUri
    });
  }

  addDataset(cartUri, datasetUri) {
    return this.fetchAppSync(addDatasetToCart, { cartUri, datasetUri });
  }

  addDatasets(cartUri, datasetsUri) {
    return this.fetchAppSync(addDatasetsToCart, { cartUri, datasetsUri });
  }

  removeDataset(cartUri, datasetUri) {
    return this.fetchAppSync(removeDatasetFromCart, { cartUri, datasetUri });
  }

  listAvailableCartDatasets(cartUri, options) {
    return this.fetchAppSync(listAvailableCartDatasets, { cartUri, options });
  }

  listAvailableCartDatasources(cartUri, options) {
    return this.fetchAppSync(listAvailableCartDatasources, {
      cartUri,
      options
    });
  }

  getAWSConsoleAccess(cartUri, platformUri) {
    return this.fetchAppSync(getAWSConsoleAccess, { cartUri, platformUri });
  }

  generateCredentials(cartUri, platformUri) {
    return this.fetchAppSync(getCredentials, { cartUri, platformUri });
  }

  getConnectionProperties(cartUri, platformUri) {
    return this.fetchAppSync(getConnectionProperties, { cartUri, platformUri });
  }

  listProviders(cartUri) {
    return this.fetchAppSync(listCartProviders, { cartUri });
  }

  listCartServices(cartUri) {
    return this.fetchAppSync(listCartServices, { cartUri });
  }

  includeCartServices(cartUri, servicesNames) {
    return this.fetchAppSync(includeServicesCart, { cartUri, servicesNames });
  }

  excludeCartServices(cartUri, servicesNames) {
    return this.fetchAppSync(excludeServicesCart, { cartUri, servicesNames });
  }

  addProvider(cartUri, input) {
    return this.fetchAppSync(addCartProvider, { cartUri, input });
  }

  listCartLogs(cartUri, options) {
    return this.fetchAppSync(listCartLogs, { cartUri, options });
  }

  deleteProvider(cartUri, arn) {
    return this.fetchAppSync(deleteCartProvider, { cartUri, arn });
  }

  updateProvider(cartUri, input) {
    return this.fetchAppSync(updateCartProvider, { cartUri, input });
  }

  listCartTrusts(cartUri) {
    return this.fetchAppSync(listCartTrusts, { cartUri });
  }

  createTrustFromCart(cartUri, input) {
    return this.fetchAppSync(createTrustFromCart, { cartUri, input });
  }

  updateTrustFromCart(trustId, input) {
    return this.fetchAppSync(updateTrustFromCart, { trustId, input });
  }

  removeTrustFromCart(trustId, cartUri) {
    return this.fetchAppSync(removeTrustFromCart, { trustId, cartUri });
  }

  acceptTrustAttachToCart(trustId, cartUri) {
    return this.fetchAppSync(acceptTrustAttachToCart, { trustId, cartUri });
  }

  rejectTrustAttachToCart(trustId, cartUri) {
    return this.fetchAppSync(rejectTrustAttachToCart, { trustId, cartUri });
  }

  getEMRTemplate(cartUri, platformUri) {
    return this.fetchAppSync(getEMRTemplate, { cartUri, platformUri });
  }

  getEnvironmentStatusRole(cartUri, platformUri) {
    return this.fetchAppSync(getCartEnvironmentStatusrole, {
      cartUri,
      platformUri
    });
  }

  getCartPlatformRole(cartUri, platformUri) {
    return this.fetchAppSync(getCartPlatformRole, { cartUri, platformUri });
  }
  // getCartLogs(cartUri, options = {}) {
  //     return this.fetchAppSync(getCartLogs, { cartUri, options });
  // }

  /* attachCartToPowerBI(cartUri) {
        return this.fetchAppSync(attachCartToPowerBI, { cartUri });
    } */

  attachCartToPowerBI(cartUri, envUri, ssmDocumentName) {
    return this.fetchAppSync(attachCartToPowerBI, {
      cartUri,
      envUri,
      ssmDocumentName
    });
  }

  getSSMDocumentName(cartUri) {
    return this.fetchAppSync(getSSMDocumentName, { cartUri });
  }

  detachCartToPowerBI(cartUri) {
    return this.fetchAppSync(detachCartToPowerBI, { cartUri });
  }

  getCommandStatus(cartUri) {
    return this.fetchAppSync(getCommandStatus, { cartUri });
  }

  attachRedhsiftPowerbi(cartUri, clusterUri, password) {
    return this.fetchAppSync(attachRedshiftFromCartToPowerBI, {
      cartUri,
      clusterUri,
      password
    });
  }

  detachRedhsiftPowerbi(cartUri, clusterUri, redshiftDsn) {
    return this.fetchAppSync(detachRedshiftFromCartToPowerBI, {
      cartUri,
      clusterUri,
      redshiftDsn
    });
  }

  getRedshiftSendCommandStatus(cartUri, clusterUri) {
    return this.fetchAppSync(getRedshiftSendCommandStatus, {
      cartUri,
      clusterUri
    });
  }

  loadAccelerationTemplates(projectUri, options = {}) {
    return this.fetchAppSync(listAccelerationTemplatesProject, {
      projectUri,
      options
    });
  }

  createAccelerationTemplate(projectUri, input) {
    return this.fetchAppSync(createAccelerationTemplateFromProject, {
      projectUri,
      input
    });
  }

  editAccelerationTemplateFromProject(templateProjectId, input) {
    return this.fetchAppSync(editAccelerationTemplateFromProject, {
      templateProjectId,
      input
    });
  }

  deleteAccelerationTemplateFromProject(templateFromProjectId) {
    return this.fetchAppSync(deleteAccelerationTemplateFromProject, {
      templateFromProjectId
    });
  }

  getAccelerationTemplateStackStatusFromProject(templateFromProjectId) {
    return this.fetchAppSync(getAccelerationTemplateStackStatusFromProject, {
      templateFromProjectId
    });
  }

  getCartTables(cartUri, platformUri) {
    return this.fetchAppSync(getCartTables, { cartUri, platformUri });
  }

  listAvailableRSDatasources(cartUri, options) {
    return this.fetchAppSync(listAvailableRSDatasources, { cartUri, options });
  }

  listProjectRSDatasources(cartUri, options) {
    return this.fetchAppSync(listProjectRSDatasources, { cartUri, options });
  }

  getRSDatasourcePowerBiStatus(cartUri, datasourceUri) {
    return this.fetchAppSync(getRSDatasourcePowerBiStatus, {
      cartUri,
      datasourceUri
    });
  }

  attachRSDatasourceToPowerBi(cartUri, datasourceUri) {
    return this.fetchAppSync(mutationAttachRSDatasourceToPowerBi, {
      cartUri,
      datasourceUri
    });
  }

  detachRSDatasourceFromPowerBi(cartUri, datasourceUri) {
    return this.fetchAppSync(mutationDetachRSDatasourceFromPowerBi, {
      cartUri,
      datasourceUri
    });
  }

  addRSDatasourceToProject(cartUri, input) {
    return this.fetchAppSync(mutationAddRSDatasourceToProject, {
      cartUri,
      input
    });
  }

  removeRSDatasourceFromProject(cartUri, datasourceUri) {
    return this.fetchAppSync(mutationRemoveRSDatasourceFromProject, {
      cartUri,
      datasourceUri
    });
  }
}

export default CartApi;
