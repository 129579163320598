export const getAccelerationTemplate = {
  query: `query Q ($templateId: String!) {
        getAccelerationTemplate(acceleration_template_id: $templateId) {
            id
            name 
            description
            version
            link_to_doc
        }
      }
    `,
  keyOutput: "getAccelerationTemplate"
};
