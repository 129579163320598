export const listAvailableRSDatasources = {
  query: `query Q ($cartUri:String!, $options: QueryOptions) {
        listAvailableRSDatasources (cartUri: $cartUri, options: $options) {
            results {
                parent
                uri 
                name
                bucket
                region
                description
                accountid
                createdat
                user_roles
                database
                owner {
                    name
                    resource_name
                    uri
                }
                datasetsList {
                    total
                }
                source_type
                RSDatasource{
                 endpoint
                 namespace_status
                 namespace_error
                 namespace_id
                 namespace_name
                 jdbc_url
                 odbc_url
                 db_name
                }
            }
            total
            offset
            limit
        }
    }`,
  keyOutput: "listAvailableRSDatasources"
};
