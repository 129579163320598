/* @flow */

import React from "react";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import Row from "../../../components/row";
import Col from "../../../components/col";
import withAppSync from "../../AppsyncHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import GraphQl from "../../../graphQL";
import { updateMessage } from "../../globalNotifications/actions";
import TermsConditionsModal from "../../../components/termsConditionsModal";
import { getErrorMessageWithoutCode } from "../../../utils/error";
import "./style.less";
import SelectClassic from "../../../components/SelectClassic";
import SelectInfinite from "../../../components/SelectInfinite";

const Log = Logger("ShareDataset");

type propTypes = {
  api: GraphQl,
  reload: Function,
  showGlobalNotification: Function,
  match: {
    params: {
      uriDataset: string
    }
  },
  location: {
    state: {
      dataset: {
        name: string
      }
    }
  },
  dataset?: Object
};

type stateTypes = {
  ready: boolean,
  groupReady: boolean,
  isFetching: boolean,
  accounts: Array<Object>,
  groups: Array<Object>,
  groupUri?: string,
  dataset: Object,
  accountUri: string,
  error: Object,
  errorSubmit: Object,
  complianceModal: boolean,
  environmentOptionSelected?: Object,
  groupOptionSelected?: Object,
  totalGroups: number,
  offsetGroups: number
};

class ShareDataset extends React.Component<propTypes, stateTypes> {
  termsCheckboxRef;

  constructor(props) {
    super(props);
    this.termsCheckboxRef = React.createRef();
    this.state = {
      ready: false,
      groupReady: false,
      isFetching: false,
      accounts: [],
      dataset: {
        uri: "",
        owner: {
          name: ""
        }
      },
      accountUri: "",
      groups: [],
      totalGroups: 0,
      offsetGroups: 0,
      groupUri: undefined,
      error: undefined,
      errorSubmit: undefined,
      complianceModal: false,
      environmentOptionSelected: null,
      groupOptionSelected: null
    };
  }

  componentDidMount() {
    this.getAccounts();

    if (this.props.dataset) {
      this.setState({ dataset: this.props.dataset });
    } else {
      this.getDataset();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dataset !== state.props) {
      return { dataset: props.dataset };
    }
    return {};
  }

  getDataset = () => {
    this.props.api.dataset
      .get(this.props.match.params.uriDataset)
      .then((dataset) => {
        let details = {};
        try {
          details = JSON.parse(dataset.details);
        } catch (error) {
          details = {};
        }
        // $FlowIgnore
        dataset.details = details;
        this.setState({
          ready: true,
          dataset
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  getAccounts = () =>
    this.props.api.account
      .getAllAccounts()
      .then((accounts) => {
        this.setState({
          accounts,
          ready: true
        });
      })
      .catch((error) => {
        this.setState({
          error,
          ready: true
        });
      });

  getGroupsFromAccount = (offset, search) => {
    let queryOptions = {};
    if (search) {
      queryOptions = {
        offset: 0,
        limit: 1000,
        search: {
          name: search
        }
      };
    } else {
      queryOptions = {
        offset,
        limit: 10
      };
    }
    return this.props.api.account
      .listGroups(this.state.accountUri, queryOptions)
      .then((response) => {
        if (response) {
          const groups = response && response.results ? response.results : [];
          if (!search) {
            this.setState((prevState) => ({
              offsetGroups: prevState.offsetGroups + response.limit
            }));
          }
          this.setState({
            groups,
            totalGroups: response.total,
            groupReady: true
          });
          return groups.map((g) => ({ label: g.name, value: g.uri }));
        }

        return false;
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  onSelectAccount = (environmentOptionSelected) => {
    this.setState(
      {
        environmentOptionSelected,
        groupOptionSelected: null,
        accountUri: environmentOptionSelected.value,
        groupUri: undefined,
        errorSubmit: undefined,
        groupReady: false
      },
      this.getGroupsFromAccount
    );
  };

  onSelectGroup = (groupOptionSelected) => {
    this.setState({
      groupOptionSelected,
      groupUri: groupOptionSelected.value,
      errorSubmit: undefined
    });
  };

  openComplianceModal = () => {
    this.setState({ complianceModal: true });
  };

  closeComplianceModal = () => {
    this.setState({ complianceModal: false });
  };

  clearForm = () => {
    this.setState({
      environmentOptionSelected: null,
      groupOptionSelected: null,
      accountUri: "",
      groupUri: undefined,
      errorSubmit: undefined,
      groupReady: false
    });
  };

  onSubmit = () => {
    if (!this.props.match.params.uriDataset && !this.state.dataset.uri) {
      return this.setState({ errorSubmit: "Choose a dataset." });
    }

    if (!this.state.groupUri) {
      return this.setState({ errorSubmit: "Pick a group before to share." });
    }

    if (
      this.termsCheckboxRef &&
      this.termsCheckboxRef.current &&
      !this.termsCheckboxRef.current.checked
    ) {
      return this.setState({
        errorSubmit: "You must accept the terms & conditions"
      });
    }

    this.setState({ errorSubmit: undefined, isFetching: true });

    return this.props.api.share
      .shareDataset(
        this.props.match.params.uriDataset || this.state.dataset.uri,
        this.state.groupUri || ""
      )
      .then(() => {
        this.setState({ isFetching: false });
        this.props.showGlobalNotification({
          message: "Share dataset success",
          type: "success"
        });
        this.props.reload();
        this.clearForm();
      })
      .catch((error) => {
        this.setState({
          errorSubmit: getErrorMessageWithoutCode(error),
          isFetching: false
        });
        this.props.showGlobalNotification({
          message: "Share dataset failure",
          type: "alert"
        });
        Log.error(error);
      });
  };

  loadMoreGroupsFromAccount = (offset, search) => {
    if (search && search.length < 3) {
      return false;
    }
    return this.getGroupsFromAccount(offset, search);
  };

  render() {
    return (
      <div className={"share-dataset-by-owner"}>
        {this.state.error && (
          <Error error={this.state.error} path={"RequestForm"} stringOnly />
        )}
        {!this.state.ready && <Loading message={"Groups data"} />}
        {!this.state.error && this.state.ready && (
          <div>
            <div>
              <div className="mt-4">
                <label className="label-form">Select an organization : </label>
                <SelectClassic
                  placeholder="Select an organization"
                  options={this.state.accounts.map((a) => {
                    if (a.uri === "uri:account:000000")
                      return { value: a.uri, label: "All Engie" };
                    return { value: a.uri, label: a.name };
                  })}
                  selectedOption={this.state.environmentOptionSelected}
                  isSearchable
                  onSelectOption={this.onSelectAccount}
                />
              </div>
              <div className="mt-4">
                <label className="label-form">and Group : </label>

                <SelectInfinite
                  isLoadingOptions={
                    !this.state.groupReady && !!this.state.accountUri
                  }
                  placeholder={
                    this.state.accountUri
                      ? "Select a group"
                      : "Select an organisation first"
                  }
                  isDisabled={!this.state.accountUri}
                  initialOptions={(this.state.accountUri
                    ? this.state.groups
                    : []
                  ).map((g) => ({ value: g.uri, label: g.name }))}
                  isClearable
                  isSearchable
                  selectedOption={this.state.groupOptionSelected}
                  onSelectOption={this.onSelectGroup}
                  totalOptions={this.state.totalGroups}
                  loadMoreOptions={this.loadMoreGroupsFromAccount}
                  optionResults
                />
              </div>
              <div className="mt-4 pl-3 form-group row">
                <input
                  id="termsconditions"
                  name="termsconditions"
                  type="checkbox"
                  className={"checkbox-facet"}
                  ref={this.termsCheckboxRef}
                />
                <label className="fas" htmlFor="termsconditions">
                  <span className="labelTermsCustom">
                    I acknowledge having read and accept the general{" "}
                    <span
                      className="terms-highlight"
                      onClick={this.openComplianceModal}
                    >
                      terms & conditions
                    </span>{" "}
                    of access and use of the service
                  </span>
                </label>
              </div>
            </div>

            <Row className="justify-content-center mt-4">
              {this.state.errorSubmit && (
                <Col size={12} style={{ color: "red", paddingBottom: "10px" }}>
                  {this.state.errorSubmit}
                </Col>
              )}

              <button
                type={"button"}
                onClick={this.onSubmit}
                className={"butn"}
              >
                {this.state.isFetching && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                Share !
              </button>
            </Row>
          </div>
        )}
        {this.state.complianceModal && (
          <TermsConditionsModal onClose={this.closeComplianceModal} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default withAppSync(connect(null, mapDispatchToProps)(ShareDataset));
