import React from "react";
import { connect } from "react-redux";
import withAppSync from "../../AppsyncHOC";
import { updateMessage } from "../../globalNotifications/actions";
import GraphQl from "../../../graphQL";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from "../../../components/loading";
import Error from "../../../components/error";
import HelpOn from "../../../components/helpOn";
import Paginator from "../../../components/paginator";
import SearchByFacet from "./searchByFacet";
import DatasetCardAddToProject from "../../datasets/card/DatasetCardAddToProject";
import DatasetCardAddedToProject from "../../datasets/card/DatasetCardAddedToProject";
import DatasourceCardAddToProject from "../../datasets/card/DatasourceCardAddToProject";
import DatasourceCardAddedToProject from "../../datasets/card/DatasourceCardAddedToProject";
import "./style.less";

type propTypes = {
  match: {
    params: {
      cartUri: string,
      groupUri: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function
};
type stateTypes = {
  ready: boolean,
  isSubmitting: boolean,
  error: Object,
  cart: Object,
  offsetDataset: number,
  totalDataset: number,
  listDatasets: Array<Object>,
  offsetDatasource: number,
  totalDatasource: number,
  listDatasources: Array<Object>,
  searchFilter: string,
  producers: Array<Object>,
  producerFilter: string,
  datasources: Array<Object>,
  datasourceFilter: string,
  dataType: string, // type of data : datasource or dataset
  checkOwned: boolean,
  checkShared: boolean,
  checkExcludeAllGroups: boolean,
  selectedDatasets: Array<Object>,
  selectAllDataset: boolean,
  selectedDatasources: Array<Object>,
  selectAllDatasource: boolean
};

class AddDataset extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isSubmitting: false,
      error: null,
      cart: {
        name: "",
        uri: ""
      },
      offsetDataset: 0,
      totalDataset: 0,
      listDatasets: [],
      offsetDatasource: 0,
      totalDatasource: 0,
      listDatasources: [],
      searchFilter: "",
      producers: [],
      producerFilter: "",
      datasources: [],
      datasourceFilter: "",
      dataType: "dataset",
      checkOwned: true,
      checkShared: true,
      checkExcludeAllGroups: true,
      selectedDatasets: [],
      selectAllDataset: false,
      selectedDatasources: [],
      selectAllDatasource: false
    };
  }

  componentDidMount() {
    this.getCart().then(this.listAvailableCartDatasets);
  }

  getCart = () =>
    this.props.api.cart
      .getCartDatasetLight(this.props.match.params.cartUri)
      .then((cart) => {
        this.setState({
          cart
        });
      })
      .catch((error) => {
        this.setState({
          ready: false,
          error
        });
      });

  listAvailableCartDatasets = () => {
    this.setState({
      ready: false,
      selectAllDataset: false
    });

    const filters = [];
    if (this.state.checkOwned) {
      filters.push({ key: "access", value: "owned" });
    }
    if (this.state.checkShared) {
      filters.push({ key: "access", value: "shared" });
    }
    if (this.state.checkExcludeAllGroups) {
      filters.push({ key: "exclude_service_groups", value: true });
    }
    if (this.state.datasourceFilter) {
      filters.push({ key: "datasource", value: this.state.datasourceFilter });
    }
    if (this.state.producerFilter) {
      filters.push({ key: "account", value: this.state.producerFilter });
    }

    const options = {
      limit: 20,
      offset: this.state.offsetDataset,
      filters,
      search: undefined
    };
    if (this.state.searchFilter) {
      options.search = {
        name: this.state.searchFilter,
        description: this.state.searchFilter,
        tags: this.state.searchFilter
      };
    }

    return this.props.api.cart
      .listAvailableCartDatasets(this.state.cart.uri, options)
      .then((response) => {
        const producers = response.results.map((resp) => ({
          value: resp.account_uri,
          label: resp.account_name
        }));
        const datasources = response.results.map((resp) => ({
          value: resp.datasource_uri,
          label: resp.datasource_name
        }));
        this.setState({
          totalDataset: response.total,
          listDatasets: response.results,
          // $FlowIgnore
          producers: Array.from(new Set(producers.map(JSON.stringify))).map(
            JSON.parse
          ),
          // $FlowIgnore
          datasources: Array.from(new Set(datasources.map(JSON.stringify))).map(
            JSON.parse
          ),
          ready: true,
          selectAllDataset: this.mustSelectedAllBeChecked(
            this.state.selectedDatasets,
            response.results
          )
        });
        return response.results;
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to get available datasets",
          type: "alert"
        });
        this.setState({
          ready: true,
          error
        });
      });
  };

  listAvailableCartDatasources = () => {
    this.setState({
      ready: false,
      selectAllDatasource: false
    });

    const filters = [];
    if (this.state.checkOwned) {
      filters.push({ key: "access", value: "owned" });
    }
    if (this.state.checkShared) {
      filters.push({ key: "access", value: "shared" });
    }
    if (this.state.checkExcludeAllGroups) {
      filters.push({ key: "exclude_service_groups", value: true });
    }
    if (this.state.producerFilter) {
      filters.push({ key: "account", value: this.state.producerFilter });
    }

    const options = {
      limit: 20,
      offset: this.state.offsetDatasource,
      filters,
      search: undefined
    };
    if (this.state.searchFilter) {
      options.search = {
        name: this.state.searchFilter,
        description: this.state.searchFilter,
        tags: this.state.searchFilter
      };
    }

    return this.props.api.cart
      .listAvailableCartDatasources(this.state.cart.uri, options)
      .then((response) => {
        const producers = response.results.map((resp) => ({
          value: resp.account_uri,
          label: resp.account_name
        }));
        this.setState({
          totalDatasource: response.total,
          listDatasources: response.results,
          // $FlowIgnore
          producers: Array.from(new Set(producers.map(JSON.stringify))).map(
            JSON.parse
          ),
          ready: true,
          selectAllDatasource: this.mustSelectedAllBeChecked(
            this.state.selectedDatasources,
            response.results
          )
        });
        return response.results;
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to get available datasources",
          type: "alert"
        });
        this.setState({
          ready: true,
          error
        });
      });
  };

  addDatasetsToProject = () => {
    this.setState({ isSubmitting: true });
    const selectedDatasets = this.state.selectedDatasets.map(
      (dataset) => dataset.uri
    );
    return this.props.api.cart
      .addDatasets(this.props.match.params.cartUri, selectedDatasets)
      .then(() => {
        this.props.showGlobalNotification({
          message: this.state.cart.is_refresh_auto_enabled
            ? "Datasets added to project, your role project will be automatically refreshed"
            : "Datasets added to project",
          type: "success"
        });
        this.setState({
          isSubmitting: false,
          selectedDatasets: [],
          offsetDataset: 0,
          offsetDatasource: 0
        });
        this.listAvailableCartDatasets();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to add datasets to project",
          type: "alert"
        });
        this.setState({
          error,
          isSubmitting: false
        });
      });
  };

  addDatasourcesToProject = () => {
    this.setState({ isSubmitting: true });
    const selectedDatasources = this.state.selectedDatasources.map(
      (datasource) => datasource.uri
    );
    return this.props.api.datasource
      .addDatasourcesToProject(
        selectedDatasources,
        this.props.match.params.cartUri
      )
      .then(() => {
        this.props.showGlobalNotification({
          message: this.state.cart.is_refresh_auto_enabled
            ? "Datasources added to project, your role project will be automatically refreshed"
            : "Datasources added to project",
          type: "success"
        });
        this.setState({
          isSubmitting: false,
          selectedDatasources: [],
          offsetDataset: 0,
          offsetDatasource: 0
        });
        this.listAvailableCartDatasources();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Failed to add datasources to project",
          type: "alert"
        });
        this.setState({
          error,
          isSubmitting: false
        });
      });
  };

  launchRequestAfterChangeInput = () => {
    if (this.state.dataType === "dataset") {
      if (this.state.checkOwned || this.state.checkShared) {
        this.listAvailableCartDatasets();
      } else {
        this.setState({
          listDatasets: []
        });
      }
    } else if (this.state.dataType === "datasource") {
      if (this.state.checkOwned || this.state.checkShared) {
        this.listAvailableCartDatasources();
      } else {
        this.setState({
          listDatasources: []
        });
      }
    }
  };

  onChangeSearchInput = (e) => {
    const newSearch = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          searchFilter: newSearch,
          offsetDataset: 0,
          offsetDatasource: 0
        },
        this.launchRequestAfterChangeInput
      );
    }, 500);
  };

  onChangeDatasourceInput = (value) => {
    this.setState(
      {
        datasourceFilter: value,
        offsetDataset: 0,
        offsetDatasource: 0
      },
      this.launchRequestAfterChangeInput
    );
  };

  onChangeProducerInput = (value) => {
    this.setState(
      {
        producerFilter: value,
        offsetDataset: 0,
        offsetDatasource: 0
      },
      this.launchRequestAfterChangeInput
    );
  };

  checkOwned = () => {
    this.setState(
      (prevState) => ({
        checkOwned: !prevState.checkOwned,
        offsetDataset: 0,
        offsetDatasource: 0
      }),
      this.launchRequestAfterChangeInput
    );
  };

  checkExcludeAllGroups = () => {
    this.setState(
      (prevState) => ({
        checkExcludeAllGroups: !prevState.checkExcludeAllGroups,
        offsetDataset: 0,
        offsetDatasource: 0
      }),
      this.launchRequestAfterChangeInput
    );
  };

  checkShared = () => {
    this.setState(
      (prevState) => ({
        checkShared: !prevState.checkShared,
        offsetDataset: 0,
        offsetDatasource: 0
      }),
      this.launchRequestAfterChangeInput
    );
  };

  changeDataType = (e) => {
    this.setState(
      {
        dataType: e.target.value,
        offsetDataset: 0,
        offsetDatasource: 0
      },
      this.launchRequestAfterChangeInput
    );
  };

  mustSelectedAllBeChecked = (selectedData, listData) => {
    const selectedDataUri = selectedData.map((dst) => dst.uri);
    const listDataUri = listData.map((dst) => dst.uri);
    const listDataInSelected = selectedDataUri.filter(
      (selectUri) => listDataUri.indexOf(selectUri) > -1
    );
    if (listDataUri.length === 0) {
      return false;
    }
    if (listDataInSelected.length === listDataUri.length) {
      return true;
    }
    return false;
  };

  selectDataset = (dataset) => {
    this.setState(
      (prevState) => ({
        selectedDatasets: [...prevState.selectedDatasets, dataset]
      }),
      () => {
        if (
          this.mustSelectedAllBeChecked(
            this.state.selectedDatasets,
            this.state.listDatasets
          )
        ) {
          this.setState({ selectAllDataset: true });
        }
      }
    );
  };

  unselectDataset = (dataset) => {
    this.setState((prevState) => ({
      selectedDatasets: prevState.selectedDatasets.filter(
        (dst) => dst.uri !== dataset.uri
      ),
      selectAllDataset: false
    }));
  };

  selectDatasource = (datasource) => {
    this.setState(
      (prevState) => ({
        selectedDatasources: [...prevState.selectedDatasources, datasource]
      }),
      () => {
        if (
          this.state.selectedDatasources.length ===
          this.state.listDatasources.length
        ) {
          this.setState({ selectAllDatasource: true });
        }
      }
    );
  };

  unselectDatasource = (datasource) => {
    this.setState((prevState) => ({
      selectedDatasources: prevState.selectedDatasources.filter(
        (data) => data.uri !== datasource.uri
      ),
      selectAllDatasource: false
    }));
  };

  isDatasetSelected = (dataset) =>
    !!this.state.selectedDatasets.find((dst) => dst.uri === dataset.uri);

  isDatasourceSelected = (datasource) =>
    !!this.state.selectedDatasources.find(
      (data) => data.uri === datasource.uri
    );

  selectAllDataset = () => {
    this.setState(
      (prevState) => ({
        selectAllDataset: !prevState.selectAllDataset
      }),
      () => {
        if (this.state.selectAllDataset) {
          this.setState((prevState) => ({
            selectedDatasets: [
              ...prevState.selectedDatasets,
              ...this.state.listDatasets.filter(
                (dataset) =>
                  this.state.selectedDatasets
                    .map((dst) => dst.uri)
                    .indexOf(dataset.uri) === -1
              )
            ]
          }));
        } else {
          this.setState({
            selectedDatasets: this.state.selectedDatasets.filter(
              (selectDst) =>
                this.state.listDatasets
                  .map((dst) => dst.uri)
                  .indexOf(selectDst.uri) === -1
            )
          });
        }
      }
    );
  };

  selectAllDatasource = () => {
    this.setState(
      (prevState) => ({
        selectAllDatasource: !prevState.selectAllDatasource
      }),
      () => {
        if (this.state.selectAllDatasource) {
          this.setState((prevState) => ({
            selectedDatasources: [
              ...prevState.selectedDatasources,
              ...this.state.listDatasources.filter(
                (datasource) =>
                  this.state.selectedDatasources
                    .map((data) => data.uri)
                    .indexOf(datasource.uri) === -1
              )
            ]
          }));
        } else {
          this.setState({
            selectedDatasources: this.state.selectedDatasources.filter(
              (selectData) =>
                this.state.listDatasources
                  .map((data) => data.uri)
                  .indexOf(selectData.uri) === -1
            )
          });
        }
      }
    );
  };

  removeAllSelectedData = () => {
    if (this.state.dataType === "dataset") {
      this.setState({
        selectAllDataset: false,
        selectedDatasets: []
      });
    } else if (this.state.dataType === "datasource") {
      this.setState({
        selectAllDatasource: false,
        selectedDatasources: []
      });
    }
  };

  listAvailableCartDatasetsFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offsetDataset: offset }, () =>
        this.listAvailableCartDatasets().then(r)
      )
    );

  listAvailableCartDatasourcesFromPagination = (offset) =>
    new Promise((r) =>
      this.setState({ offsetDatasource: offset }, () =>
        this.listAvailableCartDatasources().then(r)
      )
    );

  renderDatasets = () => (
    <div className="content">
      {this.state.error && <Error error={this.state.error} path="AddDataset" />}
      {!this.state.error && !this.state.ready && (
        <Loading message="Datasets List" />
      )}
      {!this.state.error && this.state.ready && (
        <React.Fragment>
          <div className="selectAll">
            {!this.state.selectAllDataset ? (
              <div className="selectAll-text" onClick={this.selectAllDataset}>
                <i className={"butn-icon far fa-square"} />
                <span className="butn-text">Select Page </span>
              </div>
            ) : (
              <div className="selectAll-text" onClick={this.selectAllDataset}>
                <i className={"butn-icon far fa-check-square"} />
                <span className="butn-text">Unselect Page</span>
              </div>
            )}
          </div>
          <div className="without-carousel w-100">
            <Paginator
              isLoadingPage={!this.state.ready}
              loadingMessage={<Loading message="Datasets List" />}
              limit={20}
              initialOffset={this.state.offsetDataset}
              list={this.state.listDatasets}
              totalCount={this.state.totalDataset}
              componentRender={(dataset) => (
                <DatasetCardAddToProject
                  key={`dataset-card${dataset.uri}`}
                  addDatasetToList={this.selectDataset}
                  removeDatasetToList={this.unselectDataset}
                  dataset={dataset}
                  defaultSelect={this.isDatasetSelected(dataset)}
                />
              )}
              loadPage={this.listAvailableCartDatasetsFromPagination}
              onNoResult={() => (
                <p className="total-search-results">No datasets to display</p>
              )}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );

  renderSelectedDatasets = () => (
    <div className="content">
      <React.Fragment>
        <div className="selectedData">
          <div className="selectedData-text">
            <span>Selected Datasets </span>
          </div>
          {this.state.selectedDatasets.length > 0 && (
            <div
              className="selectedData-text-button"
              onClick={this.removeAllSelectedData}
            >
              <i className={"butn-icon far fa-square"} />
              <span className="butn-text">Unselect All </span>
            </div>
          )}
        </div>
        <div className={"without-carousel w-100"}>
          {this.state.selectedDatasets.length === 0 ? (
            <div className="no-items-seleted-data">No selected datasets</div>
          ) : (
            this.state.selectedDatasets.map((dataset) => (
              <DatasetCardAddedToProject dataset={dataset} />
            ))
          )}
        </div>
      </React.Fragment>
    </div>
  );

  renderDatasources = () => (
    <div className="content">
      {this.state.error && (
        <Error error={this.state.error} path="AddDatasource" />
      )}
      {!this.state.error && !this.state.ready && (
        <Loading message="Datasource List" />
      )}
      {!this.state.error && this.state.ready && (
        <React.Fragment>
          <div className="selectAll">
            {!this.state.selectAllDatasource ? (
              <div
                className="selectAll-text"
                onClick={this.selectAllDatasource}
              >
                <i className={"butn-icon far fa-square"} />
                <span className="butn-text">Select Page </span>
              </div>
            ) : (
              <div
                className="selectAll-text"
                onClick={this.selectAllDatasource}
              >
                <i className={"butn-icon far fa-check-square"} />
                <span className="butn-text">Unselect Page</span>
              </div>
            )}
          </div>
          <div className="without-carousel w-100">
            <Paginator
              isLoadingPage={!this.state.ready}
              loadingMessage={<Loading message="Datasource List" />}
              limit={20}
              initialOffset={this.state.offsetDatasource}
              list={this.state.listDatasources}
              totalCount={this.state.totalDatasource}
              componentRender={(datasource) => (
                <DatasourceCardAddToProject
                  key={`datasource-card${datasource.uri}`}
                  addDatasourceToList={this.selectDatasource}
                  removeDatasourceToList={this.unselectDatasource}
                  datasource={datasource}
                  defaultSelect={this.isDatasourceSelected(datasource)}
                />
              )}
              loadPage={this.listAvailableCartDatasourcesFromPagination}
              onNoResult={() => (
                <p className="total-search-results">
                  No datasources to display
                </p>
              )}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );

  renderSelectedDatasources = () => (
    <div className="content">
      <React.Fragment>
        <div className="selectedData">
          <div className="selectedData-text">
            <span>Selected Datasources </span>
          </div>
          {this.state.selectedDatasources.length > 0 && (
            <div
              className="selectedData-text-button"
              onClick={this.removeAllSelectedData}
            >
              <i className={"butn-icon far fa-square"} />
              <span className="butn-text">Unselect All </span>
            </div>
          )}
        </div>
        <div className={"without-carousel w-100"}>
          {this.state.selectedDatasources.length === 0 ? (
            <div className="no-items-seleted-data">No selected datasources</div>
          ) : (
            this.state.selectedDatasources.map((datasource) => (
              <DatasourceCardAddedToProject datasource={datasource} />
            ))
          )}
        </div>
      </React.Fragment>
    </div>
  );

  render() {
    return (
      <div className="add-dataset-to-project">
        <Breadcrumb view={`Add Data to project ${this.state.cart.name}`} />
        <div className="search">
          <div className="search-radio">
            <div>
              <input
                type="radio"
                id="data"
                name="data"
                value="dataset"
                checked={this.state.dataType === "dataset"}
                onChange={this.changeDataType}
              />
              <label htmlFor="dataset" className="fas">
                <span className="labelCustom text-capitalize">
                  &nbsp; Datasets
                </span>
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="data"
                name="data"
                value="datasource"
                checked={this.state.dataType === "datasource"}
                onChange={this.changeDataType}
              />
              <label htmlFor="datasource" className="fas">
                <span className="labelCustom text-capitalize">
                  &nbsp; Datasources
                </span>
              </label>
            </div>
          </div>

          <div className="search-checkbox">
            <div>
              <input
                type="checkbox"
                className={"checkbox-facet"}
                id="owned"
                name="owned"
                value="owned"
                onClick={this.checkOwned}
                checked={this.state.checkOwned}
              />
              <label htmlFor="owned" className="fas">
                <span className="labelCustom text-capitalize">Owned</span>
              </label>
            </div>

            <div>
              <input
                type="checkbox"
                className={"checkbox-facet"}
                id="shared"
                name="shared"
                value="shared"
                onClick={this.checkShared}
                checked={this.state.checkShared}
              />
              <label htmlFor="shared" className="fas">
                <span className="labelCustom text-capitalize">Shared</span>
              </label>
            </div>
            <div className="search-checkbox-exclude">
              <div>
                <input
                  type="checkbox"
                  className={"checkbox-facet"}
                  id="exclude-all-groups"
                  name="exclude-all-groups"
                  value="exclude-all-groups"
                  onClick={this.checkExcludeAllGroups}
                  checked={this.state.checkExcludeAllGroups}
                  disabled={!this.state.checkShared}
                />
                <label htmlFor="exclude-all-groups" className="fas">
                  <span className="labelCustom text-capitalize smallerText">
                    Exclude All Groups
                  </span>
                </label>
              </div>
              <div>
                <HelpOn
                  content={
                    <div>
                      <h3>{"Exclude All Groups"}</h3>
                      <div>Exclude datasets shared with special groups </div>
                      <div>(All groups in Organization, All ENGIE...) </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="search-facet">
            <SearchByFacet
              placeholder="Select Producer"
              items={this.state.producers}
              onSelectItem={this.onChangeProducerInput}
            />

            {this.state.dataType === "dataset" && (
              <SearchByFacet
                placeholder="Select Datasource"
                items={this.state.datasources}
                onSelectItem={this.onChangeDatasourceInput}
              />
            )}
          </div>

          <div className="search-input">
            <input
              className="form-control"
              placeholder="Search"
              onChange={this.onChangeSearchInput}
            />
            <i className="fas fa-search" />
          </div>

          <div className="create-container">
            <div className="new-dataset">
              <button
                type="button"
                className="butn mr-2"
                onClick={
                  this.state.dataType === "dataset"
                    ? this.addDatasetsToProject
                    : this.addDatasourcesToProject
                }
                disabled={
                  this.state.dataType === "dataset"
                    ? this.state.selectedDatasets.length <= 0
                    : this.state.selectedDatasources.length <= 0
                }
              >
                {this.state.isSubmitting && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-plus-circle butn-icon" />
                {this.state.dataType === "dataset" && (
                  <span className="butn-text">
                    Add Selected Datasets ({this.state.selectedDatasets.length})
                  </span>
                )}
                {this.state.dataType === "datasource" && (
                  <span className="butn-text">
                    Add Selected Datasources (
                    {this.state.selectedDatasources.length})
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className={"select-data-project"}>
          <div className={"select-data-body-left"}>
            {this.state.dataType === "dataset" && this.renderDatasets()}
            {this.state.dataType === "datasource" && this.renderDatasources()}
          </div>
          <div className={"select-data-body-right"}>
            {this.state.dataType === "dataset" && this.renderSelectedDatasets()}
            {this.state.dataType === "datasource" &&
              this.renderSelectedDatasources()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(null, mapDispatchToProps)(withAppSync(AddDataset));
