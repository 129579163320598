export const listDatasetsShareableWithGroup = {
  query: `query Q ($datasourceUri: String!, $options: QueryOptions) {
        listDatasetsShareableWithGroup(datasourceUri: $datasourceUri, options: $options) {
            total
            limit
            offset
            results {
                uri
                createdat
                updatedat
                s3_inventory_last_update
                name
                tags
                parent
                access
                domain
                type
                default_table
                source_type
                owner {
                    name
                    uri
                    account {
                        uri
                        name
                    }
                }
                size
                description
                datasource {
                    name
                }
            }
        }
    }
    `,
  keyOutput: "listDatasetsShareableWithGroup"
};
