/* @flow */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Alert } from "@material-ui/lab";
import withAppSync from "../../../../AppsyncHOC";
import routes from "../../../../routes";
import GraphQl from "../../../../../graphQL";
import Logger from "../../../../../utils/logger";
import { updateMessage } from "../../../../globalNotifications/actions";
import Modal from "../../../../../components/modal";
import { formatDate } from "../../../../../utils/date";
import withGoTo from "../../../../goToHOC";
import "./style.less";

const Log = Logger("SagemakerNotebookDetails");

type propTypes = {
  cart: Object,
  instance: {
    id: string,
    name: string,
    arn: string,
    status: string,
    instance_type: string,
    createdat: string,
    updatedat: string,
    error: string,
    network: {
      name: string,
      subnet_id: string,
      security_group_ids: Array<string>
    },
    environment: {
      uri: string,
      environment: string,
      name: string,
      aws: string
    }
  },
  api: GraphQl,
  showGlobalNotification: Function,
  reload: Function,
  goTo: Function
};

type stateTypes = {
  autorefreshing: boolean,
  error: Object,
  errorStop: Object,
  errorDelete: Object,
  loadingStartInstance: boolean,
  loadingStopInstance: boolean,
  loadingGetUrlInstance: boolean,
  loadingDeleteInstance: boolean,
  stopInstanceModal: boolean,
  deleteInstanceModal: boolean,
  awsAccessPending: boolean,
  status: string,
  refreshing: boolean,
  updatedat: string,
  instance_type: string
};

class SagemakerNotebookDetails extends React.Component<propTypes, stateTypes> {
  interval: ?IntervalID;

  timeout: ?TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      autorefreshing: false,
      error: props.instance.error,
      updatedat: props.instance.updatedat,
      status: props.instance.status,
      instance_type: props.instance.instance_type,
      loadingStopInstance: false,
      loadingGetUrlInstance: false,
      loadingStartInstance: false,
      loadingDeleteInstance: false,
      stopInstanceModal: false,
      deleteInstanceModal: false,
      awsAccessPending: false,
      refreshing: false,
      errorStop: undefined,
      errorDelete: undefined
    };
  }

  componentDidMount() {
    if (
      ["Pending", "Stopping", "Updating"].includes(this.state.status) ||
      this.instanceIsNotUpToDate()
    ) {
      this.autoRefresh();
    }
  }

  instanceIsNotUpToDate = () => {
    const ONE_HOUR = 60 * 60 * 1000;
    return (
      new Date().getTime() - new Date(this.props.instance.updatedat).getTime() <
      ONE_HOUR
    );
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  autoRefresh = () => {
    if (!this.interval) {
      this.setState({ autorefreshing: true });
      this.interval = setInterval(() => {
        this.refreshStatus();
      }, 5000);
    }
  };

  refreshStatus = () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true });

      this.props.api.sagemakerNotebookInstance
        .refreshInstance(this.props.instance.id)
        .then((response) => {
          this.setState({
            status: response.status,
            error: response.error,
            updatedat: response.updatedat,
            instance_type: response.instance_type,
            refreshing: false
          });
          if (["Pending", "Stopping", "Updating"].includes(response.status)) {
            this.autoRefresh();
          } else {
            if (this.interval) {
              clearInterval(this.interval);
              this.interval = undefined;
            }
            this.setState({ autorefreshing: false });
          }
        })
        .catch((error) => {
          this.setState({
            refreshing: false,
            autorefreshing: false,
            error
          });
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
          }
        });
    }
  };

  startInstance = () => {
    this.setState({ loadingStartInstance: true, error: undefined });

    return this.props.api.sagemakerNotebookInstance
      .start(this.props.instance.id)
      .then((response) => {
        Log.info("Sagemaker Notebook is starting:", response);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook is starting",
          type: "success"
        });
        this.setState({ loadingStartInstance: false, status: response.status });
        this.refreshStatus();
      })
      .catch((error) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook start failed",
          type: "alert"
        });
        this.setState({
          loadingStartInstance: false,
          error: error.message
        });
      });
  };

  openStopInstanceModal = () =>
    this.setState({ stopInstanceModal: true, errorStop: undefined });

  closeStopInstanceModal = () =>
    this.setState({ stopInstanceModal: false, errorStop: undefined });

  stopInstanceModal = () => (
    <Modal
      title={`Stop the instance Sagemaker Notebook: ${this.props.instance.name}`}
      body={"Are you sure ?"}
      errorMessage={this.state.errorStop}
      actions={
        this.state.errorStop
          ? [
              <button
                type="button"
                className="butn"
                onClick={this.closeStopInstanceModal}
              >
                Close
              </button>
            ]
          : [
              <button
                type="button"
                className="butn butn-delete"
                onClick={this.stopInstance}
              >
                Stop
              </button>,
              <button
                type="button"
                className="butn"
                onClick={this.closeStopInstanceModal}
              >
                Close
              </button>
            ]
      }
    />
  );

  stopInstance = () => {
    this.setState({ loadingStopInstance: true, errorStop: undefined });

    return this.props.api.sagemakerNotebookInstance
      .stop(this.props.instance.id)
      .then((response) => {
        Log.info("Sagemaker Notebook stopped:", response);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook is stopping",
          type: "success"
        });
        this.setState({ loadingStopInstance: false, status: response.status });
        this.closeStopInstanceModal();
        this.refreshStatus();
      })
      .catch((errorStop) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook stop failed",
          type: "alert"
        });
        this.setState({
          loadingStopInstance: false,
          errorStop
        });
      });
  };

  openDeleteInstanceModal = () => this.setState({ deleteInstanceModal: true });

  closeDeleteInstanceModal = () =>
    this.setState({ deleteInstanceModal: false, errorDelete: undefined });

  deleteInstanceModal = () => (
    <Modal
      title={`Delete the instance Sagemaker Notebook: ${this.props.instance.name}`}
      body={"Are you sure ?"}
      errorMessage={this.state.errorDelete}
      actions={
        this.state.errorDelete
          ? [
              <button
                type="button"
                className="butn"
                onClick={this.closeDeleteInstanceModal}
              >
                Close
              </button>
            ]
          : [
              <button
                type="button"
                className="butn"
                onClick={this.closeDeleteInstanceModal}
              >
                Close
              </button>,
              <button
                type="button"
                className="butn butn-delete"
                onClick={this.deleteInstance}
              >
                Delete
              </button>
            ]
      }
    />
  );

  deleteInstance = () => {
    this.setState({ loadingDeleteInstance: true });

    return this.props.api.sagemakerNotebookInstance
      .delete(this.props.instance.id)
      .then((response) => {
        Log.info("Sagemaker Notebook deleted:", response);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook deleted",
          type: "success"
        });
        this.setState({ loadingDeleteInstance: false });
        this.closeDeleteInstanceModal();
        this.props.reload();
      })
      .catch((errorDelete) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook deleting failed",
          type: "alert"
        });
        this.setState({
          loadingDeleteInstance: false,
          errorDelete
        });
      });
  };

  getUrl = () => {
    this.setState({ loadingGetUrlInstance: true });

    return this.props.api.sagemakerNotebookInstance
      .getUrl(this.props.instance.id)
      .then((url) => {
        Log.info("Sagemaker Notebook getUrl:", url);
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook open",
          type: "success"
        });
        this.setState({ loadingGetUrlInstance: false });
        window.open(url, "_blank");
      })
      .catch((errorDelete) => {
        this.props.showGlobalNotification({
          message: "Sagemaker Notebook getUrl failed",
          type: "alert"
        });
        this.setState({
          loadingGetUrlInstance: false,
          errorDelete
        });
      });
  };

  fetchConsoleUrl = () =>
    this.props.api.cart
      .getAWSConsoleAccess(
        this.props.cart.uri,
        this.props.instance.environment.uri
      )
      .then((url) => {
        this.setState({ awsAccessPending: false });
        Log.info("... succeeded");
        const getParams = (urlwithParams) => {
          const params = {};
          const parser = document.createElement("a");
          parser.href = urlwithParams;
          const query = parser.search.substring(1);
          const vars = query.split("&");
          for (let i = 0; i < vars.length; i += 1) {
            const pair = vars[i].split("=");
            params[pair[0]] = `${pair[0]}=${decodeURIComponent(pair[1])}`;
          }
          return params;
        };
        const params = getParams(url);
        params.Destination = `Destination=${encodeURIComponent(
          `https://${this.props.cart.region}.console.aws.amazon.com/sagemaker/home#/notebook-instances/${this.props.instance.name}`
        )}`;
        const urlNotebook = `https://signin.aws.amazon.com/federation?${Object.values(
          params
        ).join("&")}`;
        window.open(urlNotebook, "_blank");
      });

  getAwsConsoleAccess = () => {
    if (!this.state.awsAccessPending) {
      this.setState({ awsAccessPending: true });

      this.fetchConsoleUrl().then(() => {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      });
      this.interval = setInterval(() => {
        Log.info("New try of getting AWS console access");
        this.fetchConsoleUrl()
          .then(() => {
            clearInterval(this.interval);
            clearTimeout(this.timeout);
          })
          .catch(() => {
            Log.error("... failed");
          });
      }, 5000);

      this.timeout = setTimeout(() => {
        Log.info("AWS attempts timeout");
        if (this.state.awsAccessPending) {
          clearInterval(this.interval);
          this.setState({ awsAccessPending: false });
        }
      }, 30000);
    }
  };

  render() {
    const { instance } = this.props;
    return (
      <li className="cdh-card card-shadow card bg-white card-sagemaker-details">
        <div className="cdh-card-header">
          <div className="cdh-card-begin">
            <div className="cdh-card-begin-content">
              <i className="fas fa-book fa-spacing" />
              Sagemaker Notebook
            </div>
          </div>
          <div className="cdh-cart-end">
            <div
              className={`cart-environment-tag ${instance.environment.environment}`}
            >
              <span>{instance.environment.environment}</span>
            </div>
          </div>
        </div>
        <div className="cdh-card-body">
          <div className="cdh-card-body-left">
            <span className="text-capitalize cdh-card-name">
              {this.props.instance.name}
            </span>

            <div className="cdh-card-details">
              <p>
                <b>Environment:</b> {this.props.instance.environment.name} (
                {instance.environment.aws})
              </p>

              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>arn:</b> {this.props.instance.arn}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>instance type:</b> {this.state.instance_type}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="fab fa-aws" />
                <span className="content-card-bucket">
                  <b>network:</b> {this.props.instance.network.name}
                </span>
              </div>

              <div className="cdh-card-detail">
                <i className="far fa-calendar-alt" />
                <span className="content-card-bucket">
                  <b>&nbsp;created:</b>{" "}
                  {formatDate(this.props.instance.createdat)}
                </span>
              </div>
            </div>
          </div>
          <div className="cdh-card-body-right">
            <div className="cdh-card-body-actions">
              <div className="btn-cart" onClick={this.getAwsConsoleAccess}>
                {this.state.awsAccessPending && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fab fa-aws" />
                <span className="text-actions"> Notebook Access</span>
              </div>

              <div
                className={classnames("btn-cart", {
                  disabled: this.state.status !== "InService"
                })}
                onClick={() =>
                  this.state.status === "InService" && this.getUrl()
                }
              >
                {this.state.loadingGetUrlInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-play fa-spacing" />
                <span className="text-actions">Open Jupyter</span>
              </div>

              <div
                className={classnames("btn-cart", {
                  disabled:
                    this.state.status !== "Stopped" &&
                    this.state.status !== "Failed"
                })}
                onClick={() =>
                  (this.state.status === "Stopped" ||
                    this.state.status === "Failed") &&
                  this.startInstance()
                }
              >
                {this.state.loadingStartInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-rocket fa-spacing" />
                <span className="text-actions">Start</span>
              </div>

              <div
                className={classnames("btn-cart", {
                  disabled: this.state.status !== "InService"
                })}
                onClick={() =>
                  this.state.status === "InService" &&
                  this.openStopInstanceModal()
                }
              >
                {this.state.loadingStopInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-stop-circle fa-spacing" />
                <span className="text-actions">Stop</span>
              </div>

              <div
                className={classnames("btn-cart", {
                  disabled:
                    this.state.status !== "Stopped" &&
                    this.state.status !== "Failed"
                })}
                onClick={() =>
                  (this.state.status === "Stopped" ||
                    this.state.status === "Failed") &&
                  this.props.goTo({
                    route: routes.Cart.EditSagemakerNotebook,
                    params: {
                      cartUri: this.props.cart.uri,
                      instanceId: this.props.instance.id
                    },
                    state: {
                      instance: this.props.instance
                    }
                  })
                }
              >
                <i className="fas fa-pencil-alt fa-spacing" />
                <span className="text-actions">Edit</span>
              </div>

              <div
                className={classnames("btn-cart", {
                  disabled: ![
                    "Stopped",
                    "Failed",
                    "NotFound",
                    "Error"
                  ].includes(this.state.status)
                })}
                onClick={() =>
                  ["Stopped", "Failed", "NotFound", "Error"].includes(
                    this.state.status
                  ) && this.openDeleteInstanceModal()
                }
              >
                {this.state.loadingDeleteInstance && (
                  <i className="fas fa-circle-notch fa-spin fa-spacing" />
                )}
                <i className="fas fa-trash fa-spacing" />
                <span className="text-actions">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cdh-card-footer">
          <div className="cdh-card-begin">
            <i className="far fa-calendar-alt fa-spacing" />
            <span className="text-footer-left">
              last update: {formatDate(this.state.updatedat)}
            </span>
          </div>

          <div className="cdh-card-end">
            <button
              type="button"
              className="button-refresh-status butn butn-flat"
              onClick={this.refreshStatus}
            >
              {this.state.refreshing || this.state.autorefreshing ? (
                <i className="fas fa-sync-alt fa-spin" />
              ) : (
                <i className="fas fa-sync-alt" />
              )}
            </button>
            <div className="text-footer-metrics">
              Status
              <span
                className={classnames("tag-metrics", {
                  "green-status": this.state.status === "InService",
                  "warning-status": this.state.status === "Stopped",
                  "error-status": ["Failed", "NotFound", "Error"].includes(
                    this.state.status
                  )
                })}
              >
                {this.state.status}
              </span>
            </div>
          </div>
        </div>
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        <div>
          {this.state.stopInstanceModal && this.stopInstanceModal()}
          {this.state.deleteInstanceModal && this.deleteInstanceModal()}
        </div>
      </li>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(SagemakerNotebookDetails)));
