/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import withAppsync from "../AppsyncHOC";
import GraphQl from "../../graphQL";
import Error from "../../components/error";
import { updateMessage } from "../globalNotifications/actions";
import collibraLogo from "./collibra-logo.png";
import withGoTo from "../goToHOC";

type propTypes = {
  uriAccount: string,
  api: GraphQl,
  showGlobalNotification: Function,
  goTo: Function,
  dataset: Object,
  open: boolean,
  onClose: Function,
  reloadPublishedDatasets: Function
};

type stateTypes = {
  error: boolean,
  publishingDataset: boolean,
  collibraLinkId: Object,
  collibraLinks: Array<Object>
};

class CollibraDatasetPublish extends Component<propTypes, stateTypes> {
  constructor(props: propTypes) {
    super(props);
    this.state = {
      error: false,
      collibraLinkId: null,
      publishingDataset: false,
      collibraLinks: []
    };
  }

  componentDidMount() {
    this.getCollibraLinks();
  }

  getCollibraLinks = () => {
    this.props.api.collibra
      .list(this.props.uriAccount)
      .then((collibraLinks) => {
        this.setState({
          collibraLinks: collibraLinks.map((c) => ({
            value: c.id,
            label: `${c.domain_name} - ${c.domain_id}`
          }))
        });
      })
      .catch((error) => {
        this.setState({
          error
        });
      });
  };

  publishDatasetToCollibra = () => {
    this.props.showGlobalNotification({
      message: "Publishing CDH dataset to Collibra. It may take few minutes...",
      type: "success"
    });
    this.props.api.collibra
      .indexDatasetOnCollibra(this.props.dataset.uri, this.state.collibraLinkId)
      .then(() => {
        this.props.reloadPublishedDatasets();
        this.props.onClose();
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  render() {
    return (
      <div>
        <Dialog
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          style={{
            overflowY: "visible"
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box>
              <Grid container spacing={2}>
                <Grid item md={1} sm={1} xs={1}>
                  <img
                    className="connector-logo-img"
                    src={collibraLogo}
                    alt="Collibra"
                    width={35}
                    height={35}
                  />
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className={"mt-1"}
                  >
                    Publish dataset to Collibra domain
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ overflowY: "visible" }}>
            {this.state.error && (
              <Error error={this.state.error} path="PublishDataset" />
            )}
            <Box sx={{ p: 3 }}>
              <CardContent>
                <Autocomplete
                  options={this.state.collibraLinks}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.label;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Collibra Domain"
                      placeholder={"Select a Collibra Domain"}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    this.setState({ collibraLinkId: value.value });
                  }}
                />
              </CardContent>
              <CardContent>
                <button
                  type={"button"}
                  className="butn butn-create"
                  disabled={!this.state.collibraLinkId}
                  style={{ margin: "5px", width: "100%" }}
                  onClick={() => this.publishDatasetToCollibra()}
                >
                  {this.state.publishingDataset ? (
                    <i className="fas fa-sync-alt fa-spin" />
                  ) : (
                    <i className={"fa fa-paper-plane"} />
                  )}
                  <span>{" Publish"}</span>
                </button>
              </CardContent>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppsync(withGoTo(CollibraDatasetPublish)));
