import React from "react"
import {
  DataSearch,
  MultiList,
  ReactiveBase,
  ReactiveList
} from "@appbaseio/reactivesearch"
import { Box, CircularProgress, Grid } from "@material-ui/core"
import withAppSync from "../AppsyncHOC"
import withGoTo from "../goToHOC"
import { getErrorMessage } from "../../utils/error"
import Col from "../../components/col"
import Breadcrumb from "../../components/breadcrumb"
import config from "../../config"
import NewDataset from "../datasets/catalog/NewDataset"
import GraphQl from "../../graphQL"
import DatasetCatalogV2Card from "../datasets/card/DatasetCatalogV2Card"
import CatalogHelpItem from "./HelpItem"
import Error from "../../components/error"
import Facet from "../datasets/components/Facets/Facet"
import Scroll from "../../components/List/Scroll"
import "./style.less"

/* eslint-disable */
type propTypes = {
  auth: {
    getUser: Function,
    getIdToken: Function
  },
  api: GraphQl
}

/* eslint-disable */
type stateTypes = {
  isTokenReady: boolean,
  token: Object,
  error: Object,
  openRegionChevron: boolean,
  openGbuChevron: boolean,
  openBuChevron: boolean,
  openBeChevron: boolean,
  openTagChevron: boolean,
  openDatasourceChevron: boolean,
  openEnvChevron: boolean,
  openOrgChevron: boolean,
  openMlChevron: boolean,
  openStChevron: boolean,
  openCgChevron: boolean,
  openDataTypeChevron: boolean,
  openDomainChevron: boolean,
  ctgKey: string
}

class FacetV2 extends React.Component<{ data: any, handleChange: any }> {
  render() {
    let { data, handleChange } = this.props
    return (
      <div>
        {data && data.length < 15 ? (
          <div>
            {data.map(item => (
              <Facet
                key={item.key}
                category={item.key}
                facet={{
                  value: item.key,
                  nb: item.doc_count
                }}
                onClickFacet={handleChange}
              />
            ))}
          </div>
        ) : (
          <div style={{ maxHeight: "400px" }}>
            <Scroll
              itemComponent={(item, key, style) => (
                <Facet
                  style={style}
                  key={key}
                  category={item.key}
                  facet={{
                    value: item.key,
                    nb: item.doc_count
                  }}
                  onClickFacet={handleChange}
                />
              )}
              list={data}
              itemComponentHeight={32}
            />
          </div>
        )}
      </div>
    )
  }
}

class CatalogV2Component extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props)
    this.state = {
      isTokenReady: false,
      token: null,
      error: null,
      openRegionChevron: false,
      openGbuChevron: true,
      openBuChevron: true,
      openBeChevron: true,
      openTagChevron: false,
      openDatasourceChevron: false,
      openEnvChevron: false,
      openOrgChevron: false,
      openMlChevron: true,
      openStChevron: true,
      openCgChevron: true,
      openDataTypeChevron: false,
      openDomainChevron: false,
      ctgKey: new Date().getTime().toString()
    }
  }

  componentDidMount() {
    this.getToken()
  }

  getToken = () => {
    this.props.auth
      .getIdToken()
      .then(token => {
        this.setState({
          token,
          isTokenReady: true
        })
      })
      .catch(error => {
        this.setState({
          error,
          isTokenReady: true
        })
        Log.error(getErrorMessage(error))
      })
  }

  transformRequest = request => {
    const transformedRequest = { ...request }
    transformedRequest.url = process.env.REACT_APP_SEARCH_API
    return {
      ...request,
      url: config.APPSYNC_ENDPOINT,
      credentials: this.state.token,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token
      },
      body: JSON.stringify({
        query: `query Q ($search: String!) {
                        searchDatasetResultsV2(search: $search)
                    }
                    `,
        variables: { search: request.body }
      })
    }
  }

  transformResponse = async response => {
    try {
      const datasets = JSON.parse(response.data.searchDatasetResultsV2)
      if (!datasets || !datasets.hits || !datasets.hits.hits) {
        return null
      }
      const uris = datasets.hits.hits.map(dataset => dataset._id)
      const datasetLastInformations = await this.props.api.dataset.getLastDataUpdate(
        uris
      )

      const datasetLastInformationsMap = new Map(
        datasetLastInformations.map(dataset => [dataset.uri, dataset])
      )
      datasets.hits.hits.forEach(dataset => {
        const datasetId = dataset._id
        if (datasetLastInformationsMap.has(datasetId)) {
          const datasetLastInfo = datasetLastInformationsMap.get(datasetId)
          dataset._source.updatedat = datasetLastInfo.last_data_update
          dataset._source.size = datasetLastInfo.size
          dataset._source.nb_views = datasetLastInfo.nb_views
        }
      })
      return datasets
    } catch (error) {
      this.setState({
        ready: true,
        error
      })
      return null
    }
  }

  onClickReset = () => {
    this.setState({
      ctgKey: Math.random().toString()
    })
  }

  render() {
    return (
      <div key={this.state.ctgKey}>
        {this.state.error && (
          <Error error={this.state.error} path={"Catalog"} />
        )}
        {this.state.isTokenReady && this.state.token !== null && (
          <ReactiveBase
            key={this.state.ctgKey}
            app="cdh-index"
            enableAppbase={false}
            url={config.APPSYNC_ENDPOINT}
            transformRequest={this.transformRequest}
            transformResponse={this.transformResponse}
          >
            <div className="dataset-list">
              <div className="d-flex">
                <div
                  className={"catalog-facets"}
                  style={{ visibility: "visible", marginTop: "25px" }}
                >
                  <div className="facet-home">
                    <h3 className="title-search">Search Criteria</h3>
                  </div>
                  <div className="actions-container">
                    <div className="actions" onClick={this.onClickReset}>
                      <i className="fas fa-minus-circle" />
                      <span> Reset filters</span>
                    </div>
                  </div>
                  <ul
                    className="facet-list-criteria collapse show"
                    style={{ marginTop: "25px" }}
                  >
                    <Box>
                      <li className="facet-category" key={"bu"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openGbuChevron: !prevState.openGbuChevron
                            }))
                          }
                        >
                          <h3>{"Global Business Units"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openGbuChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openGbuChevron && (
                        <MultiList
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="GBU"
                          renderNoResults={() => <p>No GBU found</p>}
                          componentId="GbuSensor"
                          dataField="gbus"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"bu"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openBuChevron: !prevState.openBuChevron
                            }))
                          }
                        >
                          <h3>{"Business Units"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openBuChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openBuChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="Scopes"
                          renderNoResults={() => <p>No entity scope found</p>}
                          componentId="EntityScopeSensor"
                          dataField="scope"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"be"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openBeChevron: !prevState.openBeChevron
                            }))
                          }
                        >
                          <h3>{"Business Entities"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openBeChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openBeChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="EntitiesOrganizations"
                          renderNoResults={() => <p>No ORG ID found</p>}
                          componentId="EntitiesOrgSensor"
                          dataField="org_name"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"st"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openStChevron: !prevState.openStChevron
                            }))
                          }
                        >
                          <h3>{"Source Type"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openStChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openStChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="Source Type"
                          renderNoResults={() => <p>No Source Type found</p>}
                          componentId="SourceTypeSensor"
                          dataField="source_type"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"cg"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openCgChevron: !prevState.openCgChevron
                            }))
                          }
                        >
                          <h3>{"Under Collibra governance"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openCgChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openCgChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="CollibraStatus"
                          renderNoResults={() => <p>No Collibra asset found</p>}
                          componentId="CollibraStatusSensor"
                          dataField="collibra_status"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"ml"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openMlChevron: !prevState.openMlChevron
                            }))
                          }
                        >
                          <h3>{"Maturity Level"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openMlChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openMlChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="Maturity Level"
                          renderNoResults={() => (
                            <p>No Maturity Levels found</p>
                          )}
                          componentId="MaturityLevelSensor"
                          dataField="maturity_level"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"dt"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openDomainChevron: !prevState.openDomainChevron
                            }))
                          }
                        >
                          <h3>{"Business Domains"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openDomainChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openDomainChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="Domain"
                          renderNoResults={() => <p>No Domains found</p>}
                          componentId="DomainSensor"
                          dataField="domain"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"dt"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openDataTypeChevron: !prevState.openDataTypeChevron
                            }))
                          }
                        >
                          <h3>{"Data Types"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openDataTypeChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openDataTypeChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch={false}
                          filterLabel="Data Types"
                          renderNoResults={() => <p>No Data Types found</p>}
                          componentId="DataTypeSensor"
                          dataField="data_types"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"Tags"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openTagChevron: !prevState.openTagChevron
                            }))
                          }
                        >
                          <h3>{"Tags"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openTagChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openTagChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing
                          showSearch
                          filterLabel="Tags"
                          renderNoResults={() => <p>No tags found</p>}
                          componentId="TagSensor"
                          dataField="tags"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category  mb-2" key={"Datasources"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openDatasourceChevron: !prevState.openDatasourceChevron
                            }))
                          }
                        >
                          <h3>{"Datasources"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openDatasourceChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openDatasourceChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing={false}
                          showSearch
                          filterLabel="Datasources"
                          renderNoResults={() => <p>No datasources found</p>}
                          componentId="DatasourceSensor"
                          dataField="datasource_name"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"Organizations"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openOrgChevron: !prevState.openOrgChevron
                            }))
                          }
                        >
                          <h3>{"Organizations"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openOrgChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openOrgChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing={false}
                          showSearch
                          filterLabel="Organizations"
                          renderNoResults={() => <p>No organizations found</p>}
                          componentId="OrganizationSensor"
                          dataField="account_name"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category mb-2" key={"Environments"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openEnvChevron: !prevState.openEnvChevron
                            }))
                          }
                        >
                          <h3>{"Environments"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openEnvChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openEnvChevron && (
                        <MultiList
                          innerClass={{
                            input: "searchInput"
                          }}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showMissing={false}
                          showSearch
                          filterLabel="Environments"
                          renderNoResults={() => <p>No environments found</p>}
                          componentId="EnvironmentSensor"
                          dataField="platform_name"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category" key={"Regions"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openRegionChevron: !prevState.openRegionChevron
                            }))
                          }
                        >
                          <h3>{"Regions"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openRegionChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openRegionChevron && (
                        <MultiList
                          innerClass={"catalog-facets"}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showSearch={false}
                          renderNoResults={() => <p>No regions found</p>}
                          componentId="RegionSensor"
                          filterLabel="Regions"
                          dataField="region"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <li className="facet-category" key={"usage"}>
                        <div
                          className="facet-title"
                          onClick={() =>
                            this.setState(prevState => ({
                              openUsageChevron: !prevState.openUsageChevron
                            }))
                          }
                        >
                          <h3>{"Usage"}</h3>
                          <i
                            className={`facet-chevron fas ${
                              this.state.openUsageChevron
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </li>
                      {this.state.openUsageChevron && (
                        <MultiList
                          innerClass={"catalog-facets"}
                          showCheckbox
                          showLoadMore={false}
                          loader={<CircularProgress size={15} />}
                          showSearch={false}
                          renderNoResults={() => <p>No Visibility found</p>}
                          componentId="VisibilitySensor"
                          filterLabel="Usage"
                          dataField="visibility"
                          size={200}
                          render={({ data, handleChange }) => {
                            return (
                              <FacetV2
                                handleChange={handleChange}
                                data={data}
                              />
                            )
                          }}
                        />
                      )}
                    </Box>
                  </ul>
                  <div className="back-top">
                    <a href="#layout" className="to-top">
                      <i className="fas fa-angle-double-up" /> back to top
                    </a>
                  </div>
                </div>
                <Col size={7} className={"col-md-8 noPadRight"}>
                  <Breadcrumb
                    view="Catalog"
                    helpOn={{
                      content: <CatalogHelpItem />,
                      helpCenterLink: `${config.HELP_CENTER}`
                    }}
                    hideGoBack
                  />
                  <div className={"ml-3 mt-3 mb-3"}>
                    <Grid container spacing={3}>
                      <Grid item md={10} sm={6} xs={6}>
                        <div>
                          <DataSearch
                            innerClass={{ input: "dataSearchInput" }}
                            autoSuggest
                            showIcon
                            fuzziness="AUTO"
                            componentId="SearchSensor"
                            filterLabel="text"
                            dataField={[
                              "name",
                              "region",
                              "domain",
                              "tags",
                              "platform_name",
                              "account_name",
                              "datasource_name",
                              "gbus",
                              "org_name",
                              "org_id",
                              "scope",
                              "domain",
                              "scope_id",
                              "sub_scope",
                              "visibility",
                              "source_type",
                              "collibra_status"
                            ]}
                            placeholder="Search for datasets"
                          />
                        </div>
                      </Grid>
                      <Grid>
                        <div className={"mt-3"}>
                          <NewDataset />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="ml-2">
                    <div className={"without-carousel"}>
                      <div className="list-scroll-datasets">
                        <ReactiveList
                          componentId="SearchResult"
                          dataField="model"
                          className="result-list-container"
                          pagination
                          paginationAt="bottom"
                          size={5}
                          renderResultStats={stats => (
                            <Grid container spacing={3}>
                              <Grid item md={9} sm={6} xs={6}>
                                <div className="total-search-results mb-3">
                                  <span>{`${stats.numberOfResults} dataset${
                                    stats.numberOfResults !== 1 ? "s" : ""
                                  } found`}</span>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          render={({ data }) => (
                            <div>
                              {data.map(hit => (
                                <DatasetCatalogV2Card key={hit.uri} {...hit} />
                              ))}
                            </div>
                          )}
                          infiniteScroll
                          react={{
                            and: [
                              "RegionSensor",
                              "SearchSensor",
                              "TagSensor",
                              "GbuSensor",
                              "EnvironmentSensor",
                              "OrganizationSensor",
                              "EntityScopeSensor",
                              "EntitiesOrgSensor",
                              "DatasourceSensor",
                              "MaturityLevelSensor",
                              "DataTypeSensor",
                              "DomainSensor",
                              "VisibilitySensor",
                              "SourceTypeSensor",
                              "CollibraStatusSensor"
                            ]
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </ReactiveBase>
        )}
      </div>
    )
  }
}

export default withAppSync(withGoTo(CatalogV2Component))
