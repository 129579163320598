/* @flow */
import React from "react";
import validateValue from "./validateValue";

const operators = ["=", ">", "<", ">=", "<=", "<>", "!="];

type Props = {
  columnTypes: Array<Object>,
  rule: Object,
  availableColumns: Array<{ label: string, value: string }>,
  onRuleChange: Function,
  onRemove: Function
};

export default function ExpressionRule({
  columnTypes,
  rule,
  availableColumns,
  onRuleChange,
  onRemove
}: Props) {
  function handleBooleanOperatorChange(e) {
    onRuleChange({ ...rule, booleanOperator: e.target.value });
  }

  function handleColumnChange(e) {
    const selectedColumnValue = e.target.value;
    const columnEntity = availableColumns.find(
      (col) => col.value === selectedColumnValue
    );
    const seekColumnType = columnTypes.find(
      (i) => i.Name === selectedColumnValue
    );
    if (columnEntity && seekColumnType) {
      const isValueValid = validateValue({
        columnType: seekColumnType.Type,
        value: rule.columnValue
      });

      onRuleChange({
        ...rule,
        column: `${columnEntity.value}`,
        columnType: seekColumnType.Type,
        isValueValid
      });
    }
  }

  function handleOperatorChange(e) {
    onRuleChange({ ...rule, operator: e.target.value });
  }

  function handleValueChange(e) {
    let isValueValid = true;
    if (rule.columnType) {
      isValueValid = validateValue({
        columnType: rule.columnType,
        value: e.target.value
      });
    }
    onRuleChange({ ...rule, columnValue: e.target.value, isValueValid });
  }

  function handleRemove() {
    onRemove();
  }

  return (
    <React.Fragment>
      {rule.booleanOperator ? (
        <select
          className="boolean-operator"
          value={rule.booleanOperator}
          onChange={handleBooleanOperatorChange}
        >
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
      ) : null}
      <div className="expression-builder-rule-expression">
        <select value={rule.column.value} onChange={handleColumnChange}>
          <option value="">Select column</option>
          {availableColumns.map((c) => (
            <option value={c.value || ""} key={c.label}>
              {c.label}
            </option>
          ))}
        </select>
        <select
          value={rule.operator}
          style={{ width: "90px" }}
          onChange={handleOperatorChange}
        >
          {operators.map((o) => (
            <option value={o || ""} key={o}>
              {o}
            </option>
          ))}
        </select>
        <div>
          <input
            type="text"
            value={rule.columnValue}
            onChange={handleValueChange}
          />
          {rule.isValueValid === false ? (
            <div className="expression-builder-value-error-container">
              <div className="expression-builder-value-error">
                invalid value
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="expression-builder-rule-delete-button"
          onClick={handleRemove}
        ></div>
      </div>
    </React.Fragment>
  );
}
