/* @flow */
import React from "react";
import SearchDataset from "./searchDataset";
import DatasetShareByOwner from "../../ShareDatasetByOwner";
import SearchDatasetsSugestion from "./searchDatasetsSugestion";
import GraphQl from "../../../../graphQL";
import withAppSync from "../../../AppsyncHOC";

type propTypes = {
  api: GraphQl
};

type stateTypes = {
  term: string,
  datasetChoosed: Object,
  datasets?: Array<Object>,
  isFetching: boolean
};

class NewShare extends React.Component<propTypes, stateTypes> {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      datasetChoosed: {},
      datasets: undefined,
      isFetching: false
    };
  }

  search = () => {
    this.setState({ isFetching: true });
    this.props.api.dataset
      .getDatasets({
        term: this.state.term,
        filters: [
          { field: "can_share", values: ["true"] },
          { field: "is_shareable", values: ["true"] }
        ],
        limit: 10
      })
      .then((datasets) => {
        if (datasets) this.setState({ datasets, isFetching: false });
      })
      .catch(() => {
        this.setState({ datasets: [], isFetching: false });
      });
  };

  onChange = (event, { newValue }) => {
    this.setState({ term: newValue });
  };

  onChangeTerm = (term) => {
    this.setState({ term });
  };

  onClear = (fullReset) => {
    if (fullReset) {
      this.setState({ datasets: undefined, term: "", datasetChoosed: {} });
    } else {
      this.setState({ datasets: undefined });
    }
  };

  render() {
    return (
      <div>
        <SearchDataset
          onChangeTerm={this.onChangeTerm}
          term={this.state.term}
          runSearch={this.search}
          onClear={this.onClear}
          isLoading={this.state.isFetching}
        />
        <SearchDatasetsSugestion
          datasets={this.state.datasets}
          onChoose={(datasetChoosed) =>
            this.setState({ datasetChoosed, term: datasetChoosed.name })
          }
          onClear={this.onClear}
        />
        <DatasetShareByOwner
          dataset={this.state.datasetChoosed}
          reload={() => this.onClear(true)}
        />
      </div>
    );
  }
}

export default withAppSync(NewShare);
