export const listAvailableGroupsForChangeOwnership = {
  query: `query Q ($cartUri: String!) {
        listAvailableGroupsForChangeOwnership (cartUri: $cartUri) {
            uri
            name
        }
    }
`,
  keyOutput: "listAvailableGroupsForChangeOwnership"
};
