import { mutationCreate } from "./mutationCreate";
import { mutationUpdate } from "./mutationUpdate";
import { queryList } from "./queryList";
import { queryGetSagemakerNotebookInstance } from "./queryGetSagemakerInstance";
import { mutationRefreshInstance } from "./mutationRefreshInstance";
import { mutationStartInstance } from "./mutationStartInstance";
import { mutationStopInstance } from "./mutationStopInstance";
import { mutationDeleteInstance } from "./mutationDeleteInstance";
import { mutationGetUrl } from "./mutationGetUrl";

class SagemakerNotebookInstanceApi {
  constructor(fetchAppSync) {
    this.fetchAppSync = fetchAppSync;
  }

  create(cartUri, input) {
    return this.fetchAppSync(mutationCreate, {
      cartUri,
      input
    });
  }

  // eslint-disable-next-line camelcase
  update(instance_id, input) {
    return this.fetchAppSync(mutationUpdate, {
      instance_id,
      input
    });
  }

  getSagemakerNotebookInstance(instanceId) {
    return this.fetchAppSync(queryGetSagemakerNotebookInstance, { instanceId });
  }

  list(cartUri, offset = 0, limit = 4) {
    return this.fetchAppSync(queryList, {
      cartUri,
      options: { offset, limit }
    });
  }

  refreshInstance(instanceId) {
    return this.fetchAppSync(mutationRefreshInstance, { instanceId });
  }

  start(instanceId) {
    return this.fetchAppSync(mutationStartInstance, { instanceId });
  }

  stop(instanceId) {
    return this.fetchAppSync(mutationStopInstance, { instanceId });
  }

  delete(instanceId) {
    return this.fetchAppSync(mutationDeleteInstance, { instanceId });
  }

  getUrl(instanceId) {
    return this.fetchAppSync(mutationGetUrl, { instanceId });
  }
}

export default SagemakerNotebookInstanceApi;
