/* @flow */
export const queryListMyNotifications = {
  query: `query Q ($options: QueryOptions){
    listMyNewtifications(options: $options) {
        total
        results {
            id
            is_read
            subtype {
                id
                code
                name
            }
            createdat
            content

            ... on UncompliantNewtification {
                resources {
                    uri
                }
            }
            
            ... on ShareNewtification {
                resources {
                    group {
                        uri
                        name
                        account {
                            uri
                            name
                        }
                    }
                    dataset {
                        uri
                        name
                        access
                        expiration_date
                        can_share
                        datasource {
                            uri
                            name
                        }
                    }
                    createdat
                    updatedat
                    request_status
                    message
                    reject_message
                }
            }

            ... on TrustNewtification {
                resources {
                    arn
                    description
                    status
                    type
                    id
                    group {
                        name
                        uri
                    }
                    platform {
                        aws
                        name
                        uri
                    }
                }
            }
        }
    }
}
`,
  keyOutput: "listMyNewtifications"
};

export type SharePropsTypes = {
  group: {
    uri: string,
    name: string,
    account: {
      uri: string,
      name: string
    }
  },
  dataset: {
    uri: string,
    name: string,
    access: string,
    datasource: ?{
      uri: string,
      name: string
    }
  },
  createdat: string,
  updatedat: string,
  request_status: string
};

export type NewtificationPropsTypes = {
  id: string,
  subtype: any,
  is_read: boolean,
  message: string,
  createdat: string | Date,
  updatedat: ?(string | Date),
  resources: SharePropsTypes | Object,
  target_uri: ?string
};

export type ListMyNotificationsPropsTypes =
  $ReadOnlyArray<NewtificationPropsTypes>;
