/* @flow */

export const createTrustFromCart = {
  query: `mutation Q ($cartUri: String!, $input: NewTrustInput) {
        createTrustFromCart(cartUri:$cartUri, input:$input) {
          arn
        }
      }
    `,
  keyOutput: "createTrustFromCart"
};
