export const listGroupsIdentity = {
  query: `query Q ($identityUri: String!,$options: QueryOptions) {
        listGroupsIdentity(identityUri: $identityUri, options: $options) {
            offset 
            limit
            results {
                uri 
                name
            }
        }
    }`,
  keyOutput: "listGroupsIdentity"
};
