export const getDatasourceReplication = {
  query: `
        query Q ($datasourceUri: String!, $options: QueryOptions) {
            getDatasourceReplication(originalDatasourceUri: $datasourceUri, options: $options) { 
                results {
                    source_datasource_uri
                    replication_datasource_uri
                    storage_class
                    is_entire_bucket
                    replication_id
                    status
                    createdat
                    updatedat
                    replication_rules {
                        replication_id
                        replicated_data
                        status
                    }
                }
                total
            }
        }  
`,
  keyOutput: "getDatasourceReplication"
};
