import React from "react";
import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Routes from "../../routes";
import withAppSync from "../../AppsyncHOC";
import withGoTo from "../../goToHOC";
import Error from "../../../components/error";
import Logger from "../../../utils/logger";
import { updateMessage } from "../../globalNotifications/actions";
// import "./create.less";
import GraphQl from "../../../graphQL";
import Breadcrumb from "../../../components/breadcrumb";
import { haveSpecialCharactersCDH } from "../../../utils/string";

const Log = Logger("DatabricksAccountCreate");

class NewDatabricksAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorSubmit: false,
      isCreating: false
    };
  }

  save = (values, setSubmitting) => {
    this.setState({ isCreating: true });
    const inputData = {
      dbx_account_id: values.dbx_account_id,
      dbx_account_name: values.dbx_account_name,
      dbx_client_id: values.dbx_client_id,
      account_uri: this.props.match.params.uriAccount,
      dbx_client_secret_value: values.dbx_client_secret_value,
      dbx_client_secret_id: values.dbx_client_secret_id,
      dbx_service_principal_id: values.dbx_service_principal_id
    };
    return this.props.api.databricks
      .registerDatabricksAccount(inputData)
      .then((dbxAccount) => {
        this.props.showGlobalNotification({
          message: `Databricks account registration : ${dbxAccount.dbx_account_name}`,
          type: "success"
        });
        setSubmitting(false);
        this.setState({ isCreating: false });
        return this.props.goTo({
          route: Routes.Databricks.DetailsAccount,
          params: {
            uriAccount: this.props.match.params.uriAccount,
            uuid: dbxAccount.uuid
          }
        });
      })
      .catch((errorSubmit) => {
        this.setState({ isCreating: false });
        this.props.showGlobalNotification({
          message: "Databricks account registration failed",
          type: "alert"
        });
        setSubmitting(false);
        this.setState({
          errorSubmit
        });
      });
  };

  render() {
    return (
      <div className="playgrounds-create">
        <div className="title">
          <Breadcrumb view={"Register a new Databricks Account"} />
        </div>
        <div className="form-container">
          <Formik
            initialValues={{
              dbx_account_id: "",
              dbx_account_name: "",
              account_uri: this.props.match.params.uriAccount,
              dbx_client_id: "",
              dbx_client_secret_value: "",
              dbx_client_secret_id: "",
              dbx_service_principal_id: ""
            }}
            validate={(values) => {
              Log.info("validate", values);
              const errors = {};
              if (!values.dbx_account_id) {
                errors.dbx_account_id = "This field is mandatory";
              }
              if (!values.dbx_account_name) {
                errors.dbx_account_name = "This field is mandatory";
              }
              if (!values.dbx_client_id) {
                errors.dbx_client_id = "This field is mandatory";
              }
              if (!values.dbx_client_secret_value) {
                errors.dbx_client_secret_value = "This field is mandatory";
              }
              if (!values.dbx_client_secret_id) {
                errors.dbx_client_secret_id = "This field is mandatory";
              }
              if (!values.dbx_service_principal_id) {
                errors.dbx_service_principal_id = "This field is mandatory";
              }
              if (haveSpecialCharactersCDH(values.dbx_account_name))
                errors.dbx_account_name =
                  "Databricks Account Name must not contain these special characters ( & / ' ; )";
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              Log.info("submit !", values);
              this.save(values, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {this.state.errorSubmit && (
                  <Error
                    error={this.state.errorSubmit}
                    path={"NewDatabricksAccount"}
                    rawErrorMessage
                    stringOnly
                  />
                )}
                <fieldset className="form-group">
                  <legend className="label-form">Databricks Name</legend>
                  <Field
                    type="text"
                    name="dbx_account_name"
                    className="form-control bg-white"
                    placeholder="Databricks Name..."
                    required
                  />
                  <ErrorMessage
                    name="dbx_account_name"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Databricks account ID</legend>
                  <Field
                    type="text"
                    name="dbx_account_id"
                    className="form-control bg-white"
                    placeholder="Databricks account ID..."
                  />
                  <ErrorMessage
                    name="dbx_account_id"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">Databricks Client ID</legend>
                  <Field
                    type="text"
                    name="dbx_client_id"
                    className="form-control bg-white"
                    placeholder="Databricks Client ID..."
                  />
                  <ErrorMessage
                    name="dbx_client_id"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">
                    Databricks Client Secret Value
                  </legend>
                  <Field
                    type="text"
                    name="dbx_client_secret_value"
                    className="form-control bg-white"
                    placeholder="Databricks Client Secret Value..."
                  />
                  <ErrorMessage
                    name="dbx_client_secret_value"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">
                    Databricks Client Secret ID
                  </legend>
                  <Field
                    type="text"
                    name="dbx_client_secret_id"
                    className="form-control bg-white"
                    placeholder="Databricks Client Secret ID..."
                  />
                  <ErrorMessage
                    name="dbx_client_secret_id"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <legend className="label-form">
                    Databricks Service Principal ID
                  </legend>
                  <Field
                    type="text"
                    name="dbx_service_principal_id"
                    className="form-control bg-white"
                    placeholder="Databricks Service Principal ID..."
                  />
                  <ErrorMessage
                    name="dbx_service_principal_id"
                    component="div"
                    className="error-msg"
                  />
                </fieldset>
                <div className={"row justify-content-center"}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="butn"
                  >
                    {this.state.isCreating && (
                      <i className="fas fa-circle-notch fa-spin fa-spacing" />
                    )}
                    Register New Databricks Account
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(NewDatabricksAccount)));
