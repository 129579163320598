/* @flow */

export const getDatasetOutputs = {
  query: `
  query Q ($datasetUri: String!) {
    getDataset(datasetUri: $datasetUri) {
      outputs {
        output_id
        tablename
        description
        updatedat
        tags
        is_default
        columns {
          Name
        }
      }
    }
  }
`,
  keyOutput: "getDataset"
};
