export const getDatasetsOfDatasource = {
  query: `
  query Q ($accountUri: String, $datasourceUri: String!, $options: QueryOptions) {
    getDatasource(accountUri: $accountUri, datasourceUri: $datasourceUri) {
      datasets (options: $options){
          uri
          accountid
          name
          resource_name
          description
          tags
          doc
          type
          confidentiality
          domain
          refresh_sla
          last_loaded
          details
          createdat
          updatedat
          s3_inventory_last_update
          period_start_at
          period_end_at
          expiration_date
          language
          default_table
          access
          arn
          source_type
          schema
          database
      }
    }
  }
`,
  keyOutput: "getDatasource.datasets"
};
