/* @flow */

import React from "react";
import classNames from "classnames";
import withGoTo from "../containers/goToHOC";
import routes from "../containers/routes";
import BackButton from "./backButton";
import HelpOn from "./helpOn";

import "./breadcrumb.less";

type accountPropTypes = {
  account: {
    name: string,
    uri: string
  },
  goTo: Function
};

const separator = " / ";

const Account = ({ account, goTo }: accountPropTypes) => (
  <div className="account">
    <span
      className={classNames({ activeLink: !!account.uri })}
      onClick={() => {
        if (account.uri) {
          goTo({
            route: routes.Playground.List,
            params: {
              uriAccount: account.uri
            },
            state: {
              account
            }
          });
        }
      }}
    >
      {account.name}
    </span>
    {separator}
  </div>
);

type playgroundPropTypes = {
  playground: {
    name: string,
    uri: string
  },
  account: {
    uri: string
  },
  goTo: Function
};

const Playground = ({ playground, account, goTo }: playgroundPropTypes) => (
  <div className="playground">
    <span
      className={classNames({ activeLink: playground.uri && account.uri })}
      onClick={() => {
        if (playground.uri && (account || {}).uri) {
          goTo({
            route: routes.Playground.View,
            params: {
              uriAccount: (account || {}).uri,
              uriPlayground: playground.uri
            },
            state: {
              account
            }
          });
        }
      }}
    >
      {playground.name}
    </span>
    {separator}
  </div>
);

type datasourcePropTypes = {
  datasource: {
    name: string,
    uri: string
  },
  playground: {
    uri: string
  },
  account: {
    uri: string
  },
  goTo: Function
};

const Datasource = ({
  datasource,
  playground,
  account,
  goTo
}: datasourcePropTypes) => (
  <div className="datasource">
    <span
      className={classNames({ activeLink: datasource.uri && account.uri })}
      onClick={() => {
        if (datasource.uri && account.uri) {
          goTo({
            route: routes.Datasource.View,
            params: {
              uriAccount: account.uri,
              uriPlayground: playground.uri,
              uriDatasource: datasource.uri
            },
            state: {
              account,
              playground
            }
          });
        }
      }}
    >
      {datasource.name}
    </span>
    {separator}
  </div>
);

type propTypes = {
  state: Object,
  view?: string,
  goTo: Function,
  goBack: Function,
  helpOn?: {
    content: Object,
    helpCenterLink?: string
  },
  hideGoBack: boolean,
  backLink?: Object,
  type?: string
};

const Breadcrumb = ({
  state = {},
  view,
  goTo,
  goBack,
  helpOn = {},
  hideGoBack,
  backLink,
  type
}: propTypes) => {
  const { content, helpCenterLink } = helpOn;

  const { account = {}, playground = {}, datasource = {} } = state;

  return (
    <div className="bread">
      <div className="bread-title">
        {!hideGoBack && <BackButton goBack={backLink || goBack} />}
        <h4 className="title text-primary">
          {account.name && <Account account={account} goTo={goTo} />}
          {playground.name && (
            <Playground playground={playground} account={account} goTo={goTo} />
          )}
          {datasource.name && (
            <Datasource
              datasource={datasource}
              playground={playground}
              account={account}
              goTo={goTo}
            />
          )}
          {view && <div className="viewName">{view}</div>}
        </h4>

        {content && (
          <HelpOn content={content} helpCenterLink={helpCenterLink} />
        )}
      </div>
      {type === "project" && (
        <div className="bread-project-warning">
          <i className="fas fa-exclamation-triangle fa-spacing" />
          <strong>Important notice:</strong>
          <br />
          <p>
            Security KPI needs Glue tables to be encrypted. This will be
            activated by AWS account administrators. Once this encryption is
            effective, you may face access denied errors. To solve this, you
            will need to launch a refresh role. If the issue persists, please
            contact us using the{" "}
            <a
              href="https://jira.tools.digital.engie.com/servicedesk/customer/portal/8/group/216"
              target="_blank"
              rel="noopener noreferrer"
            >
              JSD
            </a>
            . You can find more information in{" "}
            <a
              href="https://confluence.tools.digital.engie.com/display/CDHA/Glue+Catalog+Encryption+compatible+with+CDH+usage"
              target="_blank"
              rel="noopener noreferrer"
            >
              Glue Catalog Encryption compatible with CDH usage
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default withGoTo(Breadcrumb);
