/* @flow */

import React from "react";
import { connect } from "react-redux";
// $FlowIgnore
import AceEditor from "react-ace";
import { Card, CardContent } from "@material-ui/core";
import { HotTable } from "@handsontable/react";
import withGoTo from "../goToHOC";
import withAppSync from "../AppsyncHOC";
import { updateMessage } from "../globalNotifications/actions";
import "./style.less";
// $FlowIgnore
import "brace/mode/mysql";
// $FlowIgnore
import "brace/ext/language_tools";
// $FlowIgnore
import "brace/theme/sqlserver";
import GraphQl from "../../graphQL";
import AsyncQuery from "../asyncQuery";
import Loading from "../../components/loading";
import Error from "../../components/error";
import TablesList from "./tablesList";
import SelectClassic from "../../components/SelectClassic";

type propTypes = {
  api: GraphQl,
  match: {
    params: {
      cartUri: string
    }
  },
  datasource: Object,
  showInfo: Function,
  hideInfo: Function
};

type stateTypes = {
  isFetchingTables: boolean,
  error: Object,
  selectedTables: Array<Object>,
  isRunningQuery: boolean,
  aceEditorValue: string,
  selectedDatabase: Object,
  query: string,
  editorWidth: Object,
  resultsWidth: Object
};

class DatasourceSqlLab extends React.Component<propTypes, stateTypes> {
  timeout: TimeoutID;

  constructor(props: propTypes) {
    super(props);
    this.state = {
      isFetchingTables: false,
      error: undefined,
      selectedTables: [],
      isRunningQuery: false,
      query: "",
      aceEditorValue: "",
      selectedDatabase: {
        value: props.datasource.uri,
        label: props.datasource.resource_name
      },
      editorWidth: 1100,
      resultsWidth: 1000
    };
  }

  componentDidMount() {
    this.props.hideInfo();
    this.getTables();
  }

  componentWillUnmount() {
    this.props.showInfo();
  }

  getTables = () => {
    this.setState({
      isFetchingTables: true,
      selectedTables: []
    });
    this.props.api.sqlLab
      .getSqlLabDatabaseTables(
        "Datasource",
        this.props.datasource.uri,
        this.props.datasource.platform.uri
      )
      .then((tables) => {
        this.setState({
          selectedTables: tables,
          isFetchingTables: false
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingTables: false,
          error
        });
      });
  };

  buildQuery = (database, table) => {
    this.setState({
      aceEditorValue: `SELECT * FROM "${database}"."${table}" LIMIT 10;`
    });
    this.runQuery();
  };

  runQuery = () => {
    this.setState(
      {
        isRunningQuery: false
      },
      () => {
        this.setState((prevState) => {
          const query = prevState.aceEditorValue;
          return {
            isRunningQuery: true,
            query
          };
        });
      }
    );
  };

  requestQuery = () =>
    this.props.api.sqlLab.runSqlLabQuery(
      this.state.query,
      "Datasource",
      this.state.selectedDatabase.value,
      this.props.datasource.platform.uri
    );

  getStatus = (requestId) =>
    this.props.api.sqlLab.getSqlLabQueryStatus(
      requestId,
      "Datasource",
      this.state.selectedDatabase.value,
      this.props.datasource.platform.uri
    );

  getResult = (requestId) =>
    this.props.api.sqlLab.getSqlLabQueryResults(
      requestId,
      "Datasource",
      this.state.selectedDatabase.value,
      this.props.datasource.platform.uri
    );

  onChange = (newValue) => {
    this.setState({ aceEditorValue: newValue });
  };

  resize = () => {
    this.setState((prevState) => ({
      editorWidth: prevState.editorWidth === 1100 ? 700 : 1100,
      resultsWidth: prevState.resultsWidth === 1000 ? 600 : 1000
    }));
    this.props.hideInfo();
  };

  render() {
    return (
      <div>
        {this.state.error && <Error error={this.state.error} />}
        <Card>
          <CardContent>
            <div className={"expand-container"}>
              <button
                type="submit"
                onClick={this.props.hideInfo}
                className="butn expand-button"
              >
                <i className="fa fa-expand" />
              </button>
            </div>
            <div className="d-flex">
              <div
                className={"col-md-3 border-right"}
                style={{ visibility: "visible" }}
              >
                <div className="mb-3">
                  <div className="facet-home">
                    <h3 className="title-sort">Database</h3>
                  </div>
                  <SelectClassic
                    placeholder={"Select a database"}
                    options={[this.state.selectedDatabase]}
                    selectedOption={this.state.selectedDatabase || null}
                  />
                </div>
                <div className="facet-home form-group">
                  <h3 className="title-sort">
                    Tables ({this.state.selectedTables.length})
                  </h3>
                </div>
                {this.state.isFetchingTables && <Loading message={"tables"} />}
                {this.state.selectedTables &&
                  this.state.selectedTables.map((table) => (
                    <TablesList
                      table={table}
                      columns={table.columns}
                      buildQuery={this.buildQuery}
                    />
                  ))}
                {!this.state.isFetchingTables &&
                  this.state.selectedTables.length === 0 && (
                    <button
                      type={"button"}
                      className={"butn butn-flat"}
                      onClick={this.getTables}
                    >
                      <i
                        className="fas fa-sync-alt fa-spin"
                        aria-hidden="true"
                      ></i>{" "}
                      Reload
                    </button>
                  )}
              </div>
              <div className={"col-md-8"}>
                <div className={"row form-group ml-2 mt-3"}>
                  <AceEditor
                    mode="mysql"
                    theme="sqlserver"
                    onChange={this.onChange}
                    width={this.state.editorWidth}
                    height="200"
                    className={"aceDiv"}
                    name="aceDiv"
                    value={this.state.aceEditorValue}
                  />
                </div>
                <div className={"border-top border-bottom"}>
                  <button
                    disabled={!this.state.aceEditorValue}
                    type={"button"}
                    className={"butn butn-full mt-2 mb-2 mr-2 ml-2"}
                    style={{ height: "40px" }}
                    onClick={this.runQuery}
                  >
                    <i className="fa fa-play-circle" aria-hidden="true"></i> Run
                    Query
                  </button>
                </div>
                <div className={"row ml-2"} style={{ marginTop: "15px" }}>
                  <h3>Results</h3>
                </div>
                <div className={"row ml-2"}>
                  {this.state.isRunningQuery && (
                    <AsyncQuery
                      requestQuery={this.requestQuery}
                      getStatus={this.getStatus}
                      getResult={this.getResult}
                      onResult={(result) => {
                        const metadata = result.schema.map(
                          (field) => field.name
                        );
                        const data = result.records
                          .slice(1)
                          .map((item) =>
                            metadata.map((keyMetadata) =>
                              keyMetadata in item ? item[keyMetadata] : ""
                            )
                          );
                        return (
                          <div className="content">
                            <HotTable
                              colHeaders={metadata}
                              data={data}
                              width={this.state.resultsWidth}
                              height={300}
                            />
                          </div>
                        );
                      }}
                      onPending={() => <Loading message={"Query Results"} />}
                      onError={(error) => (
                        <Error title={""} error={error} path={"Query"} />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  showGlobalNotification: (value) => {
    dispatch(updateMessage(value));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAppSync(withGoTo(DatasourceSqlLab)));
