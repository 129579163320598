/*
 * TODO: I think this method must not be used
 *      because is a backend things
 */

export const getAccountIdFromUri = (uri = "") => uri.split(":")[2];

export const getAccountUriFromAccountId = (id = "") => `uri:account:${id}`;

export const getAccountUriFromUri = (uri = "") =>
  getAccountUriFromAccountId(getAccountIdFromUri(uri));

const getPlaygroundIdFromDatasetUri = (datasetUri = "") =>
  datasetUri.split(":")[3];

export const getPlaygroundUriFromDatasetUri = (datasetUri = "") =>
  `uri:platform:${getAccountIdFromUri(
    datasetUri
  )}:${getPlaygroundIdFromDatasetUri(datasetUri)}`;

export const getDatasourceIdFromDatasetUri = (datasetUri = "") =>
  datasetUri.split(":")[4];

export const getDatasourceUriFromDatasetUri = (datasetUri = "") =>
  `uri:datasource:${getAccountIdFromUri(
    datasetUri
  )}:${getPlaygroundIdFromDatasetUri(
    datasetUri
  )}:${getDatasourceIdFromDatasetUri(datasetUri)}`;

export const getDatasetIdFromDatasetUri = (datasetUri = "") =>
  datasetUri.split(":")[5];

export const getGroupNameFromGroupUri = (groupUri = "") =>
  groupUri.split(":")[3];

export const getPlatformNameFromPlatformUri = (platformUri = "") =>
  platformUri.split(":")[3];

export const getGidFromUri = (uri = "") => {
  if (uri) {
    const uriSplitted = uri.split(":");
    if (Array.isArray(uriSplitted) && uriSplitted.length === 4) {
      return uriSplitted[3];
    }
  }
  return false;
};
